import React, { useState } from "react";
import "../../../Assets/css/plotJob.css";
import HeaderMobile from "../../../Components/HeaderMobile";
import Sidebar from "../../../Components/Sidebar";
import SidebarResponsive from "../../../Components/SidebarResponsive";
import PlotJobMapContainer from "../Components/Containers/PlotJobMap.Container";

const plotJob = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const onMenuButtonClick = () => {
        setOpenMenu(!openMenu);
    };

    return (
        <div id="wrapper">
      <div className="calendar-ca content-area">
        <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenuButtonClick}
        />
        <div className="container">          
          <PlotJobMapContainer></PlotJobMapContainer>
        </div>
      </div>
    </div>
    );
};

export default plotJob;