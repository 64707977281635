import React, { Component, useEffect, useState } from "react";
import { message } from "antd";
import { map, isEqual } from "lodash";
import Loader from "../../../../../Components/Loader";
// import BillingInfo from "../../../../../Customer/Common/BillingInfo";
// import BillingInfo from "./BillingInfo";
import ServiceInfo from "./ServiceInfo";
import moment from "moment-timezone";
import { createServiceOrder } from '../../../../../Action/Customer';
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {resetValues} from "../../../../../Store/serviceOrderDetailDataSlice";
import { getDates } from "../../../../../Store/dateSlice";

const AddService = (props) => {
  const [state, setState] = useState({
    loader: false,
    franchises: {},
    leadData: "",
    currentTab: 0,
    enquiryList: {},
    field_office_uuid: "",
    customer: {},
  });
  const [priceDetail, setPriceDetail] = useState({});
  const [serviceInfo, setServiceInfo] = useState({});
  const billingInfoFunc = React.useRef(null);
  const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);
  const dispatch = useDispatch();

  // on tab change
  const onTabChange = (value) => {
    setState((s) => ({ ...s, currentTab: value }));
  };

  // on convert to customer
  const onSave = (status = 'active') => { 
    var serviceTypeUUID = null;
    const data = props.customer;
    if (serviceInfo?.worksubtype?.value) {
      serviceTypeUUID = serviceInfo?.worksubtype?.value;
    } else if (
      !serviceInfo?.worksubtype &&
      serviceInfo?.baseJobReq?.value?.service
    ) {
      serviceTypeUUID = serviceInfo?.baseJobReq?.value?.service;
    } else {
      serviceTypeUUID = serviceInfo?.baseJobReq?.value?.serviceType;
    }
    let serviceOrderObj = {};
    serviceOrderObj.status = status;
    //serviceOrderObj.service_address_uuid = data?.invoice_address?.uuid;
    serviceOrderObj.service_address_uuid = serviceInfo?.baseJobReq?.value?.address?.uuid || undefined;
    serviceOrderObj.field_office_uuid = data?.field_office.uuid;
    serviceOrderObj.is_billing_address_same =
      serviceInfo?.billingInfo?.value?.isBillingAddressSame;
    serviceOrderObj.client_uuid = data?.uuid;
    serviceOrderObj.is_service = true;
    serviceOrderObj.service_address = serviceInfo?.baseJobReq?.value?.address;
    serviceOrderObj.proposal_date = moment().format("YYYY-MM-DD");
    if(Object.keys(props.proposalInfo).length !== 0){
      serviceOrderObj.proposal_uuid = props.proposalInfo?.uuid;
    }
    serviceOrderObj.contract_date = moment().format("YYYY-MM-DD");
    serviceOrderObj.preferred_start_date = serviceInfo?.preferredDate?.value      
      ? moment(
          serviceInfo?.preferredDate?.value
            ? serviceInfo?.preferredDate?.value
            : null
        ).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    serviceOrderObj.service_type_uuid = serviceTypeUUID;
    serviceOrderObj.is_terms_accepted = (serviceInfo?.isAgreed?.value === 'no')? false : true;
    serviceOrderObj.pool_type_uuid = serviceInfo?.poolType?.value;
    serviceOrderObj.pool_size = parseInt(serviceInfo?.poolSize?.value);
    serviceOrderObj.pool_name = serviceInfo?.baseJobReq?.value?.poolName;
    serviceOrderObj.preferred_contact_methods = serviceInfo?.billingInfo?.value?.contactMethods;
    serviceOrderObj.payment_method = serviceInfo?.billingInfo?.value?.paymentMethod;
    if(serviceInfo?.baseJobReq?.value.preferredTech) serviceOrderObj.preferred_technician_uuid = serviceInfo?.baseJobReq?.value.preferredTech;

    if (serviceInfo?.serviceType?.key.toLowerCase() === "maintenance" || serviceInfo?.serviceType?.key.toLowerCase() === "service") {
      serviceOrderObj.frequency_settings = {
        recurring: serviceInfo?.frequency.value.recurring,
        start: moment(serviceInfo?.frequency.value.start).format("YYYY-MM-DD"),
      };
      if (
        serviceInfo?.frequency.value.end !== undefined &&
        serviceInfo?.frequency.value.end !== null
      )
        serviceOrderObj.frequency_settings.end = moment(
          serviceInfo?.frequency.value.end
        ).format("YYYY-MM-DD");
        if(serviceInfo?.time?.value)
          serviceOrderObj.preferred_start_time = serviceInfo?.time?.value <= 0 ? "" : moment(serviceInfo?.time.value).format("hh:mm") || "";
    }
    if (serviceInfo.maintenanceCategory)
      serviceOrderObj.is_flat_rate =
        serviceInfo.maintenanceCategory?.value.toLowerCase() === "flat_rate"
          ? true
          : false;
        
    serviceOrderObj.price = +priceDetail.price; 
    serviceOrderObj.service_price = +priceDetail.price;  
    serviceOrderObj.notes = serviceInfo?.notes?.value;
    serviceOrderObj.client_type = 'residential';
    serviceOrderObj.service_duration = serviceOrderNewFieldsValue?.duration || '';
    serviceOrderObj.reference_number = serviceOrderNewFieldsValue?.referenceNumber || '';

    const saveServiceOrder = async () => {
      const data = await createServiceOrder(serviceOrderObj);
      if (data?.uuid) {
        dispatch(resetValues());
        setState(s => ({ loader : false }));
        message.success("Service Order is created successfully.");                
        props.onChangeView(0);
        props.getserviceOrder({
          field_office_uuid: props?.customer?.field_office?.uuid,
          client_uuid: props?.customer?.uuid,
        });        
        props.getworkOrders({
          service_order__client__uuid: props?.customer?.uuid,
          scheduled_appointment__gte: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"), 
        });
        props.clearAllFilters();
        dispatch(getDates(props?.customer?.uuid))
      } else {
        setState(s => ({ loader : false }));
        message.error("Something went wrong!");
      }
    }
    saveServiceOrder();
  };

  const setServiceInfoData = (data) => {
    let obj = serviceInfo;
    obj[data.name] = data;
    setServiceInfo(obj);
  };

  const setPriceDetailData = (data) => { 
    setPriceDetail(data);
  };

  const renderTabs = () => {
    const { currentTab } = state;
    switch (currentTab) {
      case 0:
        return (
          <ServiceInfo
            customerId={props.customerId}
            customerUUID={props.customer.uuid}
            onTabChange={onTabChange}
            customer={props.customer}
            currentTab={currentTab}
            isEditEnquiry={true}
            serviceTypeData={props.checkZipCode}
            serviceInfo={serviceInfo}
            priceDetail={priceDetail}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            field_office_uuid={state.field_office_uuid}
            serviceOrder= {props.serviceOrder}
            onSave={onSave}
            onChangeView={props.onChangeView}
            proposalData={props.proposalInfo}
          />
        );
      // case 1:
      //   return (
      //     <BillingInfo
      //       ref={billingInfoFunc}
      //       onTabChange={onTabChange}
      //       setPriceDetailData={setPriceDetailData}
      //       setServiceInfoData={setServiceInfoData}
      //       priceDetail={priceDetail}
      //       serviceTypeData={props.checkZipCode}
      //       serviceInfo={serviceInfo}
      //       customer={props.customer}
      //       onSave = { onSave }
      //     />
      //   );
      default:
        return (
          <ServiceInfo
            customerId={props.customerId}
            customerUUID={props.customer.uuid}
            onTabChange={onTabChange}
            currentTab={currentTab}
            isEditEnquiry={true}
            serviceTypeData={props.checkZipCode}
            serviceInfo={serviceInfo}
            priceDetail={priceDetail}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            onSave={onSave}
            onChangeView={props.onChangeView}
            proposalData={props.proposalInfo}
          />
        );
    }
  };

  const render = () => {
    const { loader } = state;

    return (
      <div id="wrapper">
        <div className="fluid">
          {loader && <Loader />}
          {renderTabs()}
        </div>
      </div>
    );
  };

  return render();
};

export default AddService;
