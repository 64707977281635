import React from "react";
import moment from "moment-timezone";
import { Dropdown, Row, Col, Tooltip } from "antd";
import taskIcon from "../../../Assets/icons/task.svg";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {toggleTaskModal, setSelectedTaskData, setTaskFlagFromEdit} from "../../../Store/technicianTaskDataSlice";
import {editDuration, addDuration} from "../../../Store/serviceOrderDetailDataSlice";

export const DragChipTechGlance = (props) => {
  const { provided, woTaskInfo, onEventClick, shouldOpenPopup, showWOModal, role,
    showRescheduleModal, showDeleteModal, openAccountOverview } = props;
    const dispatch = useDispatch();
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));

    const renderOptList = (data) => {
      if (data?.type === 'Task' && data?.status === 'complete') {
        return false;
      }
      return true;
    }

    const createItems = (data) => {
      const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
      let actionItems = [];
      if (data?.job_forms?.length > 0) {
        actionItems.push({
          label: (
            <span>
              <span
                className="mbsc-font-icon mbsc-icon-file4"
                style={{ marginRight: "5px" }}
              ></span>
              Job Form
            </span>
          ),
          key: "job_form",
        });
      }
      if (data?.edit_work_order_url && !data?.is_invoiced) {
        actionItems.push({
          label: (
            <span>
              {" "}
              <span
                className={`mbsc-font-icon mbsc-icon-pencil mr-5 ${data?.batch_invoice_processed ? 'disabled' : ''}`}
              ></span>
              Edit Work Order
            </span>
          ),
          key: "work_order",
        });
      }
      if (data?.type == 'Task' && data.status !== "complete") {
        actionItems.push({
          label: (
            <span>
              {" "}
              <span
                className="mbsc-font-icon mbsc-icon-pencil mr-5 "
              ></span>
              Edit Task
            </span>
          ),
          key: "edit_task",
        });
      }
      if (data.status !== "complete") {
        if (role === "field_tech" || role === "lead_tech") {
          if (
            (data?.permissionset?.includes("assign_wo") ||
              data?.permissionset?.includes("reschedule_wo")) &&
            (userInfo?.user?.technician_permissions?.reschedule_wo ||
              userInfo?.user?.technician_permissions?.assign_wo)
          ) {
            actionItems.push({
              label: (
                <span>
                  <span
                    className="mbsc-font-icon mbsc-icon-line-calendar"
                    style={{ marginRight: "5px" }}
                  ></span>
                  Reschedule
                </span>
              ),
              key: "reschedule",
            });
          }
          if (
            data?.permissionset?.includes("delete_so") &&
            userInfo?.user?.technician_permissions?.delete_so
          ) {
            actionItems.push({
              label: (
                <span>
                  <span
                    className="mbsc-font-icon mbsc-icon-remove"
                    style={{ marginRight: "5px" }}
                  ></span>
                  Delete
                </span>
              ),
              key: "delete",
            });
          }
        } else {
          actionItems.push({
            label: (
              <span>
                <span
                  className="mbsc-font-icon mbsc-icon-line-calendar"
                  style={{ marginRight: "5px" }}
                ></span>
                Reschedule
              </span>
            ),
            key: "reschedule",
          });
          actionItems.push({
            label: (
              <span>
                <span
                  className="mbsc-font-icon mbsc-icon-remove"
                  style={{ marginRight: "5px" }}
                ></span>
                Delete
              </span>
            ),
            key: "delete",
          });
        }
      }
      return actionItems;
    };

    const getMenuOptions = (data) => {
      const menuItems = createItems(data);
      return <div
      className={`calendar-label duration-${data?.duration ? data?.duration : franchise_data?.data?.default_job_duration}`}
      style={{ backgroundColor: data?.color, width: '100%' }}
  >
        <Row>
          <Col
            className="subtitle time"
            span={12}
          >
            {`${moment(woTaskInfo.start).utc().format("LT")}`}-{`${moment(woTaskInfo.start).add(data?.duration ? data?.duration : franchise_data?.data?.default_job_duration, 'minutes').utc().format("LT")}`}
          </Col>
          {renderOptList(data) && menuItems?.length ?
            <Col xs={24} className="dots-icon-alignment-tech">
              <Row
                justify="end"
                gutter={[10, 5]}
                className="calendar-label-icons"
              >
                <Col>
                  <Tooltip title="Actions">
                    <Dropdown
                      placement="bottomRight"
                      menu=
                      {{
                        items: menuItems,
                        onClick: ((data, e) => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          switch (e.key) {
                            case "job_form":
                              showWOModal({ original: data });
                              return;
                            case "work_order":
                              dispatch(addDuration(data?.service_duration));
                              dispatch(editDuration(true));
                              window.open(
                                data?.edit_work_order_url,
                                "_blank"
                              );
                              return;
                            case "reschedule":
                              showRescheduleModal({ original: data });
                              return;
                            case "delete":
                              showDeleteModal({ original: data });
                              return;
                            case "edit_task":
                              dispatch(setSelectedTaskData(data))
                              dispatch(toggleTaskModal(true))
                              dispatch(setTaskFlagFromEdit(true))
                              return;
                            default:
                              return;
                          }
                        }).bind(this, data),
                      }}
                      trigger={['click']}>
                      <span
                        onClick={(e) => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          e.preventDefault();
                        }}
                        style={{ fontSize: "30px", marginTop: -10, display: 'flex', fontWeight: 800 }}
                      >
                        <div>.</div>
                        <div style={{ paddingInline: 1 }}>.</div>
                        <div>.</div>
                      </span>
                    </Dropdown>
                  </Tooltip>
                </Col>
              </Row>
            </Col> : null}
        </Row>
      <Row justify="space-between" gutter={[10]}>
          <Col xs={24} xl={12} xxl={12} className="title">
              <div className={`${data?.type === "Task" ? 'task-tile-chip' : ''}`}>
                  {data?.type === "Task" ? <img src={taskIcon} alt="" /> : null}
                  {data?.serviceType?.toLowerCase() === "service" ?
                        <span className="text-nowrap">{data?.serviceType}_{data?.workType.charAt(0).toUpperCase() + data?.workType.slice(1)}</span>
                        : <span className="text-nowrap">{data?.serviceType}</span>}
              </div>
          </Col>
      </Row>
      <Row style={{ paddingBottom: "10px" }}>
          <Col xs={24} xxl={12}
            className={`${woTaskInfo?.serviceType == "Task" ? 'subtitle link-title text-nowrap' : 'text-nowrap'}`}
            onClick={() => {
              shouldOpenPopup.current = false;
              setTimeout(
                () => (shouldOpenPopup.current = true),
                200
              );
              if (role !== "field_tech" && woTaskInfo?.serviceType == "Task") {
                openAccountOverview(woTaskInfo)
              }
            }}>
            {woTaskInfo?.client_name ? woTaskInfo?.client_name : woTaskInfo?.customer?.name}
          </Col>
        </Row>
        <Row>
          <Col
            span={16}
            className={`${woTaskInfo?.serviceType != "Task" ? 'subtitle link-title' : ''}`}
            onClick={() => {
              shouldOpenPopup.current = false;
              setTimeout(
                () => (shouldOpenPopup.current = true),
                200
              );
              if (role !== "field_tech" && woTaskInfo?.serviceType != "Task") {
                openAccountOverview(woTaskInfo)
              }
            }}
          >
            <span className={`${woTaskInfo?.serviceType != "Task" ? 'mbsc-font-icon mbsc-icon-link' : ''}`} />
            {` ${woTaskInfo.title ? woTaskInfo.title : ""}`}
          </Col>
        </Row>
  </div>
  }

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        userSelect: "none",
        padding: 8,
        margin: "0 0 8px 0",
        minHeight: "40px",
        backgroundColor: woTaskInfo.color,
        color: "white",
        ...provided.draggableProps.style
      }}
      onClick={(evt) => {
        onEventClick({ domEvent: evt, event: woTaskInfo });
      }}
    >
        {getMenuOptions(woTaskInfo)}
    </div>
  )
}