import React, { useEffect, useRef, useState } from "react";
import Script from "react-load-script";
import Axios from "axios";
import cross_icon from "../../../Assets/images/cross_icon.svg";
import AllState from "../../../Assets/data/stateList.json";
import { Row, Col, Modal, Select } from "antd";
import _ from "lodash";
import * as yup from "yup";

const { Option } = Select;

const RouteOptimization = ({
  optModalOpen,
  setOptModalOpen,
  startlocation,
  stoplocation,
  handleStartlocation,
  handleStoplocation,
  onOptimize,
}) => {
  const stop_address__ref = useRef(null);
  const start_address__ref = useRef(null);
  const [isAddressManually, setIsAddressManually] = useState(false);
  const [isStartAddressManually, setIsStartAddressManually] = useState(false);
  const [errors, setErrors] = useState({});
  const [local_startLocation, setlocal_startLocation] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
  });
  const [local_stopLocation, setlocal_stopLocation] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
  });

  useEffect(() => {
    setlocal_startLocation(startlocation);
    setlocal_stopLocation(stoplocation);
  }, []);

  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null && v?.trim() !== "")
    );
  };

  const handlePlaceSelect = () => {
    const address = document?.getElementById("google_stop_address")?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      }),
    })
      .then((res) => {
        const addressObject = res?.data?.results[0];
        const address = addressObject?.address_components;
        let obj = {};
        if (address) {
          _.map(addressObject?.address_components, (item) => {
            if (item?.types?.includes("administrative_area_level_2")) {
              obj = Object.assign(obj, { address: item.short_name });
            }
            if (item?.types?.includes("route")) {
              obj = Object.assign(obj, { route: item.long_name });
            }
            if (item?.types?.includes("street_number")) {
              obj = Object.assign(obj, { street_number: item.short_name });
            }
            if (item?.types?.includes("administrative_area_level_1")) {
              obj = Object.assign(obj, { state: item.short_name });
            }
            if (item?.types?.includes("locality")) {
              obj = Object.assign(obj, { city: item.long_name });
            }
            if (item?.types?.includes("postal_code")) {
              obj = Object.assign(obj, { zip_code: item.short_name });
            }
          });
          
          setlocal_stopLocation(
            obj?.street_number || obj?.route
              ? {
                  ...obj,
                  address: `${obj?.street_number || ""} ${obj?.route || ""}`,
                }
              : obj
          );
          handleStoplocation(
            obj?.street_number || obj?.route
              ? {
                  ...obj,
                  address: `${obj?.street_number || ""} ${obj?.route || ""}`,
                }
              : obj
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePlaceStartSelect = () => {
    const address = document?.getElementById("google_start_address")?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      }),
    })
      .then((res) => {
        const addressObject = res?.data?.results[0];
        const address = addressObject?.address_components;
        let obj = {};
        if (address) {
          _.map(addressObject?.address_components, (item) => {
            if (item?.types?.includes("administrative_area_level_2")) {
              obj = Object.assign(obj, { address: item.short_name });
            }
            if (item?.types?.includes("route")) {
              obj = Object.assign(obj, { route: item.long_name });
            }
            if (item?.types?.includes("street_number")) {
              obj = Object.assign(obj, { street_number: item.short_name });
            }
            if (item?.types?.includes("administrative_area_level_1")) {
              obj = Object.assign(obj, { state: item.short_name });
            }
            if (item?.types?.includes("locality")) {
              obj = Object.assign(obj, { city: item.long_name });
            }
            if (item?.types?.includes("postal_code")) {
              obj = Object.assign(obj, { zip_code: item.short_name });
            }
          });
          
          setlocal_startLocation(
            obj?.street_number || obj?.route
              ? {
                  ...obj,
                  address: `${obj?.street_number || ""} ${obj?.route || ""}`,
                }
              : obj
          );
          handleStartlocation(obj?.street_number || obj?.route
            ? {
                ...obj,
                address: `${obj?.street_number || ""} ${obj?.route || ""}`,
              }
            : obj);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: "us" } };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const newAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_stop_address"),
      options
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    newAutoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
      "icon",
      "name",
    ]);
    // Fire Event when a suggested name is selected
    newAutoComplete.addListener("place_changed", handlePlaceSelect);
  };

  const handleStartScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: "us" } };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const newAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_start_address"),
      options
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    newAutoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
      "icon",
      "name",
    ]);
    // Fire Event when a suggested name is selected
    newAutoComplete.addListener("place_changed", handlePlaceStartSelect);
  };

  const handleStartAddressManually = () => {
    setIsStartAddressManually(true);
  };
  const handleStartAddressManuallyRemove = () => {
    setIsStartAddressManually(false);
  };

  const handleAddressManually = () => {
    setIsAddressManually(true);
  };
  const handleAddressManuallyRemove = () => {
    setIsAddressManually(false);
  };
  
  const handleChangeStartAddress = (e) => {
    setlocal_startLocation({
      ...local_startLocation,
      [e.target.name]: e.target.value,
    });

    handleStartlocation(e, start_address__ref);
  };

  const handleChangeStopAddress = (e) => {
    setlocal_stopLocation({
      ...local_stopLocation,
      [e.target.name]: e.target.value,
    });

    handleStoplocation(e, stop_address__ref);
  };

  const renderAllState = () => {
    return _.map(AllState?.AllState, (item, i) => {
      return (
        <Option value={item.abbreviation} key={i}>
          {item.name}
        </Option>
      );
    });
  };

  useEffect(() => {
    let str = local_startLocation?.address
      ? `${local_startLocation?.address}`
      : "";
    str += local_startLocation?.city ? `, ${local_startLocation?.city}` : "";
    str += local_startLocation?.state ? `, ${local_startLocation?.state}` : "";
    str += local_startLocation?.zip_code
      ? `, ${local_startLocation?.zip_code}`
      : "";

    start_address__ref.current.value = str || "";
  }, [local_startLocation]);

  useEffect(() => {
    let str = local_stopLocation?.address
      ? `${local_stopLocation?.address}`
      : "";
    str += local_stopLocation?.city ? `, ${local_stopLocation?.city}` : "";
    str += local_stopLocation?.state ? `, ${local_stopLocation?.state}` : "";
    str += local_stopLocation?.zip_code
      ? `, ${local_stopLocation?.zip_code}`
      : "";

    stop_address__ref.current.value = str || "";
  }, [local_stopLocation]);

  const renderStartAddressField = () => {
    return (
      <>
        <div className="google_search_place fitem">
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`}
            onLoad={handleStartScriptLoad}
          />
          <input
            autoComplete="off"
            ref={start_address__ref}
            type="text"
            disabled={isStartAddressManually}
            className={"form-control mb-10"}
            placeholder={"Location"}
            name="google_start_address"
            id="google_start_address"
            onBlur={handlePlaceStartSelect}
          />
          <b>
            or{" "}
            <a onClick={handleStartAddressManually}>
              <u>enter address manually</u>
            </a>
          </b>
        </div>
        {isStartAddressManually ? (
          <div className="manually_address">
            <a onClick={handleStartAddressManuallyRemove}>
              <img src={cross_icon} alt="" className="float-right mb-10" />
            </a>
            <br />
            <div className="form-group mb-20">
              <div className="fitem">
                <input
                  type="text"
                  placeholder={"Location"}
                  name="address"
                  value={local_startLocation?.address}
                  id="address"
                  onChange={(e) =>
                    handleChangeStartAddress({
                      target: { value: e.target.value, name: e.target.name },
                    })
                  }
                />
              </div>
              <div className="half-fitem fitem">
                <div className="half-fitem fitem">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={local_startLocation?.city}
                    onChange={(e) =>
                      handleChangeStartAddress({
                        target: { value: e.target.value, name: e.target.name },
                      })
                    }
                  />
                </div>
                <div className="half-fitem fitem">
                  <Select
                    showArrow={true}
                    name="state"
                    placeholder="State"
                    virtual={false}
                    value={local_startLocation?.state || undefined}
                    onChange={(value) =>
                      handleChangeStartAddress({
                        target: { name: "state", value: value },
                      })
                    }
                  >
                    {renderAllState()}
                  </Select>
                </div>
                <div className="half-fitem fitem">
                  <input
                    type="text"
                    className="form--control w-100"
                    placeholder="ZIP Code"
                    value={local_startLocation?.zip_code}
                    name="zip_code"
                    maxLength={5}
                    minLength={5}
                    onChange={(e) =>
                      handleChangeStartAddress({
                        target: { value: e.target.value, name: e.target.name },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {_.get(errors, "start_location.address") ||
        _.get(errors, "start_location.city") ||
        _.get(errors, "start_location.state") ||
        _.get(errors, "start_location.zip_code") ? (
          <label className="new_err_msg">
            <i className="las la-info-circle" />
            {"Please enter valid start address"}
          </label>
        ) : (
          ""
        )}
      </>
    );
  };

  const renderStopAddressField = () => {
    return (
      <>
        <div className="google_search_place fitem">
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDK1YP-rKe1WhYawLJA1YXpFvJW1x2aEDc&libraries=places`}
            onLoad={handleScriptLoad}
          />
          <input
            autoComplete="off"
            ref={stop_address__ref}
            type="text"
            disabled={isAddressManually}
            className={"form-control mb-10"}
            placeholder={"Location"}
            name="google_stop_address"
            id="google_stop_address"
            onBlur={handlePlaceSelect}
          />
          <b>
            or{" "}
            <a onClick={handleAddressManually}>
              <u>enter address manually</u>
            </a>
          </b>
        </div>
        {isAddressManually ? (
          <div className="manually_address">
            <a onClick={handleAddressManuallyRemove}>
              <img src={cross_icon} alt="" className="float-right mb-10" />
            </a>
            <br />
            <div className="form-group mb-20">
              <div className="fitem">
                <input
                  type="text"
                  placeholder={"Location"}
                  name="address"
                  value={local_stopLocation?.address}
                  id="address"
                  onChange={(e) =>
                    handleChangeStopAddress({
                      target: { value: e.target.value, name: e.target.name },
                    })
                  }
                />
              </div>
              <div className="half-fitem fitem">
                <div className="half-fitem fitem">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={local_stopLocation?.city}
                    onChange={(e) =>
                      handleChangeStopAddress({
                        target: { value: e.target.value, name: e.target.name },
                      })
                    }
                  />
                </div>
                <div className="half-fitem fitem">
                  <Select
                    showArrow={true}
                    name="state"
                    placeholder="State"
                    virtual={false}
                    value={local_stopLocation?.state || undefined}
                    onChange={(value) =>
                      handleChangeStopAddress({
                        target: { name: "state", value: value },
                      })
                    }
                  >
                    {renderAllState()}
                  </Select>
                </div>
                <div className="half-fitem fitem">
                  <input
                    type="text"
                    className="form--control w-100"
                    placeholder="ZIP Code"
                    value={local_stopLocation?.zip_code}
                    name="zip_code"
                    maxLength={5}
                    minLength={5}
                    onChange={(e) =>
                      handleChangeStopAddress({
                        target: { value: e.target.value, name: e.target.name },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {_.get(errors, "end_location.address") ||
        _.get(errors, "end_location.city") ||
        _.get(errors, "end_location.state") ||
        _.get(errors, "end_location.zip_code") ? (
          <label className="new_err_msg">
            <i className="las la-info-circle" />
            {"Please enter valid stop address"}
          </label>
        ) : (
          ""
        )}
      </>
    );
  };

  const onClickOptimize = () => {    
    setErrors([]);
    const data = {
      start_location: local_startLocation,
      end_location: local_stopLocation
    };

    //Validate data
    const schema = yup.object().shape({
      start_location: yup.object().shape({
        address: yup.string().required("Address is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
        zip_code: yup.string().required("Zipcode is required"),
      }),
      end_location: yup.object().shape({
        address: yup.string().required("Address is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
        zip_code: yup.string().required("Zipcode is required"),
      }),
    });

    schema
      .validate(data, { abortEarly: false })
      .then(async () => {        
        onOptimize();        
      })
      .catch((error) => {
        let err_data = {};
        _.map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log(err_data);
      });
  }

  return (
    <Modal
      title="Route Optimization"
      open={optModalOpen}
      onOk={() => {
        onClickOptimize();
      }}
      onCancel={() => setOptModalOpen(false)}
      okText="Optimize"
      destroyOnClose
      closable={false}
      className="schedule_opt_modal"
      style={{
        top: 20,
        width: "100%",
      }}
      maskClosable={false}
    >
      <div className="container pt-10">
        <div className="fitem">
          <Row
            justify="start"
            align="middle"
            style={{ height: "100%", alignItems: "flex-start" }}
          >
            <Col md={5}>
              <label className="centered-element-input">Start Location</label>
            </Col>
            <Col md={19} xs={24}>
              { renderStartAddressField() }
            </Col>
          </Row>
        </div>
        <div className="fitem mt-10">
          <Row
            justify="start"
            align="middle"
            style={{ height: "100%", alignItems: "flex-start" }}
          >
            <Col md={5}>
              <label className="centered-element-input">Stop Location</label>
            </Col>
            <Col md={19} xs={24}>
              {renderStopAddressField()}
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default RouteOptimization;
