import React from "react";
import { Table, Row, Col } from "antd";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";

const ChemicalTable = () => {
  const {
    reportData,
    current,
    total,
    setCurrent,
    setPageSize,
    pageSize,
    setSorter,
  } = useReport();

  const handleTableChange = (pagination, filters, sorter, extra) =>
    extra.action === "sort"
      ? sorter.order !== undefined
        ? setSorter(sorter)
        : setSorter(null)
      : null;

  return (
    <>
      <Row>
        <Col span={24} className={"territory-table-container"}>
          <Table
            dataSource={
               reportData.map((item, idx) => ({ key: idx, ...item }))

            }
            columns={
               DataTable.ChemicalPricingReport(this)
            }
            bordered
            className="table hscroll"
            onChange={handleTableChange}
            pagination={{
              showSizeChanger: false,
              pageSize: pageSize,
              ordering: "",
              current,
              total,
              onChange(page, pageSize) {
                setCurrent(page);
                setPageSize(pageSize);
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ChemicalTable;
