import React, { useEffect, useState } from "react";
import { Drawer, message, Popconfirm, Select, Modal } from "antd";
import {
  map,
  isEmpty,
  uniqBy,
  filter,
  sortBy,
  orderBy,
  cloneDeep,
} from "lodash";
import axios from "../../../Lib/Axios";
import {
  StopOutlined,
  CloseOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import * as yup from "yup";
import visa from "../../../Assets/images/visa_dark.svg";
import american from "../../../Assets/images/american_express_dark.svg";
import moment from "moment-timezone";
import EmptyData from "../../../Components/EmptyData";
import PrivateNotes from "../CustomerDetails/PrivateNotes";
import UpdateContact from "./UpdateContact";
import AddAuthorizedUsers from "./AddAuthorizedUsers";
import UpdateBlockList from "./UpdateBlockList";
import { OmitProps } from "antd/lib/transfer/ListBody";
import bank from "../../../Assets/images/Bank.png";
import AddAddress from "./AddAddress";
import {
  addBillingInfo,
  getCustomer,
  getServiceOrderByClient,
  getServiceOrderByUser,
  getUserBillingInfo,
  updateBillingInfo,
  deleteBillingInfo,
} from "../../../Action/Customer";
import { getFieldTechnician } from "../../../Action/Franchise";
import { useSelector } from "react-redux";
import {
  getCustomerDetails,
  createCustomerInstax,
  createAscPaymentData,
  createCardPaymentData,
} from "../../Common/utils";
import { useDispatch } from "react-redux/es/exports";
import { addCustomer } from "../../../Store/customerSlice";
import Loader from "../../../Components/Loader";
import {
  updateCustomer,
  getCustomerInvoicePreference,
  updateCustomerInvoicePreference,
} from "../../../Action/Customer/index";
import ActiveDeactiveCustomer from "../../Common/ActiveDeactiveCustomer";
import DeleteCustomer from "../../Common/DeleteCustomer";
import BatchInvoicePreference from "../../InvoicePreference";
import { setAccountDetail } from "../../../Store/accountDetailSlice";

const AccountDetails = (props) => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer);
  const accountDetail = useSelector((state) => state.accountDetail);

  const { Option } = Select;
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [cancelation_reason, setcancelation_reason] = useState("");
  const [cancelation_details, setcancelation_details] = useState("");
  const [errors, setErrors] = useState([]);
  const [is_reason_select, setis_reason_select] = useState(false);
  const [updateContact, setUpdateContact] = useState(false);
  const [addAuthorizedUser, setAddAuthorizedUser] = useState(false);
  const [cardDetail, setCardDetail] = useState({});
  const [showCreditCard, setshowCreditCard] = useState(false);
  const [can_edit, setcan_edit] = useState(false);
  const [can_edit_bank, setcan_edit_bank] = useState(false);

  const [staxPublicApikey, setStaxPublicApikey] = useState("");
  const [updateBlockList, setUpdateBlockList] = useState(false);
  const [cardList, setCardList] = useState({});
  const [hide_delete_card, sethide_delete_card] = useState(true);
  const [addressList, setaddressList] = useState([]);
  const [card_address, setcard_address] = useState({
    address: "",
    address2:"",
    city: "",
    state: "",
    zip_code: "",
    geo_location: "",
    formatted_address:""
  });
  const [card_holder_name, setcard_holder_name] = useState("");
  const [account_holder_name, setaccount_holder_name] = useState("");
  const [card_number, setcard_number] = useState("");
  const [card_expiry_month, setcard_expiry_month] = useState("");
  const [card_expiry_year, setcard_expiry_year] = useState("");
  const [card_cvv, setcard_cvv] = useState("");
  const [addresses, setaddresses] = useState([]);
  const [showAddAddressView, setshowAddAddressView] = useState(false);
  const [is_btn_active, setis_btn_active] = useState(false);
  const [charge_invoices_to_card, setcharge_invoices_to_card] = useState(true);
  const [charge_invoices_ACHPayment, setcharge_invoices_ACHPayemnt] =
    useState(true);
  const [customer, setCustomer] = useState({});
  const [stax_customer_id, setstax_customer_id] = useState("");
  const [tech, setTech] = useState("");
  const [hideAddNewButton, sethideAddNewButton] = useState(false);
  const [fattJs, setFattJs] = useState(null);
  const [tech_list, settech_list] = useState({});
  const [loader, setLoader] = useState(false);
  const [serviceOrder, setServiceOrder] = useState([]);
  const [tempData, setTempData] = useState(customers);

  const [paymentMethod, setPaymentMethod] = useState("Please Select");
  const [isNewACHPament, setisNewACHPament] = useState(false);
  const [BankName, setBankName] = useState("");
  const [RoutingNumber, setRoutingNumber] = useState("");
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [AccountType, setAccountType] = useState("");
  const [BankHolderType, setBankHolderType] = useState("");

  const userBillingInfo = async () => {
    const data = await getUserBillingInfo({ user__uuid: props?.record?.uuid });
    setCardList(data);
  };

  const getInvoicePreference = async () => {
    const data = await getCustomerInvoicePreference({
      user_uuid: props?.record?.uuid,
    });
    dispatch(
      setAccountDetail({ invoicePreference: data?.account_level_invoice })
    );
  };

  const getCustomerInfo = async () => {
    const data = await getCustomer(props?.record?.uuid);
    dispatch(addCustomer(data));
  };
  const payment_methods = [
    { key: "Please Select", value: "Please Select" },
    { key: "Credit Card", value: "card" },
    { key: "ACH Payments", value: "ACH Payments" },
  ];
  const choosePaymentMethod = (e) => {
    setPaymentMethod(e);
  };
  const getServiceOrder = async () => {
    const data = await getServiceOrderByClient({
      field_office_uuid: props?.record?.field_office?.uuid,
      client_uuid: props?.record?.uuid,
    });
    setServiceOrder(data);
    serviceAddressCheck(true, cloneDeep(data?.[0]?.service_address));
  };
  // ACH PAYMENT
  // const renderPaymentModal = () => {
  //   return (

  //           )
  //         }

  //ACH PAYMENT
  useEffect(() => {
    let stax_public_apikey = props?.customer?.field_office?.stax_public_apikey;
    userBillingInfo();
    getServiceOrder();
    getInvoicePreference();
    setStaxPublicApikey(stax_public_apikey);
    setTech(props?.customer?.preferred_technician?.uuid || "");
    setstax_customer_id(props?.customer?.stax_customer);
    sethideAddNewButton(
      props?.customer?.field_office?.stax_public_apikey ? false : true
    );
    const getFieldTech = async () => {
      const data = await getFieldTechnician({
        field_office__uuid: props?.record?.field_office?.uuid,
      });
      settech_list(data);
    };
    getFieldTech();
  }, []);

  // Add New Card
  const addNewCard = async () => {
    let customer = JSON.parse(JSON.stringify(props.customer));
    customer.emails = [...customer.emailaddress_set];
    customer.phoneNumbers = [...customer.phone_numbers];
    customer.address = card_address;
    customer.firstName = customer.first_name;
    customer.lastName = customer.last_name;
    setLoader(true);
    const token_obj = {
      month: card_expiry_month ? card_expiry_month : null,
      year: card_expiry_year ? card_expiry_year : null,
      card_number: card_number ? card_number : null,
      firstName: card_holder_name
        ? card_holder_name?.split(" ")[0]
        : props?.customer?.first_name,
      lastName: card_holder_name
        ? card_holder_name?.split(" ")[1]
        : props?.customer.last_name,
      method: "card",
      person_name: props?.customer
        ? `${props?.customer.first_name} ${props?.customer.last_name}`
        : null,
    };
    let customerData = await getCustomerDetails(customer);
    let createCustomerResp = await createCustomerInstax(customerData);
    if (createCustomerResp.id) {
      let card_data = await createCardPaymentData(
        token_obj.card_holder_name,
        token_obj.firstName,
        token_obj.lastName,
        token_obj.card_number,
        token_obj.month,
        token_obj.year,
        createCustomerResp.id
      );
      fattJs
        .tokenize(card_data)
        .then((res) => {
          //Once the Credit Card is saved in Stax is saved on Billing info
          if (res && Object.keys(res).length > 0) {
            const postBillingInfo = async () => {
              const data = await addBillingInfo({
                user_uuid: customer?.uuid || null,
                address: customer?.address || null,
                stax_card_token: res["id"] || null,
                card_holder_name: card_holder_name,
                charge_invoice_card: charge_invoices_to_card,
              });
              //When the new Credit card is already saved in stax and Billing info, we can access to its data
              compareCreditCards(data, res);
            };
            postBillingInfo();
          }
        })
        .catch((err) => {
          console.error("Error : ", err);
          setLoader(false);
          message.error(err.errors);
          map(err.fieldErrors, (item) => {
            message.error(item.message, 10);
          });
        });
    }
  };

  //Compare the customer's Credit Cards to check if the new one is already saved
  const compareCreditCards = async (data, res) => {
    let card_saved = false;
    const list_cards = await getUserBillingInfo({
      user__uuid: props?.customer?.uuid,
    });
    const newcard = list_cards.data.find((i) => i.stax_card_token === res.id);
    const newcard_stax = JSON.parse(newcard.stax_payment_object);

    cardList.data.forEach((item) => {
      let card_info;
      if (item.stax_payment_object) {
        if (JSON.parse(item.stax_payment_object).method === "card") {
          card_info = JSON.parse(item.stax_payment_object);
          if (
            card_info?.card_last_four === newcard_stax?.card_last_four &&
            card_info?.card_exp === newcard_stax?.card_exp &&
            card_info?.meta.cardDisplay === newcard_stax?.meta.cardDisplay
          ) {
            card_saved = true;
            return;
          }
        }
      }
    });
    //If the new Credit card is same of one already saved
    if (card_saved) {
      message.error(
        "The card is already saved, please use another card or use existing one."
      );
      //Delete the new card saved
      deleteCreditCard(newcard.uuid);
    } else if (data?.uuid) {
      setcard_address({
        address: "",
        address2: "",
        city: "",
        state: "",
        zip_code: "",
        geo_location: "",
        formatted_address: "",
      });
      onCancel();
      userBillingInfo();
      setLoader(false);
      message.success("Billing Information added successfully!", 10);
    }
  };

  const deleteCreditCard = async (id) => {
    setLoader(true);
    const data = await deleteBillingInfo(id);
    if (data.length === 0) {
      setLoader(false);
      userBillingInfo();
    } else {
      setLoader(false);
      message.error(
        "Something went wrong deleting credit card, please try again."
      );
    }
  };

  // card validation
  const validation = () => {
    if (paymentMethod != "Please Select") {
      if (paymentMethod === "card")
        setTimeout(() => {
          const cardSchema = yup.object().shape({
            card_number: fattJs.validNumber
              ? null
              : yup.string().nullable().required("Card Number is required"),
            card_expiry_month: yup
              .string()
              .nullable()
              .required("Card expire month is required"),
            card_expiry_year: yup
              .string()
              .nullable()
              .required("Card expire year is required")
              .min(4, "Card expire year is invalid.")
              .max(4, "Card expire year is invalid."),
            card_cvv: fattJs.validCvv
              ? null
              : yup.string().nullable().required("Card CVC is required"),
            card_address: yup.object().shape({
              address: yup.string().required("Address is required"),
              state: yup.string().required("State is required"),
              city: yup.string().required("City is required"),
              zip_code: yup.string().required("Zipcode is required"),
            }),
            card_holder_name: yup
              .string()
              .required("Cardholder name is required"),
          });

          const cardData = {
            card_address,
            card_holder_name,
            card_number,
            card_expiry_month,
            card_expiry_year,
            card_cvv,
          };
          cardSchema
            .validate(cardData, { abortEarly: false })
            .then(() => {
              setErrors({});
              setis_btn_active(true);
              // console.log("tttt")
              addNewCard();
            })
            .catch((error) => {
              let err_data = {};
              map(error.inner, (item) => {
                // message.error(item.message,10);
                err_data[item.path] = item.message;
                setErrors(err_data);
              });
              console.error("Error : ", err_data);
              setErrors(err_data);
              // this.setState({errors: err_data, is_btn_active: false});
              // message.error(`Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`);
            });
        }, 500);
      else if (paymentMethod === "ACH Payments") {
        const ACHSchema = yup.object().shape({
          BankName: yup.string().required("Please enter correct value!"),
          RoutingNumber: yup
            .string()
            .nullable()
            .required("Please enter correct value!"),
          AccountHolderName: yup
            .string()
            .nullable()
            .required("Please enter correct value!"),
          AccountNumber: yup
            .string()
            .nullable()
            .required("Please enter correct value!"),
          AccountType: yup.string().required("Please enter correct value!"),
          BankHolderType: yup.string().required("Please enter correct value!"),
        });

        const ACHData = {
          BankName,
          RoutingNumber,
          AccountHolderName,
          AccountNumber,
          AccountType,
          BankHolderType,
        };
        ACHSchema.validate(ACHData, { abortEarly: false })
          .then(() => {
            setErrors({});
            setLoader(true);
            addNewACHPayment();
          })
          .catch((error) => {
            setLoader(false);
            let err_data = {};
            map(error?.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setErrors(err_data);
          });
      }
    } else {
      message.error("Please select a payment method");
    }
  };
  // new ach payment

  const addNewACHPayment = async () => {
    let customer = JSON.parse(JSON.stringify(props.customer));
    customer.emails = [...customer.emailaddress_set];
    customer.phoneNumbers = [...customer.phone_numbers];
    customer.address = !isEmpty(customer.billing_address[0])
      ? { ...customer.billing_address[0] }
      : { ...customer.invoice_address };
    customer.firstName = customer.first_name;
    customer.lastName = customer.last_name;
    setLoader(true);
    let customerData = await getCustomerDetails(customer);
    let createCustomerResp = await createCustomerInstax(customerData);
    let ach_data = await createAscPaymentData(
      AccountHolderName,
      AccountNumber,
      RoutingNumber,
      BankHolderType,
      AccountType,
      createCustomerResp.id
    );
    fattJs
      .tokenize(ach_data)
      .then((res) => {
        const postBillingInfo = async () => {
          const data = await addBillingInfo({
            user_uuid: customer?.uuid || null,
            address: customer?.address || null,
            stax_card_token: res?.id || null,
            charge_invoice_bank: charge_invoices_ACHPayment,
          });
          if (data?.uuid) {
            onCancel();
            userBillingInfo();
            setLoader(false);
            message.success("Billing Information added successfully!", 10);
          }
        };
        postBillingInfo();
      })
      .catch((err) => {
        console.error("Error : ", err);
        setLoader(false);
        message.error(err.errors);
        map(err.fieldErrors, (item) => {
          message.error(item.message, 10);
        });
      });
  };
  // format phone number
  const formatPhoneNumber = (phone) => {
    phone = phone?.replace(/\D/g, "");
    return (
      // (XXX) XXX-XXXX
      `(${phone?.substr(0, 3)})` +
      " " +
      phone?.substr(3, 3) +
      "-" +
      phone?.substr(6)
    );
  };
  // on add address
  const onAddAddress = (obj) => {
    addresses.push({
      invoice_address: { uuid: (Math.random() * 10).toString(), ...obj },
    });
    setaddresses(addresses);
  };

  // on Clear
  const onClear = () => {
    setPaymentMethod("Please Select");
    setcard_address({
      address: "",
      address2:"",
      city: "",
      state: "",
      zip_code: "",
      geo_location: "",
      formatted_address:""
    });
    setcard_expiry_month("");
    setcard_expiry_year("");
    setcard_holder_name("");
    setaddresses([]);
    setshowAddAddressView(false);
    setErrors({});
  };

  //on cancel
  const onCancel = () => {
    toggleCreditCard(false);
    onClear();
  };

  // toggle add address view
  const toggleAddAddress = (value) => {
    setshowAddAddressView(value);
  };

  //Set service Address
  const serviceAddressCheck = (e, item) => {
    if (e?.target?.checked || e) {
      let card_address = cloneDeep({
        uuid: item?.uuid,
        address: item?.address,
        city: item?.city,
        state: item?.state,
        zip_code: item?.zip_code,
      });
      setcard_address(card_address);
    }
  };

  //render service address
  const renderServiceOrder = () => {
    const list = [
      ...(cardList?.data || []),
      ...(serviceOrder || []),
      ...addresses,
    ];
    let addressList = [];
    filter(list, (obj) =>
      obj.invoice_address
        ? addressList.push({ ...obj.invoice_address, uuid: serviceOrder[0]?.service_address?.uuid })
        : addressList.push({ ...obj.address })
    );
    addressList = uniqBy(
      orderBy([...addressList], "uuid", "desc"),
      "formatted_address"
    );
    if (addressList && addressList.length > 0) {
      addressList = addressList.filter((x) => {
        return x.uuid !== null;
      });
    }
    return map(addressList, (item, i) => {
      return (
        <div className="fitem-check" key={i}>
          <input
            type="radio"
            name="address"
            // checked={card_address?.uuid === item.uuid}
            checked={card_address?.uuid === item.uuid}
            id={`address${i}`}
            className="fitem-ck-input"
            onChange={(e) => {
              serviceAddressCheck(e, cloneDeep(item));
            }}
          />
          <label htmlFor={`address${i}`} className="fitem-ck-txt">
            {`${item.address}, ${item.city}, ${item.state}, ${item.zip_code} `}
          </label>
        </div>
      );
    });
  };

  // update validation
  const updateValidate = () => {
    if (paymentMethod != "ACH Payments") {
      setTimeout(() => {
        const cardSchema = yup.object().shape({
          //  card_holder_name: yup.string().required("Cardholder name is required"),
          card_address: yup.object().shape({
            address: yup.string().required("Address is required"),
            state: yup.string().required("State is required"),
            city: yup.string().required("City is required"),
            zip_code: yup.string().required("Zipcode is required"),
          }),
        });
        const cardData = { card_address, card_holder_name };
        cardSchema
          .validate(cardData, { abortEarly: false })
          .then(() => {
            setErrors({});
            setis_btn_active(true);
            onUpdateCard();
          })
          .catch((error) => {
            let err_data = {};
            map(error.inner, (item) => {
              message.error(item.message, 10);
              err_data[item.path] = item.message;
            });
            console.error("Error : ", err_data);
            setErrors(err_data);
            setis_btn_active(false);
            message.error(
              `Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`
            );
          });
      }, 500);
    } else {
      onUpdateACHPayment();
    }
  };

  // Update card
  const onUpdateCard = () => {
    if (card_address.uuid.includes(".")) delete card_address.uuid;
    if (!card_address.geo_location) delete card_address.geo_location;
    // setLoader(true)
    const updateBilling = async () => {
      const data = await updateBillingInfo({
        user_uuid: props?.customer?.uuid,
        uuid: cardDetail?.uuid,
        address: card_address,
        card_holder_name: card_holder_name,
        charge_invoice_card: charge_invoices_to_card,
      });
      if (data?.uuid) {
        toggleCreditCard(false);
        onClear();
        message.success("Billing infromation updated!");
        userBillingInfo();
      }
    };
    updateBilling();
  };
  const onUpdateACHPayment = () => {
    if (card_address.uuid.includes(".")) delete card_address.uuid;
    if (!card_address.geo_location) delete card_address.geo_location;
    const updateBilling = async () => {
      const data = await updateBillingInfo({
        user_uuid: props?.customer?.uuid,
        uuid: cardDetail?.uuid,
        address: card_address,
        card_holder_name: account_holder_name,
        charge_invoice_bank: charge_invoices_ACHPayment,
      });
      if (data?.uuid) {
        toggleCreditCard(false);
        onClear();
        message.success("Billing infromation updated!");
        userBillingInfo();
      }
    };
    updateBilling();
  };

  //  const  renderACHpaymentDrawer = ()=>{
  //     const closeDrawer = () => {
  //       toggleCreditCard(false);
  //       onClear();
  //     };
  //   }

  // render drawer
  const renderCreditCardDrawer = () => {
    const closeDrawer = () => {
      toggleCreditCard(false);
      onClear();
    };

    return (
      <Drawer
        placement={"right"}
        closable={false}
        onClose={closeDrawer}
        visible={showCreditCard}
        key={"right"}
        width={"400"}
        className="drawer-wrapper"
      >
        <div className="rdrawer filter_drawer_wrapper">
          <div className="card-head flex ai-center">
            {
              <h4>
                {can_edit
                  ? "Update Credit Card"
                  : can_edit_bank
                  ? "Update ACH Payment"
                  : "Add Payment Method"}
              </h4>
            }
            <CloseOutlined />
          </div>

          <div className="card-row flex-column">
            <div className="card-full">
              <div className="form-group">
                {!can_edit_bank && !can_edit ? (
                  <div>
                    <label>
                      Payment Method <sup>*</sup>
                    </label>
                    <div className="mb-10">
                      <Select
                        placeholder="Please Select"
                        value={paymentMethod || undefined}
                        onChange={(e) => choosePaymentMethod(e)}
                      >
                        {map(payment_methods, (item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.key}
                            </Option>
                          );
                        })}
                      </Select>
                      {errors?.["paymentMethod"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["paymentMethod"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {paymentMethod != "Please Select" ? (
                  paymentMethod === "ACH Payments" ? (
                    <>
                      {!can_edit_bank ? (
                        <div className={"flex mt-10"}>
                          <div className={"flex-1"}>
                            <div className="form-group">
                              <div className="fitem">
                                <label>Bank Name</label>
                                <label style={{ color: "red" }}> &nbsp;*</label>
                                <input
                                  type="text"
                                  className="form--control"
                                  placeholder="Bank Name"
                                  maxLength={15}
                                  inputMode="text"
                                  onChange={(e) => {
                                    setBankName(e.target.value);
                                  }}
                                />
                                {errors?.["BankName"] ? (
                                  <label className="new_err_msg">
                                    <i className="las la-info-circle" />
                                    {errors?.["BankName"]}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="fitem">
                                <label>Routing Number</label>
                                <label style={{ color: "red" }}> &nbsp;*</label>
                                <input
                                  type="text"
                                  className="form--control"
                                  placeholder="Routing Number"
                                  maxLength={9}
                                  inputMode="number"
                                  onKeyPress={(e) => {
                                    const pattern = /[0-9]/;
                                    const inputChar = String.fromCharCode(
                                      e.charCode
                                    );
                                    if (!pattern.test(inputChar)) {
                                      // invalid character, prevent input
                                      e.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    if (
                                      event.clipboardData
                                        .getData("Text")
                                        .match(/[^\d]/)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setRoutingNumber(e.target.value);
                                  }}
                                />
                                {errors?.["RoutingNumber"] ? (
                                  <label className="new_err_msg">
                                    <i className="las la-info-circle" />
                                    {errors?.["RoutingNumber"]}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="fitem">
                                <label>Account Holder Name</label>
                                <label style={{ color: "red" }}> &nbsp;*</label>
                                <input
                                  type="text"
                                  className="form--control"
                                  placeholder="Account Holder Name"
                                  maxLength={20}
                                  inputMode="number"
                                  onChange={(e) => {
                                    setAccountHolderName(e.target.value);
                                  }}
                                />
                                {errors?.["AccountHolderName"] ? (
                                  <label className="new_err_msg">
                                    <i className="las la-info-circle" />
                                    {errors?.["AccountHolderName"]}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="fitem">
                                <label>Account Number</label>
                                <label style={{ color: "red" }}> &nbsp;*</label>
                                <input
                                  type="text"
                                  id="account-number"
                                  className="form--control"
                                  placeholder="Account Number"
                                  maxLength={15}
                                  inputMode="number"
                                  onKeyPress={(e) => {
                                    const pattern = /[0-9]/;
                                    const inputChar = String.fromCharCode(
                                      e.charCode
                                    );
                                    if (!pattern.test(inputChar)) {
                                      // invalid character, prevent input
                                      e.preventDefault();
                                    }
                                  }}
                                  onPaste={(event) => {
                                    if (
                                      event.clipboardData
                                        .getData("Text")
                                        .match(/[^\d]/)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setAccountNumber(e.target.value);
                                  }}
                                />
                                {errors?.["AccountNumber"] ? (
                                  <label className="new_err_msg">
                                    <i className="las la-info-circle" />
                                    {errors?.["AccountNumber"]}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="fitem">
                                <label>Account Type</label>
                                <label style={{ color: "red" }}> &nbsp;*</label>
                                <div>
                                  <input
                                    type="radio"
                                    id="achecking"
                                    name="AccountType"
                                    onChange={(e) =>
                                      setAccountType(e.target.value)
                                    }
                                    value={"checking"}
                                  />
                                  &nbsp;&nbsp;
                                  <label for="html">Checking</label>
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                  <input
                                    type="radio"
                                    id="asaving"
                                    name="AccountType"
                                    onChange={(e) =>
                                      setAccountType(e.target.value)
                                    }
                                    value="savings"
                                  />
                                  &nbsp;&nbsp;
                                  <label for="html">Savings </label>
                                </div>
                                {errors?.["AccountType"] ? (
                                  <label className="new_err_msg">
                                    <i className="las la-info-circle" />
                                    {errors?.["AccountType"]}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="fitem">
                                <label>Bank Holder Type</label>
                                <label style={{ color: "red" }}> &nbsp;*</label>
                                <div>
                                  <input
                                    type="radio"
                                    id="checking"
                                    name="BankHolderType"
                                    onChange={(e) =>
                                      setBankHolderType(e.target.value)
                                    }
                                    value="Personal"
                                  />
                                  &nbsp;&nbsp;
                                  <label for="html">Personal</label>
                                  <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                  <input
                                    type="radio"
                                    id="saving"
                                    name="BankHolderType"
                                    onChange={(e) =>
                                      setBankHolderType(e.target.value)
                                    }
                                    value="Business"
                                  />
                                  &nbsp;&nbsp;
                                  <label for="html">Business</label>
                                </div>
                                {errors?.["BankHolderType"] ? (
                                  <label className="new_err_msg">
                                    <i className="las la-info-circle" />
                                    {errors?.["BankHolderType"]}
                                  </label>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {/* change invoice to ACH PAYMENT  */}
                            <div
                              className="fitem half-fitem"
                              style={{ marginTop: "20px" }}
                            >
                              <div
                                className="fitem half-fitem"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#6f6f6f",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Charge invoices to ACH Payment
                                </span>
                              </div>
                              <div
                                className="fitem half-fitem"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div className="can-toggle can-toggle--size-small">
                                  <input
                                    id="charge_invoices_to_card"
                                    type="checkbox"
                                    className="fitem-ck-input"
                                    name={"charge_invoices_to_card"}
                                    checked={!charge_invoices_ACHPayment}
                                    onChange={(e) => {
                                      setcharge_invoices_ACHPayemnt(
                                        !e.target.checked
                                      );
                                    }}
                                  />
                                  <label
                                    htmlFor="charge_invoices_to_card"
                                    className="fitem-ck-txt black"
                                  >
                                    <div
                                      className="can-toggle__switch"
                                      data-unchecked="Yes"
                                      data-checked="No"
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="fitem">
                            <label>Account Holder Name</label>
                            <input
                              type="text"
                              value={account_holder_name || ""}
                              onChange={(e) => {
                                setaccount_holder_name(e.target.value);
                              }}
                              className="form--control"
                              placeholder="Account Holder Name"
                              disabled={can_edit_bank}
                            />
                            {errors?.["card_holder_name"] ? (
                              <label className="new_err_msg">
                                <i className="las la-info-circle" />
                                {errors?.["card_holder_name"]}
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="fitem half-fitem"
                            style={{ marginTop: "20px" }}
                          >
                            <div
                              className="fitem half-fitem"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{ color: "#6f6f6f", fontWeight: "bold" }}
                              >
                                Charge invoices to ACH Payment
                              </span>
                            </div>
                            <div
                              className="fitem half-fitem"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className="can-toggle can-toggle--size-small">
                                <input
                                  id="charge_invoices_to_card"
                                  type="checkbox"
                                  className="fitem-ck-input"
                                  name={"charge_invoices_to_card"}
                                  checked={!charge_invoices_ACHPayment}
                                  onChange={(e) => {
                                    setcharge_invoices_ACHPayemnt(
                                      !e.target.checked
                                    );
                                  }}
                                />
                                <label
                                  htmlFor="charge_invoices_to_card"
                                  className="fitem-ck-txt black"
                                >
                                  <div
                                    className="can-toggle__switch"
                                    data-unchecked="Yes"
                                    data-checked="No"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!can_edit ? (
                        <>
                          <div className="fitem">
                            <label>Card Number</label>
                            <div
                              id="card-number"
                              className="form--control"
                            ></div>
                            {errors?.["card_number"] ? (
                              <label className="new_err_msg">
                                <i className="las la-info-circle" />
                                {errors?.["card_number"]}
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="fitem">
                            <label>CVV</label>
                            <div id="card-cvv" className="form--control"></div>
                            {errors?.["card_cvv"] ? (
                              <label className="new_err_msg">
                                <i className="las la-info-circle" />
                                {errors?.["card_cvv"]}
                              </label>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="fitem">
                        <label>Cardholder Name</label>
                        <input
                          type="text"
                          value={card_holder_name || ""}
                          onChange={(e) => {
                            setcard_holder_name(e.target.value);
                          }}
                          className="form--control"
                          placeholder="Cardholder Name"
                          disabled={can_edit}
                        />
                        {errors?.["card_holder_name"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["card_holder_name"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>

                      {!can_edit ? (
                        <React.Fragment>
                          <div className="fitem half-fitem">
                            <div className="fitem half-fitem">
                              <label>Expiration Month</label>
                              <input
                                type="text"
                                className="form--control"
                                placeholder="MM"
                                maxLength={2}
                                value={card_expiry_month || ""}
                                inputMode="numeric"
                                onPaste={(event) => {
                                  if (
                                    event.clipboardData
                                      .getData("Text")
                                      .match(/[^\d]/)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onKeyPress={(e) => {
                                  const pattern = /[0-9]/;
                                  const inputChar = String.fromCharCode(
                                    e.charCode
                                  );
                                  if (!pattern.test(inputChar)) {
                                    // invalid character, prevent input
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  setcard_expiry_month(e.target.value);
                                }}
                              />
                              {errors?.["card_expiry_month"] ? (
                                <label className="new_err_msg">
                                  <i className="las la-info-circle" />
                                  {errors?.["card_expiry_month"]}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="fitem half-fitem">
                              <label>Expiration Year</label>
                              <input
                                type="text"
                                className="form--control"
                                placeholder="YYYY"
                                maxLength={4}
                                value={card_expiry_year || ""}
                                inputMode="numeric"
                                onPaste={(event) => {
                                  if (
                                    event.clipboardData
                                      .getData("Text")
                                      .match(/[^\d]/)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onKeyPress={(e) => {
                                  const pattern = /[0-9]/;
                                  const inputChar = String.fromCharCode(
                                    e.charCode
                                  );
                                  if (!pattern.test(inputChar)) {
                                    // invalid character, prevent input
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  setcard_expiry_year(e.target.value);
                                }}
                              />
                              {errors?.["card_expiry_year"] ? (
                                <label className="new_err_msg">
                                  <i className="las la-info-circle" />
                                  {errors?.["card_expiry_year"]}
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}

                      <div
                        className="fitem half-fitem"
                        style={{ marginTop: "20px" }}
                      >
                        <div
                          className="fitem half-fitem"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span
                            style={{ color: "#6f6f6f", fontWeight: "bold" }}
                          >
                            Charge invoices to card
                          </span>
                        </div>
                        <div
                          className="fitem half-fitem"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div className="can-toggle can-toggle--size-small">
                            <input
                              id="charge_invoices_to_card"
                              type="checkbox"
                              className="fitem-ck-input"
                              name={"charge_invoices_to_card"}
                              checked={!charge_invoices_to_card}
                              onChange={(e) => {
                                setcharge_invoices_to_card(!e.target.checked);
                              }}
                            />
                            <label
                              htmlFor="charge_invoices_to_card"
                              className="fitem-ck-txt black"
                            >
                              <div
                                className="can-toggle__switch"
                                data-unchecked="Yes"
                                data-checked="No"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <hr />
            {paymentMethod != "Please Select" ? (
              <>
                <div className="card-full pt-0">
                  <div className="">
                    <div className="mr-2 flex">
                      <b>Billing Address</b>
                      <a
                        href="/#"
                        className="action_link primary"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleAddAddress(true);
                        }}
                      >
                        <b>Add New Address</b>
                      </a>
                    </div>
                    <div className="inline-check-list mt-10">
                      {renderServiceOrder()}
                      {errors["card_address.address"] ||
                      errors["card_address.city"] ||
                      errors["card_address.state"] ||
                      errors["card_address.zip_code"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {"Billing address is required"}
                        </label>
                      ) : null}
                      {showAddAddressView && (
                        <AddAddress
                          onCancel={toggleAddAddress}
                          onAdd={onAddAddress}
                          btnEnable={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="footer">
            <button className={`btn btn-bdr`} onClick={() => onCancel()}>
              Cancel
            </button>
            {can_edit || can_edit_bank ? (
              <button
                className={`btn btn-warning`}
                onClick={() => updateValidate()}
              >
                {paymentMethod === "card"
                  ? "Update Card"
                  : "Update ACH Payment"}
              </button>
            ) : (
              <button
                className={`btn btn-warning`}
                onClick={() => validation()}
              >
                Add Payment Method
                {/* {can_edit ? "Update Credit Card" : can_edit_bank ? "Update ACH Payment": "Add Payment Method"} */}
              </button>
            )}
          </div>
        </div>
      </Drawer>
    );
  };
  const loadStax = () => {
    let stax_public_apikey = props?.customer?.field_office?.stax_public_apikey;
    //const { stax_public_apikey } = state;
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {
        number: {
          id: "card-number",
          placeholder: "Card Number",
          style:
            "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;",
        },
        cvv: {
          id: "card-cvv",
          placeholder: "CVV",
          style:
            "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;",
        },
      });
      fattJsObj
        .showCardForm()
        .then((handler) => {
          //console.log('form loaded', handler);
        })
        .catch((err) => {
          console.error("error occur", err);
        });
      setFattJs(fattJsObj);
    } else {
      message.error(
        "Error occur while loading stax. please contact to admin.",
        10
      );
    }
  };
  // Load Stax ACH

  const loadStaxACH = () => {
    let stax_public_apikey = props?.customer?.field_office?.stax_public_apikey;
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {});
      setFattJs(fattJsObj);
    } else {
      message.error(
        "Error occur while loading stax. please contact to admin.",
        10
      );
    }
  };
  // Load Stax

  //toggle credit card drawer
  const toggleCreditCard = (value, can_edit = false) => {
    setshowCreditCard(value);
    getServiceOrder()
  };
  useEffect(() => {
    if (paymentMethod === "card") {
      loadStax();
    } else if (paymentMethod === "ACH Payments") {
      loadStaxACH();
    }
  }, [paymentMethod]);
  const onConfirmBillingModal = async (id) => {
    setLoader(true);
    const data = await deleteBillingInfo(id);
    if (data.length === 0) {
      setLoader(false);
      userBillingInfo();
      message.success("Deleted successfull!..");
    } else {
      setLoader(false);
      message.error("Something went wrong!..");
    }
  };

  // render Billing Preference
  const renderBilling = () => {
    return (
      <div
        className={
          props?.record?.is_active ? "card order2" : "card order2 disable-field"
        }
      >
        <div className="card-title flex">
          <h5>Billing Preferences</h5>
          <div>
            {cardList?.data?.length < 5 ? (
              <button
                // className="btn btn-warning"
                className="btn btn-warning"
                onClick={() => {
                  setCardDetail({});
                  toggleCreditCard(true);
                  setcan_edit(false);
                  setcan_edit_bank(false);
                }}
              >
                Add New
              </button>
            ) : (
              <span />
            )}
          </div>
        </div>
        <div className="card-body">
          {cardList?.data?.length > 0 ? (
            map(orderBy(cardList?.data, "uuid", "asc"), (item, index) => {
              const expDate = `${JSON.parse(item?.stax_payment_object)
                ?.card_exp?.toString()
                .slice(0, 2)}/${JSON.parse(item?.stax_payment_object)
                .card_exp?.toString()
                .slice(2, 6)}`;
              const diff = moment(expDate).diff(moment(), "days");
              const displayDiff = moment(expDate).fromNow();
              const displayText =
                diff < 0
                  ? `expired for ${displayDiff}`
                  : `is due to expire ${displayDiff}`;

              return (
                <React.Fragment key={`card${index}`}>
                  {hide_delete_card ? (
                    <div className={"flex flex-end mb-10"}>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => onConfirmBillingModal(item?.uuid)}
                        okText="Yes"
                        cancelText="No"
                        overlayClassName={"popup-width"}
                      >
                        <CloseCircleOutlined className={"danger"} />
                      </Popconfirm>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="flex ai-center mb-10"
                    key={`card${item?.uuid}`}
                  >
                    <div className="card_img flex ai-center">
                      {JSON.parse(item.stax_payment_object).method ===
                      "bank" ? (
                        <img height="25" src={bank} alt="bank" />
                      ) : (
                        <img height="25" src={visa} alt="visa" />
                      )}
                      &nbsp;&nbsp;
                      <label className="m-0">
                        {JSON.parse(item.stax_payment_object).method === "bank"
                          ? `ACH Ending XXXX ${
                              JSON.parse(item.stax_payment_object)
                                .card_last_four
                            }`
                          : `${
                              JSON.parse(item.stax_payment_object).card_type
                            } ending in ${
                              JSON.parse(item.stax_payment_object)
                                .card_last_four
                            }`}
                      </label>
                    </div>

                    {JSON.parse(item.stax_payment_object).method === "bank" ? (
                      <button
                        className="btn btn-warning xs-btn"
                        onClick={() => {
                          setCardDetail(item);
                          setaccount_holder_name(
                            JSON.parse(item.stax_payment_object).person_name
                          );
                          setcard_address(item?.address);
                          setcharge_invoices_ACHPayemnt(
                            item?.charge_invoice_bank
                          );
                          setTimeout(toggleCreditCard(true), 100);
                          setcan_edit_bank(true);
                          setcan_edit(false);
                          setPaymentMethod("ACH Payments");
                        }}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        className="btn btn-warning xs-btn"
                        onClick={() => {
                          setCardDetail(item);
                          setcard_holder_name(item?.card_holder_name);
                          setcard_address(item?.address);
                          setcharge_invoices_to_card(item?.charge_invoice_card);
                          setTimeout(toggleCreditCard(true), 100);
                          setcan_edit(true);
                          setcan_edit_bank(false);
                          setPaymentMethod("card");
                        }}
                      >
                        Update
                      </button>
                    )}
                  </div>
                  {(item?.charge_invoice_bank || item?.charge_invoice_card) && (
                    <label className="m-0">Default payment method</label>
                  )}
                  {/* {index === 0 && <hr />} */}
                  {diff <= 60 && (
                    <blockquote className="horiz">
                      <b>{`This customer’s credit card ${displayText}.`}</b>
                      <button
                        className="btn btn-warning mt-10"
                        onClick={() => {
                          this.setState({ cardDetail: item });
                          this.toggleEmailRemainder(true);
                        }}
                      >
                        Send Reminder Email
                      </button>
                    </blockquote>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <EmptyData />
          )}
        </div>
      </div>
    );
  };

  // render private notes
  const renderPrivateNotes = () => {
    return (
      <div className={!props?.record?.is_active && "disable-field"}>
        <PrivateNotes
          customerId={props?.record?.uuid}
          //   getAccountNotes={this.props.getAccountNotes}
          //   addAccountNotes={this.props.addAccountNotes}
          //   accountNotes={this.props.accountNotes}
        />
      </div>
    );
  };

  // render block list
  const renderBlockList = () => {
    return (
      <div className="card">
        <div className="card-title flex">
          <h5>Block List</h5>
          <button
            className="btn btn-warning"
            onClick={() => toggleUpdateBlockList(true)}
          >
            Update
          </button>
        </div>
        <div className="card-body">
          <div
            className="blockedueser  flex flex-start mb-10"
            key={`block${1}`}
          >
            <div className="bico">
              <StopOutlined />
            </div>
            <div className="btext">
              {/* <b className="d-block">{props?.record?.first_name} {props?.record?.last_name}</b> */}
              <span>Reason</span>
            </div>
          </div>
          {/* ) : <EmptyData /> */}
          {/* } */}
        </div>
      </div>
    );
  };

  const renderSourceAttribution = () => {
    return (
      <div className="card">
        <div className="card-title flex">
          <h5>Source & Attribution</h5>
        </div>
        <div className="card-body">
          <div
            className="blockedueser  flex flex-start mb-10"
            key={`block${1}`}
          >
            <div className="fitem">
              {/* <p>How did you hear about us: via {"Online Search"}</p> */}
              {/*props?.customer?.hear_about_us ?
                  <p>How did you hear about us: via {props?.customer?.hear_about_us?.[0]}</p>
                  : ""*/}
              <div style={{ display: "grid" }}>
                <span>
                  Outreach: <b>{props?.customer?.outreach}</b>
                </span>
                <span>
                  How did you hear about us:{" "}
                  <b>{props?.customer?.hear_about_us?.[0]}</b>
                </span>
                <span>
                  Service looking for:{" "}
                  <b>{props?.customer?.service_looking_for}</b>
                </span>
                <span>
                  Attribution: <b>{props?.customer?.marketing_source}</b>
                </span>
                <span>
                  Attribution details:{" "}
                  <b>{props?.customer?.marketing_source_detail}</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //toggle update contact
  const toggleUpdateContact = (value, isReload = false) => {
    // console.log("value",value)
    if (isReload) {
      getCustomerInfo();
    }
    setUpdateContact(value);
  };

  // toggle Update Block List
  const toggleUpdateBlockList = (value) => {
    setUpdateBlockList(value);
  };

  //toggle add authorized user
  const toggleAddAuthorizedUser = (value) => {
    setAddAuthorizedUser(value);
  };

  const dectivateCustomer = () => {
    const schema = yup.object().shape({
      is_reason_select: yup.bool(),
      cancelation_reason: yup.string().required("Please select a reason."),
      ...(is_reason_select
        ? {
            cancelation_details: yup
              .string()
              .required("Please provide a reason."),
          }
        : null),
    });
    const data = { cancelation_reason, cancelation_details, is_reason_select };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setDeactivateModalVisible(false);
        setis_reason_select(false);
        setErrors({});
        const customerId = props?.customer?.uuid;
        axios
          .patch(`crm/create-customer/${customerId}/`, {
            is_active: false,
            cancelation_reason: cancelation_reason,
            cancelation_details: cancelation_details,
          })
          .then((response) => {
            message.success(`Customer deactivated successfully.`, 10);
            setTimeout(() => {
              // props.history.goBack();
              getCustomerInfo();
            }, 100);
          })
          .catch((error) => {
            message.error(`Error occurred while deactivating customer.`, 10);
          });
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        setis_reason_select(false);
      });
  };

  const onUpdate = async (id, params) => {
    await updateCustomer(id, params);
    await getCustomerInfo();
    setLoader(false);
  };

  // on update contact details
  const onUpdateClick = (tempEmailSet) => {
    onUpdate(props?.customer?.uuid, {
      phone_numbers: props?.customer?.phone_numbers,
      emailaddress_set: tempEmailSet,
    });
  };

  useEffect(() => {
    setTempData(customers);
  }, [customers.emailaddress_set]);

  // on primary email changed
  const onEmailPrimaryChanged = (item) => {
    let tempEmailSet = [];
    tempData?.emailaddress_set?.map((each) => {
      tempEmailSet.push({ email: each.email.toLowerCase(), primary: false });
    });
    const index = tempEmailSet.findIndex((obj) => obj.email === item.email);
    tempEmailSet[index].primary = true;
    
    setTempData({ ...tempData, emailaddress_set: tempEmailSet });
    onUpdateClick(tempEmailSet);
  };
  // on primary phone number changed
  const onPhonePrimaryChanged = (item) => {
    let tempPhoneNumSet = [];
    tempData?.phone_numbers?.map((num) => {
      tempPhoneNumSet.push({ ...num, primary: false });
    });
    const index = tempPhoneNumSet.findIndex((obj) => obj.phone === item.phone);
    tempPhoneNumSet[index].primary = true;
    setTempData({ ...tempData, phone_numbers: tempPhoneNumSet });

    onUpdate(props?.customer?.uuid, {
      phone_numbers: tempPhoneNumSet,
      emailaddress_set: props?.customer?.emailaddress_set,
    });
  };

  // render primary info
  const renderPrimaryInfo = () => {
    let phone_numbers = "",
      primaryEmail = "";
    if (!isEmpty(props?.record?.phone_numbers)) {
      for (let i = 0; i < props?.record?.phone_numbers?.length; i++) {
        const item = props?.record?.phone_numbers?.[i];
        if (item?.primary) {
          phone_numbers = item?.phone;
          break;
        }
      }
    }

    !isEmpty(props?.customer?.emailaddress_set) &&
      map(props?.customer?.emailaddress_set, (item) => {
        primaryEmail = item?.primary && item?.email;
      });
    let authorizedUserName = "";
    map(
      sortBy(props?.record?.authorizedUser?.userList?.results, "created_by"),
      (item, index) =>
        (authorizedUserName += `${index !== 0 ? ", " : ""}${item?.user_name}`)
    );
    return (
      <div className="card order1">
        <div className="card-title flex">
          <h5>Primary information</h5>
          <ActiveDeactiveCustomer
            record={props.record}
            page="RC"
            source={props?.source}
          />
          {/* {props?.customer?.is_active ? (
            <button
              className="btn btn-warning"
              onClick={() => setDeactivateModalVisible(true)}
            >
              Inactive
            </button>
          ) : (
            <button className="btn btn-warning">Activate</button>
          )} */}
          <Modal
            visible={deactivateModalVisible}
            okText={"Deactivate"}
            cancelText={"Cancel"}
            onOk={dectivateCustomer}
            onCancel={() => {
              setDeactivateModalVisible(false);
              setcancelation_details("");
              setcancelation_reason("");
              setis_reason_select(false);
              setErrors({});
              // this.setState({deactivateModalVisible:false, cancelation_reason: '', cancelation_details: '', is_reason_select: false, errors:{} })
            }}
            className="delete_user_modal"
            width={400}
          >
            <h3>Deactivate Customer</h3>
            <p>Please provide a reason for deactivating this customer.</p>
            <React.Fragment>
              <div className="field-group">
                <label>
                  Reason<sup>*</sup>
                </label>
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder={"Select reason"}
                  value={cancelation_reason || ""}
                  onChange={(e) => {
                    if (e) {
                      if (e === "other") {
                        setis_reason_select(true);
                      } else {
                        setis_reason_select(false);
                      }
                      setcancelation_reason(e);
                    } else {
                      setcancelation_reason("");
                    }
                  }}
                >
                  <Option value="">Select reason</Option>
                  <Option value="service">Service</Option>
                  <Option value="moving">Moving</Option>
                  <Option value="competition">Competition</Option>
                  <Option value="other">Other</Option>
                  <Option value="entered_by_accident">
                    Entered By Accident
                  </Option>
                </Select>
                {errors?.["cancelation_reason"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.["cancelation_reason"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              {cancelation_reason === "other" && (
                <div className="field-group">
                  <textarea
                    className="textarea-gray"
                    rows="5"
                    name="cancelation_details"
                    type="text"
                    maxLength={200}
                    placeholder={"Add cancellation details here."}
                    value={cancelation_details}
                    onChange={(e) => {
                      if (e) {
                        setcancelation_details(e?.target?.value);
                      } else {
                        setcancelation_details("");
                      }
                    }}
                  />
                  {errors?.["cancelation_details"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["cancelation_details"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </React.Fragment>
          </Modal>
        </div>
        <div className="card-body">
          {/* <div className={props.record?.is_active ? "card-body" : "card-body disable-field"}> */}
          <div
            className={
              props.record?.is_active
                ? "flex flex-start mb-10"
                : "flex flex-start mb-10 disable-field"
            }
          >
            {/* <div className="flex flex-start mb-10"> */}
            <div className="label" style={{ "max-width": "125px" }}>
              <b>Primary User Name:</b>
              <b>Billing Address: </b>
            </div>
            <div className="value">
              <b>
                {props?.customer?.first_name} {props?.customer?.last_name}
              </b>
              <span>
                {props?.customer?.invoice_address?.address},{" "}
                {props?.customer?.invoice_address?.city},{" "}
                {props?.customer?.invoice_address?.state},{" "}
                {props?.customer?.invoice_address?.zip_code}
              </span>
            </div>
          </div>
          {/* <div className="mb-10">
            <b>
              <a
                href={"/#"}
                onClick={(e) => {
                  e.preventDefault();
                  toggleAddAuthorizedUser(true);
                }}
              >
                <u>Add Authorized User</u>
              </a>
            </b>
          </div> */}
          {/* <div className="fitem inline-check-list"> */}
          <div
            className={
              props.record?.is_active
                ? "fitem inline-check-list"
                : "fitem inline-check-list disable-field"
            }
          >
            {!isEmpty(tempData?.phone_numbers) &&
              map(
                orderBy(tempData?.phone_numbers, "primary", "desc"),
                (item, index) => (
                  <div className="fitem-check" key={`checkboxPhone${index}`}>
                    <input
                      type="checkbox"
                      className="fitem-ck-input"
                      id={`checkboxPhone${index}`}
                      name={`phone${index}`}
                      checked={item?.primary}
                      onChange={() => onPhonePrimaryChanged(item)}
                    />
                    <label
                      htmlFor={`checkboxPhone${index}`}
                      className="fitem-ck-txt"
                    >
                      {item?.primary
                        ? `${formatPhoneNumber(item?.phone)} (Primary)`
                        : `${formatPhoneNumber(item?.phone)}`}
                    </label>
                  </div>
                )
              )}
            {/* {primaryEmail && (
              <div className="fitem-check" key={`firstCheckboxEmail${1}`}>
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id={`firstCheckboxEmail${1}`}
                  name={`firstCheckboxEmail${1}`}
                  checked={true}
                  onChange={() => this.onEmailPrimaryChanged(1)}
                />
                <label
                  htmlFor={`firstCheckboxEmail${1}`}
                  className="fitem-ck-txt text-lowercase"
                >
                  {`${
                    primaryEmail ? primaryEmail : props?.customer?.email
                  } (Primary)`}{" "}
                </label>
              </div>
            )} */}
            {/* Emails */}
            {!props?.customer?.is_dummy_email &&
              map(
                orderBy(
                  [...(tempData?.emailaddress_set || [])],
                  "primary",
                  "desc"
                ),
                (item, index) =>
                  item?.email && !item.email.includes("doody.com") ? (
                    <div
                      class={
                        props.record?.is_active
                          ? "fitem-check"
                          : "fitem-check disable-field"
                      }
                    >
                      {/* <div className="fitem-check" key={`firstCheckboxEmail${index}`}> */}
                      <input
                        type="checkbox"
                        className="fitem-ck-input"
                        id={`firstCheckboxEmail${index}`}
                        name={`firstCheckboxEmail${index}`}
                        checked={item?.primary}
                        onChange={() => onEmailPrimaryChanged(item)}
                      />
                      <label
                        htmlFor={`firstCheckboxEmail${index}`}
                        className="fitem-ck-txt text-lowercase"
                      >
                        {`${item?.email} ${item?.primary ? "(Primary)" : ""}`}
                      </label>
                    </div>
                  ) : (
                    ""
                  )
              )}
          </div>
          <div
            className={props.record?.is_active === false ? "disable-field" : ""}
          >
            <a
              href={"/#"}
              onClick={(e) => {
                e.preventDefault();
                toggleUpdateContact(true, false);
              }}
            >
              <u>Update Contact Details</u>
            </a>
          </div>
          <br />
          <br />
          <div
            className={
              props.record?.is_active
                ? "flex flex-start mb-10"
                : "flex flex-start mb-10 disable-field"
            }
          >
            <div className="label special-notes">
              <div className="special-class text-area flex-justify-between-align-center">
              <b>Special Notes</b>
              <p className="special-desc">[Internal only for Tech. Ex Gate Code]</p></div>
              <div
                style={{
                  width: "100%",
                  height: "110px",
                  padding: "7px",
                  border: "2px solid #D3D3D3",
                  borderRadius: "2px",
                  overflowY: "auto",
                }}
              >
                <p>{props?.customer?.special_notes}</p>
              </div>
            </div>
          </div>
          <div className="flex mt-30 mb-10">
            <DeleteCustomer record={props.record} source={props?.source} />
          </div>

          {/* <div className="fitem mt-10">
            <b className="d-block mb-10">Preferred Technician</b>
            <Select
              value={tech ? tech : undefined}
              showArrow
              allowClear={true}
              virtual={false}
              placeholder={"Select Technician"}
              style={{ width: "100%" }}
              onChange={(e) => {
                setTech(e ? e : null);
              }}
            >
              {map(tech_list?.results, (obj) => (
                <Option key={obj?.uuid} value={obj?.uuid}>
                  {obj?.user?.last_name
                    ? `${obj?.user?.first_name} ${obj?.user?.last_name}`
                    : obj?.user?.first_name}
                </Option>
              ))}
            </Select>
          </div> */}
        </div>
      </div>
    );
  };

  const onInvoicePreferenceChange = (e) => {
    dispatch(setAccountDetail({ invoicePreference: e.target.value }));
  };

  const onInvoicePreferenceChangeOnClick = async (e) => {
    setLoader(true);
    const data = await updateCustomerInvoicePreference(props?.record?.uuid, {
      account_level_invoice: accountDetail?.invoicePreference,
    });
    dispatch(
      setAccountDetail({ invoicePreference: data?.account_level_invoice })
    );
    setLoader(false);
    if (data?.uuid) {
      message.success("Invoice preference updated successfully.");
    } else {
      message.error("Something went wrong.");
    }
  };

  const renderInvoicePreference = () => {
    return (
      <BatchInvoicePreference
        value={accountDetail?.invoicePreference}
        handleChange={onInvoicePreferenceChange}
        handleClick={onInvoicePreferenceChangeOnClick}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="block-row">
        {loader && <Loader />}
        <div className="card-row show-lg">
          {/* {loader && <Loader />} */}
          <div className="card-col">
            {renderPrimaryInfo()}
            {/* {renderHearAbout()} */}
            {/* {renderBlockList()} */}
          </div>
          <div className="card-col">
            {renderBilling()}
            {renderSourceAttribution()}
            {/* {renderPricingHistory()} */}
            {renderPrivateNotes()}
            <div className="card-col">{renderInvoicePreference()}</div>
          </div>
        </div>
        <div className="card-row hide-lg">
          {/* {loader && <Loader />} */}
          <div className="card-col">
            {renderPrimaryInfo()}
            {/* {renderBlockList()} */}
          </div>
          <div className="card-col">
            {renderBilling()}
            {renderSourceAttribution()}
            {/* {renderPricingHistory()} */}
            {/* {renderHearAbout()} */}
          </div>
          <div className="card-col">{renderPrivateNotes()}</div>
          <div className="card-col">{renderInvoicePreference()}</div>
        </div>
        {updateContact && (
          <UpdateContact
            openDrawer={updateContact}
            record={props?.record}
            // customerList={customerList}
            // getCustomerDetail={this.props.getCustomerDetail}
            // customerId={this.props.customerId}
            closeDrawer={toggleUpdateContact}
            customer={tempData}
            // onUpdate={this.props.updateCustomer}
            // notificationList={this.props.notificationList} // api
            // updateNotificationById={this.props.updateNotificationById} // api
            // getNotification={this.props.getNotification} // api
            // GoogleApi={this.props.GoogleApi}
            // getGeocodeAddress={this.props.getGeocodeAddress} // api
            // resetGeocodeAddress={this.props.resetGeocodeAddress} // api
          />
        )}
        {addAuthorizedUser && (
          <AddAuthorizedUsers
            openDrawer={addAuthorizedUser}
            // customer={customer}
            // customerId={customerId}
            // updateCustomer={this.props.updateCustomer}
            // authorizedUser={this.props.authorizedUser}
            // getAuthorizedUserList={this.props.getAuthorizedUserList}
            // addAuthorizedUser={this.props.addAuthorizedUser}
            // deleteAuthorizedUser={this.props.deleteAuthorizedUser}
            closeDrawer={() => toggleAddAuthorizedUser(false)}
          />
        )}
        {updateBlockList && (
          <UpdateBlockList
            openDrawer={updateBlockList}
            closeDrawer={() => toggleUpdateBlockList(false)}
            // customerId={this.props.customerId}
            //APIs
            // getBlockList={this.props.getBlockList}
            // getUserList={this.props.getUserList}
            // addBlockUser={this.props.addBlockUser}
            // deleteBlockUser={this.props.deleteBlockUser}

            // reducers
            // blockList={this.props.blockList}
            // userList={this.props.userList}
          />
        )}
        {/* {emailReminderModal && <EmailRemainder
            visible={emailReminderModal}
            customer={customer}
            cardDetail={cardDetail}
            emailReminder={this.props.emailReminder}
            handleOk={(obj) => this.props.sendEmailReminder(obj)}
            handleCancel={() => this.toggleEmailRemainder(false)}
          />}
          
           */}
        {renderCreditCardDrawer()}
      </div>
    </React.Fragment>
  );
};
export default AccountDetails;
