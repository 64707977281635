import React, { useState, useEffect, useCallback, useRef } from "react";
import { Col, Row, Select, DatePicker, TimePicker, ConfigProvider, Spin, Empty } from "antd";
import { debounce, map } from "lodash";
import moment from "moment-timezone";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
    addAddressForTask, addManualAddress, addTaskNameAction, addCustomerAction, addTaskDateAction,
    addTaskTimeAction, taskFrequencyAction, addFieldOfficeUuid
} from "../../../../Store/technicianTaskDataSlice";
import "../../../../Assets/css/addNewTaskModal.css";
import { useCalendar } from "../../../Hooks/Calendar.Hook";
import CustomFrequency from "../../../../Customer/Common/CustomFrequency";
import { searchCustomer } from "../../../../Action/Customer/index";
import AddressField from "../../../../Components/AddressField";
import TaskDescription from "./TaskDescription";
import AddTechnicianForTask from "./AddTechnicianForTask";
import AddDurationField from "./AddDurationField";
import AddCategoryComponent from "./AddCategoryComponent";
import AddRecurrenceCheckbox from "./AddRecurrenceCheckbox";

const AddTaskForm = () => {
    const freqRepeatCheck = useRef('')
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.technicianTaskJourney);
    const initialStateValues = {
        customer: [],
        address: { address: "", city: "", state: "", zip_code: "" }
    };

    const [state, setState] = useState(initialStateValues);
    const calendarData = useCalendar();
    const [selectLoading, setSelectLoading] = useState(true);
    const [codeOptions, setcodeOptions] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [value, setvalue] = useState('');
    const [isRecurring, setisRecurring] = useState(true);
    if(tasks?.frequency?.recurring?.repeat !== ''){
        freqRepeatCheck.current = tasks?.frequency?.recurring?.repeat
    }
    

    useEffect(() => {
        dispatch(addFieldOfficeUuid(tasks?.franchiseData || calendarData?.franchise?.value));
        if(tasks?.showTaskFromEdit === true){
            dispatch(addTaskDateAction(moment(tasks?.selectedTaskData?.start).format("YYYY-MM-DD")));
            dispatch(addTaskTimeAction((moment(tasks?.selectedTaskData?.start).utc().format("HH:mm:ss"))));
            dispatch(taskFrequencyAction(tasks?.selectedTaskData?.frequency_settings))
            dispatch(addTaskNameAction(tasks?.selectedTaskData?.title))
        }
    }, []);

    const onChange = (e) => {
        let selectedCode = [];
        dispatch(addManualAddress(false));
        if (e) {
            setvalue(e);
            selectedCode = customerData.filter((item) => {
                return item.first_name + ' ' + item.last_name == e
            });
        } else { setvalue(''); }
        if (selectedCode.length > 0) {
            setState((s) => ({
                ...s,
                customer: selectedCode[0],
                address: {
                    ...address,
                    address: selectedCode[0]?.address[0]?.formatted_address
                }
            }));

            dispatch(addCustomerAction(selectedCode[0]))
            dispatch(addAddressForTask(selectedCode[0]?.address[0]?.formatted_address))

        } else {
            setState((s) => ({
                ...s,
                customer: []
            }));
            dispatch(addCustomerAction([]))
        }
    }

    const onSearch = (e) => {
        setcodeOptions([]);
        if (e !== "") {
            setvalue(e);
            onSearchDebounce(e);
        } else {
            setvalue('');
        }
    }

    const onSearchDebounce = useCallback(
        debounce(e => {
            getOptions(e);
        }, 1000),
        []
    );

    const getOptions = async (search) => {
        setSelectLoading(true)
        const options = await searchCustomer(search);
        setCustomerData(options?.results);
        const _options = options?.results?.map((result) => {
            return {
                value: result.first_name + " " + result.last_name,
                label: result.first_name + " " + result.last_name
            }
        });
        setSelectLoading(false)
        setcodeOptions(_options);
    }

    const handleAddressChange = (value) => {
        setState({ ...state, address: value });
        dispatch(addAddressForTask(value))
    };

    const onAddressChanged = (e, addr_ref) => {
        const { address } = state;
        dispatch(addManualAddress(true));
        setState({ ...state, address: address });
    };

    const handleTaskNameChange = (event) => {
        dispatch(addTaskNameAction(event.target.value))
    };

    const onTextChange = (e) => {
        dispatch(taskFrequencyAction(e.target.value))
    }

    const renderCustomerNameSearch = () => {
        return (
            <div className="searchable">
                <ConfigProvider renderEmpty={() => selectLoading ? <div className="searchableCustomerNameSpin"><Spin tip="Loading Customer Names..." /></div> : <Empty />}>
                    <Select
                        placeholder={tasks?.showTaskFromEdit === true ? tasks?.selectedTaskData?.customer?.name : "Customer Name"}
                        showSearch
                        allowClear={true}
                        autoClearSearchValue={true}
                        value={value || undefined}
                        onChange={onChange}
                        onSearch={onSearch}
                        suffixIcon={null}
                        options={codeOptions}
                        open={openSelect}
                        onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
                    >
                    </Select>
                </ConfigProvider>
            </div>
        );
    }

    const { address } = state;
    return (
        <>
            <Row>
                <Col xs={24} xl={11}>
                    <AddCategoryComponent />
                </Col>
                <Col span={1}></Col>
                <Col xs={24} xl={11}>
                    <div>
                        <label>
                            Task Name<sup>*</sup>
                        </label>
                        <input
                            type="text"
                            placeholder={tasks?.showTaskFromEdit === true ? tasks?.selectedTaskData?.title : "Task Name"}
                            onChange={(e) => handleTaskNameChange(e)}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xl={11} xs={24}>
                    <div className="jobPopDateTime">
                        <label>
                            Schedule Date<sup>*</sup>
                        </label>
                        <label>
                            Time
                        </label>
                        <DatePicker
                            className="taskDatePicker"
                            disabledDate={(current) => {
                                return moment().add(-1, 'days') >= current
                            }}
                            format="MMM DD,YYYY"
                            suffixIcon={<i className="las la-calendar"></i>}
                            allowClear={true}
                            value={tasks.date ? moment(tasks?.date) : null}
                            onChange={(e) => {
                                if (e) {
                                    dispatch(addTaskDateAction(moment(e).format("YYYY-MM-DD")));
                                    dispatch(taskFrequencyAction({
                                        start: moment(e).format("YYYY-MM-DD"),
                                        end: null,
                                        recurring: { repeat: "One Time", month: "" }
                                    }))
                                } else {
                                    dispatch(addTaskDateAction())
                                }
                            }}
                        />
                        <TimePicker
                            className="taskTimePicker"
                            placeholder={"--:-- AM/PM"}
                            value={tasks?.time ? moment(tasks?.time, "hh:mm A") : ""}
                            onChange={(e) => {
                                e ? dispatch(addTaskTimeAction((moment(e).format("HH:mm:ss")))) : dispatch(addTaskTimeAction((null)))
                            }}
                            format={"h:mm A"}
                            disabled={!tasks.date}
                        />                        
                    </div>

                </Col>
                <Col span={1}></Col>
                <Col xl={11} xs={24}>
                    <div className="jobPopDateTime">
                        <label>
                            Frequency
                        </label>
                        <CustomFrequency jobCreateTask="jobCreateTask"
                            onTextChange={onTextChange}
                            data={tasks.frequency}
                            is_recurring={isRecurring}
                            is_taskEdit={tasks.showTaskFromEdit}
                            />
                    </div>
                </Col>
            </Row>
            <Row>
                <AddDurationField durationType = "task"/>
                <Col span={1}></Col>
                <AddTechnicianForTask />
            </Row>
            <Row>
                <Col xl={23} xs={24}>
                    <div className="taskCustomer">
                        <label>
                            Customer
                        </label>
                        {renderCustomerNameSearch()}
                        {Object.keys(state.customer).length ? <div className="customerDetails">
                            <span className="address">
                                <label><b>Address:</b>&nbsp;{state?.customer?.address[0]?.formatted_address}</label>
                            </span>
                            <span className="email-id">
                                <label><b>Email:</b>&nbsp;{state?.customer?.emailaddress_set[0]?.email.toLowerCase()}</label>
                            </span>
                        </div> : null}
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="jobPopAddress">
                    <Col xl={23} xs={24}>
                        <label>
                            Address
                        </label>
                        <AddressField
                            handleAddressChange={handleAddressChange}
                            onAddressChanged={onAddressChanged}
                            onAddressChange={onAddressChanged}
                            data={address.address}
                            nolabel={true}
                            errors={[]}
                            jobCreateTask="jobCreateTask"
                        />
                    </Col>
                </div>
            </Row>
            <TaskDescription />
            {tasks?.showTaskFromEdit && freqRepeatCheck.current !== 'One Time' ? <AddRecurrenceCheckbox /> : ""}            
        </>
    )
}

export default AddTaskForm