import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getFieldTypes } from "../../../Action/Reports";

const FieldTypeSelector = ({ handleChange = () => {}, defaultValue }) => {
  const [fieldTypesList, setFieldTypeList] = useState([]);

  useEffect(() => {
    const getFieldTypeList = async () => {
      const data = await getFieldTypes();
      setFieldTypeList(
        data.type ? data.type.filter((e) => e !== "Serviced Not New") : []
      );
    };
    getFieldTypeList();
  }, []);

  return (
    <>
      <Select value={defaultValue} onChange={(e) => handleChange(e)}>
        <Select.Option key={"typeFieldAll"} value={"all"}>
          {"All"}
        </Select.Option>
        {fieldTypesList.sort().map((option) => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default FieldTypeSelector;
