export const setImageAfterUpdateHelper = (images) => {
        let imagesList = [];
        images.forEach((item, i) => {
          imagesList.push({
            id: item.id,
            uid: i,
            name: item.name,
            image: item.image,
            url: item.image,
          });
        });
        return imagesList;
}