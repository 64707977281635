import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { PlusOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { orderBy } from "lodash";
import { Button, Select } from 'antd';
import _ from "lodash";
import { getFranchiseListAll } from "../../Action/Franchise";
import { addPriceCatalogFilter, setPriceCatalogField, setPriceCatalogModalState } from "../../Store/priceCatalogDataSlice";
import { getPartTypeApiReq } from "../../Action/PriceCatalog";
import appData from "../../Utils/constants";


const priceCatalogFilter = () => {
  const dispatch = useDispatch();
  const { filter: filterData, franchiseList, partTypeList } = useSelector((state) => state.priceCatalog);
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};

  useEffect(() => {
    getFranchiseList();
    getPartTypeList();
  }, []);

  const getPartTypeList = async () => {
    dispatch(setPriceCatalogField({ loader: true }));
    const data = await getPartTypeApiReq();
    const formattedPartType = [{ value: "", label: "All" }, ...data.map((val) => ({ value: val, label: val }))];
    dispatch(setPriceCatalogField({ partTypeList: formattedPartType }));
  }

  const getFranchiseList = async () => {
    const data = await getFranchiseListAll();
    dispatch(setPriceCatalogField({ franchiseList: data }));
    dispatch(addPriceCatalogFilter({ field_office__uuid: userInfo.user.associated_field_offices[0]?.uuid }));
  };

  const onFilterChange = (e) => {
    dispatch(addPriceCatalogFilter({ field_office__uuid: e, current: 1 }))
  };

  const handleData = (e) => {
    dispatch(addPriceCatalogFilter({ part_type: e, current: 1 }))
  }

  const debouncedSearch = useRef(
    _.debounce(async (value) => {
      const inputValue = value.trim().toLowerCase();
      dispatch(addPriceCatalogFilter({ search: inputValue, current: 1 }));
    }, 1000)
  ).current;

  const handleSearching = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      debouncedSearch(e.target.value);
    }
  }

  const handleAddbtn = () => {
    dispatch(setPriceCatalogModalState({
      show: true, type: 'add', data: appData.initialPriceCatlogModalState,
    }))
  }
  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });
  //Verify if the user has franchise_owner role due to dual role users
  const is_fo = userInfo?.user?.user_roles.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );

  const role = is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
      ? "lead_tech"
      : userInfo?.user?.user_roles[0]?.name;

  return (
    <div className="filter-wraper">
      <div className="w-100 mb-20 desktop-price-catlog-filters">
        <div className="franchise-wraper">
          <div className="fitem price_catlog_filter">
            <label htmlFor="customerFranchise">Franchise</label>
            <Select
              id={"customerFranchise"}
              placeholder="Select a Franchise"
              name="franchise"
              className="swhite"
              onChange={onFilterChange}
              value={filterData?.field_office__uuid}
            >
              {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map(
                (option) => (
                  <Option key={option?.uuid} value={option?.uuid}>
                    {option?.name}
                  </Option>
                )
              )}
            </Select>
          </div>
          <div className="fitem price_catlog_filter">
            <label htmlFor="customerFranchise">Part Type</label>
            <Select
              className="swhite"
              name="poolOptions"
              id="poolOptions"
              value={filterData.part_type}
              onChange={handleData}
            >
              {partTypeList.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className="franchise-search-wrapper">
          <div className="fitem search-box-wrapper">
            <div className="fitem fitem-rinner search_box">
              <div className="fitem-ricon">
                <i className="las la-search"></i>
              </div>
              <input
                className="w-100 form-control flex-1 search-btn"
                type="text"
                placeholder="Search here..."
                onChange={handleSearching}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='right_side manage-btn'>
        <div className="right_side">
          <Button
            className={`btn btn-warning price_catlog_add h-42 ${(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? "" : "disabled") : ""}`}
            onClick={handleAddbtn}
            disabled={(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? false : true) : false}
          >
            <PlusOutlined className="m-0" />
          </Button>
          <Link className="link-button">
            <Button
              className={`btn btn-warning d-mobile-none h-42 ${(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? "" : "disabled") : ""}`}
              onClick={handleAddbtn}
              disabled={(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? false : true) : false}
            >
              Add New Item
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default priceCatalogFilter;
