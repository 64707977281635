import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "moment/locale/en-gb";
import en_GB from "antd/lib/locale-provider/en_GB";
import {
  DatePicker,
  message,
  Select,
  Table,
  Dropdown,
  Drawer,
  Checkbox,
  Menu,
  Modal,
  ConfigProvider,
  Row,
  Col,
  Popconfirm
} from "antd";
import { _, isEqual, isEmpty } from "lodash";
import {
  getAccountListing,
  getAccountListingTab,
  getCustomerDetail,
  getCommercialCustomer,
  emailInvoice,
  paymentProcessInvoice,
  exportAccountList
} from "../Action/Accounting";
import {
  getOverdueOpenLastInvoices,
  getInvoiceDetail,
  sendInvoice,
  deleteInvoice,
  voidInvoiceApi,
  getPaymentHistory,
} from "../Action/Invoice";
import { getUserBillingInfo } from "../Action/Customer";
import { formatToCurrency } from "../Components/Common/formatToCurrency";
import { getFranchiseListAll } from "../Action/Franchise";
import { add } from "../Store/invoiceSummarySlice";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux/es/exports";
import AddNewInvoiceRC from "../Customer/Residential/Invoices/AddNewInvoice";
import AddNewInvoiceCC from "../Customer/Commercial/Invoices/AddNewInvoice";
import LogPaymentRC from "../Customer/Residential/Invoices/LogPayment";
import LogPaymentCC from "../Customer/Commercial/Invoices/LogPayment";
import Refund from "../Customer/Residential/Invoices/Refund";
import DataTable from "../Customer/Common/DataTable";
import Loader from "../Components/Loader";
import config from "../Lib/Config";
import {
  getFranchiseSelected,
  setFranchiseSelected,
} from "../Utils/selectedFranchise";
import { printInvoiceRequest } from "../Action/Customer";
import { printInvoiceHelper } from "./AccountingHelper";
import { EventBus } from "../Components/EventBus/eventBus";
import { orderBy } from "lodash";

const { Option } = Select;
const { RangePicker } = DatePicker;
const status_mapping = [
  { key: "open", value: "Open" },
  { key: "open-emailed", value: "Open-Emailed" },
  { key: "open-printed", value: "Open-Printed" },
  { key: "overdue", value: "Overdue" },
  { key: "paid", value: "Paid" },
  { key: "void", value: "Void" },
];
const AccountListTable = (props) => {
  const [state, setState] = useState({
    accountList: [],
    selectedRowKeys: [],
    customerList: [],
    filteredCustomerList: [],
    franchiseList: [],
    tabData: {},
    account_list_pagination: {
      current: 1,
      showSizeChanger: false,
      pageSize: 15,
      ordering: "",
    },
    account_list_filter: {
      billing_type: "",
      status: [''],
      invoice_date__lte: "",
      invoice_date__gte: "",
      due_date__lte: "",
      due_date__gte: "",
      user__uuid: "",
      balance_due__lte: "",
      balance_due__gte: "",
    },
    count_filter: {
      invoice_date__lte: "",
      invoice_date__gte: "",
      balance_due__lte: "",
      balance_due__gte: "",
    },
    crr_user: {},
    action_type: "",
    search_value: "",
    active_pagination: "15",
    delete_id: "",
    isNav: "",
    set_table_active_action: "",
    curr_id: "",
    openNav: false,
    loader: false,
    is_log_payment: false,
    delete_visible: false,
    is_change_log_payment: true,
    showRefundPayment: false,
    invoice_date: "",
    is_open_date_picker: false,
    refundTypeValue: null,
    customer: {},
    ComCustomer: {},
    cardList: {},
    selectedBatchOption: "",
    all_selected: false,
    all_selected_keys_length: 0,
    loadingExportViewOrder: false,
  });
  const [previousFranchiseSelected, setPreviousFranchiseSelected] = useState();
  const row_action = {
    none: "",
    print: "Print",
    view: "View/Edit",
    resend: "Resend",
    log: "Log Payment",
    refund: "Refund Payment",
    delete: "Delete",
    void: "Void",
    payment_history: "Payment History",
    actionIssueRefund: false,
    workOrderList: [],
  };
  const row_action_credit_memo = {
    "view-credit-memo": "View/Edit",
  };
  const [active_action_uuid, setactive_action_uuid] = useState("");
  const [action, setaction] = useState("");
  const [invoice_uuid, setInvoiceuuid] = useState("");
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [defaultFranchise, setDefaultFranchise] = useState();
  const [selectedFranchise, setSelectedFranchise] = useState("all");
  const [record, setRecord] = useState({});
  const [showLogPayment, setshowLogPayment] = useState(false);
  const [showRefund, setshowRefund] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [cardList, setCardList] = useState();
  const [isAch, setIsAch] = useState(false);
  const [invoicePaymentHistory, setInvoicePaymentHistory] = useState([]);
  const dispatch = useDispatch();
  const [exportFiltersForAccountList, setexportFiltersForAccountList] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    setCurrentUser(user);
    let fran = getFranchiseSelected()?.uuid;
    setSelectedFranchise(fran || user?.user?.associated_field_offices[0]?.uuid);
    if (
      user?.user?.role.includes(`business_dev_franchiese`) ||
      user?.user?.role.includes(`accounting_franchiese`) ||
      user?.user?.role.includes(`operations_mgr`)
    ) {
      setState((s) => ({ ...s, is_change_log_payment: false }));
    }
    let { account_list_filter } = state;
    account_list_filter = Object.assign(account_list_filter, {
      invoice_date__lte: moment().endOf("month").format("YYYY-MM-DD"),
      invoice_date__gte: moment().startOf("month").format("YYYY-MM-DD"),
    });
    setState((s) => ({
      ...s,
      account_list_filter: account_list_filter,
    }));
    if (user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)) {
      setPreviousFranchiseSelected(fran);
      setDefaultFranchise(fran);
      let temp = {
        limit: 15,
        offset: 0,
        invoice_date__lte: moment().endOf("month").format("YYYY-MM-DD"),
        invoice_date__gte: moment().startOf("month").format("YYYY-MM-DD"),
      };
      if (fran !== "all") {
        temp.field_office__uuid = fran;
      }
      getAccountListingData(temp);
      let temp1 = {
        invoice_date__lte: moment().endOf("month").format("YYYY-MM-DD"),
        invoice_date__gte: moment().startOf("month").format("YYYY-MM-DD"),
      };
      if (fran !== "all") {
        temp1.field_office__uuid = fran;
      }
      //Get Acccount Tab
      getAccountListingTabData(temp1);
    } else {
      let temp = {
        limit: 15,
        offset: 0,
        invoice_date__lte: moment().endOf("month").format("YYYY-MM-DD"),
        invoice_date__gte: moment().startOf("month").format("YYYY-MM-DD"),
      };
      if(fran !== "all"){
        temp.field_office__uuid = fran || user?.user?.associated_field_offices[0]?.uuid
      }
      getAccountListingData(temp);
      //Get Acccount Tab
      getAccountListingTabData({
        invoice_date__lte: moment().endOf("month").format("YYYY-MM-DD"),
        invoice_date__gte: moment().startOf("month").format("YYYY-MM-DD"),        
        field_office__uuid : fran !== "all" ? fran || user?.user?.associated_field_offices[0]?.uuid : ""      
      });
    }
    const getFranchises = async () => {
      let franchises_result = await getFranchiseListAll({ offset: 0 });
      setState((s) => ({ ...s, franchiseList: franchises_result }));
    };
    getFranchises();
  }, []);
  useEffect(() => {
    let { all_selected, selectedRowKeys } = state;

    if (state.accountList?.count > 0) {
      let keys = [];
      if (all_selected && selectedRowKeys.length === 0) {
        if (state.selectedBatchOption === "process_payments") {
          state.accountList?.results?.forEach((row) => {
            if(row.status !== 'paid' )
            keys.push(row.uuid);
          });  
        }else{
          keys = state.accountList?.results?.map((row) => row.uuid);
        }

        setState((s) => ({
          ...s,
          loader: false,
          selectedRowKeys: keys,
          all_selected_keys_length: keys.length,
        }));
      } else {
        setState((s) => ({
          ...s,
          loader: false,
          set_table_active_action: "",
        }));
      }
    }
  }, [state.accountList]);

  const getAccountListingData = async (data) => {
    if (state.selectedBatchOption === "process_payments") {
      data.bulk_payment = true;
    }
    setexportFiltersForAccountList(data);
    let temp = await getAccountListing(data);
    if (temp?.results) {
      setState((s) => ({
        ...s,
        accountList: temp,
        delete_visible: false,
      }));
    }

    setState((s) => ({ ...s, loader: false }));
  };
  const getAccountListingTabData = async (data) => {
    if (state.selectedBatchOption === "process_payments") {
      data.bulk_payment = true;
    }
    let temp = await getAccountListingTab(data);
    if (temp) {
      setState((s) => ({
        ...s,
        tabData: temp,
      }));
    }
  };

  const setCurrentPageToFirst = () => {
    if (state.account_list_pagination.current > 1) {
      let { account_list_pagination } = state;

      account_list_pagination = Object.assign(account_list_pagination, {
        current: 1,
        offset: 0,
      });

      setState((s) => ({
        ...s,
        account_list_pagination,
        loader: true,
      }));
    }
  };

  //Selct Table Row checkbox
  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setState((s) => ({ ...s, selectedRowKeys }));
  };

  const renderHeaderCheckbox = () => {
    return (
      <Checkbox
        disabled={
          state.selectedBatchOption === "" && state.selectedRowKeys.length === 0
            ? true
            : false
        }
        checked={state.all_selected}
        onChange={() => handleSelectAll()}
      />
    );
  };

  const handleSelectAll = () => {
    let { account_list_filter, all_selected } = state;
    account_list_filter = Object.assign(account_list_filter);

    if (!all_selected) {
      setState((s) => ({
        ...s,
        loader: true,
        all_selected: true,
        selectedRowKeys: [],
      }));

      let data = {
        offset: 0,
        limit: 5000,
        ...account_list_filter,
      };

      data.status = data.status.toString();

      Object.keys(data).forEach(
        (key) => (data[key] === null || data[key] === "") && delete data[key]
      );
      getAccountListingData(data);
    } else {
      setState((s) => ({
        ...s,
        selectedRowKeys: [],
        all_selected: false,
      }));
    }
  };

  //Open another tab with invoice file
  const printInvoice = async (invoice_uuid) => {
    setState((s) => ({ ...s, loader: true }));
    const response = await printInvoiceRequest(invoice_uuid);
    const invoiceHelperRes = printInvoiceHelper(response);
    setState((s) => ({ ...s, loader: false }));
    window.open(invoiceHelperRes, "_blank");
  };

  //open component with invoice details
  const viewInvoice = async (uuid) => {
    const data = await getInvoiceDetail(uuid);
    setInvoiceDetail(data);
    setCurrentTab(1);
  };

  //API request to send email
  const sendEmailInvoice = async (uuid) => {
    const data = await sendInvoice(uuid);
    if (Array.isArray(data) && data.length > 0) {
      message.success(data[1], 10);
    } else {
      message.error("Something went wrong, please try again.", 10);
    }
    setState((s) => ({ ...s, loader: false }));
  };

  const getOverdueOpen = async () => {
    const data = await getOverdueOpenLastInvoices({
      user__uuid: props?.record?.uuid,
    });
    // setInvoiceSummary(data);
    dispatch(add(data));
  };

  // toggle log payment drawer
  const toggleLog = (value) => {
    setshowLogPayment(value);
    getOverdueOpen();
    setTimeout(() => {
      let data = {
        offset: 0,
        limit: 5000,
        ...account_list_filter,
      };
      data.status = data.status.toString();
      getAccountListingData(data);
    }, 1000);
  };

  // toggle refund drawer
  const toggleRefund = (value) => {
    setshowRefund(value);
  };

  // toggle delete modal
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  // delete invoice
  const onDeleteInvoice = async () => {
    setState((s) => ({ ...s, loader: true }));
    const response = await deleteInvoice({
      multiple_invoices_uuid: invoice_uuid,
    });
    if (
      !isEmpty(response) &&
      response?.message?.toLowerCase() === "invoice deleted successfully"
    ) {
      let data = {
        offset: 0,
        limit: 5000,
        ...account_list_filter,
      };
      data.status = data.status.toString();
      getAccountListingData(data);
      getOverdueOpen();

      message.success(response.message, 10);
    } else {
      message.error(response.message, 10);
    }
    setState((s) => ({ ...s, loader: false }));

    setTimeout(() => {
      toggleDeleteModal();
    }, 1000);
  };

  //Void invoice
  const getVoidInvoice = async (uuid) => {
    setState((s) => ({ ...s, loader: true }));
    const data = await voidInvoiceApi(uuid);
    if (data?.message !== undefined) {
      message.error(data.message, 10);
    } else {
      message.success("Void Invoice Successfully");
      let data = {
        offset: 0,
        limit: 5000,
        ...account_list_filter,
      };
      data.status = data.status.toString();
      getAccountListingData(data);
      getOverdueOpen();
    }
    setState((s) => ({ ...s, loader: false }));
  };

  //Show payment history
  const getPaymentHistoryApi = async (params) => {
    const data = await getPaymentHistory({ invoice__uuid: params });
    setInvoicePaymentHistory(data);
  };

  const onActionChange = async (e, invoice_uuid, record1) => {
    setaction(e);
    setactive_action_uuid(invoice_uuid);
    if (e !== 'print') {
      setRecord(record1);
      let customer = {};
      if (record1?.service_order?.client_type === "residential") {
        customer = await getCustomerDetail(record1?.user?.uuid);
      } else {
        customer = await getCommercialCustomer(record1?.user?.uuid);
      }
      setState((s) => ({ ...s, customer }));
    }
    switch (e) {
      case "print":
        printInvoice(invoice_uuid);
        break;
      case "view":
        setInvoiceuuid(record1?.uuid);
        viewInvoice(record1?.uuid);
        break;
      case "email":
        setState((s) => ({ ...s, loader: true }));
        sendEmailInvoice(invoice_uuid);
        break;
      case "log":
        setInvoiceuuid(invoice_uuid);
        toggleLog(true);
        break;
      case "refund":
        setInvoiceuuid(invoice_uuid);
        toggleRefund(true);
        break;
      case "delete":
        setInvoiceuuid(invoice_uuid);
        toggleDeleteModal();
        break;
      case "void":
        getVoidInvoice(invoice_uuid);
        break;
      case "payment_history":
        onChangeView(3);
        getPaymentHistoryApi(invoice_uuid);
        break;
      default:
        console.log("no action");
    }
    userBillingInfoaccount(record1?.user?.uuid)
  };
  const userBillingInfoaccount = async (temp) => {
    const biiling_info_data = await getUserBillingInfo({ "user__uuid": temp });
    if (biiling_info_data?.data?.length) {
      let temp_ach = biiling_info_data.data.filter((each) => each?.is_ach);
      let temp_card = biiling_info_data.data.filter((each) => each?.card_expiry_date);
      if (temp_card?.length) {
        setCardList(temp_card.length);
      }
      if (temp_ach?.length) {
        setIsAch(true);
      }
    }
  };
  // render table action
  const renderAction = (
    invoice_uuid = "",
    service_order_uuid = "",
    status = "",
    recordAction,
    creditMemo_id
  ) => {
    const menu = (
      <Menu>
        <Menu.Item
          key="0"
          disabled={selectedRowKeys.length > 1}
          onClick={() => onActionChange("view", invoice_uuid, recordAction)}
        >
          View
        </Menu.Item>
        <Menu.Item
          key="1"
          disabled={recordAction?.status === "void" || selectedRowKeys.length > 1}
          onClick={() => onActionChange("print", invoice_uuid)}
        >
          Print
        </Menu.Item>
        <Menu.Item
          key="2"
          disabled={recordAction?.status === "void" || selectedRowKeys.length > 1}
          onClick={() => onActionChange("email", invoice_uuid, recordAction)}
        >
          Email / Resend
        </Menu.Item>
        <Menu.Item
          key="3"
          disabled={
            (parseFloat(recordAction?.balance_due) <= 0 &&
            (recordAction?.status === "paid" || recordAction?.status === "void")) ||
            recordAction.is_processed || selectedRowKeys.length > 1
          }
          onClick={() => onActionChange("log", invoice_uuid, recordAction)}
        >
          Log Payment
        </Menu.Item>
        <Menu.Item
          key="4"
          disabled={recordAction?.status === "void" || selectedRowKeys.length > 1}
          onClick={() => onActionChange("refund", invoice_uuid, recordAction)}
        >
          Refund Payment
        </Menu.Item>
        <Menu.Item
          key="5"
          disabled={recordAction?.status === "void" || selectedRowKeys.length > 1}
          onClick={() => onActionChange("delete", invoice_uuid, recordAction)}
        >
          Delete
        </Menu.Item>
        <Menu.Item
          key="6"
          disabled={recordAction?.status === "void" || selectedRowKeys.length > 1}
          onClick={() => onActionChange("void", invoice_uuid, recordAction)}
        >
          Void
        </Menu.Item>
        <Menu.Item
          key="7"
          disabled={recordAction?.status === "void" || selectedRowKeys.length > 1}
          onClick={() =>
            onActionChange("payment_history", invoice_uuid, recordAction)
          }
        >
          Payment History
        </Menu.Item>
      </Menu>
    );

    const cm_menu = (
      <Menu>
        <Menu.Item
          disabled={selectedRowKeys.length > 1}
          key="1"
          onClick={() => onActionChange("view-credit-memo", invoice_uuid)}
        >
          View/Edit
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={creditMemo_id ? cm_menu : menu} trigger={["click"]}>
        <span onClick={(e) => e.preventDefault()}>
          {active_action_uuid === invoice_uuid
            ? creditMemo_id
              ? row_action_credit_memo[action]
              : row_action[action]
            : "Select"}{" "}
          <DownOutlined style={{ marginLeft: "10px" }} />
        </span>
      </Dropdown>
    );
  };

  const [cols, setCols] = useState(DataTable.AllAccountList({
    state,
    StatusChange,
    setState,
    deleteAccountInvoice,
    VoidInvoiceAPI,
    props,
    renderAction,
  }));

  useEffect(() => {
    const cc = DataTable.AllAccountList({
      state,
      StatusChange,
      setState,
      deleteAccountInvoice,
      VoidInvoiceAPI,
      props,
      renderAction,
    });

    setCols([...cc]);
  }, [state.selectedRowKeys]);

  const renderAccountTable = () => {
    const {
      accountList,
      selectedRowKeys,
      account_list_pagination,
      active_pagination,
      selectedBatchOption,
    } = state;

    return (
      <React.Fragment>
        <Table
          rowKey={(record) => record?.uuid}
          dataSource={accountList?.results || []}
          pagination={{ ...account_list_pagination, total: accountList?.count }}
          onChange={accountPagintaion}
          className="tablebody accounting"
          rowSelection={{
            type: "checkbox",
            selectedRowKeys,
            preserveSelectedRowKeys: true,
            columnTitle: renderHeaderCheckbox,
            onSelectAll: handleSelectAll,
            onChange: onSelectedRowKeysChange,
            getCheckboxProps: (record) => {
              return false || {
                disabled: 
                  selectedBatchOption === "" || 
                  ( state.selectedBatchOption === "process_payments" &&
                  (record.is_processed || 
                  record?.status === "paid")) ? true : false,              
              };
            },
          }}
          columns={cols}
        />

        {accountList?.results?.length > 0 ? (
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => {
                  setMessagePageLimit("15");
                }}
                className={active_pagination === "15" ? "active" : ""}
              >
                15
              </span>
            </li>
            <li>
              <span
                className={active_pagination === "30" ? "active" : ""}
                onClick={() => {
                  setMessagePageLimit("30");
                }}
              >
                30
              </span>
            </li>
            <li>
              <span
                className={active_pagination === "60" ? "active" : ""}
                onClick={() => {
                  setMessagePageLimit("60");
                }}
              >
                60
              </span>
            </li>
          </ul>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  const accountPagintaion = (pagination, filter_filler, sorter) => {
    let {
      account_list_pagination,
      active_pagination,
      search_value,
      account_list_filter,
    } = state;

    let ordering = "";

    if (sorter.column) {
      ordering =
        sorter.order === "ascend" ? `${sorter.field}` : `-${sorter.field}`;
    } else {
      ordering = "";
    }

    account_list_pagination = Object.assign(account_list_pagination, {
      current: pagination.current,
      ordering: ordering,
    });

    setState((s) => ({
      ...s,
      account_list_pagination,
      loader: true,
    }));

    let data = {
      ordering,
      limit: active_pagination,
      ...account_list_filter,
      ...account_list_pagination,
      search: search_value,
    };
    if (selectedFranchise !== "all") {
      data.field_office__uuid = selectedFranchise;
    }

    data.status = data.status.toString();
    data.offset = pagination.current * active_pagination - active_pagination;
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );

    getAccountListingData(data);
  };

  //set page limit
  const setMessagePageLimit = (custom_limit, sorter) => {
    let { search_value, account_list_pagination, account_list_filter } = state;

    account_list_pagination = Object.assign(account_list_pagination, {
      current: 1,
      pageSize: custom_limit,
    });

    setState((s) => ({
      ...s,
      account_list_pagination,
      loader: true,
      active_pagination: custom_limit,
    }));

    let data = {
      offset: 0,
      limit: custom_limit,
      search: search_value,
      ...account_list_filter,
      ...account_list_pagination,
    };
    if (selectedFranchise !== "all") {
      data.field_office__uuid = selectedFranchise;
    }

    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    getAccountListingData(data);
  };

  //Date Range Change
  const onInvoiceDateRange = (e) => {
    let { account_list_filter, active_pagination, count_filter } = state;

    if (e) {
      account_list_filter = Object.assign(account_list_filter, {
        invoice_date__lte: moment(e[1]).format("YYYY-MM-DD"),
        invoice_date__gte: moment(e[0]).format("YYYY-MM-DD"),
      });

      count_filter = Object.assign(count_filter, {
        invoice_date__lte: moment(e[1]).format("YYYY-MM-DD"),
        invoice_date__gte: moment(e[0]).format("YYYY-MM-DD"),
      });
    } else {
      account_list_filter = Object.assign(account_list_filter, {
        invoice_date__lte: "",
        invoice_date__gte: "",
      });
      count_filter = Object.assign(count_filter, {
        invoice_date__lte: "",
        invoice_date__gte: "",
      });
    }
    setState((s) => ({
      ...s,
      account_list_filter,
      count_filter,
    }));
    let data = {
      offset: 0,
      limit: active_pagination,
      ...account_list_filter,
    };
    let invoices_tab_data = { ...count_filter };
    if (selectedFranchise !== "all") {
      data.field_office__uuid = selectedFranchise;
      invoices_tab_data.field_office__uuid = selectedFranchise;
    }

    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    Object.keys(invoices_tab_data).forEach(
      (key) =>
        (invoices_tab_data[key] === null || invoices_tab_data[key] === "") &&
        delete invoices_tab_data[key]
    );

    setState((s) => ({ ...s, loader: true }));
    getAccountListingData(data);
    getAccountListingTabData(invoices_tab_data);
  };
  const customerAndStatusOnChange = (e, type, bulk_payment = false) => {
    const { search_value } = state;
    const temp = state.filteredCustomerList.filter(
      (item) => item.field_office !== null && item.field_office.uuid === e
    );
    setState((s) => ({ ...s, customerList: temp }));
    let { account_list_filter, active_pagination } = state;

    if (type === "status" && e[e.length - 1] === "") {
      account_list_filter = Object.assign(account_list_filter, {
        [type]: [""],
      });
    } else {
      account_list_filter = Object.assign(account_list_filter, { [type]: e });
      if (type === "status" && e.length > 0) {
        const idx = account_list_filter?.status?.indexOf("");
        if (idx > -1) {
          account_list_filter.status.splice(idx, 1);
          account_list_filter = Object.assign(account_list_filter, {
            status: account_list_filter.status,
          });
        }
      }
    }
    const status = [];
    status_mapping.map((x) => status.push(x.key));
    if (isEqual(account_list_filter?.status, status)) {
      account_list_filter = Object.assign(account_list_filter, {
        [type]: [""],
      });
    }
    setState((s) => ({
      ...s,
      account_list_filter,
      loader: true,
    }));
    let data = {
      offset: 0,
      serch: search_value,
      limit: active_pagination,
      ...account_list_filter,
    };
    data.status = data.status.toString();   
    data.bulk_payment = state.selectedBatchOption === "process_payments" ? true : bulk_payment;
    if (selectedFranchise !== "all" && type === "status") {
      data.field_office__uuid = selectedFranchise;
    }

    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    //Call API
    getAccountListingData(data);
  };
  const onSelectBatchOption = (value) => {
    setState((s) => ({
      ...s,
      selectedBatchOption: value,
      selectedRowKeys: [],
      all_selected: false,
      bulk_payment: false,
      loader: true,
    }));

    props.selectedBatch("");

    if (value === "process_payments") {
      customerAndStatusOnChange(
        ["open", "open-emailed", "open-printed", "overdue"],
        "status",
        true
      );
    }
    if (value === "print_invoices") {
      customerAndStatusOnChange([""], "status");
    }
    if (value === "email_invoices") {
      customerAndStatusOnChange(
        ["open", "open-emailed", "open-printed", "overdue", "paid"],
        "status"
      );
    }
    if (value === "") {
      customerAndStatusOnChange([""], "status");
    }
  };

  const onClearBatchOption = () => {
    setState((s) => ({
      ...s,
      selectedBatchOption: "",
      selectedRowKeys: [],
      all_selected: false,
      loader: true,
    }));
    props.selectedBatch("");
    customerAndStatusOnChange([""], "status");
  };

  //Invoice Process
  const invoiceProcessPayment = async () => {
    let { selectedRowKeys } = state;
    //Call API
    let params = {
      invoice_uuid_list: selectedRowKeys
    };
    const response = await paymentProcessInvoice(params);

    // if (response.invoice_count > 0) {
    //   response.messages.forEach((i) => {
    //     if(i.status === "failed"){
    //       message.error(i.message, 2.5);
    //     }else{
    //       message.success(i.message, 2.5);
    //     }
    //   });
    // }
    setState((s) => ({
      ...s,
      loader: false,
      selectedRowKeys: [],
      all_selected: false,
    }));

    onClearBatchOption();
  };

  //Invoice Process
  const invoiceEmail = async () => {
    let { selectedRowKeys } = state;
    //Call API
    let params = {
      invoice_uuid_list: selectedRowKeys,
    };
    const response = await emailInvoice(params);    
    onClearBatchOption();
    // if (response.invoice_count > 0) {
      // response.messages.forEach((i) => {
      //   message.success(i, 2.5);
      // });      
    // }
    setState((s) => ({
      ...s,
      loader: false,
      selectedRowKeys: [],
      all_selected: false,
    }));    
  };

  const onGoBatchOption = () => {
    if (state.selectedBatchOption === "process_payments") {
      message.success('Process has been initiated')
      invoiceProcessPayment();
    }
    if (state.selectedBatchOption === "email_invoices") {  
      message.success('Invoices Emailed');
      invoiceEmail();
    }
  };

  const VoidInvoiceAPI = async (uuid) => {
    let { account_list_filter, active_pagination, count_filter } = state;

    let data = {
      offset: 0,
      limit: active_pagination,
      ...account_list_filter,
    };
    let temp_data = { ...count_filter };

    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    Object.keys(temp_data).forEach(
      (key) =>
        (temp_data[key] === null || temp_data[key] === "") &&
        delete temp_data[key]
    );

    setState((s) => ({
      ...s,
      loader: true,
    }));
    const data1 = await voidInvoiceApi(uuid);
    if (data1?.message !== undefined) {
      message.error(data1.message, 10);
    } else {
      message.success("Void Invoice Successfully");
      getAccountListingData(data);
      getAccountListingTabData(temp_data);
    }
    setState((s) => ({
      ...s,
      loader: false,
    }));
  };

  //Delete invoice
  const deleteAccountInvoice = async () => {
    let {
      account_list_filter,
      active_pagination,
      count_filter,
      delete_id,
      selectedRowKeys,
    } = state;

    let data = {
      offset: 0,
      limit: active_pagination,
      ...account_list_filter,
    };
    let temp_data = { ...count_filter };

    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    Object.keys(temp_data).forEach(
      (key) =>
        (temp_data[key] === null || temp_data[key] === "") &&
        delete temp_data[key]
    );

    setState((s) => ({ ...s, loader: true }));

    if (delete_id) {
      setState((s) => ({
        ...s,
        loader: true,
        delete_visible: false,
      }));
      const data1 = await deleteInvoice({ multiple_invoices_uuid: delete_id });
      if (
        !isEmpty(data1) &&
        data1?.message?.toLowerCase() === "invoice deleted successfully"
      ) {
        message.success(data1.message, 10);
        setTimeout(() => {
          getAccountListingData(data);
          getAccountListingTabData(temp_data);
        }, 1000);
      } else {
        message.error(data1.message, 10);
      }
      setState((s) => ({
        ...s,
        loader: false,
        delete_visible: false,
      }));
    }
  };

  // Search Ticket
  const searchAccount = (e) => {
    const { account_list_filter } = state;
    setState((s) => ({ ...s, search_value: e.target.value }));
    if (e.target.value?.length >= 3 || e.target.value?.length === 0) {
      const data = {
        offset: 0,
        limit: 15,
        ...account_list_filter,
        invoice_date__lte: moment(account_list_filter?.invoice_date__lte)
          .endOf("month")
          .format("YYYY-MM-DD"),
        invoice_date__gte: moment(account_list_filter?.invoice_date__gte)
          .startOf("month")
          .format("YYYY-MM-DD"),
      };
      if (selectedFranchise !== "all") {
        data.field_office__uuid = selectedFranchise;
      }

      data.search = e.target.value;
      data.status = data.status.toString();
      getAccountListingData(data);
    }
  };

  // get logged in user
  const getLoggedInUserRole = () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    const logged_in_user_role = authUser?.user?.user_roles?.map((x) => x.name);
    const is_franchise_owner = logged_in_user_role?.includes("franchise_owner") || logged_in_user_role?.includes("operations_mgr");
    const is_account_corporate = logged_in_user_role?.includes(
      "accounting_corporate"
    );
    const is_corporate = logged_in_user_role?.includes("admin");
    const is_account_franchise = logged_in_user_role?.includes(
      "accounting_franchiese"
    );
    const franchise_uuid = authUser?.user?.field_offices?.map((x) => x.uuid);

    return {
      franchise_uuid,
      is_franchise_owner,
      is_account_corporate,
      is_corporate,
      is_account_franchise,
    };
  };
  //Due Date Range
  const onDueDateRange = (e) => {
    let { account_list_filter, active_pagination, count_filter } = state;
    if (e) {
      account_list_filter = Object.assign(account_list_filter, {
        due_date__lte: moment(e[1]).format("YYYY-MM-DD"),
        due_date__gte: moment(e[0]).format("YYYY-MM-DD"),
      });
      count_filter = Object.assign(count_filter, {
        due_date__lte: moment(e[1]).format("YYYY-MM-DD"),
        due_date__gte: moment(e[0]).format("YYYY-MM-DD"),
      });
    } else {
      account_list_filter = Object.assign(account_list_filter, {
        due_date__lte: "",
        due_date__gte: "",
      });
      count_filter = Object.assign(count_filter, {
        due_date__lte: "",
        due_date__gte: "",
      });
    }
    setState((s) => ({
      ...s,
      account_list_filter,
      count_filter,
    }));
    let data = {
      offset: 0,
      limit: active_pagination,
      ...account_list_filter,
    };
    let invoices_tab_data = { ...count_filter };
    if (selectedFranchise !== "all") {
      data.field_office__uuid = selectedFranchise;
      invoices_tab_data.field_office__uuid = selectedFranchise;
    }
    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    Object.keys(invoices_tab_data).forEach(
      (key) =>
        (invoices_tab_data[key] === null || invoices_tab_data[key] === "") &&
        delete invoices_tab_data[key]
    );
    setState((s) => ({ ...s, loader: true }));

    //Call API
    getAccountListingData(data);
    getAccountListingTabData(invoices_tab_data);
  };

  const onClearFilter = () => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    if (user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)) {
      setDefaultFranchise(previousFranchiseSelected);
    } else {
      setSelectedFranchise('all');
    }
    setState((s) => ({
      ...s,
      loader: true,
      selectedBatchOption: "",
      account_list_filter: {
        billing_type: "",
        status: [""],
        invoice_date__lte: "",
        invoice_date__gte: "",
        due_date__lte: "",
        due_date__gte: "",
        user__uuid: "",
        balance_due__lte: "",
        balance_due__gte: "",
        service_order__service_type: "",
      },
    }));
    const data = {
      offset: 0,
      limit: state.active_pagination,
      account_list_filter: {
        billing_type: "",
        status: [""],
        invoice_date__lte: "",
        invoice_date__gte: "",
        due_date__lte: "",
        due_date__gte: "",
        user__uuid: "",
        balance_due__lte: "",
        balance_due__gte: "",
      },
    };
    if ((user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)) && previousFranchiseSelected !== 'all') {
      data.field_office__uuid = previousFranchiseSelected;
    }
    getAccountListingData(data);
  };

  //inner Status Change
  const StatusChange = (type, value) => {
    let { account_list_filter, active_pagination } = state;
    let temp_value = account_list_filter.status;
    if (temp_value.length > 0) {
      temp_value[0] = value;
    } else {
      temp_value.push(value);
    }
    account_list_filter = Object.assign(account_list_filter, {
      [type]: temp_value,
    });
    setState((s) => ({
      ...s,
      account_list_filter,
      loader: true,
    }));
    let data = {
      offset: 0,
      limit: active_pagination,
      ...account_list_filter,
    };
    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    if (selectedFranchise !== "all") {
      data.field_office__uuid = selectedFranchise;
    }
    //Call API
    getAccountListingData(data);
  };

  const updateInvoiceDataOnFranchiseChange = (param) => {
    let { account_list_filter, active_pagination } = state;
    let data = {
      offset: 0,
      limit: active_pagination,
      ...account_list_filter,
    };
    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    data.field_office__uuid = param !== "all" ? param : "";
    //Get Acccount Tab
    getAccountListingTabData(data);
    EventBus.emit('FRANCHISE_UPDATED_ACCOUNTLIST', param);
  }

  const {
    selectedRowKeys,
    loader,
    tabData,
    active_pagination,
    customerList,
    search_value,
    franchiseList,
    is_log_payment,
    crr_user,
    showRefundPayment,
    invoice_date,
    is_open_date_picker,
    list,
    selectedBatchOption,
    account_list_filter,
  } = state;

  const {
    is_franchise_owner,
    is_account_corporate,
    is_corporate,
    is_account_franchise,
  } = getLoggedInUserRole();

  const onChangeView = (value) => {
    if (value === 0) {
      setInvoiceDetail({});
    }
    setCurrentTab(value);
  };
  moment.locale("en-gb", {
    week: {
      dow: 0,
    },
  });
  const onExportViewWorkOrder = async (is500=0) => {
    let payLoad = {
      ...exportFiltersForAccountList,
      is_500: is500,
      offset: 0,
      limit: state.accountList?.count 
    };
    setState((s) => ({ ...s, loadingExportViewOrder: true }));
    const response = await exportAccountList(payLoad); 
    setState((s) => ({ ...s, loadingExportViewOrder: false }));
  }
  const renderList = () => {
    return (
      <div>
        <Row gutter={[10]} className="account-filter">
          <Col lg={3} className="pl-15">
                <Select
                  className="franchise_type swhite filter_type"
                  showSearch
                  showArrow={true}
                  suffixIcon={<i className="las la-angle-down"></i>}
                  value={
                    currentUser?.user?.role.includes(`franchise_owner`)
                      ? defaultFranchise
                      : selectedFranchise
                  }
                  disabled={(props?.is_disable_franchise || state.franchiseList?.length == 1) ? true : false}
                  onSearch={(e) => {
                    getFranchiseListAll({ search: e });
                  }}
                  onBlur={() => {
                    getFranchiseListAll({ limit: 0, offset: 0 });
                  }}
                  onChange={(e) => {
                    setCurrentPageToFirst();
                    if (currentUser?.user?.role.includes(`franchise_owner`)) {
                      setPreviousFranchiseSelected(defaultFranchise);
                      setFranchiseSelected(e);
                      setSelectedFranchise(e);
                      setDefaultFranchise(getFranchiseSelected().uuid);
                      customerAndStatusOnChange(
                        getFranchiseSelected().uuid === "all"
                          ? ""
                          : getFranchiseSelected().uuid,
                        "field_office__uuid"
                      );
                    } else {
                      setSelectedFranchise(e);
                      setFranchiseSelected(e);
                      customerAndStatusOnChange(
                        e === "all" ? "" : e,
                        "field_office__uuid"
                      );
                    }
                    updateInvoiceDataOnFranchiseChange(e);
                  }}
                >
                  <Option value="all">All</Option>
                  {orderBy(state.franchiseList, [item => item.name.toLowerCase()], "asc")?.map((item) => {
                    return <Option key={item.uuid}>{item.name}</Option>;
                  })}
                </Select>
          </Col>
          <Col lg={6}>
              <ConfigProvider locale={en_GB}>
                <RangePicker
                  format={"MM/DD/YYYY"}
                  className="date_ranger dwhite"
                  dropdownClassName="dropdown_date_range"
                  suffixIcon={<i className="las la-angle-down"></i>}
                  onChange={(e) => {
                    setCurrentPageToFirst();
                    onInvoiceDateRange(e);
                  }}
                  value={
                    account_list_filter.invoice_date__gte &&
                    account_list_filter.invoice_date__lte
                      ? [
                          moment(account_list_filter.invoice_date__gte)
                            ? moment(account_list_filter.invoice_date__gte)
                            : undefined,
                          moment(account_list_filter.invoice_date__lte)
                            ? moment(account_list_filter.invoice_date__lte)
                            : undefined,
                        ]
                      : [null, null]
                  }
                  inputReadOnly
                  // renderExtraFooter={() => renderDatePickerFooter()}
                />
              </ConfigProvider>
          </Col>
          <Col lg={6}>
              <ConfigProvider locale={en_GB}>
                <RangePicker
                  format={"MM/DD/YYYY"}
                  className="due_ranger dwhite"
                  dropdownClassName="dropdown_date_range"
                  suffixIcon={<i className="las la-angle-down"></i>}
                  onChange={(e) => {
                    setCurrentPageToFirst();
                    onDueDateRange(e);
                  }}
                  value={
                    account_list_filter.due_date__gte &&
                    account_list_filter.due_date__lte
                      ? [
                          moment(account_list_filter.due_date__gte)
                            ? moment(account_list_filter.due_date__gte)
                            : undefined,
                          moment(account_list_filter.due_date__lte)
                            ? moment(account_list_filter.due_date__lte)
                            : undefined,
                        ]
                      : [null, null]
                  }
                  inputReadOnly
                />
              </ConfigProvider>
          </Col>
          <Col lg={3}>
              <Select
                className="bill_type swhite filter_type"
                defaultValue=""
                value={account_list_filter.billing_type}
                onChange={(e) => {
                  let account_list_filter_tmp = Object.assign(
                    account_list_filter,
                    { billing_type: e }
                  );
                  setCurrentPageToFirst();
                  setState((s) => ({
                    ...s,
                    account_list_filter: account_list_filter_tmp,
                    loader: true,
                  }));
                  let data = {
                    offset: 0,
                    limit: active_pagination,
                    ...account_list_filter,
                  };
                  data.status = data.status.toString();
                  if (selectedFranchise !== "all") {
                    data.field_office__uuid = selectedFranchise;
                  }
                  Object.keys(data).forEach(
                    (key) =>
                      (data[key] === null || data[key] === "") &&
                      delete data[key]
                  );
                  //Call API
                  getAccountListingData(data);
                }}
                suffixIcon={<i className="las la-angle-down"></i>}
              >
                <Option value="">All</Option>
                <Option value="email">Email</Option>
                <Option value="print">Print</Option>
              </Select>
          </Col>
          <Col lg={3}>
              <Select
                className="status_type swhite filter_type"
                defaultValue=""
                mode="multiple"
                value={account_list_filter.status}
                onChange={(e) => {
                  setCurrentPageToFirst();
                  customerAndStatusOnChange(e, "status");
                }}
                suffixIcon={<i className="las la-angle-down"></i>}
              >
                <Option
                  value=""
                  disabled={
                    state.selectedBatchOption === "bulk_delete" ||
                    state.selectedBatchOption ===
                      "process_payments_and_email_invoices" ||
                    state.selectedBatchOption === "email_invoices" ||
                    (state.selectedBatchOption === "process_payments")
                      ? "disabled"
                      : ""
                  }
                >
                  All
                </Option>
                {status_mapping.map((x) => (
                  <Option
                    disabled={
                      ((state.selectedBatchOption === "bulk_delete" ||
                        state.selectedBatchOption ===
                          "process_payments_and_email_invoices") &&
                        x?.key !== "draft") ||
                      (state.selectedBatchOption === "email_invoices" &&
                        x?.key === "draft") ||
                      ((state.selectedBatchOption === "process_payments" || state.selectedBatchOption === "email_invoices") &&
                        x?.key === "void") ||
                      ((state.selectedBatchOption === "process_payments"  && x?.key === "paid" ))
                        ? "disabled"
                        : ""
                    }
                    value={x?.key}
                    key={x?.key}
                  >
                    {x?.value}
                  </Option>
                ))}
              </Select>
          </Col>
          <Col lg={3} className="pr-15">
              <Select
                className="customer_type swhite filter_type"
                defaultValue=""
                value={account_list_filter.service_order__service_type}
                onChange={(e) => {
                  let account_list_filter_tmp = Object.assign(
                    account_list_filter,
                    { service_order__client__client_type: e }
                  );
                  setCurrentPageToFirst();
                  setState((s) => ({
                    ...s,
                    account_list_filter: account_list_filter_tmp,
                    loader: true,
                  }));
                  let data = {
                    offset: 0,
                    limit: active_pagination,
                    ...account_list_filter,
                  };
                  if (selectedFranchise !== "all") {
                    data.field_office__uuid = selectedFranchise;
                  }
                  data.status = data.status.toString();
                  Object.keys(data).forEach(
                    (key) =>
                      (data[key] === null || data[key] === "") &&
                      delete data[key]
                  );
                  //Call API
                  getAccountListingData(data);
                }}
                suffixIcon={<i className="las la-angle-down"></i>}
              >
                <Option value="">All</Option>
                <Option value="residential">Residential</Option>
                <Option value="commercial">Commercial</Option>
              </Select>
          </Col>
        </Row>
        <Row gutter={[10]}>
          <Col lg={21} />
          <Col lg={3} className="account-clear-all-filter">
              <a onClick={onClearFilter}>Clear all Filter</a>
          </Col>
        </Row>
        <div className="block-row pl-15">
          <div className="status-blocks">
            <div className="overdue stcell">
              <div className="flex flex-start ai-center pb-10">
                <h1 className="head">
                  {tabData?.overdue_invoices_count
                    ? tabData?.overdue_invoices_count
                    : 0}
                </h1>
                <h4>
                  {tabData?.overdue_invoices_amount?.total__sum
                    ? `$${formatToCurrency(
                        tabData?.overdue_invoices_amount?.total__sum
                      )}`
                    : ""}
                </h4>
              </div>
              <p>OVERDUE</p>
            </div>
            <div className="ml-10 open stcell">
              <div className="flex flex-start ai-center pb-10">
                <h1 className="head">
                  {state.tabData?.open_invoices_count
                    ? state.tabData?.open_invoices_count
                    : 0}
                </h1>
                <h4>
                  {state.tabData?.open_invoices_amount?.total__sum
                    ? `$${formatToCurrency(
                        state.tabData?.open_invoices_amount?.total__sum
                      )}`
                    : ""}
                </h4>
              </div>
              <p>OPEN INVOICES</p>
            </div>
            <div className="ml-10 paid stcell">
              <div className="flex flex-start ai-center pb-10">
                <h1 className="head">
                  {state.tabData?.paid_last_30_days_count
                    ? state.tabData?.paid_last_30_days_count
                    : 0}
                </h1>
                <h4>
                  {state.tabData?.paid_last_30_days_amount?.total_with_tax__sum
                    ? `$${formatToCurrency(
                        state.tabData?.paid_last_30_days_amount
                          ?.total_with_tax__sum
                      )}`
                    : ""}
                </h4>
              </div>
              <p>PAID LAST 30 DAYS</p>
            </div>
          </div>
        </div>
        <div
          className="block-row"
          style={{ padding: "15px", width: "100%" }}
        >
          <div className="filter_wrapper filter_wrapper_account_list acc-wrap flex pl-0">
            <div className="left_side">
              <div className="frachise_border ml-0 mr-2">
                <Select
                  className="sorange batch_action_filter"
                  showSearch
                  showArrow={true}
                  suffixIcon={<i className="las la-angle-down"></i>}
                  value={selectedBatchOption}
                  onChange={(e) => {
                    onSelectBatchOption(e);
                  }}
                >
                  <Option value="">Batch Actions</Option>
                  <Option value="process_payments">Bulk Process Payment</Option>
                  <Option value="email_invoices">Bulk Email Invoices</Option>
                </Select>
              </div>
              <div
                className="fitem form-group"
                style={{ display: "flex", paddingLeft: "10px" }}
              >
                <button
                  className={
                    state.selectedRowKeys.length > 0
                      ? "btn btn-warning mr-2"
                      : "btn disable mr-2"
                  }
                  onClick={
                    state.selectedRowKeys.length > 0
                      ? onGoBatchOption
                      : () => {}
                  }
                >
                  GO
                </button>
                <a
                  href={() => false}
                  className={
                    selectedBatchOption === ""
                      ? "disable mr-2"
                      : "btn-warning mr-2"
                  }
                  style={{
                    marginTop: "10px",
                    color: "green !important",
                    textDecoration: "underline",
                  }}
                  onClick={
                    selectedBatchOption === "" ? () => {} : onClearBatchOption
                  }
                  >
                    Refresh
                  </a>
                </div>
              </div>
              <div className="right_side" style={{ marginRight: "100px" }}>
                <div className="form-group flex ai-center w-100 flex-end">
                  <div className="fitem fitem-rinner licon search_box">
                    <div className="fitem-ricon">
                      <i className="las la-search"></i>
                    </div>
                    <input
                      style={{ width: "345px" }}
                      type="text"
                      placeholder="Invoice Number, Customer Name"
                      value={search_value}
                      onChange={(e) => {
                        searchAccount(e);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          searchAccount({
                            target: { value: state.search_value },
                          });
                        }
                      }}
                      className="form-control flex-1"
                    />
                    {
                      (state.accountList?.count|| 0) > 500 ? (
                      <Popconfirm
                        onConfirm={() => !state.loadingExportViewOrder && onExportViewWorkOrder(1)}
                        okText="Export"
                        cancelText="Cancel"
                        icon={<></>}
                        title={
                          <>
                            <div>
                              Acccount list data is more than 500 records. Data would be
                              <br />
                              exported and emailed to
                              {' '}
                              <b>{currentUser?.user?.email}</b>.
                            </div>
                          </>
                        }
                      >
                        <button
                        className="btn btn-warning"
                        >
                          {state.loadingExportViewOrder ? 'Exporting...' : 'Export'}
                        </button>
                      </Popconfirm>
                    ) : (
                      <button
                        disabled={state.loadingExportViewOrder}
                        onClick={() => onExportViewWorkOrder()}
                        className="btn btn-warning"
                        >
                          {state.loadingExportViewOrder ? 'Exporting...' : 'Export'}
                        </button>
                    )
                  }   
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div
          className="block-row"
          style={{ padding: "15px", width: "fit-content" }}
        >
          {renderAccountTable()}
        </div>
      </div>
    );
  };

  const toggleLoader = (value) => {
    setState((s) => ({ ...s, loader: value }));
  };

  const renderViews = () => {
    switch (currentTab) {
      case 0:
        return renderList();
      case 1:
        if (record?.service_order?.client_type === "residential") {
          return (
            <AddNewInvoiceRC
              invoice_uuid={invoice_uuid}
              record={record}
              onChangeView={onChangeView}
              customerId={state?.customer?.uuid}
              customer={state?.customer}
              invoiceDetail={invoiceDetail}
            />
          );
        } else {
          return (
            <AddNewInvoiceCC
              invoice_uuid={invoice_uuid}
              record={record}
              onChangeView={onChangeView}
              customerId={state?.customer?.uuid}
              customer={state?.customer}
              invoiceDetail={invoiceDetail}
            />
          );
        }
      case 3:
        return PaymentHistory();
      default:
        return renderList();
    }
  };

  const PaymentHistory = () => {
    return (
      <>
        <ul className="breadcrumb">
          <li
            onClick={() => {
              onChangeView(0);
            }}
          >
            <span className="pointer">
              <i className="las la-angle-left"></i> Back to All Accounts
            </span>
          </li>
        </ul>
        <h2 style={{ padding: "0 25px 0 25px", margin: "0" }}>
          Payment History
        </h2>
        <div className="block-row pt-10">
          <Table
            rowKey="uuid"
            dataSource={invoicePaymentHistory}
            columns={DataTable.InvoiceHistory()}
          />
        </div>
      </>
    );
  };

  const renderLogPayment = () => {
    if (record?.service_order?.client_type === "residential") {
      return (
        <LogPaymentRC
          customer={state?.customer}
          invoice_uuid={invoice_uuid}
          customerId={state?.customer.uuid}
          visible={showLogPayment}
          closeDrawer={toggleLog}
          toggleLoader={toggleLoader}
          manualLog={false}
          invoiceRecord={record}
          getOverdueOpen={getOverdueOpen}
          getInvoiceList={getAccountListingData}
        />
      );
    } else {
      return (
        <LogPaymentCC
          customer={state?.customer}
          invoice_uuid={invoice_uuid}
          customerId={state?.customer.uuid}
          visible={showLogPayment}
          closeDrawer={toggleLog}
          toggleLoader={toggleLoader}
          manualLog={false}
          invoiceRecord={record}
          getOverdueOpen={getOverdueOpen}
          getInvoiceList={getAccountListingData}
        />
      );
    }
  };

  return (
    <div>
      <div>
        {loader && <Loader />}
        {renderViews()}
      </div>
      {showLogPayment && renderLogPayment()}
      {showRefund && (
        <Refund
          customer={state?.customer}
          invoice_uuid={invoice_uuid}
          customerId={state?.customer.uuid}
          visible={showLogPayment}
          closeDrawer={toggleRefund}
          toggleLoader={toggleLoader}
          manualLog={false}
          invoiceRecord={record}
          getOverdueOpen={getOverdueOpen}
          actionIssueRefund
          invoiceList={state.accountList?.results ?? state.accountList}
          getInvoiceList={getAccountListingData}
          getInvoiceById={props.getInvoiceById}
          refundTypeValue="invoice"
          refundPayment={props.refundPayment}
          List={state.list}
          cardList={cardList}
          isAch={isAch}
        />
      )}
      <Modal
        title="Delete Customer"
        visible={showDelete}
        onCancel={() => toggleDeleteModal()}
        className="delete_user_modal"
        width={400}
        footer={[
          <button
            key="cancel"
            className="btn btn-brand btn-bdr"
            onClick={() => toggleDeleteModal()}
          >
            No
          </button>,
          <button
            key="upload"
            className={`btn btn-waning`}
            onClick={() => onDeleteInvoice()}
          >
            Yes
          </button>,
        ]}
      >
        <div>
          <h2> Are You Sure! You want to delete selected Invoice! ?</h2>
        </div>
      </Modal>
    </div>
  );
};

export default AccountListTable;
