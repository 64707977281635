import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Skeleton, Empty, Result, Input, Space } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import moment from "moment-timezone";
import { Provider, useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import ActivityLogCard from "./ActivityLog.Card";
import { getFullName } from "./ActivityLog.Common";
import store from "../../../../Store/store";
import {
  selectAllTechNotes,
  fetchTechNotes,
  techNotesRemove,
} from "../../../../Store/techNotesSlice";

const ActivityLogModal = ({
  customer_uuid = null,
  customer_name = null,
  open = false,
  setOpen = () => {},
}) => {
  const [dateType, setDateType] = useState("months");
  const [startDate, setStartDate] = useState(moment().subtract(1, dateType));
  const [endDate, setEndDate] = useState(moment());
  const [searchString, setSearchString] = useState("");

  const dispatch = useDispatch();
  const techNotes = useSelector(selectAllTechNotes);
  const techNotesStatus = useSelector((state) => state.techNotes.status);

  const error = useSelector((state) => state.techNotes.error);

  useEffect(() => {
    getTechNotes();
  }, [startDate]);

  const getTechNotes = () => {
    dispatch(
      fetchTechNotes({
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        customer_uuid: customer_uuid,
        limit: 100,
        offset: 0,
      })
    );
  };

  const hideActivityLog = () => {
    dispatch(techNotesRemove());
    setOpen(false);
  };

  const onPreviousDate = () => {
    setStartDate(moment(startDate).subtract(1, dateType));
    setEndDate(moment(endDate).subtract(1, dateType));
  };

  const onNextDate = () => {
    setStartDate(moment(startDate).add(1, dateType));
    setEndDate(moment(endDate).add(1, dateType));
  };

  const onResetDate = () => {
    setStartDate(moment().subtract(1, dateType));
    setEndDate(moment());
  };

  const formatDateTitle = () =>
    `${moment(startDate).format("MMM DD")} - ${moment(endDate).format(
      "MMM DD"
    )}, ${moment(endDate).format("YYYY")}`;

  const filterSearch = () =>
    techNotes
      .filter((note) => {
        const techFullName = getFullName(note?.technician).toLowerCase();
        const techNotes = note?.tech_notes
          ? note?.tech_notes.toLowerCase()
          : "";
        const standardSearchString = searchString.toLowerCase();
        if (!note?.tech_notes && !techFullName) {
          return false;
        }
        if (
          techNotes.includes(standardSearchString) ||
          techFullName.includes(standardSearchString)
        ) {
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        return (
          moment(a.scheduled_appointment).format("YYYYMMDD") -
          moment(b.scheduled_appointment).format("YYYYMMDD")
        );
      });

  /**
   * @description Array of tech notes merge by the scheduled_appointment
   * @param list Array of tech notes
   * @returns Array tech notes based on property
   */
  const merge = (list) =>
    list.reduce((prev, note) => {
      const test = prev.find(
        (element) =>
          moment(element.scheduled_appointment).format("DD/MM/YYYY") ===
          moment(note.scheduled_appointment).format("DD/MM/YYYY")
      );
      if (test) {
        test.notes.push(note);
        return prev;
      }
      return [
        ...prev,
        { scheduled_appointment: note.scheduled_appointment, notes: [note] },
      ];
    }, []);

  /**
   * @description return React component based on the result of API status result
   * & data filtering.
   * @returns React component for technician notes
   */
  const setNotesContent = () => {
    switch (techNotesStatus) {
      case "loading":
        return <Skeleton active />;

      case "succeeded":
        if (_.isEmpty(techNotes)) {
          return <Empty description={"No tech notes"} />;
        }
        if (searchString) {
          const filteredNotes = filterSearch();
          if (_.isEmpty(filteredNotes))
            return (
              <Empty description={"No match for tech note or technician"} />
            );
          return merge(filteredNotes).map((note, idx) => (
            <ActivityLogCard note={note} key={idx} />
          ));
        }
        merge(techNotes);
        return merge(techNotes).map((note, idx) => (
          <ActivityLogCard note={note} key={idx} />
        ));

      case "failed":
        return (
          <Result
            status="error"
            title="Request Failed"
            subTitle="There was an error on trying to get tech notes"
          />
        );
      default:
        break;
    }
  };

  return (
    <Provider store={store}>
      <Modal
        title={
          <span>
            <ReconciliationOutlined /> Activity Log
          </span>
        }
        open={open}
        onOk={hideActivityLog}
        onCancel={hideActivityLog}
        className="activity-log-modal"
        destroyOnClose
        maskClosable={true}
        closable={true}
        width={420}
        footer={null}
      >
        <Row justify="space-between">
          <Col span={3} className={"text-left"}>
            <button onClick={() => onPreviousDate()}>
              <LeftOutlined />
            </button>
          </Col>
          <Col span={18} className={"date-title text-center"}>
            <span onClick={() => onResetDate()} style={{ cursor: "pointer" }}>
              {formatDateTitle()}
            </span>
          </Col>
          <Col span={3} className={"text-right"}>
            <button onClick={() => onNextDate()}>
              <RightOutlined />
            </button>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24} className="customer-name">
            {customer_name}
          </Col>
        </Row>
        <Space direction="horizontal">
          <Input
            placeholder="Search tech note & technician"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            allowClear
          />
        </Space>
        <section className={"notes-wrapper"}>{setNotesContent()}</section>
      </Modal>
    </Provider>
  );
};

export default ActivityLogModal;
