import React from "react";
import moment from "moment-timezone";
import { Row, Col, Tooltip } from "antd";
import { Popup } from "@mobiscroll/react";
import location from "../../../Assets/icons/location.svg";
import phoneIcon from "../../../Assets/icons/phone-call.svg";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { toggleTaskModal, setSelectedTaskData, setTaskFlagFromEdit } from "../../../Store/technicianTaskDataSlice";
import {editDuration, addDuration} from "../../../Store/serviceOrderDetailDataSlice";

export const PopupModalTechGlance = (props) => {
  const { isOpen, anchor, setOpen, bgColor, currentEvent, shouldOpenPopup, showWOModal, woTaskInfo,
    showRescheduleModal, showDeleteModal, updateTaskStatus, role } = props;
  const dispatch = useDispatch();
  return (
    <Popup
      display="anchored"
      isOpen={isOpen}
      anchor={anchor}
      touchUi={false}
      showOverlay={true}
      contentPadding={false}
      closeOnOverlayClick={true}
      closeOnOverlayTap={true}
      onClose={() => setOpen(false)}
      width={350}
      cssClass="md-tooltip"
    >
      <div>
        <div
          className="md-tooltip-header"
          style={{ backgroundColor: bgColor }}
        >
          <span>{currentEvent?.serviceType ?? "service_type"}</span>
          <span
            className="mbsc-font-icon mbsc-icon-close"
            onClick={() => setOpen(false)}
          ></span>
        </div>
        <div className="md-tooltip-info">
          <div className="md-tooltip-time">
            <span className="md-tooltip-text">
              {moment(currentEvent?.start).format("dddd, MMMM DD, YYYY")}
            </span>
            <span className="md-tooltip-actions">
              {currentEvent?.job_forms?.length > 0 ? (
                <Tooltip title="Job Form">
                  <span
                    className="mbsc-font-icon mbsc-icon-file4"
                    onClick={() => {
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      showWOModal({ original: currentEvent });
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
              {currentEvent?.edit_work_order_url &&
                !currentEvent?.is_invoiced ? (
                <Tooltip title="Edit Work Order">
                  <span
                    className={`mbsc-font-icon mbsc-icon-pencil ${woTaskInfo?.batch_invoice_processed ? 'disabled' : ''}`}
                    onClick={() => {
                      dispatch(addDuration(currentEvent?.service_duration));
                      dispatch(editDuration(true));
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      window.open(currentEvent.edit_work_order_url, "_blank");
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
              {currentEvent?.status !== "complete" ? (
                <>
                  {role === "field_tech" ? (
                    (props.techPermission?.includes("assign_wo") ||
                      props.techPermission?.includes(
                        "reschedule_wo"
                      )) && (
                      <Tooltip title="Reschedule">
                        <span
                          className="mbsc-font-icon mbsc-icon-line-calendar"
                          onClick={() => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            showRescheduleModal({ original: currentEvent });
                          }}
                        ></span>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="Reschedule">
                      <span
                        className="mbsc-font-icon mbsc-icon-line-calendar"
                        onClick={() => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          showRescheduleModal({ original: currentEvent });
                        }}
                      ></span>
                    </Tooltip>
                  )}
                </>
              ) : (
                <></>
              )}
              {woTaskInfo?.serviceType == "Task" && woTaskInfo?.status !== "complete" ?
                <Tooltip title="Edit Task">
                  <span
                    className="mbsc-font-icon mbsc-icon-pencil"
                    onClick={() => {
                      shouldOpenPopup.current = false;
                      setTimeout(
                        () => (shouldOpenPopup.current = true),
                        200
                      );
                      setOpen(false)
                      dispatch(setSelectedTaskData(woTaskInfo))
                      dispatch(toggleTaskModal(true))
                      dispatch(setTaskFlagFromEdit(true))
                    }}
                  ></span>
                </Tooltip> : null}
              {role === "field_tech" && woTaskInfo?.status !== "complete" ? (
                props.techPermission?.includes("delete_so") && (
                  <Tooltip title="Delete">
                    <span
                      className="mbsc-font-icon mbsc-icon-remove"
                      onClick={() => {
                        shouldOpenPopup.current = false;
                        setTimeout(
                          () => (shouldOpenPopup.current = true),
                          200
                        );
                        showDeleteModal({ original: currentEvent });
                      }}
                    ></span>
                  </Tooltip>
                )
              ) : (woTaskInfo?.status !== "complete" &&
                <Tooltip title="Delete">
                  <span
                    className="mbsc-font-icon mbsc-icon-remove"
                    onClick={() => {
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      showDeleteModal({ original: currentEvent });
                    }}
                  ></span>
                </Tooltip>
              )}
            </span>
          </div>
          <div className="md-tooltip-time">
            <span className="md-tooltip-text">
              {moment(currentEvent?.start).utc().format("hh:mm A")}
            </span>
          </div>
          <div className="md-tooltip-status">
            <span className="md-tooltip-title">Technician:</span>
            <span className="md-tooltip-text" style={{ textTransform:'capitalize' }}>
              {currentEvent?.technician}
            </span>
          </div>  
          <div className="md-tooltip-status">
            <span className="md-tooltip-title">Customer:</span>
            <span className="md-tooltip-text md-tooltip-notes">
              {currentEvent?.serviceType == 'Task' ? currentEvent?.customer?.name : currentEvent?.client_name}
              <a href={`tel:${currentEvent?.phone_number}`}>
                <span className="md-tooltip-status">
                  <img
                    src={phoneIcon}
                    alt=""
                    className="phone-icon"
                  /> 
                  {currentEvent?.phone_number}
                </span>
              </a>
            </span>
          </div>
          <div className="md-tooltip-status">
            <span className="md-tooltip-title">Status:</span>
            <span className="md-tooltip-status md-tooltip-text">
              {currentEvent?.status}
            </span>
          </div>
          <div className="md-tooltip-notes">
            <span className="md-tooltip-title">Special Notes:</span>
            <span className="md-tooltip-text">
              {currentEvent?.special_notes}
            </span>
          </div>
          {!currentEvent?.address?.formatted_address.toLowerCase().includes('none') ?
            <div className="md-tooltip-notes">
              <span className="md-tooltip-title">Address:</span>
              <span className="md-tooltip-text">
                <Row>
                  <Col span={3}>
                    <img src={location} alt="" className="calendar-location" />
                  </Col>
                  <Col span={21}>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`https://www.google.com/maps/search/?api=1&query=${currentEvent?.address?.formatted_address}`}
                      style={{ color: "#000", textDecoration: "underline" }}
                    >
                      {currentEvent?.address?.formatted_address}
                    </a>
                  </Col>
                </Row>
              </span>
            </div> : null}
          {currentEvent?.serviceType == "Task" ? <div>
            <Row className="button-row">
              <Col span={7}>
                <button
                  className={
                    woTaskInfo?.status === "complete" ||
                      woTaskInfo?.status === "scheduled" ||
                      woTaskInfo?.status === "cancelled"
                      ? " btn button-start-disabled"
                      : "btn btn-bdr button-start-enabled"
                  }
                  disabled={
                    woTaskInfo?.status === "complete" ||
                    woTaskInfo?.status === "cancelled" ||
                    woTaskInfo?.status === "scheduled"
                  }
                  onClick={() => updateTaskStatus(woTaskInfo, 'scheduled')}
                >
                  {woTaskInfo.start_job_datetime}
                  Start Job
                </button>
              </Col>
              <Col span={7}>
                <button
                  type="submit"
                  className={
                    woTaskInfo?.status === "scheduled"
                      ? "btn btn-bdr button-start-enabled"
                      : "btn button-start-disabled"
                  }
                  onClick={() => updateTaskStatus(woTaskInfo, 'complete')}
                >
                  Complete Job
                </button>
              </Col>
            </Row>
          </div> : null}
        </div>
      </div>
    </Popup>
  )
}