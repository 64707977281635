import React from 'react';
import * as yup from 'yup';
import { message } from 'antd';
import RightSlider from '../../Login/RightSlider';
import dclogo from "../../Assets/images/download.png";
import {errorHandle} from "../../Components/errorHandle";
import { useLocation, useNavigate, useParams } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  userRegister, verifyRegisterUser
} from '../../Action/Auth';
import { Control } from '@antv/l7-component';

const Register = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams()
  
  const [state,setState] = useState({
    user: {
      "first_name": "",
      "last_name": "",
      "email": "",
      "password1": "",
      "password2": "",
      "role": ["admin"]
    },
    validPassword: {
      containsUppercase: false,
      containsNumber: false,
      checkLength: false,
      is_password_match: false,
    },
    is_btn_active: false,
    loader: false,
    is_password1_toggle: false,
    is_password2_toggle: false
  })
  const [verifyRegister, setVerifyRegister] = useState({});

  useEffect(() => {    
    const accessToken = JSON.parse(localStorage.getItem("authToken"))
      ? JSON.parse(localStorage.getItem("authToken")).key
      : "";

    if (accessToken) {
      navigate('/dashboard');
    }
    else {
      let uid = '';
      let token = params.token;


      if (location.search.split('=')[1]) {
        uid = location.search.split('=')[1]
      }
      let data = {
        uid,
        token
      }

      getVerifyRegisterUser(data);
        
      setState(s => ({
        ...s,
        loader: true
      }))
    }
  }, []);

  useEffect(() => {    
    if (verifyRegister && Object.keys(verifyRegister).length > 0) {
      // display Success messsage
      let { user } = state;
      let { first_name, last_name, email, uuid } = verifyRegister;

      user = Object.assign(user, {
        first_name,
        last_name,
        email,
        uuid
      })

      setState(s => ({
        ...s,
        loader: false,
        user
      }))
    }
    
    if (verifyRegister?.errors && Object.keys(verifyRegister?.errors).length > 0) {
      setState(s => ({
        ...s,
        loader: false
      }))
      message.error('Something went wrong');
    }

  }, [verifyRegister]);

  const getVerifyRegisterUser = async(data) => {              
    let user = await verifyRegisterUser(data);         
    setVerifyRegister(user);
  }

  const onUserFormChange = (e) => {
    let { user, validPassword } = state;
    user = Object.assign(user, { [e.target.name]: e.target.value });


    //Password validation
    if (e.target.name === 'password1') {
      validPassword = Object.assign(validPassword, {
        'containsUppercase': (/[A-Z]/).test(e.target.value),
        'containsNumber': (/\d/).test(e.target.value),
        'checkLength': e.target.value.length >= 8,
        'is_password_match': user.password1 && user.password1 === user.password2
      });
    }
    if (e.target.name === 'password2') {
      validPassword = Object.assign(validPassword, {
        'is_password_match': user.password1 && user.password1 === user.password2
      });
    }
    setState(s => ({ ...s, user, validPassword }));
    validateUser(user, validPassword);
  }

  // Validate Form
  const validateUser = (user, validPassword) => {

    const UserSchema = yup.object().shape({
      user: yup.object().shape(
        {
          first_name: yup.string().required("First name is required"),
          last_name: yup.string().required("Last name is required"),
          email: yup.string().required("Email is required").email('Your Email is invalid.'),
          password1: yup.string().required("Password is required"),
          password2: yup.string()
            .oneOf([yup.ref('password1'), null])
            .required('Password confirm is required'),
        }
      ),
      validPassword: yup.object().shape({
        containsUppercase: yup.bool().oneOf([true], ''),
        containsNumber: yup.bool().oneOf([true], ''),
        is_btn_active: yup.bool().oneOf([true], ''),
        is_password_match: yup.bool().oneOf([true], ''),

      })
    })

    const UserData = {
      user,
      validPassword
    }
    // check validity
    let IsValid;

    UserSchema.isValid(UserData)
      .then(function (valid) {
        IsValid = valid
      });

    setTimeout(() => {
      if (IsValid) {
        setState(s => ({
          ...s,
          is_btn_active: true
        }))
      }
      else {
        UserSchema.validate(UserData, { abortEarly: false })
          .catch(function () {
            setState(s => ({
              ...s,
              is_btn_active: false
            }))
          });
      }
    }, 100);
  }
  //Register User
  const registerUSer = async(e) => {
    e.preventDefault();
    setState(s => ({
      ...s,
      loader: true
    }))

    let { user } = state;
    let data = {
      "new_password1": user.password1,
      "new_password2": user.password2,
      "first_name": user.first_name,
      "last_name": user.last_name
    }
    
    const Register = await userRegister(data, user.uuid);
    
    if (Register && Register.uuid && Object.keys(Register).length > 0) {
      // display Success messsage
      message.success('Register Successfully');
      setTimeout(() => {
        navigate(`/`)
      }, 1500);

    }

    if (!Register || !Register.uuid || Object.keys(Register?.errors).length > 0) {
      // display error messsage
      if (Register?.errors.email) {
        message.error(Register?.errors.email);
      }
      else if (Register?.errors.username) {
        message.error(Register?.errors.username);
      }
      else {
        errorHandle(Register?.errors)
      }
    }
    setState(s => ({ ...s, loader: false }))
  }

  const render = () => {
    let { user, is_btn_active, validPassword, loader, is_password1_toggle, is_password2_toggle } = state;
    return (
      <div id="wrapper">
        <div className="auth-page">
          <div className="authform">
            <div className="auth-container">
              <img src={dclogo} alt="" className="logo" />
              <h1>Welcome to ASP CRM. <br />Sign up to get started.</h1>
              <label className="mb-30">Enter your details to proceed</label>
              <form onSubmit={(e) => { registerUSer(e); }} className="form-group">
                <div className="fitem">
                  <label>First Name</label>
                  <input type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    value={user.first_name}
                    onChange={(e) => { onUserFormChange(e) }} />
                </div>
                <div className="fitem">
                  <label>Last Name</label>
                  <input type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    value={user.last_name}
                    onChange={(e) => { onUserFormChange(e) }} />
                </div>
                <div className="fitem">
                  <label>Email Address</label>
                  <input type="email"
                    className="disabled"
                    placeholder="Email Address"
                    name="email"
                    value={user.email}
                    disabled
                  // onChange={(e) => { onUserFormChange(e) }} 
                  />
                </div>
                <div className="fitem fitem-rinner">
                  <label>Password</label>
                  <input
                    type={is_password1_toggle ? 'text' : 'password'}
                    className="form-control"
                    placeholder="Start typing…"
                    name="password1"
                    value={user.password1}
                    onChange={(e) => { onUserFormChange(e) }} />
                  <span className="fitem-ricon">
                    <i
                      className={is_password1_toggle ? 'lar la-eye' : 'las la-low-vision'}
                      onClick={() => {
                        setState(s => ({ ...s, is_password1_toggle: !state.is_password1_toggle }))
                      }}
                    ></i>
                  </span>
                </div>
                <div className="fitem fitem-rinner">
                  <label>Repeat Password</label>
                  <input
                    type={is_password2_toggle ? 'text' : 'password'}

                    className="form-control"
                    placeholder="Start typing…"
                    name="password2"
                    value={user.password2}
                    onChange={(e) => { onUserFormChange(e) }} />
                  <span className="fitem-ricon">

                    <i className={is_password2_toggle ? 'lar la-eye' : 'las la-low-vision'}
                      onClick={() => { setState(s => ({ ...s,is_password2_toggle: !state.is_password2_toggle })) }}
                    ></i>

                  </span>
                </div>

                <div className="fitem validate">
                  <span className={validPassword.checkLength ? 'circle success_bg' : 'circle'}></span>At leasts 8 characters<br />
                  <span className={validPassword.containsUppercase ? 'circle success_bg' : 'circle'}></span>At least one capital letter<br />
                  <span className={validPassword.containsNumber ? 'circle success_bg' : 'circle'}></span>At least one number<br />
                  <span className={validPassword.is_password_match ? 'circle success_bg' : 'circle'}></span>Passwords match<br />
                </div>

                <div className="text-right"> <button
                  type="submit"
                  className={!is_btn_active ? 'disable btn btn-secondary' : 'btn btn-secondary'}
                  disabled={!is_btn_active}>Sign Up</button>
                </div>
              </form>
            </div>
          </div>
          <RightSlider />
          {
            loader ?
              <div className='loader_wrapper'>
                <div className='loader07'></div>
              </div>
              : ''
          }
        </div>
      </div>


    );
  }

  return render();
}

export default Register;