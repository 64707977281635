import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TerritoryTable from "./Territory.Table";
import ChemicalTable from "./Chemical.Table";
import InvoiceReconciliationTable from "./InvoiceReconciliation.Table";
import CaptureLeadTable from "./CaptureLead.Table";
import ExpiringCustomerPaymentMethodTable from "./ExpiringCustomerPaymentMethod.Table";
import AgingSummaryTable from "./AgingSummary.Table";
import WorkOrderReportTable from "./WorkOrderReport.Table";
import ChemicalReportPricingTable from "./ChemicalReportPricing.Table"
import { useReport } from "../../Hooks/Report.Hook";
import TechnicianNotesTable from "./TechnicianNotes.Table";

const ReportTable = () => {
  const { id } = useParams();
  const pagination =
    id === "invoice_reconciliation_reports" || "in_territory_revenue_reports"
      ? false
      : true;
  const [active_pagination, setActive_pagination] = useState("15");
  const { reportData, setPageSize } = useReport();

  const setPageLimit = (custom_limit) => {
    setPageSize(parseInt(custom_limit));
    setActive_pagination(custom_limit);
  };

  const selectReportTable = (id) => {
    switch (id) {
      case "in_territory_revenue_reports": {
        return <TerritoryTable />;
      }
      case "chemical_reports": {
        return <ChemicalTable />;
      }
      case "invoice_reconciliation_reports": {
        return <InvoiceReconciliationTable />;
      }
      case "captured_lead_reports": {
        return <CaptureLeadTable />;
      }
      case "expiring_customer_payment_method_report": {
        return <ExpiringCustomerPaymentMethodTable />;
      }
      case "aging_summary_reports": {
        return <AgingSummaryTable />;
      }
      case "work_order_report": {
        return <WorkOrderReportTable />;
      }
      case "chemical_report_pricing": {
        return <ChemicalReportPricingTable />;
      }
      case "tech_notes": {
        return <TechnicianNotesTable />;
      }
      default: {
        return;
      }
    }
  };

  return (
    <>
      {selectReportTable(id)}{" "}
      {pagination && reportData.length > 0 ? (
        <ul className="pagination_page_size">
          <li>
            <span>Show:</span>
          </li>
          <li>
            <span
              onClick={() => {
                setPageLimit("15");
              }}
              className={active_pagination === "15" ? "active" : ""}
            >
              15
            </span>
          </li>
          <li>
            <span
              className={active_pagination === "30" ? "active" : ""}
              onClick={() => {
                setPageLimit("30");
              }}
            >
              30
            </span>
          </li>
          <li>
            <span
              className={active_pagination === "60" ? "active" : ""}
              onClick={() => {
                setPageLimit("60");
              }}
            >
              60
            </span>
          </li>
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};

export default ReportTable;
