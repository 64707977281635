import { CloseOutlined } from "@ant-design/icons";
import { Drawer, message, Select } from "antd";
import { filter, isEmpty, map } from "lodash";
import React, { Component } from "react";
import * as yup from "yup";
import Loader from "../../../Components/Loader";

const { Option } = Select;

class UpdateBlockList extends Component {
  state = {
    blockList: {},
    userList: {},
    name: "",
    reason: "",
    loader: false,
    is_btn_active: false,
    addUser: {},
    deleteList: [],
    errors: {},
  };

  componentDidMount() {
    const { customerId, getBlockList, getUserList } = this.props;

    if (customerId) {
      this.setState({ loader: true });
      getBlockList({ user__uuid: customerId }); // get block list
      getUserList({ is_active: true }); // get staff user list
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { blockList, addUser, userList } = this.state;
    // message.destroy();
    this.setState({ loader: false });
    // get block list
    if (Object.keys(nextProps?.blockList?.data).length > 0
      && JSON.stringify(nextProps?.blockList?.data) !== JSON.stringify(blockList)) {
      this.setState({ blockList: nextProps?.blockList?.data });
    }

    // add block list
    if (Object.keys(nextProps?.blockList?.addUser).length > 0
      && JSON.stringify(nextProps?.blockList?.addUser) !== JSON.stringify(addUser)) {
      this.setState({
        addUser: nextProps?.blockList?.addUser,
        blockList: { ...blockList, results: [...blockList.results, nextProps?.BlockList?.addUser] },
      });
    }

    // get user list
    if (Object.keys(nextProps?.userList?.data).length > 0
      && JSON.stringify(nextProps?.userList?.data) !== JSON.stringify(userList)) {
      this.setState({ userList: nextProps?.userList?.data });
    }
  };

  onClear = () => {
    this.setState({
      name: "",
      reason: "",
      errors: {},
    });
  };

  // on user detail change
  onTextChange = (e, type) => {
    const value = type === "bool" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  onChange = (index) => {
    const { blockList, deleteList } = this.state;
    deleteList.push(blockList.results[index].uuid);
    blockList.results.splice(index, 1);
    this.setState({ blockList, deleteList });
  };

  // validation
  validate = () => {
    const { name, reason } = this.state;
    setTimeout(() => {
      const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        reason: yup.string().required("Reason is required"),
      });

      const data = { name, reason };
      schema.validate(data, { abortEarly: false }).then(() => {
        this.setState({ is_btn_active: true, errors: {} });
        this.onBlockUser();
      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        this.setState({ errors: err_data, is_btn_active: false });
      });
    }, 500);
  };

  onBlockUser = () => {
    const { customerId } = this.props;
    const { name, reason, userList, blockList } = this.state;

    const detail = userList.results.filter(obj => obj.uuid === name)[0];
    blockList.results.push({
      blocked_user: name, reason, user: customerId,
      blocked_user_details: {
        uuid: name,
        first_name: detail?.first_name,
        last_name: detail?.last_name,
        description: detail?.description,
        designation: detail?.designation,
        role: detail?.role,
        email: detail?.email,
      },
    });
    this.onClear();
  };

  // on update
  onUpdate = () => {
    const { addBlockUser, customerId, getBlockList, closeDrawer, deleteBlockUser } = this.props;
    const { blockList, deleteList, errors } = this.state;
    let arr = [];
    map(blockList?.results, obj => {
      if (!obj.uuid) arr.push(addBlockUser(obj));
    });
    map(deleteList, uuid => arr.push(deleteBlockUser(uuid)));

    if (arr.length > 0) {
      this.setState({ loader: true });
      Promise.all(arr).then((response) => {
        if (!isEmpty(response)) {
          closeDrawer();
          setTimeout(() => getBlockList({ user__uuid: customerId }), 1000);
        }
      }).catch(() => message.error("Something went wrong"));
    } else {
      errors["general"] = "No changes has been detected.";
      this.setState({ errors });
    }
  };

  render() {
    const { openDrawer, closeDrawer } = this.props;
    const { blockList, loader, userList, name, reason, errors } = this.state;

    const blacklistedIds = map(blockList?.results || [], data => data?.blocked_user) || [];
    const userData = filter(userList?.results || [], user => !blacklistedIds.includes(user?.uuid));
    return (
      <Drawer
        placement={"right"}
        closable={false}
        onClose={closeDrawer}
        visible={openDrawer}
        key={"right"}
        width={"400"}
        className="drawer-wrapper"
      >
        <div className="rdrawer filter_drawer_wrapper">
          {loader && <Loader/>}
          <div className="card-row flex-column">
            <div className="card-full">
              <div className="flex ai-center mb-30">
                <h4 className="mb-0">Block List</h4>
                <CloseOutlined onClick={closeDrawer}/>
              </div>
              {/* block List */}
              {map(blockList?.results, (item, index) => {
                return (
                  <div key={`blockUser${item?.uuid}`}>
                    <div className="flex">
                      <label><b>{item?.blocked_user_details ? `${item?.blocked_user_details?.first_name} ${item?.blocked_user_details?.last_name} ${item?.blocked_user_details?.designation ? `, ${item?.blocked_user_details?.designation}` : ""}` : ""}</b></label>
                      <a className="red" href={"/#"} onClick={(e) => {
                        e.preventDefault();
                        this.onChange(index);
                      }}><u>Remove</u></a>
                    </div>
                    <p>{item?.reason}</p>
                  </div>
                );
              })}

              <h4>Block User</h4>
              <div className="form-group">
                <div className="fitem">
                  <div className={"flex"}>
                    <div>
                      <label>Name</label>
                    </div>
                    <div>
                      <a className="success float-right" href={"/#"} onClick={(e) => {
                        e.preventDefault();
                        this.onClear();
                      }}><u>Clear</u></a>
                    </div>
                  </div>
                  <Select
                    id={"name"}
                    showArrow={true}
                    name="name"
                    placeholder="Please Select"
                    onChange={(value) => this.onTextChange({ target: { name: "name", value: value } })}
                    value={name || undefined}
                    virtual={false}
                  >
                    {map(userData, (obj) => {
                      if (obj.first_name) {
                        return (
                          <Option key={obj.uuid} value={obj.uuid}>{`${obj.first_name} ${obj.last_name}`}</Option>);
                      }
                    })}
                  </Select>
                  {errors?.["name"] ?
                    <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.["name"]}</label> : ""}
                </div>

                <div className="fitem">
                  <label htmlFor={"reason"}>Reason</label>
                  <textarea
                    id={"reason"}
                    rows="3"
                    name="reason"
                    type="text"
                    placeholder={"Please give a reason as to why you are blocking this user from this customer."}
                    value={reason}
                    onChange={(e) => this.onTextChange(e)}/>
                  {errors?.["reason"] ?
                    <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.["reason"]}</label> : ""}
                </div>
                <button
                  // type="submit"
                  className={`btn btn-full btn-danger btn-bdr`}
                  // disabled={!is_btn_active}
                  onClick={() => this.validate()}
                >
                  Block User
                </button>
              </div>

              {errors?.["general"] ? (
                <div className="new_err_msg mt-10">
                  <i className="las la-info-circle"/>
                  {errors.general}
                </div>
              ) : ""}
            </div>
          </div>

          <div className="footer">
            <button className={`btn btn-bdr`} onClick={closeDrawer}>Cancel</button>
            <button
              className={`btn btn-warning`}
              onClick={() => this.onUpdate()}>
              Update
            </button>
          </div>
        </div>
      </Drawer>
    );
  }
}

export default UpdateBlockList;
