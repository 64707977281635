import React from "react";
import { Modal } from "antd";

export const WorkOrderFormModalCalendarContainer = (props) => {
  const {
    selectedEvent,
    woModalOpen,
    setWOModalOpen,
    handleWOSave,
    windowSize,
  } = props;
  window.document.addEventListener("handleCloseEvent", handleCloseEvent, false);

  function handleCloseEvent(e) {
    setWOModalOpen(false);
  }

  return (
    <Modal
      title={`Work Order Form ${selectedEvent?.original ? selectedEvent?.original?.title : selectedEvent?.title}`}
      open={woModalOpen}
      onOk={() => setWOModalOpen(false)}
      onCancel={() => setWOModalOpen(false)}
      destroyOnClose
      className="schedule_wo_modal"
      style={{
        top: 20,
      }}
      footer={null}
      onResponse={handleWOSave}
      maskClosable={false}
    >
      <iframe
        src={
          selectedEvent?.original
            ? selectedEvent?.original?.job_forms?.[0]?.form_url.replace(
                "https://crm.qa.poolops.com",
                "https://asp.umo.chat"
              )
            : selectedEvent?.job_forms?.[0]?.form_url.replace(
                "https://crm.qa.poolops.com",
                "https://asp.umo.chat"
              )
        }
        width={"100%"}
        height={windowSize.current[1] * 0.8}
        title="testa"
      />
    </Modal>
  );
};
