import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import moment from "moment-timezone";
import Proposalcreate from "./Proposal";
import BaseJobFormProposal from "./BasejobProposal";
import ProposalPreview from "./ProposalPreview";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getServiceMatrix } from "../../../Action/Customer";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import Loader from "../../../Components/Loader";
import {
  flag_state,
  proposal_page,
  closeBaseJobForm,
  resetState,
  due_date_select,
  email_notes,
  service_notes,
  serviceMatrixApi,
  proposalGetResponseData,
  addName,
  showPreview,
  setServiceAddress,
  addServiceTypeName,
  proposalPoolType,
  selectRateType,
  addSize,
  addPoolNumber,
  addNumberProperties,
  additionalNotes,
  saveAndNew,
  resetRedirectState,
  proposal_number,
  prefered_date,
  edit_mode,
  proposalFrequency,
  datepicker_statee,
  selectServiceOption,
  addServiceType,
  addProposalConNotes,
  selectServiceSubOption,
  additionalWork,
  showLoader,
  addressoptionPool,
  addressoption
} from "../../../Store/ProposalDataSlice";
import { getproposalsetup } from "../../../Action/Franchise";
import { createProposal, getProposalInfo } from "../../../Action/Proposals";
import UserProposalInfo from "./UserAndProposal";
import ArrowStatus from "./ArrowProposal";
import * as yup from "yup";
import { map, cloneDeep} from "lodash";
import "../../../Assets/css/proposalBase.css";
import chevronLeft from "../../../Assets/images/chevron-left.png";
import { getProposalInfoFromService, setProposalAdditionalWorkInfo } from "../../../Action/Proposals";
import { saveandsendproposal } from "../../../Action/Proposals";
import { sendEmailProposal } from "../../../Action/Proposal";

const ProposalFormPage = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [errors, setErrors] = useState("");
  const [loader, setLoader] = useState(false);

  const [matrixdata, setmatroixdata] = useState([]);
  useEffect(() => {
    dispatch(due_date_select(moment(new Date()).format("YYYY-MM-DD")));
  }, []);
  useEffect(() => {
    if (state?.record?.from == "ProposalView") {
      redirectFromViewProposal();
      dispatch(edit_mode(true));
      dispatch(flag_state(true));
      dispatch(proposal_page(false));
      clearFromNavKey();
    } else if (state?.record?.from == "ProposalEdit") {
      redirectFromViewProposal();
      dispatch(datepicker_statee(true));
      dispatch(flag_state(true));
      dispatch(proposal_page(false));
      clearFromNavKey();
    }
    else if (state?.record?.from == "ProposalDuplicate") {
      redirectFromViewProposal();
      dispatch(datepicker_statee(true));
      dispatch(flag_state(true));
      dispatch(proposal_page(false));
      clearFromNavKey();
    }
  }, [state?.record]);

  useEffect(() => {
    if (proposals.redirect_proposal == "Service-Redirect") {
      setLoader(true)
      getProposalInfodata();
      getproposaldata();
      dispatch(flag_state(true));
      dispatch(proposal_page(false));
      setLoader(false);

    }
  }, [proposals?.redirect_proposal]);
  const clearFromNavKey = () => {
    navigate('.', {
      replace: true,
      state: {
        ...state,
        record: {
          ...state.record,
          from: ''
        }
      }
    });
  };
  const getProposalInfodata = async () => {
    const uuid = proposals?.proposal_key;
    const result = await getProposalInfoFromService(uuid);
    dispatch(addName(result.pool_name));
    dispatch(setServiceAddress(result.service_address));
    dispatch(proposalPoolType(result?.pool_type));
    dispatch(addServiceTypeName(result?.service_type));
    dispatch (addServiceType(result?.service_type_uuid))
    if (result?.is_flat_rate == true) {
      dispatch(selectRateType("Flat_Rate"));
    } else {
      dispatch(selectRateType("Standard"));
    }
    dispatch(addSize(result?.pool_size));
    dispatch(selectServiceOption(result?.work_type));
    dispatch(addressoptionPool(result?.pool_name));
    dispatch(addPoolNumber(result?.number_of_pools));
    dispatch(addNumberProperties(result?.number_of_properties));
    dispatch(due_date_select(result?.proposal_date));
    dispatch(email_notes(result?.email_notes,));
    dispatch(addressoption(result?.service_address?.formatted_address));
    dispatch(proposal_number(result?.proposal_number));
    dispatch(service_notes(result?.service_agreement_notes));
    dispatch(additionalNotes(result?.additional_notes));
    dispatch(proposalPoolType(result?.pool_type_uuid));
    dispatch(proposalFrequency(result?.frequency));
    dispatch(selectServiceOption(result?.work_type_uuid))
    dispatch(selectServiceSubOption(result?.service_type_uuid));
    dispatch(addProposalConNotes(result?.notes));
    dispatch(prefered_date(result?.preferred_start_date));

    if (result.additional_work.length > 0) {
      dispatch(additionalWork(result.additional_work));
    }
    dispatch(edit_mode(true));
  };
  const apiresonse = async () => {
    let fuuid = props.customer.field_office.uuid;
    const data = await getServiceMatrix(fuuid);
    dispatch(serviceMatrixApi(data));
  };
  const getproposaldata = async () => {
    let fuuid = props.customer.field_office.uuid;
    const result = await getproposalsetup(fuuid);
    dispatch(proposalGetResponseData(result));
    dispatch(email_notes(result?.email_notes,));
    dispatch(service_notes(result?.service_agreement_notes));
  }
  const onnewproposalclick = async () => {
    let fuuid = props?.customer?.field_office.uuid;
    const data = await getServiceMatrix(fuuid);
    setmatroixdata(data);
    dispatch(flag_state(true));
    dispatch(proposal_page(false));
    getproposaldata();
  };
  const backToAllProposal = () => {
    dispatch(closeBaseJobForm(false));
    dispatch(proposal_page(true));
    dispatch(flag_state(false));
    dispatch(resetState());
  };

  const redirectFromViewProposal = () => {
    dispatch(
      service_notes(state?.record?.proposalData?.service_agreement_notes)
    );
    dispatch(email_notes(state?.record?.proposalData?.email_notes));
    dispatch(setServiceAddress(state?.record?.proposalData?.service_address));
    dispatch(due_date_select(state?.record?.proposalData?.proposal_due_date));
    dispatch(additionalNotes(state?.record?.proposalData?.additional_notes));
    dispatch(proposal_number(state?.record?.proposalData?.proposal_number));
    dispatch(addPoolNumber(state?.record?.proposalData?.number_of_pools));
    dispatch(
      addNumberProperties(state?.record?.proposalData?.number_of_properties)
    );
    dispatch(addSize(state?.record?.proposalData?.pool_size));
    dispatch(selectServiceOption(state?.record?.proposalData?.work_type));
    dispatch(proposalPoolType(state?.record?.proposalData?.pool_type_uuid));
    dispatch(addName(state?.record?.proposalData?.pool_name));
    dispatch(addServiceTypeName(state?.record?.proposalData?.service_type));
    if (state?.record?.proposalData?.service_type=="service") {
      dispatch(addServiceType(state?.record?.proposalData?.mainServiceTypeUuid));
    } else {
      dispatch(addServiceType(state?.record?.proposalData?.service_type_uuid));
    }   
     dispatch(addressoptionPool(state?.record?.proposalData?.pool_name));
    dispatch(additionalNotes(state?.record?.proposalData?.additional_notes))
    dispatch(addressoption(state?.record?.proposalData?.service_address?.formatted_address));
    if (state?.record?.proposalData?.is_flat_rate == true) {
      dispatch(selectRateType("Flat_Rate"));
    } else {
      dispatch(selectRateType("Standard"));
    }
    dispatch(proposalFrequency(state?.record?.proposalData?.frequency));
    dispatch(prefered_date(state?.record?.proposalData?.preferred_start_date));
    dispatch(addProposalConNotes(state?.record?.proposalData?.notes));
      dispatch(selectServiceOption(state?.record?.proposalData?.work_type_uuid        ))
      dispatch(selectServiceSubOption(state?.record?.proposalData?.service_type_uuid))
    if (state?.record?.proposalData.additional_work.length > 0) {
      dispatch(additionalWork(state?.record?.proposalData.additional_work));
    }
  };

  const seachvalidate = (param) => {
    const service_type_uuid = proposals?.service_type_uuid;
    const select_rate = proposals?.select_rate;
    const pool_name = proposals?.pool_name;
    const frequency = proposals?.frequency;
    const pool_type_uuid = proposals?.pool_type_uuid;
    const address = proposals?.address;
    const work_type = proposals?.work_type;
    const work_subtype = proposals?.work_subtype;
    const preferred_start_date = proposals?.preferred_start_date;

    setTimeout(() => {
      const schema = yup.object().shape({
        service_type_uuid: yup.string().required("Service Type Is Required."),
        select_rate:
          proposals?.service_type_name === "maintenance"
            ? yup.string().required("Rate Type Is Required.")
            : null,
        pool_name: yup.string().required("Pool Name Is Required."),
        pool_type_uuid: yup.string().required("Pool Type  Is Required."),
        frequency:
          (proposals?.service_type_name === "maintenance" ||
            proposals?.service_type_name === "service") &&
          yup.string().required("Frequency Is Required."),
        work_type:
          proposals?.service_type_name === "service"
            ? yup.string().required("Service Type Is Required.")
            : "",
        work_subtype:
          (proposals?.service_type_name === "service" && proposals.work_type == "79684949-e6b4-3f6d-a716-122a64220873" || proposals.work_type == "48920339-fb03-3675-be97-78b6bb7fa44e")
            ? yup.string().required("Work Sub Type Is Required.")
            : "",
        preferred_start_date:
          proposals?.service_type_name !== "maintenance" &&
            proposals?.service_type_name !== "service"
            ? yup.string().required("Preferred Start Date is required.")
            : null,
        address: yup.object().shape({
          address: yup.string().required("Address is required"),
          state: yup.string().required("State is required"),
          city: yup.string().required("City is required"),
          zip_code: yup.string().required("Zipcode is required"),
        }),
      });
      const data = {
        service_type_uuid,
        select_rate,
        pool_name,
        frequency,
        pool_type_uuid,
        work_subtype,
        work_type,
        address,
        preferred_start_date,
      };
      let IsValid;
      let err_data = {};
      schema.isValid(data).then(function (valid) {
        IsValid = valid;
      });
      setTimeout(() => {
        if (IsValid) {
          setErrors("");
          if (proposals.proposal_number == "") {
            saveAndSend(param);
          } else if (state?.record?.proposalData?.uuid!=="") {
            sendAndSaveonbuttonclick();
          } 
        } else {
          schema.validate(data, { abortEarly: false }).catch(function (err) {
            map(err.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setErrors(err_data);
            console.log("err_data:", err_data);
          });
        }
      }, 100);
    }, 300);
  };

  const previewvalidate = () => {
    const service_type_uuid = proposals?.service_type_uuid;
    const select_rate = proposals?.select_rate;
    const pool_name = proposals?.pool_name;
    const frequency = proposals?.frequency;
    const pool_type_uuid = proposals?.pool_type_uuid;
    const address = proposals?.address;
    const work_type = proposals?.work_type;
    const work_subtype = proposals?.work_subtype;
    const preferred_start_date = proposals?.preferred_start_date;
    const additional_work = proposals?.additional_work;
    const email_notes = proposals?.email_notes;
    const service_agreement = proposals?.service_agreement;

    setTimeout(() => {
      const schema = yup.object().shape({
        service_type_uuid: yup.string().required("Service Type Is Required."),
        select_rate:
          proposals?.service_type_name === "maintenance"
            ? yup.string().required("Rate Type Is Required.")
            : null,
        pool_name: yup.string().required("Pool Name Is Required."),
        pool_type_uuid: yup.string().required("Pool Type  Is Required."),
        frequency:
          (proposals?.service_type_name === "maintenance" ||
            proposals?.service_type_name === "service") &&
          yup.string().required("Frequency Is Required."),
        work_type:
          proposals?.service_type_name === "service"
            ? yup.string().required("Service Type Is Required.")
            : "",
        work_subtype:
          proposals?.service_type_name === "service"
            ? yup.string().required("Work Sub Type Is Required.")
            : "",
        preferred_start_date:
          proposals?.service_type_name !== "maintenance" &&
            proposals?.service_type_name !== "service"
            ? yup.string().required("Preferred Start Date is required.")
            : null,
        additional_work: yup.array().min(1, "Additional work is required."),
        address: yup.object().shape({
          address: yup.string().required("Address is required"),
          state: yup.string().required("State is required"),
          city: yup.string().required("City is required"),
          zip_code: yup.string().required("Zipcode is required"),
        }),
        email_notes: yup.string().required("Email notes is Required."),
        service_agreement: yup
          .string()
          .required("Service Agreement Is Required."),
      });
      const data = {
        service_type_uuid,
        select_rate,
        pool_name,
        frequency,
        pool_type_uuid,
        work_subtype,
        work_type,
        address,
        preferred_start_date,
        additional_work,
        email_notes,
        service_agreement,
      };
      let IsValid;
      let err_data = {};
      schema.isValid(data).then(function (valid) {
        IsValid = valid;
      });
      setTimeout(() => {
        if (IsValid) {
          setErrors("");
          dispatch(showPreview(true));
        } else {
          schema.validate(data, { abortEarly: false }).catch(function (err) {
            map(err.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setErrors(err_data);
            console.log("err_data:", err_data);
          });
        }
      }, 100);
    }, 300);
  };

  const saveAndSend = async (param) => {
    setLoader(true);
    let serviceTypeUUID = null;
    if (proposals?.work_type == "79684949-e6b4-3f6d-a716-122a64220873") {
      serviceTypeUUID = proposals?.work_subtype
    } else if (proposals?.work_type == "48920339-fb03-3675-be97-78b6bb7fa44e") {
      serviceTypeUUID = proposals?.work_subtype
    } else if (proposals?.service_type_uuid === "8a300258-bbe0-34f3-9d3e-ca6ab16f0000" && !proposals?.work_subtype) {
      serviceTypeUUID = proposals?.work_type
    }
    const payload = {
      field_office_uuid: props.customer.field_office.uuid,
      client_uuid: props.customer.uuid,
      proposal_number: "",
      proposal_due_date: moment(proposals.proposal_due_date).format("YYYY-MM-DD"),
      proposal_date: moment(proposals.proposal_due_date).format("YYYY-MM-DD"),
      service_type_uuid: proposals?.service_type_uuid === "8a300258-bbe0-34f3-9d3e-ca6ab16f0000" ? serviceTypeUUID : proposals.service_type_uuid,
      work_type: proposals?.work_type,
      work_subtype: proposals?.work_subtype,
      frequency: proposals?.frequency,
      number_of_properties: proposals?.number_of_properties,
      number_of_pools: proposals?.number_of_pools,
      address_option_pool: proposals.address_option_pool,
      pool_name: proposals?.pool_name,
      pool_size: proposals.pool_size,
      pool_type_uuid: proposals.pool_type_uuid,
      is_flat_rate: proposals.select_rate === "Flat_Rate" ? true : false,
      proposal_status: "active",
      service_address: { ...proposals.address },
      additional_notes: proposals.additional_notes,
      email_notes: proposals.email_notes,
      service_agreement_notes: proposals.service_agreement,
      client_type: props.customer.customer_type,
      preferred_start_date: proposals.preferred_start_date ? proposals.preferred_start_date : null,
      notes: proposals.notes
    };
    console.log("payload--->>>", payload);
    const response = await createProposal(payload);
    message.success("Proposal Saved Succesfully", 10);
    setLoader(false);
    setTimeout(() => {
      dispatch(resetState());
    }, 2000);
    console.log("response createProposal--->>>", response);
    const newAdditional = proposals.additional_work.map(v => ({...v, proposal_uuid: response.uuid}))
    const additionalParam = {
      "additional_work": newAdditional,
      "update_additional_work": [],
      "delete_additional_work": []
    }
    const setAdditionalWork = await setProposalAdditionalWorkInfo(response.uuid, additionalParam);
    console.log('setAdditionalWork', setAdditionalWork)
    let proposalPayload = {
      field_office__uuid: props.customer.field_office.uuid,
    };
    const getProposalresponse = await getProposalInfo(proposalPayload);    
    console.log("getProposalresponse--->>>", getProposalresponse);
    if (param == "Save and New") {
      apiresonse();
      // dispatch(saveAndNew());
      dispatch(flag_state(true));
      getproposaldata();
    }
    if (param == "Save and Close") {
      dispatch(resetState());
      props.tabChange(0);
    }
    // if(param="Save and Send"){
    //   const result = await sendEmailProposal(state?.record?.proposalData?.uuid);
    //       message.success("Proposal saved and emailed successfully");

    // }
  };


  const sendAndSaveonbuttonclick = async () => {
setLoader(true);
    let serviceTypeUUID = null;
    if (proposals?.work_type == "79684949-e6b4-3f6d-a716-122a64220873") {
      serviceTypeUUID = proposals?.work_subtype;
    } else if (proposals?.work_type == "48920339-fb03-3675-be97-78b6bb7fa44e") {
      serviceTypeUUID = proposals?.work_subtype;
    } else if (
      proposals?.service_type_uuid === "8a300258-bbe0-34f3-9d3e-ca6ab16f0000" &&
      !proposals?.work_subtype
    ) {
      serviceTypeUUID = proposals?.work_type;
    }
    const payload = {
      // proposal_number: "",
      proposal_due_date: moment(proposals.proposal_due_date).format(
        "YYYY-MM-DD"
      ),
      proposal_date: moment(proposals.proposal_due_date).format("YYYY-MM-DD"),
      service_type_uuid:
        proposals?.service_type_uuid === "8a300258-bbe0-34f3-9d3e-ca6ab16f0000"
          ? serviceTypeUUID
          : proposals.service_type_uuid,
      work_type: proposals?.work_type,
      work_subtype: proposals?.work_subtype,
      frequency: proposals?.frequency,
      number_of_properties: proposals?.number_of_properties,
      number_of_pools: proposals?.number_of_pools,
      address_option_pool: proposals.address_option_pool,
      pool_name: proposals?.pool_name,
      pool_size: proposals.pool_size,
      pool_type_uuid: proposals.pool_type_uuid,
      is_flat_rate: proposals.select_rate === "Flat_Rate" ? true : false,
      service_address: { ...proposals.address },
      additional_notes: proposals.additional_notes,
      email_notes: proposals.email_notes,
      service_agreement_notes: proposals.service_agreement,
      preferred_start_date: proposals.preferred_start_date
        ? proposals.preferred_start_date
        : null,
      notes: proposals.notes,
    };
    const result = await saveandsendproposal(
      state?.record?.proposalData?.uuid,
      payload
    );

    const update_services_lineitem_temp  = cloneDeep(proposals.update_additional_work);
    for (let index = update_services_lineitem_temp.length - 1; index >= 0; index--) {
      const exist = proposals.additional_work.find((sl)=> {return sl.repair_code == update_services_lineitem_temp[index].repair_code})
      if(exist){
        update_services_lineitem_temp.splice(index, 1);
      }
    }
    const additionalParam = {
      "additional_work": proposals.additional_work,
      "update_additional_work": update_services_lineitem_temp,
      "delete_additional_work": proposals.delete_additional_work
    }
    const setAdditionalWork = await setProposalAdditionalWorkInfo(state?.record?.proposalData?.uuid, additionalParam);
    message.success("Proposal Updated Succesfully", 10);
    setLoader(false);
    // dispatch(resetState());


  };
const saveandsendkeyapi = async () => {
  result = await sendEmailProposal(state?.record?.proposalData?.uuid);
};
  return (
    <div className="propsal-base-tab">
           {loader && <Loader />}

      {proposals.flag_state != true && proposals.proposal_page == true && (
        <Proposalcreate
          errors={errors}
          onnewproposalclick={onnewproposalclick}
          customer={props.customer}
        />
      )}
      {proposals.flag_state == true && proposals.base_form == true && (
        <>
          <div className="filter_wrapper">
            <ul className="breadcrumb">
              <li className={"cursor_pointer"}>
                <span
                  onClick={() => {
                    backToAllProposal();
                  }}
                >
                  <img className="proposal-leftIcon" src={chevronLeft} alt="" />
                  Back to Proposals
                </span>
              </li>
            </ul>
          </div>
          <div className="proposal-form-container">
            <ArrowStatus
              state={state?.record?.proposalData}
              customer={props.customer}
              tabChange={props.tabChange}
              editMode={proposals.isViewOnly}
            />
            <UserProposalInfo
              customer={props.customer}
              editMode={proposals.isViewOnly}
            />
          </div>
          <BaseJobFormProposal
            state={state?.record}
            errors={errors}
            customer={props.customer}
            matrixdata={matrixdata}
            seachvalidate={seachvalidate}
            previewvalidate={previewvalidate}
            editMode={proposals.isViewOnly}
            tabChange={props.tabChange}

          />
          <Modal
            open={proposals.showPreview}
            className="proposal_preview"
            width={'80%'}
            footer={[
              <button
                key="cancel"
                className="btn btn-brand btn-bdr"
                onClick={() => dispatch(showPreview(false))}
              >
                Close
              </button>,
            ]}
          >
            <ProposalPreview customer={props.customer} />
          </Modal>
        </>
      )}
    </div>
  );
};
export default ProposalFormPage;