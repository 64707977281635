import React, { useEffect } from "react";
import { Table } from 'antd';
import _ from "lodash";
import Loader from "../../Components/Loader";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import PriceCatalogFilter from "./PriceCatalogFilter";
import { getPriceCatalogApiReq } from '../../Action/PriceCatalog';
import { addPriceCatalogFilter, setPriceCatalogField, setPriceCatalogModalState, priceCatalogDeletionModal } from "../../Store/priceCatalogDataSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";
import DataTable from "../Common/DataTable";
import DeletePriceCatalogModal from "./Modals/DeletePriceCatalogModal";
import AddNewItemPriceCatalouge from "./Modals/AddNewItemPriceCatalogeDrawer";

const PriceCatalogContainer = () => {
  const dispatch = useDispatch();
  const { priceCatalogList, filter, loader } = useSelector((state) => state.priceCatalog);

  useEffect(() => {
    if (filter.field_office__uuid)
      getPriceCatalog();
  }, [filter.field_office__uuid, filter.part_type, filter.search, filter.ordering, filter.pageSize, filter.current])

  const getPriceCatalog = async () => {
    dispatch(setPriceCatalogField({ loader: true }));
    const response = await getPriceCatalogApiReq(filter);
    dispatch(setPriceCatalogField({ loader: false, priceCatalogList: response.results, filter: { ...filter, total: response.count } }));
  }

  const handleRowSelection = (priceCatalogItem) => {
    dispatch(setPriceCatalogField({ priceCatalogItem }));
  }

  const accountPagintaion = (pagination, filter_filler, sorter) => {
    dispatch(addPriceCatalogFilter({
      pageSize: pagination.pageSize,
      current: pagination.current,
      ordering: sorter.column ? (sorter.order === "ascend" ? `${sorter.field}` : `-${sorter.field}`) : '',
    }));
  };

  const setMessagePageLimit = (custom_limit) => {
    dispatch(addPriceCatalogFilter({ pageSize: custom_limit, current: 1 }))
  };

  const handleViewModal = (rowData) => {
    dispatch(setPriceCatalogModalState({ show: true, type: 'view', data: rowData }))
  }

  const handleEditModal = (rowData) => {
    dispatch(setPriceCatalogModalState({ show: true, type: 'edit', data: rowData }))
  }

  const handleDeleteModal = (rowData) => {
    dispatch(priceCatalogDeletionModal({ show: true, data: rowData }))
  }

  return (
    <div >
      {loader && <Loader />}
      <PriceCatalogFilter />
      <div className="price-catalog-table-wraper">
        <Table
          dataSource={priceCatalogList || []}
          onChange={accountPagintaion}
          pagination={{ ...filter }}
          columns={DataTable.CatalogUser(handleViewModal, handleEditModal, handleDeleteModal)}
          showSorterTooltip={true}
          className="customer-list"
          scroll={{ x: 'auto' }}
          onRow={(e) => ({
            onClick: () => handleRowSelection(e),
          })}
        />
      </div>
      {priceCatalogList?.length > 0 ? (
        <ul className="price_catalog_pagination pagination_page_size" >
          <li>
            <span>Show:</span>
          </li>
          <li>
            <span
              onClick={() => {
                setMessagePageLimit("15");
              }}
              className={filter.pageSize === "15" ? "active" : ""}
            >
              15
            </span>
          </li>
          <li>
            <span
              className={filter.pageSize === "30" ? "active" : ""}
              onClick={() => {
                setMessagePageLimit("30");
              }}
            >
              30
            </span>
          </li>
          <li>
            <span
              className={filter.pageSize === "60" ? "active" : ""}
              onClick={() => {
                setMessagePageLimit("60");
              }}
            >
              60
            </span>
          </li>
        </ul>
      ) : (
        ""
      )}
      <AddNewItemPriceCatalouge />
      <DeletePriceCatalogModal />
    </div>
  )
}

export default PriceCatalogContainer;
