import React, {Component} from "react";
import {Empty} from "antd";

class EmptyData extends Component {
  render() {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{margin: "0px", ...this.props.style}} />
    )
  }
}

export default EmptyData;