import React from "react";
import { Row, Col, Tag } from "antd";
import { getFullName } from "./ActivityLog.Common";

const ActivityLogNote = ({ note }) => {
  const {
    technician = null,
    service_type = "",
    work_order_number = "",
    tech_notes = "",
    edit_work_order_url = "",
  } = note;
  const technicianName = getFullName(technician);

  const serviceTypeTag = (() => {
    if (!service_type) {
      return <Tag color="default">Not Assigned</Tag>;
    }
    switch (service_type.toLowerCase()) {
      case "maintenance":
        return <Tag color="#ff681d99">Maintenance</Tag>;
      case "renovation":
        return <Tag color="#005d8999">Renovation</Tag>;
      case "service":
        return <Tag color="#758f9a99">Service</Tag>;
      case "construction":
        return <Tag color="#03a9f4b0">Construction</Tag>;
      default:
        return <Tag color="default">{service_type}</Tag>;
    }
  })();

  const setWOURL = (() => {
    if (!work_order_number) {
      return <Tag color="default">N / A</Tag>;
    }
    if (!edit_work_order_url) {
      return <span>{work_order_number}</span>;
    }
    return (
      <a rel="noreferrer" href={edit_work_order_url} target="_blank">
        {work_order_number}
      </a>
    );
  })();

  return (
    <div className="note-content">
      <Row justify="space-between">
        <Col span={12} className="note-content-subtitle">
          Tech Name:
        </Col>
        <Col span={12}>
          {technicianName ? (
            technicianName
          ) : (
            <Tag color="default">Not Assigned</Tag>
          )}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={12} className="note-content-subtitle">
          Service Type
        </Col>
        <Col span={12}>{serviceTypeTag}</Col>
      </Row>
      <Row justify="space-between">
        <Col span={12} className="note-content-subtitle">
          WO/Job Form
        </Col>
        <Col span={12}>{setWOURL}</Col>
      </Row>
      <Row justify="space-between">
        <Col span={12} className="note-content-subtitle">
          Tech Notes
        </Col>
        <Col span={12}>
          {tech_notes ? tech_notes : <Tag color="default">N / A</Tag>}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityLogNote;
