import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { map, isEmpty } from "lodash";
import moment from "moment-timezone";
import { DatePicker, message, Modal, Popconfirm, Select } from "antd";
import * as yup from "yup";
import AllState from "../../Assets/data/stateList.json";
import Loader from "../../Components/Loader";
import { initialCleaningDays } from '../../Action/Franchise';

const { Option } = Select;

const CommunityInfo = forwardRef(({ inputData, onDataChange}, ref) => {
    const [errors, setErrors] = useState({});
    const [preferred_contact_methods, setContactMethods] = useState([]);
    const [properties_in_community, setCommunityPreference] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [no_of_properties, setNoOfProperties] = useState('');
    const [no_of_pools, setNoOfPools] = useState('');
    const [notes, setNote] = useState('');
    const [isBillingAddressSame, setIsBillingAddressSame] = useState(true)
    const [billingAddress, setBillingAddress] = useState({ address: '', city: '', state: '', zip_code: '', address2: '' });
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);

    const contact_methods = [
        { key: 'Text', value: 'text' },
        { key: 'Home Phone', value: 'phone' },
        { key: 'Office', value: 'office' },
        { key: 'Email', value: 'email' },
    ]
    const community_preferences = [
        { key: 'Townhomes', value: 'townhomes' },
        { key: 'Condominium', value: 'condo' },
        { key: 'Apartment/HOA', value: 'apartment' },
        { key: 'Other', value: 'detached' },
    ]

    useEffect(() => {
        if (!isEmpty(inputData)) {
            setBillingAddress(inputData.address || { address: '', city: '', state: '', zip_code: '' });
            setContactMethods(inputData.preferred_contact_methods || []);            
            setCommunityPreference(inputData.properties_in_community[0] || '');
            setCompanyName(inputData.company_name || '');
            setNoOfProperties(inputData.no_of_properties || '');
            setNoOfPools(inputData.no_of_pools || '');
            setNote(inputData.notes || '');
        }
    }, []);
    useEffect(() => {
        setData({
            ...data,
            preferred_contact_methods: preferred_contact_methods,
        });
    }, [preferred_contact_methods]);

    useEffect(() => {
        setData({
            ...data,
            properties_in_community: [properties_in_community],
        });
    }, [properties_in_community]);
    useEffect(() => {
        onDataChange(data);
    }, [data]);
    // useEffect(() => {
    //     if (isBillingAddressSame) {
    //         setBillingAddress(props.serviceInfo?.serviceArea?.value?.address);
    //         setServiceAddress(props.serviceInfo?.serviceArea?.value?.address);
    //     } else {
    //         setBillingAddress({ address: '', city: '', state: '', zip_code: '', address2: '' });
    //         setServiceAddress({ address: '', city: '', state: '', zip_code: '', address2: '' });
    //     }
    // }, [isBillingAddressSame]);

    const onAddressChanged = (e) => {
        setData({ ...data, address: [e.target.value] });
        setBillingAddress(address => ({
            ...address,
            [e.target.name]: e.target.value,
        }));
    }
    // on address change
    const onAddressChange = (e) => {
        Object.assign(billingAddress, { [e.target.name]: e.target.value })
        setBillingAddress(billingAddress);
        setData({ ...data, [e.target.name]: billingAddress });
    };
    // render all state
    const renderAllState = () => {
        return map(AllState?.AllState, (item, i) => {
            return (<Option value={item.abbreviation} key={i}>{item.name}</Option>)
        })
    }
    // on contact method changed
    const onContactMethodChange = (e) => {
        const idx = preferred_contact_methods?.indexOf(e.target.value);
        if (idx > -1) {
            setContactMethods(prev => prev.filter(fruit => fruit !== e.target.value));

        } else {
            setContactMethods(prev => [...prev, e.target.value])
        }
    };
    // on community prefference changed
    const onCommunityPreferenceChange = (e) => {    
        setCommunityPreference(e.target.value);
    };
    const onTextChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        if (e.target.name === 'company_name') {
            setCompanyName(e.target.value);
        } else if (e.target.name === 'no_of_properties') {
            setNoOfProperties(e.target.value);
        } else if (e.target.name === 'no_of_pools') {
            setNoOfPools(e.target.value);
        } else {
            setNote(e.target.value);
        }
    };
    const handleIsBillingAddressSame = () => {
        setIsBillingAddressSame(!isBillingAddressSame)
    }

    return (
        <div className="adetail">
            {loader && <Loader />}
            <h3>Community Information</h3>
            <div className="form-block">
                <div className="frow">
                    <div style={{ display: 'flex' }}><h4>How would you like us to reach out to you?</h4>{` (Select all that apply) `}</div>
                    <div className="oplist">
                        {map(contact_methods, (item, index) => {
                            const isInclude = preferred_contact_methods?.includes(item?.value);
                            return (
                                <div className="inline-checks flex-1" key={`contact_methods${index}`}>
                                    <label htmlFor={item?.value} className={isInclude ? 'active' : ''}>
                                        <input type="checkbox" id={item?.value} name='contactMethod'
                                            className="" value={item?.value} onChange={onContactMethodChange} />
                                        <span>{item?.key}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                    {errors?.[`preferred_contact_methods`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`preferred_contact_methods`]}</label> : ''}
                </div>
                <div className="frow">
                    <h4>What's in your community?</h4>
                    <div className="oplist">
                        {map(community_preferences, (item, index) => {
                            return (
                                <div className="inline-checks flex-1" key={`community_preferences${index}`}>
                                    <label htmlFor={item?.value} className={properties_in_community === item?.value ? 'active' : ''}>
                                        <input type="radio" id={item?.value} name='communityPreference'
                                            className="" value={item?.value} onChange={onCommunityPreferenceChange} />
                                        <span>{item?.key}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <hr />
                <div className="frow pt-10">
                    <h3>Primary Address</h3>
                    <div className="">
                        <span>Company Name</span>
                        <div className="form-group mb-20">
                            <input
                                type="text"
                                placeholder="Company Name"
                                name="company_name"
                                value={company_name}
                                onChange={onTextChange}
                            />
                        </div>
                        <span>Address</span>
                        <div className="form-group mb-20">
                            <div className="fitem">
                                <input
                                    type="text"
                                    placeholder="Address"
                                    name="address"
                                    value={billingAddress?.address}
                                    onChange={onAddressChanged}
                                />
                                {errors?.['billingAddress.address'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.address']}</label> : ''}
                            </div>
                            <div className="fitem">
                                <input
                                    type="text"
                                    placeholder="Address Line 2 (Optional)"
                                    name="address2"
                                    value={billingAddress?.address2 || ''}
                                    onChange={onAddressChanged}
                                />
                            </div>
                            <div className="half-fitem fitem">
                                <div className="half-fitem fitem">
                                    <input
                                        type="text"
                                        placeholder="City"
                                        name="city"
                                        value={billingAddress?.city}
                                        onChange={onAddressChanged}
                                        style={{ width: '100%' }}
                                    />
                                    {errors?.['billingAddress.city'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.city']}</label> : ''}
                                </div>
                                <div className="half-fitem fitem">
                                    <Select
                                        showArrow={true}
                                        style={{ width: '100%' }}
                                        name="state"
                                        placeholder="State"
                                        virtual={false}
                                        value={billingAddress?.state || undefined}
                                        onChange={(value) => onAddressChange({ target: { name: 'state', value: value } })}
                                    >
                                        {renderAllState()}
                                    </Select>
                                    {errors?.['billingAddress.state'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.state']}</label> : ''}
                                </div>
                                <div className="half-fitem fitem">
                                    <input
                                        style={{ width: '100%' }}
                                        type="text"
                                        className='form--control'
                                        placeholder="ZIP Code"
                                        name="zip_code"
                                        maxLength={5}
                                        minLength={5}
                                        onChange={onAddressChanged}
                                        value={billingAddress?.zip_code || ''}
                                    />
                                    {errors?.['billingAddress.zip_code'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.zip_code']}</label> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-20">
                            <div className="half-fitem fitem">
                                <div className="half-fitem fitem">
                                    <span>Number of Properties</span>
                                    <input
                                        type="text"
                                        placeholder="Number of Properties"
                                        name="no_of_properties"
                                        value={no_of_properties}
                                        onChange={onTextChange}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="half-fitem fitem">
                                    <span>Number of Pools</span>
                                    <input
                                        type="text"
                                        placeholder="Number of Pools"
                                        name="no_of_pools"
                                        value={no_of_pools}
                                        onChange={onTextChange}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-20">
                            <div className="fitem">
                                <span>Note</span>
                                <input
                                    type="text"
                                    placeholder="Note"
                                    name="notes"
                                    value={notes}
                                    onChange={onTextChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default CommunityInfo