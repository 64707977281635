import React, { useEffect, useState } from "react";
import { DatePicker, message, Row, Col } from "antd";
import moment from "moment-timezone";
import { formatToCurrency } from "../Components/Common/formatToCurrency";
import quickbook from "../Assets/images/quickbook.png";
import { getQuickbooksSync, getWorkorderCount } from "../Action/Accounting";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useEventBus } from "../Components/EventBus/useEventBus";
import { getFranchiseSelected } from "../Utils/selectedFranchise";

const AccountSidebar = ({ setData, ChangeComponent }) => {
  const navigate = useNavigate();
    const [state, setState] = useState({
        monthly_forecast: {},
        SalesByItem: [],
        filter_date: moment(),
        uuid: '',
        field_office_uuid:'',
        work_orders: {},
        loader: false,
        user_role: "",
        tabData: {}
    });

    useEventBus('FRANCHISE_UPDATED_ACCOUNTLIST', (event) => {
      const selectedFranchise  = event.detail !== "all" ? event.detail : "";
      setState({
        ...state,
        uuid: selectedFranchise,
        field_office_uuid:selectedFranchise
    })
      getWorkOrderSectionData({
        month: moment().format("MM"),
        year: moment().format("YYYY"),
        field_office__uuid: selectedFranchise
      });
    });

    const getQuickbooksSynced = async () => {
        const temp = await getQuickbooksSync(state.uuid);
        if (!isEmpty(temp?.message)) {
            message.success(temp.message, 10);
        } else {
            message.error(temp.detail, 10);
        }
    }
    const getWorkOrderData =()=>{
      navigate(`/work-orders`)
    }
    const getWorkOrderSectionData = async (data) => {
        let result = await getWorkorderCount(data);
        if (result) {
          setState((s) => ({
            ...s,
            tabData: result,
          }));
        }
      };
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"))
            ? JSON.parse(localStorage.getItem("authUser"))
            : "";
        let franchiseDefaultSelected = getFranchiseSelected()?.uuid === "all" ? "" :  getFranchiseSelected()?.uuid;

        if (user) {
            const userrole = user?.user?.user_roles?.[0]?.name;
            const uuid = user?.user?.associated_field_offices?.[0]?.uuid;
            var field_office_uuid = "";
            if(userrole == "admin"){
                 field_office_uuid = franchiseDefaultSelected;
            }else if(userrole == "franchise_owner" || userrole == "operations_mgr"){
                 field_office_uuid = franchiseDefaultSelected;
            }            
            setState({
                ...state,
                uuid: uuid,
                field_office_uuid:field_office_uuid
            })
            getWorkOrderSectionData({
                month: moment().format("MM"),
                year: moment().format("YYYY"),
                field_office__uuid:field_office_uuid
            }); 
        }
    }, []);

    const { filter_date, InvoiceOverView, monthly_forecast, work_orders, tabData, uuid, field_office_uuid} = state;
    return (
        <aside className="laside minWidth px-15">
            <h3>Accounting Summary</h3>
            {<div className="form-group">
                <div className="fitem account-date">
                    <DatePicker
                        className="swhite summry_date_picker"
                        format="MMM YYYY"
                        picker="month"
                        defaultValue={moment()}
                        onChange={(e) => {
                            setState({
                                ...state,
                                filter_date: e
                            })
                            const selected_month = moment(e).format('MM');
                            const selected_year = moment(e).format('YYYY');
                            getWorkOrderSectionData({
                                month: selected_month,
                                year: selected_year,
                                field_office__uuid:field_office_uuid
                              });  
                            // getMonthlyForecast({ created_at__month: selected_month, created_at__year: selected_year });
                            // getOverViewInvoice({ invoice_date__month: selected_month, invoice_date__year: selected_year });
                            // getWorkOrdersSummary({ scheduled_time__month: selected_month, scheduled_time__year: selected_year });
                        }}
                        placeholder=""
                        allowClear={false}
                        suffixIcon={<i className="las la-angle-down"></i>} />
                </div>
            </div> }
            {/* <div className="gray_box">
                <div className="card-body">
                    <div className="flex">
                        <div className="flex-1">
                            <h4 className="mb-0">All Invoices</h4>
                            <h6 className="mb-0 grey">{moment(filter_date).format('MMM, YYYY')}</h6>
                        </div>
                        <div className="flex-1">
                            <h2 className="text-right">{InvoiceOverView?.invoices ? `$ ${formatToCurrency(InvoiceOverView?.invoices)}` : '-'}</h2>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="label">
                            <h6>Recurring Services</h6>
                            <h6>Product Sales</h6>
                            <h6>Commission</h6>
                        </div>
                        <div className="value">
                            <h6>{InvoiceOverView?.recurring_service ? `$ ${formatToCurrency(InvoiceOverView?.recurring_service)}` : '-'}</h6>
                            <h6>{InvoiceOverView?.product_sales ? `$ ${formatToCurrency(InvoiceOverView?.product_sales)}` : '-'}</h6>
                            <h6>{InvoiceOverView?.commission ? `$ ${formatToCurrency(InvoiceOverView?.commission)}` : '-'}</h6>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <h6 className="mb-0 text-center pointer" onClick={() => { ChangeComponent(3) }}><u>Jump to Accounts Receivable Aging Summary</u></h6>
                </div>
            </div>
            <div className="gray_box">
                <div className="card-body">
                    <div className="flex">
                        <div className="flex-1">
                            <h4 className="mb-0">Sales Tax</h4>
                            <h6 className="mb-0 grey">{moment(filter_date).format('MMM, YYYY')}</h6>
                        </div>
                        <div className="flex-1">
                            <h2 className="text-right">{InvoiceOverView?.sales_tax ? `$ ${formatToCurrency(InvoiceOverView?.sales_tax)}` : '0.00'}</h2>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="label">
                            <h6>Product Tax</h6>
                            <h6>Service Tax</h6>
                        </div>
                        <div className="value">
                            <h6>{InvoiceOverView?.product_tax ? `$ ${formatToCurrency(InvoiceOverView?.product_tax)}` : '0.00'}</h6>
                            <h6>{InvoiceOverView?.service_tax ? `$ ${formatToCurrency(InvoiceOverView?.service_tax)}` : '0.00'}</h6>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <h6 className="mb-0 text-center pointer" onClick={() => { ChangeComponent(7) }} ><u>Jump to Sales Tax</u></h6>
                </div>
            </div> */}
      {/* <div className="gray_box">
                <div className="card-body">
                    <div className="flex">
                        <div className="flex-1">
                            <h4 className="mb-0">Month Forecast</h4>
                            <h6 className="mb-0 grey">{moment(filter_date).format('MMM, YYYY')}</h6>
                        </div>
                        <div className="flex-1">
                            <h2 className="text-right">{monthly_forecast?.total ? `$ ${formatToCurrency(monthly_forecast?.total)}` : '-'}</h2>
                        </div>
                    </div>
                    <p>This shows services and products fulfilled, but are due to be invoiced on the next cycle.</p>
                </div>
                <div className="card-footer">
                    <h6 onClick={() => { ChangeComponent(4) }} className="mb-0 text-center pointer"><u>Jump to Unbilled Work Report</u></h6>
                </div>
            </div> */}
      {/* Work Orders */}
      {
        <div className="gray_box">
          <div className="card-body px-20">
            <Row>
              <Col lg={12}>
                <h4 className="mb-0">Work Orders and Service Orders</h4>
                <h6 className="mb-0 grey">
                  {moment(filter_date).format("MMM, YYYY")}
                </h6>
              </Col>
              <Col lg={12}>
                <h2 className="text-right">
                  {tabData?.total ? `$ ${formatToCurrency(tabData?.total)}` : 0}
                  {/*work_orders?.total ? `$ ${formatToCurrency(work_orders?.total)}` : '-'*/}
                </h2>
              </Col>
            </Row>
            <p style={{ textAlign: "justify" }}>
              View all Work Orders and Service Orders pending to be Invoiced for
              the current month. The total refers to WO(s) and SO(s) ready to be
              Emailed or Processed.
            </p>
          </div>
          <div className="card-footer text-center">
            <button
              className='btn btn-warning creatBatchInvoice'
              onClick={() => {
                getWorkOrderData();
              }}
            >
                Create Batch Invoice
            </button>
          </div>
        </div>
      }
      {/* <div className="mt-10">
                <h6 className="mb-0">Other Reports</h6>
                <u className="pointer" onClick={() => { ChangeComponent(2) }}><b>Sales by Item Breakdown</b></u><br />
                <u className="pointer" onClick={() => { ChangeComponent(1) }}><b>Expiring Customer Payment Methods</b></u>
            </div> */}
      <div className="syncbook">
        <img src={quickbook} alt="" />
        <u
          className="mt-10 d-block pointer"
          onClick={() => {
            getQuickbooksSynced();
          }}
        >
          <b>Force Resync with Quickbooks</b>
        </u>
      </div>
    </aside>
  );
};

export default AccountSidebar;
