import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Col, Row } from "antd";
import { proposalPoolType, serviceMatrixApi } from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getServiceMatrix } from "../../../Action/Customer";
import "../../../Assets/css/proposalBase.css";

const ProposalPoolTypes = (props) => {
  console.log("Propsss",props);
  const { errors, editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <h4 className="margin-top-20 margin-bottom-10">Pool type<sup>*</sup></h4>
          <div className="oplist">
            {map(proposals?.response_data?.pool_types, (item, index) => (
              <div className="inline-checks" key={`poolType${index}`}>
                <label htmlFor={item?.uuid} className={`${proposals?.pool_type_uuid === item?.uuid ? 'active' : ''}`}>
                  <input
                    type="radio"
                    id={item?.uuid}
                    name="poolType"
                    className=""
                    value={item?.uuid}
                    disabled={editMode}
                    onChange={() => dispatch(proposalPoolType(item.uuid))}
                  />
                  <span>{item?.name}</span>
                </label>
              </div>
            ))}
          </div>
          {errors["pool_type_uuid"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["pool_type_uuid"]}
                  </label>
                ) : (
                  ""
                )}
        </div>
      </Col>
    </Row>
  );
};

export default ProposalPoolTypes;
