import React, { useState, useEffect, useCallback } from "react";
import FranchiseSelector from "../../../../Components/Filters/Franchise.Selector";
import TechnicianSelector from "../../../../Components/Filters/Technician.Selector";
import ServiceTypeSelector from "../../../../Components/Filters/ServiceType.Selector";
import Loader from "../../../../Components/Loader";
import { getJobs } from "../../../../Action/PlotJobs/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, DatePicker, ConfigProvider } from "antd";
import { Button } from "@mobiscroll/react";
import moment from "moment-timezone";
import "moment/locale/en-gb";
import calendarLeft from "../../../../Assets/icons/calendar-left.svg";
import calendarRight from "../../../../Assets/icons/calendar-right.svg";
import en_GB from "antd/lib/locale-provider/en_GB";

const FilterHeader = (props) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [startWeekDate, setStartWeekDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [view, setView] = useState("day");
  const [franchise, setFranchise] = useState({});
  const [techs, setTechs] = useState([]);
  const [selections, setSelections] = useState([]);
  const [multipleMode, setmultipleMode] = useState(true);
  const [colorList, setColorList] = useState(["#34615E"]);
  const [loading, setLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [isDatePickerOpen, setisDatePickerOpen] = useState(false);
  const [tmpDateValue, settmpDateValue] = useState(moment());
  const [markersColors, setMarkerColors] = useState([
    "#7B3F00",
    "#DE3163",
    "#00CC99",
    "#005D89",
    "#006B3C",
    "#8B008B",
    "#E1A95F",
    "#FFC0CB",
    "#967117",
    "#93C572",
  ]);

  //Every time the date selected change, get all new values
  useEffect(() => {
    onChangeView(view);
  }, [currentDate]);

  //Get Job once franchise and tech is selected
  useEffect(() => {
    if (franchise.value && techs.length > 0)
    {
      onGetJobs()
    }
  }, [franchise, techs, serviceTypes])

  useEffect(() => {
    if (view === "day" && (franchise.value && techs.length > 0))
    {
      onGetJobs();
    }
  }, [selections])

  //When a Job is rescheduled, jobs array is refreshed
  useEffect(() => {
    if (props.getJobs) onGetJobs();
  }, [props.getJobs]);

  //Change date from picker
  const onChangeDate = (date) => {
    if (date) {
      setCurrentDate(moment(date));
    } else {
      setCurrentDate(moment());
    }
    setisDatePickerOpen(false);
  };

  //Chage to day or week view
  const onChangeView = (view) => {
    setLoading(true);
    setView(view);
    setEndDate(null);
    setStartWeekDate(null);
    setSelections([]);
    props.setView(view);
    if (view === "day") {
      setmultipleMode(true);
      onchangeTech(techs, view);
    } else {
      const tech_first = techs[0];
      setTechs([tech_first]);
      setmultipleMode(false);
      getWeek();
    }
  };

  //Get days week from date selected, a color is assigned to each day
  const getWeek = () => {
    const date = moment(currentDate);
    var first = new Date(date).getDate() - new Date(date).getDay(); // First day is the day of the month - the day of the week

    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(new Date(date).setDate(first));
    var lastday = new Date(new Date(date).setDate(last));

    var dates_list = [];

    while (firstday <= lastday) {
      dates_list.push({
        label: moment(firstday).format("dddd MMMM DD, yyyy"),
        value: moment(firstday).format("dddd MMMM DD, yyyy"),
        color: markersColors[dates_list.length],
      });
      firstday.setDate(firstday.getDate() + 1);
    }
    setSelections(dates_list);
    props.setSelections(dates_list);

    setStartWeekDate(new Date(dates_list?.[0]?.value));
    setEndDate(lastday);

    setLoading(false);
  };

  //Generation of random Hex color
  const getRandomColor = () => {
    let randomColor = Math.floor(Math.random() * 16777215).toString(16);
    let colors = colorList;

    //verify is not an existing color
    while (
      colorList.indexOf("#" + randomColor) > 0 ||
      randomColor === "000000"
    ) {
      colors.push("#" + randomColor);
      randomColor = Math.floor(Math.random() * 16777215).toString(16);
    }
    setColorList(colors);
    return "#" + randomColor;
  };

  //Get selected tech, it could be multiple to day view and one for week view
  const onchangeTech = (element, view_mode) => {
    let selections = Array.isArray(element) ? element : [element];
    if (view_mode === "week") {
      setTechs(selections);
    } else if (view_mode === "day") {
      let selections_colors = [];

      setTechs(selections);
      selections.forEach((i, idx) => {
        selections_colors.push({
          ...i,
          color: i.label !== "Not Assigned " ? markersColors[idx] : "#000000",
        });
      });

      setSelections(selections_colors);
      props.setSelections(selections_colors);
    }
    setLoading(false);
  };

  const onchangeServiceType = (elements) => {
    setServiceTypes(elements);
    setLoading(false);
  };

  //Request to get jobs considering parameters
  const onGetJobs = async () => {
    setLoading(true);
    let params_tech = "";
    techs.forEach((element, idx) => {
      if (element.label !== "Not Assigned ") params_tech += element.value;

      if (idx < techs.length - 1 && element.label !== "Not Assigned ")
        params_tech += ",";
    });
    
    let params_serviceTypes = "";
    if (!serviceTypes.find((i) => i.value === "all" || i === "all"))
      serviceTypes.forEach((element, idx) => {
        params_serviceTypes += element.value;
        if (idx < serviceTypes.length - 1) params_serviceTypes += ",";
      });

    let franchise_props = '';
    if(franchise?.label === "all")
    {
      franchise?.all?.forEach((row, index) => { 
        let data = row.uuid; 
        if(index < franchise?.all.length - 1)
          data += ','
        franchise_props += data;
     });         
    }else{
      franchise_props = franchise?.value;
    }

    const jobs = await getJobs({
      field_office__in: franchise_props,
      technician__uuid__in:
        view === "day"
          ? params_tech
          : techs.filter((i) => i.label !== "Not Assigned ")?.[0]?.value,
      date__gte: moment(startWeekDate ?? currentDate).format("YYYY-MM-DD"),
      date__lte: moment(endDate ?? currentDate).format("YYYY-MM-DD"),
      service_type__in: params_serviceTypes,
      include_unassigned: techs.find((i) => i.label === "Not Assigned ")
        ? true
        : false,
    });
    
    //Is assigned for each job index and color to plot
    jobs.results?.map(async (element) => {
      const plotIndex =
        view === "day"
          ? getPlotIndex(element.technician?.uuid)
          : getPlotIndex(
              moment(element.scheduled_appointment).format("dddd MMMM DD, yyyy")
            );

      element.plotIndex = plotIndex;
      element.color = plotIndex >= 0 ? markersColors[plotIndex] : "#000";
    });

    props.setJobs(jobs.results);
    props.setGetJobs(false);
    setLoading(false);
  };

  //Returns plot index considering the filter selection
  const getPlotIndex = (element) => {
    return selections.map((i) => i.value).indexOf(element);
  };

  //Render Date
  const renderCalendarNav = () => {
    if (view === "day") {
      return moment(currentDate).format("MMM DD, yyyy");
    }
    if (view === "week") {
      return (
        moment(currentDate).startOf("week").format("MMMM ") +
        moment(startWeekDate).format("DD") +
        "-" +
        moment(endDate).format("DD") +
        ", " +
        moment(currentDate).endOf("week").format("YYYY")
      );
    }
    return moment(currentDate).format("MMMM YYYY");
  };

  const navigatePage = useCallback(
    (prev) => {
      setisDatePickerOpen(false);
      setCurrentDate((date) => {
        if (prev) {
          return moment(date).subtract(1, view).startOf(view);
        } else {
          return moment(date).add(1, view).startOf(view);
        }
      });

      if (view === "week") {
        getWeek();
      }
    },
    [view]
  );

  //DAte picket configuration to start on Sunday
  moment.locale("en-gb", {
    week: {
      dow: 0,
    },
  });

  return (
    <>
      {loading || franchise.value === undefined ? <Loader /> : ""}
      <Row className="mb-5" style={{ width: "100%" }} align="middle">
        <Col md={4} xs={10} className="plot_job_header mr-15">
          <FranchiseSelector
            onControlChange={({ value, label, address, all }) => {
              props.setJobs([]);
              setSelections([]);
              props.setSelections([]);
              setFranchise({ value, label, address, all });
              props.setFranchise({ value, label, address, all });
            }}
          />
        </Col>
        <Col md={5} xs={10} className="plot_job_header mr-15">
          {franchise.value && (
            <TechnicianSelector
              multipleMode={multipleMode}
              franchise={franchise}
              unassign={true}
              defaultValue={techs[0]}
              maximum={10}
              onControlChange={(element) => {
                setLoading(true);
                props.setJobs([]);
                onchangeTech(element, view);
              }}
            />
          )}
        </Col>
        <Col md={5} xs={10} className="plot_job_header mr-15">
          {franchise.value && (
            <ServiceTypeSelector
              multipleMode={true}
              franchise={franchise}
              allValue={true}
              defaultValue={"all"}
              onControlChange={(element) => {
                setLoading(true);
                props.setJobs([]);
                onchangeServiceType(element);
              }}
            />
          )}
        </Col>
        <Col md={8} xs={18}></Col>
      </Row>
      <Row
        className=""
        align="middle"
        style={{ width: "100%", position: "relative" }}
      >
        <Col md={8} xs={24} className="md-custom-header-view">
          <Row justify="start" align="middle">
            <Col className="mr-10">
              <Button
                className={`btn-view-mode ${view === "day" ? "active" : ""}`}
                disabled={currentDate === moment().format("MMMM DD, yyyy")}
                onClick={() => onChangeView("day")}
                key={"day_view"}
              >
                <b>Day</b>
              </Button>
            </Col>
            <Col className="mr-10">
              <Button
                className={`btn-view-mode ${view === "week" ? "active" : ""}`}
                onClick={() => onChangeView("week")}
                key={"week_view"}
              >
                <b>Week</b>
              </Button>
            </Col>
            <Col className="">
              <ConfigProvider locale={en_GB}>
                <DatePicker
                  className={"datepicker-calendar"}
                  clearIcon={null}
                  placeholder={view === "day" ? "Select date" : "Select week"}
                  format={"LL"}
                  picker={view === "day" ? "date" : "week"}
                  style={{ height: "35px", marginRight: "3px" }}
                  value={currentDate}
                  showToday={false}
                  onOpenChange={(isOpen) => {
                    if (isOpen) setisDatePickerOpen(isOpen);
                  }}
                  open={isDatePickerOpen}
                  onChange={(e) => {
                    settmpDateValue(e);
                  }}
                  renderExtraFooter={(e) => (
                    <div className="date_picker_footer">
                      <button
                        onClick={(e) => {
                          setisDatePickerOpen(false);
                        }}
                        className="btn btn-close"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={(e) => {
                          onChangeDate(tmpDateValue);
                        }}
                        className="btn btn-default"
                      >
                        Apply
                      </button>
                    </div>
                  )}
                />
              </ConfigProvider>
            </Col>
          </Row>
        </Col>
        <Col md={10} xs={24} className="md-custom-header-nav">
          <Row justify="start" align="middle">
            <Col className={"calendar-today-desktop ml-10"}>
              <Button
                className={
                  moment(currentDate).format("DD-MM-yyyy") ===
                  moment().format("DD-MM-yyyy")
                    ? "btn-view-mode disabled"
                    : "btn-view-mode active"
                }
                onClick={() => {
                  setisDatePickerOpen(false);
                  setCurrentDate(moment());
                }}
                key={"today_btn"}
              >
                <b>Today</b>
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => navigatePage(true)}
                variant="outline"
                className="md-custom-header-button"
              >
                <img src={calendarLeft} alt="" />
              </Button>
            </Col>
            <Col className="md-custom-header-date">
              <span className="calendarDateHeader">{renderCalendarNav()}</span>
            </Col>
            <Col>
              <Button
                onClick={() => navigatePage(false)}
                variant="outline"
                className="md-custom-header-button"
                width={200}
              >
                <img src={calendarRight} alt="" />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" className="plot-selections">
        <>
          <div className="filters-box">
            {Array.isArray(selections) && selections.length > 0
              ? selections.map((item, index) => {
                  return (
                    <div
                      className="filters-selected"
                      key={index + "-div-" + item.value}
                    >
                      <FontAwesomeIcon
                        key={index + "-pin-" + item.value}
                        icon={faLocationDot}
                        color={item.color}
                        style={{ width: '18px', height:'20px' }}
                      ></FontAwesomeIcon>
                      <span
                        key={index + "-value-" + item.value}
                        style={{ marginLeft: "5px" }}
                      >
                        {item.label}
                      </span>
                    </div>
                  );
                })
              : "No elements, please select."}
          </div>
        </>
      </Row>
    </>
  );
};

export default FilterHeader;
