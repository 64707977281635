const {createSlice} = require('@reduxjs/toolkit');

const initialState = [];

const invoiceSummarySlice = createSlice({
    name:'invoiceSummary',
    initialState,
    reducers:{
        add(state,action){
            return state=action.payload
            // state.push(action.payload);
        },
        remove(state,action){
            return state.filter((item)=>item.id!==action.payload)
        }
    }
})

export const {add,remove} = invoiceSummarySlice.actions;
export default invoiceSummarySlice.reducer;