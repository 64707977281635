import React, { useState, useEffect, useRef } from 'react';
import Sidebar from "../Components/Sidebar";
import { map } from 'lodash';
import moment from 'moment-timezone';
import { DownOutlined } from "@ant-design/icons";
// import {errorHandle} from "../../../Common/errorHandle";
import { getFranchiseListAll } from '../Action/Franchise';
import axios from 'axios';
// import axiosInstance from '../../Lib/Axios';
import axiosInstance from '../Lib/Axios';
import chevronDown from "../Assets/images/chevron-down.png";
import { isEqual, isEmpty,orderBy } from "lodash";
import StatusOutsideAlerter from "../Components/Common/serviceOutsideAlerter";
import { DatePicker, Select, message, Dropdown, Table, Modal, Menu, Checkbox, ConfigProvider, Row, Col,Popconfirm } from 'antd';
import Header from "../Components/Header";
import DataTable from '../Customer/Common/DataTable';
import { getWorkOrderSync, generateInvoice,exportWorkOrderSync } from '../Action/Accounting';
import { getCustomer, getCommercialCustomer } from "../Action/Customer";
import { getFranchiseSelected, setFranchiseSelected } from "../Utils/selectedFranchise";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import 'moment/locale/en-gb';
import appData from "../Utils/constants"
import locale from 'antd/es/locale/en_GB';

moment.locale('en-us', { week: { dow: 0 } }) // 0-6 (Sunday-Saturday)
const { RangePicker } = DatePicker;
const { Option } = Select;
const status_mapping = [
  { key: "open", value: "Open" },
  { key: "open-emailed", value: "Open-Emailed" },
  { key: "open-printed", value: "Open-Printed" },
  { key: "overdue", value: "Overdue" },
  { key: "paid", value: "Paid" },
  { key: "void", value: "Void" },
];
const WorkOrdersIndex = () => {
  const componentMount = useRef(false);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [customerType, setCustomerType] = useState([
    {
      "id": 0,
      "label": "All",
      "value": "all"
    },
    {
      "id": 2,
      "label": "Complete",
      "value": "complete"
    },
    {
      "id": 4,
      "label": "Canceled Billable",
      "value": "cancelled_billable"
    }]);
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    loader: false,
    work_orders: [],
    franchise_list: [],
    selected_row_keys: [],
    is_modal_visible: false,
    invoice_date: new moment(),
    set_table_active_action: '',
    customer_types_list: [{ name: "residential", label: 'Residential' }, { name: "commercial", label: 'Commercial' }],
    statuses: customerType.filter((status) => status.value !== 'all').map((status) => status.value),
    // Filters
    search_value: "",
    loader: false,
    franchiseList: [],
    all: 0,
    status: [],
    filters: {},
    franchise: {},
    costumer_type: {},
    status_invoiced: "",
    service_type: "Service",
    is_all_selected: false,
    all_selected: false,
    all_selected_keys_length: 0,
    active_pagination: '15',
    account_list_pagination: {
      current: 1,
      showSizeChanger: false,
      pageSize: 15,
      ordering: "",
    },
    account_list_filter: {
      billing_type: "",
      status: [],
      invoice_date__lte: "",
      invoice_date__gte: "",
      due_date__lte: "",
      due_date__gte: "",
      user__uuid: "",
      balance_due__lte: "",
      balance_due__gte: "",
    },
    // selectedRowKeys: [],
    toggleStatusFilter: false,
    toggleWorkOrderFilter: false,
    list_pagination: { current: 1, showSizeChanger: false, pageSize: 15, ordering: "" }
  });
  const [filterCustomerType, setFilterCustomerType] = useState([]);

  const [dateFilter, setDateFilter] = useState({
    scheduled_time__lte: moment().endOf('month').format('YYYY-MM-DD'),
    scheduled_time__gte: moment().startOf('month').format('YYYY-MM-DD')
    // status: statuses.toString()
  })

  const [toggleStatusFilter, setToggleStatusFilter] = useState(false);
  const [limit, setLimit] = useState('15');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentUser, setCurrentUser] = useState('');
  const [tableData, setTableData] = useState([]);
  // const [selectedFranchise, setSelectedFranchise] = useState('all');
  const [filterData, setFilterData] = useState({
    offset: 0,
    limit: 15,
    status: 'complete, cancelled_billable',
    status_invoiced: 'not invoiced',
    service_type: 'service',
  });
  const [franchiseData, setFranchiseData] = useState()
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableSelectionData, setTableSelectionData] = useState(true)
  const [loadingExport, setLoadingExport] = useState(false);
  const getWorkOrderHistory = async (params, tableSelectionData = true) => {
    setLoader(true);
    const data = await getWorkOrderSync(params);
    if (tableSelectionData) {
      setTableData(data)
      setLoader(false);
    } else {
      let keys = data?.results?.filter((record) => !((record?.status === "complete" && record?.invoice_status === "Invoiced") || record?.status === "cancelled_non_billable" || record?.status === "incomplete")).map(e => e.client_uuid + "-" + e.service_order?.service_order_uuid + "-" + e.work_order_uuid)
      //  let keys = data?.results?.map(e=>e.uuid)
      //  let keys = data?.results?.map(e=>e.client_uuid + "-" + e.service_order?.service_order_uuid + "-" + e.work_order_uuid)
      setSelectedRowKeys(keys)
      setTableSelectionData(data)
      setLoader(false);
    }

  };

  const getFranchises = async () => {
    let franchises_result = await getFranchiseListAll({ offset: 0 });
    franchises_result = orderBy(franchises_result, [item => item.name.toLowerCase()], "asc");
    let tempList = franchises_result;
    if (tempList?.length > 1) {
      tempList = [{ uuid: 'all', name: 'All' }, ...tempList];
    }
    const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
    setFranchiseData(getFranchiseSelected()?.uuid || userInfo.user.associated_field_offices[0]?.uuid);
    setState((s) => ({ ...s, franchiseList: tempList }));
  };

  useEffect(() => {
    setLoader(true);
    const user = JSON.parse(localStorage.getItem("authUser"));
    setCurrentUser(user);
    getFranchises();
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let monthData = getStartAndEndDateOfMonth(year, month);
    setStartDate(moment(monthData.startDate).format('YYYY-MM-DD'));
    setEndDate(moment(monthData.endDate).format('YYYY-MM-DD'));
    componentMount.current = true;

  }, []);

  useEffect((pagination) => {
    setLoader(true);
    if ((!isEmpty(filterData)) && !componentMount.current) {
      // Get first and last day of the month
      getWorkOrderHistory(getTableFilters(pagination))
    }

  }, [filterData])

  useEffect((pagination) => {
    setLoader(true);
    if (franchiseData) {
      // Get first and last day of the month
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let monthData = getStartAndEndDateOfMonth(year, month);
      let payload = {};
      if (componentMount.current) {
        payload = {
          "status__in": 'complete, cancelled_billable',
          "scheduled_appointment__lte": startDate ? startDate : moment(monthData.startDate).format('YYYY-MM-DD'),
          "scheduled_appointment__gte": endDate ? endDate : moment(monthData.endDate).format('YYYY-MM-DD'),
          "limit": 15,
          "offset": 0,
          "ordering": "service_order_number",
          "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
          "status_invoiced": 'not invoiced',
          "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
        }
        componentMount.current = false;
      } else {
        payload = {
          "status__in": filterData?.status ? filterData?.status : appData.workorderdataTypes,
          "scheduled_appointment__lte": startDate ? startDate : moment(monthData.startDate).format('YYYY-MM-DD'),
          "scheduled_appointment__gte": endDate ? endDate : moment(monthData.endDate).format('YYYY-MM-DD'),
          "limit": pagination?.pageSize,
          "offset": 0,
          "ordering": "service_order_number",
          "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
          "status_invoiced": filterData?.status_invoiced ? filterData?.status_invoiced : appData.invoiceTypes,
          "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
        }
      }
      if (franchiseData !== "all") {
        payload["field_office__uuid"] = franchiseData
      }
      if (currentUser?.user?.role.includes(`franchise_owner`) || currentUser?.user?.role.includes(`operations_mgr`)) {
        setFranchiseSelected(franchiseData);
      } else {
        setFranchiseSelected(franchiseData);
        customerAndStatusOnChange(franchiseData === 'all' ? '' : franchiseData, "field_office__uuid");
      }
      getWorkOrderHistory(payload)
    }
  }, [franchiseData])

  useEffect(() => {
    let {
      all_selected,
      selectedRowKeys
    } = state;
    if (tableData?.count > 0) {
      let keys = []
      if (all_selected && selectedRowKeys?.length === 0) {
        // keys = tableData?.results?.map(row => row.uuid);
        let keys = tabledata?.results?.map(e => e.client_uuid + "-" + e.service_order?.service_order_uuid + "-" + e.work_order_uuid)
        // let keys = tabledata?.results?.map(e=>e.client_uuid + "-" + e.service_order?.service_order_uuid)
        setSelectedRowKeys(keys)
        setState((s) => ({
          ...s,
          loader: false,
          // selectedRowKeys: keys,
          all_selected_keys_length: keys.length
        }));
      }
      else {
        setState((s) => ({
          ...s,
          loader: false,
          set_table_active_action: ''
        }));
      }
    }
  }, [tableData])

  const onFilter = (e, type) => {
    let {
      statuses,
      franchise_list,
      list_pagination,
      active_pagination
    } = state;

    let filters = { ...filterData };

    if (e === "" || !e || e === 'all') {
      switch (type) {
        case "status":
          filters = { ...filters, status: statuses.toString() };
          break;
        case "costumer_type":
          filters = { ...filters, costumer_type: "" };
          break;
        case "franchise_type":
          filters = { ...filters, field_office_uuid: "" };
          break;
        case "status_invoiced":
          filters = { ...filters, status_invoiced: "" };
          break;
        case "service_type":
          filters = { ...filters, service_type: "" };
          break;
        case "date_type":
          filters = { ...filters, date_type: "" };
        // break;
      }
    }
    else {
      switch (type) {
        case "status":
          filters = { ...filters, status: e.toString() };
          break;
        case "costumer_type":
          filters = { ...filters, costumer_type: `${e.charAt(0).toLowerCase()}${e.slice(1)}` };
          break;
        case "franchise_type":
          const franchise = franchise_list?.find((franchise) => franchise.id === parseInt(e));
          filters = { ...filters, field_office_uuid: franchise?.uuid };
          // filters = {...filters, field_office_uuid : e };
          break;
        case "status_invoiced":
          filters = { ...filters, status_invoiced: e }
          break;
        case "service_type":
          filters = { ...filters, service_type: e }
          break;
        case "date_type":
          filters = { ...filters, date_type: e };
        // break;
      }
    }

    let data = {
      // is_exclude_invoiced_wo: true,
      offset: 0,
      limit: active_pagination,
      ...filters
    }
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);

    setFilterData(data)

  }


  const setSelectedDateRange = (e) => {
    if (e) {
      setStartDate(moment(e[0]).format('YYYY-MM-DD'));
      setEndDate(moment(e[1]).format('YYYY-MM-DD'));
      // setDateType('');
    } else {
      setStartDate(null);
      setEndDate(null);
      // setDateType('');
    }
    setCurrentPageToFirst()
    onFilter(e, "date_type");
  }
  const customerAndStatusOnChange = (e, type) => {
    const { search_value } = state;
    const temp = state.filteredCustomerList?.filter(
      (item) => item.field_office !== null && item.field_office.uuid === e
    );
    setState((s) => ({ ...s, customerList: temp }));
    let { account_list_filter, active_pagination } = state;
    if (type === "status" && e[e.length - 1] === "") {
      account_list_filter = Object.assign(account_list_filter, {
        [type]: [""],
      });
    } else {
      account_list_filter = Object.assign(account_list_filter, { [type]: e });
      if (type === "status") {
        const idx = account_list_filter?.status?.indexOf("");
        if (idx > -1) {
          account_list_filter.status.splice(idx, 1);
          account_list_filter = Object.assign(account_list_filter, {
            status: account_list_filter.status,
          });
        }
      }
    }
    const status = [];
    status_mapping?.map((x) => status.push(x.key));
    if (isEqual(account_list_filter?.status, status)) {
      account_list_filter = Object.assign(account_list_filter, {
        [type]: [""],
      });
    }
    setState((s) => ({
      ...s,
      account_list_filter,
      loader: true,
    }));
    let data = {
      offset: 0,
      serch: search_value,
      limit: active_pagination,
      ...account_list_filter,
    };
    data.status = data.status.toString();
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );
    //Call API 
    // gettableDataingData(data);
    // onFilter(data, "franchise_type")
  };
  const getStartAndEndDateOfMonth = (year, month) => {
    let monthData = {
      'startDate': "",
      "endDate": ""
    }
    var firstDay = new Date(year, month, 1);
    var lastDay = new Date(year, month + 1, 0);
    let startDate = moment(firstDay);
    let endDate = moment(lastDay);
    monthData.startDate = startDate;
    monthData.endDate = endDate;
    return monthData;
  }
  const renderSideBar = () => {
    let {
      filters
    } = dateFilter

    return (
      <aside className="laside">
        <h3>Filters</h3>
        <div className="form-group">
          <div className='fitem'>
            <label>Franchise<sup>*</sup></label>
            <Select
              className="swhite franchise_filter"
              showSearch
              defaultValue=""
              showArrow={true}
              suffixIcon={<i className="las la-angle-down"></i>}
              value={franchiseData}
              // onSearch={(e) => {
              //   getFranchiseListAll({ search: e });
              // }}
              // onBlur={() => {
              //   getFranchiseListAll({ limit: 0, offset: 0 });
              // }}
              onChange={(event) => {
                setCurrentPageToFirst()
                setFranchiseData(event)
              }}
            >
              {state.franchiseList?.map((item) => {
                return <Option key={item.uuid} value={item.uuid}>{item.name}</Option>;
              })}
            </Select>
          </div>
          <div className='fitem'>
            <label>Work Order Date Range<sup>*</sup></label>
            <ConfigProvider locale={locale}>
              <RangePicker
                format={"MM/DD/YYYY"}
                className="date_ranger dwhite"
                dropdownClassName="dropdown_date_range"
                prefixIcon={<i className="las la-calendar"></i>}
                onChange={(e) => {
                  setCurrentPageToFirst();
                  setSelectedDateRange(e);
                }}
                value={
                  startDate !== null
                    ? [moment(startDate), moment(endDate)]
                    : ""
                }
              />
            </ConfigProvider>
          </div>
        </div>
      </aside>
    );
  }

  const WorkPagination = (pagination, filter_filler, sorter) => {
    let {
      account_list_pagination,
      active_pagination,
      // search_value,
      // account_list_filter,
    } = state;

    let ordering = "";

    if (sorter.column) {
      ordering =
        sorter.order === "ascend" ? `${sorter.field}` : `-${sorter.field}`;
    } else {
      ordering = "";
    }
    account_list_pagination = Object.assign(account_list_pagination || {}, {
      current: pagination.current,
      ordering: ordering,
    }) || {};
    setLimit(pagination?.pageSize);
    setState((s) => ({
      ...s,
      account_list_pagination,
      loader: true,
    }));
    let data = {
      "status__in": filterData?.status ? filterData?.status : appData.completeCancelBillableWorkOrder,
      "scheduled_appointment__lte": startDate ? startDate : moment().format('YYYY-MM-DD'),
      "scheduled_appointment__gte": endDate ? endDate : moment().add(30, 'days').format('YYYY-MM-DD'),
      "limit": pagination?.pageSize,
      "offset": pagination.current * active_pagination - active_pagination,
      "ordering": ordering ? ordering : "service_order_number",
      "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
      "status_invoiced": filterData?.status_invoiced ? filterData?.status_invoiced : appData.notInvoiced,
      "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
      ...(franchiseData != 'all' && { "field_office__uuid": franchiseData })
    }
    setLoader(true);
    getWorkOrderHistory(data)
  };


  const renderAction = (record) => {
    const menu = (
      <Menu>
        <Menu.Item
          key="0"
          disabled={selectedRowKeys.length > 1}
        >
          <a href={'/#'} onClick={(e) => onActionChange(e, 'send', record)}>Generate Invoice</a>
        </Menu.Item>
        <Menu.Item key="1" disabled={selectedRowKeys.length > 1}><a href={'/#'} onClick={(e) => onActionChange(e, 'customer', record)}>View Customer</a></Menu.Item>
        <Menu.Item disabled={!record.work_order_uuid || (selectedRowKeys.length >1)} key="2"><a href={'/#'} onClick={(e) => onActionChange(e, 'work_order', record)}>View Work Order</a></Menu.Item>
        <Menu.Item key="3" disabled={selectedRowKeys.length > 1}><a href={'/#'} onClick={(e) => onActionChange(e, 'service_order', record)}>View Service Order</a></Menu.Item>
      </Menu>
    )

    return (
      <Dropdown overlay={menu} trigger={['hover']}>
        <span onClick={e => e.preventDefault()}>
          <DownOutlined />
        </span>
      </Dropdown>
    )
  }

  const onActionChange = (event, action, row) => {
    //commercial
    event.preventDefault();
    (async () => {

      let data;
      let data_comm;
      if (row?.service_order?.client_type === 'residential') {
        data = await getCustomer(row?.client_uuid);
      } else {
        data_comm = await getCommercialCustomer(row?.client_uuid);
      }
      switch (action) {
        case 'send':
          onSelectedRowKeysChange([`${row?.client_uuid + "-" + row?.service_order?.service_order_uuid + "-" + row?.work_order_uuid}`])
          setState({ ...state, is_modal_visible: true });
          break;
        case 'customer':

          if (row?.client_name) {
            if (row?.service_order?.client_type === 'residential') {
              navigate(`/residential/customer-detail`, {
                state: {
                  record: {
                    ...data,
                    uuid: row?.client_uuid,
                    customer: row?.client_name,
                    selectedTab: 0,
                  }
                }
              });
            } else {
              navigate(`/commercial/customer-detail`, {
                state: {
                  record: {
                    ...data_comm,
                    id: row?.client_uuid,
                    customer: row?.client_name,
                    selectedTab: 0,
                  }
                }
              });
            }
          }
          break;
        case 'work_order':
          if (row?.client_name) {
            if (row?.service_order?.client_type === 'residential') {
              navigate(`/residential/customer-detail`, {
                state: {
                  record: {
                    ...data,
                    'id': row?.client_uuid,
                    uuid: row?.client_uuid,
                    customer: row?.client_name,
                    selectedTab: 2,
                    service: row?.service_order?.service_order_number,
                    work_order_uuid: row?.work_order_uuid,
                    transection_type: "Update",
                    from: 'workOrder',
                    field_office_uuid: row?.field_office_uuid,
                  }
                }
              });
            } else {
              navigate(`/commercial/customer-detail`, {
                state: {
                  record: {
                    ...data_comm,
                    'id': row?.client_uuid,
                    uuid: row?.client_uuid,
                    customer: row?.client_name,
                    selectedTab: 2,
                    service: row?.service_order?.service_order_number,
                    work_order_uuid: row?.work_order_uuid,
                    transection_type: "Update",
                    from: 'workOrder',
                    field_office_uuid: row?.field_office_uuid,
                  }
                }
              });
            }
          }
          break;
        case 'service_order':
          if (row?.client_name) {
            if (row?.service_order?.client_type === 'residential') {
              navigate(`/residential/customer-detail`, {
                state: {
                  record: {
                    ...data,
                    'id': row?.client_uuid,
                    uuid: row?.client_uuid,
                    customer: row?.client_name,
                    selectedTab: 2,
                    service: row?.service_order?.service_order_number,
                    work_order_uuid: row?.work_order_uuid,
                    transection_type: "service",
                    from: 'workOrder',
                    field_office_uuid: row?.field_office_uuid,
                  }
                }
              });
            } else {
              navigate(`/commercial/customer-detail`, {
                state: {
                  record: {
                    ...data_comm,
                    'id': row?.client_uuid,
                    uuid: row?.client_uuid,
                    customer: row?.client_name,
                    selectedTab: 2,
                    service: row?.service_order?.service_order_number,
                    work_order_uuid: row?.work_order_uuid,
                    transection_type: "service",
                    from: 'workOrder',
                    field_office_uuid: row?.field_office_uuid,
                  }
                }
              });
            }
          }
      }
    })();
  }

  const setCurrentPageToFirst = () => {
    if (state.account_list_pagination?.current > 1) {
      let { account_list_pagination } = state;

      account_list_pagination = Object.assign(account_list_pagination, {
        current: 1,
        offset: 0
      });

      setState((s) => ({
        ...s,
        account_list_pagination,
        loader: true,
      }));
    }
  }

  const onSelectedRowKeysChange = (data) => {
    setSelectedRowKeys(data)
    let filtersData = [];
    data.forEach((eachKey) => {
      // let filterData = tableData?.results?.filter((r) => r.uuid === eachKey)[0]
      let filterData = tableData?.results?.filter(e => e.client_uuid + "-" + e.service_order?.service_order_uuid + "-" + e.work_order_uuid === eachKey)[0]
      filtersData.push(filterData);

    })
    setSelectedRowData(filtersData);
  }

  const conditionHandleSelectAll = () => {
    let returnVal = tableData?.results?.filter((record) => {
      !((record?.status === "complete" && record?.invoice_status === "Invoiced") || record?.status === "cancelled_non_billable" || record?.status === "incomplete")
    }) //||[]
    return returnVal?.length > 0
  }

  const renderHeaderCheckbox = () => {
    const { all_selected } = state;
    return <Checkbox
      disabled={conditionHandleSelectAll() ? true : false}
      checked={all_selected}
      onChange={handleSelectAll}
    />
  }
  const handleSelectAll = () => {
    const { all_selected } = state;
    if (!all_selected) {
      // let keys = tableData?.results?.map(e=>e.uuid)
      // let keys = tableData?.results?.map(e=>e.client_uuid + "-" + e.service_order?.service_order_uuid + "-" + e.work_order_uuid)
      // setSelectedRowKeys(keys)
      setState({
        // loader: true,
        ...state,
        all_selected: true,
        selected_row_keys: []
      });
      setSelectedRowKeys([])
      let data = {
        "status__in": filterData?.status ? filterData?.status : appData.completeCancelBillableWorkOrder,
        "scheduled_appointment__lte": startDate ? startDate : moment().format('YYYY-MM-DD'),
        "scheduled_appointment__gte": endDate ? endDate : moment().add(30, 'days').format('YYYY-MM-DD'),
        "limit": 5000,
        "offset": 0,
        "ordering": "service_order_number",
        "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
        "status_invoiced": filterData?.status_invoiced ? filterData?.status_invoiced : appData.notInvoiced,
        "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
      }
      getWorkOrderHistory(data, false);

    }
    else {
      setState({
        ...state,
        selected_row_keys: [],
        all_selected: false,
        // all: 0
      });
      setSelectedRowKeys([])
    }
  }

  const setMessagePageLimit = (custom_limit, Sorter) => {
    let {
      search_value,
      account_list_pagination,
      account_list_filter
    } = state;

    account_list_pagination = Object.assign(account_list_pagination, { 'current': 1, 'pageSize': custom_limit }) || {};

    setState((s) => ({
      ...s,
      account_list_pagination,
      loader: true,
      active_pagination: custom_limit,
    }))

    let data = {
      "status__in": filterData?.status ? filterData?.status : appData.completeCancelBillableWorkOrder,
      "scheduled_appointment__lte": startDate ? startDate : moment().format('YYYY-MM-DD'),
      "scheduled_appointment__gte": endDate ? endDate : moment().add(30, 'days').format('YYYY-MM-DD'),
      "limit": custom_limit,
      "offset": 0,
      "ordering": "service_order_number",
      "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
      "status_invoiced": filterData?.status_invoiced ? filterData?.status_invoiced : appData.notInvoiced,
      "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
      ...(franchiseData != 'all' && { "field_office__uuid": franchiseData })

    }
    setLoader(true);
    getWorkOrderHistory(data)
  }
  const isCheckBoxEnabled = (invoice_status, wo_status) => {
    let isCheckBoxDisabled = false;
    if (invoice_status.toLowerCase() === "invoiced") {
      isCheckBoxDisabled = true;
    } else if (invoice_status.toLowerCase() === "to be invoiced") {
      if (
        wo_status.toLowerCase() === "cancelled_non_billable" ||
        wo_status.toLowerCase() === "incomplete"
      ) {
        isCheckBoxDisabled = true;
      } else {
        isCheckBoxDisabled = false;
      }
    }
    return isCheckBoxDisabled;
  };

  const [cols, setCols] = useState(DataTable.AllWorkOrdersList(renderAction));

  useEffect(() => {
    setCols(DataTable.AllWorkOrdersList(renderAction))
  }, [selectedRowKeys]);

  const renderWorkOrderTableData = () => {
    let {
      list_pagination,
      active_pagination,
      // selected_row_keys,
      account_list_pagination,
      // selectedRowKeys,
    } = state;
    return (
      <React.Fragment>
        <Table
          // let keys = data?.results?.map(e=>e.client_uuid + "-" + e.service_order?.service_order_uuid)
          // rowKey={record => record?.uuid}
          rowKey={record => record?.client_uuid + "-" + record.service_order?.service_order_uuid + "-" + record.work_order_uuid}
          dataSource={tableData?.results}
          pagination={{ ...account_list_pagination, total: tableData?.count }}
          onChange={WorkPagination}
          columns={cols}
          rowSelection={{
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            columnTitle: renderHeaderCheckbox,
            onSelectAll: handleSelectAll,
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys) => { onSelectedRowKeysChange(selectedRowKeys) },
            getCheckboxProps: (record) => {
              return {
                disabled: isCheckBoxEnabled(record?.invoice_status, record?.status) || record?.batch_invoice_processed
              }
            },
          }}
        />
        {tableData?.results?.length > 0 ? (
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setMessagePageLimit('15') }}
                className={active_pagination === "15" ? "active" : ""}
              >
                15
              </span>
            </li>
            <li>
              <span
                className={active_pagination === "30" ? "active" : ""}
                onClick={() => { setMessagePageLimit('30') }}
              >
                30
              </span>
            </li>
            <li>
              <span
                className={active_pagination === "60" ? "active" : ""}
                onClick={() => { setMessagePageLimit('60') }}
              >
                60
              </span>
            </li>
          </ul>
        ) : (
          ""
        )}
      </React.Fragment>
    )
  }

  const toggleStatus = () => {
    if (toggleStatusFilter) {
      setToggleStatusFilter(false)
    } else {
      setToggleStatusFilter(true)
    }
  }

  const closeWorkOrderFilter = () => {
    setToggleStatusFilter(false)
  };
  const onStatusChanged = (e, index) => {
    const { status } = state;
    let arrStatus = [...status];
    // let arrStatus = [{...filterCustomerType}];
    if (e.target.checked) {
      if (index === 0) {
        customerType.forEach((element, index) => {
          element['is_change'] = true;
          if (index > 0 && arrStatus.indexOf(element.value) === -1) {
            arrStatus.push(element.value)
          }
        });
      } else {
        if (arrStatus.indexOf(e.target.id) === -1) {
          customerType[index]['is_change'] = true;
          arrStatus.push(e.target.id)
        }
      }
    } else {
      if (index === 0) {
        customerType.forEach(element => {
          element['is_change'] = false;
        });
        arrStatus = []
      } else {
        const ind = arrStatus.indexOf(e.target.id);
        if (ind > -1) {
          customerType[0]['is_change'] = false;
          customerType[index]['is_change'] = false;
          arrStatus.splice(ind, 1);
        }
      }
    }
    setState((state) => ({
      ...state,
      status: arrStatus,
    }));
    setFilterCustomerType({ status: arrStatus }, () => {
      setToggleStatusFilter(false)
      // onFilter(arrStatus.join(), "status");
    });
    setCurrentPageToFirst()
    onFilter(arrStatus.join(), "status");
  }

  const onDateChange = (data) => {
    const { is_modal_visible, invoice_date } = state;
    //  data() moment(new Date()).format("DD/MM/YYYY")
    setState({ ...state, invoice_date: data, is_modal_visible: true, });
  };

  const sendInvoiceData = async (payload) => {
    setLoader(true)
    const result = await generateInvoice(payload);
    if (result?.message === undefined) {
      // closeAndClear();
      setLoader(false)
      message.error(result?.message);
      setState((state) => ({ ...state, loader: false, is_modal_visible: false, all_selected: false }))
      // props.getInvoiceList();
      setSelectedRowKeys([])
    } else {
      message.success("Batch invoice generation initiated");
      setState((state) => ({ ...state, loader: false, is_modal_visible: false, all_selected: false }))
      setSelectedRowKeys([])
    }
  }

  // On select invoice data and click on send
  const onSendInvoice = async () => {
    const { invoice_date } = state;

    // let selectedRows = tableData?.results.filter((record)=>selectedRowKeys.indexOf(record?.uuid)!==-1)
    let selectedRows = tableData?.results?.filter((record) => selectedRowKeys.indexOf(record?.client_uuid + "-" + record.service_order?.service_order_uuid + "-" + record.work_order_uuid) !== -1)
    // let keys = data?.results?.map(e=>e.client_uuid + "-" + e.service_order?.service_order_uuid)

    let arrData = [];
    map(selectedRows, data => {
      arrData.push({
        service_order_uuid: data?.service_order?.service_order_uuid,
        work_order_uuid: data?.work_order_uuid,
        user_uuid: data?.client_uuid
      })
    })

    let payload = {
      "work_orders": arrData,
      "invoice_date": moment(invoice_date).format('YYYY-MM-DD'),
    }
    // navigate(`/account/account-list`);

    setTimeout(() => {
      navigate(`/account/account-list`); // Code to execute after the timeout
    }, 6000);

    await sendInvoiceData(payload);

    let data = {
      "status__in": filterData?.status ? filterData?.status : appData.completeCancelBillableWorkOrder,
      "scheduled_appointment__lte": startDate ? startDate : moment().format('YYYY-MM-DD'),
      "scheduled_appointment__gte": endDate ? endDate : moment().add(30, 'days').format('YYYY-MM-DD'),
      "limit": state.active_pagination,
      "offset": 0,
      "ordering": "service_order_number",
      "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
      "status_invoiced": filterData?.status_invoiced ? filterData?.status_invoiced : appData.notInvoiced,
      "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
      ...(franchiseData != 'all' && { "field_office__uuid": franchiseData })
    }
    setLoader(false);
    getWorkOrderHistory(data);
  }

  const renderStatusFilters = () => {
    const { status } = state;
    let selectedstatusvalue = status ? status.length === 0 ? "WO Status All" : `WO Status:${status.length} items selected` : "WO Status All"
    return <StatusOutsideAlerter closeServiceOrderFilter={closeWorkOrderFilter}>
      <div className="image-input work-order-status">
        <div className="fitem">
          <label>WO Status<sup>*</sup></label>
          <input
            type="text"
            style={{ "background": "white", "padding": "8px 16px", "font-size": "14px", "font-weight": 600 }}
            id="txtStatus"
            value={selectedstatusvalue}
            // value={status && status.length === 0 ? 'WO Status: All' : `WO Status: ${status.length} items selected`}
            onFocus={() => { toggleStatus() }}
          />
          <img
            style={{ "top": "24.5px", "height": "35px" }}
            src={chevronDown}
            alt=""
            onClick={() => { toggleStatus() }}
          />
        </div>
        {toggleStatusFilter && <div className="field-dropdown right min-height-auto" style={{ maxWidth: 240 }}>
          <div className="field-dropdown-wo">
            {
              map(customerType, (item, index) => {
                return (
                  <div key={index} className="fitem-check">
                    <input
                      id={item.value}
                      type="checkbox"
                      checked={item.is_change}
                      className="fitem-ck-input"
                      onChange={(e) => { onStatusChanged(e, index) }}
                    />
                    <label htmlFor={item.value} className="fitem-ck-txt">{item.label}</label>
                  </div>
                )
              })}
          </div>
        </div>}
      </div>
    </StatusOutsideAlerter>
  }
  const getTableFilters = (pagination) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let monthData = getStartAndEndDateOfMonth(year, month);
    if (filterData?.status) {
      setSelectedStatus(filterData?.status)
    }
    let payload = {
      "status__in": filterData?.status ? filterData?.status : selectedStatus,
      "scheduled_appointment__lte": startDate ? startDate : moment(monthData.startDate).format('YYYY-MM-DD'),
      "scheduled_appointment__gte": endDate ? endDate : moment(monthData.endDate).format('YYYY-MM-DD'),
      "limit": pagination?.pageSize,
      "offset": 0,
      "ordering": "service_order_number",
      "service_order__client__client_type": filterData?.costumer_type ? filterData?.costumer_type : "residential,commercial",
      "status_invoiced": filterData?.status_invoiced ? filterData?.status_invoiced : appData.notInvoiced,
      "service_type__in": filterData?.service_type ? filterData?.service_type : appData.serviceTypes,
    }
    if (franchiseData && franchiseData !== "all") {
      payload["field_office__uuid"] = franchiseData
    }
    return payload;
  }
  const onExportTable = async (is500=0) => {
    
    let payLoad = {
      ...getTableFilters(),
      is_500: is500,
    };
    payLoad.limit = tableData?.count;
    payLoad.offset = 0;
    setLoadingExport(true);
    const response = await exportWorkOrderSync(payLoad);
    setLoadingExport(false);      
  }
  return (
    <>
      <div id="wrapper" className="accouting-area">
        {loader && <Loader />}
        <div id="accounting-tab" className="content-area">
          <Sidebar sideBarBackgroundColor="#005d89" />
          <div className="container">
            <div className="aside-area pt-0 account_module_wrapper">
              <div className="account_expired_card" style={{ "flex": "1" }}>
                <Modal
                  className=""
                  width={400}
                  okText={"Generate"}
                  cancelText={"Cancel"}
                  visible={state.is_modal_visible}
                  onOk={async () => await onSendInvoice()}
                  onCancel={() => setState({ ...state, is_modal_visible: false })}
                >
                  <h3 className='pt-20 mb-5'>Generate Invoices</h3>
                  <p>{`${selectedRowKeys.length} invoices selected.`}</p>
                  <div className="fitem">
                    <label>Invoice Date</label>
                    <DatePicker
                      name='invoice_date'
                      format="MM/DD/YYYY"
                      style={{ 'width': '100%' }}
                      value={state.invoice_date}
                      onChange={(e) => {
                        onDateChange(e);
                      }}
                    />
                  </div>
                </Modal>
                <Row gutter={[10]} style={{ paddingBlock: 20, paddingLeft: 30, margin: 0, background: '#eff0f2' }}>
                    <Col lg={4} className="pl-15">
                        <div className='fitem'>
                                <label>Franchise<sup>*</sup></label>
                                <Select
                                  className="swhite franchise_filter"
                                  showSearch
                                  defaultValue=""
                                  showArrow={true}
                                  suffixIcon={<i className="las la-angle-down"></i>}
                                  value={franchiseData}
                                  // onSearch={(e) => {
                                  //   getFranchiseListAll({ search: e });
                                  // }}
                                  // onBlur={() => {
                                  //   getFranchiseListAll({ limit: 0, offset: 0 });
                                  // }}
                                  onChange={(event) => {
                                    setCurrentPageToFirst()
                                    setFranchiseData(event)
                                  }}
                                >
                                  {state.franchiseList?.map((item) => {
                                    return <Option key={item.uuid} value={item.uuid}>{item.name}</Option>;
                                  })}
                            </Select>
                          </div>
                    </Col>
                    <Col lg={6} className="pl-15">
                       <div className='fitem'>
                            <label>Work Order Date Range<sup>*</sup></label>
                            <ConfigProvider locale={locale}>
                              <RangePicker
                                format={"MM/DD/YYYY"}
                                className="date_ranger dwhite"
                                dropdownClassName="dropdown_date_range"
                                prefixIcon={<i className="las la-calendar"></i>}
                                onChange={(e) => {
                                  setCurrentPageToFirst();
                                  setSelectedDateRange(e);
                                }}
                                value={
                                  startDate !== null
                                    ? [moment(startDate), moment(endDate)]
                                    : ""
                                }
                              />
                            </ConfigProvider>
                      </div>
                    </Col>
                    <Col lg={4} className="pl-15">
                        <div className='fitem'>
                          <label>Service Type<sup>*</sup></label>
                            <Select
                              showSearch
                              value={state.service_type}
                              showArrow={true}
                              className="swhite service_status_filter"
                              suffixIcon={<i className="las la-angle-down"></i>}
                              onChange={(e) => {
                                setState(s => ({
                                  ...s,
                                  filters: { ...state.filters, service_type: e },
                                  service_type: e,
                                }));
                                setCurrentPageToFirst()
                                onFilter(e, "service_type");
                              }}
                            >
                              <Option value="">All</Option>
                              <Option value="Maintenance">Maintenance</Option>
                              <Option value="Service">Service</Option>
                              <Option value="Construction">Construction</Option>
                              <Option value="Renovation">Renovation</Option>
                            </Select>
                          </div>
                    </Col>
                    <Col lg={4} className="pl-15">
                        <div className="fitem">
                            <label>Customer Type<sup>*</sup></label>
                            <Select
                              showSearch
                              defaultValue=""
                              showArrow={true}
                              className="swhite customer_type_filter"
                              suffixIcon={<i className="las la-angle-down"></i>}
                              onChange={(e) => {
                                setState(s => ({
                                  ...s,
                                  filters: { ...state.filters, costumer_type: e },
                                  costumer_type: e,
                                }));
                                setCurrentPageToFirst()
                                onFilter(e, "costumer_type");
                              }}
                            >
                              <Option value="">All</Option>
                              {map(state.customer_types_list, (item) => {
                                return (
                                  <Option className="text-capitalize" key={item.name} value={item.name}>{item.label}</Option>
                                )
                              })}
                            </Select>
                          </div>
                    </Col>
                    <Col lg={4} className="pl-15">
                        {renderStatusFilters()}
                    </Col>
                </Row>
                <ul className="breadcrumb padding-left-45">
                  <li onClick={() => {
                    navigate(`/account/account-list`)
                  }}
                  >
                    <span className="pointer">
                      <i className="las la-angle-left"></i> Back to All Accounts
                    </span>
                  </li>
                </ul>
                <Modal
                  className=""
                  width={400}
                  okText={"Generate"}
                  cancelText={"Cancel"}
                  visible={state.is_modal_visible}
                  onOk={async () => await onSendInvoice()}
                  onCancel={()=> setState({ ...state, is_modal_visible: false }) }
                >
                  <h3 style={{ "paddingTop": "25px", "marginBottom": "5px" }}>Generate Invoices</h3>
                  <p>{`${selectedRowKeys.length} Service orders and Workorders selected.`}</p>
                  <div className="fitem">
                    <label>Invoice Date</label>
                    <DatePicker
                      name='invoice_date'
                      format="MM/DD/YYYY"
                      style={{ 'width': '100%' }}
                      value={state.invoice_date}
                      onChange={(e) => {
                        onDateChange(e);
                      }}
                    />
                  </div>
                </Modal>
                <div className="filter_wrapper acc-wrap flex bg-transparent" style={{ padding: '10px 25px 0 45px' }}>
                  <h2 style={{ 'margin': '0' }}>Batch Invoicing</h2>
                  <div className="right_side">
                    <div className="form-group flex ai-center w-100 flex-end">
                      <button
                        disabled={!selectedRowKeys?.length}
                        style={{ "alignSelf": "baseline", "margin": "0px 10px", border: '1px solid orange' }}
                        className={`btn ${!selectedRowKeys?.length ? "btn-disabled" : "btn btn-warning"}`}
                        // className="btn btn btn-warning"
                        onClick={() => setState({ ...state, is_modal_visible: true })}
                      >
                        Generate Invoices
                      </button>
                    {
                        tableData?.count > 500 ? (
                        <Popconfirm
                          onConfirm={() => !loadingExport && onExportTable(1)}
                          okText="Export"
                          cancelText="Cancel"
                          icon={<></>}
                          title={
                            <>
                              <div>
                                Invoices list data is more than 500 records. Data would be
                                <br />
                                exported and emailed to
                                {' '}
                                <b>{currentUser?.user?.email}</b>.
                              </div>
                            </>
                          }
                        >
                          <button
                          className="btn btn-warning"
                          >
                            {loadingExport ? 'Exporting...' : 'Export'}
                          </button>
                        </Popconfirm>
                      ) : (
                        <button
                          disabled={loadingExport}
                          onClick={() => onExportTable()}
                          className="btn btn-warning"
                          >
                            {loadingExport ? 'Exporting...' : 'Export'}
                          </button>
                      )
                    } 
                    </div>
                  </div>
                </div>-
                <div className="block-row pt-10 padding-left-45">
                  {renderWorkOrderTableData()}
                </div>
                {/* {loader ? <Loader /> : ''} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default WorkOrdersIndex;