import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import TechnicianSelector from "../../../../Components/Filters/Technician.Selector";
import Loader from "../../../../Components/Loader";
import location from "../../../../Assets/icons/location.svg";
import phoneIcon from "../../../../Assets/icons/phone-call.svg";
import {
  Modal,
  Row,
  Col,
  Tooltip,
  Checkbox,
  DatePicker,
  notification,
  Input,
  TimePicker,
  message,
} from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Popup } from "@mobiscroll/react";
import moment from "moment-timezone";

import {
  deleteService,
  rescheduleService,
  rescheduleRecurringService,
} from "../../../../Action/Calendar";

const GoogleMapContainer = ({
  jobs = [],
  selections = [],
  view = "",
  setGetJobs = false,
  franchise,
}) => {
  const shouldOpenPopup = useRef(true);
  const [currentJob, setCurrentJob] = React.useState(null);
  const [hoverJob, setHoverJob] = React.useState(null);
  const [map, setMap] = useState(null);
  const [anchor, setAnchor] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [center, setCenter] = useState({ lat: 32.871105, lng: -80.008023 }); //Initiated point for map
  const timerRef = React.useRef(null);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableRescheduleBtn, setDisableRescheduleBtn] = useState(true);
  const [rescheduleRecurring, setRescheduleRecurring] = useState(false);
  const [rescheduleTechnician, setRescheduleTechnician] = useState({
    value: null,
    label: null,
  });
  const [rescheduleDate, setRescheduleDate] = useState(null);
  const [rescheduleTimeSelection, setRescheduleTimeSelection] = useState(null);
  const [technician, setTechnician] = useState({});
  const [woModalOpen, setWOModalOpen] = useState(false);
  const [optionsMap, setOptionsMap] = useState({
    gestureHandling: "cooperative",
    styles: [
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
    ],
  });

  const [bgColor, setBgColor] = React.useState("");

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  //Load Google Maps
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}`
  });

  //Get User info
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });

  //Verify if the user has franchise_owner role due to dual role users
  const is_fo = userInfo?.user?.user_roles.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );

  const role = is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
    ? "lead_tech"
    : userInfo?.user?.user_roles[0]?.name;

  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (
      franchise !== undefined &&
      franchise.address !== undefined &&
      franchise.address.geo_location !== null
    ) {
      setCenter({
        lat: franchise?.address?.geo_location?.latitude,
        lng: franchise?.address?.geo_location?.longitude,
      });
    } else if (jobs.length > 0) {
      setCenter({
        lat: jobs?.[0]?.service_address?.geo_location?.latitude,
        lng: jobs?.[0]?.service_address?.geo_location?.longitude,
      });
    } else {
      setCenter({ lat: 32.871105, lng: -80.008023 });
    }
  }, [franchise, jobs]);

  //Consider all the jobs position to re calculate the zoom
  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      jobs.map((marker) => {
        if (marker.service_address?.geo_location)
          bounds.extend({
            lat: marker.service_address?.geo_location?.latitude,
            lng: marker.service_address?.geo_location?.longitude,
          });
      });
      map.fitBounds(bounds);
    }
  }, [map, jobs]);

  //New data if the job is rescheduled
  useEffect(() => {
    if (rescheduleTechnician.value) {
      setDisableRescheduleBtn(false);
      return;
    }

    if (rescheduleDate) {
      setRescheduleRecurring(false);
      setDisableRescheduleBtn(false);
      return;
    }
    setDisableRescheduleBtn(true);
  }, [rescheduleTechnician, rescheduleDate]);

  //Open Job Popup
  const onClickMarker = async (args, element) => {
    if (!shouldOpenPopup.current) {
      return;
    }

    setBgColor(element.color);
    setCurrentJob(element);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setAnchor(args.domEvent.target);

    setOpen(true);
    shouldOpenPopup.current = true;
    setHover(false);
    setHoverJob(null);
  };

  //Open modal to confirm
  const openNotificationWithIcon = (type, title, text) => {
    notification[type]({
      message: title,
      description: text,
      icon:
        type === "success" ? (
          <CheckCircleFilled
            style={{
              color: "#52c41a",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              color: "#ff4d4f",
            }}
          />
        ),
    });
  };

  //Open modal to reschedule job
  const onRescheduleWorkOrder = async () => {
    try {
      if (rescheduleRecurring) {
        await rescheduleRecurringService({
          service_uuid: currentJob.work_order_uuid,
          technician_uuid: rescheduleTechnician.value ?? undefined,
        });
      } else {
        await rescheduleService({
          service_uuid: currentJob.work_order_uuid,
          date_to: rescheduleDate
            ? rescheduleDate.format("YYYY-MM-DD")
            : undefined,
          time_to: rescheduleTimeSelection
            ? rescheduleTimeSelection.format("HH:mm")
            : "9:00",
          technician_uuid: rescheduleTechnician?.value !== "unassign" ? rescheduleTechnician?.value : "not assigned",
        });
      }
      openNotificationWithIcon(
        "success",
        "Reschedule work order:",
        currentJob.work_order_name
      );
      setRescheduleModalOpen(false);
      setGetJobs(true);
    } catch (error) {
      setRescheduleModalOpen(false);
      openNotificationWithIcon("error", "Could not remove event");
    }
  };

  //Open modal to Delete jov
  const showDeleteModal = (item) => {
    setCurrentJob(item);
    setOpen(false);
    setDeleteModalOpen(true);
  };

  //Delete job
  const onDeleteWorkOrder = async () => {
    try {
      await deleteService(currentJob.work_order_uuid);
      openNotificationWithIcon(
        "error",
        "Delete work order:",
        currentJob.work_order_name
      );

      //Remove job from array
      const record_idx = jobs.indexOf(
        (i) => i.work_order_uuid === currentJob.work_order_uuid
      );
      jobs.splice(record_idx, 1);
      setDeleteModalOpen(false);
    } catch (error) {
      message.error("Could not remove event");
      setDeleteModalOpen(true);
    }
  };

  //Open modal to reschedule
  const showRescheduleModal = (item) => {
    setCurrentJob(item);
    setOpen(false);
    setRescheduleModalOpen(true);
  };

  //Change date to reschedule
  const onChangeTimePicker = (time) => {
    setRescheduleTimeSelection(time);
  };

  //Disable date to reschedule
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  //Reschedule all future jobs
  const onChangRecurring = (e) => {
    setRescheduleRecurring(e.target.checked);
    if (e.target.checked) {
      setRescheduleTimeSelection(moment("9:00", "HH:mm"));
      setRescheduleDate(null);
    }
  };

  //Open modal to modify job form
  const showWOModal = (item) => {
    if (window.innerWidth < 768) {
      window.open(item.job_forms[0].form_url, "_blank");
      return;
    }
    setCurrentJob(item);
    setOpen(false);
    setWOModalOpen(true);
  };

  //Close Reschedule modal
  const hideRescheduleModal = () => {
    setRescheduleModalOpen(false);
  };

  const onCloseRescheduleModal = () => {
    setRescheduleTimeSelection(null);
    setRescheduleDate(null);
    setRescheduleRecurring(false);
    setRescheduleTechnician({ value: null, label: null });
  };

  return (
    <>
      {!isLoaded ? (
        <Loader />
      ) : (
        <>
          <GoogleMap
            id="job_map"
            zoom={12}
            center={center}
            mapContainerClassName="map-container"
            options={optionsMap}
            onLoad={onLoad}
          >
            {jobs.map((item) => {
              return (
                <MarkerF
                  key={item.work_order_uuid}
                  position={{
                    lat: item.service_address?.geo_location?.latitude,
                    lng: item.service_address?.geo_location?.longitude,
                  }}
                  onClick={(element) => {
                    onClickMarker(element, item);
                  }}
                  onMouseOver={()=>{
                    setHoverJob(item);
                    setHover(true);
                  }}
                  onMouseOut={()=>{
                    setHoverJob(null);
                    setHover(false);
                  }}
                  options={{
                    icon : {
                      path:
                        "M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9 " +
                        "C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z " +
                        "M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8 c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4," +
                        "248,122.2,220.8,122.2,187.2z",
                      fillOpacity: 1.0,
                      strokeColor: item.color,
                      scale: 0.1,
                      fillColor: item.color,
                    }
                  }}
                >
                  {hover && 
                    hoverJob?.work_order_uuid === item.work_order_uuid &&
                    hoverJob?.work_order_uuid !== currentJob?.work_order_uuid && (
                    <InfoWindowF
                      position={{
                        lat: item.service_address?.geo_location?.latitude,
                        lng: item.service_address?.geo_location?.longitude,
                      }}
                    >
                      <>
                      <div
                        className="md-tooltip-header md-tooltip-notes"
                        style={{ backgroundColor: "#3D3E3F" }}
                      >
                        <span className="md-tooltip-title">
                          {hoverJob?.technician?.first_name === undefined ?
                            "Not Assigned" :
                            hoverJob?.technician?.first_name + " " + hoverJob?.technician?.last_name
                          }
                        </span> 
                        <span className="md-tooltip-text md-tooltip-notes">
                          {hoverJob?.work_order_name}
                        </span>
                      </div>
                      </>
                    </InfoWindowF>
                  )}
                  {isOpen &&
                    currentJob.work_order_uuid === item.work_order_uuid && (
                      <InfoWindowF
                        position={{
                          lat: item.service_address?.geo_location?.latitude,
                          lng: item.service_address?.geo_location?.longitude,
                        }}
                      >
                        <>
                          <div
                            className="md-tooltip-header"
                            style={{ backgroundColor: bgColor }}
                          >
                            <span>
                              {currentJob?.service_type ?? "service_type"}
                            </span>
                            <span
                              className="mbsc-font-icon mbsc-icon-close"
                              onClick={() => {
                                setCurrentJob(null);
                                setOpen(false);
                              }}
                            ></span>
                          </div>
                          <div className="md-tooltip-info">
                            <div className="md-tooltip-time">
                              <span className="md-tooltip-text">
                                {moment(
                                  currentJob?.scheduled_appointment
                                ).format("dddd, MMMM DD, YYYY")}
                              </span>
                              <span className="md-tooltip-actions">
                                {currentJob?.job_forms?.length > 0 ? (
                                  <Tooltip title="Job Form">
                                    <span
                                      className="mbsc-font-icon mbsc-icon-file4"
                                      onClick={() => {
                                        shouldOpenPopup.current = false;
                                        setTimeout(
                                          () =>
                                            (shouldOpenPopup.current = true),
                                          200
                                        );
                                        showWOModal(currentJob);
                                      }}
                                    ></span>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                {currentJob?.edit_work_order_url &&
                                !currentJob?.is_invoiced ? (
                                  <Tooltip title="Edit Work Order">
                                    <span
                                      className={`mbsc-font-icon mbsc-icon-pencil ${
                                        currentJob?.batch_invoice_processed
                                          ? "disabled"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        shouldOpenPopup.current = false;
                                        setTimeout(
                                          () =>
                                            (shouldOpenPopup.current = true),
                                          200
                                        );
                                        window.open(
                                          currentJob.edit_work_order_url,
                                          "_blank"
                                        );
                                      }}
                                    ></span>
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                {currentJob?.status !== "complete" ? (
                                  <>
                                    {role === "field_tech" ||
                                    role === "lead_tech" ? (
                                      (((currentJob?.permissionset?.includes(
                                        "assign_wo"
                                      ) ||
                                        currentJob?.permissionset?.includes(
                                          "reschedule_wo"
                                        )) &&
                                        userInfo?.user?.technician_permissions
                                          ?.reschedule_wo) ||
                                        userInfo?.user?.technician_permissions
                                          ?.assign_wo) && (
                                        <Tooltip title="Reschedule">
                                          <span
                                            className="mbsc-font-icon mbsc-icon-line-calendar"
                                            onClick={() => {
                                              shouldOpenPopup.current = false;
                                              setTimeout(
                                                () =>
                                                  (shouldOpenPopup.current = true),
                                                200
                                              );
                                              showRescheduleModal(currentJob);
                                            }}
                                          ></span>
                                        </Tooltip>
                                      )
                                    ) : (
                                      <Tooltip title="Reschedule">
                                        <span
                                          className="mbsc-font-icon mbsc-icon-line-calendar"
                                          onClick={() => {
                                            shouldOpenPopup.current = false;
                                            setTimeout(
                                              () =>
                                                (shouldOpenPopup.current = true),
                                              200
                                            );
                                            showRescheduleModal(currentJob);
                                          }}
                                        ></span>
                                      </Tooltip>
                                    )}
                                    {role === "field_tech" ||
                                    role === "lead_tech" ? (
                                      currentJob?.permissionset.includes(
                                        "delete_so"
                                      ) &&
                                      userInfo?.user?.technician_permissions
                                        ?.delete_so && (
                                        <Tooltip title="Delete">
                                          <span
                                            className="mbsc-font-icon mbsc-icon-remove"
                                            onClick={() => {
                                              shouldOpenPopup.current = false;
                                              setTimeout(
                                                () =>
                                                  (shouldOpenPopup.current = true),
                                                200
                                              );
                                              showDeleteModal(currentJob);
                                            }}
                                          ></span>
                                        </Tooltip>
                                      )
                                    ) : (
                                      <Tooltip title="Delete">
                                        <span
                                          className="mbsc-font-icon mbsc-icon-remove"
                                          onClick={() => {
                                            shouldOpenPopup.current = false;
                                            setTimeout(
                                              () =>
                                                (shouldOpenPopup.current = true),
                                              200
                                            );
                                            showDeleteModal(currentJob);
                                          }}
                                        ></span>
                                      </Tooltip>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </div>
                            <div className="md-tooltip-time">
                              <span className="md-tooltip-text">
                                {moment(currentJob?.scheduled_appointment)
                                  .utc()
                                  .format("hh:mm A")}
                              </span>
                            </div>
                            <div className="md-tooltip-name">
                              <span>{currentJob?.work_order_name}</span>
                            </div>
                            <div className="md-tooltip-status">
                              <span className="md-tooltip-title">Technician:</span>
                              <span className="md-tooltip-text" style={{ textTransform:'capitalize' }}>
                              {currentJob?.technician?.first_name === undefined ?
                                "Not Assigned" :
                                currentJob?.technician?.first_name + " " + currentJob?.technician?.last_name
                              }
                              </span>
                            </div>
                            <div className="md-tooltip-status">
                              <span className="md-tooltip-title">Customer:</span>
                              <span className="md-tooltip-text md-tooltip-notes">
                                {currentJob?.client?.first_name + " " + currentJob?.client?.last_name}
                                <a href={`tel:${currentJob?.client?.phone_numbers[0]?.phone}`}>
                                  <span className="md-tooltip-status">
                                    <img
                                      src={phoneIcon}
                                      alt=""
                                      className="phone-icon"
                                    />
                                    {currentJob?.client?.phone_numbers[0]?.phone}
                                  </span>
                                </a>
                              </span>
                            </div>
                            <div className="md-tooltip-status">
                              <span className="md-tooltip-title">Status:</span>
                              <span className="md-tooltip-status md-tooltip-text">
                                {currentJob?.status}
                              </span>
                            </div>
                            <div className="md-tooltip-notes">
                              <span className="md-tooltip-title">
                                Special Notes:
                              </span>
                              <span className="md-tooltip-text">
                                {currentJob?.special_notes}
                              </span>
                            </div>
                            <div className="md-tooltip-notes">
                              <span className="md-tooltip-title">Address:</span>
                              <span className="md-tooltip-text">
                                <Row>
                                  <Col span={3}>
                                    <img
                                      src={location}
                                      alt=""
                                      className="calendar-location"
                                    />
                                  </Col>
                                  <Col span={21}>
                                    <a
                                      target={"_blank"}
                                      rel="noreferrer"
                                      href={`https://www.google.com/maps/search/?api=1&query=${currentJob?.service_address?.formatted_address}`}
                                      style={{
                                        color: "#000",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {
                                        currentJob?.service_address
                                          ?.formatted_address
                                      }
                                    </a>
                                  </Col>
                                </Row>
                              </span>
                            </div>
                          </div>
                        </>
                      </InfoWindowF>
                    )}
                </MarkerF>
              );
            })}
          </GoogleMap>

          <Modal
            title="Reassign Technician"
            open={rescheduleModalOpen}
            onOk={onRescheduleWorkOrder}
            onCancel={() => {
              hideRescheduleModal();
            }}
            okText="Update"
            cancelText="Cancel"
            className="schedule_line_modal"
            destroyOnClose
            afterClose={onCloseRescheduleModal}
            okButtonProps={{ disabled: disableRescheduleBtn }}
          >
            <div className="fitem">
              <label>
                <b>Work Order</b>
              </label>
              <Input
                placeholder={currentJob?.work_order_name ?? "title"}
                disabled
              />
            </div>
            <div className="fitem">
              <label>
                <b>Reassign From</b>
              </label>
              <Input
                placeholder={
                  currentJob?.technician !== null
                    ? `${currentJob?.technician?.first_name} ${currentJob?.technician?.last_name}`
                    : "Not assigned"
                }
                disabled
              />
            </div>
            <div className="fitem">
              <label>
                <b>Reassign To</b>
              </label>
              {role === "field_tech" || role === "lead_tech" ? (
                <TechnicianSelector
                  permission={currentJob?.permissionset.includes("assign_wo")}
                  franchise={franchise}
                  onControlChange={({ value, label }) => {
                    setRescheduleTechnician({ value, label });
                  }}
                />
              ) : (
                <TechnicianSelector
                  franchise={franchise}
                  onControlChange={({ value, label }) => {
                    setRescheduleTechnician({ value, label });
                  }}
                />
              )}
            </div>
            <Row className="fitem">
              <Col span={12}>
                <label>
                  <b>Assign From Date</b>
                </label>
                <DatePicker
                  defaultValue={
                    currentJob?.scheduled_appointment
                      ? moment(currentJob?.scheduled_appointment)
                      : moment()
                  }
                  format={"YYYY-MM-DD"}
                  disabled
                />
              </Col>
              <Col span={12} style={{ paddingLeft: "5px" }}>
                <label>
                  <b>Time</b>
                </label>
                <TimePicker
                  value={moment(currentJob?.scheduled_appointment).utc()}
                  format={"h:mm A"}
                  disabled
                />
              </Col>
            </Row>
            <Row className="fitem">
              <Col span={12}>
                <label>
                  <b>Reassign Date</b>
                </label>
                <DatePicker
                  value={rescheduleDate}
                  onChange={(value) => {
                    value
                      ? setRescheduleDate(moment(value))
                      : setRescheduleDate(null);
                  }}
                  disabledDate={disabledDate}
                  format={"YYYY-MM-DD"}
                  popupClassName="reassignEventDatePicker"
                  disabled={
                    (role === "field_tech" || role === "lead_tech") &&
                    !currentJob?.permissionset.includes("reschedule_wo")
                  }
                />
              </Col>
              <Col span={12} style={{ paddingLeft: "5px" }}>
                <label>
                  <b>Time</b>
                </label>
                <TimePicker
                  value={
                    rescheduleTimeSelection
                      ? rescheduleTimeSelection
                      : moment("9:00", "hh:mm A")
                  }
                  format={"h:mm A"}
                  onChange={onChangeTimePicker}
                  disabled={
                    (role === "field_tech" || role === "lead_tech") &&
                    !currentJob?.permissionset.includes("reschedule_wo")
                  }
                />
              </Col>
            </Row>
            {technician.value !== "unassign" &&
            currentJob?.status === "forecast" ? (
              <>
                <Row className="fitem">
                  <Col
                    span={24}
                    style={{ textAlign: "center", margin: "15px 0" }}
                  >
                    or
                  </Col>
                </Row>
                <Row className="fitem">
                  <Col span={24}>
                    <Checkbox
                      onChange={onChangRecurring}
                      checked={rescheduleRecurring}
                      disabled={
                        (role === "field_tech" || role === "lead_tech") &&
                        !currentJob?.permissionset.includes("assign_wo")
                      }
                    >
                      Reassign all future recurring instances of this work
                      order.
                    </Checkbox>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Modal>

          <Modal
            title="Delete Work Order"
            open={deleteModalOpen}
            onOk={onDeleteWorkOrder}
            onCancel={() => setDeleteModalOpen(false)}
            okText="Delete"
            cancelText="Cancel"
            className="schedule_delete_modal"
          >
            <p>{`Are you sure you would like to delete this work order?`}</p>
            <p>Any changes made to this work order will be lost.</p>
          </Modal>

          <Modal
            title={`Work Order Form ${currentJob?.work_order_name}`}
            open={woModalOpen}
            onOk={() => setWOModalOpen(false)}
            onCancel={() => setWOModalOpen(false)}
            destroyOnClose
            className="schedule_wo_modal"
            style={{
              top: 20,
            }}
            footer={null}
          >
            <iframe
              src={currentJob?.job_forms[0]?.form_url}
              width={"100%"}
              height={windowSize.current[1] * 0.8}
              title="testa"
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default GoogleMapContainer;
