import React from 'react';
import { Carousel } from 'antd';
import home from "../Assets/images/login-02.png";
import wave from "../Assets/images/wave.png";
const RightSlider  = () => {
    return (
      <div className="sidebg text-center">
        <img src={home} alt="" />
        <img src={wave} alt=""  style={{'position':'relative','bottom':'40px'}} />
        <div className="slide">
          <Carousel autoplay>
            <div>
              <h2 className="mb-10">Manage Your Services</h2>
              <p>Keep up to date with your customers and their services through Service Management  </p>
            </div>
            <div>
              <h2 className="mb-10">Customize your reports</h2>
              <p>Take control of your reports and monitor things like your services, sales and franchises.</p>
            </div>
            <div>
              <h2 className="mb-10">Follow up on new leads</h2>
              <p>Take control of your reports and monitor things like your services, sales and franchises.</p>
            </div>
          </Carousel>
        </div>
      </div>
    );
}

export default RightSlider;