class config {
  /*
   * Return dynamic url based on host
   */
  getHost = () => {
    const host = window.location.host;
    const proto = window.location.protocol;
    return `https://api.qa2.poolops.com/api/v1/`;
    // eslint-disable-next-line
    if (host.substring(host, 9) === "localhost" || host.substring(host, 9) === "127.0.0.1")  {
      //local URL for api
      //return "http://localhost:8000/api/v1/";
      return `https://api.dev.poolops.com/api/v1/`;
    } else if (host === "crm.poolops.com") {
      return `https://api.poolops.com/api/v1/`;
    } else if (host === "crm.dev.poolops.com") {
      return `https://api.dev.poolops.com/api/v1/`;
    } else if (host === "crm.qa.poolops.com") {
      return `https://api.qa.poolops.com/api/v1/`;
    } else if (host === "crm.uat.poolops.com") {
      return `https://api.uat.poolops.com/api/v1/`;
    } else if (host === "crm.dev2.poolops.com") {
      return `https://api.dev2.poolops.com/api/v1/`;
    } else if (host === "crm.qa2.poolops.com") {
      return `https://api.qa2.poolops.com/api/v1/`;
    }
    // else{
    //   return `${proto}//api.${host}/api/v1/`;
    // }
  };
}
export default new config();
