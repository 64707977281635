import React, { useEffect, useState } from "react";
import { _, isEmpty } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import HeaderMobile from "../../Components/HeaderMobile";
import { Header } from "antd/lib/layout/layout";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import Footer from "../Residential/NewCustomer/Footer";
import Loader from "../../Components/Loader";
import ServiceInfo from "./ServiceInfo";
import CommunityInfo from "./CommunityInfo";
import { updateLead } from "../../Action/Customer";

const AddCommunityInfo = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [loader, setLoader] = useState(false);
    const [btnLabel, setBtnLabel] = useState('Save Lead');
    const [infoData, setInfoData] = useState({
        uuid: state.data.uuid || "",
        zip_code: state.data.zip_code || "",
        first_name: state.data.first_name || "",
        last_name: state.data.last_name || "",
        address: state.data.address[0] || [],
        phone_numbers: state.data.phone_numbers || [],
        emailaddress_set: state.data.emailaddress_set || [],
        authorized_users: state.data.authorized_users || [],
        hear_about_us: state.data.hear_about_us || [],
        preferred_contact_methods: state.data.preferred_contact_methods || [],
        properties_in_community: state.data.properties_in_community || [],
        company_name: state.data.company_name || "",
        no_of_properties: state.data.no_of_properties || "",
        no_of_pools: state.data.no_of_pools || "",
        notes: state.data.notes || "",
    });
    const [data, setData] = useState({});
    const onMenubuttonClick = () => {
        setOpenMenu(!openMenu);
    }
    const onDataChange = (incomingData) => {
        setData({
            ...infoData,
            ...incomingData
        });
    }
    const updateLeadDetails = async () => {
        let temp = {...data};
        setLoader(true);
        temp.phone =  data.phone_numbers[0]?.phone;
        temp.email =  data.emailaddress_set[0].email.toLowerCase();
        temp.address =  [data.address];
        const result = await updateLead(temp);
        if(state?.source === "customers-lead-list")
            navigate("/customer-lead-list", { state: {currentTab: 'lead' }});
        else if(state.data.type === 'residential')
            navigate(`/residential`, { state: {currentTab: 0 }});
        else
            navigate("/commercial/lead-list", { state: { 'tab': 0 } })
        setLoader(false);
    }
    
    useEffect(() => {
        setInfoData(data)
        setData(data)
    }, [data]);

    return (
        <div id="wrapper">
            {loader && <Loader />}
            <div className="content-area">
                <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
                <Sidebar sideBarBackgroundColor='#005d89' />
                <SidebarResponsive sideBarBackgroundColor='#005d89' openMenu={openMenu} onMenubuttonClick={onMenubuttonClick} />
                <div className='fluid'>
                    <div className='container new-cust'>
                        <div className='fluid-block'>
                            <div className='article-block'>
                                <ServiceInfo inputData={infoData} onDataChange={onDataChange} />
                                <CommunityInfo inputData={infoData} onDataChange={onDataChange} />
                                <div className="aside-bar">
                                    <div className='acol'>
                                        <h2 className="mb-20">Community Summary</h2>
                                        <h5>Contact Information</h5>
                                        {/* <div>{`${state.data.first_name} ${state.data.last_name}`} </div> */}
                                        <div>{state.data.company_name}</div>
                                        <div>{state.data.email}</div>
                                        <div>{state.data.phone_numbers[0]?.phone}</div>
                                        <hr />
                                        <h5>Community Information</h5>
                                        <div>{(state.data.address[0]?.street_number && state.data.address[0]?.route) ?
                                            `${state.data.address[0]?.street_number || ''} ${state.data.address[0]?.route || ''} ` :
                                            `${state.data.address[0]?.address || ''}`}
                                        </div>
                                        <div>{(state.data.address[0]?.street_number && state.data.address[0]?.route) ?
                                            `${state.data.address[0]?.city || ''}, ${state.data.address[0]?.state || ''} ${state.data.address[0]?.zip_code || ''}` :
                                            `${state.data.address[0]?.city || ''}, ${state.data.address[0]?.state || ''} ${state.data.address[0]?.zip_code || ''}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fix-btn-grp" style={{ width: "100%" }}>
                            {
                                state?.source === "customers-lead-list" ?
                                <Link to="/customer-lead-list" className='btn-cancel'><button className="btn btn-white">Cancel</button></Link>
                                :
                                <Link to="/commercial/lead-list" className='btn-cancel'><button className="btn btn-white">Cancel</button></Link>
                            }
                            <button className={`btn btn-bdr`} type='button' onClick={updateLeadDetails}>{'Save Lead'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCommunityInfo;