import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux/es/hooks/useSelector";
import GlobalSearch from "../Components/GlobalSearch";
import homeIcon from "../Assets/icons/home.svg";
import userIcon from "../Assets/icons/user-placeholder.svg";
import plotIcon from "../Assets/icons/plot_icon.svg";
import calendarIcon from "../Assets/icons/calendar_icon.svg";
import techIcon from "../Assets/icons/Tech-Glance-Icon.svg";

import { addProposalSearchValue } from "../Store/proposalTableSlice";

const ProposalHeaderSection = (props) => {
  const dispatch = useDispatch();
  const proposalData = useSelector((state) => state.proposalsData);

  const onSearch = async (e) => {
    dispatch(addProposalSearchValue(e));
  }

  return (
    <>
      <div>
        <Row>
          <Col flex={8}>
            <GlobalSearch />
          </Col>
          <Col className="global-links-wrapper-proposal">
            <a href="/" className="quick-link-home-proposal">
              <img src={homeIcon} alt={""} style={{ width: "40px" }} />
            </a>
            <a href="#" className="quick-link-home-proposal">
              <img src={userIcon} alt={""} style={{ width: "40px" }} />
            </a>
          </Col>
        </Row>
      </div>

      <div className="proposal-heading">
        <Row>
          <Col xxl={3} md={3}>
            <span className="Proposal-text">Proposals</span>
          </Col>
          <Col xxl={21} md={21} className="linkContainer-proposal">
            <Row className="quick-link-wrapper-proposal">
              <a href="/field_tech/plot_job" className="quick-link-container">
                <img src={plotIcon} alt={""} style={{ width: "60px" }} />
                <span className={"quick-link-title-proposal"} href="#">
                  Plot Jobs on Map
                </span>
              </a>
              <a href="/calendar" className="quick-link-container">
                <img src={calendarIcon} alt={""} style={{ width: "60px" }} />
                <span className={"quick-link-title-proposal"} href="#">
                  Calendar View
                </span>
              </a>
              <a href="/tech-glance" className="quick-link-container">
                <img src={techIcon} alt={""} style={{ width: "60px" }} />
                <span className={"quick-link-title-proposal"} href="#">
                  Tech Glance
                </span>
              </a>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ProposalHeaderSection;