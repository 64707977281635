import {
  Card,
  Slider,
  Switch,
  Checkbox,
  Select,
  Col,
  Row,
  TimePicker,
  message,
} from "antd";
import { _, isEqual, isEmpty, map } from "lodash";
import * as yup from "yup";
import moment from "moment-timezone";
// import Loader from "../../Components/Loader";
import React, { useEffect, useState } from "react";
import {
  updateFranchiseServiceNotification,
  getFranchiseServiceNotification,
} from "../../Action/Franchise";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const FranchiseServiceNotification = ({ tabChange, franchise_data }) => {
  const [errors, setErrors] = useState({});
  // const [loader, setLoader] = useState(false);
  const [pulseData, setPulseData] = useState([]);
  const [pulseCommunication, setPulseCommunication] = useState(false);
  const [pulsePreNotification, setPulsePreNotification] = useState(false);
  const [pulsePostNotification, setPulsePostNotification] = useState(false);
  const [pulseBatchNotification, setPulseBatchNotification] = useState(false);
  const [pulseBioNotification, setPulseBioNotification] = useState(false);
  const [checkedListPost, setCheckedListPost] = useState([]);
  const [checkedListPreBatch, setCheckedListPreBatch] = useState([]);
  const [checkedListPreBio, setCheckedListPreBio] = useState([]);
  const [batch_time, setBatch_time] = useState("");

  const plainOptions = ["Email", "SMS"];
  const resetFieldAll = () => {
    setPulsePreNotification(false);
    setPulsePostNotification(false);
    setPulseBatchNotification(false);
    setPulseBioNotification(false);
    setCheckedListPost([]);
    setCheckedListPreBatch([]);
    setCheckedListPreBio([]);
    setBatch_time("");
    setErrors({});
  };
  const resetFieldPre = () => {
    setPulseBatchNotification(false);
    setPulseBioNotification(false);
    setCheckedListPreBatch([]);
    setCheckedListPreBio([]);
    setBatch_time("");
    setErrors({});
  };
  const handleUpdate = async () => {
    const data = {
      id: 1,
      uuid: franchise_data?.uuid,
      pulsem_token: pulseData?.pulsem_token,
      pulsem_location_id: pulseData?.pulsem_location_id,
      // batch_time: !batch_time ? '12:00:00' : moment(batch_time).format("HH:mm:ss") === "Invalid date" ? formatTime(batch_time) : moment(batch_time).format("HH:mm:ss"),
      // batch_time: batch_time? moment.tz(batch_time, 'UTC').format("YYYY-MM-DD HH:mm:ss") : null,
      batch_time: batch_time
        ? moment(batch_time).format("YYYY-MM-DD HH:mm")
        : null,
      batch_email: checkedListPreBatch.includes("Email"),
      batch_sms: checkedListPreBatch.includes("SMS"),
      bio_email: checkedListPreBio.includes("Email"),
      bio_sms: checkedListPreBio.includes("SMS"),
      check_email: checkedListPost.includes("Email"),
      check_sms: checkedListPost.includes("SMS"),
    };
    // if (data.batch_email || data.batch_sms) {
    //   data.batch_time = batch_time;
    // }
    let temp_url = await updateFranchiseServiceNotification(
      franchise_data?.uuid,
      data
    );
    if (!isEmpty(temp_url.pulsem_token)) {
      message.success("Updated Successfully");
    } else {
      message.error("Pulsem token and Location ID Required");
    }
    // setLoader(false);
  };
  const onChangePulseMCommunication = (check) => {
    setPulseCommunication(check);
    if (!check) {
      resetFieldAll();
    }
  };
  const onChangePulsePreNotification = (check) => {
    setErrors({});
    setPulsePreNotification(check);
    if (!check) {
      resetFieldPre();
    } else {
      setPulseBatchNotification(true);
    }
  };
  const onChangePulsePostNotification = (check) => {
    setPulsePostNotification(check);
    setErrors({});
    if (!check) {
      setCheckedListPost([]);
    }
  };
  const onChangePulseBatchNotification = (check) => {
    setErrors({});
    setPulseBatchNotification(check);
    setPulseBioNotification(!check);
    if (!check) {
      setCheckedListPreBatch([]);
      setBatch_time("");
    }
  };
  const onChangePulseBioNotification = (check) => {
    setErrors({});
    setPulseBioNotification(check);
    setPulseBatchNotification(!check);
    if (!check) {
      setCheckedListPreBio([]);
    }
  };

  const onChangeCheckBoxPost = (list) => {
    setCheckedListPost(list);
  };
  const onChangeCheckBoxPreBatch = (list) => {
    setCheckedListPreBatch(list);
  };
  const onChangeCheckBoxPreBio = (list) => {
    setCheckedListPreBio(list);
  };
  const getData = async () => {
    // setLoader(true);
    let data = await getFranchiseServiceNotification(franchise_data?.uuid);
    if (data.uuid) {
      // setLoader(false);
      if (
        !data?.batch_email &&
        !data?.batch_sms &&
        !data?.batch_time &&
        !data?.bio_email &&
        !data?.bio_sms &&
        !data?.check_email &&
        !data?.check_sms
      ) {
        setPulseCommunication(false);
        setPulseBatchNotification(true);
      } else {
        setPulseCommunication(true);
      }
      if (data?.batch_email || data?.batch_sms || data?.batch_time) {
        setPulsePreNotification(true);
        setPulseBatchNotification(true);
        let temp = [];
        if (data.batch_time) {
          // let temp = moment(data.batch_time, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
          let temp = moment
            .tz(data.batch_time, "UTC")
            .format("YYYY-MM-DD HH:mm");
          setBatch_time(temp);
        }
        if (data.batch_email && data.batch_sms) {
          temp.push("SMS");
          temp.push("Email");
        } else if (data.batch_sms) {
          temp.push("SMS");
        } else {
          temp.push("Email");
        }
        setCheckedListPreBatch(temp);
      }
      if (data?.bio_email || data?.bio_sms) {
        setPulsePreNotification(true);
        setPulseBioNotification(true);
        let temp = [];
        if (data.bio_email && data.bio_sms) {
          temp.push("Email");
          temp.push("SMS");
        } else if (data.bio_sms) {
          temp.push("SMS");
        } else {
          temp.push("Email");
        }
        setCheckedListPreBio(temp);
      }
      if (data?.check_email || data?.check_sms) {
        setPulsePostNotification(true);
        let temp = [];
        if (data.check_email && data.check_sms) {
          temp.push("Email");
          temp.push("SMS");
        } else if (data.check_sms) {
          temp.push("SMS");
        } else {
          temp.push("Email");
        }
        setCheckedListPost(temp);
      }
      setPulseData(data);
    }
  };

  // Validate Form
  const validate = () => {
    // setLoader(true);
    const schema = yup.object().shape({
      checkedListPost: pulsePostNotification
        ? yup.array().min(1).defined("Contact method is required")
        : null,
      checkedListPreBatch:
        pulsePreNotification && pulseBatchNotification
          ? yup.array().min(1).defined("Contact method is required")
          : null,
      checkedListPreBio:
        pulsePreNotification && pulseBioNotification
          ? yup.array().min(1).defined("Contact method is required")
          : null,
      batch_time:
        pulsePreNotification && pulseBatchNotification
          ? yup.string().required("Time is required")
          : null,
    });

    const dataObj = {
      checkedListPost,
      checkedListPreBatch,
      checkedListPreBio,
      batch_time,
    };
    // check validity
    return schema
      .validate(dataObj, { abortEarly: false })
      .then(() => {
        setErrors({});
        handleUpdate();
        return Promise.resolve("success");
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log("error-->> ", err_data);
        return Promise.reject("fail");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {/* {loader && <Loader/>} */}
      <Card
        size="medium"
        style={{ width: 400,padding:10,backgroundColor:"#EBF0EC"
        }}
        title={<h6 style={{ margin: "6px" }}> PulseM Communication</h6>}
        // extra={
        //   <Switch
        //     size="small"
        //     className={
        //       pulseCommunication ? "switch-active" : "switch-nonactive"
        //     }
        //     checked={pulseCommunication}
        //     onChange={onChangePulseMCommunication}
        //   ></Switch>
        // }
      >
        {/* {pulseCommunication && ( */}
        <>
          <div style={{ padding: "6px 0px" }}>
            <>
              <Row>
                <Col span={22}>
                  <h6> PulseM Pre-Service Notification</h6>
                </Col>
                <Col span={2}>
                  <Switch
                    size="medium"
                    className={
                      pulsePreNotification
                        ? "switch-active"
                        : "switch-nonactive"
                    }
                    checked={pulsePreNotification}
                    onChange={onChangePulsePreNotification}
                  ></Switch>
                </Col>
              </Row>
              {pulsePreNotification && (
                <>
                  <div className={pulseBioNotification && "disableddiv"}>
                    <Row style={{ margin: "12px 0px" }}>
                      <Col span={3}>
                        <Switch
                          size="medium"
                          className={
                            pulseBatchNotification
                              ? "switch-active"
                              : "switch-nonactive"
                          }
                          checked={pulseBatchNotification}
                          onChange={onChangePulseBatchNotification}
                        ></Switch>
                      </Col>
                      <Col span={21}>
                        <h6 style={{ margin: "4px" }}>
                          {" "}
                          PulseM Batch Notification (Bio)
                        </h6>
                      </Col>
                    </Row>
                    <div style={{ padding: "0px 51px" }}>
                      <CheckboxGroup
                        options={plainOptions}
                        value={checkedListPreBatch}
                        onChange={onChangeCheckBoxPreBatch}
                      />
                      {errors?.["checkedListPreBatch"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["checkedListPreBatch"]}
                        </label>
                      ) : (
                        ""
                      )}
                      <TimePicker
                        style={{ marginTop: "10px" }}
                        suffixIcon={<i className="las la-angle-down"></i>}
                        value={batch_time ? moment(batch_time) : ""}
                        onChange={(e) => {
                          setBatch_time(e);
                        }}
                        format={"HH:mm"}
                      />
                      {errors?.["batch_time"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["batch_time"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className={pulseBatchNotification && "disableddiv"}>
                    <Row style={{ margin: "12px 0px" }}>
                      <Col span={3}>
                        <Switch
                          size="medium"
                          className={
                            pulseBioNotification
                              ? "switch-active"
                              : "switch-nonactive"
                          }
                          checked={pulseBioNotification}
                          onChange={onChangePulseBioNotification}
                        ></Switch>
                      </Col>
                      <Col span={21}>
                        <h6 style={{ margin: "4px" }}>
                          {" "}
                          PulseM Pre-Service Notification (Bio)
                        </h6>
                      </Col>
                    </Row>
                    <CheckboxGroup
                      style={{ padding: "0px 51px" }}
                      options={plainOptions}
                      value={checkedListPreBio}
                      onChange={onChangeCheckBoxPreBio}
                    />
                    {errors?.["checkedListPreBio"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.["checkedListPreBio"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </>
          </div>
          <hr />
          <Row>
            <Col span={22}>
              <h6> PulseM Post-Service Notification (Check)</h6>
            </Col>
            <Col span={2}>
              <Switch
                size="medium"
                className={
                  pulsePostNotification ? "switch-active" : "switch-nonactive"
                }
                checked={pulsePostNotification}
                onChange={onChangePulsePostNotification}
              ></Switch>
            </Col>
          </Row>
          {pulsePostNotification && (
            <>
              <CheckboxGroup
                style={{ padding: "0px 10px" }}
                options={plainOptions}
                value={checkedListPost}
                onChange={onChangeCheckBoxPost}
              />
              {errors?.["checkedListPost"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.["checkedListPost"]}
                </label>
              ) : (
                ""
              )}
            </>
          )}
          <div style={{ textAlign: "right", margin: "16px 0px 0px" }}>
            {" "}
            <button onClick={validate} className="btn btn-warning">
              Update Preference
            </button>
          </div>
        </>
        {/* )} */}
      </Card>
    </div>
  );
};
export default FranchiseServiceNotification;
