import React from "react";
import { Modal } from "antd";

export const WorkOrderFormModalTechGlance = (props) => {
    const { selectedEvent, woModalOpen, setWOModalOpen, windowSize } = props;
    return (
      <Modal
        title={`Work Order Form ${selectedEvent?.original.title}`}
        open={woModalOpen}
        onOk={() => setWOModalOpen(false)}
        onCancel={() => setWOModalOpen(false)}
        destroyOnClose
        className="schedule_wo_modal"
        style={{
          top: 20,
        }}
        footer={null}
      >
        <iframe
          src={selectedEvent?.original?.job_forms?.[0]?.form_url}
          width={"100%"}
          height={windowSize.current[1] * 0.8}
          title="testa"
        />
      </Modal>
    )
}