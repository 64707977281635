import React, { Component } from 'react';
import { Table } from 'antd';
import Datatable from '../../DataTable';
class CommercialTable extends Component {
  render() {
    let {
      commercial_selected_row_keys,
      commercial_staff_pagination,
      commercial_allocated_service,
      handleSelectAll,
      renderHeaderCheckbox
    } = this.props;

    return (
      <div>
        <Table
          rowKey={record => record.uuid}
          dataSource={commercial_allocated_service}
          pagination={commercial_staff_pagination}
          rowSelection={{
            type:'checkbox',
            preserveSelectedRowKeys: true,
            selectedRowKeys: commercial_selected_row_keys,
            columnTitle: () => renderHeaderCheckbox(),
            onSelectAll: () => handleSelectAll("commercial"),
            onChange: (commercial_selected_row_keys) => {
              this.props.onCommercialSelectedRowKeysChange(commercial_selected_row_keys)
            },
            getCheckboxProps: (record) => {
              return {
                disabled: record.is_selected || record.status === 'cancelled'
              };
            }
          }}
          onChange={this.props.commercialPaginationChange}
          columns={Datatable.ServiceToPerform()}
        />
        {commercial_staff_pagination.total > 0 ? this.props.commercial_staff_page_size_limit : ""}
      </div>
    );
  }
}

export default CommercialTable;