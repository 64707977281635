import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class WorkOrderOutsideAlerter extends Component {

  constructor(props) {
    super(props);
    this.setWorkOrderRef = this.setWorkOrderRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWorkOrderRef(node) {
    this.workOrderWrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.workOrderWrapperRef && !this.workOrderWrapperRef.contains(event.target)) {
      this.props.closeWorkOrderFilter();
    }
  }

  render() {
    return <div ref={this.setWorkOrderRef}>{this.props.children}</div>;
  }
}

WorkOrderOutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
};
