import React, { useState } from "react";
import "../../Assets/css/dashboard.css";
import HeaderMobile from "../../Components/HeaderMobile";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import { DashboardProvider } from "../Hooks/Dashboard.Hook";
import DashboardContainer from "../Component/Container/Dashboard.Container";
import DashboardGlobalSearchContainer from "../Component/Container/Dashboard.GlobalSearch.Container";
import { CalendarProvider } from "../../Calendar/Hooks/Calendar.Hook";

import StaxModal from "../../Customer/Common/Stax.Modal";

const Dashboard = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const [open, setOpen] = useState(true);
  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div id="wrapper">
      <CalendarProvider>
        <div className="dashboard-ca content-area">
          {/* Header */}
          <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
          <DashboardGlobalSearchContainer />
          {/* Sidebar */}
          <Sidebar sideBarBackgroundColor="#005d89" />
          <SidebarResponsive
            sideBarBackgroundColor="#005d89"
            openMenu={openMenu}
            onMenubuttonClick={onMenubuttonClick}
          />
          <div className="container">
            <DashboardProvider>
              {/*<StaxModal open={open} setOpen={(operation) => setOpen(operation)}/>*/}
              <DashboardContainer />
            </DashboardProvider>
          </div>
        </div>
      </CalendarProvider>
    </div>
  );
};

export default Dashboard;
