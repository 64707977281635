/*eslint no-unused-vars: "off"*/
import React from 'react';
import moment from 'moment-timezone';

// maping Disposition for hardcoded
const DISPOSITION_MAPPING = {
  complaint: 'Complaint',
  missed_service: 'Missed service',
  schedule_problem: 'Schedule Problem',
  unhappy_with_service: 'Unhappy With Service',
  issue_with_tech: 'Issue With Tech',
  driving: 'Driving',
  billing: 'Billing',
  missed_svc: 'Missed SVC',
  edit_existing_service: 'Edit existing Service',
  other: 'Other'
};
const accessToken = JSON.parse(localStorage.getItem("authUser"))
  ? JSON.parse(localStorage.getItem("authUser")).user
  : "";

class DataTable {

  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date)
  }

  //User List
  AllMessageList = (self) => {
    const messageColumns = [
      {
        title: 'Ticket name',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => (<span className="type ellipse">{text}</span>)
      },
      {
        title: 'Customer name',
        dataIndex: 'user_details',
        key: 'user_details',
        render: (text, record) => (<span className="type ellipse">{record.user_details?.first_name} {record.user_details?.last_name}</span>)
      },
      {
        title: 'Franchise',
        dataIndex: 'field_office',
        key: 'field_office',
        render: (text, record) => (<span className="type ellipse">{record?.field_office?.name}</span>)
      },
      {
        title: 'Disposition',
        dataIndex: 'disposition',
        key: 'disposition',
        className: 'lfbdr',
        render: (text, record) => (<span className="type ellipse">{DISPOSITION_MAPPING[record.reason]}</span>)
      },
      {
        title: 'Assigned to',
        dataIndex: 'assigned_to',
        key: 'assigned_to',
        render: (text, record) => (<span className="type ellipse">

          {

            record.assigned_user_details ?
              <React.Fragment>
                {accessToken.uuid === record.assigned_user_details.uuid ? <b>Me</b> :
                  <React.Fragment>
                    {record.assigned_user_details.first_name} {record.assigned_user_details.last_name}
                  </React.Fragment>
                }
              </React.Fragment>
              : ''
          }

        </span>)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (<span className="type ellipse">
          {record.status ?

            <React.Fragment>
              {
                record.status === "open" ? <span className="status_before primary"><i></i>Open</span>
                  : <span className="status_before gray"><i></i>Closed</span>
              }
            </React.Fragment>
            : ''}
        </span>)
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        render: (text, record) => (<span className="type ellipse">
          {
            record.priority ?
              <React.Fragment>
                {
                  record.priority === "3" ? <span className="status_before high"><i></i>High</span>
                    : record.priority === "4" ? <span className="status_before medium"><i></i>Standard</span> : ''
                }
              </React.Fragment>
              : ''
          }

        </span>)
      },
     

      {
        title: 'Follow Up Date',
        dataIndex: 'follow_up_date',
        key: 'follow_up_date',
        render: (text, record) => {
          return (<span className="type ellipse">
            {record.follow_up_date ? moment(record?.follow_up_date).format("MMM DD, YYYY"):'-'}
          </span>)
        }
      },
      {
        title: 'Ticket Created',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter:true,
        render: (text, record) => {
          return (<span className="type ellipse">
            {record.created_at && this.UTCToLocal(record?.created_at).format("MMM DD, YYYY")}
          </span>)
        }
      },

    ]
    return messageColumns;
  }

}

export default new DataTable();