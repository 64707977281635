import React, { useState, useEffect } from "react";
import { Drawer, message, Select, Radio, Input, Space, RadioChangeEvent, Modal } from "antd";
import Loader from "../../../Components/Loader";
import { map } from "lodash";
import * as yup from "yup";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";
import '../Invoices/refundPayment.css';
import { refundInvoice } from "../../../Action/Invoice";
import {

  getUserBillingInfo,

} from "../../../Action/Customer";
const { Option } = Select;

const Refund = (props) => {
  let filterList = [];
  const [invoiceID, setInvoiceID] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [state, setState] = useState({
    quickBookAccount: '',
    loader: false,
    staffList: {},
    errors: {},
    franchise_uuid: '',
    invoice_uuid: '',
    received_by_uuid: '',
    user_uuid: '',
    amount: '',
    reason: '',
    // issueTo: props?.cardList?.count === 0 ? 'check' : 'cc',
    issueTo: 'check',
    list: props.List,
    refundType: 'invoice',
    actionIssueRefund: props.actionIssueRefund,
    authUser: localStorage.getItem('authUser'),
    payment_method_type: props.invoice_uuid.payment_method_type,

    amount_received: 0,
    amount_received_error: false,
    refundTypeValue: props.refundTypeValue,
    actionValue: ''
  });
  const [radioValue, setRadioValue] = useState("check");
  const getQuickbooksAccountList = () => {
    setOptions([
      { key: "Maintenance", value: "Maintenance" },
      { key: "Service", value: "Service - flat rate" },
      { key: "Retail_Supplies", value: "Retail supplies" },
      { key: "Labor", value: "Labor" },
      { key: "Subcontract_Income", value: "Subcontract income" }
    ]);
  };
  useEffect(() => {
    getQuickbooksAccountList();
    const { customerId } = props;
    if (props.invoice_uuid) {
      setState((s) => ({
        ...s,
        invoice_uuid: props.invoice_uuid
      }))
    }
    //get invoice uu id
    const invoice = props.invoiceList.find((item) => {
      return item.uuid === props.invoice_uuid
    });
    setInvoiceID(invoice.invoice_id)
    //userBillingInfo()
    let authUser = JSON.parse(localStorage.getItem('authUser'))
    const associated_field_offices = authUser?.user?.associated_field_offices?.map(x => x.uuid);
    //props.getStaffUsers({ staff_profile__field_office__uuid: associated_field_offices?.[0] });
    setState((s) => ({ ...s, franchise_uuid: associated_field_offices?.[0], received_by_uuid: authUser?.user?.uuid, user_uuid: customerId, authUser: authUser?.user?.first_name + " " + authUser?.user?.last_name }))
    if (props.invoice_uuid.uuid) {
      props.getInvoiceById(props.invoice_uuid.uuid)
    }
  }, []);
  // const userBillingInfo = async () => {
  //   const data = await getUserBillingInfo({ user__uuid: props?.record?.uuid });
  //   var size = Object.keys(data).length;
  //   console.log("size",data.data.length);
  //   setCardList(size);

  // };
  const getDerivedStateFromProps = (nextProps, prevState) => {
    const { invoice_uuid, cardList, amount_received } = this.state;
    if (Object.keys(nextProps?.Invoice?.errors).length > 0 && nextProps?.Invoice?.errors !== props?.Invoice?.errors) {
      setState({ loader: false });
      // errorHandle(nextProps?.Invoice?.errors)
    }
    if (Object.keys(nextProps?.Invoice?.invoiceDetail).length > 0
      && JSON.stringify(nextProps?.Invoice?.invoiceDetail?.amount_received) !== JSON.stringify(amount_received)) {
      setState((s) => ({
        ...s,
        loader: false,
        amount_received: nextProps?.Invoice?.invoiceDetail?.amount_received - nextProps?.Invoice?.invoiceDetail?.refund_amount
      }))
    }
    // get staff list
    if (nextProps?.Invoice && Object.keys(nextProps?.Invoice?.staffList).length > 0
      && JSON.stringify(props.Invoice?.staffList) !== JSON.stringify(nextProps?.Invoice?.staffList)) {
      setState((s) => ({ ...s, staffList: nextProps?.Invoice?.staffList, loader: false }))
    }

    if (nextProps.invoice_uuid && nextProps.invoice_uuid !== invoice_uuid) {
      setState((s) => ({ invoice_uuid: nextProps.invoice_uuid.uuid, loader: false }))
    }

    // get staff list
    if (nextProps?.Invoice && Object.keys(nextProps?.Invoice?.refundPayment).length > 0
      && JSON.stringify(props.Invoice?.refundPayment) !== JSON.stringify(nextProps?.Invoice?.refundPayment)) {
      message.success(nextProps?.Invoice?.refundPayment?.message, 8)

      setTimeout(() => this.setState({ loader: false }, () => {
        onClear()
        props.getInvoiceList()
        props.getInvoiceSummary()
      }), 1000)
    }

    // Get remaining credit balance
    if (nextProps?.RemainingCreditBalance?.data && nextProps?.RemainingCreditBalance?.data !== props.RemainingCreditBalance?.data) {
      setState((s) => ({
        ...s,
        loader: false,
        amount: nextProps?.RemainingCreditBalance?.data?.amount,
        amount_received: nextProps?.RemainingCreditBalance?.data?.amount
      }));
    }
  }
  const onChange = (e) => {
    setRadioValue(e.target.value);
    setState({ ...state, issueTo: e.target.value });
  };

  const ontogglemodal = () => {
    setState({ ...state, loader: false });
    setIsModalOpen(false);
  }
  const validate = () => {

    //setState((s) => ({...s, loader: true }));

    // console.log('state--->> ', state);
    const { invoice_uuid, received_by_uuid, amount, refundType, amount_received, quickBookAccount } = state;
    // console.log("this is amount",amount);
    // const { invoice_uuid, received_by_uuid, amount, refundType, amount_received } = state;

    //const { invoice_uuid, received_by_uuid, amount, refundType, amount_received,quickBookAccount } = state;

    let finalAmount = Number(amount);
    // let schema='';
    // if(finalAmount === ""){
    //     schema = yup.object().shape({
    //       amount: yup.number().required("Amount is required")
    //     });
    // }
    // else if (finalAmount > amount_received || finalAmount === 0 || finalAmount === "0" || finalAmount === "" ) {
    //     schema = yup.object().shape({
    //       amount: yup.number().required("Amount is should not be greater than the required amount")
    //     });
    // } else if(finalAmount === 0 || finalAmount === "0") {
    //     schema = yup.object().shape({
    //       amount: yup.number().required("Amount should be greater than zero")
    //     });
    // }



    const schema = yup.object().shape({
      amount: yup.number().required("Amount is required").moreThan(0, 'Amount is required').typeError(),
      quickBookAccount: yup.string().required("Select A Quick Book Account"),
    });

    const data = { invoice_uuid, received_by_uuid, amount: finalAmount, quickBookAccount, refundType };
    // const data = { invoice_uuid, received_by_uuid, amount:finalAmount, refundType };
    //onRefund();
    setTimeout(() => {
      schema.validate(data, { abortEarly: false }).then(() => {
        setIsModalOpen(true);

        setState((s) => ({ ...s, errors: {} }));

        // console.log('validated fdsd');

        const invoice = props.invoiceList.find((item) => {
          return item.uuid === props.invoice_uuid
        });
        // console.log("invoice",invoice);
        const balance = Number(invoice.balance_due);


        // if(finalAmount > balance ){

        //     message.error(`Amount should be less or equal to $${balance}`)
        // }else{
        //     onRefund();
        // }


      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        console.log('err_data--->>> ', err_data);
        setState(() => ({ ...state, errors: err_data, is_btn_active: false }));
      });
    }, 200);



  }



  const onRefund = () => {
    setIsModalOpen(false);
    const {
      invoice_uuid,
      received_by_uuid,
      amount,
      reason, issueTo, refundType,
      user_uuid
    } = state;



    //   "invoice": "string",
    // "processed_by": "string",
    // "amount": "string",
    // "reason": "string",
    // "refund_type": "string",
    // "issue_to": "check",
    // "customer_uuid": "string"

    // if (refundType === 'pre-payment') {
    //   data = { ...data, customer_uuid: user_uuid }
    // }
    // else {
    //   data = { ...data, invoice: invoice_uuid }
    // }
    setState((s) => ({ ...s, loader: true }));
    // props.refundPayment({...data});
    // console.log("invoice uuid",props.invoiceList[0].uuid);
    let authUser = JSON.parse(localStorage.getItem('authUser'));
    let data = {
      processed_by: authUser?.user?.uuid,
      amount: state.amount,
      reason: state.reason,
      issue_to: state.issueTo,
      refund_type: 'invoice',
      //invoice:props.invoiceList[0].uuid,
      qb_account_type: state.quickBookAccount.toLowerCase(),
      invoice: props.invoice_uuid,
    }
    scheduleRefund(data);
  }

  const scheduleRefund = async (data) => {
    let response = await refundInvoice(data);
    setState((s) => ({ ...s, loader: false }));
    let status; 
    if(typeof response?.status === 'object') {
      status = response?.status[0]; 
    } else {
       status = response?.status; 
    }
    if (status !== 200) {
      message.error(response.data.message);
      onClear();
    } else {
      message.success(response.data.message);
      onClear();
    }
  }
  const onClear = () => {
    setState({ ...state, invoice_uuid: '', received_by_uuid: '', amount: '', reason: '', actionIssueRefund: false, payment_method_type: '' });
    props.closeDrawer(false);
  }

  const onChangeIssueTo = e => {
    setState((s) => ({
      ...s,
      issueTo: e.target.value,
    }));
  };

  const onTextChange = (e, type) => {
    // console.log('value-->> ', e.target.value);
    const { customerId } = props;
    // if (e.target.name === "'refundType'") {
    //   setState({ [e.target.name]: e.target.value });
    // }
    // if (e.target.name === "invoice_uuid") {
    //   let v = JSON.parse(e.target.value);
    //   props.getInvoiceById(v.uuid);
    //   setState({ [e.target.name]: v.uuid, payment_method_type: v.payment_method_type });
    // } else if (e.target.value === "pre-payment") {
    //   const invoiceList = props.List?.map(l => {
    //     return l.uuid;
    //   });
    //   const value = type === 'bool' ? e.target.checked : e.target.value;
    //   setState({ [e.target.name]: value, invoice_uuid: invoiceList?.join("#"), payment_method_type: '' });
    //   props.remainingCreditBalance({ 'customer_uuid': customerId });
    // } else {
    //   const value = type === 'bool' ? e.target.checked : e.target.value;
    //   setState({ [e.target.name]: value })
    // }
    // if(e.target.value === 'pre-payment') {
    //   const value = type === 'bool' ? e.target.checked : e.target.value;
    //   setState({ [e.target.name]: value })
    // }
    if (e.target.name === 'amount') {
      const inputValue = e.target.value;
      // Check if the input contains a decimal point and has more than two decimal places
      if (inputValue.indexOf('.') > -1 && inputValue.split('.')[1].length > 2) {
        return; // Do nothing if the input is invalid
      }
      // Update the state with the valid input value
      setState((s) => ({ ...s,amount: inputValue }));
    }

    if (e.target.name === 'reason') {
      setState((s) => ({ ...s, reason: e.target.value }));
    }

  }
  const onNumericInputChange = (value) => {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')

  }
  // on search received by
  const onSearchReceivedBy = (search) => {
    if (search?.length === 0 || search?.length >= 3) {
      props.getStaffUsers({ search: search, staff_profile__field_office__uuid: state.franchise_uuid })
    }
  }
  const onReceiveChange = (e) => {
    // let { payment_log } = state;
    // payment_log = Object.assign(payment_log, { 'received_by_uuid': e });
    // setState((state)=>(
    //   {...state,payment_log}
    // ))
  }
  return (

    <Drawer
      placement={'right'}
      closable={false}
      onClose={() => onClear()}
      visible={'visible'}
      key={'right'}
      width={'400'}
      className="drawer-wrapper"
    >
      <div className="rdrawer log_payment_drawer">
        <div className="card-row">
          <div className="card-full">
            {state.loader && <Loader />}
            <h4>Refund Payment</h4>
            <div className={'form-group'}>
              <div className="fitem">
                <label>Refund Type</label>
                <input type="text" className="refund_select" style={{ marginBottom: '6px' }} placeholder="Invoice" name="refundType" max={5} maxLength={5} value={'Invoice'} readonly />
                <input type="text" className="refund_select" placeholder="Invoice Id" name="refundType" max={5} maxLength={5} value={invoiceID} readonly />
              </div>
              <div className="fitem text_with_place">
                <label>Issue to</label><label style={{ color: 'red' }}> &nbsp;*</label>
              </div>
              <div className="fitem text_with_place">
                <Radio.Group onChange={onChange} value={radioValue}>
                  <Space direction="vertical">
                    <Radio value={"check"}>Check</Radio>
                    <Radio disabled={!props.cardList} value={"cc"}>Credit Card</Radio>
                    <Radio disabled={!props.isAch} value={"ach"}>ACH Payment</Radio>
                  </Space>
                </Radio.Group>
                {/* {errors?.['issueTo'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['issueTo']}</label> : ''} */}
              </div>
              {/* <div className="fitem text_with_place ">
                <label>Amount</label>
                <div style={{ "position": "relative" }}>
                  <input type="text"
                    className="twhite"
                    name="amount"
                    value={state.amount}
                    onChange={(e) => formatToCurrency(e.target.value) && onTextChange(e)}
                    onPaste={(event) => {
                      if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="$0.00"
                  />
                </div>
              </div> */}
              <div className="fitem amount-process">
                <label>Amount</label><label style={{ color: 'red' }}> &nbsp;*</label>
                <input type="number"
                  className="refund_select"
                  placeholder="$0.00"
                  name="amount"
                  value={state.amount}
                  onChange={(e) => formatToCurrency(e.target.value) && onTextChange(e)}
                  onPaste={(event) => {
                    if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                      event.preventDefault();
                    }
                  }} />
                {state.errors?.['amount'] ? <label className="new_err_msg"><i className="las la-info-circle" />{state.errors?.['amount']}</label> : ''}


              </div>
              <div className="fitem">
                <label>Select Quick Books Account</label><label style={{ color: 'red' }}> &nbsp;*</label>
                <Select
                  placeholder="Select The Quick Book Account"
                  id="quickBookAccount"
                  name="quickBookAccount"
                  showArrow={true}
                  virtual={false}
                  value={state.quickBookAccount}
                  options={options}
                  onChange={(value) => setState((s) => ({ ...s, quickBookAccount: value }))}
                  className="refund_select">
                </Select>
                {state.errors?.['quickBookAccount'] ? <label className="new_err_msg"><i className="las la-info-circle" />{state.errors?.['quickBookAccount']}</label> : ''}
              </div>


              <div className="fitem">
                <label>Reason</label>
                <textarea
                  className="refund_select"
                  rows="5"
                  name="reason"
                  type="text"
                  placeholder={"Reason"}
                  value={state.reason}
                  onChange={(e) => onTextChange(e)} />
              </div>
              <div className="fitem">
                <label>Issued By</label>
                <input type="text" className="refund_select" placeholder="Issued By" name="issuedBy" max={5} maxLength={5} value={state.authUser} readonly />
              </div>
            </div>
          </div>
          <div className="footer" style={{ 'width': '400px' }}>
            <button
              className="btn btn-brand btn-bdr"
              onClick={() => onClear()}
            >Cancel</button>
            <button
              className="btn btn-brand btn-bdr"
              onClick={() => validate()}
            >Refund</button>
          </div>
        </div>
        <Modal
          title="Refund Amount "
          visible={isModalOpen}
          onCancel={() => ontogglemodal()}
          className="delete_user_modal"
          width={400}
          footer={[
            <button
              key="cancel"
              className="btn btn-brand btn-bdr"
              onClick={() => ontogglemodal()} >
              No
            </button>,
            <button
              key="upload"
              className={`btn btn-waning`}
              onClick={() => onRefund()}>
              Yes
            </button>,
          ]}
        >
          <div>
            <h2> Are you sure you want to issue the Refund? </h2>
          </div>
        </Modal>
      </div>
    </Drawer>

  );
}
export default Refund;