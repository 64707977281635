import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Select, message, Popconfirm, Button as ButtonAntd } from "antd";
import { map, orderBy, _ } from 'lodash';
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux/es/hooks/useSelector";
import userimg from "../Assets/icons/down_arrow.svg";
import appData from "../Utils/constants";
import { updateProposalStatus, getProposalReport, sendBulkEmailProposal } from '../Action/Proposal';
import { setProposalStatusFromEdit, setSelectedRowCount } from "../Store/proposalTableSlice";

const ProposalsButtonSection = (props) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const proposalData = useSelector((state) => state.proposalsData);

  const [editToggle, setEditToggle] = useState(false);
  const [actionToggle, setActionToggle] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const ref = useRef();
  const actionRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (actionToggle && actionRef.current && !actionRef.current.contains(event.target)) {
        let selElemClass = event.target.className;
        if (!selElemClass.includes("proposalActionbtn")) {
          setActionToggle(!actionToggle);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [actionToggle]);

  const onProposalCancel = () => {
    setEditToggle(false);
    dispatch(setProposalStatusFromEdit(""));
  }

  const onUpdateProposal = async () => {
    setEditToggle(false);
    let obj = {
      proposal_status: proposalData?.proposalStatusEdit,
      proposal_uuid: proposalData?.selectedRowsCount
    }
    const response = await updateProposalStatus(obj);
    if (response?.message) {
      message.success(`Status of the respective Proposal(s) has been changed successfully`, 5);
    }
    else{
      message.error(`Error occurred while updating Proposal(s).`, 5);
    }
    props.getProposalList();
    dispatch(setProposalStatusFromEdit(""));
  }

  const onBulkEmailAction = async () => {
    let obj = {
      proposal_uuid_list: proposalData?.selectedRowsCount
    }
    const response = await sendBulkEmailProposal(obj);
    if (Array.isArray(response?.message) && response?.message?.length > 0) {
      message.success(`Proposals Emailed`, 7);
    }
    else{
      message.error(`Proposals Emailed failed.`, 7);
    }
    props.getProposalList();
  }

  const onExportProposalTable = async (type, is500) => {
    let payLoad = {
      ...proposalData.exportFilters,
      is_500: is500,
      export_in: type
    };
    setLoadingExport(true);
    const response = await getProposalReport(payLoad);
    setLoadingExport(false);
  }

  const onProposalStatusSelect = (e) => {
    dispatch(setProposalStatusFromEdit(e));
  }

  const actionsListClick = (type) => {
    switch (type) {
      case "New Proposal":
        setActionToggle(false);
        break;
      case "Bulk Email Proposal":
        onBulkEmailAction();
        setActionToggle(false);
        break;
      default:
    }
  };

  return (
    <>
      <div className="proposalRightButton">        
        {(proposalData.proposalResponse.count > 500  ) ?
          <Popconfirm            
            placement="bottom"
            onConfirm={() => !loadingExport && onExportProposalTable("csv_format",1)}
            okText="Export"
            cancelText="Cancel"
            icon={<></>}
            title={
              <>
                <div>
                  Proposal list data is more than 500 records. Data would be
                  <br />
                  exported and emailed to
                  {' '}
                  <b>{proposalData?.loggedInUserEmail}</b>.
                </div>
              </>
            }
          >
            <Button className="proposalbtn btn btn-primary btn-bdr flex">
              <span className="buttonTextProposal">{loadingExport ? 'Exporting...' : 'Export'}</span>
            </Button>

          </Popconfirm>
          : <Button className="proposalbtn btn btn-primary btn-bdr flex"
            onClick={() =>onExportProposalTable("csv_format",0) }>
            <span className="buttonTextProposal">{loadingExport ? 'Exporting...' : 'Export'}</span>
          </Button>
        }

      </div>
      <div className="proposalRightButton">
        <Button className="proposalEditbtn btn btn-primary btn-bdr flex"
          onClick={() => setEditToggle(true)}>
          <span className="buttonTextProposal">Edit Status</span>
        </Button>
      </div>
      <Modal
        className=""
        width={400}
        onCancel={() => setEditToggle(false)}
        visible={proposalData?.selectedRowsCount?.length === 0 && editToggle}
        footer={[
          <ButtonAntd
            key="cancel"
            className="btn btn-white-background"
            onClick={() => setEditToggle(false)}
          >
            Cancel
          </ButtonAntd>,
          <ButtonAntd
            key="update"
            className={`btn btn-warning btnAddListSubmit`}
            onClick={() => {
              setEditToggle(false)
            }}
          >
            Update
          </ButtonAntd>,
        ]}
      >
        <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
          Update Proposal Status
        </h3>
        <p>{proposalData?.selectedRowsCount?.length + " Proposal selected."}</p>
        <div className="field-group">
          <label style={{ color: "red" }}>
            Please Select any proposal!
          </label>
        </div>
      </Modal>
      <Modal
        className=""
        width={400}
        visible={proposalData?.selectedRowsCount?.length > 0 && editToggle}
        onCancel={() => setEditToggle(false)}
        footer={[
          <ButtonAntd
            key="cancel"
            className="btn btn-white-background"
            onClick={() => onProposalCancel()}
          >
            Cancel
          </ButtonAntd>,
          <ButtonAntd
            key="update"
            className={`btn btn-warning btnAddListSubmit`}
            disabled={proposalData?.proposalStatusEdit === ""}
            onClick={() => {
              onUpdateProposal()
            }}
          >
            Update
          </ButtonAntd>,
        ]}
      >
        <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
          Update Proposal Status
        </h3>
        <p>{proposalData?.selectedRowsCount?.length + " Proposal selected."}</p>
        <div className="field-group">
          <label>
            Change Proposal Status <span className="danger">*</span>
          </label>
          <Select
            id={"proposalStatus"}
            placeholder="Select"
            name="selectedProposalStatus"
            onChange={(e) => onProposalStatusSelect(e)}
            value={proposalData?.proposalStatusEdit}
          >
            {appData?.proposalStatusFromEdit?.map((option) =>
              <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
            }
          </Select>
        </div>
        </Modal>
      <div className="proposalRightButton">
        <Button className="proposalActionbtn btn btn-primary btn-bdr flex"
          onClick={() => setActionToggle(!actionToggle)}>
          <span className="buttonTextProposal">Actions</span>
          <span className="arrowButtonActionProposal"></span>
          <img src={userimg} className="downArrActionIcon" alt="" />
        </Button>
        {actionToggle && (
          <ul
            ref={actionRef}
            className={`list-group`}
          >
            <li
              key={"new-proposal"}
              disabled
              onClick={() => actionsListClick("New Proposal")}
              className="list-group-item"
            >
              <span className="new-proposal-text"><b>New Proposal</b></span>
            </li>
            <li
              key={"bulk-email"}
              onClick={() => actionsListClick("Bulk Email Proposal")}
              className="list-group-item"
            >
              <span className="bulk-email-text"><b> Bulk Email Proposal </b></span>
            </li>
          </ul>
        )}
      </div>
    </>
  )
}

export default ProposalsButtonSection;