import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

const Notes = ({ noteTitle, noteText, placeholder, name, changeHandler }) => {
  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <label>{noteTitle}</label>
          <textarea
            placeholder="Customer proposal"
            name="message"
            rows="5"
            value={noteText}
            onChange={(e)=>changeHandler(e.target.value)}
          ></textarea>
        </div>
      </Col>
    </Row>
  );
};

export default Notes;
