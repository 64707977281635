import axiosInstance from "../../Lib/Axios";

//send request saved offline
export const sendRequest = async ({api, method, payload}) => {
  try {
    let response = {};
    switch (method) {
      case "post":
        response = await axiosInstance.post(api, payload);
        break;
      case "get":
        response = await axiosInstance.get(api, payload);
        break;
      case "patch":
        response = await axiosInstance.patch(api, payload);
        break;
      default:
        response = null;
        break;
    }
    response.success = true;
    return response;
  } catch (error) {
    if (error.status !== 200) {
      //Add session storage to identify failled request
      throw error;
    }
  }
};
