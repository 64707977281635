import React from "react";
import { useParams } from "react-router-dom";
import TerritoryTopMenu from "./Territory.TopMenu";
import ChemicalTopMenu from "./Chemical.TopMenu";
import InvoiceReconciliationTopMenu from "./InvoiceReconciliation.TopMenu";
import ExpiringCustomerPaymentMethodTopMenu from "./ExpiringCustomerPaymentMethod.TopMenu";
import CaptureLeadTopMenu from "./CaptureLead.TopMenu";
import AgingSummaryTopMenu from "./AgingSummary.TopMenu";
import WorkOrderReportTopMenu from "./WorkOrderReport.TopMenu";
import ChemicalReportPricingTopMenu from "./ChemicalReportPricing.TopMenu"
import TechnicianNotes from "./TechnicianNotes.TopMenu";

const TopMenu = () => {
  const { id } = useParams();

  const selectTopMenu = (id) => {
    switch (id) {
      case "in_territory_revenue_reports": {
        return <TerritoryTopMenu />;
      }
      case "chemical_reports": {
        return <ChemicalTopMenu />;
      }
      case "invoice_reconciliation_reports": {
        return <InvoiceReconciliationTopMenu />;
      }
      case "captured_lead_reports": {
        return <CaptureLeadTopMenu />;
      }
      case "aging_summary_reports": {
        return <AgingSummaryTopMenu />;
      }
      case "expiring_customer_payment_method_report": {
        return <ExpiringCustomerPaymentMethodTopMenu />;
      }
      case "work_order_report": {
        return <WorkOrderReportTopMenu />;
      }
      case "chemical_report_pricing": {
        return <ChemicalReportPricingTopMenu />;
      }
      case "tech_notes": {
        return <TechnicianNotes />;
      }
      default: {
        return;
      }
    }
  };
  return <>{selectTopMenu(id)}</>;
};

export default TopMenu;
