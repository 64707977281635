import React from "react";
import moment from "moment-timezone";
import { formatToCurrency } from "../../../../Components/Common/formatToCurrency";

import { Rate } from 'antd';
import note from '../../../../Assets/images/noteico.png';
import chevronDown from '../../../../Assets/images/chevron-down.png';
import {editDuration} from "../../../../Store/serviceOrderDetailDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";

// mapping STATUS for hardcoded
const STATUS = {
  draft: 'Draft',
  open: 'Open',
  'open-emailed': 'Open',
  'open-printed': 'Open',
  'overdue': 'Overdue',
  'Closed':'closed',
  paid: 'Paid',
  void: 'Void',
  closed:'Closed',
  'partially refunded':'Partially Refunded',
  refunded: 'Refunded'
};

// mapping SERVICE_STATUS for hardcoded
const SERVICE_STATUS = {
  forecast: "Forecast",
  scheduled: 'Scheduled',
  incomplete: 'Incomplete',
  complete: 'Complete',
  in_progress: 'In Progress',  
  cancelled: 'Cancelled',
  suspend: 'Suspend'
};

// Forecast - Grey
// Scheduled - Blue
// Complete - Green
// Incomplete - Yellow
// Canceled, Billed - Red
// Canceled, Non Billable - Red
// Canceled, Billable - Red

const SERVICE_STATUS_COLOR_CLASS = {
  forecast: "gray-status",
  tentative: "info-status",
  paused: "warning-status",
  scheduled: "info-status",
  technician_assigned: "success-status",
  technician_en_route: "success-status",
  in_progress: "success-status",
  complete: "success-status",
  cancelled: "danger-status",
  suspend: "danger-status",
  incomplete: "warning-status"
};

// mapping frequency for hardcoded
const FREQUENCY_MAPPING = {
  weekly: 'Weekly',
  bi_monthly: 'Every Other Week',
  once: 'Just Once',
  twice_weekly: 'Twice Weekly',
  monthly: 'Monthly'
}

class DataTable {
  Service = () => {
    return [
      {
        title: '',
        dataIndex: 'scheduled_time',
        key: 'scheduled_time',
        sorter: false,
        className: 'tdate',
        render: (text, record) => (          
          <b className="no-wrap">{moment(record?.scheduled_time).format('MMM DD, YYYY')}</b>          )
      },
      {
        title: '',
        dataIndex: 'scheduled_time',
        key: 'scheduled_time',
        sorter: false,
        render: (text, record) => (<strong className="time">{moment(record?.scheduled_time).format('hh:mm A')}</strong>)
      },
      {
        title: '',
        dataIndex: 'frequency',
        key: 'frequency',
        sorter: false,
        render: (text, record) => (<span className="feedback">{record?.frequency ? FREQUENCY_MAPPING[record?.frequency] : '' }</span>)
      },
      {
        title: '',
        dataIndex: 'technician_name',
        key: 'technician_name',
        sorter: false,
        render: (text, record) => (<span className="feedback">{record?.technician_name || ''}</span>)
      },
      {
        title: '',
        dataIndex: 'rating',
        key: 'rating',
        sorter: false,
        render: (text, record) => (<span className="feedback"><Rate allowHalf disabled value={record?.rating}/></span>)
      },
      {
        title: '',
        dataIndex: 'technician_comment',
        key: 'technician_comment',
        sorter: false,
        render: (text, record) => (<span className="note">{record.technician_comment ? record.technician_comment : ''}
          {record.technician_image ? <figure><img src={note} alt=""/></figure> : ''}
        </span>)
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        sorter: false,
        render: (text, record) => (<span className={`status ${SERVICE_STATUS_COLOR_CLASS[record.status]}`}>{record.status ? SERVICE_STATUS[record.status]: ''}</span>)
      }
    ]
  }

  InvoiceWeb = (self) => {
    const invoiceColumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: false,
        className: 'tdate',
        render: (text, record) => (<span className="date"><b>{record?.invoice_date?record?.invoice_date:record?.date_creditmemo}</b></span>)
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: false,
        render: (text, record) => (<span className="type">{record?.invoice_date?"Invoice":"Credit Memo"}</span>)
      },
      {
        title: 'Invoice #',
        dataIndex: 'invoice_id',
        key: 'invoice_id',
        sorter: false,
        className: 'tdate',
        render: (text, record) => (<span className="invoice_id">{record?.invoice_id?record?.invoice_id:record?.creditmemo_id}</span>)
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        key: 'due_date',
        sorter: false,
        className:'lfbdr tdate',
        render: (text, record) => (<span className="due_Date">{record.invoice_id?moment(record?.due_date).format('MM/DD/YYYY'):"N/A"}</span>)
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        sorter: false,
        className: 'tprice',
        render: (text, record) => (record?.status==='draft'?<span className="balance">--</span>:<span className="balance">${record.creditmemo_id && record?.remaining_balance > 0 ? "-" : ""}{record?.balance_due?record?.balance_due:record?.remaining_balance}</span>)
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        sorter: false,
        className: 'tprice',
        render: (text, record) => (<React.Fragment><span className="totalamt">${record.creditmemo_id && record?.total_with_tax > 0 ? "-" : ""}{record?.total_with_tax}</span></React.Fragment>)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: false,
        className: 'tstatus',
        render: (text, record) => (<span className={
          STATUS[record?.status] === 'Overdue' ? 'danger' : STATUS[record?.status] === 'Open' ? 'success' : ''
        }  ><b>{STATUS[record?.status]}</b></span>)
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        className: 'no-wrap',
        render: (text, record) => self.renderAction(record?.uuid, record?.service_order_uuid, record?.status,record,record?.creditmemo_id)
      },
    ]
    return invoiceColumns;
  }

   //Payment History
   AllHistoryList = (page) => {
     const historyColumns = [
       {
         title: 'Invoice Number',
         dataIndex: 'invoice',
         key: 'invoice',
         render: (text, record) => (<span className="type ellipse">{record?.invoice_number || ''}</span>)
       },
       {
         title: 'Customer Name',
         dataIndex: 'user',
         key: 'user',
         //  render: (text, record) => (<span className="type ellipse">{text?.first_name} {text?.last_name}</span>)
         render: (text, record) => (<span className="type ellipse">{record?.customer_name?record?.customer_name:"-"}</span>)
       },
    //    {
    //      title: 'Amount Received',
    //      dataIndex: 'amount_received',
    //      key: 'amount_received',
    //      render: (text, record) => (<b className="type ellipse">${record?.amount_received ? formatToCurrency(record?.amount_received) : '0.00'}</b>)
    //    }
    //    ,
       {
         title: 'Payment Type',
         dataIndex: 'cash_payment',
         key: 'cash_payment',
         render: (text, record) => (<b className="type ellipse">{record?.payment_type?record?.payment_type==="cc" || record?.payment_type==="CC"?record?.payment_type.toUpperCase():record?.payment_type.charAt(0).toUpperCase() + record?.payment_type.slice(1):"-"}</b>)
        },
       {
         title: 'Check Id',
         dataIndex: 'cheque_id',
         key: 'cheque_id',
         render: (text, record) => (<b className="type ellipse">{record?.check_id !== 'None' ? record?.check_id : '-'}</b>)
       },
       {
         title: 'Received From',
         dataIndex: 'received_from',
         key: 'received_from',
         render: (text, record) => (<span className="type ellipse">{record?.received_from?record?.received_from:"-"}</span>)
       },
       {
         title: 'Received By',
         dataIndex: 'received_by',
         key: 'received_by',
         render: (text, record) => (<span className="type ellipse">{record?.received_by?record?.received_by:"-"}</span>)
       },

       {
         title: 'Payment Date',
         dataIndex: 'payment_date',
         key: 'payment_date',
         render: (text, record) => (<span className="type ellipse">{record?.payment_date ? moment(record?.payment_date).format('MMM DD, YYYY') : 'Check'}</span>)
       },
       {
         title: '',
         dataIndex: 'invoice',
         key: 'action',
         render: (text, record) => 
         {
           return( record?.allow_edit_delete && (record.payment_type !== "CC" && record.payment_type !== "CM") 
            && <div className="position-relative status-dropdown">
             <div className="flex ai-center">
               <span className="status-d-arrow">
                 <img src={chevronDown} alt=""/>
               </span>
             </div>
             <ul className="status-dropdownmenu">
               <li>
                 <a href="/#" 
                   onClick={(e) => { 
                     e.preventDefault();
                     page.setState({ show_edit_payment: true, selected_payment: record, edit:true })
                   }}
                 >Edit Payment
                 </a>
               </li>
               <li>
                 <a href="/#" 
                   onClick={(e) => { 
                     e.preventDefault();
                     page.setState({show_delete_modal: true, selected_payment: record});
                   }}
                 >Delete Payment
                 </a>
               </li>
             </ul>
           </div>)
         }
       }
     ]
     return historyColumns;
   }

   UpComingWorkOrders = (page) => {  
    const dispatch = useDispatch();  
     return [
       {
         title: 'Work Order',
         dataIndex: 'work_order_number',
         key: 'work_order_number',
         sorter: true,
         render: (text, record) => {             
           if (record?.work_order_number) {            
             return(<>
               <b className="no-wrap" onClick={(e) => { e.preventDefault();page.setState({...page.state, showAddons: true, currentRecord: record, transection_type: 'View'})}}>
                 {record?.work_order_number ? record?.work_order_number: 'Not created'}
               </b>
               <br />{record?.is_altered && <span className="awo-status mb-5">Altered work order</span>}
               <br />{<a href={"".concat(record?.job_forms?.[0]?.form_url.replace(/\/$/,""), "/", record?.job_forms?.[0]?.form_data_uuid)} target="_blank" rel='noreferrer'>{record?.job_forms?.[0]?.form_name}</a>}               
             </>)
           } else {
             return(<>
               <b className="no-wrap">{record?.work_order_number ? record?.work_order_number: 'Not created'}</b>
               <br />{record?.is_altered && <span className="awo-status mb-5">Altered work order</span>}
               <br />{<a href={"".concat(record?.job_forms?.[0]?.form_url.replace(/\/$/,""), "/", record?.job_forms?.[0]?.form_data_uuid)} target="_blank" rel='noreferrer'>{record?.job_forms?.[0]?.form_name}</a>}
             </>)
           }
         }
       },
       {
         title: 'Date & Time',
         dataIndex: 'scheduled_appointment',
         key: 'scheduled_appointment',
         sorter: true,
         render: (text, record) => (
          <b className="no-wrap">
            {record?.scheduled_appointment ? moment(record?.scheduled_appointment).utcOffset(record?.scheduled_appointment).format("MMM DD,YYYY") : ''} &nbsp; &nbsp;
            {record?.scheduled_appointment ? moment(record?.scheduled_appointment).utcOffset(record?.scheduled_appointment).format("hh:mm") : 'Time not set'}
          </b>)
       },
       {
        title: 'Job Name',
        dataIndex: 'service_name',
        key: 'service_name',
        sorter: true,
        render: (text, record) => {  
          return (
            <b className="wrap">
              {record?.work_order_name}
            </b>
          );                                 
        }
      },
       {
         title: 'Service Order',
         dataIndex: 'service_order__service_order_number',
         key: 'service_order_number',
         sorter: true,
         render: (text, record) => (
           <b className="no-wrap">{record?.service_order_number} </b>)
       },
       {
         title: 'Technician',
         dataIndex: 'technician__user__first_name',
         key: 'technician',
         sorter: true,
         render: (text, record) => (
           <b className="no-wrap">{record?.technicianUser ? `${record?.technicianUser?.user?.first_name} ${record?.technicianUser?.user?.last_name}` : 'Not assigned'}</b>)
       },
       {
         title: 'Status',
         dataIndex: 'status',
         key: 'status',
         sorter: true,
         render: (text, record) => {
           if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
             return(
               <div className="status danger-status">
                Cancelled
                 <small>Non Billable</small>
               </div>
             )
           } else if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
             return(
               <div className="status danger-status">
                Cancelled
                 <small>Billable</small>
               </div>
             )
           } else if (record?.status === 'cancelled' && record?.is_auto_invoice_genrated) {
             return(
               <div className="status danger-status">
                Cancelled
                 <small>Billed</small>
               </div>
             )
           } else {
             return(
               <span className={record.status ? `status ${SERVICE_STATUS_COLOR_CLASS[record.status]}`: ''}>{record.status ? SERVICE_STATUS[record.status]: ''}</span>
             )
           }
         }
       },
       {
         render: (text, record) => {
           let isInvoiced = false;
           if(record?.is_invoiced===true){
             isInvoiced = true;
           }else{
             isInvoiced = false;
           }
           return(
            <div className={record?.customer?.is_active ?  "position-relative status-dropdown" : "position-relative status-dropdown disable-field"}>
             <div className="flex ai-center">
               <span className="status-d-arrow">
                 <img src={chevronDown} alt=""/>
               </span>
             </div>
             <ul className="status-dropdownmenu">
               {(record?.status !== "cancelled" || record?.status !== "complete") && !isInvoiced &&
                <li className={`${record?.batch_invoice_processed ? 'disabled': ''}`}>
                  <a role="button" href="/#" onClick={(e) => { 
                    e.preventDefault(); 
                    dispatch(editDuration(true));
                    page.setState((s) => ({...s, showAddons: true, currentRecord: record, transection_type: 'Update'}))  
                    }
                  }>
                      Edit Work Order
                  </a>
                </li>
               }
               <li><a role="button" href="/#" onClick={(e) => { e.preventDefault();page.setState((s) => ({...s, showAddons: true, currentRecord: record, transection_type: 'View'}))}} >View Work Order</a></li>
               {(record?.status !== "cancelled" || record?.status !== "complete") && !isInvoiced && 
                <li className={`${record?.batch_invoice_processed ? 'disabled': ''}`}>
                    <a href="/#" 
                       onClick={(e) => { 
                          e.preventDefault(); 
                          page.setState((s) => ({...s, isDeleteWOModalVisible: true, currentRecord: record}))}}>
                        Delete Work Order
                    </a>
                </li>}
              {record?.customer?.field_office?.is_optimoroute_enabled == true && <li><a role="button" href="/#" onClick={(e) => { e.preventDefault();page.sendToOptimo([record])}} >Send to Optimo Route</a></li>}
             </ul>
           </div>)
         }
       }
     ]
   }

   HistoryWorkOrders = (page) => {
    const dispatch = useDispatch();
     return [
       {
         title: 'Work Order',
         dataIndex: 'work_order_number',
         key: 'work_order_number',
         sorter: true,
         render: (text, record) => {
           if (record?.work_order_number) {
             return(<>
               <b className="no-wrap" onClick={(e) => { e.preventDefault();page.setState({...page.state, showAddons: true, currentRecord: record, transection_type: 'View'})}}>
                 {record?.work_order_number ? record?.work_order_number: 'Not created'}
               </b>
               <br />{record?.is_altered && <span className="awo-status mb-5">Altered work order</span>}
               <br />{<a href={"".concat(record?.job_forms?.[0]?.form_url.replace(/\/$/,""), "/", record?.job_forms?.[0]?.form_data_uuid)} target="_blank" rel='noreferrer'>{record?.job_forms?.[0]?.form_name}</a>}
             </>)
           } else {
             return(<>
               <b className="no-wrap">{record?.work_order_number ? record?.work_order_number: 'Not created'}</b>
               <br />{record?.is_altered && <span className="awo-status mb-5">Altered work order</span>}
               <br />{<a href={"".concat(record?.job_forms?.[0]?.form_url.replace(/\/$/,""), "/", record?.job_forms?.[0]?.form_data_uuid)} target="_blank" rel='noreferrer'>{record?.job_forms?.[0]?.form_name}</a>}
             </>)
           }
         }
       },
       {
         title: 'Date & Time',
         dataIndex: 'scheduled_time',
         key: 'scheduled_time',
         sorter: true,
         render: (text, record) => (
          <b className="no-wrap">
            {record?.scheduled_appointment ? moment(record?.scheduled_appointment).utcOffset(record?.scheduled_appointment).format("MMM DD,YYYY") : ''} &nbsp; &nbsp;
            {record?.scheduled_appointment ? moment(record?.scheduled_appointment).utcOffset(record?.scheduled_appointment).format("hh:mm") : 'Time not set'}
          </b>)
       },
       {
        title: 'Job Name',
        dataIndex: 'service_order__service_order_number',
        key: 'customer__user__first_name',
        sorter: true,
        render: (text, record) => {          
          return (
            <b className="wrap">
              {record?.work_order_name}
            </b>
          );                          
        }
      },
       {
         title: 'Service Order',
         dataIndex: 'service_order__service_order_number',
         key: 'service_order_number',
         sorter: true,
         render: (text, record) => (
           <b className="no-wrap">{record?.service_order_number}</b>)
       },
       {
         title: 'Technician',
         dataIndex: 'technician__user__first_name',
         key: 'technician',
         sorter: true,
         render: (text, record) => (
           <b className="no-wrap">{record?.technicianUser ? `${record?.technicianUser?.user?.first_name} ${record?.technicianUser?.user?.last_name} ` : 'Not assigned'}</b>)

       },
       {
         title: 'Status',
         dataIndex: 'status',
         key: 'status',
         sorter: true,
         render: (text, record) => {
           if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
             return(
               <div className="status danger-status">
                Cancelled
                 <small>Non Billable</small>
               </div>
             )
           } else if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
             return(
               <div className="status danger-status">
                Cancelled
                 <small>Billable</small>
               </div>
             )
           } else if (record?.status === 'cancelled' && record?.is_auto_invoice_genrated) {
             return(
               <div className="status danger-status">
                Cancelled
                 <small>Billed</small>
               </div>
             )
           } else {
             return(
               <span className={record.status ? `status ${SERVICE_STATUS_COLOR_CLASS[record.status]}`: ''}>{record.status ? SERVICE_STATUS[record.status]: ''}</span>
             )
           }
         }
       },
       {
         render: (text, record) => {
           let isInvoiced = false;
           if(record?.is_invoiced===true){
             isInvoiced = true;
           }else{
             isInvoiced = false;
           }
           return(
            <div className={record?.customer?.is_active ?  "position-relative status-dropdown" : "position-relative status-dropdown disable-field"}>
             <div className="flex ai-center">
               <span className="status-d-arrow">
                 <img src={chevronDown} alt=""/>
               </span>
             </div>
             <ul className="status-dropdownmenu">
               {(record?.status.toLowerCase() !== "cancelled" || record?.status.toLowerCase() !== "complete") && !isInvoiced && 
                <li><a href={() => false} 
                className={`${record?.batch_invoice_processed ? 'disabled': ''}`}
                onClick={(e) => { 
                  e.preventDefault();  
                  dispatch(editDuration(true));                 
                  page.setState((s) => ({...s, showAddons: true, currentRecord: record, transection_type: 'Update'}))
                }}>Edit Work Order </a></li>
               }
               <li><a href={() => false} onClick={(e) => { e.preventDefault(); page.setState((s) => ({ ...s, showAddons: true, currentRecord: record, transection_type: 'View'}))}} >View Work Order</a></li>
               {(record?.status !== "cancelled" || record?.status !== "complete") && !isInvoiced && 
               <li className={`${record?.batch_invoice_processed ? 'disabled': ''}`}><a href="/#" onClick={(e) => { e.preventDefault(); page.setState((s) => ({...s, isDeleteWOModalVisible: true, currentRecord: record}))}}>Delete Work Order</a></li>
               }
               {record?.customer?.field_office?.is_optimoroute_enabled == true && <li><a href={() => false} onClick={(e) => { e.preventDefault();page.sendToOptimo([record])}} >Send to Optimo Route</a></li>}
             </ul>
           </div>)
         }
       }
     ]
   }

   DescriptionWork = (page) => {
    return [
      // {
      //   title: '',
      //   dataIndex: 'repair_code',
      //   key: 'repair_code',
      //   sorter: false,
      //   render: (text, record) => (
      //     record?.repair_code?.toLowerCase() === page.service_type?.toLowerCase() ?
      //     <b className="wrap">
      //     <input type="checkbox" 
      //       className="fitem-ck-input"
      //       id="checkbox_line"  
      //       checked={ record.billable ? true : false }          
      //       onChange={(e) => { page.set_is_billable_Edit(true); page.setlineItem(record); page.updateDiagnostic(record) }}
      //       name="checkbox_line" 
      //       ></input>
      //       </b>
      //     :
      //     <b className="wrap">{''}
      //     </b>
      //   )
      // },
      {
        title: 'Bundle Product',
        dataIndex: 'bundle_code',
        key: 'bundle_code',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record?.bundle_code}</b>)
      },
      {
        title: 'Repair Code',
        dataIndex: 'repair_code',
        key: 'repair_code',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record?.repair_code}</b>)
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: false,
      },
      {
        title: 'Apply tax',
        dataIndex: 'is_taxable',
        key: 'is_taxable',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">
            <input type="checkbox" 
            className="fitem-ck-input"
            id="checkbox"
            checked={record?.is_taxable}
            onChange={(e) => { page.applyTax(record); page.removeBundleTax(record) }}
            name="checkboxs" 
            disabled = { page.transection_type === "View" || (record?.bundle_code !== undefined && record?.bundle_code !== null) }
            ></input>
          </b>)
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        key: 'unit_cost',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">${formatToCurrency(page.amount(record?.unit_cost))}</b>)
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record?.quantity}</b>)
      },
      {
        title: 'Total',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: false,
        render: (text, record) => (
          // <b className="no-wrap">${formatToCurrency(record?.total)}</b>
          <b className="no-wrap">${formatToCurrency(page.totalPriceDescription(record))}</b>
          )
      },
      {
        title: 'Action',
        dataIndex: 'repair_code',
        key: 'repair_code',
        sorter: false,
        render: (text, record) => (
          
          <div className="position-relative status-dropdown">
             <div className="flex ai-center">
              Select
               <span className="status-d-arrow">
                 <img src={chevronDown} alt=""/>
               </span>
             </div>
             { page.transection_type !== "View" ? 
             <ul className="status-dropdownmenu">               
               <li><a href="/#" onClick={(e) => { e.preventDefault(); page.editLine(record) }} disabled={page.transection_type === "View"} >Edit</a></li>               
               <li><a href="/#" onClick={(e) => { e.preventDefault(); page.deleteLine(record)}} disabled={page.transection_type === "View"} >Delete</a></li>
             </ul>
             : '' }
           </div>                    
        )
      },
    ]
   }
}

export default new DataTable()

