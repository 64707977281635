import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, DatePicker } from "antd";
import { Button } from "@mobiscroll/react";
import moment from "moment-timezone";
import calendarLeft from "../../../Assets/icons/calendar-left.svg";
import calendarRight from "../../../Assets/icons/calendar-right.svg";

const NavDateHeader = (props) => {
  const [currentDate, setCurrentDate] = useState(props.current);
  const [view, setView] = useState("day");
  moment.suppressDeprecationWarnings = true;

  useEffect(() => {
    props.handleChange(currentDate);
  }, [currentDate]);

  const navigatePage = useCallback(
    (prev) => {
      setCurrentDate((date) => {
        if (prev) {
          return moment(date).subtract(1, view).startOf(view);
        }
        return moment(date).add(1, view).startOf(view);
      });
    },
    [view]
  );

  const onChange = (date) => {
    if (date) {
      setCurrentDate(moment(date).startOf(view));
    } else {
      setCurrentDate(moment().startOf(view));
    }
  };

  return (
    <Row
      justify="space-between"
      style={{
        width: "100%",
        backgroundColor: "white",
        border: "1px solid lightgrey",
      }}
      align="middle"
    >
      <Col span={8} className="md-custom-header-view"></Col>
      <Col span={16} className="md-custom-header-nav">
        <Row justify="start" gutter={[10, 10]} align="middle">
          <Col>
            <Button
              className="todayButton"
              disabled={currentDate === moment().format("MMMM DD, yyyy")}
              onClick={() => setCurrentDate(moment().format("MMMM DD, yyyy"))}
            >
              Today
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => navigatePage(true)}
              variant="outline"
              className="md-custom-header-button"
            >
              <img src={calendarLeft} alt="" style={{ marginTop: "10px" }} />
            </Button>
          </Col>
          <Col style={{ display: "inline-block" }}>
            <span className="calendarDateHeader">
              {moment(currentDate).format("MMMM DD, yyyy")}
            </span>
          </Col>
          <Col>
            <Button
              onClick={() => navigatePage(false)}
              variant="outline"
              className="md-custom-header-button"
            >
              <img src={calendarRight} alt="" style={{ marginTop: "10px" }} />
            </Button>
          </Col>
          <DatePicker
            className={"datepicker-techglance"}
            format={"YYYY-MM-DD"}
            onChange={onChange}
            showToday={false}
            style={{ width: "45px" }}
            value={moment(currentDate)}
            allowClear={false}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default NavDateHeader;
