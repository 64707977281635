export const withJSONColumnOrder = (sorter) => {
  if (sorter === null) return {};
  if (sorter.column.dataIndex !== sorter.column.filterName) {
    return {
      ordering_fields:
        sorter.order === "ascend"
          ? sorter.column.filterName
          : "-" + sorter.column.filterName,
    };
  } else {
    return {
      ordering:
        sorter.order === "ascend"
          ? sorter.column.filterName
          : "-" + sorter.column.filterName,
    };
  }
};

export const withJSONSameOrder = (sorter) => {
  if (sorter === null) return {};
  return {
    ordering:
      sorter.order === "ascend"
        ? sorter.column.filterName
        : "-" + sorter.column.filterName,
  };
};
