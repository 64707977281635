import React, {useEffect, useState } from "react";
import * as yup from "yup";
import moment from "moment-timezone"; 
import Modal from "antd/lib/modal/Modal";
import { isUndefined, map } from "lodash";
import { Drawer, message, Select } from "antd";
import Loader from "../../../Components/Loader";
import { formatToCurrency } from "../../../Utils/formatToCurrency";
import { addBillingInfo, getUserBillingInfo, deleteBillingInfo } from "../../../Action/Customer";
import { getCustomerDetails, createCustomerInstax, createAscPaymentData, createCardPaymentData } from '../../Common/utils';
import { createManualPayment } from "../../../Action/Invoice";

const { Option } = Select;
const LogPayment = (props) => {
// class LogPayment extends Component {
  const [fattJs, setFattJs] = useState(null);
  const [newcardID,setNewCardID] = useState(null);
  const [newachID, setNewAchID] = useState(null);
  const [AccountHolderName,setAccountHolderName] = useState('');
  const [BankName, setBankName] = useState('');
  const [RoutingNumber, setRoutingNumber] = useState('');
  const [AccountNumber, setAccountNumber] = useState('');
  const [AccountType, setAccountType] = useState('');
  const [BankHolderType, setBankHolderType] = useState('');
  const [card_holder_name, setcard_holder_name] = useState('');
  const [errorsAch, setErrorsAch] = useState({});
  const [state, setState] = useState({
    loader: false,
    log_type: '',
    payment_log: {
    cash_payment: false,
    cheque_payment: false,
    is_check: false,
    received_from: '',
    amount_received: props?.invoiceRecord?.balance_due || '',
    cheque_id: '',
    user_uuid: '',
    received_by_uuid: '',
    invoice_uuid: ''
    },
    staffList: {},
    errors: {},
    card_payment_type: 1,
    new_card_uuid: '',
    new_ach_uuid: '',
    is_card_payment: false,
    ach_payment_type: 1,
    is_new_ach_payment: false,
    is_old_ach_payment: true,
    id_old_card: false,
    franchise_uuid: '',
    card_number : '',
    card_expiry_month : '',
    card_expiry_year : '',
    card_cvv : '',
    stax_customer_id : '',
    stax_public_apikey: ' ',
    hideAddNewButton: false,
    transaction_type: 'create',
    showSuccess: false,
    received_by_uuid: '',
    user_uuid: '',
    show_confirm: false,
    card_count:0,
    card_list:[],
    ach_list: []
  })  

  function getState(){
    setState({
        loader: false,
        log_type: '',
        payment_log: {
        cash_payment: false,
        cheque_payment: false,
        is_check: false,
        received_from: '',
        amount_received: props?.invoiceRecord?.balance_due || '',
        cheque_id: '',
        user_uuid: '',
        received_by_uuid: '',
        invoice_uuid: ''
        },
        staffList: {},
        errors: {},
        card_payment_type: 1,
        ach_payment_type: 1,
        new_card_uuid: '',
        new_ach_uuid: '',
        is_card_payment: false,
        id_old_card: false,
        is_new_ach_payment: false,
        is_old_ach_payment: true,
        franchise_uuid: '',
        card_number : '',
        card_expiry_month : '',
        card_expiry_year : '',
        card_cvv : '',
        stax_customer_id : '',
        stax_public_apikey: props?.customer?.field_office?.stax_public_apikey,
        hideAddNewButton: false,
        transaction_type: 'create',
        showSuccess: false,
        received_by_uuid: '',
        user_uuid: '',
        show_confirm: false,
        card_count: 0,
        card_list: [],
        ach_list: []
    })
  }

  useEffect(()=>{
    if(newcardID!=null){
      addPaymentLog();
    }
  },[newcardID])

  useEffect(() => {
    if (newachID != null) {
      addPaymentLog();
    }
  }, [newachID])

  useEffect(()=>{
    getState();
    let {payment_log,type} = state
    const authUser = JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser"))?.user
      : "";
    payment_log = Object.assign(payment_log, {
      user_uuid: props?.customerId,
      invoice_uuid:props?.invoice_uuid,
      received_by: `${authUser?.first_name} ${authUser?.last_name}`,
      received_by_uuid: authUser?.uuid || '',
      amount_received: props?.invoiceRecord?.balance_due || '',
    });
    setState((state)=>({
      ...state,
      payment_log:payment_log
    }))

    // Card List
    const userBillingInfo = async () => {
      const data = await getUserBillingInfo({user__uuid:props?.customer?.uuid});
      setState((state)=>({
        ...state,
        card_list:data,
        ach_list: data
      }))
    }
    userBillingInfo()
  },[])

  // Validate numeric input
  const onNumericInputChange = (value) => {
    const reg = /^-?\d*(\.\d*)?$/;
    return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
  };

  //Payment type change
  const onLogTypeChange = (e) => {
    let { payment_log,card_count } = state;
    const authUser = JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser"))?.user
      : "";

    payment_log = Object.assign(payment_log,
      {
        cash_payment: false,
        cheque_payment: false,
        received_from: `${props?.customer?.first_name} ${props?.customer?.last_name}`,
        amount_received: props?.invoiceRecord?.balance_due || '',
        cheque_id: '',
        received_by_uuid: authUser?.uuid || '',
        received_by: `${authUser?.first_name} ${authUser?.last_name}`,
        is_check: e.target.id === 'cheque'
      }
    )

    setState((state)=>(
        {...state,
            log_type: e.target.id,
            payment_log,
            errors: {}
          }
    ))
    if (e.target.id === 'card') {
      setState((state)=>(
        {...state,
            id_old_card: true
          }
      ))
      if(card_count===0){
        setState((state)=>({
            ...state,
            card_payment_type: 0,
            is_card_payment: true,
            id_old_card: false,
          }));
        loadStax();
      }
    }
    else {
      setState((state)=>({
        ...state,
        id_old_card: false
      }))
    }
  }

  // On log text change
  const onTextChange = (e) => {
    let { payment_log } = state;
    const inputValue = e.target.value;
    payment_log = Object.assign(payment_log, { [e.target.name]: inputValue });
      // Check if the input contains a decimal point and has more than two decimal places
      if (inputValue.indexOf('.') > -1 && inputValue.split('.')[1].length > 2) {
        return; // Do nothing if the input is invalid
      }
      // Update the state with the valid input value
    setState((state)=>(
      {...state,payment_log}
    ))
  }

  // On search received by
  const onSearchReceivedBy = (search) => {
    if (search?.length === 0 || search?.length >= 3) {
      props.getStaffUsers({ search: search, staff_profile__field_office__uuid: state.franchise_uuid, invoice_list: true })
    }
  }

  //Receive by
  const onReceiveChange = (e) => {
    let { payment_log } = state;
    payment_log = Object.assign(payment_log, { 'received_by_uuid': e });
    setState((state)=>(
      {...state,payment_log}
    ))
  }

  const validateBalance = () => {
    const { payment_log, invoice_summary } = state;
    const currentBalance = invoice_summary?.total_open_balance;

    if(currentBalance - payment_log.amount_received < 0) {
      toggleConfirmModal();
    }
    else {
      validateLog();
    }
  }

  // validate required fields
  const validateLog = () => {
    setState((state) => ({ ...state, loader: true }))
    const { 
      payment_log,
      log_type,
      card_payment_type,
      is_card_payment,
      new_card_uuid,
      id_old_card,
      card_number,
      card_expiry_month,
      card_expiry_year,
      card_cvv,
      invoice_summary,
      received_by_uuid,
      transaction_type,
      ach_payment_type,
      new_ach_uuid,
      is_old_ach_payment,
    } = state;
    const is_manual_log = props.manualLog;
    if (log_type === 'ach' && ach_payment_type === 0) {
      ACHPaymentValidation();
    } else if (log_type === 'ach' && ach_payment_type === 1) {
      const PaymentSchema = yup.object().shape({
        log_type: yup.string().required('Log type is required'),
        payment_log: yup.object().shape({
          received_from: yup.string().required("Received from is required"),
          amount_received: yup.number().required("Amount received is required").moreThan(0, 'Amount received is required').typeError("Amount received is required"),
          user_uuid: yup.string().required("user_uuid is required"),
          received_by_uuid: yup.string().required("Received by is required"),
          cheque_id: yup.string().when('is_check', {
            is: true,
            then: yup.string().required('Check id is required'),
          }),
          invoice_uuid: yup.string().when('is_manual_log', {
            is: false,
            then: yup.string().required("Invoice id is required")
          }),
        }),
        // is_card_payment: yup.bool(),
        is_old_ach_payment: yup.bool(),
        new_ach_uuid: yup.string().when('is_old_ach_payment', {
          is: true,
          then: yup.string().required("ACH is required")
        }),
      });
      const cardData = { payment_log, log_type, is_old_ach_payment, new_ach_uuid };
      PaymentSchema.validate(cardData, { abortEarly: false }).then(() => {
        setState((state) => ({ ...state, errors: {} }))
        if (transaction_type !== 'edit') {
          addPaymentLog();
        }
        else {
          updatePaymentLog();
        }
      }).catch((error) => {
        setState((state) => ({ ...state, loader: false }))
        let err_data = {};
        map(error?.inner, (item) => { err_data[item.path] = item.message });
        setErrorsAch(err_data);
        // message.error(`Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`);
      });
    }
    else if (log_type === 'card' && card_payment_type === 0) {
      const PaymentSchema = yup.object().shape({
        card_number : fattJs.validNumber ? null : yup.string().nullable().required('Card Number is required'),
        card_expiry_month : yup.string().nullable().required('Card expire month is required'),
        card_expiry_year : yup.string().nullable().required('Card expire year is required').min(4,
          'Card expire year is invalid.').max(4, 'Card expire year is invalid.'),
        card_cvv : fattJs.validCvv ? null : yup.string().nullable().required('Card CVC is required'),
        log_type: yup.string().required('Log type is required'),
        payment_log: yup.object().shape({
          received_from: yup.string().required("Received from is required"),
          amount_received: yup.number().required("Amount received is required").moreThan(0, 'Amount received is required').typeError("Amount received is required"),
          user_uuid: yup.string().required("user_uuid is required"),
          received_by_uuid: yup.string().required("Received by is required"),
          cheque_id: yup.string().when('is_check', {
            is: true,
            then: yup.string().required('Check id is required'),
          }),
          invoice_uuid: yup.string().when('is_manual_log', {
            is: false,
            then: yup.string().required("Invoice id is required")
          }),
        }),
        is_card_payment: yup.bool(),
        id_old_card: yup.bool(),
        new_card_uuid: yup.string().when('id_old_card', {
          is: true,
          then: yup.string().required("Card is required"),
        }),
      });

      const cardData = { 
        payment_log,
        log_type,
        is_card_payment,
        id_old_card,
        new_card_uuid,
        card_number,
        card_expiry_month,
        card_expiry_year,
        card_cvv
      };
      PaymentSchema.validate(cardData, { abortEarly: false }).then(() => {
        setState((state)=>(
          {...state, errors: {} }
        ))
        addNewCard();
      }).catch((error) => { 
        let err_data = {};
        map(error.inner, (item) => {err_data[item.path] = item.message})
        // console.log("err_data",err_data)
        setState((state)=>(
          {...state, errors: err_data, is_btn_active: false, loader: false }
        ))
        console.log("err_data",err_data)
        message.error(`Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`);
      });
    } else {
      const PaymentSchema = yup.object().shape({
        log_type: yup.string().required('Log type is required'),
        payment_log: yup.object().shape({
          received_from: yup.string().required("Received from is required"),
          amount_received: yup.number().required("Amount received is required").moreThan(0, 'Amount received is required').typeError("Amount received is required"),
          user_uuid: yup.string().required("user_uuid is required"),
          received_by_uuid: yup.string().required("Received by is required"),
          cheque_id: yup.string().when('is_check', {
            is: true,
            then: yup.string().required('Check id is required'),
          }),
          invoice_uuid: yup.string().when('is_manual_log', {
            is: false,
            then: yup.string().required("Invoice id is required")
          }),
        }),
        is_card_payment: yup.bool(),
        id_old_card: yup.bool(),
        new_card_uuid: yup.string().when('id_old_card', {
          is: true,
          then: yup.string().required("Card is required")
        }),
      });

      const cardData = { payment_log, log_type, is_card_payment, id_old_card, new_card_uuid };
      PaymentSchema.validate(cardData, { abortEarly: false }).then(() => {
        setState((state)=>({...state, errors: {} }))
        if(transaction_type !== 'edit')
        {
          addPaymentLog();
        }
        else
        {
          updatePaymentLog();
        }

      }).catch((error) => { 
        let err_data = {};
        map(error.inner, (item) => {err_data[item.path] = item.message})
        setState((state)=>({...state,errors: err_data, is_btn_active: false, loader: false}))
        // message.error(`Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`);
      });
    }
  }



  const addNewACHPayment = async () => {
    const { card_expiry_month, card_expiry_year, card_number, is_new_ach_payment } = state;
    // const customer = props.customer;
    let customer = JSON.parse(JSON.stringify(props.customer));
    customer.emails = [... customer.emailaddress_set];
    customer.phoneNumbers = [... customer.phone_numbers];
    if(customer.invoice_address)    
      customer.address = {...customer.invoice_address};
    else if(customer.billing_address && customer?.billing_address?.length > 0)
      customer.address = { ...customer.billing_address[0] }
    else if(customer.address && customer?.address?.length > 0)
      customer.address = { ...customer.address[0] }

    customer.firstName = customer.first_name;
    customer.lastName = customer.last_name;
    setState((state) => ({ ...state, loader: true }));
    let customerData = await getCustomerDetails(customer);
    let createCustomerResp = await createCustomerInstax(customerData);
    let ach_data = await createAscPaymentData(AccountHolderName, AccountNumber, RoutingNumber, BankHolderType, AccountType, createCustomerResp.id);
    fattJs.tokenize(ach_data).then((res) => {
      const postBillingInfo = async () => {
        const data = await addBillingInfo({
          user_uuid: customer?.uuid || null,
          address: customer ? customer.address.length > 0 ? customer.address[0] : customer.address : null,
          stax_card_token: res?.id || null,
          charge_invoice_bank: true,
      })
        if (data?.uuid) {
          setNewAchID(data?.uuid);
          setState((state) => ({ ...state, loader: false }));
          message.success("Billing Information added successfully!", 10);
        }
      }
      postBillingInfo();
    }).catch((err) => {
      console.error("Error : ", err);
      setState((state) => ({ ...state, loader: false }));
      message.error(err.errors)
    map(err.fieldErrors, (item) => {
      message.error(item.message, 10)
    });
    });
    // const token_obj = {
    //   address_1: customer ? customer.address[0].address : null,
    //   address_city: customer ? customer.address[0].city : null,
    //   address_state: customer ? customer.address[0].state : null,
    //   address_zip: customer ? customer.address[0].zip_code : null,
    //   email: customer && customer.email ? customer.email : null,
    //   firstname: customer && customer.first_name ? customer.first_name : null,
    //   lastname: customer && customer.last_name ? customer.last_name : null,
    //   method: 'bank',
    //   person_name: AccountHolderName ||'null',
    //   phone: customer?.phone_numbers?.[0]?.phone,
    // }
    // if(is_new_ach_payment) {
    // token_obj['bank_account'] = AccountNumber || '';
    // token_obj['bank_routing'] = RoutingNumber || '';
    // token_obj['bank_holder_type'] = BankHolderType.toLowerCase() || '';
    // token_obj['bank_type'] = AccountType.toLowerCase() || '';
    // }
    // if (state.stax_customer_id) {
    //   token_obj['customer_id'] = state.stax_customer_id
    // }
    // fattJs.tokenize(token_obj).then((res) => {
    //   if (res && Object.keys(res).length > 0) {
    //     if (!customer.address[0].geo_location) {
    //       delete customer.address[0].geo_location
    //     }
    //     const postBillingInfo = async () => {
    //       const data = await addBillingInfo({
    //         user_uuid: customer ? customer.uuid : null,
    //         address: customer ? customer.address[0] : null,
    //         stax_card_token: res['id'] || null,
    //         charge_invoice_bank:true
    //       })
    //       if (data?.uuid) {
    //         setNewAchID(data?.uuid);
    //       }
    //     }
    //     postBillingInfo();
    //   }
    // }).catch((err) => {
    //   console.log(err.errors[0]);
    //   message.error(err.errors[0]);
    //   setState((state) => ({ ...state, loader: false }))
    // });
  }
  // const addNewACHPayment = () => {
  //   const { card_expiry_month, card_expiry_year, card_number, is_new_ach_payment } = state;
  //   const customer = props.customer;
  //   setState((state) => ({ ...state, loader: true }))
  //   const token_obj = {
  //     address_1: customer ? customer.address[0].address : null,
  //     address_city: customer ? customer.address[0].city : null,
  //     address_state: customer ? customer.address[0].state : null,
  //     address_zip: customer ? customer.address[0].zip_code : null,
  //     email: customer && customer.email ? customer.email : null,
  //     firstname: customer && customer.first_name ? customer.first_name : null,
  //     lastname: customer && customer.last_name ? customer.last_name : null,
  //     method: 'bank',
  //     person_name: customer ? `${customer.first_name} ${customer.last_name}` : null,
  //     phone: customer?.phone_numbers?.[0]?.phone,
  //   }
  //   token_obj['bank_account'] = AccountNumber || '';
  //   token_obj['bank_routing'] = RoutingNumber || '';
  //   token_obj['bank_holder_type'] = BankHolderType.toLowerCase() || '';
  //   token_obj['bank_type'] = AccountType.toLowerCase() || '';
  //   if (state.stax_customer_id) {
  //     token_obj['customer_id'] = state.stax_customer_id
  //   }
  //   fattJs.tokenize(token_obj).then((res) => {
  //     if (res && Object.keys(res).length > 0) {
  //       if (!customer.address[0].geo_location) {
  //         delete customer.address[0].geo_location
  //       }
  //       const postBillingInfo = async () => {
  //         const data = await addBillingInfo({
  //           user_uuid: customer ? customer.uuid : null,
  //           address: customer ? customer.address[0] : null,
  //           stax_card_token: res['id'] || null,
  //           charge_invoice_bank:true
  //         })
  //         if (data?.uuid) {
  //           // setState((state) => ({ ...state, loader: false }));
  //           // message.success('ACH payment successful', 10);
  //           // closeAndClear();
  //           setNewAchID(data?.uuid);
  //         }
  //       }
  //       postBillingInfo();
  //     }
  //   }).catch((err) => {
  //     console.log(err.errors[0]);
  //     message.error(err.errors[0]);
  //     setState((state) => ({ ...state, loader: false }))
     
  //   });
  // }

  // ACH PAYMENT VALIDATION
  const ACHPaymentValidation = () => {
    const {
      payment_log,
      new_ach_uuid,
      is_old_ach_payment,
      is_new_ach_payment,
    } = state;
    const ACHSchema = yup.object().shape({
      is_new_ach_payment: yup.boolean(),
      BankName: yup.string().when('is_new_ach_payment', {
        is: true,
        then: yup.string().required("Please enter correct value!"),
      }),
      RoutingNumber: yup.string().when('is_new_ach_payment', {
        is: true,
        then: yup.string().nullable().required('Please enter correct value!'),
      }),
      AccountNumber: yup.string().when('is_new_ach_payment', {
        is: true,
        then: yup.string().nullable().required('Please enter correct value!'),
      }),
      AccountHolderName: yup.string().when('is_new_ach_payment', {
        is: true,
        then: yup.string().required('Please enter correct value!'),
      }),
      AccountType: yup.string().when('is_new_ach_payment', {
        is: true,
        then: yup.string().required("Please enter correct value!"),
      }),
      BankHolderType: yup.string().when('is_new_ach_payment', {
        is: true,
        then: yup.string().required("Please enter correct value!"),
      }),
      is_old_ach_payment: yup.boolean(),
      new_ach_uuid: yup.string().when('is_old_ach_payment', {
        is: true,
        then: yup.string().required("Account is required"),
      }),
      payment_log: yup.object().shape({
        received_from: yup.string().required("Received from is required"),
        amount_received: yup.number().required("Amount received is required").moreThan(0, 'Amount received is required').typeError("Amount received is required"),
        received_by_uuid: yup.string().required("Received by is required"),
      }),
    })
    const ACHData = { BankName, RoutingNumber, AccountNumber, AccountType, BankHolderType, payment_log, new_ach_uuid, is_old_ach_payment, is_new_ach_payment,AccountHolderName };
    ACHSchema.validate(ACHData, { abortEarly: false }).then(() => {
      setState({ ...state, loader: true });
      setErrorsAch({});
      addNewACHPayment();
    }).catch((error) => {
      let err_data = {};
      map(error?.inner, (item) => { err_data[item.path] = item.message })
      setErrorsAch(err_data);
      setState((state) => ({ ...state, loader: false }))
     // message.error(`Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`);
    });
  }

  const toggleConfirmModal = () => {
    setState((state)=>({...state,show_confirm: !state.show_confirm}))
  }

  const renderModal = () => {
    return <Modal
    title="Negative balance"
    visible={state.show_confirm}
    onCancel={() => toggleConfirmModal()}
    className=""
    width={400}
    footer={[
      <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => toggleConfirmModal()}>Cancel</button>,
      <button key="upload" className={`btn btn-waning`} onClick={() => {
        validateLog();
        toggleConfirmModal();
      }}>Log Payment</button>,
    ]}
  >
    <div>
      <p> Are you sure you would like to log this payment and create a negative balance on the account?</p>
    </div>
  </Modal>
  }

  // Add new card
  const addNewCard = async () => {
    const { card_expiry_month, card_expiry_year, card_number } = state;
    let customer = JSON.parse(JSON.stringify(props.customer));
    customer.emails = [... customer.emailaddress_set];
    customer.phoneNumbers = [... customer.phone_numbers];
    if(customer.invoice_address)    
      customer.address = {...customer.invoice_address};
    else if(customer.billing_address && customer?.billing_address?.length > 0)
      customer.address = { ...customer.billing_address[0] }
    else if(customer.address && customer?.address?.length > 0)
      customer.address = { ...customer.address[0] }

    customer.firstName = customer.first_name;
    customer.lastName = customer.last_name;
    setState((state)=>({...state, loader : true }))
    const token_obj = {
      month: card_expiry_month ? card_expiry_month : null,
      year: card_expiry_year ? card_expiry_year : null,
      card_number: card_number ? card_number : null,
      firstname: card_holder_name ? card_holder_name?.split(' ')[0] : customer?.first_name,
      lastname: card_holder_name ? card_holder_name?.split(' ')[1] : customer?.last_name ,
      method: 'card',
      person_name: customer ? `${customer.first_name} ${customer.last_name}` : null,
    }
    let customerData = await getCustomerDetails(customer);
    let createCustomerResp = await createCustomerInstax(customerData);
    if(createCustomerResp.id) {
    let card_data = await createCardPaymentData(card_holder_name, token_obj.firstname, token_obj.lastname, token_obj.card_number, token_obj.month, token_obj.year, createCustomerResp.id);
    fattJs.tokenize(card_data).then((res) => {
      //Once the Credit Card is saved in Stax is saved on Billing info
      if (res && Object.keys(res).length > 0) {
        const postBillingInfo = async () =>{
          const data = await addBillingInfo({
            user_uuid: customer ? customer.uuid : null,
            address: customer ? customer.address.length > 0 ? customer.address[0] : customer.address : null,
            stax_card_token : res['id'] || null,
            card_holder_name: customer ? `${customer.first_name} ${customer.last_name}` : null, 
            charge_invoice_card:true 
          })
          //When the new Credit card is already saved in stax and Billing info, we can access to its data
          compareCreditCards(data, res);
        }
        postBillingInfo();
      }
    }).catch((err) => {
      console.error("Error : ",err);
      setState((state)=>({...state, loader : false }))
      map(err.fieldErrors, (item) => {
        message.error(item.message,10)
      });
      message.error(err.message,10);
    });
  }
  }

  //Compare the customer's Credit Cards to check if the new one is already saved
  const compareCreditCards = async(data, res) => {
    let card_saved = false;
    const list_cards = await getUserBillingInfo({
      user__uuid: props?.customer?.uuid,
    });
    const newcard = list_cards.data.find((i) => i.stax_card_token === res.id);
    const newcard_stax = JSON.parse(newcard.stax_payment_object);

    state.card_list.data.forEach((item) => {
      let card_info;
      if (item.stax_payment_object) {
        if (JSON.parse(item.stax_payment_object).method === "card") {
          card_info = JSON.parse(item.stax_payment_object);
          if (
            card_info?.card_last_four === newcard_stax?.card_last_four &&
            card_info?.card_exp === newcard_stax?.card_exp &&
            card_info?.meta.cardDisplay === newcard_stax?.meta.cardDisplay
          ) {
            card_saved = true;
            return;
          }
        }
      }
    });
    //If the new Credit card is same of one already saved
    if (card_saved) {
      message.error(
        "The card is already saved, please use another card or use existing one."
      );
      //Delete the new card saved
      deleteCreditCard(newcard.uuid);
    } else if (data?.uuid) {
      setNewCardID(data?.uuid);
    }
  }

  const deleteCreditCard = async (id) => {
    setState((state) => ({ ...state, loader: true }));
    const data = await deleteBillingInfo(id)
    if (data.length === 0) {
      setState((state) => ({ ...state, loader: false }));
    } else {
      setState((state) => ({ ...state, loader: false }));
      message.error("Something went wrong deleting credit card, please try again.");
    }
  }

  const updatePaymentLog = () => {
    let { payment_log, log_type, new_card_uuid } = state;
    let { received_from, received_by_uuid, user_uuid, amount_received, cheque_id, invoice_uuid, uuid } = payment_log;
    setState((state)=>({...state, loader : true }))
    let data = {
      cash_payment: log_type === 'cash' ? true : false,
      cheque_payment: log_type === 'cheque' ? true : false,
      cc_auto_payment: log_type === 'card' ? true : false,
      received_from,
      amount_received: parseFloat(amount_received).toFixed(2),
      user_uuid,
      uuid,
      received_by_uuid,
      cheque_id,
      invoice_uuid
    }
    if (log_type === 'cash') {
      delete data.cheque_id;
      delete data.user_billing_info;
    }
    if (log_type === 'check') {
      delete data.user_billing_info;
    }
    if (log_type === 'card') {
      delete data.cheque_id
      data.user_billing_info = new_card_uuid;
    }

    props.postPaymentLog(data);
  }

  //Add Log Payment Log
  const addPaymentLog = () => {
    setState((state)=>({...state, loader : true }))
    // message.info("Processing payment");
    let { payment_log, log_type, new_card_uuid, new_ach_uuid  } = state;
    let { received_from, received_by_uuid, user_uuid, amount_received, cheque_id, invoice_uuid } = payment_log
    let data = {
      cash_payment: log_type === 'cash' ? true : false,
      cheque_payment: log_type === 'cheque' ? true : false,
      cc_auto_payment: log_type === 'card' ? true : false,
      received_from,
      amount_received: parseFloat(amount_received).toFixed(2),
      total:parseFloat(amount_received).toFixed(2),
      remaining_balance:parseFloat(amount_received).toFixed(2),
      pay_method:log_type === 'cash'?"CASH":log_type === 'cheque'?"CHEQUE":"bank",
      user_uuid,
      received_by_uuid,
      processed_by_uuid:received_by_uuid,
      cheque_id,
      invoice_uuid,
      date_unattachedpayment:moment().format('YYYY-MM-DD')
    }
    if (props.manualLog) {
      delete data.invoice_uuid;
    }
    if (log_type === 'cash') {
      delete data.cheque_id;
      delete data.user_billing_info;
    }
    if (log_type === 'check') {
      delete data.user_billing_info;
    }
    if (log_type === 'card') {
      delete data.cheque_id
      data.user_billing_info = newcardID ? newcardID : new_card_uuid;
      data.card_id=newcardID ? newcardID : new_card_uuid;
    }
    if (log_type === 'ach') {
      data.user_billing_info = newachID ? newachID : new_ach_uuid;
      data.bank_payment = true;
    }
      const addPayment = async () => {
        const data1 = await createManualPayment(data)
        if(data1?.data?.message ===undefined){
          closeAndClear();
          message.success("Your payment was log successfully");
          setState((state)=>({...state, loader : false }))
          props.getInvoiceList();
        }else{
          message.error(data1.data.message);
          setState((state)=>({...state, loader : false }))
        }
      }
      addPayment()
     
  }

  // Clean drawer
  const clear = () => {
    let { payment_log } = state;

    payment_log = Object.assign(payment_log,
      {
        cash_payment: false,
        cheque_payment: false,
        received_from: '',
        amount_received: '',
        cheque_id: '',
        received_by_uuid: '',
        is_check: false
      }
    )
    setState((state)=>({
      ...state,
      payment_log,
      log_type: '',
      errors: {},
      loader: false
    }))
  }

  //Close drawer and clear value
  const closeAndClear = () => {
    props.closeDrawer(false);
    clear();
  }

  // Load Stax
  const loadStax = () => {
    let stax_public_apikey=props?.customer?.field_office?.stax_public_apikey;
    //const { stax_public_apikey } = state;
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {
        number: {
          id: "card-number",
          placeholder: "Card Number",
          style: "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;"
        },
        cvv: {
          id: "card-cvv",
          placeholder: "CVV",
          style: "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;"
        }
      });
      fattJsObj.showCardForm().then(handler => {
        console.log('form loaded', handler);
      }).catch(err => {
        console.error('error occur', err);
      });
      setFattJs(fattJsObj);
    } else {
      message.error('Error occur while loading stax. please contact to admin.', 10);
    }
  }

   // Load Stax ACH
   const loadStaxACH = () => {
    let stax_public_apikey = props?.customer?.field_office?.stax_public_apikey;
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {});
      setFattJs(fattJsObj);
    } else {
      message.error('Error occur while loading stax. please contact to admin.', 10);
    }
  }

    //Render Card Block
    const renderACHBlock = () => {
      const { log_type, payment_log, staffList, errors, ach_payment_type, new_ach_uuid, ach_list } = state;
      return (
        <div className={log_type === 'ach' ? 'form-group  log_card' : 'form-group'}>
          <div className="fitem">
            <div className="fitem-check">
              <input
                type="radio"
                className="fitem-ck-input"
                id="ach"
                checked={log_type === 'ach'}
                onChange={(e) => { onLogTypeChange(e) }}
                name="ach" />
              <label htmlFor="ach" className="fitem-ck-txt">ACH Payment</label>
            </div>
          </div>
          {log_type === 'ach' ?
            <div className="p-30">
              <div className="fitem text_with_place">
                <label>Amount Received<sup>*</sup></label>
                <div style={{ "position": "relative" }} className="amount-process">
                  <input type="number"
                    className="twhite"
                    name="amount_received"
                    value={payment_log.amount_received}
                    onChange={(e) => onNumericInputChange(e.target.value) && onTextChange(e)}
                    onPaste={(event) => {
                      if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="$0.00"
                  />
                </div>
                {payment_log.amount_received ? <div className="fix_isd_code t-25"><span>$</span></div> : ''}
                {errorsAch?.['payment_log.amount_received'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['payment_log.amount_received']}</label> : ''}
              </div>
              <div className="fitem">
                <label>Received From<sup>*</sup></label>
                <input type="text"
                  className="twhite"
                  name="received_from"
                  value={payment_log.received_from}
                  onChange={(e) => { onTextChange(e) }}
                  placeholder="" />
                {errorsAch?.['payment_log.received_from'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['payment_log.received_from']}</label> : ''}
              </div>
              <div className="fitem">
                <div >
                  <div className="fitem-check">
                    <input
                      type="radio"
                      className="fitem-ck-input"
                      id="new_card"
                      checked={ach_payment_type === 0}
                      onChange={() => {
                        setState((state) => (
                          {
                            ...state,
                            ach_payment_type: 0,
                            is_new_ach_payment: true,
                            is_old_ach_payment: false,
                          }
                        ))
                        loadStaxACH();
                      }}
                      name="card_type" />
                    <label htmlFor="new_card" className="fitem-ck-txt">Add New ACH Payment</label>
                  </div>
                </div>
                <div >
                  <div className="fitem-check">
                    <input
                      type="radio"
                      className="fitem-ck-input"
                      id="old_card"
                      checked={ach_payment_type === 1}
                      onChange={() => {
                        setState((state) => (
                          {
                            ...state,
                            ach_payment_type: 1,
                            is_new_ach_payment: false,
                            is_old_ach_payment: true,
                          }
                        ))
                      }}
                      name="card_type" />
                    <label htmlFor="old_card" className="fitem-ck-txt">Use Existing ACH Payment</label>
                  </div>
                </div>
              </div>
              {ach_payment_type === 1 ?
                <div className="fitem">
                  <label>ACH Payment List<sup>*</sup></label>
                  <Select
                    placeholder="Select Card"
                    filterOption={false}
                    value={new_ach_uuid}
                    name="new_ach_uuid"
                    onChange={(e) => {
                      setState((state) => (
                        {
                          ...state,
                          new_ach_uuid: e
                        }
                      ))
                    }}
                    className="swhite">
                    {map(ach_list?.data, (item) => {
                      if(item.is_ach) {
                      return (
                        <Option key={item.uuid}>{JSON.parse(item?.stax_payment_object)?.person_name} ACH XXXX {JSON.parse(item?.stax_payment_object)?.meta["accountDisplay"]}</Option>
                      )
                      }
                    })}
  
                  </Select>
                  {errorsAch?.['new_ach_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['new_ach_uuid']}</label> : ''}
                </div>
                :
                <div className="form-group">
  
                  <div className="fitem">
                    <label>Bank Name</label>
                    <input type="text" className="form--control" placeholder="Bank Name" maxLength={15}
                      inputMode="text"
                      style={{ background: "white" }}
                      // onPaste={(event) => {
                      // if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                      //     event.preventDefault();
                      // }
                      // }}
                      // onKeyPress={(e) => {
                      // const pattern = /[0-9]/;
                      // const inputChar = String.fromCharCode(e.charCode);
                      // if (!pattern.test(inputChar)
                      // ) {
                      //     // invalid character, prevent input
                      //     e.preventDefault();
                      // }
                      // }}
                      onChange={(e) => { setBankName(e.target.value) }}
                    />
                    {errorsAch?.['BankName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['BankName']}</label> : ''}
                  </div>
                  <div className="fitem" >
                    <label>Routing Number</label>
                    <input type="text" className="form--control" placeholder="Routing Number" maxLength={9}
                      inputMode="number"
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)
                        ) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                      if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                          event.preventDefault();
                      }
                      }}
                      style={{ background: "white" }}
                      onChange={(e) => { setRoutingNumber(e.target.value) }}
                    />
                    {errorsAch?.['RoutingNumber'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['RoutingNumber']}</label> : ''}
                  </div>
                  <div className="fitem">
                    <label>Account Holder Name</label>
                    <input type="text" className="form--control" placeholder="Account Holder Name"
                      inputMode="text"
                      style={{ background: "white" }}
                    
                      onChange={(e) => { setAccountHolderName(e.target.value) }}
                    />
                      {errorsAch?.['AccountHolderName'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errorsAch?.['AccountHolderName']}</label> : ''}
                  </div>
                  <div className="fitem">
                    <label>Account Number</label>
                    <input type="text" id="account-number" className="form--control" placeholder="Account Number" maxLength={17}
                      inputMode="number"
                      style={{ background: "white" }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)
                        ) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                            event.preventDefault();
                        }
                        }}
                      onChange={(e) => { setAccountNumber(e.target.value) }}
                    />
                    {errorsAch?.['AccountNumber'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['AccountNumber']}</label> : ''}
                  </div>
                  <div className="fitem">
                    <label>Account Type</label>
                    <div>
                      <input type="radio" id="achecking" name="AccountType" onChange={e => setAccountType(e.target.value)} value={"Checking"} />&nbsp;&nbsp;
                      <label for="html">Checking</label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input type="radio" id="asaving" name="AccountType" onChange={e => setAccountType(e.target.value)} value="Savings" />&nbsp;&nbsp;
                      <label for="html">Saving </label>
                    </div>
                    {errorsAch?.['AccountType'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['AccountType']}</label> : ''}
                  </div>
                  <div className="fitem">
                    <label>Bank Holder Type</label>
                    <div>
                      <input type="radio" id="checking" name="BankHolderType" onChange={e => setBankHolderType(e.target.value)} value="Personal" />&nbsp;&nbsp;
                      <label for="html">Personal</label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input type="radio" id="saving" name="BankHolderType" onChange={e => setBankHolderType(e.target.value)} value="Business" />&nbsp;&nbsp;
                      <label for="html">Business</label>
                    </div>
                    {errorsAch?.['BankHolderType'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errorsAch?.['BankHolderType']}</label> : ''}
                  </div>
                </div>
              }
              <div className="fitem">
                <label>Received By<sup>*</sup></label>
                <Select
                  showSearch
                  filterOption={false}
                  value={state.payment_log?.received_by_uuid}
                  onChange={(e) => { onReceiveChange(e) }}
                  onSearch={(e) => onSearchReceivedBy(e)}
                  onBlur={() => onSearchReceivedBy()}
                  defaultValue=""
                  className="swhite">
                  <Option value={state.payment_log?.received_by_uuid}>
                    {state.payment_log?.received_by}
                  </Option>
                </Select>
                {errors['payment_log.received_by_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['payment_log.received_by_uuid']}</label> : ''}
              </div>
            </div>
            : ''}
        </div>
  
      )
    }

  //Render cash block
  const renderCashBlock = () => {
    const { log_type, payment_log, staffList, errors, transaction_type } = state;
    return (
      <div className={log_type === 'cash' ? 'form-group  log_card  mb-20' : 'form-group'}>
        <div className="fitem">
          <div className="fitem-check">
            <input
              id="cash"
              type="radio"
              className="fitem-ck-input"
              checked={log_type === 'cash'}
              onChange={(e) => { onLogTypeChange(e) }}
              name="cash" />
            <label htmlFor="cash" className="fitem-ck-txt">Cash Payment</label>
          </div>
        </div>
        {log_type === 'cash' ?
          <div className="p-30">
            <div className="fitem text_with_place ">
              <label>Amount Received</label>
              <div style={{"position": "relative"}} className="amount-process">

                <input type="number"
                  className="twhite"
                  name="amount_received"
                  value={payment_log.amount_received}
                  onChange={(e) => formatToCurrency(e.target.value) && onTextChange(e)}
                  onPaste={(event) => {
                    if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="$0.00"
                />
              </div>
              {payment_log.amount_received ? <div className="fix_isd_code t-25"><span>$</span></div> : ''}
              {errors?.['payment_log.amount_received'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.amount_received']}</label> : ''}
            </div>
            <div className="fitem">
              <label>Received From</label>
              <input type="text"
                className="twhite"
                name="received_from"
                value={payment_log.received_from}
                onChange={(e) => { onTextChange(e) }}
                placeholder="" />
              {errors?.['payment_log.received_from'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.received_from']}</label> : ''}
            </div>
            <div className="fitem">
              <label>Received By</label>
              <Select
                showSearch
                filterOption={false}
                value={state.payment_log?.received_by_uuid}
                onChange={(e) => { onReceiveChange(e) }}
                onSearch={(e) => onSearchReceivedBy(e)}
                onBlur={() => onSearchReceivedBy()}
                className="swhite">
                <Option value={state.payment_log?.received_by_uuid}>
                  {state.payment_log?.received_by}
                </Option>
              </Select>
              {errors?.['payment_log.received_by_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.received_by_uuid']}</label> : ''}
            </div>
          </div>
          : ''}
      </div>
    )
  }

  const renderChequeBlock = () => {
    const { log_type, payment_log, staffList, errors, transaction_type } = state;

    return (
      <div className={log_type === 'cheque' ? 'form-group  log_card' : 'form-group'}>
        <div className="fitem">
          <div className="fitem-check">
            <input
              type="radio"
              className="fitem-ck-input"
              id="cheque"
              checked={log_type === 'cheque'}
              onChange={(e) => { onLogTypeChange(e) }}
              name="cheque" />
            <label htmlFor="cheque" className="fitem-ck-txt">Check Payment</label>
          </div>
        </div>
        {log_type === 'cheque' ?
          <div className="p-30">
            <div className="fitem text_with_place">
              <label>Amount Received</label>
              <div style={{"position": "relative"}} className="amount-process">
                <input type="number"
                  className="twhite"
                  name="amount_received"
                  value={payment_log.amount_received}
                  onChange={(e) => onNumericInputChange(e.target.value) && onTextChange(e)}
                  onPaste={(event) => {
                    if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="$0.00"
                />
              </div>
              {payment_log.amount_received ? <div className="fix_isd_code t-25"><span>$</span></div> : ''}
              {errors?.['payment_log.amount_received'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.amount_received']}</label> : ''}
            </div>
            <div className="fitem">
              <label>Received From</label>
              <input type="text"
                className="twhite"
                name="received_from"
                value={payment_log.received_from}
                onChange={(e) => { onTextChange(e) }}
                placeholder="" />
              {errors?.['payment_log.received_from'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.received_from']}</label> : ''}
            </div>
            <div className="fitem">
              <label>Check ID #</label>
              <input type="text"
                className="twhite"
                name="cheque_id"
                maxLength="7"
                value={payment_log.cheque_id}
                onChange={(e) => { onTextChange(e) }}
                onPaste={(event) => {
                  if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                    event.preventDefault();
                  }
                }}
                onKeyPress={(e) => {
                  const pattern = /[0-9]/;
                  const inputChar = String.fromCharCode(e.charCode);
                  if (!pattern.test(inputChar)
                  ) {
                    // invalid character, prevent input
                    e.preventDefault();
                  }
                }}
                placeholder="" />
              {errors?.['payment_log.cheque_id'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.cheque_id']}</label> : ''}
            </div>
            <div className="fitem">
              <label>Received By</label>
              <Select
                showSearch
                filterOption={false}
                value={state.payment_log?.received_by_uuid}
                onChange={(e) => { onReceiveChange(e) }}
                onSearch={(e) => onSearchReceivedBy(e)}
                onBlur={() => onSearchReceivedBy()}
                defaultValue=""
                className="swhite">
                <Option value={state.payment_log?.received_by_uuid}>
                  {state.payment_log?.received_by}
                </Option>
              </Select>
              {errors?.['payment_log.received_by_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.received_by_uuid']}</label> : ''}
            </div>
          </div>
          : ''}
      </div>
    )
  }

  //Render Card Block
  const renderCardBlock = () => {
    const { log_type, payment_log, staffList, errors, card_payment_type, new_card_uuid, card_list } = state;
    return (
      <div className={log_type === 'card' ? 'form-group  log_card' : 'form-group'}>
        <div className="fitem">
          <div className="fitem-check">
            <input
              type="radio"
              className="fitem-ck-input"
              id="card"
              checked={log_type === 'card'}
              onChange={(e) => { onLogTypeChange(e) }}
              name="card" />
            <label htmlFor="card" className="fitem-ck-txt">Card Payment</label>
          </div>
        </div>

        {log_type === 'card' ?
          <div className="p-30">
            <div className="fitem text_with_place">
              <label>Amount Received</label>
              <div style={{"position": "relative"}} className="amount-process">
                {/* <span style={{
                  "position": "absolute",
                  "left": "5px",
                  "top": "10px",
                  "color": "gray"
                }}>$</span> */}
                <input type="number"
                  className="twhite"
                  name="amount_received"
                  value={payment_log.amount_received}
                  onChange={(e) => onNumericInputChange(e.target.value) && onTextChange(e)}
                  onPaste={(event) => {
                    if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="$0.00"
                />
              </div>
              {payment_log.amount_received ? <div className="fix_isd_code t-25"><span>$</span></div> : ''}
              {errors?.['payment_log.amount_received'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.amount_received']}</label> : ''}
            </div>
            <div className="fitem">
              <label>Received From</label>
              <input type="text"
                className="twhite"
                name="received_from"
                value={payment_log.received_from}
                onChange={(e) => { onTextChange(e) }}
                placeholder="" />
              {errors?.['payment_log.received_from'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['payment_log.received_from']}</label> : ''}
            </div>

            <div className="fitem half-fitem">
              <div className="fitem half-fitem inline-check-list">
                <div className="fitem-check">
                  <input
                    type="radio"
                    className="fitem-ck-input"
                    id="new_card"
                    checked={card_payment_type === 0}
                    onChange={() => {
                      setState((state)=>(
                        {
                        ...state,  
                        card_payment_type: 0,
                        is_card_payment: true,
                        id_old_card: false
                        }
                      ))
                      loadStax();
                    }}
                    name="card_type" />
                  <label htmlFor="new_card" className="fitem-ck-txt">Add New Card</label>
                </div>
              </div>

              <div className="fitem half-fitem inline-check-list">
                <div className="fitem-check">
                  <input
                    type="radio"
                    className="fitem-ck-input"
                    id="old_card"
                    checked={card_payment_type === 1}
                    onChange={() => {
                      setState((state)=>(
                        {
                        ...state,  
                        card_payment_type: 1,
                        is_card_payment: false,
                        id_old_card: true
                        }
                      ))
                    }}
                    name="card_type" />
                  <label htmlFor="old_card" className="fitem-ck-txt">Use Existing Card</label>
                </div>
              </div>
            </div>
            {card_payment_type === 1 ?
              <div className="fitem">
                <label>Card List</label>
                <Select
                  placeholder="Select Card"
                  filterOption={false}
                  value={new_card_uuid}
                  onChange={(e) => {
                    setState((state)=>(
                    {
                      ...state,
                      new_card_uuid: e
                    }
                    ))
                  }}
                  className="swhite">
                  {map(card_list?.data, (item) => {
                    if(!item.is_ach) {
                    return (
                      <Option key={item.uuid}>{item?.card_holder_name}-{JSON.parse(item?.stax_payment_object)?.card_last_four}</Option>
                    )
                    }
                  })}

                </Select>
                {errors['new_card_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_card_uuid']}</label> : ''}
              </div>
              :
              <React.Fragment>

                <div className="fitem">
                  <label>Card Number</label>
                  <div id="card-number" className="form--control twhite"></div>
                </div>

                <div className="fitem">
                  <label>CVV</label>
                  <div id="card-cvv" className="form--control twhite"></div>
                </div>
                
                <div className="fitem">
                  <label>Cardholder Name</label>
                  <input
                    type="text"
                    value={card_holder_name || ""}
                    onChange={(e) => {
                      setcard_holder_name(e.target.value);
                    }}
                    className="form--control twhite"
                    placeholder="Cardholder Name"                            
                  />
                  {errors?.["card_holder_name"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["card_holder_name"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>

                <div className="fitem half-fitem">
                  <div className="fitem half-fitem">
                    <label>Expiration Month</label>
                    <input type="text" className="form--control twhite" placeholder="MM" maxLength={2} 
                      inputMode="numeric"
                      onPaste={(event) => {
                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)
                        ) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setState((state)=>({...state, card_expiry_month: e.target.value })) }} 
                    />
                    {errors?.['card_expiry_month'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['card_expiry_month']}</label> : ''}
                  </div>
                  <div className="fitem half-fitem">
                    <label>Expiration Year</label>
                    <input type="text" className="form--control twhite" placeholder="YYYY" maxLength={4} 
                      inputMode="numeric"
                      onPaste={(event) => {
                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)
                        ) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setState((state)=>({...state, card_expiry_year: e.target.value })) }} 
                    />
                    {errors?.['card_expiry_year'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['card_expiry_year']}</label> : ''}
                  </div>
                </div>
                
              </React.Fragment>
            }
            <div className="fitem">
              <label>Received By</label>
              <Select
                showSearch
                filterOption={false}
                value={state.payment_log?.received_by_uuid}
                onChange={(e) => { onReceiveChange(e) }}
                onSearch={(e) => onSearchReceivedBy(e)}
                onBlur={() => onSearchReceivedBy()}
                defaultValue=""
                className="swhite">
                <Option value={state.payment_log?.received_by_uuid}>
                  {state.payment_log?.received_by}
                </Option>
              </Select>
              {errors['payment_log.received_by_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['payment_log.received_by_uuid']}</label> : ''}
            </div>
          </div>
          : ''}
      </div>

    )
  }

//   render() {
    const { visible, payment } = props;
    const { errors, loader, transaction_type, showSuccess } = state;

    return (
      <Drawer
        placement={'right'}
        closable={false}
        onClose={() => closeAndClear()}
        visible={visible}
        key={'right'}
        width={'400'}
        className="drawer-wrapper"
      >
        <div className="rdrawer log_payment_drawer">
          <div className="card-row">
            <div className="card-full">
              {loader && <Loader/>}
              <h4>{`${ transaction_type === 'create' ? 'Log Manual Payment' : 'Edit Payment'}`}</h4>
              { (transaction_type !== 'edit' || payment.payment_type === "Cash") && renderCashBlock()}
              { (transaction_type !== 'edit' || payment.payment_type === "Check") && renderChequeBlock()}
              { transaction_type !== 'edit' && renderCardBlock()}
              { transaction_type !== 'edit' && renderACHBlock()}
              {errors?.['log_type'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['log_type']}</label> : ''}
            </div>
            {
              showSuccess ?
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "fixed",
                  width: "350px",
                  zIndex: "9",
                  justifyContent: "center",
                  bottom: "100px",

                }}>
                  <div className="fitem-ricon">
                    <i style={{fill: "green"}} className="las la-check"></i>
                  </div>
                  <span style={{marginLeft: "15px"}}>Your payment was successfully added</span>
                </div> : null
            }

            <div className="footer" style={{ 'width': '400px' }}>
              <button
                className="btn btn-brand btn-bdr"
                onClick={() => {
                  closeAndClear()
                }}
              >Cancel</button>
              <button
                // disabled={!is_btn_active}
                style={state.loader? {opacity: '0.5', cursor: 'not-allowed'} : {opacity: '1'}}
                className={`btn btn-warning`}
                onClick={() => validateLog()}
                disabled = {transaction_type === 'create' ? state.loader : null}
              >{ transaction_type === 'create' ? 'Log Payment' : 'Update Payment'}</button>
            </div>
          </div>
        </div>
      </Drawer>
    );
}

export default LogPayment;