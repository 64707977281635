export function formatToCurrency (number)
{
	let formatedNumber = parseFloat(number).toLocaleString("es-US");

	if(formatedNumber.includes("NaN"))
	{
		formatedNumber = parseFloat(0).toLocaleString("es-US");
	}
	if(!formatedNumber.includes("."))
	{
		return `${formatedNumber}.00`;
	}
	else if(formatedNumber.split(".")[1].length == 1)
	{
		return `${formatedNumber}0`
	}
	else 
	{
		return formatedNumber;
	}
}