import React,{useState, useEffect, useRef} from "react";
import { Table, Row, Col } from "antd";
import moment from "moment-timezone";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";
import Loader from "../../../Components/Loader";
import { Modal } from "antd";
import { getFranchise } from "../../../Action/Franchise";
const TechnicianNotesTable = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;
  const {
    reportData,
    current,
    total,
    setCurrent,
    setPageSize,
    pageSize,
    setSorter,
    loading,
    leadReportclientType,
    isMultiLocation
  } = useReport();

  const [woModalOpen,setWOModalOpen] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [selectedRecord,setSelectedRecord] = useState(null);

  const handleTableChange = (pagination, filters, sorter, extra) =>
    extra.action === "sort"
      ? sorter.order !== undefined
        ? setSorter(sorter)
        : setSorter(null)
      : null;
  const setUserPageLimit = (custom_limit) => {
    setCurrent(1);
    setPageSize(custom_limit);
  }
  const handelOpenForm = (record) => {
    setWOModalOpen(true);
    setSelectedRecord(record);
  }
  const handleWOSave = (saveWOResponse) => {
    setWOModalOpen(saveWOResponse);
  };
  /*const handleChemicalType = () => {
    const total = reportData.reduce((result, item) => {
      return (result += item.total_jobs);
    }, 0);
    if (reportType === "detail" && total === 0) {
      return true;
    }
    if (total > 0) return false;
    return true;
  };*/

  const getTableColumns = () => {
    let tableColumns = DataTable.TechnicianNotesReport(this,handelOpenForm); 
    if(!isCorporate && !isMultiLocation)
      tableColumns = tableColumns.filter(object => {return object.key !== "field_office";});

    if(leadReportclientType === "residential")
      tableColumns = tableColumns.filter(object => {return object.key !== "company_name";});
    
    return tableColumns;
  };
  const getStatusClassNames = () => {

    if(!isCorporate && !isMultiLocation)
      
      return leadReportclientType !== "residential"? "technician-notes-3-table-container" : "technician-notes-2-table-container";

    return leadReportclientType === "residential"? "technician-notes-1-table-container" :"technician-notes-table-container";
  };
  
  return (
    <>
      <Row>
      {loading && <Loader />}
          <Col span={24} className={getStatusClassNames()}>
            <Table
              dataSource={
                  reportData.map((item, idx) => ({ key: idx, ...item }))
              }
              columns={getTableColumns()}
              bordered
              className="table hscroll"
              onChange={handleTableChange}
              pagination={{
                showSizeChanger: false,
                pageSize: pageSize,
                ordering: "",
                current,
                total,
                onChange(page, pageSize) {
                  setCurrent(page);
                  setPageSize(pageSize);
                },
              }}
            />
            <ul className="pagination_page_size">
                  <li>
                    <span>Show:</span>
                  </li>
                  <li>
                    <span
                      onClick={() => { setUserPageLimit('15') }}
                      className={pageSize === '15' || pageSize === 15  ? 'active' : ''}
                    >15</span>
                  </li>
                  <li>
                    <span
                      className={pageSize === '30' || pageSize === 30 ? 'active' : ''}
                      onClick={() => { setUserPageLimit('30') }}>30</span>
                  </li>
                  <li>
                    <span
                      className={pageSize === '60' || pageSize === 60 ? 'active' : ''}
                      onClick={() => { setUserPageLimit('60') }}>60</span>
                  </li>
                </ul>
          </Col>
      </Row>
      {/*
      title={`Work Order Form ${selectedEvent?.original?.title}`}
      */
      }
      <Modal
      title={"Work Order Form"+" "+selectedRecord?.job_name}
      open={woModalOpen}
      onOk={() => setWOModalOpen(false)}
      onCancel={() => setWOModalOpen(false)}
      destroyOnClose
      className="schedule_wo_modal"
      style={{
        top: 20,
      }}
      footer={null}
      onResponse={handleWOSave}
      maskClosable={false}
    >
      <iframe
        src={selectedRecord?.form_url+selectedRecord?.uuid}
        width={"100%"}
        height={windowSize.current[1] * 0.8}
        title={selectedRecord?.form_name}
      />
    </Modal>
    </>
  );
};

export default TechnicianNotesTable;
