import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

const serviceOrderList = [
  {
    service_order_number: "SO-00043",
    uuid: "9ed04cd8-633f-3737-a51a-91615b7a8fb2",
    status: null,
    service_address: {
      uuid: "31389c86-9697-3006-9a35-d2f6c7e3973a",
      address: "301 East 25th Street",
      address2: "",
      city: "West Palm Beach",
      state: "FL",
      zip_code: "33404",
      geo_location: null,
      formatted_address: "301 East 25th Street, West Palm Beach, FL. 33404",
    },
    service_type: "maintenance",
    frequency: "monthly",
  },
];

const workOrderList = [
  {
    work_order_number: "WO-00024",
    uuid: "a79a0320-c669-312f-8d7e-a4c9f9bece80",
    scheduled_appointment: "2022-07-21T09:00:00",
    service_name: null,
    technician: null,
    status: "forecast",
    job_forms: [1],
  },
  {
    work_order_number: "WO-00025",
    uuid: "a79a0320-c669-312f-8d7e-a4c9f9bece81",
    scheduled_appointment: "2022-07-21T09:00:00",
    service_name: null,
    technician: null,
    status: "forecast",
    job_forms: [1],
  },
  {
    work_order_number: "WO-00026",
    uuid: "a79a0320-c669-312f-8d7e-a4c9f9bece82",
    scheduled_appointment: "2022-07-21T09:00:00",
    service_name: null,
    technician: null,
    status: "forecast",
    job_forms: [1],
  },
];

// Create Customer
export const createCustomer = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`crm/create-customer/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

export const createCustomerTechJourney = async (params, clientType) => {
  const apiEP = {
    residential: {
      createLeadURL: "crm/create-customer/",
      createCustomerURL: "service-order/",
    },
    commercial: {
      createLeadURL: "crm/commercial-lead-user/",
      createCustomerURL: "crm/commercial-service-order-enquiry/",
    },
  };
  const createCustomerResponse = await axiosInstance
    .post(apiEP[clientType].createLeadURL, params[clientType].lead)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  const createServiceOrderResponse = await axiosInstance
    .post(apiEP[clientType].createCustomerURL, {
      ...params[clientType].serviceOrder,
      service_address_uuid: createCustomerResponse.service_address_uuid,
      field_office_uuid:
        clientType === "residential"
          ? createCustomerResponse.field_office_uuid
          : params[clientType].serviceOrder.field_office_uuid,
      client_uuid: createCustomerResponse.uuid,
    })
    .then((response) => {
      let vals = response?.data;
      vals.invoice_address = createCustomerResponse.invoice_address;
      return vals;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });

  return createServiceOrderResponse;
};

// field-office-price getReapairCode
export const getCustomerRepairCode = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`field-office-price?field_office_uuid=${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
// RETAILSALE CUSTOMER DATA
export const retailSaleCustomerData = (params) => {
  return axiosInstance
    .get(`crm/customer/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Create Service Order
export const createServiceOrder = (params) => {
  console.log("CREATE SERVICE ORDER PARAMS", params)
  return axiosInstance
    .post(`service-order/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Customer List
export const getCustomerList = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/customer/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};
// get commercial customer tab table list

export const getCustomerListCommercial = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/commercial-customer/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Get lead list
export const getCommercialCustomerList = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/commercial-lead-user/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};
//CREATE COMMERCIAL CUSTOMER
export const createCommercialCustomer = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`crm/commercial-lead-user/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};
//

// Get residential lead list
export const getResidentialLeadList = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/residential-lead-user/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Create lead
export const createLead = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`crm/commercial-lead-user/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Create lead residential
export const createLeadResidential = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`crm/residential-lead-user/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Get lead by id
export const getLeadById = (id) => {
  return axiosInstance
    .get(`crm/commercial-lead-user/${id}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Get lead by id
export const getLeadByIdResidential = (id) => {
  return axiosInstance
    .get(`crm/residential-lead-user/${id}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Update lead
export const updateLead = (params) => {
  return axiosInstance
    .patch(`crm/commercial-lead-user/${params.uuid}/`, { ...params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Update lead
export const updateLeadResidential = (params) => {
  return axiosInstance
    .patch(`crm/residential-lead-user/${params.uuid}/`, { ...params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Get service order by id
export const getServiceOrderLeadById = (param) => {
  return axiosInstance
    .get(
      `crm/commercial-service-order-enquiry/by_client/?field_office_uuid=${param.field_office_uuid}&client_uuid=${param.client_uuid}`
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//One Customer
export const getCustomer = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/customer/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

//Commercial Customer
export const getCommercialCustomer = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/commercial-customer/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Service Order List
export const getServiceOrderList = (params) => {
  return axiosInstance
    .get(`service-order/customer_list/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const getService = (params) => {
  return axiosInstance
    .get(`/service/${params}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};
export const sendTechnicianWorkOrder = (params) => {
  return axiosInstance
    .post(`technician-form-details/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const sendTechnicianWorkOrderM = async (params) => {
  try {
    return await axiosInstance.post(`technician-form-details/`, params)?.data;
  }
  catch(error){
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  };
};

// Service Order List by client
export const getServiceOrderByClient = (params) => {
  return axiosInstance
    .get(`service-order/by_client/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Service Order List by client
export const getServiceOrderByLead = (params) => {
  return axiosInstance
    .get(`service-order/by_client/?status=enquiry`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Service(Work Order) by id
export const getWorkOrder = (params) => {
  return axiosInstance
    .get(`/service/${params}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

export const createWorkOrder = (params) => {
  return axiosInstance
    .post(`/service/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Service(Work Order) List
export const getWorkOrderList = (params) => {
  // return workOrderList;
  // return (dispatch) => {
  return axiosInstance
    .get(`service/customer_list/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Service(Work Order) List
export const getWorkOrderListByClient = (params) => {
  return axiosInstance
    .get(`service/by_client/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

//Delete service (work order)
export const deleteService = (id) => {
  return axiosInstance
    .delete(`/service/${id}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// User Billing Info
export const getUserBillingInfo = (params) => {
  // return billingInfo;
  // return (dispatch) => {
  return axiosInstance
    .get(`user-billing-info/get_card_details`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Send work order to Optimo Route
export const sendToOptimoRoute = (params) => {
  return axiosInstance
    .post(`ftech/service-allocation/weekly_allocation/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Create user Pool characteristics
export const createPoolCharacteristics = (params) => {
  return axiosInstance
    .post(`pool-characteristic/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

//Create user Pool characteristics
export const updatePoolCharacteristics = (params, id) => {
  return axiosInstance
    .put(`pool-characteristic/${id}/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// save images from pool characteritics
export const savePoolImages = async (params) => {
  try {
    const response = await axiosInstance.post(
      `/pool-characteristic/upload_images/`,
      params
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};
export const getNewServiceOrderData = (params) => {
  return axiosInstance
    .get(`/pool-characteristic/client_uuid/?client_uuid=${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get services address by customer
export const getServicesAddress = (client_uuid) => {
  return axiosInstance
    .get(`/crm/commercial-customer/${client_uuid}/service-addresses/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get Pool Characteristics by customer
export const getPoolCharacteristics = (params) => {
  return axiosInstance
    .get(`/pool-characteristic/client_uuid/?client_uuid=${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// get images from pool characteritics
export const getPoolImages = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/pool-characteristic/list_for_pictures/?id=${params}`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

//Create Notes
export const createNotes = (params) => {
  return axiosInstance
    .post(`/crm/account-notes/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get Notes
export const getNotes = (params) => {
  return axiosInstance
    .get(`/crm/account-notes`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Add Billing info
export const addBillingInfo = (params) => {
  return axiosInstance
    .post(`/user-billing-info/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Update Billing info
export const updateBillingInfo = (params) => {
  return axiosInstance
    .patch(`/user-billing-info/${params.uuid}/`, { ...params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//De-Active the customer
export const deActiveCustomer = (params) => {
  return axiosInstance
    .patch(`/crm/create-customer/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get Service Order by User Uiid
export const getServiceOrderByUser = (params) => {
  return axiosInstance
    .get(`/service-order/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Save work order info from edit it
export const createServiceLineItem = (params, service_order_uuid) => {
  return axiosInstance
    .post(
      `/service-line-item/do_batch/?service_uuid=` + service_order_uuid,
      params
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get Work Order lines
export const getLines = (params) => {
  return axiosInstance
    .get(`/service-line-item/list_for_service/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const saveMaintenencePoolReport = (
  payload,
  isEmail,
  uuid,
  date,
  hour
) => {
  return axiosInstance
    .post(
      `/form-data/save_and_complete/?form_data_uuid=${uuid}&send_email=${isEmail}&report_date=${date}&report_time=${hour}`,
      payload
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getSaveMaintenencePoolReport = (params) => {
  return axiosInstance
    .get(`/form-data/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        //window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get Prices
export const getPrices = (params) => {
  return axiosInstance
    .get(`/field-office-price/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getPDFMaintenencePoolReport = (uuid) => {
  return axiosInstance
    .get(`form-data/as_pdf/?form_data_uuid=${uuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const deleteBillingInfo = (id) => {
  return axiosInstance
    .delete(`user-billing-info/${id}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// getPauseService
export const getPauseService = async (params) => {};

// Convert Customer
export const convertLeadToCustomer = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`crm/commercial-service-order-enquiry/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
  // return Promise.resolve({result: "successsssss"});
};

// get work orders by service order
export const getWorkOrderByService = async (params) => {
  try {
    const response = await axiosInstance.get(`/service/by_service_order/`, {
      params: params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// get work orders by service order
export const getServiceMatrix = async (field_office__uuid) => {
  try {
    const response = await axiosInstance.get(
      `/field-office/${field_office__uuid}/service-matrix/`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// get work orders by service order
export const getWorkOrdersFilter = async (params) => {
  try {
    //Edit when API is ready
    const response = await axiosInstance.get(`/service-ftech/get_work_order/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// get work orders by service order
export const editServiceOrder = async (uuid, params) => {
  try {
    const response = await axiosInstance.put(`/service-order/${uuid}/`, params);
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

//Convert Customer save lead
export const updateLeadConvertCustomer = (id, params) => {
  return axiosInstance
    .patch(`crm/commercial-service-order-enquiry/${id}/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// search customer detail
export const searchCustomer = (params, franchise) => {
  return axiosInstance
    .get(`crm/customer-user/?q=${params}&limit=60&is_active=true&offset=0&search_customer=True`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
};

//Search technician
export const searchTechnician = (params, StaffProfileFieldOfficeuuid)=>{
  return axiosInstance
  .get(`crm/staff-user/?q=${params}&is_active=true&role=franchise_owner,field_tech&staff_profile__field_office__uuid=${StaffProfileFieldOfficeuuid}`)
  .then((response)=>{
    return response?.data
  })
  .catch((error)=>{
    console.log('Error =>',error)
  })
}

// Update customer detail
export const updateCustomer = (id, params) => {
  return axiosInstance
    .patch(`crm/customer-user/${id}/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Update customer detail
export const updateCustomerCommercial = (id, params) => {
  return axiosInstance
    .patch(`crm/commercial-customer/${id}/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// upload Image
export const postUploadImage = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`images/upload_images/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

export const getUploadImages = (uuid, type) => {
  return axiosInstance
    .get(`/images/list_for_pictures/?id=${uuid}&id_type=${type}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getUploadImage = (uuid) => {
  return axiosInstance
    .get(`/images/list_for_pictures/?id=${uuid}&id_type=formdata`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getUploadImageFilterClean = (uuid) => {
  return axiosInstance
    .get(`/images/list_for_pictures/?id=${uuid}&id_type=filterclean`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getUploadImagePoolInspection = (uuid) => {
  return axiosInstance
    .get(`/images/list_for_pictures/?id=${uuid}&id_type=poolinspect`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getUploadImageEditWorkOrder = (uuid) => {
  return axiosInstance
    .get(`/images/list_for_pictures/?id=${uuid}&id_type=service`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const updatePulseCustomerAccount = (uuid, params) => {
  return axiosInstance
    .patch(`/pulsem-customer-account/${uuid}/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getPulseCustomerAccount = (uuid) => {
  return axiosInstance
    .get(`/pulsem-customer-account/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getAvalaraTaxValue = (zip_code) => {
  return axiosInstance
    .get(`/service/get_avalara_tax_rate/?zip_code=${zip_code}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const deactivateserviceorderapi = (params, service_order_uuid) => {
  // alert(params);
  // console.log(params);
  return axiosInstance
    .patch(
      `/service-order/deactivate_reactivate/?uuid=` + service_order_uuid,
      params
    )
    .then((response) => {
      return response?.data?.message;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const getLeadsList = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`lead-report`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const getCustomersList = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`customer-report`, { params })
    .then((response) => {
      console.log("success");
      console.log(response.data);
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const activateDeactivatedCustomer = (service_order_uuid, params) => {
  return (
    axiosInstance
      .patch(
        `user/active_inactive_customer/?uuid=` + service_order_uuid,
        params
      )
      // .patch(`user/active_inactive_customer/?uuid=${service_order_uuid}&${{...params}}`)
      .then((response) => {
        message.success(response.data.success);
        return response?.data;
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403 ||
          error?.response?.status === 404
        ) {
          message.error("Something went wrong!");
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          console.log("ERROR: ", error.response);
          message.error(error?.response?.data?.error);
          // return error?.response?.data;
        }
      })
  );
};

//Get Previous Chemical datas
export const getChimicalDatas = (params) => {
  return axiosInstance
    .get(`/chemical-report/get_chemical_details/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getCustomersForRetailSales = (params) => {
  return axiosInstance
    .get(`crm/customer-user/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// delete Customer
export const verifyCustomerToDelete = async (customer_uuid) => {
  try {
    const response = await axiosInstance.get(
      `/crm/customer/${customer_uuid}/has-invoices`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      //message.error("Fail to request data");
      throw error;
    }
  }
};

// delete Customer
export const deleteCustomer = async (customer_uuid) => {
  try {
    const response = await axiosInstance.delete(
      `/crm/customer/${customer_uuid}`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      //message.error("Fail to request data");
      throw error;
    }
  }
};
export const getCustomers = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`/crm/general-customer/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

export const getLeads= (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`/crm/general-lead/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Print invoice
export const printInvoiceRequest = async (invoice_uuid) => {
  try {
    const response = await axiosInstance.get(
      `invoice/${invoice_uuid}/render/?action=base64`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      //message.error("Fail to request data");
      throw error;
    }
  }
};

// Delete Lead
export const deleteCommercialLead = async (uuid) => {
  try {
    const response = await axiosInstance.delete(
      `crm/commercial-lead-user/${uuid}`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      throw error;
    }
  }
};

// Delete Lead
export const deleteResidentialLead = async (uuid) => {
  try {
    const response = await axiosInstance.delete(
      `crm/residential-lead-user/${uuid}`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      throw error;
    }
  }
};

// Get Customer Invoice Preference
export const getCustomerInvoicePreference = async (params) => {
  try {
    const response = await axiosInstance
    .get(
      `crm/customer/invoice-preferences/`, { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      throw error;
    }
  }
};

// Update Customer Invoice Preference
export const updateCustomerInvoicePreference = (user_uuid, params) => {
  return axiosInstance
    .patch(`crm/customer/invoice-preference-update/?user_uuid=${user_uuid}`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const exportLeads= (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`/crm/general-lead/export/`, { params })
    .then((response) => {
      if (response.headers['content-type'] === 'application/json') {
        return response.data;
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const timestamp = Date.now();
        
        if(params?.leads_report == true)
          link.setAttribute('download', `Lead Report_${timestamp}.csv`);
        else
          link.setAttribute('download', `leadsList_${timestamp}.csv`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
      }
    });
};

export const exportCustomers = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`/crm/general-customer/export/`, { params })
    .then((response) => {
      if (response.headers['content-type'] === 'application/json') {
        return response.data;
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const timestamp = Date.now();
        if(params?.customersReport == true)
          link.setAttribute('download', `Customer Report_${timestamp}.csv`);
        else
          link.setAttribute('download', `customersList_${timestamp}.csv`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
      }
    });
  
};

export const checkEmail = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/accounts/check-email?email=${params.email}`      
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      throw error;
    }
  }
};

export const deactivateAndReactivate = (proposal_uuid,params) => {
  return axiosInstance
   .patch(`/proposal/active_deactive_proposal/?uuid=${proposal_uuid}`, params)
   .then((response) => {
     return response?.data;
   })
   .catch((error) => {
     if (error?.response) {
       return error?.response?.data;
     }
   });
};

export const deleteProposal = (proposal_uuid ) => {
 return axiosInstance
   .patch(`/proposal/delete_proposal/?uuid=${proposal_uuid}`)
   .then((response) => {
     return response?.data;
   })
   .catch((error) => {
     if (error?.response) {
       return error?.response?.data;
     }
   });
 };

 //get proposal api
export const getProposalData = (id) => {
  return axiosInstance
    .get(`/proposal/${id}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//update proposal signature api
export const updateProposalSignature = (payload, uuid) => {
  console.log(uuid, 'uuid')
  return axiosInstance
    .patch(`/update-signature/${uuid}/`, { ...payload })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//updateProposalStatus
export const updateProposalStatus = (params) => {
  console.log(params, 'params')
  return axiosInstance
  .patch(`/proposal/update_proposal_status/`, { ...params })
  .then((response) => {
    return response?.data;
  })
  .catch((error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      window.location.assign("/");
      localStorage.clear();
      sessionStorage.clear();
    }
    if (error?.response) {
      return error?.response?.data;
    }
  });
};
