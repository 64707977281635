import {map} from "lodash";
import {message} from "antd";

export const errorHandle = (errors) => {
  // message.destroy()
  if (typeof  errors === 'string') {
    message.error('Something went wrong', 10)
  } else if (Array.isArray(errors)) {
    map(errors, (item) => {
      if (typeof item === 'string') {
        message.error(item, 10)
      } else {
        message.error('Something went wrong', 10)
      }
    })
  } else if (Object.keys(errors).length > 0) {
    // const keys = Object.keys(errors);
    map(Object.values(errors), (item, index) => {
      if (typeof item === 'string') {
        // message.error(`${keys[index]}: ${item}`, 10)
        message.error(`${item}`, 10)
      } else if (Array.isArray(item)) {
        const str = item ? item?.join(', ') : item
        if (typeof str === 'string') {
          // message.error(`${keys[index]}: ${str}`, 10)
          message.error(`${str}`, 10)
        } else {
          message.error('Something went wrong', 10)
        }
      } else {
        message.error('Something went wrong', 10)
      }
    })
  } else {
    message.error('Something went wrong', 10)
  }
}