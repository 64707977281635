import React, { useEffect } from "react";
import {Drawer, Select} from "antd";
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { FilterOutlined } from '@ant-design/icons';
import { setProposalDrawer,addCustomerType, setEndDate, setStartDate,
         addProposalStatus, addProposalServiceType, addSelectedFranchise, setFilterFlag } from "../Store/proposalTableSlice";
import ProposalFranchiseFilter from "./ProposalFranchiseFilter";
import ProposalDateFilter from "./ProposalDateFilter";
import appData from "../Utils/constants";

const ProposalFilterDrawer = () => {
    const { Option } = Select;
    const dispatch = useDispatch();
    const proposalData = useSelector((state) => state.proposalsData);

    useEffect(() => {
        
    }, [])

    const filterOut = (e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(setProposalDrawer(true));
        dispatch(setFilterFlag(false));
    }

    const filterProposals = (e) => {
        dispatch(setFilterFlag(true));
        dispatch(setProposalDrawer(false));
        dispatch(addSelectedFranchise(proposalData.drawerFranchiseSelected));
        dispatch(setStartDate(proposalData.startDateDrawer));
        dispatch(setEndDate(proposalData.endDateDrawer));
    }

    const closeDrawer = () => {
        dispatch(setProposalDrawer(false));
    };

  const onMultiSelectServiceType = (e) => {
    let selection = e;

    if (e.length > 1 && e[0] === "all") {
      selection = e.filter(item => item !== "all");
    }
    else if (e.length === 0 || (e.length > 0 && e.includes('all'))) {
      selection = ["all"];
    }
    dispatch(addProposalServiceType(selection))
  }

  const onMultiSelectCustomerType = (e) => {
    let selection = e;

    if (e.length > 1 && e[0] === "all") {
      selection = e.filter(item => item !== "all");
    }
    else if (e.length === 0 || (e.length > 0 && e.includes('all'))) {
      selection = ["all"];
    }
    dispatch(addCustomerType(selection))
  }

  const onMultiSelectStatusType = (e) => {
    let selection = e;

    if (e.length > 1 && e[0] === "all") {
      selection = e.filter(item => item !== "all");
    }
    else if (e.length === 0 || (e.length > 0 && e.includes('all'))) {
      selection = ["all"];
    }
    dispatch(addProposalStatus(selection))
  }

    const renderDrawer = () => {

        return (
          <Drawer
            placement={'right'}
            closable={false}
            onClose={closeDrawer}
            visible={proposalData.openDrawer}
            key={'right'}
            width={'400'}
            className="drawer-wrapper"
          >
            <div className="group_wrapper rdrawer">
              <div className="card-head">
                <h4>Filter Proposals</h4>
              </div>
              <div className="card-row">
                <div className="card-full">
                  <div className="form-group">
                    <div className="fitem">
                        <label htmlFor="proposalType">Franchise</label>
                        <ProposalFranchiseFilter view="drawer"/>
                    </div>
                    <div className="fitem">
                        <label htmlFor="proposalType">Proposal Date</label>
                        <ProposalDateFilter view="drawer"/>
                    </div>
                    <div className="fitem">
                        <label htmlFor="proposalType">Proposal Status</label>
                          <Select
                            showArrow={true}
                            name="proposal_status"
                            value={proposalData?.proposalStatusSelected}
                            className="filterDrawer"
                            mode="multiple"
                            onChange={(e) => onMultiSelectStatusType(e)}
                          >
                            {appData?.proposalStatus?.map((option) =>
                              <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                            }
                          </Select>
                    </div>
                    <div className="fitem">
                        <label htmlFor="proposalType">Customer Type</label>
                            <Select
                                showArrow={true}
                                name="customer_type"
                                value={proposalData?.customerTypeSelected}
                                className="filterDrawer"
                                mode="multiple"
                                onChange={(e) =>  onMultiSelectCustomerType(e)}
                            >
                              {appData?.proposalCustomerType?.map((option) =>
                                <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                              }
                            </Select>
                    </div>
                    <div className="fitem">
                        <label htmlFor="proposalType">Service Type</label>
                            <Select
                                showArrow={true}
                                name="service_type"
                                value={proposalData?.proposalServiceTypeSelected}
                                className="filterDrawer"
                                mode="multiple"
                                onChange={(e) => onMultiSelectServiceType(e)}
                            >   {
                                    appData?.proposalServiceTypes?.map((option) =>
                                    <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                                }
                            </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <button className={`btn btn-proposal-cancel`} onClick={() => { closeDrawer() }}>Cancel</button>
                <button
                  // type="submit"
                  className={`btn btn-bdr`}
                  onClick={() => filterProposals()}>Filter Proposals
                </button>
              </div>
            </div>
          </Drawer>
        )
      };


    return (
        <>
            <span className="ico-btn">
                <FilterOutlined onClick={(e) => filterOut(e)}>
                    Filter
                </FilterOutlined>
            </span>
            {renderDrawer()}
        </>
    )
}

export default ProposalFilterDrawer;