import React, { useState, useEffect } from "react";
import stax from '../../Assets/images/stax.png';
import { Select, Modal, message } from "antd";
import { isEmpty, map } from 'lodash';
import * as yup from 'yup';
import Loader from '../../Components/Loader';
import { staxPaySetUp, getDataStaxPaySetUp } from '../../Action/Franchise';

const SetupOptionsStaxPay = ({ franchise_data }) => {
    const [reconnect, setReconnect] = useState(false);
    const [state, setState] = useState(
        {
            errors: {},
            loader: false,
            stax_public_apikey: '',
            stax_bearer_apikey:''
        }
    );
    const onTextChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const onValid = async () => {
        let { stax_public_apikey,stax_bearer_apikey } = state;
        let temp_url = await staxPaySetUp(franchise_data?.uuid, {
            "stax_public_apikey": stax_public_apikey,
            "stax_bearer_apikey": stax_bearer_apikey
        });
        if (!isEmpty(temp_url.stax_public_apikey)) {
            setReconnect(true);
            message.success('StaxPay Connected Successfully');
            onFormat();
        }
    }
    const getData = async () => {
        let data = await getDataStaxPaySetUp(franchise_data?.uuid);
        if (!isEmpty(data.stax_public_apikey)) {
            setReconnect(true);;
        }
    }
    const onFormat = () => {
        setState({
            errors: {},
            loader: false,
            stax_public_apikey: '',
            stax_bearer_apikey: ''
        });
    }
    const onSetupClick = (e) => {
        e.preventDefault();
        setTimeout(() => {
            let { stax_public_apikey , stax_bearer_apikey} = state;
            const schema = yup.object().shape({
                stax_public_apikey : yup.string().nullable().required("Web Payments Token is required"),
                stax_bearer_apikey : yup.string().nullable().required("API Key is required")
            })
            const data = { stax_public_apikey , stax_bearer_apikey}
            // check validity
            let IsValid;
            let err_data = {};
            schema.isValid(data)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    onValid();
                }
                else {
                    schema.validate(data, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            })
                            setState({
                                ...state,
                                errors: err_data
                            })
                            console.log("err_data:", err_data)
                        });
                }
            }, 100);
        }, 300);
    }
    useEffect(() => {
        getData();
    }, []);

    let { stax_public_apikey, stax_bearer_apikey,errors, loader } = state;

    return (
        <div className="">
            <div className="card">
                <div className="card-body">
                <img src={stax} alt="" className="stripe_logo" />
                <p> Your API keys are available in the StaxPay Fattmerchant Dashboard. They include randomly generated Web Payments Token and API Key. Sign-in to StaxPay Fattmerchant site and follow these steps Go to <b>{`Apps > Search API Keys > click Launch`}</b> and API keys code will be visible on site. Update/Replace these API keys here.</p>
                <p> If you don’t have an account then you can access link <b><a href="https://fattmerchant.com/home/sign-up/" target="_blank" rel="noopener noreferrer">SIGN UP</a></b> . Once you update required information, fill-up form details and follow the process as Stax site guides you. Once sign up process complete your account will be created successfully. Repeat above steps to update API Keys. Still, if you face any issue contact PoolOps support team.</p>
               <div className="form-group">
                        {!reconnect ? <>
                            <div className="fitem">
                                <label style={{ fontWeight: 'bold' }}>
                                    Web Payments Token  <sup>*</sup>
                                </label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="stax_public_apikey"
                                    onChange={(e) => onTextChange(e)}
                                    value={stax_public_apikey || ''}
                                />
                                {errors['stax_public_apikey'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['stax_public_apikey']}</label> : ''}
                            </div>
                            <div className="fitem">
                                <label style={{ fontWeight: 'bold' }}>
                                    API Key  <sup>*</sup>
                                </label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="stax_bearer_apikey"
                                    onChange={(e) => onTextChange(e)}
                                    value={stax_bearer_apikey || ''}
                                />
                                {errors['stax_bearer_apikey'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['stax_bearer_apikey']}</label> : ''}
                            </div>
                            <br />
                            <div>
                                <button className='btn btn-primary' onClick={(e) => onSetupClick(e)}>Connect</button>
                            </div>
                        </>
                            :
                            <div>
                                <button className='btn btn-warning' >Stax Is Already Connected</button>
                                <button className='btn btn-primary' onClick={() => setReconnect(false)}>Reconnect</button>

                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                loader ?
                    <Loader />
                    : ''}
        </div >
    );
}

export default SetupOptionsStaxPay;