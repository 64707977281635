import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import Axios from 'axios';
import { Select } from "antd";
import Script from "react-load-script";
import { map, isEmpty, get } from "lodash";
import cross_icon from "../Assets/images/cross_icon.svg";
import AllState from "../Assets/data/stateList.json";
import { useSelector } from "react-redux/es/hooks/useSelector";

const { Option } = Select
const AddressField = forwardRef(({ fieldName, handleAddressChange, onAddressChanged, onAddressChange, errors, data, nolabel, jobCreateTask, isDisabled, label = 'Service Address' ,errorsRemittance}, ref) => {
    const address_ref = useRef(null);
    const defaultFieldName = 'google_address';
    const [isAddressManually, setIsAddressManually] = useState(false);
    const [address, setAddress] = useState({ address: '', state: '', city: '', zip_code: '', route: '', street_number: '' });
    const [autoComplete, setAutoComplete] = useState();
    const [addressStr, setAddressStr] = useState("");

    const tasks = useSelector((state) => state.technicianTaskJourney);

    const renderAllState = () => {
        return map(AllState?.AllState, (item, i) => {
            return (<Option value={item.abbreviation} key={i}>{item.name}</Option>)
        })
    }
    const removeEmpty = (obj) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null && v?.trim() !== "")));
    }
    const handlePlaceSelect = () => {
        const address = document?.getElementById(fieldName ? fieldName : defaultFieldName)?.value;
        if(address){    
            Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                params: removeEmpty({
                    latlng: undefined,
                    address,
                    key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
                })
            }).then((res) => {
                const addressObject = res?.data?.results[0];
                const address = addressObject?.address_components;
                let obj = {};
                if (address) {
                    map(addressObject?.address_components, (item) => {
                        if (item?.types?.includes('administrative_area_level_2')) {
                            obj = Object.assign(obj, { 'address': item.short_name })
                        }
                        if (item?.types?.includes('route')) {
                            obj = Object.assign(obj, { 'route': item.long_name })
                        }
                        if (item?.types?.includes('street_number')) {
                            obj = Object.assign(obj, { 'street_number': item.short_name })
                        }
                        if (item?.types?.includes('administrative_area_level_1')) {
                            obj = Object.assign(obj, { 'state': item.short_name })
                        }
                        if (item?.types?.includes('locality')) {
                            obj = Object.assign(obj, { 'city': item.long_name })
                        }
                        if (item?.types?.includes('postal_code')) {
                            obj = Object.assign(obj, { 'zip_code': item.short_name })
                        }
                    });
                    setAddress((obj?.street_number || obj?.route) ? { ...obj, address: `${obj?.street_number || ''} ${obj?.route || ''}` } : obj)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
        else{
            let addr = { address: '', state: '', city: '', zip_code: '', route: '', street_number: '' };
            setAddress(addr);
            handleAddressChange(addr);
        }
    }
    const handleScriptLoad = () => {
        // Declare Options For Autocomplete
        const options = { componentRestrictions: { country: 'us' } };
        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        const newAutoComplete = new google.maps.places.Autocomplete(
            document.getElementById(fieldName ? fieldName : defaultFieldName),
            options,
        );
        // Avoid paying for data that you don't need by restricting the set of
        // place fields that are returned to just the address components and formatted
        // address.
        newAutoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'name']);
        // Fire Event when a suggested name is selected
        newAutoComplete.addListener('place_changed', handlePlaceSelect);
        setAutoComplete(newAutoComplete)
    };
    const handleAddressManually = () => {
        setIsAddressManually(true);
        if (jobCreateTask === "jobCreateTask") {
            setAddress({
                ...address,
                address: ''
            });
        }
    }
    const handleAddressManuallyRemove = () => {
        setIsAddressManually(false);
    }
    const handleChangeAddress = (e) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value,
        });
        if (e.target.name === 'state') {
            onAddressChange(e, address_ref);
        } else {
            onAddressChanged(e, address_ref);
        }
    }    
    useEffect(() => {
        let str = address?.address ? `${address?.address}` : '';
        str += address?.city ? `, ${address?.city}` : '';
        str += address?.state ? `, ${address?.state}` : '';
        str += address?.zip_code ? `, ${address?.zip_code}` : '';
        setAddressStr(str);
        address_ref.current.value = str || '';
        
        if (address.address) {
            handleAddressChange(address);
        }
    }, [address]);
    
    useEffect(() => {
        if (data && data.address) {
            setAddress(data);
            let addressStr = data?.address ? `${data?.address}` : '';
            addressStr += data?.city ? `, ${data?.city}` : '';
            addressStr += data?.state ? `, ${data?.state}` : '';
            addressStr += data?.zip_code ? `, ${data?.zip_code}` : '';
            if (addressStr) {
                address_ref.current.value = addressStr || '';
            }
        }
        else if(data && jobCreateTask === "jobCreateTask"){
            setAddress({ address: data, state: '', city: '', zip_code: '', route: '', street_number: '' });
            address_ref.current.value = data;
        }        
        else {
            setAddress({ address: '', state: '', city: '', zip_code: '', route: '', street_number: '' });
            address_ref.current.value = '';
        }
    }, [data]);

    return (
        <div>
            <div className="google_search_place fitem">
                {nolabel ? null : <label>{label}</label>}
                <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`}
                    onLoad={handleScriptLoad}
                />
                <input
                    autoComplete="off"
                    ref={address_ref}
                    type="text"
                    disabled={isAddressManually || isDisabled}
                    className={'form-control mb-10'}
                    placeholder={tasks?.showTaskFromEdit === true ? tasks?.selectedTaskData?.address?.formatted_address : "Location"}
                    name={fieldName ? fieldName : defaultFieldName}
                    id={fieldName ? fieldName : defaultFieldName}
                    onBlur={handlePlaceSelect}
                />
                <b>or <a onClick={handleAddressManually}><u>enter address manually</u></a></b>
            </div>
            {isAddressManually && !isDisabled ? <div className="manually_address">
                <a onClick={handleAddressManuallyRemove}><img src={cross_icon} alt="" className="float-right mb-10" /></a>
                <br />
                <div className="form-group mb-20">
                    <div className="fitem">
                        <input
                            type="text"
                            placeholder={"Location"}
                            name="address"
                            value={address?.address}
                            id="address"
                            onChange={(e) => (handleChangeAddress({ target: { value: e.target.value, name: e.target.name } }))}
                        />
                    </div>
                    <div className="half-fitem fitem">
                        <div className="half-fitem fitem">
                            <input
                                type="text"
                                placeholder="City"
                                name="city"
                                value={address?.city}
                                onChange={(e) => (handleChangeAddress({ target: { value: e.target.value, name: e.target.name } }))}

                            />
                        </div>
                        <div className="half-fitem fitem">
                            <Select
                                showArrow={true}
                                name="state"
                                placeholder="State"
                                virtual={false}
                                value={address?.state || undefined}
                                onChange={(value) => handleChangeAddress({ target: { name: 'state', value: value } })}
                            >
                                {renderAllState()}
                            </Select>
                        </div>
                        <div className="half-fitem fitem">
                            <input
                                type="text"
                                className='form--control w-100'
                                placeholder="ZIP Code"
                                value={address?.zip_code}
                                name="zip_code"
                                maxLength={5}
                                minLength={5}
                                onChange={(e) => (handleChangeAddress({ target: { value: e.target.value, name: e.target.name } }))}
                            />
                        </div>
                    </div>
                </div>
            </div> : null}
            {(get(errors, 'address.address') || get(errors, 'address.city') || get(errors, 'address.state') || get(errors, 'address.zip_code')) ?
            <label className="new_err_msg"><i className="las la-info-circle" />{'Please enter valid address'}</label>
        : ''}
            {get(errorsRemittance, 'remittance.address') || get(errorsRemittance, 'remittance.city') || get(errorsRemittance, 'remittance.state') || get(errorsRemittance, 'remittance.zip_code') ?
                <label className="new_err_msg"><i className="las la-info-circle" />{'Please enter valid remittance address'}</label>
                : ''}
        </div>
    );

});

export default AddressField;
