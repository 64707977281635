import React, { useState, useRef } from "react";
import { Tag, Empty, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { getAutoSuggest } from "../Action/Search/index";
import { getCustomerDetail, getCommercialCustomer } from "../Action/Accounting";
import "../Assets/css/globalsearch.css";

const GlobalSearch = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchOverlay, setSearchOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  let listInnerRef = null;
  const navigate = useNavigate();

  const debouncedSearch = useRef(
    _.debounce(async (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      if (inputLength === 0) {
        setSuggestions([]);
      } else {
        const reply = await getAutoSuggest({ search: inputValue, limit: 1000 });
        const fetchSuggestions =
          reply?.results?.length > 0 ? reply?.results : [];
        const processSuggestions = fetchSuggestions
          .reduce(
            (previous, suggestion) => {
              switch (true) {
                case suggestion.client_type === "residential" &&
                  !suggestion.lead:
                  previous.map((item) => {
                    if (item.title === "residential")
                      item.suggestions.push(suggestion);
                    return item;
                  });
                  break;
                case suggestion.client_type === "commercial" &&
                  !suggestion.lead:
                  previous.map((item) => {
                    if (item.title === "commercial")
                      item.suggestions.push(suggestion);
                    return item;
                  });
                  break;
                case suggestion.client_type === "residential" &&
                  suggestion.lead:
                  previous.map((item) => {
                    if (item.title === "residential_lead")
                      item.suggestions.push(suggestion);
                    return item;
                  });
                  break;
                case suggestion.client_type === "commercial" && suggestion.lead:
                  previous.map((item) => {
                    if (item.title === "commercial_lead")
                      item.suggestions.push(suggestion);
                    return item;
                  });
                  break;
                default:
                  previous.map((item) => {
                    if (item.title === "unknown")
                      item.suggestions.push(suggestion);
                    return item;
                  });
                  return previous;
              }
              return previous;
            },
            [
              { title: "residential", suggestions: [] },
              { title: "commercial", suggestions: [] },
              { title: "residential_lead", suggestions: [] },
              { title: "commercial_lead", suggestions: [] },
              { title: "unknown", suggestions: [] },
            ]
          )
          .filter((section) => section.suggestions.length > 0);
        setSuggestions(processSuggestions);
      }
      setLoading(false);
    }, 1000)
  ).current;

  const getUsers = async function (search, offset = 0) {
    const reply = await getAutoSuggest({
      search: search,
      limit: 100,
      offset: offset,
    });
    return reply;
  };

  const getEntireUserList = async function (search, offset = 0) {
    const reply = await getUsers(search, offset);
    console.log("Retreiving data from API for page : " + offset);
    if (reply.count > offset + 100) {
      return reply.results.concat(
        await getEntireUserList(search, offset + 100)
      );
    } else {
      return reply.results;
    }
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    setLoading(true);
    debouncedSearch(value);
  };
  const onSuggestionsClearRequested = () => {
    setSearchValue("");
    setSearchOverlay(false);
    setLoading(false);
    setSuggestions([]);
    debouncedSearch.cancel();
    debouncedSearch.flush();
  };
  const getSuggestionValue = (suggestion) =>
    `${suggestion.first_name} ${suggestion.last_name}`;

  const onChange = (event, { newValue }) => {
    setSuggestions([]);
    setSearchValue(newValue);
  };

  const renderSuggestion = (
    { last_name, first_name, client_type, lead },
    { query }
  ) => {
    const name = `${first_name} ${last_name}`;
    const matches = AutosuggestHighlightMatch(name, query, {
      insideWords: true,
      findAllOccurrences: true,
    });
    const parts = AutosuggestHighlightParse(name, matches);

    return (
      <div className="suggestion-option">
        <span className="suggestion-icon">
          <UserOutlined />
        </span>
        <span className="suggestion-name">
          {parts.map((part, index) => {
            const className = part.highlight
              ? "react-autosuggest__suggestion-match"
              : null;

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            );
          })}
        </span>
        <span className="suggestion-type">{assignTags(client_type, lead)}</span>
      </div>
    );
  };

  const handleInputBlur = (event) => {
    onSuggestionsClearRequested();
  };

  const inputProps = {
    placeholder: "Search",
    value: searchValue,
    onChange: onChange,
    onClick: () => setSearchOverlay(true),
    // onBlur: handleInputBlur,
  };
  const renderInputComponent = (inputProps) => (
    <div className="inputContainer">
      <i className=" icon las la-search"></i>
      <input {...inputProps} />
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    let selectedComponent = null;
    if (children) {
      selectedComponent = children;
    } else {
      if (query !== "" && loading === false) {
        selectedComponent = <Empty description="No match found." />;
      }
      if (query !== "" && loading === true) {
        selectedComponent = (
          <div className="selectorSpin">
            <Spin tip="Loading customers..." />
          </div>
        );
      }
    }
    return <div {...containerProps}>{selectedComponent}</div>;
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const openAccountOverview = async (data) => {
    let record = {};
    const customer_id = data?.uuid;
    if (data.client_type === "unknown") {
      setSearchValue("");
      setSearchOverlay(false);
      setLoading(false);
      setSuggestions([]);
      return;
    }    
    switch (true) {
      case data?.client_type === "residential" && !data?.lead:
        record = await getCustomerDetail(customer_id); // get customer detail        
        record.selectedTab = 0;
        navigate(`/residential/customer-detail`, {
          state: {
            id: record?.uuid,
            hear_about_us: record?.hear_about_us,
            customer_satisfaction_score: record?.customer_satisfaction_score,
            record: record,
          },
        });
        break;
      case data?.client_type === "commercial" && !data?.lead:
        record = await getCommercialCustomer(customer_id); // get customer detail
        record.selectedTab = 0;
        navigate(`/commercial/customer-detail`, {
          state: {
            id: record?.uuid,
            hear_about_us: record?.hear_about_us,
            customer_satisfaction_score: record?.customer_satisfaction_score,
            record: record,
          },
        });
        break;
      case data?.client_type === "residential" && data?.lead:
        navigate(`/residential/lead/detail/${customer_id}`, {
          state: { uuid: customer_id, view: "residential" },
        });
        break;
      case data?.client_type && data?.lead:
        navigate(`/commercial/lead-list/lead/detail/${customer_id}`, {
          state: { uuid: customer_id, view: "commercial" },
        });
        break;
      default:
        return;
    }
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    openAccountOverview(suggestion);
  };

  const assignTags = (client_type, lead) => {
    const tags = [];
    switch (client_type) {
      case "residential":
        tags.push(
          <Tag color={"cyan"} key={"residential"}>
            residential
          </Tag>
        );
        break;
      case "commercial":
        tags.push(
          <Tag color={"orange"} key={"commercial"}>
            commercial
          </Tag>
        );
        break;
      default:
        break;
    }
    if (lead) {
      switch (true) {
        case client_type === "residential":
          tags.push(
            <Tag color={"blue"} key={"lead"}>
              lead
            </Tag>
          );
          break;
        case client_type === "commercial":
          tags.push(
            <Tag color={"gold"} key={"lead"}>
              lead
            </Tag>
          );
          break;
        default:
          break;
      }
    }
    if (tags.length === 0) {
      tags.push(
        <Tag color={"default"} key={"unknown"}>
          unknown
        </Tag>
      );
    }
    return tags;
  };

  return (
    <div className={`global-search ${searchOverlay ? "suggestion-selected" : ""}`}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        multiSection={true}
        renderSectionTitle={(section) => (
          <span>{section.title.replace("_", " ")}</span>
        )}
        getSectionSuggestions={(section) =>
          section?.suggestions ? section.suggestions : []
        }
      />
      {searchOverlay ? (
        <div
          className={`overlay`}
          onClick={() => {
            onSuggestionsClearRequested();
          }}
        ></div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GlobalSearch;
