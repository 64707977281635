import React from "react";

const ReportCard = ({
  title,
  description,
  go2Report = () => {},
  id,
  display,
  buttonLabel
}) => {
  return (
    <div
      className="card report-card"
      style={!display ? { display: "none" } : {}}
    >
      <div className="card-title">
        <h5>{title}</h5>
      </div>
      <div className="card-body lh-21">{description}</div>
      <div className="card-footer pt-0">
        <button className="btn btn-warning" onClick={() => go2Report(id)}>
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

export default ReportCard;
