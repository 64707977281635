import axiosInstance from "../../Lib/Axios";
import { message } from "antd";
import { isEmpty } from "lodash";

// getDashboardSummary
export const getAllServices = async (params) => {
  try {
    const response = await axiosInstance.get(`/service/all_services/`, {
      params,
    });
    if (response?.data?.results) {
      response.data.results.map((each) => {
        if (each?.permissionset) {
          let per = each.permissionset;
          let temp = [];
          for (var key in per) {
            if (per[key]) {
              temp.push(key);
            }
          }
          each.permissionset = temp;
          const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
          if (userInfo?.user?.is_lead_technician) {
            let per = userInfo?.user?.technician_permissions;
            let temp_per = [];
            for (var key in per) {
              if (per[key]) {
                temp_per.push(key);
              }
            }
            each.permissionset = temp_per;
          }
          return {
            ...each,
          };
        }
      });
    }
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// deleteService
export const deleteService = async (work_order_uuid) => {
  try {
    const response = await axiosInstance.delete(`/service/${work_order_uuid}`);
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      //message.error("Fail to request data");
      throw error;
    }
  }
};

//startJobTime
export const startJobTime = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/service/startjobtime/`,
      body,
      {}
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      throw error;
    }
  }
};

// rescheduleService
export const rescheduleService = async (body) => {
  try {
    const response = await axiosInstance.post(`/service/reschedule/`, body, {});
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      //message.error("Fail to request data");
      throw error;
    }
  }
};

// rescheduleService
export const rescheduleRecurringService = async (body) => {
  try {
    const response = await axiosInstance.post(`/service/reassign/`, body, {});
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      //message.error("Fail to request data");
      throw error;
    }
  }
};

export const getAllServicesTechGlance = async (params) => {
  try {
    const response = await axiosInstance.get(`service/tech_glance_service/`, {
      params,
    });
    if (response?.data?.results) {
      response.data.results = response.data.results.filter(
        (each) => !isEmpty(each)
      );
      response.data.results.map((each) => {
        if (each?.permissionset) {
          let per = each.permissionset;
          let temp = [];
          for (var key in per) {
            if (per[key]) {
              temp.push(key);
            }
          }
          each.permissionset = temp;
          each.technician = each.technician ? each.technician : "Not assigned";
          return {
            ...each,
          };
        }
      });
    }
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

//Route Optimization
export const getRouteOptimization = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/service/optimize_route/?technician_uuid=${params.user_uuid}` +
        `&date=${params.date}&start_address=${params.start_location}&end_address=${params.end_location}`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      return error;
    }
  }
};

/**
 * getTechNotes List of tech notes based on customerID
 * @param start_date start date YYYY-MM-DD
 * @param end_date end date YYYY-MM-DD
 * @param customer_uuid customer uuid string
 * @param limit limit integer
 * @param offset offset integer
 * @returns Array of tech notes
 */
export const getTechNotes = async (params) => {
  try {
    const response = await axiosInstance.get(`/service/tech_notes/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};



export const getAllTasks = async (params) => {
  try {
    const response = await axiosInstance.get(`/tasks/task_list/`, {
      params,
    });
    if (response?.data?.results) {
      response.data.results.map((each) => {
        if (each?.permissionset) {
          let per = each.permissionset;
          let temp = [];
          for (var key in per) {
            if (per[key]) {
              temp.push(key);
            }
          }
          each.permissionset = temp;
          const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
          if (userInfo?.user?.is_lead_technician) {
            let per = userInfo?.user?.technician_permissions;
            let temp_per = [];
            for (var key in per) {
              if (per[key]) {
                temp_per.push(key);
              }
            }
            each.permissionset = temp_per;
          }
          return {
            ...each,
          };
        }
      });
    }
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

export const updateTaskInfo = (params) => {
  return axiosInstance        
    .patch(`/tasks/task_update/?task_uuid=${params.task_uuid}`, {task_status: params.task_status})     
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
};

export const reassignTaskInfo = (params) => {
  return axiosInstance        
    .patch(`/tasks/task_update/?task_uuid=${params.task_uuid}`, {reassign_to: params.reassign_to, reassign_date : params.reassign_date, reassign_time : params.reassign_time , is_reassign_future_tasks : params.is_reassign_future_tasks})     
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
};