import React from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import PDFIcon from "../../../Assets/images/pdf-icon.png";
import CSVIcon from "../../../Assets/images/csv-icon.png";
import { useReport } from "../../Hooks/Report.Hook";
import ReportsData from "../../Common/ReportList.Data";
import { getPDFReport, getCSVReport } from "../../Common/getObjectReport";
import { withJSONSameOrder } from "../../Common/getOrderingObject";

const AgingSummaryTopMenu = () => {
  const { id } = useParams();
  const {
    franchise,
    superSearch,
    sorter,
    serviceDatePeriod,
    serviceSecondDatePeriod,
    agingStatus,
  } = useReport();
  const report_type = "customer_aging_summary_report";

  const getFilters = () => {
    return {
      field_office__uuid:
        franchise.value === "all" ? undefined : franchise.value,
      ...withJSONSameOrder(sorter),
      start_invoice_date: !_.isEmpty(serviceDatePeriod)
        ? serviceDatePeriod.start_date
        : undefined,
      end_invoice_date: !_.isEmpty(serviceDatePeriod)
        ? serviceDatePeriod.end_date
        : undefined,
      start_due_date: !_.isEmpty(serviceSecondDatePeriod)
        ? serviceSecondDatePeriod.start_date
        : undefined,
      end_due_date: !_.isEmpty(serviceSecondDatePeriod)
        ? serviceSecondDatePeriod.end_date
        : undefined,
      status: agingStatus.value === "all" ? undefined : agingStatus.value,
      q: superSearch !== "" ? superSearch : undefined,
    };
  };

  return (
    <div className="filter_wrapper acc-wrap p-0 mb-20">
      {/*<h2 className="m-0 text-nowrap pr-10">
        {ReportsData.find((item) => item.id === id)?.title}
      </h2>*/}

      <div class="report-topmenu_title">
          <h2>{ReportsData.find((item) => item.id === id)?.title}</h2>
      </div>
      <div className="form-group flex ai-center w-100 flex-end fltr-txt">
        <Tooltip title="Download CSV" color={"#005d89"}>
          <button
            className="btn btn-warning-outline"
            onClick={() => getCSVReport({ report_type, filters: getFilters() })}
          >
            <img
              src={CSVIcon}
              alt={"Download CSV"}
              style={{ maxWidth: "25px" }}
            />
          </button>
        </Tooltip>
        <Tooltip title="Download PDF" color={"#005d89"}>
          <button
            className="btn btn-warning"
            onClick={() => getPDFReport({ report_type, filters: getFilters() })}
          >
            <img
              src={PDFIcon}
              alt={"Download PDF"}
              style={{ maxWidth: "21px" }}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default AgingSummaryTopMenu;
