import React from "react";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import { useReport } from "../../Hooks/Report.Hook";
import FranchiseSelector from "../Filters/Franchise.Selector";
import AgingStatusSelector from "../Filters/AgingStatus.Selector";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';

const AgingSummarySidebar = () => {
  const {
    franchise,
    agingStatus,
    setServiceDatePeriod,
    setServiceSecondDatePeriod,
    setFranchise,
    setAgingStatus,
  } = useReport();

  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
  };
  const handleCustomDateChange = (e) => {
    setServiceDatePeriod({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
  };
  const handleSecondCustomDateChange = (e) => {
    setServiceSecondDatePeriod({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const handleAgingStatusChange = (value, { label }) => {
    setAgingStatus({ value, label });
  };

  return (
    <>
      <div className="fitem">
        <label>
          <b>Franchise</b>
        </label>
        <FranchiseSelector
          handleChange={handleFranchiseChange}
          defaultValue={franchise}
        />
      </div>
      <div className="fitem">
        <label>
          <b>
            Invoice Date
          </b>
        </label>
        <DatePicker.RangePicker
          disabledDate={disabledDate}
          onChange={handleCustomDateChange}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Due Date</b>
        </label>
        <DatePicker.RangePicker
          disabledDate={disabledDate}
          onChange={handleSecondCustomDateChange}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Status</b>
        </label>
        <AgingStatusSelector
          allValue={true}
          defaultValue={agingStatus}
          handleChange={handleAgingStatusChange}
        />
      </div>
    </>
  );
};

export default AgingSummarySidebar;
