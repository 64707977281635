import { updatePoolSizeRequest } from "../Utils/infoRequests";

export function calculateAllChemicals(chem, client, dosages, poolSize) {
  const freeChlorine = parseFloat(chem.freeChlorine ?? 0).toFixed(1);
  const pH = parseFloat(chem.pH ?? 0, 10).toFixed(1);

  const totalAlkalinity = parseFloat(chem.totalAlkalinity ?? 0, 10).toFixed(1);
  const calciumHardness = parseFloat(chem.calciumHardness ?? 0, 10).toFixed(1);

  const cyanuricAcid = parseFloat(chem.cyanuricAcid ?? 0, 10).toFixed(1);

  poolSize = parseFloat(poolSize);
  //"freechlorine":
  var CalciumHypochlorite = 0;
  var LiquidChlorine = 0;

  if (3.0 > freeChlorine) {
    CalciumHypochlorite = poolSize / 10000;
    LiquidChlorine = poolSize / 10000;
  }

  //"calciumhardness":      
  var CalciumChloride = 0;
  if (200 > +calciumHardness) {
    CalciumChloride =
      1.2 * ((300 - +calciumHardness) / 10) * (poolSize / 10000);
    CalciumChloride = Math.round(CalciumChloride * 100) / 100;
  }else
    CalciumChloride = 0;

  //"cyanuricacid":
  var CyanuricAcid = 0;

  if (30 > cyanuricAcid) {
    CyanuricAcid = 13 * ((40 - cyanuricAcid) / 10) * (poolSize / 10000);
    CyanuricAcid = CyanuricAcid / 16;
    CyanuricAcid = Math.round(CyanuricAcid * 100) / 100;
  }

  //"totalalkalinity":
  //"ph":
  var SodaAsh = 0;
  var MuriaticAcid = 0;
  var SodiumBicarbonate = 0;      
  if (80 > totalAlkalinity) {
    SodiumBicarbonate =
      1.4 * ((100 - totalAlkalinity) / 10) * (poolSize / 10000);
    SodiumBicarbonate = Math.round(SodiumBicarbonate * 100) / 100;
  } else if (120 < totalAlkalinity) {
    MuriaticAcid = 21 * ((totalAlkalinity - 100) / 10) * (poolSize / 10000);
    MuriaticAcid = MuriaticAcid / 128;
    MuriaticAcid = Math.round(MuriaticAcid * 100) / 100;
  }

  if (7.2 > pH) {
    SodaAsh = 6 * ((7.4 - pH) / 0.2) * (poolSize / 10000);
    SodaAsh = SodaAsh / 16;
    SodaAsh = Math.round(SodaAsh * 100) / 100;
  } else if (7.8 < pH) {
    var mAcid = 0;
    mAcid = 12 * ((pH - 7.4) / 0.2) * (poolSize / 10000);

    MuriaticAcid += mAcid / 128;

    MuriaticAcid = Math.round(MuriaticAcid * 100) / 100;
  }


  var LSIData = calculateLSI(chem, client);

  dosages.LiquidChlorine =
    LiquidChlorine !== undefined
      ? LiquidChlorine
      : dosages.LiquidChlorine;
  dosages.CalciumHypochlorite =
    CalciumHypochlorite !== undefined 
      ? CalciumHypochlorite
      : dosages.CalciumHypochlorite;
  dosages.CalciumChloride =
    CalciumChloride !== undefined 
      ? CalciumChloride
      : dosages.CalciumChloride;
  dosages.CyanuricAcid =
    CyanuricAcid !== undefined
      ? CyanuricAcid
      : dosages.CyanuricAcid;
  dosages.SodiumBicarbonate =
    SodiumBicarbonate !== undefined 
      ? SodiumBicarbonate
      : dosages.SodiumBicarbonate;
  dosages.MuriaticAcid =
    MuriaticAcid !== undefined
      ? MuriaticAcid
      : dosages.MuriaticAcid;
  dosages.SodaAsh =
    SodaAsh !== undefined ? SodaAsh 
    : dosages.SodaAsh;
  dosages.LSI =
    LSIData.LSI !== undefined ? LSIData.LSI : dosages.lsi;
  dosages.LSIRecommendation =
    LSIData.LSIRecommendation !== ""
      ? LSIData.LSIRecommendation
      : dosages.LSIRecommendation;

  return dosages;
}

export function calculateChemicals(chem, client, reading, dosages, poolSize) {
  const freeChlorine = parseFloat(chem.freeChlorine ?? 0).toFixed(1);
  const pH = parseFloat(chem.pH ?? 0, 10).toFixed(1);

  const totalAlkalinity = parseFloat(chem.totalAlkalinity ?? 0, 10).toFixed(1);
  const calciumHardness = parseFloat(chem.calciumHardness ?? 0, 10).toFixed(1);

  const cyanuricAcid = parseFloat(chem.cyanuricAcid ?? 0, 10).toFixed(1);

  poolSize = parseFloat(poolSize);
  switch (reading) {
    case "freechlorine":
      var CalciumHypochlorite = 0;
      var LiquidChlorine = 0;

      if (3.0 > freeChlorine) {
        CalciumHypochlorite = poolSize / 10000;
        LiquidChlorine = poolSize / 10000;
      }
      break;
    case "calciumhardness":      
      var CalciumChloride = 0;
      if (200 > +calciumHardness) {
        CalciumChloride =
          1.2 * ((300 - +calciumHardness) / 10) * (poolSize / 10000);
        CalciumChloride = Math.round(CalciumChloride * 100) / 100;
      }else
        CalciumChloride = 0;
      break;
    case "cyanuricacid":
      var CyanuricAcid = 0;

      if (30 > cyanuricAcid) {
        CyanuricAcid = 13 * ((40 - cyanuricAcid) / 10) * (poolSize / 10000);
        CyanuricAcid = CyanuricAcid / 16;
        CyanuricAcid = Math.round(CyanuricAcid * 100) / 100;
      }
      break;
    case "totalalkalinity":
    case "ph":
      var SodaAsh = 0;
      var MuriaticAcid = 0;
      var SodiumBicarbonate = 0;      
      if (80 > totalAlkalinity) {
        SodiumBicarbonate =
          1.4 * ((100 - totalAlkalinity) / 10) * (poolSize / 10000);
        SodiumBicarbonate = Math.round(SodiumBicarbonate * 100) / 100;
      } else if (120 < totalAlkalinity) {
        MuriaticAcid = 21 * ((totalAlkalinity - 100) / 10) * (poolSize / 10000);
        MuriaticAcid = MuriaticAcid / 128;
        MuriaticAcid = Math.round(MuriaticAcid * 100) / 100;
      }

      if (7.2 > pH) {
        SodaAsh = 6 * ((7.4 - pH) / 0.2) * (poolSize / 10000);
        SodaAsh = SodaAsh / 16;
        SodaAsh = Math.round(SodaAsh * 100) / 100;
      } else if (7.8 < pH) {
        var mAcid = 0;
        mAcid = 12 * ((pH - 7.4) / 0.2) * (poolSize / 10000);

        MuriaticAcid += mAcid / 128;

        MuriaticAcid = Math.round(MuriaticAcid * 100) / 100;
      }
      break;
    default:
      break;
  }

  var LSIData = calculateLSI(chem, client);

  dosages.LiquidChlorine =
    LiquidChlorine !== undefined && LiquidChlorine !== 0
      ? LiquidChlorine
      : reading === "freechlorine" ? LiquidChlorine : dosages.LiquidChlorine;
  dosages.CalciumHypochlorite =
    CalciumHypochlorite !== undefined && CalciumHypochlorite !== 0
      ? CalciumHypochlorite
      : reading === "freechlorine" ? CalciumHypochlorite : dosages.CalciumHypochlorite;
  dosages.CalciumChloride =
    CalciumChloride !== undefined && CalciumChloride !== 0
      ? CalciumChloride
      : reading === "calciumhardness" ? CalciumChloride : dosages.CalciumChloride;
  dosages.CyanuricAcid =
    CyanuricAcid !== undefined && CyanuricAcid !== 0
      ? CyanuricAcid
      : reading === "cyanuricacid" ? CyanuricAcid : dosages.CyanuricAcid;
  dosages.SodiumBicarbonate =
    SodiumBicarbonate !== undefined && SodiumBicarbonate !== 0
      ? SodiumBicarbonate
      : reading === "totalalkalinity" || reading === "ph" ? SodiumBicarbonate : dosages.SodiumBicarbonate;
  dosages.MuriaticAcid =
    MuriaticAcid !== undefined && MuriaticAcid !== 0
      ? MuriaticAcid
      : reading === "totalalkalinity" || reading === "ph" ? MuriaticAcid : dosages.MuriaticAcid;
  dosages.SodaAsh =
    SodaAsh !== undefined && SodaAsh !== 0 ? SodaAsh 
    : reading === "totalalkalinity" || reading === "ph" ? SodaAsh : dosages.SodaAsh;
  dosages.LSI =
    LSIData.LSI !== undefined && LSIData.LSI !== 0 ? LSIData.LSI : dosages.lsi;
  dosages.LSIRecommendation =
    LSIData.LSIRecommendation !== ""
      ? LSIData.LSIRecommendation
      : dosages.LSIRecommendation;

  return dosages;
}

export function calculateLSI(chem, client) {
  var tempFact = 0;
  var hardFact = 0;
  var alkFact = 0;
  var phFact = 0;
  var tdsFact = 12.1; //assumed <1000 ppm
  var lsi = 0;

  if (
    null !== chem.poolTemp &&
    null !== chem.calciumHardness &&
    null !== chem.totalAlkalinity &&
    null !== chem.pH
  ) {
    tempFact = getTempFactor(chem.poolTemp);
    hardFact = getHardFactor(chem.calciumHardness);
    alkFact = getAlkFactor(chem.totalAlkalinity);
    phFact = getpHFactor(chem.pH);
    tdsFact = getTDSFactor(chem.tds);

    lsi = (parseFloat(chem.pH) ?? 0) + tempFact + hardFact + (alkFact - phFact) - tdsFact;
    lsi = Math.round(lsi * 1000) / 1000;

    var LSIRecommendation = getRecommendation(lsi);
  } else {
    lsi = "Not Calculated";
  }

  return { LSI: lsi, LSIRecommendation: LSIRecommendation };
}

function inRange(x, min, max) {
  return min <= x && x <= max;
}

function getTempFactor(poolTemp) {
  var tempFact = 0;

  if (inRange(poolTemp, 0, 32)) {
    tempFact = 0;
  } else if (inRange(poolTemp, 33, 37)) {
    tempFact = 0.1;
  } else if (inRange(poolTemp, 38, 46)) {
    tempFact = 0.2;
  } else if (inRange(poolTemp, 47, 53)) {
    tempFact = 0.3;
  } else if (inRange(poolTemp, 54, 60)) {
    tempFact = 0.4;
  } else if (inRange(poolTemp, 61, 66)) {
    tempFact = 0.5;
  } else if (inRange(poolTemp, 67, 76)) {
    tempFact = 0.6;
  } else if (inRange(poolTemp, 77, 84)) {
    tempFact = 0.7;
  } else if (inRange(poolTemp, 85, 94)) {
    tempFact = 0.8;
  } else if (inRange(poolTemp, 95, 145)) {
    tempFact = 0.9;
  }

  return tempFact;
}

function getHardFactor(factor) {
  var retFactor = 0;

  if (inRange(factor, 0, 5)) {
    retFactor = 0.3;
  } else if (inRange(factor, 6, 25)) {
    retFactor = 1.0;
  } else if (inRange(factor, 26, 50)) {
    retFactor = 1.3;
  } else if (inRange(factor, 51, 75)) {
    retFactor = 1.5;
  } else if (inRange(factor, 76, 100)) {
    retFactor = 1.6;
  } else if (inRange(factor, 101, 150)) {
    retFactor = 1.8;
  } else if (inRange(factor, 151, 200)) {
    retFactor = 1.9;
  } else if (inRange(factor, 201, 300)) {
    retFactor = 2.1;
  } else if (inRange(factor, 301, 400)) {
    retFactor = 2.2;
  } else if (inRange(factor, 401, 1000)) {
    retFactor = 2.5;
  }

  return retFactor;
}

function getAlkFactor(factor) {
  var retFactor = 0;

  if (inRange(factor, 0, 5)) {
    retFactor = 0.7;
  } else if (inRange(factor, 6, 25)) {
    retFactor = 1.4;
  } else if (inRange(factor, 26, 50)) {
    retFactor = 1.7;
  } else if (inRange(factor, 51, 75)) {
    retFactor = 1.9;
  } else if (inRange(factor, 76, 100)) {
    retFactor = 2.0;
  } else if (inRange(factor, 101, 150)) {
    retFactor = 2.2;
  } else if (inRange(factor, 151, 200)) {
    retFactor = 2.3;
  } else if (inRange(factor, 201, 300)) {
    retFactor = 2.5;
  } else if (inRange(factor, 301, 500)) {
    retFactor = 2.6;
  } else if (inRange(factor, 501, 800)) {
    retFactor = 2.9;
  }

  return retFactor;
}

function getpHFactor(factor) {
  var retFactor = 0;

  if (inRange(factor, 0, 7.0)) {
    retFactor = 0.23;
  } else if (inRange(factor, 7.01, 7.2)) {
    retFactor = 0.27;
  } else if (inRange(factor, 7.21, 7.4)) {
    retFactor = 0.31;
  } else if (inRange(factor, 7.41, 7.6)) {
    retFactor = 0.33;
  } else if (inRange(factor, 7.61, 7.8)) {
    retFactor = 0.35;
  } else if (inRange(factor, 7.81, 14.0)) {
    retFactor = 0.36;
  }

  return retFactor;
}

function getTDSFactor(factor) {
  var retFactor = 12.1;
  factor = parseInt(factor);

  if (inRange(factor, 0, 999)) {
    retFactor = 12.1;
  } else if (inRange(factor, 1000, 1999)) {
    retFactor = 12.19;
  } else if (inRange(factor, 2000, 2999)) {
    retFactor = 12.29;
  } else if (inRange(factor, 3000, 3999)) {
    retFactor = 12.35;
  } else if (inRange(factor, 4000, 4999)) {
    retFactor = 12.41;
  } else if (inRange(factor, 5000, 100000)) {
    retFactor = 12.44;
  }

  return retFactor;
}

function getRecommendation(factor) {
  var retFactor = "";

  if (inRange(factor, -10.0, -5.0)) {
    retFactor = "Severe Corrosion: Treatment Recommended";
  } else if (inRange(factor, -4.999, -3.0)) {
    retFactor = "Moderate Corrosion: Treatment Recommended";
  } else if (inRange(factor, -2.999, -2.0)) {
    retFactor = "Moderate Corrosion: Treatment May Be Needed";
  } else if (inRange(factor, -1.999, -1.0)) {
    retFactor = "Mild Corrosion: Treatment May Be Needed";
  } else if (inRange(factor, -0.999, 0.999)) {
    retFactor = "Near balanced";
  } else if (inRange(factor, 1.0, 1.999)) {
    retFactor = "Mild Scale Coating: Treatment May Be Needed";
  } else if (inRange(factor, 2.0, 2.999)) {
    retFactor = "Mild to Moderate Scale Coating: Treatment May Be Needed";
  } else if (inRange(factor, 3.0, 3.999)) {
    retFactor = "Moderate Scale Forming: Treatment Recommended";
  } else if (inRange(factor, 4.0, 24.999)) {
    retFactor = "Severe Scale Coating: Treatment Recommended";
  }

  return retFactor;
}

