export const getFranchiseSelected = () => {
    return JSON.parse(localStorage.getItem("selectedFranchise"));
}

export const setFranchiseSelected = (id, name) => {
    localStorage.setItem("selectedFranchise", JSON.stringify({
        uuid: id,
        name: name
    }));
    console.log('getFranchiseSelected--->> ', getFranchiseSelected());
}

