import * as yup from "yup";
import { map } from "lodash";


export const validateBaseJob = (techJourneyState) => {  
  const {
    selectedServiceType: { name: serviceType },
    workSubTypeArray,
  } = techJourneyState;
  console.log("Validation----", serviceType);
  const start = techJourneyState?.frequency?.value?.start;
  const workSubType = techJourneyState?.selectedWorkSubType?.name; 
  const poolType = techJourneyState?.selectedPoolType?.name;
  const workType = techJourneyState?.selectedWorkType?.name;
  const paymentMethod = techJourneyState?.paymentMethod?.value;

  const BaseJobSchema = yup.object().shape({
    serviceType: yup
      .string()
      .required({ serviceType: "Service Type is required." }),
    workType: serviceType === "maintenance" ? yup
      .string()
      .required("Rate type is required."): null,
    start: serviceType === "maintenance" ? yup
      .string()
      .required("Frequency is required."): null,
    poolType:
      serviceType !== "renovation" && serviceType !== "construction"
        ? yup.string().required("Pool Type is Required.")
        : null,
    workSubType:
      serviceType === "service" && workSubTypeArray?.length > 0
        ? yup.string().required("Work Sub Type is required." )
        : null,
    paymentMethod: yup.string()
      .required("Payment Method is required.")
  });

  return BaseJobSchema.validate(
    {
      serviceType,
      start,
      poolType,
      workSubTypeArray,
      workSubType,
      workType,
      paymentMethod
    },
    { abortEarly: false }
  )
    .then(() => Promise.resolve({ status: "success" }))
    .catch((error) => {
      let err_data = {};
      map(error?.inner, (item) => { err_data[item.path] = item.message })
      console.log(err_data);
      return Promise.reject({ status: "fail", error });
    });
};
