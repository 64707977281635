import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { map, isEmpty } from "lodash";
import moment from "moment-timezone";
import { Select, TimePicker, Row, Col } from "antd";
import * as yup from "yup";
import AllState from "../../Assets/data/stateList.json";
import Loader from "../../Components/Loader";
import CustomFrequency from "../Common/CustomFrequency";
import AddressField from "../../Components/AddressField";
import AddDurationField from "../../Calendar/Components/Modals/SubComponents/AddDurationField";
import AddReferenceField from "../../Calendar/Components/Modals/SubComponents/AddReferenceField";

const { Option } = Select;

const ServiceOrderInfo = forwardRef(
  (
    {
      serviceOrderData,
      serviceTypeData,
      setServiceInfoData,
      setPriceDetailData,
      leadData,
    },
    ref
  ) => {
    const [loader, setLoader] = useState(false);
    const [serviceType, setServiceType] = useState("");
    const [serviceTypeTitle, setServiceTypeTitle] = useState("");
    const [frequency, setFrequency] = useState({});
    const [maintenanceCategory, setMaintenanceCategory] = useState("");
    const [poolType, setPoolType] = useState("");
    const [poolSize, setPoolSize] = useState(0);
    const [errors, setErrors] = useState({});
    const [isBasicDetail, setIsBasicDetail] = useState(false);
    const [otherPoolType, setOtherPoolType] = useState("");
    const [poolName, setPoolName] = useState("");
    const [frequencyList, setFrequencyList] = useState([]);
    const [maintenanceCategories, setMaintenanceCategories] = useState([]);
    const [services, setServices] = useState([]);
    const [service, setService] = useState("");
    const [workSubTypeList, setWorkSubTypeList] = useState([]);
    const [workSubType, setWorkSubType] = useState("");
    const [serviceTypeName, setServiceTypeName] = useState("");
    const [customFrequency, setcustomFrequency] = useState(false);
    const [worktypesList, setworktypesList] = useState([]);
    const [note, setNote] = useState("");
    const [propertyAddress, setPropertyAddress] = useState({
      address: "",
      city: "",
      state: "",
      zip_code: "",
      address2: "",
    });
    const [isRenovationOrConstruction, setIsRenovationOrConstruction] =
      useState(false);
    const [time, setTime] = useState(moment("8:00", "HH:mm"));
    const [isRecurring, setisRecurring] = useState(true);
    serviceTypeData = serviceTypeData?.results[0];

    // render all state
    const renderAllState = () => {
      return map(AllState?.AllState, (item, i) => {
        return (
          <Option value={item.abbreviation} key={i}>
            {item.name}
          </Option>
        );
      });
    };
    const handleAddressChange = (value) => {
      setPropertyAddress(value);
    };
    // on address change
    const onAddressChange = (e) => {
      Object.assign(propertyAddress, { [e.target.name]: e.target.value });
      setPropertyAddress(propertyAddress);
    };
    const onAddressChanged = (e) => {
      setPropertyAddress((address) => ({
        ...address,
        [e.target.name]: e.target.value,
      }));
    };
    useImperativeHandle(ref, () => ({
      validate() {
        const schema = yup.object().shape({
          frequency:
            serviceTypeName === "maintenance" || serviceTypeName === "service"
              ? yup
                  .object()
                  .shape({
                    start: yup.string().required("Frequency Is Required."),
                  })
                  .nullable(false)
              : null,
          maintenanceCategory:
            serviceTypeName === "maintenance"
              ? yup.string().required("Rate Type Is Required.")
              : null,
          serviceType: yup.string().required("Service Type Is Required."),
          poolType:
            serviceTypeName !== "renovation" &&
            serviceTypeName !== "construction"
              ? yup.string().required("Pool Type Is Required.")
              : null,
          poolName:
            serviceTypeName !== "renovation" &&
            serviceTypeName !== "construction"
              ? yup.string().required("Pool Name Is Required.")
              : null,
          otherPoolType:
            poolType === "-1" &&
            (otherPoolType === undefined || otherPoolType === "")
              ? yup.string().required("Please enter the Other Pool Type.")
              : null,
          service:
            serviceTypeName === "service"
              ? yup.string().required("Service Is Required.")
              : null,
          workSubType:
            serviceTypeName === "service" && workSubTypeList.length > 0
              ? yup.string().required("Work Sub Type Is Required.")
              : null,
        });

        const data = {
          frequency,
          maintenanceCategory,
          serviceType,
          poolType,
          poolSize,
          poolName,
          service,
          workSubType,
        };
        return schema
          .validate(data, { abortEarly: false })
          .then(() => {
            setIsBasicDetail(true);
            setServiceInfoData({ value: data, key: true, name: "baseJobReq" });
            setErrors({});
            return Promise.resolve("success");
          })
          .catch((error) => {
            let err_data = {};
            map(error?.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setIsBasicDetail(false);
            setErrors(err_data);
            console.log(err_data);
            return Promise.reject("fail");
          });
      },
    }));
    const onTextChange = (e) => {
      if (e.target.name === "serviceType") {
        setServiceType(e.target.value);
        let obj = serviceTypeData?.service_matrix?.servicetypes.filter(
          (item) => item.uuid === e.target.value
        )[0];
        if (obj.name.toLowerCase() === "service") {
          setServices(obj?.worktypes);
          setFrequencyList([]);
          setWorkSubTypeList([]);
          setWorkSubType("");
          setFrequency("");
          setServiceTypeName("service");
          setcustomFrequency(true);
          setPriceDetailData({ frequency: "", price: 0, key: "" });
          setServiceInfoData({ value: true, key: true, name: "is_service" });
          setIsRenovationOrConstruction(false);
          setisRecurring(false)
        }
        if (obj.name.toLowerCase() === "maintenance") {
          setcustomFrequency(true);
          setServices([]);
          setWorkSubTypeList([]);
          setService("");
          setWorkSubType("");
          setFrequency("");
          setServiceTypeName("maintenance");
          setMaintenanceCategories(obj?.worktypes);
          setPriceDetailData({ frequency: "", price: 0, key: "" });
          setServiceInfoData({ value: false, key: false, name: "is_service" });
          setIsRenovationOrConstruction(false);
          setisRecurring(true)
        }
        if (
          obj.name.toLowerCase() === "renovation" ||
          obj.name.toLowerCase() === "construction"
        ) {
          setcustomFrequency(false);
          setServices([]);
          setWorkSubTypeList([]);
          setService("");
          setWorkSubType("");
          setFrequency("");
          obj.name.toLowerCase() === "renovation"
            ? setServiceTypeName("renovation")
            : setServiceTypeName("construction");
          setIsRenovationOrConstruction(true);
          setPriceDetailData({ frequency: "", price: 0, key: "" });
        }

        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: "service_type_uuid",
        });
        let _worktypesList =
          serviceTypeData?.service_matrix.servicetypes.filter((element) => {
            return element.name === e.target.key;
          });
        setworktypesList(_worktypesList);
      }
      if (e.target.name === "frequency") {
        let obj = e.target.value;
        obj.start = obj.start && moment(obj.start).format("YYYY-MM-DD");
        obj.end = obj.end && moment(obj.end).format("YYYY-MM-DD");
        setFrequency(e.target.value);
        setServiceInfoData({
          value: obj,
          key: e.target.key,
          name: "frequency_settings",
        });
        if(serviceTypeName.toLocaleLowerCase() !== "service"){
          setPriceDetailData({
            frequency: obj,
            price: e.target?.price,
            key: e.target?.key,
          });
        }
      }
      if (e.target.name === "service") {
        setService(e.target.value);
        setServiceTypeTitle(
          e.target.key.charAt(0).toUpperCase() + e.target.key.slice(1)
        );
        setFrequency("");
        setWorkSubType("");
        let obj = services?.filter((item) => item.uuid === e.target.value)[0];
        setWorkSubTypeList(obj?.worksubtypes);

        if (obj?.worksubtypes.length === 0) {
          // setServiceInfoData({ value: '', key: '', name: e.target.name });
          setPriceDetailData({
            frequency: e.target?.value,
            price: e.target?.price,
            key: e.target?.key,
          });
          
          if(obj?.is_recurring !== undefined && obj?.is_recurring){
            setisRecurring(true)
          }else{
            setisRecurring(false)
          }
        } else {
          // setServiceInfoData({ value: e.target.value, key: e.target.key, name: e.target.name });
          setPriceDetailData({ frequency: "", price: 0, key: "" });
          setisRecurring(false)
        }
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
      }
      if (e.target.name === "worksubtype") {
        setWorkSubType(e.target.value);        
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        setPriceDetailData({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        
        const obj = services?.filter((item) => item.uuid === service)[0];
        const subWorkType = obj.worksubtypes.find(i => i.uuid === e.target?.value)
        if(subWorkType?.is_recurring !== undefined && subWorkType?.is_recurring){
          setisRecurring(true)
        }else{
          setisRecurring(false)
        }
      }
      if (e.target.name === "poolType") {
        setPoolType(e.target.value);
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: "pool_type_uuid",
        });
        setOtherPoolType("");
        // setServiceInfoData({ value: null, key: 'otherPoolType', name: 'otherPoolType' });
      }
      if (e.target.name === "poolSize") {
        const result = e.target.value.replace(/\D/g, "");
        setPoolSize(result);
        setServiceInfoData({ value: result, key: result, name: "pool_size" });
      }
      if (e.target.name === "poolName") {
        setPoolName(e.target.value);
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: "pool_name",
        });
      }
      if (e.target.name === "maintenanceCategory") {
        setMaintenanceCategory(e.target.value);
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
      }
      if (e.target.name === "notes") {
        setNote(e.target.value);
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
      }
      if (e.target.name === "time") {
        setTime(e.target.value);
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
      }
    };
    useEffect(() => {
      let obj = serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) => item.name.toLowerCase() === "maintenance"
      )[0];
      setcustomFrequency(true);
      setMaintenanceCategories(obj?.worktypes);
      setServiceInfoData({
        value: obj.uuid,
        key: obj.name,
        name: "service_type_uuid",
      });      

      setworktypesList(serviceTypeData?.service_matrix.servicetypes[0]);
      setPropertyAddress(leadData.address.length > 1 ? leadData.address[1]: leadData.address[0]);
      setIsRenovationOrConstruction(false);
      onTextChange({
        target: {
          name: "time",
          value: moment("8:00", "HH:mm"),
          key: "time",
        },
      });
      loadSavedData();
    }, []);
    useEffect(() => {
      if (serviceType) {
        setServiceInfoData({
          value: serviceType,
          key: serviceTypeName,
          name: "service_type_uuid",
        });
      }
    }, [serviceType]);
    useEffect(() => {
      if (propertyAddress.address) {
        setServiceInfoData({
          value: propertyAddress,
          key: "",
          name: "address",
        });
      }
    }, [propertyAddress]);

    const loadSavedData = async () => {
      if (serviceOrderData.length > 0) {
        let obj = serviceTypeData?.service_matrix?.servicetypes.filter(
          (item) =>
            item.name.toLowerCase() ===
            serviceOrderData[0]?.service_type.toLowerCase()
        )[0];
        setServiceType(obj?.uuid);
        setServiceTypeName(obj?.name);
        setIsRenovationOrConstruction(false);
        if (serviceOrderData[0].service_type.toLowerCase() === "service") {
          setcustomFrequency(true);
          const worktype_obj = obj?.worktypes.find(
            (i) => i.name === serviceOrderData[0].worktype
          );
          setServices(obj?.worktypes);
          setService(worktype_obj.uuid);          
          setTime(serviceOrderData[0]?.preferred_start_time ? moment(serviceOrderData[0]?.preferred_start_time, "HH:mm") : moment("8:00", "HH:mm"))
          
          setServiceInfoData({
            value:worktype_obj.uuid,
            key: worktype_obj.name,
            name: "service"
          });

          setServiceTypeTitle(
            serviceOrderData[0]?.worktype.charAt(0).toUpperCase() +
              serviceOrderData[0]?.worktype.slice(1)
          );
          setWorkSubTypeList(worktype_obj?.worksubtypes);          
          setFrequency(serviceOrderData[0].frequency_settings);
          
          if (
            serviceOrderData[0]?.worksubtype &&
            serviceOrderData[0]?.worksubtype !== "" &&
            serviceOrderData[0]?.worksubtype !== undefined
          ) {            
            const worksubtype_obj = worktype_obj?.worksubtypes.find(
              (i) => i.uuid === serviceOrderData[0]?.service_type_uuid
            );
            if(worksubtype_obj){
              setWorkSubType(worksubtype_obj.uuid);
              setPriceDetailData({
                frequency: worksubtype_obj.uuid,
                price: worksubtype_obj?.price,
                key: worksubtype_obj.name,
              });
              setisRecurring(worksubtype_obj.is_recurring)
            }else{
              setPriceDetailData({
                frequency: worktype_obj?.uuid,
                price: worktype_obj?.price,
                key: worktype_obj?.name,
              });
              setisRecurring(worktype_obj.is_recurring)
            }
          } else {
            setPriceDetailData({
              frequency: worktype_obj?.uuid,
              price: worktype_obj?.price,
              key: worktype_obj?.name,
            });
            setisRecurring(worktype_obj.is_recurring)
          }              
        }
        if (serviceOrderData[0].service_type.toLowerCase() === "maintenance") {
          setFrequency(serviceOrderData[0].frequency_settings);

          setPriceDetailData({
            frequency: serviceOrderData[0].frequency_settings,
            price: serviceOrderData[0].service_price,
            key: "custom _frequency",
            name: "frequency_settings",
          });
          setMaintenanceCategory(
            serviceOrderData[0].is_flat_rate ? "Flat_Rate" : "Standard"
          );
          setTime(serviceOrderData[0]?.preferred_start_time ? moment(serviceOrderData[0]?.preferred_start_time, "HH:mm") : moment("8:00", "HH:mm"))
        }
        if (
          serviceOrderData[0].service_type.toLowerCase() === "renovation" ||
          serviceOrderData[0].service_type.toLowerCase() === "construction"
        ) {
          setPriceDetailData({
            frequency: "",
            price: serviceOrderData[0].service_price,
            key: "",
            name: "",
          });
          setIsRenovationOrConstruction(true);
          setcustomFrequency(false);
        }
        setPropertyAddress(leadData.address.length > 1 ? leadData.address[1]: leadData.address[0]);
        setPoolType(serviceOrderData[0].pool_type_uuid);
        setNote(serviceOrderData[0].notes);
        setPoolName(serviceOrderData[0].pool_name);
        setPoolSize(serviceOrderData[0].pool_size);
      }
    };

    return (
      <div className="adetail">
        <h3>Service Information</h3>
        <div className="form-block">
          <div className="frow">
            <h4>Service Type</h4>
            <div className="oplist">
              {map(
                serviceTypeData?.service_matrix?.servicetypes,
                (item, index) => (
                  <div className="inline-checks" key={`serviceType${index}`}>
                    <label
                      htmlFor={item?.uuid}
                      className={`${
                        serviceType === item?.uuid ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        id={item?.uuid}
                        name="serviceType"
                        className=""
                        value={item?.uuid}
                        onChange={() =>
                          onTextChange({
                            target: {
                              name: "serviceType",
                              value: item?.uuid,
                              key: item?.name,
                            },
                          })
                        }
                      />
                      <span>{item?.name}</span>
                    </label>
                  </div>
                )
              )}
            </div>
            {errors?.[`serviceType`] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.[`serviceType`]}
              </label>
            ) : (
              ""
            )}
          </div>
          {
            //Add Maintenance Category
            customFrequency && serviceTypeName === "maintenance" ? (
              <div className="frow">
                <h4>
                  Select Rate Type<sup>*</sup>
                </h4>
                <div className="oplist">
                  {map(maintenanceCategories, (item, index) => (
                    <div className="inline-checks" key={`category${index}`}>
                      <label
                        htmlFor={item.id}
                        className={`${
                          maintenanceCategory === item.name ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id={item?.id}
                          name="maintenanceCategory"
                          className=""
                          value={item.uuid}
                          onChange={() =>
                            onTextChange({
                              target: {
                                name: "maintenanceCategory",
                                value: item.name,
                                price: 0,
                                key: item.name,
                              },
                            })
                          }
                        />
                        <div className="form-group">
                          <div className="fitem">
                            <span>{item.name}</span>
                            <br />
                            <span style={{ fontSize: "12px" }}>
                              ({item.additional_text})
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                {errors?.[`maintenanceCategory`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`maintenanceCategory`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            ) : null
          }

          {frequencyList?.length > 0 ? (
            <div className="frow">
              <h4>How often do you need your pool cleaned?</h4>
              <div className="oplist">
                {map(frequencyList, (item, index) => (
                  <div className="inline-checks" key={`frequency${index}`}>
                    <label
                      htmlFor={item?.uuid}
                      className={`${frequency === item?.uuid ? "active" : ""}`}
                    >
                      <input
                        type="radio"
                        id={item?.uuid}
                        name="frequency"
                        className=""
                        value={item?.uuid}
                        onChange={() =>
                          onTextChange({
                            target: {
                              name: "frequency",
                              value: item?.uuid,
                              price: item?.price,
                              key: item?.name,
                            },
                          })
                        }
                      />
                      <span>{item?.name}</span>
                    </label>
                  </div>
                ))}
              </div>
              {errors?.[`frequency`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`frequency`]}
                </label>
              ) : (
                ""
              )}
            </div>
          ) : null}
          {isRenovationOrConstruction ? (
            <>
              <div className="frow">
                <label>Notes: Please provide customer's request</label>
                <textarea
                  placeholder="Customer proposal"
                  name="message"
                  rows="5"
                  value={note}
                  onChange={(e) => {
                    onTextChange({
                      target: {
                        name: "notes",
                        value: e.target.value,
                        key: "notes",
                      },
                    });
                  }}
                ></textarea>
              </div>
              <AddReferenceField />
            </>
          ) : (
            ""
          )}
          {services.length > 0 ? (
            <div className="frow">
              <h4>What service you are looking for?</h4>
              <div className="oplist">
                {map(services, (item, index) => (
                  <div className="inline-checks" key={`service${index}`}>
                    <label
                      htmlFor={item?.uuid}
                      className={`${service === item?.uuid ? "active" : ""}`}
                    >
                      <input
                        type="radio"
                        id={item?.uuid}
                        name="service"
                        className=""
                        value={item?.uuid}
                        onChange={() =>
                          onTextChange({
                            target: {
                              name: "service",
                              value: item?.uuid,
                              price: item?.price,
                              key: item?.name,
                            },
                          })
                        }
                      />
                      <span>{item?.name}</span>
                    </label>
                  </div>
                ))}
              </div>
              {errors?.[`service`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`service`]}
                </label>
              ) : (
                ""
              )}
              {workSubTypeList.length > 0 ? (
                <div className="frow">
                  <h4>{serviceTypeTitle} Categories</h4>
                  <div className="oplist">
                    {map(workSubTypeList, (item, index) => (
                      <div className="inline-checks" key={`service${index}`}>
                        <label
                          htmlFor={item?.uuid}
                          className={`${
                            workSubType === item?.uuid ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id={item?.uuid}
                            title={item.additional_text}
                            name="worksubtype"
                            className=""
                            value={item?.uuid}
                            onChange={() =>
                              onTextChange({
                                target: {
                                  name: "worksubtype",
                                  value: item?.uuid,
                                  price: item?.price,
                                  key: item?.name,
                                },
                              })
                            }
                          />
                          <span>{item?.name}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {errors?.[`workSubType`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`workSubType`]}
                </label>
              ) : (
                ""
              )}
            </div>
          ) : null}
          {customFrequency ? (
            <div>
              {serviceTypeName === "maintenance" ? (
                <h4>How often do you need your pool cleaned?</h4>
              ) : (
                <h4> How often do you need your service?</h4>
              )}
              <CustomFrequency
                onTextChange={onTextChange}
                data={frequency}
                is_recurring={isRecurring}
                recurring_base_prices={
                  serviceTypeData?.service_matrix?.recurring_base_prices
                }
              />
              {errors?.[`frequency.start`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`frequency.start`]}
                </label>
              ) : (
                ""
              )}
            </div>
          ) : null}
          {customFrequency ? (
            <>
              <Row>
                <div className="mb-10" style={{ width: "50%" }}>
                  <h4>Select Time</h4>
                  <TimePicker
                    className=""
                    placeholder={"--:-- AM/PM"}
                    value={time}
                    onChange={(e) => {
                      onTextChange({
                        target: {
                          name: "time",
                          value: e,
                          key: "time",
                        },
                      });
                    }}
                    allowClear={false}
                    format={"h:mm A"}
                  />
                </div>
                <Col span={1}></Col>
                <AddDurationField durationType="service" />
              </Row>
              {errors?.[`time`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`time`]}
                </label>
              ) : (
                ""
              )}
               <Row>
                <AddReferenceField />
              </Row>
            </>
          ) : null}
          {!isRenovationOrConstruction ? (
            <>
              <div className="frow">
                <h4>Pool type</h4>
                <div className="oplist">
                  {map(
                    serviceTypeData?.service_matrix?.pool_types,
                    (item, index) => (
                      <div className="inline-checks" key={`poolType${index}`}>
                        <label
                          htmlFor={item?.uuid}
                          className={`${
                            poolType === item?.uuid ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id={item?.uuid}
                            name="poolType"
                            className=""
                            value={item?.uuid}
                            onChange={() =>
                              onTextChange({
                                target: {
                                  name: "poolType",
                                  value: item?.uuid,
                                  key: item?.name,
                                },
                              })
                            }
                          />
                          <span>{item?.name}</span>
                        </label>
                      </div>
                    )
                  )}
                  {poolType === "179a4155-9268-3633-ad49-0a7e14af60a2" && (
                    <div className="from-group w-100">
                      <div className="fitem">
                        <input
                          type="text"
                          id="other_pool_type"
                          name="otherPoolType"
                          placeholder="Additional other pool type"
                          value={otherPoolType}
                          onChange={(e) => {
                            setOtherPoolType(e.target.value);
                            setServiceInfoData({
                              value: e.target.value,
                              key: "otherPoolType",
                              name: e.target.name,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {errors?.[`poolType`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`poolType`]}
                  </label>
                ) : (
                  ""
                )}
                {errors?.[`otherPoolType`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`otherPoolType`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div className="frow">
                <h4>Notes</h4>
                <textarea
                  id="notes"
                  name="notes"
                  placeholder=""
                  value={note}
                  onChange={(e) =>
                    onTextChange({
                      target: { name: "notes", value: e.target.value, key: "" },
                    })
                  }
                ></textarea>
              </div>
              <div className="frow" style={{ width: "70%" }}>
                <h4>Enter Service Address and Pool Name</h4>
                <div>
                  <div className="form-group mb-20">
                    <div className="half-fitem fitem">
                      <div className="half-fitem fitem">
                        <label style={{ fontWeight: "bold" }}>
                          Pool Name <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          placeholder="Pool Name"
                          name="poolName"
                          value={poolName}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setPoolName(e.target.value);
                            setServiceInfoData({
                              value: e.target.value,
                              key: "poolName",
                              name: "pool_name",
                            });
                          }}
                        />
                        {errors?.[`poolName`] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.[`poolName`]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="half-fitem fitem">
                        <label style={{ fontWeight: "bold" }}>
                          Pool size in Gallons
                        </label>
                        <input
                          type="text"
                          id="poolSize"
                          style={{ width: "100%" }}
                          name="poolSize"
                          placeholder="20000"
                          value={poolSize}
                          onChange={onTextChange}
                        />
                        {errors?.[`poolSize`] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.[`poolSize`]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <h3>Property Address</h3>
                  <AddressField
                    handleAddressChange={handleAddressChange}
                    onAddressChanged={onAddressChange}
                    onAddressChange={onAddressChange}
                    errors={errors}
                    data={propertyAddress}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
);

export default ServiceOrderInfo;
