import React, { useDebugValue, useEffect } from "react";
import PaymentContainer from "../Components/Containers/Payment.Container";
import Header from "../Components/Common/PaymentHeader";
import "../../Assets/css/payment.css";
import useResponsiveContainer from "../../Calendar/Components/Containers/useResponsiveContainer";
import scriptFile from "../common/tracking";

const Payment = () => {
  const [isMobile, isDesktopOrLaptop] = useResponsiveContainer();

  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script")
    script.setAttribute('file',scriptFile);
    head.appendChild(script);
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <div
        className={`container ${
          isMobile ? "container-payment-mobile" : "container-payment"
        } `}
      >
        <PaymentContainer />
      </div>
    </div>
  );
};

export default Payment;
