import React, { useEffect, useState } from "react";
import { Col, Row, Select } from "antd";
import { orderBy } from "lodash";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useCalendar } from "../../../Hooks/Calendar.Hook";

import {
  addPreferredTechnician,
} from "../../../../Store/techJourneyDataSlice";

const PreferredTechnician = () => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const { Option } = Select;

  return (
    <Row>
      <Col span={24}>
        <div className="from-group">
          <div className="fitem">
            <label>Preferred Technician</label>
            <Select
              showSearch
              placeholder="Search"
              optionFilterProp="children"
              value={techJourneyState.preferredTechnician}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(preferredTechnician) =>
                dispatch(addPreferredTechnician(preferredTechnician))
              }
            >
              {techJourneyState.technicianArray &&
                orderBy(techJourneyState.technicianArray, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc")?.map(
                  (option) => (
                    <Option
                      key={option?.uuid}
                      value={option?.uuid}
                    >{`${option?.user?.first_name || ""} ${
                      option?.user?.last_name || ""
                    }`}</Option>
                  )
                )}
            </Select>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PreferredTechnician;
