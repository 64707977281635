import React, { useCallback, useEffect, useState } from "react";
import { Table, Row, Col } from "antd";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import DataTable from "./DataColumns";
import {
  getTerritoryTypeReport,
  getTerritoryZipReport,
} from "../../../Action/Reports/";
import { useReport } from "../../Hooks/Report.Hook";

const ExpandTerritoryZipRow = ({ record }) => {
  const [reportData, setReportData] = useState([]);
  const { superSearch, serviceType } = useReport();

  const buildReport = useCallback(async () => {
    const reportData = await getTerritoryZipReport({
      field_office__uuid: record.franchise_uuid,
      u_type: record.type,
      search: superSearch !== "" ? superSearch : undefined,
      service_type:
        serviceType === "all"
          ? undefined
          : serviceType.toLowerCase().replace(/ /g, "_"),
    });
    setReportData(reportData);
  }, [record, superSearch, serviceType]);

  useEffect(() => {
    buildReport();
  }, [buildReport]);

  return (
    <Table
      dataSource={reportData.map((item, idx) => ({ ...item, key: idx }))}
      columns={DataTable.InTerritoryRevenueZipReport(this)}
      className="table hscroll"
      pagination={false}
    />
  );
};

const ExpandTerritoryTypeRow = ({ record }) => {
  const [reportData, setReportData] = useState([]);
  const { fieldType, superSearch, serviceType } = useReport();

  const buildReport = useCallback(async () => {
    const reportData = await getTerritoryTypeReport({
      field_office__uuid: record.franchise_uuid,
      u_type:
        fieldType === "all"
          ? undefined
          : fieldType.toLowerCase().replace(/ /g, "_"),
      search: superSearch !== "" ? superSearch : undefined,
      service_type:
        serviceType === "all"
          ? undefined
          : serviceType.toLowerCase().replace(/ /g, "_"),
    });
    setReportData(reportData);
  }, [record, fieldType, superSearch, serviceType]);

  useEffect(() => {
    buildReport();
  }, [buildReport]);

  return (
    <Table
      dataSource={reportData.map((item, idx) => ({ ...item, key: idx }))}
      columns={DataTable.InTerritoryRevenueTypeReport(this)}
      className="table hscroll"
      pagination={false}
      expandable={{
        expandedRowRender: (record) => (
          <ExpandTerritoryZipRow record={record} />
        ),
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
          ) : (
            <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
          ),
      }}
    />
  );
};

const TerritoryTable = () => {
  const { reportData } = useReport();

  return (
    <>
      <Row>
        <Col span={24} className={"territory-table-container"}>
          <Table
            dataSource={reportData.map((item, idx) => ({ ...item, key: idx }))}
            columns={DataTable.InTerritoryRevenueSummaryReport(this)}
            className="table hscroll"
            pagination={false}
            expandable={{
              expandedRowRender: (record) => {
                return <ExpandTerritoryTypeRow record={record} />;
              },
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                ) : (
                  <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                ),
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default TerritoryTable;
