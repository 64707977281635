import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { map, isEmpty } from "lodash";
import * as yup from "yup";
import { useSelector } from "react-redux/es/hooks/useSelector";

import CustomFrequency from "../Common/CustomFrequency";
import { message, TimePicker, Row, Col } from "antd";
import moment from "moment-timezone";
import DurationFilter from '../../Components/Filters/Duration.Filter';
import AddDurationField from "../../Calendar/Components/Modals/SubComponents/AddDurationField";
import AddReferenceField from "../../Calendar/Components/Modals/SubComponents/AddReferenceField";

const BaseJobRequirements = forwardRef((props, ref) => {
  const [serviceType, setServiceType] = useState("");
  const [serviceTypeTitle, setServiceTypeTitle] = useState("");
  const [frequency, setFrequency] = useState({});
  const [maintenanceCategory, setMaintenanceCategory] = useState("");
  const [poolType, setPoolType] = useState("");
  const [poolSize, setPoolSize] = useState(0);
  const [errors, setErrors] = useState({});
  const [isBasicDetail, setIsBasicDetail] = useState(false);
  const [otherPoolType, setOtherPoolType] = useState("");
  const [frequencyList, setFrequencyList] = useState([]);
  const [maintenanceCategories, setMaintenanceCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [service, setService] = useState("");
  const [workSubTypeList, setWorkSubTypeList] = useState([]);
  const [workSubType, setWorkSubType] = useState("");
  const [serviceTypeName, setServiceTypeName] = useState("");
  const [customFrequency, setcustomFrequency] = useState(false);
  const [priceDetail, setpriceDetail] = useState({});
  const [isRenovationOrConstruction, setIsRenovationOrConstruction] =
    useState(false);
  const [notes, setNotes] = useState("");
  const [time, setTime] = useState(moment("8:00", "HH:mm"));
  const serviceTypeData = useSelector((state) => state.zipCodeData);
  const [worktypesList, setworktypesList] = useState([]);
  const [isRecurring, setisRecurring] = useState(true);

  useImperativeHandle(ref, () => ({
    validate() {
      const schema = yup.object().shape({
        frequency:
          serviceTypeName === "maintenance"
            ? yup
                .object()
                .shape({
                  start: yup.string().required("Frequency Is Required."),
                })
                .nullable(false)
            : null,
        maintenanceCategory:
          serviceTypeName === "maintenance"
            ? yup.string().required("Rate Type Is Required.")
            : null,
        serviceType: yup.string().required("Service Type Is Required."),
        poolType:
          serviceTypeName !== "renovation" && serviceTypeName !== "construction"
            ? yup.string().required("Pool Type Is Required.")
            : null,
        otherPoolType:
          poolType === "-1" &&
          (otherPoolType === undefined || otherPoolType === "")
            ? yup.string().required("Please enter the Other Pool Type.")
            : null,
        service:
          serviceTypeName === "service"
            ? yup.string().required("Service Is Required.")
            : null,
        workSubType:
          serviceTypeName === "service" && workSubTypeList.length > 0
            ? yup.string().required("Work Sub Type Is Required.")
            : null,
      });
      const data = {
        frequency,
        serviceType,
        maintenanceCategory,
        poolType,
        poolSize,
        service,
        workSubType,
      };
      return schema
        .validate(data, { abortEarly: false })
        .then(() => {
          setIsBasicDetail(true);
          props.setServiceInfoData({
            value: data,
            key: true,
            name: "baseJobReq",
          });
          setErrors({});
          return Promise.resolve("success");
        })
        .catch((error) => {
          let err_data = {};
          map(error?.inner, (item) => {
            err_data[item.path] = item.message;
          });
          setIsBasicDetail(false);
          setErrors(err_data);
          console.log(err_data);
          return Promise.reject("fail");
        });
    },
  }));

  useEffect(() => {
    setworktypesList(props.data?.service_matrix?.servicetypes[0]);

    if (props.data.datainfo) {
      let obj = serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) =>
          item.name.toLowerCase() ===
          props.data.datainfo.service_type.toLowerCase()
      )[0];
      setServiceType(obj.uuid);
      setServiceTypeName(props.data.datainfo.service_type.toLowerCase());
      if (props.data.datainfo.service_type.toLowerCase() === "maintenance") {
        loadMaintenance(obj);
      } else if (props.data.datainfo.service_type.toLowerCase() === "service") {
        loadService(obj);
      } else {
        loadRenovationConstruction(obj);
      }

      if (props.data.datainfo.pool_type_uuid)
        setPoolType(props.data.datainfo.pool_type_uuid);
      if (props.data.datainfo.pool_size)
        setPoolSize(props.data.datainfo.pool_size);
      if(props.data.preferred_start_time)      
        setTime(props.data?.preferred_start_time ? moment(props.data?.preferred_start_time, "HH:mm") : moment("8:00", "HH:mm"));
      if(props.data.datainfo.notes) 
        setNotes(props.data.datainfo.notes);
    } else {
      let obj = serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) => item.name.toLowerCase() === "maintenance"
      )[0];
      setServiceType(obj.uuid);
      setServiceTypeName("maintenance");
      setcustomFrequency(true);

      setMaintenanceCategories(obj?.worktypes);
      props.setServiceInfoData({
        value: obj.uuid,
        key: obj.name,
        name: "serviceType",
      });

      onTextChange({
        target: {
          name: "time",
          value: moment("8:00", "HH:mm"),
          key: "time",
        },
      });

      // setMaintenanceCategory('Standard'); //Do not selected as default
      // props.setServiceInfoData({value: 'Standard',key: 'maintenanceCategory'});

      setIsRenovationOrConstruction(false);
    }
  }, []);

  const loadMaintenance = (obj) => {
    setcustomFrequency(true);
    setMaintenanceCategories(obj?.worktypes);
    let category = props.data.datainfo.is_flat_rate ? "Flat_Rate" : "Standard";
    setMaintenanceCategory(category);
    setFrequency(props.data.datainfo.frequency_settings);
    const serviceInfo = [];
    serviceInfo.push({ value: obj.uuid, key: obj.name, name: "serviceType" });
    serviceInfo.push({
      value: props.data.datainfo.frequency_settings,
      key: "custom _frequency",
      name: "frequency",
    });
    serviceInfo.push({
      value: props.data.datainfo.pool_type_uuid,
      key: props.data.datainfo.pool_type,
      name: "poolType",
    });
    props.setServiceInfoList(serviceInfo);
    props.setPriceDetailData({
      frequency: props.data.datainfo.frequency_settings,
      price: props.data.datainfo.service_price,
      key: "custom _frequency",
    });
  };

  const loadService = (obj) => {
    setcustomFrequency(true);
    const worktype_obj = obj?.worktypes.find(
      (i) => i.name === props.data.datainfo?.worktype
    );
    setServices(obj?.worktypes);
    setService(worktype_obj.uuid);
    setServiceTypeName("service");

    setServiceTypeTitle(
      props.data.datainfo?.worktype.charAt(0).toUpperCase() +
        props.data.datainfo?.worktype.slice(1)
    );
    setWorkSubTypeList(worktype_obj?.worksubtypes);
    const serviceInfo = [];
    serviceInfo.push({ value: obj.uuid, key: obj.name, name: "serviceType" });
    serviceInfo.push({
      value: worktype_obj.uuid,
      key: worktype_obj.name,
      name: "service",
    });
    serviceInfo.push({
      value: props.data.datainfo.pool_type_uuid,
      key: props.data.datainfo.pool_type,
      name: "poolType",
    });
    if (
      props.data.datainfo.worksubtype &&
      props.data.datainfo.worksubtype !== ""
    ) {      
      const worksubtype_obj = worktype_obj?.worksubtypes.find(
        (i) => i.uuid === props.data.datainfo.service_type_uuid
      );
      if(worksubtype_obj){
        setWorkSubType(worksubtype_obj.uuid);
        props.setPriceDetailData({
          frequency: worksubtype_obj.uuid,
          price: worksubtype_obj?.price,
          key: worksubtype_obj.name,
        });
        serviceInfo.push({
          value: worksubtype_obj.uuid,
          key: worksubtype_obj.name,
          name: "worksubtype",
        });
        setisRecurring(worksubtype_obj.is_recurring)
      } else {
        props.setPriceDetailData({
          frequency: worktype_obj.uuid,
          price: worktype_obj?.price,
          key: worktype_obj.name,
        });
        setisRecurring(worktype_obj.is_recurring)
      }
    } else {
      props.setPriceDetailData({
        frequency: worktype_obj.uuid,
        price: worktype_obj?.price,
        key: worktype_obj.name,
      });
      setisRecurring(worktype_obj.is_recurring)
    }
    props.setServiceInfoList(serviceInfo);
    setFrequency(props.data.datainfo.frequency_settings);
  };

  const loadRenovationConstruction = (obj) => {
    setIsRenovationOrConstruction(true);
    setcustomFrequency(false);
    setServices([]);
    setWorkSubTypeList([]);
    setService("");
    setWorkSubType("");
    const serviceInfo = [];
    serviceInfo.push({ value: obj.uuid, key: obj.name, name: "serviceType" });
    obj.name.toLowerCase() === "renovation"
      ? setServiceTypeName("renovation")
      : setServiceTypeName("construction");
    setIsRenovationOrConstruction(true);
    props.setPriceDetailData({
      frequency: props.data.datainfo.frequency_settings,
      price: props.data.datainfo.service_price,
      key: "custom _frequency",
    });
    props.setServiceInfoList(serviceInfo);
  };

  const onTextChange = (e) => {
    if (e.target.name === "serviceType") {
      setServiceType(e.target.value);
      let obj = serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) => item.uuid === e.target.value
      )[0];
      if (obj.name.toLowerCase() === "service") {
        setServices(obj?.worktypes);
        setFrequencyList([]);
        setFrequency("");
        setWorkSubTypeList([]);
        setWorkSubType("");
        setServiceTypeName("service");
        setcustomFrequency(true);
        setIsRenovationOrConstruction(false);
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });
        setIsRenovationOrConstruction(false);
        setisRecurring(false)
      }
      if (obj.name.toLowerCase() === "maintenance") {
        setcustomFrequency(true);
        setServices([]);
        setWorkSubTypeList([]);
        setService("");
        setWorkSubType("");
        setFrequency("");
        setServiceTypeName("maintenance");
        setMaintenanceCategories(obj?.worktypes);
        setIsRenovationOrConstruction(false);
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });
        setIsRenovationOrConstruction(false);
        setisRecurring(true)
      }
      if (
        obj.name.toLowerCase() === "renovation" ||
        obj.name.toLowerCase() === "construction"
      ) {
        setcustomFrequency(false);
        setServices([]);
        setWorkSubTypeList([]);
        setService("");
        setWorkSubType("");
        setFrequency("");
        obj.name.toLowerCase() === "renovation"
          ? setServiceTypeName("renovation")
          : setServiceTypeName("construction");
        setIsRenovationOrConstruction(true);
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });
      }

      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      let _worktypesList = props.data?.service_matrix?.servicetypes.filter(
        (element) => {
          return element.name === e.target.key;
        }
      );
      setworktypesList(_worktypesList);
    }
    if (e.target.name === "frequency") { 
      setFrequency(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      if(serviceTypeName  !== "service")
      {
        props.setPriceDetailData({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        setpriceDetail({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
      }
    }
    if (e.target.name === "service") {
      setService(e.target.value);
      setServiceTypeTitle(
        e.target.key.charAt(0).toUpperCase() + e.target.key.slice(1)
      );
      setFrequency("");
      setWorkSubType("");
      let obj = services?.filter((item) => item.uuid === e.target.value)[0];
      setWorkSubTypeList(obj?.worksubtypes);
      if (obj?.worksubtypes.length === 0) {
        props.setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        props.setPriceDetailData({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        setpriceDetail({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });

        if(obj?.is_recurring !== undefined && obj?.is_recurring){
          setisRecurring(true)
        }else{
          setisRecurring(false)
        }
      } else {
        props.setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });
        setisRecurring(false)
      }
    }
    if (e.target.name === "worksubtype") {
      setWorkSubType(e.target.value);      
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      props.setPriceDetailData({
        frequency: e.target?.value,
        price: e.target?.price,
        key: e.target?.key,
      });
      setpriceDetail({
        frequency: e.target?.value,
        price: e.target?.price,
        key: e.target?.key,
      });
      
      const obj = services?.filter((item) => item.uuid === service)[0];
      const subWorkType = obj.worksubtypes.find(i => i.uuid === e.target?.value)
      if(subWorkType?.is_recurring !== undefined && subWorkType?.is_recurring){
        setisRecurring(true)
      }else{
        setisRecurring(false)
      }
    }
    if (e.target.name === "poolType") {
      setPoolType(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setOtherPoolType("");
      // props.setServiceInfoData({value:null,key:'otherPoolType',name:'otherPoolType'});
      props.setPriceDetailData(priceDetail);
    }
    if (e.target.name === "poolSize") {
      const result = e.target.value.replace(/\D/g, "");
      if (result <= 2147483647) {
        setPoolSize(result);
        props.setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
      } else {
        message.error("Pool size can not be more than 2147483647");
      }
    }
    if (e.target.name === "maintenanceCategory") {
      setMaintenanceCategory(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "notes") {
      setNotes(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "time") {
      setTime(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
  };

  const renderWorktypes = () => {
    if (worktypesList.length > 0) {
      if (worktypesList?.[0]?.name === "maintenance")
        return map(
          worktypesList[0].worktypes[0].worksubtypes,
          (item, index) => (
            <div className="inline-checks" key={`frequency${index}`}>
              <label
                htmlFor={item?.uuid}
                className={`${frequency === item?.uuid ? "active" : ""}`}
              >
                <input
                  type="radio"
                  id={item?.uuid}
                  name="frequency"
                  className=""
                  value={item?.uuid}
                  onChange={() =>
                    onTextChange({
                      target: {
                        name: "frequency",
                        value: item?.uuid,
                        price: item?.price,
                        key: item?.name,
                      },
                    })
                  }
                />
                <span>{item?.name}</span>
              </label>
            </div>
          )
        );
      else
        return map(worktypesList[0].worktypes, (item, index) => (
          <div className="inline-checks" key={`frequency${index}`}>
            <label
              htmlFor={item?.uuid}
              className={`${frequency === item?.uuid ? "active" : ""}`}
            >
              <input
                type="radio"
                id={item?.uuid}
                name="frequency"
                className=""
                value={item?.uuid}
                onChange={() =>
                  onTextChange({
                    target: {
                      name: "frequency",
                      value: item?.uuid,
                      price: item?.price,
                      key: item?.name,
                    },
                  })
                }
              />
              <span>{item?.name}</span>
            </label>
          </div>
        ));
    }
  };

  return (
    <div className="adetail">
      <h3>Base Job Requirements</h3>
      <div className="form-block">
        <div className="frow">
          <h4>Service Type</h4>
          <div className="oplist">
            {map(
              serviceTypeData?.service_matrix?.servicetypes,
              (item, index) => (
                <div className="inline-checks" key={`serviceType${index}`}>
                  <label
                    htmlFor={item?.uuid}
                    className={`${serviceType === item?.uuid ? "active" : ""}`}
                  >
                    <input
                      type="radio"
                      id={item?.uuid}
                      name="serviceType"
                      className=""
                      value={item?.uuid}
                      onChange={() =>
                        onTextChange({
                          target: {
                            name: "serviceType",
                            value: item?.uuid,
                            key: item?.name,
                          },
                        })
                      }
                    />
                    <span>{item?.name}</span>
                  </label>
                </div>
              )
            )}
          </div>
          {errors?.[`serviceType`] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.[`serviceType`]}
            </label>
          ) : (
            ""
          )}
        </div>
        {
          //Add Maintenance Category
          customFrequency && serviceTypeName === "maintenance" ? (
            <div className="frow">
              <h4>
                Select Rate Type<sup>*</sup>
              </h4>
              <div className="oplist">
                {map(maintenanceCategories, (item, index) => (
                  <div className="inline-checks" key={`category${index}`}>
                    <label
                      htmlFor={item.id}
                      className={`${
                        maintenanceCategory === item.name ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        id={item?.id}
                        name="maintenanceCategory"
                        className=""
                        value={item.uuid}
                        onChange={() =>
                          onTextChange({
                            target: {
                              name: "maintenanceCategory",
                              value: item.name,
                              price: 0,
                              key: item.name,
                            },
                          })
                        }
                      />
                      <div className="form-group">
                        <div className="fitem">
                          <span>{item.name}</span>
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            ({item.additional_text})
                          </span>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              {errors?.[`maintenanceCategory`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`maintenanceCategory`]}
                </label>
              ) : (
                ""
              )}
            </div>
          ) : null
        }
        {services.length > 0 ? (
          <div className="frow">
            <h4>What service you are looking for?</h4>
            <div className="oplist">
              {map(services, (item, index) => (
                <div className="inline-checks" key={`service${index}`}>
                  <label
                    htmlFor={item?.uuid}
                    className={`${service === item?.uuid ? "active" : ""}`}
                  >
                    <input
                      type="radio"
                      id={item?.uuid}
                      name="service"
                      className=""
                      value={item?.uuid}
                      onChange={() =>
                        onTextChange({
                          target: {
                            name: "service",
                            value: item?.uuid,
                            price: item?.price,
                            key: item?.name,
                          },
                        })
                      }
                    />
                    <span>{item?.name}</span>
                  </label>
                </div>
              ))}
            </div>
            {errors?.[`service`] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.[`service`]}
              </label>
            ) : (
              ""
            )}
            {workSubTypeList?.length > 0 ? (
              <div className="frow">
                <h4>{serviceTypeTitle} Categories</h4>
                <div className="oplist">
                  {map(workSubTypeList, (item, index) => (
                    <div className="inline-checks" key={`service${index}`}>
                      <label
                        htmlFor={item?.uuid}
                        className={`${
                          workSubType === item?.uuid ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id={item?.uuid}
                          title={item.additional_text}
                          name="worksubtype"
                          className=""
                          value={item?.uuid}
                          onChange={() =>
                            onTextChange({
                              target: {
                                name: "worksubtype",
                                value: item?.uuid,
                                price: item?.price,
                                key: item?.name,
                              },
                            })
                          }
                        />
                        <span>{item?.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {errors?.[`workSubType`] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.[`workSubType`]}
              </label>
            ) : (
              ""
            )}
            {frequencyList?.length > 0 ? (
              <div className="frow">
                <h4>How often do you need your pool cleaned?</h4>
                <div className="oplist">
                  {map(frequencyList, (item, index) => (
                    <div className="inline-checks" key={`frequency${index}`}>
                      <label
                        htmlFor={item?.uuid}
                        className={`${
                          frequency === item?.uuid ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id={item?.uuid}
                          name="frequency"
                          className=""
                          value={item?.uuid}
                          onChange={() =>
                            onTextChange({
                              target: {
                                name: "frequency",
                                value: item?.uuid,
                                price: item?.price,
                                key: item?.name,
                              },
                            })
                          }
                        />
                        <span>{item?.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
                {errors?.[`frequency`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`frequency`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            ) : null}
            
          </div>
        ) : null}
        {customFrequency ? (
          <>
            <div>
              {serviceTypeName === "maintenance" ? (
                <h4>How often do you need your pool cleaned?</h4>
              ) : (
                <h4> How often do you need your service?</h4>
              )}
              <CustomFrequency
                onTextChange={onTextChange}
                data={frequency}
                is_recurring={isRecurring}
                recurring_base_prices={
                  props.fieldOfficeData?.service_matrix?.recurring_base_prices
                }
              />
            </div>
            {errors?.[`frequency.start`] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.[`frequency.start`]}
              </label>
            ) : (
              ""
            )}
          </>
        ) : null}
        {customFrequency ? (
          <>
            <Row>
              <div className="mb-10" style={{ width: "50%" }}>
                <h4>Select Time</h4>
                <TimePicker
                  className=""
                  placeholder={"--:-- AM/PM"}
                  value={time}
                  onChange={(e) => {
                    onTextChange({
                      target: {
                        name: "time",
                        value: e,
                        key: "time",
                      },
                    });
                  }}
                  allowClear={false}
                  format={"h:mm A"}
                />
              </div>
              <Col span={1}></Col>
              <AddDurationField durationType="service" />
            </Row>
            {errors?.[`time`] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.[`time`]}
              </label>
            ) : (
              ""
            )}
            <Row>
              <AddReferenceField />
            </Row>
          </>          
        ) : null}
        {!isRenovationOrConstruction ? (
          <>
            <div className="frow">
              <h4>Pool type</h4>
              <div className="oplist">
                {map(
                  serviceTypeData?.service_matrix?.pool_types,
                  (item, index) => (
                    <div className="inline-checks" key={`poolType${index}`}>
                      <label
                        htmlFor={item?.uuid}
                        className={`${poolType === item?.uuid ? "active" : ""}`}
                      >
                        <input
                          type="radio"
                          id={item?.uuid}
                          name="poolType"
                          className=""
                          value={item?.uuid}
                          onChange={() =>
                            onTextChange({
                              target: {
                                name: "poolType",
                                value: item?.uuid,
                                key: item?.name,
                              },
                            })
                          }
                        />
                        <span>{item?.name}</span>
                      </label>
                    </div>
                  )
                )}
                {poolType === "179a4155-9268-3633-ad49-0a7e14af60a2" && (
                  <div className="from-group w-100">
                    <div className="fitem">
                      <input
                        type="text"
                        id="other_pool_type"
                        name="otherPoolType"
                        placeholder="Additional other pool type"
                        value={otherPoolType}
                        onChange={(e) => {
                          setOtherPoolType(e.target.value);
                          props.setServiceInfoData({
                            value: e.target.value,
                            key: "otherPoolType",
                            name: e.target.name,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {errors?.[`poolType`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`poolType`]}
                </label>
              ) : (
                ""
              )}
              {errors?.[`otherPoolType`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`otherPoolType`]}
                </label>
              ) : (
                ""
              )}
            </div>
            <hr />
            <div className="from-group">
              <div className="fitem">
                <label>Pool size in Gallons</label>
                <input
                  type="text"
                  id="poolSize"
                  name="poolSize"
                  placeholder="20000"
                  value={poolSize}
                  onChange={(e) =>
                    onTextChange({
                      target: {
                        name: "poolSize",
                        value: e.target.value,
                        key: "poolSize",
                      },
                    })
                  }
                />
              </div>
            </div>
            <hr />
          </>
        ) : (
          ""
        )}
        {isRenovationOrConstruction ? (
          <>
            <div className="frow">
              <label>Notes: Please provide customer's request</label>
              <textarea
                placeholder="Customer proposal"
                name="message"
                rows="5"
                value={notes}
                onChange={(e) => {
                  onTextChange({
                    target: {
                      name: "notes",
                      value: e.target.value,
                      key: "notes",
                    },
                  });
                }}
              ></textarea>
            </div>
            <AddReferenceField />
          </>
            ) : (
              ""
            )}
        {services.length > 0 ? (
          <div className="from-group">
            <div className="fitem">
              <label>Problem Description Notes</label>
              <textarea
                id="problemDesc"
                name="problemDesc"
                placeholder=""
                value={notes}
                onChange={(e) =>
                  onTextChange({
                    target: {
                      name: "notes",
                      value: e.target.value,
                      key: "notes",
                    },
                  })
                }
              ></textarea>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default BaseJobRequirements;
