import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";

const TechGlanceContext = createContext();

export function TechGlanceProvider({ children }) {
  const [franchise, setFranchise] = useState({ value: null, label: null });
  const [technician, setTechnician] = useState({
    value: null,
    label: null,
  });
  const [status, setStatus] = useState([
    "complete",
    "incomplete",
    "scheduled",
    "forecast",
    "cancelled",
  ]);

  const [loading, setLoading] = useState(false);

  const buildTech = useCallback(async () => {
    let data = [];
    setLoading(true);
    // eslint-disable-next-line default-case
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    buildTech();
  }, [buildTech]);

  const value = useMemo(() => {
    return {
      loading,
      setLoading,
      franchise,
      setFranchise,
      technician,
      setTechnician,
      status,
      setStatus,
    };
  }, [
    loading,
    setLoading,
    franchise,
    setFranchise,
    technician,
    setTechnician,
    status,
    setStatus,
  ]);

  return (
    <TechGlanceContext.Provider value={value}>
      {children}
    </TechGlanceContext.Provider>
  );
}

export function useCalendar() {
  return useContext(TechGlanceContext);
}
