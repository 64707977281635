import axios from "axios";
import config from "./Config";

const instance = axios.create({
  baseURL: config.getHost(),
  timeout: 3000000,
});

instance.interceptors.request.use(
  async (reqConfig) => {
    const accessToken = JSON.parse(localStorage.getItem("authToken"))
      ? JSON.parse(localStorage.getItem("authToken")).key
      : "";

    let headers;
    if (accessToken) {
      headers = {
        Authorization: `token ${accessToken}`,
        "Content-Type": "application/json",
      };
    } else {
      headers = {
        "Content-Type": "application/json",
      };
    }
    return { ...reqConfig, headers };
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.assign(`/?ref=${window.location.pathname}`);
      localStorage.clear();
      sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default instance;
