import React, {useEffect} from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { Col, Row, Checkbox } from "antd";
import {addRecurrenceAction} from "../../../../Store/technicianTaskDataSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";

const AddRecurrenceCheckbox = () => {

    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.technicianTaskJourney);
    const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
    let is_lead_tech = false;
    Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
      if (element[1]) {
        is_lead_tech = true;
      }
    });

    const is_fo = userInfo?.user?.user_roles?.find(
      (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
    );
  
    const role = is_fo
      ? "franchise_owner"
      : userInfo?.user?.is_lead_technician && is_lead_tech
        ? "lead_tech"
        : userInfo?.user?.user_roles[0]?.name;

    const updateRecurrenceStatus = (e) => {
        dispatch(addRecurrenceAction(e.target.checked))
    };
    
    return (
        <div>
            <Row>
                <Col span={24} style={{ textAlign: "center", margin: "15px 0" }}>
                    or
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Checkbox
                        onChange={updateRecurrenceStatus}
                        checked={tasks.recurrenceFlag}
                        disabled={
                            (role === "field_tech" || role === "lead_tech") &&
                            !tasks?.selectedTaskData?.permissionset?.includes(
                              "assign_wo"
                            )
                          }
                    >
                    <span className="recurrence-subtext">Apply changes on all the future recurring instances of this Task</span>
                    </Checkbox>
                </Col>
            </Row>
        </div>
    );
};

export default AddRecurrenceCheckbox;