import React, { useState } from "react";
import "../../Assets/css/calendar.css";
import HeaderMobile from "../../Components/HeaderMobile";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import { CalendarProvider } from "../Hooks/Calendar.Hook";
import CalendarContainer from "../Components/Containers/Calendar.Container";

const Calendar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const onMenuButtonClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div id="wrapper">
      <div className="calendar-ca content-area">
        <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenuButtonClick}
        />
        <div className="container">
          <CalendarProvider>
            <CalendarContainer />
          </CalendarProvider>
          
        </div>
      </div>
    </div>
  );
};

export default Calendar;
