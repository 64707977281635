import React from "react";

const STATUS_COLOR_CLASS = {
  active: "success-status",
  inactive: "gray-status",
  pending: "warning-status"
};

const SERVICE_STATUS = {
  active: "ACTIVE",
  inactive: 'INACTIVE',
  pending: 'PENDING',
};

const CUSTOMER_TYPE = {
  residential: "Residential",
  commercial: 'Commercial'
};

class DataTable {

    customerColumns = () => {
      return ([
        {
          title: 'Customer',
          dataIndex: 'last_name',
          key: 'last_name',
          sorter: true,
          render: (text, record) => <span>{record?.user?.last_name}, {record?.user?.first_name}</span>
        },
        {
          title: 'Customer Type',
          dataIndex: 'user_roles__name',
          key: 'user_roles__name',
          sorter: true,
          render: (text, record) => (<span>{CUSTOMER_TYPE[record?.customer_type] || ''}</span>)
        },
        {
          title: 'Active Services',
          dataIndex: 'active_services',
          key: 'active_services',
          sorter: true,
          render: (text, record) => (<span>{record?.active_services || '0'}</span>)
        },
        {
          title: 'status',
          dataIndex: 'status',
          key: 'status',
          sorter: false,
          render: (text, record) => (<span className={`status ${STATUS_COLOR_CLASS[record.status]}`}>{record.status ? SERVICE_STATUS[record.status]: ''}</span>)
        }
      ]);
    }
    bulkColums = () => {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const logged_in_user_role = authUser?.user?.user_roles?.map((x) => x.name);
      const columns = [
        {
          title: 'Service Order',
          dataIndex: 'service_order_number',
          key: 'service_order_number',
          sorter: true,
          render: (text, record) => <span>{record?.service_order_number}</span>
        },
        {
          title: 'Service Type',
          dataIndex: 'service_type_name',
          key: 'service_type_name',
          sorter: true,
          render: (text, record) => (<span> {record?.service_type_name}</span>)
        },
        {
          title: 'Franchise',
          dataIndex: 'field_office_name',
          key: 'field_office_name',
          sorter: true,
          render: (text, record) => (<span> {record?.field_office_name}</span>)
        },
        {
          title: ' Customer Name',
          dataIndex: 'customer_name',
          key: 'customer_name',
          sorter: true,
          render: (text, record) => (<span>{record?.customer_name ? record?.customer_name : "-"}</span>)
        },
        {
          title: 'Job Name',
          dataIndex: 'service_order_name',
          key: 'service_order_name',
          sorter: true,
          render: (text, record) => (<span>{record?.service_order_name}</span>)
        },
        {
          title: 'Technician',
          dataIndex: 'pftech_name',
          key: 'pftech_name',
          sorter: false,
          render: (text, record) => (<span> {record?.pftech_name ? record?.pftech_name : "NA"}</span>)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          sorter: false,
          render: (text, record) => (<span className={`status ${STATUS_COLOR_CLASS[record.status]}`}>{record.is_active ? 'Active': ''}</span>)
        },
      ];
      /* if(logged_in_user_role?.includes("franchise_owner")) {
        columns.push(
          {
            title: 'Franchise',
            dataIndex: 'field_office_name',
            key: 'field_office_name',
            sorter: false,
            render: (text, record) => (<span> {record?.field_office_name}</span>)
          }
        );
      } */
      return columns;
    }
}


export default new DataTable();