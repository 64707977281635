import React from "react";
import { Modal, message } from "antd";
import {
  openActivateModal,
} from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import "../../../Assets/css/proposalBase.css";
import { deactivateAndReactivate } from "../../../Action/Customer";
import Loader from "../../../Components/Loader";
// What is this ? import { useState,resetState } from "react";

const ReactivateModal = (props) => {
  const {proposalTableuuid,fromProposalTable,saveClick}=props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [loader, setLoader] = useState(false);
 
  const oncloseactivate = () => {
    dispatch(openActivateModal(false));
  };

  const reactivateProposal = async () => {
    setLoader(true);
    const proposal_uuid= props.state;
    const param = {
      is_active: true, //This parameter should always be true for reactivate
    };
    if(fromProposalTable === 'fromProposalTable'){
      const result = await deactivateAndReactivate(proposalTableuuid,param);
      message.success(result.message,10)
      dispatch(openActivateModal(false));
      saveClick(true)
      setLoader(false);

    }
    else{
      const result = await deactivateAndReactivate(proposal_uuid,param);
      message.success(result.message,10)
      dispatch(openActivateModal(false));
      // What is this dispatch(resetState());
      setLoader(false);

    }
  };
  return (
    <div>
      {proposals.activate_modal == true && (
        <div>
                           {loader && <Loader />}
          <Modal
            title={<h3> Reactivate Proposal </h3>}
            open={proposals.activate_modal}
            footer={[
              <button
                key="upload"
                style={{ backgroundColor: "white", color: "black" }}
                className={`btn btn-waning`}
                onClick={() => oncloseactivate()}
              >
                Cancel
              </button>,
              <button
                key="cancel"
                className="btn btn-brand btn-bdr"
                onClick={() => reactivateProposal()}
              >
                Reactivate
              </button>,
            ]}
            // className="delete_user_modal"
            width={400}
          >
            <div>
              <p>
                {" "}
                The Proposal will be Reactivated and moved to the Proposal Tab.
              </p>
            </div>
          </Modal>
        </div>
      )}
     
    </div>
  );
};
export default ReactivateModal;
