import React from "react";
import moment from "moment-timezone";
import { map, isEmpty, orderBy } from "lodash";
import { formatToCurrency } from "../../Utils/formatToCurrency";
import { Select, Modal } from "antd";
// import chevronDown from '../../Assets/images/chevron-down.png';

const { Option } = Select;

const SERVICE_STATUS_COLOR_CLASS = {
  forecast: "gray-status",
  tentative: "info-status",
  scheduled: "blue-status",
  complete: "complete-status",
  cancelled: "billable",
  incomplete: "incomplete-status"

};

const SERVICE_STATUS = {
  complete: 'COMPLETE',
  cancelled: 'CANCELED',
  incomplete: 'INCOMPLETE',
  forecast: 'FORECAST',
  scheduled: 'SCHEDULED'


};

// mapping STATUS for hardcoded
const STATUS = {
  draft: "Draft",
  open: "Open",
  "open-emailed": "Open",
  "open-printed": "Open",
  overdue: "Overdue",
  Closed: "closed",
  paid: "Paid",
  void: "Void",
  closed: "Closed",
  "partially refunded": "Partially Refunded",
  refunded: "Refunded",
};

// mapping of lead stage
const leadStage = {
  new: "New",
  contacted: "Contacted",
  interested: "Interested",
  customer: "Customer",
};

// mapping of out reach
const outreach = {
  website: "Website",
  call_center: "Call Center",
};

// const LAST_CLEANED = { less_than_2_weeks: 'Less than 2 weeks ago', '1-2_months': '2 to 6 weeks', '2_months_or_more': 'More than 6 weeks ago' }

class DataTable {
  // format phone number
  // formatPhoneNumber = (phone) => {
  //   phone = phone?.replace(/\D/g, "");
  //   return (
  //     phone?.substr(0, 3) + " " + phone?.substr(3, 3) + " " + phone?.substr(6)
  //   );
  // };

  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  };

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date);
  };

  // customer columns
  customerColumns = (franchiseList, customerList, search_value) => {
    return [
      {
        title: "Acc Number",
        dataIndex: "acc_number",
        key: "acc_number",
        sorter: false,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "customer_name",//first_name
        className: "tphnum",
        sorter: true,
        // render: (text, record) => <span>{`${record?.first_name} ${record?.last_name}`}</span>
        render: (text, record) => {
          // const isInclude = (search_value && (record?.first_name?.toLowerCase()?.includes(search_value?.toLowerCase()) || record?.last_name?.toLowerCase().includes(search_value?.toLowerCase()))) ? true : false;
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          // if (search_value && isInclude) {
          //   const pattern = new RegExp(search_value, "gi");
          //   const new_text = name.replace(pattern, "<u>$&</u>");
          //   return <span className="ellipse" id={`customerName${record.uuid}`} dangerouslySetInnerHTML={{ __html: `<b>${new_text}</b>` }}></span>
          // } else {
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
          // }
        },
      },
      {
        title: "Address (Billing)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: false,
        // render: (text, record) => <span>{record?.address?.[0]?.formatted_address}</span>
        render: (text, record) => {
          const address =
            record?.address?.length > 0
              ? `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
              }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
              }`
              : "";
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        // render: (text, record) => <span>{record?.first_name}</span>
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });

          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
        // render: (text, record) => {
        //   if (!record?.is_dummy_email && !record.email.includes('doody.com')) {
        //     const isInclude = (search_value && record?.email?.toLowerCase()?.includes(search_value?.toLowerCase())) ? true : false;
        //     if (search_value && isInclude) {
        //       const pattern = new RegExp(search_value, "gi");
        //       const new_text = record?.email.replace(pattern, "<u>$&</u>");
        //       return <span className="ellipse" id={`customerEmail${record.uuid}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(new_text)}` }}></span>
        //     } else {
        //       return <span className="ellipse" id={`customerEmail${record.uuid}`} dangerouslySetInnerHTML={{ __html: `${record?.email}` }}></span>
        //     }
        //   } else {
        //     return '';
        //   }
        // }
      },
      {
        title: "Franchise",
        dataIndex: "franchise",
        key: "franchise",
        sorter: false,
        filters: franchiseList,
        className: "lfbdr filterico",
        filterIcon: <i className="las la-filter ml-10" />,
        render: (text, record) =>
          !isEmpty(record?.field_office) ? (
            <span>{`${record?.field_office?.name}`}</span>
          ) : (
            ""
          ),
      },
      {
        title: "Joined",
        dataIndex: "joined",
        key: "joined",
        sorter: false,
        className: "tdate",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{local_time.fromNow(true)}</span>;
        },
      },
      {
        title: "Last Service",
        dataIndex: "last_service",
        key: "last_service",
        sorter: false,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Outstanding",
        dataIndex: "outstanding",
        key: "outstanding",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              record?.customer_open_invoice_balance
            )}`}</b>
          </span>
        ),
      },
      {
        title: "Satisfaction",
        dataIndex: "satisfaction",
        key: "satisfaction",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span>
            {record?.customer_satisfaction_score
              ? `${record?.customer_satisfaction_score}%`
              : ""}
          </span>
        ),
      },
      {
        title: "Next follow up",
        dataIndex: "next_follow_up",
        key: "next_follow_up",
        sorter: false,
        className: "tdate",
        render: (text) => (
          <span>
            {text ? this.UTCToLocal(text).format("MMM DD, YYYY") : ""}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span>
            {record?.status === "pending" ? (
              <b className="info">Pending</b>
            ) : record?.is_active ? (
              <b className="success">Active</b>
            ) : (
              <b className="danger">Inactive</b>
            )}
          </span>
        ),
      },
    ];
  };
  // commercial customer columns
  residentialCustomerColumns = (franchiseList, customerList, search_value) => {
    return [
      {
        title: "Acc Number",
        dataIndex: "acc_number",
        key: "acc_number",
        sorter: false,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        className: "tphnum",
        sorter: false,
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Address (Service)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: false,
        render: (text, record) => {
          const address =
            record?.address?.length > 0
              ? `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
              }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
              }`
              : "";
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Lead Stage",
        dataIndex: "lead_stage",
        key: "lead_stage",
        sorter: false,
        render: (text, record) => <span>{record?.lead_stage}</span>,
      },
      {
        title: "Outreach",
        dataIndex: "outreach",
        key: "outreach",
        sorter: false,
        render: (text, record) => <span>{record?.outreach}</span>,
      },
      {
        title: "Lead Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Last Contacted",
        dataIndex: "next_scheduled_contact",
        key: "next_scheduled_contact",
        sorter: false,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Next Schedule Contact",
        dataIndex: "next_schedule_contact",
        key: "next_schedule_contact",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
    ];
  };

  // commercial customer columns
  commercialCustomerColumns = (franchiseList, customerList, search_value) => {
    return [
      {
        title: "Acc Number",
        dataIndex: "acc_number",
        key: "acc_number",
        sorter: false,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: false,
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        className: "tphnum",
        sorter: false,
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Address (Service)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: false,
        render: (text, record) => {
          const address =
            record?.address?.length > 0
              ? `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
              }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
              }`
              : "";
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Lead Stage",
        dataIndex: "lead_stage",
        key: "lead_stage",
        sorter: false,
        render: (text, record) => <span>{record?.lead_stage}</span>,
      },
      {
        title: "Outreach",
        dataIndex: "outreach",
        key: "outreach",
        sorter: false,
        render: (text, record) => <span>{record?.outreach}</span>,
      },
      {
        title: "Lead Creation Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Last Contacted",
        dataIndex: "next_scheduled_contact",
        key: "next_scheduled_contact",
        sorter: false,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Next Schedule Contact",
        dataIndex: "next_schedule_contact",
        key: "next_schedule_contact",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
    ];
  };
  // commercial customer table columns

  // commercial customer columns
  commercialCustomerTableColumns = (
    franchiseList,
    customerListCommercial,
    search_value
  ) => {
    return [
      {
        title: "Acc Number",
        dataIndex: "acc_number",
        key: "acc_number",
        sorter: false,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: false,
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "customer_name",
        className: "tphnum",
        sorter: true,
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Address (Service)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: false,
        render: (text, record) => {
          const address =
            record?.address?.length > 0
              ? `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
              }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
              }`
              : "";
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Franchise",
        dataIndex: "franchise",
        key: "franchise",
        sorter: false,
        filters: franchiseList,
        className: "lfbdr filterico",
        filterIcon: <i className="las la-filter ml-10" />,
        render: (text, record) =>
          !isEmpty(record?.field_office) ? (
            <span>{`${record?.field_office?.name}`}</span>
          ) : (
            ""
          ),
      },
      {
        title: "Joined",
        dataIndex: "joined",
        key: "joined",
        sorter: false,
        className: "tdate",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{local_time.fromNow(true)}</span>;
        },
      },

      {
        title: "Last Service",
        dataIndex: "last_service",
        key: "last_service",
        sorter: false,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Outstanding",
        dataIndex: "outstanding",
        key: "outstanding",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              record?.customer_open_invoice_balance
            )}`}</b>
          </span>
        ),
      },
      {
        title: "Satisfaction",
        dataIndex: "satisfaction",
        key: "satisfaction",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span>
            {record?.customer_satisfaction_score
              ? `${record?.customer_satisfaction_score}%`
              : ""}
          </span>
        ),
      },
      {
        title: "Next follow up",
        dataIndex: "next_follow_up",
        key: "next_follow_up",
        sorter: false,
        className: "tdate",
        render: (text) => (
          <span>
            {text ? this.UTCToLocal(text).format("MMM DD, YYYY") : ""}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span>
            {record?.status === "pending" ? (
              <b className="info">Pending</b>
            ) : record?.is_active ? (
              <b className="success">Active</b>
            ) : (
              <b className="danger">Inactive</b>
            )}
          </span>
        ),
      },
    ];
  };

  //commercial customer table columns end here
  // Invoice Table
  InvoiceWeb = (renderAction) => {
    const invoiceColumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span className="date">
            <b>
              {record?.invoice_date
                ? record?.invoice_date
                : record?.date_creditmemo}
            </b>
          </span>
        ),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: false,
        render: (text, record) => (
          <span className="type">
            {record?.invoice_date ? record.invoice_type : "Credit Memo"}
          </span>
        ),
      },
      {
        title: "Invoice #",
        dataIndex: "invoice_id",
        key: "invoice_id",
        sorter: false,
        className: "tdate",
        render: (text, record) => (
          <span className="invoice_id">
            {record?.invoice_id ? record?.invoice_id : record?.creditmemo_id}
          </span>
        ),
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        sorter: false,
        className: "lfbdr tdate",
        render: (text, record) => (
          <span className="due_Date">
            {record.invoice_id
              ? moment(record?.due_date).format("MM/DD/YYYY")
              : "N/A"}
          </span>
        ),
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        sorter: false,
        className: "tprice",
        render: (text, record) =>
          record?.status === "draft" ? (
            <span className="balance">--</span>
          ) : (
            <span className="balance">
              $
              {record.creditmemo_id && record?.remaining_balance > 0 ? "-" : ""}
              {record?.balance_due
                ? record?.balance_due
                : record?.remaining_balance}
            </span>
          ),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: false,
        className: "tprice",
        render: (text, record) => (
          <React.Fragment>
            <span className="totalamt">
              ${record.creditmemo_id && record?.total_with_tax > 0 ? "-" : ""}
              {record?.total_with_tax}
            </span>
          </React.Fragment>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: false,
        className: "tstatus",
        render: (text, record) => (
          <span
            className={
              STATUS[record?.status] === "Overdue"
                ? "danger"
                : STATUS[record?.status] === "Open"
                  ? "success"
                  : ""
            }
          >
            <b>{STATUS[record?.status]}</b>
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "no-wrap",
        render: (text, record) =>
          renderAction(
            record?.uuid,
            record?.service_order_uuid,
            record?.status,
            record,
            record?.creditmemo_id
          ),
      },
    ];
    return invoiceColumns;
  };

  //payment history table starts here
  InvoiceHistory = (self) => {
    const historyColumns = [
      {
        title: 'Invoice Number',
        dataIndex: 'invoice',
        key: 'invoice',
        render: (text, record) => (<span className="type ellipse">{record?.invoice_number || ''}</span>)
      },
      {
        title: 'Customer Name',
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => (<span className="type ellipse">{record?.customer_name}</span>)
      },
      {
        title: 'Amount Received',
        dataIndex: 'amount_received',
        key: 'amount_received',
        render: (text, record) => (<b className="type ellipse">${record?.amount_received ? `${formatToCurrency(record?.amount_received)}` : '$ 0.00'}</b>)
      }
      ,
      {
        title: 'Payment Type',
        dataIndex: 'cash_payment',
        key: 'cash_payment',
        render: (text, record) => (<b className="type ellipse">{record?.payment_type ? record?.payment_type === "cc" || record?.payment_type === "CC" ? record?.payment_type.toUpperCase() : record?.payment_type.charAt(0).toUpperCase() + record?.payment_type.slice(1) : "-"}</b>)
      },
      {
        title: 'Check Id',
        dataIndex: 'cheque_id',
        key: 'cheque_id',
        render: (text, record) => (<b className="type ellipse">{record?.check_id !== 'None' ? record?.check_id : '-'}</b>)

      },
      {
        title: 'Received From',
        dataIndex: 'received_from',
        key: 'received_from',
        render: (text, record) => (<span className="type ellipse">{record?.received_from ? record?.received_from : "-"}</span>)
      },
      {
        title: 'Received By',
        dataIndex: 'received_by',
        key: 'received_by',
        render: (text, record) => (<span className="type ellipse">{record?.received_by ? record?.received_by : "-"}</span>)
      },
      {
        title: 'Payment Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (text, record) => (<span className="type ellipse">{record?.payment_date ? moment(record?.payment_date).format('MMM DD, YYYY') : 'Check'}</span>)
      }
    ]
    return historyColumns;
  }



  //Payment history table end here
  //Accounting table
  AllAccountList = (self) => {
    
    const accountColumns = [
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        key: "uuid",
        sorter: (a, b) => a?.invoice_date.localeCompare(b?.invoice_date),
        render: (text, record) => (
          <span className="type ellipse">
            {moment(text).format("MM/DD/YYYY")}
          </span>
        ),
      },
      {
        title: "Invoice #",
        dataIndex: "invoice_id",
        key: "uuid",
        sorter: false,
        render: (text, record) => (
          <span className="type ellipse"># {text}</span>
        ),
      },
      {
        title: "Customer Name",
        dataIndex: "service_order_client_first_name",
        key: "uuid",
        sorter: (a,b)=> a.user?.first_name.localeCompare(b.user?.first_name),
        render: (text, record) => (
          <span className="type ellipse">
            {record.user
              ? `${record.user.first_name}, ${record.user.last_name}`
              : ""}
          </span>
        ),
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        render: (text, record) => (
          <span className="type ellipse text-capitalize">
            {record?.company_name || ""}
          </span>
        ),
      },
      {
        title: "Service Type",
        dataIndex: "service_order__service_type",
        key: "service_order__client__service_type",
        sorter: (a,b) => a?.service_order?.service_type?.localeCompare(b?.service_order?.service_type),
        render: (text, record) => (
          <span className="type ellipse text-capitalize">
            {record?.service_order?.service_type || ""}
          </span>
        ),
      },
      {
        title: "Customer Type",
        dataIndex: "service_order__customer_type",
        key: "service_order__client__customer_type",
        sorter: true,
        render: (text, record) => (
          <span className="type ellipse text-capitalize">
            {record?.user?.user_roles.length > 0 ?
              record?.user?.user_roles[0]?.name === "customer" ?
                "Residential" : "Commercial"
              : ""}
          </span>
        ),
      },
      {
        title: "Due Date",
        dataIndex: "due_date",
        key: "uuid",
        // className: "lfbdr",
        sorter: (a, b) => a?.due_date.localeCompare(b?.due_date),
        render: (text) => {
          return (
            <span className="type ellipse">
              {moment(text).format("MM/DD/YYYY")}
            </span>
          );
        },
      },
      {
        title: "Sub Total",
        dataIndex: "total",
        key: "total",
        sorter: false,
        render: (text, record) => (
          <span className="type ellipse">${formatToCurrency(text)}</span>
        ),
      }, 
      {
        title: "Sales Tax",
        dataIndex: "sales_tax",
        key: "uuid",
        sorter: false,
        render: (text, record) => (
          <span className="type ellipse">${formatToCurrency(text)}</span>
        ),
      },
      {
        title: "Total",
        dataIndex: "total_with_tax",
        key: "total_with_tax",
        sorter: (a,b) => a?.total_with_tax-b?.total_with_tax,
        render: (text, record) => (
          <span className="type ellipse">${formatToCurrency(text)}</span>
        ),
      },
      {
        title: "Deposit Status",
        dataIndex: "deposit_status",
        key: "uuid",
        sorter: (a,b) => a?.balance_due-b?.balance_due,
        render: (text, record) =>
          <span className="type ellipse">{record?.deposit_status}</span>
      },
      {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "uuid",
        sorter: false,
        render: (text, record) =>
          <span className="type ellipse">{record?.payment_status}</span>
      },
      {
        title: "Invoice Status",
        dataIndex: "status",
        key: "uuid",
        sorter: true,
        render: (text, record) => (
          <span className="type ellipse text-successs text-capitalize">
            {text}
          </span>
        ),
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        sorter: false,
        className: "no-wrap",
        render: (text, record) =>
          self.renderAction(
            record?.uuid,
            record?.service_order_uuid,
            record?.status,
            record,
            record?.creditmemo_id
          ),
      },
    ];
    return accountColumns;
  };


  // WorkOrders Table

  AllWorkOrdersList = (renderAction) => {
    const workOrdersColumns = [
      {
        title: 'Service Order',
        dataIndex: 'service_order_number',
        key: 'service_order_number',
        sorter: true,
        // defaultSortOrder: 'ascend',
        // sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.service_order.service_order_number.localeCompare(b.service_order.service_order_number),
        showSorterTooltip: true,
        render: (text, record) => (
          <span className="type ellipse" style={{ fontWeight: "bold" }}>
            {record?.service_order?.service_order_number}
          </span>)
      },
      {
        title: 'Work Order',
        dataIndex: 'work_order_number',
        key: 'work_order_number',
        sorter: true,
        showSorterTooltip: true,
        // defaultSortOrder: 'ascend',
        // sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a?.work_order_number?.localeCompare(b?.work_order_number),
        render: (text, record) => (
          <span className="type ellipse" style={{ fontWeight: "bold" }}>
            {record?.work_order_number ? record?.work_order_number : ""}
          </span>)
      },
      {
        title: 'Service Type',
        dataIndex: 'service_type_name',
        key: 'service_type_name',
        sorter: true,
        showSorterTooltip: true,
        render: (text, record) => (
          <span className="type ellipse">{`${record?.service_type_name?.charAt(0).toUpperCase()}${record?.service_type_name?.slice(1)}`}</span>)
      },
      {
        title: 'Customer',
        dataIndex: 'client_name',
        key: 'client_name',
        sorter: true,
        showSorterTooltip: true,
        // defaultSortOrder: 'ascend',
        // sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.client_name.localeCompare(b.client_name),
        render: (text, record) => (<span className="type ellipse">{record?.client_name}</span>)
      },
      {
        title: 'Customer Type',
        dataIndex: 'client_type',
        key: 'client_type',
        sorter: true,
        showSorterTooltip: true,
        // defaultSortOrder: 'ascend',
        // sortDirections: ['ascend', 'descend', 'ascend'],
        // sorter: (a, b) => (a.service_order?.client_type) - (b.service_order?.client_type),
        sorter: (a, b) => a.service_order?.client_type.localeCompare(b.service_order?.client_type),
        render: (text, record) => (
          // <span className="type ellipse">{`${record?.service_order?.client_type}`}</span>)
          // render: (text, record) => <b>{'${record?.service_order?.client_type?.charAt(0).toUpperCase()}${record?.service_order?.client_type?.slice(1)}'}</b>
          <span className="type ellipse">{`${record?.service_order?.client_type?.charAt(0).toUpperCase()}${record?.service_order?.client_type?.slice(1)}`}</span>)
      },

      {
        title: 'Service Date',
        dataIndex: 'scheduled_appointment',
        key: 'scheduled_appointment',
        sorter: true,
        // defaultSortOrder: 'ascend',
        // sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.scheduled_appointment.localeCompare(b.scheduled_appointment),
        showSorterTooltip: true,
        render: (text, record) => (<span className="type ellipse">{moment(record?.scheduled_appointment).format('MMM DD, YYYY')}</span>)
      },
      // {
      //   title: 'Billing Type',
      //   dataIndex: 'billing_type',
      //   key: 'billing_type',
      //   render: (text, record) => (<span className="type ellipse">{record?.billing_type}</span>)
      // },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
        key: 'amount_due',
        sorter: true,
        // defaultSortOrder: 'ascend',
        // sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => (a.amount_due) - (b.amount_due),
        showSorterTooltip: true,
        render: (text, record) => (<span className="type ellipse">${(record?.amount_due)}</span>)
      },
      {
        title: 'Invoice Status',
        dataIndex: 'invoice_status',
        key: 'invoice_status',
        sorter: true,
        showSorterTooltip: true,
        render: (text, record) => (<span className="type ellipse">{record?.invoice_status}</span>)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // sorter: true,
        // showSorterTooltip: false,
        // sortDirections: ['ascend', 'descend', 'ascend'],
        // sorter: (a, b) => {
        //   const a_status = a.status.replaceAll('_', ' ');
        //   const b_status = b.status.replaceAll('_', ' ');

        //   return a_status.localeCompare(b_status)},
        render: (text, record) => {
          switch (record?.status) {
            case 'cancelled_non_billable':
              return (
                <div className="billable">
                  CANCELED
                  <small>Non Billable</small>
                </div>
              )
            case 'cancelled_billable':
              return (
                <div className="billable">
                  CANCELED
                  <small>Billable</small>
                </div>
              )
            // case 'cancelled_billable':
            //   return(
            //     <div className="status danger-status">
            //         CANCELED
            //       <small>Billed</small>
            //     </div>
            //   )
            default:
              return <span className={record?.status ? `status ${SERVICE_STATUS_COLOR_CLASS[record.status]}` : ''}>
                {record?.status ? SERVICE_STATUS[record?.status] : ''}
              </span>
          }
        }
      },
      {
        title: 'Action',
        dataIndex: 'record',
        key: 'record',
        className: 'no-wrap',
        render: (text, record) => renderAction(record)
      },
    ]
    return workOrdersColumns;
  };
  CustomersTableColumns = (
    franchiseList,
    customerListCommercial,
    search_value
  ) => {
    return [
      {
        title: "Franchise",
        dataIndex: "field_office__name",
        filterName: "field_office__name",
        key: "franchise",
        sorter: true,
        render: (text, record) => <span>{record?.field_office?.name}</span>,
      },
      {
        title: "Acc Number",
        dataIndex: "account_number",
        key: "acc_number",
        sorter: true,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "customer_name",
        className: "tphnum",
        sorter: true,
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },     
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Address (Service)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: true,
        render: (text, record) => {
          const serviceaddress = record?.address?.length > 1 ? record?.address?.filter((obj) => obj.uuid !== record?.invoice_address?.uuid) : record?.address;
          record['address'] = serviceaddress;
          const address =
            record?.address?.length > 1
              ? `${record?.address?.[1]?.address || ""}, ${record?.address?.[1]?.city || ""
              }, ${record?.address?.[1]?.state || ""} ${record?.address?.[1]?.zip_code || ""
              }`
              : `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
            }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
            }`;
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },      
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        sorter: true,
        render: (text, record) => <span>{record?.service_type}</span>,
      },
      {
        title: "Customer Type",
        dataIndex: "customer_type",
        key: "customer_type",
        sorter: true,
        render: (text, record) => <span>{record.customer_type}</span>,
      },
      {
        title: "Joined",
        dataIndex: "created_at",
        key: "joined",
        sorter: true,
        className: "tdate",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{local_time.fromNow(true)}</span>;
        },
      },
      {
        title: "Last Service",
        dataIndex: "last_service",
        key: "last_service",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? moment(text).format('MMM DD, YYYY')
              : ""}
          </span>
        ),
      },
      {
        title: "Outstanding",
        dataIndex: "customer_open_invoice_balance",
        key: "outstanding",
        sorter: true,
        className: "tdate",
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              record?.customer_open_invoice_balance
            )}`}</b>
          </span>
        ),
      },
      {
        title: "Next follow up",
        dataIndex: "next_follow_up",
        key: "next_follow_up",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text ? this.UTCToLocal(text).format("MMM DD, YYYY") : ""}
          </span>
        ),
      },
    ];
  };
  LeadsTableColumns = (
    franchiseList,
    customerListCommercial,
    search_value
  ) => {
    return [
      {
        title: "Franchise",
        dataIndex: "field_office__name",
        filterName: "field_office__name",
        key: "franchise",
        sorter: true,
        render: (text, record) => <span>{record?.field_office?.name}</span>,
      },
      {
        title: "Acc Number",
        dataIndex: "account_number",
        key: "account_number",
        sorter: true,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Lead Name",
        dataIndex: "first_name",
        key: "lead_name",
        className: "tphnum",
        sorter: true,
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },     
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Address (Service)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: true,
        render: (text, record) => {
          const serviceaddress = record?.address?.length > 1 ? record?.address?.filter((obj) => obj.uuid !== record?.invoice_address?.uuid) : record?.address;
          record['address'] = serviceaddress;
          const address =
            record?.address?.length > 1
              ? `${record?.address?.[1]?.address || ""}, ${record?.address?.[1]?.city || ""
              }, ${record?.address?.[1]?.state || ""} ${record?.address?.[1]?.zip_code || ""
              }`
              : `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
            }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
            }`;
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },      
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        sorter: true,
        render: (text, record) => <span>{record?.service_type}</span>,
      },
      {
        title: "Lead Type",
        dataIndex: "lead_type",
        key: "lead_type",
        sorter: true,
        render: (text, record) => <span>{record.lead_type}</span>,
      },
      {
        title: "Lead Stage",
        dataIndex: "lead_stage",
        key: "lead_stage",
        sorter: true,
        render: (text, record) => <span>{record?.lead_stage}</span>,
      },
      {
        title: "Outreach",
        dataIndex: "outreach",
        key: "outreach",
        sorter: true,
        render: (text, record) => <span>{record?.outreach}</span>,
      },
      {
        title: "Lead Creation Date",
        dataIndex: "created_at",
        key: "joined",
        sorter: true,
        className: "tdate",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{local_time.fromNow(true)}</span>;
        },
      },
      {
        title: "Last Contacted",
        dataIndex: "last_contacted",
        key: "last_contacted",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Next Schedule Contact",
        dataIndex: "next_scheduled_contact",
        key: "next_scheduled_contact",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        sorter: true,
        render: (text, record) => <span>{record?.is_active?"Active":"Inactive" }</span>,
      },
    ];
  };
  InactiveCustomersTableColumns = (
    franchiseList,
    customerListCommercial,
    search_value
  ) => {
    return [
      {
        title: "Franchise",
        dataIndex: "field_office__name",
        filterName: "field_office__name",
        key: "franchise",
        sorter: true,
        render: (text, record) => <span>{record?.field_office?.name}</span>,
      },
      {
        title: "Acc Number",
        dataIndex: "account_number",
        key: "acc_number",
        sorter: true,
        render: (text, record) => <span>{record?.account_number}</span>,
      },
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "customer_name",
        className: "tphnum",
        sorter: true,
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },     
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tphnum",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        sorter: true,
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Address (Service)",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: true,
        render: (text, record) => {
          const address =
            record?.address?.length > 0
              ? `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
              }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
              }`
              : "";
          const isInclude =
            search_value &&
              address?.toLowerCase()?.includes(search_value?.toLowerCase())
              ? true
              : false;
          if (search_value && isInclude) {
            const pattern = new RegExp(search_value, "gi");
            const new_text = address.replace(pattern, "<u>$&</u>");
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${new_text}` }}
              ></span>
            );
          } else {
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          }
        },
      },      
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        sorter: true,
        render: (text, record) => <span>{record?.service_type}</span>,
      },
      {
        title: "Customer Type",
        dataIndex: "customer_type",
        key: "customer_type",
        sorter: true,
        render: (text, record) => <span>{record.customer_type}</span>,
      },
      {
        title: "Joined",
        dataIndex: "created_at",
        key: "joined",
        sorter: true,
        className: "tdate",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{local_time.fromNow(true)}</span>;
        },
      },
      {
        title: "Last Service",
        dataIndex: "last_service",
        key: "last_service",
        sorter: true,
        className: "tdate",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Deactivate Date",
        dataIndex: "cancelation_date",
        key: "cancelation_date",
        sorter: true,
        className: "tdate",
        render: (text, record) => <span>{record.cancelation_date}</span>,
      },
      {
        title: "Deactivate Reason",
        dataIndex: "cancelation_reason",
        key: "cancelation_reason",
        sorter: true,
        className: "tdate",
        render: (text, record) => <span>{record.cancelation_reason}</span>,
      },
    ];
  };

}

export default new DataTable()
