import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import { getServiceMatrix } from "../../../../Action/Customer/index";
import {
  selectRateType
} from "../../../../Store/techJourneyDataSlice";

const SelectService = () => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const { workTypesArray: rateTypes } = techJourneyState;

  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <h4>Select Rate Type</h4>
          <div className="oplist">
            {rateTypes &&
              rateTypes.map((item, index) => (
                <div
                  className="inline-checks"
                  key={`category${index}`}
                >
                  <label htmlFor={item.id} className={`${techJourneyState?.selectedWorkType?.name === item.name ? 'active' : ''}`}>
                    <input
                      type="radio"
                      id={item?.id}
                      name="maintenanceCategory"
                      className=""
                      value={item.uuid}
                      onChange={() =>dispatch(selectRateType({...item, price: item.price, serviceType: "maintenance"}))
                        // onTextChange({
                        //   target: {
                        //     name: "maintenanceCategory",
                        //     value: item.name,
                        //     price: 0,
                        //     key: item.name,
                        //   },
                        // })
                    }
                    />
                    <div className="form-group">
                      <div className="fitem">
                        <span>{item.name}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          ({item.additional_text})
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              ))}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SelectService;
