import React, { useState, useEffect, useRef } from "react";
import { Modal, DatePicker, Input, message } from "antd";
import { Provider, useSelector, useDispatch } from "react-redux";
import { getCustomerDetails, createCustomerInstax } from "./utils";
import _ from "lodash";
import store from "../../Store/store";
import moment from "moment";

const StaxModal = ({
  open = false,
  setOpen = () => {},
  customer = {},
  onSuccessCall = () => {},
  type = "",
  staxKey = null
}) => {

  const effectCalled = useRef(false);
  const [staxJsObj, setStaxJsObj] = useState(null);
  const [bankName, setBankName] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankHolderType, setBankHolderType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!effectCalled.current && staxKey && staxKey !== "") {
      loadStax(staxKey);
      effectCalled.current = true;
    }else if(!effectCalled.current){
      message.error("Stax is not set up for this franchise", 10);
      hideActivityLog();
    }
  }, []);

  const hideActivityLog = () => {
    setOpen(false);
  };

  const addNewACHPayment = async () => {
    let customerData = await getCustomerDetails(customer);
    let createCustomerResp = await createCustomerInstax(customerData);
    if (createCustomerResp.id) {
      const ach_data = {
        method: "bank",
        person_name: accountHolderName || "",
        bank_account: accountNumber || "",
        bank_routing: routingNumber || "",
        bank_holder_type: bankHolderType.toLowerCase() || "",
        bank_type: accountType.toLowerCase() || "",
      };

      staxJsObj
        .tokenize(ach_data)
        .then(async (res) => {
          if (res && Object.keys(res).length > 0) {
            onSuccessCall(undefined, res);
            message.success("Card added successfully!");
          }
        })
        .catch((err) => {
          message.error(err.message, 10);
        });
    } else {
      message.success("Error con stax customer creation");
    }
  };

  const loadStax = (staxPublicApiKey) => {
    const StaxJs = window.StaxJs;
    const staxObject = new StaxJs(staxPublicApiKey,{});
    setStaxJsObj(staxObject);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  return (
    <Provider store={store}>
      <Modal
        title={<span>Add Bank Account</span>}
        open={open}
        onOk={() => addNewACHPayment()}
        onCancel={hideActivityLog}
        className="stax-log-modal"
        destroyOnClose
        maskClosable={true}
        closable={false}
        width={420}
        //footer={null}
      >
        <div className="fitem">
          <label>
            Bank Name <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            placeholder="Bank Name"
            onChange={(value) => setBankName(value.target.value)}
            value={bankName}
          />
        </div>
        <div className="fitem">
          <label>Routing Number</label>
          <label style={{ color: "red" }}> &nbsp;*</label>
          <input
            type="text"
            value={routingNumber}
            className="form--control"
            placeholder="Routing Number"
            maxLength={9}
            inputMode="number"
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                e.preventDefault();
              }
            }}
            onPaste={(event) => {
              if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setRoutingNumber(e.target.value);
            }}
          />
        </div>
        <div className="fitem">
          <label>Account Holder Name</label>
          <label style={{ color: "red" }}> &nbsp;*</label>
          <input
            type="text"
            className="form--control"
            value={accountHolderName}
            placeholder="Account Holder Name"
            maxLength={18}
            inputMode="number"
            onChange={(e) => {
              setAccountHolderName(e.target.value);
            }}
          />
        </div>
        <div className="fitem">
          <label>Account Number</label>
          <label style={{ color: "red" }}> &nbsp;*</label>
          <input
            type="text"
            id="account-number"
            value={accountNumber}
            className="form--control"
            placeholder="Account Number"
            maxLength={15}
            inputMode="number"
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                e.preventDefault();
              }
            }}
            onPaste={(event) => {
              if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              setAccountNumber(e.target.value);
            }}
          />
        </div>
        <div className="fitem">
          <label>Account Type</label>
          <label style={{ color: "red" }}> &nbsp;*</label>
          <div>
            <input
              type="radio"
              id="achecking"
              name="AccountType"
              checked={accountType === "checking"}
              onChange={(e) => setAccountType(e.target.value)}
              value={"checking"}
            />
            &nbsp;&nbsp;
            <label>Checking</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <input
              type="radio"
              id="asaving"
              checked={accountType === "savings"}
              name="AccountType"
              onChange={(e) => setAccountType(e.target.value)}
              value="savings"
            />
            &nbsp;&nbsp;
            <label>Saving </label>
          </div>
        </div>
        <div className="fitem">
          <label>Bank Holder Type</label>
          <label style={{ color: "red" }}> &nbsp;*</label>
          <div>
            <input
              type="radio"
              id="checking"
              checked={bankHolderType === "Personal"}
              name="BankHolderType"
              onChange={(e) => setBankHolderType(e.target.value)}
              value="Personal"
            />
            &nbsp;&nbsp;
            <label>Personal</label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <input
              type="radio"
              id="saving"
              checked={bankHolderType === "Business"}
              name="BankHolderType"
              onChange={(e) => setBankHolderType(e.target.value)}
              value="Business"
            />
            &nbsp;&nbsp;
            <label>Business</label>
          </div>
        </div>
      </Modal>
    </Provider>
  );
};

export default StaxModal;
