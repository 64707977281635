import React, { useState, useEffect } from "react";

import quickbook from "../../Assets/images/quickbook.png";
import hubspot from "../../Assets/images/hubspot.png";
import optimoroute from "../../Assets/images/optimoroute.png";
import pulsem from "../../Assets/images/pulsem.png";
import { Select, Modal, message, Input } from "antd";
import { isEmpty, map } from "lodash";
import * as yup from "yup";
import step_1 from "../../Assets/images/step_1.png";
import step_2 from "../../Assets/images/step_2.png";
import step_3 from "../../Assets/images/step_3.png";
import step_4 from "../../Assets/images/step_4.png";
import step_5 from "../../Assets/images/step_5.png";
import Loader from "../../Components/Loader";
import stax from "../../Assets/images/stax.png";
import { loginWithQuickbook } from "../../Action/Franchise";
import { useSearchParams } from "react-router-dom";
import SetupOptionsPulseM from './SetupOptionsPulseM';
import SetupOptionsOptimoRoute from './SetupOptionsOptimoRoute';
import SetupOptionsStaxPay from './SetupOptionsStaxPay';
import SetupGpsTrack from './SetupGpsTrack';
import SetupHubspot from './SetupHubspot';

const { Option } = Select;

const SetupOptions = ({
  franchise_data,
  updateFranchiseData,
  disabledSetupQuickbook,
}) => {
  const [url, setURL] = useState("");

  const [state, setState] = useState({
    stripe_account_id: null,
    isDisabledSetupQuickbook: false,
    optimoroute_api_key: "",
    // optimoroute_api_key_value: this.props.franchise_data.optimoroute_api_key ? this.props.franchise_data.optimoroute_api_key : '',
    // isOptimorouteConnected: this.props.franchise_data.optimoroute_api_key ? true : false,
    errors: {},
    is_quickbooks_modal: false,
    quickbooks_client_id: "",
    quickbooks_client_secret: "",
    quickbooks_environment: "",
    stax_public_apikey: "",
    stax_bearer_apikey: "",
    is_show_stax_fields: false,
    stax_keys: {},
    pulsem_token: "",
    pulsem_location_id: "",
    is_show_pulsem_fields: false,
    pulsem_keys: {},
    loader: false,
    isOptimorouteConnected: true,
    optimoroute_api_key: "",
  });
  const onValid = async () => {
    let {
      quickbooks_client_id,
      quickbooks_client_secret,
      quickbooks_environment,
    } = state;
    if (!isEmpty(franchise_data)) {
      let temp_url = await loginWithQuickbook(franchise_data?.uuid, {
        client_id: quickbooks_client_id,
        client_secret: quickbooks_client_secret,
        environment: quickbooks_environment,
      });

      if (temp_url.message) {
        message.info(temp_url.message);
      } else if (temp_url.url) {
        updateFranchiseData({ ...franchise_data, from_quickBooks: true });
        window.open(temp_url.url, "_blank");
      }
    }
    setState({ ...state, is_quickbooks_modal: false });
  };
  const onSetupClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      let {
        quickbooks_client_id,
        quickbooks_client_secret,
        quickbooks_environment,
      } = state;
      const schema = yup.object().shape({
        quickbooks_client_id: yup.string().required("Client ID is required"),
        quickbooks_client_secret: yup
          .string()
          .required("Client Secret is required"),
        quickbooks_environment: yup
          .string()
          .required("Environment is required"),
      });
      const data = {
        quickbooks_client_id,
        quickbooks_client_secret,
        quickbooks_environment,
      };
      // check validity
      let IsValid;
      let err_data = {};
      schema.isValid(data).then(function (valid) {
        IsValid = valid;
      });
      setTimeout(() => {
        if (IsValid) {
          onValid();
        } else {
          schema.validate(data, { abortEarly: false }).catch(function (err) {
            map(err.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setState({
              ...state,
              errors: err_data,
            });
            console.log("err_data:", err_data);
          });
        }
      }, 100);
    }, 300);
  };
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;
  useEffect(() => {
    if (!state.is_quickbooks_modal) {
      setState({
        ...state,
        errors: {},
        quickbooks_client_id: "",
        quickbooks_client_secret: "",
        quickbooks_environment: "",
      });
    }
  }, [state.is_quickbooks_modal]);
  useEffect(() => {
    if (
      franchise_data?.stax_public_apikey === null ||
      franchise_data?.stax_public_apikey === ""
    ) {
      setState({
        ...state,
        is_show_stax_fields: true,
      });
    }
    if (
      franchise_data?.pulsem_token === null ||
      franchise_data?.stax_public_apikey === ""
    ) {
      setState({
        ...state,
        is_show_pulsem_fields: true,
      });
    }
    if (franchise_data) {
      // this.props.getOptimorouteKey(this.props?.franchise_data.uuid);
      // this.props.getStaxKeys(this.props?.franchise_data.uuid)
      setState({
        ...state,
        stripe_account_id: franchise_data?.stripe_account_id
          ? franchise_data?.stripe_account_id
          : "",
        stax_public_apikey: franchise_data?.stax_public_apikey
          ? franchise_data?.stax_public_apikey
          : "",
        pulsem_token: franchise_data?.pulsem_token
          ? franchise_data?.pulsem_token
          : "",
        pulsem_location_id: franchise_data?.pulsem_location_id
          ? franchise_data?.pulsem_location_id
          : "",
      });
    }
    // const franchiseData = JSON.parse(localStorage.getItem('temp_franchise'))
    // if (franchiseData.data.optimoroute_api_key) {
    //     setState({
    //         ...state,
    //         isOptimorouteConnected: franchiseData.data.optimoroute_api_key ? true : false,
    //     })
    // }

    if (franchise_data) {
      setState({
        ...state,
        isDisabledSetupQuickbook: franchise_data.is_quickbooks_connected,
      });
    }
    if (disabledSetupQuickbook) {
      setState({ ...state, isDisabledSetupQuickbook: disabledSetupQuickbook });
    }
  }, []);

  let { isDisabledSetupQuickbook, is_quickbooks_modal, errors, loader } = state;

  return (
    <div className="setup_option_wrapper">
      <div className="left_side">
        <div className="card">
          <div className="card-body">
            <img src={quickbook} alt="" className="stripe_logo" />
            <p>
              Creating an app generates your development keys. Use the app for yourself, your clients, or make it available to all QuickBooks users by listing it on QuickBooks app store.
            </p>

            <ol className="quickbook_list">
              <li>
                If you are connecting to QuickBooks for the first time, please refer to the <a href={"https://images.poolops.com/PoolOps%202.0%20QBO-Setup%20User%20Guide.pdf"} target={"_blank"} rel="noreferrer"> QuickBooks Setup Guide</a> to generate your Client ID and Client Secret.
              </li>
              <li>
                If you already have your Client ID and Client Secret, please proceed with QuickBooks setup.
              </li>
            </ol>
            <button
              className={`btn ${
                isDisabledSetupQuickbook ? "disabled" : "btn-primary"
              }`}
              disabled={isDisabledSetupQuickbook}
              onClick={() => {
                setState({ ...state, is_quickbooks_modal: true });
              }}
            >
              {isDisabledSetupQuickbook
                ? "Quickbook is already connected"
                : "Setup Quickbooks"}
            </button>
          </div>
        </div>
        <SetupGpsTrack franchise_data={franchise_data}/>
        {isCorporate && <SetupHubspot franchise_data={franchise_data}/>}
        {/* <div className="card">
                    <div className="card-body">
                        <img src={hubspot} alt="" className="stripe_logo" />
                        <p>Marketing software to hlep you grow trafic, convert more visitors, and run complete inbound marketing campain at a scale</p>
                    </div>
                    <div className="form-group">
                        <button style={{marginLeft:'30px',marginBottom:'30px'}} className="btn btn-warning">Click here to connect Your Hubspot Account</button>
                    </div>
                </div> */}
      </div>
      <div className="right_side">
        <SetupOptionsStaxPay franchise_data={franchise_data}/>
        <SetupOptionsOptimoRoute franchise_data={franchise_data}/>
        <SetupOptionsPulseM franchise_data={franchise_data}/>
        {/* <div className="card mb-20 stripe_Card">
                    <div className="card-body">
                        <img src={stax} alt="" className="stripe_logo" />
                        <p> Your API keys are available in the StaxPay Fattmerchant Dashboard. They include randomly generated Web Payments Token and API Key. Sign-in to StaxPay Fattmerchant site and follow these steps Go to <b>{`Apps > Search API Keys > click Launch`}</b> and API keys code will be visible on site. Update/Replace these API keys here.</p>
                        <p> If you don’t have an account then you can access link <b><a href="https://fattmerchant.com/home/sign-up/" target="_blank" rel="noopener noreferrer">SIGN UP</a></b> . Once you update required information, fill-up form details and follow the process as Stax site guides you. Once sign up process complete your account will be created successfully. Repeat above steps to update API Keys. Still, if you face any issue contact DoodyCalls support team.</p>

                    </div>
                    <div className="form-group" style={{ marginLeft: '30px', marginBottom: '30px' }}>
                        <button className="btn btn-warning">Stax is connected already</button>
                        <button className="btn btn-primary" onClick={''}> Save </button>
                    </div>
                </div> */}
                <div className="card mb-20 stripe_Card">
                    {/* <div className="card-body">
                        <img src={optimoroute} alt="" className="stripe_logo" />

                        <p>To enable the API and generate the API key please log into the OptimoRoute web application. In the Administration section select <b> Settings &gt; WS API</b> and enable the API. The API key will be generated for you.</p>

                        <div className="form-group">
                            {state.isOptimorouteConnected ?
                                <React.Fragment>
                                    <button className="btn btn-warning">Optimoroute is already connected</button>
                                    <button className="btn btn-primary" onClick={() => {

                                    }}>Reconnect</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="fitem">
                                        <label className="error-color">OptimoRoute API Key </label>
                                        <input
                                            type="text"
                                            placeholder="Your secret token"
                                            value={state.optimoroute_api_key_value}
                                            onChange={(e) => this.setState({
                                                optimoroute_api_key_value: e.target.value
                                            })} />

                                        {this.state.errors['optimoroute_api_key'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{this.state.errors['optimoroute_api_key']}</label> : ''}
                                    </div>
                                    <button className="btn btn-primary" onClick={('')}>Save Optimoroute API </button> </React.Fragment>}
                        </div>
                    </div> */}
        </div>
        <div className="card mb-20 stripe_Card">
          {/* <div className="card-body">
                        <img src={pulsem} alt="" className="stripe_logo" />
                        <p>To enable the API and generate the API key </p>
                        <ul className="quickbook_list">
                            <li>Contact <b>support@pulsem.me</b> to request your API access.</li>
                            <li>You will be provided with your <b>PulseM API key</b> and <b>Location ID</b> information.</li>
                            <li>Once received, please add the keys to their input fields and select Save PulseM API.</li>
                        </ul>

                        <div className="form-group">

                            <>
                                <div className="fitem">
                                    <label className="error-color">Authorization Access Token </label>
                                    <input type="text" placeholder="Your Authorization Access Token" />
                                    {state.errors['pulsem_token'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{state.errors['pulsem_token']}</label> : ''}
                                </div>
                                <div className="fitem">
                                    <label className="error-color">Location ID </label>
                                    <input type="text" placeholder="Your Location ID" />
                                    <label className="new_err_msg"><i className="las la-info-circle"></i></label>
                                </div>
                            </>

                        </div>
                        <div className="form-group">

                            <button className="btn btn-warning">PulseM Is Already Connected</button>

                            <button className="btn btn-primary" onClick={''}>Save PulseM API</button>                            
                        </div>
                    </div>   delete_user_modal*/}
        </div>
      </div>
      <Modal
        visible={is_quickbooks_modal}
        okText={"Connect"}
        cancelText={"Cancel"}
        onOk={(e) => onSetupClick(e)}
        onCancel={() => {
          setState({ ...state, is_quickbooks_modal: false });
        }}
        className="quickbook_setup_modal"
        closable={false}
      >
        <div className="p15">
          <h4>Quickbooks Connect</h4>
          <div className="form-group">
            <div className="fitem">
              <div className="fitem">
                <label>Client ID</label>
                <input
                  type="text"
                  name="city"
                  autoComplete="franchise_city"
                  value={state.quickbooks_client_id}
                  onChange={(e) => {
                    setState({
                      ...state,
                      quickbooks_client_id: e.target.value,
                    });
                  }}
                  placeholder="Client ID"
                />
                {errors["quickbooks_client_secret"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["quickbooks_client_secret"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="fitem">
              <div className="fitem">
                <label>Client Secret</label>
                <Input
                  value={state.quickbooks_client_secret}
                  onChange={(e) => {
                    setState({
                      ...state,
                      quickbooks_client_secret: e.target.value,
                    });
                  }}
                  placeholder="Client Secret"
                />
                {errors["quickbooks_client_secret"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["quickbooks_client_secret"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="fitem">
              <div className="fitem">
                <label>Environment</label>
                <Select
                  name="quickbooks_environment"
                  value={state.quickbooks_environment || undefined}
                  onChange={(e) => {
                    setState({
                      ...state,
                      quickbooks_environment: e,
                    });
                  }}
                  placeholder="Environment"
                  defaultValue="Select Environment"
                >
                  <Option value="sandbox">Sandbox</Option>
                  <Option value="production">Production</Option>
                </Select>
                {errors["quickbooks_environment"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["quickbooks_environment"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {loader ? <Loader /> : ""}
    </div>
  );
};

export default SetupOptions;
