import React, { useState, useEffect, useCallback } from "react";
import { Line, G2 } from "@ant-design/plots";
import moment from "moment-timezone";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import { getDashboardServiceTrend } from "../../../Action/Dashboard";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";
import { createCurrentFramePeriod } from "../../Common/creationFramePeriod.function";

const RevenueTrend = () => {
  const [revenueTrendMetrics, setRevenueTrendMetrics] = useState([]);
  const { franchise, period, periodType } = useDashboard();

  const getRevenueTrendMetrics = useCallback(async () => {
    const data = {
      ...createCurrentFramePeriod(periodType),
      franchise_filter: franchise,
      period_filter:
        periodType === "day" || periodType === "today" ? "week" : periodType,
      created_at__gte_prev: undefined,
      created_at__lte_prev: undefined,
    };

    const response = await getDashboardServiceTrend(data);
    if (response.status !== 200) {
      return;
    }

    const process_dashboard_metrics = response.data;
    setRevenueTrendMetrics([
      ...process_dashboard_metrics.reduce((result, { service, trend }) => {
        const haveData = trend.reduce((prev, { revenue }) => prev + revenue, 0);
        if (haveData !== 0)
          return result.concat(
            trend.reduce(
              (last, tag) => [...last, { service, ...tag, ...tag.period }],
              []
            )
          );
        return result;
      }, []),
    ]);
  }, [period, franchise, periodType, setRevenueTrendMetrics]);

  useEffect(() => {
    getRevenueTrendMetrics();
  }, [getRevenueTrendMetrics]);

  G2.registerShape("point", "breath-point", {
    draw(cfg, container) {
      const data = cfg.data;
      const point = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();
      if (data.period === moment().format("dddd")) {
        group.addShape("circle", {
          attrs: {
            x: point.x,
            y: point.y,
            r: 5,
            fill: cfg.color,
          },
        });
      }

      return group;
    },
  });
  const idG2Point =
    periodType === "day" || periodType === "today"
      ? {
          shape: "breath-point",
        }
      : true;

  const config = {
    data: revenueTrendMetrics,
    xField: "period",
    yField: "revenue",
    seriesField: "service",
    color: ["#FF681D", "#005D89", "#4FCFF6", "#758F9A", "7E7D7D"],
    tooltip: {
      formatter: ({ service, revenue }) => ({
        name:
          service.charAt(0).toUpperCase() + service.slice(1).replace("_", " "),
        value: `$ ${formatToCurrency(revenue)}`,
      }),
    },
    yAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
        formatter: (v) => `$${formatToCurrency(v)}`,
      },
    },
    xAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
      },
    },
    legend: {
      position: "bottom",
      itemName: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text, item, index) =>
          text.charAt(0).toUpperCase() + text.slice(1).replace("_", " "),
      },
    },
    smooth: false,
    point: idG2Point,
  };

  return (
    <>
      {revenueTrendMetrics.length > 0 ? (
        <Line {...config} />
      ) : (
        <>
          <div className="no-data-component"></div>
          <div className="no-data-title">
            <div>Total</div>
          </div>
          <div className="no-data-content">
            <div>0</div>
          </div>
        </>
      )}
    </>
  );
};

export default RevenueTrend;
