import React, { useState, useEffect } from "react";
import FilterHeader from "./FilterHeader.Container";
import GoogleMapRender from "./GoogleMap.Container";

const PlotJobMapContainer = () => {
  const [jobs, setJobs] = useState([]);
  const [selections, setSelections] = useState([]);
  const [view, setView] = useState("day");
  const [getJobs, setGetJobs] = useState(false);
  const [franchise, setFranchise] = useState({});

  return (
    <div className="block-row">
      <h1>Plot Jobs on Map</h1>
      <FilterHeader setJobs={setJobs} setSelections={setSelections} setView={setView} getJobs={getJobs} setGetJobs={setGetJobs} setFranchise={setFranchise}></FilterHeader>
      <div className="block-row plot-jobs">
        <GoogleMapRender jobs={jobs} selections={selections} view={view} setGetJobs={setGetJobs} franchise={franchise}></GoogleMapRender>
      </div>
    </div>
  );
};

export default PlotJobMapContainer;
