import React from "react";
import moment from "moment-timezone";
import { formatToCurrency } from "../../Components/Common/formatToCurrency";


const SERVICE_STATUS_COLOR_CLASS = {
  cancelled: "gray-status",
  Active: "success-status",
};

class DataTable {
  //Price list table
  PriceListTable = (page) => {
    return [
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{''}</b>)
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{''}</b>)
      },
      {
        title: 'Repair With Estimated Tax',
        dataIndex: 'RepairWET',
        key: 'RepairWET',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{''}</b>)
      },
    ]
  }
  //Franchise alert list
  franchiseAlertTable = (page) => {
    return [
      {
        title: 'Franchise Alert',
        dataIndex: 'Falert',
        key: 'Falert',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record.Falert}</b>)
      },
      {
        title: 'Description',
        key: 'description',
        sorter: false,
        render: (text, record) => (
          <b className="wrap">{record.description}</b >)
      },
      {
        title: 'Start Date',
        key: 'startDate',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record.startDate}</b>)
      },
      {
        title: 'End Date',
        key: 'EndDate',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record.EndDate}</b>)
      },
      {
        title: 'Status',
        key: 'status',
        sorter: false,
        render: (text, record) => (
          <span className={record.status ? `status ${SERVICE_STATUS_COLOR_CLASS[record.status]}`: ''}>{record.status}</span>
          )
      },
     
     
      ]
    }

  //Franchise List
  AllFranchiseList = (self) => {
    const franchiseColumns = [
      {
        title: "ID & Name",
        dataIndex: "name",
        key: "name",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">            
            {("00" + parseInt(record.id)).slice(-3)} {record.name}
          </span>
        ),
      },
      {
        title: "Owner",
        dataIndex: "owner",
        key: "owner",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            {record?.owner?.first_name} {record?.owner?.last_name}
          </span>
        ),
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        sorter: true,
        showSorterTooltip: false,
        className: "taddress",
        render: (text, record) =>{                    
          const address =
            `${record?.address || ""}, 
            ${record?.city || ""}, ${record?.state || ""} 
            ${record?.zip_code || ""}`
            ;
          return (
            <span className="type ellipse">{address}</span>
          )
        }
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
        sorter: true,
        showSorterTooltip: false,
        className: "tprice",
        render: (text) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Customers",
        dataIndex: "customers",
        key: "customers",
        className: "tprice",
        render: (text) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Monthly Revenue",
        dataIndex: "monthly_revenue",
        key: "monthly_revenue",
        className: "tprice",
        render: (text) => (
          <span className="type ellipse">$ {text ? formatToCurrency(text) : "-"}</span>
        ),
      },
      {
        title: "Yearly Revenue",
        dataIndex: "yearly_revenue",
        key: "yearly_revenue",
        className: "tprice",
        render: (text) => (
          <span className="type ellipse">$ {text ? formatToCurrency(text) : "-"}</span>
        ),
      },
      {
        title: "All Time Revenue",
        dataIndex: "all_time_revenue",
        key: "all_time_revenue",
        className: "tname",
        render: (text) => (
          <span className="type ellipse">$ {text ? formatToCurrency(text) : "-"}</span>
        ),
      },
      {
        title: "Satisfaction",
        dataIndex: "satisfaction",
        key: "satisfaction",
        className: "tprice",
        render: (text) => (
          <span className="type ellipse">{text ? text + "%" : "-"}</span>
        ),
      },
      {
        title: "Year Started",
        dataIndex: "invoice_date",
        key: "invoice_date",
        className: "tdate",
        render: (text) => (
          <span className="type ellipse">
            {text ? moment(text).format("YYYY") : "-"}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "close",
        key: "close",
        className: "tstatus",
        render: (text, record) => (
          <span className="type">
            {record.close ? (
              <b style={{ color: "#d12619" }}>Close</b>
            ) : (
              <b className="text-successs">Open</b>
            )}
          </span>
        ),
      },
      {
        title: "",
        dataIndex: "uuid",
        key: "uuid",
        className: "moreaction",
        render: (text, record) => (
          <div
            className="table_action_wrapper cursor_pointer"
            ref={(node) => {
              self.node = node;
            }}
          >
            <span
              className={
                self.isNav === record.uuid && self.openNav
                  ? "las la-ellipsis-v las_success"
                  : "las la-ellipsis-v"
              }
              onClick={(e) => {
                e.stopPropagation();
                self.ShowNav(record, text);
              }}
            ></span>
            <ul
              className={
                self.isNav === record.uuid && self.openNav
                  ? "table_Action_menu"
                  : "d-none"
              }
            >
              <li>
                <span>
                  View
                </span>
              </li>
              <li
                disabled={self.is_crud_allow ? false : true}
                className={self.is_crud_allow ? "" : "disbale_btn"}
              >
                <span>
                  Edit
                </span>
              </li>

              <li
                disabled={self.is_crud_allow ? false : true}
                className={self.is_crud_allow ? "" : "disbale_btn"}
              >
                {record.close ? (
                  <span>
                    Open
                  </span>
                ) : (
                  <span>
                    Close
                  </span>
                )}
              </li>
            </ul>
          </div>
        ),
      },
    ];
    return franchiseColumns;
  }
};

export default new DataTable();