import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Row, Col, Modal, message, Button } from "antd";
import AddTaskForm from "./SubComponents/AddTaskForm";
import "../../../Assets/css/addNewTaskModal.css";
import { createTaskHelper } from "../../helper/listHelper";
import { EventBus } from "../../../Components/EventBus/eventBus";
import {
  toggleTaskModal,
  resetState,
} from "../../../Store/technicianTaskDataSlice";

const AddTask = () => {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.technicianTaskJourney);
  const [disableSave, setDisableSave] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const validate = () => {
    setConfirmLoading(true);
    const taskData = tasks;
    createTaskHelper(taskData)
      .then((res) => {
        if (res?.task_name || res[0]?.task_name) {
          message.success(`Task added successfully`, 5);
          dispatch(toggleTaskModal(false));
          EventBus.emit("TASK_CREATED");
          setConfirmLoading(false);
        } else {
          message.error("Task is not added, please try again.");
          dispatch(toggleTaskModal(false));
          setConfirmLoading(false);
        }
        dispatch(resetState());
      })
      .catch((error) => {
        console.error(error);
        message.error("Task is not added, please try again.");
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    dispatch(resetState());
    dispatch(toggleTaskModal(false));
  };

  useEffect(() => {
    if (
      tasks.selected_category_value !== "" &&
      tasks.selected_task_name &&
      tasks.date &&
      tasks.duration
    ) {
      setDisableSave(false);
    } else if (tasks.showTaskFromEdit === true) {
      if (
        ((tasks.selected_task_name == "" &&
          tasks?.selectedTaskData?.title !== "") ||
          tasks.selected_task_name) &&
        tasks.selected_category_value !== "" &&
        tasks.date &&
        tasks.duration
      )
        setDisableSave(false);
      else setDisableSave(true);
    } else {
      setDisableSave(true);
    }
  }, [tasks]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Modal
            visible={tasks.showTaskModal}
            title={
              tasks.showTaskFromEdit ? <h3>Edit Task</h3> : <h3>Add Task</h3>
            }
            closable={false}
            className="addNewTaskModal"
            destroyOnClose={true}
            footer={[
              <Button
                key="cancel"
                className="btn btn-close"
                onClick={handleCancel}
                disabled={confirmLoading}
              >
                Cancel
              </Button>,
              <Button
                key="save"
                className={`btn btn-warning btnAddListSubmit`}
                onClick={() => validate()}
                disabled={disableSave}
                loading={confirmLoading}
              >
                Save
              </Button>,
            ]}
          >
            <AddTaskForm />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default AddTask;
