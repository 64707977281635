import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { useState, useEffect } from "react";
import Compressor from 'compressorjs';

const FileUploader = (props) => {
  const { Dragger } = Upload;
  const [fileList, setfileList] = useState(props.defaultFileList);

  useEffect(() => {
    setfileList(props.defaultFileList);
  }, [props.defaultFileList]);

  const propsUploader = {
    name: "file",
    multiple: true,
    listType: "picture",
    onChange: async(info) => {
      const file = info.file;
      if(file.status !== "removed"){        
        if (file?.type?.includes("image"))  
          await compressImage(info);
        else
          await encodeFile(info);
      }
    },    

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },

    beforeUpload() {
      return false;
    },
  };

  async function compressImage(info) {
    const file = info.file;
    new Compressor(file, {
      quality: 0.7,
      maxHeight: "800",
      maxWidth: "800",
      retainExif: true, 
      mimeType: "image/jpeg",
      async success(result) {
        info.file = new File([result], info.file.name, {
          type: result.type,
        });
        info.file.uid = file.uid;        
        await encodeFile(info);
      },
      error(err) {
        console.log(err.message);
      },
    });
  }

  async function encodeFile(info){
    const { status } = info.file;
    const file = info.file;
    
    props.getFiles({
      uid: file.uid,
      name: file.name,
      image: file,
    });

    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }

    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  const onRemoveFile = (e) => {
    props.onRemoveFile(e);
  };

  return (
    <Dragger
      {...propsUploader}
      defaultFileList={fileList}
      onRemove={(e, index) => onRemoveFile(e, index)}
      disabled={props.edit ?? false}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag you file(s) to this area to upload
      </p>
    </Dragger>
  );
};

export default FileUploader;
