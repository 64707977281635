import React, { Component, useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { map } from 'lodash';
import {errorHandle} from "../Components/errorHandle";
import { getStatusClassNames } from 'antd/lib/_util/statusUtils';
import { addNewGroup } from '../Action/User';
const { Option } = Select;


// mapping role for hardcoded
const ROLE_MAPPING = {
  franchise_owner: "Franchise Owner",
  field_tech: "Field Tech",
  customer: "Customer",
  lead: "Lead",
  commercial_customer: "Commercial Customer",
  commercial_lead: "Commercial Lead",
  business_dev_franchiese: 'Business Dev Franchiese',
  accounting_franchiese: 'Accounting Franchise',
  accounting_corporate: 'Accounting Corporate',
  service_rep: "Call Center",
  operations_mgr: "Operations Manager",
  admin: "Corporate",
};

const AddGroup = (props) => {
const [state,setState] = useState(
  {
    group: {
      name: '',
      status: 'active',
      description: '',
      franchise_uuid: ''
    },
    users: [],
    errors: {}
  }
)

function getState(){
  setState({
    group: {
      name: '',
      status: 'active',
      description: '',
      franchise_uuid: ''
    },
    users: [],
    errors: {}
  })
}

useEffect(()=>{
    getState();
    const {group} = state;
    const {franchise_uuid, is_disable_franchise} = getLoggedInUserRole();
    if (is_disable_franchise) Object.assign(group, {franchise_uuid})
    setState((state)=>({...state,group}))
},[])

const postNewGroup = async (value) => {
  const data = await addNewGroup(value);
  if(data.uuid){
    setState((state)=>({
            ...state,
            group: {
              name: '',
              status: '',
              description: '',
              franchise_uuid: ''
            },
            users: [],
            loader: false
          }))
  message.success("Group Added Successfully..!",10);  
  props.groupDrawerToggle(false)
  //Get Group List
  props.getGroupList({ 'offset': 0, 'limit': 15 });
  }
}


  // UNSAFE_componentWillReceiveProps = (props) => {
  //   // add new group
  //   // message.destroy();
  //   if (Object.keys(props.addGroup.data).length > 0 && props.addGroup.data !== this.props.addGroup.data) {

  //     this.setState({
  //       group: {
  //         name: '',
  //         status: '',
  //         description: '',
  //         franchise_uuid: ''
  //       },
  //       users: [],
  //       loader: false
  //     })

  //     this.props.groupDrawerToggle(false)
  //     //Get Group List
  //     this.props.getGroupList({ 'offset': 0, 'limit': 15 });
  //   }

  //   if (Object.keys(props.addGroup.errors).length > 0 && props.addGroup.errors !== this.props.addGroup.errors) {
  //     // display error messsage
  //     this.setState({ loader: false })
  //     errorHandle(props?.addGroup?.errors)
  //   }
  // }

  // get logged in user
  const getLoggedInUserRole = () => {
    let authUser = JSON.parse(localStorage.getItem('authUser'))
    const logged_in_user_role = authUser?.user?.user_roles?.map(x => x.name)

    const is_franchise_owner = logged_in_user_role?.includes('franchise_owner') || logged_in_user_role?.includes('operations_mgr')
    // const is_account_corporate = logged_in_user_role?.includes('accounting_corporate')
    const is_business_dev_franchiese = logged_in_user_role?.includes('business_dev_franchiese')
    const is_operations_mgr = logged_in_user_role?.includes('operations_mgr')
    // const is_field_tech = logged_in_user_role?.includes('field_tech')
    const franchise_uuid = authUser?.user?.field_offices?.map(x => x.uuid);
    return {franchise_uuid: franchise_uuid?.[0], is_disable_franchise: (is_franchise_owner || is_business_dev_franchiese || is_operations_mgr)}
  }

  // Set Group value
  const onGroupChange = (e) => {
    let { group } = state;
    group = Object.assign(group, { [e.target.name]: e.target.value });
    setState((state)=>({
      ...state,
      group
    }))
  }

  // Render User
  const renderUser = () => {
    return map(props.userList, (item, i) => {
      return (
        <Option key={i} value={JSON.stringify(item)}>
          {`${item?.first_name || ''} ${item?.last_name || ''}, ${item?.role ? ROLE_MAPPING[item.role] : ''}`}
        </Option>
      )
    })
  }

  //Set user value
  const onUserChange = (a) => {
    let { users } = state;

    let temp_user = JSON.parse(a);

    if (users.length > 0) {
      let index = users.findIndex((item) => { return item.uuid === temp_user.uuid })
      if (index > -1) {
        users[index]['uuid'] = temp_user.uuid;
        users[index]['first_name'] = temp_user.first_name;
        users[index]['last_name'] = temp_user.last_name;
        users[index]['role'] = temp_user.role;
      }
      else {
        users.push(
          {
            uuid: temp_user.uuid,
            first_name: temp_user.first_name,
            last_name: temp_user.last_name,
            role: temp_user.role
          }
        )
      }
    }
    else {
      users.push(
        {
          uuid: temp_user.uuid,
          first_name: temp_user.first_name,
          last_name: temp_user.last_name,
          role: temp_user.role
        }
      )

    }

    setState((state)=>({
      ...state,
      users
    }))

  }
  //search user
  const userSearch = (e) => {
    if(state.group?.franchise_uuid !== 'All Franchise'){
      props.getUserList({ 'q': e, 'is_group_call': true, staff_profile__field_office__uuid: state.group?.franchise_uuid || '' })
    } else {
      props.getUserList({ 'q': e, user_roles__name: 'service_rep' })
    }
  }

  // Render Selected user

  const renderSelectedUser = () => {
    let { users } = state;

    return map(users.length > 0 && users, (item, i) => {
      return (
        <div className="user" key={i}>
          <p>{`${item.first_name} ${item.last_name},  ${item.role.length > 0 ? ROLE_MAPPING[item.role.join(',')] : ''}`}</p>
          <span onClick={(e) => { removeUser(e, item); }}><i className="las la-minus-circle"></i></span>
        </div>
      )
    })
  }

  // remove user
  const removeUser = (e, id) => {
    e.preventDefault();
    const { users } = state;
    var index = users.indexOf(id);
    users.splice(index, 1);
    setState((state)=>({
      ...state,
      users
    }))
  }

  //Add new Group
  const addNewGroupData = () => {
    let { users, group, errors } = state;
    let temp_data = [];
    if (!group.name) {
      errors.name = 'Name is required'
    }
    else {
      delete errors.name;
    }
    if (!group.description) {
      errors.description = 'Description is required'
    }
    else {
      delete errors.description;
    }

    // if (!group.franchise_uuid) {
    //   errors.franchise_uuid = 'Franchise is required'
    // }
    // else {
    //   delete errors.franchise_uuid;
    // }
    delete errors.franchise_uuid;

    if (group.name && group.description && group.franchise_uuid) {
      if (group.name.toString().length > 20) {
        errors.name = "Name is no more than 20 characters"
      }
      else {
        delete errors.name;
        map(users, (item) => {
          temp_data.push({ uuid: item.uuid })
        })

        let data = {
          ...group,
          users: []
        }

        if (data.franchise_uuid === 'All Franchise'){
          data.franchise_uuid = null;
          data.is_public = true;
        }

        //add new group api call
        postNewGroup(data);
        setState((state)=>({
          ...state,
          loader: true
        }))
      }

    }

    setState((state)=>({
      ...state,
      errors
    }))
  }

    let { group, loader, errors } = state;
    const {franchiseList} = props;
    const {is_disable_franchise} = getLoggedInUserRole()

    return (
      <div className="group_wrapper rdrawer">
        <div className="card-row">
          <div className="card-full">
            <h5>Add Group</h5>
            <div className="form-group">
              <div className="fitem">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={group.name}
                  onChange={(e) => { onGroupChange(e) }}
                />
                {errors['name'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['name']}</label> : ''}
              </div>

              <div className="fitem">
                <label htmlFor="">Description</label>
                <textarea
                  name="description"
                  rows="4"
                  value={group.description}
                  onChange={(e) => { onGroupChange(e) }}
                  placeholder="Describe the purpose of group"></textarea>
                {errors['description'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['description']}</label> : ''}
              </div>
              <div className="fitem">
                <label htmlFor="">Franchise</label>
                <Select
                  value={group?.franchise_uuid || undefined}
                  style={{ 'width': '100%' }}
                  placeholder="All Franchise"
                  onChange={(e) => {
                    let { group } = state;
                    group = Object.assign(group, { 'franchise_uuid': e });
                    setState((state)=>({...state, group }));
                      if(group?.franchise_uuid !== 'All Franchise'){
                        props.getUserList({ 'offset': 0, 'limit': 15, 'is_group_call': true, staff_profile__field_office__uuid: group?.franchise_uuid || '' });
                      } else {
                        props.getUserList({ 'offset': 0, 'limit': 15, user_roles__name: 'service_rep' });
                      }
                  }}
                  disabled={is_disable_franchise ? true : false}
                >
                  <Option value="All Franchise">All Franchise</Option>
                  {map(franchiseList, (item, i) => {
                    return (
                      <Option key={i} value={item.uuid}>{item.name}</Option>
                    )
                  })}

                </Select>
                {errors['franchise_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['franchise_uuid']}</label> : ''}
              </div>
              <div className="fitem">
                <label htmlFor="">Status</label>
                <Select
                  defaultValue='active'
                  value={group.status}
                  placeholder="Status"
                  onChange={(e) => {
                    let { group } = state
                    group = Object.assign(group, { 'status': e })
                    setState((state)=>({
                      ...state,
                      group
                    }))
                  }}
                >
                  <Option value='active'>Active</Option>
                  <Option value='inactive'>InActive</Option>
                </Select>
              </div>
              <div className="fitem">
                <label htmlFor="">Users</label>
                <Select
                  showSearch
                  showArrow={true}
                  filterOption={true}
                  notFoundContent={null}
                  value=""
                  suffixIcon={<i className="las la-search"></i>}
                  placeholder="Search proposal"
                  onChange={(e, b) => { onUserChange(e, b) }}
                  onSearch={(e) => { userSearch(e) }}
                  onBlur={() => {
                    if(group?.franchise_uuid !== 'All Franchise'){
                      props.getUserList({ 'offset': 0, 'limit': 15, 'is_group_call': true, staff_profile__field_office__uuid: group?.franchise_uuid || '' });
                    } else {
                      props.getUserList({ 'offset': 0, 'limit': 15, user_roles__name: 'service_rep' });
                    }
                  }}
                  className="ant-licon search-input"
                >

                  {renderUser()}
                </Select>
                <div className="active_user_list">
                  {renderSelectedUser()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <button
            className="btn btn-primary"
            onClick={() => {
              setState((state)=>({
                ...state,
                group: {
                  name: '',
                  status: 'active',
                  description: '',
                  franchise_uuid: ''
                },
                users: [],
                errors : {}
              }))
              props.groupDrawerToggle(false)
            }}
          >Cancel</button>
          <button
            onClick={() => { addNewGroupData() }}
            className='btn btn-warning'
          >
            Add Group</button>
        </div>
        {
          loader ?
            <div className='loader_wrapper'>
              <div className='loader07' ></div>
            </div>
            : ''
        }
      </div>
    );
  }
export default AddGroup;