import React, { useEffect, useState } from "react";
import { _, isEmpty } from "lodash";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HeaderMobile from "../../Components/HeaderMobile";
import { Header } from "antd/lib/layout/layout";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import Footer from "../Residential/NewCustomer/Footer";
import Loader from "../../Components/Loader";
import ServiceOrderInfo from "./ServiceOrderInfo";
import {
  updateLead,
  convertLeadToCustomer,
  updateLeadConvertCustomer,
  getServiceOrderLeadById,
} from "../../Action/Customer";
import BillingInfoLead from "./BillingInfoLead";
import ServiceSummaryLead from "./ServiceSummaryLead";
import { message } from "antd";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { resetValues } from "../../Store/serviceOrderDetailDataSlice";
import StaxModal from "../Common/Stax.Modal";
import StaxModalACH from "../Common/StaxACH.Modal";

const ServiceOrderLead = () => {
  const billingInfoFunc = React.useRef(null);
  const serviceAreaFunc = React.useRef(null);
  const validateBaseJobReq = React.useRef(null);
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [dataServiceOrder, setDataServiceOrder] = useState([]);
  const [priceDetail, setPriceDetail] = useState({});
  const [serviceInfo, setServiceInfo] = useState({
    address: {
      value: (state.leadData?.address?.length > 1 ? state.leadData?.address[1] : state.leadData?.address[0]),
      key: "",
      name: "address",
    },
    billing_address: {
      value: (state.leadData.invoice_address || state.data?.[0]?.billing_address) ?? {},
      key: "",
      name: "billing_address",
    },
    pool_size: {
      value: state.data?.[0]?.pool_size ?? 0,
      key: state.data?.[0]?.pool_size ?? "",
      name: "pool_size",
    },
    pool_name: {
      value: state.data?.[0]?.pool_name ?? 0,
      key: state.data?.[0]?.pool_name ?? "",
      name: "pool_name",
    },
    frequency_settings: {
      value: state.data?.[0]?.frequency_settings ?? {},
      key: "custom _frequency",
      name: "frequency_settings",
    },
    maintenanceCategory: {
      value: state.data?.[0]?.is_flat_rate ? "Flat_Rate" : "Standard",
      key: "Flat_Rate",
      name: "maintenanceCategory",
    },
    service_type_uuid: {
      value: state.data?.[0]?.service_type_uuid ?? 0,
      key: state.data?.[0]?.service_type ?? "",
      name: "service_type_uuid",
    },
    is_service: {
      value: state.data?.[0]?.is_service ?? false,
      key: state.data?.[0]?.is_service ?? false,
      name: "is_service",
    },
    preferred_start_date: {
      value: state.data?.[0]?.preferred_start_date ?? {},
      key: "preferred_start_date",
      name: "preferred_start_date",
    },
    serviceType: {
      value: state.data?.[0]?.service_type_uuid ?? "",
      key: state.data?.[0]?.service_type ?? "",
      name: "serviceType",
    },
    notes: {
      value: state.data?.[0]?.notes ?? "",
      key: state.data?.[0]?.notes ?? "",
      name: "notes",
    },
    pool_characteristic: {
      value: state.data?.[0]?.pool_characteristic ?? {},
      key: "pool_characteristic",
      name: "pool_characteristic",
    },
    time: {
      value: state.data?.[0]?.time ?? moment("8:00", "HH:mm"),
      key: "time",
      name: "time",
    },
    payment_method: {
      value: state.data?.[0]?.payment_method ?? "",
      key: "payment_method",
      name: "payment_method"
    },
  });
  const [btnLabel, setBtnLabel] = useState("Next: GO TO BILLING");
  const [currentTab, setCurrentTab] = useState(0);
  const [openStax, setOpenStax] = useState(false);
  const [openStaxModalACH, setOpenStaxModalACH] = useState(false);
  const navigate = useNavigate();
  const getLeadServiceOrderDetail = async (param) => {
    let data = await getServiceOrderLeadById(param);
    setDataServiceOrder(data);
    setLoader(false);
  };
  const serviceOrderNewFieldsValue = useSelector(
    (state) => state.serviceOrderAddedData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (state.data.length > 0 && state.data?.[0].uuid)
      getLeadServiceOrderDetail({
        field_office_uuid: state.leadData.field_office.uuid,
        client_uuid: state.leadData.uuid,
      });
  }, []);
  const renderTabs = () => {
    switch (currentTab) {
      case 0:
        return (
          <ServiceOrderInfo
            ref={validateBaseJobReq}
            serviceOrderData={state.data}
            serviceTypeData={state.serviceTypeData}
            setServiceInfoData={setServiceInfoData}
            setPriceDetailData={setPriceDetailData}
            leadData={state.leadData}
          />
        );
      case 1:
        return (
          <BillingInfoLead
            ref={billingInfoFunc}
            uuid={state.data.uuid}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            serviceInfo={serviceInfo}
            data={state.serviceTypeData.results[0]}
            leadData={state.leadData}
            dataServiceOrder={dataServiceOrder}
            setServiceInfoList={setServiceInfoList}
          />
        );
      default:
        return (
          <ServiceOrderInfo
            ref={validateBaseJobReq}
            serviceOrderData={state.data}
            serviceTypeData={state.serviceTypeData}
            setServiceInfoData={setServiceInfoData}
            setPriceDetailData={setPriceDetailData}
          />
        );
    }
  };
  const setPriceDetailData = (data) => {
    setPriceDetail(data);
  };

  const setServiceInfoList = (list) => {
    let obj = { ...serviceInfo };
    list.forEach((element) => {
      obj[element.name] = element;
    });

    setServiceInfo(obj);
  };

  const setServiceInfoData = (data) => {
    let obj = { ...serviceInfo };
    obj[data.name] = data;
    setServiceInfo(obj);
  };

  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };
  const validate = (action, staxProps) => {
    if (action === "save") {
      if (currentTab === 0) {
        validateBaseJobReq.current.validate().then((res) => {
          if (res === "success") {
            setLoader(true);
            let obj = {};
            obj.field_office_uuid = state.leadData.field_office.uuid || "";
            obj.is_billing_address_same =
              serviceInfo.is_billing_address_same?.value || true;
            obj.client_uuid = state.leadData.uuid || "";
            obj.is_service =
              serviceInfo.serviceType.key.toLowerCase() === "maintenance"
                ? false
                : true;
            obj.proposal_date = moment().format("YYYY-MM-DD");
            obj.contract_date = moment().format("YYYY-MM-DD");
            if (
              serviceInfo.worksubtype?.value &&
              serviceInfo.service_type_uuid.key.toLowerCase() !==
                "maintenance" &&
              (serviceInfo.service.key === "Diagnostic" ||
                serviceInfo.service.key === "pool inspection")
            ) {
              obj.service_type_uuid = serviceInfo.worksubtype.value;
            } else if (
              serviceInfo.service?.value &&
              serviceInfo.service_type_uuid.key.toLowerCase() !== "maintenance"
            ) {
              obj.service_type_uuid = serviceInfo.service.value;
            } else {
              obj.service_type_uuid = serviceInfo.service_type_uuid.value;
            }

            if (
              serviceInfo.service_type_uuid.key.toLowerCase() ===
                "maintenance" ||
              serviceInfo.service_type_uuid.key.toLowerCase() === "service"
            ) {
              obj.frequency_settings = serviceInfo.frequency_settings?.value;
              if (serviceInfo?.time?.value)
                obj.preferred_start_time =
                  serviceInfo?.time?.value <= 0
                    ? ""
                    : moment(serviceInfo?.time.value).format("hh:mm") || "";
            }
            // obj.service_type_uuid = serviceInfo.service_type_uuid?.value;
            obj.pool_type_uuid = serviceInfo.pool_type_uuid?.value;
            obj.pool_size = serviceInfo.pool_size?.value;
            obj.preferred_contact_methods =
              serviceInfo.preferred_contact_methods?.value;
            obj.payment_method = serviceInfo.payment_method?.value;
            obj.charge_invoice_card = serviceInfo.charge_invoice_card?.value || false;
            obj.is_flat_rate =
              serviceInfo.maintenanceCategory.value === "Flat_Rate"
                ? true
                : false;
            obj.price = priceDetail?.price;
            obj.service_price = priceDetail?.price;
            obj.pool_name = serviceInfo.pool_name?.value;
            obj.is_enquiry = true;
            obj.service_address = serviceInfo.address?.value;
            obj.billing_address = serviceInfo.billing_address?.value;
            obj.notes = serviceInfo.notes?.value;
            // obj.billing_address = serviceInfo.address?.value;
            obj.stax_card_token = staxProps?.id;
            obj.preferred_start_date =
              moment(serviceInfo.preferred_start_date?.value).format(
                "YYYY-MM-DD"
              ) || moment().format("YYYY-MM-DD");
            obj.is_email_invoice = serviceInfo.invoice_preference?.value.find(
              (i) => i.name === "is_email_invoice"
            ).value;
            obj.is_print_and_mail_invoices =
              serviceInfo.invoice_preference?.value.find(
                (i) => i.name === "is_print_and_mail_invoices"
              ).value;
            obj.schedule_time_range = serviceInfo.time_range?.value;
            obj.service_duration = serviceOrderNewFieldsValue?.duration || "";
            obj.reference_number =
              serviceOrderNewFieldsValue?.referenceNumber || "";
            if (dataServiceOrder.length) {
              const patchServiceOrderLead = async () => {
                const data = await updateLeadConvertCustomer(
                  dataServiceOrder[0].uuid,
                  obj
                );
                if (data?.uuid) {
                  dispatch(resetValues());
                  setLoader(false);
                  message.success("Service Order is updated successfully.");
                  if (state?.source === "customers-lead-list")
                    navigate("/customer-lead-list", {
                      state: { currentTab: "lead" },
                    });
                  else navigate("/commercial/lead-list", { state: { tab: 0 } });
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
              };
              patchServiceOrderLead();
            } else {
              const postServiceOrderLead = async () => {
                obj.client_type = "commercial";
                const data = await convertLeadToCustomer(obj);
                if (data?.uuid) {
                  // setLoader(false);
                  dispatch(resetValues());
                  getLeadServiceOrderDetail(data);
                  message.success("Service Order is created successfully.");
                  if (state?.source === "customers-lead-list")
                    navigate("/customer-lead-list", {
                      state: { currentTab: "lead" },
                    });
                  else navigate("/commercial/lead-list", { state: { tab: 0 } });
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
              };
              postServiceOrderLead();
            }
          }
        });
      } else {
        billingInfoFunc.current.validate("save_lead").then((res) => {
          if (res === "success") {
            setLoader(true);
            let obj = {};
            obj.field_office_uuid = state.leadData.field_office.uuid || "";
            obj.is_billing_address_same =
              serviceInfo.is_billing_address_same?.value || true;
            obj.client_uuid = state.leadData.uuid || "";
            obj.is_service =
              serviceInfo.serviceType.key.toLowerCase() === "maintenance"
                ? false
                : true;
            obj.proposal_date = moment().format("YYYY-MM-DD");
            obj.contract_date = moment().format("YYYY-MM-DD");
            if (
              serviceInfo.worksubtype?.value &&
              serviceInfo.service_type_uuid.key.toLowerCase() !==
                "maintenance" &&
              (serviceInfo.service.key === "Diagnostic" ||
                serviceInfo.service.key === "pool inspection")
            ) {
              obj.service_type_uuid = serviceInfo.worksubtype.value;
            } else if (
              serviceInfo.service?.value &&
              serviceInfo.service_type_uuid.key.toLowerCase() !== "maintenance"
            ) {
              obj.service_type_uuid = serviceInfo.service.value;
            } else {
              obj.service_type_uuid = serviceInfo.service_type_uuid.value;
            }
            if (
              serviceInfo.service_type_uuid.key.toLowerCase() ===
                "maintenance" ||
              serviceInfo.service_type_uuid.key.toLowerCase() === "service"
            ) {
              obj.frequency_settings = serviceInfo.frequency_settings?.value;
              if (serviceInfo?.time?.value)
                obj.preferred_start_time =
                  serviceInfo?.time?.value <= 0
                    ? ""
                    : moment(serviceInfo?.time.value).format("hh:mm") || "";
            }
            obj.pool_type_uuid = serviceInfo.pool_type_uuid?.value;
            obj.pool_size = serviceInfo.pool_size?.value;
            obj.preferred_contact_methods =
              serviceInfo.preferred_contact_methods?.value;
            obj.payment_method = serviceInfo.payment_method?.value;
            obj.charge_invoice_card = serviceInfo.charge_invoice_card?.value || false;
            obj.is_flat_rate =
              serviceInfo.maintenanceCategory.value === "Flat_Rate"
                ? true
                : false;
            obj.price = priceDetail?.price;
            obj.service_price = priceDetail?.price;
            obj.pool_name = serviceInfo.pool_name?.value;
            obj.is_enquiry = true;
            obj.service_address = serviceInfo.address?.value;
            obj.notes = serviceInfo.notes?.value;
            obj.billing_address = serviceInfo.billing_address?.value;
            obj.stax_card_token = staxProps?.id;
            obj.preferred_start_date =
              moment(serviceInfo.preferred_start_date?.value).format(
                "YYYY-MM-DD"
              ) || moment().format("YYYY-MM-DD");
            obj.is_email_invoice = serviceInfo.invoice_preference?.value.find(
              (i) => i.name === "is_email_invoice"
            ).value;
            obj.is_print_and_mail_invoices =
              serviceInfo.invoice_preference?.value.find(
                (i) => i.name === "is_print_and_mail_invoices"
              ).value;
            obj.schedule_time_range = serviceInfo.time_range?.value;
            obj.service_duration = serviceOrderNewFieldsValue?.duration || "";
            obj.reference_number =
              serviceOrderNewFieldsValue?.referenceNumber || "";
            if (dataServiceOrder.length) {
              const patchServiceOrderLead = async () => {
                const data = await updateLeadConvertCustomer(
                  dataServiceOrder[0].uuid,
                  obj
                );
                if (data?.uuid) {
                  dispatch(resetValues());
                  setLoader(false);
                  message.success("Service Order is updated successfully.");
                  if (state?.source === "customers-lead-list")
                    navigate("/customer-lead-list", {
                      state: { currentTab: "lead" },
                    });
                  else navigate("/commercial/lead-list", { state: { tab: 0 } });
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
              };
              patchServiceOrderLead();
            } else {
              const postServiceOrderLead = async () => {
                obj.client_type = "commercial";
                const data = await convertLeadToCustomer(obj);
                if (data?.uuid) {
                  // setLoader(false);
                  dispatch(resetValues());
                  getLeadServiceOrderDetail(data);
                  message.success("Service Order is created successfully.");
                  if (state?.source === "customers-lead-list")
                    navigate("/customer-lead-list", {
                      state: { currentTab: "lead" },
                    });
                  else navigate("/commercial/lead-list", { state: { tab: 0 } });
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
              };
              postServiceOrderLead();
            }
          }
        });
      }
    } else {
      if (currentTab === 0) {
        validateBaseJobReq.current.validate().then((res) => {
          if (res === "success") {
            setCurrentTab(1);
            setBtnLabel("Convert to Customer");
          }
        });
      } else {
        billingInfoFunc.current.validate(undefined, staxProps).then((res) => {
          const primaryEmail = serviceInfo?.serviceArea?.value?.emails.filter(
            (email) => {
              return email.primary;
            }
          );
          if (res === "success") {
            setLoader(true);
            let obj = {};
            obj.field_office_uuid = state.leadData.field_office.uuid || "";
            obj.is_billing_address_same =
              serviceInfo.is_billing_address_same?.value || true;
            obj.client_uuid = state.leadData.uuid || "";
            obj.is_service =
              serviceInfo.serviceType.key.toLowerCase() === "maintenance"
                ? false
                : true;
            obj.proposal_date = moment().format("YYYY-MM-DD");
            obj.contract_date = moment().format("YYYY-MM-DD");
            if (
              serviceInfo.worksubtype?.value &&
              serviceInfo.service_type_uuid.key.toLowerCase() !==
                "maintenance" &&
              (serviceInfo.service.key === "Diagnostic" ||
                serviceInfo.service.key === "pool inspection")
            ) {
              obj.service_type_uuid = serviceInfo.worksubtype.value;
            } else if (
              serviceInfo.service?.value &&
              serviceInfo.service_type_uuid.key.toLowerCase() !== "maintenance"
            ) {
              obj.service_type_uuid = serviceInfo.service.value;
            } else {
              obj.service_type_uuid = serviceInfo.service_type_uuid.value;
            }
            if (
              serviceInfo.service_type_uuid?.key.toLowerCase() ===
                "maintenance" ||
              serviceInfo.service_type_uuid?.key.toLowerCase() === "service"
            ) {
              obj.frequency_settings = serviceInfo.frequency_settings?.value;
              if (serviceInfo?.time?.value)
                obj.preferred_start_time =
                  serviceInfo?.time?.value <= 0
                    ? ""
                    : moment(serviceInfo?.time.value).format("hh:mm") || "";
            }
            obj.pool_type_uuid = serviceInfo.pool_type_uuid?.value;
            obj.pool_size = serviceInfo.pool_size?.value;
            obj.preferred_contact_methods =
              serviceInfo.preferred_contact_methods?.value;
            obj.payment_method = serviceInfo.payment_method?.value;
            obj.charge_invoice_card = serviceInfo.charge_invoice_card?.value || false;
            obj.is_flat_rate =
              serviceInfo.maintenanceCategory.value === "Flat_Rate"
                ? true
                : false;
            obj.price = priceDetail?.price;
            obj.service_price = priceDetail?.price;
            if (
              serviceInfo.service_type_uuid?.key.toLowerCase() ===
                "maintenance" ||
              serviceInfo.service_type_uuid?.key.toLowerCase() === "service"
            ) {
              obj.pool_name = serviceInfo.pool_name?.value;
            } else {
              obj.pool_name = "New Pool";
            }
            // obj.pool_name = serviceInfo.pool_name?.value;
            obj.is_enquiry = false;
            // obj.client_type = 'commercial';
            obj.service_address = serviceInfo.address?.value;
            obj.notes = serviceInfo.notes?.value;
            obj.billing_address = serviceInfo.billing_address?.value;
            obj.stax_card_token = staxProps?.id;   
            obj.preferred_start_date =
              moment(serviceInfo.preferred_start_date?.value).format(
                "YYYY-MM-DD"
              ) || moment().format("YYYY-MM-DD");
            obj.is_email_invoice = serviceInfo.invoice_preference?.value.find(
              (i) => i.name === "is_email_invoice"
            ).value;
            obj.is_print_and_mail_invoices =
              serviceInfo.invoice_preference?.value.find(
                (i) => i.name === "is_print_and_mail_invoices"
              ).value;
            obj.schedule_time_range = serviceInfo.time_range?.value;
            obj.status = "Active Order";
            obj.service_duration = serviceOrderNewFieldsValue?.duration || "";
            obj.reference_number =
              serviceOrderNewFieldsValue?.referenceNumber || "";
            if (dataServiceOrder.length) {
              const patchServiceOrderLead = async () => {
                const data = await updateLeadConvertCustomer(
                  dataServiceOrder[0].uuid,
                  obj
                );
                if (data?.uuid) {
                  dispatch(resetValues());
                  setLoader(false);
                  message.success("Service Order is updated successfully.");
                  if (state?.source === "customers-lead-list")
                    navigate("/customer-lead-list", {
                      state: { currentTab: "customer" },
                    });
                  else navigate("/commercial/lead-list", { state: { tab: 1 } });
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
              };
              patchServiceOrderLead();
            } else {
              const postServiceOrderLead = async () => {
                obj.client_type = "commercial";
                obj.stax_card_token = staxProps?.id;
                obj.card_holder_name = staxProps?.person_name;
                const data = await convertLeadToCustomer(obj);
                if (data?.uuid) {
                  dispatch(resetValues());
                  setLoader(false);
                  message.success("Service Order is created successfully.");
                  if (state?.source === "customers-lead-list") {
                    sessionStorage.setItem("CACHED_CURRENT_TAB", "customer");
                    navigate("/customer-lead-list");
                  } else
                    navigate("/commercial/lead-list", { state: { tab: 1 } });
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
              };
              postServiceOrderLead();
            }
          }
        });
      }
    }
  };
  const onSaveLead = () => {
    validate("save");
  };

  const openPayment = () => {
    if (currentTab === 1) {
      if (serviceInfo?.payment_method?.value === "card" || 
        serviceInfo?.payment_method?.value === "ACH Payments") {
        setLoader(true);
        billingInfoFunc.current.validate("save_lead").then((res) => {
          if (res === "success") {
            setLoader(false);
            if (serviceInfo?.payment_method?.value === "card") {
              setOpenStax(true);
            } else{
              setOpenStaxModalACH(true);
            }
          } else{
            setLoader(false);
            message.error("Something went wrong!");
          }
        }).catch(() =>{
          setLoader(false);
          message.error("Please correct errors");
        });
      }
      else {
        validate();
      }
    } else {
      validate();
    }
  };

  return (
    <div id="wrapper">
      {loader && <Loader />}
      <div className="content-area">
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenubuttonClick}
        />
        <div className="fluid">
          <div className="container new-cust">
            <div className="fluid-block">
              <div className="article-block">
                {renderTabs()}
                <ServiceSummaryLead
                  serviceInfo={serviceInfo}
                  priceDetail={priceDetail}
                  setPriceDetail={setPriceDetail}
                  setServiceInfoData={setServiceInfoData}
                />
              </div>
            </div>
            <div className="fix-btn-grp" style={{ width: "100%" }}>
              {state?.source === "customers-lead-list" ? (
                <Link to="/customer-lead-list" className="btn-cancel">
                  <button className="btn btn-white">Cancel</button>
                </Link>
              ) : (
                <Link to="/commercial/lead-list" className="btn-cancel">
                  <button className="btn btn-white">Cancel</button>
                </Link>
              )}
              <button
                type="button"
                className={`btn btn-bdr`}
                onClick={onSaveLead}
              >
                {"Save Lead"}
              </button>
              <button className={`btn btn-save-add`} onClick={() => openPayment()}>
                {btnLabel}
              </button>
            </div>
            {openStax ? (
              <StaxModal
                open={openStax}
                setOpen={(operation) => setOpenStax(operation)}
                onSuccessCall={validate}
                customer={{
                  address: serviceInfo?.billing_address?.value ? serviceInfo?.billing_address?.value : serviceInfo?.address?.value,
                  emails: state.leadData?.emailaddress_set || "",
                  firstName: state.leadData?.first_name || "",
                  lastName: state.leadData?.last_name || "",
                  phoneNumbers: state.leadData?.phone_numbers,
                }}
                staxKey={state.staxKey}
              />
            ) : (
              <></>
            )}
            {openStaxModalACH ? (
              <StaxModalACH
                open={openStaxModalACH}
                setOpen={(operation) => setOpenStaxModalACH(operation)}
                onSuccessCall={validate}
                customer={{
                  address: serviceInfo?.billing_address?.value ? serviceInfo?.billing_address?.value : serviceInfo?.address?.value,
                  emails: state.leadData?.emailaddress_set || "",
                  firstName: state.leadData?.first_name || "",
                  lastName: state.leadData?.last_name || "",
                  phoneNumbers: state.leadData?.phone_numbers,
                }}
                staxKey={state.staxKey}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOrderLead;
