const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  franchiseList: [],
  partTypeList: [],
  priceCatalogList: [],
  priceCatalogItem: {},
  loader: false,
  managingModal: {
    show: false,
    type: '',
    data: {}
  },
  filter: {
    field_office__uuid: '',
    ordering: '',
    search: '',
    part_type: "",
    current: 1,
    pageSize: "15",
    total: 0,
    showSizeChanger: false,
    productcode: ''
  },
  deleteModal: {
    show: false,
    data: {}
  },
  errorMessage: {},
}

const priceCatalogDataSlice = createSlice({
  name: "priceCatalog",
  initialState,
  reducers: {
    addList(state, action) {
      return (state = {
        ...state,
        priceCatalogList: action.payload,
      });
    },
    addPriceCatalogFilter(state, action) {
      return (state = {
        ...state,
        filter: { ...state.filter, ...action.payload },
      });
    },
    setPriceCatalogField(state, action) {
      return (state = {
        ...state,
        ...action.payload
      });
    },
    setPriceCatalogModalState(state, action) {
      return (state = {
        ...state,
        managingModal: { ...state.managingModal, ...action.payload },
      });
    },
    priceCatalogDeletionModal(state, action) {
      return (state = {
        ...state,
        deleteModal: { ...state.delete, ...action.payload },
      });
    },

  }
})

export const {
  priceCatalogDeletionModal,
  addPriceCatalogFilter,
  addList,
  setPriceCatalogField,
  setPriceCatalogModalState,
  addNewPriceItemDrawer,
} = priceCatalogDataSlice.actions;

export default priceCatalogDataSlice.reducer;
