import React, { useEffect, useState } from "react";
import { Select, ConfigProvider, Empty, Spin, Row, message } from "antd";
import { orderBy, isEmpty } from "lodash";
import { getFieldTechnician } from "../../Action/Franchise/index";

const { Option } = Select;

const TechnicianSelector = ({
  franchise = { value: null, label: null },
  allValue = false,
  multipleMode = false,
  unassign = false,
  permission = true,
  defaultValue = { value: null, label: null },
  maximum = 0,
  onControlChange = () => {},
}) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const is_fo = userInfo?.user?.user_roles.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );
  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });
  const role = is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
    ? "lead_tech"
    : userInfo?.user?.user_roles[0]?.name;

  const [loading, setLoading] = useState(true);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [selectedListTechnician, setSelectedListTechnician] = useState([]);
  const [allOption, setAllOption] = useState(allValue);

  useEffect(() => {
    const getTechnicianList = async () => {
      setLoading(true);
      setTechnicians([]);
      setSelectedTechnician(null);
      setSelectedListTechnician([]);

      if (franchise.value === null) {
        setTechnicians([]);
        return;
      }

      const data = await getFieldTechnician({
        field_office__uuid: franchise.value === "all" ?
          userInfo.user.associated_field_offices[0].uuid :
          franchise.value,
      });

      if (isEmpty(data?.results)) {
        setTechnicians([]);
        setLoading(false);
        return;
      }
      data.results = orderBy(data.results, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc");
      switch (true) {
        case role === "admin" || role === "franchise_owner" || role === "operations_mgr":
          if (multipleMode) {
            setSelectedListTechnician([
              {
                value: data.results[0]?.user.uuid,
                label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
              },
            ]);
          } else {
            setSelectedTechnician({
              value: data.results[0]?.user.uuid,
              label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
            });
          }
          onControlChange({
            value: data.results[0]?.user.uuid,
            label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
          });
          if (unassign)
            data.results.push({
              user: {
                uuid: "unassign",
                first_name: "Not Assigned",
                last_name: "",
              },
            });
          break;
        case role === "field_tech" || role === "lead_tech":
          if (multipleMode) {
            setSelectedListTechnician([
              {
                value: userInfo.user.uuid,
                label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
              },
            ]);
          } else
            setSelectedTechnician({
              value: userInfo.user.uuid,
              label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
            });

          onControlChange({
            value: userInfo.user.uuid,
            label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
          });
          if (unassign)
            data.results.push({
              user: {
                uuid: "unassign",
                first_name: "Not Assigned",
                last_name: "",
              },
            });
          break;
        default:
          if (multipleMode) {
            setSelectedListTechnician([
              {
                value: userInfo.user.uuid,
                label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
              },
            ]);
          } else
            setSelectedTechnician({
              value: userInfo.user.uuid,
              label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
            });
          onControlChange({
            value: userInfo.user.uuid,
            label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
          });
          break;
      }

      if (maximum > 0 && data.results.length <= maximum) {
        setAllOption(true);
      }else{
        setAllOption(false);
      }

      setTechnicians(data.results);
      setLoading(false);
    };

    getTechnicianList();
  }, [franchise]);

  useEffect(() => {
    if (!multipleMode) {
      if (defaultValue) {
        setSelectedTechnician(defaultValue);
      } else {
        setSelectedTechnician({});
      }
    }
  }, [multipleMode]);

  const updateTechnician = (selectedTech, options) => {
    if (multipleMode) {
      //If the selector has Multiple mode
      if (maximum > 0 && selectedListTechnician.length >= maximum && selectedTech.length >= maximum) {
        message.warning("You have selected the maximum number of Technicians.");
      } else {
        if (selectedTech[selectedTech.length - 1] === "all") {
          //If the last option selected is 'all'
          seletectAll(); //Select 'all' and Remove the rest of the options selected
          return;
        } else if (selectedTech.find((i) => i === "all")) {
          //if the last selection is not 'all'
          const idx = selectedTech?.indexOf("all"); //Uncheck 'all' option
          selectedTech.splice(idx, 1);
          options.splice(idx, 1);
          setSelectedListTechnician(selectedTech);
          onControlChange(options ?? { value: null, label: null });
        }
        if (selectedTech.length === technicians.length) {
          //If all the possible options have been selected
          seletectAll(); //Select only 'all' option
        } else {
          setSelectedListTechnician(selectedTech);
          onControlChange(options ?? { value: null, label: null });
        }
      }
    } else {
      setSelectedTechnician(selectedTech);
      onControlChange(options ?? { value: null, label: null });
    }
  };

  const seletectAll = () => {
    let options = [];

    technicians.forEach((tech) => {
      options.push({
        value: tech.user.uuid,
        label: `${tech?.user?.first_name} ${tech?.user?.last_name}`,
      });
    });

    setSelectedListTechnician([{ value: "all", label: "All" }]);
    onControlChange(options ?? { value: null, label: null });
  };

  const configureTechnicianSelector = () => {
    return (
      <div className="fitem">
        <ConfigProvider
          renderEmpty={() =>
            loading ? (
              <div className="selectorSpin">
                <Spin tip="Loading technician..." />
              </div>
            ) : (
              <div className="selectorSpin">
                <Empty />
              </div>
            )
          }
        >
          <Select
            showSearch
            allowClear
            value={
              !multipleMode
                ? selectedTechnician || undefined
                : selectedListTechnician
            }
            onChange={(value, options) => updateTechnician(value, options)}
            placeholder={allOption ? "Technician: All" : "Select a technician"}
            className="swhite tech_select"
            optionFilterProp="children"
            maxTagCount = 'responsive'
            data-title = {multipleMode ? `${selectedListTechnician.length} Techs` : 'Technician'}
            disabled={!permission}
            mode={multipleMode ? "multiple" : ""}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allOption && multipleMode ? (
              <Option key={"technicianAll"} value={"all"} label={"all"}>
                {"All"}
              </Option>
            ) : (
              <></>
            )}
            {technicians?.map((option, index) => (
              <Option
                key={option.user.uuid}
                value={option.user.uuid}
                label={`${option?.user?.first_name} ${option?.user?.last_name}`}
              >
                {`${option?.user?.first_name} ${option?.user?.last_name}`}
              </Option>
            ))}
          </Select>
        </ConfigProvider>
      </div>
    );
  };

  return configureTechnicianSelector();
};

export default TechnicianSelector;
