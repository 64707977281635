import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import DataTable from "./Services/DataTable";
import workOrderStatus from "../../../Utils/workOrderStatus.json";
import ServiceOutsideAlerter from "../../../Components/Common/serviceOutsideAlerter";
import WorkOrderOutsideAlerter from "../../../Components/Common/workOrderOutsideAlerter";
import TechnicianOutsideAlerter from "../../../Components/Common/technicianOutsideAlerter";
import StatusOutsideAlerter from "../../../Components/Common/statusOutsideAlerter";
import AddService from "./Services/AddService/AddService";
import ReviewService from "./Services/ReviewService/ReviewService";
import Loader from "../../../Components/Loader";
import EditWorkOrder from "./Services/EditWorkOrder";
import EditService from "./Common/EditService";
import RenderServiceOrder from "../../Common/RenderServiceOrder";
import { checkZipCode } from "../../../Action/Franchise";
import { PlayCircleOutlined } from "@ant-design/icons";
import { getFieldTechnician } from "../../../Action/Franchise/index";
import { delete_service_orders_details } from "../../../Action/Franchise/index";
import { map, isEmpty } from "lodash";
import { DatePicker, Table, Popconfirm, Modal, message } from "antd";
import {
  CheckOutlined,
  InfoCircleOutlined,
  ToolFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  getServiceOrderByClient,
  sendToOptimoRoute,
  deleteService,
  getPauseService,
  getWorkOrdersFilter,
  getServiceMatrix,
} from "../../../Action/Customer";
import { fieldTechniciansData } from "../../../Store/fieldTechnicianListSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import chevronDown from "../../../Assets/images/chevron-down.png";
import chevronRight from "../../../Assets/images/chevron-right.png";
import chevronLeft from "../../../Assets/images/chevron-left.png";
// import playIcon from '../../../Assets/images/pauseplay-icon.svg';
import editIcon from "../../../Assets/images/edit-pencil-green.svg";
import close from "../../../Assets/images/close.png";
import { useLocation, useNavigate } from "react-router";
import { deactivateserviceorderapi } from "../../../Action/Customer/index";
import {setNewWOFlag} from "../../../Store/serviceOrderDetailDataSlice";
import {updateServiceUUIDMap} from "../../../Store/serviceOrderDetailDataSlice";
import { getCommercialDates } from "../../../Store/dateSlice";
import { resetState } from "../../../Store/ProposalDataSlice";
// import share from '../../../Assets/images/share-icon.svg';

const { RangePicker } = DatePicker;

const Services = (props) => {
  const {tabChange} =props;
  const proposals = useSelector((state) => state.proposalDataSlice);
  const fieldTechnicianList = useSelector((state) => state.fieldTechnicianListSlice);
  const [loader, setLoader] = useState(false);
  const [deactivatebutton, setdeactivatebutton] = useState(false);
  const [activatebutton, setactivatebutton] = useState(false);
  const serviceOrderDetailState = useSelector((state) => state.serviceOrderAddedData);
  const [deactivatemodleconfirmation, setdeactivatemodleconfirmation] =
    useState(false);
  const [activatemodleconfirmation, setactivatemodleconfirmation] =
    useState(false);
  // Delete Service Orders Residential//
  const [deleteserviceorder, setdeleteserviceorder] = useState(false);
  const [deleteconfirmationpopup, setdeleteconfirmationpopup] = useState(false);
  // Delete service Orders Residential//
  const [loader_workOrder, setLoader_WorkOrder] = useState(false);
  const stopInitialWorkOrderRender = useRef(true);
  const selectedServiceUUID = useRef([]);
  const stopRecall = useRef(false);
  const proposalData = useRef({})
  const location = useLocation();
  const navigate = useNavigate(); 
  const [state, setState] = useState({
    serviceOrder: {
      results: [],
    },
    selectedService: {},
    selectedView: 0,
    selectedHistory: [],
    historyCount: 0,
    currentHistory: {},
    historyPagination: {
      showSizeChanger: false,
      pageSize: 10,
      ordering: "",
    },
    unavailableDays: [
      { key: "sun", value: "Sunday", checked: false },
      { key: "mon", value: "Monday", checked: false },
      { key: "tues", value: "Tuesday", checked: false },
      { key: "wed", value: "Wednesday", checked: false },
      { key: "thurs", value: "Thursday", checked: false },
      { key: "fri", value: "Friday", checked: false },
      { key: "sat", value: "Saturday", checked: false },
    ],
    pauseServiceVisible: false,
    viewHistory: false,
    editService: false,
    addOns: [],
    isUpdateDays: false,
    enquiryList: {},
    isEditEnquiry: false,
    customer: {},
    isUpdateService: false,
    startDate: null,
    endDate: null,
    serviceOrderFilter: [],
    workOrderFilter: [],
    fieldTechnicianFilter: [],
    workOrderCount: 0,
    workOrderList: [],
    serviceWorkOrderList: [],
    workOrderListPagination: {
      showSizeChanger: false,
      pageSize: 10,
      ordering: "",
    },
    scheduled_appointment__range: null,
    service_order__uuid: [],
    work_order_number: [],
    technician__uuid: [],
    status: [],
    ordering: "",
    scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
    scheduled_appointment__lt: null,
    viewType: "upcoming",
    optionSelected: null,
    toggleServiceOrderFilter: false,
    toggleWorkOrderFilter: false,
    toggleTechnicianFilter: false,
    toggleStatusFilter: false,
    isServiceDetail: false,
    woSearch: "",
    transection_type: "Create",
    deActivate: false,
    isModalVisible: false,
    isDeleteWOModalVisible: false,
    resumeModalVisible: false,
    preferred_start_date: "",
    pauseList: [],
    is_upcoming: true,
    isDeletePause: false,
    pause_uuid: "",
    pause_type: "",
    reviewServiceOrder: false,
    showAddons: false,
  });
  const [workOrderListTable, setworkOrderListTable] = useState([]);
  const [checkZipCodeData, setcheckZipCodeData] = useState({});
  const [selectedService, setSelectedService] = useState({});
  const [serviceMatrix, setServiceMatrix] = useState({});
  const [serviceOrderList, setserviceOrderList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [activePagination, setActivePagination] = useState("10");
  const [selectedServiceOrder,setSelectedServiceOrder] = useState('')
  const dispatch = useDispatch();

  let nextServiceDate = useSelector((state)=> state.dateSlice);
  props.nextService(nextServiceDate);

  useEffect(() => {
    if (proposals.redirect_key == "Proposal-Redirect" && Object.keys(checkZipCodeData).length > 0) {
      let proposalPayload = {
        service_type:proposals.service_type_name,
        is_flat_rate:proposals.select_rate === 'Standard' ? false : true,
        pool_name:proposals.pool_name,
        pool_size:proposals.pool_size,
        service_address:proposals.address,
        pool_type_uuid:proposals.pool_type_uuid
      }
      proposalData.current = proposalPayload
      onChangeView(2);
      setTimeout(() => {
        dispatch(resetState())
      }, 0);
    }
  }, [proposals.redirect_key, checkZipCodeData]);

  useEffect(() => {
    const { reviewServiceOrder } = state;
    setState((s) => ({ ...s, customer: props.customer }));
    getSericeMatrixInfo();
    getCheckZipCode();

    let t = getRedirectToService();
    if (getRedirectToService()) {
    } else if (location?.state?.record?.from === "ViewByWorkOrder") {      
      getFieldTechnicianFilter();
      let params = {
        field_office_uuid: props?.customer?.field_office?.uuid,
        client_uuid: props.customer.uuid,
      };
      let getworkOrdersParams = {
        offset: 0,
        service_order__client__uuid: props?.customer?.uuid,
        scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
      };
      initialServiceDataSet(params, getworkOrdersParams);
      setState((s) => ({
        ...s,
        showAddons: true,
        transection_type: props?.customer.transection_type,
        customer: props?.customer.customer, //field_office data
        currentRecord: { uuid: props?.customer.work_order_uuid },
      }));
    }
    else if (location?.state?.record?.from === "workOrder") {
      (async () => {
        let data = await getServiceOrderByClient({ field_office_uuid: props?.customer?.field_office?.uuid, client_uuid: props?.customer?.uuid });
        setserviceOrderList(data);
        data = data.filter((each) => location?.state?.record?.service === each.service_order_number);
        setSelectedService(data[0]);
        onViewService(data[0]);
      })();
    if (location?.state?.record?.transection_type !== 'service') {
      setState((s) => ({
        ...s,
        showAddons: true,
        transection_type: location?.state?.record?.transection_type,
        customer: location?.state?.record?.customer, //field_office data
        currentRecord: { uuid: location?.state?.record?.work_order_uuid },
      }));
    }
    } else {
      if (props?.customer?.uuid) {
        //Get WO from custosmer
        setState((s) => ({
          ...s,
          selectedView: 0,
          reviewServiceOrder: reviewServiceOrder,
        }));
        setLoader(true);
        let params = {
          field_office_uuid: props?.customer?.field_office?.uuid,
          client_uuid: props.customer.uuid,
        };
        let getworkOrdersParams = {
          offset: 0,
          service_order__client__uuid: props?.customer?.uuid,
          scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
        };
        if(!stopRecall.current){
          initialServiceDataSet(params, getworkOrdersParams);
        }
        stopRecall.current = true
        setTimeout(() => {
          stopRecall.current = false
        },3000)
        if (location?.state?.record?.from === 'ViewByServiceOrder') {
          (async () => {            
            const data = await getServiceOrderByClient({ field_office_uuid: props?.customer?.field_office?.uuid, client_uuid: props?.customer?.uuid });
            const selectedService = data.find(i => i.uuid === props?.customer?.service?.uuid)
            setSelectedService(selectedService);
            onViewService(selectedService);
            setTimeout(() => {
              toggleEditService(true);
              clearFromNavKey();
            }, 300);
          })();
        }
        if (location?.state?.record?.from === 'viewByProposal') {
          setLoader(true);
          (async () => {
            const data = await getServiceOrderByClient({ field_office_uuid: props?.customer?.field_office?.uuid, client_uuid: props?.customer?.uuid });
            const selectedService = data.find(i => i.uuid === props?.customer?.service?.uuid)
            setSelectedService(selectedService);
            proposalData.current = location?.state?.record?.proposalData
            //onViewService(selectedService);
            clearFromNavKey();
            onChangeView(2);
           })();
        }
      }
    }
  }, []);

  const initialServiceDataSet = async (params, getworkOrdersParams) => {
    let serviceOrderList = await getServiceOrderByClient(params);
    let _serviceOrder = {
      count: serviceOrderList.length,
      next: null,
      previous: null,
      results: serviceOrderList,
    };
    setState((s) => ({ ...s, serviceOrder: _serviceOrder, serviceOrderFilter: serviceOrderList }));
    setserviceOrderList(serviceOrderList);
    initialGetworkOrders(serviceOrderList, getworkOrdersParams);
    setLoader(false);
  }

  const initialGetworkOrders = async (serviceOrderListParam, params) => {
    setLoader_WorkOrder(true);
    if(state.viewType === "history") params.limit = 1000;
    var _workOrders = [];
    let tech_data = await getFieldTechnician({
      field_office__uuid: props?.customer?.field_office?.uuid,
    });
    dispatch(fieldTechniciansData(tech_data?.results));
    let list = await getWorkOrdersFilter(params);
    const serviceOrderList = serviceOrderListParam;

    _workOrders = list.results;

    if (_workOrders.length > 0) {
      _workOrders = sortWorkOrders({
        workOrders: _workOrders,
        columnKey: "scheduled_appointment",
        field: "scheduled_appointment",
        order: state.viewType === "history" ? "descend" : "ascend",
      });
    }

    if (_workOrders.length > 0) {
      _workOrders.forEach(function (element) {
        const so = serviceOrderList?.find(
          (service) => (service.uuid = element.service_order_uuid)
        );

        const tech = tech_data?.results?.find(
          (tech) => tech.user.uuid === element.technician_uuid
        );

        if (so?.service_type === "Maintenance" && props.customer?.is_active) {
          element.job_forms = element.job_forms.filter(
            (i) => i.form_name === "Maintenance Pool Report"
          );
        }

        element.technicianUser = tech;
        element.customer = props.customer;
        element.service_order = so;
      });
    }

    setState((s) => ({ ...s, workOrderList: _workOrders, workOrderFilter: list.results, fieldTechnicianFilter: tech_data?.results }));
    setworkOrderListTable(_workOrders);
    stopInitialWorkOrderRender.current = false;
    setLoader_WorkOrder(false);
  }

  const clearFromNavKey = () => {
    navigate('.', {
      replace: true,
      state: {
        ...location.state,
        record: {
          ...location.state.record,
          from: ''
        }
      }
    });
  };

  const getserviceOrder = async (params) => {
    let serviceOrderList = await getServiceOrderByClient(params);
    let soUUIDMap = structuredClone(serviceOrderDetailState.serviceUUIDMap)
    serviceOrderList.forEach(function (item) {
      let soMap = serviceOrderDetailState.serviceUUIDMap.findIndex(o => o.name === item.service_order_number)
      if(soMap === -1){
        let obj = {}
        obj['name'] = item.service_order_number
        obj['uuid'] = item.uuid
        soUUIDMap.push(obj)
        dispatch(updateServiceUUIDMap(soUUIDMap))
      }
    });
    let _serviceOrder = {
      count: serviceOrderList.length,
      next: null,
      previous: null,
      results: serviceOrderList,
    };

    setState((s) => ({ ...s, serviceOrder: _serviceOrder }));
    setserviceOrderList(serviceOrderList);

    setLoader(false);
  };

  const sortWorkOrders = (params) => {
    let _array = [];

    if (params?.workOrders?.length > 0)
      switch (params.field) {
        case "scheduled_appointment":
          _array = params.workOrders?.sort(
            (objA, objB) =>
              Number(new Date(objA[params.columnKey])) -
              Number(new Date(objB[params.columnKey]))
          );
          break;
        case "work_order_number":
        case "service_order__service_order_number":
          _array = params.workOrders?.sort(
            (objA, objB) =>
              Number(objA[params.columnKey].split("-")[1]) -
              Number(objB[params.columnKey].split("-")[1])
          );
          break;
        case "service_name":
        case "status":
          _array = params.workOrders?.sort(
            (objA, objB) => objA[params.columnKey] - objB[params.columnKey]
          );
          break;
        case "technician__user__first_name":
          _array = params.workOrders;
          break;
        default:
          _array = params.workOrders;
          break;
      }

    if (params.order === "descend") {
      _array = _array.reverse();
    }

    return _array;
  };
  const getworkOrdersExecute = useRef({execute:false, params:{}});
  const getworkOrders = async (params) => {
    if(getworkOrdersExecute.current.execute && getworkOrdersExecute.current.params == JSON.stringify(params)) return;
    getworkOrdersExecute.current.execute = true;
    getworkOrdersExecute.current.params = JSON.stringify(params);
    setLoader_WorkOrder(true);
    if(state.viewType === "history") params.limit = 1000;
    var _workOrders = [];
    let tech_data;
    if(fieldTechnicianList?.fieldTechnicians?.length){
      tech_data = fieldTechnicianList?.fieldTechnicians;
    }else {
      tech_data = await getFieldTechnicianFilter();
    }
    let list = await getWorkOrdersFilter(params);
    const serviceOrderList = await getServiceOrderByClient({
      field_office_uuid: props?.customer?.field_office?.uuid,
      client_uuid: props?.customer?.uuid,
    });
    _workOrders = list.results;

    if (_workOrders.length > 0) {
      _workOrders = sortWorkOrders({
        workOrders: _workOrders,
        columnKey: "scheduled_appointment",
        field: "scheduled_appointment",
        order: state.viewType === "history" ? "descend" : "ascend",
      });
    }

    if (_workOrders.length > 0) {
      _workOrders.forEach(function (element) {
        const so = serviceOrderList?.find(
          (service) => (service.uuid = element.service_order_uuid)
        );

        const tech = tech_data?.find(
          (tech) => tech.user.uuid === element.technician_uuid
        );

        if (so?.service_type === "Maintenance" && props.customer?.is_active) {
          element.job_forms = element.job_forms.filter(
            (i) => i.form_name === "Maintenance Pool Report"
          );
        }

        element.technicianUser = tech;
        element.customer = props.customer;
        element.service_order = so;
      });
    }

    setState((s) => ({ ...s, workOrderList: _workOrders }));
    setworkOrderListTable(_workOrders);
    setLoader_WorkOrder(false);
    getworkOrdersExecute.current.execute = false;
    getworkOrdersExecute.current.params = JSON.stringify({});
  };

  const getServiceOrderFilter = async (params) => {
    let filterValues = await getServiceOrderByClient(params);
    setState((s) => ({ ...s, serviceOrderFilter: filterValues }));
  };

  const getWorkOrderFilterExecute = useRef({execute:false, params:{}});
  const getWorkOrderFilter = async (params) => {
    if(getWorkOrderFilterExecute.current.execute && getWorkOrderFilterExecute.current.params == JSON.stringify(params)) return;
    getWorkOrderFilterExecute.current.execute = true;
    getWorkOrderFilterExecute.current.params = JSON.stringify(params);
    setLoader(true);
    params.offset = offset;
    params.limit = state.viewType === "history" ? 1000 : activePagination;
    let filterValues = await getWorkOrdersFilter(params);
    setState((s) => ({ ...s, workOrderFilter: filterValues.results }));
    setLoader(false);
    getWorkOrderFilterExecute.current.execute = false;
    getWorkOrderFilterExecute.current.params = JSON.stringify({});
  };

  
  const getFieldTechnicianFilterExecute = useRef({execute:false});
  const getFieldTechnicianFilter = async () => {
    if(fieldTechnicianList?.fieldTechnicians?.length) return;
    if(getFieldTechnicianFilterExecute.current.execute) return;
    getFieldTechnicianFilterExecute.current.execute = true;
    setLoader(true);
    let filterValues = await getFieldTechnician({
      field_office__uuid: props?.customer?.field_office?.uuid,
    });
    dispatch(fieldTechniciansData(filterValues?.results));
    setState((s) => ({ ...s, fieldTechnicianFilter: filterValues?.results }));
    setLoader(false);
    getFieldTechnicianFilterExecute.current.execute = false;
    return filterValues?.results;
  };

  const getSericeMatrixInfo = async () => {
    let fieldOfficeUuid = "";
    if (location?.state?.record?.from === "ViewByWorkOrder" || location?.state?.record?.from === "workOrder") {
      fieldOfficeUuid = location?.state?.record?.field_office_uuid;
    } else {
      fieldOfficeUuid = props?.customer?.field_office?.uuid;
    }
    setLoader(true);
    let data = await getServiceMatrix(fieldOfficeUuid);

    setServiceMatrix(data);

    setLoader(false);
  };

  const getRedirectToService = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    
    return params.redirect_to_service;
  };

  const getRedirectToWorkOrder = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    return params.redirect_to_work_order;
  };

  const onDateRangeChange = (e) => {
    if (e) {
      setState((state) => ({
        ...state,
        scheduled_appointment__range: `${moment(e[0]).format(
          "YYYY-MM-DD hh:mm:ss"
        )},${moment(e[1]).format("YYYY-MM-DD 23:59:59")}`,
      }));
    } else {
      setState((state) => ({
        ...state,
        scheduled_appointment__range: null,
      }));
    }
  };
  const onclickonthreedots = () => {
    setdeleteserviceorder(!deleteserviceorder)
    if (selectedService.is_active == true) {
      if (
        deactivatebutton == false &&
        activatebutton == false &&
        (selectedService.frequency_settings.end == null ||
          selectedService.frequency_settings.end >
          moment().format("YYYY-MM-DD"))
      ) {
        setdeactivatebutton(true);
        setactivatebutton(false);
      } else {
        setdeactivatebutton(false);
      }
    } else {
      if (selectedService.is_active == false) {
        if (
          activatebutton == false &&
          deactivatebutton == false &&
          (selectedService.frequency_settings.end ==
            moment().format("YYYY-MM-DD") ||
            selectedService.frequency_settings.end <
            moment().format("YYYY-MM-DD"))
        ) {
          setdeactivatebutton(false);
          setactivatebutton(true);
        } else {
          setactivatebutton(false);
        }
      }
    }
  };
  const ondeactivatecanclebutton = () => {
    setdeactivatemodleconfirmation(false);
  };
  const onclickondeactivaceSO = () => {
    setdeactivatemodleconfirmation(true);
  };
  const onclickonactivaceSO = () => {
    setactivatemodleconfirmation(true);
  };
  const onactivatecanclebutton = () => {
    setactivatemodleconfirmation(false);
  };
  const onclickonokbutton = async () => {
    setLoader(true);
    let params = {
      is_active: deactivatebutton,
    };
    let data = await deactivateserviceorderapi(params, selectedService.uuid);
    let result = data;
    setLoader(false);
    message.success("Service Order Deactivated");
    setdeactivatemodleconfirmation(false);
    setTimeout(function () { reloadServices(); }, 400);
  };
  const onclickonactiveokbutton = async () => {
    setLoader(true);
    let params = {
      is_active: deactivatebutton,
    };
    let data = await deactivateserviceorderapi(params, selectedService.uuid);
    let result = data;
    setLoader(false);
    message.success("Service Order Reactivated");
    setactivatemodleconfirmation(false);
    setTimeout(function () { reloadServices(); }, 400);
  };
  const filterParams = (params) => {
    for (var propName in params) {
      if (
        params[propName] === "" ||
        params[propName] === null ||
        params[propName] === "null" ||
        params[propName] === undefined ||
        params[propName] === "undefined"
      ) {
        delete params[propName];
      }
    }
    return params;
  };

  const countAppliedFilters = () => {
    const {
      scheduled_appointment__range,
      service_order__uuid,
      work_order_number,
      technician__uuid,
      status,
    } = state;
    const queryParams = {
      scheduled_appointment__range: `${scheduled_appointment__range}`,
      service_order__uuid__in:
        service_order__uuid && service_order__uuid.length > 0
          ? `${service_order__uuid.join()}`
          : null,
      work_order_number__in:
        work_order_number && work_order_number.length > 0
          ? `${work_order_number}`
          : null,
      technician__uuid__in:
        technician__uuid && technician__uuid.length > 0
          ? `${technician__uuid.join()}`
          : null,
      status__in: status && status.length > 0 ? `${status.join()}` : null,
    };
    const params = filterParams(queryParams);
    return Object.keys(params).length;
  };

  // on view service click
  const onViewService = (item) => {
    setState((s) => ({
      ...s,
      selectedService: item,
      toggleServiceOrderFilter: false,
      toggleWorkOrderFilter: false,
      toggleTechnicianFilter: false,
      toggleStatusFilter: false,
      scheduled_appointment__range: null,
      service_order__uuid: [],
      work_order_number: [],
      technician__uuid: [],
      status: [],
    }));
    let passUUID = ''
    let findSOonMap = serviceOrderDetailState.serviceUUIDMap.find(o => o.name === item.service_order_number)
    if(findSOonMap.uuid !== item.uuid){
      passUUID = findSOonMap.uuid
      item['uuid'] = findSOonMap.uuid
    }
    else{
      passUUID = item.uuid
    }
    onChangeView(1);
    setSelectedService(item);
    setSelectedServiceOrder(passUUID)
    getWorkOrderFilter({
      service_order__client__uuid: props?.customer?.uuid,
      service_order__uuid: passUUID,
      scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
    });

    getFieldTechnicianFilter();
    // getPauseService({ service_order_uuid: item?.uuid }); //Missing API
    clearStatus();
  };

  // on sorting selected
  const onWorkOrderHandleChange = (pagination, filters, sorter) => {
    setLoader(true);
    let offsetValue = (pagination.current - 1) * activePagination;
    setOffset(offsetValue);
    let ordering = "";
    if (sorter.column) {
      ordering =
        sorter.order === "ascend" ? `${sorter.field}` : `-${sorter.field}`;
    } else {
      ordering = "";
    }

    if (ordering) {
      sorter.workOrders = state.workOrderList;
      const _workOrders = sortWorkOrders(sorter);

      setState((s) => ({
        ...s,
        ordering: ordering,
        workOrderList: _workOrders,
      }));
      setworkOrderListTable(_workOrders);
      setLoader(false);
    } else {
      setState((s) => ({ ...s, ordering: "" }));
      setLoader(false);
      // onFilterChange();
    }
  };

  useEffect(() => {
    selectedServiceUUID.current = selectedServiceOrder
    onFilterChange();
  }, [
    state.scheduled_appointment__lt,
    state.scheduled_appointment__gte,
    state.service_order__uuid,
    state.work_order_number,
    state.technician__uuid,
    state.status,
    state.scheduled_appointment__range,
    state.selectedService,
    state.selectedServiceOrder
  ]);

  //api call after filter and sorting Modified to do it FE
  const onFilterChange = async () => {
    const customerId = props?.customer?.uuid;

    const {
      scheduled_appointment__range,
      service_order__uuid,
      work_order_number,
      technician__uuid,
      status,
      ordering,
      scheduled_appointment__gte,
      scheduled_appointment__lt,
      isServiceDetail,
      viewType,
    } = state;

    const queryParams = {
      service_order__client__uuid: customerId,
      scheduled_appointment__range: scheduled_appointment__range,
      service_order__uuid__in:
        service_order__uuid && service_order__uuid.length > 0
          ? service_order__uuid.join()
          : null,
      work_order_number__in:
        work_order_number && work_order_number.length > 0
          ? work_order_number.join()
          : null,
      technician__uuid__in:
        technician__uuid && technician__uuid.length > 0
          ? technician__uuid.join()
          : null,
      status__in: status && status.length > 0 ? `${status.join()}` : null,
      ordering: ordering,
      scheduled_appointment__gte: scheduled_appointment__gte,
      scheduled_appointment__lt: scheduled_appointment__lt,
    };
    if (isServiceDetail) {
      delete queryParams.service_order__uuid__in;
      queryParams["service_order__uuid"] = selectedService?.uuid;
    } else {
      delete queryParams.service_order__uuid;
    }

    const params = await filterParams(queryParams);
    if (!stopInitialWorkOrderRender.current && !stopRecall.current) {
      await getworkOrders(params);
    }
    stopRecall.current = false

    if (viewType === "upcoming") {
    getWorkOrderFilter({
        service_order__client__uuid: customerId,
        ...(selectedService
          ? { service_order__uuid: selectedServiceUUID.current }
          : null),
        scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
      });
    } else if (viewType === "history") {
      getWorkOrderFilter({
        service_order__client__uuid: customerId,
        ...(selectedService
          ? { service_order__uuid: selectedServiceUUID.current }
          : null),
        scheduled_appointment__lt: moment()
          .subtract(1, "day")
          .format("YYYY-MM-DD 23:59:59"),
      });
    }
  };

  useEffect(() => {
    const { workOrderList, fieldTechnicianFilter } = state;
      if (workOrderList.length > 0 && fieldTechnicianFilter.length > 0) {
        workOrderList.forEach(function (element) {
          const tech = state.fieldTechnicianFilter?.find(
            (tech) => tech.user.uuid === element.technician_uuid
          );
  
          element.technicianUser = tech;
        });
        setState((s) => ({
          ...s,
          workOrderList: workOrderList,
        }));
        setworkOrderListTable(workOrderList);
      }

  }, [state.workOrderList, state.fieldTechnicianFilter])
  const upcomingWorkOrders = () => {
    setState((state) => ({
      ...state,
      viewType: "upcoming",
      scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
      scheduled_appointment__lt: null,
    }));
  };

  const historyWorkOrders = () => {
    setState((state) => ({
      ...state,
      viewType: "history",
      scheduled_appointment__lt: moment()
        .subtract(1, "day")
        .format("YYYY-MM-DD 23:59:59"),
      scheduled_appointment__gte: null,
      ordering: "-scheduled_appointment",
    }));
  };
  //#region "Service Order Filter"
  const renderServiceOrderFilters = () => {
    const {
      toggleServiceOrderFilter,
      serviceOrderFilter,
      service_order__uuid,
    } = state;

    return (
      <ServiceOutsideAlerter closeServiceOrderFilter={closeServiceOrderFilter}>
        <div className="form-group image-input">
          <div className="fitem">
            <input
              type="text"
              id="txtServiceOrder"
              placeholder={
                service_order__uuid && service_order__uuid.length === 0
                  ? "All"
                  : `${service_order__uuid.length} items selected`
              }
              onChange={(e) => {
                onSearchServiceOrder(e);
              }}
              onFocus={() => {
                toggleServiceOrder();
              }}
            />
            {service_order__uuid && service_order__uuid.length === 0 && (
              <img
                src={chevronDown}
                alt=""
                onClick={() => {
                  toggleServiceOrder();
                }}
              />
            )}
            {service_order__uuid && service_order__uuid.length > 0 && (
              <img
                src={close}
                alt=""
                onClick={() => {
                  clearServiceOrder();
                }}
              />
            )}
          </div>
          {toggleServiceOrderFilter && (
            <div className="field-dropdown">
              <div className="field-dropdown-body">
                <div className="fitem-check">
                  <input
                    id="allServiceOrder"
                    type="checkbox"
                    className="fitem-ck-input"
                    checked={state?.allServiceOrderSelected}
                    onChange={(e) => {
                      onServiceOrderChanged(e, null, true);
                    }}
                  />
                  <label htmlFor="allServiceOrder" className="fitem-ck-txt">
                    All Service Orders
                  </label>
                </div>
                {map(serviceOrderFilter, (item, index) => {
                  return (
                    <div key={index} className="fitem-check">
                      <input
                        id={item.uuid}
                        type="checkbox"
                        className="fitem-ck-input"
                        onChange={(e) => {
                          onServiceOrderChanged(e, index);
                        }}
                        checked={state?.service_order__uuid?.includes(
                          item?.uuid
                        )}
                      />{" "}
                      {/* checked={item.is_change} */}
                      <label htmlFor={item.uuid} className="fitem-ck-txt">
                        {item.service_order_number}
                      </label>
                      <span className="fcheck-span">
                        {item?.service_address?.formatted_address}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="flex ai-center field-dropdown-footer">
                <button
                  className="btn btn-brand btn-bdr btn-full mr-5"
                  onClick={() => {
                    clearServiceOrder();
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary btn-full ml-5 mt-0"
                  onClick={() => {
                    onFilterChange();
                    setState((s) => ({
                      ...s,
                      toggleServiceOrderFilter: false,
                    }));
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      </ServiceOutsideAlerter>
    );
  };

  const closeServiceOrderFilter = () => {
    setState((state) => ({
      ...state,
      toggleServiceOrderFilter: false,
    }));
  };

  const toggleServiceOrder = () => {
    const { toggleServiceOrderFilter } = state;
    if (toggleServiceOrderFilter) {
      setState((state) => ({
        ...state,
        toggleServiceOrderFilter: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        toggleServiceOrderFilter: true,
        toggleWorkOrderFilter: false,
        toggleTechnicianFilter: false,
        toggleStatusFilter: false,
      }));
    }
  };

  const onSearchServiceOrder = (e) => {
    const { serviceOrderFilter } = this.state;
    if (e.target.value) {
      const data = serviceOrderFilter.filter((obj) => {
        return (
          obj.service_order_number
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) > -1
        );
      });
      setState((s) => ({ ...s, serviceOrderFilter: data }));
    } else {
      getServiceOrderFilter({ client_uuid: props.customer.uuid });
    }
  };

  const onServiceOrderChanged = (e, index, isAll = false) => {
    const { serviceOrderFilter, service_order__uuid, allServiceOrderSelected } =
      state;
    var arrServiceUuid = [...service_order__uuid];

    if (isAll) {
      if (e.target.checked) {
        arrServiceUuid = serviceOrderFilter.map((item) => item.uuid);
        document.getElementById("allServiceOrder").checked = true;
      } else {
        arrServiceUuid = [];
        document.getElementById("allServiceOrder").checked = false;
      }
    } else {
      if (e.target.checked) {
        // case where we want to toggle select all
        if (index === null) {
          arrServiceUuid = [];
          serviceOrderFilter.forEach((element) => {
            element["is_change"] = !allServiceOrderSelected;
            if (!allServiceOrderSelected) {
              arrServiceUuid.push(element?.uuid);
            }
          });
        } else {
          if (arrServiceUuid.indexOf(e.target.id) === -1) {
            document.getElementById("allServiceOrder").checked = false;
            arrServiceUuid.push(e.target.id);
            serviceOrderFilter[index]["is_change"] = true;
          }
        }
      } else {
        if (index === null) {
          arrServiceUuid = [];
          serviceOrderFilter.forEach((element) => {
            element["is_change"] = false;
          });
        } else {
          const ind = arrServiceUuid.indexOf(e.target.id);
          if (ind > -1) {
            arrServiceUuid.splice(ind, 1);
            serviceOrderFilter[index]["is_change"] = false;
            document.getElementById("allServiceOrder").checked = false;
          }
        }
      }
    }

    setState((state) => ({
      ...state,
      service_order__uuid: arrServiceUuid,
      // if All Service Order is selected then we toggle or we unset the allServiceOrderSelected flag.
      allServiceOrderSelected:
        index === null ? !allServiceOrderSelected : false,
    }));
  };

  const clearServiceOrder = () => {
    const { serviceOrderFilter } = state;

    if (document.getElementById("allServiceOrder")) {
      document.getElementById("allServiceOrder").checked = false;
    }
    serviceOrderFilter.forEach((element) => {
      element["is_change"] = false;
    });

    setState((state) => ({
      ...state,
      service_order__uuid: [],
      toggleServiceOrderFilter: false,
      allServiceOrderSelected: false,
    }));
  };
  //#endregion

  //#region "Work Order Filter"
  const renderWorkOrderFilters = () => {
    const {
      toggleWorkOrderFilter,
      workOrderFilter,
      work_order_number,
      woSearch = "",
    } = state;
    return (
      <WorkOrderOutsideAlerter closeWorkOrderFilter={closeWorkOrderFilter}>
        <div className="form-group image-input">
          <div className="fitem">
            <input
              type="text"
              id="txtWorkOrder"
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  woSearch: e.target.value,
                }));
              }}
              placeholder={
                work_order_number && work_order_number.length === 0
                  ? "All"
                  : `${work_order_number.length} items selected`
              }
              onFocus={() => {
                toggleWorkOrder();
              }}
            />
            {work_order_number && work_order_number.length === 0 && (
              <img
                src={chevronDown}
                alt=""
                onClick={() => {
                  toggleWorkOrder();
                }}
              />
            )}
            {work_order_number && work_order_number.length > 0 && (
              <img
                src={close}
                alt=""
                onClick={() => {
                  clearWorkOrder();
                }}
              />
            )}
          </div>
          {toggleWorkOrderFilter && (
            <div className="field-dropdown">
              <div className="field-dropdown-body">
                <div className="fitem-check">
                  <input
                    id="allWorkOrder"
                    type="checkbox"
                    className="fitem-ck-input"
                    checked={state?.allWorkOrderSelected}
                    onChange={(e) => {
                      onWorkOrderChanged(e, null, true);
                    }}
                  />
                  <label htmlFor="allWorkOrder" className="fitem-ck-txt">
                    All Workorders
                  </label>
                </div>
                {map(
                  workOrderFilter.filter((wo) =>
                    (wo?.address + wo?.work_order_number).includes(woSearch)
                  ),
                  (item, index) => {
                    return (
                      <div key={index} className="fitem-check">
                        <input
                          id={item?.uuid}
                          type="checkbox"
                          value={item?.work_order_number}
                          className="fitem-ck-input chkWorkOrder"
                          onChange={(e) => {
                            onWorkOrderChanged(e, index);
                          }}
                          checked={this?.state?.work_order_number?.includes(
                            item?.work_order_number
                          )}
                        />{" "}
                        {/* checked={item.is_change} */}
                        <label htmlFor={item?.uuid} className="fitem-ck-txt">
                          {item?.work_order_number}
                        </label>
                        <span className="fcheck-span">{item?.address}</span>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="flex ai-center field-dropdown-footer">
                <button
                  className="btn btn-brand btn-bdr btn-full mr-5"
                  onClick={() => {
                    clearWorkOrder();
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary btn-full ml-5 mt-0"
                  onClick={() => {
                    onFilterChange();
                    setState((state) => ({
                      ...state,
                      service_order__uuid: [],
                      toggleServiceOrderFilter: false,
                      allServiceOrderSelected: false,
                    }));
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      </WorkOrderOutsideAlerter>
    );
  };

  const closeWorkOrderFilter = () => {
    setState((state) => ({
      ...state,
      toggleWorkOrderFilter: false,
    }));
  };

  const toggleWorkOrder = () => {
    const { toggleWorkOrderFilter } = state;
    if (toggleWorkOrderFilter) {
      setState((state) => ({
        ...state,
        toggleWorkOrderFilter: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        toggleWorkOrderFilter: true,
        toggleServiceOrderFilter: false,
        toggleTechnicianFilter: false,
        toggleStatusFilter: false,
      }));
    }
  };

  const onWorkOrderChanged = (e, index, isAll = false) => {
    const { workOrderFilter, work_order_number, allWorkOrderSelected } = state;
    let arrWorkOrderNumber = [...work_order_number];

    if (isAll) {
      if (e.target.checked) {
        arrWorkOrderNumber = workOrderFilter.map(
          (item) => item.work_order_number
        );
        document.getElementById("allWorkOrder").checked = true;
      } else {
        arrWorkOrderNumber = [];
        document.getElementById("allWorkOrder").checked = false;
      }
    } else {
      if (e.target.checked) {
        if (index === null) {
          arrWorkOrderNumber = [];
          workOrderFilter.forEach((element) => {
            element["is_change"] = !allWorkOrderSelected;
            if (!allWorkOrderSelected) {
              arrWorkOrderNumber.push(element?.work_order_number);
            }
          });
        } else {
          if (arrWorkOrderNumber.indexOf(e.target.value) === -1) {
            document.getElementById("allWorkOrder").checked = false;
            arrWorkOrderNumber.push(e.target.value);
            workOrderFilter[index]["is_change"] = true;
          }
        }
      } else {
        if (index === null) {
          arrWorkOrderNumber = [];
          workOrderFilter.forEach((element) => {
            element["is_change"] = false;
          });
        } else {
          const ind = arrWorkOrderNumber.indexOf(e.target.value);
          if (ind > -1) {
            arrWorkOrderNumber.splice(ind, 1);
            workOrderFilter[index]["is_change"] = false;
            document.getElementById("allWorkOrder").checked = false;
          }
        }
      }
    }

    setState((state) => ({
      ...state,
      work_order_number: arrWorkOrderNumber,
      allWorkOrderSelected: index === null ? !allWorkOrderSelected : false,
    }));
  };

  const clearWorkOrder = () => {
    const { workOrderFilter } = state;
    workOrderFilter.forEach((element) => {
      element["is_change"] = false;
    });
    if (document.getElementById("allWorkOrder")) {
      document.getElementById("allWorkOrder").checked = false;
    }

    setState(
      (state) => ({
        ...state,
        work_order_number: [],
        toggleWorkOrderFilter: false,
        allWorkOrderSelected: false,
      }),
      () => {
        onFilterChange();
      }
    );
  };
  //#endregion

  //#region "Technician Filter"
  const renderTechnicianFilters = () => {
    const { toggleTechnicianFilter, fieldTechnicianFilter, technician__uuid } =
      state;
    return (
      <TechnicianOutsideAlerter closeTechnicianFilter={closeTechnicianFilter}>
        <div className="form-group image-input">
          <div className="fitem">
            <input
              type="text"
              id="txtTechnician"
              placeholder={
                technician__uuid && technician__uuid.length === 0
                  ? "All"
                  : `${technician__uuid.length} items selected`
              }
              onChange={(e) => {
                onSearchTechnician(e);
              }}
              onFocus={() => {
                toggleTechnician();
              }}
            />
            {technician__uuid && technician__uuid.length === 0 && (
              <img
                src={chevronDown}
                alt=""
                onClick={() => {
                  toggleTechnician();
                }}
              />
            )}
            {technician__uuid && technician__uuid.length > 0 && (
              <img
                src={close}
                alt=""
                onClick={() => {
                  clearTechnician();
                }}
              />
            )}
          </div>
          {toggleTechnicianFilter && (
            <div className="field-dropdown">
              <div className="field-dropdown-body">
                <div className="fitem-check">
                  <input
                    id="allTech"
                    type="checkbox"
                    className="fitem-ck-input"
                    onChange={(e) => {
                      onTechnicianChanged(e, null);
                    }}
                  />
                  <label htmlFor="allTech" className="fitem-ck-txt">
                    All technicians
                  </label>
                </div>
                {map(fieldTechnicianFilter, (item, index) => {
                  return (
                    <div key={index} className="fitem-check">
                      <input
                        id={item?.user?.uuid}
                        type="checkbox"
                        className="fitem-ck-input"
                        onChange={(e) => {
                          onTechnicianChanged(e, index);
                        }}
                        checked={item.is_change}
                      />{" "}
                      {/* checked={item.is_change} */}
                      <label
                        htmlFor={item?.user?.uuid}
                        className="fitem-ck-txt"
                      >
                        {item?.user?.first_name}, {item?.user?.last_name}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="flex ai-center field-dropdown-footer">
                <button
                  className="btn btn-brand btn-bdr btn-full mr-5"
                  onClick={() => {
                    clearTechnician();
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary btn-full ml-5 mt-0"
                  onClick={() => {
                    onFilterChange();
                    setState((state) => ({
                      ...state,
                      toggleTechnicianFilter: false,
                    }));
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      </TechnicianOutsideAlerter>
    );
  };

  const clearTechnician = () => {
    const { fieldTechnicianFilter } = state;
    fieldTechnicianFilter.forEach((element) => {
      element["is_change"] = false;
    });
    if (document.getElementById("allTech")) {
      document.getElementById("allTech").checked = false;
    }
    setState((s) => ({
      ...s,
      technician__uuid: [],
      toggleTechnicianFilter: false,
    }));
  };

  const closeTechnicianFilter = () => {
    setState((state) => ({
      ...state,
      toggleTechnicianFilter: false,
    }));
  };

  const toggleTechnician = () => {
    const { toggleTechnicianFilter } = state;
    if (toggleTechnicianFilter) {
      setState((state) => ({
        ...state,
        toggleTechnicianFilter: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        toggleTechnicianFilter: true,
        toggleServiceOrderFilter: false,
        toggleWorkOrderFilter: false,
        toggleStatusFilter: false,
      }));
    }
  };

  const onSearchTechnician = (e) => {
    const { fieldTechnicianFilter } = state;
    if (e.target.value) {
      const data = fieldTechnicianFilter.filter((obj) => {
        return (
          obj.user.last_name
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) > -1
        );
      });
      setState((s) => ({ ...s, fieldTechnicianFilter: data }));
    } else {
      getFieldTechnicianFilter();
    }
  };

  const onTechnicianChanged = (e, index) => {
    const { fieldTechnicianFilter, technician__uuid, allTechnicianSelected } =
      state;

    let arrTechnicianUuid = [...technician__uuid];
    if (e.target.checked) {
      if (index === null) {
        arrTechnicianUuid = [];
        fieldTechnicianFilter.forEach((element) => {
          element["is_change"] = !allTechnicianSelected;
          if (!allTechnicianSelected) {
            arrTechnicianUuid.push(element?.uuid);
          }
        });
      } else {
        if (arrTechnicianUuid.indexOf(e.target.id) === -1) {
          document.getElementById("allTech").checked = false;
          arrTechnicianUuid.push(e.target.id);
          fieldTechnicianFilter[index]["is_change"] = true;
        }
      }
    } else {
      if (index === null) {
        arrTechnicianUuid = [];
        fieldTechnicianFilter.forEach((element) => {
          element["is_change"] = false;
        });
      } else {
        const ind = arrTechnicianUuid.indexOf(e.target.id);
        if (ind > -1) {
          arrTechnicianUuid.splice(ind, 1);
          fieldTechnicianFilter[index]["is_change"] = false;
          document.getElementById("allTech").checked = false;
        }
      }
    }

    setState((state) => ({
      ...state,
      technician__uuid: arrTechnicianUuid,
      allTechnicianSelected: index === null ? !allTechnicianSelected : false,
    }));
  };
  //#endregion

  //#region "Status Filter"
  const renderStatusFilters = () => {
    const { toggleStatusFilter, status } = state;
    return (
      <StatusOutsideAlerter closeStatusFilter={closeStatusFilter}>
        <div className="form-group image-input">
          <div className="fitem">
            <input
              type="text"
              id="txtStatus"
              placeholder={
                status && status.length === 0
                  ? "All"
                  : `${status.length} items selected`
              }
              onFocus={() => {
                toggleStatus();
              }}
            />
            {status && status.length === 0 && (
              <img
                src={chevronDown}
                alt=""
                onClick={() => {
                  toggleStatus();
                }}
              />
            )}
            {status && status.length > 0 && (
              <img
                src={close}
                alt=""
                onClick={() => {
                  clearStatus();
                }}
              />
            )}
          </div>
          {toggleStatusFilter && (
            <div className="field-dropdown right">
              <div className="field-dropdown-body">
                {map(workOrderStatus.WorkOrderStatus, (item, index) => {
                  return (
                    <div key={index} className="fitem-check">
                      <input
                        id={item.value}
                        type="checkbox"
                        className="fitem-ck-input"
                        onChange={(e) => {
                          onStatusChanged(e, index);
                        }}
                        checked={item.is_change}
                      />
                      <label htmlFor={item.value} className="fitem-ck-txt">
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="flex ai-center field-dropdown-footer">
                <button
                  className="btn btn-brand btn-bdr btn-full mr-5"
                  onClick={() => {
                    clearStatus();
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary btn-full ml-5 mt-0"
                  onClick={() => {
                    onFilterChange();
                    setState((state) => ({
                      ...state,
                      toggleStatusFilter: false,
                    }));
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      </StatusOutsideAlerter>
    );
  };

  const closeStatusFilter = () => {
    setState((state) => ({
      ...state,
      toggleStatusFilter: false,
    }));
  };

  const toggleStatus = () => {
    const { toggleStatusFilter } = state;
    if (toggleStatusFilter) {
      setState((state) => ({
        ...state,
        toggleStatusFilter: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        toggleStatusFilter: true,
        toggleServiceOrderFilter: false,
        toggleWorkOrderFilter: false,
        toggleTechnicianFilter: false,
      }));
    }
  };

  const onStatusChanged = (e, index) => {
    const { status } = state;
    let arrStatus = [...status];
    if (e.target.checked) {
      if (index === 0) {
        workOrderStatus.WorkOrderStatus.forEach((element, index) => {
          element["is_change"] = true;
          if (index > 0 && arrStatus.indexOf(element.value) === -1) {
            arrStatus.push(element.value);
          }
        });
      } else {
        if (arrStatus.indexOf(e.target.id) === -1) {
          workOrderStatus.WorkOrderStatus[index]["is_change"] = true;
          arrStatus.push(e.target.id);
        }
      }
    } else {
      if (index === 0) {
        workOrderStatus.WorkOrderStatus.forEach((element) => {
          element["is_change"] = false;
        });
        arrStatus = [];
      } else {
        const ind = arrStatus.indexOf(e.target.id);
        if (ind > -1) {
          workOrderStatus.WorkOrderStatus[0]["is_change"] = false;
          workOrderStatus.WorkOrderStatus[index]["is_change"] = false;
          arrStatus.splice(ind, 1);
        }
      }
    }
    setState((state) => ({
      ...state,
      status: arrStatus,
    }));
  };

  const clearStatus = () => {
    workOrderStatus.WorkOrderStatus.forEach((element) => {
      element["is_change"] = false;
    });
    setState((s) => ({ ...s, status: [], toggleStatusFilter: false }));
  };
  //#endregion

  const renderPausedService = (totalLength) => (item, index) => {
    const { pause_obj } = item;
    if (item?.is_paused) {
      return (
        <RenderServiceOrder
          totalLength={totalLength}
          item={item}
          index={item}
          status={"Pause"}
          renderServiceType={renderServiceType}
          renderContractform={renderContractform}
          setState={setState}
          setSelectedService={setSelectedService}
          onViewService={onViewService}
          getPauseService={getPauseService}
          pause_obj={pause_obj}
          customerActive={props.customer?.is_active}
        />
        // <React.Fragment key={`service${index}`}>
        //   {renderPoolName(item)}
        //   <div className="flex position-relative">
        //     <strong
        //       className="snum pointer"
        //       onClick={() =>
        //         setState((s) => ({ ...s, isServiceDetail: true }), () => {
        //           this.onViewService(item);
        //         })
        //       }
        //     >
        //       {item?.service_order_number}
        //     </strong>
        //     {renderServiceType(item)}
        //     <span
        //       className="service-arrow"
        //       onClick={() =>
        //         setState((s) => ({ ...s, isServiceDetail: true }), () => {
        //           this.onViewService(item);
        //         })
        //       }
        //     >
        //       <img src={chevronRight} alt="" />
        //     </span>
        //   </div>
        //   <h5
        //     className="sadd pointer"
        //     onClick={() =>
        //       setState((s) => ({ ...s, isServiceDetail: true }), () => {
        //         this.onViewService(item);
        //       })
        //     }
        //   >
        //     {!isEmpty(item?.service_address?.formatted_address)
        //       ? `${item?.service_address?.formatted_address}`
        //       : "-"}
        //   </h5>
        //   {!!pause_obj && (
        //     <span className="pause-duration">
        //       Paused from{" "}
        //       {moment(pause_obj?.pause_from).format("MMMM DD, YYYY")} to{" "}
        //       {pause_obj?.pause_to
        //         ? moment(pause_obj?.pause_to).format("MMMM DD, YYYY")
        //         : "Indefinite"}{" "}
        //     </span>
        //   )}
        //   <span className="latitude">
        //     {!isEmpty(item?.service_address?.geo_location)
        //       ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
        //       : ``}
        //   </span>
        //   <br />
        //   <a href="/#">Swimming Pool Maintenance Program</a>
        //   {totalLength - 1 !== index && <hr />}
        // </React.Fragment>
      );
    }
  };

  const renderWorkOrders = () => {
    const {
      workOrderListPagination,
      workOrderCount,
      viewType,
      isDeleteWOModalVisible,
    } = state;
    return (
      <React.Fragment>
        <Table
          rowKey={(record) => record.work_order_number}
          className="tablebody service_history_table"
          dataSource={workOrderListTable}
          pagination={{ ...workOrderListPagination, total: workOrderCount }}
          columns={
            viewType === "upcoming"
              ? DataTable.UpComingWorkOrders({
                state: { ...state },
                setState,
                sendToOptimo,
              })
              : DataTable.HistoryWorkOrders({
                state: { ...state },
                setState,
                sendToOptimo,
              })
          }
          sortDirections={["descend", "ascend"]}
          onChange={onWorkOrderHandleChange}
          position={false}
        />
        <Modal
          visible={isDeleteWOModalVisible}
          okText={"Delete"}
          cancelText={"Cancel"}
          onOk={() => {
            deleteWorkOrder();
            setState((s) => ({
              ...s,
              isDeleteWOModalVisible: false,
            }));
          }}
          onCancel={() => {
            setState((state) => ({
              ...state,
              isDeleteWOModalVisible: false,
            }));
          }}
          className="delete_user_modal"
          width={400}
        >
          <h3>{`Delete ${viewType === "upcoming" ? "Upcoming" : "Past"
            } Work Order`}</h3>
          <p>
            {state.currentRecord?.has_lineitems
              ? `This Work Order has attached line items. Deleting it will also delete these line items.`
              : ``}
          </p>
          <p>{`Are you sure you would like to delete this ${viewType === "upcoming" ? "upcoming" : "past"
            } work order?`}</p>
          <p>Any changes made to this work order will be lost.</p>
        </Modal>
      </React.Fragment>
    );
  };

  const renderServiceType = (item) => {
    return (
      <span className="color-22232A fs-12">{item.service_order_name}</span>
    );
  };
  const renderPoolName = (item) => {
    return (
      <div style={{ textAlign: "right" }}>
        <span className="color-22232A fs-12">{item.pool_name}</span>
      </div>
    );
  };

  const renderContractform = (item) => {
    //Service Order
    let url = "";
    if (item.contract_forms.length > 0) {
      let t = item.contract_forms[0];
      url = "".concat(t.form_url.replace(/\/$/, ""), "/", t.form_data_uuid);
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {item.contract_forms[0].form_name}
        </a>
      );
    }
  };

  const reloadServices = () => {
    setSelectedService({});
    selectedServiceUUID.current = []
    setdeleteserviceorder(false);
    setactivatebutton(false);
    setdeactivatebutton(false);
    const customerId = props?.customer?.uuid;
    const viewType = { state };
    onChangeView(0);
    if (customerId) {
      setState((s) => ({
        ...s,
        scheduled_appointment__range: null,
        service_order__uuid: [],
        work_order_number: [],
        technician__uuid: [],
        status: [],
        isServiceDetail: false,
      }));

      workOrderStatus.WorkOrderStatus.forEach((element) => {
        element["is_change"] = false;
      });
      // props.viewServiceDetail(false, {});  //not needed so far
      getFieldTechnicianFilter();
      if (viewType === "upcoming") {
        getWorkOrderFilter({
          service_order__client__uuid: customerId,
          service_order__uuid: selectedService?.uuid,
          scheduled_appointment__gte: moment().format(
            "YYYY-MM-DD 00:00:00"
          ),
        });
      } else if (viewType === "history") {
        getWorkOrderFilter({
          service_order__uuid: selectedService?.uuid,
          scheduled_appointment__lt: moment()
            .subtract(1, "day")
            .format("YYYY-MM-DD 23:59:59"),
        });
      }
      stopRecall.current = true
      getworkOrders({
        service_order__client__uuid: customerId,
        scheduled_appointment__gte: moment().format(
          "YYYY-MM-DD 00:00:00"
        ),
      });
      getserviceOrder({
        field_office_uuid: props?.customer?.field_office?.uuid,
        client_uuid: customerId,
      }); // get service orders
    }
  }

  const renderActiveService = (totalLength) => (item, index) => {
    if (item?.is_active && !item?.is_hide_from_display) {
      return (
        <RenderServiceOrder
          totalLength={totalLength}
          item={item}
          index={item}
          status={item?.status}
          renderServiceType={renderServiceType}
          renderContractform={renderContractform}
          setState={setState}
          setSelectedService={setSelectedService}
          onViewService={onViewService}
          getPauseService={getPauseService}
          tabChange={tabChange}

        />
        // <React.Fragment key={`service${index}`}>
        //   {renderPoolName(item)}
        //   <div className="flex justify-content-start position-relative">
        //     <strong
        //       className="snum pointer"
        //       style={{ 'minWidth': '56px' }}
        //       onClick={() => {
        //         setState((s) => ({ ...s, isServiceDetail: true }));
        //         setSelectedService(item);
        //         onViewService(item);
        //       }}
        //     >
        //       {item?.service_order_number}
        //     </strong>
        //     {renderServiceType(item)}
        //   </div>
        //   <div className="position-relative">
        //     <span
        //       className="service-arrow"
        //       onClick={() => {
        //         setState((s) => ({ ...s, isServiceDetail: true }));
        //         onViewService(item);
        //         setSelectedService(item);
        //         getPauseService({ service_order__uuid: item?.uuid });
        //       }}
        //     >
        //       <img src={chevronRight} alt="" />
        //     </span>
        //     <h5
        //       className="sadd mb-4 pointer"
        //       style={{ wordWrap: "break-word" }}
        //       onClick={() => {
        //         setState((s) => ({ ...s, isServiceDetail: true }));
        //         onViewService(item);
        //         setSelectedService(item);
        //       }}
        //     >
        //       {!isEmpty(item?.service_address?.formatted_address)
        //         ? `${item?.service_address?.formatted_address}`
        //         : "-"}
        //     </h5>
        //     <span className="latitude pl-20 opacity-5 fs-11">
        //       {!isEmpty(item?.service_address?.geo_location)
        //         ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
        //         : ``}
        //     </span>
        //     <br />
        //     {renderContractform(item)}
        //   </div>
        //   {totalLength - 1 !== index && <hr />}
        // </React.Fragment>
      );
    }
  };

  const renderInquiryService = (totalLength) => (item, index) => {
    if (item?.is_active && !item?.is_paused && !item?.is_hide_from_display) {
      return (
        <RenderServiceOrder
          totalLength={totalLength}
          item={item}
          index={item}
          status={"Inquiry Service"}
          renderServiceType={renderServiceType}
          renderContractform={renderContractform}
          setState={setState}
          setSelectedService={setSelectedService}
          onViewService={onViewService}
          getPauseService={getPauseService}
        />
        // <React.Fragment key={`service${index}`}>
        //   {renderPoolName(item)}
        //   <div className="flex justify-content-start position-relative">
        //     <strong
        //       className="snum pointer"
        //       onClick={() => {
        //         setState((s) => ({ ...s, isServiceDetail: true, reviewServiceOrder: true }));
        //         onViewService(item);
        //       }}
        //     >
        //       {item?.service_order_number}
        //     </strong>
        //     {renderServiceType(item)}
        //   </div>
        //   <div className="position-relative">
        //     <span
        //       className="service-arrow"
        //       onClick={() => {
        //         setState((s) => ({ ...s, isServiceDetail: true, reviewServiceOrder: true }));
        //         onViewService(item);
        //       }}
        //     >
        //       <img src={chevronRight} alt="" />
        //     </span>
        //     <h5
        //       className="sadd mb-4 pointer"
        //       onClick={() => {
        //         setState((s) => ({ ...s, isServiceDetail: true, reviewServiceOrder: true }));
        //         onViewService(item);
        //       }}
        //     >
        //       {!isEmpty(item?.service_address?.formatted_address)
        //         ? `${item?.service_address?.formatted_address}`
        //         : "-"}
        //     </h5>
        //     <span className="latitude pl-20 opacity-5 fs-11">
        //       {!isEmpty(item?.service_address?.geo_location)
        //         ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
        //         : ``}
        //     </span>
        //     <br />
        //     <a href="/#">Swimming Pool Maintenance Program</a>
        //   </div>
        //   {totalLength - 1 !== index && <hr />}
        // </React.Fragment>
      );
    }
  };

  const renderInactiveService = (totalLength) => (item, index) => {
    if (!item?.is_active && !item?.is_hide_from_display) {
      return (
        <RenderServiceOrder
          totalLength={totalLength}
          item={item}
          index={item}
          status={"Inactive Service"}
          renderServiceType={renderServiceType}
          renderContractform={renderContractform}
          setState={setState}
          setSelectedService={setSelectedService}
          onViewService={onViewService}
          getPauseService={getPauseService}
        />
        // <React.Fragment key={`service${index}`}>
        //   {renderPoolName(item)}
        //   <div className="flex position-relative">
        //     <strong
        //       className="snum pointer"
        //       onClick={() =>
        //         setState({ isServiceDetail: true }, () => {
        //           onViewService(item);
        //         })
        //       }
        //     >
        //       {item?.service_order_number}
        //     </strong>
        //     {renderServiceType(item)}
        //     <span
        //       className="service-arrow"
        //       onClick={() =>
        //         setState({ isServiceDetail: true }, () => {
        //           onViewService(item);
        //         })
        //       }
        //     >
        //       <img src={chevronRight} alt="" />
        //     </span>
        //   </div>
        //   <h5
        //     className="sadd pointer"
        //     onClick={() =>
        //       setState({ isServiceDetail: true }, () => {
        //         onViewService(item);
        //       })
        //     }
        //   >
        //     {!isEmpty(item?.service_address?.formatted_address)
        //       ? `${item?.service_address?.formatted_address}`
        //       : "-"}
        //   </h5>
        //   <span className="latitude">
        //     {!isEmpty(item?.service_address?.geo_location)
        //       ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
        //       : ``}
        //   </span>
        //   <br />
        //   <a href="/#">Swimming Pool Maintenance Program</a>
        //   {totalLength - 1 !== index && <hr />}
        // </React.Fragment>
      );
    }
  };

  // on change view
  const onChangeView = (value) => {
    setState((s) => ({ ...s, selectedView: value }));
    if(value === 0){
      proposalData.current = {}
    }
  };

  // toggle edit service
  const toggleEditService = (value) => {
    setState((s) => ({ ...s, editService: value }));
  };

  const clearAllFilters = () => {
    const { customerId } = props?.customer?.uuid;
    setState((s) => ({
      ...s,
      scheduled_appointment__range: null,
      service_order__uuid: [],
      work_order_number: [],
      technician__uuid: [],
      status: [],
    }));

    clearServiceOrder();
    clearWorkOrder();
    clearTechnician();
    clearStatus();
    getworkOrders({
      service_order__client__uuid: customerId,
      scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
    });
  };

  const renderNewService = () => {
    const { serviceOrder } = state;
    const { results } = serviceOrder;

    const actives = results?.filter((item) => {
      return item?.status === "Active Order";
    });

    const isDisplay = results?.filter((item) => {
      return item?.is_hide_from_display === false && item?.status === "Active Order";
    });

    const isDisplayInactive = results?.filter((item) => {
      return item?.is_hide_from_display === false && item?.status === "Inactive Order";
    });

    const inactive = results?.filter((item) => {
      return item?.is_active === false;
    });
    const paused = results?.filter((item) => {
      return item?.is_paused === true;
    });

    return (
      <React.Fragment>
        <div className="flex singleservice">
          <div className="card-col service-order-card">
            <div className="card">
              <div className="card-body">
                <div className={props.customer?.is_active ? "flex mb-20" : "flex mb-20 disable-field"}>
                  <h3 className="so-heading mb-0">Service Orders</h3>
                  {props?.customer?.status !== "pending" && (
                    <button className="btn btn-warning" onClick={(e) => {
                      e.preventDefault();
                      setState((s) => ({ ...s, isEditEnquiry: false }));
                      onChangeView(2);
                    }
                  }>Create Service Order</button>
                  )}
                </div>
                {props?.customer?.status !== "pending" &&
                  actives &&
                  actives.length > 0 && isDisplay.length > 0 && (
                    <div className={props.customer?.is_active ? "service-status active-service" : "service-status active-service disable-field"}>
                      <div className="active_service_list color-252730">
                        <div className="subs">
                          <strong
                            className="color-22232A service-status-head"
                            style={{ marginBottom: "0px" }}
                          >
                            Active
                          </strong>
                          {map(actives, renderActiveService(actives.length))}
                        </div>
                      </div>
                    </div>
                  )}
                {this?.props?.customer?.status !== "pending" &&
                  paused &&
                  paused.length > 0 && (
                    <div className={props.customer?.is_active ? "service-status paused-service" : "service-status paused-service disable-field"}>
                      <div className="active_service_list lh-21 color-252730">
                        <div className="subs">
                          <strong
                            className="color-22232A service-status-head"
                            style={{ marginBottom: "0px" }}
                          >
                            Paused
                          </strong>
                          {map(paused, renderPausedService(paused.length))}
                        </div>
                      </div>
                    </div>
                  )}
                {(this?.props?.customer?.status === "pending" &&
                  this?.state?.enquiryList &&
                  this?.state?.enquiryList?.results?.length) > 0 && (
                    <div className={props.customer?.is_active ? "service-status inactive-service" : "service-status inactive-service disable-field"}>
                      <div className="active_service_list lh-21 color-252730">
                        <div className="subs">
                          <strong
                            className="color-22232A service-status-head"
                            style={{ marginBottom: "0px" }}
                          >
                            Inquired
                          </strong>
                          {map(
                            this?.state?.enquiryList?.results,
                            renderInquiryService(
                              this?.state?.enquiryList?.results?.length
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                {this?.props?.customer?.status !== "pending" &&
                  inactive &&
                  inactive.length > 0 && isDisplayInactive.length > 0 && (
                    <div className="service-status inactive-service">
                      <div className="active_service_list lh-21 color-252730">
                        <div className="subs">
                          <strong
                            className="color-22232A service-status-head"
                            style={{ marginBottom: "0px" }}
                          >
                            Inactive
                          </strong>
                          {map(
                            inactive,
                            renderInactiveService(inactive.length)
                          )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="w-100">
            <div className="work-orders-table">
              <div className="work-orders-toolbar">
                <div className="table-header sorting-btn-group flex ai-center">
                  <h3 className="mb-0">Work Orders</h3>
                  <div className="sorting flex ai-center no-bdr">
                    {props?.customer?.status !== "pending" && (
                      <>
                        <span className="mr-10">
                          {countAppliedFilters()} filters applied
                        </span>
                        <a
                          role="button"
                          href="/#"
                          className="mr-10"
                          onClick={(e) => {
                            e.preventDefault();
                            clearAllFilters();
                          }}
                        >
                          Clear all filters
                        </a>
                      </>
                    )}
                    <button
                      className={
                        state?.viewType === "upcoming"
                          ? "btn btn-upcoming"
                          : "btn btn-history"
                      }
                      onClick={() => {
                        upcomingWorkOrders();
                      }}
                    >
                      Upcoming
                    </button>
                    <button
                      className={
                        state?.viewType === "history"
                          ? "btn btn-upcoming"
                          : "btn btn-history"
                      }
                      onClick={() => {
                        historyWorkOrders();
                      }}
                    >
                      History
                    </button>
                   {props?.customer?.field_office?.is_optimoroute_enabled == true && <div className={props.customer?.is_active ? "position-relative status-dropdown" : "position-relative status-dropdown disable-field"}>
                      <div className="flex ai-center">
                        <button className={"btn btn-default ml-10"}>
                          Optimo Route{" "}
                          <img src={chevronDown} className="ml-5" alt="" />
                        </button>
                      </div>
                      <ul className="status-dropdownmenu">
                        <li>
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              sendToOptimo(state.workOrderList);
                            }}
                          >
                            Send to Optimo Route
                          </a>
                        </li>
                        <li>
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            Synchronize to Optimo Route
                          </a>
                        </li>
                      </ul>
                    </div>}
                  </div>
                </div>
                {props?.customer?.status !== "pending" && (
                  <div className="sorting sorting-fields flex no-bdr">
                    <div className="fitem date-range-field mr-10">
                      <label>Date Range</label>
                      <RangePicker
                        format="MMM D, YYYY"
                        defaultValue={
                          !!state.startDate && !!state.endDate
                            ? [moment(state.startDate), moment(state.endDate)]
                            : []
                        }
                        onChange={(e) => {
                          onDateRangeChange(e);
                        }}
                      />
                    </div>
                    <div className="fitem service-order-field mr-10">
                      <label>Service Order</label>
                      {renderServiceOrderFilters()}
                    </div>
                    <div className="fitem service-order-field mr-10">
                      <label>Work Order</label>
                      {renderWorkOrderFilters()}
                    </div>
                    <div className="fitem service-order-field mr-10">
                      <label>Technician</label>
                      {renderTechnicianFilters()}
                    </div>

                    <div className="fitem service-order-field">
                      <label>Status</label>
                      {renderStatusFilters()}
                    </div>
                  </div>
                )}
              </div>
              {this?.props?.customer?.status !== "pending" &&
                renderWorkOrders()}
              {this?.props?.customer?.status === "pending" && (
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                  <span className="noWorkOrder">
                    <ToolFilled />
                  </span>
                  <p className="mb-10" style={{ fontWeight: "bold" }}>
                    {state?.viewType === "upcoming"
                      ? "No Upcoming Work Orders"
                      : "No History Work Orders"}
                  </p>
                  <p>Work orders will appear here when a service is active.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const sendToOptimo = async (list) => {
    if (list.length > 0) {
      const woToSend = list[0];

      setLoader(true);
      let params = {
        service_uuid_list: [woToSend.uuid],
        allocation_start_date: moment(woToSend.scheduled_appointment).format(
          "YYYY-MM-DD"
        ),
        field_office__uuid: props.customer?.field_office?.uuid,
      };

      const data = await sendToOptimoRoute(params);

      setLoader(false);
      if (data.message.includes("wrong")) message.error(data.message);
      else {
        message.success("Work orders sent to OptimoRoute!");
      }
    } else message.info("There are not work orders to send, please verify.");
  };

  const deleteWorkOrder = async () => {
    const { currentRecord, viewType } = state;

    const response = await deleteService(currentRecord.uuid);
    message.success(`Work ${currentRecord.work_order_number} deleted`);

    if (viewType === "upcoming") {
      getworkOrders({
        service_order__client__uuid: props?.customer?.uuid,
        ...(selectedService
          ? { service_order__uuid: selectedService?.uuid }
          : null),
        scheduled_appointment__gte: moment().format("YYYY-MM-DD 00:00:00"),
      });
    } else if (viewType === "history") {
      getworkOrders({
        service_order__client__uuid: props?.customer?.uuid,
        ...(selectedService
          ? { service_order__uuid: selectedService?.uuid }
          : null),
        scheduled_appointment__lt: moment()
          .subtract(1, "day")
          .format("YYYY-MM-DD 23:59:59"),
      });
    }
    dispatch(getCommercialDates(props?.customer?.uuid))
  };

  const goback = (e) => {
    const customerId = props.customer.uuid;
    setState((state) => ({ ...state, showAddons: false }));
    if (customerId) {
      onFilterChange();
    }
    if (selectedService && Object.keys(selectedService).length > 0) {
      // props.getServiceOrderById(
      //   selectedService?.uuid,
      //   this?.props?.customer?.status === "pending"
      // );
    }
  };

  const toggleSidebar = (value) => {
    if (value !== state.showSidebar)
      setState((s) => ({ ...s, showSidebar: value }));
  };

  //render Service Details
  const renderServiceDetails = (item, index) => {
    if (
      selectedService?.uuid === item?.uuid &&
      selectedService?.service_order_number === item?.service_order_number
    ) {
      return (
        <React.Fragment key={`serviceDetail${index}`}>
          <div className="mt-10">
            <div className="">
              <h5>Service Details</h5>
              <div className="sdetail">
                <div className="subs">
                  <span className="service-type-title">Service Type</span>
                  {
                    <div className="flex">
                      <div className="flex-1 text-capitalize">
                        {item.service_type}
                        {item?.service_type?.toLowerCase() === "maintenance" ? (
                          item.is_flat_rate ? (
                            " Flat Rate"
                          ) : (
                            " Standard Rate"
                          )
                        ) : item?.service_type?.toLowerCase() === "service" ? (
                          <>
                            {` > ${item?.worktype} ${item?.worksubtype ? "> " + item?.worksubtype : ""
                              } `}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="sdetail">
                <div className="subs">
                  <span className="service-type-title">
                    Frequency Selection
                  </span>
                  {
                    <div className="flex">
                      <div className="flex-1">
                        {item?.service_type?.toLowerCase() === "service"
                          ? "N/A"
                          : item.frequency}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="sdetail">
                <div className="subs">
                  <span className="service-type-title">Pool Type</span>
                  {
                    <div className="flex">
                      <div className="flex-1 text-capitalize">
                        {item.pool_type}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="sdetail">
                <div className="subs">
                  <span className="service-type-title">
                    Pool size in gallons
                  </span>
                  <span className="service-type-body">{item?.pool_size}</span>
                </div>
              </div>
              {item?.service_type?.toLowerCase() === "service" ? (
                <>
                  <div className="sdetail">
                    <div className="subs">
                      <span className="service-type-title">
                        Problem Description Notes
                      </span>
                      <span className="service-type-body">
                        {item?.notes ? item?.notes : ""}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="sdetail">
                <div className="subs">
                  <span className="service-type-title">
                    Preferred Technician
                  </span>
                  {selectedService?.preferred_technician?.user?.first_name && (
                    <span className="service-type-body">{`${selectedService?.preferred_technician?.user?.first_name} ${selectedService?.preferred_technician?.user?.last_name}`}</span>
                  )}
                  {!selectedService?.preferred_technician?.user?.first_name && (
                    <span className="service-type-body">None</span>
                  )}
                </div>
              </div>
              <hr />
            </div>
            <div className="mt-10">
              <div className="">
                <div className="sdetail">
                  <div className="subs">
                    <span className="flex ai-center">
                      <b>Servicer Order Total (Monthly Charge) </b>
                      <b className="mr-0">$ {Number(selectedService?.service_price)?.toFixed(2) || '0'}</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };
  // Hide Delete Service Orders Confirmation Popup Residential//
  const onHideCancelPopup = () => {
    setdeleteconfirmationpopup(false)
  }
  // Delete Service Orders Residential Popup API Call Button//
  const get_delete_service_orders = async (e) => {
    setLoader(true);
    const data = await delete_service_orders_details(selectedService.uuid);
    setdeleteconfirmationpopup(false)
    setLoader(false);
    if (!isEmpty(data)) {
      await goback();
      message.success("The service order has been deleted");
    } else {
      message.error("Something went wrong!..");
    }
    setTimeout(function () { reloadServices(); }, 400);
  }
  // Show Delete Service Orders Confirmation Popup Residential//
  const service_order_delete_confirmation = () => {
    setdeleteconfirmationpopup(true)
  }
  const cancelbutton = () => {
    setdeleteconfirmationpopup(true)
  }
  // render service overview
  const renderServiceOverview = (item, isEnquiry) => {
    const {
      serviceOrder,
      deActivate,
      isModalVisible,
      resumeModalVisible,
      preferred_start_date,
      errors,
      reviewServiceOrder,
    } = state;
    const isPending = props?.customer?.status === "pending";

    return (
      <React.Fragment>
        <div className={!props.customer?.is_active && "disable-field"}>
          <span style={{ color: "#1F1F25", fontSize: 12 }}>
            {item?.service_order_number ? `${item?.service_order_number}` : ""}
            <span
              style={{
                textTransform: "capitalize",
                opacity: "0.5",
                marginLeft: "10px",
              }}
            >
              {item?.service_type ? ` ${item?.service_type} ` : ""}
            </span>
            {item?.service_type?.toLowerCase() === "maintenance" ? (
              <>
                <span style={{ textTransform: "capitalize", opacity: "0.5" }}>
                  {item?.worksubtype ? " - " + item?.worksubtype : ""}
                </span>
              </>
            ) : item?.service_type?.toLowerCase() === "service" ? (
              <>
                <span style={{ textTransform: "capitalize", opacity: "0.5" }}>
                  {item?.worktype} {item?.worksubtype ? item?.worksubtype : ""}
                </span>
              </>
            ) : (
              ""
            )}
          </span>
          <div className="flex" style={{ alignItems: "center" }}>
            <h5 style={{ paddingRight: 10, marginTop: 6, marginBottom: 2 }}>
              {!isEmpty(item?.service_address)
                ? `${item?.service_address?.address}, ${item?.service_address?.city}, ${item?.service_address?.state} ${item?.service_address?.zip_code}`
                : "-"}
            </h5>
            {!isPending && !reviewServiceOrder && (
              <>
                {selectedService.is_active == true && (
                  <span className="status success-status drk">ACTIVE</span>
                )}
                {item?.status?.toLowerCase() === "paused" &&
                  !reviewServiceOrder && (
                    <span className="status warning-status drk">PAUSED</span>
                  )}
                {selectedService.is_active == false && (
                  <span className="status gray-status drk">INACTIVE</span>
                )}
              </>
            )}
            {isPending && !reviewServiceOrder && (
              <span className="status info-status drk">PENDING</span>
            )}
            {reviewServiceOrder && (
              <span className="status info-status drk">INQUIRED</span>
            )}
          </div>
          <span className="latitude opacity-5 fs-11">
            {!isEmpty(item?.service_address?.geo_location)
              ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
              : `not`}
          </span>
          <div className="pb-0 flex mt-10">
            {reviewServiceOrder && (
              <button
                className="btn btn-warning px-10 flex-1"
                onClick={() => {
                  setState((s) => ({ ...s, isEditEnquiry: false }));
                  this.props.toggleSidebar(true);
                  this.onChangeView(3);
                }}
              >
                Review Service Order
              </button>
            )}
            {!isPending && (
              <>
                <button
                  className={`btn btn-warning px-10 flex-1 ${item?.batch_invoice_processed ? 'disabled': ''}`}
                  onClick={() => toggleEditService(true)}
                >
                  Edit this Service Order
                </button>
                {/* {selectedService?.status?.toLowerCase() !== "active" &&
                  selectedService?.status?.toLowerCase() !== "active order" && (
                    <button
                        className={
                          `btn btn-white-background btn-bdr flex ai-center justify-content-center px-10 disable ${item?.batch_invoice_processed ? 'disabled': ''}`
                        }>
                      <span className="mr-10">
                        <PlayCircleOutlined />{" "}
                      </span>
                      Pause
                    </button>
                  )} */}
                {/* {selectedService?.status?.toLowerCase() !== "paused" &&
                  (selectedService?.status?.toLowerCase() === "active" ||
                    selectedService?.status?.toLowerCase() ===
                    "active order") && (
                    <button
                      className={
                        selectedService?.status?.toLowerCase() !== "active" &&
                          selectedService?.status?.toLowerCase() !== "active order"
                          ? `btn btn-white-background btn-bdr flex ai-center justify-content-center px-10 disable ${item?.batch_invoice_processed ? 'disabled': ''}`
                          : `btn btn-white-background btn-bdr flex ai-center justify-content-center px-10 ${item?.batch_invoice_processed ? 'disabled': ''}`
                      }
                      onClick={() => {
                        this.props.getPauseService({
                          service_order__uuid: selectedService?.uuid,
                        }); // get pause service list
                        this.togglePauseService(true);
                      }}
                    >
                      <span className="mr-10">
                        <PlayCircleOutlined />{" "}
                      </span>
                      Pause
                    </button>
                  )} */}
              </>
            )}
            {selectedService?.status?.toLowerCase() === "paused" &&
              !isPending && (
                <React.Fragment>
                  <button
                    className="btn btn-brand btn-bdr flex ai-center justify-content-center px-10"
                    onClick={() => {
                      this.setState({ resumeModalVisible: true });
                    }}
                  >
                    <img className="mr-5" alt="" />
                    Resume
                  </button>
                  <Modal
                    visible={resumeModalVisible}
                    okText={"Resume Service Order"}
                    cancelText={"Cancel"}
                    onOk={() => {
                      this.resumeServiceOrder();
                      this.setState({ resumeModalVisible: false });
                    }}
                    onCancel={() => {
                      this.setState({ resumeModalVisible: false });
                    }}
                    className="delete_user_modal"
                    width={400}
                  >
                    <h3>Resume Service Order</h3>
                    <p>This service order is currently on a pause:</p>
                    <div className="alert-dateblock">
                      <span>
                        {moment(selectedService?.pause_obj?.pause_from).format(
                          "MMMM DD, YYYY"
                        )}{" "}
                        to{" "}
                        {selectedService?.pause_obj?.pause_to
                          ? moment(selectedService?.pause_obj?.pause_to).format(
                            "MMMM DD, YYYY"
                          )
                          : "Indefinite"}
                      </span>
                      <span>{selectedService?.pause_obj?.reason?.reason}</span>
                    </div>
                  </Modal>
                </React.Fragment>
              )}
            {!isPending && (
              <button className="btn dots-button" onClick={onclickonthreedots}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            )}
          </div>
          {/* <div>
            {deleteserviceorder && (
              <button
                onClick={service_order_delete_confirmation}
                style={{backgroundColor: "white",
                color: "rgb(0, 93, 137)",
                borderColor: "rgb(0, 93, 137)",
                display: "flex",
                marginTop: "25px",
                // marginLeft: "0px",
              }}
                className="btn btn-warning">
                  Delete Service Orders
              </button>
            )}
            </div> */}

          <div style={{ display: 'flex' }}>
            {deleteserviceorder && (
              <>
                <button
                  onClick={service_order_delete_confirmation}
                  style={{
                    backgroundColor: "white",
                    color: "rgb(0, 93, 137)",
                    borderColor: "rgb(0, 93, 137)",
                    // display: "flex",
                    marginTop: "25px",
                    padding: "8px 4px",
                    // marginLeft: "0px",
                  }}
                  className={`btn btn-warning ${item?.batch_invoice_processed ? 'disabled': ''}`}>
                  Delete Service Order
                </button>
                <Modal title="Error Modal"
                  visible={deleteconfirmationpopup}
                  okText={"Delete"}
                  cancelText={"Cancel"}
                  onCancel={() => onHideCancelPopup()}
                  onOk={get_delete_service_orders}
                  className="delete_user_modal"
                  width={400}><div>
                    <h3> Delete Service Order </h3>
                    <p>Are you sure you would like to delete this Service orders?</p>
                    <p>All Forecast and scheduled work orders will be cancelled and deleted</p>
                  </div>
                </Modal>
              </>
            )}
            {(selectedService.is_active && deactivatebutton) && (
              <button
                onClick={onclickondeactivaceSO}
                style={{
                  backgroundColor: "white",
                  color: "rgb(0, 93, 137)",
                  borderColor: "rgb(0, 93, 137)",
                  // display: "flex",
                  marginTop: "25px",
                  padding: "8px 4px",
                  // marginLeft: "118px",
                }}
                className={`btn btn-warning ${item?.batch_invoice_processed ? 'disabled': ''}`}
              >
                Deactivate Service Order
              </button>
            )}
            {(!selectedService.is_active && activatebutton) && (
              <button
                onClick={onclickonactivaceSO}
                style={{
                  backgroundColor: "white",
                  color: "rgb(0, 93, 137)",
                  borderColor: "rgb(0, 93, 137)",
                  // display: "flex",
                  marginTop: "25px",
                  padding: "8px 4px",
                  // marginLeft: "118px",
                }}
                className={`btn btn-warning ${item?.batch_invoice_processed ? 'disabled': ''}`}
              >
                Reactivate Service Order
              </button>
            )}
          </div>

          <Modal
            title="Error Modal "
            visible={deactivatemodleconfirmation}
            okText={"Deactivate"}
            cancelText={"Cancel"}
            onCancel={() => ondeactivatecanclebutton()}
            onOk={onclickonokbutton}
            className="delete_user_modal"
            width={400}
          >
            <div>
              <h3> Deactivate Service Order </h3>
              <p>
                Are you sure you would like to deactivate this service order?
              </p>
              <p>All forecast orders will be cancelled and deleted.</p>
              <p>Any Scheduled work orders need to be determined manually.</p>
            </div>
          </Modal>
          <Modal
            title="Error Modal "
            visible={activatemodleconfirmation}
            okText={"Reactivate"}
            cancelText={"Cancel"}
            onCancel={() => onactivatecanclebutton()}
            onOk={onclickonactiveokbutton}
            className="delete_user_modal"
            width={400}
          >
            <div>
              <h3> Reactivate Service Order </h3>
              <p>
                Are you sure you would like to reactivate this service order?
              </p>
            </div>
          </Modal>
          {deActivate && (
            <div className="pb-0 flex pt-10 justify-content-end">
              {selectedService?.is_active && (
                <button
                  className={`btn btn-brand btn-bdr flex ai-center justify-content-center px-10 ${item?.batch_invoice_processed ? 'disabled': ''}`}
                  onClick={() => this.setIsModalVisible(true)}
                >
                  Deactivate Service Order
                </button>
              )}
              {!selectedService?.is_active && (
                <button
                  className={`btn btn-brand btn-bdr flex ai-center justify-content-center px-10 ${item?.batch_invoice_processed ? 'disabled': ''}`}
                  onClick={() => this.setIsModalVisible(true)}
                >
                  Reactivate Service Order
                </button>
              )}

              <Modal
                visible={isModalVisible}
                okText={`${selectedService?.is_active ? "Deactivate" : "Reactivate"
                  }`}
                cancelText={"Cancel"}
                onOk={() => {
                  if (selectedService?.is_active) {
                    this.deactivateServiceOrder();
                    setTimeout(() => {
                      this.setIsModalVisible(false);
                    }, 1500);
                  } else {
                    this.reactivateServiceOrder();
                  }
                }}
                onCancel={() => {
                  this.setState({
                    preferred_start_date: "",
                    errors: {},
                  });
                  this.setIsModalVisible(false);
                }}
                className="delete_user_modal"
                width={400}
              >
                <h3>{`${selectedService?.is_active ? "Deactivate" : "Reactivate"
                  } Service Order`}</h3>
                <p>{`Are you sure you would like to ${selectedService?.is_active ? "deactivate" : "reactivate"
                  } this service order?`}</p>
                {selectedService?.is_active && (
                  <React.Fragment>
                    <p>
                      All Forecast work orders will be cancelled and deleted.
                    </p>
                    <p>
                      Any Scheduled work orders will need to be determined
                      manually
                    </p>
                  </React.Fragment>
                )}
                {!selectedService?.is_active &&
                  selectedService?.frequency !== "once" && (
                    <React.Fragment>
                      <p>
                        As this is a recurring service order, please specify the
                        date to resume service at this button.
                      </p>
                      <div className="field-group">
                        <label>
                          Next Service<sup>*</sup>
                        </label>
                        <DatePicker
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          name="preferred_start_date"
                          disabledDate={(current) => {
                            return current && current <= moment().endOf("day");
                          }}
                          suffixIcon={<i className="las la-calendar"></i>}
                          allowClear={true}
                          value={preferred_start_date}
                          onChange={(e) => {
                            if (e) {
                              this.setState((s) => ({
                                preferred_start_date: e,
                              }));
                            } else {
                              this.setState((s) => ({
                                preferred_start_date: "",
                              }));
                            }
                          }}
                        />
                        {errors?.["preferred_start_date"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["preferred_start_date"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </React.Fragment>
                  )}
              </Modal>
            </div>
          )}
          <hr />
          {!reviewServiceOrder && (
            <div className="active_service_list lh-21 color-252730">
              {map(serviceOrderList, renderServiceDetails)}
              {/* {renderServiceDetails(serviceOrder?.results.find(i => i.uuid === state.selectedService.uuid), 0)} */}
            </div>
          )}
          {reviewServiceOrder && (
            <div className="active_service_list lh-21 color-252730">
              {renderServiceDetails(selectedService, 0)}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderSelectedService = () => {
    const { pauseList, is_upcoming, isDeletePause, viewType } = state;
    const customerId = props?.customer?.uuid;
    const { is_paused, pause_obj } = selectedService;
    const { upcoming, history } = pauseList;
    const isPending = props?.customer?.status === "pending";
    return (
      <React.Fragment>
        <div className="filter_wrapper">
          <ul className="breadcrumb">
            <li>
              <span
                onClick={() => {
                  reloadServices();
                }}
              >
                Services
              </span>
            </li>
            <li>
              <span>
                {!isEmpty(selectedService?.service_address)
                  ? `${selectedService?.service_address?.address}, ${selectedService?.service_address?.city}, ${selectedService?.service_address?.state} ${selectedService?.service_address?.zip_code}`
                  : "-"}
              </span>
            </li>
          </ul>
        </div>
        <div className="flex singleservice">
          <div className="card-col service-order-card">
            <div className="card">
              <div className="card-body">
                {renderServiceOverview(selectedService)}
              </div>
            </div>
            {/* {!isPending && (
              <div className="card pause-card mt-20">
                <div className="card-head flex ai-center">
                  <h5>Pauses</h5>
                  <div className="sorting-btn-group flex ai-center">
                    <button
                      type="button"
                      className={
                        is_upcoming === true
                          ? "btn btn-upcoming"
                          : "btn btn-history"
                      }
                      onClick={(e) => {
                        setState((state) => ({
                          ...state,
                          is_upcoming: true,
                        }));
                      }}
                    >
                      Upcoming
                    </button>
                    <button
                      type="button"
                      className={
                        is_upcoming === false
                          ? "btn btn-upcoming"
                          : "btn btn-history"
                      }
                      onClick={(e) => {
                        setState((state) => ({
                          ...state,
                          is_upcoming: false,
                        }));
                      }}
                    >
                      History
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  {is_upcoming && (
                    <React.Fragment>
                      {map(
                        upcoming && upcoming.length > 0 && upcoming,
                        (item, index) => {
                          return (
                            <div
                              className="pause-list-block flex ai-center"
                              key={`pauseService${index}`}
                            >
                              <div className="pause-heading">
                                <a href="/#">
                                  {item?.pause_from
                                    ? moment(item?.pause_from).format(
                                      "MMM DD, YYYY"
                                    )
                                    : ""}{" "}
                                  to{" "}
                                  {item?.pause_to
                                    ? moment(item?.pause_to).format(
                                      "MMM DD, YYYY"
                                    )
                                    : "Indefinite"}
                                </a>
                                <span>{item?.reason?.reason}</span>
                              </div>
                              <div className="flex ai-center">
                                {this.isServiceInProgress(
                                  item?.pause_from,
                                  item?.pause_to
                                ) && (
                                    <button
                                      type="button"
                                      className="btn btn-inprogress"
                                    >
                                      In Progress
                                    </button>
                                  )}
                                {!this.isServiceInProgress(
                                  item?.pause_from,
                                  item?.pause_to
                                ) && (
                                    <button
                                      type="button"
                                      className="btn btn-upcoming"
                                    >
                                      Upcoming
                                    </button>
                                  )}
                                <div className="position-relative pause-dropdown">
                                  <button className="dots-button">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </button>
                                  <ul className="pause-dropdownmenu">
                                    <li>
                                      <a
                                        href="/#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.editUpcomingPause(item);
                                        }}
                                      >
                                        Edit Pause
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="/#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.confirmDeletePause(
                                            item?.uuid,
                                            "upcoming"
                                          );
                                        }}
                                      >
                                        Delete Pause
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                      {(!upcoming || upcoming.length === 0) && (
                        <span>No pause upcoming found</span>
                      )}
                    </React.Fragment>
                  )}
                  {!is_upcoming && (
                    <React.Fragment>
                      {map(
                        history && history.length > 0 && history,
                        (item, index) => {
                          return (
                            <div
                              className="pause-list-block flex ai-center"
                              key={`pauseService${index}`}
                            >
                              <div className="pause-heading">
                                <a href="/#">
                                  {item?.pause_from
                                    ? moment(item?.pause_from).format(
                                      "MMM DD, YYYY"
                                    )
                                    : ""}{" "}
                                  to{" "}
                                  {item?.pause_to
                                    ? moment(item?.pause_to).format(
                                      "MMM DD, YYYY"
                                    )
                                    : "Indefinite"}
                                </a>
                                <span>{item?.reason?.reason}</span>
                              </div>
                            </div>
                          );
                        }
                      )}
                      {(!history || history.length === 0) && (
                        <span>No pause history found</span>
                      )}
                    </React.Fragment>
                  )}
                  <Modal
                    visible={isDeletePause}
                    okText={"Delete"}
                    cancelText={"Cancel"}
                    onOk={() => {
                      this.deletePause();
                      setState((state) => ({
                        ...state,
                        isDeletePause: false,
                      }));
                    }}
                    onCancel={() => {
                      setState((state) => ({
                        ...state,
                        isDeletePause: false,
                      }));
                    }}
                    className="delete_user_modal"
                    width={400}
                  >
                    <h3>Delete Pause?</h3>
                    <p>Are you sure you would like to delete this pause?</p>
                    <p>Once deleted, it will be removed from the pause panel</p>
                  </Modal>
                </div>
              </div>
            )} */}
          </div>

          <div className="work-orders-table">
            {!!is_paused && (
              <div className="order-alert-warning">
                <div>
                  <p>
                    This service order is currently paused and may affect
                    upcoming work orders:
                  </p>
                  <div className="alert-dateblock">
                    <span>
                      {moment(pause_obj?.pause_from).format("MMMM DD, YYYY")} to{" "}
                      {pause_obj?.pause_to
                        ? moment(pause_obj?.pause_to).format("MMMM DD, YYYY")
                        : "Indefinite"}
                    </span>
                    {pause_obj?.reason?.reason}
                  </div>
                </div>
                <div className="flex ai-center alert-cation-btn">
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      this.props.updatePauseService(pause_obj?.uuid, {
                        is_end_pause: true,
                      });
                      setTimeout(() => this.rerender(), 1000);
                    }}
                    okText="Yes"
                    cancelText="No"
                    overlayClassName={"popup-width"}
                  ></Popconfirm>
                  <a
                    role="button"
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.togglePauseService(true);
                    }}
                  >
                    <img src={editIcon} alt="" />
                    Edit Pause
                  </a>
                </div>
              </div>
            )}
            <div className="work-orders-toolbar">
              <div className="table-header sorting-btn-group flex ai-center">
                <h3 className="mb-0">Work Orders</h3>
                <div className="sorting flex ai-center no-bdr">
                  <button
                    className={
                      state?.viewType === "upcoming"
                        ? "btn btn-upcoming"
                        : "btn btn-history"
                    }
                    onClick={() => {
                      upcomingWorkOrders();
                    }}
                  >
                    Upcoming
                  </button>
                  <button
                    className={
                      state?.viewType === "history"
                        ? "btn btn-upcoming"
                        : "btn btn-history"
                    }
                    onClick={() => {
                      historyWorkOrders();
                    }}
                  >
                    History
                  </button>
                  {!isPending &&
                    selectedService.is_active ==
                    true && (
                      <button
                        className={props.customer?.is_active ? "btn btn-brand btn-bdr flex ai-center" : "btn btn-brand btn-bdr flex ai-center disable-field"}
                        onClick={() => {
                          setState((state) => ({
                            ...state,
                            showAddons: true,
                            currentRecord: selectedService,
                            transection_type: "Create",
                          }));
                          dispatch(setNewWOFlag(true))
                        }}
                      >
                        <PlusCircleOutlined style={{ marginRight: "6px" }} />{" "}
                        Create Work Order
                      </button>
                    )}
                  {!isPending && selectedService.is_active == false && (
                    <button
                      disabled={true}
                      className={props.customer?.is_active ? "btn btn-brand btn-bdr flex ai-center" : "btn btn-brand btn-bdr flex ai-center disable-field"}
                    >
                      <PlusCircleOutlined style={{ marginRight: "6px" }} />{" "}
                      Create Work Order
                    </button>
                  )}
                </div>
              </div>
              {!isPending && (
                <div className="sorting sorting-fields flex no-bdr">
                  <div className="fitem date-range-field mr-10">
                    <label>Date Range</label>
                    <RangePicker
                      format="MMM D, YYYY"
                      defaultValue={
                        !!state.startDate && !!state.endDate
                          ? [moment(state.startDate), moment(state.endDate)]
                          : []
                      }
                      onChange={(e) => {
                        onDateRangeChange(e);
                      }}
                    />
                  </div>
                  <div className="fitem service-order-field mr-10">
                    <label>Work Order</label>
                    {renderWorkOrderFilters()}
                  </div>
                  <div className="fitem service-order-field mr-10">
                    <label>Technician</label>
                    {renderTechnicianFilters()}
                  </div>
                  <div className="fitem service-order-field">
                    <label>Status</label>
                    {renderStatusFilters()}
                  </div>
                </div>
              )}
            </div>
            {!isPending && renderWorkOrders()}
            {isPending && (
              <div style={{ textAlign: "center", fontSize: "14px" }}>
                <span className="noWorkOrder">
                  <ToolFilled />
                </span>
                <p className="mb-10" style={{ fontWeight: "bold" }}>
                  {state?.viewType === "upcoming"
                    ? "No Upcoming Work Orders"
                    : "No History Work Orders"}
                </p>
                <p> Work orders will appear here when a service is active.</p>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getCheckZipCode = async () => {
    const data = await checkZipCode({
      addressable_zip_codes__contains:
        props?.customer?.field_office?.addressable_zip_codes[0],
      close: false,
    });
    if (data?.count > 0) {
      setcheckZipCodeData(data.results[0]);
    }
  };

  const renderViews = () => {
    const { selectedView, enquiryList, isEditEnquiry } = state;
    switch (selectedView) {
      case 0:
        return renderNewService();
      case 1:
        return renderSelectedService();
      case 2:
        return (
          <React.Fragment>
            <div className="filter_wrapper">
              <ul className="breadcrumb">
                <li>
                  <span
                    role="button"
                    className="backbtn"
                    onClick={() => {
                      toggleSidebar(true);
                      onChangeView(0);
                    }}
                  >
                    <img src={chevronLeft} alt="" />
                    {`Back to all services`}
                  </span>
                </li>
              </ul>
            </div>
            <AddService
              customerId={props.customer.id}
              onChangeView={onChangeView}
              customer={props.customer}
              checkZipCode={checkZipCodeData}
              getserviceOrder={getserviceOrder}
              getworkOrders={getworkOrders}
              clearAllFilters={clearAllFilters}
              serviceOrder={serviceOrderList}
              proposalInfo={proposalData.current}
            />
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <div className="filter_wrapper">
              <ul className="breadcrumb">
                <li
                  onClick={() => this.backToResidentialList()}
                  className="fs-14"
                >
                  <span className="pointer">
                    <i className="las la-angle-left"></i> Back to All
                    Residential Customers
                  </span>
                </li>
              </ul>
            </div>
            <ReviewService
              {...{ ...this.props, ...state }}
              customerId={this.props.customer.id}
              onChangeView={this.onChangeView}
              backToResidentialList={this.backToResidentialList}
              // service={enquiryList?.results?.[0]}
              // field_office_uuid={enquiryList?.results?.[0]?.field_office_uuid || ''}
              // isEditEnquiry={isEditEnquiry}
              // reducer
              zipcodeCheck={this.props.zipcodeCheck}
              PricingCalc={this.props.pricingCalc}
              serviceOrderEnquiry={this.props.serviceOrderEnquiry}
              customerList={this.props.customerList}
              AddCard={this.props.AddCard}
              serviceOrder={this.props.serviceOrder}
              CardList={this.props.CardList}
              Service={this.props.service}
              fieldTechnicianFilter={this.props.fieldTechnicianFilter}
              // api
              getServiceOrder={this.props.getServiceOrder}
              getFieldOfficeById={this.props.getFieldOfficeById}
              getFranchiseById={this.props.getFranchiseById}
              pricingCalculator={this.props.pricingCalculator}
              resetCheckZipcode={this.props.resetCheckZipcode}
              resetPricingCalculator={this.props.resetPricingCalculator}
              getServiceOrderEnquiry={this.props.getServiceOrderEnquiry}
              updateServiceOrderEnquiry={this.props.updateServiceOrderEnquiry}
              checkZipCode={this.props.checkZipCode}
              getCustomerDetail={this.props.getCustomerDetail}
              addNewCard={this.props.addNewCard}
              addServiceOrder={this.props.addServiceOrder}
              updateServiceOrder={this.props.updateServiceOrder}
              updatePartialServiceOrder={this.props.updatePartialServiceOrder}
              getCardList={this.props.getCardList}
              deleteCard={this.props.deleteCard}
              deActivateCustomerConfirm={this.props.deActivateCustomerConfirm}
              updateCustomer={this.props.updateCustomer}
              cancelReviewService={this.cancelReviewService}
            />
          </React.Fragment>
        );
      default:
        return renderNewService();
    }
  };

  const render = () => {
    const { addOns, showAddons, currentRecord, transection_type, editService } =
      state;

    if (showAddons) {
      return (
        <EditWorkOrder
          currentRecord={currentRecord}
          customer={props.customer}
          transection_type={transection_type}
          goback={goback}
          service={selectedService}
          setSelectedService={setSelectedService}
        />
      );
    }
    return (
      <div className="block-row p-0">
        {loader && <Loader />}
        {loader_workOrder && <Loader />}
        <div className="container">{renderViews()}</div>
        {editService && (
          <EditService
            visible={editService}
            service={selectedService}
            addOns={addOns}
            closeDrawer={() => toggleEditService(false)}
            customerId={props?.customer?.uuid}
            customer={state.customer}
            fieldOfficeUUID={props?.customer?.field_office?.uuid}
            onEditSuccess={() =>
              setState((s) => ({ ...s, isUpdateService: true }))
            }
            serviceMatrix={serviceMatrix}
            workOrder={state.workOrderList[0]}
            setSelectedService={setSelectedService}
            getserviceOrder={getserviceOrder}
            serviceOrderList={serviceOrderList}
            setserviceOrderList={setserviceOrderList}
            // getserviceOrder = {getserviceOrder}
            // viewService={viewService}
            GoogleApi={props.GoogleApi}
            getWorkOrders={() => {
              getworkOrders({
                service_order__client__uuid: props?.customer?.uuid,
                service_order__uuid: state.selectedService?.uuid,
                scheduled_appointment__gte: moment().format(
                  "YYYY-MM-DD 00:00:00"
                ),
              });
            }}
          />
        )}
      </div>
    );
  };

  return render();
};

export default Services;
