import React, { useEffect, useRef, useState } from "react";
import { Drawer, message, Select, TimePicker, Row, Col } from "antd";
import moment from 'moment-timezone';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { map, isEmpty, orderBy } from "lodash";
import Script from "react-load-script";
import * as yup from 'yup'
import cross_icon from "../../../../Assets/images/cross_icon.svg";
import Loader from "../../../../Components/Loader";
import CustomFrequency from "../../../Common/CustomFrequency";
import AllState from "../../../../Assets/data/stateList.json";
import Axios from "axios";
import { formatToCurrency } from "../../../../Utils/formatToCurrency";
import { editServiceOrder, getServiceOrderByUser } from "../../../../Action/Customer/index";
import { getFieldTechnician } from "../../../../Action/Franchise";
import DurationFilter from "../../../../Components/Filters/Duration.Filter";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {resetValues, addDuration, addReferenceNumber, editDuration} from "../../../../Store/serviceOrderDetailDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import AddDurationField from "../../../../Calendar/Components/Modals/SubComponents/AddDurationField";
import AddReferenceField from "../../../../Calendar/Components/Modals/SubComponents/AddReferenceField";

const { Option } = Select;
const REGULAR_EXPRESSION_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null && v?.trim() !== "")));
}

// mapping for days
const days = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];


const EditService = (props) => {
  const [state, setState] = useState({
    uuid: '',
    location: {
      address: '',
      city: '',
      state: '',
      zip_code: ''
    },
    regularity: '',
    propertySize: '',
    cleaningAreas: '',
    depositLocation: '',
    preferredServiceDays: [],
    securityCode: '',
    notes: '',
    total_price_per_service: '',
    addOns: [],
    isDoggyDoor: false,
    cleanOutside: false,
    is_btn_active: false,
    errors: {},
    miscellaneous: [],
    mis_type: '',
    org_miscellaneous: [],
    service_add_ons: [],
    loader: false,
    total: 0,
    service: {},
    is_address_manually: false,
    is_google_init: false,
    renderErrorFromProps: false,
    is_rate_type: '',
  });
  const [selectRateType, setRateType] = useState('');
  const [frequency, setFrequency] = useState(null);
  const [frequencyDescription, setFrequencyDescription] = useState(null);
  const [job_name, setjob_name] = useState('');
  const [pool_type, setpool_type] = useState('');
  const [tech_list, settech_list] = useState({});
  const [tech, setTech] = useState("");
  const [techUUID, setTechUUID] = useState("");
  const [otherPoolType, setOtherPoolType] = useState('');
  const [pool_size, setpool_size] = useState('');
  const [notes, setnotes] = useState('');
  const [price, setprice] = useState('');
  const [poolTypeList, setpoolTypeList] = useState([])
  const geo_location_ref = useRef();
  const address_ref = useRef();
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [auto_pay, setauto_pay] = useState(true);
  const [auto_invoice, setauto_invoice] = useState(true);
  const [apply_tax, setapply_tax] = useState(false);
  const [due_date, setdue_date] = useState("");
  const [time, setTime] = useState(moment("8:00", "HH:mm"));
  const [isRecurring, setisRecurring] = useState(true);
  const [dueDate_list, setdueDate_list] = useState([
    {id: "0", name: "Net 0"},
    {id: "05", name: "Net 05"},
    {id: "10", name: "Net 10"},
    {id: "15", name: "Net 15"},
    {id: "30", name: "Net 30"}
  ]);
  const fieldTechnicianList = useSelector((state) => state.fieldTechnicianListSlice);
  const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);
  const dispatch = useDispatch();
  let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));

  useEffect(() => {    
    setServiceDetail(props);
    UNSAFE_componentWillReceiveProps(props);
    getpoolTypeList();
    if (!isEmpty(props.service.preferred_technician)) {
      setTech(`${props?.service?.preferred_technician?.user?.first_name} ${props?.service?.preferred_technician?.user?.last_name}` || "");
      setTechUUID(props?.service?.preferred_technician?.uuid);
    } else {
      setTech("");
      setTechUUID("");
    }
    const getFieldTech = async () => {
      let tech_data;
      if(fieldTechnicianList?.fieldTechnicians?.length){
        tech_data = fieldTechnicianList?.fieldTechnicians;
        settech_list(tech_data);
      }else {
        tech_data = await getFieldTechnician({
          field_office__uuid: props?.customer?.field_office?.uuid,
        });
        settech_list(tech_data?.results);
      }
    };  
    getFieldTech();
  }, []);

  const UNSAFE_componentWillReceiveProps = (nextProps) => {

    if (state.renderErrorFromProps && !!nextProps?.Service?.errors?.service_address && nextProps?.Service?.errors?.service_address?.length > 0) {
      setState(s => ({
        ...s,
        errors: {
          ...this.state.errors,
          "location.address": nextProps?.Service?.errors?.service_address?.[0] || "Address Is Required"
        },
        renderErrorFromProps: false
      }))
    }

    if (Object.keys(nextProps?.Service?.data || {}).length > 0 && (!nextProps?.Service?.errors?.service_address || nextProps?.Service?.errors?.service_address?.length === 0)) {
      // nextProps.closeDrawer();
      nextProps.editService();
    }

    if (!!window?.google?.maps?.places && !state.is_google_init) {
      handleScriptLoad();
      setState(s => ({ ...s, is_google_init: true }))
    }

    // message.destroy()
    if (nextProps?.service && JSON.stringify(nextProps?.service) !== JSON.stringify(state.service)) {
      setServiceDetail(nextProps)
    }

    // get add ons
    if (Object.keys(nextProps?.addOns).length > 0) {
      setState(s => ({ ...s, addOns: nextProps?.addOns, loader: false }));
    }

    if (nextProps?.Service?.pauseService && Object.keys(nextProps?.Service?.pauseService).length > 0 &&
      JSON.stringify(nextProps?.Service?.pauseService) !== JSON.stringify(props?.Service?.pauseService)) {
      setState(s => ({ ...s, loader: false, pauseService: nextProps?.Service?.pauseService }))
      props.closeDrawer();
      onClear();
    }

    // get geocode
    if (nextProps?.GoogleApi?.getGeocode && Object.keys(nextProps?.GoogleApi?.getGeocode) && JSON.stringify(nextProps?.GoogleApi?.getGeocode) !== JSON.stringify(this.props?.GoogleApi?.getGeocode)) {
      setAddress(nextProps?.GoogleApi?.getGeocode)
    }

    setState(s => ({ ...s, loader: false }))

  }

  const setServiceDetail = (nextProps) => {
    const { service } = nextProps;
    setState(s => ({
      ...s,
      service: nextProps?.service,
      uuid: service?.uuid,
      location: { ...service?.service_address },
    }));
    setjob_name(service?.service_order_name);
    setpool_type(service?.pool_type_uuid);
    setpool_size(service?.pool_size);
    setprice(service?.service_price);
    setnotes(service?.notes);   
    setRateType(service?.is_flat_rate === true ? 'Flat Rate': 'Standard Rate')
    setState((s) => ({ ...s, is_rate_type: service?.is_flat_rate }));
     
    setauto_invoice(service?.is_auto_invoice ?? true);
    setauto_pay(service?.is_auto_payment?? true);
    setapply_tax(service?.is_apply_tax ?? false);
    setdue_date(service?.auto_payment_day ?? '');
    dispatch(addDuration(service?.service_duration || franchise_data?.data?.default_job_duration));
    dispatch(addReferenceNumber(service?.reference_number || ""));
    dispatch(editDuration(true));
    
    
    const emails =
      nextProps?.customer?.emailaddress_set?.length > 0
        ? orderBy([...nextProps?.customer?.emailaddress_set], "primary", "desc")
        : [{ email: "", primary: true }];
    setEmails(emails);

    if (service?.service_type?.toLowerCase() === "maintenance" || service?.service_type?.toLowerCase() === "service") {
      setFrequency(service?.frequency_settings ?? '');
      setFrequencyDescription(service?.frequency_description ?? "");
      setTime(service?.preferred_start_time ? moment(service?.preferred_start_time, "HH:mm") : moment("8:00", "HH:mm"))
    }

    if(service?.service_type?.toLowerCase() === "service")
    {
      const obj = props.serviceMatrix.servicetypes.find((item) => item.name.toLowerCase() === "service");
      const workTypeselected = obj.worktypes.filter((item) => item.uuid === service?.service_type_uuid)[0];
      let workSubTypeSelected = {};
      
      if(!workTypeselected){
        obj.worktypes.forEach(i => {          
          const subTypeSelected = i.worksubtypes.filter(sub => sub.uuid === service?.service_type_uuid)
          if(subTypeSelected.length > 0){
            workSubTypeSelected = subTypeSelected[0];
          }
        })
      }
      
      if(workSubTypeSelected){        
          setisRecurring(workSubTypeSelected?.is_recurring !== undefined ? workSubTypeSelected?.is_recurring : false)
      }else if(workTypeselected) {
          setisRecurring(workTypeselected?.is_recurring)        
      } else setisRecurring(obj?.is_recurring)
    }else{
      setisRecurring(true)
    }

    const location = props?.service?.service_address;
    let address = location?.address ? `${location?.address}` : '';
    address += location?.city ? `, ${location?.city}` : '';
    address += location?.state ? `, ${location?.state}` : '';
    address += location?.zip_code ? `, ${location?.zip_code}` : '';

    setTimeout(() => {
      address_ref.current.value = address;
      geo_location_ref.current.value = service?.service_address?.geo_location ? `${service?.service_address?.geo_location?.latitude}, ${service?.service_address?.geo_location?.longitude}` : '';
    }, 100);
  }

  const getpoolTypeList = () => {
    setpoolTypeList(props.serviceMatrix.pool_types);
  }

  const setAddress = (addressObject, geo_location_old) => {
    if (!isEmpty(addressObject)) {
      if (addressObject?.status === "OK") {
        let geo_location = '';
        let obj = {}, components = addressObject?.results?.[0];
        map(components?.address_components, (item) => {
          if (item?.types?.includes('administrative_area_level_2')) {
            obj = Object.assign(obj, { 'county': item.short_name })
          }
          if (item?.types?.includes('route')) {
            obj = Object.assign(obj, { 'route': item.long_name })
          }
          if (item?.types?.includes('street_number')) {
            obj = Object.assign(obj, { 'street_number': item.short_name })
          }
          if (item?.types?.includes('administrative_area_level_1')) {
            obj = Object.assign(obj, { 'state': item.short_name })
          }
          if (item?.types?.includes('locality')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (item?.types?.includes('postal_code')) {
            obj = Object.assign(obj, { 'zip_code': item.short_name })
          }
        });

        obj = Object.assign(obj, { 'address': `${obj?.street_number || ''} ${obj?.route || ''}`.trim() })

        if (!isEmpty(components.geometry.location) && !geo_location_old) {
          Object.assign(obj, { geo_location: { latitude: components?.geometry?.location?.lat, longitude: components?.geometry?.location?.lng } })
          geo_location = !isEmpty(components?.geometry?.location) ? `${components?.geometry?.location?.lat}, ${components?.geometry?.location?.lng}` : ''
        }

        function isInvalid(str) {
          return !str || str.trim().length === 0;
        }

        if (isInvalid(obj?.address) || isInvalid(obj?.city) || isInvalid(obj?.state) || isInvalid(obj?.zip_code)) {
          setState(s => ({
            ...s,
            is_address_manually: true,
          }))
        }
        
        setState((s) => ({
          ...s,
          location: obj,
          geo_location: geo_location
        }));
        
        return {
          location: obj,
          geo_location: obj.geo_location
        };
      } else {
        message.error("Something went wrong while getting address");
      }
    }
  }

  // Google Map Place API
  const handleScriptLoad = () => {
    setState((state) => ({
      ...state,
      loader: true
    }))
    const options = { componentRestrictions: { country: 'us' } };

    const newAutoComplete = new window.google.maps.places.Autocomplete(
      document.getElementById('google_address'),
      options,
    );

    newAutoComplete.setFields(['address_components', 'formatted_address']);

    newAutoComplete.addListener('place_changed', handlePlaceSelect);

    setState((state) => ({
      ...state,
      autocomplete: newAutoComplete,
      loader: false
    }))
  };

  // on start place change
  const handlePlaceSelect = () => {
    makeGoogleApiCall({ address: document?.getElementById('google_address')?.value });
  }

  // on text change
  const onTextChange = (e, type) => {
    const name = e.target.name
    const value = type === 'bool' ? e.target.checked : e.target.value;
    if (name === 'job_name') {
      setjob_name(value);
    }
    if (name === 'frequency') {
      setFrequency(value);
    }
    if (name === 'pool_type') {
      setpool_type(value);
    }
    if (name === 'pool_size') {
      setpool_size(value);
    }
    if (name === 'notes') {
      setnotes(value);
    }
    if (name === 'price') {
      setprice(value.includes('$') ? value.split('$')[1] : value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "time"){      
      setTime(value);
    }
  };

  // on primary email changed
  const onPrimaryEmailChanged = (index) => {
    map(emails, (obj) => (obj.primary = false));
    emails[index].primary = true;
    setEmails([...emails]);
  };

  //on add
  const onAddEmail = (name, type) => {
    if (name === "email") {
      let addedEmails = [];
      let obj = {};
      if (emails.length === 0) {
        obj = { email: email, primary: true };
        addedEmails = [...emails, obj];
      } else {
        if (!isEmailDuplicate(emails, email)) {
          obj = { email: email, primary: false };
          addedEmails = [...emails, obj];
        } else {
          addedEmails = [...emails];
          message.error("This Email is already Exist");
        }
      }
      setEmail("");
      setEmails(addedEmails);
    }
  };

  const onChangeEmail = (row, name) => {
    let data = emails;
    data.splice(row, 1);
    if (name === "email") {
      const idx = data?.findIndex((x) => x.primary);
      if (idx <= -1 && !isEmpty(data)) {
        data[0].primary = true;
      }
    }

    setEmails([...data]);
  };

  const isEmailDuplicate = (emailList, email) => {
    let result = false;
    emailList.forEach((each) => {
      if (each.email === email) {
        result = true;
      }
    });
    return result;
  };

  const makeGoogleApiCall = ({ geo_location, address }) => {
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: geo_location,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const result = res?.data;
      setAddress(result, geo_location);
    }).catch((err) => {
      console.log(err)
    })
  }

  // on geo location changed
  const onGeolocationChanged = (e) => {
    const geo_location = e.target.value;
    setState(s => ({ ...s, geo_location, is_address_manually: true }))
  }

  // validate numeric input
  const onNumericInputChange = (value) => {
    const reg = /^-?\d*(\.\d*)?$/;
    return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
  };

  // validate required fields
  const validate = () => {
    const {
      service,
      location,
      geo_location,
    } = state;

    function isAddressNotEmpty() {
      if (
        location?.address?.length > 0
        || location?.city?.length > 0
        || location?.state?.length > 0
        || location?.zip_code?.length > 0
      ) {
        return true;
      }
      return false;
    }

    const schema = yup.object().shape({
      // ...((!geo_location || isAddressNotEmpty()) && {
      //   location: yup.object().shape({
      //     address: yup.string().required('Address is required.'),
      //     city: yup.string().required('city is required.'),
      //     state: yup.string().required('state is required.'),
      //     zip_code: yup.string().required('zip code is required.'),
      //   })
      // }),
      job_name: yup.string().required('Job name Is Required.'),
      frequency: service?.service_type.toLowerCase() === 'maintenance' || service?.service_type.toLowerCase() === 'service' ? yup.object().required('Frequency Is Required.') : null,
      // pool_type: yup.string().required('Pool Type Is Required.'),
      // price: yup.number().required('Price Is Required.')
    });
    const data = {
      // location,
      job_name,
      frequency,
      // pool_type,
      // price
      due_date
    };
    setTimeout(() => {
      schema.validate(data, { abortEarly: false }).then(() => {
        setState(s => ({ ...s, is_btn_active: true, errors: {} }));
        onAddService();
      }).catch((error) => {
        let err_data = {};
        console.log(error);
        map(error.inner, (item) => { err_data[item.path] = item.message })
        setState(s => ({ ...s, errors: err_data, is_btn_active: false }))
      });
    }, 200);
  };

  // on clear
  const onClear = () => {
    setState(s => ({
      ...s,
      location: {
        address: '',
        city: '',
        state: '',
        zip_code: ''
      },
      geo_location: '',
      regularity: '',
      propertySize: '',
      cleaningAreas: '',
      depositLocation: '',
      preferredServiceDays: [],
      securityCode: '',
      notes: '',
      isDoggyDoor: false,
      cleanOutside: false,
      errors: {},
      total_price_per_service: '',
      miscellaneous: [...state.org_miscellaneous],
    }));
    dispatch(resetValues());
  }
  const editService = async (uuid, obj) => {
    if(obj.frequency_settings!=null){
      if((obj.frequency_settings!=null || obj.frequency_settings !== undefined) && obj.frequency_settings?.recurring?.until !== undefined){
        Object.assign( obj.frequency_settings.recurring, { until:moment(obj?.frequency_settings?.recurring?.until).format("YYYY-MM-DD")  })
      }
    }
    setState(s => ({ ...s, loader: true }));
    let data = await editServiceOrder(uuid, obj);
    if (data?.uuid) {
      dispatch(resetValues());
      getServiceOrder(data.uuid);
      message.success("Service Order is saved successfully.");
      return data;
    }
    message.error("Something went wrong!");
    setState(s => ({ ...s, loader: false }));
    return props;
  }
  // const editService = async (uuid, obj) => {
  //   setState(s => ({ ...s, loader: true }));
  //   let data = await editServiceOrder(uuid, obj);
  //   if (data?.uuid) {
  //     message.success("Service Order is saved successfully.");
  //     props.getserviceOrder({ field_office_uuid: props.fieldOfficeUUID, client_uuid: props.customerId });
  //   } else {
  //     message.error("Something went wrong!");
  //   }
  //   setState(s => ({ ...s, loader: false }));
  // }

  // on Add Service
  const onAddService = async() => {
    const {
      uuid,
      location,
      geo_location,
      is_rate_type,
    } = state;

    if (!location?.geo_location) {
      delete location?.geo_location
    }

    let obj = {
      service_address: ({ ...state?.location, ...(geo_location?.trim() === '' ? { geo_location: null } : { geo_location }) }),
      service_order_name: job_name,
      pool_type: pool_type,
      // pool_size: pool_size,
      notes: notes ?? '',
      // service_price: price,
      service_price: Number(price),
      service_duration: serviceOrderNewFieldsValue?.duration || '',
      reference_number: serviceOrderNewFieldsValue?.referenceNumber || '',
      service_type: state.service.uuid,
      service_type_uuid: state.service.service_type_uuid,
      client_uuid: props.customerId,
      is_auto_payment: auto_pay,
      is_auto_invoice: auto_invoice,      
      is_apply_tax: apply_tax,
      auto_payment_day: +due_date,
      is_flat_rate: is_rate_type
    }
    if (techUUID) {
      obj.preferred_technician_uuid = techUUID
    }
    if (geo_location) {
      Object.assign(obj.service_address, { 
        geo_location: { 
          latitude: parseFloat(geo_location?.split(",")?.[0]?.trim()),
          longitude: parseFloat(geo_location?.split(",")?.[1]?.trim()),
        } 
      })
    }
    
    if (frequency) {
      obj.frequency_settings = frequency;
    }
    if(time) obj.preferred_start_time = time <= 0 ? "" : moment(time).format("hh:mm") || "";

    if (!isEmpty(obj.frequency_settings)) {
      obj.frequency_settings.start = moment(obj.frequency_settings.start).format("YYYY-MM-DD");
      if (obj.frequency_settings.end) {
        obj.frequency_settings.end = moment(obj.frequency_settings.end).format("YYYY-MM-DD");
      }
    }

    const editServiceReply = await editService(uuid, obj);
    
    //To display the correct data we need to wait for Update Service Order and reload Service Order list
    //as well as WOs list.
    setTimeout(async () => {
      let service = await getServiceOrderByUser(uuid); //Get Service order from db
      
      if((service?.service_type?.toLowerCase() === "maintenance" || service?.service_type?.toLowerCase() === "service") && service?.frequency_settings?.recurring !== undefined)
        service.frequency = service?.frequency_settings?.recurring?.repeat;

      props.setSelectedService({
        ...props.service,
        ...service,
        service_address: { ...state?.location },
      })

      //Update SO list
      let list = props.serviceOrderList;
      const index = list.findIndex((object) => {
        return object.uuid === service.uuid;
      });

      list[index] = { ...props.service, ...service };
      props.setserviceOrderList(list);
    }, 1000)

    props.closeDrawer();
    props.getWorkOrders(); //Reload WO list after update SO
    props.onEditSuccess && props.onEditSuccess();
    onClear();

    setState(s => ({
      ...s,
      renderErrorFromProps: true
    }))
  };
  
  const getServiceOrder = async (uuid) => {
    let service = await getServiceOrderByUser(uuid);     
    props.setSelectedService({ ...props.service, ...service, service_address: { ...state?.location } });
  }
  // render all state
  const renderAllState = () => {
    return map(AllState?.AllState, (item, i) => {
      return (<Option value={item.abbreviation} key={i}>{item.name}</Option>)
    })
  }

  const onAddressChange = (e) => {
    const { location } = state;
    Object.assign(location, { [e.target.name]: e.target.value });
    let address = location?.address ? `${location?.address}` : '';
    address += location?.city ? `, ${location?.city}` : '';
    address += location?.state ? `, ${location?.state}` : '';
    address += location?.zip_code ? `, ${location?.zip_code}` : '';
    address_ref.current.value = address;
    setState(s => ({ ...s, location, errors: {} }));
  };
  const selectRecurring = ()  => {
    const slug = state?.service?.service_type.toLowerCase();
    const workType = state?.service?.worktype.toLowerCase();
    const workSubType = state?.service?.worksubtype.toLowerCase();
    const servicesTypeMatrix = props.serviceMatrix.servicetypes;
    const serviceMatrix = servicesTypeMatrix.find(
      (service) => slug === service.name.toLowerCase()
    );
    if (!serviceMatrix) {
      return false;
    }
    switch (slug) {
      case "maintenance":
        return !!serviceMatrix?.is_recurring;
      case "service":
        if (workType) {
          const serviceWorkType = serviceMatrix.worktypes.find(
            (item) => workType === item.name.toLowerCase()
          );
          if (workSubType && serviceWorkType?.worksubtypes.length) {
            const serviceWorkSubType = serviceWorkType.worksubtypes.find(
              (item) => workSubType === item.name.toLowerCase()
            );
            return !!serviceWorkSubType?.is_recurring;
          } else {
            return !!serviceWorkType?.is_recurring;
          }
        } else {
          return !!serviceMatrix?.is_recurring;
        }
      case "construction":
        return false;
      case "renovation":
        return false;
      default:
        return false;
    }
  };

  const services = [
    {
      id: 1,
      label: 'Flat Rate',
      value: 'Flat Rate'
    },

    {
      id: 2,
      label: 'Standard Rate',
      value: 'Standard Rate'
    }
  ]

  const handleRateType = (value) => {
    const check = value === 'Flat Rate' ?  true : false;
    setRateType(value);
    setState((s) => ({ ...s, is_rate_type: check }));
  };

  const render = () => {
    const { closeDrawer, visible } = props;
    const {
      service,
      location,
      errors,
      loader,
      geo_location,
      is_address_manually
    } = state;

    return (
      <Drawer
        placement={'right'}
        closable={false}
        onClose={closeDrawer}
        visible={visible}
        key={'right'}
        width={'525'}
        className="drawer-wrapper"
      >
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`}
          onLoad={handleScriptLoad}
        />
        <div className="edit-service-drawer  rdrawer">
          {loader && <Loader />}
          <div className="card-head flex ai-center">
            <h4>Edit Service</h4>
            <CloseOutlined onClick={closeDrawer} />
          </div>
          <div className="card-row flex flex-column">
            <div className="card-full">
              <div className="form-group">
              <div className="fitem">
                  <label htmlFor="address">Contact Address</label>
                  <div className="google_search_place">

                    <input
                      autoComplete="off"
                      type="text"
                      ref={address_ref}
                      placeholder={"Address"}
                      name="google_address"
                      id="google_address"
                      onChange={(e) => {
                        if (!e.target.value) {
                          setState(s => ({
                            ...s,
                            location: { address: '', state: '', city: '', zip_code: '' },
                          }))
                        }
                      }}
                      disabled={is_address_manually}
                    />
                    <b>or <a href="/#" onClick={(e) => {
                      e.preventDefault();
                      setState(s => ({
                        ...s,
                        is_address_manually: true,
                      }))
                    }}><u>enter address manually</u></a></b>
                  </div>
                  {
                    is_address_manually &&
                    <div className="manually_address">
                      <a href="/#" onClick={(e) => {
                        e.preventDefault();
                        setState(s => ({
                          ...s,
                          is_address_manually: false,
                        }))
                      }}><img src={cross_icon} alt="" className="float-right mb-10" /></a>
                      <br />

                      <div className="form-group mb-20">
                        <div className="fitem">
                          <input
                            type="text"
                            placeholder="Address"
                            name="address"
                            value={location?.address}
                            onChange={(e) => onAddressChange(e)}
                          />
                        </div>
                        <div className="half-fitem fitem">
                          <div className="half-fitem fitem">
                            <input
                              type="text"
                              placeholder="City"
                              name="city"
                              value={location?.city}
                              onChange={(e) => onAddressChange(e)}
                            />
                          </div>
                          <div className="half-fitem fitem">
                            <Select
                              showArrow={true}
                              name="state"
                              placeholder="State"
                              onChange={(value) => onAddressChange({ target: { name: 'state', value: value } })}
                              value={location?.state || undefined}
                              virtual={false}
                            >
                              {renderAllState()}
                            </Select>
                          </div>
                          <div className="half-fitem fitem">
                            <input
                              type="text"
                              className='form--control'
                              placeholder="ZIP Code"
                              name="zip_code"
                              maxLength={5}
                              minLength={5}
                              onChange={(e) => onNumericInputChange(e.target.value) && onAddressChange(e)}
                              value={location?.zip_code || ''}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {((errors?.['location.address'] || errors?.['location.city'] || errors?.['location.state'] || errors?.['location.zip_code']) && !loader) ?
                    <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['location.address'] || errors?.['location.city'] || errors?.['location.state'] || errors?.['location.zip_code'] || 'Address is required'}</label> : ''}
                </div>
                <div className="fitem">
                  <input
                    type="text"
                    ref={geo_location_ref}
                    placeholder={'Latitude, Longitude'}
                    name="geo_location"
                    id="geo_location"
                    value={geo_location || ''}
                    onChange={(e) => onGeolocationChanged(e)}
                  />
                </div>
                {/* Emails */}
                <div>
                  <h5 className="mt-30">Email Addresses</h5>
                  <div className="inline-check-list gcheck">
                    {map(emails, (item, index) =>
                      item?.email && !item.email.includes("doody.com") ? (
                        <div className="fitem-check" key={`email${index}`}>
                          <input
                            type="checkbox"
                            className="fitem-ck-input"
                            id={`checkboxEmail${index}`}
                            name={`checkboxEmail${index}`}
                            checked={item?.primary}
                            onChange={(e) => onPrimaryEmailChanged(index)}
                          />
                          <label
                            htmlFor={`checkboxEmail${index}`}
                            className="fitem-ck-txt black"
                          >{`${item?.email} ${
                            item?.primary ? "(Primary)" : ""
                          }`}</label>
                          <a
                            href={() => false}
                            className="red float-right"
                            onClick={(e) => onChangeEmail(index, "email")}
                          >
                            <u>Remove</u>
                          </a>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </div>
                  <div className="fitem fitem-rinner mt-10 flex">
                    <input
                      name="email"
                      type="text"
                      placeholder="Add New Email Address"
                      value={email}
                      onChange={onTextChange}
                    />
                    <button
                      className={`btn fitem-ricon ${
                        !REGULAR_EXPRESSION_EMAIL.test(email)
                          ? "disabled"
                          : "btn-warning"
                      }`}
                      disabled={!REGULAR_EXPRESSION_EMAIL.test(email)}
                      onClick={(e) => onAddEmail("email")}
                    >
                      <PlusOutlined />
                    </button>
                  </div>
                  {errors?.[`emails`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`emails`]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem">
                  <div className="fitem">
                    <label>Service Type</label>
                    <p style={{ 'width': '100%', 'fontWeight': 'bold' }}>
                      {service.service_type}
                      {
                        service?.service_type?.toLowerCase() === 'maintenance' ?
                          // service?.frequency
                          null
                          : service?.service_type?.toLowerCase() === 'service' ?
                            <>
                              {` > ${service?.worktype} ${service?.worksubtype ? '> ' + service?.worksubtype : ''} `}
                            </>
                            : ''
                      }
                    </p>
                  </div>
                </div>

                <div className="frow">
                  <h4>Select Rate Type</h4>
                    <Select
                      value={selectRateType}
                      showArrow
                      allowClear={true}
                      virtual={false}
                      placeholder="Select Rate Type"
                      style={{ width: "100%" }}
                      onChange={handleRateType}
                    >
                      {services?.map((item) => {
                        return <Option key={item.id} value={item.value}>{item.label}</Option>;
                      })}
                    </Select>
                </div>

                <div className="fitem">
                  <label style={{ 'textTransform': 'none' }}>Job Name</label>
                  <input
                    type="text"
                    className={'mb-10'}
                    placeholder={'Job name'}
                    name="job_name"
                    id="job_name"
                    value={job_name || ''}
                    onChange={(e) => onTextChange(e)}
                  />
                  {errors?.['job_name'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['job_name']}</label> : ''}
                </div>
                {service?.service_type?.toLowerCase() === 'maintenance' || service?.service_type?.toLowerCase() === 'service' ? (
                  <div className="fitem">
                    {service?.service_type?.toLowerCase() === "maintenance" ? (
                      <h4>How often do you need your pool cleaned?</h4>
                    ) : (
                      <h4> How often do you need your service?</h4>
                    )}
                    <CustomFrequency
                      onTextChange={onTextChange}
                      data={frequency}
                      description={frequencyDescription}
                      is_recurring={selectRecurring()}
                      recurring_base_prices={
                        props.serviceMatrix?.recurring_base_prices
                      }
                    />
                  </div>
                ) : ''}                
                {errors?.['frequency'] ? <label className="new_err_msg"><i className="las la-info-circle" />Frequency is required.</label> : ''}
                {service?.service_type?.toLowerCase() === 'maintenance' || service?.service_type?.toLowerCase() === 'service' ? (
                <>
                  <Row>
                    <div className="mb-10" style={{ width: "50%" }}>
                      <h4>Select Time</h4>
                      <TimePicker
                        className=""
                        placeholder={"--:-- AM/PM"}
                        value={time}
                        onChange={(e) => {
                          onTextChange({
                            target: {
                              name: "time",
                              value: e,
                              key: "time",
                            },
                          });
                        }}
                        allowClear={false}
                        format={"h:mm A"}
                      />
                    </div>
                    <Col span={1}></Col>
                    <AddDurationField durationType="service" />
                  </Row>
                <Row>
                  <AddReferenceField />
                </Row>
                </>
                ) : null}
                {/* <div className="fitem">
                  <label style={{ 'textTransform': 'none' }}>Pool Type</label>
                  <div className="oplist">
                    <Select
                      showArrow
                      virtual={false}
                      style={{ width: '100%' }}
                      value={pool_type}
                      onChange={(e) => onTextChange({ target: { name: 'pool_type', value: e } })}
                    >
                      {
                        poolTypeList?.map(obj => <Option value={obj.uuid} key={obj.uuid}>{obj.name}</Option>)
                      }
                    </Select>
                  </div>
                  {errors?.['pool_type'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['pool_type']}</label> : ''}
                </div> */}
                {(service.service_type?.toLowerCase() !== "service" && service?.service_type?.toLowerCase() !== "maintenance") ? (
                  <div>
                    <AddReferenceField />
                  </div>) : ("")
                }
                <div className="fitem">
                  <div className="fitem">
                    <label>Pool Type</label>
                    <p style={{ 'width': '100%', 'fontWeight': 'bold' }}>
                      {service.pool_type}
                    </p>
                  </div>
                </div>
                {/* <div className="fitem">
                  <label style={{ 'textTransform': 'none' }}>Pool Size</label>
                  <input
                    type="text"
                    className={'mb-10'}
                    placeholder={'0'}
                    name="pool_size"
                    id="pool_size"
                    value={pool_size || ''}
                    onChange={(e) => onTextChange(e)}
                  />
                  {errors?.['pool_size'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['pool_size']}</label> : ''}
                </div> */}
                <div className="fitem">
                  <div className="fitem">
                    <label>Pool Size</label>
                    <p style={{ 'width': '100%', 'fontWeight': 'bold' }}>
                      {service.pool_size}
                    </p>
                  </div>
                </div>
                {
                  service.service_type?.toLowerCase() === 'service' ?
                    <div className="fitem">
                      <label style={{ 'textTransform': 'none' }}>Problem Description Notes</label>
                      <textarea
                        type="text"
                        className={'mb-10'}
                        placeholder={'Notes'}
                        name="notes"
                        id="notes"
                        value={notes || ''}
                        onChange={(e) => onTextChange(e)}
                      />
                      {errors?.['pool_size'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['pool_size']}</label> : ''}
                    </div>
                    : ''
                }
              </div>
              <div className="pricing" style={{ 'width': '100%', 'position': 'relative' }}>
                <h4>Service Order Total (Monthly Charge): { }</h4>
                <span style={{ 'position': 'absolute', 'left': '2%', 'paddingTop': '12px' }} >$</span>
                <input
                  type="text"
                  className={'mb-10'}
                  placeholder={'Price'}
                  name="price"
                  id="price"
                  value={price}
                  onChange={(e) => onTextChange(e)}
                  readonly
                />
              </div>
              <div className="fitem-check mt-10">
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id={`auto_pay`}
                  name={`auto_pay`}
                  checked={auto_pay}
                  onChange={(e) => setauto_pay(e.target.checked)}
                />
                <label htmlFor={`auto_pay`} className="fitem-ck-txt">
                  Auto Pay
                </label>
              </div>
              {service?.service_type?.toLowerCase() === "maintenance" ? (
                <div className="fitem-check mt-10">
                  <input
                    type="checkbox"
                    className="fitem-ck-input"
                    id={`auto_invoice`}
                    name={`auto_invoice`}
                    checked={auto_invoice}
                    onChange={(e) => setauto_invoice(e.target.checked)}
                  />
                  <label htmlFor={`auto_invoice`} className="fitem-ck-txt">
                    Auto-Invoicing
                  </label>
                </div>
              ) : (
                ""
              )}
              <div className="fitem-check mt-10">
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id={`apply_tax`}
                  name={`apply_tax`}
                  checked={apply_tax}
                  onChange={(e) => setapply_tax(e.target.checked)}
                />
                <label htmlFor={`apply_tax`} className="fitem-ck-txt">
                  Apply Tax
                </label>
              </div>
              <div className="fitem mt-10">
                <b className="d-block mb-10">Preferred Technician</b>
                <Select
                  value={tech ? tech : undefined}
                  showArrow
                  allowClear={true}
                  virtual={false}
                  placeholder={"Select Technician"}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setTech(e ? e : null);
                    setTechUUID(e ? e : null);
                    // this.setState({tech: e ? e : null, loader: true})
                    // this.props.updateCustomer(customer?.uuid, {preferred_technician_uuid: e ? e : null})
                  }}
                >
                  {orderBy(tech_list, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc")?.map((obj) => (
                    <Option key={obj?.uuid} value={obj?.uuid}>
                      {obj?.user?.last_name
                        ? `${obj?.user?.first_name} ${obj?.user?.last_name}`
                        : obj?.user?.first_name}
                    </Option>
                  ))}
                </Select>
              </div>              
                <div className="form-group">
                  <div className="fitem">
                    <div className="fitem">
                      <label>
                        Due Date
                      </label>
                      <Select
                        value={due_date ? due_date : undefined}
                        showArrow
                        allowClear={true}
                        virtual={false}
                        placeholder={"Select Due Date"}
                        style={{ width: "100%" }}
                        onChange={(e) => {                          
                          setdue_date(e ? e : null);
                        }}
                      >
                        {map(dueDate_list, (obj) => (
                          <Option key={obj?.id} value={obj?.id}>
                            {obj?.name}
                          </Option>
                        ))}
                      </Select>
                      {errors["due_date"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle"></i>
                          {errors["due_date"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>              
            </div>
          </div>
          <div className="footer">
            <button
              type="button"
              className="btn btn-brand btn-bdr"
              onClick={() => { onClear(); closeDrawer() }}
            >Cancel</button>
            <button
              // type="submit"
              className={`btn btn-primary`}
              // disabled={!is_btn_active}
              onClick={() => validate()}>
              Update Service
            </button>
          </div>
        </div>
      </Drawer>

    )
  }

  return render();
}

export default EditService;
