import React, { useState, useEffect } from "react";
import { ConfigProvider, Spin, Empty, Select } from "antd";
import { orderBy } from "lodash";
import { getServiceMatrix } from "../../Action/Customer/index";

const { Option } = Select;

const ServiceTypeSelector = ({
  allValue = false,
  multipleMode = false,
  defaultValue = { value: null, label: null },
  franchise = { value: null, label: null },
  onControlChange = () => {},
}) => {
  const [loading, setLoading] = useState(true);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedListService, setSelectedListService] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};

  useEffect(() => {
    if (multipleMode) {
      setSelectedListService(["all"]);
      onControlChange(["all"]);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const getServiceTypes = async () => {
      const response = await getServiceMatrix(franchise?.value === "all" ?
        userInfo.user.associated_field_offices[0].uuid :
        franchise.value
      );
      setServiceTypes(response?.servicetypes);
      setLoading(false);
    };

    getServiceTypes();
  }, [franchise]);

  useEffect(() => {
    if (!multipleMode) {
      if (defaultValue) setSelectedService(defaultValue);
      else setSelectedService({});
    }
  }, [multipleMode]);

  const updateService = (selectedService, options) => {
    if (multipleMode) {
      //If the selector has Multiple mode
      if (selectedService[selectedService.length - 1] === "all") {
        //If the last option selected is 'all'
        setSelectedListService(["all"]); //Remove the rest of the options selected
        options = ["all"];
      } else if (selectedService.find((i) => i === "all")) {
        //if the last selection is not 'all'
        const idx = selectedService?.indexOf("all"); //Uncheck 'all' option
        selectedService.splice(idx, 1);
        options.splice(idx, 1);
      }
      if (selectedService.length === serviceTypes.length) {
        //If all the possible options have been selected
        setSelectedListService(["all"]); //Select only 'all' option
        options = ["all"];
      } else {
        setSelectedListService(selectedService);
      }
    } else {
      setSelectedService(selectedService);
    }
    onControlChange(options ?? { value: null, label: null });
  };

  return (
    <div className="fitem">
      <ConfigProvider
        renderEmpty={() =>
          loading ? (
            <div className="selectorSpin">
              <Spin tip="Loading technician..." />
            </div>
          ) : (
            <div className="selectorSpin">
              <Empty />
            </div>
          )
        }
      >
        <Select
          allowClear
          value={
            !multipleMode ? selectedService || undefined : selectedListService
          }
          onChange={(value, options) => updateService(value, options)}
          placeholder={allValue ? "Service Type: All" : "Select a service type"}
          data-title = {multipleMode ? `${selectedListService.length} Services` : 'Services'}
          className="swhite service_type_select"
          optionFilterProp="children"
          mode={multipleMode ? "multiple" : ""}
          style={{ width: "100%" }}
          maxTagCount = 'responsive'
        >
          {allValue ? (
            <Option key={"serviceAll"} value={"all"} label={"all"}>
              {"All"}
            </Option>
          ) : (
            <></>
          )}
          {orderBy(serviceTypes, "name", "asc")?.map((option, index) => (
            <Option key={option.uuid} value={option.uuid} label={option?.name}>
              <label>{option?.name}</label>
            </Option>
          ))}
        </Select>
      </ConfigProvider>
    </div>
  );
};

export default ServiceTypeSelector;
