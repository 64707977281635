import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Components/Sidebar";
import Breadcrumb from "../Components/Breadcrumb";
import CustomerDetails from "./Common/CustomerDetails";
import FooterForm from "./Common/FooterForm";
import { upsertServicePool } from "../Utils/infoRequests";
import { DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import { Row, Col, Select, message } from "antd";
import { useParams } from "react-router";
import { getPoolCharacteristics } from "../Action/Customer/index";
import { useLocation } from 'react-router-dom'
import { jsPDF } from "jspdf";
import pdfHeader from '../Assets/images/headerImgPdf.png';
import config from '../Lib/Config';
import { saveMaintenencePoolReport, getSaveMaintenencePoolReport, getPDFMaintenencePoolReport } from "../Action/Customer/index";
import { exclude } from "query-string";
import moment from "moment-timezone";

const { Option } = Select;
const SwimmingPoolMaintenanceProgramForm = (props) => {
  const params = useParams()
  const pdfRef = useRef(null);
  const location = useLocation()
  const { clientUUID } = useParams();
  const [loader, setLoader] = useState(false);
  const [poolSize, setPoolSize] = useState(0);
  const [allData, setAllData] = useState({})
  const [poolCleaner, setPoolCleaner] = useState();
  const [saltSystem, setsaltSystem] = useState();
  const [condition1, setcondition1] = useState();
  const [condition2, setcondition2] = useState();
  const [pumpInfo, setPumpInfo] = useState();
  const [filterMake, setfilterMake] = useState();
  const [heaterInfo, setHeaterInfo] = useState();
  const [chlorinatorInfo, setchlorinatorInfo] = useState();
  const [accessInfo, setaccessInfo] = useState();
  const [assignedDayOfWeek, setassignedDayOfWeek] = useState('Select');
  const [programTerm, setprogramTerm] = useState();
  const [programTermOther, setprogramTermOther] = useState();
  const [programFrequency, setprogramFrequency] = useState();
  const [programFrequencyOther, setprogramFrequencyOther] = useState();
  const [billTypeId, setbillTypeId] = useState();
  const [billAmount, setbillAmount] = useState(0.0);
  const [chemicalProvider, setchemicalProvider] = useState();
  const [maximumCostOver, setmaximumCostOver] = useState(0.0);
  const [dayofMonthMailed, setdayofMonthMailed] = useState();
  const [dayOfMonthDue, setdayOfMonthDue] = useState();
  const [isAutopay, setisAutopay] = useState();
  const [techNotes, settechNotes] = useState('');
  const [contractChangeDateOwner, setcontractChangeDateOwner] = useState();
  const [signatureOwnerURL, setsignatureOwnerURL] = useState();
  const [signatureASPURL, setsignatureASPURL] = useState();
  const [client, setClient] = useState();
  const [task, setTask] = useState({});
  const [textButton, setTextButton] = useState("Save & Email");
  var successMessage = "";
  const imageSources = [];

  function getClient() {
    setClient({
      adSource: null,
      automationYN: null,
      cellPhone: null,
      chlorinatorType: null,
      city: "Charleston",
      comments: null,
      createdDateUtc: "2022-04-07T07:55:41",
      customField1: "11000",
      customField2: null,
      customField3: null,
      customField4: null,
      customField5: null,
      customField6: null,
      customField7: null,
      customField8: null,
      customField9: null,
      customField10: null,
      customField11: null,
      customerTypeFlags: null,
      email: "shrutika.kapse@capgemini.com",
      endDate: null,
      filterType: null,
      firstName: "Test",
      heaterYN: null,
      homePhone: "",
      id: params.userId,
      isDeleted: false,
      isSyncWithQB: false,
      lastFilterClean: null,
      lastName: "Client1",
      maintenanceFee: null,
      poolCleanerType: null,
      pumpType1: null,
      pumpType2: null,
      qbId: "223",
      startDate: null,
      state: "SC",
      streetAddress: "351 King Street",
      tenantId: 52,
      updatedDateUtc: "2022-04-07T08:21:35",
      zip: "29401",
    });
  }

  useEffect(() => {
    getFormData();
    getPoolSize();
    getClient();
    getTask();
  }, []);

  const getPoolSize = async () => {
    let client_uuid = window.localStorage.getItem("customerUUID");
    setLoader(true);
    const data = await getPoolCharacteristics(client_uuid);
    setPoolSize(data[0].pool_size ?? 0);
    setLoader(false);

  }

  const getFormData = async () => {
    const data = await getSaveMaintenencePoolReport(params.formuuid);    
    setClient({ client: data.client });
    setAllData(data);
    setPoolCleaner(data.form_data?.otherinfo?.poolCleaner);
    setsaltSystem(data.form_data?.otherinfo?.saltSystems);
    setcondition1(data.form_data?.otherinfo?.condition1);
    setcondition2(data.form_data?.otherinfo?.condition2);
    setPumpInfo(data.form_data?.otherinfo?.pumpInfo);
    setfilterMake(data.form_data?.otherinfo?.filterMake);
    setHeaterInfo(data.form_data?.otherinfo?.heaterInfo);
    setchlorinatorInfo(data.form_data?.otherinfo?.chlorinatorInfo);
    setaccessInfo(data.form_data?.otherinfo?.accessInfo);
    setassignedDayOfWeek(data.form_data?.otherinfo?.assignedDayOfWeek);
    setprogramTerm(data.form_data?.otherinfo?.programTerm);
    setprogramTermOther(data.form_data?.otherinfo?.programTermOther);
    setprogramFrequency(data.form_data?.otherinfo?.programFrequency);
    setprogramFrequencyOther(data.form_data?.otherinfo?.programFrequencyOther);
    setbillTypeId(data.form_data?.otherinfo?.billTypeId);
    setbillAmount(data.form_data?.otherinfo?.billAmount);
    setchemicalProvider(data.form_data?.otherinfo?.chemicalProvider);
    setmaximumCostOver(data.form_data?.otherinfo?.maximumCostOver);
    setdayofMonthMailed(data.form_data?.otherinfo?.dayofMonthMailed);
    setdayOfMonthDue(data.form_data?.otherinfo?.dayOfMonthDue);
    setisAutopay(data.form_data?.otherinfo?.isAutopay);
    settechNotes(data.form_data?.otherinfo?.techNotes);
    setcontractChangeDateOwner(data.form_data?.otherinfo?.contractChangeDateOwner);
  }
  const options = [
    { value: "Opened", label: "Opened" },
    { value: "Closed", label: "Closed" }

  ]

  const options1 = [
    { value: "Green", label: "Green" },
    { value: "Clear", label: "Clear" }
  ]
  function getTask() {
    setTask({
      taskId: params.taskId,
      isComplete: true,
    });
  }

  function upsertServicePoolForm(isComplete) {
    var sms_status=isComplete;
    setTask((task) => ({
      ...task,
      [isComplete]: isComplete,
    }));
    setLoader(true);
    let payload = {}
    let formData = {};

    formData.customer_details = {
      "customer_name": client.firstName,
      "email": client.email,
      "address": client.streetAddress,
      "phone": client.homePhone,
      "city_state_zip": client.state,
      "pool_size": poolSize,

    }
    formData.otherinfo = {
      "poolCleaner": poolCleaner,
      "saltSystems": saltSystem,
      "condition1": condition1,
      "condition2": condition2,
      "pumpInfo": pumpInfo,
      "filterMake": filterMake,
      "heaterInfo": heaterInfo,
      "chlorinatorInfo": chlorinatorInfo,
      "accessInfo": accessInfo,
      "assignedDayOfWeek": assignedDayOfWeek,
      "programTerm": programTerm,
      "programTermOther": programTermOther,
      "programFrequency": programFrequency,
      "programFrequencyOther": programFrequencyOther,
      "billTypeId": billTypeId,
      "billAmount": billAmount,
      "chemicalProvider": chemicalProvider,
      "maximumCostOver": maximumCostOver,
      "dayofMonthMailed": dayofMonthMailed,
      "dayOfMonthDue": dayOfMonthDue,
      "isAutopay": isAutopay,
      "techNotes": techNotes,
      "contractChangeDateOwner": contractChangeDateOwner,
      "signatureOwnerURL": signatureOwnerURL,
      "signatureASPURL": signatureASPURL
    }
    payload["form_data"] = formData    
    payload["has_attachments"] = imageSources.length > 0 ? true : false
    var date = moment().format("YYYY-MM-DD");
    var hour = moment().format("HH:mm");
    upsertServicePool(task).then(() => {
      if (isComplete) setTextButton("Save & Resend");
    });

    const postFormData = async() => {
    let sms_send= sms_status === true ? true : false; 
      const data = await saveMaintenencePoolReport(
        payload, 
        sms_send, 
        params.formuuid,
        date,
        hour
      );
      if (data?.form_data?.uuid) {
        setLoader(false);
        message.success("Form Data saved  Successfully!..");
        if(isComplete)
        if ( window !== window.parent ) {
          var event = new CustomEvent('handleCloseEvent', { detail: data })
          window.parent.document.dispatchEvent(event)
        }
        else close();
      }
    }
    postFormData()
  }
  const handlePoolSize = (value) => {
  }
  
  const handleChange = (event) => {
    if (event.target.name === 'condition1') {
      setcondition1(event.target.value);
    }
    else if (event.target.name === 'condition2') {
      setcondition2(event.target.value);
    } else if (event.target.name === 'assignedDayOfWeek') {
      setassignedDayOfWeek(event.target.value);
    }
  }
  const handleChangeCondition2 = (e) => {
    setcondition2(e.target.value);
  }
  return (
    <div className="wrapper" >
      <div className="content-area">
        <Breadcrumb value={"Back to job"} />
        <Sidebar sideBarBackgroundColor="#005d89" />
        <div className="fluid">
          <div className="container new-cust">
            <div className="fluid-block">
              <div className="maintenance-container">
                <div className="form-group mb-20">
                  <div className="full-fitem fitem">
                    <div className="full-fitem fitem text-left"></div>
                    <div className="full-fitem fitem mb-30">
                      <h1>Pool Maintenance Program</h1>
                    </div>
                    <div className="full-fitem fitem text-right">
                      <div className="full-fitem fitem text-right" >
                        <a target="_blank"  href={`${config.getHost()}form-data/as_pdf/?form_data_uuid=${params.formuuid}`}>
                          <i
                            className="fa fa-download"
                            style={{ marginLeft: "10px" }}
                          ></i>
                          <DownloadOutlined />
                          Download PDF
                        </a>
                      </div>
                      <div className="full-fitem fitem text-right">
                        <a target="_blank" href={`${config.getHost()}form-data/as_html/?form_data_uuid=${params.formuuid}`}>
                          <i
                            className="fa fa-download"
                            style={{ marginLeft: "10px" }}
                          ></i>
                          <LinkOutlined />
                          View Contract
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <CustomerDetails
                  client={client}
                  handlePoolSize={handlePoolSize}
                  poolSize={allData.pool_size}
                  showPoolSize={false}
                  allData={allData}
                />
                <div className="form-group mt-30">
                  <h4>Program Information</h4>
                  <div className="form-group">
                    <div className="fitem half-item">
                      <div className="fitem half-item">
                        <Row>
                          <Col span={3}>
                            <label htmlFor="poolCleaner">
                              Pool Cleaner
                            </label>
                          </Col>
                          <Col span={5}>
                            <input
                              type="radio"
                              name="poolCleaner"
                              onChange={e => setPoolCleaner(e.target.value)}
                              value="Yes"
                              checked={poolCleaner === "Yes"}
                            />
                            &nbsp; Yes
                            <br />
                            <input
                              type="radio"
                              name="poolCleaner"
                              onChange={e => setPoolCleaner(e.target.value)}
                              value="No"
                              checked={poolCleaner === "No"}
                            />
                            &nbsp; No
                          </Col>
                          <Col span={3}>
                            <label htmlFor="saltSystem">
                              Salt System
                            </label>
                          </Col>
                          <Col>
                            <input
                              name="saltSystem"
                              type="radio"
                              id="saltSystem"
                              value="Yes"
                              onChange={e => setsaltSystem(e.target.value)}
                              checked={saltSystem === "Yes"}
                            />
                            &nbsp; Yes
                            <br />
                            <input
                              name="saltSystem"
                              type="radio"
                              id="saltSystem"
                              value="No"
                              onChange={e => setsaltSystem(e.target.value)}
                              checked={saltSystem === "No"}
                            />
                            &nbsp; No
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label htmlFor="condition1">Condition 1</label>
                        <Select value={condition1} onChange={(e) => { handleChange({ target: { name: 'condition1', value: e } }) }}>
                          {options.map((option) => (
                            <Option selected={condition1 === option.value} value={option.value}>{option.label}</Option>
                          ))}
                        </Select>
                      </div>
                      <div className="fitem half-fitem">
                        <label htmlFor="CalciumHardness">
                          Condition 2
                        </label>
                        <Select value={condition2} onChange={(e) => { handleChange({ target: { name: 'condition2', value: e } }) }}>
                          {options1.map((option) => (
                            <Option selected={condition1 === option.value} value={option.value}>{option.label}</Option>
                          ))}
                        </Select>
                      </div>
                      <div className="fitem half-fitem">
                        <label htmlFor="pumpInfo">Pump Info</label>
                        <input
                          id="pumpInfo"
                          type="text"
                          name="pumpInfo"
                          value={pumpInfo}
                          onChange={e => setPumpInfo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label htmlFor="filterInfo">Filter Info</label>
                        <input
                          id="filterMake"
                          type="text"
                          name="filterMake"
                          value={filterMake}
                          onChange={e => setfilterMake(e.target.value)}
                        />
                      </div>
                      <div className="fitem half-fitem">
                        <label htmlFor="chemical.heaterInfo">Heater Info</label>
                        <input
                          id="heaterInfo"
                          type="text"
                          name="heaterInfo"
                          value={heaterInfo}
                          onChange={e => setHeaterInfo(e.target.value)}
                        />
                      </div>
                      <div className="fitem half-fitem">
                        <label htmlFor="chlorinatorInfo">
                          Chlorinator Info
                        </label>
                        <input
                          id="chlorinatorInfo"
                          type="text"
                          name="chlorinatorInfo"
                          value={chlorinatorInfo}
                          onChange={e => setchlorinatorInfo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label htmlFor="accessInfo">Access Info</label>
                        <input
                          id="accessInfo"
                          type="text"
                          name="accessInfo"
                          value={accessInfo}
                          onChange={e => setaccessInfo(e.target.value)}
                        />
                      </div>
                      <div className="fitem half-fitem">
                        <label htmlFor="chlorinatorInfo">
                          Assigned Day of Week
                        </label>
                        <Select
                          id="assignedDayOfWeek"
                          name="assignedDayOfWeek"
                          onChange={(e) => { handleChange({ target: { name: 'assignedDayOfWeek', value: e } }) }}
                          value={assignedDayOfWeek}
                        >
                          <Option value=" ">-- Select --</Option>
                          <Option value="Sunday">Sunday</Option>
                          <Option value="Monday">Monday</Option>
                          <Option value="Tuesday">Tuesday</Option>
                          <Option value="Wednesday">Wednesday</Option>
                          <Option value="Thursday">Thursday</Option>
                          <Option value="Friday">Friday</Option>
                          <Option value="Saturday">Saturday</Option>
                        </Select>
                      </div>
                      <div className="fitem half-fitem"></div>
                    </div>
                  </div>
                  <div className="form-group mt-30">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label className="text-left" htmlFor="programTerm">
                          Program Term
                        </label>
                        <label className="text-left" htmlFor="programTerm">
                          THE PROGRAM SHALL BEGIN ON THE START DATE AND SHALL
                          CONTINUE (CHECK ONE)
                        </label>
                        <div className="fitem half-fitem">
                          <Row>
                            <Col span={3}>
                              <input
                                id="programTerm"
                                type="radio"
                                name="programTerm"
                                value={"Monthly Until Cancelled"}
                                onChange={e => setprogramTerm(e.target.value)}
                                checked={programTerm === "Monthly Until Cancelled"}
                              />
                            </Col>
                            <Col>
                              <label>Monthly Until Cancelled</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programTerm"
                                type="radio"
                                name="programTerm"
                                value={"6 Months"}
                                onChange={e => setprogramTerm(e.target.value)}
                                checked={programTerm === "6 Months"}
                              />
                            </Col>
                            <Col>
                              <label>6 Months</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programTerm"
                                type="radio"
                                name="programTerm"
                                value={"12 Months"}
                                onChange={e => setprogramTerm(e.target.value)}
                                checked={programTerm === "12 Months"}
                              />
                            </Col>
                            <Col>
                              <label>12 Months</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programTerm"
                                type="radio"
                                name="programTerm"
                                value={"Other"}
                                onChange={e => setprogramTerm(e.target.value)}
                                checked={programTerm === "Other"}
                              />
                            </Col>
                            <Col>
                              <label>Other</label>
                            </Col>
                            <Col>
                              <input
                                id="programTermOther"
                                type="text"
                                name="programTermOther"
                                value={programTermOther}
                                onChange={e => setprogramTermOther(e.target.value)}

                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="fitem half-fitem">
                        <label className="text-left" htmlFor="programTerm">
                          Program Frequency
                        </label>
                        <label className="text-left" htmlFor="programTerm">
                          {" "}
                          ASP SHALL MAINTAIN THE SWIMMING POOL AT THE FOLLOWING
                          FREQUENCY (CHECK ONE)
                        </label>
                        <div className="fitem half-fitem">
                          <Row>
                            <Col span={3}>
                              <input
                                id="programFrequency"
                                type="radio"
                                name="programFrequency"
                                value="Daily"
                                onChange={e => setprogramFrequency(e.target.value)}
                                checked={programFrequency === "Daily"}
                              />
                            </Col>
                            <Col>
                              <label>Daily</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programFrequency"
                                type="radio"
                                name="programFrequency"
                                value="Weekly"
                                onChange={e => setprogramFrequency(e.target.value)}
                                checked={programFrequency === "Weekly"}
                              />
                            </Col>
                            <Col>
                              <label>Weekly</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programFrequency"
                                type="radio"
                                name="programFrequency"
                                value="Bi-Weekly"
                                onChange={e => setprogramFrequency(e.target.value)}
                                checked={programFrequency === "Bi-Weekly"}
                              />
                            </Col>
                            <Col>
                              <label>Bi-Weekly</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programFrequency"
                                type="radio"
                                name="programFrequency"
                                value="Monthly"
                                onChange={e => setprogramFrequency(e.target.value)}
                                checked={programFrequency === "Monthly"}
                              />
                            </Col>
                            <Col>
                              <label>Monthly</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programFrequency"
                                type="radio"
                                name="programFrequency"
                                value="Chemical Check"
                                onChange={e => setprogramFrequency(e.target.value)}
                                checked={programFrequency === "Chemical Check"}
                              />
                            </Col>
                            <Col>
                              <label>Chemical Check</label>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <input
                                id="programFrequency"
                                type="radio"
                                name="programFrequency"
                                value="Other"
                                onChange={e => setprogramFrequency(e.target.value)}
                                checked={programFrequency === "Other"}
                              />
                            </Col>
                            <Col>
                              <label>Other</label>
                            </Col>
                            <Col>
                              <input
                                id="programFrequencyOther"
                                type="text"
                                name="programFrequencyOther"
                                value={programFrequencyOther}
                                onChange={e => setprogramFrequencyOther(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-10">
                  <h4>Monthly Maintenance Fee</h4>
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem">
                      <Row>
                        <Col span={2}>
                          <input
                            id="billTypeId"
                            type="radio"
                            name="billTypeId"
                            value={"Flat Rate"}
                            onChange={e => setbillTypeId(e.target.value)}
                            checked={billTypeId === "Flat Rate"}
                          />
                        </Col>
                        <Col>
                          <label>Flat Rate</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={2}>
                          <input
                            id="billTypeId"
                            type="radio"
                            name="billTypeId"
                            value={"Standard"}
                            onChange={e => setbillTypeId(e.target.value)}
                            checked={billTypeId === "Standard"}
                          />
                        </Col>
                        <Col>
                          <label>Standard</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label htmlFor="billAmount">Bill Amount</label>
                      </div>
                      <input
                        id="billAmount"
                        type="number"
                        step="0.01"
                        name="billAmount"
                        value={billAmount}
                        onChange={e => setbillAmount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-10">
                  <h4>Errata</h4>
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem">
                      <Row>
                        <Col span={2}>
                          <input
                            id="chemicalProvider"
                            type="radio"
                            name="chemicalProvider"
                            value={"Chemicals Added from ASP"}
                            onChange={e => setchemicalProvider(e.target.value)}
                            checked={chemicalProvider === "Chemicals Added from ASP"}
                          />
                        </Col>
                        <Col>
                          <label>Chemicals Added from ASP</label>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={2}>
                          <input
                            id="chemicalProvider"
                            type="radio"
                            name="chemicalProvider"
                            value={"Customer Supplies Chemicals"}
                            onChange={e => setchemicalProvider(e.target.value)}
                            checked={chemicalProvider === "Customer Supplies Chemicals"}
                          />
                        </Col>
                        <Col>
                          <label>Customer Supplies Chemicals</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="fitem half-fitem">
                      <div className="">
                        <label htmlFor="billAmount">
                          Max Repair Amount
                        </label>
                        <input
                          id="maximumCostOver"
                          type="number"
                          step="0.01"
                          name="maximumCostOver"
                          value={maximumCostOver}
                          onChange={e => setmaximumCostOver(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-froup">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label htmlFor="billAmount">
                          Day of Month Mailed
                        </label>
                        <input
                          id="dayofMonthMailed"
                          type="number"
                          name="dayofMonthMailed"
                          value={dayofMonthMailed}
                          onChange={e => setdayofMonthMailed(e.target.value)}
                        />
                      </div>
                      <div className="fitem half-fitem">
                        <label htmlFor="billAmount">
                          Day Of Month Due
                        </label>
                        <input
                          id="dayOfMonthDue"
                          type="number"
                          name="dayOfMonthDue"
                          value={dayOfMonthDue}
                          onChange={e => setdayOfMonthDue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-froup">
                    <div>
                      <Row>
                        <Col span={3}>
                          <label htmlFor="isAutoPay">Autopay</label>
                        </Col>
                        <Col span={6}>
                          <input
                            id="isAutopay"
                            type="radio"
                            name="isAutopay"
                            value={"Yes"}
                            onChange={e => setisAutopay(e.target.value)}
                            checked={isAutopay === "Yes"}
                          />
                          &nbsp; Yes
                          <br />
                          <input
                            id="isAutopay"
                            type="radio"
                            name="isAutopay"
                            value={"No"}
                            onChange={e => setisAutopay(e.target.value)}
                            checked={isAutopay === "No"}
                          />
                          &nbsp; No
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-30">
                  <hr style={{ borderBottom: "3px solid black" }} />
                  <div className="fitem maintenance-title">
                    <h2>Tech Notes</h2>
                    <div className="fitem">
                      <div className="fitem">
                        <textarea
                          id="chemical.comment"
                          rows="5"
                          cols="48"
                          autoComplete="off"
                          value={techNotes}
                          onChange={e => settechNotes(e.target.value)}
                        />
                      </div>
                      <div>
                        <strong>{successMessage}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-10">
                  <strong>Client Approval</strong>
                  <div className="fitem half-fitem mt-10">
                    <div className="fitem half-fitem">
                      <Row>
                        <Col>
                          <strong>Owner Date</strong>
                        </Col>
                        <Col>
                          <input
                            id="contractChangeDateOwner"
                            type="datetime-local"
                            name="input"
                            value={contractChangeDateOwner}
                            onChange={e => setcontractChangeDateOwner(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className="fitem half-fitem">
                      <img
                        style={{ minHeight: "200px", minWidth: "400px" }}
                        className="rounded img-fluid img-thumbnail"
                        src={signatureOwnerURL}
                        alt=""
                      />
                    </div>
                    <div className="fitem half-fitem">
                      <img
                        className="rounded img-fluid img-thumbnail"
                        src={signatureASPURL}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <FooterForm
                  textButton={textButton}
                  handleClick={() => upsertServicePoolForm(false)}
                  handleClickEmail={() => upsertServicePoolForm(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwimmingPoolMaintenanceProgramForm;
