import React ,{useState} from "react";
import AddressField from "../../../Components/AddressField";
import { setServiceAddress } from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

const ServiceAddressForProposal = (props) => {
  const {errors}=props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);

  const handleAddressChange = (value) => {
    dispatch(setServiceAddress(value))
}
const onAddressChanged = (e, addr_ref) => {        
  dispatch(setServiceAddress({ [e.target.name]: e.target.value }))
};
const onAddressChange = (e) => {
  dispatch(setServiceAddress({ [e.target.name]: e.target.value }))

};
  return (
    <>
    <label style={{ fontWeight: "bold" }}>Service Address<sup>*</sup></label>
    <AddressField handleAddressChange={handleAddressChange} onAddressChanged={onAddressChanged} onAddressChange={onAddressChange} errors={errors} data={proposals.address}   nolabel
/>        
    </>
  );
};
export default ServiceAddressForProposal;
