import React, { useState } from "react";
import { Radio, Col, Row } from "antd";
import ServiceRevenueCountChart from "../Charts/ServiceRevenueCount.Chart";

const ServiceRevenueCountContainer = () => {
  const [currentServiceTab, setServiceCurrentTab] = useState("service");

  return (
    <div
      className="cardMetric"
      style={{ padding: "0 0 10px 0", maxHeight: "400px" }}
    >
      <Row>
        <Col xs={24} xxl={24} md={24} className="serviceChart cardFilters">
          <Radio.Group
            onChange={(e) => setServiceCurrentTab(e.target.value)}
            defaultValue="service"
          >
            <Radio.Button value="service">Service</Radio.Button>
            <Radio.Button value="revenue">Revenue</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={24} xxl={24} md={24} style={{ minHeight: "290px" }}>
          <ServiceRevenueCountChart type={currentServiceTab} />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceRevenueCountContainer;
