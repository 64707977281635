const { createSlice } = require('@reduxjs/toolkit');
import moment from "moment-timezone";

const initialState = {
  serviceOrderNumberList: [],
  isOpenClearAll: false,
  loading: false,
  errors: {},
  isOpenDelete: false,
  addItemModal: false,
  selectedItem: {},
  selectedWorkOrders: [],

  newInvoice: {
    taxRate: 1,
    customer: {},
    uuid: '',
    status: 'open',
    serviceOrderDropDownValue: null,
    retailService: [],
    rowsData: [],
    invoice_date: moment(),
    due_date: '',
    serviceOrderUuid: [],
    total: '',
    sales_tax: '',
    balance_due: '',
    quantity: '',
    notes: '',
    amount_received: '0',
  },

  newItemModal: {
    openSelect: false,
    repairCodeData: [],
    codeOptions: [],
    repairCodeValue: "",
    newItemModalData: {
      price: 0, fo_adjusted_price: 0, quantity: '', description: '',
      include_labor: false,
      include_bundle: false,
    },
  },
};

const invoiceDataSlice = createSlice({
  name: 'invoiceData',
  initialState,
  reducers: {
    setNewInvoiceDataField(state, action) {
      return (state = {
        ...state,
        newInvoice: { ...state.newInvoice, ...action.payload }
      });
    },

    setAddItemModal(state, action) {
      return (state = {
        ...state,
        newItemModal: { ...state.newItemModal, ...action.payload },
      });
    },

    setInvoiceDataField(state, action) {
      return (state) = {
        ...state,
        ...action.payload
      };
    },

    resetCurrentInvoiceData(state, action) {
      return (state) = {
        ...state,
        ...{newItemModal: {
          openSelect: false,
          repairCodeData: [],
          repairCodeOptions: [],
          codeOptions: [],
          repairCodeValue: "",
          newItemModalData: {
            price: 0, fo_adjusted_price: 0, quantity: '', description: '',
            include_labor: false,
            include_bundle: false,
          },
        },
      },
      };
    },

    resetInvoiceData(state, action) {
      return (state) = {
        ...state,
        ...{
          serviceOrderNumberList: [],
          isOpenClearAll: false,
          loading: false,
          taxRate: 1,
          errors: {},
          isOpenDelete: false,
          addItemModal: false,
          selectedItem: {},
          selectedWorkOrders: [],

          newInvoice: {
            customer: {},
            status: "open",
            serviceOrderDropDownValue: null,
            retailService: [],
            rowsData: [],
            invoice_date: moment(),
            due_date: '',
            serviceOrderUuid: [],
            total: '',
            sales_tax: '',
            balance_due: '',
            quantity: '',
            notes: '',
            amount_received: '0',
          },

          newItemModal: {
            openSelect: false,
            repairCodeData: [],
            repairCodeOptions: [],
            codeOptions: [],
            repairCodeValue: "",
            newItemModalData: {
              price: 0, fo_adjusted_price: 0, quantity: '', description: '',
              include_labor: false,
              include_bundle: false,
            },
          },
        }
      };
    },
  },
});

export const { setNewInvoiceDataField, setAddItemModal, setInvoiceDataField, resetInvoiceData, resetCurrentInvoiceData } = invoiceDataSlice.actions;
export default invoiceDataSlice.reducer;