import React, { useEffect, useState } from "react";
import { Select } from "antd";

const LeadStageSelector = ({
  handleChange = () => {},
  allValue = true,
  defaultValue,
}) => {
  const [leadStageList, setLeadStageList] = useState([]);

  useEffect(() => {
    const getLeadStageList = async () => {
      const data = [
        { key: "contacted", value: "Contacted" },
        { key: "interested", value: "Interested" },
        { key: "new", value: "New" },
      ];
      setLeadStageList(data ? data : []);
    };
    getLeadStageList();
  }, []);

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        placeholder={"Select a Status"}
      >
        {allValue ? (
          <Select.Option key={"all"} value={"all"} label={"All"}>
            {"All"}
          </Select.Option>
        ) : (
          <></>
        )}
        {leadStageList.map((option) => (
          <Select.Option key={option.key} value={option.key} label={option.value}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default LeadStageSelector;
