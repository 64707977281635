import React, { Component } from 'react';
import { Select, DatePicker, message } from 'antd';
import * as yup from 'yup';
import {map, uniqBy} from 'lodash';
import moment from 'moment-timezone';
import {errorHandle} from "../../Components/errorHandle";
const { Option } = Select;
class CreateNewTicket extends Component {

  state = {
    UserList: [],
    CustomerUser: [],
    GroupList: [],
    reason: [
      { label: 'Complaint', value: 'complaint' },
      { label: 'Missed service', value: 'missed_service' },
      { label: 'Schedule Problem', value: 'schedule_problem' },
      { label: 'Issue With Tech', value: 'issue_with_tech' },
      { label: 'Unhappy With Service', value: 'unhappy_with_service' },
      { label: 'Driving', value: 'driving' },            
    ],
    new_ticket: {
      title: '',
      message: '',
      reason: '',
      follow_up_date: '',
      status: 'open',
      priority: '4',
      assigned_user: '',
      user: '',
      cc_to_customer: false,
      assigned_group: '',
      zip_code: ''

    },
    assigned_group_details: { uuid: '', name: '' },
    loader: false,
    is_btn_Active: false,
    errors: {},
    role: ''

  }

  componentDidMount = () => {
    //get Group 
    this.props.getGroupList({ 'limit': 1000, offset: 0 })
    this.setState({
      UserList: this.props.UserList?.data?.results
    })
  }

  componentDidUpdate = (props) => {
    //Get Group list
    if(props.GroupList?.data)
    if (Object.keys(props.GroupList?.data).length > 0 && props.GroupList.data !== this.props.GroupList.data) {
      this.setState({
        loader: false,
        GroupList: props.GroupList.data.results
      })
    }

    if(props.GroupList?.errors)
    if (Object.keys(props.GroupList.errors).length > 0 && props.GroupList.errors !== this.props.GroupList.errors) {
      errorHandle(props.GroupList.errors)
      this.setState({
        loader: false,
      })
    }

    
    //Get Customer List
    if(props.AllCustomer?.data)
    if (Object.keys(props.AllCustomer.data).length > 0 && props.AllCustomer.data !== this.props.AllCustomer.data) {

      this.setState({
        loader: false,
        CustomerUser: props.AllCustomer.data.results
      })
    }
    if(props.AllCustomer?.errors)
    if (Object.keys(props.AllCustomer.errors).length > 0 && props.AllCustomer.errors !== this.props.AllCustomer.errors) {
      errorHandle(props.AllCustomer.errors)
      this.setState({
        loader: false,
      })
    }

    //Get User list
    if(props.UserList?.data)
    if (Object.keys(props.UserList.data).length > 0 && props.UserList.data !== this.props.UserList.data) {
      this.setState({
        loader: false,
        UserList: props.UserList.data.results
      })

      // }
      if(props.UserList?.errors)
      if (Object.keys(props.UserList.errors).length > 0 && props.UserList.errors !== this.props.UserList.errors) {
        message.error('Something went wrong', 10);
        this.setState({
          loader: false,
        })
      }
    }
    
    //Create New Ticket
    if(props.NewTicket?.data)
    if (Object.keys(props.NewTicket.data).length > 0 && props.NewTicket.data !== this.props.NewTicket.data) {
      this.setState({
        loader: false,
      });


      if (this.props.is_message_table) {
        //Get All the messages
        this.props.getAllMessages({ 'limit': 15, offset: 0 });
      }
      else {
        const accessToken = JSON.parse(localStorage.getItem("authUser"))
          ? JSON.parse(localStorage.getItem("authUser")).user
          : "";
        //Get My the messages
        this.props.getAllMessages({ 'offset': 0, 'limit': 15, assigned_user__uuid: accessToken.uuid });
      }

      this.closeAndClear();
    }

    if(props.NewTicket?.errors)
    if (Object.keys(props.NewTicket.errors).length > 0 && props.NewTicket.errors !== this.props.NewTicket.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }

  }



  // Create ticket onChange
  newTicketChange = (e) => {
    let { new_ticket } = this.state;
    new_ticket = Object.assign(new_ticket, { [e.target.name]: e.target.value });
    this.setState({
      new_ticket
    })
  }
  //Create ticket new change
  onSelectChange = (e, type) => {
    let { new_ticket } = this.state
    new_ticket = Object.assign(new_ticket, { [type]: e });
    this.setState({ new_ticket });
  }

  //Create New Ticket
  createNewTicket = () => {
    let { new_ticket, assigned_group_details } = this.state;
    let data = {
      ...new_ticket,
      assigned_group_details
    }

    if (assigned_group_details?.uuid) {
      delete data.assigned_user
    }

    if (new_ticket?.assigned_user) {
      delete data.assigned_group_details
      delete data.assigned_group
    }

    if (!data.follow_up_date) {
      delete data.follow_up_date
    }
    this.setState({
      loader: true
    })
    this.props.createNewTickets(data);
  }


  //Validation for required field
  ValidateTicket = () => {
    var self = this;
    setTimeout(() => {
      let { new_ticket } = this.state;
      const TicketSchema = yup.object().shape({
        new_ticket: yup.object().shape({
          title: yup.string().required("Title is required"),
          message: yup.string().required("Message is required"),
          assigned_group: yup.string(),
          assigned_user: yup.string().test('match', 'Assign user or Assign Group is required', function(assigned_user) {
            return !!(assigned_user || this.parent.assigned_group);
          }),
          user: yup.string().required("user is required"),
          // follow_up_date: yup.string().required("Follow up date is required"),
          status: yup.string().required("Status is required"),
          reason: yup.string().required("Reason is required"),
          zip_code: yup.string().required("Zipcode is required"),
        })
      })

      const TicketData = {
        new_ticket
      }

      // check validity
      let IsValid;
      let err_data = {};
      TicketSchema.isValid(TicketData)
        .then(function (valid) {
          IsValid = valid
        });

      setTimeout(() => {
        if (IsValid) {
          self.setState({
            errors: {}
          })
          //Create new ticket
          this.createNewTicket()
        }
        else {
          TicketSchema.validate(TicketData, { abortEarly: false })
            .catch(function (err) {
              map(err.inner, (item) => {
                err_data[item.path] = item.message;
              })
              self.setState({
                errors: err_data,
                is_btn_Active: false
              })
              console.log("err_data:", err_data)

            });
        }
      }, 100);

    }, 300);
  }
  // close and clear ticket data
  closeAndClear = () => {
    this.props.newTicketToggle(false)
    this.setState({
      errors: {},
      new_ticket: {
        title: '',
        message: '',
        reason: '',
        follow_up_date: '',
        status: 'open',
        priority: '4',
        assigned_user: '',
        user: '',
        cc_to_customer: false,
        zip_code: ''
      },
      assigned_group_details: { uuid: '', name: '' },
    })
  }

  //render User list
  renderUser = () => {
    let { UserList } = this.state;
    return map(UserList, (item, i) => {

      return (
        <Option value={item.uuid} key={i}>{item.first_name} {item.last_name} </Option>
      )
    })
  }

  renderCustomerAccountsOptions = (customers) => {
    let options = [];

    customers.forEach((item, i) => 
    {
      options = options.concat(item.address.map((address, j) => {
        return <Option key={`${i}-${j}`} value={item.uuid}>{item.first_name} {item.last_name} | 
          <React.Fragment>
            {address.formatted_address}
          </React.Fragment> 
        </Option>
      }))
    });

    return options;
  }

  getAllCustomerList = () => {

  }

  render() {
    let { reason, new_ticket, GroupList, CustomerUser, assigned_group_details, errors, loader } = this.state;

    return (
      <div className="group_wrapper rdrawer">
        <div className="card-row">
          <div className="card-full">
            <h4>Create New Ticket</h4>
            <div className="form-group">           
              <div className="fitem">
                <input
                  type="text"
                  placeholder="Add your ticket title"
                  name="title"
                  value={new_ticket.title}
                  onChange={(e) => {
                    this.newTicketChange(e);
                  }}
                />
                {errors['new_ticket.title'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.title']}</label> : ''}
              </div>

              <div className="fitem">
                <textarea
                  placeholder="Add your message here"
                  name="message"
                  rows="7"
                  value={new_ticket.message}
                  onChange={(e) => {
                    this.newTicketChange(e);
                  }}
                ></textarea>
                {errors['new_ticket.message'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.message']}</label> : ''}
              </div>
              <h5 className="mt-20 mb-0">Ticket Details</h5>
              <hr />
              <div className="fitem half-fitem">
                <div className=" fitem half-fitem">
                  <label>Follow Up Date</label>
                  <DatePicker
                    style={{ 'width': '100%' }}
                    name='follow_up_date'
                    allowClear={false}
                    format="MM/DD/YYYY"
                    suffixIcon={<i className="las la-calendar"></i>}
                    disabledDate={(current) => { return current < moment().startOf('day'); }}
                    onChange={(e) => {
                      new_ticket = Object.assign(new_ticket, { 'follow_up_date': moment(e).format('YYYY-MM-DD') });
                      this.setState({
                        new_ticket
                      })
                    }}
                    value={new_ticket.follow_up_date ? moment(new_ticket.follow_up_date) : null}
                  />
                  {errors['new_ticket.follow_up_date'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.follow_up_date']}</label> : ''}
                </div>
                <div className="fitem half-fitem">
                  <label>Disposition</label>
                  <Select
                    style={{ 'width': '100%' }}
                    value={new_ticket.reason}
                    onChange={(e) => {
                      this.onSelectChange(e, 'reason')
                    }}
                    defaultValue="">
                    <Option value=""></Option>
                    {
                      map(reason, (item, i) => {
                        return (<Option value={item.value} key={i}>{item.label}</Option>)
                      })
                    }
                  </Select>
                  {errors['new_ticket.reason'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.reason']}</label> : ''}
                </div>

              </div>

              <div className="fitem half-fitem">
                <div className=" fitem half-fitem">
                  <label>Priority</label>
                  <Select
                    defaultValue=""
                    value={new_ticket.priority}
                    onChange={(e) => {
                      this.onSelectChange(e, 'priority')
                    }}

                    style={{ 'width': '100%' }}
                  >
                    <Option value="">All</Option>
                    <Option key="2" value="4"><span className="medium prty">Standard Priority</span></Option>
                    <Option key="3" value="3"><span className="high prty">High Priority</span></Option>
                  </Select>

                  <Select
                    showSearch={true}
                    showArrow={true}
                    filterOption={false}
                    notFoundContent={null}
                    style={{ 'width': '100%' }}
                    className="mt-10"
                    placeholder="Assign to Group"
                    value={assigned_group_details.name}
                    defaultValue=""
                    disabled={new_ticket.assigned_user}
                    allowClear={true}
                    onChange={(a, b) => {
                      let { assigned_group_details } = this.state;
                      assigned_group_details = Object.assign(assigned_group_details, { name: a, uuid: b?.key || '' })
                      new_ticket = Object.assign(new_ticket, { 'assigned_group': b?.key || '', assigned_user: '' })
                      this.setState({
                        assigned_group_details,
                        new_ticket,
                        loader: true,
                        UserList: []
                      });
                      //Get Staff based on group
                      this.props.getUserList({ 'limit': 1000, offset: 0, group__uuid: b?.key || '', 'ordering': 'first_name', 'is_case_mgmt': true });
                    }}
                    onBlur={() => { this.props.getGroupList({ 'offset': 0, 'limit': 15 }); }}
                    onSearch={(e) => this.props.getGroupList({ 'search': e })}
                  >
                    {uniqBy(GroupList.filter(item => item.status === 'active'), 'uuid', 'asc').map((item) => {

                      return (
                        <Option key={item.uuid} value={item.name}>{item.name}</Option>
                      )
                    })}
                    <Option value="">Assign to Group</Option>
                  </Select>
                  {errors['new_ticket.assigned_user'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.assigned_user']}</label> : ''}
                </div>

                <div className=" fitem half-fitem">
                  <label>Status</label>
                  <Select
                    defaultValue=""
                    value={new_ticket.status}
                    onChange={(e) => {
                      this.onSelectChange(e, 'status')
                    }}
                    style={{ 'width': '100%' }}
                  >
                    <Option value="">All</Option>
                    <Option key='open' value="open"><span className="open prty">Open</span></Option>
                    <Option key='closed' value="closed"><span className="closed prty">Closed</span></Option>
                  </Select>
                  {errors['new_ticket.status'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.status']}</label> : ''}
                  <Select
                    disabled={new_ticket?.assigned_group}
                    allowClear={true}
                    showSearch
                    showArrow={true}
                    filterOption={false}
                    notFoundContent={null}
                    placeholder="Search proposal"
                    defaultValue=""
                    value={new_ticket.assigned_user}
                    onChange={(e) => {
                      this.onSelectChange(e, 'assigned_user');
                    }}
                    onSearch={(e) => {
                      if (assigned_group_details.uuid) {
                        this.props.getUserList({ 'limit': 1000, 'search': e, group__uuid: assigned_group_details.uuid, 'ordering': 'first_name', 'is_case_mgmt': true})
                      } else {
                        this.props.getUserList({ 'limit': 1000, 'search': e, 'ordering': 'first_name', 'is_case_mgmt': true})
                      }
                    }}

                    onBlur={() => {
                      if (assigned_group_details.uuid) {
                        this.props.getUserList({ 'limit': 1000, group__uuid: assigned_group_details.uuid, 'ordering': 'first_name', 'is_case_mgmt': true });
                      }
                    }}
                    className=" mt-10"
                  >
                    <Option value="">Assign to ASP Individual</Option>
                    {this.renderUser()}

                  </Select>
                  {/*{errors['new_ticket.assigned_user'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.assigned_user']}</label> : ''}*/}
                </div>
              </div>
              <div className="fitem">
                <Select
                  showSearch
                  showArrow={true}
                  filterOption={false}
                  notFoundContent={null}
                  placeholder="Search proposal"
                  defaultValue=""
                  value={new_ticket.user}
                  onChange={(e) => { this.onSelectChange(e, 'user') }}
                  onSearch={(e) => {
                    if (new_ticket.zip_code.length > 0) {
                      this.getAllCustomerList({ 'q': e, user_address__zip_code: new_ticket.zip_code })
                    }

                  }}
                  onBlur={() => {
                    if (new_ticket.zip_code.length > 0) {
                      this.getAllCustomerList({ 'limit': 15, offset: 0, user_address__zip_code: new_ticket.zip_code });
                    }

                  }}
                >
                  <Option value="">Assign to Customer Account</Option>
                  {
                    map(CustomerUser, (item, i) => {

                      return (<Option key={i} value={item.uuid}>{item.first_name} {item.last_name},&nbsp;
                        {item.field_office ?
                          <React.Fragment>
                            {item.service_address.address}, {item.service_address.city}, {item.service_address.state} {item.service_address.zip_code}
                          </React.Fragment> : ''} </Option>)
                    })
                  }
                </Select>
                {errors['new_ticket.user'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.user']}</label> : ''}

              </div>
              <div className="fitem">
                <div className="fitem half-fitem inline-check-list">
                  <div className="fitem-check">
                    <input
                      onChange={(e) => {
                        new_ticket = Object.assign(new_ticket, { 'cc_to_customer': e.target.checked });
                        this.setState({
                          new_ticket
                        })
                      }}
                      checked={new_ticket.cc_to_customer}
                      type="checkbox"
                      className="fitem-ck-input"
                      id="cc" />
                    <label htmlFor="cc" className="fitem-ck-txt" >CC ticket to  Customer </label></div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="footer" style={{ 'width': '690px' }}>
          <button
            className="btn btn-brand btn-bdr"
            onClick={() => {

              this.closeAndClear()
            }}
          >Cancel</button>
          <button
            // disabled={!is_btn_Active}
            // className={is_btn_Active ? 'btn btn-warning' : 'btn disabled'}
            className="btn btn-warning"
            onClick={() => { this.ValidateTicket() }}

          >Create Ticket</button>
        </div>
        {
          loader ?
            <div className='loader_wrapper'>
              < div className='loader07' ></div >
            </div >
            : ''
        }
      </div>
    );
  }
}

export default CreateNewTicket;