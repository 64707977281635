import React, { createContext, useState, useMemo, useContext } from "react";
import { creationFramePeriod } from "../Common/creationFramePeriod.function";
import { getFranchiseSelected } from "../../Utils/selectedFranchise";

const DashboardContext = createContext();

export function DashboardProvider({ children }) {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;

  const [franchise, setFranchise] = useState(
    getFranchiseSelected()?.uuid || 
    userInfo?.user?.associated_field_offices[0].uuid
  );
  const [period, setPeriod] = useState(creationFramePeriod("day"));
  const [periodType, setPeriodType] = useState("today");

  const value = useMemo(() => {
    return {
      franchise,
      setFranchise,
      setPeriod,
      period,
      periodType,
      setPeriodType,
    };
  }, [franchise, setFranchise, setPeriod, period, periodType, setPeriodType]);

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboard() {
  return useContext(DashboardContext);
}
