import React, { useState, useCallback, useEffect } from "react";
import { Col } from "antd";
import moment from "moment-timezone";
import _ from "lodash";
import newCustomerIcon from "../../../Assets/icons/new-customers.svg";
import newLeadsIcon from "../../../Assets/icons/new-leads.svg";
import revenueIcon from "../../../Assets/icons/revenue.svg";
import invoicedIcon from "../../../Assets/icons/invoice.svg";
import { formatToCurrencyNoDecimal } from "../../../Components/Common/formatToCurrency";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import SummaryCard from "../Cards/Summary.Card";
import {
  getDashboardSummary,
  getDashboardSummaryInvoiced,
} from "../../../Action/Dashboard";

const SummaryContainer = () => {
  const [summaryMetrics, setSummaryMetrics] = useState([
    {
      title: "New Customer",
      slug: "new-lead",
      text_color: "#4FCFF6",
      icon_src: "/media/new-customers.svg",
      order: 1,
      linkTo: "/customer-lead-list",
      loading: true,
      currency: false,
    },
    {
      title: "New Lead",
      slug: "new-customer",
      text_color: "#FF681D",
      icon_src: "/media/new-leads.svg",
      order: 2,
      linkTo: "/customer-lead-list",
      loading: true,
      currency: false,
    },
    {
      title: "Revenue",
      slug: "revenue",
      text_color: "#005D89",
      icon_src: "/media/revenue.svg",
      order: 3,
      linkTo: "/account/account-list",
      loading: true,
      currency: true,
    },
    {
      title: "To Be Invoiced",
      slug: "to-be-invoiced",
      text_color: "#005D89",
      icon_src: "/media/invoice.svg",
      order: 4,
      linkTo: "/work-orders",
      loading: true,
      currency: true,
    },
  ]);
  const { franchise, periodType } = useDashboard();

  const formatSummaryInfo = (element) => {
    switch (element.title) {
      case "New Lead":
        return {
          ...element,
          slug: "new-lead",
          title: element.total_count > 1 ? "New Leads" : "New Lead",
          text_color: "#FF681D",
          icon_src: newLeadsIcon,
          order: 2,
          linkTo: "/customer-lead-list",
          loading: false,
          currency: false,
        };
      case "New Customer":
        return {
          ...element,
          slug: "new-customer",
          title: element.total_count > 1 ? "New Customers" : "New Customer",
          text_color: "#4FCFF6",
          icon_src: newCustomerIcon,
          order: 1,
          linkTo: "/customer-lead-list",
          loading: false,
          currency: false,
        };
      case "Revenue":
        return {
          ...element,
          slug: "revenue",
          text_color: "#005D89",
          icon_src: revenueIcon,
          total_count: `$ ${formatToCurrencyNoDecimal(element.total_count)}`,
          order: 3,
          linkTo: "/account/account-list",
          loading: false,
          currency: true,
        };
      case "To Be Invoiced":
        return {
          ...element,
          slug: "to-be-invoiced",
          text_color: "#66AA5F",
          icon_src: invoicedIcon,
          total_count: `$ ${formatToCurrencyNoDecimal(element.total_count)}`,
          order: 4,
          linkTo: "/work-orders",
          loading: false,
          currency: true,
        };
      default:
        return {
          ...element,
          text_color: "#758F9A",
          icon_src: null,
        };
    }
  };

  const getDashboardMetrics = useCallback(async () => {
    const data = {
      filter_date: moment().format("YYYY-MM-DD"),
      franchise_filter: franchise,
      period_filter: periodType === "day" ? "today" : periodType,
    };

    getDashboardSummary(data).then((response) => {
      if (response.status !== 200) {
        return;
      }
      const process_dashboard_metrics = [...response.data]
        .map(formatSummaryInfo)
        .sort((a, b) => a.order - b.order);
      setSummaryMetrics((state) =>
        _.unionBy(process_dashboard_metrics, state, "slug").sort(
          (a, b) => a.order - b.order
        )
      );
    });

    getDashboardSummaryInvoiced(data).then((response) => {
      if (response.status !== 200) {
        return;
      }
      const process_dashboard_metrics = [...response.data].map(
        formatSummaryInfo
      );
      setSummaryMetrics((state) =>
        _.unionBy(process_dashboard_metrics, state, "slug").sort(
          (a, b) => a.order - b.order
        )
      );
    });
  }, [periodType, franchise, setSummaryMetrics]);

  useEffect(() => {
    getDashboardMetrics();
  }, [getDashboardMetrics]);

  useEffect(() => {
    setSummaryMetrics(
      summaryMetrics.map((metric) => ({ ...metric, loading: true }))
    );
  }, [franchise, periodType]);

  return summaryMetrics.map((item, idx) => (
    <Col sm={24} xxl={6} md={12} key={idx}>
      <SummaryCard
        total_count={item.total_count}
        title={item.title}
        total_residential={item.total_residential}
        total_commercial={item.total_commercial}
        compared_percentage={item.compared_percentage}
        text_color={item.text_color}
        icon_src={item.icon_src}
        linkTo={item.linkTo}
        loading={item.loading}
        currency={item.currency}
        slug={item.slug}
      />
    </Col>
  ));
};

export default SummaryContainer;
