import React, { useEffect, useState } from "react";
import { Select, message } from "antd";
import "../../../Assets/css/proposalBase.css";
import { getServicesAddress } from "../../../Action/Customer";
import { getPoolCharacteristics } from "../../../Action/Customer";
import {
  poolCharachterResult,
  serviceAddressApi,
  addressoption,
  addressoptionPool,
  proposalPoolType,
  setServiceAddress,
  addName,
  addSize,
} from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

const PoolNoFile = (props) => {
  const { editMode,matrixdata} = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);

  const [addressDetails, setAddressDetails] = useState([]);
  const [addressOption, setAddressOption] = useState([]);
  const [poolOption, setPoolOption] = useState([]);
  const [pools, setPools] = useState([]);
  useEffect(() => {
    getServiceAddressByApi();
    getPoolNameByApi();
  }, []);
  useEffect(() => {
    if (proposals.service_address_uuid === 'New') {
      setPoolOption([<Option key='New'>New</Option>])
    }else {
      if(Object.keys(proposals.select_pool_api).length){
        const temp_pool = [<Option key="new" value="New"></Option>];
        proposals.select_pool_api.map((item) => {
          if (item.pool_name && item.pool_type_uuid) {
            temp_pool.push(
              <Option key={item.pool_name} value={item.pool_name}></Option>
            );
          }
        });
        setPoolOption(temp_pool);
      }
    }
  }, [proposals.service_address_uuid, proposals.select_pool_api])
  const getServiceAddressByApi = async () => {
    const temp_address = [<Option key="New">New</Option>];
    const data = await getServicesAddress(props?.matrixdata?.uuid);
    dispatch(serviceAddressApi(data));
    if (data?.length === 1 && matrixdata?.customer_type==="Residential"    ) {
      data.map((item) =>
        temp_address.push(
          <Option key={item?.uuid}>{item?.formatted_address}</Option>
        )
      );
      setAddressOption(temp_address);
      setAddressDetails(data);
      dispatch(addressoption(data[0].uuid));
      dispatch(setServiceAddress(data[0]))
    } else if (data?.length > 1) {
      data.map((item) =>
        temp_address.push(
          <Option key={item?.uuid}>{item?.formatted_address}</Option>
        )
      );
      setAddressOption(temp_address);
      setAddressDetails(data);
    }else if (matrixdata?.customer_type ==="Commercial") {
      data.map((item) =>
      temp_address.push(
        <Option key={item?.uuid}>{item?.formatted_address}</Option>
      )
    );
    setAddressOption(temp_address);
    setAddressDetails(data);
    } else {
      message.error("The customer does not have Services Addresses.");
    }
  };

  const getPoolNameByApi = async () => {
    const temp_pool = [<Option key="new" value="New"></Option>];
    const data = await getPoolCharacteristics(props?.matrixdata?.uuid);
    dispatch(poolCharachterResult(data));
    if (data?.length === 1) {
      if (data[0].pool_name && data[0].pool_type_uuid) {
        data.map((item) =>
          temp_pool.push(
            <Option key={item?.pool_name}>{item?.pool_name}</Option>
          )
        );
        setPoolOption(temp_pool);
        dispatch(addressoptionPool(data[0].pool_name));
        dispatch(proposalPoolType(data[0].pool_type_uuid));
        dispatch(addName(data[0].pool_name));
        dispatch(addSize(data[0].pool_size));
        dispatch(setServiceAddress(proposals.select_service_api[0]));
      }
    } else if (data?.length > 1 && proposals?.service_address_uuid !== 'New') {
      data.map((item) => {
        if (item.pool_name && item.pool_type_uuid) {
          temp_pool.push(
            <Option key={item.pool_name} value={item.pool_name}></Option>
          );
        }
      });
    }
    setPoolOption(temp_pool);

  };
  
  const reloadPools = async () => {
    const temp_pool = [<Option key='New'>New</Option>];
    const data = await getPoolCharacteristics(props?.matrixdata.uuid);
    if (data?.length === 1) {
      if (data[0].pool_name && data[0].pool_type_uuid) {
        data.map((item) =>
          temp_pool.push(
            <Option key={item?.pool_name}>{item?.pool_name}</Option>
          )
        );
        setPoolOption(temp_pool);
        dispatch(addressoptionPool(data[0].pool_name));
        dispatch(proposalPoolType(data[0].pool_type_uuid));
        dispatch(addName(data[0].pool_name));
        dispatch(addSize(data[0].pool_size));
        dispatch(setServiceAddress(proposals.select_service_api[0]));
      }
    } else if (data?.length > 0) {
      let pool_list = data.filter(i => i.service_address?.uuid === proposals?.service_address_uuid)
      pool_list.map((item) => {
        if(item.pool_name && item.pool_type_uuid){
          temp_pool.push(
            <Option key={item.pool_name} value={item.pool_name}></Option>
          )
        }
      });
      setPoolOption(temp_pool);
      if (pool_list.length > 0) {
        dispatch(addName(pool_list[0]?.pool_name));
        dispatch(proposalPoolType(pool_list[0]?.pool_type_uuid ?? ""));
        dispatch(addSize(pool_list[0]?.pool_size ?? 0));
        dispatch(addressoptionPool(pool_list[0]?.pool_name))

      }
    }
  }

  const reloadPoolsRedirect = async () => {
    const temp_pool = [<Option key='New'>New</Option>];
    const data = await getPoolCharacteristics(props?.matrixdata.uuid);
    if (data?.length === 1) {
      if (data[0].pool_name && data[0].pool_type_uuid) {
        data.map((item) =>
          temp_pool.push(
            <Option key={item?.pool_name}>{item?.pool_name}</Option>
          )
        );
        setPoolOption(temp_pool);
        dispatch(addressoptionPool(data[0].pool_name));
        dispatch(proposalPoolType(data[0].pool_type_uuid));
        dispatch(addName(data[0].pool_name));
        dispatch(addSize(data[0].pool_size));
        dispatch(setServiceAddress(proposals.select_service_api[0]));
      }
    } else if (data?.length > 0) {
      let pool_list = data.filter(i => i.service_address?.uuid === proposals?.service_address_uuid)
      pool_list.map((item) => {
        if(item.pool_name && item.pool_type_uuid){
          temp_pool.push(
            <Option key={item.pool_name} value={item.pool_name}></Option>
          )
        }
      });
      setPoolOption(temp_pool);
      if (pool_list.length > 0) {
        dispatch(addName(pool_list[0]?.pool_name));
        dispatch(proposalPoolType(pool_list[0]?.pool_type_uuid ?? ""));
        dispatch(addSize(pool_list[0]?.pool_size ?? 0));
        dispatch(addressoptionPool(pool_list[0]?.pool_name))

      }else {
        dispatch(addName(''));
        dispatch(proposalPoolType(''));
        dispatch(addSize(''));
        dispatch(addressoptionPool('New'))
      }
    }
  }

  const reloadAddress = async () => {
        const temp_address = [<Option key="New">New</Option>];
    const data = await getServicesAddress(props?.matrixdata?.uuid);
    dispatch(serviceAddressApi(data));
    if (data?.length === 1 && (matrixdata?.proposalData?.proposal_user_type == "residential")) {
      data.map((item) =>
        temp_address.push(
          <Option key={item?.uuid}>{item?.formatted_address}</Option>
        )
      );
      setAddressOption(temp_address);
      setAddressDetails(data);
      dispatch(addressoption(data[0].uuid));
      dispatch(setServiceAddress(data[0]))
    } else if (data?.length > 1 && (matrixdata?.proposalData?.proposal_user_type == "residential")) {
      data.map((item) =>
        temp_address.push(
          <Option key={item?.uuid}>{item?.formatted_address}</Option>
        )
      );
      setAddressOption(temp_address);
      setAddressDetails(data);
    }else if (matrixdata?.proposalData?.proposal_user_type == "commercial") {
      data.map((item) =>
      temp_address.push(
        <Option key={item?.uuid}>{item?.formatted_address}</Option>
      )
    );
    setAddressOption(temp_address);
    setAddressDetails(data);
    dispatch(addressoption(temp_address[0]));
    dispatch(setServiceAddress(temp_address[0]))
    } else {
      message.error("The customer does not have Services Addresses.");
    }
  }
  useEffect(() => {
    if (matrixdata?.proposalData?.proposal_user_type) {
      reloadPoolsRedirect();
      reloadAddress();
    } else {
      setPoolOption([<Option key="New">New</Option>]);
    }
  }, [matrixdata?.proposalData?.proposal_user_type]);

  const onTextChange = (e) => {
    dispatch(addressoption(e.target.value));
    if (e.target.value !== "New") {
      const resultapi = proposals.select_pool_api.filter(x => x.uuid === proposals.address_option_pool);
      dispatch(addressoptionPool(resultapi[0]?.pool_name));
      dispatch(addName(resultapi[0]?.pool_name));
      dispatch(addSize(resultapi[0]?.pool_size));
      dispatch(proposalPoolType(resultapi[0]?.pool_type_uuid));
      const result = proposals.select_service_api.filter(x => x.uuid === e.target.value);
      dispatch(setServiceAddress(result[0]));
    } else {
      dispatch(addressoptionPool('New'));
      dispatch(addName(''));
      dispatch(addSize(0));
      dispatch(proposalPoolType(''));
      dispatch(setServiceAddress(''));
    }
  };
  

  const onPoolOptionChange = (e) => {
    dispatch(addressoptionPool(e.target.value));
    if (e.target.value !== "New") {
      const resultapi = proposals.select_pool_api.filter(x => x.pool_name === e.target.value);
      dispatch(proposalPoolType(resultapi[0]?.pool_type_uuid));
      dispatch(addName(resultapi[0]?.pool_name));
      dispatch(addSize(resultapi[0]?.pool_size));
    }else{
      dispatch(addressoptionPool('New'));
      dispatch(proposalPoolType(''));
      dispatch(addName(''));
      dispatch(addSize(0));
    }
  };

  return (
    <div className="form-group mb-20">
      {proposals.service_type_name != "maintenance" && (
        <h3>Enter Service Address And Pool Name</h3>
      )}
      <div className="half-fitem fitem">
        <div className="half-fitem fitem">
          <label style={{ fontWeight: "bold" }}>Select Service Address</label>
          <Select
            showArrow={true}
            style={{ width: "100%" }}
            name="serviceAddress"
            placeholder="Select"
            virtual={false}
            value={proposals.service_address_uuid}
            disabled={editMode}
            onChange={(value) => onTextChange({ target: { value: value } })}
          >
            {addressOption}
          </Select>
        </div>
        <div className="half-fitem fitem">
          <label style={{ fontWeight: "bold" }}>Select Pool</label>
          <Select
            showArrow={true}
            style={{ width: "100%" }}
            name="pool"
            placeholder="Select"
            virtual={false}
            value={proposals.address_option_pool}
            disabled={editMode}
            onChange={(value) =>
              onPoolOptionChange({ target: { value: value } })
            }
          >
            {poolOption}
          </Select>
        </div>
      </div>
    </div>
  );
};
export default PoolNoFile;
