import { Col, Row, Select, message } from "antd";
import React, { useState, useRef, useEffect, useMemo } from "react";
import { map, isEmpty, debounce, set } from "lodash";
import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ModalComponent from "../../Components/Common/modal";
import AddressField from "../../Components/AddressField";
import { createCustomer, getCustomer, getCommercialCustomer } from "../../Action/Customer";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { getCustomersForRetailSales, retailSaleCustomerData, createCommercialCustomer, activateDeactivatedCustomer } from "../../Action/Customer";

const RetailNewCustomer = ({ isOpen, toggleModal }) => {
  const navigate = useNavigate();
  const { Option } = Select;
  const REGULAR_EXPRESSION_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [address, setAddress] = useState({ address: '', state: '', city: '', zip_code: '' })
  const [customerType, setCustomerType] = useState('residential')
  const [firstName, setFisrtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('')
  const [errors, setErrors] = useState({})
  const [selectedCustomerError, setSelectedCustomerError] = useState('');
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('')
  const [addNewCustomerModel, setAddNewCustomerModel] = useState(false);
  const [address_ref, setAddress_ref] = useState('');
  const [hideAddCustomer, setHideAddCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [fetching, setFetching] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const fetchRef = useRef(0);

  const fetchInitialData = async () => {
    const customers = await getCustomers();
    setCustomerOptions(customers);
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  const onAddInvoice = async () => {
    let customerDetail = { ...selectedCustomer };
    if (!Object.keys(customerDetail).length) {
      setSelectedCustomerError("Customer Selection Required");
    } else {
      let response = true;
      if (customerDetail?.status === 'inactive') {
        response = false;
        const data = {
          "customer_status": "Activate",
          "cancelation_reason": '',
        }
        response = await activateDeactivatedCustomer(customerDetail?.uuid, data);
        response = false;
        if (customerDetail?.client_type === 'Residential') {
          customerDetail = await getCustomer(customerDetail?.uuid);
          response = true;
        }
        if (customerDetail?.client_type === 'Commercial') {
          customerDetail = await getCommercialCustomer(customerDetail?.uuid);
          response = true;
        }
      }
      if (response) {
        if (customerDetail?.client_type === 'Residential') {
          navigate(`/residential/customer-detail`, {
            state: {
              record: {
                isRetailSale: true,
                selectedTab: 4,
                ...customerDetail
              }
            }
          });
        }
        if (customerDetail?.client_type === 'Commercial') {
          navigate(`/commercial/customer-detail`, {
            state: {
              record: {
                isRetailSale: true,
                selectedTab: 4,
                ...customerDetail
              }
            }
          });
        }
      }
      onCancelRetailSales();
    }
  };

  const onCancelRetailSales = () => {
    toggleModal();
    setCustomerOptions([]);
    setHideAddCustomer(false);
    setSelectedCustomer({});
    setSelectedCustomerError('');
  };

  const getCustomers = async (searchText = '') => {
    const query = `?q=${searchText}&limit=15&offset=0&search_customer=True`;
    const response = await getCustomersForRetailSales(query);
    return response?.results || [];
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setCustomerOptions([]);
      setFetching(true);
      getCustomers(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setCustomerOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, 0);
  }, [getCustomers, 0]);



  const handleCutomerType = (e) => {
    setCustomerType(e)
  }
  const checkMail = (e) => {
    // API call to check Email
    return true
  }
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value)
  }
  const handleFirstName = (e) => {
    setFisrtName(e.target.value)
  }
  const handleAddress = (e) => {
    setAddress(e.target.value)
  }
  const handleLastName = (e) => {
    setLastName(e.target.value)
  }
  const onPrimaryEmailChanged = (index) => {
    map(emails, (obj) => obj.primary = false)
    emails[index].primary = true
    setEmails([...emails])
  }
  const create_UUID = () => {
    // Generate and return a UUID
    // You can use any implementation to generate a UUID
    // Here's a simple example using the Math.random() function
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };


  const genarateGibberrishEmail = () => {
    let newEmail = [{ email: '', primary: true }]
    // Issue with previous code
    newEmail[0].email = create_UUID() + '@doody.com'
    setEmails(newEmail);
  }

  const onPhonePrimaryChanged = (index) => {
    map(phoneNumbers, (obj) => obj.primary = false)
    phoneNumbers[index].primary = true
    setPhoneNumbers([...phoneNumbers])
  }
  const formatPhoneNumber = (phone) => {
    if (!phone) return ''
    phone = phone?.replace(/\D/g, '');
    return '(' + phone?.substr(0, 3) + ') ' + phone?.substr(3, 3) + '-' + phone?.substr(6);
  }
  const onNumericInputChange = (e) => {
    const reg = /^-?\d*(\.\d*)?$/;
    return ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-')
  }
  const onAdd = (type) => {
    if (type === 'phone') {
      let obj = {}
      if (phoneNumbers.length === 0) {
        obj = { 'phone': phone, 'primary': true, type: 'mobile' }
      } else {
        obj = { 'phone': phone, 'primary': false, type: 'mobile' }
      }
      setPhoneNumbers([...phoneNumbers, obj])
      setPhone('')
    }
    if (checkMail(email) && type === 'email') {
      let obj = {}
      if (emails.length === 0) {
        obj = { 'email': email.toLowerCase(), 'primary': true }
      } else {
        obj = { 'email': email.toLowerCase(), 'primary': false }
      }
      setEmails([...emails, obj])
      setEmail('')
    }
  }

  const onTextChange = (e) => {
    if (onNumericInputChange(e) && e.target.name === 'phone') {
      setPhone(e.target.value)
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    }
  }

  const onChange = (row, name) => {
    let data = name === 'phone' ? phoneNumbers : emails;
    data.splice(row, 1);
    if (name === 'phone' || name === 'email') {
      const idx = data?.findIndex(x => x.primary)
      if (idx <= -1 && !isEmpty(data)) {
        data[0].primary = true;
      }
    }
    name === 'phone' ? setPhoneNumbers([...data]) : setEmails([...data]);
  };

  // on address change
  const onAddressChange = (e, addr_ref) => {
    setAddress_ref(addr_ref);
    Object.assign(address, { [e.target.name]: e.target.value });
    setAddress(address);
  };

  const callCreateCustomer = async () => {
    const primaryEmail = emails.filter((item) => item.primary === true)
    const { user: authUser } = JSON.parse(localStorage.getItem("authUser"));
    if (customerType === "residential") {
      const customerObj = {
        "field_office_uuid": authUser?.associated_field_offices[0]?.uuid,
        "service_address": address,
        "billing_address": address,
        "is_billing_address_same": true,
        "first_name": firstName,
        "last_name": lastName,
        "email": primaryEmail[0].email,
        "emailaddress_set": emails,
        "phone_numbers": phoneNumbers,
        "hear_about_us": null,
        "is_active": true,
        "status": "active",
        "client_type": customerType,
        "zip_code": authUser?.associated_field_offices[0]?.addressable_zip_codes[0],
      }
      try {
        const data = await createCustomer(customerObj);
        if (data?.uuid) {
          closeModal();
          setAddNewCustomerModel(false);
          const customerData = await retailSaleCustomerData(data.uuid);
          if (customerData?.uuid) {
            navigate(`/residential/customer-detail`, {
              state: {
                record: {
                  isRetailSale: true,
                  selectedTab: 4,
                  ...customerData,
                }
              }
            });
          }
        } else {
          message.error("This email is already registered.");
        }
      } catch (e) {
        console.log(e)
      }

    } else {
      let customerObj = {
        "field_office_uuid": authUser?.associated_field_offices[0]?.uuid,
        "company_name": companyName,
        "no_of_properties":"",
        "no_of_pools":"",
        "notes":"",
        "address": [address],
        "invoice_address": address,
        "lead_stage": "customer",
        "properties_in_community": [],
        "preferred_contact_methods": [],
        "outreach": "Franchise",
        "is_billing_address_same": true,
        "first_name": firstName,
        "last_name": lastName,
        "email": primaryEmail[0].email,
        "emailaddress_set": emails,
        "zip_code": authUser?.associated_field_offices[0]?.addressable_zip_codes[0],
        "is_retail": true,
        "phone_numbers": phoneNumbers,
        "hear_about_us": null,
        "is_active": true,
        "status": "active",
        "client_type": customerType,
        "role":["commercial_customer"]
      }

      try {
        const data = await createCommercialCustomer(customerObj);
        if (data.uuid) {
          closeModal(false);
          setAddNewCustomerModel(false);
          const customerData = await getCommercialCustomer(data?.uuid);
          if (customerData?.uuid) {
            navigate("/commercial/customer-detail", {
              state: {
                record: {
                  isRetailSale: true,
                  selectedTab: 4,
                  ...customerData,
                }
              }
            });
          }
        } else {
          message.error("This email is already registered.");
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  const addCustomer = async () => {
    if (!addNewCustomerModel) {
      onAddInvoice();
    }
    else {

        const schema = yup.object().shape({
          firstName: yup.string().required("First name is required."),
          lastName: yup.string().required("Last name is required."),
          companyName:
            customerType === "commercial"
              ? yup.string().required("company name is required.")
              : null,
          phoneNumbers: yup
            .array(
              yup.object({
                phone: yup.string().required("Phone is required"),
              })
            )
            .min(1)
            .required("Phone number is required"),
          emails: yup
            .array(
              yup.object({
                email: yup.string().required("Email is required"),
              })
            )
            .min(1)
            .required("Email Address is required"),
          address: yup.object().shape({
              address: yup.string().required('address is required.'),
              city: yup.string().required('city is required.'),
              state: yup.string().required('state is required.'),
              zip_code: yup.string().required('zipcode is required.'),
            }),
        });
        const data = {
          firstName,
          lastName,
          customerType,
          companyName,
          address,
          phoneNumbers,
          emails,
        };

        schema
          .validate(data, { abortEarly: false })
          .then(async () => {
            setErrors({});
            // Call api to create customer on backend
            await callCreateCustomer();
          })
          .catch((error) => {
            let err_data = {};
            map(error?.inner, (item) => {
              err_data = set(err_data, item?.path, item?.message);
            });
            setErrors(err_data);
            console.log(error);
          });

    }
  }

  const closeModal = () => {
    if (!addNewCustomerModel) {
      onCancelRetailSales();
      return;
    }
    toggleModal(false);
    setAddNewCustomerModel(false);
    setEmail("");
    setEmails([]);
    setPhone('');
    setPhoneNumbers([]);
    setAddress('');
    setFisrtName('');
    setAddress('');
    setLastName('');
    setCompanyName('');
    setErrors({});
    setAddress_ref('');
    setCustomerType("Residential");
  }

  return (
    <ModalComponent
      maskClosable={false}
      title={addNewCustomerModel ? "Add New Customer" : "Product Sale"}
      visible={isOpen}
      width={350}
      closable={false}
      className={addNewCustomerModel ? 'addCustomerRetailSaleModal' : 'retailSaleModal'}
      onCancel={closeModal}
      footer={[
        <button
          key="cancel"
          className='btn btn-close'
          onClick={closeModal}
        >
          Cancel
        </button>,
        <button
          key="add-invoice"
          className="btn btn-brand btn-bdr"
          onClick={addCustomer}
        >
          {addNewCustomerModel ? "Add customer" : " Add Invoice"}
        </button>
      ]}>
      {
        addNewCustomerModel ? (
          <div>
            <div className="form-group">
              <Row>
                <div className='half-fitem fitem'>
                  <Col span={11.5}>
                    <label>First Name<span style={{ 'color': 'red' }}>*</span></label>
                    <input type="text" placeholder="First Name" name="firstName" value={firstName} onChange={handleFirstName} />
                    {errors['firstName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['firstName']}</label> : ''}
                  </Col>
                  <Col span={1}></Col>
                  <Col span={11.5}>
                    <label>Last Name<span style={{ 'color': 'red' }}>*</span></label>
                    <input type="text" placeholder="Last Name" name="lastName" value={lastName} onChange={handleLastName} />
                    {errors['lastName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['lastName']}</label> : ''}
                    {/* </div> */}
                  </Col>
                </div>
              </Row>
            </div>

            <Select value={customerType} defaultValue="" onChange={(e) => { handleCutomerType(e); }}>
              <Option value="residential">Residential</Option>
              <Option value="commercial">Commercial</Option>
            </Select>
            <div>
              {(customerType === "commercial") ? (<>
                <h5 className="mt-30">Company Name<span style={{ 'color': 'red' }}>*</span></h5>
                <input type="text" placeholder="Company Name" onChange={(e) => handleCompanyName(e)} />
                {errors['companyName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['companyName']}</label> : ''}
              </>) : null}
              <div>
                <h5 className="mt-30">Phone Numbers<span style={{ 'color': 'red' }}>*</span></h5>
                <div className="inline-check-list gcheck">
                  {map(phoneNumbers, (item, index) => <div className="fitem-check" key={`phoneNumber${index}`}>
                    <input type="checkbox" className="fitem-ck-input" id={`checkboxPhone${index}`} name={`checkboxPhone${index}`} checked={item?.primary} onChange={(e) => onPhonePrimaryChanged(index)} />
                    <label htmlFor={`checkboxPhone${index}`} className="fitem-ck-txt black">{`${formatPhoneNumber(item.phone)} ${item.primary ? '(Primary)' : ''}`}</label>
                    <a className="red float-right" onClick={(e) => onChange(index, 'phone')}><u>Remove</u></a>
                  </div>)}
                </div>
                <div className="fitem fitem-rinner mt-10 flex">
                  <input name="phone" type="text" placeholder="Add New Phone" value={phone} maxLength={"10"} max={"10"} onChange={onTextChange} onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputChar = String.fromCharCode(e.charCode);
                                        if (!pattern.test(inputChar)) {
                                            // invalid character, prevent input
                                            e.preventDefault();
                                        }
                                    }} />
                  <button
                    className={`btn fitem-ricon ${phone.length !== 10 ? 'disabled' : 'btn-warning'}`}
                    disabled={phone.length !== 10}
                    onClick={(e) => onAdd('phone')}
                  >
                    <PlusOutlined />
                  </button>
                </div>
                {errors?.[`phoneNumbers`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`phoneNumbers`]}</label> : ''}
              </div>
              <div>
                <h5 className="mt-30">Email Addresses<span style={{ 'color': 'red' }}>*</span></h5>

                <div className="inline-check-list gcheck" >
                  {map(emails, (item, index) => item?.email && !item.email.includes('doody.com') ? <div className="fitem-check" key={`email${index}`}>
                    <input type="checkbox" className="fitem-ck-input" id={`checkboxEmail${index}`} name={`checkboxEmail${index}`} checked={item?.primary} onChange={(e) => onPrimaryEmailChanged(index)} />
                    <label htmlFor={`checkboxEmail${index}`} className="fitem-ck-txt black" style={{ overflowWrap: "anywhere" }} >{`${item?.email} ${item?.primary ? '(Primary)' : ''}`}</label>
                    <a className="red float-right" onClick={(e) => onChange(index, 'email')}><u>Remove</u></a>
                  </div> : ''
                  )}
                </div>
                <div className="fitem fitem-rinner mt-10 flex">
                  <input name="email" type="text" placeholder="Add New Email Address" value={email} onChange={onTextChange} />
                  <button
                    className={`btn fitem-ricon ${!REGULAR_EXPRESSION_EMAIL.test(email) ? 'disabled' : 'btn-warning'}`}
                    disabled={!REGULAR_EXPRESSION_EMAIL.test(email)}
                    onClick={(e) => onAdd('email')}
                  >
                    <PlusOutlined />
                  </button>
                </div>
                {errors?.[`emails`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`emails`]}</label> : ''}
              </div>
              <div className="mt-30">
                <AddressField handleAddressChange={setAddress} onAddressChanged={onAddressChange} onAddressChange={onAddressChange} onChange={(e) => handleAddress(e)} errors={errors} data={address} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <label>Customer <sup>*</sup></label>
            <div className="mb-10">
              <Select
                showSearch
                showArrow={false}
                style={{ marginTop: 5 }}
                placeholder="Customer Name"
                value={selectedCustomer?.uuid || null}
                onSearch={(searchVal) => {
                  if (searchVal && (searchVal.length > 2)){
                    debounceFetcher(searchVal);
                  }
                }}
                disabled={hideAddCustomer}
                onChange={(val, info) => {
                  setSelectedCustomer(info?.label || {});
                  if (val) {
                    setHideAddCustomer(true);
                    setSelectedCustomerError('');
                  }
                }}
                filterOption={false}
                notFoundContent={null}
              >
                {customerOptions?.length ? customerOptions?.map(item => (
                  <Option key={item?.uuid} value={item?.uuid} label={item}>
                    <div>

                      {item?.first_name + ' ' + item?.last_name}
                    </div>
                  </Option>
                )): ''}
              </Select>
              {selectedCustomerError ?
                <label className="new_err_msg"><i className="las la-info-circle" />
                    {selectedCustomerError}
                </label>
                :
                ''
              }
            </div>

            {!selectedCustomer?.uuid ?
              <div>
                <div style={{ color: 'rgb(79, 207, 246)', cursor: 'pointer', marginBottom: 40 }}>
                  <div onClick={() => {
                    setAddNewCustomerModel(true)
                  }}>
                    <PlusCircleOutlined style={{ marginRight: 5 }} />
                    Add New Customer
                  </div>
                </div>
              </div>
              :
              <div style={{ marginBottom: 40 }}>
                <div style={{ display: 'inline' }}>
                  <div style={{ display: 'flex', wordBreak: 'break-word' }}>
                    <div style={{ fontWeight: 'bold', width: "60px", minWidth: "60px" }}>Address:</div>
                    {selectedCustomer?.invoice_address?.address ? 
                        <>
                          {selectedCustomer?.invoice_address?.address}, {selectedCustomer?.invoice_address?.city}, {selectedCustomer?.invoice_address?.state} {selectedCustomer?.invoice_address?.zip_code}
                        </>: 
                        <>
                          {selectedCustomer?.address[selectedCustomer?.address?.length - 1 || 0]?.formatted_address || ''}
                        </>  
                    }
                  </div>
                  <div style={{ display: 'flex', wordBreak: 'break-word' }}>
                    <div style={{ fontWeight: 'bold', width: "60px", minWidth: "60px" }}>Email:</div>
                    {selectedCustomer?.email || ''}
                  </div>
                </div>
              </div>
            }

          </div>
        )}


    </ModalComponent>
  )
}

export default RetailNewCustomer;
