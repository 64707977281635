import React, { useEffect, useState } from "react";
import { Select } from "antd";

const StageSelector = ({
  handleChange = () => {},
  defaultValue,
}) => {

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        placeholder={"Select a Status"}
      >
          <Select.Option key="Lead" value="Lead" label="Lead">
            Lead
          </Select.Option>
          <Select.Option key= "Customer" value= "Customer" label= "Customer">
            Customer
          </Select.Option>

      </Select>
    </>
  );
};

export default StageSelector;
