import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

const ImageUploadWorkOrder = (props) => {
  const { Dragger } = Upload;
  let imagesList = [];

  async function imgRequest({ file, onSuccess }) {
    let dataUrl = await getUrl(file);
    imagesList = props.fileList;
    setTimeout(() => {
      //Simulate success response
      // onSuccess("ok");
    }, 0);

    let img = {};
    switch (props.params.level) {
      case "EditWorkOrder":
        img = {
          image_url: file,
          is_tech_image: props.params.isTechImage,
          type: props.params.image_type,
        };
        break;
      default:
        break;
    }

    await getBase64(img);
  }

  async function getBase64(img) {
    var reader = new FileReader();
    reader.readAsDataURL(img.image_url);
    reader.onload = function () {
      const image2Process = document.createElement("img");
      image2Process.src = reader.result;
      image2Process.onload = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = image2Process.naturalWidth;
        canvas.height = image2Process.naturalHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image2Process, 0, 0);

        ctx.fillStyle = "white";
        ctx.textBaseline = "middle";
        ctx.textAlign = "right";
        if(canvas.width<300) ctx.font = "11px Lato";
        else if(canvas.width<500) ctx.font = "22px Lato";
        else if(canvas.width<900) ctx.font = "36px Lato";
        else ctx.font = "40px Lato";
        ctx.fillText(
          `${moment(img?.image_url?.lastModified).format(
            "MMM DD YYYY HH:mm A"
          )}`,
          canvas.width - 10,
          canvas.height - 26
        );

        img.image = canvas.toDataURL();
        imagesList.push(img);
        props.getFiles(imagesList);
      };
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const compareFiles = (fileList, file) => {
    fileList.forEach((each, index) => {
      if (
        Object.keys(each).includes("image_url") &&
        each.image_url.uid === file.uid
      ) {
        return index;
      }
    });
    return -1;
  };

  const fileProps = {
    onPreview: (file) => {
      console.log(file);
      var fileURL = file.url;
      if (file.thumbUrl) {
        fileURL = URL.createObjectURL(file.originFileObj);
      }
      window.open(fileURL);
    },
    beforeUpload: async (file, success) => {
      file.url = file;
      imgRequest({ file, success });
      return false;
    },
    onRemove: (file) => {
      const index = props.fileList.indexOf(file);
      const newFileList = props.fileList.slice();
      newFileList.splice(index, 1);
      if (index === -1) {
        let index2 = compareFiles(props.fileList, file);
        const newFileList = props.fileList.slice();
        newFileList.splice(index2, 1);
      }
      props.getFiles(newFileList);
    },
  };

  async function getUrl(file) {
    return new Promise(function (resolve) {
      if (!file.type) {
        resolve("");
        return;
      }
      let src = window.URL.createObjectURL(file);
      resolve(src);
      return src;
    });
  }

  return (
    <Dragger
      {...fileProps}
      customRequest={imgRequest}
      defaultFileList={[...props.fileList]}
      listType="picture"
      accept={props.accept}
      multiple={true}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag you file(s) to this area to upload
      </p>
    </Dragger>
  );
};

export default ImageUploadWorkOrder;
