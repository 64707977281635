import React,{useState} from "react";
import { useParams } from "react-router-dom";
import { Popconfirm} from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import PDFIcon from "../../../Assets/images/pdf-icon.png";
import CSVIcon from "../../../Assets/images/csv-icon.png";
import FranchiseSelector from "../Filters/Franchise.Selector";
import { useReport } from "../../Hooks/Report.Hook";
import ReportsData from "../../Common/ReportList.Data";
import { getPDFReport, getCSVReport } from "../../Common/getObjectReport";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';
import {
  withJSONColumnOrder,
} from "../../Common/getOrderingObject";
import {
  exportLeads,exportCustomers
} from "../../../Action/Customer";
const TerritoryTopMenu = () => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const { id } = useParams();
  const {
    franchise,
    serviceDatePeriod,
    superSearch,
    buildReport,
    resetReport,
    reportType,
    setReportType,
    setFranchise,
    setServiceDatePeriod,
    leadStage,
    hearAboutUs,
    channelAttribution,
    outreach,
    creationDatePeriod,
    contactedDatePeriod,
    stage,
    lastServiceDatePeriod,
    joinedDatePeriod,
    serviceType,
    leadReportclientType,
    setLeadStage,
    setChannelAttribution,
    setHearAboutUs,
    setOutreach,
    setCreationDatePeriod,
    setContactedDatePeriod,
    setStage,
    setLastServiceDatePeriod,
    setJoinedDatePeriod,
    setServiceType,
    sorter,
    setSorter,
    total
  } = useReport();
  const [loadingExport, setLoadingExport] = useState(false);
  const onExportTable = async (is500=0) => {
    
    let payLoad = {
      ...getFilters(),
      is_500: is500,
      leads_report : true,
      customersReport : true,
    };
    Reflect.deleteProperty(payLoad, 'limit');
    Reflect.deleteProperty(payLoad, 'offset');
    setLoadingExport(true);
    const response = stage?.value === "Lead"? await exportLeads(payLoad) : await exportCustomers(payLoad)
    setLoadingExport(false);      
  }
  const handleRadioChange = (e) => {
    setReportType(e.target.value);
  };

  const report_type =
  stage?.value === "Lead"?
      "lead_report"
    : (stage?.value === "Customer")?
      "customer_report"
      : "" ;

  const getFilters = () => {
    let ordering = '';    
    if (sorter!== null && sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }
    return stage?.value === "Lead"?
            { 
              q: superSearch !== "" ? superSearch : undefined,
              offset: 0,
              limit: total,
              ordering: ordering,
              contacted_date__lte:!_.isEmpty(contactedDatePeriod)? contactedDatePeriod.contacted_end_date: undefined,
              contacted_date__gte: !_.isEmpty(contactedDatePeriod)? contactedDatePeriod.contacted_start_date: undefined,
              lead_profile__field_office__uuid:franchise.value === "all" ? undefined : franchise.value,
              lead_profile__outreach:outreach.value === "all" ? undefined : outreach.value,
              lead_profile__stage:leadStage.value === "all" ? undefined : leadStage.value,
              created_at__lte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.end_date: undefined,
              created_at__gte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.start_date: undefined,
              channel_attribution: channelAttribution.value === "all" ? undefined : channelAttribution.value,
              hear_about_us: hearAboutUs.value === "all" ? undefined : hearAboutUs.value,
              account_type: leadReportclientType,
            }
            :(stage?.value === "Customer")
                ?{
                    is_active: null,
                    offset: 0,
                    limit: total,
                    q: superSearch !== "" ? superSearch : undefined,
                    ordering: ordering,
                    lead_profile__field_office__uuid:franchise.value === "all" ? undefined : franchise.value,
                    account_type: leadReportclientType,
                    created_at__lte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.end_date: undefined,
                    created_at__gte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.start_date: undefined,
                    last_serviced__lte: !_.isEmpty(lastServiceDatePeriod)? lastServiceDatePeriod.end_date: undefined,
                    last_serviced__gte: !_.isEmpty(lastServiceDatePeriod)? lastServiceDatePeriod.start_date: undefined,
                    service_type: serviceType === "all" ? undefined : serviceType.toLowerCase().replace(/ /g, "_"),
                    outreach: outreach.value === "all" ? undefined : outreach.value,
                    channel_attribution: channelAttribution.value === "all" ? undefined : channelAttribution.value,
                    hear_about_us: hearAboutUs.value === "all" ? undefined : hearAboutUs.value,
                  }
              : undefined
  };
/*
  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
  };
  const handleCustomDateChange = (e) => {
    setServiceDatePeriod({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
*/
  return (
    <>
      <div className="filter_wrapper acc-wrap p-0 mb-20">
        {/*<h2 className="m-0 text-nowrap pr-10">
          {ReportsData.find((item) => item.id === id)?.title}
      </h2>*/}
        <div class="report-long-topmenu_title">
                <h2>{ReportsData.find((item) => item.id === id)?.title}</h2>
        </div>
        <div className="form-group flex ai-center w-100 fltr-txt" style={{justifyContent: "left"}}>
          {/*<Tooltip title="Download CSV" color={"#005d89"}>
            <button
              className="btn btn-warning-outline"
              onClick={() =>
                getCSVReport({ report_type, filters: getFilters() })
              }
            >
              <img
                src={CSVIcon}
                alt={"Download CSV"}
                style={{ maxWidth: "25px" }}
              />
            </button>
          </Tooltip>
          <Tooltip title="Download PDF" color={"#005d89"}>
            <button
              className="btn btn-warning"
              onClick={() =>
                getPDFReport({ report_type, filters: getFilters() })
              }
            >
              <img
                src={PDFIcon}
                alt={"Download PDF"}
                style={{ maxWidth: "21px" }}
              />
            </button>
            </Tooltip>*/}
            {
                      (total|| 0) > 500 ? (
                      <Popconfirm
                        onConfirm={() => !loadingExport && onExportTable(1)}
                        okText="Export"
                        cancelText="Cancel"
                        icon={<></>}
                        title={
                          <>
                            <div>
                              Report data is more than 500 records. Data would be
                              <br />
                              exported and emailed to
                              {' '}
                              <b>{userInfo?.user?.email}</b>.
                            </div>
                          </>
                        }
                      >
                        <button
                        className="btn btn-warning"
                        >
                          {loadingExport ? 'Exporting...' : 'Export'}
                        </button>
                      </Popconfirm>
                    ) : (
                      <button
                        disabled={loadingExport}
                        onClick={() => onExportTable()}
                        className="btn btn-warning"
                        >
                          {loadingExport ? 'Exporting...' : 'Export'}
                        </button>
                    )
                  }   
        </div>
      </div>
      {/*
      <div className="" style={{ display: "flex", marginBottom: "20px" }}>
        <div className="fitem" style={{ width: "200px", marginRight: "10px" }}>
      
          <label>
            <b>Franchise</b>
          </label>
          <FranchiseSelector
            handleChange={handleFranchiseChange}
            defaultValue={franchise}
          />
        </div>
        <div className="fitem">
          <label>
            <b>Joined Dates (Range)</b>
          </label>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            onChange={handleCustomDateChange}
          />
        </div>
        <div className="fitem">
          <label>
            <b>Lead Report</b>
          </label>
          <div style={{ margin: "10px 10px 0 10px" }}>
            <Radio.Group name="radiogroup" defaultValue={reportType}>
              <Radio value={"detail"} onChange={handleRadioChange}>
                Residential
              </Radio>
              <Radio value={"summary"} onChange={handleRadioChange}>
                Commercial
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="fitem">
          <div style={{ margin: "25px 10px 0 10px" }}>
            <button
              type="button"
              className="btn btn-brand btn-bdr"
              onClick={resetReport}
            >
              Reset
            </button>
          </div>
        </div>
        <div className="fitem">
          <div style={{ margin: "25px 10px 0 10px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={buildReport}
            >
              Build Report
            </button>
          </div>
        </div>
      </div>
      */}
    </>
  );
};

export default TerritoryTopMenu;
