import React, { useState, useEffect } from "react";
import pulsem from '../../Assets/images/pulsem.png';
import { Select, Modal, message } from "antd";
import { isEmpty, map } from 'lodash';
import * as yup from 'yup';
import Loader from '../../Components/Loader';
import { pulseMSetUp, getDataPulseMSetUp } from '../../Action/Franchise';
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

const SetupOptionsPulseM = ({ franchise_data }) => {
    const [reconnect, setReconnect] = useState(false);
    const [state, setState] = useState(
        {
            errors: {},
            pulsem_token: '',
            pulsem_location_id: '',
            is_show_pulsem_fields: false,
            pulsem_keys: {},
            loader: false,
        }
    );
    const onTextChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const onValid = async () => {
        let { pulsem_token, pulsem_location_id } = state;
        let temp_url = await pulseMSetUp(franchise_data?.uuid, {
            "pulsem_token": pulsem_token,
            "pulsem_location_id": pulsem_location_id,
        });
        if (!isEmpty(temp_url.pulsem_token)) {
            setReconnect(true);
            message.success('PulseM Connected Successfully');
            onFormat();
        } else {
            if(temp_url?.status === 400) {
                message.error(temp_url?.data?.data?.message);
            }
        }
    }
    const getData = async () => {
        let data = await getDataPulseMSetUp(franchise_data?.uuid);
        if (!isEmpty(data.pulsem_token)) {
            setReconnect(true);;
        }
    }
    const onFormat = () => {
        setState({
            errors: {},
            pulsem_token: '',
            pulsem_location_id: '',
            is_show_pulsem_fields: false,
            pulsem_keys: {},
            loader: false,
        });
    }
    const onSetupClick = (e) => {
        e.preventDefault();
        setTimeout(() => {
            let { pulsem_token, pulsem_location_id } = state;
            const schema = yup.object().shape({
                pulsem_token: yup.string().required("Authorization Access Token is required"),
                pulsem_location_id: yup.string().required("Location ID is required"),
            })
            const data = { pulsem_token, pulsem_location_id }
            // check validity
            let IsValid;
            let err_data = {};
            schema.isValid(data)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    onValid();
                }
                else {
                    schema.validate(data, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            })
                            setState({
                                ...state,
                                errors: err_data
                            })
                            console.log("err_data:", err_data)
                        });
                }
            }, 100);
        }, 300);
    }
    useEffect(() => {
        getData();
    }, []);

    let { pulsem_token, pulsem_location_id, errors, loader } = state;

    return (
        <div className="">
            <div className="card">
                <div className="card-body">
                    <img src={pulsem} alt="" className="stripe_logo" />
                    <p>To enable the API and generate the API key</p>
                    <ul className="quickbook_list">
                        <li>Connect <u>support@pulsem.me</u> to request your API access.</li>
                        <li>You will be provided with Authorization Access Token and Location ID information.</li>
                        <li>Once received, please add the keys to their input fields and select Save PulseM API.</li>
                        {/* <li>Copy and paste <b>https://crm.dev.poolops.com/franchise/franchise-detail</b> into the Link field under Redirect URIs and click Save at the bottom of the screen</li> */}
                    </ul>
                    <div className="form-group">
                        {!reconnect ? <>
                            <div className="fitem">
                                <label style={{ fontWeight: 'bold' }}>
                                    Authorization Access Token <sup>*</sup>
                                </label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="Access Token"
                                    name="pulsem_token"
                                    onChange={(e) => onTextChange(e)}
                                    value={pulsem_token || ''}
                                />
                                {errors['pulsem_token'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['pulsem_token']}</label> : ''}
                            </div>
                            <div className="fitem">
                                <label style={{ fontWeight: 'bold' }}>
                                    Location ID <sup>*</sup>
                                </label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="pulsem_location_id"
                                    onChange={(e) => onTextChange(e)}
                                    value={pulsem_location_id || ''}
                                />
                                {errors['pulsem_location_id'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['pulsem_location_id']}</label> : ''}
                            </div>
                            <br />
                            <div>
                                <button className='btn btn-primary' onClick={(e) => onSetupClick(e)}>Connect</button>
                            </div>
                        </>
                            :
                            <div>
                                <button className='btn btn-warning' >PulseM Is Already Connected</button>
                                <button className='btn btn-primary' onClick={() => setReconnect(false)}>Reconnect</button>

                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                loader ?
                    <Loader />
                    : ''}
        </div >
    );
}

export default SetupOptionsPulseM;