import React, { useEffect } from 'react'

const SubscriptionDetails = (props) => {
    useEffect(()=> {
    }, []);
  return (
    <div>
        <h4>Subscription details</h4>
        <div className="flex">
            <div className="flex-1">
                <div className="flex summary-detail">                
                    <span className='text-capitalize'>{props?.serviceInfo?.serviceType?.key}</span>
                    <span>${props.priceDetail?.price}</span>
                </div>
                {props?.serviceInfo?.serviceType?.key?.toLowerCase() !=='maintenance'?
                <>
                    <p className='text-capitalize' style={{'marginBottom': '0px'}}>{props?.serviceInfo?.service?.key}</p>
                    <p className='text-capitalize' style={{'marginBottom': '0px'}}>{props?.serviceInfo?.worksubtype?.key}</p> </>:
                    null}
                <p className='text-capitalize'>{props?.priceDetail?.frequency?.recurring?.repeat}</p> 
                <p className='text-capitalize'>{props?.serviceInfo?.poolType?.key}{props?.serviceInfo?.otherPoolType?.value?" / "+props?.serviceInfo?.otherPoolType?.value:null}</p>
            </div>
        </div>
        <hr/>
        {props?.serviceInfo?.serviceType?.key?.toLowerCase() ==='maintenance'?
        <p style={{'textAlign':'justify'}}>Pool must be clear and balanced before we begin maintenance. Price may change once we visit pool.</p>
        :
        props?.serviceInfo?.service?.key || props?.priceDetail?.key?
        <p style={{'textAlign':'justify'}}>{`Price is for ${props?.serviceInfo?.service?.key || props?.priceDetail?.key} only. Price for repair to be given once ${props?.serviceInfo?.service?.key || props?.priceDetail?.key} has been completed`}</p>
        :null}
        {/* <div className="flex">
            <div className="flex-1" >
                <p>Other</p>
            </div>
            <div className=""><b>+${'0'}</b></div>
        </div> */}
    </div>
  )
}

export default SubscriptionDetails;
