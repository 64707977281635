import React, { useEffect, useState } from "react";
import { Row, Col, Spin, message } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { _, isEmpty, cloneDeep } from "lodash";
import { setFranchiseData } from "../../../Store/technicianTaskDataSlice";
import {
  getAllServicesTechGlance,
  getAllTasks,
  rescheduleService,
  reassignTaskInfo,
} from "../../../Action/Calendar";
import FranchiseSelector from "../Filters/Franchise.Selector";
import StatusSelector from "../Filters/Status.Selector";
import { colorStatus } from "../Common/selectColor";
import { useCalendar } from "../../Hooks/TechGlance.Hook";
import NavDateHeader from "../Common/navDateHeader";
import TechColumn from "../Common/techColumn";
import { DragDropContext } from "react-beautiful-dnd";
import AddTask from "../../../Calendar/Components/Modals/AddTask.Modal";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useEventBus } from "../../../Components/EventBus/useEventBus";

const TechGlanceContainersV2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    franchise,
    setFranchise,
    technician,
    setTechnician,
    status,
    setStatus,
  } = useCalendar();

  const [myEvents, setEvents] = useState([]);
  const [eventsByTech, setEventsByTech] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [tech, setTech] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const role = userInfo && userInfo?.user?.user_roles[0]?.name;
  const [loading, setLoading] = useState(true);
  const [start_date, setStart_date] = useState(moment().format("YYYY-MM-DD"));
  const [noOrdersMsg, setNoOrdersMsg] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    moment().format("MMMM DD, yyyy")
  );

  //EVENT LIST MANAGEMENT
  useEffect(() => {
    if (franchise?.value) {
      getWorkOrder();
      dispatch(setFranchiseData(franchise.value));
    }
  }, [franchise]);

  useEffect(() => {
    if (myEvents.length) {
      let temp_tech = myEvents.map((each) => {
        return each.technician;
      });
      var filteredNull = temp_tech.sort().filter(function (el) {
        return el != null;
      });
      let index = 1;
      temp_tech = [...new Set(filteredNull)];
      let temp = [{ id: index, name: "Not assigned" }];
      let temp_1 = temp_tech
        .map((each) => {
          return { id: ++index, name: each };
        })
        .filter((each) => each.name !== "Not assigned");
      temp = [...temp, ...temp_1];
      let filtered = [];
      temp.forEach((technician) => {
        let techList = myEvents.filter((i) => i.technician === technician.name);
        filtered.push(techList);
      });
      setEventsByTech(filtered);
      // filter();
      setTech(temp);
    }
  }, [myEvents]);

  const getTechs = async function (offset = 0) {    
    let franchise_data = "";
    if (franchise?.label === "all") {
      franchise?.all?.forEach((row, index) => {
        let data = row.uuid;
        if (index < franchise?.all.length - 1) data += ",";
        franchise_data += data;
      });
    } else {
      franchise_data = franchise.value;
    }
    const reply = getAllServicesTechGlance({
      start_date: start_date,
      field_office_uuid: franchise_data,
      limit: 1000,
      offset: offset,
    });
    const taskData = getAllTasks({
      start_date: start_date,
      end_date: start_date,
      field_office_uuid: franchise_data,
    });
    const serviceTaskData = await Promise.all([reply, taskData]);
    let serviceTaskDataCombine
    if (serviceTaskData?.[0]?.next != null) {
      let arrRep = []
      for (let rep = 1; rep < serviceTaskData?.[0]?.count / 1000; rep++) {
        let tempRep = await getAllServicesTechGlance({
          start_date: start_date,
          field_office_uuid: franchise_data,
          limit: 1000,
          offset: rep * 1000,
        })
        arrRep[rep - 1] = tempRep?.results
      }
      serviceTaskDataCombine = [
        ...(serviceTaskData?.[0]?.results || []),
        ...(serviceTaskData?.[1]?.results || []),
      ];
      for (let inRep = 0; inRep < arrRep.length; inRep++){
        serviceTaskDataCombine.push(...(arrRep?.[inRep]))
      }
    }
    else{
      serviceTaskDataCombine = [
        ...(serviceTaskData?.[0]?.results || []),
        ...(serviceTaskData?.[1]?.results || []),
      ];
    }
    let removenullTechnician = serviceTaskDataCombine.map(function (elem) {
      return Object.assign({}, elem, {
        technician: elem.technician == null ? "Not assigned" : elem.technician,
      });
    });
    return removenullTechnician;
  };

  const getWorkOrder = async () => {
    setEvents([]);
    setLoading(true);
    setNoOrdersMsg(false);
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    let reply = await getTechs();
    if (role === "field_tech") {
      if (!userInfo?.user?.technician_permissions?.see_calenders) {
        let temp = reply.filter(
          (each) =>
            `${userInfo?.user?.first_name} ${userInfo?.user?.last_name}` ===
              each?.technician ||
            each?.technician === "Not assigned" ||
            each.is_task
        );
        reply = temp;
      }
    }
    setEvents(
      reply
        ? reply.map((item) => ({
            start: item?.scheduled_appointment || item?.scheduled_date,
            title: item?.work_order_name || item?.task_name,
            status: item?.status,
            color: colorStatus[item?.status] ?? "#7b7a7a",
            serviceType: item?.servicetype || "Task",
            technician: item?.technician?.name || item?.technician,
            technicianData: item?.technician,
            technician_uuid: item?.technician_uuid || item?.technician?.uuid,
            work_order_uuid: item?.work_order_uuid || item?.uuid,
            job_forms: item?.job_forms || null,
            special_notes: item?.special_notes || item?.customer?.special_notes,
            address: item?.service_address || item?.address,
            edit_work_order_url: item?.edit_work_order_url,
            editable: item?.status !== "complete" ? true : false,
            client_type: item?.client_type || item?.customer?.customer_type,
            client_uuid: item?.client_uuid || item?.customer?.uuid,
            client_name: item?.client_name || null,
            is_invoiced: item?.is_invoiced || null,
            permissionset: item?.permissionset || null,
            type: item?.is_task ? "Task" : "Service",
            resource: item?.resource || null,
            customer: item?.customer,
            task_category: item?.task_category || null,
            task_description: item?.description || null,
            duration: item?.duration || item?.service_duration,
            frequency_settings: item?.frequency_settings || null,
            is_reassign_future_tasks: item?.is_reassign_future_tasks,
            workType: item?.worktype || '',
            worksubtype:item?.worksubtype || null,
            service_duration: item?.service_duration || franchise_data?.data?.default_job_duration,
            is_recurring:item?.is_recurring,
            phone_number: item?.phone_number || item?.customer?.phone_number
          }))
        : []
    );
  };

  useEventBus("TASK_CREATED", (event) => {
    getWorkOrder();
  });

  //FILTER CHANGE HANDLERS
  useEffect(() => {
    filter();
  }, [status, eventsByTech]);

  const dateChange = (newDate) => {
    setStart_date(moment(newDate).format("YYYY-MM-DD"));
    setCurrentDate(newDate);
  };

  useEffect(() => {
    if (franchise.value) {
      getWorkOrder();
    }
  }, [currentDate]);

  const filter = () => {
    let filtered = [];
    eventsByTech.forEach((techy, index) => {
      let techList = {
        id: ++index,
        name: techy[0]?.technician,
        technician_uuid: techy[0]?.technician_uuid || "NOT_ASSIGNED",
        tList: [],
      };
      let tempList = techy.filter((i) => {
        //status filter
        let matchStats = false;
        status.forEach((stat) => {
          if (stat === i.status) matchStats = true;
        });
        //date filter
        // let mm = moment(i.start).format("YYYY-MM-DD");
        // let cd = moment(currentDate).format("YYYY-MM-DD");
        //return (mm === cd && matchStats);
        return matchStats;
      });
      techList.tList = tempList;
      filtered.push(techList);
    });
    setFilteredEvents(filtered);
    if (filtered.length > 0 && franchise.value) setLoading(false);
  };

  const onDragEndHandler = async (res) => {
    const { draggableId, source, destination } = res;
    const draggableIdArray = draggableId.split("_");
    const draggableIndex = draggableIdArray[1];
    const filteredEventsTemp = cloneDeep(filteredEvents);
    const draggableData = filteredEventsTemp[source.droppableId - 1].tList[draggableIndex];
    const droppableData = filteredEventsTemp[destination.droppableId - 1];
    const previousDateValidate = moment(currentDate).diff(moment(), 'days');
    const isFieldTech = userInfo && userInfo?.user?.user_roles?.[0]?.display_name == "Field Technician" ? true : false;
    
    if (!userInfo?.user?.technician_permissions?.assign_wo && isFieldTech) { // Restrict if user have no permission for reassign.
      message.error('Access Denied.', 7);
      return;
    } else if (previousDateValidate < 0) { // Restrict previous date drag drop operation.
      message.error('Job/Tasks can be reassigned only for current date.', 7);
      return;
    } else if (draggableData.technician == droppableData.name) { // If we are dropping in same drop area.
      message.error('Please select a different Technician to reassign.', 7);
      return;
    } else if (draggableData.status == 'complete') { // To restrict completed tasks/Job from reschedule.
      message.error('Completed Tasks/Jobs cannot be reassigned.', 7);
      return;
    } else if (draggableData.status == 'cancelled') { // To restrict completed tasks/Job from reschedule.
      message.error('Cancelled Tasks/Jobs cannot be reassigned.', 7);
      return;
    } 
    
    if (draggableData.type === 'Service') {
      setLoading(true);
      message.destroy()
      const response = await rescheduleService({
        service_uuid: draggableData?.work_order_uuid,
        technician_uuid: droppableData?.technician_uuid != "NOT_ASSIGNED" ? droppableData?.technician_uuid : 'not assigned',
      });
      if (!isEmpty(response) && response?.error) {
        setLoading(false);
        message.error(response.error, 7);
      } else {
        message.success(`Job has been assigned from ${draggableData.technician} to ${droppableData.name} Successfully.`, 7);
        await getWorkOrder();
      }
    } else if (draggableData.type === 'Task') {
      setLoading(true);
      message.destroy()
      const response = await reassignTaskInfo({
        task_uuid: draggableData?.work_order_uuid,
        reassign_to: droppableData?.technician_uuid != "NOT_ASSIGNED" ? droppableData?.technician_uuid : 'Not assigned',
      });
      if (!isEmpty(response) && response?.message?.toLowerCase() === "error while updating tasks") {
        setLoading(false);
        message.error(response.message, 7);
      } else {
        message.success(`Task has been assigned from ${draggableData.technician} to ${droppableData.name} Successfully.`, 7);
        await getWorkOrder();
      }
    }
  }

  return (
    <div className="block-row">
      {/* Filters */}
      <Row justify="space-between" style={{ width: "100%" }} align="middle">
        <Col span={6} style={{ padding: "0 5px 0 0" }}>
          <FranchiseSelector
            onControlChange={({ value, label, all }) => {
              setEvents([]);
              setFranchise({ value, label, all });
            }}
          />
        </Col>
        <Col span={12} style={{ padding: "0 0 0 5px" }}>
          <StatusSelector
            onControlChange={(status) => {
              setStatus(status);
            }}
          />
        </Col>
      </Row>

      <Spin spinning={loading} delay={500}>
        {/* Date header */}
        <Row
          justify="space-between"
          style={{ width: "100%", paddingTop: "20px" }}
          align="middle"
        >
          {/* {noOrdersMsg ?
                        <h1>No work orders for this location</h1>
                        :
                        <NavDateHeader current={currentDate} handleChange={dateChange} />
                    } */}
          <NavDateHeader current={currentDate} handleChange={dateChange} />
        </Row>
        {/* Dynamic columns */}
        {!loading && (
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              border: "1px solid lightgrey",
              position: "relative",
              height: "79vh",
              overflow: "auto",
            }}
          >
            {noOrdersMsg ? (
              <h1 style={{ textAlign: "center" }}>
                No work orders for this location
              </h1>
            ) : (
              <DragDropContext
                onDragEnd={onDragEndHandler}
              >
                <Row style={{ flexFlow: "row" }}>
                  {filteredEvents.map((tech, index) => {
                    return (
                      <Col style={{ border: "1px solid lightgrey", height: 'inherit' }}>
                        <TechColumn
                          technicianInfo={tech}
                          index={index}
                          key={tech.id}
                          getWorkOrder={getWorkOrder}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </DragDropContext>
            )}
          </div>
        )}
      </Spin>
      {franchise ? <AddTask /> : null}
    </div>
  );
};

export default TechGlanceContainersV2;
