import React, { useEffect, useState } from "react";
import { Select, ConfigProvider, Empty, Spin } from "antd";
import { orderBy, isEmpty } from "lodash";
import { getFranchiseListAll } from "../../Action/Franchise";
import { getFranchiseSelected, setFranchiseSelected } from "../../Utils/selectedFranchise";
const { Option } = Select;

const FranchiseSelector = ({ onControlChange = () => {} }) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const is_fo = userInfo?.user?.user_roles.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );
  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });
  const role = is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
    ? "lead_tech"
    : userInfo?.user?.user_roles[0]?.name;

  const [loading, setLoading] = useState(true);
  const [franchiseList, setFranchiseList] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [allValue, setAllValue] = useState(role === "admin" ? false : true);

  useEffect(() => {
    const getFranchiseList = async () => {
      setLoading(true);
      setFranchiseList([]);
      const data = await getFranchiseListAll();
      let franSelected =  getFranchiseSelected()?.uuid ? {value: getFranchiseSelected().uuid} : null;

      if (isEmpty(data)) {
        setFranchiseList([]);
        return;
      }
      const ordererFranchiseList = orderBy(data, [item => item.name.toLowerCase()], "asc")
      if(franSelected?.value === "all"){
        franSelected.label = "all"
        franSelected.all = ordererFranchiseList
      }
      
      if (allValue && data.count>1) {
        setSelectedFranchise(franSelected || "all");

        onControlChange(franSelected || {
          value: ordererFranchiseList[0].uuid,
          label: "all",
          address: {},
          all: ordererFranchiseList,
        });
      } else {
        switch (true) {
          case role === "admin":
            const franchise_user = userInfo.user.associated_field_offices[0];
            setSelectedFranchise(franSelected || {
              value: franchise_user?.uuid,
              label: franchise_user?.name,
              address: {
                address: franchise_user?.address,
                city: franchise_user?.city,
                state: franchise_user?.state,
                zip_code: franchise_user?.zip_code,
              },
            });
            onControlChange(franSelected || {
              value: franchise_user?.uuid,
              label: franchise_user?.name,
              address: {
                address: franchise_user?.address,
                city: franchise_user?.city,
                state: franchise_user?.state,
                zip_code: franchise_user?.zip_code,
              },
            });
            break;
          case role === "franchise_owner":
            setSelectedFranchise(franSelected || {
              value: ordererFranchiseList[0]?.uuid,
              label: ordererFranchiseList[0]?.name,
            });
            onControlChange(franSelected || {
              value: ordererFranchiseList[0]?.uuid,
              label: ordererFranchiseList[0]?.name,
              address: ordererFranchiseList[0]?.field_office_address,
            });
            break;
          case role === "field_tech" || role === "lead_tech":
            setSelectedFranchise(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
            });
            onControlChange(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
              address: ordererFranchiseList[0]?.field_office_address,
            });
            break;
          default:
            setSelectedFranchise(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
            });
            onControlChange(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
              address: ordererFranchiseList[0]?.field_office_address,
            });
            break;
        }
      }
      setFranchiseList(ordererFranchiseList);
      setLoading(false);
    };
    getFranchiseList();
  }, []);

  const updateFranchise = (selectedFranchise, options) => {
    setSelectedFranchise(selectedFranchise);
    setFranchiseSelected(selectedFranchise);
    if(selectedFranchise==="all"){
      options.value=franchiseList[0].uuid;
      options.all = franchiseList;
    }

    options.address = franchiseList.find(
      (franchise) => franchise.uuid === options.value
    )?.field_office_address ?? {};
    onControlChange(options);
  };

  const configureFranchiseSelector = () => {
    switch (true) {
      case role === "admin" || role === "franchise_owner" || role === "operations_mgr":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading franchise..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              value={selectedFranchise || undefined}
              onChange={(value, options) => updateFranchise(value, options)}
              placeholder={allValue ? "Franchise: All" : "Select a franchise"}
              className="swhite calendar-filter-franchise"
              disabled={franchiseList.length === 1}
              key={"franchise_select"}
            >
              {(allValue && franchiseList.length>1) ? (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {"All"}
                </Option>
              ) : (
                <></>
              )}
              {franchiseList?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                  label={option?.name}
                >
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        );
      case role === "field_tech" || role === "lead_tech":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading franchise..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              value={selectedFranchise || undefined}
              onChange={(value, options) => updateFranchise(value, options)}
              placeholder={allValue ? "Franchise: All" : "Select a franchise"}
              className="swhite calendar-filter-franchise"
              disabled={franchiseList.length === 1}
            >
              {(allValue && franchiseList.length>1) ? (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {"All"}
                </Option>
              ) : (
                <></>
              )}
              {franchiseList?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                  label={option?.name}
                >
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        );
      default:
        return <></>;
    }
  };

  return configureFranchiseSelector();
};

export default FranchiseSelector;
