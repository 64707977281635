import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { authLogout } from "../../Action/Auth";

const Forbidden = () => {
  const navigate = useNavigate();
  return (
    <div id="wrapper">
      <div className="content-area dashboard-ca">
        <div className="container">
          <Result
            status="403"
            title="Forbidden"
            subTitle="You do not have the access level"
            extra={
              <>
                <Button type="primary" onClick={() => navigate("/")}>
                  Back to Home
                </Button>
                <Button
                  className="btn btn-warning"
                  onClick={() => {
                    authLogout({
                      key: JSON.parse(localStorage.getItem("authToken")).key,
                    });
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate(`/`);
                  }}
                >
                  Logout
                </Button>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Forbidden;
