import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Col, Row } from "antd";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import { getWorkOrder } from "../../../Action/Dashboard";
import { getServices } from "../../../Action/Reports";
import ServiceTypeSelector from "../Filters/ServiceType.Filter";
import WorkOrderChart from "../Charts/WorkOrder.Chart";

const WorkOrderContainer = () => {
  const [workOrderList, setWorkOrderList] = useState([]);
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceOrderFilter, setServiceOrderFilter] = useState([]);
  const { franchise, period } = useDashboard();

  const handleServiceTypeChange = (filterList) => {
    setServiceOrderFilter(filterList);
  };

  const getWorkOrderList = useCallback(async () => {
    const data = {
      created_at__lte: period.created_at__lte,
      created_at__gte: period.created_at__gte,
      franchise_filter: franchise,
    };

    const response = await getWorkOrder(data);
    if (response.status !== 200) {
      return;
    }

    setWorkOrderList(response.data ? response.data : []);
  }, [franchise, period, setWorkOrderList]);

  const getServiceTypeList = useCallback(async () => {
    const data = await getServices();
    const serviceTypeResult = data
      ? data
          .sort((a, b) => (a.sort_order < b.sort_order ? -1 : 1))
          .map((item) => ({
            ...item,
            title: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }))
      : [];
    setServiceOrderFilter(serviceTypeResult.map((service) => service.name));
    setServiceTypeList(serviceTypeResult);
  }, [setServiceTypeList]);

  const value = useMemo(
    () =>
      [
        ...new Set(
          [{ name: "All" }, ...serviceTypeList].map((type) => type.name)
        ),
      ].map((name) => ({ name: name })),
    [serviceTypeList]
  );

  useEffect(() => {
    getWorkOrderList();
    getServiceTypeList();
  }, [getWorkOrderList, getServiceTypeList]);

  return (
    <div
      className="cardMetric"
      style={{ padding: "0 0 10px 0", maxHeight: "400px" }}
    >
      <Row>
        <Col xs={24} xxl={24} md={24} className="serviceChart cardFilters">
          <span className="cardTitle">Work Order Status</span>
          <ServiceTypeSelector
            handleChange={handleServiceTypeChange}
            serviceTypeList={value}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          xxl={24}
          md={24}
          style={{ padding: "10px", height: "300px" }}
        >
          <WorkOrderChart
            data={workOrderList.reduce((result, workOrder) => {
              const filterWorkOrder = {
                ...workOrder,
                services: workOrder.services.filter((service) =>
                  serviceOrderFilter.includes(service.service_type)
                ),
              };

              return [...result, filterWorkOrder];
            }, [])}
          />
        </Col>
      </Row>
    </div>
  );
};

export default WorkOrderContainer;
