import React from "react";

// Mapping Proposal Status
const PROPOSAL_STATUS = {
  in_progress: 'In Progress',
  active: 'Active',
  enquiry_received: 'Enquiry Received',
  sent : 'Sent',
  accepted: 'Accepted',
  declined:'Declined',
  needs_review: 'Needs Review',
  expired: 'Expired',
  closed: 'Closed',
  deactivated: 'Deactivated'
}

// const LAST_CLEANED = { less_than_2_weeks: 'Less than 2 weeks ago', '1-2_months': '2 to 6 weeks', '2_months_or_more': 'More than 6 weeks ago' }

class DataTable {
  
  // proposal columns
  proposalColumns = (renderAction) => {
    const proposalCols = [          
      {
        title: 'Franchise',
        dataIndex: 'franchise_name',
        key: 'franchise_name',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{`${record?.franchise_name || 'NA'}`}</span>
      },
      {
        title: 'Proposal #',
        dataIndex: 'proposal_number',
        key: 'proposal_number',
        sorter: true,
        className:'field-wrap',
        render: (text, record) => <span>{record?.proposal_number || "Not Created"}</span>
      },
      {
        title: 'Customer Name',
        dataIndex: 'client_name',
        key: 'client_name',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.client_name || 'NA'}</span>
      },
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        key: 'company_name',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.company_name}</span>
      },
      {
        title: 'Phone Number',
        dataIndex: 'client_phone_number',
        key: 'client_phone_number',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.client_phone_number || ''}</span>
      },
      {
        title: 'Email Address',
        dataIndex: 'client_email_address',
        key: 'client_email_address',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.client_email_address}</span>
      },
      {
        title: 'Address (Billing)',
        dataIndex: 'address',
        key: 'address',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span className="ellipse">{record?.billing_address?.[0]?.formatted_address}</span>
      },
      {
        title: 'Interested in',
        dataIndex: 'interested_in',
        key: 'interested_in',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.interested_in}</span>
      },  
      {
        title: 'Customer Type',
        dataIndex: 'proposal_user_type',
        key: 'proposal_user_type',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.proposal_user_type}</span>
      },
      {
        title: 'Proposal Date',
        dataIndex: 'proposal_date',
        key: 'proposal_date',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.proposal_date}</span>
      },
      {
        title: 'Proposal Status',
        dataIndex: 'proposal_status',
        key: 'proposal_status',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.proposal_status ? PROPOSAL_STATUS[record?.proposal_status]: ''}</span>
      },
      {
        title: 'Actions',
        dataIndex: 'record',
        key: 'record',
        className: 'no-wrap',
        render: (text, record) => renderAction(record)
      },
    ]
    return proposalCols;
  }
}

export default new DataTable();
