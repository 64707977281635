import React, { useEffect, useState, forwardRef, useRef } from "react";
import { map } from "lodash";
import { message, Select } from "antd";
import Script from "react-load-script";
import AllState from "../../../Assets/data/stateList.json";
import cross_icon from "../../../Assets/images/cross_icon.svg";
import * as yup from "yup";
import Axios from 'axios';
const { Option } = Select;

const AddAddress = forwardRef((props, refs) => {
  const [addressSelected, setaddressSelected] = useState("");
  const [state, setState] = useState({
    is_address_manually: false,
    address: { address: '', city: '', state: '', zip_code: '' },
    is_btn_active: false
  })

  function getState() {
    setState({
      is_address_manually: false,
      address: { address: '', city: '', state: '', zip_code: '' },
      is_btn_active: false
    })
  }

  useEffect(() => {
    
    getState()
  }, [])

  useEffect(() => {
    validate()
  }, [state.address])

  // Google Map Place API
  const handleScriptLoad = () => {

    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: 'us' } };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const newAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById('google_address'),
      options,
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    newAutoComplete.setFields(['address_components', 'formatted_address']);

    // Fire Event when a suggested name is selected
    newAutoComplete.addListener('place_changed', handlePlaceSelect);

    setState({
      autocomplete: newAutoComplete,
    })
  };

  const removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null && v?.trim() !== "")));
  }

  const handlePlaceSelect = () => {
    const address = document?.getElementById('google_address')?.value;
    setaddressSelected(address);
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const addressObject = res?.data?.results[0];
      let obj = {};
      map(addressObject?.address_components, (item) => {
        if (item?.types?.includes('administrative_area_level_2')) {
          obj = Object.assign(obj, { 'address': item.short_name })
        }
        if (item?.types?.includes('route')) {
          obj = Object.assign(obj, { 'route': item.long_name })
        }
        if (item?.types?.includes('street_number')) {
          obj = Object.assign(obj, { 'street_number': item.short_name })
        }
        if (item?.types?.includes('administrative_area_level_1')) {
          obj = Object.assign(obj, { 'state': item.short_name })
        }
        if (item?.types?.includes('locality')) {
          obj = Object.assign(obj, { 'city': item.long_name })
        }
        if (item?.types?.includes('postal_code')) {
          obj = Object.assign(obj, { 'zip_code': item.short_name })
        }
      });
      obj.address = `${obj?.street_number || ''} ${obj?.route || ''}`;
      setState((state) => ({ ...state, address: obj }), () => {
        if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
          message.error('Please enter valid address')
        }
        validate();
      });
    }).catch((err) => {
      console.log(err)
    })
  }

  // validate numeric input
  const onNumericInputChange = (value) => {
    const reg = /^-?\d*(\.\d*)?$/;
    console.log(((!isNaN(value) && reg.test(value)) || value === '' || value === '-'))
    return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
  };

  // on address change
  const onAddressChange = (e) => {
    const { address } = state;
    Object.assign(address, { [e.target.name]: e.target.value })
    setState((state) => ({ ...state, address }));
  };

  // card validation
  const validate = () => {
    const { address } = state;
    setTimeout(() => {
      const schema = yup.object().shape({
        address: yup.object().shape({
          address: yup.string().required("Address is required"),
          state: yup.string().required("State is required"),
          city: yup.string().required("City is required"),
          zip_code: yup.string().required("Zipcode is required")
        }),
      })

      const data = { address };
      schema.validate(data, { abortEarly: false }).then(() => {
        setState((state) => ({
          ...state,
          is_btn_active: true
        }))
      }).catch(() => {
        setState((state) => ({
          ...state,
          is_btn_active: false
        }))
      });
    }, 500);
  }

  // render all state
  const renderAllState = () => {
    return map(AllState?.AllState, (item, i) => {
      return (<Option value={item.abbreviation} key={i}>{item.name}</Option>)
    })
  }

  const address_ref = useRef(null);
  if (!state.is_address_manually) {
    if (addressSelected) {
      address_ref.current.value = addressSelected;
    }
  } else {
    let addressStr = state.address?.address ? `${state.address?.address}` : '';
    addressStr += state.address?.city ? `, ${state.address?.city}` : '';
    addressStr += state.address?.state ? `, ${state.address?.state}` : '';
    addressStr += state.address?.zip_code ? `, ${state.address?.zip_code}` : '';
    if (addressStr) {
      address_ref.current.value = addressStr || '';
    }
  }

  // render() {
  const { is_address_manually, address, is_btn_active } = state;
  const { onCancel, onAdd } = props;

  return (
    <div>
      <div className="google_search_place mb-10">
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`}
          onLoad={handleScriptLoad}
        />

        <input
          autoComplete="off"
          type="text"
          ref={address_ref}
          className={is_address_manually ? 'google_search_disable form--control' : 'form--control'}
          placeholder="Search for address"
          name="google_address"
          id="google_address"
          onChange={(e) => {
            if (!e.target.value) {
              setState((state) => ({
                ...state,
                address: { address: '', state: '', city: '', zipcode: '' },
              }))
            }
          }}
          disabled={is_address_manually}
        />
        <b>or <a href="/" onClick={(e) => {
          e.preventDefault();
          setState((state) => ({
            ...state,
            is_address_manually: true,
            address: { address: '', state: '', city: '', zipcode: '' },
            is_btn_active:true
          }))
          address_ref.current.value = '';
        }}><u>enter address manually</u></a></b>

      </div>
      {
        is_address_manually &&
        <div className="manually_address">
          <a className="float-right" href="/#" onClick={(e) => {
            e.preventDefault();
            setState((state) => ({
              ...state,
              is_address_manually: true,
              address: { address: '', state: '', city: '', zipcode: '' },
            }))
          }}><img src={cross_icon} alt="" className="remove_address" /></a>
          <br />

          <div className="form-group">
            <div className="fitem">
              <input
                type="text"
                placeholder="Address"
                name="address"
                value={address?.address}
                onChange={(e) => onAddressChange(e)}
              />
            </div>
            <div className="fitem">
              <input
                type="text"
                className='form--control'
                placeholder="City"
                name="city"
                value={address?.city}
                onChange={(e) => onAddressChange(e)}
              />
            </div>
            <div className="fitem">
              <Select
                showArrow={true}
                name="state"
                placeholder="State"
                onChange={(value) => onAddressChange({ target: { name: 'state', value: value } })}
                value={address?.state || undefined}
                virtual={false}
              >
                {renderAllState()}
              </Select>
            </div>

            <div className="fitem">
              <input
                type="text"
                className='form--control'
                placeholder="ZIP Code"
                name="zip_code"
                maxLength={5}
                minLength={5}
                onChange={(e) => onNumericInputChange(e.target.value) && onAddressChange(e)}
                value={address?.zip_code || ''}
              />
            </div>
          </div>
        </div>
      }
      <div className={"mt-10"}>
        <button className={'btn btn-bdr'} onClick={() => onCancel(false)}>Cancel</button>
        <button
          // disabled={!is_btn_active}
          // className={`btn ${is_btn_active ? 'btn-warning' : 'disabled'}`}
          className={'btn btn-warning'}
          onClick={() => {
            onAdd((address?.street_number && address?.route) ? { ...address, address: `${address?.street_number} ${address?.route}` } : address); onCancel()
          }}
        >Add Address</button>
      </div>
    </div>
  )
})

export default AddAddress