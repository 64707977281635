import React, { useState, useEffect, useRef } from "react";
import { Modal, DatePicker, Input, message } from "antd";
import { Provider, useSelector, useDispatch } from "react-redux";
import { getCustomerDetails, createCustomerInstax } from "./utils";
import _ from "lodash";
import store from "../../Store/store";
import moment from "moment";
import { addBillingInfo } from "../../Action/Customer";

const StaxModal = ({
  open = false,
  setOpen = () => {},
  customer = {},
  onSuccessCall = () => {},
  type = "",
  staxKey = null
}) => {

  const effectCalled = useRef(false);
  const [staxJsObj, setStaxJsObj] = useState(null);
  const [cardHolderName, setCardHolderName] = useState("");
  const [expirationMonth, setExpirationMonth] = useState(null);
  const [expirationYear, setExpirationYear] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!effectCalled.current && staxKey && staxKey !== "") {
      loadStax(staxKey);
      effectCalled.current = true;
    }else if(!effectCalled.current){
      message.error("Stax is not set up for this franchise", 10);
      hideActivityLog();
    }
  }, []);

  const hideActivityLog = () => {
    setOpen(false);
  };

  const onAddCard = async () => {
    let customerData = await getCustomerDetails(customer);
    let createCustomerResp = await createCustomerInstax(customerData);
    if (createCustomerResp.id) {
      const card_data = {
        method: "card",
        first_name: cardHolderName?.split(" ")[0] || "",
        last_name: cardHolderName?.split(" ")[1] || "",
        person_name: cardHolderName || "",
        card_number: "",
        month: moment(expirationMonth).format("MM") || "",
        year: moment(expirationYear).format("YYYY") || "",
        customer_id: createCustomerResp.id,
      };

      staxJsObj
        .tokenize(card_data)
        .then(async (res) => {
          if (res && Object.keys(res).length > 0) {
            onSuccessCall(undefined, res);
            message.success("Card added successfully!");
          }
        })
        .catch((err) => {
          message.error(err.message, 10);
        });
    } else {
      message.success("Error con stax customer creation");
    }
  };

  const loadStax = (staxPublicApiKey) => {
    const FattJs = window.FattJs;
    const staxObject = new FattJs(staxPublicApiKey, {
      number: {
        id: "card-number",
        placeholder: "Card Number",
        style:
          "vertical-align: middle; height: 100%; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;", // 35px is the height of my card-number div
      },
      cvv: {
        id: "card-cvv",
        placeholder: "CVV",
        style:
          "vertical-align: middle; height: 100%; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;", // 35px is the height of my card-cvv div
      },
    });
    staxObject.showCardForm().then((handler) => {
      console.log("form loaded! :^)");
      // for testing!
      //handler.setTestPan("4111111111111111");
      //handler.setTestCvv("123");
    });
    staxObject.on("card_form_complete", (message) => {
      console.log("card_form_complete", message);
      // activate pay button
      //this.setState({ isPayButtonDisabled: false });
    });

    staxObject.on("card_form_uncomplete", (message) => {
      console.log("card_form_uncomplete", message);
      // deactivate pay button
      //this.setState({ isPayButtonDisabled: true });
    });

    setStaxJsObj(staxObject);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  return (
    <Provider store={store}>
      <Modal
        title={<span>Add Card</span>}
        open={open}
        onOk={() => onAddCard()}
        onCancel={hideActivityLog}
        className="stax-log-modal"
        destroyOnClose
        maskClosable={true}
        closable={false}
        width={420}
        //footer={null}
      >
        <div className="fitem">
          <label>
            Card Number <span style={{ color: "red" }}>*</span>
          </label>
          <div id="card-number" className="form--control"></div>
        </div>
        <div className="fitem">
          <label>
            CVV <span style={{ color: "red" }}>*</span>
          </label>
          <div id="card-cvv" className="form--control"></div>
        </div>
        <div className="fitem">
          <label>
            Card Holder Name <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            placeholder="Card Holder Name"
            onChange={(value) => setCardHolderName(value.target.value)}
            value={cardHolderName}
          />
        </div>
        <div className="fitem">
          <label>
            Expiration Month <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            picker="month"
            placeholder="MM"
            format="MM"
            suffixIcon={null}
            popupClassName={"stax-no-datepicker-header"}
            onChange={(value) => setExpirationMonth(value)}
            value={expirationMonth}
          />
        </div>
        <div className="fitem">
          <label>
            Expiration Year <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            picker="year"
            placeholder="YYYY"
            suffixIcon={null}
            disabledDate={disabledDate}
            popupClassName={"stax-datepicker-year"}
            onChange={(value) => setExpirationYear(value)}
            value={expirationYear}
          />
        </div>
      </Modal>
    </Provider>
  );
};

export default StaxModal;
