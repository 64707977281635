import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Col, Row } from "antd";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import { selectPoolType } from "../../../../Store/techJourneyDataSlice";

const PoolTypes = () => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const { workTypesArray: rateTypes } = techJourneyState;

  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <h4>Pool type</h4>
          <div className="oplist">
            {map(
              techJourneyState?.serviceMatrix?.pool_types,
              (item, index) => (
                <div
                  className="inline-checks"
                  key={`poolType${index}`}
                >
                  <label htmlFor={item?.uuid} className={`${techJourneyState?.selectedPoolType?.uuid === item?.uuid ? 'active' : ''}`}>
                    <input
                      type="radio"
                      id={item?.uuid}
                      name="poolType"
                      className=""
                      value={item?.uuid}
                      onChange={() => dispatch(selectPoolType(item))
                        // onTextChange({
                        //   target: {
                        //     name: "poolType",
                        //     value: item?.uuid,
                        //     key: item?.name,
                        //   },
                        // })
                      }
                    />
                    <span>{item?.name}</span>
                  </label>
                </div>
              )
            )}
            {/* {poolType==='179a4155-9268-3633-ad49-0a7e14af60a2' && <div className="from-group w-100">
              <div className="fitem">
                <input 
                  type="text" 
                  id="other_pool_type" 
                  name="otherPoolType" 
                  placeholder="Additional other pool type"
                  value={otherPoolType}
                  onChange={(e) => {
                    setOtherPoolType(e.target.value);
                    props.setServiceInfoData({value:e.target.value,key:'otherPoolType',name:e.target.name});
                  }}
                />
              </div>
            </div>} */}
          </div>
          {/* {errors?.[`poolType`] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.[`poolType`]}</label> : ''} */}
          {/* {errors?.[`otherPoolType`] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.[`otherPoolType`]}</label> : ''} */}
        </div>
      </Col>
    </Row>
  );
};

export default PoolTypes;
