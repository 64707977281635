import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {
  addNumberProperties,
  addPoolNumber,
} from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";

const NumberOfPool = (props) => {
  const { editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  return (
    <div className="form-group mb-20">
      <div className="half-fitem fitem">
        <div className="half-fitem fitem">
          <span style={{ fontWeight: "bold" }}>Number of Properties </span>
          <input
            type="text"
            placeholder="Number of Properties"
            name="no_of_properties"
            style={{ width: "71%" }}
            disabled={editMode}
            value={proposals?.number_of_properties}
            onChange={(e) => dispatch(addNumberProperties(e.target.value))}
            onPaste={(event) => {
              if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                event.preventDefault();
              }
            }}
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                e.preventDefault();
              }
            }}
          />
        </div>
        <div className="half-fitem fitem">
          <span style={{ fontWeight: "bold" }}>Number of Pools</span>
          <input
            type="text"
            placeholder="Number of Pools"
            name="no_of_pools"
            style={{ width: "78%" }}
            disabled={editMode}
            value={proposals?.number_of_pools}
            onChange={(e) => dispatch(addPoolNumber(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
};
export default NumberOfPool;
