import React from "react";
import { Modal, message } from "antd";
import {
  openDeactivateModal, resetState
} from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import "../../../Assets/css/proposalBase.css";
import { deactivateAndReactivate } from "../../../Action/Customer";
import Loader from "../../../Components/Loader";
import { useState } from "react";
const DeactivateModal = (props) => {
  const {proposalTableuuid,fromProposalTable,saveClick}=props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [loader, setLoader] = useState(false);


  const onclosedeactivate = () => {
    dispatch(openDeactivateModal(false));
  };
 
  const deactivateProposal = async () => {
    setLoader(true);
    const proposal_uuid= props.state;
    const param = {
      is_active: proposals.deactivate_proposal,
    };
    if(fromProposalTable === 'fromProposalTable'){
      const result = await deactivateAndReactivate(proposalTableuuid,param);
      message.success(result.message,10)
      dispatch(openDeactivateModal(false));
      saveClick(true);
      setLoader(false);

    }
    else{
      const result = await deactivateAndReactivate(proposal_uuid,param);
      message.success(result.message,10)
      dispatch(openDeactivateModal(false));
      dispatch(resetState())
      setLoader(false);

    }
    
  };
  return (
    <div>
                 {loader && <Loader />}
      {proposals.deactivate_modal == true && (
        <div>
          <Modal
            title={<h3> Deactivate Proposal </h3>}
            open={proposals.deactivate_modal}
            closeIcon={null}        
            footer={[
              <button
                key="upload"
                style={{ backgroundColor: "white", color: "black" }}
                className={`btn btn-waning`}
                onClick={() => onclosedeactivate()}
              >
                Cancel
              </button>,
              <button
                key="cancel"
                className="btn btn-brand btn-bdr"
                onClick={() => deactivateProposal()}
              >
                Deactivate
              </button>,
            ]}
            width={400}
          >
            <div>
              <p>
                The Proposal will be deactivated and moved to the Proposal Tab.
              </p>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default DeactivateModal;
