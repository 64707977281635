import React, { useEffect, useRef } from "react";
import "../../../Assets/css/proposalBase.css";
import {
  openDeactivateModal,
  openDeleteModal,
  openActivateModal,
  deactivateProposal,
  deleteProposal,
  redirect_tab,
  resetRedirectState
} from "../../../Store/ProposalDataSlice";
import moment from "moment-timezone";
import DeleteProposal from "./DeleteProposal";
import ReactivateModal from "./ReactivateProposal";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Modal, message } from "antd";
import { service_oreder_modal } from "../../../Store/ProposalDataSlice";
import DeactivateModal from "./ProposalModals";
const ArrowStatus = (props) => {
  const { editMode } = props;
  const redirectState = useRef(false);
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  // const proposalData = useSelector((state) => state.proposalsData);
  useEffect(()=> {
    return() => {
      if(!redirectState.current){
        dispatch(resetRedirectState());
      }
    }
  },[])

  const proposal_status_object = {
    sent: {
      date: "May 01,2022",
      user: "astha mishra",
    },
    accepted: {
        date: "May 01,2022",
        user: "ashu mishra"
    },
    declined: {
      date: "May 01,2022",
      user: "aman verma",
    },
    in_progress: {
      date: "May 01,2022",
      user: "gunjan kapoor",
    },
    enquiry_received: {
      date: "May 01,2022",
      user: "swati dixit",
    },
  };
  const  inquiry_data=props?.state?.proposal_status_object?.enquiry_received?.date;
  const progress_date=props?.state?.proposal_status_object?.in_progress?.date;
  const sent_date=props?.state?.proposal_status_object?.sent?.date;
  const accepted_date=props?.state?.proposal_status_object?.accepted?.date;
  const opendeactrivatemodal = () => {
    dispatch(openDeactivateModal(true));
    dispatch(deactivateProposal(false));
  };
  const openactivatemodal = () => {
    dispatch(openActivateModal(true));
    dispatch(deactivateProposal(true));
  };
  const opendeletemodal = () => {
    dispatch(openDeleteModal(true));
    dispatch(deleteProposal(true));
  };
  const onclickonservicebutton = () => {
    dispatch(service_oreder_modal(true));
  };
  const onCancleButton = () => {
    dispatch(service_oreder_modal(false));
  };
  const onServiceOrderCreate = () => {
    redirectState.current = true;
    dispatch(redirect_tab("Proposal-Redirect"));
    props.tabChange(2);
  };
  return (
    <div className="status-arrow">
      <div className="div-status">
        <div className="dp-flex margin-top-30 mb-5 container-one">
          <div className="inquire-status received relative w-200">
            <button
              className={
                (props?.state?.proposal_status=="enquiry_received" ||props?.state?.proposal_status=="accepted" || props?.state?.proposal_status=="sent" || props?.state?.proposal_status=="in_progress")
                  ? "proposal-button inquire"
                  : "proposal-button sent-one"
              }
            >
              <b>Inquiry Recieved</b>
            </button>
           
          </div>
          <div className="inquire-status relative w-200">
            <button
              className={
                (props?.state?.proposal_status=="in_progress" || props?.state?.proposal_status=="accepted" ||props?.state?.proposal_status=="sent")                  
                  ? "proposal-button progress"
                  : "proposal-button sent-one"
              }
            >
              <b className={ (props?.state?.proposal_status=="in_progress" || props?.state?.proposal_status=="accepted" ||props?.state?.proposal_status=="sent")?"before-color":"after-color"}>Proposal In Progress</b>
            </button>
            
          </div>
          <div className="inquire-status relative w-200">
            <button
              className={
                (props?.state?.proposal_status=="sent" || props?.state?.proposal_status=="accepted")                  
                  ? "proposal-button sent"
                  : "proposal-button sent-one"
              }
            >
              <b className={(props?.state?.proposal_status=="sent" || props?.state?.proposal_status=="accepted") ? "before-color":"after-color" }>Proposal Sent</b>
            </button>
           
          </div>
          <div className="inquire-status relative w-200">
            <button
              className={
                props?.state?.proposal_status=="accepted"
                  ? "proposal-button accepted"
                  : "proposal-button sent-one"
              }
            >
              <b className={ props?.state?.proposal_status=="accepted"? "before-color":"after-color"}>{props?.state?.proposal_status=="accepted"?<span>Proposal Accepted</span>:<span>Proposal Declined</span>}</b>
            </button>

          </div>
        </div>
        <div className="dp-flex margin-top-2">
          <div className="inquire-status status-w-215">
            <p className="proposal-status inquire">
              { moment(inquiry_data).format('ll')  } <br></br>
              { props?.state?.proposal_status_object?.enquiry_received?.user}
            </p>
          </div>
       { props?.state?.proposal_status=="accepted" && <>  <div className="inquire-status status-w-233">
            <p className="proposal-status status-in-progress">
           
            {moment(progress_date).format('ll') } <br></br>{props?.state?.proposal_status_object?.in_progress?.user}
             
              
            </p>
          </div>
          <div className="inquire-status status-w-233">
            <p className="proposal-status status-in-progress">
              { moment(sent_date).format('ll')} <br></br>
              { props?.state?.proposal_status_object?.sent?.user}
            </p>
          </div>
          <div className="inquire-status status-w-233">
            <p className="proposal-status status-in-progress">
              { moment(accepted_date).format('ll') } <br></br>
              { props?.state?.proposal_status_object?.accepted?.user}
            </p>
          </div></>}
        { props?.state?.proposal_status!=="accepted" &&  <div className="inquire-status status-w-233">
            <p className="proposal-inquiry-slab">
      {props?.state?.proposal_status=="enquiry_received" ?
       <span>Moved to<br/> in Progress </span>: 
       <span>Moved to<br/> Proposal Sent </span>}
            </p>
          </div>}
        </div>
       <div className="proposal-row-SO-css">   {((props?.state?.is_active == true  && props?.state?.uuid != "") || proposals?.proposal_number == "")   && <div className="margin-top-10">
          <b>
            If this user is no longer intrested
            <a
              className={`proposal-a margin-left-5 margin-right-5 ${
              (  editMode || props?.state?.uuid == ""|| proposals.proposal_number=="")
                  ? "disabled"
                  : ""
              }`}
              href="/#"
              disabled={editMode || props?.state?.uuid == "" || proposals.proposal_number==""}
              onClick={(e) => {
                e.preventDefault();
                opendeactrivatemodal();
              }}
            >
              <u>{"Deactivate Proposal"}</u>
            </a>
            or
            <a
              className={`proposal-a margin-left-5 ${
                (  editMode || props?.state?.uuid == ""|| proposals.proposal_number=="")
                ? "disabled"
                  : ""
              }`}
              href="/#"
              disabled={editMode || props?.state?.uuid == "" || proposals.proposal_number==""}
              onClick={(e) => {
                e.preventDefault();
                opendeletemodal();
              }}
            >
              <u>{" Delete Proposal"}</u>
            </a>
          </b>
        </div>}
        {props?.state?.is_active==false && (
          <div className="margin-top-10">
            <b>
              if this user is interested
              <a
                className={`proposal-a margin-left-5 margin-right-5 ${
                  editMode ? "disabled" : ""
                }`}
                href="/#"
                disabled={editMode}
                onClick={(e) => {
                  e.preventDefault();
                  openactivatemodal();
                }}
              >
                <u>{"Reactivate Proposal"}</u>
              </a>
              or
              <a
                className={`proposal-a margin-left-5 ${
                  editMode || props?.state?.uuid == ""
                    ? "disabled"
                    : ""
                }`}
                href="/#"
                disabled={editMode || props?.state?.uuid == ""}
                onClick={(e) => {
                  e.preventDefault();
                  opendeletemodal();
                }}
              >
                <u>{" Delete Proposal"}</u>
              </a>
            </b>
          </div>
        )}
         <div className="inquire-status status-w-233">
          {props?.state?.proposal_status=="accepted"&& (
            <div className="inquire-status status-w-235 proposal-flex-end">
              <button
                className="btn btn-warning proposal-SO-btn"
                style={{ marginTop: "7px", marginLeft: "10px"}}
                onClick={(e) => {
                  e.preventDefault();
                  onclickonservicebutton();
                }}
              >
                Create Service Order
              </button>
            </div>
          )}</div></div>
      </div>
      {proposals.deactivate_modal == true && <DeactivateModal  state={props.state.uuid}/>}
      {proposals.activate_modal == true && <ReactivateModal state={props.state.uuid}/>}
      {proposals.delete_modal == true && <DeleteProposal state={props.state.uuid}/>}
      <Modal
        title={<h2> Create Service Order </h2>}
        open={proposals?.service_modal_state}
        style={{ borderRadius: "0 !important" }}
        closeIcon={null}
        // className="delete_user_modal"
        width={500}
        footer={[
          <button
            key="upload"
            style={{ backgroundColor: "white", color: "black" }}
            className={`btn btn-waning`}
            onClick={() => onCancleButton()}
          >
            Cancel
          </button>,
          <button
            key="cancel"
            className="btn btn-brand btn-bdr"
            onClick={() => onServiceOrderCreate()}
          >
            Create Service Order
          </button>,
        ]}
      >
        <div>
          <p>A Service Order will be created for this Proposal.</p>
        </div>
      </Modal>
    </div>
  );
};
export default ArrowStatus;
