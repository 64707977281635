const {createSlice} = require('@reduxjs/toolkit');

const initialState = [];

const customerSlice = createSlice({
    name:'customer',
    initialState,
    reducers:{
        addCustomer(state,action){
            return state=action.payload
        },
        refreshCustomerStatusTable(state, action) {
            return (state = {
              ...state,
              refreshCustomerStatusTable: action.payload,
            });
          },
    }
})

export const {addCustomer, refreshCustomerStatusTable} = customerSlice.actions;
export default customerSlice.reducer;