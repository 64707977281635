import React, { useEffect } from 'react'
import SubscriptionDetails from './SubscriptionDetails';
import Total from './Total';

const ServiceSummary = (props) => {  
  useEffect(() => {
  }, [])
  return (
    <div className="aside-bar">
      <div className={`acol ${props.popClass || ''}`}>
          <h2 className="mb-20">Service Summary</h2>
          <SubscriptionDetails serviceInfo={props?.serviceInfo} priceDetail={props?.priceDetail}/>
          <Total priceDetail={props?.priceDetail} setPriceDetail={props.setPriceDetail}/>
      </div>
    </div>
  )
}

export default ServiceSummary;
