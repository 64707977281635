import React, { useState, useEffect } from 'react';
import Loader from '../../Components/Loader';
import { DatePicker, message, Select } from 'antd';
import moment from 'moment-timezone';
import Script from 'react-load-script';
import AllState from "../../Assets/data/stateList.json";
import { map, isEmpty } from 'lodash';
import * as yup from 'yup';
import cross_icon from "../../Assets/images/cross_icon.svg";
import { errorHandle } from "../../Components/errorHandle";
import { PlusOutlined } from '@ant-design/icons';
import { getUserList } from "../../Action/User";
import AddressField from "../../Components/AddressField";
import Axios from 'axios';
const { Option } = Select;


const AddFranchise = ({ closeAddDrawer, createNewFranchise, inputData, type, isOpen=false }) => {
    const [errors, setErrors] = useState({});
    const [errorsRemittance, setErrorsRemittance] = useState({});
    const [loader, setLoader] = useState(false);
    const [userList, setUserList] = useState([]);
    const [address_ref, setAddress_ref] = useState('');
    const [isRemAddressSame, setIsRemAddressSame] = useState(false);
    const [state, setState] = useState({
        temp_zipcode_1: '',
        temp_zipcode_2: '',
        temp_zipcode_3: '',
        new_franchise: {
            city: '',
            state: '',
            name: '',
            owner_uuid: '',
            owned_zip_codes: [],
            addressable_zip_codes: [],
            new_cust_not_accept_zip_codes: [],
        },
        phone_number: { phone: '' },
        is_address_manually: false,
        address: { address: '', city: '', state: '', zip_code: '' },
        is_add_user_Active: false,
        owner: { uuid: '', first_name: '', last_name: '' },
        remittance: { address: '', city: '', state: '', zip_code: ''},
    });  
    const [remitFlag, setRemitFlag] = useState(false);
    const [franchiseFlag, setFranchiseFlag] = useState(false);

    //Close Drawer
    const handleCancel = () => {
        clearAll();
        closeAddDrawer(false);
    }
    useEffect(() => {
        if (remitFlag && franchiseFlag) {
            saveData();
        }
        setRemitFlag(false);
        setFranchiseFlag(false);
    }, [remitFlag, franchiseFlag])
    //API call for franchise list
    const getUserListData = async (data) => {
        setLoader(true);
        const user_list = await getUserList(data);
        setUserList(user_list?.results || []);
        setLoader(false);
    }
    const handleAddressChange = (value) => {
        if(isRemAddressSame){
            setState({
                ...state,
                address: value,
                remittance: value
            })
        }
        else setState({ ...state, address: value });
    }
    const handleRemittanceChange = (value) => {
        setState({ ...state, remittance: value });
    }
    //Render All State
    const renderAllState = () => {
        return map(AllState?.AllState, (item, i) => {
            return (<Option value={item.abbreviation} key={i}>{item.name}</Option>)
        })
    }

    //render User list
    const renderUser = () => {
        return map(userList, (item) => {
            return (
                <Option key={item.uuid} value={`${item.first_name} ${item.last_name}`}>{item.first_name} {item.last_name} </Option>
            )
        })
    }
    //Set New francbhise Text
    const onFranchiseTextChange = (e) => {
        let { new_franchise } = state;
        new_franchise = Object.assign(new_franchise, { [e.target.name]: e.target.value });
        setState({
            ...state,
            new_franchise: new_franchise
        });
    }
    //Set Franchise Select value
    const onSelectChange = (e, name) => {
        let { new_franchise } = state;
        new_franchise = Object.assign(new_franchise, { [name]: e });
        setState({
            ...state,
            new_franchise: new_franchise
        });
    }

    const onSelectChangeOwner = (value, name, e) => {
        let { new_franchise, owner } = state;
        owner = Object.assign(owner, { uuid: e.key, first_name: value.slice(0, value.lastIndexOf(' ')), last_name: value.slice(value.lastIndexOf(' ')) });
        new_franchise = Object.assign(new_franchise, { [name]: e.key });
        setState({
            ...state,
            new_franchise: new_franchise,
            owner: owner

        });
    }
    //Add Franchise Zipcode (Owned Zips)
    const addNewOwnedZipCodes = () => {
        const { temp_zipcode_1, new_franchise } = state;
        let zipcode = new_franchise.owned_zip_codes;
        if (zipcode.length > 0) {
            if (zipcode.includes(temp_zipcode_1)) {
                return
            } else if (temp_zipcode_1 && temp_zipcode_1.length === 5) {
                setErrors({ ...errors, temp_zipcode_1: '' });
                zipcode.push(temp_zipcode_1);
            } else {
                setErrors({ ...errors, temp_zipcode_1: 'Invalid zipcode' });
            }
        } else {
            setErrors({ ...errors, temp_zipcode_1: '' });
            if (temp_zipcode_1 && temp_zipcode_1.length === 5) {
                zipcode.push(temp_zipcode_1);
            } else {
                setErrors({ ...errors, temp_zipcode_1: 'Invalid zipcode' });
            }
        }
        setState({
            ...state,
            temp_zipcode_1: "",
            new_franchise: { ...new_franchise, owned_zip_codes: zipcode },
        });
    }


    //Remove Own Zipcode
    const removeOwnedZipCode = (e) => {
        let { new_franchise } = state;
        let { owned_zip_codes } = new_franchise;
        let index = owned_zip_codes.indexOf(e);
        owned_zip_codes.splice(index, 1);
        new_franchise = Object.assign(new_franchise, { owned_zip_codes });
        setState({
            ...state,
            new_franchise: new_franchise
        })
    }
    //Add Franchise Zipcode (Owned Zips)
    const addNewAddressableZipCodes = () => {
        const { temp_zipcode_2, new_franchise } = state;
        let zipcode = new_franchise.addressable_zip_codes;
        if (zipcode.length > 0) {
            if (zipcode.includes(temp_zipcode_2)) {
                if(temp_zipcode_2) {
                    setErrors({ ...errors, temp_zipcode_2: 'zipcode already exists' });
                }
                else {
                    setErrors({ ...errors, temp_zipcode_2: '' });
                }
            } else if (temp_zipcode_2 && temp_zipcode_2.length === 5) {
                setErrors({ ...errors, temp_zipcode_2: '' });
                zipcode.push(temp_zipcode_2);
            } else {
                setErrors({ ...errors, temp_zipcode_2: 'Invalid zipcode' });
            }
        } else {
            setErrors({ ...errors, temp_zipcode_2: '' });
            if (temp_zipcode_2 && temp_zipcode_2.length === 5) {
                zipcode.push(temp_zipcode_2);
            } else {
                setErrors({ ...errors, temp_zipcode_2: 'Invalid zipcode' });
            }
        }
        setState({
            ...state,
            temp_zipcode_2: "",
            new_franchise: { ...new_franchise, addressable_zip_codes: zipcode },
        });
        // let { new_franchise, temp_zipcode_2 } = state;
        // let zipcode = new_franchise['addressable_zip_codes'];
        // if (new_franchise['addressable_zip_codes'].length === 5) {
        //     setErrors({ ...errors, temp_zipcode_2: '' });
        //     if (new_franchise['addressable_zip_codes'].includes(temp_zipcode_2)) {
        //         return
        //     }
        // }
        // if (temp_zipcode_2?.length === 5) {
        //     setErrors({ ...errors, temp_zipcode_2: '' });
        //     zipcode.push(temp_zipcode_2)
        // } else {
        //     setErrors({ ...errors, 'temp_zipcode_2': 'Invalid zipcode' });
        // }
        // setState({
        //     ...state,
        //     new_franchise: { ...new_franchise, addressable_zip_codes: zipcode },
        // });
    }
    //Remove Addressable ZipCodes
    const removeAddressableZipCode = (e) => {
        let { new_franchise } = state;
        let { addressable_zip_codes } = new_franchise;
        let index = addressable_zip_codes.indexOf(e);
        addressable_zip_codes.splice(index, 1);
        new_franchise = Object.assign(new_franchise, { addressable_zip_codes });
        setState({
            ...state,
            new_franchise: new_franchise
        });
    }
    //Add new customer not accept zip codes
    const addNewCustNotAcceptZipCodes = () => {
        const { temp_zipcode_3, new_franchise } = state;
        let zipcode = new_franchise.new_cust_not_accept_zip_codes;
        if (zipcode.length > 0) {
            if (zipcode.includes(temp_zipcode_3)) {
                return
            } else if (temp_zipcode_3 && temp_zipcode_3.length === 5) {
                setErrors({ ...errors, temp_zipcode_3: '' });
                zipcode.push(temp_zipcode_3);
            } else {
                setErrors({ ...errors, temp_zipcode_3: 'Invalid zipcode' });
            }
        } else {
            setErrors({ ...errors, temp_zipcode_3: '' });
            if (temp_zipcode_3 && temp_zipcode_3.length === 5) {
                zipcode.push(temp_zipcode_3);
            } else {
                setErrors({ ...errors, temp_zipcode_3: 'Invalid zipcode' });
            }
        }
        setState({
            ...state,
            temp_zipcode_3: "",
            new_franchise: { ...new_franchise, new_cust_not_accept_zip_codes: zipcode },
        });
        // let { new_franchise, temp_zipcode_3, errors } = state;
        // let zipcode = new_franchise['new_cust_not_accept_zip_codes'];
        // if (new_franchise['new_cust_not_accept_zip_codes'].length === 5) {
        //     setErrors({ ...errors, temp_zipcode_3: '' });
        //     if (new_franchise['new_cust_not_accept_zip_codes'].includes(temp_zipcode_3)) {
        //         return
        //     }
        // }
        // if (temp_zipcode_3?.length === 5) {
        //     setErrors({ ...errors, temp_zipcode_3: '' });
        //     zipcode.push(temp_zipcode_3)
        // } else {
        //     setErrors({ ...errors, 'temp_zipcode_3': 'Invalid zipcode' });
        // }
        // setState({
        //     ...state,
        //     new_franchise: { ...new_franchise, new_cust_not_accept_zip_codes: zipcode },
        // });
    }
    //Remove Addressable ZipCodes
    const removeNotAcceptZipCode = (e) => {
        let { new_franchise } = state;
        let { new_cust_not_accept_zip_codes } = new_franchise;
        let index = new_cust_not_accept_zip_codes.indexOf(e);
        new_cust_not_accept_zip_codes.splice(index, 1);
        new_franchise = Object.assign(new_franchise, { new_cust_not_accept_zip_codes });
        setState({
            ...state,
            new_franchise: new_franchise,
        });
    }
    // set address
    const setAddress = (addressObject) => {
        if (!isEmpty(addressObject)) {
            if (addressObject?.status === "OK") {
                let obj = {}, components = addressObject?.results?.[0];
                map(components?.address_components, (item) => {
                    if (item?.types?.includes('administrative_area_level_2')) {
                        obj = Object.assign(obj, { 'address': item.short_name })
                    }
                    if (item?.types?.includes('route')) {
                        obj = Object.assign(obj, { 'route': item.short_name })
                    }
                    if (item?.types?.includes('street_number')) {
                        obj = Object.assign(obj, { 'street_number': item.short_name })
                    }
                    if (item?.types?.includes('administrative_area_level_1')) {
                        obj = Object.assign(obj, { 'state': item.short_name })
                    }
                    if (item?.types?.includes('locality')) {
                        obj = Object.assign(obj, { 'city': item.long_name })
                    }
                    if (item?.types?.includes('postal_code')) {
                        obj = Object.assign(obj, { 'zip_code': item.short_name })
                    }
                });
                setState({ ...state, address: obj });
            } else {
                message.error('Something went wrong while getting address')
            }
        }
    }
    // Google Map Place API
    // const handleScriptLoad = () => {
    //     const options = { componentRestrictions: { country: 'us' } };
    //     const newAutoComplete = new google.maps.places.Autocomplete(
    //         document.getElementById('address'),
    //         options,
    //     );
    //     newAutoComplete.setFields(['address_components', 'name', 'formatted_address']);
    //     newAutoComplete.addListener('place_changed', this.handlePlaceSelect);
    //     this.setState({
    //         autocomplete: newAutoComplete,
    //     })
    // };
    const removeEmpty = (obj) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null && v?.trim() !== "")));
    }
    const handlePlaceSelect = () => {
        const address = document?.getElementById('address')?.value;
        Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: this.removeEmpty({
                latlng: undefined,
                address,
                key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
            })
        }).then((res) => {
            const addressObject = res?.data?.results[0];
            let obj = {};
            map(addressObject?.address_components, (item) => {
                if (item?.types?.includes('route') || item?.types?.includes('administrative_area_level_2')) {
                    obj = Object.assign(obj, { 'address': item.short_name })
                }
                if (item?.types?.includes('route')) {
                    obj = Object.assign(obj, { 'route': item.long_name })
                }
                if (item?.types?.includes('street_number')) {
                    obj = Object.assign(obj, { 'street_number': item.short_name })
                }
                if (item?.types?.includes('administrative_area_level_1')) {
                    obj = Object.assign(obj, { 'state': item.short_name })
                }
                if (item?.types?.includes('locality')) {
                    obj = Object.assign(obj, { 'city': item.long_name })
                }
                if (item?.types?.includes('postal_code')) {
                    obj = Object.assign(obj, { 'zip_code': item.short_name })
                }
            });
            this.setState({ address: obj });
        }).catch((err) => {
            console.log(err)
        })
    }
    // on address change
    const onAddressChange = (e, addr_ref) => {
        setAddress_ref(addr_ref);
        const { address } = state;
        Object.assign(address, { [e.target.name]: e.target.value })
        if(isRemAddressSame){
            setState({...state, 
                address: address,
                remittance: address
            });
        }
        else setState({ ...state, address: address });
    };
    const onRemittanceChange = (e, addr_ref) =>{
        const {remittance} = state;
        Object.assign(remittance, { [e.target.name]: e.target.value })
        setState({ ...state, remittance: remittance});
    };
    const handleIsRemAddressSame = () => {
        let isRem = !isRemAddressSame;
        setIsRemAddressSame(isRem);
        if(isRem){
            setState({...state, remittance: address});
        }
        else{
            setState({
                ...state,
                remittance: { address: '', city: '', state: '', zip_code: ''}
            })
        }
    };
    // validate numeric input
    const onNumericInputChange = (value) => {
        const reg = /^-?\d*(\.\d*)?$/;
        return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
    };
    //Validate Data

    const ValidateTicketRemit= (e) => {
        e.preventDefault();
        setTimeout(() => {
            let {remittance } = state;
            const FranchiseSchema = yup.object().shape({
                remittance: yup.object().shape({
                    address: yup.string().required("Address is required"),
                    city: yup.string().required("City is required"),
                    state: yup.string().required("State is required"),
                    zip_code: yup.string().required("Zipcode is required"),
                }),
            });
            const FranchiseData = {
                remittance
            }
            let IsValid;
            let err_data = {};
            FranchiseSchema.isValid(FranchiseData)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    setErrorsRemittance({});
                    setState({
                        ...state,
                        is_add_user_Active: true,
                    });
                    setRemitFlag(true);
                }
                else {
                    FranchiseSchema.validate(FranchiseData, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            });
                            setErrorsRemittance(err_data);
                            setState({
                                ...state,
                                is_checkout_btn_active: false,
                                is_add_user_Active: false
                            })
                        });
                }
            }, 100);
        }, 300);
    }

    const ValidateTicket = (e) => {
        e.preventDefault();
        setTimeout(() => {
            let { new_franchise, address, phone_number, temp_zipcode_1, temp_zipcode_2, temp_zipcode_3 } = state;
            const FranchiseSchema = yup.object().shape({
                new_franchise: yup.object().shape({
                    city: yup.string().required("City is required"),
                    state: yup.string().required("State is required"),
                    name: yup.string().required("Franchise is required"),
                    // manager_uuid: yup.string().required("Manager is required"),
                    owner_uuid: yup.string().required("Owner is required"),
                    // aggrement_date: yup.string().required("Aggrement date is required"),
                    // owned_zip_codes: yup.array().required("Zipcode is required"),
                    // addressable_zip_codes: yup.array().required("Zipcode is required"),
                    // new_cust_not_accept_zip_codes: yup.array().required(),
                }),
                phone_number: yup.object().shape({
                    phone: yup.string().required("Phone Number is required").min(10, 'Phone Number is required'),
                }),
                address: yup.object().shape({
                    address: yup.string().required("Address is required"),
                    city: yup.string().required("City is required"),
                    state: yup.string().required("State is required"),
                    zip_code: yup.string().required("Zipcode is required"),
                })
            });
            const FranchiseData = {
                new_franchise, address, phone_number, temp_zipcode_1, temp_zipcode_2, temp_zipcode_3
            }
            let IsValid;
            let err_data = {};
            FranchiseSchema.isValid(FranchiseData)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    setErrors({});
                    setState({
                        ...state,
                        is_add_user_Active: true,
                    });
                    setFranchiseFlag(true);
                }
                else {
                    FranchiseSchema.validate(FranchiseData, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            });
                            setErrors(err_data);
                            setState({
                                ...state,
                                is_checkout_btn_active: false,
                                is_add_user_Active: false
                            })
                        });
                }
            }, 100);
        }, 300);
    }

    // validate update setup
    const validateFranchiseDetails = (e) => {
        ValidateTicket(e);
        ValidateTicketRemit(e);
    }
    //Call New Franchise API
    const saveData = () => {
        const { new_franchise, address, phone_number, owner, remittance } = state;
        const addr = (address?.street_number && address?.route) ?
            `${address?.street_number || ''} ${address?.route || ''}` :
            `${address?.address || ''}`;
        const field_office_address = (address?.street_number && address?.route) ? { ...address, address: `${address?.street_number} ${address?.route}` } : { ...address, address: addr };
        
        let data = { ...new_franchise };
        data.address = addr;
        data.field_office_address = address;
        data.phone_number = phone_number;
        data.field_office_address = field_office_address;
        data.invoice_date = moment().format("YYYY-MM-DD");
        data.owner = owner;
        if (data?.new_cust_not_accept_zip_codes.length === 0) {
            data.new_cust_not_accept_zip_codes = [];
        }
        data.remittance_address = remittance?.address || '';
        data.remittance_city = remittance?.city || '';
        data.remittance_state = remittance?.state || '';
        data.remittance_zip_code = remittance?.zip_code || '';

        createNewFranchise(data);
        clearAll();
    }
    const clearAll = () => {
        setState({
            temp_zipcode_1: '',
            temp_zipcode_2: '',
            temp_zipcode_3: '',
            new_franchise: {
                city: '',
                state: '',
                name: '',
                owner_uuid: '',
                owned_zip_codes: [],
                addressable_zip_codes: [],
                new_cust_not_accept_zip_codes: [],
            },
            phone_number: { phone: '' },
            is_address_manually: false,
            address: { address: '', city: '', state: '', zip_code: '' },
            is_add_user_Active: false,
            owner: { uuid: '', first_name: '', last_name: '' },
            remittance: { address: '', city: '', state: '', zip_code: ''},
        });
        setErrors({});
        if (address_ref) {
            address_ref.current.value = '';
        }
    }
    const setData = () => {
        if (inputData) {
            setState({
                ...state,
                new_franchise: {
                    city: inputData.city,
                    state: inputData.state,
                    name: inputData.name,
                    owner_uuid: inputData.owner?.uuid,
                    owned_zip_codes: inputData.owned_zip_codes,
                    addressable_zip_codes: inputData.addressable_zip_codes,
                    new_cust_not_accept_zip_codes: inputData.new_cust_not_accept_zip_codes,
                },
                phone_number: { phone: inputData.phone_number.phone },
                address: { address: inputData.field_office_address.address, city: inputData.field_office_address.city, state: inputData.field_office_address.state, zip_code: inputData.field_office_address.zip_code },
                is_address_manually: false,
                // temp_zipcode_1: data.owned_zip_codes,
                // temp_zipcode_2: data.addressable_zip_codes,
                // temp_zipcode_3: data.new_cust_not_accept_zip_codes,
                owner: {
                    uuid: inputData.owner?.uuid || '',
                    first_name: inputData.owner?.first_name || '',
                    last_name: inputData.owner?.last_name || ''
                },
                remittance: {address: inputData.remittance_address, city: inputData.remittance_city, state: inputData.remittance_state, zip_code: inputData.remittance_zip_code},
            });
            // setLoader(false);
        } else {
            setState({
                // userList: [],
                temp_zipcode_1: '',
                temp_zipcode_2: '',
                temp_zipcode_3: '',
                new_franchise: {
                    city: '',
                    state: '',
                    name: '',
                    owner_uuid: '',
                    owned_zip_codes: [],
                    addressable_zip_codes: [],
                    new_cust_not_accept_zip_codes: [],
                },
                phone_number: { phone: '' },
                is_address_manually: false,
                address: { address: '', city: '', state: '', zip_code: '' },
                is_add_user_Active: false,
                remittance: { address: '', city: '', state: '', zip_code: ''},
            });
        }
    }
    useEffect(() => {
        getUserListData({
            limit: 15,
            offset: 0,
        });
        type === 'edit' && setData();
    }, []);

    useEffect(()=>{
        isOpen && type === 'edit' && setData();
    }, [isOpen]);
    
    let { new_franchise, phone_number, temp_zipcode_1, temp_zipcode_2, temp_zipcode_3, is_address_manually,
        address, owner, remittance } = state;
    return (
        <div className="group_wrapper rdrawer">
            <div className="card-row">
                <div className="card-full">
                    {type === 'edit' ? <h4>Update Franchise Details</h4> : <h4>New Franchise Details</h4>}
                    <div className="form-group">
                        <div className="fitem">
                            <label>Franchise Name</label>
                            <input
                                    type="text"
                                    name="name"
                                    value={new_franchise.name}
                                    onChange={(e) => { onFranchiseTextChange(e) }}
                                    placeholder="Franchise Name" />
                            {errors['new_franchise.name'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.name']}</label> : ''}
                        </div>
                        <div className="fitem half-fitem">
                            <div className="fitem half-fitem">
                                <label>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    autoComplete="franchise_city"
                                    value={new_franchise.city}
                                    onChange={(e) => { onFranchiseTextChange(e) }}
                                    placeholder="City" />
                                {errors['new_franchise.city'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.city']}</label> : ''}
                            </div>
                            <div className="fitem half-fitem">
                                <label>State</label>
                                <Select
                                    name="franchise_state"
                                    onFocus={(event) => {
                                        event.target.autocomplete = "franchise_state"
                                    }}
                                    showSearch
                                    value={new_franchise.state || undefined}
                                    onChange={(e) => { onSelectChange(e, 'state') }}
                                    placeholder="State">
                                    {renderAllState()}
                                </Select>
                                {errors['new_franchise.state'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.state']}</label> : ''}
                            </div>
                        </div>
                        <div className="fitem">
                            <label>Franchise Owner</label>
                            <Select
                                showSearch
                                showArrow={true}
                                filterOption={false}
                                notFoundContent={null}
                                placeholder="Franchise Owner"
                                // value={new_franchise.owner_uuid || undefined}
                                value={`${owner.first_name} ${owner.last_name}`}
                                onChange={(e, key) => { onSelectChangeOwner(e, 'owner_uuid', key) }}
                                onSearch={(e) => { getUserListData({ 'search': e }) }}
                            // onBlur={() => { getUserListData({ 'offset': 0, 'limit': 15 }); }}
                            >
                                {renderUser()}
                            </Select>
                            {errors['new_franchise.owner_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.owner_uuid']}</label> : ''}
                        </div>
                        <div className="fitem relative">
                            <label>Franchise Zipcode (Owned Zips)</label>
                            <div className="fitem-rinner">
                                <input
                                    disabled
                                    placeholder="Franchise Zipcode, Comma Delimited"
                                    type="text"
                                    inputMode="numeric"
                                    max='5'
                                    maxLength='5'
                                    name='temp_zipcode_1'
                                    value={temp_zipcode_1}
                                    style={{cursor: 'not-allowed'}}
                                    onChange={(e) => setState({ ...state, temp_zipcode_1: e.target.value })}
                                    onPaste={(event) => {
                                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputChar = String.fromCharCode(e.charCode);
                                        if (!pattern.test(inputChar)) {
                                            // invalid character, prevent input
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <div className="fitem-ricon">
                                    {temp_zipcode_1 ?
                                        <button style={{ right: 'auto', backgroundColor: '#ff681d', border: 'none', color: 'white' }}
                                            onClick={() => addNewOwnedZipCodes()} ><PlusOutlined /></button> : ''}
                                </div>
                                {errors['temp_zipcode_1'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['temp_zipcode_1']}</label> : ''}
                            </div>
                            {new_franchise.owned_zip_codes?.length > 0 ? <div className="zip_code_list">
                                <b>Sort: Lowest to Highest</b>
                                <ul>
                                    {
                                        map(new_franchise.owned_zip_codes, (item) => {
                                            return (<li key={item}>{item} <i onClick={() => { removeOwnedZipCode(item) }} className="las la-times-circle"></i> </li>)
                                        })
                                    }
                                </ul>
                            </div> : ''}
                        </div>
                        <div className="fitem relative">
                            <label>Franchise Zipcode (Serviced Zips)</label>
                            <div className="relative">
                            <div className="fitem-rinner">
                                <input
                                    placeholder="Franchise Zipcode, Comma Delimited"
                                    type="text"
                                    inputMode="numeric"
                                    max='5'
                                    maxLength='5'
                                    name='addressable_zip_codes'
                                    value={temp_zipcode_2}
                                    onChange={(e) => { setState({ ...state, temp_zipcode_2: e.target.value }) }}
                                    onPaste={(event) => {
                                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputChar = String.fromCharCode(e.charCode);
                                        if (!pattern.test(inputChar)) {
                                            // invalid character, prevent input
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <div className="fitem-ricon">
                                    {temp_zipcode_2 ?
                                        // <i
                                        //     onClick={() => { addNewAddressableZipCodes('addressable_zip_codes') }}
                                        //     className="las la-plus-circle add_zipcode_icon"
                                        // ></i> 
                                        <button style={{ right: 'auto', backgroundColor: '#ff681d', border: 'none', color: 'white' }}
                                            onClick={() => addNewAddressableZipCodes('addressable_zip_codes')} ><PlusOutlined /></button>
                                        : ''}
                                </div>
                                </div>
                                {errors['new_franchise.addressable_zip_codes'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.addressable_zip_codes']}</label> : ''}
                                {errors['temp_zipcode_2'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['temp_zipcode_2']}</label> : ''}
                            </div>
                            {new_franchise.addressable_zip_codes?.length > 0 ?
                                <div className="zip_code_list">
                                    <b>Sort: Lowest to Highest</b>
                                    <ul>
                                        {
                                            map(new_franchise.addressable_zip_codes, (item) => {
                                                return (<li key={item}>{item} <i onClick={() => { removeAddressableZipCode(item) }} className="las la-times-circle"></i> </li>)
                                            })

                                        }
                                    </ul>
                                </div>
                                : ''}
                        </div>
                        {/* <div className="fitem relative">
                            <label>Franchise Zipcode (Serviced, New Customers Not Accepted)</label>
                            <div className="fitem-rinner">
                                <input
                                    placeholder="Franchise Zipcode, Comma Delimited"
                                    type="text"
                                    inputMode="numeric"
                                    max='5'
                                    maxLength='5'
                                    name='temp_zipcode_3'
                                    value={temp_zipcode_3}
                                    onChange={(e) => { setState({ ...state, temp_zipcode_3: e.target.value }) }}
                                    onPaste={(event) => {
                                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputChar = String.fromCharCode(e.charCode);
                                        if (!pattern.test(inputChar)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <div className="fitem-ricon">
                                    {temp_zipcode_3 ?
                                        <button style={{ right: 'auto', backgroundColor: '#ff681d', border: 'none', color: 'white' }}
                                            onClick={() => addNewCustNotAcceptZipCodes()} ><PlusOutlined /></button>
                                        : ''}
                                </div>
                                {errors['temp_zipcode_3'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['temp_zipcode_3']}</label> : ''}
                            </div>
                            {new_franchise.new_cust_not_accept_zip_codes.length > 0 ?
                                <div className="zip_code_list">
                                    <b>Sort: Lowest to Highest</b>
                                    <ul>
                                        {
                                            map(new_franchise.new_cust_not_accept_zip_codes, (item) => {
                                                return (<li key={item}>{item} <i onClick={() => { removeNotAcceptZipCode(item) }} className="las la-times-circle"></i> </li>)
                                            })
                                        }
                                    </ul>
                                </div>
                                : ''}
                        </div> */}
                        {address && (
                            <div>
                            <label>Franchise Address</label>
                            <AddressField fieldName={"google_address1"} nolabel={true} handleAddressChange={handleAddressChange} onAddressChanged={onAddressChange} onAddressChange={onAddressChange} errors={errors} data={address} />
                            </div>
                        )}
                        {remittance && (
                            <div>
                            <label>Remittance Address</label>
                            <div className="fitem-check mt-10 mb-10">
                                <input
                                    type="checkbox"
                                    className="fitem-ck-input"
                                    checked={isRemAddressSame}
                                    id="isRemAddress"
                                    name="isRemAddress"
                                    onChange={handleIsRemAddressSame}
                                />
                                <label htmlFor="isRemAddress" className="fitem-ck-txt">
                                    Same as Franchise Address
                            </label>
                            </div>
                            <AddressField isDisabled={isRemAddressSame} fieldName={"google_address2"} nolabel={true} handleAddressChange={handleRemittanceChange} onAddressChanged={onRemittanceChange} onAddressChange={onRemittanceChange} errorsRemittance={errorsRemittance} data={remittance} />
                            </div>
                        )}
                        <div className="fitem">
                            <label>Franchise Number</label>
                            <div className={phone_number.phone ? 'fix_isd_code' : ''}>
                                {/* {phone_number.phone ? <span>+1</span> : ''} */}
                                <input
                                    placeholder="Franchise Number"
                                    name="phone_number"
                                    value={phone_number.phone}
                                    onChange={(e) => {
                                        phone_number = Object.assign(phone_number, { 'phone': e.target.value });
                                        setState({
                                            ...state,
                                            phone_number
                                        })
                                    }}
                                    type="text"
                                    inputMode="numeric"
                                    max='10'
                                    maxLength='10'
                                    onPaste={(event) => {
                                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputChar = String.fromCharCode(e.charCode);
                                        if (!pattern.test(inputChar)) {
                                            // invalid character, prevent input
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                {errors['phone_number.phone'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['phone_number.phone']}</label> : ''}
                            </div>
                        </div>
                        {/* Commenting out this part for EAP-2293 */}
                        {/* <div className="fitem">
                            <label>Date Agreement Signed</label>
                            <DatePicker
                                style={{ 'width': '100%' }}
                                name='aggrement_date'
                                format="MM/DD/YYYY"
                                suffixIcon={<i className="las la-calendar"></i>}
                                disabledDate={(endValue) => { return endValue.valueOf() >= moment().valueOf(); }}
                                allowClear={false}
                                onChange={(e) => {
                                    new_franchise = Object.assign(new_franchise, { 'aggrement_date': moment(e).format('YYYY-MM-DD') });
                                    setState({
                                        ...state,
                                        new_franchise: new_franchise,
                                    })
                                }}
                                value={new_franchise.aggrement_date ? moment(new_franchise.aggrement_date) : null}
                            />
                            {errors['new_franchise.aggrement_date'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{"Aggrement Date Is Required"}</label> : ''}
                        </div>
                        <div className="fitem">
                            <label>FEIN</label>
                            <input
                                type="text"
                                className='form--control'
                                placeholder="FEIN"
                                name="fein"
                                onChange={(e) => onFranchiseTextChange(e)}
                                value={new_franchise?.fein || ''}
                            />
                        </div> */}
                    </div>
                    <div className="footer" style={{ 'width': '420px' }}>
                        <button
                            onClick={handleCancel}
                            className="btn btn-close">Cancel</button>
                        <button
                            // className={!is_add_user_Active ? 'btn disabled' : 'btn btn-warning'}
                            className='btn btn-bdr'
                            // disabled={!is_add_user_Active}
                            onClick={(e) => {
                                validateFranchiseDetails(e);
                            }}>{type === 'edit' ? 'Update Setup' : 'Start Setup'}</button>
                    </div>
                </div>
                {loader && < Loader />}
            </div>
        </div>
    );
}

export default AddFranchise;