import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Select } from "antd";
import { map } from "lodash";
import { useCalendar } from "../../../Hooks/Calendar.Hook";
import { getTaskCategoryData, addTaskCategory, addTaskCategoryCustomValue} from "../../../../Store/technicianTaskDataSlice";
import { getTaskCategory } from "../../../../Action/Technician/index";
const { Option } = Select;

const AddCategoryComponent = () => {
    const dispatch = useDispatch();
    const calendarData = useCalendar();
    const tasks = useSelector((state) => state.technicianTaskJourney);

    const getTaskCategoryCall = async () => {
        const alltaskcategoryData = await getTaskCategory(
           {field_office__uuid: tasks.franchiseData || calendarData?.franchise?.value});
        dispatch(getTaskCategoryData(alltaskcategoryData));

    };

    const handleCategoryChange = (value) => {
        dispatch(addTaskCategory(value));
    };

    const handleCustomCategory = (e) => {
        dispatch(addTaskCategoryCustomValue(e.target.value));
    }

    const onBlurHandler = (e) => {
        if (e.target.value === "") {
            dispatch(addTaskCategory("General"));
            dispatch(addTaskCategoryCustomValue(""));
        }
    }

    useEffect(() => {
        getTaskCategoryCall();
    }, []);

    return (
        <div>
            <label>
                Task Category<sup>*</sup>
            </label>
            {(tasks.selected_category_value !== ("Add Category")) ?
                <Select
                    defaultValue={tasks?.showTaskFromEdit === true ? tasks?.selectedTaskData?.task_category : "General"}
                    dropdownClassName="sm-filter"
                    showArrow={true}
                    name={'taskCategory'}
                    placeholder="Select Category"
                    onChange={(e) => handleCategoryChange(e)}
                >
                    {map(tasks.taskCategoryData, (item, index) => <Option selected value={item.category} key={item?.uuid}>{item?.category}</Option>)}
                </Select> :
                <input
                    type="text"
                    placeholder="Enter Custom category"
                    name="taskCustomCategory"
                    value={tasks.selected_category_name}
                    onChange={(e) => handleCustomCategory(e)}
                    onBlur={(e) => onBlurHandler(e)}
                />}
        </div>
    );
};

export default AddCategoryComponent;
