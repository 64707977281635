import React from "react";
import { Empty } from "antd";
import { Column } from "@ant-design/plots";

const WorkOrderChart = ({ data = [] }) => {
  const processData = data.reduce((result, workOrder) => {
    const { period, services } = workOrder;

    const totalCount = services.reduce((total, order) => {
      total += order.status.reduce(
        (total_status, status) => (total_status += status.total_count),
        0
      );
      return total;
    }, 0);
    if (totalCount === 0) return [...result];

    const servicesFlat = services.reduce((result_service, service) => {
      return [
        ...result_service,
        ...service.status.map((stat) => ({
          ...stat,
          service_type: service.service_type,
          period: period,
        })),
      ];
    }, []);
    const serviceMerge = servicesFlat.reduce((service_merge, flatService) => {
      const check = service_merge.find(
        (item) => item.service === flatService.service
      );
      return check
        ? service_merge.map((item) =>
            item.service === flatService.service
              ? {
                  ...item,
                  total_count: item.total_count + flatService.total_count,
                }
              : item
          )
        : [
            ...service_merge,
            {
              service: flatService.service,
              total_count: flatService.total_count,
              period: flatService.period,
            },
          ];
    }, []);
    return [...result, ...serviceMerge];
  }, []);

  const config = {
    data: processData,
    appendPadding: [0, 0, 20, 0],
    isStack: true,
    xField: "period",
    yField: "total_count",
    seriesField: "service",
    color: ["#66aa5f", "#4a9ac0", "#777676", "#758F9A"],
    maxColumnWidth: 60,
    xAxis: {
      label: {
        // autoHide: false,
        // offset: 20,
        // rotate: -1,
        // autoEllipsis: true,
        autoEllipsis: true,
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text) => text.charAt(0).toUpperCase() + text.slice(1),
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
      },
    },
    legend: {
      position: "bottom",
      layout: "horizontal",
      maxRow: 4,
      pageNavigator: {
        marker: {
          style: {
            size: 0,
          },
        },
        text: {
          style: {
            fontSize: 0,
          },
        },
      },
      itemName: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text, item, index) =>
          text.toLowerCase() === "scheduled"
            ? "Upcoming"
            : text.charAt(0).toUpperCase() + text.slice(1),
      },
    },
    tooltip: {
      formatter: ({ service, total_count }) => ({
        name: service.charAt(0).toUpperCase() + service.slice(1),
        value: total_count,
      }),
    },
  };

  return (
    <>
      {data.length > 0 ? (
        <Column {...config} />
      ) : (
        <Empty className="no-data-empty" description={false} />
      )}
    </>
  );
};

export default WorkOrderChart;
