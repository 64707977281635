import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { orderBy } from "lodash";
import { getFranchiseListAll } from "../../../Action/Franchise";

const FranchiseSelector = ({
  handleChange,
  defaultValue,
  allValue = true,
  error = false,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo.user.user_roles[0].display_name === "Corporate"
      ? true
      : false;

  const [franchiseList, setFranchiseList] = useState(
    isCorporate ? [] : userInfo.user.field_offices
  );

  useEffect(() => {
    const getFranchiseList = async () => {
      const data = await getFranchiseListAll();
      setFranchiseList(data ? data : []);
    };
    getFranchiseList();
  }, []);

  return (
    <>
      {isCorporate ? (
        <Select
          value={defaultValue}
          onChange={(value, options) => handleChange(value, options)}
          status={error ? "error" : ""}
          placeholder={"Franchise"}
        >
          {allValue ? (
            <Select.Option key={"franchiseAll"} value={"all"} label={"All"}>
              {"All"}
            </Select.Option>
          ) : (
            <></>
          )}
          {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
            <Select.Option
              key={option?.uuid}
              value={option?.uuid}
              label={option?.name}
            >
              {option?.name}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Select value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        disabled={!userInfo.user.associated_field_offices[0].is_multi_location_franchise && franchiseList.length === 1}>
          <Select.Option key={"franchiseAll"} value={"all"} label={"All"}>
            {"All"}
          </Select.Option>
          {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
            <Select.Option
              key={option?.uuid}
              value={option?.uuid}
              label={option?.name}
            >
              {option?.name}
            </Select.Option>
          ))}
          {/* <Select.Option
            value={franchiseList[0]?.uuid}
            label={franchiseList[0]?.name}
          >
            {franchiseList[0]?.name}
          </Select.Option> */}
        </Select>
      )}
    </>
  );
};

export default FranchiseSelector;
