import React from "react";
import { useDashboard } from "../../Hooks/Dashboard.Hook";

const TimeNoteLabel = () => {
  const { periodType } = useDashboard();

  const timeLabel = () => {
    switch (periodType) {
      case "today":
        return <span>* 12:00 am to now</span>;
      case "week":
        return <span>Monday to now</span>;
      case "month":
        return <span>1st of month to now</span>;
      case "year":
        return <span>1st of year to now</span>;
      default:
        return <span>* 12:00 am to now</span>;
    }
  };

  return timeLabel();
};

export default TimeNoteLabel;
