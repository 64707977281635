import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { getServices } from "../../../Action/Reports";

const ServicesSelector = ({ handleChange = () => {}, defaultValue }) => {
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    const getServiceList = async () => {
      const data = await getServices();
      setServiceList(
        data
          ? data
              .sort((a, b) =>
                a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
              )
              .map(
                (item) => ({...item, title: item.name.charAt(0).toUpperCase() + item.name.slice(1)})
              )
          : []
      );
    };
    getServiceList();
  }, []);

  return (
    <>
      <Select value={defaultValue} onChange={(e) => handleChange(e)}>
        <Select.Option key={"servicesAll"} value={"all"}>
          {"All"}
        </Select.Option>
        {serviceList.sort().map((option) => (
          <Select.Option key={option.name} value={option.name}>
            {option.title}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default ServicesSelector;
