import React, { useState, useEffect } from "react";
import stax from '../../Assets/images/stax.png';
import { Select, Modal, message } from "antd";
import { isEmpty, map } from 'lodash';
import * as yup from 'yup';
import Loader from '../../Components/Loader';
import { hubspotSetUp, getHubspotSetUp } from '../../Action/Franchise';
import hubspot from "../../Assets/images/hubspot.png";
const SetupHubspot = ({ franchise_data }) => {
    const [reconnect, setReconnect] = useState(false);
    const [state, setState] = useState(
        {
            errors: {},
            loader: false,
            contact_owener_id: '',
        }
    );
    const onTextChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const onValid = async () => {
        let { contact_owener_id } = state;
        let temp_url = await hubspotSetUp({
            "field_office": franchise_data?.id,
            "hubspot_contact_owner_id": contact_owener_id,
        });
        if (!isEmpty(temp_url.hubspot_contact_owner_id)) {
            setReconnect(true);
            message.success('Hubspot Connected Successfully');
            onFormat();
        }
    }
    const getData = async () => {
        let data = await getHubspotSetUp(franchise_data?.hubspot_feild_office_uuid,);
        if (!isEmpty(data.hubspot_contact_owner_id)) {
            setReconnect(true);
        }
    }
    const onFormat = () => {
        setState({
            errors: {},
            loader: false,
            contact_owener_id: '',
        });
    }
    const onSetupClick = (e) => {
        e.preventDefault();
        setTimeout(() => {
            let { contact_owener_id } = state;
            const schema = yup.object().shape({
                contact_owener_id : yup.string().nullable().required("Hubspot contcat owner id is required"),
            })
            const data = { contact_owener_id }
            // check validity
            let IsValid;
            let err_data = {};
            schema.isValid(data)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    onValid();
                }
                else {
                    schema.validate(data, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            })
                            setState({
                                ...state,
                                errors: err_data
                            })
                            console.log("err_data:", err_data)
                        });
                }
            }, 100);
        }, 300);
    }
    useEffect(() => {
        getData();
    }, []);

    let { contact_owener_id,errors, loader } = state;

    return (
        <div className="">
            <div className="card">
                <div className="card-body">
                <img src={hubspot} alt="" className="stripe_logo" />
                <p><b>Hubspot Settings</b></p>
               <div className="form-group">
                {console.log(reconnect)}
                        {!reconnect ? <>
                            <div className="fitem">
                            <label style={{ fontWeight: 'bold' }}>Hubspot contact owner Id  <sup>*</sup></label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="contact_owener_id"
                                    onChange={(e) => onTextChange(e)}
                                    value={contact_owener_id || ''}
                                />
                                {errors['contact_owener_id'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['contact_owener_id']}</label> : ''}
                            </div>
                            
                            <br />
                            <div>
                                <button className='btn btn-primary' onClick={(e) => onSetupClick(e)}>Save Hubspot settings</button>
                            </div>
                        </>
                            :
                            <div>
                                <button className='btn btn-warning' >Hubspot Is Already Connected</button>
                                <button className='btn btn-primary' onClick={() => setReconnect(false)}>Reconnect</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                loader ?
                    <Loader />
                    : ''}
        </div >
    );
}

export default SetupHubspot;