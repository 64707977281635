import React from "react";
import { Row, Col } from "antd";
import FranchiseSelector from "../Filters/Franchise.Selector";
import StaffSelector from "../Filters/Staff.Selector";
import StatusSelector from "../Filters/Status.Selector";
import { useCalendar } from "../../Hooks/Calendar.Hook";

const FilterHeader = ({ setEvents = () => ({}) }) => {
  const { franchise, setFranchise, setTechnician, setStatuss } =
    useCalendar();

  return (
    <>
      <Row justify="space-between" style={{ width: "100%" }} align="middle">
        <Col md={5} xs={10}>
          <FranchiseSelector
            onControlChange={({ value, label, address, all }) => {
              setEvents([]);
              setFranchise({ value, label, address, all });
            }}
          />
        </Col>
        <Col md={5} xs={10}>
          <StaffSelector
            franchise={franchise}
            autoSelection={true}
            onControlChange={({
              value,
              label,
              start_location,
              stop_location,
            }) => {
              setEvents([]);
              setTechnician({ value, label, start_location, stop_location });
            }}
            unassign={true}
          />
        </Col>
        <Col md={12} xs={3} className="statusSelectorDesktop">
          <StatusSelector
            onControlChange={(status) => {
              setStatus(status);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default FilterHeader;
