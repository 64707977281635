import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

// getAutoSuggest
export const getAutoSuggest = async (params) => {
  try {
    const response = await axiosInstance.get(
      `crm/search/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};
