import React from "react";
import ReactDOM from "react-dom/client";
import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import "./Assets/style.css";
import "../src/App.css";
import "./Assets/css/style.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
if (JSON.parse(localStorage.getItem("cached")) === null) {
  localStorage.setItem("cached", JSON.stringify(false));
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
  onSuccess: () => {
    localStorage.setItem("cached", JSON.stringify(true));
    //window.location.reload();
  },
  onUpdate: () => {
    localStorage.setItem("cached", JSON.stringify(true));
    //window.location.reload();
  },
});
