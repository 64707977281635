import React from 'react';
import { Modal, message } from 'antd';
import { deletePriceCatalogItem, getPriceCatalogApiReq } from '../../../Action/PriceCatalog';
import { setPriceCatalogField, priceCatalogDeletionModal } from "../../../Store/priceCatalogDataSlice"
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";

const DeletePriceCatalogModal = () => {
  const dispatch = useDispatch();
  const { deleteModal, filter } = useSelector((state) => state.priceCatalog);

  const closeDeleteModal = () => {
    dispatch(priceCatalogDeletionModal({ show: false, data: {} }))
  }

  const getPriceCatalog = async () => {
    dispatch(setPriceCatalogField({ loader: true }));
    const response = await getPriceCatalogApiReq(filter);
    dispatch(setPriceCatalogField({ loader: false, priceCatalogList: response.results, filter: { ...filter, total: response.count } }));
  }

  const deleteItem = async () => {
    dispatch(setPriceCatalogField({ loader: true }));
    let response = await deletePriceCatalogItem(deleteModal.data.uuid)
    dispatch(priceCatalogDeletionModal({ show: false, data: {} }))
    if (response) {
      getPriceCatalog();
      message.success(
        `Item deleted`,
        5
      );
    } else {
      message.error(
        `Failed to delete Item`,
        5
      );
      dispatch(setPriceCatalogField({ loader: false }));
    }
  }

  return (
    <div>
      <Modal
        title="Delete Item"
        visible={deleteModal.show}
        onCancel={closeDeleteModal}
        width={400}
        footer={[
          <button className="btn btn-close" onClick={closeDeleteModal} >Cancel</button>,
          <button className="btn btn-brand btn-bdr" onClick={deleteItem} >Delete Item</button>,
        ]}
      >
        <div>
          <p>{`Are you sure you want to delete Item ${deleteModal.data?.productcode} ? This can not be Undone.`}</p>
        </div>
      </Modal>
    </div>
  )
}
export default DeletePriceCatalogModal;