const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  invoicePreference: 'none'
}
const accountDetailSlice = createSlice({
  name: "accountDetail",
  initialState,
  reducers: {
    setAccountDetail(state, action) {
      return (state = {
        ...state,
        ...action.payload,
      });
    },
  }
})
export const { setAccountDetail } = accountDetailSlice.actions;

export default accountDetailSlice.reducer;