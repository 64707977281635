import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Col, Row, Select } from "antd";
import { map } from "lodash";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import { getServiceMatrix } from "../../../../Action/Customer/index";
import {
  selectRateType,
  selectWorkSubType,
} from "../../../../Store/techJourneyDataSlice";

const ServiceRequired = () => {
  const { Option } = Select;
  const [workTypes, setWorkTypes] = useState({});

  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const { workTypesArray: rateTypes } = techJourneyState;

  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <label>
            What service you are looking for?<sup>*</sup>
          </label>
          <div className="oplist">
            {rateTypes && (
              <Select
                dropdownClassName="sm-filter"
                showArrow
                name={"service"}
                virtual={false}
                style={{ width: "100%", marginBottom: "10px" }}
                placeholder={"Select Service Type"}
                onChange={(e) => {
                  dispatch(selectRateType(rateTypes[e]));
                }}
              >
                {rateTypes.map((item, index) => (
                  <Option
                    value={index}
                    key={item?.uuid}
                  >
                    {item?.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          {/* {errors?.[`service`] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.[`service`]}</label> : ''} */}
          {(!isEmpty(techJourneyState?.selectedWorkType) && !isEmpty(techJourneyState?.workSubTypeArray)) &&(
              <div>
                <label> {techJourneyState.selectedWorkType.name
                    .split(" ")
                    .map((item) =>
                      item
                        .toLowerCase()
                        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
                    )
                    .join(" ")}
                  {" Categories"}<sup>*</sup>
              </label>
              <Select
                  dropdownClassName="sm-filter"
                  showArrow
                  name={'worksubtype'}
                  virtual={false}
                  style={{ width: '100%' }}
                  value={techJourneyState?.selectedWorkSubType ? techJourneyState?.selectedWorkSubType?.name : null}
                  placeholder={'Select Category Type'}
                  onChange={(e) =>
                    dispatch(selectWorkSubType(techJourneyState?.workSubTypeArray[e]))
                  }
              >
                {techJourneyState?.workSubTypeArray.map((item, index) => <Option value={index} key={item?.uuid}>{item?.name}</Option>)}
              </Select>
            </div>
            )}

          {/* <div className="oplist">
            {!isEmpty(techJourneyState?.workSubTypeArray) &&
              techJourneyState?.workSubTypeArray.map((item, index) => (
                <div
                  className="inline-checks"
                  key={`service${index}`}
                >
                  <label htmlFor={item?.uuid}
                    className={`${techJourneyState.selectedWorkSubType  && techJourneyState.selectedWorkSubType.uuid === item?.uuid ? 'active' : ''}`}>
                    <input
                      type="radio"
                      id={item?.uuid}
                      title={item.additional_text}
                      name="worksubtype"
                      className=""
                      value={item?.uuid}
                      onChange={
                        () => dispatch(selectWorkSubType({...item, price: item.price}))
                        // onTextChange({
                        //   target: {
                        //     name: "worksubtype",
                        //     value: item?.uuid,
                        //     price: item?.price,
                        //     key: item?.name,
                        //   },
                        // })
                      }
                    />
                    <span>{item?.name}</span>
                  </label>
                </div>
              ))}
          </div> */}
        </div>
      </Col>
    </Row>
  );
};

export default ServiceRequired;
