import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import chevronRight from "../../Assets/images/chevron-right.png";
import { Button, Upload, message } from "antd";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { postUploadImage, getUploadImages } from "../../Action/Customer";
import { UploadOutlined } from '@ant-design/icons';
import Loader from "../../Components/Loader";
import { proposal_key,redirect_from_service } from "../../Store/ProposalDataSlice";
const RenderServiceOrder = ({ totalLength, item, index, status, setCurrentTab,tabChange, serviceOrderList,...props }) => {
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [changes, setChanges] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if(item.files.length < 0){ 
      getFiles();
    }else{
      setFileList(item.files)
    }
  }, [])
  const getFiles = async() =>{
    const response = await getUploadImages(item.uuid, 'serviceorder');    

    if(response.length > 0)   {      
      let file_list = [];        
      response.forEach(element => {        
        element.url = element.image;
        element.so_uuid = item.uuid;
        file_list.push(element);
      });          
      console.log('file_list', file_list);
      setFileList(file_list);    
    }   
  }  
  const onredirectToProposal = () => {
    if (item?.client_type == "commercial") {
      const proposal_uuid = item?.proposal;
      dispatch(proposal_key(proposal_uuid));
      dispatch(redirect_from_service("Service-Redirect"));
      tabChange(3);
    }
   else if (serviceOrderList[0]?.client_type == "residential") {
      const proposal_uuid = serviceOrderList[0]?.proposal;
      dispatch(proposal_key(proposal_uuid));
      dispatch(redirect_from_service("Service-Redirect"));
      tabChange(3);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('id', item.uuid);
    formData.append('id_type', "serviceorder");  
    let uploaded = [];    
    fileList.forEach((file) => {
      if(file.id){
        uploaded.push(file);        
      }
      else{
        formData.append('upload_file', file.originFileObj);      
      }
    });
    formData.append('uploaded_file', JSON.stringify(uploaded)); 
    setUploading(true);
    
    await postUploadImage(formData)          
    .then((res) => {
        console.log(res);
        if(res.message === "No documents uploaded")
        {
          message.error(res.message);
        }else
          message.success(res.message);
      })
      .catch(() => {
        message.error('Upload Failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const fileProps = {
    onPreview: (file) => {
      var fileURL = file.image;
      if(!file.id){
        fileURL = URL.createObjectURL(file.url);
      }
      window.open(fileURL);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setChanges(true);
    },
    beforeUpload: async (file) => {
      setChanges(true);            
      file.url = file;
      file.so_uuid = item.uuid;

      const addFile = async () => {
        setFileList([...fileList, file]);  
      }
      await addFile();      
      console.log("onchange", file)                
      return false;
    },
    onChange(info) {
      setChanges(true);
      setLoader(true);     
      let newFileList = [...info.fileList];      
      setFileList(newFileList);
      setLoader(false);
    },  
  };


  const renderServiceOrder = () => {
    switch (status) {
      case 'Active Order':
        return (
          <React.Fragment key={`service${index}`}>
            {loader && <Loader />}
            <div style={{ textAlign: "right" }}>{item?.pool_name}</div>
            <div className="flex justify-content-start position-relative">
              <strong
                className="snum pointer"
                style={{ minWidth: "56px" }}
                onClick={() => {
                  props.setState((s) => ({ ...s, isServiceDetail: true }));
                  props.setSelectedService(item);
                  props.onViewService(item);
                }}
              >
                {item?.service_order_number}
              </strong>
              {props.renderServiceType(item)}
            </div>
            <div className="position-relative">
              <span
                className="service-arrow"
                onClick={() => {
                  props.setState((s) => ({ ...s, isServiceDetail: true }));
                  props.onViewService(item);
                  props.setSelectedService(item);
                  props.getPauseService({ service_order__uuid: item?.uuid });
                }}
              >
                <img src={chevronRight} alt="" />
              </span>
              <h5
                className="sadd mb-4 pointer"
                style={{ wordWrap: "break-word" }}
                onClick={() => {
                  props.setState((s) => ({ ...s, isServiceDetail: true }));
                  props.onViewService(item);
                  props.setSelectedService(item);
                }}
              >
                {!isEmpty(item?.service_address?.formatted_address)
                  ? `${item?.service_address?.formatted_address}`
                  : "-"}
              </h5>
              <span className="latitude pl-20 opacity-5 fs-11">
                {!isEmpty(item?.service_address?.geo_location)
                  ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
                  : ``}
              </span>
              <br />
              {props.renderContractform(item)}
              <div style={{ display: "block", marginTop: "1em" }}>
                <Upload
                  {...fileProps}
                  // fileList={item.files?.length > 0 ? item.files : fileList}
                  fileList={fileList?.length > 0 ? fileList : null}
                  multiple={true}
                  key={item.uuid}
                >
                  <Button icon={<UploadOutlined />} className="btn btn-upload">
                    Attach Files
                  </Button>
                </Upload>
              </div>
              {/* <Link to="/pool-maintenance-program-form/:formuuid"  target="_blank">
                Pool matainance program
              </Link> */}
            
            {(index?.proposal) && <div>
                <a
                 onClick={onredirectToProposal}
                 >
                  <u style={{ color: "skyblue" }}> View Proposal</u>
                </a>
              </div>}
            </div>
            {totalLength - 1 !== index && <hr />}
            {changes ? (
              <div style={{ display: "flex", marginTop: "1em" }}>
                <Button
                  type="primary"
                  onClick={handleUpload}
                  disabled={!changes}
                  loading={uploading}
                  style={{ width: "100%" }}
                  className="btn btn-warning"
                >
                  {uploading ? "Uploading" : "Save files"}
                </Button>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
        break;
      case 'Pause':
        return (
          <React.Fragment key={`service${index}`}>
            <div className="flex position-relative">
              <strong
                className="snum pointer"
                onClick={() =>
                  props.setState((s) => ({ ...s, isServiceDetail: true }), () => {
                    props.onViewService(item);
                  })
                }
              >
                {item?.service_order_number}
              </strong>
              {props.renderServiceType(item)}
              <span
                className="service-arrow"
                onClick={() =>
                  props.setState((s) => ({ ...s, isServiceDetail: true }), () => {
                    props.onViewService(item);
                  })
                }
              >
                <img src={chevronRight} alt="" />
              </span>
            </div>
            <h5
              className="sadd pointer"
              onClick={() =>
                props.setState((s) => ({ ...s, isServiceDetail: true }), () => {
                  props.onViewService(item);
                })
              }
            >
              {!isEmpty(item?.service_address?.formatted_address)
                ? `${item?.service_address?.formatted_address}`
                : "-"}
            </h5>
            {!!props.pause_obj && (
              <span className="pause-duration">
                Paused from{" "}
                {moment(props.pause_obj?.pause_from).format("MMMM DD, YYYY")} to{" "}
                {props.pause_obj?.pause_to
                  ? moment(props.pause_obj?.pause_to).format("MMMM DD, YYYY")
                  : "Indefinite"}{" "}
              </span>
            )}
            <span className="latitude">
              {!isEmpty(item?.service_address?.geo_location)
                ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
                : ``}
            </span>
            <br />
            <a href="/#">click here</a>
            {totalLength - 1 !== index && <hr />}
          </React.Fragment>
        );
        break;
      case "Inquiry Service":
        return (
          <React.Fragment key={`service${index}`}>
            <div className="flex justify-content-start position-relative">
              <strong
                className="snum pointer"
                onClick={() => {
                  props.setState((s) => ({ ...s, isServiceDetail: true, reviewServiceOrder: true }));
                  props.onViewService(item);
                }}
              >
                {item?.service_order_number}
              </strong>
              {props.renderServiceType(item)}
            </div>
            <div className="position-relative">
              <span
                className="service-arrow"
                onClick={() => {
                  props.setState((s) => ({ ...s, isServiceDetail: true, reviewServiceOrder: true }));
                  props.onViewService(item);
                }}
              >
                <img src={chevronRight} alt="" />
              </span>
              <h5
                className="sadd mb-4 pointer"
                onClick={() => {
                  props.setState((s) => ({ ...s, isServiceDetail: true, reviewServiceOrder: true }));
                  props.onViewService(item);
                }}
              >
                {!isEmpty(item?.service_address?.formatted_address)
                  ? `${item?.service_address?.formatted_address}`
                  : "-"}
              </h5>
              <span className="latitude pl-20 opacity-5 fs-11">
                {!isEmpty(item?.service_address?.geo_location)
                  ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
                  : ``}
              </span>
              <br />
              <a href="/#">Swimming Pool Maintenance Program</a>
            </div>
            {totalLength - 1 !== index && <hr />}
          </React.Fragment>
        );
        break;
      case "Inactive Service":
          return(
            <React.Fragment key={`service${index}`}>
              <div className="flex position-relative">
                <strong
                  className="snum pointer"
                  style={{ 'minWidth': '56px' }}
                  onClick={() =>{
                    props.setState((s) => ({ ...s, isServiceDetail: true }));
                    props.onViewService(item);
                    props.setSelectedService(item);
                    // props.getPauseService({ service_order__uuid: item?.uuid });
                  }}
                >
                  {item?.service_order_number}
                </strong>
                {props.renderServiceType(item)}
              </div>
              <div className="position-relative">
                <span
                  className="service-arrow"
                  onClick={() =>
                    {
                      props.setState((s) => ({ ...s, isServiceDetail: true }));
                      props.onViewService(item);
                      props.setSelectedService(item);
                    }
                  }
                >
                  <img src={chevronRight} alt="" />
                </span>
                <h5
                  className="sadd pointer"
                  onClick={() => {
                    props.setState((s) => ({ ...s, isServiceDetail: true }));
                    props.onViewService(item);
                    props.setSelectedService(item);
                  }}
                >
                  {!isEmpty(item?.service_address?.formatted_address)
                    ? `${item?.service_address?.formatted_address}`
                    : "-"}
                </h5>
                <span className="latitude">
                  {!isEmpty(item?.service_address?.geo_location)
                    ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
                    : ``}
                </span>
                <br />
                <a className={!props.customerActive && "disable-field"} href="/#">Swimming Pool Maintenance Program</a>
              </div>
              {totalLength - 1 !== index && <hr />}
            </React.Fragment>);
          break;
      default:

    }
  }
  return renderServiceOrder();
}

export default RenderServiceOrder;