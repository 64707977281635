import React, { Component, useEffect,useState } from 'react';
import { message, Table, TimePicker } from 'antd';
import moment from 'moment-timezone';
import { map } from 'lodash';
import Datatable from '../Components/DataTable';
import Loader from "../../../Components/Loader";
import { errorHandle } from '../../../Components/errorHandle';
import { getWeeklyAvgData, getServiceStatistics, getFieldOfficeStaff, updateFieldStaff } from '../../../Action/BulkReassign';


const Step1 = (props) => {
const [state,setState] = useState({
    field_staff: [],
    ffield_staff_pagination: {},
    loader: false,
    activePagination: '15',
    technicians: [],
})
function getState(){
    setState({
        field_staff: [],
        ffield_staff_pagination: {},
        loader: false,
        activePagination: '15',
        technicians: [],
    })
}

const [fieldStaff, setFieldStaff] = useState([])
const [ffieldStaffPagination, setFfieldStaffPagination] = useState({});
const [loaders, setLoader] = useState(false);
const [activePaginations, setActivePagination] = useState('15');
const [technicians, setTechnicians] = useState([]);

const getFieldOfficeStaffApiCall = async (params) => {
  const response = await getFieldOfficeStaff(params);
  setFieldStaff(response)
  setLoader(false)
}

const updateFieldStaffApiCall = async (params) => {
  const response = await updateFieldStaff(params);
  if(response.results){
    setFieldStaff(response)
  }
}

useEffect(()=>{
    // getState();
    setFieldStaff([])
    setFfieldStaffPagination({})
    setLoader(false)
    setActivePagination('15')
    setTechnicians([])
    setState({
        ...state,
        loader: false
    });
    setState({
      ...state,
      loader: false
  });
  setLoader(false)

    sessionStorage.removeItem('residentail_selected_row_keys');
    sessionStorage.removeItem('intial_selected_row_keys');
    sessionStorage.removeItem('commercial_selected_row_keys');
    // sessionStorage.removeItem('install_selected_row_keys');
    sessionStorage.removeItem('already_scheduled_intial_selected_row_keys');
    sessionStorage.removeItem('already_scheduled_residentail_selected_row_keys');
    sessionStorage.removeItem('already_scheduled_commercial_selected_row_keys');
},[])

useEffect(() => {
  const user = JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))
    : "";
    const default_franchise_uuid = user?.user?.associated_field_offices?.[0]?.uuid;
    // Get Staff Details
  setLoader(true)
  // let currentDate = moment().format('YYYY-MM-DD') ;
  // let startTime = moment(props.weekStartDate).format('YYYY-MM-DD')
  //   let startDate = startTime
  //   if(startTime < currentDate) {
  //     startDate = currentDate
  //   }
    getFieldOfficeStaffApiCall({ 
        start_date: moment(props.weekStartDate).format('YYYY-MM-DD'),
        field_office__uuid : default_franchise_uuid,
        ordering:'user__first_name'
    });
}, [props.weekStartDate])

useEffect(() => {
  let { ffield_staff_pagination } = state;
    let technician_list = [];

    // message.destroy();
    //get Staff List
    if (fieldStaff && Object.keys(fieldStaff).length > 0) {
      ffieldStaffPagination.total = fieldStaff.count
      map(fieldStaff?.results, (item) => {
        technician_list.push({
          title: item?.user.first_name + " " + item?.user.last_name,
          technician_name: item?.user.first_name + " " + item?.user.last_name,
          technician_uuid: item?.uuid,
          id: item?.uuid,
          ftech_hours: item?.ftech_hours_of_work.filter(data => data.enable === true).map(data => data.date),
          service_count: item?.skillsets.filter(data => data.enable === true).length,
          user_uuid: item?.user?.uuid,
          inactive_days: [],
          classNames : ["filter-resource"]
        })
      });
      props.handleTechnicianDetails(technician_list);
      if(props?.history?.location?.state?.query?.value === 'dashboard') {
        props.nextStep(2)
      }
      setState({
        ...state,
        loader: false,
        field_staff: state?.field_staff,
        technicians: technician_list
      })
      setLoader(false)
      setFieldStaff(fieldStaff)
      setTechnicians(technician_list)
      // setTimeout(() => {
      //   setState({ loader: false })
      // }, 5000);
    }

    // if (Object.keys(state?.field_staff.errors).length > 0 && state?.field_staff.errors !== state?.field_staff.errors) {
    //   setState({
    //     loader: false,
    //   });
    //   errorHandle(props?.FieldStaff?.errors);
    // }

    //Update Staff
    // if (Object.keys(props.UpdateStaff.data).length > 0 && props.UpdateStaff.data !== props.UpdateStaff.data) {
    //   map(props?.FieldStaff.data.results, (item) => {
    //     technician_list.push({
    //       title: item?.user.first_name + " " + item?.user.last_name,
    //       technician_name: item?.user.first_name + " " + item?.user.last_name,
    //       technician_uuid: item?.uuid,
    //       id: item?.uuid, //For displayng data because currently using user uuid
    //       ftech_hours: item?.ftech_hours_of_work.filter(data => data.enable === true).map(data => data.date),
    //       service_count: item?.skillsets.filter(data => data.enable === true).length,
    //       user_uuid: item?.user?.uuid,
    //       inactive_days: [],
    //       classNames : ["filter-resource"]
    //     })
    //   });
    //   props.handleTechnicianDetails(technician_list);
    //   setState({
    //     loader: false,
    //   })
    //   //Get Staff Details
    //   // props.getFieldOfficeStaff({ start_date: moment(moment().day(1)).format('YYYY-MM-DD') });

    // }

    // if (Object.keys(props.UpdateStaff.errors).length > 0 && props.UpdateStaff.errors !== props.UpdateStaff.errors) {
    //   // message.error('Something went wrong', 10);
    //   setState({
    //     loader: false,
    //   });
    //   errorHandle(props?.UpdateStaff?.errors);
    // }
}, [])

  //Staff Skill Change
  const stafCheckChange = (e, uuid, skill_uuid) => {
    let { field_staff } = state;

    let index = field_staff.findIndex(x => { return x.uuid === uuid });
    let index2 = field_staff[index]['skillsets'].findIndex(x => { return x.uuid === skill_uuid });

    field_staff[index]['skillsets'][index2]['enable'] = e.target.checked;


    //Update User API
    UpdateStaff(field_staff[index])

    setState((state)=>({
      ...state,  
      field_staff
    }))
    setFieldStaff(fieldStaff)
  }


  //Update Time
  const updateStaffTime = (e, uuid, skill_uuid, type) => {
    let { field_staff } = state;
    let index = fieldStaff.results.findIndex(x => { return x.uuid === uuid });
    let index2 = fieldStaff.results[index]['ftech_hours_of_work'].findIndex(x => { return x.uuid === skill_uuid });
    fieldStaff.results[index]['ftech_hours_of_work'][index2][type] = moment(e).format('HH:mm:ss');

    //Update User API
    UpdateStaff(fieldStaff.results[index])
    setState({
      ...state,
      field_staff: fieldStaff
    })
    setFieldStaff(fieldStaff)
  }

  //Update Staff User
  const UpdateStaff = (staff) => {
    updateFieldStaffApiCall({
      uuid: staff?.uuid,
      skillsets: staff?.skillsets,
      start_date: staff?.start_date,
      ftech_hours_of_work: staff?.ftech_hours_of_work
    });
    setState((state)=>({...state, loader: false }));
    setLoader(false)

  }


  // Set price list as per invision
  const arrayMove = (arr, fromIndex, toIndex) => {
    if (fromIndex && fromIndex >= 0) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }
  };

  // Table Pagination
  const paginationOnChange = (pagination, filters, sorter) => {
    let { field_staff_pagination } = state;
    let ordering = '';

    // field_staff_pagination = Object.assign(field_staff_pagination, { 'current': pagination.current });

    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `-user__first_name` : `user__first_name`
    }
    else {
      ordering = ''
    }

    setLoader(true)
    // let currentDate = moment().format('YYYY-MM-DD') ;
    // let startTime = moment(props.weekStartDate).format('YYYY-MM-DD')
    //   let startDate = startTime
    //   if(startTime < currentDate) {
    //     startDate = currentDate
    //   }
    getFieldOfficeStaffApiCall({ 
      start_date: moment(props.weekStartDate).format('YYYY-MM-DD'), 
      ordering,
      field_office__uuid : props.selected_franchise
    });


    setState((state)=>({
      ...state,  
      loader: true,
      field_staff_pagination
    }))
    setLoader(true)
    setFfieldStaffPagination(ffieldStaffPagination)


  }
  // Staff Time Change
  const setFieldTime = (uuid, skill_uuid) => {
    let { field_staff } = state;
    let index = fieldStaff.results.findIndex(x => { return x.uuid === uuid });
    let index2 = fieldStaff.results[index]['ftech_hours_of_work'].findIndex(x => { return x.uuid === skill_uuid });
    if (fieldStaff.results[index]['ftech_hours_of_work'][index2]['enable']) {
      fieldStaff.results[index]['ftech_hours_of_work'][index2]['enable'] = false;
      fieldStaff.results[index]['ftech_hours_of_work'][index2]['start_time'] = null;
      fieldStaff.results[index]['ftech_hours_of_work'][index2]['end_time'] = null;
    }
    else {
      var date = moment().format("YYYY-MM-DD");
      var time_start = "09:00";
      var time_end = "17:00";

      fieldStaff.results[index]['ftech_hours_of_work'][index2]['enable'] = true;
      fieldStaff.results[index]['ftech_hours_of_work'][index2]['start_time'] = moment(date + ' ' + time_start).format('HH:mm:ss');
      fieldStaff.results[index]['ftech_hours_of_work'][index2]['end_time'] = moment(date + ' ' + time_end).format('HH:mm:ss');
    }
    //Update User API
    UpdateStaff(fieldStaff.results[index])
    setState((state)=>({
      ...state,  
      field_staff: fieldStaff
    }))
    setFieldStaff(fieldStaff)
  }
  //Render Working Hours
  const renderWorkingHours = (record) => {
    let hoursOfWork = record?.ftech_hours_of_work;
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'sun'), 0);
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'mon'), 1);
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'tues'), 2);
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'wed'), 3);
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'thurs'), 4);
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'fri'), 5);
    arrayMove(hoursOfWork, hoursOfWork.findIndex((x) => x.day === 'sat'), 6);
    return map(hoursOfWork, (list, index) => {
      if (list?.day.charAt(0) === 'm') {
        // console.log("list.start_time:::>", list.start_time, "list.end_time:::>", moment(list.end_time, 'hh:mm'));
      }
      return (
        <li key={index}>
          <span
            onClick={() => {
              setFieldTime(record.uuid, list.uuid)
            }}
            className={!list.enable ? 'day-name text-capitalize doff' : 'day-name text-capitalize'}>{list?.day.charAt(0)}

            <div className="time_tool_tip">
              <label>{moment(list.date).format('ddd')} {moment(list.date).format('MMM DD,YYYY')}</label>
              {list.enable ?
                <p>Uncheck this date if a field technician is unavailable to work this day, off sick or on holiday.</p>
                : <p>Check this date if a field technician is available to work.</p>
              }
            </div>
          </span>
          <div className="work-time">
            <TimePicker
              className="swhite"
              disabled={!list.enable}
              placeholder='-'
              // minuteStep={15}
              defaultPickerValue={moment()}
              value={list.enable ? moment(list.start_time, "hh:mm") : undefined}
              onChange={(e) => {
                updateStaffTime(e, record.uuid, list.uuid, 'start_time')
              }}
              allowClear={false}
              format={'h:mm A'} />
            <TimePicker
              className="swhite"
              disabled={!list.enable}
              placeholder='-'
              // minuteStep={15}
              defaultPickerValue={moment()}
              value={list.enable ? moment(list.end_time, "hh:mm") : undefined}
              onChange={(e) => {
                updateStaffTime(e, record.uuid, list.uuid, 'end_time')
              }}
              allowClear={false}
              format={'h:mm A'} />
          </div>
        </li>
      )
    })

  }


  const setPageLimit = (value) => {
    setState({...state, loader: true });
    setLoader(true)
    let { ffield_staff_pagination } = state;
    Object.assign(ffield_staff_pagination, { current: 1, pageSize: value, ordering: '' });
    Object.assign(ffieldStaffPagination, { current: 1, pageSize: value, ordering: '' });
    setState((state)=>({...state, activePagination: value, ffield_staff_pagination, intialActivePagination: value }));
    setActivePagination(value)
    setFfieldStaffPagination(ffieldStaffPagination)
    
    // let currentDate = moment().format('YYYY-MM-DD') ;
    // let startTime = moment(props.weekStartDate).format('YYYY-MM-DD')
    // let startDate = startTime
    // if(startTime < currentDate) {
    //   startDate = currentDate
    // }
    getFieldOfficeStaffApiCall({ 
      start_date: moment(props.weekStartDate).format('YYYY-MM-DD'),
      field_office__uuid : props.selected_franchise
    });

  }


    let { field_staff, loader, ffield_staff_pagination, activePagination } = state;
    let { current } = props;
    return (
      <div className="main">

        <div className="filter_wrapper acc-wrap flex no-background p-0">
          <div className="left_side mr-2 d-block">
            <h6 className="mb-5">Step 1 of 2: Review Technicians</h6>
            <h3>OptimoRoute Scheduling</h3>
          </div>

          <div className="right_side">
            <div className="form-group flex ai-center w-100 flex-end">
              <div className="fitem max-width-84">
                <div className="justify-content-end">
                  {
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        props.nextStep(props.current === 0 ? 1 : props.current === 1 ? 2 : 0)
                      }}>
                      Next</button>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Table
          rowKey={record => record.uuid}
          dataSource={fieldStaff?.results}
          columns={Datatable.YourStaff(renderWorkingHours)}
          pagination={ffieldStaffPagination}
          onChange={paginationOnChange}
        />

        {fieldStaff?.results?.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setPageLimit('15') }}
                className={activePaginations === '15' ? 'active' : ''}
              >15</span>
            </li>
            <li>
              <span
                className={activePaginations === '30' ? 'active' : ''}
                onClick={() => { setPageLimit('30') }}
              > 30</span>
            </li>
            <li>
              <span
                className={activePaginations === '60' ? 'active' : ''}
                onClick={() => { setPageLimit('60') }}
              >60</span>
            </li>
          </ul>
          : ""
        }
        {loaders ? <Loader /> : ''}
      </div>
    );
  }

export default Step1;