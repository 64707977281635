import React, { useEffect, useState } from "react";
import FileUploader from "../../../Components/FileUploader";
import * as yup from "yup";
import { map, isEmpty } from "lodash";
import { message, Select, DatePicker, Modal } from "antd";
import moment from "moment-timezone";
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  createPoolCharacteristics,
  updatePoolCharacteristics,
  getPoolCharacteristics,
  getServiceOrderByClient,
  getServiceMatrix,
  getServicesAddress,
  getPoolImages,
  savePoolImages,
} from "../../../Action/Customer/index";
import { isSchema } from "yup";
import Loader from "../../../Components/Loader";
import { setImageAfterUpdateHelper } from "./../../customerHelper";

const PoolCharacteristics = (props) => {
  const { Option } = Select;
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [pumptypes, setpumptypes] = useState([]);
  const [filterTypes, setfilterTypes] = useState([]);
  const [chlorinatorTypes, setchlorinatorTypes] = useState([]);
  const [poolCleanerTypes, setpoolCleanerTypes] = useState([]);
  const [poolTypes, setpoolTypes] = useState([]);
  const [servicesAddress, setservicesAddress] = useState([]);
  const [poolNameList, setPoolNameList] = useState([]);
  const [poolList, setPoolList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [imageUpdate, setImageUpdate] = useState(false);
  const [showimages, setshowimages] = useState(false);
  const [defaultFileList, setdefaultFileList] = useState([]);
  const [editModal, setEditModal] = useState(false);

  const [optionsYN] = useState([
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ]);
  const [pool, setPool] = useState({
    client_uuid: "",
    pool_type_uuid: "",
    pool_name: "",
    service_address_uuid: "",
    pump1: "",
    pump_type1: "",
    pump2: "",
    pump_type2: "",
    fileupload: [],
    filter: "",
    filter_type: "",
    chlorinator: "",
    chlorinator_type: "",
    heater: "",
    heater_yn: "",
    pool_cleaner: "",
    pool_cleaner_type: "",
    automation: "",
    automation_yn: "",
    misc: "",
    misc1: "",
    misc2: "",
    pump1_installation_date: "",
    pump1_serial_number: "",
    pump1_warranty_date: "",
    pump2_installation_date: "",
    pump2_serial_number: "",
    pump2_warranty_date: "",
    filter_installation_date: "",
    filter_serial_number: "",
    filter_warranty_date: "",
    chlorinator_installation_date: "",
    chlorinator_serial_number: "",
    chlorinator_warranty_date: "",
    heater_installation_date: "",
    heater_serial_number: "",
    heater_warranty_date: "",
    pool_cleaner_installation_date: "",
    pool_cleaner_serial_number: "",
    pool_cleaner_warranty_date: "",
    automation_installation_date: "",
    automation_serial_number: "",
    automation_warranty_date: "",
    misc_installation_date: "",
    misc_serial_number: "",
    misc_warranty_date: "",
    misc1_installation_date: "",
    misc1_warranty_date: "",
    misc1_serial_number: "",
    misc2_installation_date: "",
    misc2_warranty_date: "",
    misc2_serial_number: "",
  });

  useEffect(() => {
    getServicesAddressList();
    getPoolTypes();
    getpumptypes();
    getfilterTypes();
    getchlorinatorTypes();
    getpoolCleanerTypes();
  }, []);

  useEffect(() => {
    if (!isEmpty(pool.service_address_uuid)) getPools();
  }, [pool.service_address_uuid]);

  useEffect(() => {    
    if(servicesAddress.length === 1) //by default select the service address and pool if Customer has only one pool. 
    {
      setPool(() => ({ ...pool, service_address_uuid: servicesAddress[0].uuid }));
      cleanPool();
    }
  }, [servicesAddress])

  //EAP-4575 Requirement change, select Pool name if there's just one
  useEffect(() => {
    if(poolNameList.length === 1) //by default select the service address and pool if Customer has only one pool. 
    {
      handlePoolName(poolNameList[0].uuid);
    }
  }, [poolNameList])

  const getServicesAddressList = async () => {
    let data = await getServicesAddress(props?.customer?.uuid);
    if (data.length > 0 && data[0].uuid !== undefined) {      
      if(props?.address){
        data = data.filter(address => address.uuid === props?.address?.uuid)
      }
      
      setservicesAddress(data);
      if (props.customer.address) {
        setPool((state) => ({
          ...state,
          service_address_uuid: props?.address ? props?.address?.uuid : props.customer.address.uuid,
        }));
      }
    } else {
      message.error("The customer does not have Services Addresses.");
    }
  }

  const getPools = async () => {
    setLoader(true);
    const data = await getPoolCharacteristics(props?.customer?.uuid);
    if (data.length > 0) {
      let pool_list = data.filter(i => i.service_address?.uuid === pool.service_address_uuid)
      setPoolList(pool_list);
      let pools_name = [];
      pool_list.forEach((element) => {
        if(element.pool_name && element.pool_type_uuid){
          pools_name.push({
            uuid: element.uuid,
            name: element.pool_name,
            item: element,
          });
        }
      });
      setPoolNameList(pools_name);      
    }

    if(props?.pool_characteristic){

    }

    setPool((p) => ({ ...p, client_uuid: props?.customer?.uuid }));
    setLoader(false);
  }

  const getPoolTypes = async () => {
    const data = await getServiceMatrix(props?.customer?.field_office?.uuid);

    if (data.pool_types.length > 0) {
      setpoolTypes(data.pool_types);
    } else message.error("Couldn't find pool types");
  };

  const handleServiceAddress = (value) => {
    setPool(() => ({ ...pool, service_address_uuid: value }));
    cleanPool();
  };
  const handlePoolType = (e) => {
    const pool = poolTypes.find((pool) => pool.uuid === e);
    setPool((p) => ({ ...p, pool_type_name: pool.name, pool_type_uuid: e }));

  };
  const handlePoolName = async (pool_uuid) => {
    setPool(() => ({ ...pool, uuid: pool_uuid }));
    const pool_selected = poolList.find((i) => i.uuid === pool_uuid);
    pool_selected.service_address_uuid = pool.service_address_uuid;
    setPool(pool_selected);
    setshowimages(false);
    const images = await getPoolImages(pool_selected.id);
    if (images.length > 0) {
      const imageResult = setImageAfterUpdateHelper(images);
      setdefaultFileList(imageResult);
      setPool((p) => ({ ...p, fileupload: imageResult }));
      setshowimages(true);
    } else {
      setdefaultFileList([]);
      setPool((p) => ({ ...p, fileupload: [] }));
      setshowimages(true);
    }
  };

  const setImageAfterUpdate = async () => {
    const pool_selected = poolList.find((i) => i.uuid === pool.uuid);
    const images = await getPoolImages(pool_selected.id);
    if (images.length > 0) {
      const imageResult = setImageAfterUpdateHelper(images);
      return imageResult;
    }
  }

  const handlePump1 = (e) => {
    setPool(() => ({ ...pool, pump1: e.target.value }));
  };

  const handlePump2 = (e) => {
    setPool(() => ({ ...pool, pump2: e.target.value }));
  };

  const handlefilter = (e) => {
    setPool(() => ({ ...pool, filter: e.target.value }));
  };

  const handlechlorinator = (e) => {
    setPool(() => ({ ...pool, chlorinator: e.target.value }));
  };

  const handleheater = (e) => {
    setPool(() => ({ ...pool, heater: e.target.value }));
  };

  const handlepoolcleaner = (e) => {
    setPool(() => ({ ...pool, pool_cleaner: e.target.value }));
  };

  const handleautomation = (e) => {
    setPool(() => ({ ...pool, automation: e.target.value }));
  };

  const handlemisc = (e) => {
    setPool(() => ({ ...pool, misc: e.target.value }));
  };

  const handlemisc1 = (e) => {
    setPool(() => ({ ...pool, misc1: e.target.value }));
  };

  const handlemisc2 = (e) => {
    setPool(() => ({ ...pool, misc2: e.target.value }));
  };

  const handlePoolInstallSerialWarrantyData = (e, selectedType, fieldType) => {
    const data = {};
    data[selectedType + '_' + fieldType] = fieldType.toLowerCase().includes('date') ? e ? moment(e).format("YYYY-MM-DD") : e : e;
    setPool((p) => ({ ...p, ...data }));
  }

  const renderCustomField2 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        {loader && <Loader />}
        <label htmlFor="customField2">Pump 1:</label>
        <div>
          <input
            type="text"
            id="pump1"
            name="pool.pump1"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.pump1 == null ? '' : pool.pump1}
            onChange={handlePump1}
          />
        </div>
      </div>
    );
  };

  const renderCustomField3 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        <label htmlFor="customField3">Pump 2:</label>
        <div>
          <input
            type="text"
            id="pump2"
            name="pool.pump2"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.pump2 == null ? '' : pool.pump2}
            onChange={handlePump2}
          />
        </div>
      </div>
    );
  };

  const renderCustomField4 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        <label htmlFor="customField4">Filter:</label>
        <div>
          <input
            type="text"
            id="filter"
            name="pool.filter"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.filter == null ? '' : pool.filter}
            onChange={handlefilter}
          />
        </div>
      </div>
    );
  };

  const renderCustomField5 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        <label htmlFor="customField5">Chlorinator:</label>
        <div>
          <input
            type="text"
            id="chlorinator"
            name="pool.chlorinator"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.chlorinator == null ? '' : pool.chlorinator}
            onChange={handlechlorinator}
          />
        </div>
      </div>
    );
  };

  const renderCustomField6 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        <label htmlFor="customField6">Heater:</label>
        <div>
          <input
            type="text"
            id="heater"
            name="heater"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.heater == null ? '' : pool.heater}
            onChange={handleheater}
          />
          <err field="client.customField6"></err>
        </div>
      </div>
    );
  };

  const renderCustomField7 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        <label htmlFor="customField7">Pool Cleaner:</label>
        <div>
          <input
            type="text"
            id="pool_cleaner"
            name="pool_cleaner"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.pool_cleaner == null ? '' : pool.pool_cleaner}
            onChange={handlepoolcleaner}
          />
          <err field="client.customField7"></err>
        </div>
      </div>
    );
  };

  const renderCustomField8 = () => {
    return (
      <div className="fitem half-fitem margin-left-20 pool-field-width">
        <label for="customField8">Automation:</label>
        <div>
          <input
            type="text"
            id="automation"
            name="automation"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.automation == null ? '' : pool.automation}
            onChange={handleautomation}
            maxLength={10}
          />
          {errors?.["automation"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["automation"]}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const renderCustomField9 = () => {
    return (
      <div className="fitem half-fitem pool-width-misc-field">
        <label for="customField9">Misc 1:</label>
        <div>
          <input
            type="text"
            id="misc"
            name="misc"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.misc == null ? '' : pool.misc}
            onChange={handlemisc}
          />
        </div>
      </div>
    );
  };

  const renderCustomField10 = () => {
    return (
      <div className="fitem half-fitem pool-width-misc-field">
        <label for="customField10">Misc 2: {props.misc}</label>
        <div>
          <input
            type="text"
            id="misc1"
            name="misc1"
            className="form--control"
            value={pool.misc1 == null ? '' : pool.misc1}
            onChange={handlemisc1}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    );
  };

  const renderCustomField11 = () => {
    return (
      <div className="fitem half-fitem pool-width-misc-field">
        <label for="customField11">Misc 3:</label>
        <div>
          <input
            type="text"
            id="misc2"
            name="misc2"
            className="form--control"
            style={{ width: "100%" }}
            value={pool.misc2 == null ? '' : pool.misc2}
            onChange={handlemisc2}
          />
        </div>
      </div>
    );
  };

  const renderInstallSerialWarrantyField = (selectedType) => {
    return (
      <>
        <div className="fitem half-fitem margin-left-20 pool-width-date-field">
          <label htmlFor="customField2">Installed Date:</label>
          <div>
            <DatePicker
              format="MMM DD,YYYY"
              suffixIcon={<i className="las la-calendar"></i>}
              allowClear={true}
              value={pool[selectedType + '_installation_date'] ? moment(pool[selectedType + '_installation_date']) : null}
              onChange={(e) => {
                handlePoolInstallSerialWarrantyData(e, selectedType, 'installation_date')
              }}
            />
          </div>
        </div>
        <div className="fitem half-fitem margin-left-20 pool-width-serial-field">
          <label htmlFor="customField2">Serial Number:</label>
          <div>
            <input
              maxLength={50}
              type="text"
              id="pump1"
              name="pool.pump1"
              className="form--control"
              style={{ width: "100%" }}
              value={pool[selectedType + '_serial_number'] == null ? '': pool[selectedType + '_serial_number']}
              onChange={(e) => {
                handlePoolInstallSerialWarrantyData(e.target.value, selectedType, 'serial_number')
              }}
            />
          </div>
        </div>
        <div className="fitem half-fitem margin-left-20 pool-width-date-field">
          <label htmlFor="customField2">Warranty Date:</label>
          <div>
            <DatePicker
              format="MMM DD,YYYY"
              suffixIcon={<i className="las la-calendar"></i>}
              allowClear={true}
              value={pool[selectedType + '_warranty_date'] ? moment(pool[selectedType + '_warranty_date']) : null}
              onChange={(e) => {
                handlePoolInstallSerialWarrantyData(e, selectedType, 'warranty_date')
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const cleanPool = () => {
    setPool((p) => ({
      ...p,
      pool_name: "",
      pool_type_uuid: "",
      pool_size: "",
      pump1: "",
      pump_type1: "",
      pump2: "",
      pump_type2: "",
      filter: "",
      filter_type: "",
      chlorinator: "",
      chlorinator_type: "",
      heater: "",
      heater_yn: "",
      pool_cleaner: "",
      pool_cleaner_type: "",
      automation: "",
      automation_yn: "",
      misc: "",
      misc1: "",
      misc2: "",
      pump1_installation_date: "",
      pump1_serial_number: "",
      pump1_warranty_date: "",
      pump2_installation_date: "",
      pump2_serial_number: "",
      pump2_warranty_date: "",
      filter_installation_date: "",
      filter_serial_number: "",
      filter_warranty_date: "",
      chlorinator_installation_date: "",
      chlorinator_serial_number: "",
      chlorinator_warranty_date: "",
      heater_installation_date: "",
      heater_serial_number: "",
      heater_warranty_date: "",
      pool_cleaner_installation_date: "",
      pool_cleaner_serial_number: "",
      pool_cleaner_warranty_date: "",
      automation_installation_date: "",
      automation_serial_number: "",
      automation_warranty_date: "",
      misc_installation_date: "",
      misc_serial_number: "",
      misc_warranty_date: "",
      misc1_installation_date: "",
      misc1_warranty_date: "",
      misc1_serial_number: "",
      misc2_installation_date: "",
      misc2_warranty_date: "",
      misc2_serial_number: "",
    }));
  };

  const loadPreviousData = () => {
    const pool_selected = poolList.find((i) => i.uuid === pool.uuid);
    pool_selected.service_address_uuid = pool.service_address_uuid;
    setPool(pool_selected);
  };

  const cancel = () => {
    if (props.onResponse) {
      props?.onResponse(false);
    }
    else {
      setEdit(false);
      loadPreviousData();
      getPools();
    }
  };

  const close = () => {
    setEditModal(false);
    message.error("Pool characteristics cannot be saved.");
    loadPreviousData();
    getPools();
  };

  const validate = () => {
    let valid = true;
    const schema = yup.object({
      client_uuid: yup
        .string()
        .required("Missing client_uuid, please verify data."),
        pool_type_uuid: yup
        .string()
        .nullable(true)
        .required("Please Select Pool Type and Pool Size"),
      automation: pool.automation
        ? yup
          .string()
          .test(
            "len",
            "Must be less than 10 characters",
            (val) => val.length <= 10
          )
        : null,
    });
    schema
      .validate(pool, { abortEarly: false })
      .then(() => {
        setErrors({});
        valid = true;
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        valid = false;
      });

    if (pool.fileupload === null) {
      delete pool.fileupload;
    }

    return valid;
  };
  const save = async () => {
    const val = validate();
    if (val) {
      setLoader(true);
      const data = await createPoolCharacteristics(pool);

      if (data?.id) {
        if (pool.fileupload) {
          if (imageUpdate && pool.fileupload.length > 0) {
            const params = new FormData();
            params.append('pool_id', data.id);
            pool.fileupload.forEach((item) => {
              if (!item.status || item.status !== "removed") {
                params.append('upload_file', item.image);
              }
            });
            
            const images_data = await savePoolImages(params);
            if (images_data.message === "Pool Images uploaded Successfully.") {
              setLoader(false);
              message.success("Pool Characteristics is saved successfully.");
            }
          } else message.success("Pool Characteristics is saved successfully.");
        } else {
          setLoader(false);
          message.success("Pool Characteristics is saved successfully.");
        }
      } else {
        setLoader(false);
        message.error("Something went wrong!");
      }
    }
  };

  const onhandleEditPoolCharactersticsPopup = () => {
    setEditModal(true);
  };

  const update = async () => {
    const val = validate();
    if (val) {
      setLoader(true);
      const data = await updatePoolCharacteristics(pool, pool.id);
      if (data?.id) {
        if (pool.fileupload !== undefined) {
          let imagesList = [];
          const params = new FormData();
          params.append('pool_id', data.id);
          
          if (imageUpdate) {
            pool.fileupload.forEach((item) => {
              if (!item.status || item.status !== "removed") {
                if(item.id){                  
                  imagesList.push(item)
                }
                else{
                  params.append('upload_file', item.image);      
                }                
              }
            });        
            params.append('uploaded_file', JSON.stringify(imagesList));            
            const images_data = await savePoolImages(params);
            if (
              images_data.message === "Pool Images uploaded Successfully." ||
              images_data.message === "Pool Images deleted Successfully."
            ) {
              setshowimages(false);
              const getImageData = await setImageAfterUpdate();
              setdefaultFileList(getImageData);
              setPool((p) => ({ ...p, fileupload: getImageData }));
              setshowimages(true);
              setLoader(false);
              setEdit(false);
              setEditModal(false);
              message.success("Pool Characteristics is saved successfully.");
              handleClick();
            }
          } else{
            setLoader(false);
            setEdit(false);
            setEditModal(false);
            message.success("Pool Characteristics is saved successfully.");
          }
          handleClick();
        } else {
          setLoader(false);
          setEditModal(false);
          message.success("Pool Characteristics is saved successfully.");
          handleClick();
        }
      } else {
        setLoader(false);
        message.error("Please Select Pool Type and Pool Size");

      }


    }
  };

  const handleClick = () => {
    if (props.onResponse) {
      props?.onResponse(false);
    }
  }

  const renderButton = () => {

      return (
        <React.Fragment>
          <button onClick={() => cancel()} className={`btn btn-transparent`}>
            <span>Cancel</span>
          </button>
          <button
            onClick={() => onhandleEditPoolCharactersticsPopup()}
            className={`btn btn-warning`}
            type="primary"
          >
            <span>Update</span>
          </button>
        </React.Fragment>
      );
  };

  const getpumptypes = () => {
    setpumptypes([
      { value: "Single Speed", text: "Single Speed" },
      { value: "Variable Speed", text: "Variable Speed" },
      { value: "Booster", text: "Booster" },
    ]);
  };

  const getfilterTypes = () => {
    setfilterTypes([
      { value: "Sand", text: "Sand" },
      { value: "DE", text: "DE" },
      { value: "Cartridge", text: "Cartridge " },
      { value: "Single Cartridge", text: "Single Cartridge " },
    ]);
  };

  const getchlorinatorTypes = () => {
    setchlorinatorTypes([
      { value: "Salt", text: "Salt " },
      { value: "Inline", text: "Inline " },
      { value: "Offline", text: "Offline " },
      { value: "Other", text: "Other " },
    ]);
  };

  const getpoolCleanerTypes = () => {
    setpoolCleanerTypes([
      {
        value: "Suction Side",
        text: "Suction Side",
      },
      {
        value: "Pressure Side",
        text: "Pressure Side",
      },
      {
        value: "Robotic",
        text: "Robotic ",
      },
    ]);
  };

  const getFiles = (file) => {
    let imagesList = pool.fileupload;
    imagesList.push(file);
    setImageUpdate(true);
    setPool(() => ({ ...pool, fileupload: imagesList }));
  };

  const onRemoveFile = (file) => {
    const newFileList = pool.fileupload.slice();
    const find_element = file.id ?? file.uid;
    const index = newFileList.map(e => e.id ?? e.uid).indexOf(find_element);
    newFileList.splice(index, 1);

    setImageUpdate(true);
    setPool(() => ({ ...pool, fileupload: newFileList }));
  };

  const renderNotAvailable = () => {    
    return (
      servicesAddress.length === 0 ?
        <div className="table-header sorting-btn-group flex ai-center">
          <span className="danger">Please add a Service Address to enable Pool Characteristics</span>
        </div> : null
      );
  }

  return (
    <div className="block-row p-0">
      <div className="container">
        <div className="flex singleservice">
          <div className="w-100">
            <div
              className={
                props.customer?.is_active
                  ? "pool-characteristics"
                  : "pool-characteristics disable-field"
              }
            >
              <div className="pool-characteristics-toolbar">
                <div className="table-header sorting-btn-group flex ai-center">
                  <h3 className="mb-0">Pool Characteristics</h3>
                </div>
                { renderNotAvailable() }
                <div
                  className="fitem"
                  style={{
                    borderBottom: "2px solid #E1E1E5",
                    marginBottom: "15px",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="form-group">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem pool-field-width">
                        <label
                          htmlFor="pumpType1"
                          className="col-sm-3 control-label"
                        >
                          Service Address:
                        </label>
                        <div className="col-sm-9 col-lg-8">
                          <Select
                            id="pumpType1"
                            name="pumpType1"
                            showArrow={true}
                            virtual={false}
                            value={pool.service_address_uuid}
                            onChange={(value) => handleServiceAddress(value)}
                          >
                            {servicesAddress.map((item) => {
                              return (
                                <Option key={item?.uuid}>
                                  {item?.address}, {item?.city}, {item?.state}{" "}
                                  {item?.zip_code}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="fitem half-fitem margin-left-20 pool-field-width">
                        <label
                          htmlFor="pumpType1"
                          className="col-sm-3 control-label"
                        >
                          Pool Name:
                        </label>
                        <div className="col-sm-9 col-lg-8">
                          <Select
                            id="pooltype"
                            name="pooltype"
                            showArrow={true}
                            virtual={false}
                            value={pool.pool_name}
                            onChange={(e) => handlePoolName(e)}
                          >
                            {poolNameList.map((item) => {
                              return (
                                <Option key={item.uuid}>{item.name}</Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="fitem half-fitem margin-left-20 pool-width-date-field">
                      </div>
                      <div className="fitem half-fitem margin-left-20 pool-width-serial-field">
                      </div>
                      <div className="fitem half-fitem margin-left-20 pool-width-date-field">
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem pool-field-width">
                        <label
                          htmlFor="pumpType1"
                          className="col-sm-3 control-label"
                        >
                          Pool Type:
                        </label>
                        <div className="col-sm-9 col-lg-8">
                          <Select
                            id="pool_type_uuid"
                            name="pool_type_uuid"
                            showArrow={true}
                            virtual={false}
                            value={pool.pool_type_uuid}
                            onChange={handlePoolType}
                          >
                            {poolTypes.map((item) => {
                              return (
                                <Option key={item.uuid}>{item.name}</Option>
                              );
                            })}
                          </Select>
                          {errors?.["pool_type_uuid"] ? (
                            <label className="new_err_msg">
                              <i className="las la-info-circle" />
                              {errors?.["pool_type_uuid"]}
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div
                        className="fitem half-fitem margin-left-20 pool-field-width">
                        <label
                          htmlFor="pumpType1"
                          className="col-sm-3 control-label"
                        >
                          Pool Size:
                        </label>
                        <div className="col-sm-9 col-lg-8">
                          <input
                            type="text"
                            id="pool_size"
                            name="pool.pool_size"
                            className="form--control"
                            style={{ width: "100%" }}
                            value={pool.pool_size}
                            onChange={(e) =>
                              setPool(() => ({
                                ...pool,
                                pool_size: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                      <div className="fitem half-fitem margin-left-20 pool-width-serial-field">                                       
                      </div>
                      <div className="fitem half-fitem margin-left-20 pool-width-date-field">                      
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  {errors?.["client_uuid"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["client_uuid"]}
                    </label>
                  ) : (
                    ""
                  )}
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label
                        htmlFor="pumpType1"
                        className="col-sm-3 control-label"
                      >
                        Pump Type 1:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="pumpType1"
                          name="pumpType1"
                          showArrow={true}
                          virtual={false}
                          value={pool.pump_type1}
                          options={pumptypes}
                          onChange={(value) =>
                            setPool(() => ({ ...pool, pump_type1: value }))
                          }
                        />
                      </div>
                    </div>
                    {renderCustomField2()}
                    {renderInstallSerialWarrantyField('pump1')}
                  </div>
                  <div className="col-lg-1"></div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label htmlFor="pumpType2" className="col-sm-3 control-label">
                        Pump Type 2:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="pump_type2"
                          name="pump_type2"
                          showArrow={true}
                          placeholder=""
                          virtual={false}
                          value={pool.pump_type2}
                          options={pumptypes}
                          onChange={(value) =>
                            setPool(() => ({ ...pool, pump_type2: value }))
                          }
                        ></Select>
                        <err field={props?.customer?.pumpType2}></err>
                      </div>
                    </div>
                    {renderCustomField3()}
                    {renderInstallSerialWarrantyField('pump2')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label
                        htmlFor="filterType"
                        className="col-sm-3 control-label"
                      >
                        Filter Type:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="filter_type"
                          name="filter_type"
                          showArrow={true}
                          virtual={false}
                          value={pool.filter_type}
                          options={filterTypes}
                          onChange={(value) =>
                            setPool({ ...pool, filter_type: value })
                          }
                        ></Select>
                        <err field={props?.customer?.pumpType1}></err>
                      </div>
                    </div>
                    {renderCustomField4()}
                    {renderInstallSerialWarrantyField('filter')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label
                        htmlFor="chlorinatorType"
                        className="col-sm-3 control-label"
                      >
                        Chlorinator Type:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="chlorinator_type"
                          name="chlorinator_type"
                          showArrow={true}
                          virtual={false}
                          value={pool.chlorinator_type}
                          options={chlorinatorTypes}
                          onChange={(value) =>
                            setPool({ ...pool, chlorinator_type: value })
                          }
                        ></Select>
                        <err field={props?.customer?.pumpType1}></err>
                      </div>
                    </div>
                    {renderCustomField5()}
                    {renderInstallSerialWarrantyField('chlorinator')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label for="heaterYN" className="col-sm-3 control-label">
                        Heater Y/N:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="heaterYN"
                          showArrow={true}
                          virtual={false}
                          name="pool.heater_yn"
                          value={pool.heater_yn}
                          options={optionsYN}
                          onChange={(value) =>
                            setPool({ ...pool, heater_yn: value })
                          }
                        ></Select>
                        <err field="client.heaterYN"></err>
                      </div>
                    </div>
                    {renderCustomField6()}
                    {renderInstallSerialWarrantyField('heater')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label
                        htmlFor="poolCleanerType"
                        className="col-sm-3 control-label"
                      >
                        Pool Cleaner Type:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="pool_cleaner_type"
                          name="pool_cleaner_type"
                          showArrow={true}
                          virtual={false}
                          value={pool.pool_cleaner_type}
                          options={poolCleanerTypes}
                          onChange={(value) =>
                            setPool({ ...pool, pool_cleaner_type: value })
                          }
                        ></Select>
                        <err field="client.pumpType1"></err>
                      </div>
                    </div>
                    {renderCustomField7()}
                    {renderInstallSerialWarrantyField('pool_cleaner')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    <div className="fitem half-fitem pool-field-width">
                      <label for="automationYN" className="col-sm-3 control-label">
                        Automation Y/N:
                      </label>
                      <div className="col-sm-9 col-lg-8">
                        <Select
                          id="automation_yn"
                          name="automation_yn"
                          showArrow={true}
                          virtual={false}
                          value={pool.automation_yn}
                          options={optionsYN}
                          onChange={(value) =>
                            setPool({ ...pool, automation_yn: value })
                          }
                        ></Select>
                        <err field="client.heaterYN"></err>
                      </div>
                    </div>
                    {renderCustomField8()}
                    {renderInstallSerialWarrantyField('automation')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    {renderCustomField9()}
                    {renderInstallSerialWarrantyField('misc')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    {renderCustomField10()}
                    {renderInstallSerialWarrantyField('misc1')}
                  </div>
                </div>
                <div className="form-group">
                  <div className="fitem half-fitem">
                    {renderCustomField11()}
                    {renderInstallSerialWarrantyField('misc2')}
                  </div>
                </div>
                <div className="mt-20">
                  <div className="fitem">
                    {showimages ? (
                      <FileUploader
                        getFiles={getFiles}
                        defaultFileList={defaultFileList}
                        onRemoveFile={onRemoveFile}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mt-30">
                  <div className="fitem  text-right">{renderButton()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={editModal}
        okText={"Yes"}
        mask={true}
        onOk={() => update()}
        onCancel={() => close()}
        cancelText={"No"}
        className="pool_characterstics_modal"
        closeIcon={null}        
        width={400}
      >
        <div>
          <span>
            <b>
              <ExclamationCircleFilled /> Do you want to edit the Pool Characteristics ?
            </b>
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default PoolCharacteristics;
