import React, { useEffect, useState } from "react";
import { Select, ConfigProvider, Empty, Spin } from "antd";
import { orderBy, isEmpty } from "lodash";
import { getFieldTechnicianCalendar } from "../../../Action/Franchise/index";
import { useCalendar } from "../../Hooks/Calendar.Hook";

const { Option } = Select;

const StaffSelector = ({
  franchise = { value: null, label: null },
  onControlChange = () => {},
  autoSelection = false,
  unassign = false,
  permission = true,
  defaultValue = { value: null, label: null },
  reassignPop,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const is_fo = userInfo?.user?.user_roles.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );
  const is_fo_tech =
    userInfo?.user?.user_roles.filter(
      (i) =>
        i.name === "franchise_owner" ||
        i.name === "field_tech" ||
        i.name === "operations_mgr"
    )?.length >= 2;

  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });
  const role = is_fo_tech
    ? "franchise_owner_tech"
    : is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
    ? "lead_tech"
    : userInfo?.user?.user_roles[0]?.name;

  const [seeCal, setSeeCal] = useState(false);
  const [techList, setTechList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const { offline, setOffline } = useCalendar();

  useEffect(() => {
    const getFieldTech = async () => {
      setLoading(true);
      setTechnicians([]);
      setSelectedTechnician(null);
      if (franchise.value === null) {
        setTechnicians([]);
        return;
      }
      let data = null;
      if (offline) {
        data = JSON.parse(localStorage.getItem("field_technician"));
      } else {
        try {
          data = await getFieldTechnicianCalendar({
            field_office__uuid:
              franchise.value === "all"
                ? userInfo.user.associated_field_offices[0].uuid
                : franchise.value,
          });
          console.log("terch", data)
          localStorage.setItem("field_technician", JSON.stringify(data));
        } catch (error) {
          setOffline(true);
          data = JSON.parse(localStorage.getItem("field_technician"));
        }
      }
      //let temp =  data.results.filter((each) => userInfo.user.uuid === each.user.uuid);
      setSeeCal(userInfo.user?.technician_permissions?.see_calenders);
      setTechList(userInfo.user?.technician_permissions?.assign_wo);
      if (isEmpty(data?.results)) {
        setTechnicians([]);
        onControlChange({ value: null, label: null, address: {} });
        setLoading(false);
        return;
      }

      switch (true) {
        case role === "admin":
          if (franchise && autoSelection) {
            setSelectedTechnician({
              value: data.results[0]?.user.uuid,
              label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
              start_location: data.results[0]?.staff_profile?.start_location,
              stop_location: data.results[0]?.staff_profile?.stop_location,
            });
            onControlChange({
              value: data.results[0]?.user.uuid,
              label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
              start_location: data.results[0]?.staff_profile?.start_location,
              stop_location: data.results[0]?.staff_profile?.stop_location,
            });
          }
          if (franchise && defaultValue.value) {
            setSelectedTechnician({
              value: defaultValue.value,
              label: defaultValue.label,
            });
          }
          break;
        case role === "franchise_owner":
        case role === "operations_mgr":
          if (franchise && autoSelection) {
            setSelectedTechnician({
              value: "unassign",
              label: "Not Assigned",
            });
            onControlChange({
              value: "unassign",
              label: "Not Assigned",
            });
          }
          if (franchise && defaultValue.value) {
            setSelectedTechnician({
              value: defaultValue.value,
              label: defaultValue.label,
            });
          }
          break;
        case role === "field_tech" ||
          role === "lead_tech" ||
          role === "franchise_owner_tech":
          if (franchise) {
            const tech_value = defaultValue.value
              ? defaultValue
              : {
                  value: userInfo.user.uuid,
                  label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
                };
            setSelectedTechnician({
              value: tech_value.value,
              label: tech_value.label,
            });
            onControlChange({
              value: tech_value.value,
              label: tech_value.label,
            });
          }
          break;
        default:
          break;
      }
      let techList = orderBy(
        data.results,
        [
          (item) =>
            item?.user?.first_name.toLowerCase() +
            " " +
            item?.user?.last_name.toLowerCase(),
        ],
        "asc"
      );
      if (unassign) {
        techList.push({
          user: {
            uuid: "unassign",
            first_name: "Not Assigned",
            last_name: "",
          },
        });
      }
      setTechnicians(techList);
      setLoading(false);
    };
    getFieldTech();
  }, [franchise]);

  const updateTechnician = (selectedTechnician, options) => {
    const tech = technicians.find((i) => i.user.uuid === selectedTechnician);
    options = {
      ...options,
      start_location: tech?.staff_profile?.start_location,
      stop_location: tech.staff_profile?.stop_location,
    };
    setSelectedTechnician(selectedTechnician);
    onControlChange(options ?? { value: null, label: null });
  };

  const configureStaffeSelector = () => {
    switch (true) {
      case role === "admin" ||
        role === "franchise_owner" ||
        role === "franchise_owner_tech" ||
        role === "operations_mgr":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading technicians..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              showSearch
              allowClear
              placeholder="Not assigned"
              className="swhite calendar-filter-tech"
              optionFilterProp="children"
              onChange={(value, options) => updateTechnician(value, options)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedTechnician || undefined}
              disabled={!permission}
            >
              {technicians?.map((technician) => {
                return (
                  <Option
                    key={technician.user.uuid}
                    value={technician.user.uuid}
                    label={`${technician?.user?.first_name} ${technician?.user?.last_name}`}
                  >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                );
              })}
            </Select>
          </ConfigProvider>
        );
      case role === "field_tech" || role === "lead_tech":
        if (seeCal || (reassignPop && techList)) {
          return (
            <Select
              showSearch
              allowClear
              placeholder="Technician: Not assigned"
              className="swhite calendar-filter-tech"
              optionFilterProp="children"
              onChange={(value, options) => updateTechnician(value, options)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedTechnician || undefined}
              disabled={!permission}
            >
              {technicians?.map((technician) => {
                return (
                  <Option
                    key={technician.user.uuid}
                    value={technician.user.uuid}
                    label={`${technician?.user?.first_name} ${technician?.user?.last_name}`}
                  >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                );
              })}
            </Select>
          );
        } else {
          return (
            <Select
              value={selectedTechnician}
              className="swhite calendar-filter-tech"
              disabled={!permission}
            >
              <Option
                value={selectedTechnician?.uuid}
                label={selectedTechnician?.label}
              >
                {selectedTechnician?.label}
              </Option>
            </Select>
          );
        }
      default:
        break;
    }
  };

  return configureStaffeSelector();
};

export default StaffSelector;
