import React, {useEffect, useState} from "react";
const SideBar = (props) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(props.priceDetail?.price);
  }, [props.priceDetail]);
  
  const saveData = (e) => {
    setValue(e.target.value);
    props.setPriceDetail(e.target.value);
  }

  const renderSummary = () => {       
    return (
      <div className="aside-bar">
        <div className="acol">
          <h2 className="mb-20">Service Summary</h2>
          <h4>Subscription details</h4>
          <div className="flex">
            <div className="flex-1">
                <p className='text-capitalize'>{props?.serviceInfo?.serviceType?.key}</p>
                {props?.serviceInfo?.serviceType?.key.toLowerCase() !=='maintenance'?<p className='text-capitalize'>{props?.serviceInfo?.service?.key}</p>:null}
                <p className='text-capitalize'>{props?.priceDetail?.frequency?.recurring?.repeat}</p> 
                <p className='text-capitalize'>{props?.serviceInfo?.poolType?.key}{props?.serviceInfo?.otherPoolType?.value?" / "+props?.serviceInfo?.otherPoolType?.value:null}</p>
            </div>
          </div>        
          <hr/>
          {props?.serviceInfo?.serviceType?.key.toLowerCase() ==='maintenance'?
            <p style={{'textAlign':'justify'}}>Pool must be clear and balanced before we begin maintenance. Price may change once we visit pool.</p>
            :
           props?.serviceInfo?.service?.key || props?.priceDetail?.key?
            <p style={{'textAlign':'justify'}}>{`Price is for ${props?.serviceInfo?.service?.key || props?.priceDetail?.key} only. Price for repair to be given once ${props?.serviceInfo?.service?.key || props?.priceDetail?.key} has been completed`}</p>
          :null}  
          <div className="wlight">
          <div className="flex ai-center">
        <div className="flex-1">
          <h4 className="m-0">Total{props.priceDetail?.key ? props.priceDetail?.key === 'One Time' ? "" : " (Monthly Charge) :" : ""}</h4>
        </div>
        <div className="pricing" style={{ 'width': '50%', 'position': 'relative' }}>
          <span style={{ 'position': 'absolute', 'left': '20%', 'paddingTop': '9px' }} >$</span>
          <input
            type="text"
            className='pricing pl-10'
            style={{ 'width': '100%', 'backgroundColor': '#f9ffd9', 'paddingLeft': '25%', 'textAlign': 'left', 'marginLeft': '8px' }}
            placeholder="Price"
            name="price"
            value={value}
            onChange={(e) => saveData(e)}
          />
        </div>
          </div>
        </div>        
        </div>
        
      </div>
    );
  }

  const render = () => {
    return renderSummary()
  }

  return render();
}

export default SideBar;