import React, { useState, useRef, useEffect } from 'react'
import { Table, Input, message } from 'antd';
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import SignatureCanvas from 'react-signature-canvas';
import "../Assets/css/proposal.css";
import Loader from './Loader';
import { getProposalData, updateProposalSignature, updateProposalStatus } from '../Action/Customer/index';
import headerImage from '../Assets/images/proposal-header.svg';

const Proposal = () => {
    //canvas
    const ownerSignRef = useRef();// Create a first ref for the second canvas
    const franchiseOwnerRef = useRef(); // Create a second ref for the second canvas
    const [ownerSignature, setOwnerSignature] = useState();
    const [franchiseOwnerSignature, setFranchiseOwnerSignature] = useState();
    const [signature, setSignature] = useState();

    const params = useParams(); 
    const [loader, setLoader] = useState(false);
    const [proposalData, setProposalData] = useState([]);
    const [uuid, setUuid] = useState("");
    const [status, setStatus] = useState();
    const [validationCheckbox, setValidationCheckbox] = useState('');

    //errors
    const [errors, setErrors] = useState({});
    //form states
    const [formData, setFormData] = useState({
        ownerName: '',
        franchiseOwnerName: '',
        checkedList: [], // Checkbox state
    });

    //handle Owner Signature to B64
    const handleOwnerSignature = () => {
        setOwnerSignature(ownerSignRef.current.toDataURL());
    };

    //handle Franchise Owner Signature to B64
    const handleFranchiseOwnerSignature = () => {
        const base64Signature =  setFranchiseOwnerSignature(franchiseOwnerRef.current.toDataURL());
    };

    //clear Owner Signature
    const clearOwnerSignature = () => {
        ownerSignRef.current.clear();
        setOwnerSignature(null);
    };

    //clear Franchise Owner Signature
    const clearFranchiseOwnerSignature = () => {
        franchiseOwnerRef.current.clear();
        setFranchiseOwnerSignature(null);
    };

    //convert base64 to image when getting the data from an api for owner signature
    const loadBase64Image = (base64Image) => {
        const canvas = ownerSignRef.current.getCanvas();
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = base64Image;
    
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
        };
    };

    //convert base64 to image when getting the data from an api for franchise owner signature
    const loadBase64ImageFranchise = (base64Image) => {
        const canvas = franchiseOwnerRef.current.getCanvas();
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = base64Image;
    
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
        };
    };

    //validations
    const validationSchema = yup.object().shape({
        ownerName: yup.string().required('*Owner name is required'),
        franchiseOwnerName: yup.string().required('*Franchise owner name is required'),
    });

    //Implement Yup validation
    const validateForm = async () => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            return true; // Validation passed
        } catch (error) {
            const newErrors = {};

            error.inner.forEach((e) => {
                newErrors[e.path] = e.message;
            });

            setErrors(newErrors);
            return false; // Validation failed
        }
    };

    //Display error messages
    const renderError = (field) => {
        return errors[field] ? <div className="error">{errors[field]}</div> : null;
    };

    //onchange checkboxes
    const handleCheckboxChange = (id) => {
        let updatedCheckboxes = formData?.checkedList;
        updatedCheckboxes.map((obj) => {
            if(obj.id === id) {
                obj.value = !obj.value;
            }
        })

        setFormData({ ...formData, checkedList: updatedCheckboxes });
    };

    //validation checkbox
    const validationCheckboxes = () => {
        const validCheckbox = formData?.checkedList;
        let flag = 0;
         
        validCheckbox.forEach((obj) => {
            if (obj.value == false) {
                flag++;
            }
        });
         
        if(flag == 2) {
            setValidationCheckbox('Please select at least one checkbox.');
            return false;
         }
        else {
           // Clear any previous validation error if at least one checkbox is checked
            setValidationCheckbox(null);
            return true;
        }
    }

    //get proposal data api
    const getProposalDetail = async (param) => {
        let data = await getProposalData(param);
            //checkbox array of objects
        const checkboxes = [
            { id: 1, label: 'Chemicals added from asp’s inventory', value: data?.chemicals_added_from_asp_inventory },
            { id: 2, label: 'Customer supplies chemicals/supplement from asp as needed*', value: data?.chemicals_supplies_from_asp_as_needed },
        ];

        formData.checkedList =  checkboxes;
        setProposalData(data);
        setFormData({ ...formData, ownerName: data?.client_name , franchiseOwnerName: data?.field_office?.name });
        loadBase64Image(data?.customer_signature)
        loadBase64ImageFranchise(data?.owner_signature)
        setLoader(false);
    };

    //Fetching get proposal data
    useEffect(() => {
        setLoader(true);
        setUuid(params.uuid);
        getProposalDetail(params.uuid);
    }, []);

    //on update signature api
    const onUpdateSignature = async () => {
        let payload = {
            customer_signature: ownerSignature,
            owner_signature: franchiseOwnerSignature,
            chemicals_added_from_asp_inventory: formData.checkedList[0].value,
            chemicals_supplies_from_asp_as_needed: formData.checkedList[1].value,
            owner_name: formData.franchiseOwnerName,
            customer_name: formData.ownerName,
        }
        const response = await updateProposalSignature(payload, uuid);
        setSignature(response);
    }

    //proposal status Check
    const PROPOSAL_STATUS_CHECK = {
        accepted: 'accepted',
        declined: 'declined',
        needs_review: 'needs_review',
        in_progress: 'in_progress',
        active: 'active',
        enquiry_received: 'enquiry_received',
        sent : 'sent',
        expired: 'expired',
        closed: 'closed',
        deactivated: 'deactivated'
    };

    //on update proposal status api
    const onUpdateProposal = async (status) => {
        let payload = {
            proposal_status: status,
            proposal_uuid: [uuid],
        }
        const response = await updateProposalStatus(payload);
        setStatus(response);     
        window.open("about:blank", "_self");
        window.close();  
    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    //handle Update Proposal With Update Signature
    const handleUpdateProposalWithUpdateSignature = async(status) => {
        const validation = validationCheckboxes();
        const isValid = await validateForm();
        if (isValid && validation) {
            onUpdateSignature();
            const updateStatus = await onUpdateProposal(status);
            message.success(`Porposal Status is ${status}`) 
        }
    }

    //input fields
    const nameInputField = [
        {
            id: 1, title: 'Owner Name', placeholder: 'Owner Name', name: "ownerName", value: formData.ownerName, onChange: (e) => setFormData({ ...formData, ownerName: e.target.value })
        },

        {
            id: 2, title: 'Franchise Owner Name', placeholder: 'Franchise Owner Name', name: "franchiseOwnerName", value: formData.franchiseOwnerName, onChange: (e) => setFormData({ ...formData, franchiseOwnerName: e.target.value })
        }
    ]

    //Billing table
    const billingColumns = [
        {
            title: 'Proposal#',
            dataIndex: 'proposalNumber',
            key: 'proposalNumber',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Rate',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const billingData = [
        {
            key: '1',
            proposalNumber: proposalData?.proposal_number,
            date: proposalData?.proposal_date,
            total: `$ ${proposalData?.proposal_price}`,
        },
    ];

    //Amount-table
    const amountColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const amountData = [
        {
            key: '1',
            date: proposalData?.proposal_date,
            description:<span> 
                            <b>Service:</b> {proposalData?.service_type} <br />
                            <b>Rate:</b> {proposalData?.is_flat_rate === true ? 'Flat' : 'Standard'} <br />
                            <b>Frequency:</b> {proposalData?.frequency} <br />
                            <b>Notes:</b> {proposalData?.notes}
                        </span>,
            rate: `$ ${proposalData?.proposal_price}`,
            amount: `$ ${proposalData?.total_price}`,
        },
    ];

    const subtotalAmount = proposalData?.additional_work?.reduce((acc, item) => acc + parseFloat(item.total), 0);
    const totalAmount = subtotalAmount + proposalData?.sales_tax;

    // Additional work table
    const additionalWorkColumns = [
        {
            title: 'Repair Code',
            dataIndex: 'repair_code',
            key: 'repair_code',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Unit Cost',
            dataIndex: 'unit_cost',
            key: 'unit_cost',
        },
        {
            title: 'QTY',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Amount',
            dataIndex: 'total',
            key: 'total',
        },
    ];
    const additionalWorkData = proposalData?.additional_work;

    return (
        <div className="proposal">
            {loader && <Loader />}
            <img src={headerImage} className="w-100" alt="proposal-header" />

            <section className="proposal-container">
                {/* proposal address description section*/}
                <section className="proposal-desc">
                    <div className="proposal-address">
                        <p>{proposalData?.field_office?.address} {proposalData?.field_office?.city} {proposalData?.field_office?.state}, {proposalData?.field_office?.zip_code}</p>
                        <p className="mb-15">{proposalData?.field_office?.phone}</p>
                        <p className="mb-15">{proposalData?.field_office?.email}</p>
                        <p className="mb-15">www.ASPpoolco.com</p>
                    </div>

                    <button type="button"><img src="/media/asp-logo.png" alt="" /></button>
                </section>

                {/* proposal address description with billing table section*/}
                <section className="proposal-desc mt-50 align-center">
                    <div className="proposal-billing-address">
                        <p className="mb-15"><b>Billing/Service Address</b></p>
                        <p>{proposalData?.service_address?.formatted_address}</p>
                        <p>{proposalData?.client_phone_number}</p>
                        <p>{proposalData?.client_email_address}</p>
                    </div>

                    <div className="billing-table">
                        <Table columns={billingColumns} dataSource={billingData} pagination={false} />
                    </div>
                </section>

                {/* proposal application section*/}
                <section className="proposal-app">
                    <h2 className="proposal-app-title">This Proposal Expires on {proposalData?.proposal_due_date}</h2>
                    <p className="proposal-app-notes border-bottom">Notes</p>
                    <div className="proposal-application">
                        <p className="proposal-letter">
                            <b>Hi {proposalData?.client_name},</b>
                            <br />
                            {proposalData?.email_notes}
                            <br />If you have any questions, feel free to give us a call (866)-253-0455 or franchiseOwnerName
                            us at info@aswimmingpool.com.
                            <br />
                            <br />
                            <b>Thank you,</b>
                            <br />
                            {proposalData?.field_office?.name}
                            <br />
                            <br />
                            Please review and sign below if everything is agreed upon. We look forward to
                            hearing from you!
                        </p>

                        <div className="amount-table">
                            <Table columns={amountColumns} dataSource={amountData} pagination={false} />
                        </div>

                        <div className="additional-work-table mt-20">
                            <p className="proposal-app-notes mb-0">Additional Work</p>

                            <Table columns={additionalWorkColumns} dataSource={additionalWorkData} pagination={false} />

                            <div className="proposal-count mt-50">
                                <div className="dp-flex mb-10 grid-col-2">
                                    <b className="amount">Subtotal</b>
                                    <b>${subtotalAmount}</b>
                                </div>

                                <div className="dp-flex grid-col-2">
                                    <b className="amount">SALES TAX</b>
                                    <b>${proposalData?.sales_tax}</b>
                                </div>

                                <div className="dp-flex mt-20 grid-col-2">
                                    <b className="amount">Total</b>
                                    <b>{totalAmount}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* proposal service agreement section*/}
                <section className="proposal-service-agreement">
                    <p className="proposal-app-notes border-bottom">Service agreements</p>

                    <form className="proposal-form relative" onSubmit={handleSubmit}>
                        <p>
                            <b>Flat Rate Billing</b>
                            <p>
                                With flat rate billing, we charge a flat rate for our program,
                                including basic chemicals to adjust chlorine, ph, and alkalinity
                                to not include salt. specialty chemicals such as algaecide and phosphate
                                remover are not included in this fee & are sold only if needed.
                            </p>
                        </p>

                        <p>
                            <b>Standard Billing</b>
                            <p>
                                The cost of any chemicals added to the swimming pool. your total bill
                                will vary depending on the chemicals added to maintain the proper chemical
                                balances. this fee does not include chemicals.
                            </p>
                        </p>

                        <>
                            {formData?.checkedList?.map((checkbox) => (
                                <div key={checkbox.id}>
                                    <input
                                        type="checkbox"
                                        value={checkbox.value}
                                        name="checkedList"
                                        checked={checkbox.value}
                                        onChange={() => handleCheckboxChange(checkbox.id)}
                                        disabled={(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.declined) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.accepted) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.deactivated) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.closed) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.expired)}
                                    />
                                    <label className="ml-5 checkbox-label">{checkbox.label}</label>
                                </div>
                            ))}
                            {renderError('checkedList')}
                            <div className="error">{validationCheckbox}</div>
                        </>

                        <p>
                            <b>*NOTE:</b>
                            If the customer supplies all chemicals and chemicals needed are not available,
                            asp will use our chemicals and customer will be billed accordingly.
                        </p>

                        <p>
                            <b>Service Agreement</b>
                            <p>
                                {proposalData?.service_agreement_notes}
                            </p>
                        </p>

                        <p className="mb-0">
                            <b>Client Approval</b>
                        </p>

                        <div className='dp-flex justity-btwn align-center'>
                            <div className="proposal-franchise-owner-signature width-40-percent">
                                    <div className="dp-flex justity-btwn align-center">
                                        <p className="name mb-0">Owner Signature</p>
                                        <div className="dp-flex align-center">
                                            <span>Date</span>
                                        </div>
                                    </div>
                                    <div className={`proposal-input mt-5 ${(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.declined) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.accepted) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.deactivated) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.closed) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.expired) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.needs_review) ? 'signature-disabled' : null}`}>
                                        <div className="dp-flex align-center relative">
                                            <SignatureCanvas
                                                penColor="Black"
                                                canvasProps={{ className: 'signature' }}
                                                ref={ownerSignRef}
                                                onEnd={handleOwnerSignature}
                                            />
                                            {(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.in_progress) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.active) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.enquiry_received) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.sent) || (proposalData?.proposal_status === null) ? 
                                            <div onClick={clearOwnerSignature} className="clear-signature"><i className="las la-sync"></i></div>
                                            :  null 
                                            }
                                        </div>
                                    </div>
                            </div>

                            <div className="proposal-franchise-owner-signature width-40-percent">
                                    <div className="dp-flex justity-btwn align-center">
                                        <p className="name mb-0">Franchise Owner Signature</p>
                                        <div className="dp-flex align-center">
                                            <span>Date</span>
                                        </div>
                                    </div>
                                    <div className={`proposal-input mt-5 ${(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.declined) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.accepted) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.deactivated) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.closed) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.expired) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.needs_review) ? 'signature-disabled' : null}`}>
                                        <div className="dp-flex align-center relative">
                                            <SignatureCanvas
                                                penColor="Black"
                                                canvasProps={{ className: 'signature' }}
                                                ref={franchiseOwnerRef}
                                                onEnd={handleFranchiseOwnerSignature}
                                            />
                                            {(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.in_progress) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.active) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.enquiry_received) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.sent) || (proposalData?.proposal_status === null) ? 
                                                <div onClick={clearFranchiseOwnerSignature} className="clear-signature"><i className="las la-sync"></i></div> 
                                                : 
                                                null 
                                            }
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="proposal-information dp-flex justity-btwn align-center mt-20">
                            {nameInputField.map((data) => (
                                <div className="proposal-franchise-owner-name width-40-percent" key={data.id}>
                                    <div className="dp-flex justity-btwn align-center">
                                        <p className="name mb-0">{data.title}</p>
                                        <div className="dp-flex align-center">
                                            <span>Date</span>
                                        </div>
                                    </div>
                                    <div className="proposal-input mt-5 relative">
                                        <Input
                                            placeholder={data.placeholder}
                                            type="text"
                                            name={data.name}
                                            value={data.value}
                                            onChange={data.onChange}
                                            disabled={(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.declined) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.accepted) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.deactivated) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.closed) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.expired) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.needs_review)}
                                        />
                                        <div className="absolute">{renderError(data.name)}</div>
                                    </div> 
                                </div>
                            ))}
                        </div>

                        {(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.in_progress) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.active) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.enquiry_received) || (proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.sent) || (proposalData?.proposal_status === null) ? 
                            <div className="proposal-action-btns">
                                <button className="btn-outline" onClick={() => handleUpdateProposalWithUpdateSignature(PROPOSAL_STATUS_CHECK.declined)}>Decline</button>
                                <button className="btn btn-warning" onClick={() => handleUpdateProposalWithUpdateSignature(PROPOSAL_STATUS_CHECK.needs_review)}>Need to Review</button>
                                <button className="btn btn-warning" onClick={() => handleUpdateProposalWithUpdateSignature(PROPOSAL_STATUS_CHECK.accepted)}>Accept & Submit</button>
                            </div> 
                        : null}

                        {(proposalData?.proposal_status === PROPOSAL_STATUS_CHECK.needs_review) ? 
                        <div className="proposal-action-btns">
                            <button className="btn-outline" onClick={() => handleUpdateProposalWithUpdateSignature(PROPOSAL_STATUS_CHECK.declined)}>Decline</button>
                            <button className="btn btn-warning" onClick={() => handleUpdateProposalWithUpdateSignature(PROPOSAL_STATUS_CHECK.accepted)}>Accept & Submit</button>
                        </div> 
                        : null}
                    </form>
                </section>
            </section>
        </div>)
}

export default Proposal
