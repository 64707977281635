import React, { useEffect, useState, useCallback } from "react";
import { Select } from "antd";
import { orderBy } from "lodash";
import { getFranchiseListAll } from "../../../Action/Franchise";

const FranchiseSelector = ({
  handleChange,
  defaultValue,
}) => {

  localStorage.setItem("Auth", true);
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;
  const [franchiseList, setFranchiseList] = useState(
    isCorporate ? [] : userInfo?.user?.field_offices
  );

  useEffect(() => {
    const getFranchiseList = async () => {
      const data = await getFranchiseListAll();
      setFranchiseList(data ? data : []);
    };
    getFranchiseList();
  }, []);

  return (
    <>
      <Select value={defaultValue} onChange={(e) => handleChange(e)} disabled={franchiseList.length === 1}>
          <Select.Option key={"franchiseAll"} value={"all"}>
            {"All"}
          </Select.Option>
          {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
            <Select.Option key={option?.uuid} value={option?.uuid}>
              {option?.name}
            </Select.Option>
          ))}
        </Select>
    </>
  );
};

export default FranchiseSelector;
