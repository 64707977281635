import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Radio,
  Row,
  Col,
  Spin,
  Modal,
  Input,
  DatePicker,
  TimePicker,
  message,
  Tooltip,
  notification,
  Checkbox,
  Dropdown,
  Menu,
  Alert,
} from "antd";
import {
  sendOfflineRequests,
  saveOfflineRequest,
  init,
} from "../../helper/apiOfflineHelper";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  EllipsisOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { Detector } from "react-detect-offline";
import { useNavigate } from "react-router-dom";
import { CalendarToday, Button } from "@mobiscroll/react";
import * as yup from "yup";
import moment from "moment-timezone";
import _ from "lodash";
import {
  getAllTasks,
  getAllServices,
  deleteService,
  rescheduleService,
  rescheduleRecurringService,
  startJobTime,
  getRouteOptimization,
  updateTaskInfo,
  reassignTaskInfo,
} from "../../../Action/Calendar";
import {
  getCustomerDetail,
  getCommercialCustomer,
} from "../../../Action/Accounting";
import {
  getSaveMaintenencePoolReport,
  getUploadImage,
} from "../../../Action/Customer/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import FilterHeader from "./FilterHeader.Container";
import FilterHeaderMobile from "./FilterHeaderMobile.Container";
import FilterHeaderStatusMobile from "./FilterHeaderStatusMobile.Container";
import RouteOptimization from "./RouteOptimization.Container";
import useResponsiveContainer from "./useResponsiveContainer";
import ToggleAddList from "../PopupView/ToggleAddList";
import { colorStatus } from "../Common/selectColor";
import { useCalendar } from "../../Hooks/Calendar.Hook";
import { exportData2Calendar } from "../../../Utils/exportCalendar";
import location from "../../../Assets/icons/location.svg";
import calendarLeft from "../../../Assets/icons/calendar-left.svg";
import calendarRight from "../../../Assets/icons/calendar-right.svg";
import poolIcon from "../../../Assets/icons/pool_icon.svg";
import poolIconBlack from "../../../Assets/icons/pool_icon_black.svg";
import ActivityLogModal from "../Modals/ActivityLog/ActivityLogs.Modal";
import taskIcon from "../../../Assets/icons/task.svg";
import { useEventBus } from "../../../Components/EventBus/useEventBus";
import { DesktopEventCalendarContainer } from "./Desktop.Eventcalendar.Calendar.Container";
import { MobileEventCalendarContainer } from "./Mobile.Eventcalendar.Calendar.Container";
import { PopupCalendarContainer } from "./Popup.Calendar.Container";
import { ReassignJobModalCalendarContainer } from "./ReassignJob.Modal.Calendar.Container";
import { DeleteJobModalCalendarContainer } from "./DeleteJob.Modal.Calendar.Container";
import { WorkOrderFormModalCalendarContainer } from "./WorkOrderForm.Modal.Calendar.Container";
import { PoolCharactersticsModalCalendarContainer } from "./PoolCharacteristics.Modal.Calendar.Container";
import { RouteOptimizationModalCalendarContainer } from "./RouteOptimization.Modal.Calendar.Container";
import {
  setServiceTaskData,
  setSelectedTaskData,
  toggleTaskModal,
  setTaskFlagFromEdit,
  setDisableTechnicianTask,
} from "../../../Store/technicianTaskDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import AddTask from "../../../Calendar/Components/Modals/AddTask.Modal";
import {
  editDuration,
  addDuration,
} from "../../../Store/serviceOrderDetailDataSlice";
import { parse } from "query-string";

const calendarColorsExample = [
  {
    background: "#f1f1f1",
    recurring: {
      repeat: "daily",
      until: moment().subtract(1, "days"),
    },
  },
  {
    date: moment(),
    background: "#fbedd0",
  },
];

const openNotificationWithIcon = (type, title, text) => {
  notification[type]({
    message: title,
    description: text,
    icon:
      type === "success" ? (
        <CheckCircleFilled
          style={{
            color: "#52c41a",
          }}
        />
      ) : (
        <CloseCircleFilled
          style={{
            color: "#ff4d4f",
          }}
        />
      ),
  });
};
const CalendarContainers = () => {
  const dispatch = useDispatch();
  useEventBus("CUSTOMER_CREATED", (event) => {
    getWorkOrder();
  });

  useEventBus("TASK_CREATED", (event) => {
    getWorkOrder();
  });

  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });
  //Verify if the user has franchise_owner role due to dual role users
  const is_fo = userInfo?.user?.user_roles?.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );

  const role = is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
    ? "lead_tech"
    : userInfo?.user?.user_roles[0]?.name;

  const dualRole = userInfo?.user?.user_roles?.find(
    (i) => i.name === ("field_tech" || "lead_tech")
  );
  //technician_permissions
  const navigate = useNavigate();
  // todo unassign technician
  const { franchise, technician, status, offline, setOffline } = useCalendar();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [myEvents, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [start_date, setStart_date] = useState(
    window.innerWidth > 768
      ? moment().startOf("week").format("YYYY-MM-DD")
      : moment().startOf("day").format("YYYY-MM-DD")
  );
  const [end_date, setEnd_date] = useState(
    window.innerWidth > 768
      ? moment().endOf("week").format("YYYY-MM-DD")
      : moment().endOf("day").format("YYYY-MM-DD")
  );
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [disableRescheduleBtn, setDisableRescheduleBtn] = useState(true);
  const [rescheduleTechnician, setRescheduleTechnician] = useState({
    value: null,
    label: null,
  });
  const [rescheduleTimeSelection, setRescheduleTimeSelection] = useState(null);
  const [rescheduleDate, setRescheduleDate] = useState(null);
  const [rescheduleRecurring, setRescheduleRecurring] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [woModalOpen, setWOModalOpen] = useState(false);
  const [pcModalOpen, setPCModalOpen] = useState(false);
  const [optModalOpen, setOptModalOpen] = useState(false);
  const [activityModalOpen, setActivityModalOpen] = useState(false);

  const [bgColor, setBgColor] = React.useState("");
  const [anchor, setAnchor] = React.useState(null);
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const timerRef = React.useRef(null);
  const [view, setView] = useState(window.innerWidth > 768 ? "week" : "day");
  const shouldOpenPopup = React.useRef(true);
  const [optionsSelector, setOptionsSelector] = useState([
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
  ]);
  const [viewData, setViewData] = useState(
    window.innerWidth > 768
      ? {
          calendar: { type: "week", labels: "all" },
        }
      : {
          agenda: {
            type: "day",
            startTime: "00:00",
            endTime: "23:59",
            size: 1,
            days: false,
          },
        }
  );
  const [viewMobileData, setViewMobileData] = useState(
    window.innerWidth > 768
      ? {
          calendar: {
            type: "week",
            labels: false,
          },
          agenda: {
            type: "week",
          },
        }
      : {
          agenda: {
            type: "day",
            startTime: "00:00",
            endTime: "23:59",
            size: 1,
          },
        }
  );

  const viewp = React.useMemo(() => {
    return {
      calendar: { type: "month" },
      agenda: { type: "week" },
    };
  }, []);

  const [currentDate, setCurrentDate] = React.useState(moment());
  const myInvalid = [
    {
      recurring: {
        repeat: "daily",
        until: moment().subtract(1, "days"),
      },
    },
  ];
  const [isMobile, isDesktopOrLaptop] = useResponsiveContainer();
  const [startLocation, setStartLocation] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
  });
  const [stopLocation, setStopLocation] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
  });
  const [errors, setErrors] = useState({});
  const roleDetails = userInfo && userInfo?.user?.user_roles[0]?.name;
  const leadTechnician = userInfo && userInfo?.user?.is_lead_technician;

  useEffect(() => {
    if (franchise?.value && technician?.value) {
      // todo agregar cuando no hay status
      getWorkOrder();
      if (technician?.start_location && technician?.start_location.address)
        setStartLocation(technician.start_location);
      else if (franchise?.address) setStartLocation(franchise.address);
      else
        setStartLocation({
          address: "",
          city: "",
          state: "",
          zip_code: "",
        });

      if (technician?.stop_location && technician?.stop_location.address)
        setStopLocation(technician.stop_location);
      else if (franchise?.address) setStopLocation(franchise.address);
      else
        setStopLocation({
          address: "",
          city: "",
          state: "",
          zip_code: "",
        });
    } else {
      setEvents([]);
      setLoading(false);
      setStopLocation({
        address: "",
        city: "",
        state: "",
        zip_code: "",
      });
      setStartLocation({
        address: "",
        city: "",
        state: "",
        zip_code: "",
      });
    }
  }, [franchise, technician, start_date, end_date, offline]);

  useEffect(() => {
    if (rescheduleTechnician?.value) {
      setDisableRescheduleBtn(false);
      return;
    }

    if (rescheduleDate) {
      setRescheduleRecurring(false);
      setDisableRescheduleBtn(false);
      return;
    }
    setDisableRescheduleBtn(true);
  }, [rescheduleTechnician, rescheduleDate]);

  //Use Effect to refresh the event list
  //Flag to know when fire the useEffect
  const [serviceSuccess, setServiceSuccess] = useState(false);

  useEffect(() => {
    dispatch(setServiceTaskData(myEvents));
    init();
  }, [myEvents]);

  useEffect(() => {
    if (serviceSuccess === true) {
      getWorkOrder();
      setServiceSuccess(false);
    }
  }, [serviceSuccess]);

  const getUsers = async function (offset = 0) {
    let franchise_data = "";
    if (franchise?.label === "all") {
      franchise?.all?.forEach((row, index) => {
        let data = row.uuid;
        if (index < franchise?.all.length - 1) data += ",";
        franchise_data += data;
      });
    } else {
      franchise_data = franchise.value;
    }
    if (offline !== undefined) {
      if (offline) {
        let dataEvents = localStorage.getItem("events");
        return JSON.parse(dataEvents);
      } else {
        let mergeResults = [];
        try {
          const serviceData = await getAllServices({
            start_date: start_date,
            end_date: end_date,
            field_office_uuid: franchise_data,
            tech_uuid: technician?.value,
            tech_unassigned: technician?.value === "unassign" ? true : false,
            limit: 1000,
            offset: offset,
          });
          const taskData = await getAllTasks({
            start_date: start_date,
            end_date: end_date,
            field_office_uuid: franchise_data,
            tech_uuid: technician?.value,
          });
          mergeResults = [...serviceData.results, ...taskData.results];

          let dayEvents = [];
          if (!moment().isBetween(moment(start_date), moment(end_date))) {
            dayEvents = [...mergeResults];
          } else {
            dayEvents = mergeResults.filter(
              (event) =>
                moment(event.scheduled_appointment).format("YYYY-MM-DD") ===
                moment().format("YYYY-MM-DD")
            );
          }
          for (let event of dayEvents) {
            if (event.job_forms[0].form_name === "Maintenance Pool Report") {
              const formID = event.job_forms[0].form_url.substring(
                event.job_forms[0].form_url.lastIndexOf("/") + 1
              );
              const formData = await getSaveMaintenencePoolReport(formID);
              const formImages = await getUploadImage(formID);
              event.formData = formData;
              event.formImages = formImages;
              event.formID = formID;
              console.log(formID, formData);
            }
          }
          localStorage.setItem("events", JSON.stringify(dayEvents));

          return mergeResults;
        } catch (error) {
          setOffline(true);
          _handleChangeView({ target: { value: "day" } });
          setOptionsSelector((state) =>
            state.map((item) => {
              if (item.value !== "day") {
                return { ...item, disabled: true };
              }
              return item;
            })
          );
          let dataEvents = localStorage.getItem("events");
          return JSON.parse(dataEvents);
        }
      }
    } else {
      let dataEvents = localStorage.getItem("events");
      return JSON.parse(dataEvents);
    }

    //const serviceTaskData = await Promise.all([serviceData, taskData]);
    //let serviceTaskDataCombine = [...serviceTaskData[0].results, ...serviceTaskData[1].results];
    //return serviceTaskDataCombine;
  };

  const getEntireUserList = async function (offset = 0) {
    const reply = await getUsers(offset);
    console.log("Retreiving data from API for page : " + offset);
    if (reply?.count > offset + 100) {
      return reply?.results?.concat(await getEntireUserList(offset + 100));
    } else {
      return reply?.results;
    }
  };

  const getWorkOrder = async () => {
    setEvents([]);
    setLoading(true);
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    let reply = await getUsers();
    if (reply.length > 0) {
      reply = reply.sort((eventA, eventB) => {
        const time1 = new Date(
          eventA.scheduled_appointment
            ? eventA.scheduled_appointment
            : eventA.scheduled_date
        );
        const time2 = new Date(
          eventB.scheduled_appointment
            ? eventB.scheduled_appointment
            : eventB.scheduled_date
        );
        return time1.getTime() - time2.getTime();
      });
    }
    setEvents(
      reply
        ? reply.map((item) => ({
            start: item?.scheduled_appointment || item?.scheduled_date,
            end: item?.scheduled_appointment || item?.scheduled_date,
            title: item?.work_order_name || item?.task_name,
            status: item?.status,
            color: colorStatus[item?.status] ?? "#7b7a7a",
            serviceType: item?.servicetype || "Task",
            technician: item?.technician || item?.technician?.name,
            technician_uuid: item?.technician_uuid || item?.technician?.uuid,
            technicianData: item?.technician,
            work_order_uuid: item?.work_order_uuid || item?.uuid,
            job_forms: item?.job_forms || null,
            special_notes: item?.special_notes || item?.customer?.special_notes,
            address: item?.service_address || item?.address,
            edit_work_order_url: item?.edit_work_order_url,
            editable: item?.status !== "complete" ? true : false,
            client_type: item?.client_type || item?.customer?.customer_type,
            client_uuid: item?.client_uuid || item?.customer?.uuid,
            client_name: item?.client_name || null,
            is_invoiced: item?.is_invoiced || null,
            permissionset: item?.permissionset || null,
            start_job_datetime: item?.start_job_datetime || null,
            end_job_datetime: item?.end_job_datetime || null,
            type: item?.is_service ? "Service" : "Task",
            customer: item?.customer,
            task_category: item?.task_category || null,
            task_description: item?.description || null,
            duration: item?.duration || item?.service_duration,
            frequency_settings: item?.frequency_settings || null,
            pool_characteristic: item?.pool_characteristic || null,
            is_reassign_future_tasks: item?.is_reassign_future_tasks,
            workType: item?.worktype || "",
            worksubtype: item?.worksubtype || null,
            service_duration:
              item?.service_duration ||
              franchise_data?.data?.default_job_duration,
            is_recurring: item?.is_recurring,
            phone_number: item?.phone_number || item?.customer?.phone_number,
          }))
        : []
    );
    setLoading(false);
  };

  //callback Function
  const handlePoolCancel = (cancelBtnResponse) => {
    setPCModalOpen(cancelBtnResponse);
  };

  const handleWOSave = (saveWOResponse) => {
    setWOModalOpen(saveWOResponse);
  };

  const _handleChangeView = ({ target: { value } }) => {
    setOpen(false);
    if (!JSON.parse(localStorage.getItem("offline"))) {
      setEvents([]);
    }
    let tmpView;
    let tmpMobileView;
    switch (value) {
      case "month":
        tmpView = {
          calendar: { type: "month", labels: 1 },
        };
        tmpMobileView = {
          calendar: {
            type: "month",
            labels: false,
          },
          agenda: {
            type: "month",
          },
        };
        break;
      case "week":
        tmpView = {
          calendar: { type: "week", labels: "all" },
        };
        tmpMobileView = {
          calendar: {
            type: "week",
            labels: false,
          },
          agenda: {
            type: "week",
          },
        };
        break;
      default:
        //Day
        tmpView = {
          agenda: {
            type: "day",
            startTime: "00:00",
            endTime: "23:59",
            size: 1,
            days: false,
          },
        };
        tmpMobileView = {
          agenda: {
            type: "day",
            startTime: "00:00",
            endTime: "23:59",
            size: 1,
          },
        };
        break;
    }
    setViewData(tmpView);
    setViewMobileData(tmpMobileView);
    setView(value);
    setStart_date(moment(currentDate).startOf(value).format("YYYY-MM-DD"));
    setEnd_date(moment(currentDate).endOf(value).format("YYYY-MM-DD"));
  };

  const navigatePage = useCallback(
    (prev) => {
      setOpen(false);
      let start_date;
      let end_date;
      setCurrentDate((date) => {
        if (prev) {
          start_date =
            view === "week"
              ? moment(date)
                  .subtract(1, view)
                  .startOf(view)
                  .format("YYYY-MM-DD")
              : view === "day"
              ? moment(date).subtract(1, "days").format("YYYY-MM-DD")
              : moment(date)
                  .subtract(1, view)
                  .startOf(view)
                  .format("YYYY-MM-DD");
          end_date =
            view === "week"
              ? moment(date).subtract(1, view).endOf(view).format("YYYY-MM-DD")
              : view === "day"
              ? moment(date).subtract(1, view).format("YYYY-MM-DD")
              : moment(date).subtract(1, view).endOf(view).format("YYYY-MM-DD");

          setStart_date(start_date);
          setEnd_date(end_date);
          return moment(date).subtract(1, view).startOf(view).toDate();
        }

        start_date =
          view === "week"
            ? moment(date)
                .weekday(0)
                .add(1, view)
                .startOf(view)
                .format("YYYY-MM-DD")
            : view === "day"
            ? moment(date).add(1, view).format("YYYY-MM-DD")
            : moment(date).add(1, view).startOf(view).format("YYYY-MM-DD");

        end_date =
          view === "week"
            ? moment(date).add(1, view).endOf(view).format("YYYY-MM-DD")
            : view === "day"
            ? moment(date).add(1, view).format("YYYY-MM-DD")
            : moment(date).add(1, view).endOf(view).format("YYYY-MM-DD");

        setStart_date(start_date);
        setEnd_date(end_date);
        return moment(date).add(1, view).startOf(view).toDate();
      });
    },
    [view]
  );

  const onSelectedDateChange = (event) => setCurrentDate(event.date);

  const renderLabelContent = useCallback((data) => {
    return (
      <>
        <div className="md-label-content">
          {data?.serviceType ?? "service_type"}
        </div>
      </>
    );
  });

  const onEventClick = React.useCallback((args) => {
    if (!shouldOpenPopup.current) {
      return;
    }
    const event = args?.event;
    setBgColor(event.color);
    setCurrentEvent(event);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setAnchor(args.domEvent.currentTarget);

    setOpen(true);
    shouldOpenPopup.current = true;
  }, []);

  const renderCalendarNav = () => {
    if (view === "day") {
      return moment(currentDate).format("MMM DD, yyyy");
    }
    if (view === "week") {
      return (
        moment(currentDate).startOf("week").format("MMMM ") +
        moment(currentDate).endOf("week").format("YYYY")
      );
    }
    return moment(currentDate).format("MMMM YYYY");
  };

  const exportCalendar = async () => {
    await exportData2Calendar(
      myEvents.map((item) => ({
        start: item?.scheduled_appointment,
        title: item?.work_order_name,
        description: item?.servicetype,
      })),
      "Calendar.ics"
    );
  };

  const onChangeCalendar = (date) => {
    if (date) {
      setCurrentDate(moment(date).startOf("day"));
    } else {
      setCurrentDate(moment().startOf("day"));
    }
    setStart_date(moment(date).startOf(view).format("YYYY-MM-DD"));
    setEnd_date(moment(date).endOf(view).format("YYYY-MM-DD"));
  };

  const customWithNavButtons = () => {
    return (
      <Row
        justify="space-between"
        style={{ width: "100%", position: "relative" }}
        align="middle"
      >
        <Col md={9} xs={24} className="md-custom-header-view">
          {role !== "field_tech" && view === "day" && isMobile ? (
            <Row
              justify="start"
              align="middle"
              style={{ height: "100%", padding: "0px 5px" }}
            >
              <Col sm={5} xs={10}>
                <Input
                  type="text"
                  value={
                    startLocation.address &&
                    `${startLocation?.address}, ${startLocation?.city}, ${startLocation?.state}, ${startLocation?.zip_code} `
                  }
                  title={
                    startLocation.address &&
                    `${startLocation?.address}, ${startLocation?.city}, ${startLocation?.state}, ${startLocation?.zip_code} `
                  }
                  placeholder="Start Location"
                  disabled
                />
              </Col>
              <Col sm={5} xs={10}>
                <Input
                  type="text"
                  value={
                    stopLocation?.address &&
                    `${stopLocation?.address}, ${stopLocation?.city}, ${stopLocation?.state}, ${stopLocation?.zip_code} `
                  }
                  title={
                    stopLocation.address &&
                    `${stopLocation?.address}, ${stopLocation?.city}, ${stopLocation?.state}, ${stopLocation?.zip_code} `
                  }
                  placeholder="Stop Location"
                  disabled
                />
              </Col>
              <Col sm={3} xs={3} style={{ marginRight: "0px" }}>
                <Button
                  className="calendarViewAddBtn responsive-icon"
                  style={{ padding: "0px", marginLeft: "0px" }}
                  title="Route Optimization"
                  disabled={
                    technician?.value === "unassign" ||
                    new Date(moment(currentDate).format("MM/DD/YYYY")) <
                      new Date(moment().format("MM/DD/YYYY")) ||
                    offline
                  }
                  onClick={() => {
                    setOptModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faMap}></FontAwesomeIcon>
                </Button>
              </Col>
            </Row>
          ) : null}
          <Row
            justify="start"
            align="middle"
            style={{ height: "100%", padding: "0px 5px" }}
          >
            <Col>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={optionsSelector}
                onChange={_handleChangeView}
                value={view}
              />
            </Col>
            <Col>
              <span
                onClick={() => {
                  setOpen(false);
                  setStart_date(
                    moment()
                      .startOf(
                        viewData?.calendar ? viewData?.calendar.type : "day"
                      )
                      .format("YYYY-MM-DD")
                  );
                  setEnd_date(
                    moment()
                      .endOf(
                        viewData?.calendar ? viewData?.calendar.type : "day"
                      )
                      .format("YYYY-MM-DD")
                  );
                }}
                className={"calendar-today-mobile"}
              >
                <CalendarToday
                  className={
                    moment(currentDate).format("DD-MM-yyyy") ===
                    moment().format("DD-MM-yyyy")
                      ? "disabled"
                      : ""
                  }
                />
              </span>
            </Col>
            <Col>
              <DatePicker
                className={"datepicker-calendar"}
                popupClassName={"datepicker-calendar-popup"}
                format={"YYYY-MM-DD"}
                onChange={onChangeCalendar}
                showToday={false}
                style={{ width: "45px" }}
                value={moment(currentDate)}
                allowClear={false}
                disabled={offline}
              />
            </Col>
            {isMobile ? (
              <Col>
                <FilterHeaderStatusMobile setEvents={setEvents} />
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col md={10} xs={24} className="md-custom-header-nav">
          <Row justify="start" align="middle">
            <Col className={"calendar-today-desktop"}>
              <span
                onClick={() => {
                  setOpen(false);
                  setStart_date(
                    moment()
                      .startOf(
                        viewData?.calendar ? viewData?.calendar.type : "day"
                      )
                      .format("YYYY-MM-DD")
                  );
                  setEnd_date(
                    moment()
                      .endOf(
                        viewData?.calendar ? viewData?.calendar.type : "day"
                      )
                      .format("YYYY-MM-DD")
                  );
                }}
              >
                <CalendarToday
                  className={
                    moment(currentDate).format("DD-MM-yyyy") ===
                    moment().format("DD-MM-yyyy")
                      ? "disabled"
                      : ""
                  }
                />
              </span>
            </Col>
            <Col>
              <Button
                onClick={() => navigatePage(true)}
                variant="outline"
                className="md-custom-header-button"
                disabled={offline}
              >
                <img src={calendarLeft} alt="" />
              </Button>
            </Col>
            <Col className="md-custom-header-date">
              <span className="calendarDateHeader">{renderCalendarNav()}</span>
            </Col>
            <Col>
              <Button
                onClick={() => navigatePage(false)}
                variant="outline"
                className="md-custom-header-button"
                width={200}
                disabled={offline}
              >
                <img src={calendarRight} alt="" />
              </Button>
            </Col>
          </Row>
        </Col>

        <Col md={5} xs={24} className="md-custom-header-nav">
          <Row justify="end" align="middle">
            {view === "day" && role !== "field_tech" ? (
              <>
                <Col>
                  {isDesktopOrLaptop ? (
                    <Button
                      className="calendarViewAddBtn"
                      disabled={
                        technician?.value === "unassign" ||
                        new Date(moment(currentDate).format("MM/DD/YYYY")) <
                          new Date(moment().format("MM/DD/YYYY")) ||
                        offline
                      }
                      onClick={() => {
                        setOptModalOpen(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMap}
                        style={{ paddingRight: "10px" }}
                      ></FontAwesomeIcon>
                      Route Optimization
                    </Button>
                  ) : null}
                </Col>
              </>
            ) : (
              " "
            )}
            {!(roleDetails?.includes("field_tech") && !leadTechnician) ? (
              <>
                <Col>
                  {isDesktopOrLaptop ? (
                    <ToggleAddList
                      franchise={franchise}
                      isCalendar={true}
                      disabled={offline}
                    />
                  ) : null}
                </Col>
              </>
            ) : (
              " "
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const showRescheduleModal = (item) => {
    dispatch(setSelectedTaskData(item));
    setSelectedEvent(item);
    setOpen(false);
    setRescheduleModalOpen(true);
  };

  const hideRescheduleModal = () => {
    setRescheduleModalOpen(false);
  };

  const onCloseRescheduleModal = () => {
    setRescheduleTimeSelection(null);
    setRescheduleDate(null);
    setRescheduleRecurring(false);
    setRescheduleTechnician({ value: null, label: null });
  };

  const onStartJobTime = async () => {
    if (
      currentEvent?.technician_uuid !== undefined &&
      currentEvent?.technician_uuid !== null
    ) {
      try {
        if (offline) {
          saveOfflineRequest({
            name: "saveStartJobTime",
            api: `/service/startjobtime/`,
            method: "post",
            payload: {
              service_uuid: currentEvent?.work_order_uuid,
              start_date: moment().format("YYYY-MM-DD"),
              start_time: moment().format("HH:mm"),
            },
          });
          let dataEvents = JSON.parse(localStorage.getItem("events"));
          localStorage.setItem(
            "events",
            JSON.stringify(
              dataEvents.map((event) => {
                if (event.work_order_uuid === currentEvent?.work_order_uuid) {
                  return {
                    ...event,
                    start_job_datetime: `${moment().format(
                      "YYYY-MM-DD"
                    )}T${moment().format("HH:mm")}:00Z`,
                  };
                } else {
                  return event;
                }
              })
            )
          );
        } else {
          await startJobTime({
            service_uuid: currentEvent?.work_order_uuid,
            start_date: moment().format("YYYY-MM-DD"),
            start_time: moment().format("HH:mm"),
          });
        }
        setServiceSuccess(true);
        setOpen(false);
      } catch (error) {
        message.error("Could not start or complete job");
      }
    }
  };

  const onCompleteJobTime = async () => {
    if (
      currentEvent?.technician_uuid !== undefined &&
      currentEvent?.technician_uuid !== null
    ) {
      try {
        if (offline) {
          saveOfflineRequest({
            name: "saveEndJobTime",
            api: `/service/startjobtime/`,
            method: "post",
            payload: {
              service_uuid: currentEvent?.work_order_uuid,
              end_date: moment().format("YYYY-MM-DD"),
              end_time: moment().format("HH:mm"),
            },
          });
          let dataEvents = JSON.parse(localStorage.getItem("events"));
          localStorage.setItem(
            "events",
            JSON.stringify(
              dataEvents.map((event) => {
                if (event.work_order_uuid === currentEvent?.work_order_uuid) {
                  return {
                    ...event,
                    status: "complete",
                    end_job_datetime: `${moment().format(
                      "YYYY-MM-DD"
                    )}T${moment().format("HH:mm")}:00Z`,
                  };
                } else {
                  return event;
                }
              })
            )
          );
        } else {
          await startJobTime({
            service_uuid: currentEvent?.work_order_uuid,
            end_date: moment().format("YYYY-MM-DD"),
            end_time: moment().format("HH:mm"),
          });
        }
        setServiceSuccess(true);
        setOpen(false);
      } catch (error) {
        message.error("Could not complete job");
      }
    }
  };

  const updateTaskStatus = async (currData, setStatus) => {
    if (
      currentEvent?.technician_uuid !== undefined &&
      currentEvent?.technician_uuid !== null
    ) {
      try {
        await updateTaskInfo({
          task_uuid:
            currData?.type == "Task"
              ? currData?.work_order_uuid
              : currentEvent?.work_order_uuid,
          task_status: setStatus,
        });
        setServiceSuccess(true);
        setOpen(false);
        setDeleteModalOpen(false);
      } catch (error) {
        message.error("Something went wrong.");
      }
    }
  };

  const onRescheduleTask = async () => {
    try {
      await reassignTaskInfo({
        task_uuid: selectedEvent?.original?.work_order_uuid,
        reassign_to:
          rescheduleTechnician?.value == "unassign"
            ? "Not Assigned"
            : rescheduleTechnician?.value,
        reassign_date: rescheduleDate
          ? rescheduleDate.format("YYYY-MM-DD")
          : undefined,
        reassign_time: rescheduleTimeSelection
          ? rescheduleTimeSelection.format("HH:mm:ss")
          : "9:00:00",
        is_reassign_future_tasks: rescheduleRecurring,
      });
      openNotificationWithIcon(
        "success",
        "Reschedule Task:",
        selectedEvent.original.title
      );
      setRescheduleModalOpen(false);
      await getWorkOrder();
    } catch (error) {
      setRescheduleModalOpen(false);
      openNotificationWithIcon("error", "Could not remove event");
    }
  };

  const onRescheduleWorkOrder = async () => {
    try {
      if (rescheduleRecurring) {
        await rescheduleRecurringService({
          service_uuid: selectedEvent?.original?.work_order_uuid,
          technician_uuid:
            rescheduleTechnician?.value !== "unassign"
              ? rescheduleTechnician?.value
              : undefined,
        });
      } else {
        await rescheduleService({
          service_uuid: selectedEvent?.original?.work_order_uuid,
          date_to: rescheduleDate
            ? rescheduleDate.format("YYYY-MM-DD")
            : undefined,
          time_to: rescheduleTimeSelection
            ? rescheduleTimeSelection.format("HH:mm")
            : "9:00",
          technician_uuid:
            rescheduleTechnician?.value !== "unassign"
              ? rescheduleTechnician?.value
              : "not assigned",
        });
      }
      openNotificationWithIcon(
        "success",
        "Reschedule work order:",
        selectedEvent?.original?.title
      );
      setRescheduleModalOpen(false);
      await getWorkOrder();
    } catch (error) {
      setRescheduleModalOpen(false);
      openNotificationWithIcon("error", "Could not remove event");
    }
  };

  const showDeleteModal = (item) => {
    dispatch(setSelectedTaskData(item));
    setSelectedEvent(item);
    setOpen(false);
    setDeleteModalOpen(true);
  };
  const showActivityLogModal = (item) => {
    setSelectedEvent(item);
    setOpen(false);
    setActivityModalOpen(true);
  };

  const showWOModal = (item) => {
    /*if (window.innerWidth < 768) {
      window.open(item?.original?.job_forms?.[0]?.form_url.replace('https://crm.dev2.poolops.com', 'https://classy-strudel-0352a1.netlify.app'), "_blank");
      return;
    }*/
    setSelectedEvent(item);
    setOpen(false);
    setWOModalOpen(true);
  };

  const showPoolCharacteristicsModal = (item) => {
    setSelectedEvent(item);
    setOpen(false);
    setPCModalOpen(true);
  };

  const onDeleteWorkOrder = async () => {
    try {
      await deleteService(selectedEvent?.original?.work_order_uuid);
      openNotificationWithIcon(
        "error",
        "Delete work order:",
        selectedEvent?.original?.title
      );
      await getWorkOrder();
      setDeleteModalOpen(false);
    } catch (error) {
      message.error("Could not remove event");
      setDeleteModalOpen(true);
    }
  };

  const onChangeTimePicker = (time) => {
    setRescheduleTimeSelection(time);
  };

  const handleEditEvent = (all) => {
    const { event, oldEvent } = all;
    if (
      event?.status === "complete" ||
      moment(event?.start).isBefore(moment().subtract(1, "day"))
    )
      return;
    setRescheduleTimeSelection(moment(event.start));
    setRescheduleDate(moment(event.start));
    event.start = new Date(moment(oldEvent?.start ?? event.start));
    dispatch(setSelectedTaskData({ original: event }));
    setSelectedEvent({ original: event });
    setRescheduleModalOpen(true);
  };

  const openAccountOverview = async (data) => {
    if (offline) return;
    if (data?.client_type || data?.original?.client_type) {
      let record = {};
      setOpen(false);

      const customer_id = data?.client_uuid ?? data?.original?.client_uuid;
      if (
        data?.client_type === "residential" ||
        data?.original?.client_type === "residential"
      ) {
        record = await getCustomerDetail(customer_id); // get customer detail
      } else {
        record = await getCommercialCustomer(customer_id); // get customer detail
      }
      let profileUrl = data?.client_type
        ? data?.client_type
        : data?.original?.client_type;
      navigate(`/${profileUrl}/customer-detail`, {
        state: {
          id: record?.uuid,
          hear_about_us: record?.hear_about_us,
          customer_satisfaction_score: record?.customer_satisfaction_score,
          record: record,
        },
      });
    } else {
      message.error("Client type not available for this customer.", 5);
    }
  };

  const createItems = (data) => {
    let actionItems = [];
    if (data?.original?.type != "Task") {
      actionItems.push({
        label: (
          <span>
            <img src={poolIconBlack} style={{ marginRight: "5px" }} alt="" />
            Pool Characteristics
          </span>
        ),
        key: "pool_characteristics",
        disabled: offline,
      });
    }
    if (data?.original?.job_forms?.length > 0 || data?.job_forms?.length > 0) {
      actionItems.push({
        label: (
          <span>
            <span
              className="mbsc-font-icon mbsc-icon-file4"
              style={{ marginRight: "5px" }}
            ></span>
            Job Form
          </span>
        ),
        key: "job_form",
      });
    }
    if (data?.original?.edit_work_order_url && !data?.original?.is_invoiced) {
      actionItems.push({
        label: (
          <span>
            {" "}
            <span
              className={`mbsc-font-icon mbsc-icon-pencil mr-5 ${
                data?.original?.batch_invoice_processed ? "disabled" : ""
              }`}
            ></span>
            Edit Work Order
          </span>
        ),
        key: "work_order",
        disabled: offline,
      });
    }
    if (data?.original?.type == "Task" && data.original.status !== "complete") {
      actionItems.push({
        label: (
          <span>
            {" "}
            <span className="mbsc-font-icon mbsc-icon-pencil mr-5 "></span>
            Edit Task
          </span>
        ),
        key: "edit_task",
        disabled: offline,
      });
    }
    if (data?.original?.status !== "complete") {
      if (role === "field_tech" || role === "lead_tech") {
        if (
          (data?.original?.permissionset?.includes("assign_wo") ||
            data?.original?.permissionset?.includes("reschedule_wo")) &&
          (userInfo?.user?.technician_permissions?.reschedule_wo ||
            userInfo?.user?.technician_permissions?.assign_wo)
        ) {
          actionItems.push({
            label: (
              <span>
                <span
                  className="mbsc-font-icon mbsc-icon-line-calendar"
                  style={{ marginRight: "5px" }}
                ></span>
                Reschedule
              </span>
            ),
            key: "reschedule",
            disabled: offline,
          });
        }
        if (
          data?.original?.permissionset?.includes("delete_so") &&
          userInfo?.user?.technician_permissions?.delete_so
        ) {
          actionItems.push({
            label: (
              <span>
                <span
                  className="mbsc-font-icon mbsc-icon-remove"
                  style={{ marginRight: "5px" }}
                ></span>
                Delete
              </span>
            ),
            key: "delete",
            disabled: offline,
          });
        }
      } else {
        actionItems.push({
          label: (
            <span>
              <span
                className="mbsc-font-icon mbsc-icon-line-calendar"
                style={{ marginRight: "5px" }}
              ></span>
              Reschedule
            </span>
          ),
          key: "reschedule",
          disabled: offline,
        });
        actionItems.push({
          label: (
            <span>
              <span
                className="mbsc-font-icon mbsc-icon-remove"
                style={{ marginRight: "5px" }}
              ></span>
              Delete
            </span>
          ),
          key: "delete",
          disabled: offline,
        });
      }
    }
    if (data?.original?.type != "Task") {
      actionItems.push({
        label: (
          <span>
            <ReconciliationOutlined
              style={{ fontSize: "20px", marginRight: "5px" }}
            />
            Activity Log
          </span>
        ),
        key: "activity_log",
        disabled: offline,
      });
    }
    return actionItems;
  };

  const renderEvent = (event) => {
    const data = event?.original;
    return (
      <div className="custom-event-list">
        <div
          className="calendar-label"
          style={{ backgroundColor: data?.color, marginBottom: "2px" }}
        >
          <Row justify="space-between" gutter={[10]}>
            <Col xs={24} xxl={12}>
              <div
                className={`${data?.type == "Task" ? "task-tile-chip" : ""}`}
              >
                {data?.type == "Task" ? <img src={taskIcon} alt="" /> : null}
                <span className="title">{data?.serviceType}</span>
              </div>
            </Col>
            <Col xs={24} xxl={12}>
              <Row justify="space-between">
                <Col xs={24} xxl={10}>
                  {`${moment(data?.start).utc().format("HH:mm")}`}
                </Col>
                <Col xs={24} xxl={14}>
                  <Row justify="end" className="calendar-label-icons">
                    {data?.type == "Service" ? (
                      <Col>
                        <img
                          src={poolIcon}
                          alt=""
                          onClick={() => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            showPoolCharacteristicsModal(event);
                          }}
                        />
                      </Col>
                    ) : null}
                    {data?.job_forms?.length > 0 ? (
                      <>
                        <Col>
                          <span
                            className="mbsc-font-icon mbsc-icon-file4"
                            onClick={() => {
                              shouldOpenPopup.current = false;
                              setTimeout(
                                () => (shouldOpenPopup.current = true),
                                200
                              );
                              showWOModal({ original: data });
                            }}
                          ></span>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    {data?.edit_work_order_url && !data?.is_invoiced ? (
                      <span
                        className={`mbsc-font-icon mbsc-icon-pencil ${
                          data?.original?.batch_invoice_processed
                            ? "disabled"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(addDuration(data?.service_duration));
                          dispatch(editDuration(true));
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          window.open(data?.edit_work_order_url, "_blank");
                        }}
                      ></span>
                    ) : (
                      <></>
                    )}
                    {data?.status !== "complete" ? (
                      <>
                        {role === "field_tech" || role === "lead_tech" ? (
                          (data?.permissionset?.includes("assign_wo") ||
                            data?.permissionset?.includes("reschedule_wo")) &&
                          (userInfo?.user?.technician_permissions
                            ?.reschedule_wo ||
                            userInfo?.user?.technician_permissions
                              ?.assign_wo) && (
                            <Col>
                              <span
                                className="mbsc-font-icon mbsc-icon-line-calendar"
                                onClick={() => {
                                  shouldOpenPopup.current = false;
                                  setTimeout(
                                    () => (shouldOpenPopup.current = true),
                                    200
                                  );
                                  showRescheduleModal({ original: data });
                                }}
                              ></span>
                            </Col>
                          )
                        ) : (
                          <Col>
                            <span
                              className="mbsc-font-icon mbsc-icon-line-calendar"
                              onClick={() => {
                                shouldOpenPopup.current = false;
                                setTimeout(
                                  () => (shouldOpenPopup.current = true),
                                  200
                                );
                                showRescheduleModal({ original: data });
                              }}
                            ></span>
                          </Col>
                        )}
                        {data?.type == "Task" ? (
                          <Tooltip title="Edit Task">
                            <span
                              className="mbsc-font-icon mbsc-icon-pencil"
                              onClick={() => {
                                shouldOpenPopup.current = false;
                                setTimeout(
                                  () => (shouldOpenPopup.current = true),
                                  200
                                );
                                dispatch(setSelectedTaskData(data));
                                dispatch(toggleTaskModal(true));
                                dispatch(setTaskFlagFromEdit(true));
                                if (
                                  role === "field_tech" &&
                                  data?.permissionset?.length == 0
                                ) {
                                  dispatch(setDisableTechnicianTask(true));
                                }
                              }}
                            ></span>
                          </Tooltip>
                        ) : null}
                        {role === "field_tech" || role === "lead_tech" ? (
                          data?.permissionset?.includes("delete_so") &&
                          userInfo?.user?.technician_permissions?.delete_so && (
                            <Col>
                              <span
                                className="mbsc-font-icon mbsc-icon-remove"
                                onClick={() => {
                                  shouldOpenPopup.current = false;
                                  setTimeout(
                                    () => (shouldOpenPopup.current = true),
                                    200
                                  );
                                  showDeleteModal({ original: data });
                                }}
                              ></span>
                            </Col>
                          )
                        ) : (
                          <Col>
                            <span
                              className="mbsc-font-icon mbsc-icon-remove"
                              onClick={() => {
                                shouldOpenPopup.current = false;
                                setTimeout(
                                  () => (shouldOpenPopup.current = true),
                                  200
                                );
                                showDeleteModal({ original: data });
                              }}
                            ></span>
                          </Col>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {data?.type != "Task" ? (
                      <Col>
                        <ReconciliationOutlined
                          style={{ fontSize: "20px" }}
                          onClick={() => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            showActivityLogModal({ original: data });
                          }}
                        />
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {data?.type == "Task" ? (
              <Col span={24}>
                <span
                  className=""
                  onClick={() => {
                    shouldOpenPopup.current = false;
                    setTimeout(() => (shouldOpenPopup.current = true), 200);
                    if (role !== "lead_tech" && role !== "field_tech") {
                      openAccountOverview(data);
                    }
                  }}
                >
                  <span
                    className={`${
                      role !== "field_tech" && role !== "lead_tech"
                        ? "link-title"
                        : ""
                    }`}
                  >
                    {` ${data?.customer ? data?.customer?.name : ""}`}
                  </span>
                </span>
              </Col>
            ) : null}
            <Col span={24}>
              <span
                className=""
                onClick={() => {
                  shouldOpenPopup.current = false;
                  setTimeout(() => (shouldOpenPopup.current = true), 200);
                  if (role !== "lead_tech" && role !== "field_tech") {
                    openAccountOverview(data);
                  }
                }}
              >
                <span
                  className={
                    role !== "field_tech" &&
                    role !== "lead_tech" &&
                    data?.type != "Task"
                      ? `mbsc-font-icon mbsc-icon-link`
                      : `mbsc-font-icon`
                  }
                />
                <span
                  className={`${
                    role !== "field_tech" &&
                    role !== "lead_tech" &&
                    data?.type != "Task"
                      ? "link-title"
                      : ""
                  }`}
                >
                  {` ${data?.title ? data?.title : ""}`}
                </span>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderAgenda = (day, args) => {
    return (
      <div className="custom-event-list">
        {args?.data?.map((data) => (
          <>
            <div
              className="calendar-label"
              style={{ backgroundColor: data?.color, marginBottom: "2px" }}
              onClick={(evt) => {
                onEventClick({ domEvent: evt, event: data });
              }}
            >
              <Row justify="space-between" gutter={[10]}>
                <Col xs={24} xxl={12}>
                  {`${moment(data?.start).utc().format("HH:mm")}`}
                </Col>
                <Col xs={24} xxl={12}>
                  <Row
                    justify="end"
                    gutter={[10, 5]}
                    className="calendar-label-icons"
                  >
                    {data?.type != "Task" ? (
                      <Col>
                        <img
                          src={poolIcon}
                          alt=""
                          onClick={() => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            showPoolCharacteristicsModal({ original: data });
                          }}
                        />
                      </Col>
                    ) : null}
                    {data?.job_forms?.length > 0 ? (
                      <>
                        <Col>
                          <span
                            className="mbsc-font-icon mbsc-icon-file4"
                            onClick={() => {
                              shouldOpenPopup.current = false;
                              setTimeout(
                                () => (shouldOpenPopup.current = true),
                                200
                              );
                              showWOModal({ original: data });
                            }}
                          ></span>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    {data?.edit_work_order_url && !data?.is_invoiced ? (
                      <span
                        className={`mbsc-font-icon mbsc-icon-pencil ${
                          data?.original?.batch_invoice_processed
                            ? "disabled"
                            : ""
                        }`}
                        onClick={() => {
                          dispatch(addDuration(data?.service_duration));
                          dispatch(editDuration(true));
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          window.open(data?.edit_work_order_url, "_blank");
                        }}
                      ></span>
                    ) : (
                      <></>
                    )}
                    {data?.status !== "complete" ? (
                      <>
                        {role === "field_tech" || role === "lead_tech" ? (
                          (data?.permissionset?.includes("assign_wo") ||
                            data?.permissionset?.includes("reschedule_wo")) &&
                          (userInfo?.user?.technician_permissions
                            ?.reschedule_wo ||
                            userInfo?.user?.technician_permissions
                              ?.assign_wo) && (
                            <Col>
                              <span
                                className="mbsc-font-icon mbsc-icon-line-calendar"
                                onClick={() => {
                                  shouldOpenPopup.current = false;
                                  setTimeout(
                                    () => (shouldOpenPopup.current = true),
                                    200
                                  );
                                  showRescheduleModal({ original: data });
                                }}
                              ></span>
                            </Col>
                          )
                        ) : (
                          <Col>
                            <span
                              className="mbsc-font-icon mbsc-icon-line-calendar"
                              onClick={() => {
                                shouldOpenPopup.current = false;
                                setTimeout(
                                  () => (shouldOpenPopup.current = true),
                                  200
                                );
                                showRescheduleModal({ original: data });
                              }}
                            ></span>
                          </Col>
                        )}
                        {data?.type == "Task" ? (
                          <Tooltip title="Edit Task">
                            <span
                              className="mbsc-font-icon mbsc-icon-pencil"
                              onClick={() => {
                                shouldOpenPopup.current = false;
                                setTimeout(
                                  () => (shouldOpenPopup.current = true),
                                  200
                                );
                                dispatch(setSelectedTaskData(data));
                                dispatch(toggleTaskModal(true));
                                dispatch(setTaskFlagFromEdit(true));
                                if (
                                  role === "field_tech" &&
                                  data?.permissionset?.length == 0
                                ) {
                                  dispatch(setDisableTechnicianTask(true));
                                }
                              }}
                            ></span>
                          </Tooltip>
                        ) : null}
                        {role === "field_tech" || role === "lead_tech" ? (
                          data?.permissionset?.includes("delete_so") &&
                          userInfo?.user?.technician_permissions?.delete_so && (
                            <Col>
                              <span
                                className="mbsc-font-icon mbsc-icon-remove"
                                onClick={() => {
                                  shouldOpenPopup.current = false;
                                  setTimeout(
                                    () => (shouldOpenPopup.current = true),
                                    200
                                  );
                                  showDeleteModal({ original: data });
                                }}
                              ></span>
                            </Col>
                          )
                        ) : (
                          <Col>
                            <span
                              className="mbsc-font-icon mbsc-icon-remove"
                              onClick={() => {
                                shouldOpenPopup.current = false;
                                setTimeout(
                                  () => (shouldOpenPopup.current = true),
                                  200
                                );
                                showDeleteModal({ original: data });
                              }}
                            ></span>
                          </Col>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {data?.type != "Task" ? (
                      <ReconciliationOutlined
                        style={{ fontSize: "20px" }}
                        onClick={() => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          showActivityLogModal({ original: data });
                        }}
                      />
                    ) : null}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={18}>
                  <div
                    className={`${
                      data?.type == "Task" ? "task-tile-chip" : ""
                    }`}
                  >
                    {data?.type == "Task" ? (
                      <img src={taskIcon} alt="" />
                    ) : null}
                    <span className="title">{data?.serviceType}</span>
                  </div>
                  {data?.type == "Task" ? (
                    <div
                      span={18}
                      className="subtitle"
                      onClick={() => {
                        shouldOpenPopup.current = false;
                        setTimeout(() => (shouldOpenPopup.current = true), 200);
                        if (
                          role !== "lead_tech" &&
                          role !== "field_tech" &&
                          data?.client_uuid
                        ) {
                          openAccountOverview(data);
                        }
                      }}
                    >
                      <span
                        className={`${
                          role !== "field_tech" && role !== "lead_tech"
                            ? "link-title"
                            : ""
                        }`}
                      >
                        {` ${data?.customer ? data?.customer?.name : ""}`}
                      </span>
                    </div>
                  ) : null}
                  <span
                    className=""
                    onClick={() => {
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      if (
                        role !== "lead_tech" &&
                        role !== "field_tech" &&
                        data?.type != "Task"
                      ) {
                        openAccountOverview(data);
                      }
                    }}
                  >
                    <span
                      className={
                        role !== "field_tech" &&
                        role !== "lead_tech" &&
                        data?.type != "Task"
                          ? `mbsc-font-icon mbsc-icon-link`
                          : `mbsc-font-icon`
                      }
                    />
                    <span
                      className={`${
                        role !== "field_tech" &&
                        role !== "lead_tech" &&
                        data?.type != "Task"
                          ? "link-title"
                          : ""
                      }`}
                    >
                      {` ${data?.title ? data?.title : ""}`}
                    </span>
                  </span>
                </Col>
              </Row>
            </div>
          </>
        ))}
      </div>
    );
  };

  const onChangRecurring = (e) => {
    setRescheduleRecurring(e.target.checked);
    if (e.target.checked) {
      setRescheduleTimeSelection(moment("9:00", "HH:mm"));
      setRescheduleDate(null);
    }
  };

  const onOptimize = async () => {
    setErrors([]);

    const data = {
      user_uuid: technician.value,
      start_location: startLocation,
      end_location: stopLocation,
      date: moment(currentDate).format("YYYY-MM-DD"),
    };

    //Validate data
    const schema = yup.object().shape({
      user_uuid: yup.string().required("Technician is required"),
      start_location: yup.object().shape({
        address: yup.string().required("Address is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
      }),
      end_location: yup.object().shape({
        address: yup.string().required("Address is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
      }),
    });

    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        const response = await getRouteOptimization({
          user_uuid: technician?.value,
          start_location: `${startLocation?.address}, ${startLocation?.city}, ${startLocation?.state}, ${startLocation?.zip_code} `,
          end_location: `${stopLocation?.address}, ${stopLocation?.city}, ${stopLocation?.state}, ${stopLocation?.zip_code} `,
          date: moment(currentDate).format("YYYY-MM-DD"),
        });

        if (response?.success) {
          message.success(response?.success);
          setOptModalOpen(false);
          //get events updated
          if (franchise.value && technician.value) getWorkOrder();
        } else {
          message.error("Something went wrong, please try again.");
        }
      })
      .catch((error) => {
        let err_data = {};
        _.map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log(err_data);
      });
  };

  const handleStartAddressChange = (e) => {
    if (e.target) {
      setStartLocation({
        ...startLocation,
        [e.target.name]: e.target.value,
      });
    } else {
      setStartLocation(e);
    }
  };

  const handleStopAddressChange = (e) => {
    if (e.target) {
      setStopLocation({
        ...stopLocation,
        [e.target.name]: e.target.value,
      });
    } else {
      setStopLocation(e);
    }
  };

  const offlineRender = ({ online }) => {
    if (offline === undefined) {
      setOffline(!online);
    }
  };
  const offlineDetection = (online) => {
    localStorage.setItem("offline", JSON.parse(!online));

    if (online === false) {
      setTimeout(() => {
        _handleChangeView({ target: { value: "day" } });
        onChangeCalendar();
        setOffline(!online);
        setOptionsSelector((state) =>
          state.map((item) => {
            if (item.value !== "day") {
              return { ...item, disabled: !online };
            }
            return item;
          })
        );
        getWorkOrder();
      }, 500);
    }
    if (online === true) {
      sendOfflineRequests();
      setTimeout(() => {
        setOffline(!online);
        setOptionsSelector((state) =>
          state.map((item) => {
            if (item.value !== "day") {
              return { ...item, disabled: !online };
            }
            return item;
          })
        );
      }, 2000);
    }
  };

  return (
    <>
      {offline ? (
        <Alert
          message={
            <span style={{ fontSize: "18px" }}>
              You are on offline mode,{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                see more
              </span>
            </span>
          }
          banner
        />
      ) : (
        <></>
      )}
      <div className="block-row cal-listview">
        {isDesktopOrLaptop ? <FilterHeader setEvents={setEvents} /> : null}
        <div className={`calendar-${view}`}>
          <Spin spinning={loading} delay={500}>
            <DesktopEventCalendarContainer
              onSelectedDateChange={onSelectedDateChange}
              customWithNavButtons={customWithNavButtons}
              onEventClick={onEventClick}
              calendarColorsExample={calendarColorsExample}
              role={role}
              userInfo={userInfo}
              handleEditEvent={handleEditEvent}
              status={status}
              renderLabelContent={renderLabelContent}
              viewData={viewData}
              renderAgenda={renderAgenda}
              myInvalid={myInvalid}
              renderEvent={renderEvent}
              shouldOpenPopup={shouldOpenPopup}
              showPoolCharacteristicsModal={showPoolCharacteristicsModal}
              poolIcon={poolIcon}
              taskIcon={taskIcon}
              showWOModal={showWOModal}
              showRescheduleModal={showRescheduleModal}
              showDeleteModal={showDeleteModal}
              openAccountOverview={openAccountOverview}
              currentDate={currentDate}
              showActivityLogModal={showActivityLogModal}
              createItems={createItems}
            />
            <MobileEventCalendarContainer
              onSelectedDateChange={onSelectedDateChange}
              currentDate={currentDate}
              customWithNavButtons={customWithNavButtons}
              myEvents={myEvents}
              status={status}
              onEventClick={onEventClick}
              viewMobileData={viewMobileData}
              createItems={createItems}
              showPoolCharacteristicsModal={showPoolCharacteristicsModal}
              showRescheduleModal={showRescheduleModal}
              showDeleteModal={showDeleteModal}
              shouldOpenPopup={shouldOpenPopup}
              role={role}
              openAccountOverview={openAccountOverview}
              showActivityLogModal={showActivityLogModal}
              taskIcon={taskIcon}
              showWOModal={showWOModal}
            />
          </Spin>
          {roleDetails?.includes("field_tech") && !leadTechnician ? (
            <AddTask />
          ) : null}
        </div>
        {isMobile ? (
          <div className="colorForMobile">
            <FilterHeaderMobile setEvents={setEvents} />
          </div>
        ) : null}
        <PopupCalendarContainer
          isOpen={isOpen}
          anchor={anchor}
          setOpen={setOpen}
          bgColor={bgColor}
          currentEvent={currentEvent}
          poolIcon={poolIcon}
          shouldOpenPopup={shouldOpenPopup}
          showPoolCharacteristicsModal={showPoolCharacteristicsModal}
          showWOModal={showWOModal}
          role={role}
          userInfo={userInfo}
          showRescheduleModal={showRescheduleModal}
          showDeleteModal={showDeleteModal}
          location={location}
          dualRole={dualRole}
          onStartJobTime={onStartJobTime}
          onCompleteJobTime={onCompleteJobTime}
          updateTaskStatus={updateTaskStatus}
          showActivityLogModal={showActivityLogModal}
          isMobile={isMobile}
          createItems={createItems}
        />
        <ReassignJobModalCalendarContainer
          rescheduleModalOpen={rescheduleModalOpen}
          onRescheduleWorkOrder={onRescheduleWorkOrder}
          getWorkOrder={getWorkOrder}
          hideRescheduleModal={hideRescheduleModal}
          onCloseRescheduleModal={onCloseRescheduleModal}
          disableRescheduleBtn={disableRescheduleBtn}
          selectedEvent={selectedEvent}
          franchise={franchise}
          setRescheduleTechnician={setRescheduleTechnician}
          rescheduleDate={rescheduleDate}
          setRescheduleDate={setRescheduleDate}
          disabledDate={disabledDate}
          rescheduleTimeSelection={rescheduleTimeSelection}
          onChangeTimePicker={onChangeTimePicker}
          technician={technician}
          onChangRecurring={onChangRecurring}
          rescheduleRecurring={rescheduleRecurring}
          role={role}
          onRescheduleTask={onRescheduleTask}
        />
        <DeleteJobModalCalendarContainer
          deleteModalOpen={deleteModalOpen}
          onDeleteWorkOrder={onDeleteWorkOrder}
          setDeleteModalOpen={setDeleteModalOpen}
          updateTaskStatus={updateTaskStatus}
        />
        <WorkOrderFormModalCalendarContainer
          selectedEvent={selectedEvent}
          woModalOpen={woModalOpen}
          setWOModalOpen={setWOModalOpen}
          handleWOSave={handleWOSave}
          windowSize={windowSize}
        />
        <PoolCharactersticsModalCalendarContainer
          pcModalOpen={pcModalOpen}
          setPCModalOpen={setPCModalOpen}
          selectedEvent={selectedEvent}
          franchise={franchise}
          handlePoolCancel={handlePoolCancel}
        />
        <RouteOptimizationModalCalendarContainer
          optModalOpen={optModalOpen}
          onOptimize={onOptimize}
          setOptModalOpen={setOptModalOpen}
          handleStartAddressChange={handleStartAddressChange}
          errors={errors}
          startLocation={startLocation}
          handleStopAddressChange={handleStopAddressChange}
          stopLocation={stopLocation}
        />
        {activityModalOpen ? (
          <ActivityLogModal
            customer_uuid={selectedEvent?.original?.client_uuid}
            customer_name={selectedEvent?.original?.client_name}
            open={activityModalOpen}
            setOpen={(operation) => setActivityModalOpen(operation)}
          />
        ) : (
          <></>
        )}
        <Detector render={offlineRender} onChange={offlineDetection} />
      </div>
    </>
  );
};
export default CalendarContainers;
