import appData from "../Utils/constants";
import keyBy from "lodash/keyBy";
import moment from "moment-timezone";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  showLoader: true,
  serviceMatrix: [],
  serviceTypeArray: [],
  selectedServiceType: {},
  workTypesArray: [],
  selectedWorkSubType: {},
  frequency: {},
  time: moment("8:00", "HH:mm"),
  preferred_start_date: moment().format("YYYY-MM-DD"),
  schedule_time_range: "",
  poolSize: 0,
  poolName:"",
  price: 0,
  paymentMethodList: appData.paymentMethods,
  invoicePreferences: keyBy(appData.invoicePreferences, "key"),
  contactMethods: keyBy(appData.contactMethods, "key"),
  showBaseJobModal: false,
  showCustomerModal: false,
  fieldOfficeSelectedZip : "",
  isRecurring: true,
  communityDetails: {
    poolName:'',
    no_of_properties: 0,
    no_of_pools: 0,
    notes: "",
    preferred_contact_methods: [],
  },
  customerDetails: {
    first_name: "",
    zipcode: "",
    last_name: "",
    address: {
      address:"",
      state:"",
      city:"",
      zip_code:"",
      route:"",
      street_number:""
   },
    billing_address: {
      address:"",
      state:"",
      city:"",
      zip_code:"",
      route:"",
      street_number:""
   },
    phone_numbers: [
      {
         "phone":"",
         "primary":false,
         "type":"mobile",
         "can_send_sms":false
      }
   ],
    phone: "",
    phoneType: "mobile",
    emailaddress_set: [],
    email: "",
    company_name: "",
    company_type: "",
    hear_about_us: [],
    lead_stage: "New",
    lead_stage_key: "new",
    outreach: "Call Center",
    outreach_key: "call_center",
    sameContactAddress: true,
    specialNotes:"",    
  },
};

const techJourneyDataSlice = createSlice({
  name: "techJourney",
  initialState,
  reducers: {
    showLoader(state, action) {
      return (state = {
        ...state,
        showLoader: action.payload,
      });
    },
    setFieldOfficeSelectedZip(state,action){
      return (state = {
        ...state,
        fieldOfficeSelectedZip: action.payload,
      });
    },
    setTechJourney(state, action) {
      return (state = {
        ...state,
        techJourney: action.payload,
      });
    },
    toggleCustomerModal(state, action) {
      return (state = {
        ...state,
        showCustomerModal: action.payload,
      });
    },
    toggleBaseJobModal(state, action) {
      return (state = {
        ...state,
        showBaseJobModal: action.payload,
      });
    },
    updateCustomerDetails(state, action) {
      return (state = {
        ...state,
        customerDetails: action.payload,
      });
    },
    updateCommunityDetails(state, action) {
      return (state = {
        ...state,
        communityDetails: action.payload,
      });
    },
    addServiceMatrix(state, action) {
      return (state = {
        ...state,
        serviceMatrix: action.payload,
        serviceTypeArray: action.payload.servicetypes,
        selectedServiceType: action.payload.servicetypes[0],
        workTypesArray: action.payload.servicetypes[0].worktypes,
        selectedWorkType: null,
        selectedWorkSubType: {},
        isRecurring: true,
        price: 0,
      });
    },
    selectServiceType(state, action) {
      if(action.payload.name === 'service'){
        setRecurring(false)
      }else{
        setRecurring(true)
      }

      return (state = {
        ...state,
        selectedServiceType: action.payload,
        workTypesArray: action.payload.worktypes,
        selectedWorkType: null,
        workSubTypeArray: null,
        selectedWorkSubType: null,
        isRecurring: action.payload.name === 'service' ? false : true
      });
    },
    selectRateType(state, action) {
      if(action.payload.worksubtypes.length > 0){
        setRecurring(false);
      }else{          
        setRecurring(action.payload.is_recurring)          
      }
            
      return (state = {
        ...state,
        selectedWorkType: action.payload,
        workSubTypeArray: action.payload.worksubtypes,
        selectedWorkSubType: null,
        price: action.payload.price || 0,
        isRecurring: action.payload.serviceType === "maintenance" ? true : action.payload.is_recurring
      });
    },
    selectWorkSubType(state, action) {
      if(action.payload.is_recurring !== undefined && action.payload.is_recurring){
        setRecurring(true)
      }else{
        setRecurring(false)
      }
    
      return (state = {
        ...state,
        selectedWorkSubType: action.payload,
        price: action.payload.price || 0,
        isRecurring: action.payload.is_recurring
      });
    },
    selectPoolType(state, action) {
      return (state = {
        ...state,
        selectedPoolType: action.payload,
      });
    },
    updatePreferredDate(state, action) {
      return (state = {
        ...state,
        preferred_start_date: action.payload,
      });
    },
    updateScheduleTimeRange(state, action) {
      return (state = {
        ...state,
        schedule_time_range: action.payload,
      });
    },
    addPoolSize(state, action) {
      return (state = {
        ...state,
        poolSize: action.payload,
      });
    },
    addPoolName(state, action) {
      return (state = {
        ...state,
        poolName: action.payload,
      });
    },
    updatePrice(state, action) {
      return (state = {
        ...state,
        price: action.payload,
      });
    },
    updateFrequency(state, action) {
      return (state = {
        ...state,
        frequency: action.payload,
      });
    },
    addRenovationNotes(state, action) {
      return (state = {
        ...state,
        renovationNotes: action.payload,
      });
    },
    addConstructionNotes(state, action) {
      return (state = {
        ...state,
        constructionNotes: action.payload,
      });
    },
    addSpecialNotes(state, action) {
      return (state = {
        ...state,
        specialNotes: action.payload,
      });
    },
    addTechnicianArray(state, action) {
      return (state = {
        ...state,
        technicianArray: action.payload,
      });
    },
    addPreferredTechnician(state, action) {
      return (state = {
        ...state,
        preferredTechnician: action.payload,
      });
    },
    updateInvoicePreference(state, action) {
      return (state = {
        ...state,
        invoicePreferences: { ...state.invoicePreferences, ...action.payload },
      });
    },
    updateContactMethods(state, action) {
      return (state = {
        ...state,
        contactMethods: { ...state.contactMethods, ...action.payload },
      });
    },
    selectPaymentMethod(state, action) {
      return (state = {
        ...state,
        paymentMethod: action.payload,
      });
    },
    addEmail(state, action) {
      return (state = {
        ...state,
        value: action.payload,
      });
    },
    addPhoneNumber(state, action) {
      return (state = {
        ...state,
        value: action.payload,
      });
    },
    createCustomer(state, action) {
      return (state = {
        ...state,
        newCustomer: action.payload,
      });
    },
    toggleSameContactAddress(state, action) {
      return (state = {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          sameContactAddress: action.payload,
        },
      });
    },
    resetState(state, action) {
      return (state = {
        selectedWorkSubType: {},
        poolSize: 0,
        poolName:"",
        price: 0,
        preferred_start_date: moment().format("YYYY-MM-DD"),
        schedule_time_range: "",        
        time: moment("8:00", "HH:mm"),
        paymentMethod: {},
        paymentMethodList: appData.paymentMethods,
        invoicePreferences: keyBy(appData.invoicePreferences, "key"),
        contactMethods: keyBy(appData.contactMethods, "key"),
        showBaseJobModal: false,
        showCustomerModal: false,
        communityDetails: {
          poolName:"",
          no_of_properties: 0,
          no_of_pools: 0,
          notes: "",
          preferred_contact_methods: [],
        },
        customerDetails: {
          first_name: "",
          zipcode: "",
          last_name: "",
          address: { address: "", city: "", state: "", zip_code: "" },
          billing_address: { address: "", city: "", state: "", zip_code: "" },
          phone_numbers: [
            {
              phone: "",
              primary: false,
              type: "mobile",
              can_send_sms: false,
            },
          ],
          emailaddress_set: [
            {
              email: "",
              primary: false,
            },
          ],
          phone: "",
          phoneType: "mobile",
          email: "",
          company_name: "",
          company_type: "",
          hear_about_us: [],
          lead_stage: "New",
          lead_stage_key: "new",
          outreach: "Call Center",
          outreach_key: "call_center",
        },
        serviceMatrix: state.serviceMatrix,
        serviceTypeArray: state.serviceMatrix.servicetypes,
        selectedServiceType: state.serviceMatrix.servicetypes[0],
        workTypesArray: state.serviceMatrix.servicetypes[0].worktypes,
        technicianArray: state.technicianArray,
        selectedWorkType: null,
        frequency: {},
        preferredTechnician: "",
        specialNotes: "",
        constructionNotes: "",
        renovationNotes: "",
        paymentMethod: "",
        isRecurring:true
      });
    },
    selectTime(state, action)
    {
      return (state = {
        ...state,
        time: action.payload
      })
    },
    setRecurring(state, action)
    {
      return (state = {
        ...state,
        isRecurring: action.payload
      })
    }
  },
});

export const {
  showLoader,
  setTechJourney,
  updateCustomerDetails,
  toggleCustomerModal,
  toggleBaseJobModal,
  addServiceMatrix,
  selectServiceType,
  selectRateType,
  selectPoolType,
  addPoolSize,
  addPoolName,
  addUserName,
  addPhoneNumber,
  addEmail,
  selectWorkSubType,
  updateFrequency,
  updatePrice,
  addRenovationNotes,
  addConstructionNotes,
  addSpecialNotes,
  addTechnicianArray,
  addPreferredTechnician,
  updateInvoicePreference,
  updateContactMethods,
  selectPaymentMethod,
  createCustomer,
  toggleSameContactAddress,
  resetState,
  updateCommunityDetails,
  updatePreferredDate,
  updateScheduleTimeRange,
  selectTime,
  setFieldOfficeSelectedZip,
  setRecurring
} = techJourneyDataSlice.actions;

export default techJourneyDataSlice.reducer;
