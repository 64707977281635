import React, { useState, useEffect, useRef } from "react";
import { Modal, message, Button as ButtonAntd } from "antd";
import { Button } from "@mobiscroll/react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import AddCustomer from "../Modals/AddCustomer.Modal";
import MaintenancePop from "./MaintenancePop";
import JobPop from "./JobPop";

import useResponsiveContainer from "../Containers/useResponsiveContainer";

import NewCustomer from "../../../Customer/Residential/NewCustomer/NewCustomer";
import "../../../Assets/css/addNewCustomerBaseJobModal.css";
import BaseJobRequirementsModal from "../Modals/BaseJobRequirement.Modal";
import AddTask from "../Modals/AddTask.Modal";
import {resetValues} from "../../../Store/serviceOrderDetailDataSlice";
import userimg from "../../../Assets/icons/down_arrow.svg";

import {
  setTechJourney,
  toggleCustomerModal,
  toggleBaseJobModal,
} from "../../../Store/techJourneyDataSlice";
import { useCalendar } from "../../Hooks/Calendar.Hook";
import {
  createWorkOrderHelper,
  createServiceOrderHelper,
} from "../../helper/listHelper";
import {toggleTaskModal, setTaskFlagFromEdit} from "../../../Store/technicianTaskDataSlice";

const ToggleAddList = (props) => {
  const [toggle, setToggle] = useState(false);
  const [toggleCustomer, setToggleCustomer] = useState(false);
  const [toggleLead, setToggleLead] = useState(false);
  const [isJobPopupOpen, setIsJobPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Add New Job");
  const [submitBtnText, setSubmitBtnText] = useState("Schedule New Job");
  const [jobPopupSubmitBtnDisable, setJobPopupSubmitBtnDisable] =
    useState(true);
  const [createCustomer, setcreateCustomer] = useState(false);
  const [createServiceOrder, setcreateServiceOrder] = useState(false);
  const [customerLinkClicked, setcustomerLinkClicked] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isMobile, isDesktopOrLaptop] = useResponsiveContainer();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const ref = useRef();
  const jobPopUpDataRef = useRef();
  const btnData = ["Job", "Customer", "Lead", "Task"];

  const createServiceOrderDataRef = useRef();
  const createServiceOrderPriceRef = useRef();

  // To close the Add new list when click outside
  useEffect(() => {
    const handler = (event) => {
      if (toggle && ref.current && !ref.current.contains(event.target)) {
        let selElemClass = event.target.className;
        if (!selElemClass.includes("calendarViewAddBtn")) {
          setToggle(!toggle);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [toggle]);

  const {
    franchise: { value: userID },
  } = useCalendar();

  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const taskJourneyState = useSelector((state) => state.technicianTaskJourney);
  const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);
  const { showBaseJobModal, customerDetails } = techJourneyState;

  // 3168 Add Commercial Customer - Ayesha's code
  const [toggleCustomerInfo, setToggleCustomerInfo] = useState(false);
  const [toggleBaseJobCom, setToggleBaseJobCom] = useState(false);
  const [inputData, setInputData] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const handleCustomerInfo = (data) => {
    setInputData(data);
    setToggleCustomer(false);
    setToggleLead(false);
    if (data?.company_type === "commercial") {
      setToggleCustomerInfo(true);
    } else {
      setToggleBaseJobCom(true);
    }
  };
  const handleAddJob = (data) => {
    setCustomerData(data);
    setToggleCustomerInfo(false);
    setToggleBaseJobCom(true);
  };

  const listClick = (type) => {
    switch (type) {
      case "Job":
        setIsJobPopupOpen(true);
        setToggle(!toggle);
        setcustomerLinkClicked(true);
        break;
      case "Customer":
        setToggleCustomer(true);
        break;
      case "Lead":
        // code block for Lead
        setToggleLead(true);
        break;
      case "Task":
        // code block for Task
        dispatch(toggleTaskModal(true))
        dispatch(setTaskFlagFromEdit(false))
        // setToggleTask(true);
        break;
      default:
      // code block MISC
    }
  };

  const onSubmitClick = () => {
    setConfirmLoading(true);
    if (customerLinkClicked) {
      setJobPopupSubmitBtnDisable(true);
      const params = jobPopUpDataRef.current;
      createWorkOrderHelper(params).then((res) => {
        if (res.uuid) {
          message.success(
            `Work order ${res?.work_order_number || ""} added.`,
            5
          );
          setIsJobPopupOpen(false);
        } else
          message.error("The work order have not been save, please try again.");
        setConfirmLoading(false);
      });
    } else if (createServiceOrder) {
      const customerData = jobPopUpDataRef.current;
      const serviceOrderData = createServiceOrderDataRef.current;
      const serviceOrderPriceData = createServiceOrderPriceRef.current;
      const serviceOrderFields = serviceOrderNewFieldsValue;
      createServiceOrderHelper(
        customerData,
        serviceOrderData,
        serviceOrderPriceData,
        serviceOrderFields
      ).then((res) => {
        if (res.uuid) {
          message.success(
            `Service order ${res?.service_order_number || ""} added.`,
            5
          );
          setPopupTitle("Add New Job");
          setIsJobPopupOpen(false);
          setcreateServiceOrder(false);
          dispatch(resetValues());
        } else
          message.error(
            "The service order have not been save, please try again."
          );
        setConfirmLoading(false);
      });
    }
  };

  const onCancelBtnClicked = () => {
    dispatch(resetValues());
    setIsJobPopupOpen(false);
    setcustomerLinkClicked(false);
    setcreateServiceOrder(false);
    setPopupTitle("Add New Job");
    setSubmitBtnText("Schedule New Job");
  };

  useEffect(() => {
    if (jobPopUpDataRef.current?.customer) {
      let selUUID = jobPopUpDataRef.current;
      setSelectedCustomer(selUUID.customer);
    }
  }, [jobPopUpDataRef.current]);

  useEffect(() => {
    if (createServiceOrder === true) {
      setcustomerLinkClicked(false);
      setPopupTitle("");
      setSubmitBtnText("Save & Activate");
    }
  }, [createServiceOrder]);
  // on convert to customer
  const onSave = (params, serviceInfoDetails, priceData, isAgreedFlag) => {
    if (serviceInfoDetails?.serviceType?.value) {
      if (
        serviceInfoDetails?.serviceType?.key === "maintenance" &&
        serviceInfoDetails?.frequency?.value &&
        serviceInfoDetails?.maintenanceCategory?.value &&
        isAgreedFlag === true
      ) {
        setJobPopupSubmitBtnDisable(false);
        createServiceOrderDataRef.current = serviceInfoDetails;
        createServiceOrderPriceRef.current = priceData;
      } else if (
        serviceInfoDetails?.serviceType?.key === "service" &&
        (serviceInfoDetails?.service?.value !== "" ||
          (serviceInfoDetails?.service?.value &&
            serviceInfoDetails?.worksubtype?.value)) &&
        serviceInfoDetails?.frequency?.value &&
        isAgreedFlag === true
      ) {
        setJobPopupSubmitBtnDisable(false);        
        createServiceOrderDataRef.current = serviceInfoDetails;
        createServiceOrderPriceRef.current = priceData;
      } else if (
        serviceInfoDetails?.serviceType?.key === "construction" &&
        serviceInfoDetails?.preferredDate?.value &&
        isAgreedFlag === true
      ) {
        setJobPopupSubmitBtnDisable(false);
        createServiceOrderDataRef.current = serviceInfoDetails;
        createServiceOrderPriceRef.current = priceData;
      } else if (
        serviceInfoDetails?.serviceType?.key === "renovation" &&
        serviceInfoDetails?.preferredDate?.value &&
        isAgreedFlag === true
      ) {
        setJobPopupSubmitBtnDisable(false);
        createServiceOrderDataRef.current = serviceInfoDetails;
        createServiceOrderPriceRef.current = priceData;
      } else {
        setJobPopupSubmitBtnDisable(true);
      }
    }
  };

  const cancelCustomerModal = () => {
    setToggleCustomer(false);
    setcreateCustomer(false);
    setIsJobPopupOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setToggle(!toggle)}
        disabled={props?.disabled ? true : false}
        className={`calendarViewAddBtn calendarViewAddBtn${isDesktopOrLaptop ? "Desktop" : "Mobile"
          }`}
      >
        <div className="buttonText">{isDesktopOrLaptop ? "+ Add" : "+ Add"}</div>
        <span className="arrowButton"></span>
        <img src={userimg} className="downArrowIco" alt=""/>
      </Button>
      {toggle && (
        <ul
          ref={ref}
          className={`list-group ${isMobile ? "list-view-mobile" : ""}`}
        >
          {btnData.map((item, i) => (
            <li
              key={i}
              onClick={() => listClick(item)}
              className="list-group-item"
            >
              <b> {item} </b>
            </li>
          ))}
        </ul>
      )}
      <Modal
        title={popupTitle}
        visible={isJobPopupOpen && !createCustomer}
        width={350}
        closable={false}
        className={createServiceOrder ? "addNewCustomerBaseJobModal" : "addNewPop"}
        destroyOnClose={true}
        
        footer={[
          <ButtonAntd
            key="cancel"
            className="btn btn-white-background"
            onClick={() => onCancelBtnClicked()}
            disabled={confirmLoading}
          >
            Cancel
          </ButtonAntd>,
          <ButtonAntd
            key="submit"
            disabled={jobPopupSubmitBtnDisable}
            className={`btn btn-warning btnAddListSubmit`}
            onClick={() => {
              onSubmitClick();
            }}
            loading={confirmLoading}
          >
          
            {submitBtnText}
          </ButtonAntd>,
        ]}
      >
        {customerLinkClicked ? (
          <JobPop
            jobPopUpDataRef={jobPopUpDataRef}
            setJobPopupSubmitBtnDisable={setJobPopupSubmitBtnDisable}
            setcreateCustomer={setcreateCustomer}
            setcreateServiceOrder={setcreateServiceOrder}
            franchise={props.franchise}
          />
        ) : null}
        {createServiceOrder ? (
          <MaintenancePop
            selectedCustomer={selectedCustomer}
            setJobPopupSubmitBtnDisable={setJobPopupSubmitBtnDisable}
            onSave={onSave}
          />
        ) : null}
      </Modal>
      <AddCustomer
        openModal={toggleCustomer || createCustomer}
        setOpenModal={cancelCustomerModal}
        type={"customer"}
        handleCustomerInfo={handleCustomerInfo}
      />
      <AddCustomer
        openModal={toggleLead}
        setOpenModal={setToggleLead}
        type={"lead"}
        handleCustomerInfo={handleCustomerInfo}
      />
      <BaseJobRequirementsModal
        openModal={
          showBaseJobModal
        }
        setOpenModal={() => dispatch(toggleBaseJobModal(false))}
        inputData={customerData}
      />
      <AddTask />
    </>
  );
};

export default ToggleAddList;
