/*eslint no-unused-vars: "off"*/
import React from 'react';
import { map, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import {  Tooltip, Button  } from 'antd';

class DataTable {

  // formatPhoneNumber = (phone) => {
  //   phone = phone?.replace(/\D/g, "");
  //   return (
  //     phone?.substr(0, 3) + " " + phone?.substr(3, 3) + " " + phone?.substr(6)
  //   );
  // };

  YourStaff = (renderWorkingHours) => {
    const StaffColums = [
      {
        title: 'Name',
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: true,
        showSorterTooltip: false,
        render: (text, record) => (<span className="type ellipse"> <strong>{record?.user?.first_name} {record?.user?.last_name}</strong></span>)
      },
      // {
      //   title: 'Skillset',
      //   dataIndex: 'uuid',
      //   key: 'uuid',
      //   render: (text, record) => (
      //     <div className="d-flex flex-wrap justify-content-start">
      //       {map(record?.skillsets, (skill) => {

      //         return (
      //           <div className="fitem-check mr-15" key={skill.uuid}>
      //             <input type="checkbox"
      //               onChange={(e) => {
      //                 self.stafCheckChange(e, record.uuid, skill.uuid)
      //               }}
      //               checked={skill.enable} className="fitem-ck-input" id={`checkbox${skill.uuid}`} name="checkboxs" />
      //             <label htmlFor={`checkbox${skill.uuid}`} className="fitem-ck-txt">{skill?.skillset?.skill_display_name}</label>
      //           </div>
      //         )
      //       })}
      //     </div>
      //   )
      // },
      {
        title: 'Working Days',
        dataIndex: 'uuid',
        key: 'uuid',
        render: (text, record) => (
          <ul className="list-unstyled d-flex working-day" >
            {  renderWorkingHours(record)}
          </ul>
        )
      }
    ]
    return StaffColums
  }

  IntialClean = (self) => {
    const IntialColumns = [
      {
        title: 'Acc Number',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Customer Name',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Address (Service)',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Phone Number',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Email Address',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Service Type',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Reason',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Schedule Service',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
      {
        title: 'Date of next Service',
        dataIndex: '',
        key: '',
        render: (text, record) => (<span className="type ellipse"></span>)
      },
    ]
    return IntialColumns
  }

  ServiceToPerform = (self) => {
    const ServiceColumns = [
      {
        title: 'Customer Name',
        dataIndex: 'name',
        key: 'service_order__client',
        sorter: true,
        render: (text, record) => (<span className="type ellipse">{record?.service_order?.client?.first_name} {record?.service_order?.client?.last_name} </span>)
      },
      {
        title: 'Work Order Date/Time',
        dataIndex: 'scheduled_appointment',
        key: 'scheduled_appointment',
        sorter: true,
        render: (text, record) => (
        // <span className="type ellipse">{record?.scheduled_appointment ? moment(record?.scheduled_appointment).format("MMM DD,YYYY"): ''} {record?.scheduled_appointment ? moment(record?.scheduled_appointment).format("hh:mm"): 'Time not set'} </span>
          <b className="no-wrap">{record?.scheduled_appointment ? moment.tz(record?.scheduled_appointment, "UTC").format("MMM DD, YYYY"): ''} {record?.scheduled_appointment && record?.status === 'scheduled' ? moment.tz(record?.scheduled_appointment, "UTC").format("hh:mm A"): ''}</b>
        )
      },
      {
        title: 'Service Address',
        dataIndex: 'address',
        key: 'service_order__service_address__address',
        sorter: true,
        render: (text, record) => (
          <div>
            <div className="flex justify-content-start">
              <span className="type ellipse">{`${record?.service_order?.service_address?.address}, ${record?.service_order?.service_address?.city}, ${record?.service_order?.service_address?.state} ${record?.service_order?.service_address?.zip_code}`}</span>
              {record.is_optimo_error &&
                <Tooltip overlayClassName={'custom-tootip'} placement="bottom" title={'Address has not been verified by OptimoRoute. Scheduling this service may return an error.!'}>
                  <i className="las la-exclamation-circle text-danger ml-5"></i>
                </Tooltip>
              }
            </div>
            <span className="type ellipse custom-text-muted">{record?.service_order?.service_address?.geo_location ? record?.service_order?.service_address?.geo_location.latitude : null} {record?.service_order?.service_address?.geo_location ? record?.service_order?.service_address?.geo_location.longitude : null} </span>
          </div>
        )
      },
      {
        title: 'Work Order',
        dataIndex: 'work_order_number',
        key: 'work_order_number',
        sorter: true,
        render: (text, record) => (<span className="type ellipse custom-text-muted">{record?.work_order_number && record?.work_order_number !== null ? record?.work_order_number :'Not created'} </span>)
      },
      {
        title: 'Service Order',
        dataIndex: 'service_order_number',
        key: 'service_order__service_order_number',
        sorter: true,
        render: (text, record) => (<span className="type ellipse custom-text-high-light">{record?.service_order?.service_order_number} </span>)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (<span className={record?.status === 'scheduled' ?  ' type ellipse status blue-status ': 'type ellipse status gray-status '}>{record?.status} </span>)
      },

      // {
      //   title: 'Phone Number',
      //   dataIndex: 'service_order__phone_numbers__phone',
      //   key: 'service_order__phone_numbers__phone',
      //   sorter: true,
      //   showSorterTooltip: false,
      //   render: (text, record) => {
      //     let phone_numbers = "", item = record?.service_order?.phone_numbers
      //     if (!isEmpty(record?.service_order?.phone_numbers)) {
      //       for( let i = 0; i < record?.service_order?.phone_numbers?.length; i++) {
      //         if (item[i]?.primary) {
      //           phone_numbers = item[i]?.phone
      //           break
      //         }
      //       }
      //     }
      //     return (
      //       <span>
      //         {phone_numbers ? `+1 ${this.formatPhoneNumber(phone_numbers)}` : ""}
      //       </span>
      //     );
      //   },
      // },
      // {
      //   title: 'Email Address',
      //   dataIndex: 'service_order_email',
      //   key: 'service_order__email',
      //   sorter: true,
      //   showSorterTooltip: false,
      //   render: (text, record) => (<span className="type ellipse">{record?.service_order?.email} </span>)
      // },
      // {
      //   title: 'Last Service',
      //   dataIndex: 'last_service',
      //   key: 'last_service',
      //   render: (text, record) => (
      //     <span className="type ellipse">{record?.service_order?.last_service ? moment(record?.service_order?.last_service).format("MMM DD,YYYY") : ''}</span>
      //   )
      // },
    ]
    return ServiceColumns;
  }
}
export default new DataTable();