import React from "react";
import { Table, Row, Col, Typography } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";

const { Text } = Typography;

const AgingSummaryTable = () => {
  const {
    reportData,
    current,
    total,
    query,
    setCurrent,
    setPageSize,
    pageSize,
    setSorter,
    loading,
  } = useReport();

  const handleTableChange = (pagination, filters, sorter, extra) =>
    extra.action === "sort"
      ? sorter.order !== undefined
        ? setSorter(sorter)
        : setSorter(null)
      : null;

  const processData = reportData.map((item, idx) => ({
    ...item,
    key: idx,
  }));

  return (
    <>
      <Row>
        <Col span={24} className={"residential-lead-table-container"}>
          <div className="card">
            <div className="card-title">
              <div className="form-group ai-center w-100 fltr-txt pt-10 ccr-block">
                <div className="fitem half-fitem d-block franch-min-w franch-min-w-inv">
                  <label className="d-block helper-text">Franchise</label>
                  <h6 className="mb-0 font-weight-bold">
                    {!_.isEmpty(query?.franchise)
                      ? query?.franchise?.label
                      : "--"}
                  </h6>
                </div>
                <div
                  className="fitem half-fitem d-block franch-min-w franch-min-w-inv"
                  style={{ marginLeft: "20px", minWidth: "35%" }}
                >
                  <label className="d-block helper-text">Invoice Date</label>
                  <h6 className="mb-0 font-weight-bold">
                    {!_.isEmpty(query?.period)
                      ? `${moment(query.period?.start_date).format(
                          "ll"
                        )} - ${moment(query.period?.end_date).format("ll")}`
                      : "--"}
                  </h6>
                </div>
                <div
                  className="fitem half-fitem d-block franch-min-w franch-min-w-inv"
                  style={{ marginLeft: "20px", minWidth: "35%" }}
                >
                  <label className="d-block helper-text">Due Date</label>
                  <h6 className="mb-0 font-weight-bold">
                    {!_.isEmpty(query?.secondPeriod)
                      ? `${moment(query.secondPeriod?.start_date).format(
                          "ll"
                        )} - ${moment(query.secondPeriod?.end_date).format(
                          "ll"
                        )}`
                      : "--"}
                  </h6>
                </div>
                <div
                  className="fitem half-fitem d-block franch-min-w franch-min-w-inv"
                  style={{ marginLeft: "20px", minWidth: "15%" }}
                >
                  <label className="d-block helper-text">Status</label>
                  <h6 className="mb-0 font-weight-bold">
                    {!_.isEmpty(query?.agingStatus)
                      ? query?.agingStatus?.label
                      : "--"}
                  </h6>
                </div>{" "}
              </div>
            </div>
          </div>
          <Table
            dataSource={processData}
            columns={DataTable.AgingSummary(this)}
            className="table hscroll"
            onChange={handleTableChange}
            loading={loading}
            pagination={{
              showSizeChanger: false,
              pageSize: pageSize,
              ordering: "",
              current,
              total,
              onChange(page, pageSize) {
                setCurrent(page);
                setPageSize(pageSize);
              }
            }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={3}>
                      <span
                        style={{
                          fontWeight: "900",
                          fontSize: "18px",
                          paddingLeft: "14px",
                        }}
                      >
                        Total
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Text>{`$ ${formatToCurrency(
                        pageData.reduce(
                          (result, row) => result + row.account_balance,
                          0
                        )
                      )}`}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text>{`$ ${formatToCurrency(
                        pageData.reduce(
                          (result, row) => result + row.first_days,
                          0
                        )
                      )}`}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Text>{`$ ${formatToCurrency(
                        pageData.reduce(
                          (result, row) => result + row.medium_days,
                          0
                        )
                      )}`}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <Text>{`$ ${formatToCurrency(
                        pageData.reduce(
                          (result, row) => result + row.old_days,
                          0
                        )
                      )}`}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <Text>{`$ ${formatToCurrency(
                        pageData.reduce(
                          (result, row) => result + row.older_days,
                          0
                        )
                      )}`}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <Text>{`$ ${formatToCurrency(
                        pageData.reduce((result, row) => result + row.total, 0)
                      )}`}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default AgingSummaryTable;
