import React, {useEffect,useState} from 'react';
import {map, sortBy, isEmpty} from 'lodash';
import * as yup from "yup";
import moment from "moment-timezone";
import PrivateNotes from './PrivateNotes';
import { getServiceOrderByClient, getCommercialCustomer, activateDeactivatedCustomer } from '../../../Action/Customer';
import { getDefaultNormalizer } from '@testing-library/react';
import noActiveService from '../../../../src/Assets/images/no-active-service.svg';
import { getLastThreePayment, getInvoice, getLastThreeInvoice } from '../../../Action/Invoice';
import { formatToCurrency } from "../../../Utils/formatToCurrency";
import {
  message,
  Select,
  Dropdown,
  Modal,
} from "antd";
import { useNavigate } from 'react-router';
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {updateServiceUUIDMap} from "../../../Store/serviceOrderDetailDataSlice";

const AccountOverview = (props) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const [other_reason, setOther_reason] = useState("");
  const [error, setError] = useState({});
  const [btnLbl, setBtnLbl] = useState("Deactivate");
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [reason, setReason] = useState('');
  const [otherReasonVisible, setOtherReasonVisible] = useState(false);
  // mapping STATUS for hardcoded
const STATUS = {
  draft: 'Draft',
  open: 'Open',
  'open-emailed': 'Open',
  'open-printed': 'Open',
  'overdue': 'Overdue',
  paid: 'Paid',
  void: 'Void',
  "CC Auto": "CC Auto"
};
  const setRecurring = (frequency) => {
    switch (frequency) {
    case 'twice_weekly':
      return 'Twice Weekly'
    case 'weekly':
      return 'Weekly'
    case 'bi_monthly':
      return 'Every Other Week'
    case 'monthly':
      return 'Monthly'
    case 'once':
      return 'One-Time'
    default:
      return ''
    }
  }
  const[state,setState] = useState({
    serviceOrderList:[],
    loader:false,
    lastThreePaymentList:{},
    invoiceList:[]
  });
  const [customer, setCustomer] = useState({});
  const dispatch = useDispatch();
  const serviceOrderDetailState = useSelector((state) => state.serviceOrderAddedData);
  function getState(){
    setState({
      serviceOrderList:[],
      loader:false,
      lastThreePaymentList:{},
      lastThreeInvoice:{},
      invoiceList:[]
    })
  }

  useEffect(()=>{
    getInitialData();
  },[props?.record?.uuid]);

  const getInitialData = ()=>{
    setBtnLbl(props.record?.is_active ? "Deactivate" : "Reactivate");
    getState();
    getCustomerInfo();
    const serviceOrderList = async () => {
      const data = await getServiceOrderByClient({field_office_uuid:props?.record?.field_office?.uuid,client_uuid:props?.record?.uuid});
      let soUUIDMap = []
      data.forEach(function (item) {
        let obj = {}
        obj['name'] = item.service_order_number
        obj['uuid'] = item.uuid
        soUUIDMap.push(obj)
      });
      dispatch(updateServiceUUIDMap(soUUIDMap))
      setState((state)=>(
        {
        ...state,
        serviceOrderList:data.length>0?data:[]
        }
      ))
    }
    serviceOrderList();

    // Last 3 Payment
    const lastThreePayment = async () => {
      const data = await getLastThreePayment({user__uuid:props?.record?.uuid});
      setState((state)=>(
        {
        ...state,
        lastThreePaymentList:data
        }
      ))
    }
    lastThreePayment();

      // Last 3 Invoice
    const lastThreeInvoice = async () => {
      const data = await getLastThreeInvoice({user__uuid:props?.record?.uuid});
      setState((state)=>(
        {
        ...state,
        lastThreeInvoice:data
        }
      ))
    }
    lastThreeInvoice();

    // Last 3 Invoice
    const getInvoiceList = async () => {
      const data = await getInvoice({user__uuid:props?.record?.uuid})
      setState((state)=>(
        {
        ...state,
        invoiceList:data?.results
        }
      ))
    }
    getInvoiceList()
  }

  //Get customer data
  const getCustomerInfo = async() =>{
    const data = await getCommercialCustomer(props?.record?.uuid);
    setCustomer(data);
  }

      // convert date time from utc to local time
  const UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  const localToUTC = (date) => {
    return moment.utc(date)
  }

  const renderContractform = (item) => {
    //Service Order
    let url = "";
    if (item.contract_forms.length > 0) {
      let t = item.contract_forms[0];
      url = "".concat(t.form_url.replace(/\/$/, ""), "/", t.form_data_uuid);
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {item.contract_forms[0].form_name}
        </a>
      );
    }
  };

  const renderActiveService =  (item, index) => {
    if (item?.is_active) {
      return (
        <React.Fragment key={`service${index}`}>
          <div className="position-relative">
            {renderContractform(item)}
          </div>
          <hr />
        </React.Fragment>
      );
    }
  };

  // format phone number
  const formatPhoneNumber = (phone) => {
    phone = phone?.replace(/\D/g, "");
    return (
    // (XXX) XXX-XXXX
    `(${phone?.substr(0, 3)})` + " " + phone?.substr(3, 3) + "-" + phone?.substr(6)
     );
  }

  const onDeactivate = () => {
    setDeactivateModalVisible(true);
  }

  const onReactivate = () => {
    activateDeactivateUser();
    if(props?.source === "customers-lead-list")
      navigate('/customer-lead-list', { state: {currentTab: 'customer' }});
    else
      navigate(`/commercial/lead-list`, { state: {currentTab: 1 }});
  }

  const resetDeactivateModal = () => {
    setError({});
    setOther_reason("");
    setOtherReasonVisible(false);
    setReason("");
  }

  const activateDeactivateUser = async () => {
    const data =  {
      // "uuid": props.record?.uuid,
      "customer_status":props.record?.is_active ? "Deactivate" : "Activate",
      // "cancelation_reason":reason || '',
      }
      if(reason) {
        data.cancelation_reason = reason;
      }
      if(other_reason) {
        data.cancelation_details = other_reason;
      }
      const response = await activateDeactivatedCustomer(props.record?.uuid, data);
  }

  const validate = () => {
    const schema = yup.object().shape({
      reason: yup.string().required('Reason Is Required.'),
    });
    const data = { reason };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setError({});
        activateDeactivateUser();
        setDeactivateModalVisible(false);
        if(props?.source === "customers-lead-list")
          navigate('/customer-lead-list', { state: {currentTab: 'inactive_customer' }});
        else
          navigate(`/commercial/lead-list`, { state: {currentTab: 2 }});
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => { err_data[item.path] = item.message })
        setError(err_data)
        console.log(error);
        // setDeactivateModalVisible(false);
        // message.error("Something went wrong!");
      });
    }

      // render primary info
  const renderPrimaryInfo = () => {
    let phone_numbers = '', primaryEmail = '';
    if (!isEmpty(customer?.phone_numbers)) {
      for (let i = 0; i < customer?.phone_numbers?.length; i++) {
        const item = customer?.phone_numbers?.[i];
        if (item?.primary) {
          phone_numbers = item?.phone;
          break;
        }
      }
    }

    !isEmpty(customer?.emailaddress_set) && map(customer?.emailaddress_set, (item) => {
      primaryEmail = item?.primary && item?.email;
    });
    let authorizedUserName = ''
    map(sortBy(props?.record?.authorizedUser?.userList?.results, 'created_by'), (item, index) => authorizedUserName+= `${index !== 0 ? ', ' : ''}${item?.user_name}`)
    
    return (
      <div className="card">
        <div className="card-title flex">
          <h5>Primary Information</h5>
          {/* <button className="btn btn-warning view-all-btn" onClick={
            (e) => {
              // e.preventDefault();
              if (btnLbl === "Deactivate") {
                onDeactivate();
              } else {
                onReactivate();
              }
            }
          }>{btnLbl}</button> */}
        </div>
        <div className="card-body">
          <div className="flex flex-start mb-10">
            <div className="label">
              <b>Company Name:</b>
            </div>
            <div className="value">
              <b>{props?.record?.company_name}</b>              
            </div>
          </div>
          <div className="flex flex-start mb-10">
            <div className="label">
              <b>Primary User:</b>
              {/* <span>Authorized Users: </span> */}
            </div>
            <div className="value">
              <b>{customer?.first_name} {customer?.last_name}</b>            
              {/* <span>{authorizedUserName}</span> */}
            </div>
          </div>
          <div className="mb-10"><b>{!isEmpty(props?.record?.phone_numbers) ? phone_numbers ? `${formatPhoneNumber(phone_numbers)} (Primary)` : `${formatPhoneNumber(props?.record?.phone_numbers?.[0]?.phone)}` : ''}</b></div>
          <div><b>{`${primaryEmail ? primaryEmail : customer?.email} (Primary)`}</b> </div>
        </div>
      </div>
    )
  };

//render customer metrics
const renderMetrics = () => {
        return (
          <div className="card cust-metric">
            <div className="card-title">
              <h5>Customer Metrics</h5>
            </div>
            <div className="card-body">
              <div className="flex">
                <span>
                  <h2 className="success">0 <sub className={"cursor_pointer"}><i className="las la-external-link-alt" /></sub></h2>
                  Complaints in the past 30 days
                </span>
                <span>
                  <h2 className="success">0</h2>
                  Customer satisfaction score
                </span>
              </div>
            </div>
          </div>
        )
      };

const renderActiveServices = () => {
  const {serviceOrderList,loader} = state;
  // let items = serviceOrderList.filter((item) => item.is_active) || [];
  let items = serviceOrderList || [];

  const actives = items ? items?.filter((item) => {
    return item?.is_active && !item?.is_hide_from_display;;
  }): [];

  if(loader) {
    return <div className="card">
      <div className="card-title flex">
        <h5>Active Services</h5>
        <button className="btn btn-warning view-all-btn" onClick={(e) => {e.preventDefault(); props.tabChange(2) }}>View All</button>
      </div>
      <div className="card-body">

      </div>
    </div>
  }
    
        return (
          <div className="card">
            <div className="card-title flex">
              <h5> {props.status === "pending" ? "Inquired Services" : "Active Services"} </h5>
              <button className={props?.record?.is_active ?"btn btn-warning view-all-btn" : "btn btn-warning view-all-btn disable-field"} onClick={(e) => {e.preventDefault(); props.tabChange(2) }}>View All</button>
            </div>
            <div className="card-body">
              {
                actives.length === 0 && <div className="no-service text-center">
                  <img className="mr-5" src={noActiveService} alt=""/>
                  <h5>No Active Services</h5>
                  <p>Service orders will appear here when active.</p>
                </div>
              }
              {
                actives?.map((item, index) => {
                  // for pre-pay message
                  const expDate = moment(item?.initial_cleaning).add(6, 'month')
                  const diff = moment(expDate).diff(moment(), 'days');
                  const displayDiff = moment(expDate).fromNow();
                  const displayText = diff < 0 ? `expired for ${displayDiff}` : `will expires in the next ${displayDiff}`;
    
                  return <React.Fragment key={index}>
                    {!isEmpty(item?.service_address) ? <div className="service-status p-0 aoa-service">
                      <div className="active_service_list color-252730">
                        <div className="subs" onClick={(e) => {e.preventDefault(); props.tabChange(2) }}>
                          <div className="flex justify-content-start position-relative">
                            <strong className="snum">{item?.service_order_number}</strong>
                            <span className="color-1F1F25 opacity-5 fs-12">{item?.frequency ? `${setRecurring(item?.frequency)}` : ''}</span>
                          </div>
                          <div className="position-relative">
                            <span className="service-arrow ">
                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOBAMAAADgeEClAAAAAXNSR0IB2cksfwAAAB5QTFRFAAAAdHR0b29vcHBwbm5ub29vb29vbm5ua2trbm5uzpGQqQAAAAp0Uk5TAAtOOfVlRWQ5T+9n3DQAAAAkSURBVHicYxBiAAKTACDB5gpipqAz3WEEiAuWgDNaQAokgRgAy9cGGr87WN8AAAAASUVORK5CYII=" alt=""/>
                            </span>
                            <h5 className={props.status === "pending" ? "sadd1 mb-4" : "sadd mb-4"}>{!isEmpty(item?.service_address) ? `${item?.service_address?.address}, ${item?.service_address?.city}, ${item?.service_address?.state} ${item?.service_address?.zip_code}` : '-'}</h5>
                            <span className="altude pl-20 opacity-5 fs-11">{!isEmpty(item?.service_address?.geo_location) ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}` : ``}</span>
                            {/* <hr/> */}
                          </div>
                        </div>
                      </div>
                    </div> :
                      <></>
                    }
                    {item?.is_active ? renderActiveService(item, actives.length): <></>}
                    {/*
                      actives &&
                      actives.length > 0 && (
                        <div >
                          {map(actives, renderActiveService(actives.length))}
                        </div>
                      )*/}
                    {(item?.calculated_price?.is_prepaid && diff <= 30) &&
                      <blockquote className="horiz">
                        <b>This service is on a pre-pay plan. This {displayText}.</b>
                        <p className="mb-0">This customer needs to update their pre-pay plan, or move to monthly payments.</p>
                      </blockquote>}
                  </React.Fragment>
                })
              } 
              {/* {this.state.totalServiceOrders > 5 && <center><Pagination onChange={(page) => {this.fetchServiceOrders(page)}} size="small" current={this.state.serviceOrderPage || 1} total={this.state.totalServiceOrders || 5} showTotal={false} pageSize={5}/></center>} */}
            </div>
          </div>
        )
      }

  //render private notes
  const renderPrivateNotes = () => {
    return (
    <div className={!props?.record?.is_active && "disable-field"}>
    <PrivateNotes
      customerId={props?.record?.uuid}
    />
    </div>
    );
  };

    // render invoice
    const renderInvoice = () => {
      const {cardList, invoiceList, invoiceSummary, customer, lastThreePaymentList,last_th_cr_memo} = state;
      let lastThreePaymentListArr = [];
      let lengthLastThreePaymentList = lastThreePaymentList.length;
      var size = lengthLastThreePaymentList>3?3:lengthLastThreePaymentList;
      lastThreePaymentListArr = lengthLastThreePaymentList>0?lastThreePaymentList.slice(0, size):[];
      let invoice = [],  payments = [], overdue_days = 0
      map(invoiceList, x => {
        if (x.status === 'overdue') overdue_days = moment(x.due_date).diff(moment(), 'days')
        // if(x.status!=='draft'){
          invoice.push({date: x.invoice_date, amount: x.total_with_tax, type: x.status, uuid: x.uuid, service_order_uuid: x.service_order_uuid})
        // }
      })
      map(invoiceList, x => {
        if (x.status === 'paid') {
          payments.push({date: x.invoice_date, amount: x.total_with_tax, type: x.payment_method_type, uuid: x.uuid, service_order_uuid: x.service_order_uuid})
        }
      })
  
      // let isCardExpired = false, cardDetail = {}, displayText = '';
      // const list = cardList?.results || [];
      // for (let i = 0; i < list?.length; i++) {
      //   const item = list[i];
      //   const expDate = `${item?.stripe_card_details?.exp_month}/01/${item?.stripe_card_details?.exp_year}`;
      //   const diff = moment(expDate).diff(moment(), 'days');
      //   const displayDiff = moment(expDate).fromNow();
      //   displayText = diff < 0 ? `expired for ${displayDiff}` : `will expire ${displayDiff}`;
      //   if (diff <= 60) {
      //     isCardExpired = true;
      //     cardDetail = item;
      //     break;
      //   }
      // }
  
      return (
        <div className="card invoice-payment">
          <div className="card-title flex">
            <h5>Invoicing</h5>
            <button className={props?.record?.is_active ?"btn btn-warning" : "btn btn-warning disable-field"} onClick={(e) => {e.preventDefault(); props.tabChange(4) }}>View Invoices</button>
          </div>
          <div className="card-body p-0">
          <span className="smhead">Last 3 Invoices</span>
          {invoice?.length > 0 ?
            map(invoice.slice(-3), (item, index) =>
              <a><div className={`flex scell ${item.type === 'overdue' ? 'overdue' : ''} cursor_pointer`} key={`invoice${index}`} onClick={() => {
                // this.props.onInvoiceLineClick(item?.uuid, item?.service_order_uuid)
                props.tabChange(4)
              }}>
                <span className="sdate">{
                  item?.type === 'overdue' ?
                    <u>{moment(item?.date).format('MMMM YYYY')}</u> : moment(item?.date).format('MMMM YYYY')}</span>
                <div className="sdetail flex">
                  <span className="amt">${formatToCurrency(item?.amount)}</span>
                  <span className={`stats-color ${item.type === 'overdue' ? 'danger' : 'success'}`}><b>{STATUS[item?.type]}</b></span>
                </div>
              </div></a>
            ) : <div className="flex scell">-</div>
          }
        </div>
        <div>
          <span className="smhead">Last 3 Payments Made</span>
          {lastThreePaymentListArr?.length > 0 ?
            map(lastThreePaymentListArr, (item, index) =>
              <a><div  className="flex scell cursor_pointer" key={`invoice${index}`} onClick={() => {
                // this.props.onInvoiceLineClick(item?.uuid, item?.service_order_uuid)
                // this.props.onInvoicePaymentHistoryClick(item?.invoice_uuid)
                props.tabChange(4)
              }}>
                <span className="sdate">{moment(item?.created_at).format('MMMM YYYY')}</span>
                <div className="sdetail flex">
                  <span className="amt">${formatToCurrency(item?.amount)}</span>
                  <span className="stats-color success"><b>{item?.payment_mode}</b></span>
                </div>
              </div></a>
            ) : <div className="flex scell">-</div>
          }
        </div>
          {/* <div className="card-body">
            <div className="flex flex-start">
              <h3 className="danger mb-0"><span className="status inactive">Suspended</span></h3>
            </div>
            <span className="danger d-block xshead mt-10"><b>{0} days over due</b></span>
              <blockquote className="suspend mt-10">
                <span className="danger d-block mb-10"><b>{`This customer’s credit card ${''}. Please contact them to update`}</b></span>
                <button
                  className="btn btn-danger">
                  Send Reminder Email</button>
              </blockquote>
          </div> */}
        </div>
      )
    };

    return(
    <div className="block-row">
        <div className="card-row show-lg">
          {/* {loader && <Loader />} */}
          <div className="card-col">
            {renderPrimaryInfo()}
            {/* {renderMetrics()} */}
            {renderActiveServices()}
          </div>          
          <div className="card-col">
            {renderPrivateNotes()}            
            {renderInvoice()}
          </div>
          {/* {emailReminderModal && <EmailRemainder
            visible={emailReminderModal}
            customer={customer}
            cardDetail={cardDetail}
            handleOk={(obj) => this.props.sendEmailReminder(obj)}
            handleCancel={() => this.toggleEmailRemainder(false)}
          />} */}
        </div>
        <div className="card-row hide-lg">
          {/* {loader && <Loader />} */}
          <div className="card-col">
            {renderPrimaryInfo()}
            {/* {props?.record?.status !== "pending" && renderMetrics()} */}
          </div>
          <div className="card-col">
            {renderActiveServices()}
          </div>
          <div className="card-col">
            {renderPrivateNotes()}
          </div>
          <div className="card-col">
            {renderInvoice()}
          </div>
          {/* {emailReminderModal && <EmailRemainder
            visible={emailReminderModal}
            customer={customer}
            cardDetail={cardDetail}
            handleOk={(obj) => this.props.sendEmailReminder(obj)}
            handleCancel={() => this.toggleEmailRemainder(false)}
          />} */}
        </div>
        <Modal
        open={deactivateModalVisible}
        okText={btnLbl}
        mask={true}
        onOk={validate}
        onCancel={() => {resetDeactivateModal();setDeactivateModalVisible(false)}}
        cancelText={"Cancel"}
        className="delete_user_modal"
        width={400}
      >
        <h4>{`${btnLbl} Customer`}</h4>
        <span>Please provide a reason for deactivating this user</span>
        <br />
        <div className="field-group">
          <label>
            Reason<sup>*</sup>
          </label>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={"Select reason"}
            value={reason || ""}
            onChange={(e) => {
              setReason(e);
              if (e === 'other') {
                setOtherReasonVisible(true);
              } else {
                setOtherReasonVisible(false);
              }
            }}
          >
            <Option value="">Select reason</Option>
            <Option value="service">Service Quality</Option>
            <Option value="moving">Moving</Option>
            <Option value="competition">Pricing</Option>
            <Option value="other">Other</Option>
          </Select>
          {error?.["reason"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {error?.["reason"]}
            </label>
          ) : (
            ""
          )}
        </div>
        {otherReasonVisible && <div className="form-group mb-20">
          <div className="frow">
            <textarea
              placeholder="Add cancellation details here."
              name="other_reason"
              rows="5"
              value={other_reason}
              onChange={(e) => {
                setOther_reason(e.target.value);
              }}></textarea>
          </div>
        </div>}
        <span>All the scheduled and forecast workorders will be deleted.</span>
      </Modal>
      </div>
    )
}
export default AccountOverview