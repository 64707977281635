import React, { useEffect, useState } from "react";
import { Select, ConfigProvider, Empty, Spin } from "antd";
import { orderBy, isEmpty } from "lodash";
import { getFranchiseListAll } from "../../../Action/Franchise";
import {
  getFranchiseSelected,
  setFranchiseSelected,
} from "../../../Utils/selectedFranchise";

const { Option } = Select;

const FranchiseSelector = ({ onControlChange = () => {} }) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const is_fo = userInfo?.user?.user_roles.find(
    (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
  );
  let is_lead_tech = false;
  Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
    if (element[1]) {
      //If the user has at least one permission as true, is teach lead
      is_lead_tech = true;
    }
  });
  const role = is_fo
    ? "franchise_owner"
    : userInfo?.user?.is_lead_technician && is_lead_tech
    ? "lead_tech"
    : userInfo?.user?.user_roles[0]?.name;

  const [loading, setLoading] = useState(true);
  const [franchiseList, setFranchiseList] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [allValue, setAllValue] = useState(role === "admin" ? false : true);

  useEffect(() => {
    const getFranchiseList = async () => {
      setLoading(true);
      setFranchiseList([]);
      const data = await getFranchiseListAll();
      if (isEmpty(data)) {
        setFranchiseList([]);
        return;
      }
      const ordererFranchiseList = orderBy(
        data,
        [(item) => item.name.toLowerCase()],
        "asc"
      );
      let franSelected = getFranchiseSelected()?.uuid
        ? { value: getFranchiseSelected().uuid }
        : null;
      if (franSelected?.value === "all") {
        franSelected.label = "all";
        franSelected.all = ordererFranchiseList;
      }
      if (allValue && data.length > 0) {
        setSelectedFranchise(franSelected || "all");

        onControlChange(
          franSelected || {
            value: ordererFranchiseList[0].uuid,
            label: "all",
            address: {},
            all: ordererFranchiseList,
          }
        );
      } else {
        switch (true) {
          case role === "admin":
            const franchise_user = userInfo.user.associated_field_offices[0];
            setSelectedFranchise(
              franSelected || {
                value: franchise_user?.uuid,
                label: franchise_user?.name,
                address: {
                  address: franchise_user?.address,
                  city: franchise_user?.city,
                  state: franchise_user?.state,
                  zip_code: franchise_user?.zip_code,
                },
              }
            );
            onControlChange(
              franSelected || {
                value: franchise_user?.uuid,
                label: franchise_user?.name,
                address: {
                  address: franchise_user?.address,
                  city: franchise_user?.city,
                  state: franchise_user?.state,
                  zip_code: franchise_user?.zip_code,
                },
              }
            );
            break;
          case role === "franchise_owner":
          case role === "operations_mgr":
            setSelectedFranchise(
              franSelected || {
                value: ordererFranchiseList[0]?.uuid,
                label: ordererFranchiseList[0]?.name,
                address: {
                  address: ordererFranchiseList[0]?.address,
                  city: ordererFranchiseList[0]?.city,
                  state: ordererFranchiseList[0]?.state,
                  zip_code: ordererFranchiseList[0]?.zip_code,
                },
              }
            );
            onControlChange(
              franSelected || {
                value: ordererFranchiseList[0]?.uuid,
                label: ordererFranchiseList[0]?.name,
                address: {
                  address: ordererFranchiseList[0]?.address,
                  city: ordererFranchiseList[0]?.city,
                  state: ordererFranchiseList[0]?.state,
                  zip_code: ordererFranchiseList[0]?.zip_code,
                },
              }
            );
            break;
          case role === "field_tech" || role === "lead_tech":
            setSelectedFranchise(
              franSelected || {
                value: userInfo.user.associated_field_offices[0]?.uuid,
                label: userInfo.user.associated_field_offices[0]?.name,
                address: {
                  address: ordererFranchiseList[0]?.address,
                  city: ordererFranchiseList[0]?.city,
                  state: ordererFranchiseList[0]?.state,
                  zip_code: ordererFranchiseList[0]?.zip_code,
                },
              }
            );
            onControlChange(
              franSelected || {
                value: userInfo.user.associated_field_offices[0]?.uuid,
                label: userInfo.user.associated_field_offices[0]?.name,
                address: {
                  address: ordererFranchiseList[0]?.address,
                  city: ordererFranchiseList[0]?.city,
                  state: ordererFranchiseList[0]?.state,
                  zip_code: ordererFranchiseList[0]?.zip_code,
                },
              }
            );
            break;
          default:
            setSelectedFranchise(
              franSelected || {
                value: userInfo.user.associated_field_offices[0]?.uuid,
                label: userInfo.user.associated_field_offices[0]?.name,
              }
            );
            onControlChange(
              franSelected || {
                value: userInfo.user.associated_field_offices[0]?.uuid,
                label: userInfo.user.associated_field_offices[0]?.name,
              }
            );
            break;
        }
      }
      setFranchiseList(ordererFranchiseList);
      setLoading(false);
    };
    getFranchiseList();
  }, []);

  const updateFranchise = (selectedFranchise, options) => {
    if (selectedFranchise === "all") {
      setSelectedFranchise("all");

      onControlChange({
        value: franchiseList[0].uuid,
        label: "all",
        address: {},
        all: franchiseList,
      });
    } else {
      const franchise = franchiseList.find((i) => i.uuid === selectedFranchise);
      const address = {
        address: franchise?.address,
        city: franchise?.city,
        state: franchise?.state,
        zip_code: franchise.zip_code,
      };
      options = { ...options, address };
      setSelectedFranchise(selectedFranchise);
      onControlChange(options);
    }
    setFranchiseSelected(selectedFranchise);
  };

  const configureFranchiseSelector = () => {
    switch (true) {
      case role === "admin" ||
        role === "franchise_owner" ||
        role === "operations_mgr":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading franchise..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              showSearch
              value={selectedFranchise || undefined}
              onChange={(value, options) => updateFranchise(value, options)}
              placeholder={allValue ? "Franchise: All" : "Select a franchise"}
              className="swhite calendar-filter-franchise"
              disabled={franchiseList.length === 1}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {allValue && franchiseList?.length > 1 ? (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {"All"}
                </Option>
              ) : (
                <></>
              )}
              {franchiseList?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                  label={option?.name}
                >
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        );
      case role === "field_tech" || role === "lead_tech":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading franchise..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              value={selectedFranchise || undefined}
              onChange={(value, options) => updateFranchise(value, options)}
              placeholder={allValue ? "Franchise: All" : "Select a franchise"}
              className="swhite calendar-filter-franchise"
              disabled={franchiseList.length === 1}
            >
              {allValue && franchiseList.length > 1 ? (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {"All"}
                </Option>
              ) : (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {userInfo.user.associated_field_offices[0]?.name}
                </Option>
              )}
              {franchiseList?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                  label={option?.name}
                >
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        );
      default:
        return <></>;
    }
  };

  return configureFranchiseSelector();
};

export default FranchiseSelector;
