import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import invoiceSummaryReducer from './invoiceSummarySlice';
import customer from './customerSlice';
import zipCodeDataSlice from './zipCodeDataSlice';
import techJourneyDataSlice from './techJourneyDataSlice';
import priceCatalogDataSlice from './priceCatalogDataSlice';
import techNotesSlice from "./techNotesSlice";
import paymentSlice from "./paymentSlice";
import appDataSlice from "./appDataSlice";
import technicianTaskDataSlice from './technicianTaskDataSlice';
import serviceOrderDetailDataSlice from "./serviceOrderDetailDataSlice";
import fieldTechnicianListSlice from "./fieldTechnicianListSlice";
import invoiceDataSlice from "./invoiceDataSlice";
import accountDetailSlice from "./accountDetailSlice";
import dateSlice from "./dateSlice";
import proposalTableSlice from "./proposalTableSlice";
import ProposalDataSlice from "./ProposalDataSlice";


const store = configureStore({
  devTools: true,
  reducer: {
    cart: cartReducer,
    invoiceSummary: invoiceSummaryReducer,
    invoice: invoiceDataSlice,
    customer: customer,
    zipCodeData: zipCodeDataSlice,
    techJourney: techJourneyDataSlice,
    techNotes: techNotesSlice,
    priceCatalog: priceCatalogDataSlice,
    payment: paymentSlice,
    appData: appDataSlice,
    technicianTaskJourney: technicianTaskDataSlice,
    serviceOrderAddedData: serviceOrderDetailDataSlice,
    accountDetail: accountDetailSlice,
    fieldTechnicianListSlice: fieldTechnicianListSlice,
    dateSlice: dateSlice,
    proposalsData: proposalTableSlice,
    proposalDataSlice:ProposalDataSlice,
  },
});

export default store;