import axiosInstance from "../../Lib/Axios";
import { message } from "antd";
import Axios from "axios";
import { isEmpty } from "lodash";

// getJobs to plot
export const getJobs = async (params) => {
  try {
    const response = await axiosInstance.get(`/ftech/plotjobs/`, {
      params,
    });
    if (response?.data?.results) {
      response.data.results.map((each) => {
        if (each?.permissionset) {
          let per = each.permissionset;
          let temp = [];
          for (var key in per) {
            if (per[key]) {
              temp.push(key);
            }
          }
          each.permissionset = temp;
          const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
          if (userInfo?.user?.is_lead_technician) {
            let per = userInfo?.user?.technician_permissions;
            let temp_per = [];
            for (var key in per) {
              if (per[key]) {
                temp_per.push(key);
              }
            }
            each.permissionset = temp_per;
          }
          return {
            ...each,
          };
        }
      });
    }
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      if (error.response?.data?.detail) {
        message.error(error.response?.data?.detail);
      } else {
        message.error("Fail to request data");
      }
      return error;
    }
  }
};
