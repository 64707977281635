import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { orderBy } from "lodash";
import { Table, Drawer, Select, message } from 'antd';
import Header from '../../Components/Header';
import HeaderMobile from "../../Components/HeaderMobile";
import Loader from '../../Components/Loader';
import Sidebar from '../../Components/Sidebar';
import SidebarResponsive from "../../Components/SidebarResponsive";
import DataTable from '../Common/DataTable';
import AddFranchise from '../Common/AddFranchise';
import { getFranchiseData, createFranchise, getFranchiseListAll } from '../../Action/Franchise';

const { Option } = Select;
const FranchiseList = () => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))
    : "";

    const navigate = useNavigate();
    const [disableFranchiseDropdown, setDisableFranchiseDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [filterData, setFilterData] = useState({
        franchise: 'all',
    });
    const [franchiseList, setFranchiseList] = useState({});
    const [franchiseData, setFranchiseData] = useState([]);
    const [isAddNewFranchise, setIsAddNewFranchise] = useState(false);
    const [isAddFranchise, setIsAddFranchise] = useState(false);
    const [franchise_pagination, setFranchise_pagination] = useState({
        showSizeChanger: false,
        pageSize: 15,
        ordering: "",
        current: 1
    });
    const [active_pagination, setActive_pagination] = useState('15');
    const [isNav, setIsNav] = useState('');
    const [openNav, setOpenNav] = useState(false);
    const [is_crud_allow, setIs_crud_allow] = useState(true);
    const [openMenu, setOpenMenu] = useState(false);
    const onMenuButtonClick = () => {
      setOpenMenu(!openMenu);
    };

    // on customer list row
    const onFranchiseListRow = (record) => {
        return {
            onClick: () => {
                navigate(`/franchise/franchise-detail`, { state: { record: record } })
                localStorage.setItem('temp_franchise', JSON.stringify({ 'data': record }))                
            }
        }
    }
    // Show Table Nav
    const ShowNav = (record) => {
        if (!openNav) {
            document.addEventListener('click', handleOutsideClick, false);
        } else {
            document.removeEventListener('click', handleOutsideClick, false);
        }
        setOpenNav((prevState) => !prevState);
        setIsNav(record.uuid);
    }
    // hide list toggle button
    const handleOutsideClick = (e) => {
        // ignore clicks on the Component itself
        if (this?.node && this?.node?.contains(e.target)) {
            return;
        }
        ShowNav({ 'uuid': '' }, '');
    }
    //Call New Franchise API
    const createNewFranchise = async (newData) => {
        closeAddDrawer(false);
        setLoader(true);
        const data = await createFranchise(newData);
        if (data?.name) {
            setLoader(false);
            getDataFranchise({
                limit: 15,
                offset: 0,
            });
            message.success("Franchise added successfully.");
        } else {
            setLoader(false);
            message.error("Something went wrong!");
        }
    }
    // Get Franchise datas for Table
    const getDataFranchise = async (data) => {
        setLoader(true);
        const franchise_list = await getFranchiseData(data);
        setFranchiseData(franchise_list);
        setLoader(false);
    }
    //Get Franchise list
    const getFranchiseList = async () => {
        setLoader(true);
        const data = await getFranchiseListAll();
        setFranchiseList(data ? data : []);
        setLoader(false);
    }
    //Render Table 
    const renderFranchiseTable = () => {
        return (
            <div className="leadstbl">
                <Table
                    rowKey={record => record.uuid}
                    dataSource={franchiseData?.results || []}
                    pagination={{ ...franchise_pagination, total: franchiseData?.count }}
                    onChange={onFranchiseHandelChange}
                    columns={DataTable.AllFranchiseList({ ...franchiseData, ShowNav, isNav, openNav, is_crud_allow })}
                    onRow={onFranchiseListRow}
                    // onRow={(record) => {onFranchiseListRow(record)}}
                    className="table hscroll"
                />
                <ul className="pagination_page_size">
                    <li>
                        <span>Show:</span>
                    </li>
                    <li>
                        <span
                            onClick={() => { setUserPageLimit('15') }}
                            className={active_pagination === '15' ? 'active' : ''}
                        >15</span>
                    </li>
                    <li>
                        <span
                            className={active_pagination === '30' ? 'active' : ''}
                            onClick={() => { setUserPageLimit('30') }}>30</span>
                    </li>
                    <li>
                        <span
                            className={active_pagination === '60' ? 'active' : ''}
                            onClick={() => { setUserPageLimit('60') }}>60</span>
                    </li>
                </ul>
            </div>
        )
    }
    //set page limit
    const setUserPageLimit = (custom_limit) => {
        setFranchise_pagination({
            ...franchise_pagination,
            current: 1,
            pageSize: custom_limit
        });
        setActive_pagination(custom_limit);
        setLoader(true);
        let data = {
            offset: 0, 
            limit: custom_limit, 
            ordering: '', 
            search: searchValue, 
            uuid: filterData?.franchise === 'all' ? "" : filterData?.franchise
        }
        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);        
        getDataFranchise(data);
    }
    //Table Pagination and Ordering
    const onFranchiseHandelChange = (pagination, filters, sorter) => {
        setFranchise_pagination({
            ...franchise_pagination,
            current: pagination.current,
        });
        let ordering = ''
        if (sorter.column) {
            ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
        }
        else {
            ordering = ''
        }
        let data = {
            offset: (pagination.current * 15) - 15,
            limit: active_pagination,
            ordering,
            search: searchValue,
        }
        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
        // this.props.getFieldOffice(data)
        getDataFranchise(data);
        setLoader(true);
    }
    //Close drawer
    const closeAddDrawer = (type) => {
        setIsAddFranchise(type);
    }
    const leadingZeros = (value, size) => {
        let s = String(value);
        while (s.length < (size || 2)) { s = "0" + s; }
        return s;
    }
    const onEnterSearchValue = (e) => {
        if (e.target.value.length >= 3) {
            setSearchValue('');
            setLoader(true);
            searchTicket(e);
        } else {
            alert("Please type at least 3 character.");
        }
    }
    const searchTicket = (e) => {
        if (e.target.value?.length >= 3 || e.target.value?.length === 0) {
            getDataFranchise({ 'search': e.target.value, 'offset': 0, 'limit': 15 });
        }
        setSearchValue(e.target.value);
    }
    const onFilterChange = (e, type) => {
        const value = type === 'bool' ? e.target.checked : e.target.value;
        setFilterData({ [e.target.name]: value });
    }
    useEffect(() => {
        if (filterData?.franchise && filterData?.franchise !== 'all') {
            // setLoader(true);
            getDataFranchise({
                uuid: filterData?.franchise
            });
        } else if (filterData?.franchise === 'all') {
            getDataFranchise({
                limit: 15,
                offset: 0,
            });
        }
    }, [filterData]);
    useEffect(() => {
        getDataFranchise({
            limit: 15,
            offset: 0,
        });
        getFranchiseList();

        if(user.user.role[0] === "franchise_owner" || user.user.role[0] === "operations_mgr"){
            setIsAddNewFranchise(true)
        }        
    }, []);
    return (
        <div id="wrapper">
            <div className="content-area">
                {/* Header */}
                <Header
                    placeholder="ID & Name, Owner, Address"
                    search_value={searchValue}
                    onSearch={(e) => searchTicket(e)}
                    onEnter={(e) => onEnterSearchValue(e)}
                />
                <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
                {/* Sidebar */}
                <Sidebar sideBarBackgroundColor='#005d89' />
                <SidebarResponsive
                    sideBarBackgroundColor="#005d89"
                    openMenu={openMenu}
                    onMenubuttonClick={onMenuButtonClick}
                />
                <div className="container">
                    {/* Tool Section */}
                    <div className="filter_wrapper">
                    {user.user.role[0] !== "franchise_owner" && user.user.role[0] !== "operations_mgr" &&
                        <div className="left_side">
                            <Select
                                className="swhite"
                                placeholder="Select a Franchise"
                                name="franchise"
                                disabled={disableFranchiseDropdown}
                                onChange={(e) => onFilterChange({ target: { name: 'franchise', value: e } }, '', 'franchise')}
                                value={filterData?.franchise || undefined}>
                                <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>
                                {
                                    orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) =>
                                        <Option key={option?.uuid} value={option?.uuid}>{`${leadingZeros(option?.id, 3)} ${option?.name}`}</Option>)
                                }
                            </Select>
                        </div>
}
                        <div className="right_side">
                            <span className="ico-btn" ><i className="las la-filter"></i></span>
                            {user.user.role[0] !== "franchise_owner" && user.user.role[0] !== "operations_mgr" && <button
                                // disabled={is_add_new_franchise ? false : true}
                                disabled={isAddNewFranchise}
                                onClick={() => closeAddDrawer(true)}
                                className={!isAddNewFranchise ? "btn btn-warning" : "btn btn-brand btn-bdr disbale_btn"}
                            >Add New Franchise</button>}
                        </div>
                    </div>
                    {/* Table */}
                    {renderFranchiseTable()}
                    {/* Add Franchise Drawer */}
                    <Drawer
                        visible={isAddFranchise}
                        width={'420'}
                        closable={false}
                        className="drawer-wrapper create_ticket_drawer"
                    >
                        <AddFranchise
                            closeAddDrawer={closeAddDrawer}
                            createNewFranchise={createNewFranchise}
                        //Reducer
                        // UserList={this.props.UserList}
                        // FranchiseAdd={this.props.FranchiseAdd}
                        // GoogleApi={this.props.GoogleApi}
                        // API Call
                        // addFieldOffice={this.props.addFieldOffice}
                        // getFieldOffice={this.props.getFieldOffice}
                        // getFranchiseList={this.props.getFranchiseList}
                        // getUserList={this.props.getUserList}
                        // getGeocodeAddress={this.props.getGeocodeAddress}
                        // resetGeocodeAddress={this.props.resetGeocodeAddress}
                        />
                    </Drawer>
                </div>
            </div>
            {loader && <Loader />}
        </div >
    );
}

export default FranchiseList;