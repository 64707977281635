import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTechNotes } from "../Action/Calendar";

const initialState = {
  techNotes: [],
  status: "idle",
  error: null,
};

export const fetchTechNotes = createAsyncThunk(
  "techNotes/fetchTechNotes",
  async (params) => {
    const response = await getTechNotes(params);
    return response?.results ? response?.results : [];
  }
);

const techNotesSlice = createSlice({
  name: "techNotes",
  initialState,
  reducers: {
    techNotesRemove(state, action) {
      state.techNotes = [];
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTechNotes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTechNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.techNotes = action.payload;
      })
      .addCase(fetchTechNotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { techNotesRemove } = techNotesSlice.actions;
export const selectAllTechNotes = (state) => state.techNotes.techNotes;

export default techNotesSlice.reducer;
