import React from 'react';
import { Radio, Space,Button } from 'antd';

const optionsSelector = [
    { label: "Account level Invoice", value: true },
    { label: "None", value: false },
];

const BatchInvoicePreference = ({ value, handleChange,handleClick }) => {

    return <div className="card">
                <div className="card-title flex">
                    <h5>Invoice Preference</h5>
                </div>
                <Radio.Group onChange={handleChange} value={value} style={{ padding: '20px 0px 20px 25px' }}>
                    <Space direction="vertical">
                        {optionsSelector.map((option) => {
                            return <Radio value={option.value}>{option.label}</Radio>
                        })} 
                    </Space>
                </Radio.Group>
                <div className='btn-update-invoice'>
                <Button className='btn btn-warning' onClick={handleClick}>Update</Button>
                </div>
               
           </div>
}

export default BatchInvoicePreference;