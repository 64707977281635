import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader";
import _, { map } from "lodash";
import moment from "moment-timezone";
import AccountOverviewFranchise from "./AccountOverviewFranchise";
import StandradServicePrice from "./StandradServicePrice";
import PoolType from "./PoolType";
import CustomizedServices from "./CustomizedServices";
import FranchiseAlerts from "./FranchiseAlerts";
import SetupOptions from "./SetupOptions";
import FranchiseServiceNotification from "./FranchiseServiceNotification";
import PricingList from "./PricingList";
import AutoScheduling from "./AutoScheduling";
import { useForm } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import { loginCallbackQuickbook } from "../../Action/Franchise";
import { message } from "antd";
import ProposalSetUpFranchise from "./ProposalSetup";
const tabs = [
  "OVERVIEW",
  // "STANDARD SERVICE PRICE",
  // "POOL TYPE",
  // "CUSTOMIZED SERVICES",
  // "FRANCHISE ALERTS",
  "SETUP OPTIONS",
  "FRANCHISE SERVICE NOTIFICATION",
  // "PRICING LIST",
  "AUTO SCHEDULING",
  "PROPOSAL SETUP"
];
const FranchiseDetail = (props) => {
  const [loader, setLoader] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [state, setState] = useState({});
  const [isDisabledSetupQuickbook, setisDisabledSetupQuickbook] =
    useState(false);
  const { location } = useLocation();

  const tabChange = (e) => {
    setCurrentTab(e);
  };
  useEffect(()=>{
    const franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    if (franchise_data) {
      setState((s) => ({ ...s, record: franchise_data.data }));
    }
  },[currentTab])
  useEffect(() => {
    const franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    if (franchise_data) {
      setState((s) => ({ ...s, record: franchise_data.data }));
    }
    const callQuickBook = async () => {
      //for quickbooks
      if (window.location.search && !isEmpty(franchise_data)) {
        setisDisabledSetupQuickbook(true);
        setLoader(true);
        const search = window.location.search;
        const response = await loginCallbackQuickbook(
          franchise_data?.data?.uuid,
          search
        );
        setLoader(false);
        const { is_quickbooks_connected } = response;
        if (is_quickbooks_connected) {
          setCurrentTab(1); //Select QB tab
        }
        if (response.error) {
          message.error("QuickBook connection error");
          setisDisabledSetupQuickbook(false);
        }
        if(response[0]=== "already_conected") {
          message.warn("QuickBook already connected");
          setCurrentTab(1); //Select QB tab
        }
      }
    };

    callQuickBook();
  }, []);

  const updateFranchiseData = (data) => {
    localStorage.setItem("temp_franchise", JSON.stringify({ data: data }));
      };
  
  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return (
          <AccountOverviewFranchise
            tabChange={tabChange}
            data={state?.record}
            onFranchiseUpdate={(record) => {
              setState({...state, record: record?.data })
            }}
          />
        );
      case 1:
        return (
          <SetupOptions
            tabChange={tabChange}
            franchise_data={state?.record}
            updateFranchiseData={updateFranchiseData}
            disabledSetupQuickbook={isDisabledSetupQuickbook}
          />
        );
          case 2:
           return(<FranchiseServiceNotification  tabChange={tabChange} franchise_data={state?.record}/>);
          case 3:
           return <AutoScheduling  tabChange={tabChange} franchise_data={state?.record}/>
           case 4:
           return <ProposalSetUpFranchise tabChange={tabChange} franchise_data={state?.record}
           />
      //Remove once the other tabs are available
      // case 1:
      //     return <StandradServicePrice  tabChange={tabChange} franchise_data={state?.record}/>
      // case 3:
      //     return <CustomizedServices  tabChange={tabChange} franchise_data={state?.record}/>
      // case 4:
      //     return <FranchiseAlerts  tabChange={tabChange} franchise_data={state?.record}/>
      // case 5:
      //      return <SetupOptions  tabChange={tabChange} franchise_data={state?.record}/>
      // case 6:
      //      return <FranchiseServiceNotification  tabChange={tabChange} franchise_data={state?.record}/>
      // case 7:
      //      return <PricingList  tabChange={tabChange} franchise_data={state?.record}/>
      // case 8:
      //      return <AutoScheduling  tabChange={tabChange} franchise_data={state?.record}/>
      default:
        return (
          <AccountOverviewFranchise
            tabChange={tabChange}
            data={state?.record}
          />
        );
    }
  };
  return (
    <div id="wrapper">
      <div className="content-area franchisee">
        {/* Header */}
        <Header placeholder="ID & Name, Owner, Address" />

        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <div className="container">
          <div className="filter_wrapper">
            <ul className="breadcrumb">
              <li className={"cursor_pointer"}>
                <span
                  onClick={() =>
                    window.location.assign("/franchise/franchise-list")
                  }
                >
                  Franchises
                </span>
              </li>
              <li>
                <span>
                  {("00" + parseInt(state?.record?.id)).slice(-3)}{" "}
                  {state?.record?.name}
                </span>
              </li>
            </ul>
          </div>
          <div className="residential-wrapper">
            <div className="res-head pb-0">
              <div className="flex ai-center mb-20">
                <div className="">
                  <h3>
                    {("00" + parseInt(state?.record?.id)).slice(-3)}{" "}
                    {state?.record?.name}
                  </h3>
                </div>
              </div>
              <ul className="tabs dark">
                {map(tabs, (item, index) => (
                  <li
                    key={`tab${index}`}
                    className={index === currentTab ? "active" : ""}
                    onClick={(e) => setCurrentTab(index)}
                  >
                    <span className="flex ai-center">
                      {item}
                      {item.toLowerCase()?.includes("tickets") && (
                        <div className="count">0</div>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="step_wrapper">{renderTab()}</div>
          </div>
        </div>
      </div>
      {loader ? <Loader /> : ""}
    </div>
  );
};

export default FranchiseDetail;
