import React from "react";
import { PageHeader, Button, Row, Col } from "antd";
import asp_min_logo from '../Assets/images/asp-min-logo.png';
import userimg from "../Assets/images/userimg.jpg";
import { MenuOutlined, DollarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'

const HeaderMobile = ({onMenubuttonClick}) => {
    const user = JSON.parse(localStorage.getItem("authUser"))
        ? JSON.parse(localStorage.getItem("authUser"))
        : "";
    return (
        <div>
            <PageHeader
                className="page-header"
                onBack={() => null}
                style={{ background: '#005d89' }
                }
            >
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col span={8}>
                            <figure className="user-status-res">{user?.user?.photo ?
                                <img className="userimgheader" src={user.user.photo} alt="" />
                                : <img className="userimgheader" src={userimg} alt="" />}<small className="astatus"></small>
                            </figure>
                        </Col>
                        <Col span={8} className="text-center">
                            <Button type="primary" className="plain-btn" onClick={() => null}>
                                <img src={asp_min_logo} alt="" />
                            </Button>
                        </Col>
                        <Col span={3} className="text-end">
                            <Link to="/price-catalog"><Button type="primary"  icon={<DollarOutlined className="font-24"/>} size='large'></Button></Link>
                        </Col>
                        <Col span={2} className="text-end">
                            <Button type="primary" className="menu-button" icon={<MenuOutlined className="font-24"/>} size='large' onClick={onMenubuttonClick}/>
                        </Col>
                    </Row>
                </div>
            </PageHeader>
        </div>
    )
}

export default HeaderMobile;