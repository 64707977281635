import React,{useState,useEffect,useRef} from "react";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import { Table,Menu, Dropdown, message } from "antd";
import DataTable from "./Datatable";
import mockData from "./mockData";
import { map } from 'lodash';
import moment from 'moment-timezone';
import { DownOutlined } from "@ant-design/icons";
import { getCustomer, getCommercialCustomer } from '../../Action/Customer';
import { getProposal,sendEmailProposal,getDuplicate,getPrintProposal } from '../../Action/Proposal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Loader from "../../Components/Loader";
import { setProposalResponse, setSelectedRowCount, setExportFilters } from "../../Store/proposalTableSlice";
import ProposalFranchiseFilter from "../ProposalFranchiseFilter";
import ProposalDateFilter from "../ProposalDateFilter";
import ProposalWeekFilter from "../ProposalWeekFilter";
import ProposalFilterDrawer from "../ProposalFilterDrawer";
import ProposalsButtonSection from "../ProposalsButtonSection";
import ProposalHeaderSection from "../ProposalHeaderSection";
import DeactivateModal from "../../Customer/Common/Proposals/ProposalModals";
import ReactivateModal from "../../Customer/Common/Proposals/ReactivateProposal";
import "../../Assets/css/proposalPage.css";
import { openDeactivateModal,openActivateModal } from "../../Store/ProposalDataSlice";
const ProposalList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const proposalData = useSelector((state) => state.proposalsData);
    const proposalList = proposalData.proposalResponse
    const proposals = useSelector((state) => state.proposalDataSlice);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [limit, setLimit] = useState('15');
    const sortOrder = useRef('');
    const [current, setCurrent] = useState(1);
    const [loader, setLoader] = useState(false);
    const deactivateUUID = useRef('');
    const reactivateUUID = useRef('');
    const disableActions = useRef(false);
    let pageOffset = useRef<Number | null>([0]);
    const onMenuButtonClick = () => {
      setOpenMenu(!openMenu);
    };
  const getProposalList = async () => {
    if(proposalData?.franchiseSelected !== ''){
    setLoader(true);
    let proposalPayload = {
      offset: pageOffset,
      limit: limit,
      ordering: sortOrder.current,
      proposal_date_range: proposalData?.startDate !== null ? `${moment(proposalData?.startDate).format('YYYY-MM-DD')},${moment(proposalData.endDate).format('YYYY-MM-DD')}` : null,
      service_type: proposalData?.filterButtonFlag === true ? ((proposalData?.proposalServiceTypeSelected !== "all" && proposalData?.proposalServiceTypeSelected[0] !== "all" )? proposalData?.proposalServiceTypeSelected.join(",") : "maintenance,service,construction,renovation") : "",
      proposal_status : proposalData?.filterButtonFlag === true ? ((proposalData?.proposalStatusSelected !== "all" && proposalData?.proposalStatusSelected[0] !== "all") ? proposalData?.proposalStatusSelected.join(",") : "sent,accepted,declined,needs_review,expired,closed,deactivated") : "",
      proposal_user_type : proposalData?.filterButtonFlag === true ? ((proposalData?.customerTypeSelected !== "all" && proposalData?.customerTypeSelected[0] !== "all") ? proposalData?.customerTypeSelected.join(",") : "residential,commercial,lead_residential,lead_commercial") : "",
    }
    if (proposalData?.franchiseSelected !== 'all') {
      proposalPayload.field_office__uuid = proposalData.franchiseSelected;
    }
    else{
      proposalPayload.field_office__uuid = ''
    }
    dispatch(setExportFilters(proposalPayload));
    const response = await getProposal(proposalPayload)
    if (Object.keys(response)?.length > 0) {
      setLoader(false);
      dispatch(setProposalResponse(response))
    }
    setSelectedRowKeys([])
    dispatch(setSelectedRowCount([]));
    disableActions.current = false
    }
  }
  const onSelectedRowKeysChange = (selectedRowKeys) => {
    if(selectedRowKeys.length > 1){
      disableActions.current = true
    }
    else{
      disableActions.current = false
    }
    console.log('selectedRow',selectedRowKeys)
    setSelectedRowKeys(selectedRowKeys)
    dispatch(setSelectedRowCount(selectedRowKeys));
  }
  const setProposalPageLimit = (value) => {
    setLimit(value)
  };
  const createServiceOrder = (event,row) => {
    event.preventDefault();
    (async () => {
      let data;
      if (row?.proposal_user_type === 'residential') {
        data = await getCustomer(row?.client_uuid);
        navigate(`/residential/customer-detail`, {
          state: {
            record: {
              ...data,
              id: row?.client_uuid,
              proposalData: row,
              customer: row?.customer,
              selectedTab: 2,
              service: row?.service_order,
              from: 'viewByProposal',
              transection_type: "Add",
              field_office_uuid: row?.field_office_uuid,
            }
          }
        });
      } else {
        data = await getCommercialCustomer(row?.client_uuid);
        navigate(`/commercial/customer-detail`, {
          state: {
            record: {
              ...data,
              id: row?.client_uuid,
              proposalData: row,
              customer: row?.customer,
              selectedTab: 2,
              service: row?.service_order,
              from: 'viewByProposal',
              transection_type: "Add",
              field_office_uuid: row?.field_office_uuid,
            }
          }
        });
      }
    })();
  }
  const ondeactivate=(event,row)=>{
    event.preventDefault();
    deactivateUUID.current = row?.uuid
    dispatch(openDeactivateModal(true))
  }

  const refreshDeactivate = (saveClicked) => {
    if(saveClicked){
      setTimeout(() => getProposalList(), 1500);
    }
  }

  const onreactivate=(event,row)=>{
    event.preventDefault();
    reactivateUUID.current = row?.uuid
    dispatch(openActivateModal(true))
  }

  const refreshReactivate = (saveClicked) => {
    if(saveClicked){
      setTimeout(() => getProposalList(), 1500);
    }
  }
  
  const viewEditDuplicateProposal = (event,row,action) => {
    event.preventDefault();
    (async () => {
      let data;
      let duplicateRow;
      if(action === 'duplicate'){
        duplicateRow = await getDuplicate(row?.uuid)
      }
      if (row?.proposal_user_type === 'residential') {
        data = await getCustomer(row?.client_uuid);
        navigate(`/residential/customer-detail`, {
          state: {
            record: {
              ...data,
              id: row?.client_uuid,
              proposalData:action === 'duplicate' ? duplicateRow :row,
              customer: row?.customer,
              selectedTab: 3,
              service: row?.service_order,
              from: action === 'view' ? 'ProposalView' : action === 'duplicate' ? 'ProposalDuplicate' : 'ProposalEdit',
              transection_type: "Add",
              field_office_uuid: row?.field_office_uuid,
            }
          }
        });
      } else {
        data = await getCommercialCustomer(row?.client_uuid);
        navigate(`/commercial/customer-detail`, {
          state: {
            record: {
              ...data,
              id: row?.client_uuid,
              proposalData: action === 'duplicate' ? duplicateRow :row,
              customer: row?.customer,
              selectedTab: 3,
              service: row?.service_order,
              from: action === 'view' ? 'ProposalView' : action === 'duplicate' ? 'ProposalDuplicate' : 'ProposalEdit',
              transection_type: "Add",
              field_office_uuid: row?.field_office_uuid,
            }
          }
        });
      }
    })();
  }

  const onProposalTableChange = (pagination, filters, sorter) => {
    let newOffset = (pagination.current-1)*parseInt(limit)
    pageOffset = newOffset
    let ordering1 = sortOrder.current
    if (sorter.column) {
      ordering1 = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering1 = ''
    }
    sortOrder.current = ordering1
    setCurrent(pagination.current)
    getProposalList()
  }

  const printProposal = (event,row) => {
    event.preventDefault();
    getPrintProposal(row?.uuid)
  }

  const emailProposal = async (rec) => {
    let obj = rec?.uuid
    const response = await sendEmailProposal(obj);
    if (Array.isArray(response) && response.length > 0) {
      message.success(response[1], 5);
    }
    else {
      message.error(`Proposal Emailed Failed.`, 5);
    }
  }

   // Render table action menu
   const renderAction = (record) => {
    const menu = (
      <Menu className={disableActions.current ? "proposal-action-dd disableActions" : "proposal-action-dd"}>
        <Menu.Item key="1"><a href={'/#'} onClick={(e) => viewEditDuplicateProposal(e, record,'view')}>View Proposal</a></Menu.Item>
        {record.proposal_status !== 'accepted' && record.proposal_status !== 'closed' && record.is_active && record.proposal_status !== 'expired' ? (<Menu.Item key="2"><a href={'/#'} onClick={(e) => viewEditDuplicateProposal(e, record,'edit')}> Edit Proposal</a></Menu.Item>) : null }
        <Menu.Item key="3"><a onClick={(e) => emailProposal(record)}>Email/Resend Proposal</a></Menu.Item>
        <Menu.Item key="4"><a href={'/#'} onClick={(e) => printProposal(e, record)}>Print Proposal</a></Menu.Item>
        {record.proposal_status !== 'sent' && record.proposal_status !== 'needs_review' && record.proposal_status !== 'in_progress' && record.proposal_status !== 'enquiry_received' ? (<Menu.Item key="5"><a href={'/#'} onClick={(e) => viewEditDuplicateProposal(e, record,'duplicate')}>Duplicate Proposal</a></Menu.Item>) : null }
        {record.proposal_status !== 'accepted' && record.proposal_status !== 'closed' && record.is_active && record.proposal_status !== 'expired' ? (<Menu.Item key="6"><a href={'/#'} onClick={(e) => ondeactivate(e, record)}>Deactivate Proposal</a></Menu.Item>) : null }
        {!record.is_active ? (<Menu.Item key="6"><a href={'/#'} onClick={(e) => onreactivate(e, record)}>Reactivate Proposal</a></Menu.Item>) : null }
        {record.proposal_status === 'accepted'? (<Menu.Item key="7"><a href={'/#'} onClick={(e) => createServiceOrder(e, record)}>Create Service Order</a></Menu.Item>) : null }
      </Menu>
    )

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <span onClick={e => e.preventDefault()}>
          <span className="dout-text">Select</span> <DownOutlined />
        </span>
      </Dropdown>
    )
  }
  useEffect(() => {
    getProposalList();
  }, [proposalData?.franchiseSelected, proposalData?.startDate, proposalData?.endDate]);

  useEffect(() => {
    if (proposalData?.filterButtonFlag) {
      getProposalList();
    }
  }, [proposalData?.filterButtonFlag]);
 
return(
    <div className="content-area proposal-container">
     <ProposalHeaderSection />
     <div className="container">
     {loader && <Loader />}
       <div className="filter_wrapper">
         <div className="left_side">
           <ProposalFranchiseFilter />
           <ProposalDateFilter />
           <ProposalWeekFilter />
         </div>
         <div className="right_side but-sec">
           <ProposalFilterDrawer />
           <ProposalsButtonSection getProposalList={getProposalList} />
         </div>
       </div>
      </div>
      {/* <div>DEACTIVATE:{deactivateUUID.current}</div> */}
        <Table
          rowKey={record => record.uuid}
          dataSource={proposalData?.proposalResponse?.results}
          columns={DataTable.proposalColumns(renderAction)}
          className="customer-list field_serve"
          pagination={{ showSizeChanger: false, pageSize: limit, current, total: proposalList?.count }}
          rowSelection={{
            type: 'checkbox',
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys) => { onSelectedRowKeysChange(selectedRowKeys) },
          }}
          onChange={onProposalTableChange}
         />
         {proposalList?.results?.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setProposalPageLimit('15') }}
                className={limit === '15' ? 'active' : ''}
              >15</span>
            </li>
            <li>
              <span
                className={limit === '30' ? 'active' : ''}
                onClick={() => { setProposalPageLimit('30') }}>30</span>
            </li>
            <li>
              <span
                className={limit === '60' ? 'active' : ''}
                onClick={() => { setProposalPageLimit('60') }}>60</span>
            </li>
          </ul> : ''}
        <Sidebar sideBarBackgroundColor='#005d89' />
        <SidebarResponsive
        sideBarBackgroundColor="#005d89"
        openMenu={openMenu}
        onMenubuttonClick={onMenuButtonClick}
        />
      {proposals.deactivate_modal === true && <DeactivateModal proposalTableuuid={deactivateUUID.current} saveClick={refreshDeactivate} fromProposalTable={'fromProposalTable'}/>}
      {proposals.activate_modal === true && <ReactivateModal proposalTableuuid={reactivateUUID.current} saveClick={refreshReactivate} fromProposalTable={'fromProposalTable'}/>}
    </div>
    
    )
   
}

export default ProposalList;