import React, { useEffect, useState } from "react";
import { getServiceMatrix } from "../../../Action/Customer";
import {
  serviceMatrixApi,
  selectRateType,
} from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { map } from "lodash";
import "../../../Assets/css/proposalBase.css";


const PropsalRateType = (props) => {
  const{errors, editMode}=props;
  const dispatch = useDispatch(props);
  const proposals = useSelector((state) => state.proposalDataSlice);
  const onratetypeselection = (e) => {
    dispatch(selectRateType(e.target.value));
  };
  return (
    <div className="frow">
      {proposals?.service_type_name === "maintenance" && (
        <div>
            <h4>
        Select Rate Type<sup>*</sup>
      </h4>
         
        <div className="oplist">
          {map(proposals?.workTypesArray, (item, index) => (
            <div className="inline-checks" key={`category${index}`}>
              <label
                htmlFor={item.id}
                className={`${
                  proposals.select_rate === item.name ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  id={item?.id}
                  name="maintenanceCategory"
                  className=""
                  value={item.uuid}
                  disabled={editMode}
                  onChange={() =>
                    onratetypeselection({
                      target: {
                        name: "maintenanceCategory",
                        value: item.name,
                        price: 0,
                        key: item.name,
                      },
                    })
                  }
                />
                <div className="form-group">
                  <div className="fitem">
                    <span>{item.name}</span>
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      ({item.additional_text})
                    </span>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </div>
        {errors?.["select_rate"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["select_rate"]}
                    </label>
                  ) : (
                    ""
                  )}
        
        </div>
      )}
    </div>
  );
};
export default PropsalRateType;
