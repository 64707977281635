import React, { useEffect, useState } from 'react'

const Total = (props) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(props.priceDetail?.price);
  }, [props.priceDetail]);
  
  const saveData = (e) => {
    setValue(e.target.value);
    props.setPriceDetail((p) => ({ ...p, price: e.target.value }));
  }
  return (
    <div className="wlight">
      <div className="flex ai-center">
        <div className="flex-1">
          <h4 className="m-0">Total{props.priceDetail?.key ? props.priceDetail?.key === 'One Time' ? "" : " (Monthly Charge) :" : ""}</h4>
        </div>        
        <div className="pricing" style={{ 'width': '50%', 'position': 'relative' }}>
          <span style={{ 'position': 'absolute', 'left': '20%', 'paddingTop': '9px' }} >$</span>
          <input
            type="text"
            className='pricing pl-10'
            style={{ 'width': '100%', 'backgroundColor': '#f9ffd9', 'paddingLeft': '25%', 'textAlign': 'left', 'marginLeft': '8px' }}
            placeholder="Price"
            name="price"
            value={value}
            onChange={(e) => saveData(e)}
          />
        </div>
      </div>
    </div>
  )
}
export default Total;