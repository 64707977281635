import React, { useEffect, useState, useCallback } from "react";
import { Dropdown, Menu, Checkbox } from "antd";
import _ from "lodash";
import { FilterOutlined } from "@ant-design/icons";
import { getServices } from "../../../Action/Reports";

const ServiceTypeSelector = ({
  handleChange = () => {},
  serviceTypeList = [],
}) => {
  const [filterList, setFilterList] = useState([]);

  const handleCheckboxClick = (e) => {
    let checkedList = [];
    if (e.target.data.toLowerCase() === "all") {
      if (e.target.checked) {
        checkedList = serviceTypeList.map((staff) => staff.name);
      } else {
        checkedList = [];
      }
    } else {
      checkedList = e.target.checked
        ? [...filterList, e.target.data]
        : filterList.filter((item) => item !== e.target.data && item !== "All");
    }
    if (
      _.isEqual(
        ["All", ...checkedList].sort(),
        serviceTypeList.map((staff) => staff.name).sort()
      )
    ) {
      checkedList = serviceTypeList.map((staff) => staff.name);
    }

    setFilterList(checkedList);
    handleChange(checkedList);
  };

  useEffect(() => {
    let serviceTags = serviceTypeList.map((service) => service.name);
    setFilterList(serviceTags);
  }, [serviceTypeList]);

  const ServiceTypeMenu = (
    <Menu
      items={serviceTypeList.map((item) => ({
        key: item.name,
        label: (
          <Checkbox
            onChange={handleCheckboxClick}
            onClick={(e) => e.stopPropagation()}
            checked={filterList.includes(item.name)}
            data={item.name}
          >
            {item.name}
          </Checkbox>
        ),
      }))}
    ></Menu>
  );

  return (
    <>
      <Dropdown overlay={ServiceTypeMenu} trigger={["click"]}>
        <button className="btn">
          <FilterOutlined />
        </button>
      </Dropdown>
    </>
  );
};

export default ServiceTypeSelector;
