import React from "react";
import { Row, Col, Modal } from "antd";
import AddressField from "../../../../src/Components/AddressField";

export const RouteOptimizationModalCalendarContainer = (props) => {
  const { optModalOpen, onOptimize, setOptModalOpen, handleStartAddressChange, errors, startLocation, handleStopAddressChange, stopLocation } = props;

  return (
    <Modal
      title="Route Optimization"
      open={optModalOpen}
      onOk={() => {
        onOptimize();
      }}
      onCancel={() => setOptModalOpen(false)}
      okText="Optimize"
      destroyOnClose
      closable={false}
      className="schedule_opt_modal"
      style={{
        top: 20,
        width: "100%",
      }}
      maskClosable={false}
    >
      <div className="container pt-10">
        <div className="fitem">
          <Row
            justify="start"
            align="middle"
            style={{ height: "100%", alignItems: "flex-start" }}
          >
            <Col md={5}>
              <label className="centered-element-input">Start Location</label>
            </Col>
            <Col md={19} xs={24}>
              <AddressField
                handleAddressChange={handleStartAddressChange}
                onAddressChanged={handleStartAddressChange}
                onAddressChange={handleStartAddressChange}
                errors={errors}
                data={startLocation}
                nolabel={true}
              />
              {errors?.[`start_location.address`] ||
                errors?.[`start_location.state`] ||
                errors?.[`start_location.city`] ||
                errors?.[`start_location.zip_code`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {"Start Location is required"}
                </label>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
        <div className="fitem mt-10">
          <Row
            justify="start"
            align="middle"
            style={{ height: "100%", alignItems: "flex-start" }}
          >
            <Col md={5}>
              <label className="centered-element-input">Stop Location</label>
            </Col>
            <Col md={19} xs={24}>
              <AddressField
                handleAddressChange={handleStopAddressChange}
                onAddressChanged={handleStopAddressChange}
                onAddressChange={handleStopAddressChange}
                errors={errors}
                data={stopLocation}
                nolabel={true}
              />
              {errors?.[`end_location.address`] ||
                errors?.[`end_location.state`] ||
                errors?.[`end_location.city`] ||
                errors?.[`end_location.zip_code`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {"Stop Location is required"}
                </label>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}