import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { DatePicker, Col, Row } from "antd";
import moment from "moment-timezone";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { initialCleaningDays, checkZipCode, getFieldTechnician } from "../../../../Action/Franchise";
import { updatePreferredDate, updateScheduleTimeRange } from "../../../../Store/techJourneyDataSlice";
import AddReferenceField from "../../../../Calendar/Components/Modals/SubComponents/AddReferenceField";

const PreferredDateTime = () => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const [data, setData] = useState([]);
  const zipCode = techJourneyState.customerDetails?.address?.zip_code;
  const [checkZipCodeData, setCheckZipCodeData] = useState({});
  const [preferredStartDate, setPreferredStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [displayPreferredStartDate, setDisplayPreferredStartDate] = useState('');
  
  const [timeRange, setTimeRange] = useState('');
  const time_range = [
    { key: 'AM (8:00 to 12:00)', value: 'am' },
    { key: 'PM (1:00 to 5:00)', value: 'pm' },
    { key: 'Flexible', value: 'flexible' },
  ];

  useEffect(() => {
    getZipCodeDetails();
      const getInitialCleanningDays = async () => {
          const data = await initialCleaningDays({
              zip_code: checkZipCodeData,
          });
          if (data?.data.length) {
              setData(data?.data);
          } else {
              let startdate = moment().format("MM-DD-YYYY");
              let new_date = [];
              var new_date1 = moment(startdate, "MM-DD-YYYY").add(1,"days");
              var new_date2 = moment(startdate, "MM-DD-YYYY").add(2,"days");
              var new_date3 = moment(startdate, "MM-DD-YYYY").add(3, "days");
              new_date.push(startdate);
              new_date.push(new_date1);
              new_date.push(new_date2);
              new_date.push(new_date3);
              new_date.sort(function (a, b) { return new Date(a) - new Date(b) });
              setData(new_date);
          }
      };
      getInitialCleanningDays();
  }, []);

  const getZipCodeDetails = async () => {
    const data = await checkZipCode({
        addressable_zip_codes__contains: zipCode,
        close: false,
    });
    if (data?.count > 0) {
      setCheckZipCodeData(data?.results[0]);
    }
  };

  const renderExtraFooter = () => {
    return (
      <div className="data_indicator">
        <a href="/#" onClick={(e) => { e.preventDefault(); }} className="btn">Available</a>
        <a href="/#" onClick={(e) => { e.preventDefault(); }} className="btn unavailable">Unavailable</a>
      </div>
    )
  };

  //Preferred date
  useEffect(()=>{
    let temp = moment(preferredStartDate).format("YYYY-MM-DD");
    if(temp !== techJourneyState.preferred_start_date){
      dispatch(updatePreferredDate(temp));
    }
  }, [preferredStartDate]);

  const onDataChange = (e) => {
    setPreferredStartDate(e.target.checked ? e.target.value : null);
    setDisplayPreferredStartDate(null);
  };

  const onSelectDisplayPreferredStartDate = (e) => {
    setDisplayPreferredStartDate(e);
    setPreferredStartDate(moment(e).format("YYYY-MM-DD"));
  };

  //Time range
  useEffect(()=> {
    if(timeRange !== techJourneyState.schedule_time_range){
      dispatch(updateScheduleTimeRange(timeRange));
    }
  }, [timeRange]);
  
  const onTimeRangeChange = (e) => {
    setTimeRange(e.target.checked ? e.target.value : null);
  };

  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <h4>Preferred Start Date</h4>
          <div className="oplist">
              {map(data?.sort(function (a, b) { return new Date(a) - new Date(b) })?.slice(0, 4), (item, i) => {
                  return (
                      <div
                          className="inline-checks flex-1"
                          key={`preferred_start_dates${i}`}
                      >
                          <label
                              htmlFor={item?.value}
                              className={
                                  preferredStartDate ===
                                      moment(item).format("MM-DD-YYYY")
                                      ? "active"
                                      : ""
                              }
                          >
                              <input
                                  type="radio"
                                  id={item?.value}
                                  name="preferredStartDate"
                                  checked={
                                      preferredStartDate ===
                                      moment(item).format("MM-DD-YYYY")
                                  }
                                  value={moment(item).format("MM-DD-YYYY")}
                                  className=""
                                  onChange={onDataChange}
                              />
                              <span>
                                  {item === moment().format("MM-DD-YYYY") ? "Today" : 
                                    moment(item, "MM-DD-YYYY").format("ddd, MMM DD")
                                  }
                              </span>
                          </label>
                      </div>
                  );
              })}
              <div className="inline-checks flex-1">
                  <DatePicker
                      renderExtraFooter={(e) => renderExtraFooter}
                      inputReadOnly
                      mode={"date"}
                      className={
                          displayPreferredStartDate ? "active" : "box_opacity"
                      }
                      placeholder={"Select another date"}
                      format={"ddd, MMM DD"}
                      value={displayPreferredStartDate}
                      onChange={onSelectDisplayPreferredStartDate}
                      disabledDate={(current) => {
                          return current && current <= moment().add(3, "days");
                      }}
                  />
              </div>
          </div>
      </div>

      <AddReferenceField type="renovationAndConstruction" screen="soPopUp" />

      <div className="frow">
        <h4>Select a time range for the visit</h4>
        <div className="oplist">
          {map(time_range, (item, i) => {
            return (
              <div className="inline-checks flex-1" key={`timeRange${i}`}>
                <label htmlFor={item?.value} className={timeRange === item.value ? 'active' : ''}>
                  <input type="radio" id={item?.value} name="timeRange"
                    checked={timeRange === item} value={item.value} className="" onChange={onTimeRangeChange} />
                  <span>{item?.key}</span>
                </label>
              </div>
            )
          })}
        </div>
      </div>
      </Col>
    </Row>
  );
};

export default PreferredDateTime;
