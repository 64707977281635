import React from "react";
import StatusSelector from "../Filters/Status.Selector";
import { useCalendar } from "../../Hooks/Calendar.Hook";

const FilterHeaderStatusMobile = ({ setEvents = () => ({}) }) => {
  const { setStatus } = useCalendar();

  return (
    <>
      <StatusSelector
        onControlChange={(status) => {
          setStatus(status);
        }}
      />
    </>
  );
};

export default FilterHeaderStatusMobile;
