import React from "react";
import { Empty } from "antd";
import { Column } from "@ant-design/plots";

const RevenueTrend = ({ data = [] }) => {
  const config = {
    data: data,
    appendPadding: [0, 0, 20, 0],
    isStack: true,
    xField: "technicianName",
    yField: "count",
    seriesField: "serviceType",
    color: ["#66aa5f", "#4a9ac0", "#4FCFF6", "#758F9A"],
    maxColumnWidth: 30,
    autofit: true,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
        autoEllipsis: true,
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
      },
      verticalLimitLength: 80,
    },
    yAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
      },
    },
    legend: {
      position: "bottom",
      layout: "horizontal",
      maxRow: 4,
      pageNavigator: {
        marker: {
          style: {
            size: 0,
          },
        },
        text: {
          style: {
            fontSize: 0,
          },
        },
      },
      itemName: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text, item, index) =>
          text.toLowerCase() === "scheduled"
            ? "Upcoming"
            : text.charAt(0).toUpperCase() + text.slice(1),
      },
    },
    tooltip: {
      formatter: ({ serviceType, count }) => ({
        name: serviceType.charAt(0).toUpperCase() + serviceType.slice(1),
        value: count,
      }),
    },
  };

  return (
    <>
      {data.length > 0 ? (
        <Column {...config} />
      ) : (
        <>
          <Empty className="no-data-empty" description={false} />
        </>
      )}
    </>
  );
};

export default RevenueTrend;
