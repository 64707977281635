import React, { Component, useEffect, useState } from 'react';
import { message, Table, Select, Drawer, DatePicker, Modal } from 'antd';
import { map,orderBy } from 'lodash';
import moment from 'moment-timezone';
import AddUser from './AddUser';
import HeaderMobile from '../Components/HeaderMobile';
import SidebarResponsive from '../Components/SidebarResponsive';
import EditUser from './EditUser';
import AddGroup from './AddGroup'
// import EditGroup from './EditGroup';

import DataTable from './Common/DataTable';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Loader from "../Components/Loader";
import { deleteUser, getFranchiseList, getGroupList, getRoles, getUserEditDetail, getUserList, getTechSkills, getTechPermissions } from '../Action/User';
import { getFranchiseListAll } from "../Action/Franchise";
import {getFranchiseSelected, setFranchiseSelected} from "../Utils/selectedFranchise";
import { forGotPWD } from '../Action/Auth';

const UserList = (props) => {
const [globalSearchError, setGlobalSearchError] = useState(false);
const [openMenu, setOpenMenu] = useState(false);
const [currentUser, setCurrentUser] = useState('');
const [defaultFranchise, setDefaultFranchise] = useState();
const [selectedFranchise, setSelectedFranchise] = useState('all');
const [currentGroup, setCurrentGroup] = useState('');
const [defaultFranchiseGroup, setDefaultFranchiseGroup] = useState();
const [selectedFranchiseGroup, setSelectedFranchiseGroup] = useState('all');
const [state,setState] = useState({
    userList: [],
    groupList: [],
    groupListFilterData: [],
    franchiseList: [],
    roleList: [],
    skillsList: [],
    User_details: {},
    group_details: {},
    user_list_filter: { created_at__lte: null, created_at__gte: null, staff_profile__field_office__uuid: '', status: '', role: '' },
    group_list_filter: { created_at__lte: null, created_at__gte: null, field_office__uuid: '', status: '', role: '' },
    user_list_pagination: { showSizeChanger: false, pageSize: 15, ordering: "", staff_profile__field_office__uuid: '' },
    group_list_pagination: { showSizeChanger: false, pageSize: 15, ordering: "", field_office__uuid: '' },
    isNav: '',
    delete_id: '',
    search_value: '',
    active_pagination: '15',
    loader: false,
    openNav: false,
    is_user_table: true,
    is_add_user: false,
    is_add_group: false,
    is_edit_group: false,
    is_add_user_Active: false,
    is_update_user: false,
    is_user_filter: false,
    is_disable_franchise: false,
    visible: false,
    is_add_new_user: true,
    is_edit_user: true,
    is_delete_user: true,
})
const [searchValue, setSearchValue] = useState('');
const [currentTab, setCurrentTab] = useState(1);

const { Option } = Select;
const { RangePicker } = DatePicker;

 
function getState(){
    setState({
        userList: [],
        groupList: [],
        franchiseList: [],
        roleList: [],
        skillsList: [],
        User_details: {},
        group_details: {},
        user_list_filter: { created_at__lte: null, created_at__gte: null, staff_profile__field_office__uuid: '', status: '', role: '' },
        group_list_filter: { created_at__lte: null, created_at__gte: null, field_office__uuid: '', status: '', role: '' },
        user_list_pagination: { showSizeChanger: false, pageSize: 15, ordering: "", staff_profile__field_office__uuid: '' },
        group_list_pagination: { showSizeChanger: false, pageSize: 15, ordering: "", field_office__uuid: '' },
        isNav: '',
        delete_id: '',
        search_value: '',
        active_pagination: '15',
        loader: false,
        openNav: false,
        is_user_table: true,
        is_add_user: false,
        is_add_group: false,
        is_edit_group: false,
        is_add_user_Active: false,
        is_update_user: false,
        is_user_filter: false,
        is_disable_franchise: false,
        visible: false,
        is_add_new_user: true,
        is_edit_user: true,
        is_delete_user: true,
    })
}

const onMenubuttonClick = () => {
  setOpenMenu(!openMenu);
}
const onSearch = async (e) => {
 enterSearchUser();
}

    useEffect(()=>{
        getState();
        const {is_disable_franchise, franchise_uuid, is_franchise_owner, authUser} = getLoggedInUserRole()
        const user = JSON.parse(localStorage.getItem("authUser"));
        setFranchiseSelected(getFranchiseSelected()?.uuid || 'all');
        setSelectedFranchise(user?.user?.associated_field_offices[0]?.uuid);
        // setSelectedFranchiseGroup(franchise_uuid);

        setCurrentUser(user);
        setCurrentGroup(authUser);

        var roles = user?.user?.role;
        
        // Get User Roles
        if (roles.includes(`business_dev_franchiese`) || roles.includes(`accounting_franchiese`)) {
            setState((state)=>({
                ...state,
                is_disable_franchise: true
            }))
          }
      
          if ((roles.includes(`business_dev_franchiese`) || roles.includes(`accounting_franchiese`) || roles.includes(`service_rep`))) {
            setState((state)=>({
                ...state,
                is_edit_user: false
            }))
          }
      
          if (roles.includes(`service_rep`)) {
            setState((state)=>({
                ...state,
                is_add_new_user: false
            }))
          }
      
          if ((roles.includes(`accounting_franchiese`) || roles.includes(`business_dev_franchiese`) || roles.includes(`service_rep`))) {
            setState((state)=>({
                ...state,
                is_delete_user: false
            }))
          }

          setState((state)=>({
            ...state,
            loader:true
          }))
          if(roles.includes(`operations_mgr`) || roles.includes(`franchise_owner`) || is_franchise_owner) {
            let temp = {
              'offset': 0,
              'limit': 15
            }
            if(getFranchiseSelected()?.uuid !== 'all') {
              temp.staff_profile__field_office__uuid = getFranchiseSelected()?.uuid;
              temp.field_office__uuid = franchise_uuid;
            }
            getUserListData(temp);
            getGroupListData(temp);
            getGroupListDataProps(temp);
            // getGroupListDataProps({ 'offset': 0, 'limit': 15, 'field_office__uuid': getFranchiseSelected().uuid});
          } else {
            getUserListData({ 'offset': 0, 'limit': 15, staff_profile__field_office__uuid: user?.user?.associated_field_offices[0]?.uuid});
            getGroupListDataProps({ 'offset': 0, 'limit': 15, field_office__uuid: franchise_uuid});
            getGroupListData({ 'offset': 0, 'limit': 15, field_office__uuid: franchise_uuid});
          }
          getFranchiseListData();
          getRolesData();
          getTechSkillsList();
          getTechPermissionList();
          // if(location?.state?.currentTab !== undefined){
          //   setCurrentTab(location?.state?.currentTab)
          // }
          if(roles.includes(`operations_mgr`) || roles.includes(`franchise_owner`) || is_franchise_owner) {
            setDefaultFranchise(getFranchiseSelected().uuid);
            setDefaultFranchiseGroup(getFranchiseSelected().uuid);
          }
    },[]);

    useEffect(()=>{
      onSearch();
    }, [searchValue])

    //Get User List
    const getUserListData = async (value) =>{
        const data = await getUserList(value);
        setState((state)=>({
            ...state,
            userList: data?.results,
            user_list_pagination: {...state.user_list_pagination, total: data?.count},
            is_user_filter: false,
        }))
        setTimeout(() => setState((state)=>({...state,loader: false})), 500)
    }

    //Get Franchise List
    const getFranchiseListData = async () => {
        const data = await getFranchiseListAll();
        setState((state)=>({
            ...state,
            franchiseList: data,
            loader: false
          }))
    }

    //Get Roles List
    const getRolesData = async () => {
        const data = await getRoles();
        setState((state)=>({
            ...state,
            roleList: data?.results,
            loader: false
          }))
    }

    //Get Roles List
    const getTechSkillsList = async () => {
      const data = await getTechSkills();
      setState((state)=>({
          ...state,
          skillsList: data.results,
          loader: false
        }))
  }

  const getTechPermissionList = async () => {
    const data = await getTechPermissions();
    setState((state)=>({
        ...state,
        permissionList: data?.results,
        loader: false
      }))
}

//filter data
const getGroupListData = async (value) => {
  const data = await getGroupList({ 'offset': value.offset, 'limit': value.limit, field_office__uuid: value.field_office__uuid})
    setState((state)=>({
      ...state,
      groupList: data?.results,//filter
      group_list_pagination: {...state.group_list_pagination, total: data?.count},
      loader: false
    }))
}

//replicate filter data
const getGroupListDataProps = async (value) => {
      const data = await getGroupList(value)
      if(value.field_office__uuid) {
        setState((state)=>({
          ...state,
          groupList: data?.results,
          group_list_pagination: {...state.group_list_pagination, total: data?.count},
          loader: false
        }))
      }
      else {
      setState((state)=>({
          ...state,
          groupList: data?.results,
          groupListFilterData: data?.results,
          group_list_pagination: {...state.group_list_pagination, total: data?.count},
          loader: false
      }))
    }
  }

const handleEditSuccess = () => {
  const payload = { 
    'q': searchValue, 
    'offset': ((state?.user_list_pagination.current * 15) - 15) || 0, 
    'limit': state?.active_pagination || 15, 
  };
  const user = JSON.parse(localStorage.getItem("authUser"));
  if (getFranchiseSelected()?.uuid) {

    const val = user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)
    ? getFranchiseSelected()?.uuid
    : selectedFranchise;
    if (val !== 'all') {
      payload.staff_profile__field_office__uuid = val;
    }
  }
  getUserListData(payload);
  setState((state)=>({
    ...state,
    is_update_user: false,
    loader: false
  }))
}
  // get logged in user
  const getLoggedInUserRole = () => {
    let authUser = JSON.parse(localStorage.getItem('authUser'))
    const logged_in_user_role = authUser?.user?.user_roles?.map(x => x.name)

    const is_franchise_owner = logged_in_user_role?.includes('franchise_owner') || logged_in_user_role?.includes('operations_mgr')
    const is_account_corporate = logged_in_user_role?.includes('accounting_corporate')
    const is_business_dev_franchiese = logged_in_user_role?.includes('business_dev_franchiese')
    const is_operations_mgr = logged_in_user_role?.includes('operations_mgr')
    const is_admin = logged_in_user_role?.includes('admin')
    // const is_field_tech = logged_in_user_role?.includes('field_tech')
    const franchise_uuid = authUser?.user?.field_offices?.map(x => x.uuid);
    // const filteredIds = franchise_uuid.filter(item => item.uuid === selectedFranchiseGroup).map(item => item.uuid);

    const is_call_center = logged_in_user_role?.includes('service_rep')
    return {is_call_center, franchise_uuid: franchise_uuid?.[0], is_admin, is_account_corporate,
      is_disable_franchise: (is_franchise_owner || is_business_dev_franchiese || is_operations_mgr)}
  }

  ////User Table///

  //render User Table
  const renderUserTable = () => {
    let { userList, user_list_pagination, active_pagination } = state;
    return (
      <div>
        <Table
          rowKey={record => record.uuid}
          dataSource={userList}
          onChange={userHandleTableChange}
          pagination={user_list_pagination}
          columns={DataTable.UserList(state,setState,ShowNav,editUSer,deleteUSer, resetPwd)}
          className="user-list"
          position={false}
          showSorterTooltip={false}
        />

        {userList?.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setUserPageLimit('15') }}
                className={active_pagination === '15' ? 'active' : ''}
              >15</span>
            </li>
            <li>
              <span
                className={active_pagination === '30' ? 'active' : ''}
                onClick={() => { setUserPageLimit('30') }}>30</span>
            </li>
            <li>
              <span
                className={active_pagination === '60' ? 'active' : ''}
                onClick={() => { setUserPageLimit('60') }}>60</span>
            </li>
          </ul> : ''}
      </div>
    )
  }

  //Table Order and Pagination
  const userHandleTableChange = (pagination, filters, sorter) => {
    let { search_value, active_pagination } = state;
    let user_list_pagination = state.user_list_pagination;
    let { user_list_filter } = state;
    let ordering = ''
    user_list_pagination = Object.assign(user_list_pagination, { 'current': pagination.current });

    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`
    }
    else {
      ordering = ''
    }

    setState((state)=>({
      ...state,
      userList: [],
      user_list_pagination,
      loader: true,
    }))

    let data = {
      offset: (pagination.current * 15) - 15,
      limit: active_pagination,
      ordering,
      search: search_value,
      ...user_list_filter
    }
    const user = JSON.parse(localStorage.getItem("authUser"));
    if (getFranchiseSelected()?.uuid) {
      const val = user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)
      ? getFranchiseSelected()?.uuid
      : selectedFranchise;
      if (val !== 'all') {
        data.staff_profile__field_office__uuid = val;
      }
    }

    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getUserListData(data);
  }

  //set page limit pagination
  const setUserPageLimit = (custom_limit) => {
    let { search_value, user_list_pagination,user_list_filter } = state;
    user_list_pagination = Object.assign(user_list_pagination, { 'current': 1, 'pageSize': custom_limit,search: search_value,staff_profile__field_office__uuid:user_list_filter.staff_profile__field_office__uuid });


    setState((state)=>({
      ...state,
      user_list_pagination,
      loader: true,
      active_pagination: custom_limit,
      // search_value:''
    }))
    const payload = { 
      offset: 0, 
      limit: custom_limit, 
      ordering: '', 
      search: search_value,
    };
    const user = JSON.parse(localStorage.getItem("authUser"));
    if (getFranchiseSelected()?.uuid) {
      const val = user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)
      ? getFranchiseSelected()?.uuid
      : selectedFranchise;
      if (val !== 'all') {
        payload.staff_profile__field_office__uuid = val;
      }
    }
    getUserListData(payload);
  }

  //pagination
  const setGroupPageLimit = (custom_limit) => {
    let { search_value, group_list_pagination, group_list_filter } = state;
    group_list_pagination = Object.assign(group_list_pagination, { 'current': 1, 'pageSize': custom_limit, search: search_value,field_office__uuid:group_list_filter.field_office__uuid });


    setState((state)=>({
      ...state,
      group_list_pagination,
      loader: true,
      active_pagination: custom_limit,
      // search_value:''
    }))
    const payload = { 
      offset: 0, 
      limit: custom_limit, 
      ordering: '', 
      search: search_value,
    };

    let authUser = JSON.parse(localStorage.getItem('authUser'))

    if (getFranchiseSelected()?.uuid) {
      const val = authUser?.user?.user_roles?.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)
      ? getFranchiseSelected()?.uuid
      : selectedFranchiseGroup;
    if(val !== 'all') {
      state.groupListFilterData.map((item)=>{
      if(item.uuid == val) {
        if(item.franchise_uuid !== null) {
          payload.field_office__uuid = item.franchise_uuid;
        }
      }
      })
    }
    }

    getGroupListData(payload);
  }

  // Show Table Nav
  const ShowNav = (record,) => {
    // if (!state.openNav) {
    //   // attach/remove event handler
    //   document.addEventListener('click', handleOutsideClick, false);
    // } else {
    //   document.removeEventListener('click', handleOutsideClick, false);
    // }

    setState((state) => ({
      ...state,
      openNav: !state.openNav,
      isNav: record.uuid
    }));
  }

  // hide list toggle button
  const handleOutsideClick = (e) => {
    // ignore clicks on the Component itself
    // if (node && node.contains(e.target)) {
    //   return;
    // }


    ShowNav({ 'uuid': '' }, '');
  }

  //Edit User Action
  const editUSer = (user) => {
    const getUserEditDetails = async () => {
      let payload = user.is_active ? user.uuid : user.uuid +'/?is_active='+ user.is_active;
      const data = await getUserEditDetail(payload)
      setState((state)=>({
        ...state,
        loader: false,
        is_update_user: true,
        User_details: data
      }))
    }
    getUserEditDetails();
    if(user.role?.includes('service_rep')){
      props.getGroupList({ 'offset': 0, 'limit': 15, 'is_public': true});
    }
    setState((state)=>({
      ...state,  
      loader: true,
      is_update_user: true
    }))
  }

  //Delete User Action
  const deleteUSer = () => {
    // delete user api call
    const delUser = async () => {
      const data = await deleteUser(state.delete_id);
      getUserListData({ 'offset': 0, 'limit': state.custom_limit, search: state.search_value });
      // user_list_pagination.current = 1;
      setState((state)=>({
        ...state,
        visible: false,
        // user_list_pagination,
        delete_id: ''
      }))
      message.success("Record Deleted!..",10)
    }
    delUser();
    setState((state)=>({
      ...state,  
      loader: true
    }))
  }

  //Reset Password
  const resetPwd = (user) => {
    let email = user?.email;
    setState((state)=>({...state, loader: true }));
    const postResetPassword = async () => {
        const data = await forGotPWD({email, is_crm: true});
        if(data?.detail){
            message.success("Password reset e-mail has been sent", 10);
        }else if(data?.errors?.email){
            message.error(data.errors.email, 10);
        }else{
            message.error("This email-id does not exist!", 10);
        }
        setState((state)=>({...state, loader:false}));
    }
    postResetPassword();  
  };

  //Delete Group
  const deleteGroup = () => {
    // delete user api call
    this.props.deleteGroup(this.state.delete_id);
    this.setState({
      loader: true
    })
  }

  //Edit Group Details
  const editGroup = group => {
    this.props.getGroupDetails(group.uuid);
    this.setState({
      loader: true,
    })
    this.editGroupToggle(true)
  }

  //Switch tab
  const switchTab = (type) => {
   
    let { user_list_pagination, group_list_pagination } = state;
    const {franchise_uuid, is_disable_franchise} = getLoggedInUserRole()
    if (type) {
      user_list_pagination = Object.assign(user_list_pagination, {
        showSizeChanger: false, pageSize: 15, ordering: ""
      })
      if(currentUser?.user?.role?.includes(`franchise_owner`) || currentUser?.user?.role?.includes(`operations_mgr`)) {
        let temp = {
          'offset': 0,
          'limit': 15
        }
        if(getFranchiseSelected().uuid !== 'all') {
          temp.staff_profile__field_office__uuid = getFranchiseSelected().uuid;
        }
        getUserListData(temp);
      } else {
        let temp = {
          'offset': 0,
          'limit': 15
        }
        if(selectedFranchise !== 'all') {
          temp.staff_profile__field_office__uuid = selectedFranchise;
        }
        getUserListData(temp);
      }
      setState((state)=>({...state, loader: true, is_user_table: type, user_list_pagination, search_value: '', active_pagination:'15' }));
    }
    else {
      user_list_pagination = Object.assign(user_list_pagination, {
        showSizeChanger: false, pageSize: 15, ordering: ""
      })

      const selectedGroupFranchise = currentGroup?.user?.user_roles.includes(`franchise_owner`) || currentUser?.user?.role?.includes(`operations_mgr`) ? defaultFranchiseGroup : selectedFranchiseGroup;
      if(selectedGroupFranchise) {
        let temp = {
          'offset': 0,
        }
        if(selectedGroupFranchise !== "all") {
          state.groupListFilterData.map((item)=>{
            if(item.uuid == selectedGroupFranchise) {
              if(item.franchise_uuid !== null) {
                temp.field_office__uuid = item.franchise_uuid;
              }
            }
          })
        }
        getGroupListDataProps(temp);
      } else {
        getGroupListDataProps({ 'offset': 0 });
      }
      setCurrentTab(2);
      setState((state)=>({...state, loader: true, is_user_table: type, group_list_pagination, search_value: '', active_pagination: '15' }));
    }
  }



  ////Group Table///

  // Render Group Table
  const renderGroupTable = () => {
    let { groupList, group_list_pagination, active_pagination } = state;
    return (
      <div>
        <Table className="group-list"
          rowKey={record => record.uuid}
          dataSource={groupList}
          onChange={groupHandleTableChange}
          pagination={group_list_pagination}
          columns={DataTable.GroupList(this)}
          position={false}
          showSorterTooltip={false}

        />
        {groupList?.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setGroupPageLimit('15') }}
                className={active_pagination === '15' ? 'active' : ''}
              >15</span>
            </li>
            <li>
              <span
                className={active_pagination === '30' ? 'active' : ''}
                onClick={() => { setGroupPageLimit('30') }}>30</span>
            </li>
            <li>
              <span
                className={active_pagination === '60' ? 'active' : ''}
                onClick={() => { setGroupPageLimit('60') }}>60</span>
            </li>
          </ul> : ''}
      </div>
    )
  }



  // Group Pagination
  const groupHandleTableChange = (pagination, filters, sorter) => {
    // const {is_disable_franchise, franchise_uuid} = getLoggedInUserRole()
    let { search_value } = state;
    let group_list_pagination = state.group_list_pagination;
    let { group_list_filter, active_pagination } = state;
    let ordering = ''
    group_list_pagination = Object.assign(group_list_pagination, { 'current': pagination.current });


    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.column.key}` : `-${sorter.column.key}`
    }
    else {
      ordering = ''
    }

    setState((state)=>({
        ...state,
      group_list_pagination,
      loader: true,
      groupList: [],
    }))


    let data = {
      offset: (pagination.current * 15) - 15,
      limit: active_pagination, ordering,
      search: search_value,
      // field_office__uuid: is_disable_franchise ? franchise_uuid : '',
      ...group_list_filter
    }

    let authUser = JSON.parse(localStorage.getItem('authUser'))

    if (getFranchiseSelected()?.uuid) {
      const val = authUser?.user?.user_roles?.includes(`franchise_owner`) || authUser?.user?.role?.includes(`operations_mgr`)
      ? getFranchiseSelected()?.uuid
      : selectedFranchiseGroup;
      if (val !== 'all') {
        data.field_office__uuid = val;
      }
    }

    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getGroupListData(data);
  }

  //Toggle Add User Drawer 
  const userDrawerToggle = (key) => {
    setState((state)=>({
      ...state,  
      is_add_user: key
    }))
    getGroupListData({ 'offset': 0, 'limit': 15, 'is_public': true});
  }
  // group  drawer toggle
  const groupDrawerToggle = (key) => {
    setState((state)=>({
      ...state,  
      is_add_group: key
    }))
  }

  //edit user toggle
  const editGroupToggle = (key) => {
    setState((state)=>({ 
        ...state,
        is_edit_group: key 
    }))
  }

  //Toggle Add User CustomDrawer
  const userEditDrawerToggle = (key) => {
    setState((state)=>({
      ...state,  
      is_update_user: key
    }))
  }

  //Search User
  const searchUser = (e) => {
    let { is_user_table } = state;
    const search_value = e.target.value;
    
    if (is_user_table) {
      if (search_value?.length >= 3 || search_value?.length === 0) {
        getUserListData({ 'q': e.target.value, 'offset': 0, 'limit': 15 })
      }
      setState((state)=>({
        ...state,
        search_value: e.target.value
      }))
    }
    else {
      const {is_disable_franchise, franchise_uuid} = getLoggedInUserRole()
      if (search_value?.length >= 3 || search_value?.length === 0) {
        //getGroupListData({ 'search': e.target.value, 'offset': 0, 'limit': 15, field_office__uuid: is_disable_franchise ? franchise_uuid : '' })
      }
      setState((state)=>({
        ...state,
        search_value: e.target.value
      }))
    }
  }

  //Search User on Enter
  const enterSearchUser = (e) => {
    if(currentTab===1){
      const payload = { 
        'q': searchValue, 
        'offset': state?.user_list_pagination?.offset || 0, 
        'limit': state?.user_list_pagination?.limit || 15, 
      };
      const user = JSON.parse(localStorage.getItem("authUser"));
    if (getFranchiseSelected()?.uuid) {
      const val = user?.user?.role.includes(`franchise_owner`) || user?.user?.role.includes(`operations_mgr`)
      ? getFranchiseSelected()?.uuid
      : selectedFranchise;
      if (val !== 'all') {
        payload.staff_profile__field_office__uuid = val;
      }
    }
      getUserListData(payload)
      setState((state)=>({
        ...state,
        search_value: searchValue
      }))
    }else{
      const {franchise_uuid, is_disable_franchise} = getLoggedInUserRole()
        getGroupListData({ 'search': searchValue, 'offset': state?.group_list_pagination?.offset || 0, 'limit': state?.group_list_pagination?.limit || 15, field_office__uuid: is_disable_franchise ? franchise_uuid : '' })
        setState((state)=>({
          ...state,
          search_value: searchValue
        }))
    }
  }
  //Filter

  // Franchise Filter
  const onFranchiseFilterChange = (e) => {
    let { user_list_pagination, active_pagination, search_value, user_list_filter } = state;

    user_list_pagination = Object.assign(user_list_pagination, { 'staff_profile__field_office__uuid': e, current: 1 });
    // user_list_filter = Object.assign(user_list_filter, { 'staff_profile__field_office__uuid': e });
    if(currentUser?.user?.role.includes(`franchise_owner`) || currentUser?.user?.role.includes(`operations_mgr`)) {
      setFranchiseSelected(e);
      setDefaultFranchise(getFranchiseSelected().uuid);
    } else {
      setSelectedFranchise(e);
    }
    setState((state)=>({...state, loader: true, user_list_pagination, user_list_filter }));
    let data = {
      offset: (user_list_pagination.current * 15) - 15 || 0,
      limit: active_pagination,
      search: search_value,
      ...user_list_filter
    }
    if(e !== 'all') {
      data.staff_profile__field_office__uuid =  e;
    }
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getUserListData(data);
  }

  const onGroupFilterChange = (e)=>{
    const {is_disable_franchise, franchise_uuid, is_franchise_owner} = getLoggedInUserRole();
    let { group_list_pagination, active_pagination, search_value,group_list_filter } = state;

    group_list_pagination = Object.assign(group_list_pagination, { 'field_office__uuid': e });
    // group_list_filter = Object.assign(group_list_filter, { 'field_office__uuid': e });
    if(currentGroup?.user?.user_roles.includes('operations_mgr') || currentGroup?.user?.user_roles.includes('franchise_owner') || is_franchise_owner) {
      setFranchiseSelected(e);
      setDefaultFranchiseGroup(getFranchiseSelected().uuid);
    } else {
      setSelectedFranchiseGroup(e);
    }

    setState((state)=>({...state, loader: true, group_list_pagination, group_list_filter }));
    let data = {
      offset: (group_list_pagination.current * 15) - 15 || 0,
      limit: active_pagination,
      search: search_value,
      // field_office__uuid: e,
      ...group_list_filter
    }
    //filter
    if(e !== 'all') {
      state.groupListFilterData.map((item)=>{
      if(item.uuid == e) {
        if(item.franchise_uuid !== null) {
          data.field_office__uuid = item.franchise_uuid;
        }
      }
      })
    }

    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getGroupListData(data);
  }

  const onClose = () => {
    setState((state)=>({
      ...state,  
      is_user_filter: false
    }));
  }

    let { loader, is_user_table, is_add_user, is_update_user,
      is_add_group, search_value, is_edit_group, franchiseList,
      is_user_filter, groupList, userList, user_list_filter, roleList, skillsList, is_disable_franchise, is_add_new_user } = state;
    const {is_call_center} = getLoggedInUserRole();

    const onKeyPressHandler = (event) => {
      setGlobalSearchError(false);
      if (event.key === "Enter" && searchValue?.length > 3) {
        enterSearchUser();
      } else if(event.key === "Enter" && searchValue?.length < 3) {
        setGlobalSearchError(true);
      }
    }

    return (
      <div id="wrapper">
        <div className="content-area">
          <HeaderMobile onMenubuttonClick={onMenubuttonClick} />

          {/* Header */}
          {/* <Header
            // placeholder=" Name, Phone Number, Email"
            placeholder={is_user_table ? " Name, Phone Number, Email" : " Name"}
            search_value={search_value}
            onSearch={(e) => { searchUser(e) }} 
            onEnter={(e) => setState((state)=>({...state,search_value: '', loader: true}, () => enterSearchUser(e)))} /> */}
          <Header
            placeholder={"Name, Phone Number, Email"}
            readOnly={false}
            search_value={searchValue}
            setSearchValue={setSearchValue}
            onSearch={enterSearchUser}
            onKeyPressHandler={onKeyPressHandler}
          />
          {globalSearchError && (
            <label className="new_err_msg ml-10">
              <i className="las la-info-circle ml-5" />
              Please enter at least 3 letters
            </label>
          )}
          <Sidebar sideBarBackgroundColor="#005d89" />
          <SidebarResponsive
            sideBarBackgroundColor="#005d89"
            openMenu={openMenu}
            onMenubuttonClick={onMenubuttonClick}
          />
          <div className="container">
            {loader && <Loader />}
            <div className="filter_wrapper">
              <div className="left_side">
                <div className="fitem">
                  {is_user_table ? (
                    <Select
                      placeholder="All"
                      value={
                        currentUser?.user?.role.includes(`franchise_owner`) || currentUser?.user?.role.includes(`operations_mgr`)
                          ? defaultFranchise
                          : selectedFranchise
                      }
                      onChange={(e) => {
                        onFranchiseFilterChange(e);
                      }}
                      disabled={(is_disable_franchise || franchiseList?.length === 1) ? true : false}
                      className="swhite"
                    >
                       <Option value="all">All</Option>
                      {map(franchiseList, (item) => {
                        return <Option key={item.uuid}>{item.name}</Option>;
                      })}
                    </Select>
                  ) : (
                    <Select
                      placeholder="All Franchise"
                      defaultValue=""
                      // value={state.group_list_pagination.field_office__uuid}
                      value={
                        currentGroup?.user?.user_roles.includes(`franchise_owner`) || currentGroup?.user?.user_roles.includes(`operations_mgr`)
                          ? defaultFranchiseGroup
                          : selectedFranchiseGroup
                      }
                      onChange={(e) => {
                        onGroupFilterChange(e);
                      }}
                      disabled={(is_disable_franchise || franchiseList?.length === 1) ? true : false}
                      className="swhite"
                    >
                      <Option value="all">All Group</Option>
                      
                      {map(state.groupListFilterData, (item) => {
                        return (<Option key={item.uuid}>{item.name}</Option>)
                      })}
                    </Select>
                  )}
                </div>
                <ul
                  className="tabs"
                  style={{ marginLeft: "10px" }}
                >
                  <li
                    className={is_user_table ? "active" : ""}
                    onClick={() => {
                      switchTab(true);
                    }}
                  >
                    <span>USER</span>
                  </li>
                  <li
                    style={{ marginLeft: "10px" }}
                    className={is_user_table ? "" : "active"}
                    onClick={() => {
                      switchTab(false);
                    }}
                  >
                    <span>GROUPS</span>
                  </li>
                </ul>
              </div>
              <div className="right_side">
                {is_user_table ? (
                  <React.Fragment>
                    <span
                      className="ico-btn"
                      onClick={() => {
                        setState((state) => ({
                          ...state,
                          is_user_filter: true,
                        }));
                      }}
                    >
                      <i className="las la-filter"></i>
                    </span>
                    <button
                      className={
                        is_add_new_user
                          ? "btn btn-warning"
                          : " btn btn-brand btn-bdr disbale_btn "
                      }
                      disabled={is_add_new_user ? false : true}
                      onClick={() => {
                        userDrawerToggle(true);
                        setState((state) => ({
                          ...state,
                          is_update_user: false,
                        }));
                      }}
                    >
                      Add New User
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button
                      className={`btn ${
                        is_call_center
                          ? "btn-brand btn-bdr disbale_btn"
                          : "btn-warning"
                      }`}
                      disabled={is_call_center}
                      onClick={() => {
                        groupDrawerToggle(true);
                      }}
                    >
                      Add New Group
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="data">
              {is_user_table ? renderUserTable() : renderGroupTable()}
            </div>
          </div>
        </div>

        {/* Add user */}
        {is_add_user && (
          <Drawer
            visible={is_add_user}
            width={"820"}
            closable={false}
            className="drawer-wrapper"
          >
            <AddUser
              {...props}
              getUserList={getUserListData}
              getGroupList={getGroupListData}
              getGroupListDataProps={getGroupListDataProps}
              groupList={state.groupList}
              roleList={state.roleList}
              skillsetList={state.skillsList}
              permissionList={state.permissionList}
              franchiseList={state.franchiseList}
              is_update_user={state.is_update_user}
              is_disable_franchise={state.is_disable_franchise}
              userDrawerToggle={() => {
                userDrawerToggle();
              }}
            />
          </Drawer>
        )}

        {/* Edit user */}
        <Drawer
          visible={is_update_user}
          width={"820"}
          closable={false}
          className="drawer-wrapper"
        >
          <EditUser
            {...props}
            getRoles={getRolesData}
            getGroupList={getGroupListDataProps}
            getUserList={getUserListData}
            deleteUSer={deleteUSer}
            handleEditSuccess={handleEditSuccess}
            User_details={state.User_details}
            groupList={state.groupList}
            roleList={state.roleList}
            skillsetList={state.skillsList}
            permissionList={state.permissionList}
            franchiseList={state.franchiseList}
            is_update_user={state.is_update_user}
            is_disable_franchise={state.is_disable_franchise}
            userEditDrawerToggle={userEditDrawerToggle}
            user_list_filter={user_list_filter}
          />
        </Drawer>

        {/* Add Group */}
        <Drawer
          visible={is_add_group}
          width={"400"}
          closable={false}
          style={{ width: "auto" }}
          className="drawer-wrapper"
        >
          <AddGroup
            userList={state.userList}
            franchiseList={state.franchiseList}
            groupDrawerToggle={() => {
              groupDrawerToggle();
            }}
            getUserList={getUserListData}
            getGroupList={getGroupListDataProps}
            {...props}
            closable={false}
          />
        </Drawer>

        {/* Edit Group */}
        {/* <Drawer
          visible={is_edit_group}
          width={'400'}
          closable={false}
          className="drawer-wrapper"
        >
          <EditGroup
            userList={state.userList}
            group_details={state.group_details}
            franchiseList={state.franchiseList}
            editGroupToggle={() => { editGroupToggle() }}
            {...props}

          />
        </Drawer> */}

        {/* User filter  */}
        <Drawer
          visible={is_user_filter}
          width={"400"}
          closable={false}
          onClose={onClose}
          className="drawer-wrapper"
        >
          <div className="filter_drawer_wrapper rdrawer">
            <div
              className="card-head"
              style={{ borderBottom: "0" }}
            >
              <h4>Filter Users</h4>
            </div>
            <div className="card-row">
              <div className="card-full">
                <div className="form-group">
                  <div className="fitem">
                    <label htmlFor="">Customer Status</label>
                    <div className="fitem-check">
                      <input
                        type="checkbox"
                        className="fitem-ck-input"
                        id="active"
                        checked={user_list_filter?.is_active === true}
                        onChange={(e) => {
                          if (e.target.checked) {
                            user_list_filter = Object.assign(user_list_filter, {
                              is_active: true,
                            });
                          } else {
                            user_list_filter = Object.assign(user_list_filter, {
                              is_active: "",
                            });
                          }

                          setState((state) => ({ ...state, user_list_filter }));
                        }}
                      />
                      <label
                        htmlFor="active"
                        className="fitem-ck-txt"
                      >
                        Active
                      </label>
                    </div>
                    <div className="fitem-check">
                      <input
                        type="checkbox"
                        className="fitem-ck-input"
                        id="in_active"
                        checked={user_list_filter?.is_active === false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            user_list_filter = Object.assign(user_list_filter, {
                              is_active: false,
                            });
                          } else {
                            user_list_filter = Object.assign(user_list_filter, {
                              is_active: "",
                            });
                          }
                          setState((state) => ({ ...state, user_list_filter }));
                        }}
                      />
                      <label
                        htmlFor="in_active"
                        className="fitem-ck-txt"
                      >
                        InActive
                      </label>
                    </div>
                  </div>

                  <div className="fitem">
                    <label htmlFor="">Franchise</label>
                    <Select
                      placeholder="All"
                      value={
                        currentUser?.user?.role.includes(`franchise_owner`) || currentUser?.user?.role.includes(`operations_mgr`)
                          ? defaultFranchise
                          : selectedFranchise
                      }
                      // defaultValue=""
                      // value={user_list_filter?.staff_profile__field_office__uuid}
                      onChange={(e) => {
                        // if(currentUser?.user?.role.includes(`franchise_owner`)) {
                        //   setFranchiseSelected(e);
                        //   setDefaultFranchise(getFranchiseSelected().uuid);
                        // } else {
                        //   setSelectedFranchise(e);
                        // }
                        onFranchiseFilterChange(e);
                        user_list_filter = Object.assign(user_list_filter, {
                          staff_profile__field_office__uuid: e,
                        });
                        setState((state) => ({ ...state, user_list_filter }));
                      }}
                      disabled={is_disable_franchise ? true : false}
                    >
                      <Option value="all">All</Option>
                      {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((item) => {
                        return <Option key={item.uuid}>{item.name}</Option>;
                      })}
                    </Select>
                  </div>

                  <div className="fitem">
                    <label
                      htmlFor=""
                      className=""
                    >
                      Joined
                    </label>
                    <RangePicker
                      style={{ width: "100%" }}
                      allowClear={true}
                      inputReadOnly
                      value={
                        user_list_filter?.created_at__gte &&
                        user_list_filter?.created_at__lte
                          ? [
                              moment(user_list_filter?.created_at__gte),
                              moment(user_list_filter?.created_at__lte),
                            ]
                          : ""
                      }
                      onChange={(e) => {
                        if (e) {
                          user_list_filter = Object.assign(user_list_filter, {
                            created_at__gte: `${moment(e[0]).format(
                              "YYYY-MM-DD"
                            )}T00:00:00`,
                            created_at__lte: `${moment(e[1]).format(
                              "YYYY-MM-DD"
                            )}T11:59:59`,
                          });
                        } else {
                          user_list_filter = Object.assign(user_list_filter, {
                            created_at__lte: null,
                            created_at__gte: null,
                          });
                          getUserListData({
                            offset: 0,
                            limit: 15,
                            ...user_list_filter,
                          });
                        }

                        setState((state) => ({
                          ...state,
                          user_list_filter,
                        }));
                      }}
                      format="MMM DD, YYYY"
                      separator="-"
                    />
                  </div>
                  <div className="fitem">
                    <label htmlFor="">Role</label>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="All"
                      defaultValue=""
                      value={user_list_filter?.role}
                      onChange={(e) => {
                        user_list_filter = Object.assign(user_list_filter, {
                          role: e,
                        });
                        setState((state) => ({ ...state, user_list_filter }));
                      }}
                    >
                      <Option value="all">All</Option>
                      {map(roleList, (item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.name}
                          >
                            {item.display_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="fitem">
                    <label htmlFor="">Group</label>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="All"
                      defaultValue=""
                      value={
                        currentGroup?.user?.user_roles.includes(`franchise_owner`) || currentGroup?.user?.user_roles.includes(`operations_mgr`)
                          ? defaultFranchiseGroup
                          : selectedFranchiseGroup
                      }
                      onChange={(e) => {
                        onGroupFilterChange(e);
                        group_list_filter = Object.assign(group_list_filter, {
                          field_office__uuid: e,
                        });
                        setState((state) => ({ ...state, group_list_filter }));
                      }}
                      disabled={(is_disable_franchise || franchiseList?.length === 1) ? true : false}
                    >
                      <Option value="">All</Option>
                      {map(groupList, (item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.uuid}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="footer">
                <button
                  className="btn btn-warning"
                  onClick={() => {
                    user_list_filter = Object.assign(user_list_filter, {
                      created_at__lte: null,
                      created_at__gte: null,
                      staff_profile__field_office__uuid: "",
                      status: "",
                      role: "",
                      is_active: "",
                      group__uuid: "",
                    });
                    setState((state) => ({
                      ...state,
                      user_list_filter,
                      loader: true,
                    }));

                    getUserListData({ offset: 0, limit: 15 });
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-bdr"
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      is_user_filter: false,
                    }));
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setState((state) => ({
                      ...state,
                      loader: true,
                    }));
                    let data = {
                      offset: 0,
                      limit: 15,
                      ...user_list_filter,
                    };
                    Object.keys(data).forEach(
                      (key) =>
                        (data[key] === null || data[key] === "") &&
                        delete data[key]
                    );
                    getUserListData(data);
                  }}
                >
                  Filter Users
                </button>
              </div>
            </div>
          </div>
        </Drawer>
        <Modal
          title="Delete User"
          open={state.visible}
          okText={"Yes"}
          cancelText={"No"}
          onOk={deleteUSer}
          onCancel={() => {
            setState((state) => ({ ...state, visible: false }));
          }}
          className="delete_user_modal"
          width={400}
        >
          <h2> Are you sure?</h2>
        </Modal>
      </div>
    );
}

export default UserList;