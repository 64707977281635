import React from "react";
import { Table, Typography, Row, Col, Empty } from "antd";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";
const { Text } = Typography;

const TerritoryTable = () => {
  const { reportData } = useReport();
  const showDiscount = reportData?.discounts?.discount_list.length > 0;
  const showProducts = reportData?.products?.product_list.length > 0;
  const showServices = reportData?.services?.service_list.length > 0;

  return (
    <>
      <Row>
        <Col span={24} className={"invoice-table-container"}>
          {showProducts ? (
            <Table
              dataSource={reportData?.products?.product_list.map(
                (item, idx) => ({
                  ...item,
                  key: idx,
                })
              )}
              columns={DataTable.InvoiceReconciliationProductsReport(this)}
              className="table hscroll"
              pagination={false}
              bordered
              summary={(pageData) => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} span={3}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.products?.total_invoiced
                        )}`}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.products?.total_completed
                        )}`}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.products?.total_paid
                        )}`}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          ) : (
            <></>
          )}
          {showServices ? (
            <Table
              dataSource={reportData?.services?.service_list.map(
                (item, idx) => ({
                  ...item,
                  key: idx,
                })
              )}
              columns={DataTable.InvoiceReconciliationServicesReport(this)}
              pagination={false}
              className="table hscroll"
              bordered
              summary={(pageData) => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.services?.total_invoiced
                        )}`}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.services?.total_completed
                        )}`}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.services?.total_paid
                        )}`}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          ) : (
            <></>
          )}
          {showDiscount ? (
            <Table
              dataSource={reportData?.discounts?.discount_list.map(
                (item, idx) => ({ ...item, key: idx })
              )}
              columns={DataTable.InvoiceReconciliationDiscountReports(this)}
              className="table hscroll"
              bordered
              pagination={false}
              summary={(pageData) => {
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.discounts?.total_invoiced
                        )}`}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.discounts?.total_completed
                        )}`}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text>{`$ ${formatToCurrency(
                          reportData?.discounts?.total_paid
                        )}`}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
          ) : (
            <></>
          )}
          {!showDiscount && !showProducts && !showServices ? (
            <Empty className="no-data-empty" description={false} />
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </>
  );
};

export default TerritoryTable;
