const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  response_data: [],
  service_type_uuid: "17df7b5c-a5c3-4f36-b77f-9cb0570d9af2",
  workTypesArray: [],
  serviceTypeArray: [],
  email_notes: "",
  service_agreement: "",
  select_rate: "",
  number_of_pools: "",
  number_of_properties: "",
  pool_name: "",
  pool_size: 0,
  pool_type_uuid: "",
  additional_notes: "",
  work_type: "",
  work_subtype: "",
  work_subtype: "",
  notes: "",
  proposal_number:"",
  modal_state: true,
  deactivate_modal: false,
  activate_modal: false,
  delete_modal: false,
  base_form: true,
  delete_proposal: false,
  proposal_page: true,
  frequency: "",
  select_pool_api: "",
  select_service_api: "",
  service_address_uuid: "New",
  address_option_pool: "New",
  primary_no: "",
  proposal_due_date:"",
  data_save: "",
  due_select: "",
  isViewOnly: false,
  flag_state: false,
  datepicker_state:false,
  address: {
    uuid: "New",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip_code: "",
    geo_location: "",
    formatted_address: "",
  },
  preferred_start_date: "",
  service_type_name: "maintenance",
  deactivate_proposal: false,
  service_modal_state:false,
  redirect_key:"",
  additional_work: [],
  update_additional_work:[],
  delete_additional_work:[],
  proposal_response_data:[],
  showLoader:false,
  showPreview:false,
  proposal_key:"",
  redirect_proposal:"",
  proposalBasePrice:0
};
const ProposalDataSlice = createSlice({
  name: "proposaljourney",
  initialState,
  reducers: {
    showLoader(state, action) {
      return (state = {
        ...state,
        showLoader: action.payload,
      });
    },
    showPreview(state, action) {
      return (state = {
        ...state,
        showPreview: action.payload,
      });
    },
    serviceMatrixApi(state, action) {
      return (state = {
        ...state,
        response_data: action.payload,
        serviceTypeArray: action.payload.servicetypes,
        serviceTypeName: action.payload.servicetypes[0].name,
        workTypesArray: action.payload.servicetypes[0].worktypes,
        optional: action.payload.servicetypes[1].worktypes,
        dignostic: action.payload.servicetypes[1].worktypes[0].worksubtypes,
        sub_option: action.payload.servicetypes[1].worktypes[2].worksubtypes,
      });
    },
    addServiceType(state, action) {
      return (state = {
        ...state,
        service_type_uuid: action.payload,
      });
    },
    addServiceTypeName(state, action) {
      return (state = {
        ...state,
        service_type_name: action.payload,
      });
    },
    selectRateType(state, action) {
      return (state = {
        ...state,
        select_rate: action.payload,
      });
    },
    addPoolNumber(state, action) {
      return (state = {
        ...state,
        number_of_pools: action.payload,
      });
    },
    addNumberProperties(state, action) {
      return (state = {
        ...state,
        number_of_properties: action.payload,
      });
    },
    addName(state, action) {
      return (state = {
        ...state,
        pool_name: action.payload,
      });
    },
    addSize(state, action) {
      return (state = {
        ...state,
        pool_size: action.payload,
      });
    },
    proposalPoolType(state, action) {
      return (state = {
        ...state,
        pool_type_uuid: action.payload,
      });
    },
    additionalNotes(state, action) {
      return (state = {
        ...state,
        additional_notes: action.payload,
      });
    },
    selectServiceOption(state, action) {
      return (state = {
        ...state,
        work_type: action.payload,
      });
    },
    selectServiceSubOption(state, action) {
      return (state = {
        ...state,
        work_subtype: action.payload,
      });
    },

    addProposalConNotes(state, action) {
      return (state = {
        ...state,
        notes: action.payload,
      });
    },
    addItemModal(state, action) {
      return (state = {
        ...state,
        addItemModal: action.payload,
      });
    },
    openDeactivateModal(state, action) {
      return (state = {
        ...state,
        deactivate_modal: action.payload,
      });
    },
    openActivateModal(state, action) {
      return (state = {
        ...state,
        activate_modal: action.payload,
      });
    },
    openDeleteModal(state, action) {
      return (state = {
        ...state,
        delete_modal: action.payload,
      });
    },
    closeBaseJobForm(state, action) {
      return (state = {
        ...state,
        base_form: action.payload,
      });
    },
    proposalFrequency(state, action) {
      return (state = {
        ...state,
        frequency: action.payload,
      });
    },
    poolCharachterResult(state, action) {
      return (state = {
        ...state,
        select_pool_api: action.payload,
      });
    },
    serviceAddressApi(state, action) {
      return (state = {
        ...state,
        select_service_api: action.payload,
      });
    },
    addressoption(state, action) {
      return (state = {
        ...state,
        service_address_uuid: action.payload,
      });
    },
    addressoptionPool(state, action) {
      return (state = {
        ...state,
        address_option_pool: action.payload,
      });
    },
    primaryNumberProposal(state, action) {
      return (state = {
        ...state,
        primary_no: action.payload,
      });
    },
    apiResponse(state, action) {
      return (state = {
        ...state,
        data_save: action.payload,
      });
    },
    due_date_select(state, action) {
      return (state = {
        ...state,
        proposal_due_date: action.payload,
      });
    },
    setServiceAddress(state, action) {
      return (state = {
        ...state,
        address: action.payload,
      });
    },
    flag_state(state, action) {
      return (state = {
        ...state,
        flag_state: action.payload,
      });
    },
    proposal_page(state, action) {
      return (state = {
        ...state,
        proposal_page: action.payload,
      });
    },
    edit_mode(state, action) {
      return (state = {
        ...state,
        isViewOnly: action.payload,
      });
    },
    deactivateProposal(state, action) {
      return (state = {
        ...state,
        deactivate_proposal: action.payload,
      });
    },
    deleteProposal(state, action) {
      return (state = {
        ...state,
        delete_proposal: action.payload,
      });
    },
    prefered_date(state, action) {
      return (state = {
        ...state,
        preferred_start_date: action.payload,
      });
    },
    email_notes(state, action) {
      return (state = {
        ...state,
        email_notes: action.payload,
      });
    },
    service_notes(state, action) {
      return (state = {
        ...state,
        service_agreement: action.payload,
      });
    },
    service_oreder_modal(state, action) {
      return (state = {
        ...state,
        service_modal_state: action.payload,
      });
    },
    redirect_tab(state, action) {
      return (state = {
        ...state,
        redirect_key: action.payload,
      });
    },
    additionalWork(state, action) {
      return (state = {
        ...state,
        additional_work: action.payload,
      });
    },
    updateAdditionalWork(state, action) {
      return (state = {
        ...state,
        update_additional_work: action.payload,
      });
    },
    deleteAdditionalWork(state, action) {
      return (state = {
        ...state,
        delete_additional_work: action.payload,
      });
    },
    proposalGetResponseData(state, action) {
      return (state = {
        ...state,
        proposal_response_data: action.payload,
      });
    },
    proposal_key(state, action) {
      return (state = {
        ...state,
        proposal_key: action.payload,
      });
    },
    redirect_from_service(state, action) {
      return (state = {
        ...state,
        redirect_proposal: action.payload,
      });
    },
    proposal_number(state, action) {
      return (state = {
        ...state,
        proposal_number: action.payload,
      });
    },
    proposal_base_price(state, action) {
      return (state = {
        ...state,
        proposalBasePrice: action.payload,
      });
    },
    datepicker_statee(state, action) {
      return (state = {
        ...state,
        datepicker_state: action.payload,
      });
    },
    resetServiceData(state, action) {
      return (state = {
        ... state,
        select_rate: "",
        number_of_pools: "",
        number_of_properties: "",
        pool_name: "",
        pool_size: 0,
        pool_type_uuid: "",
        additional_notes: "",
        work_type: "",
        work_subtype: "",
        notes: "",
        base_form: true,///
        proposal_page: false,///
        frequency: "",
        service_address_uuid: "New",
        address_option_pool: "New",
        data_save: "",
        flag_state: true,////
        address: {
          uuid: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip_code: "",
          geo_location: "",
          formatted_address: "",
        },
        preferred_start_date: "",
        service_type_uuid: '',
        service_type_name: '',
        additional_work: [],
        update_additional_work:[],
        delete_additional_work:[],
        proposal_response_data:[],
      });
    },
    saveAndNew(state, action) {
      return (state = {
        ... state,
        service_type_uuid: "17df7b5c-a5c3-4f36-b77f-9cb0570d9af2",
        service_type_name: "maintenance",
        select_rate: "",
        number_of_pools: "",
        number_of_properties: "",
        pool_name: "",
        pool_size: 0,
        pool_type_uuid: "",
        additional_notes: "",
        work_type: "",
        work_subtype: "",
        notes: "",
        base_form: true,///
        proposal_page: false,///
        frequency: "",
        service_address_uuid: "New",
        address_option_pool: "New",
        data_save: "",
        flag_state: true,////
        address: {
          uuid: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip_code: "",
          geo_location: "",
          formatted_address: "",
        },
        preferred_start_date: "",
        additional_work: [],
        update_additional_work:[],
        delete_additional_work:[],
        proposal_response_data:[],
      });
    },
    resetState(state, action) {
      return (state = {
        response_data: [],
        service_type_uuid: "17df7b5c-a5c3-4f36-b77f-9cb0570d9af2",
        workTypesArray: [],
        serviceTypeArray: [],
        email_notes: "",
        service_agreement: "",
        select_rate: "",
        number_of_pools: "",
        number_of_properties: "",
        pool_name: "",
        pool_size: 0,
        pool_type_uuid: "",
        additional_notes: "",
        work_type: "",
        work_subtype: "",
        work_subtype: "",
        notes: "",
        proposal_number:"",
        modal_state: true,
        deactivate_modal: false,
        activate_modal: false,
        delete_modal: false,
        base_form: true,
        delete_proposal: false,
        proposal_page: true,
        frequency: "",
        select_pool_api: "",
        select_service_api: "",
        service_address_uuid: "New",
        address_option_pool: "New",
        primary_no: "",
        data_save: "",
        due_select: "",
        isViewOnly: false,
        flag_state: false,
        datepicker_state:false,
        address: {
          uuid: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip_code: "",
          geo_location: "",
          formatted_address: "",
        },
        preferred_start_date: "",
        service_type_name: "maintenance",
        deactivate_proposal: false,
        service_modal_state:false,
        redirect_key:"",
        additional_work: [],
        update_additional_work:[],
        delete_additional_work:[],
        proposal_response_data:[],
        showLoader:false,
        showPreview:false,
        proposal_key:"",
        redirect_proposal:"",
        proposal_due_date:""
      });
    },
    resetRedirectState(state, action) {
      return (state = {
        ... state,
        response_data: [],
        service_type_uuid: "17df7b5c-a5c3-4f36-b77f-9cb0570d9af2",
        workTypesArray: [],
        serviceTypeArray: [],
        email_notes: "",
        service_agreement: "",
        select_rate: "",
        number_of_pools: "",
        number_of_properties: "",
        pool_name: "",
        pool_size: 0,
        pool_type_uuid: "",
        additional_notes: "",
        work_type: "",
        work_subtype: "",
        work_subtype: "",
        notes: "",
        modal_state: true,
        deactivate_modal: false,
        activate_modal: false,
        delete_modal: false,
        base_form: true,
        delete_proposal: false,
        proposal_page: true,
        frequency: "",
        select_pool_api: "",
        select_service_api: "",
        service_address_uuid: "New",
        address_option_pool: "New",
        primary_no: "",
        data_save: "",
        due_select: "",
        isViewOnly: false,
        flag_state: false,
        address: {
          uuid: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          zip_code: "",
          geo_location: "",
          formatted_address: "",
        },
        preferred_start_date: "",
        service_type_name: "maintenance",
        deactivate_proposal: false,
        service_modal_state:false,
        additional_work: [],
        update_additional_work:[],
        delete_additional_work:[],
        proposal_response_data:[],
        showLoader:false,
        showPreview:false,
        redirect_proposal:"",
        proposal_key:""
      });
    },
  },
});
export const {
  serviceMatrixApi,
  addServiceType,
  selectRateType,
  addPoolNumber,
  addNumberProperties,
  addSize,
  addName,
  service_notes,
  proposalPoolType,
  additionalNotes,
  selectServiceOption,
  selectServiceSubOption,
  addProposalConNotes,
  addItemModal,
  deactivateProposal,
  openActivateModal,
  openDeactivateModal,
  openDeleteModal,
  closeBaseJobForm,
  proposalFrequency,
  addressoption,
  poolCharachterResult,
  serviceAddressApi,
  addressoptionPool,
  primaryNumberProposal,
  apiResponse,
  due_date_select,
  setServiceAddress,
  resetState,
  addServiceTypeName,
  flag_state,
  proposal_page,
  prefered_date,
  edit_mode,
  deleteProposal,
  email_notes,
  serviceOrderCrete,
  service_oreder_modal,
  redirect_tab,
  additionalWork,
  updateAdditionalWork,
  deleteAdditionalWork,
  proposalGetResponseData,
  showLoader,
  showPreview,
  proposal_key,
  redirect_from_service,
  resetServiceData,
  saveAndNew,
  datepicker_statee,
  resetRedirectState,
  proposal_number,
  proposal_base_price
} = ProposalDataSlice.actions;
export default ProposalDataSlice.reducer;
