import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mobiscroll/react";
import userimg from "../../../Assets/icons/down_arrow.svg";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { closeBaseJobForm } from "../../../Store/ProposalDataSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { proposal_page, resetState, flag_state, showPreview } from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";

const ProposalButtons = (props) => {
  const { seachvalidate, previewvalidate, editMode } = props;
  const [toggle, setToggle] = useState(false);
  const btnData = ["Save and New", "Save and Close"];
  const ref = useRef();
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const cancleclick = () => {
    dispatch(closeBaseJobForm(false));
    dispatch(proposal_page(true));
    dispatch(resetState());
    props.tabChange(0);
  }
  // To close the Add new list when click outside
  useEffect(() => {
    const handler = (event) => {
      if (toggle && ref.current && !ref.current.contains(event.target)) {
        let selElemClass = event.target.className;
        if (!selElemClass.includes("proposalListBtn")) {
          setToggle(!toggle);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [toggle]);

  const listClick = (type) => {
    switch (type) {
      case "Save and New":
        setToggle(!toggle);
        seachvalidate("Save and New")
        break;
      case "Save and Close":
        setToggle(!toggle);
        seachvalidate("Save and Close")
        break;
      default:
      // code block MISC
    }
  };

  return (
    <div className="fix-btn-grp">
      <button className="btn btn-white" onClick={cancleclick}>Cancel</button>
      <button className="btn btn-white" disabled={editMode} onClick={previewvalidate}>Preview</button>
      <button className={`btn btn-save-add`} onClick={seachvalidate} disabled={editMode}>Save</button>
      <Button className={`proposalSaveBtn`} onClick={seachvalidate} disabled={editMode}>
        <span className="buttonText">Save and Send</span>
      </Button>
      <Button
        onClick={() => setToggle(!toggle)}
        className={`proposalListBtn`}
        disabled={editMode}
      >
        <img src={userimg} className="downArrowIco" alt="" />
      </Button>
      {toggle && (
        <ul
          ref={ref}
          className={`list-group`}
        >
          {btnData.map((item, i) => (
            <li
              key={i}
              onClick={() => listClick(item)}
              className="list-group-item"
            >
              <b> {item} </b>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default ProposalButtons;
