import React, { useEffect, useState } from "react";
import { Select } from "antd";

const HearAboutUsSelector = ({
  handleChange = () => {},
  allValue = true,
  defaultValue,
}) => {
  const [hearAboutUsList, setHearAboutUsList] = useState([]);

  useEffect(() => {
    const getHearAboutUsList = async () => {
      const data = [
        "Online Search",
        "Social Media",
        "Truck",
        "Post card",
        "Referral",
        "Other",
        "Direct Email"
      ];
      
      setHearAboutUsList(data ? data : []);
    };
    getHearAboutUsList();
  }, []);

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        placeholder={"Select an option"}
      >
        {allValue ? (
          <Select.Option key={"all"} value={"all"} label={"All"}>
            {"All"}
          </Select.Option>
        ) : (
          <></>
        )}
        {hearAboutUsList.sort().map((option) => (
          <Select.Option key={option} value={option} label={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default HearAboutUsSelector;
