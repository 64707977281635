import React from "react";
import moment from "moment-timezone";
import { map, isEmpty, orderBy } from 'lodash';
// import chevronDown from '../../../../Assets/images/chevron-down.png';
import {formatToCurrency} from '../../Components/Common/formatToCurrency';

// mapping of lead stage
const leadStage = {
  new: 'New',
  contacted: 'Contacted',
  interested: 'Interested',
  customer: 'Customer'
};

// mapping of out reach
const outreach = {
  website: 'Website',
  call_center: 'Call Center'

};

const SERVICE_STATUS_COLOR_CLASS = {
  forecast: "gray-status",
  tentative: "info-status",
  paused: "warning-status",
  scheduled: "info-status",
  technician_assigned: "success-status",
  technician_en_route: "success-status",
  in_progress: "success-status",
  complete: "success-status",
  cancelled: "danger-status",
  suspend: "danger-status",
  incomplete: "warning-status"
};

const DAY = {
  1:'Monday',
  2:'Tuesday',
  3:'Wednesday',
  4:'Thursday',
  5:'Friday',
  6:'Saturday',
  0:'Sunday'
}

// mapping SERVICE_STATUS for hardcoded
const SERVICE_STATUS = {
  forecast: "Forecast",
  scheduled: 'Scheduled',
  incomplete: 'Incomplete',
  complete: 'Complete',
  in_progress: 'In Progress',  
  cancelled: 'Cancelled',
  suspend: 'Suspend'
};

// const LAST_CLEANED = { less_than_2_weeks: 'Less than 2 weeks ago', '1-2_months': '2 to 6 weeks', '2_months_or_more': 'More than 6 weeks ago' }

class DataTable {
  // format phone number
  // formatPhoneNumber = (phone) => {
  //   phone = phone?.replace(/\D/g, '');
  //   return phone?.substr(0, 3) + ' ' + phone?.substr(3, 3) + ' ' + phone?.substr(6);
  // }

  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date)
  }

  // customer columns
  customerColumns = (renderAction) => {
    const workOrderColumns = [          
      {
        title: 'Customer',
        dataIndex: 'client__first_name',
        key: 'client__first_name',
        sorter: true,
        className: 'field-wrap text-capitalize',
        render: (text, record) => <span>{`${record?.customer?.first_name||'NA'} ${record?.customer?.last_name||''}`}</span>
      },
      {
        title: 'Work Order #',
        dataIndex: 'work_order_number',
        key: 'work_order_number',
        sorter: true,
        className:'tdate text-capitalize',
        render: (text, record) => <span>{record?.work_order_number||"Not Created"}</span>
      },  
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        className: 'tdate text-capitalize',
        render: (text, record) => {
          if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
            return(
              <div className="status danger-status">
               Cancelled
                <small>Non Billable</small>
              </div>
            )
          } else if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
            return(
              <div className="status danger-status">
               Cancelled
                <small>Billable</small>
              </div>
            )
          } else if (record?.status === 'cancelled' && record?.is_auto_invoice_genrated) {
            return(
              <div className="status danger-status">
               Cancelled
                <small>Billed</small>
              </div>
            )
          } else {
            return(
              <span className={record.status ? `status ${SERVICE_STATUS_COLOR_CLASS[record.status]}`: ''}>{record.status ? SERVICE_STATUS[record.status]: ''}</span>
            )
          }
        }
      },
      {
        title: 'Day',
        dataIndex: 'scheduled_appointment__week_day',
        key: 'scheduled_appointment__week_day',
        sorter: true,
        className: 'tdate',
        render: (text, record) => (
          <b className="no-wrap">{record?.scheduled_time ? DAY[moment(record?.scheduled_time).day()]: ''}</b>)
      },
      {
        title: 'Date',
        dataIndex: 'scheduled_appointment',
        key: 'scheduled_appointment',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => (
          <span className="no-wrap">{record?.scheduled_time ? moment(record?.scheduled_time).format("MM/DD/YYYY" ): ''}</span>)
      },  
      {
        title: 'Optimo Completed',
        dataIndex: 'service_allocation__is_completed',
        key: 'service_allocation__is_completed',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) =><div className="status-optimo">{record?.service_allocation.length===0?"No":record?.service_allocation?.[0]?.is_completed && record?.service_allocation?.[0]?.service_end_by_tech_ts?moment(record?.service_allocation?.[0]?.service_end_by_tech_ts).format("MM/DD/YYYY"):record?.service_allocation?.[0]?.is_completed===false?"No":"No"}<small>{record?.service_allocation.length===0?"":record?.service_allocation?.[0]?.is_completed && record?.service_allocation?.[0]?.service_end_by_tech_ts?moment(record?.service_allocation?.[0]?.service_end_by_tech_ts).format("hh:mm A"):""}</small></div>
      },
      {
        title: 'Optimo Sent',
        dataIndex: 'service_allocation__created_at',
        key: 'service_allocation__created_at',
        sorter: true,
        className:'tdate',
        render: (text, record) => <div className="status-optimo"><small style={{'fontWeight':'unset'}}>{record?.service_allocation.length>0?"YES":"NO"}</small><small style={{'fontWeight':'normal'}}>{record?.service_allocation.length>0?moment(record?.service_allocation?.[0]?.created_at).format("MM/DD/YYYY"):""}</small></div>
      },
      {
        title: 'Technician',
        dataIndex: 'technician__username',
        key: 'technician__username',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.technician?.first_name || 'NA'}</span>
        // render: (text, record) => !isEmpty(record?.field_office) ?
        //   <span>{`${record?.field_office?.name}`}</span> : ''
      },
      {
        title: 'Customer Type',
        dataIndex: 'joined',
        key: 'joined',
        sorter: false,
        className: 'field-wrap text-capitalize',
        render: (text, record) => <b>{record?.service_order?.client_type}</b>
        // render: (text, record) => {
        //   const local_time = this.UTCToLocal(record?.created_at)
        //   return <span>{local_time.fromNow(true)}</span>
        // }
      },
      {
        title: 'Service Frequency',
        dataIndex: 'service_order',
        key: 'service_order',
        sorter: false,
        className:'field-wrap',
        render: (text, record) => <span>{record?.frequency}</span>
      },
      {
        title: 'Address',
        dataIndex: 'formatted_address',
        key: 'formatted_address',
        sorter: false,
        className: 'field-wrap-address',
        render: (text, record) => <span>{record?.service_order?.service_address?.formatted_address}</span>
      },
      {
        title: 'City',
        dataIndex: 'service_order__service_address__city',
        key: 'service_order__service_address__city',
        sorter: true,
        className: 'tdate',
        render: (text, record) => <span>{record?.service_order?.service_address?.city}</span>
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip_code',
        key: 'zip_code',
        sorter: false,
        className: 'tdate',
        render: (text, record) => <span>{record?.service_order?.service_address?.zip_code}</span>
      },
      {
        title: 'Amount',
        dataIndex: 'total_price',
        key: 'total_price',
        sorter: false,
        className: 'tdate',
        render: (text, record) => <span>${formatToCurrency(record?.total_price)}</span>
      },
      {
        title: '',
        dataIndex: 'record',
        key: 'record',
        className: 'no-wrap',
        render: (text, record) => renderAction(record)
      },
    ]
    return workOrderColumns;
  }
  //optimo coloum

  customeroptimorouteColumns = (renderAction) => {
    const workOrderoptimoColumns = [          
      {
        title: 'Customer',
        dataIndex: 'client__last_name',
        key: 'client__last_name',
        sorter: true,
        className: 'field-wrap text-capitalize',
        render: (text, record) => <span>{`${record?.customer?.first_name||'NA'} ${record?.customer?.last_name||''}`}</span>
      },
      {
        title: 'Work Order #',
        dataIndex: 'work_order_number',
        key: 'work_order_number',
        sorter: true,
        className:'tdate text-capitalize',
        render: (text, record) => <span>{record?.work_order_number||"Not Created"}</span>
      },  
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        className: 'tdate text-capitalize',
        render: (text, record) => {
          if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
            return(
              <div className="status danger-status">
               Cancelled
                <small>Non Billable</small>
              </div>
            )
          } else if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
            return(
              <div className="status danger-status">
               Cancelled
                <small>Billable</small>
              </div>
            )
          } else if (record?.status === 'cancelled' && record?.is_auto_invoice_genrated) {
            return(
              <div className="status danger-status">
               Cancelled
                <small>Billed</small>
              </div>
            )
          } else {
            return(
              <span className={record.status ? `status ${SERVICE_STATUS_COLOR_CLASS[record.status]}`: ''}>{record.status ? SERVICE_STATUS[record.status]: ''}</span>
            )
          }
        }
      },
      {
        title: 'Day',
        dataIndex: 'scheduled_appointment__week_day',
        key: 'scheduled_appointment__week_day',
        sorter: true,
        className: 'tdate',
        render: (text, record) => (
          <b className="no-wrap">{record?.scheduled_time ? DAY[moment(record?.scheduled_time).day()]: ''}</b>)
      },
      {
        title: 'Date',
        dataIndex: 'scheduled_appointment',
        key: 'scheduled_appointment',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => (
          <span className="no-wrap">{record?.scheduled_time ? moment(record?.scheduled_time).format("MM/DD/YYYY" ): ''}</span>)
      },
      {
        title: 'Technician',
        dataIndex: 'service_order__client',
        key: 'service_order__client',
        sorter: true,
        className: 'field-wrap',
        render: (text, record) => <span>{record?.technician?.first_name || 'NA'}</span>
        // render: (text, record) => !isEmpty(record?.field_office) ?
        //   <span>{`${record?.field_office?.name}`}</span> : ''
      },
      {
        title: 'Customer Type',
        dataIndex: 'joined',
        key: 'joined',
        sorter: false,
        className: 'field-wrap text-capitalize',
        render: (text, record) => <b>{record?.service_order?.client_type}</b>
        // render: (text, record) => {
        //   const local_time = this.UTCToLocal(record?.created_at)
        //   return <span>{local_time.fromNow(true)}</span>
        // }
      },
      {
        title: 'Service Frequency',
        dataIndex: 'service_order',
        key: 'service_order',
        sorter: false,
        className:'field-wrap',
        render: (text, record) => <span>{record?.frequency}</span>
      },
      {
        title: 'Address',
        dataIndex: 'formatted_address',
        key: 'formatted_address',
        sorter: false,
        className: 'field-wrap-address',
        render: (text, record) => <span>{record?.service_order?.service_address?.formatted_address}</span>
      },
      {
        title: 'City',
        dataIndex: 'service_order__service_address__city',
        key: 'service_order__service_address__city',
        sorter: true,
        className: 'tdate',
        render: (text, record) => <span>{record?.service_order?.service_address?.city}</span>
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip_code',
        key: 'zip_code',
        sorter: false,
        className: 'tdate',
        render: (text, record) => <span>{record?.service_order?.service_address?.zip_code}</span>
      },
      {
        title: 'Amount',
        dataIndex: 'total_price',
        key: 'total_price',
        sorter: false,
        className: 'tdate',
        render: (text, record) => <span>${formatToCurrency(record?.total_price)}</span>
      },
      {
        title: '',
        dataIndex: 'record',
        key: 'record',
        className: 'no-wrap',
        render: (text, record) => renderAction(record)
      },
    ]
    return workOrderoptimoColumns;
  }
  // leads columns
  
  leadColumns = (search_value) => {
    return ([
      {
        title: 'Acc Number',
        dataIndex: 'acc_number',
        key: 'acc_number',
        sorter: false,
        render: (text, record) => <span>{record?.account_number}</span>
      },
      {
        title: 'Customer Name',
        dataIndex: 'customer_name',
        key: 'customer_name',
        sorter: false,
        render: (text, record) => {
          const isInclude = (search_value && (record?.first_name?.toLowerCase()?.includes(encodeURIComponent(search_value)?.toLowerCase()) || record?.last_name?.toLowerCase().includes(encodeURIComponent(search_value)?.toLowerCase())));
          return <span className="ellipse" style={{ textDecoration: isInclude ? "underline" : "" }}><b>{record?.first_name ? `${record?.first_name} ${record?.last_name}` : ''}</b></span>
        }
      },
      {
        title: 'Address (Service)',
        dataIndex: 'address',
        key: 'address',
        sorter: false,
        render: (text, record) => <span className="ellipse">{
          record?.address?.[0]?.address ? 
            `${record?.address?.[0]?.address || ''}, ${record?.address?.[0]?.city || ''}, ${record?.address?.[0]?.state || ''} ${record?.address?.[0]?.zip_code || ''}`
            : ""
          }</span>
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_numbers__phone',
        key: 'phone_numbers__phone',
        sorter: true,
        render: (text, record) => {
          let phone_numbers = '';
          !isEmpty(record?.phone_numbers) && map(record?.phone_numbers, (item) => {
            phone_numbers = item?.primary && item?.phone;
          })

          return <span>{!isEmpty(record?.phone_numbers) ? phone_numbers ? `${phone_numbers}` : `${record?.phone_numbers?.[0]?.phone}` : ''}</span>
        }
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (text, record) => {
          if (!record?.is_dummy_email && !record.email.includes('doody.com')) {
            const isInclude = (search_value && record?.email?.toLowerCase()?.includes(encodeURIComponent(search_value)?.toLowerCase()));
            return <span className="ellipse" style={{ textDecoration: isInclude ? "underline" : "" }}>{record?.email}</span>
          } else {
            return ''
          }          
        }
      },
      {
        title: 'Lead Stage',
        dataIndex: 'lead_stage',
        key: 'lead_stage',
        sorter: false,
        className: 'lfbdr tprofile filterico',
        render: (text, record) => <span>{record?.is_active ? record?.lead_stage ? `${leadStage[record?.lead_stage]}` : '' : 'Deactivated'}</span>
      },
      {
        title: 'Outreach',
        dataIndex: 'outreach',
        key: 'outreach',
        sorter: false,
        className: 'tdate',
        render: (text, record) => <span>{record?.outreach ? `${outreach[record?.outreach]}` : ''}</span>
      },
      {
        title: 'Lead Creation date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        className: 'tdate',
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at)
          return <span>{record?.created_at ? local_time.format('MMM DD, YYYY') : ''}</span>
        }
      },
      {
        title: 'Last Contacted',
        dataIndex: 'last_contacted',
        key: 'last_contacted',
        sorter: false,
        className: 'tdate',
        render: (text, record) => {
          let contact_log = record?.lead_status_logs?.filter(x => x.stage === "contacted")
          contact_log = orderBy(contact_log, 'created_at', 'desc')
          const local_time = this.UTCToLocal(contact_log[0]?.created_at)
          return <span>{(contact_log[0] && contact_log[0].created_at) ? local_time.format('MMM DD, YYYY') : ''}</span>
        }
      },
      {
        title: 'Next Scheduled Contact',
        dataIndex: 'next_scheduled_contact',
        key: 'next_scheduled_contact',
        sorter: true,
        className: 'tdate',
        render: (text, record) => {
          return <span>{record?.next_scheduled_contact ? this.UTCToLocal(record?.next_scheduled_contact).format('MMM DD, YYYY') : ''}</span>
        }
      },
    ]);
  }

  leadCallHistoryColumns = () => {
    return ([
      {
        title: 'Date/Time',
        dataIndex: 'date_time',
        key: 'date_time',
        className: 'tdate',
        sorter: false,
        render: (text, record) => <span>{record?.next_callback ? moment(record?.next_callback).format('MM/DD/YYYY') : ''}</span>
      },
      {
        title: 'Purpose',
        dataIndex: 'purpose',
        key: 'purpose',
        sorter: false,
        className: 'tname',
        render: (text, record) => <span>{record?.reason ? record?.reason : ''}</span>
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        sorter: false,
        className: 'tadd',
        render: (text, record) => <span>{record?.notes ? record?.notes : ''}</span>
      },
      {
        title: 'Contact Method',
        dataIndex: 'contact_method',
        key: 'contact_method',
        sorter: false,
        className: 'tadd',
        render: (text, record) => <span>{record?.contact_method ? record?.contact_method : ''}</span>
      }
    ])
  }
}

export default new DataTable();
