import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { activateDeactivatedCustomer } from "../../Action/Customer";
import { message, Select, Dropdown, Modal } from "antd";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { refreshCustomerStatusTable } from "../../Store/customerSlice";


const ActiveDeactiveCustomer = (props) => {
  const { Option } = Select;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [other_reason, setOther_reason] = useState("");
  const [error, setError] = useState({});
  const [btnLbl, setBtnLbl] = useState("Deactivate");
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [reason, setReason] = useState("");
  const [otherReasonVisible, setOtherReasonVisible] = useState(false);
  const onDeactivate = () => {
    setDeactivateModalVisible(true);
  };
  const onReactivate = () => {
    activateDeactivateUser();
    if(props?.source === "customers-lead-list")
      navigate('/customer-lead-list', { state: {currentTab: 'customer' }});
    else if (props.page === "RC") {
      navigate(`/residential`, { state: { currentTab: 1 } });
    } else {
      navigate(`/commercial/lead-list`, { state: { currentTab: 1 } });
    }
  };
  const resetDeactivateModal = () => {
    setError({});
    setOther_reason("");
    setOtherReasonVisible(false);
    setReason("");
  };
  const activateDeactivateUser = async () => {
    const data = {
      //   "uuid": props.record?.uuid,
      customer_status: props.record?.is_active ? "Deactivate" : "Activate",
      // "cancelation_reason":reason || '',
    };
    if (reason) {
      data.cancelation_reason = reason;
    }
    if (other_reason) {
      data.cancelation_details = other_reason;
    }
    await activateDeactivatedCustomer(props.record?.uuid, data);
    dispatch(refreshCustomerStatusTable(data?.customer_status));
  };
  const validate = () => {
    const schema = yup.object().shape({
      reason: yup.string().required("Reason Is Required."),
    });
    const data = { reason };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setError({});
        activateDeactivateUser();
        setDeactivateModalVisible(false);
        if(props?.source === "customers-lead-list")
          navigate('/customer-lead-list', { state: {currentTab: 'inactive_customer' }});
        else if (props.page === "RC") {
          navigate(`/residential`, { state: { currentTab: 2 } });
        } else {
          navigate(`/commercial/lead-list`, { state: { currentTab: 2 } });
        }
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setError(err_data);
        console.log(error);
        // setDeactivateModalVisible(false);
        // message.error("Something went wrong!");
      });
  };

  useEffect(() => {
    setBtnLbl(props.record?.is_active ? "Deactivate" : "Reactivate");
  }, [props]);

  return (
    <>
      <button
        className="btn btn-warning view-all-btn"
        onClick={(e) => {
          // e.preventDefault();
          if (btnLbl === "Deactivate") {
            onDeactivate();
          } else {
            onReactivate();
          }
        }}
      >
        {btnLbl}
      </button>
      <Modal
        open={deactivateModalVisible}
        okText={btnLbl}
        mask={true}
        onOk={validate}
        onCancel={() => {
          resetDeactivateModal();
          setDeactivateModalVisible(false);
        }}
        cancelText={"Cancel"}
        className="delete_user_modal"
        width={400}
      >
        <h4>{`${btnLbl} Customer`}</h4>
        <span>Please provide a reason for deactivating this user</span>
        <br />
        <div className="field-group">
          <label>
            Reason<sup>*</sup>
          </label>
          <Select
            showSearch
            optionFilterProp="children"
            placeholder={"Select reason"}
            value={reason || ""}
            onChange={(e) => {
              setReason(e);
              if (e === "other") {
                setOtherReasonVisible(true);
              } else {
                setOtherReasonVisible(false);
              }
            }}
          >
            <Option value="">Select reason</Option>
            <Option value="service">Service Quality</Option>
            <Option value="moving">Moving</Option>
            <Option value="competition">Pricing</Option>
            <Option value="other">Other</Option>
          </Select>
          {error?.["reason"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {error?.["reason"]}
            </label>
          ) : (
            ""
          )}
        </div>
        {otherReasonVisible && (
          <div className="form-group mb-20">
            <div className="frow">
              <textarea
                placeholder="Add deactivation notes here"
                name="other_reason"
                rows="5"
                value={other_reason}
                onChange={(e) => {
                  setOther_reason(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        )}
        <p>All the scheduled and forecast workorders will be deleted.</p>
      </Modal>
    </>
  );
};

export default ActiveDeactiveCustomer;
