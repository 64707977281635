import React from "react";
import { Modal,message } from "antd";
import {
  openDeleteModal,resetState
} from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import "../../../Assets/css/proposalBase.css";
import { deleteProposal } from "../../../Action/Customer";
import Loader from "../../../Components/Loader";
import { useState } from "react";


const DeleteProposal = (props) => {
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [loader, setLoader] = useState(false);

  const onclosedelete = () => {
    dispatch(openDeleteModal(false));
  };


  const deleteProposals = async () => {
    setLoader(true);
    const proposal_uuid= props.state;
    const result = await deleteProposal(proposal_uuid);
    message.success(result.message,10)
    dispatch(openDeleteModal(false))
    dispatch(resetState());
    setLoader(false);
    };
  return (
    <div>
      {proposals.delete_modal == true && (
        <div>
         {loader && <Loader />}
          <Modal
            title={<h3> Delete Proposal </h3>}
            open={proposals.delete_modal}
            closeIcon={null}
            width={400}
            footer={[
              <button
                key="upload"
                style={{ backgroundColor: "white", color: "black" }}
                className={`btn btn-waning`}
                onClick={() => onclosedelete()}
              >
                Cancel
              </button>,
              <button
                key="cancel"
                className="btn btn-brand btn-bdr"
                onClick={() => deleteProposals()}
              >
                Delete
              </button>,
            ]}
          >
            <div>
              <p>Are you sure you want to delete this proposal?</p>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default DeleteProposal;
