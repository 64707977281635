import React from "react";
import { Empty, Skeleton } from "antd";
import _ from "lodash";
import { Column } from "@ant-design/plots";
import { useReport } from "../../Hooks/Report.Hook";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";

const TerritoryExtraSidebar = () => {
  const { chartData, loading } = useReport();

  const config = {
    data: chartData,
    appendPadding: [0, 0, 20, 0],
    xField: "title",
    yField: "revenue",
    color: "#005D89",
    xAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text) => text.charAt(0).toUpperCase() + text.slice(1),
      },
    },
    yAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
        formatter: (v) => `$${formatToCurrency(v)}`,
      },
    },
    legend: {
      position: "bottom",
      layout: "horizontal",
      maxRow: 4,
      pageNavigator: {
        marker: {
          style: {
            size: 0,
          },
        },
        text: {
          style: {
            fontSize: 0,
          },
        },
      },
      itemName: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text, item, index) =>
          text.charAt(0).toUpperCase() + text.slice(1),
      },
    },
    tooltip: {
      formatter: ({ title, revenue }) => ({
        name: title.charAt(0).toUpperCase() + title.slice(1),
        value: `$ ${formatToCurrency(revenue)}`,
      }),
    },
    meta: {
      sales: {
        alias: "Revenue",
      },
    },
  };
  return (
    <div className="territory-chart">
      <label style={{ margin: "20px 0px", width: "100%", textAlign: "center" }}>
        <b>Aging Summary Report</b>
      </label>
      {loading ? (
        <Skeleton active style={{ padding: 10 }} />
      ) : !_.isEmpty(chartData) ? (
        <Column {...config} />
      ) : (
        <Empty className="no-data-empty" description={false} />
      )}
    </div>
  );
};

export default TerritoryExtraSidebar;
