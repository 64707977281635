import axiosInstance from "../../Lib/Axios";

//Add User
export const addUser = (params) => {
  return axiosInstance
    .post(`/crm/staff-user/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          if (error.response.status === 500) {
            return error?.response?.data
          }
          return error?.response?.data
        }
      }
    });
};

// get user of detail
export const getUserEditDetail = (params) => {
  return axiosInstance
  ///
    .get(`/crm/staff-user/${params}`)
    .then((response) => {
      if (response?.data) {
        // response.data.results.map((each) => {
          if (response.data?.staff_profile?.permissionset) {
            let per = response.data.staff_profile.permissionset;
            let temp = [];
            for (var key in per) {
              if (per[key]) {
                temp.push(key);
              }
            }
            response.data.staff_profile.permissionset = temp;
          }
        // })
      }
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data
        }
      }
    });
};

// update User Details
export const updateEditedUSer = (params, id) => {
  return axiosInstance
    .patch(`/crm/staff-user/${id}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        } else {
          if (error.response.status === 500) {
            return error?.response?.data;
          }
        }
      }
    });
};

//Get User List
export const getUserList = (params) => {
  return axiosInstance
    .get(`/crm/staff-user/`, { params })
    .then((response) => {
      if (response?.data?.results) {
        response.data.results.map((each) => {
          if (each?.staff_profile?.permissionset) {
            let per = each.staff_profile.permissionset;
            let temp = [];
            for (var key in per) {
              if (per[key]) {
                temp.push(key);
              }
            }
            each.staff_profile.permissionset = temp;
            return {
              ...each,
            }
          }
        })
      }
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data;
        }
      }
    });
};

//Delete User
export const deleteUser = (params) => {
  return axiosInstance
    .delete(`/crm/staff-user/${params}/`, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data;
        }
      }
    });
};

// Get Group List
export const getGroupList = (params) => {
  return axiosInstance
    .get(`/crm/user-group/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data
        }
      }
    });
};

// get Franchise list
export const getFranchiseList = () => {
  return axiosInstance
    .get(`field-office/?ordering=name/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data;
        }
      }
    });
};

//Add Group
export const addNewGroup = (params) => {
  return axiosInstance
    .post(`/crm/user-group/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data;
        }
      }
    });
};

//Get Group Details
export const getGroupDetails = (params) => {
  return axiosInstance
    .get(`/crm/user-group/${params}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data;
        }
      }
    });
};

// update Group Details
export const updateEditeGroup = (params, id) => {
  return axiosInstance
    .patch(`/crm/user-group/${id}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        } else {
          return error?.response?.data;
        }
      }
    });
};

//Delete User
export const deleteGroup = (params) => {
  return axiosInstance
    .delete(`/crm/user-group/${params}/`, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data;
        }
      }
    });
};

export const getRoles = (params) => {
  return axiosInstance
    .get(`/role/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data
        }
      }
    });
};

export const getTechSkills = () => {
  return axiosInstance
    .get(`/skillset/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data
        }
      }
    });
};

export const getTechPermissions = () => {
  return axiosInstance
    .get(`field-technicians-permissions/get_tech_permissions/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error.response) {
          return error?.response?.data
        }
      }
    });
};
