import React,
{
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import _, { startCase } from "lodash";
import {
  getTerritorySummaryReport,
  getChemicalReport,
  getTypeRevenue,
  getInvoiceReconciliationReport,
  getChemicalReportSummary,
  getExpiringPaymentReport,
  getWorkOrderReport,
  getAgingSummaryReport,
  getChemicalPricingReport,
  getTechnicianNotesReport
} from "../../Action/Reports";
import {
  /*getResidentialLeadList,
  getCommercialCustomerList,*/
  getLeadsList,
  getCustomerList,
  getCustomerListCommercial,
  getCustomersList,
  getCustomers,
  getLeads
} from "../../Action/Customer";
import {
  withJSONColumnOrder,
  withJSONSameOrder,
} from "../Common/getOrderingObject";
import { setFranchiseSelected } from '../../Utils/selectedFranchise';

const ReportContext = createContext();

export function ReportsProvider({ children }) {
  const { id } = useParams();
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;
  const [franchise, setFranchise] = useState(
    isCorporate
      ? { value: userInfo.user.associated_field_offices[0]?.uuid, label: userInfo.user.associated_field_offices[0]?.name }
      : userInfo.user.associated_field_offices[0].is_multi_location_franchise?
        {
          value: "all",
          label: "All",
        }
        :
        {
          value: userInfo.user.associated_field_offices[0]?.uuid,
          label: userInfo.user.associated_field_offices[0]?.name,
        }
  );
  const [fieldType, setFieldType] = useState("all");
  const [serviceType, setServiceType] = useState("all");
  const [serviceDate, setServiceDate] = useState("day");
  const [serviceDatePeriod, setServiceDatePeriod] = useState({});
  const [serviceSecondDatePeriod, setServiceSecondDatePeriod] = useState({});
  const [agingStatus, setAgingStatus] = useState({
    value: "all",
    label: "All",
  });
  const [leadStage, setLeadStage] = useState("all");
  const [hearAboutUs, setHearAboutUs] = useState("all");
  const [channelAttribution, setChannelAttribution] = useState("all");
  const [outreach, setOutreach] = useState("all");
  const [creationDatePeriod, setCreationDatePeriod] = useState({});
  const [contactedDatePeriod, setContactedDatePeriod] = useState({});
  const [lastServiceDatePeriod, setLastServiceDatePeriod] = useState({});
  const [joinedDatePeriod, setJoinedDatePeriod] = useState({});
  const [stage, setStage] = useState();
  const [query, setQueryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [sorter, setSorter] = useState(null);
  const [superSearch, setSuperSearch] = useState("");
  const [pageSize, setPageSize] = useState(15);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [reportType, setReportType] = useState("detail");
  const [leadReportStageType, setLeadReportStageType] = useState();
  const [leadReportclientType, setLeadReportclientType] = useState("all");
  const [groupBy, setGroupBy] = useState("customer");
  const [reportMainType, setReportMainType] = useState("detail");
  const [reportSubType, setReportSubType] = useState("customer");
  const [technician, setTechnician] =  useState(['all']);
  const [errors, setErrors] = useState({});
  const [isMultiLocation,setIsMultiLocation] = useState(userInfo.user.associated_field_offices[0].is_multi_location_franchise || false);
  const buildChart = useCallback(async () => {
    let data = [];
    switch (id) {
      case "in_territory_revenue_reports":
        try {
          data = await getTypeRevenue({
            field_office__uuid:
              franchise.value === "all" ? undefined : franchise.value,
            u_type:
              fieldType === "all"
                ? undefined
                : fieldType.toLowerCase().replace(/ /g, "_"),
            search: superSearch !== "" ? superSearch : undefined,
            service_type:
              serviceType === "all"
                ? undefined
                : serviceType.toLowerCase().replace(/ /g, "_"),
          });
          setChartData(
            data
              ? data.reduce((prev, current) => {
                  if (current.serviced === 0 && current.owned === 0) {
                    return prev;
                  }
                  return [
                    ...prev,
                    {
                      type: "owned",
                      revenue: current.owned,
                      service: current.service.toLowerCase(),
                    },
                    {
                      type: "serviced",
                      revenue: current.serviced,
                      service: current.service.toLowerCase(),
                    },
                  ];
                }, [])
              : []
          );
        } catch (error) {
          setChartData([]);
        }
        return;
      default: {
        return;
      }
    }
  }, [franchise, fieldType, superSearch, id, serviceType]);

  const buildReport = useCallback(async (is_fired_by_build_buttun) => {
    let data = [];
    setLoading(true);
    if(is_fired_by_build_buttun!== undefined && is_fired_by_build_buttun){
      setCurrent(1);
    }
    setQueryData({
      franchise: franchise.value === "all" ? undefined : franchise,
      ...withJSONColumnOrder(sorter),
      period: serviceDatePeriod,
      secondPeriod: serviceSecondDatePeriod,
      agingStatus: agingStatus.value === "all" ? undefined : agingStatus,
      leadStage: leadStage.value === "all" ? undefined : leadStage.value,
      outreach: outreach.value === "all" ? undefined : outreach.value,
      hearAboutUs: hearAboutUs.value === "all" ? undefined : hearAboutUs.value,
      channelAttribution: channelAttribution.value === "all" ? undefined : channelAttribution.value,
      search: superSearch !== "" ? superSearch : undefined,
      offset: (current - 1) * pageSize,
      limit: pageSize,
    });

    // eslint-disable-next-line default-case
    switch (id) {
      case "in_territory_revenue_reports":
        try {
          data = await getTerritorySummaryReport({
            field_office__uuid:
              franchise.value === "all" ? undefined : franchise.value,
            u_type:
              fieldType === "all"
                ? undefined
                : fieldType.toLowerCase().replace(/ /g, "_"),
            search: superSearch !== "" ? superSearch : undefined,
            service_type:
              serviceType === "all"
                ? undefined
                : serviceType.toLowerCase().replace(/ /g, "_"),
            offset: (current - 1) * pageSize,
            limit: pageSize,
          });
          setReportData(data ? data : []);
        } catch (error) {
          setReportData([]);
        }
        await buildChart();
        break;
      case "chemical_reports":
        data =
          reportType === "detail"
            ? await getChemicalReport({
                service__field_office__uuid:
                  franchise.value === "all" ? undefined : franchise.value,
                ...withJSONColumnOrder(sorter),
                search: superSearch !== "" ? superSearch : undefined,
                start_date: !_.isEmpty(serviceDatePeriod)
                  ? serviceDatePeriod.start_date
                  : moment().format("YYYY-MM-DD"),
                end_date: !_.isEmpty(serviceDatePeriod)
                  ? serviceDatePeriod.end_date
                  : moment().format("YYYY-MM-DD"),
                offset: (current - 1) * pageSize,
                limit: pageSize,
                technician: technician.toString()
              })
            : 
              await getChemicalReportSummary({
                  service__field_office__uuid:
                    franchise.value === "all" ? undefined : franchise.value,
                  ...withJSONColumnOrder(sorter),
                  groupBy:groupBy,
                  search: superSearch !== "" ? superSearch : undefined,
                  start_date: !_.isEmpty(serviceDatePeriod)
                    ? serviceDatePeriod.start_date
                    : moment().format("YYYY-MM-DD"),
                  end_date: !_.isEmpty(serviceDatePeriod)
                    ? serviceDatePeriod.end_date
                    : moment().format("YYYY-MM-DD"),
                  offset: (current - 1) * pageSize,
                  limit: pageSize,
                  technician: technician.toString()
                });
        setReportMainType(reportType);
        setReportSubType(groupBy);
        setTotal(data.count);
        setReportData(data?.results ? data.results : []);
        break;
      case "invoice_reconciliation_reports":
        if (franchise === "all" || serviceDate === "day") {
          break;
        }
        data = await getInvoiceReconciliationReport({
          field_office__uuid:
            franchise.value === "all" ? undefined : franchise.value,
          ...serviceDatePeriod,
          search: superSearch !== "" ? superSearch : undefined,
        });
        const invoiceData = {
          products: {
            ...data.products,
            product_list: [
              ...data?.products.product_list.reduce((result, list) => {
                return list.services.length > 0
                  ? [
                      ...result,
                      { name: startCase(list.service_name) },
                      ...list.services,
                    ]
                  : [...result, ...list.services];
              }, []),
            ],
          },
          services: {
            ...data.services,
            service_list: [
              ...data?.services.service_list.reduce((result, list) => {
                return list.services.length > 0
                  ? [
                      ...result,
                      { name: startCase(list.service_name) },
                      ...list.services,
                    ]
                  : [...result, ...list.services];
              }, []),
            ],
          },
          discounts: data?.discounts,
          sales_tax: data?.sales_tax,
          total_invoiced:
            data?.products.total_invoiced +
            data?.services.total_invoiced +
            data?.discounts.total_invoiced +
            data?.sales_tax.total_invoiced,
          total_paid:
            data?.products.total_paid +
            data?.services.total_paid +
            data?.discounts.total_paid +
            data?.sales_tax.total_paid,
          total_completed:
            data?.products.total_completed +
            data?.services.total_completed +
            data?.discounts.total_completed +
            data?.sales_tax.total_completed,
        };
        setChartData(
          [
            {
              type: "Service",
              revenue: invoiceData.services.total_paid,
              service: "Service",
            },
            {
              type: "Discount",
              revenue: invoiceData.discounts.total_paid,
              service: "Discount",
            },
            {
              type: "Taxes",
              revenue: invoiceData.sales_tax.total_paid,
              service: "Taxes",
            },
          ].filter(({ revenue }) => revenue !== 0)
        );
        setReportData(invoiceData);
        break;
      case "captured_lead_reports":
        let ordering = '';    
        if (sorter!== null && sorter.column) {
          ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
        } else {
          ordering = '-created_at'
        }
        data =
          stage?.value === "Lead"?
          await getLeads({
            q: superSearch !== "" ? superSearch : undefined,
            offset: (current - 1) * pageSize,
            limit: pageSize,
            ordering: ordering,
            contacted_date__lte:!_.isEmpty(contactedDatePeriod)? contactedDatePeriod.contacted_end_date: undefined,
            contacted_date__gte: !_.isEmpty(contactedDatePeriod)? contactedDatePeriod.contacted_start_date: undefined,
            lead_profile__field_office__uuid:franchise.value === "all" ? undefined : franchise.value,
            lead_profile__outreach:outreach.value === "all" ? undefined : outreach.value,
            lead_profile__stage:leadStage.value === "all" ? undefined : leadStage.value,
            created_at__lte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.end_date: undefined,
            created_at__gte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.start_date: undefined,
            channel_attribution: channelAttribution.value === "all" ? undefined : channelAttribution.value,
            hear_about_us: hearAboutUs.value === "all" ? undefined : hearAboutUs.value,
            account_type: leadReportclientType,
          })
         :(stage?.value === "Customer")
                  ? 
                  await getCustomers({
                    is_active: null,
                    q: superSearch !== "" ? superSearch : undefined,
                    offset: (current - 1) * pageSize,
                    limit: pageSize,
                    ordering: ordering,
                    lead_profile__field_office__uuid:franchise.value === "all" ? undefined : franchise.value,
                    account_type: leadReportclientType,
                    created_at__lte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.end_date: undefined,
                    created_at__gte: !_.isEmpty(joinedDatePeriod)? joinedDatePeriod.start_date: undefined,
                    last_serviced__lte: !_.isEmpty(lastServiceDatePeriod)? lastServiceDatePeriod.end_date: undefined,
                    last_serviced__gte: !_.isEmpty(lastServiceDatePeriod)? lastServiceDatePeriod.start_date: undefined,
                    service_type: serviceType === "all" ? undefined : serviceType.toLowerCase().replace(/ /g, "_"),
                    outreach: outreach.value === "all" ? undefined : outreach.value,
                    channel_attribution: channelAttribution.value === "all" ? undefined : channelAttribution.value,
                    hear_about_us: hearAboutUs.value === "all" ? undefined : hearAboutUs.value,
                  })
                  
                : undefined
                ;
        
        setTotal(data?.count);
        setLeadReportStageType(stage?.value);
        //setLeadReportclientType(reportType); 
        setReportData(data?.results ? data?.results : []);
        break;
      case "aging_summary_reports":
        try {
          data = await getAgingSummaryReport({
            field_office__uuid:
              franchise.value === "all" ? undefined : franchise.value,
            ...withJSONSameOrder(sorter),
            start_invoice_date: !_.isEmpty(serviceDatePeriod)
              ? serviceDatePeriod.start_date
              : undefined,
            end_invoice_date: !_.isEmpty(serviceDatePeriod)
              ? serviceDatePeriod.end_date
              : undefined,
            start_due_date: !_.isEmpty(serviceSecondDatePeriod)
              ? serviceSecondDatePeriod.start_date
              : undefined,
            end_due_date: !_.isEmpty(serviceSecondDatePeriod)
              ? serviceSecondDatePeriod.end_date
              : undefined,
            status: agingStatus.value === "all" ? undefined : agingStatus.value,
            q: superSearch !== "" ? superSearch : undefined,
            offset: (current - 1) * pageSize,
            limit: pageSize,
          });
          setTotal(data.count);
          const agingChart = data?.results.reduce(
            (result, row) => {
              return [
                {
                  type: "first_days",
                  revenue:
                    result.find((item) => item.type === "first_days").revenue +
                    row.first_days,
                  title: "1-30",
                },
                {
                  type: "medium_days",
                  revenue:
                    result.find((item) => item.type === "medium_days").revenue +
                    row.medium_days,
                  title: "31-60",
                },
                {
                  type: "old_days",
                  revenue:
                    result.find((item) => item.type === "old_days").revenue +
                    row.old_days,
                  title: "61-90",
                },
                {
                  type: "older_days",
                  revenue:
                    result.find((item) => item.type === "older_days").revenue +
                    row.older_days,
                  title: "91+",
                },
              ];
            },
            [
              { type: "first_days", revenue: 0, title: "1-30" },
              { type: "medium_days", revenue: 0, title: "31-60" },
              { type: "old_days", revenue: 0, title: "61-90" },
              { type: "older_days", revenue: 0, title: "91+" },
            ]
          );
          setChartData(agingChart);
          setReportData(data?.results ? data?.results : []);
        } catch (error) {
          setReportData([]);
        }
        break;
      case "expiring_customer_payment_method_report":
        try {
          data = await getExpiringPaymentReport({
            field_office__uuid:
              franchise.value === "all" ? undefined : franchise.value,
            ...withJSONSameOrder(sorter),
            start_date: !_.isEmpty(serviceDatePeriod)
              ? serviceDatePeriod.start_date
              : moment().startOf("month").format("YYYY-MM-DD"),
            end_date: !_.isEmpty(serviceDatePeriod)
              ? serviceDatePeriod.end_date
              : moment().endOf("month").format("YYYY-MM-DD"),
            q: superSearch !== "" ? superSearch : undefined,
            offset: (current - 1) * pageSize,
            limit: pageSize,
          });
          setTotal(data.count);
          setReportData(data?.results ? data?.results : []);
        } catch (error) {
          setReportData([]);
        }
        break;
      case "work_order_report":
        try {
          data = await getWorkOrderReport({
            service_order__field_office__uuid:
              franchise.value === "all" ? undefined : franchise.value,
            ...withJSONSameOrder(sorter),
            scheduled_appointment__range: !_.isEmpty(serviceDatePeriod)
              ? `${serviceDatePeriod.start_date}T0:0:0.0Z,${serviceDatePeriod.end_date}T23:59:59.99Z`
              : undefined,
            q: superSearch !== "" ? superSearch : undefined,
            offset: (current - 1) * pageSize,
            limit: pageSize,
          });
          setTotal(data.count);
          setReportData(data?.results ? data?.results : []);
        } catch (error) {
          setReportData([]);
        }
        return;
      case "chemical_report_pricing":
        try {
          data = await getChemicalPricingReport({
            service__field_office__uuid:
              franchise.value === "all" ? undefined : franchise.value,
            ...withJSONColumnOrder(sorter),
            search: superSearch !== "" ? superSearch : undefined,
            start_date: !_.isEmpty(serviceDatePeriod)
              ? serviceDatePeriod.start_date
              : moment().format("YYYY-MM-DD"),
            end_date: !_.isEmpty(serviceDatePeriod)
              ? serviceDatePeriod.end_date
              : moment().format("YYYY-MM-DD"),
            offset: (current - 1) * pageSize,
            limit: pageSize,
          });
          setTotal(data.count);
          setReportData(data?.results ? data?.results : []);
        } catch (error) {
          setReportData([]);
        }
        break;
      case "tech_notes":
        data = await getTechnicianNotesReport({
                service_order__field_office__uuid:
                  franchise.value === "all" ? undefined : franchise.value,
                ...withJSONColumnOrder(sorter),
                search: superSearch !== "" ? superSearch : undefined,
                start_date: !_.isEmpty(serviceDatePeriod)
                  ? serviceDatePeriod.start_date
                  : moment().format("YYYY-MM-DD"),
                end_date: !_.isEmpty(serviceDatePeriod)
                  ? serviceDatePeriod.end_date
                  : moment().format("YYYY-MM-DD"),
                offset: (current - 1) * pageSize,
                limit: pageSize,
                technician: technician.toString(),
                account_type: leadReportclientType,
                service_type: serviceType === "all" ? undefined : serviceType.toLowerCase().replace(/ /g, "_"),
              });
        setTotal(data.count);
        setReportData(data?.results ? data.results : []);
        break;
    }
    setLoading(false);
  }, [
    franchise,
    fieldType,
    pageSize,
    sorter,
    superSearch,
    current,
    id,
    serviceDatePeriod,
    serviceSecondDatePeriod,
    serviceType,
    reportType,    
    leadStage,
    outreach,
    hearAboutUs,
    channelAttribution,
    creationDatePeriod,
    contactedDatePeriod,
    stage,
    leadReportStageType,
    leadReportclientType,
    query,
    agingStatus,
    lastServiceDatePeriod,
    joinedDatePeriod,
    groupBy,
    reportMainType,
    reportSubType,
    technician,
    isMultiLocation,
    errors,
    setFranchise,
    setServiceDate,
    setQueryData,
    setLoading,
  ]);

  const resetReport = useCallback(async () => {
    setFranchise("all");
    setFieldType("all");
    setLeadStage("all");
    setHearAboutUs("all");
    setChannelAttribution("all");
    setOutreach("all"); 
    setTechnician(['all']);   
    setErrors({})
    //setStage("Lead")
    window.location.reload();
  }, []);

  useEffect(() => {
    buildReport();
  }, [current, pageSize, sorter,superSearch]);
  useEffect(() => {
    setFranchiseSelected(franchise.value,franchise.label);
  }, [franchise]);

  const value = useMemo(() => {
    return {
      franchise,
      fieldType,
      setFieldType,
      setFranchise,
      buildReport,
      buildChart,
      reportData,
      resetReport,
      current,
      setCurrent,
      total,
      pageSize,
      setPageSize,
      serviceDate,
      setServiceDate,
      sorter,
      setSorter,
      superSearch,
      setSuperSearch,
      serviceDatePeriod,
      setServiceDatePeriod,
      serviceType,
      setServiceType,
      chartData,
      setChartData,
      reportType,
      setReportType,
      query,
      setQueryData,
      serviceSecondDatePeriod,
      setServiceSecondDatePeriod,
      agingStatus,
      setAgingStatus,
      loading,
      setLoading,
      leadStage,
      hearAboutUs,
      channelAttribution,
      outreach,
      creationDatePeriod,
      contactedDatePeriod,
      stage,
      setLeadStage,
      setChannelAttribution,
      setHearAboutUs,
      setOutreach,
      setCreationDatePeriod,
      setContactedDatePeriod,
      setStage,
      leadReportStageType,
      leadReportclientType,
      setLeadReportStageType,
      setLeadReportclientType,
      lastServiceDatePeriod,
      joinedDatePeriod,
      setLastServiceDatePeriod,
      setJoinedDatePeriod,
      reportMainType,
      reportSubType,
      setReportMainType,
      setReportSubType,
      groupBy,
      setGroupBy,
      technician,
      setTechnician,
      isMultiLocation,
      setIsMultiLocation,
      errors,
      setErrors
    };
  }, [
    franchise,
    fieldType,
    setFieldType,
    setFranchise,
    buildReport,
    buildChart,
    reportData,
    resetReport,
    current,
    setCurrent,
    total,
    pageSize,
    setPageSize,
    serviceDate,
    setServiceDate,
    sorter,
    setSorter,
    superSearch,
    setSuperSearch,
    serviceDatePeriod,
    setServiceDatePeriod,
    serviceType,
    setServiceType,
    chartData,
    setChartData,
    reportType,
    setReportType,
    query,
    setQueryData,
    serviceSecondDatePeriod,
    setServiceSecondDatePeriod,
    agingStatus,
    setAgingStatus,
    loading,
    setLoading,
    leadStage,
    hearAboutUs,
    channelAttribution,
    outreach,
    creationDatePeriod,
    contactedDatePeriod,
    stage,
    setLeadStage,
    setChannelAttribution,
    setHearAboutUs,
    setOutreach,
    setCreationDatePeriod,
    setContactedDatePeriod,
    setStage,
    leadReportStageType,
    leadReportclientType,
    setLeadReportStageType,
    setLeadReportclientType,
    lastServiceDatePeriod,
    joinedDatePeriod,
    setLastServiceDatePeriod,
    setJoinedDatePeriod,
    groupBy,   
    setGroupBy,
    reportMainType,
    reportSubType,
    setReportMainType,
    setReportSubType,
    technician,
    setTechnician,
    errors,
    setErrors,
    isMultiLocation,
    setIsMultiLocation
  ]);

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
}

export function useReport() {
  return useContext(ReportContext);
}
