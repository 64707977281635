import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

// Check Zip Code
export const checkZipCode = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`field-office/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Initial Clean Days
export const initialCleaningDays = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`field-office/initial_cleanning_days/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// get list of franchise
export const getFranchise = () => {
  // return (dispatch) => {
  return axiosInstance
    .get(`field-office/?ordering=name/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

export const getFranchiseListAll = () => {
  return axiosInstance
    .get('field-office/list_all')
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    })
};

// get commercial customer table list

export const getCommercailCustomer = () => {
  // return (dispatch) => {
  return axiosInstance
    .get(`field-office/?ordering=name/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};
//end of commercial customer tabel list
// get list of franchise
export const getFieldTechnician = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .get(`ftech/field-technicians/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};
export const getFieldTechnicianCalendar = async (params) => {
  try {
    const response = await axiosInstance.get(`ftech/field-technicians/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};

// For Franchise Table
export const getFranchiseData = (params) => {
  return axiosInstance
    .get(`/field-office/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Create Franchise
export const createFranchise = (params) => {
  return axiosInstance
    .post(`field-office/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Get franchise Details
export const updateFranchise = (params, uuid) => {
  return axiosInstance
    .patch(`/field-office/${uuid}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// quick callback api
export const loginWithQuickbook = (uuid, params) => {
  console.log("loginWithQuickbook");
  return axiosInstance
    .get(`/field-office/${uuid}/quickbooks-oauth/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// quick callback api
export const loginCallbackQuickbook = (uuid, params) => {
  return axiosInstance
    .get(`/field-office/${uuid}/quickbooks-oauth-callback/${params}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Add auto schedule date and payment date for Maintenance Services
export const addAutoScheduleDate = async (uuid, params) => {
  try {
    const response = await axiosInstance.put(
      `field-office/${uuid}/autoschedule/`, params
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};

// Get auto schedule date and payment date for Maintenance Services
export const getAutoScheduleDate = async (uuid) => {
  try {
    const response = await axiosInstance.get(
      `/field-office/${uuid}/autoschedule`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};

//Post franchise Details
export const pulseMSetUp = (uuid, params) => {

  return axiosInstance
    .patch(`/field-office-app-setup/${uuid}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response;
      }
    });
};
//Get franchise Details
export const getDataPulseMSetUp = (uuid, params) => {
  return axiosInstance
    .get(`/field-office-app-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const updateFranchiseServiceNotification = (uuid, params) => {
  return axiosInstance
    .patch(`/field-office-notification-setup/${uuid}/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const getFranchiseServiceNotification = (uuid) => {
  return axiosInstance
    .get(`/field-office-notification-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Post franchise Optimoroute Details
export const optimorouteSetUp = (uuid, params) => {

  return axiosInstance
    .patch(`/field-office-optimoroute-setup/${uuid}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Get franchise Optimoroute Details
export const getDataOptimorouteSetUp = (uuid, params) => {
  return axiosInstance
    .get(`/field-office-optimoroute-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Post franchise StaxPay Details
export const staxPaySetUp = (uuid, params) => {

  return axiosInstance
    .patch(`/field-office-staxpay-setup/${uuid}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Get franchise StaxPay Details
export const getDataStaxPaySetUp = (uuid, params) => {
  return axiosInstance
    .get(`/field-office-staxpay-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

// Delete Service Orders API
export const delete_service_orders_details = (uuid) => {
  return axiosInstance
    .patch(
      `/service-order/delete_service_order/?service_order_uuid=` + uuid
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Search technician
export const searchTechnician = (params) => {
  return axiosInstance
    .get(`crm/staff-user/?q=${params}&is_active=true&role=franchise_owner,field_tech`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      console.log('Error =>', error)
    })
}

//Post franchise gps track Details
export const gpsTrackSetUp = (uuid, params) => {

  return axiosInstance
    .patch(`/field-office-gps-setup/${uuid}/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Get franchise gps track Details
export const getGpsTrackSetUp = (uuid, params) => {
  return axiosInstance
    .get(`/field-office-gps-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Get franchise gps track Details
export const getGpsTrackSetUpForFO = (uuid, params) => {
  return axiosInstance
    .get(`/field-office-gps-setup/get_gps_for_fo/`,{field_office__uuid: uuid})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//Post franchise hubspot Details
export const hubspotSetUp = (params) => {

  return axiosInstance
    .post(`/field-office-hubspot-setup/save_hubspot/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
//Get franchise hubspot Details
export const getHubspotSetUp = (uuid) => {
  return axiosInstance
    .get(`/field-office-hubspot-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//get proposal notes details
export const getproposalsetup = (uuid) => {
  return axiosInstance
    .get(`/proposal-setup/${uuid}/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

//update or create email and service agreement notes
export const proposalpostapi = (params) => {

  return axiosInstance
    .post(`/proposal-setup/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
