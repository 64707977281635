import React, { useEffect, useState } from "react";
import { Select } from "antd";

const ChannelAttributionSelector = ({
  handleChange = () => {},
  allValue = true,
  defaultValue,
}) => {
  const [channelAttributionList, setChannelAttributionList] = useState([]);

  useEffect(() => {
    const getChannelAttributionList = async () => {
      const data = [
        "Offline Marketing",
        "Organic",
        "Email",
        "Branded Search",
        "Directory",
        "Display",
        "Map",
        "Remarketing",
        "Search",
        "Social"
      ];
      
      setChannelAttributionList(data ? data : []);
    };
    getChannelAttributionList();
  }, []);

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        placeholder={"Select an option"}
      >
        {allValue ? (
          <Select.Option key={"all"} value={"all"} label={"All"}>
            {"All"}
          </Select.Option>
        ) : (
          <></>
        )}
        {channelAttributionList.sort().map((option) => (
          <Select.Option key={option} value={option} label={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};
export default ChannelAttributionSelector;
