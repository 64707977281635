import React, { useState, useEffect } from "react";
import optimoroute from '../../Assets/images/optimoroute.png';
import { Select, Modal, message } from "antd";
import { isEmpty, map } from 'lodash';
import * as yup from 'yup';
import Loader from '../../Components/Loader';
import { optimorouteSetUp, getDataOptimorouteSetUp } from '../../Action/Franchise';

const SetupOptionsOptimoRoute = ({ franchise_data }) => {
    const [reconnect, setReconnect] = useState(false);
    const [state, setState] = useState(
        {
            errors: {},
            loader: false,
            optimoroute_api_key: ''
        }
    );
    const onTextChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const onValid = async () => {
        let { optimoroute_api_key } = state;
        let temp_url = await optimorouteSetUp(franchise_data?.uuid, {
            "optimoroute_api_key": optimoroute_api_key,
        });
        if (!isEmpty(temp_url.optimoroute_api_key)) {
            setReconnect(true);
            message.success('Optimoroute Connected Successfully');
            onFormat();
        }
    }
    const getData = async () => {
        let data = await getDataOptimorouteSetUp(franchise_data?.uuid);
        if (!isEmpty(data.optimoroute_api_key)) {
            setReconnect(true);;
        }
    }
    const onFormat = () => {
        setState({
            errors: {},
            loader: false,
            optimoroute_api_key: ''
        });
    }
    const onSetupClick = (e) => {
        e.preventDefault();
        setTimeout(() => {
            let { optimoroute_api_key } = state;
            const schema = yup.object().shape({
                optimoroute_api_key: yup.string().required("OptimoRoute API Key is required"),
            })
            const data = { optimoroute_api_key}
            // check validity
            let IsValid;
            let err_data = {};
            schema.isValid(data)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    onValid();
                }
                else {
                    schema.validate(data, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            })
                            setState({
                                ...state,
                                errors: err_data
                            })
                            console.log("err_data:", err_data)
                        });
                }
            }, 100);
        }, 300);
    }
    useEffect(() => {
        getData();
    }, []);

    let { optimoroute_api_key, errors, loader } = state;

    return (
        <div className="">
            {franchise_data.is_optimoroute_enabled == true && <div className="card">
                <div className="card-body">
                    <img src={optimoroute} alt="" className="stripe_logo" />
                    <p>To enable the API and generate the API key please log into the OptimoRoute web application. In the Administration section select <b> Settings &gt; WS API</b> and enable the API. The API key will be generated for you.</p>
                    <div className="form-group">
                        {!reconnect ? <>
                            <div className="fitem">
                                <label style={{ fontWeight: 'bold' }}>
                                    OptimoRoute API Key  <sup>*</sup>
                                </label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="optimoroute_api_key"
                                    onChange={(e) => onTextChange(e)}
                                    value={optimoroute_api_key || ''}
                                />
                                {errors['optimoroute_api_key'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['optimoroute_api_key']}</label> : ''}
                            </div>
                            <br />
                            <div>
                                <button className='btn btn-primary' onClick={(e) => onSetupClick(e)}>Connect</button>
                            </div>
                        </>
                            :
                            <div>
                                <button className='btn btn-warning' >OptimoRoute Is Already Connected</button>
                                <button className='btn btn-primary' onClick={() => setReconnect(false)}>Reconnect</button>

                            </div>
                        }
                    </div>
                </div>
            </div>}
            {
                loader ?
                    <Loader />
                    : ''}
        </div >
    );
}

export default SetupOptionsOptimoRoute;