import React from "react";
import { Dropdown, Row, Col, Tooltip } from "antd";
import { Eventcalendar, localeEn } from "@mobiscroll/react";
import moment from "moment-timezone";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {toggleTaskModal, setSelectedTaskData, setTaskFlagFromEdit, setDisableTechnicianTask} from "../../../Store/technicianTaskDataSlice";
import { ReconciliationOutlined } from "@ant-design/icons";
import { EllipsisOutlined } from "@ant-design/icons";
import {editDuration, addDuration} from "../../../Store/serviceOrderDetailDataSlice";

export const DesktopEventCalendarContainer = (props) => {
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    const { onSelectedDateChange, customWithNavButtons, onEventClick, calendarColorsExample,
        role, userInfo, handleEditEvent, myEvents, status, renderLabelContent, viewData,
        renderAgenda, myInvalid, renderEvent, shouldOpenPopup, showPoolCharacteristicsModal, createItems,
        poolIcon, showWOModal, showRescheduleModal, showDeleteModal, openAccountOverview, currentDate, taskIcon,
        showActivityLogModal } = props;
        const dispatch = useDispatch();
    const tasks = useSelector((state) => state.technicianTaskJourney.ServiceTaskData);
    const renderOptList = (data) => {
        if (data?.original?.type == 'Task' && data?.original?.status == 'complete') {
          return false;
        }
        return true;
    }

    const getMenuOptions = (data, mode = '') => {
        return <>
            <div className="custom-event-list">
                <div className={`duration-${data?.original?.duration ? data?.original?.duration : franchise_data?.data?.default_job_duration}`}>
                    <div
                        className="calendar-label"
                        style={{ backgroundColor: data?.original?.color, width: '100%' }}
                    >
                        <Row>
                            <Col
                                span={12}
                                className="subtitle time"
                            >
                                {`${moment(data?.original?.start).utc().format("LT")}`}-{`${moment(data?.original?.start).add(data?.original?.duration ? data?.original?.duration : franchise_data?.data?.default_job_duration, 'minutes').utc().format("LT")}`}
                            </Col>
                            {renderOptList(data) ?
                                <Col xs={24} className="dots-icon-alignment">
                                    <Row
                                        justify="end"
                                        gutter={[10, 5]}
                                        className={`calendar-label-icons ${mode === 'popover' ? "popover-dots-alignment" : ""}`}
                                    >
                                        <Col>
                                            <Tooltip title="Actions">
                                                <Dropdown
                                                    placement="bottomRight"
                                                    menu=
                                                    {{
                                                        items: createItems(data),
                                                        onClick: ((data, e) => {
                                                            shouldOpenPopup.current = false;
                                                            setTimeout(
                                                                () => (shouldOpenPopup.current = true),
                                                                200
                                                            );
                                                            switch (e.key) {
                                                                case "pool_characteristics":
                                                                    showPoolCharacteristicsModal(data);
                                                                    return;
                                                                case "job_form":
                                                                    showWOModal(data);
                                                                    return;
                                                                case "work_order":
                                                                    dispatch(addDuration(data?.original?.service_duration));
                                                                    dispatch(editDuration(true))
                                                                    window.open(
                                                                        data?.original?.edit_work_order_url,
                                                                        "_blank"
                                                                    );
                                                                    return;
                                                                case "reschedule":
                                                                    showRescheduleModal(data);
                                                                    return;
                                                                case "delete":
                                                                    showDeleteModal(data);
                                                                    return;
                                                                case "edit_task":
                                                                    dispatch(setSelectedTaskData(data?.original))
                                                                    dispatch(toggleTaskModal(true))
                                                                    dispatch(setTaskFlagFromEdit(true))
                                                                    return;
                                                                case "activity_log":
                                                                    showActivityLogModal(data);
                                                                    return;
                                                                default:
                                                                    return;
                                                            }
                                                        }).bind(this, data),
                                                    }}
                                                    trigger={mode === 'popover' ? ['click', 'hover'] : ['click']}>
                                                    <span
                                                        onClick={(e) => {
                                                            shouldOpenPopup.current = false;
                                                            setTimeout(
                                                                () => (shouldOpenPopup.current = true),
                                                                200
                                                            );
                                                            e.preventDefault();
                                                        }}
                                                        style={{ fontSize: "27px", marginTop: -8, display: 'flex', fontWeight: 700 }}
                                                    >
                                                        <div>.</div>
                                                        <div style={{ paddingInline: 1 }}>.</div>
                                                        <div>.</div>
                                                    </span>
                                                </Dropdown>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Col> : null}
                        </Row>
                        <Row justify="space-between" gutter={[10]}>
                            <Col xs={24} xl={12} xxl={12} className="service-type">
                                <div className={`${data?.original?.type == "Task" ? 'task-tile-chip' : ''}`}>
                                    {data?.original?.type == "Task" ? <img src={taskIcon} alt="" /> : null}
                                    {data?.original?.serviceType?.toLowerCase() === "service" ?
                                        <span>{data?.original?.serviceType}_{data?.original?.workType.charAt(0).toUpperCase() + data?.original?.workType.slice(1)}</span>
                                        : <span>{data?.original?.serviceType}</span>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {data?.original?.type != "Task" ?
                                <Col className="subtitle time text-nowrap">
                                    <span>
                                        {data?.original?.client_name ? data?.original?.client_name : ""}
                                    </span>
                                </Col> : ""}
                        </Row>
                        <Row>
                            {data?.original?.type == "Task" ?
                                <Col
                                    span={18}
                                    className="subtitle"
                                    onClick={() => {
                                        shouldOpenPopup.current = false;
                                        setTimeout(() => (shouldOpenPopup.current = true), 200);
                                        if (role !== "lead_tech" && role !== "field_tech" && data?.original?.client_uuid) {
                                            openAccountOverview(data);
                                        }
                                    }}
                                >
                                    <span
                                        className={`${role !== "field_tech" && role !== "lead_tech"
                                            ? "link-title"
                                            : ""
                                            }`}>
                                        {` ${data?.original?.customer ? data?.original?.customer?.name : ""}`}
                                    </span>
                                </Col> : null}
                            <Col
                                span={18}
                                className="subtitle"
                                onClick={() => {
                                    shouldOpenPopup.current = false;
                                    setTimeout(() => (shouldOpenPopup.current = true), 200);
                                    if (role !== "lead_tech" && role !== "field_tech" && data?.original?.type != "Task") {
                                        openAccountOverview(data);
                                    }
                                }}
                            >
                                <span
                                    className={
                                        role !== "field_tech" && role !== "lead_tech" && data?.original?.type != "Task"
                                            ? `mbsc-font-icon mbsc-icon-link`
                                            : `mbsc-font-icon`
                                    }
                                />
                                <span
                                    className={`${role !== "field_tech" && role !== "lead_tech" && data?.original?.type != "Task"
                                        ? "link-title"
                                        : ""
                                        }`}
                                >
                                    {` ${data?.original?.title ? data?.original?.title : ""}`}
                                </span>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
    </>
    }
    return (
        <Eventcalendar
            class="main-calendar-desktop"
            onSelectedDateChange={onSelectedDateChange}
            selectedDate={currentDate}
            renderHeader={customWithNavButtons}
            onEventClick={onEventClick}
            locale={{
                ...localeEn,
                moreEventsText: "+ {count} more",
                dayNamesShort: [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ],
            }}
            renderDayContent={(day) => {
              const date = day.date;
              return (
                <>
                  <span>
                    [{tasks?.length
                      ? tasks?.reduce((prev, item) => {
                          if (
                            moment(item.start).utc().format("YYYY-MM-DD").toLowerCase() ===
                            moment(date).utc().format("YYYY-MM-DD").toLowerCase()
                          ) {
                            prev = prev + 1;
                          }
                          return prev;
                        }, 0)
                      : 0} jobs]
                  </span>
                </>
              );
            }}
            theme="ios"
            themeVariant="light"
            clickToCreate={false}
            dragToCreate={false}
            colors={calendarColorsExample}
            dragToMove={
                role !== "field_tech" && role !== "lead_tech"
                    ? true
                    : userInfo?.user?.technician_permissions?.reschedule_wo
            }
            dragToResize={false}
            onEventUpdate={handleEditEvent}
            eventDelete={false}
            data={tasks?.filter((item) => _.includes(status, item?.status))}
            renderLabelContent={renderLabelContent}
            view={viewData}
            dateFormat="MM/YYYY"
            renderAgenda={(day, args) => {
                return <div className="custom-event-list">
                    {args?.data?.map((subData) => {
                        return <div style={{ marginBottom: "2px" }} onClick={(evt) => onEventClick({ domEvent: evt, event: subData })}>
                                {getMenuOptions({ original: subData })}
                            </div>
                    })}
                </div>
            }}
            invalidateEvent={"start-end"}
            invalid={myInvalid}
            renderEvent={(data) => {
                return getMenuOptions(data, 'popover')
            }}
            renderLabel={(data) => {
                return getMenuOptions(data)
            }}
        />
    )
}