import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { map, isEmpty } from "lodash";
import * as yup from "yup";
import Script from "react-load-script";
import moment from "moment-timezone";
import { Select, Row, Col } from "antd";
import Axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import cross_icon from "../../Assets/images/cross_icon.svg";
import AllState from "../../Assets/data/stateList.json";
import Loader from "../../Components/Loader";
import { checkZipCode } from '../../Action/Franchise';
import serviceData from '../data.json';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { addZipCodeData } from '../../Store/zipCodeDataSlice';
import AddressField from "../../Components/AddressField";

const { Option } = Select
const REGULAR_EXPRESSION_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ServiceArea = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);
  const [hearAboutUsType, setHearAboutUsType] = useState('');
  const [zipCode, setZipCode] = useState("");
  const [zipCodeCheck, setZipCodeCheck] = useState(false);
  const [firstName, setFisrtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState({ address: '', state: '', city: '', zip_code: '', route: '', street_number: '' })
  const [isAddressManually, setIsAddressManually] = useState(false);
  const [phone, setPhone] = useState('');
  const [userName, setUserName] = useState('');
  const [authorizedUser, setAuthorizedUser] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('')
  const [autoComplete, setAutoComplete] = useState();
  const [isBasicDetail, setIsBasicDetail] = useState(false);
  const [phoneType, setPhoneType] = useState('mobile')
  const [canSendSms, setCanSendSms] = useState(false)
  const [errors, setErrors] = useState({})
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState({});
  const [standardRate, setStandardRate] = useState(false);
  const [outreach, setOutreach] = useState('Call Center')

  useEffect(() => {
    if (zipCode.length > 3) {
      props.setServiceInfoData({});
      const getZipCode = async () => {
        const data = await checkZipCode({ addressable_zip_codes__contains: zipCode, close: false });
        if (data?.count > 0) {
          setZipCodeCheck(true)
          dispatch(addZipCodeData(data?.results[0]))
          props.onSetData(data?.results[0])
          props.setServiceInfoData({ value: zipCode, key: true, name: 'zipCode', data: data?.results[0] });

        } else {
          setZipCodeCheck(false);
          props.setServiceInfoData({ value: zipCode, key: false, name: 'zipCode' });
        }
        getHearAboutUsOptions(data.results?.[0]?.service_matrix?.hear_about_us_choices);
        setLoader(false);
        setData(data);
      }
      getZipCode();
    }
  }, [zipCode])

  useImperativeHandle(ref, () => ({
    validate() {
      const schema = yup.object().shape({
        zipCode: yup.string().required('Zipcode is required'),
        zipCodeCheck: yup.bool(),
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        address: yup.object().shape({
          address: yup.string().required('address is required.'),
          city: yup.string().required('city is required.'),
          state: yup.string().required('state is required.'),
          zip_code: yup.string().required('zipcode is required.').test("match", "Enter proper zip code", val => val && val === zipCode),
        }),
        phoneNumbers: yup.array(yup.object({
          phone: yup.string().required('Phone is required')
        })).min(1).required("Phone number is required"),
        emails: yup.array(yup.object({
          email: yup.string().required('Email is required')
        })).min(1).required("Email Address is required"),
        hearAboutUsType: yup.string().required("This field is required"),
      });
      const data = {
        zipCode,
        zipCodeCheck,
        firstName,
        lastName,
        address,
        phoneNumbers,
        emails,
        authorizedUser,
        hearAboutUsType
      };
      return schema.validate(data, { abortEarly: false }).then(() => {
        setIsBasicDetail(true);
        props.setServiceInfoData({ value: data, key: true, name: 'serviceArea' })
        setErrors({})
        return Promise.resolve('success')
      }).catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => { err_data[item.path] = item.message })
        setIsBasicDetail(false);
        setErrors(err_data)
        return Promise.reject('fail')
      });
    }
  }));

  const handleZipCode = (e) => {
    if (e.target.value.length > 3) {
      setLoader(true);
    }
    props.setServiceInfoData({ data: {} });
    setZipCode(e.target.value)
  }
  const handleFirstName = (e) => {
    setFisrtName(e.target.value)
  }
  const handleLastName = (e) => {
    setLastName(e.target.value)
  }
  const getHearAboutUsOptions = (optionsArray) => {
    let options = []

    optionsArray?.forEach(element => {
      options.push({ value: element, label: element })
    });

    setHearAboutUsOptions(options)
  }

  const onAddressChanged = (e) => {
    setAddress(address => ({
      ...address,
      [e.target.name]: e.target.value,
    }))
    props.setServiceInfoData({ value: e.target.value, key: e.target.key, name: e.target.name });
  }
  const onNumericInputChange = (e) => {
    const reg = /^-?\d*(\.\d*)?$/;
    return ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-')
  }

  const onPhoneChange = (e, type) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    setCanSendSms(value);

    Object.assign(phoneNumbers[0], { can_send_sms: e.target.checked })
    if (e.target.name === 'type' && e.target.value === 'phone') {
      Object.assign(phoneNumbers[0], { 'can_send_sms': false })
    }
    setPhoneNumbers([...phoneNumbers]);
  }

  const onStandardRate = (e) => {
    setStandardRate(e.target.checked);
    props.setStandardRate(e.target.checked);
  }

  const onTextChange = (e) => {
    if (onNumericInputChange(e) && e.target.name === 'phone') {
      setPhone(e.target.value)
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    }
    if (e.target.name === 'username') {
      setUserName(e.target.value)
    }
    if (e.target.name === 'phoneType') {
      setPhoneType(e.target.value)
    }
  }

  const checkMail = (e) => {
    // API call to check Email
    return true
  }

  // on address change
  const onAddressChange = (e) => {
    Object.assign(address, { [e.target.name]: e.target.value })
    setAddress(address)
  };

  // on primary phone number changed
  const onPhonePrimaryChanged = (index) => {
    map(phoneNumbers, (obj) => obj.primary = false)
    phoneNumbers[index].primary = true
    setPhoneNumbers([...phoneNumbers])
  }

  const onPrimaryEmailChanged = (index) => {
    map(emails, (obj) => obj.primary = false)
    emails[index].primary = true
    setEmails([...emails])
  }

  const genarateGibberrishEmail = () => {
    let newEmail = [{ email: '', primary: true }]
    newEmail[0].email = this.create_UUID() + '@doody.com'
    setEmails(newEmail);
  }

  //on change
  const onChange = (row, name) => {
    let data = name === 'phone' ? phoneNumbers : name === 'email' ? emails : authorizedUser;
    // if(name === 'phone'){
    //   data['type'] = phoneType;
    // }
    data.splice(row, 1);
    if (name === 'phone' || name === 'email') {
      const idx = data?.findIndex(x => x.primary)
      if (idx <= -1 && !isEmpty(data)) {
        data[0].primary = true;
      }
    }
    name === 'phone' ? setPhoneNumbers([...data]) : name === 'email' ? setEmails([...data]) : setAuthorizedUser([...data])
    if (name === 'email') {
      if (data.length === 0) {
        genarateGibberrishEmail()
      }
    }
  };

  const onAdd = (type) => {
    if (type === 'phone') {
      let obj = {}
      if (phoneNumbers.length === 0) {
        obj = { 'phone': phone, 'primary': true, 'type': phoneType, 'can_send_sms': canSendSms }
      } else {
        obj = { 'phone': phone, 'primary': false, 'type': phoneType, 'can_send_sms': canSendSms }
      }
      setPhoneNumbers([...phoneNumbers, obj])
      setPhone('')
    }
    if (checkMail(email) && type === 'email') {
      let obj = {}
      if (emails.length === 0) {
        obj = { 'email': email, 'primary': true }
      } else {
        obj = { 'email': email, 'primary': false }
      }
      setEmails([...emails, obj])
      setEmail('')
    }
    if (type === 'username') {
      let obj = {}
      if (authorizedUser.length === 0) {
        obj = { 'username': userName, 'primary': true }
      } else {
        obj = { 'username': userName, 'primary': false }
      }
      setAuthorizedUser([...authorizedUser, obj])
      setUserName('')
    }
    setPhone('')

  }


  // Google Map Place API
  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: 'us' } };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const newAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById('google_address'),
      options,
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    newAutoComplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'name']);
    // Fire Event when a suggested name is selected
    newAutoComplete.addListener('place_changed', handlePlaceSelect);
    setAutoComplete(newAutoComplete)
  };

  // format phone number
  const formatPhoneNumber = (phone) => {
    if (!phone) return ''
    phone = phone?.replace(/\D/g, '');
    return '(' + phone?.substr(0, 3) + ') ' + phone?.substr(3, 3) + '-' + phone?.substr(6);
  }

  const removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null && v?.trim() !== "")));
  }

  const onHearAboutUsType = (value) => {
    setHearAboutUsType(value)
    props.setServiceInfoData({ value: value, key: value, name: 'hear_about_us' });
  }
  const onSetOutreach = (value) => {
    setOutreach(value)
    props.setServiceInfoData({ value: value, key: value, name: 'outreach' });
  }
  // on start place change
  const handlePlaceSelect = () => {
    const address = document?.getElementById('google_address')?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const addressObject = res?.data?.results[0];
      const address = addressObject.address_components;
      let obj = {};
      if (address) {
        map(addressObject?.address_components, (item) => {
          if (item?.types?.includes('administrative_area_level_2')) {
            obj = Object.assign(obj, { 'address': item.short_name })
          }
          if (item?.types?.includes('route')) {
            obj = Object.assign(obj, { 'route': item.long_name })
          }
          if (item?.types?.includes('street_number')) {
            obj = Object.assign(obj, { 'street_number': item.short_name })
          }
          if (item?.types?.includes('administrative_area_level_1')) {
            obj = Object.assign(obj, { 'state': item.short_name })
          }
          if (item?.types?.includes('locality')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (item?.types?.includes('postal_code')) {
            obj = Object.assign(obj, { 'zip_code': item.short_name })
          }
        });
        setAddress((obj?.street_number || obj?.route) ? { ...obj, address: `${obj?.street_number || ''} ${obj?.route || ''}` } : obj)
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  const handleAddressChange = (value) => {
    setAddress(value);
  }
  const renderOutReach = () => {
    return [
      <Option
        key="franchise_owner"
        value="Franchise"
      ></Option>,
      <Option
        key="call_center"
        value="Call Center"
      ></Option>,
    ];
  };

  return (
    <div className="aleft">
      {loader && <Loader />}
      <h4>Do we service this area?</h4>
      <p>If you are here, this is for a new customer calling right now. First of all, take their zipcode for their service address to make sure we service this area.</p>
      <div className="form-group">
        <div className="fitem">
          <label>Service Zipcode</label>
          <input type="text" placeholder="Zipcode" disabled={props.serviceInfo?.serviceArea?.value.zipCodeCheck} name="zipCode" max={5} maxLength={5} value={zipCode} onChange={handleZipCode} />
          {errors?.['zipCode'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['zipCode']}</label> : ''}
        </div>
        {zipCode ? zipCodeCheck === true ?
          <div className="greenbox mb-30">
            <b className="d-block">We cover this area.</b>
            <b className="d-block">{`This is under the ${props.data?.name} Franchise.`}</b>
          </div> :
          <div className="dangerbox mb-30">
            <b className="d-block">We don’t currently have any franchises covering this area. Save their email to notify them if we move into this area. </b>
          </div> : ''
        }
      </div>
      {zipCodeCheck && <div className="form-group">
        <h4>Contact Information</h4>
        <Row>
          <div className='half-fitem fitem'>
            <Col span={11.5}>
              {/* <div className="half-fitem fitem"> */}
              <label>First Name</label>
              <input type="text" placeholder="First Name" name="firstName" value={firstName} onChange={handleFirstName} />
              {errors['firstName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['firstName']}</label> : ''}
              {/* </div> */}
            </Col>
            <Col span={1}></Col>
            <Col span={11.5}>
              <label>Last Name</label>
              <input type="text" placeholder="Last Name" name="lastName" value={lastName} onChange={handleLastName} />
              {errors['lastName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['lastName']}</label> : ''}
            </Col>
          </div>
        </Row>
        <AddressField 
          handleAddressChange={handleAddressChange} 
          onAddressChanged={onAddressChanged} 
          onAddressChange={onAddressChange}
          label={'Service Address'}
          errors={errors} />
      </div>}
      {zipCodeCheck &&
        <div>
          <h5 className=" mt-30">Phone Numbers</h5>

          {
            map(phoneNumbers, (item, index) =>
              <div className="fitem-check" key={`phoneNumber${index}`}>
                <input type="checkbox" className="fitem-ck-input" id={`checkboxPhone${index}`} name={`checkboxPhone${index}`} checked={item?.primary} onChange={(e) => onPhonePrimaryChanged(index)} />
                <label htmlFor={`checkboxPhone${index}`} className="fitem-ck-txt black">{`${formatPhoneNumber(item.phone)} ${item.primary ? '(Primary)' : ''}`}</label>
                <a className="red float-right" onClick={(e) => onChange(index, 'phone')}><u>Remove</u></a>
              </div>)
          }
          <Row>
            <div className="fitem fitem-rinner mt-10 flex">
              <Col span={11}>
                <input name="phone" type="text" placeholder="Add New Phone" value={phone} maxLength={"10"} max={"10"} onChange={onTextChange}  onKeyPress={(e) => {
                                        const pattern = /[0-9]/;
                                        const inputChar = String.fromCharCode(e.charCode);
                                        if (!pattern.test(inputChar)) {
                                            // invalid character, prevent input
                                            e.preventDefault();
                                        }
                                    }}/>
              </Col>
              <Col span={1}></Col>
              <Col span={9}>
                <Select
                  onChange={(e) => onTextChange({ target: { name: 'phoneType', value: e } })}
                  style={{ marginRight: "10px" }}
                  name="phoneType"
                  value={phoneType}
                  placeholder="Type">
                  <Option value="home_phone">Home</Option>
                  <Option value="mobile">Mobile</Option>
                </Select>
              </Col>
              <Col span={3} style={{ position: 'static', textAlign: 'center' }}>
                <button style={{ right: 'auto' }} className={`btn fitem-ricon fitem-ricon-phone ${phone.length !== 10 ? 'disabled' : 'btn-warning'}`}
                  disabled={phone.length !== 10} onClick={(e) => onAdd('phone')} ><PlusOutlined /></button>
              </Col>
            </div>
          </Row>
          {phoneType === 'mobile' ? <div className="fitem-check">
            <input
              type="checkbox"
              name="canSendSms"
              className="fitem-ck-input"
              id={`canSendSms`}
              checked={canSendSms}
              onChange={(e) => onPhoneChange(e, 'bool')}
            />
            <label htmlFor={`canSendSms`} className="fitem-ck-txt">{`Send SMS updates about my service to this number.`}</label>
          </div> : null}
          {errors?.[`phoneNumbers`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`phoneNumbers`]}</label> : ''}
        </div>}
      <div>
        <h5 className="mt-30">Email Addresses</h5>
        <div className="inline-check-list gcheck">
          {
            map(emails, (item, index) => item?.email && !item.email.includes('doody.com') ? <div className="fitem-check" key={`email${index}`}>
              <input type="checkbox" className="fitem-ck-input" id={`checkboxEmail${index}`} name={`checkboxEmail${index}`} checked={item?.primary} onChange={(e) => onPrimaryEmailChanged(index)} />
              <label htmlFor={`checkboxEmail${index}`} className="fitem-ck-txt black">{`${item?.email} ${item?.primary ? '(Primary)' : ''}`}</label>
              <a className="red float-right" onClick={(e) => onChange(index, 'email')}><u>Remove</u></a>
            </div> : ''
            )}
        </div>
        <div className="fitem fitem-rinner mt-10 flex">
          <input name="email" type="text" placeholder="Add New Email Address" value={email} onChange={onTextChange} />
          <button className={`btn fitem-ricon ${!REGULAR_EXPRESSION_EMAIL.test(email) ? 'disabled' : 'btn-warning'}`}
            disabled={!REGULAR_EXPRESSION_EMAIL.test(email)} onClick={(e) => onAdd('email')} ><PlusOutlined /></button>
        </div>
        {errors?.[`emails`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`emails`]}</label> : ''}
      </div>


      {/* {zipCodeCheck && <div>
        <h5 className=" mt-30">Authorized Users</h5>
        {map(authorizedUser, (item, index) => <div className="fitem-check flex" key={`user${index}`}>
          <b className="d-block">{item?.username}</b>
          <div className="flex">
            <span className="mr-2">{`Added ${moment(item?.created_at)?.format('MM/DD/YYYY')}`}</span>
            <a className="red" onClick={(e) => onChange(index, 'user')}><u>Remove</u></a>
          </div>
        </div>
        )}
        <div className="fitem fitem-rinner mt-10 flex">
          <input name="username" type="text" placeholder="Add New Authorized User" value={userName} onChange={onTextChange} />
          <button className={`btn fitem-ricon ${!userName ? 'disabled' : 'btn-warning'}`}
            disabled={!userName} onClick={(e) => onAdd('username')} ><PlusOutlined /></button>
        </div>

      </div>} */}
      {zipCodeCheck && <div>
        <h5 className=" mt-30">How did you hear about us?</h5>
        <Select
          showArrow={true}
          name="hearAboutUsType"
          placeholder="Select"
          virtual={false}
          options={hearAboutUsOptions}
          value={hearAboutUsType}
          onChange={(e) => onHearAboutUsType(e)}
        ></Select>
        {errors['hearAboutUsType'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['hearAboutUsType']}</label> : ''}
      </div>

      }
      {zipCodeCheck && <div>
        <h5 className=" mt-30">Outreach</h5>
        <Select
          showSearch
          showArrow={true}
          filterOption={false}
          notFoundContent={null}
          placeholder="Select"
          value={outreach}
          onChange={(e) => { onSetOutreach(e) }}
        >
            {renderOutReach()}
        </Select>
      </div>

      }
      {/* {zipCodeCheck && <div className='mt-20'>        
        <div className="fitem-check">
            <input
              type="checkbox"
              name="standardRate"
              className="fitem-ck-input"
              id={`standardRate`}
              checked={standardRate}
              onChange={(e) => onStandardRate(e)}
            />
            <label htmlFor={`standardRate`} className="fitem-ck-txt">{`Standard Rate`}</label>
          </div>
      </div>} */}
    </div>
  )
});

export default ServiceArea
