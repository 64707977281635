import React, { Component, useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { map, isEmpty } from "lodash";
import { Select, TimePicker, Upload, message, Checkbox, Divider } from 'antd';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { errorHandle } from "../Components/errorHandle";
import AddressField from "../Components/AddressField";
import { addUser } from '../Action/User';
import Script from 'react-load-script';

const { Option } = Select;
const AddUser = (props) => {
  const [state, setState] = useState({
    hours_of_work: [
      {
        key: 'Sunday',
        day: 'sun',
        start_time: '',
        end_time: '',
        enable: false
      },
      {
        key: 'Monday',
        day: 'mon',
        start_time: '',
        end_time: '',
        enable: false
      },
      {
        key: 'Tuesday',
        day: 'tues',
        start_time: '',
        end_time: '',
        enable: false
      },
      {
        key: 'Wednesday',
        day: 'wed',
        start_time: '',
        end_time: '',
        enable: false
      },
      {
        key: 'Thursday',
        day: 'thurs',
        start_time: '',
        end_time: '',
        enable: false
      },
      {
        key: 'Friday',
        day: 'fri',
        start_time: '',
        end_time: '',
        enable: false
      },
      {
        key: 'Saturday',
        day: 'sat',
        start_time: '',
        end_time: '',
        enable: false
      }
    ],
    group: { uuid: '', name: '' },
    user: {
      first_name: '',
      last_name: '',
      email: '',
      franchise_uuid: '',
      is_active: true,
      photo: '',
      description: ''
    },
    phone_numbers: {
      uuid: '',
      phone: '',
      type: 'mobile',
      can_send_sms: true,
      primary: true
    },
    is_add_user_Active: false,
    skillsetList: [
      // { key: 'maintenance', value: 'Maintenance' },
      // { key: 'repair_service', value: 'Repair Service' },
      // { key: 'renovation', value: 'Renovation' },
      // { key: 'construction', value: 'Construction' },
      // { key: 'estimator', value: 'Estimator' },
    ],
    skillset: [],
    permissionset: [],
    startAutocomplete: {},
    start_location: { address: '', city: '', state: '', zip_code: '' },
    stop_location: { address: '', city: '', state: '', zip_code: '' },
    errors: {}
  })
  const [startAutocomplete, setstartAutocomplete] = useState({});
  const [alsoTech, setAlsoTech] = useState(false);

  const start_location_ref = useRef();
  const stop_location_ref = useRef();

  const [start_locationText, setStart_locationText]=useState('')
  const [stop_locationText, setStop_locationText]=useState('')

  useEffect(() => {
    const { user } = state;
    const { franchise_uuid, is_disable_franchise, is_account_corporate } = getLoggedInUserRole();
    if (is_disable_franchise || is_account_corporate) Object.assign(user, { franchise_uuid })
    setState((state) => ({ ...state, user }))
  }, [])

  const removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== null && v?.trim() !== "")));
  }

  // get logged in user
  const getLoggedInUserRole = () => {
    let authUser = JSON.parse(localStorage.getItem('authUser'))
    const logged_in_user_role = authUser?.user?.user_roles?.map(x => x.name)

    const is_franchise_owner = logged_in_user_role?.includes('franchise_owner') || logged_in_user_role?.includes('operations_mgr')
    const is_account_corporate = logged_in_user_role?.includes('accounting_corporate')
    const is_business_dev_franchiese = logged_in_user_role?.includes('business_dev_franchiese')
    const is_operations_mgr = logged_in_user_role?.includes('operations_mgr')
    // const is_field_tech = logged_in_user_role?.includes('field_tech')
    const franchise_uuid = authUser?.user?.field_offices?.map(x => x.uuid);
    return { franchise_uuid: franchise_uuid?.[0], is_account_corporate, is_disable_franchise: (is_business_dev_franchiese || is_operations_mgr) }
  }

  //User detil setup
  const onUserDetailChange = (e) => {
    let { user } = state;
    user = Object.assign(user, { [e.target.name]: e.target.value });
    setState((state) => ({
      ...state,
      user
    }))
  }

  //Working hours change
  const onWorkingHoursChange = (e, hour, index) => {
    let { hours_of_work } = state;
    if (e.target.checked) {
      hours_of_work[index].enable = e.target.checked;
      hours_of_work[index].start_time = moment('09:00 AM', 'HH:mm A');
      hours_of_work[index].end_time = moment('05:00 PM', 'HH:mm A')
    }
    else {
      hours_of_work[index].enable = e.target.checked;
      hours_of_work[index].start_time = '';
      hours_of_work[index].end_time = ''
    }
    setState((state) => ({
      ...state,
      hours_of_work
    }))
  }

  // on Working Time Change
  const onTimeChange = (e, type, hour) => {
    let { hours_of_work } = state;

    let index = hours_of_work.findIndex(x => x.key === hour.key);
    hours_of_work[index][type] = e;
    setState((state) => ({
      ...state,
      hours_of_work
    }))
  }

  //User Form Validation
  const validateUser = () => {
    setTimeout(() => {
      let { hours_of_work, user, phone_numbers, group, skillset, start_location, stop_location } = state;
      const UserSchema = yup.object().shape({
        user: yup.object().shape({
          first_name: yup.string().required("First name is required"),
          last_name: yup.string().required("Last name is required"),
          email: yup.string().required("Email is required").email('Your Email is invalid.'),
          franchise_uuid: yup.string().required("Franchise is required"),
          role: yup.array().of(yup.string()).required("Role is required"),
        }),
        phone_numbers: yup.object().shape({
          phone: yup.string().trim().matches(/^[0-9]{0}(?:[0-9]{10})?$/, 'Please enter a valid Phone Number.')
        }),
        group: yup.object().shape({
          name: yup.string().required("Group name is required"),
        }),
        // hours_of_work: yup.array().of(
        //   yup.object().shape({
        //     start_time: yup.string().when(['enable'], {
        //       is: true,
        //       then: yup.string().required('Start time is required'),
        //     }),
        //     end_time: yup.string().when(['enable'], {
        //       is: true,
        //       then: yup.string().required('End time is required'),
        //     }),
        //   })
        // )
      })


      const UserData = {
        user, phone_numbers, group, skillset,
        start_location, stop_location
        //  hours_of_work
      }

      // check validity
      let IsValid;
      let err_data = {};
      UserSchema.isValid(UserData)
        .then(function (valid) {
          IsValid = valid
        });

      setTimeout(() => {
        if (IsValid) {

          let hours = [];
          if (user?.role?.includes('field_tech')) {
            map(hours_of_work, x => {
              if (x.enable) {
                if (x.start_time && x.end_time) hours.push(x)
              }
            })
          }

          if (user?.role?.includes('field_tech') && hours?.length === 0) {
            setState((state) => ({
              ...state,
              errors: { hours_of_work: 'Hours of work is required' }
            }))
          } else {
            setState((state) => ({ ...state, errors: {} }))
            postUser()
          }
        }
        else {
          UserSchema.validate(UserData, { abortEarly: false })
            .catch(function (err) {
              map(err.inner, (item) => {
                err_data[item.path] = item.message;
              })
              console.log("err_data::::>", err_data)
              setState((state) => ({
                ...state,
                errors: err_data,
              }))
            });
        }
      }, 100);
    }, 300);
  }

  const formatChangePermission = () => {
    let { permissionset } = state;
    if (!isEmpty(permissionset)) {
      let obj = {
        delete_so: permissionset.includes('delete_so'),
        assign_wo: permissionset.includes('assign_wo'),
        create_wo: permissionset.includes('create_wo'),
        reschedule_wo: permissionset.includes('reschedule_wo'),
        see_calenders: permissionset.includes('see_calenders'),
        enable_price_catalog: permissionset.includes('enable_price_catalog'),
      }
      return obj;
    }
  }

  // user photo selection
  const userPhotoUpload = (info) => {
    let { user } = state;
    const reader = new FileReader();
    reader.onloadend = function () {
      user = Object.assign(user, { 'photo': reader.result });
      setState((state) => ({ ...state, user }));
    }
    reader.readAsDataURL(info.file);
  }

  // convert images to base64
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  //Add New User
  const postUser = () => {
    let { user, hours_of_work, group, phone_numbers, skillset, permissionset, start_location, stop_location } = state;
    let temp_hours_of_work = []

    map(hours_of_work, (item, i) => {
      if (hours_of_work[i]['start_time'] && hours_of_work[i]['end_time']) {
        temp_hours_of_work.push({
          day: hours_of_work[i]['day'],
          start_time: moment(hours_of_work[i]['start_time']).format('HH:mm:ss'),
          end_time: moment(hours_of_work[i]['end_time']).format('HH:mm:ss'),
          enable: true
        })

      }
      else {
        temp_hours_of_work.push({
          day: hours_of_work[i]['day'],
          start_time: null,
          end_time: null,
          enable: false
        })
      }

    })

    let staff_profile = {
      skillset: skillset?.length > 0 ? skillset : null,
      permissionset: formatChangePermission() || null,
      start_location: (start_location?.street_number && start_location?.route) ? { ...start_location, address: `${start_location?.street_number} ${start_location?.route}` } : start_location,
      stop_location: (stop_location?.street_number && stop_location?.route) ? { ...stop_location, address: `${stop_location?.street_number} ${stop_location?.route}` } : stop_location
    }

    if (!staff_profile.start_location.zip_code) {
      staff_profile.start_location = {}
    }

    if (!staff_profile.stop_location.zip_code) {
      staff_profile.stop_location = {}
    }

    let temp_phone_numbers = [];
    if (phone_numbers?.phone !== '') {
      temp_phone_numbers.push(phone_numbers);
    }
    map(temp_phone_numbers, x => {
      if (!x.uuid) delete x?.uuid
    })
    
    user.email = user?.email?.toLowerCase();

    let data = {
      ...user,
      hours_of_work: temp_hours_of_work,
      phone_numbers: temp_phone_numbers,
      group,
      staff_profile
    }

    setState((state) => ({
      ...state,
      loader: true
    }))

    if (data?.franchise_uuid === '') {
      const { franchise_uuid, is_disable_franchise } = getLoggedInUserRole();
      if (is_disable_franchise) Object.assign(data, { franchise_uuid })
      else delete data.franchise_uuid
    }
    if (data?.role.includes('franchise_owner') || data?.role.includes('operations_mgr') && alsoTech) {
      data.role.push("field_tech");
    }
    const postAddUser = async () => {
      const postdata = await addUser(data);
      if (postdata?.uuid) {
        setState((state) => ({ ...state, loader: false }))
        props.userDrawerToggle(false);
        resetComponent();
        props.getUserList({ 'offset': 0, 'limit': 15 });
        message.success("User Added Successfully");
      }
      else {
        setState((state) => ({ ...state, loader: false }));
        message.error(`Enter a valid user information for ${Object.keys(postdata).join(", ")}`);
      }
    }
    postAddUser()
  }

  // reset Component
  const resetComponent = () => {
    setState((state) => ({
      ...state,
      hours_of_work: [
        {
          key: 'Sunday',
          day: 'sun',
          start_time: '',
          end_time: '',
          enable: false
        },
        {
          key: 'Monday',
          day: 'mon',
          start_time: '',
          end_time: '',
          enable: false
        },
        {
          key: 'Tuesday',
          day: 'tues',
          start_time: '',
          end_time: '',
          enable: false
        },
        {
          key: 'Wednesday',
          day: 'wed',
          start_time: '',
          end_time: '',
          enable: false
        },
        {
          key: 'Thursday',
          day: 'thurs',
          start_time: '',
          end_time: '',
          enable: false
        },
        {
          key: 'Friday',
          day: 'fri',
          start_time: '',
          end_time: '',
          enable: false
        },
        {
          key: 'Saturday',
          day: 'sat',
          start_time: '',
          end_time: '',
          enable: false
        }
      ],
      group: { uuid: '', name: '' },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        franchise_uuid: '',
        is_active: true,
        photo: '',
        description: ''
      },
      phone_numbers: {
        uuid: '',
        phone: '',
        type: 'mobile',
        can_send_sms: true,
        primary: true
      },
      errors: {}
    }))
  }

  // Render Skill Set
  const renderSkillSet = () => {
    let { skillset } = state;
    let { skillsetList } = props;
    return map(skillsetList, (item, i) => {
      return (
        <div className="fitem-check" key={i}>
          <input
            type="checkbox"
            className="fitem-ck-input"
            id={`add_skill${i}`}
            onChange={(e) => { onAddSkillSetChange(e, item); }}
            checked={skillset.includes(item.skill_name)}
            disabled={false}
            name="checkboxs" />
          <label htmlFor={`add_skill${i}`} className="fitem-ck-txt">{item.skill_display_name}</label>
        </div>
      )
    })
  }

  // Render Skill Set
  const renderPermissionSet = () => {
    let { permissionset } = state;
    let { permissionList } = props;
    return map(permissionList, (item, i) => {
      return (
        <div className="fitem-check" key={i}>
          <input
            type="checkbox"
            className="fitem-ck-input"
            id={`add_per${i}`}
            onChange={(e) => { onAddPermissionSetChange(e, item); }}
            checked={permissionset.includes(item.permission_name)}
            disabled={false}
            name="checkboxs" />
          <label htmlFor={`add_per${i}`} className="fitem-ck-txt">{item.permission_display_name}</label>
        </div>
      )
    })
  }

  //Set skill set value
  const onAddSkillSetChange = (e, item) => {
    let { skillset } = state;
    if (e.target.checked) {
      skillset.push(item.skill_name);
    }
    else {
      const index = skillset.indexOf(item.skill_name);
      if (index > -1) {
        skillset.splice(index, 1);
      }
    }
    setState((state) => ({ ...state, skillset }))
  }

  //Set skill set value
  const onAddPermissionSetChange = (e, item) => {
    let { permissionset } = state;
    console.log('permissionset-->> ', permissionset);
    console.log('item-->> ', item);
    if (e.target.checked) {
      permissionset.push(item.permission_name);
    }
    else {
      const index = permissionset.indexOf(item.permission_name);
      if (index > -1) {
        permissionset.splice(index, 1);
      }
    }
    setState((state) => ({ ...state, permissionset }))
  }

  // Google Map Place API
  const handleScriptLoad = () => {
    setState((state) => ({
      ...state,
      loader: true,
    }));
    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: "us" } };
    //////////////////////Start Location////////////////////////
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const startLocationAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_addstart_address"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    startLocationAutoComplete.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    startLocationAutoComplete.addListener("place_changed", startLocationChange);

    setState((state) => ({
      ...state,
      startAutocomplete: startLocationAutoComplete,
    }));
    //////////////////////Stop Location////////////////////////
    const stopLocationAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_addstop_address"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    stopLocationAutoComplete.setFields([
      "address_components",
      "formatted_address",
    ]);
    // Fire Event when a suggested name is selected
    stopLocationAutoComplete.addListener("place_changed", stopLocationChange);
    setState((state) => ({
      ...state,
      stopAutocomplete: stopLocationAutoComplete,
    }));
    setState((state) => ({
      ...state,
      loader: false,
    }));

    /////FUNCTIONS FOR FO
  //////////////////////Start Location////////////////////////
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const startLocationAutoCompleteFO = new google.maps.places.Autocomplete(
      document.getElementById("google_start_addressFO"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    startLocationAutoCompleteFO.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    startLocationAutoCompleteFO.addListener("place_changed", startLocationChangeFO);

    setState((state) => ({
      ...state,
      startAutocompleteFO: startLocationAutoCompleteFO,
    }));
    //////////////////////Stop Location////////////////////////
    const stopLocationAutoCompleteFO = new google.maps.places.Autocomplete(
      document.getElementById("google_stop_addressFO"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    stopLocationAutoCompleteFO.setFields([
      "address_components",
      "formatted_address",
    ]);
    // Fire Event when a suggested name is selected
    stopLocationAutoCompleteFO.addListener("place_changed", stopLocationChangeFO);
    setState((state) => ({
      ...state,
      stopAutocomplete: stopLocationAutoCompleteFO,
    }));
    setState((state) => ({
      ...state,
      loader: false,
    }));

  };


  //Start Address change
  const startLocationChange = () => {
    const address = document?.getElementById('google_addstart_address')?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const addressObject = res?.data?.results[0];
      const address = addressObject.address_components;
      let obj = {};
      if (address) {
        map(addressObject?.address_components, (item) => {
          if (item?.types?.includes('administrative_area_level_2')) {
            obj = Object.assign(obj, { 'address': item.short_name })
          }
          if (item?.types?.includes('route')) {
            obj = Object.assign(obj, { 'route': item.long_name })
          }
          if (item?.types?.includes('street_number')) {
            obj = Object.assign(obj, { 'street_number': item.short_name })
          }
          if (item?.types?.includes('administrative_area_level_1')) {
            obj = Object.assign(obj, { 'state': item.short_name })
          }
          if (item?.types?.includes('locality')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (!obj.city && item?.types?.includes('neighborhood')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (item?.types?.includes('postal_code')) {
            obj = Object.assign(obj, { 'zip_code': item.short_name })
          }
        });
        let resAdd = {};
        resAdd['latitude'] = addressObject?.geometry?.location?.lat
        resAdd['longitude'] = addressObject?.geometry?.location.lng
        obj.geo_location = resAdd
        setState((state) => ({ ...state, start_location: obj }))
        if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
          message.error('Please enter valid start location');
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  };

  //Stop Address change
  const stopLocationChange = () => {
    const address = document?.getElementById('google_addstop_address')?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const addressObject = res?.data?.results[0];
      const address = addressObject.address_components;
      let obj = {};
      if (address) {
        map(addressObject?.address_components, (item) => {
          if (item?.types?.includes('administrative_area_level_2')) {
            obj = Object.assign(obj, { 'address': item.short_name })
          }
          if (item?.types?.includes('route')) {
            obj = Object.assign(obj, { 'route': item.long_name })
          }
          if (item?.types?.includes('street_number')) {
            obj = Object.assign(obj, { 'street_number': item.short_name })
          }
          if (item?.types?.includes('administrative_area_level_1')) {
            obj = Object.assign(obj, { 'state': item.short_name })
          }
          if (item?.types?.includes('locality')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (!obj.city && item?.types?.includes('neighborhood')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (item?.types?.includes('postal_code')) {
            obj = Object.assign(obj, { 'zip_code': item.short_name })
          }
        });
        let resAdd = {};
        resAdd['latitude'] = addressObject?.geometry?.location?.lat
        resAdd['longitude'] = addressObject?.geometry?.location.lng
        obj.geo_location = resAdd
        setState((state) => ({ ...state, stop_location: obj }))
        if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
          message.error('Please enter valid start location');
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const onAddressChange = (e, addr_ref) => {
    const lState = {...state};
    lState.start_location = {...lState.start_location, [e.target.name]: e.target.value };
    setState(lState);
  };

  const onStopAddressChange = (e, addr_ref) => {
    const lState = {...state};
    lState.stop_location = {...lState.stop_location, [e.target.name]: e.target.value };
    setState(lState);
  };
  

  const handleAddress = (e) => {
    setState({...state, start_location: e.target.value})
  }
  
  // render specific setting
  const renderSpecificSetting = () => {
    const { errors } = state;
    return (
      <div className="specific-fitem">
        <h6>Field Tech Specific Settings</h6>
        <div className="fitem">
          <label htmlFor="">Start Location</label>
          <AddressField nolabel handleAddressChange={(add) => {
            setState({...state, start_location: add })
          }} onAddressChanged={onAddressChange} onAddressChange={onAddressChange} onChange={(e) => handleAddress(e)} errors={errors} data={state.start_location} />
        </div>

        <div className="fitem">
          <label htmlFor="">Stop Location</label>
          <AddressField
            fieldName="stop_location" 
            nolabel 
            handleAddressChange={(add) => {
              setState({...state, stop_location: add })
            }} 
            onAddressChanged={onStopAddressChange} 
            onAddressChange={onStopAddressChange} 
            onChange={(e) => setState({...state, stop_location: e.target.value})} 
            errors={errors} data={state.stop_location} 
          />
        </div>

        <div className="fitem inline-check-list gcheck mt-20" style={{ display: 'flex' }}>
          {/* Render Skill */}
          <div>
            <h5>Skills</h5>
            {renderSkillSet()}
          </div>
          <Divider type="vertical" style={{ height: '15em', borderLeft: '2px solid rgba(0, 0, 0, 0.06)' }} />
          <div>
            <h5>Permissions</h5>
            {renderPermissionSet()}
          </div>
          {errors['skillset'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['skillset']}</label> : ''}
        </div>
      </div>
    )
  };

  //GOOGLE FUNCTIONS FO
   //Start Address change
   const startLocationChangeFO = () => {
    const address = document?.getElementById('google_start_addressFO')?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const addressObject = res?.data?.results[0];
      const address = addressObject.address_components;
      let obj = {};
      if (address) {
        map(addressObject?.address_components, (item) => {
          if (item?.types?.includes('administrative_area_level_2')) {
            obj = Object.assign(obj, { 'address': item.short_name })
          }
          if (item?.types?.includes('route')) {
            obj = Object.assign(obj, { 'route': item.long_name })
          }
          if (item?.types?.includes('street_number')) {
            obj = Object.assign(obj, { 'street_number': item.short_name })
          }
          if (item?.types?.includes('administrative_area_level_1')) {
            obj = Object.assign(obj, { 'state': item.short_name })
          }
          if (item?.types?.includes('locality')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (!obj.city && item?.types?.includes('neighborhood')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (item?.types?.includes('postal_code')) {
            obj = Object.assign(obj, { 'zip_code': item.short_name })
          }
        });
        let resAdd = {};
        resAdd['latitude'] = addressObject?.geometry?.location?.lat
        resAdd['longitude'] = addressObject?.geometry?.location.lng
        obj.geo_location = resAdd
        setState((state) => ({ ...state, start_location: obj }))
        if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
          message.error('Please enter valid start location');
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  };

  //Stop Address change
  const stopLocationChangeFO = () => {
    const address = document?.getElementById('google_stop_addressFO')?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      })
    }).then((res) => {
      const addressObject = res?.data?.results[0];
      const address = addressObject.address_components;
      let obj = {};
      if (address) {
        map(addressObject?.address_components, (item) => {
          if (item?.types?.includes('administrative_area_level_2')) {
            obj = Object.assign(obj, { 'address': item.short_name })
          }
          if (item?.types?.includes('route')) {
            obj = Object.assign(obj, { 'route': item.long_name })
          }
          if (item?.types?.includes('street_number')) {
            obj = Object.assign(obj, { 'street_number': item.short_name })
          }
          if (item?.types?.includes('administrative_area_level_1')) {
            obj = Object.assign(obj, { 'state': item.short_name })
          }
          if (item?.types?.includes('locality')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (!obj.city && item?.types?.includes('neighborhood')) {
            obj = Object.assign(obj, { 'city': item.long_name })
          }
          if (item?.types?.includes('postal_code')) {
            obj = Object.assign(obj, { 'zip_code': item.short_name })
          }
        });
        let resAdd = {};
        resAdd['latitude'] = addressObject?.geometry?.location?.lat
        resAdd['longitude'] = addressObject?.geometry?.location.lng
        obj.geo_location = resAdd
        setState((state) => ({ ...state, stop_location: obj }))
        if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
          message.error('Please enter valid start location');
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }



  // render specific setting FO
  const renderSpecificSettingFO = () => {
    const { errors } = state;
    return (
      <div className="specific-fitem" style={{ marginTop: '10px' }}>
        <h6>Field Tech Specific Settings</h6>
        <div className='fitem'>
          <label htmlFor="">Start Location</label>
          <AddressField 
            nolabel 
            handleAddressChange={(add) => {
                setState({...state, start_location: add })
            }} 
            onAddressChanged={onAddressChange} 
            onAddressChange={onAddressChange} 
            onChange={(e) => handleAddress(e)} 
            errors={errors} 
            data={state.start_location} />

        </div>

        <div className='fitem'>
          <label htmlFor=''>Stop Location</label>
          <AddressField 
            fieldName="stop_location"
            nolabel 
            handleAddressChange={(add) => {
              setState({...state, stop_location: add })
            }} 
            onAddressChanged={onStopAddressChange} 
            onAddressChange={onStopAddressChange} 
            onChange={(e) => setState({...state, stop_location: e.target.value})} 
            errors={errors} data={state.stop_location} 
          />
        </div>
        <div className="fitem inline-check-list gcheck mt-10">
          <h5>Skills</h5>
          {renderSkillSet()}
          {errors['skillset'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['skillset']}</label> : ''}
        </div>
      </div>
    )
  };

  const onChangeAlsoTech = (e) => {
    setAlsoTech(e.target.checked);
  }

  let { loader, user, phone_numbers, errors, hours_of_work, group } = state;
  let { franchiseList, groupList, roleList } = props;
  const { is_disable_franchise } = getLoggedInUserRole()
  const grp = groupList?.filter((item) => item.uuid === group?.uuid);
  return (
    <React.Fragment>
      <div className="add_user_wrapper rdrawer">
        <div className="card-row">
          <div className="left-sec card-half">
            <h4>Add User</h4>
            <div className="form-group">
              <div className="fitem">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={user.first_name}
                  onChange={(e) => { onUserDetailChange(e) }} />
                {errors['user.first_name'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['user.first_name']}</label> : ''}
              </div>

              <div className="fitem">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={user.last_name}
                  onChange={(e) => { onUserDetailChange(e) }} />
                {errors['user.last_name'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['user.last_name']}</label> : ''}
              </div>

              <div className="fitem">
                <label htmlFor="">Phone number</label>
                <div className={phone_numbers.phone ? 'fix_isd_code' : ''}>
                  {phone_numbers.phone ? <span></span> : ''}
                  <input
                    style={{ marginLeft: phone_numbers?.phone ? "10px" : "0px" }}
                    type="text"
                    placeholder="Phone number"
                    name="phone_numbers"
                    value={phone_numbers?.phone}
                    inputMode="numeric"
                    max='10'
                    maxLength='10'
                    onPaste={(event) => {
                      if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)
                      ) {
                        // invalid character, prevent input
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let { phone_numbers } = state;
                      phone_numbers = Object.assign(phone_numbers, { 'phone': e.target.value });
                      setState((state) => ({
                        ...state,
                        phone_numbers
                      }))
                    }} />
                  {errors['phone_numbers.phone'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['phone_numbers.phone']}</label> : ''}
                </div>


              </div>

              <div className="fitem">
                <label htmlFor="">Email Address</label>
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={user.email}
                  onChange={(e) => { onUserDetailChange(e) }} />
                {errors['user.email'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['user.email']}</label> : ''}
              </div>

              <div className="fitem">
                <label htmlFor="">Franchise</label>
                <Select
                  value={user.franchise_uuid || undefined}
                  style={{ 'width': '100%' }}
                  placeholder="Please Select"
                  onChange={(e) => {
                    let { user } = state;
                    user = Object.assign(user, { 'franchise_uuid': e });
                    // setState((state)=>({...state, user }, () => {
                    setState((state) => ({ ...state, user, loader: false, group: { 'uuid': '', 'name': '' } }))
                    if (user.role?.includes('service_rep') || !state.user?.franchise_uuid) {
                      props.getGroupListDataProps({ 'offset': 0, 'limit': 15, 'is_public': true });
                    } else {
                      props.getGroupListDataProps({ 'offset': 0, 'limit': 15, field_office__uuid: e || '' });
                    }
                    // }))
                  }}
                  disabled={is_disable_franchise ? true : false}
                >
                  {map(franchiseList, (item, i) => {
                    return (
                      <Option key={i} value={item.uuid}>{item.name}</Option>
                    )
                  })}

                </Select>
                {errors['user.franchise_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['user.franchise_uuid']}</label> : ''}
              </div>

              <div className="fitem">
                <label htmlFor="">Group</label>
                <Select

                  style={{ 'width': '100%' }}
                  placeholder="Please Select"
                  value={group.name || undefined}
                  // eslint-disable-next-line
                  onChange={({ }, e) => {
                    setState((state) => ({
                      ...state,
                      group: { 'uuid': e.value, 'name': e.children }
                    }))
                  }}
                >
                  {groupList.filter(item => item.status === 'active').map((item, i) => {
                    return (
                      <Option key={i} value={item.uuid} >{item.name}</Option>
                    )
                  })}
                </Select>
                {errors['group.name'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['group.name']}</label> : ''}
              </div>

              <div className="fitem">
                <label htmlFor="">Roles</label>
                <Select
                  value={user.role || undefined}
                  style={{ 'width': '100%' }}
                  placeholder="Roles"
                  onChange={(e) => {
                    let { user } = state;
                    let role = [];
                    role.push(e)
                    user = Object.assign(user, { 'role': role });
                    setState((state) => ({ ...state, user }));
                    if (!user.role?.includes('franchise_owner') && !user.role?.includes('operations_mgr')) {
                      setAlsoTech(false);
                    }
                    if (user.role?.includes('service_rep') || !state.user?.franchise_uuid) {
                      props.getGroupListDataProps({ 'offset': 0, 'limit': 15, 'is_public': true });
                      setState((state) => ({
                        ...state,
                        group: {}
                      }))
                    } else {
                      props.getGroupListDataProps({ 'offset': 0, 'limit': 15, field_office__uuid: state.user?.franchise_uuid || '' });
                    }
                  }}
                >
                  {map(roleList, (item, i) => {
                    return (
                      <Option key={i} value={item.name}>{item.display_name}</Option>
                    )
                  })}
                </Select>
                {errors['user.role'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['user.role']}</label> : ''}
              </div>
              {(grp?.[0]?.name?.toLowerCase()?.includes('field') || user?.role?.[0].includes("field")) && renderSpecificSetting()}
              {(grp?.[0]?.name?.toLowerCase()?.includes("franchise_owner") || user?.role?.[0].includes("franchise_owner")) ||
               (grp?.[0]?.name?.toLowerCase()?.includes("operations_mgr") || user?.role?.[0].includes("operations_mgr")) &&
                <Checkbox onChange={onChangeAlsoTech}>Also a Technician</Checkbox>
              }
              {alsoTech && renderSpecificSettingFO()}
            </div>
            <div className="hours-work-wrap mt-20">
              <h5>Hours of work</h5>
              {map(hours_of_work, (item, i) => {
                // console.log(`${errors}hours_of_work[${i}].end_time`,errors[`hours_of_work[0].end_time`])
                return (
                  <div className="fitem inline-check-list gcheck" key={i}>
                    <div className="fitem-check flex mb-10 ai-center">
                      <div className="hours-week">
                        <input type="checkbox"
                          className="fitem-ck-input"
                          id={`hours${i}`}
                          checked={hours_of_work[i].enable || false}
                          onChange={(e) => { onWorkingHoursChange(e, hours_of_work[i], i) }}

                        />
                        <label htmlFor={`hours${i}`} className="fitem-ck-txt mb-0">{item.key}</label>
                      </div>
                      <div className="flex">
                        <div className="fitem">
                          <TimePicker
                            suffixIcon={<i className="las la-angle-down"></i>}
                            format="hh:mm A"
                            minuteStep={15}
                            placeholder="From"
                            // ref={`start_time${i}`}
                            disabled={!hours_of_work[i]['enable']}
                            value={hours_of_work[i].start_time === "" ? null : hours_of_work[i].start_time}
                            allowClear={false}
                            onChange={(e) => { onTimeChange(e, 'start_time', hours_of_work[i]) }}
                          />
                        </div>
                        <div className="fitem">
                          <TimePicker
                            suffixIcon={<i className="las la-angle-down"></i>}
                            placeholder="To"
                            format="hh:mm A"
                            minuteStep={15}
                            allowClear={false}
                            disabled={!hours_of_work[i]['enable']}
                            value={hours_of_work[i].end_time === "" ? null : hours_of_work[i].end_time}
                            onChange={(e) => { onTimeChange(e, 'end_time', hours_of_work[i]) }}
                          />
                        </div>
                      </div>
                      {errors[`hours_of_work${[i]}.end_time`] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors[`hours_of_work${[i]}.end_time`]}</label> : ''}
                    </div>

                    <div>

                    </div>
                  </div>
                )
              })}
              {errors?.['hours_of_work'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['hours_of_work']}</label> : ''}
            </div>

            <div className="fitem">
              <label htmlFor="">Status</label>
              <Select
                value={user.is_active}
                defaultValue="true"
                style={{ 'width': '100%' }}
                placeholder="Status"
                onChange={(e) => {
                  let { user } = state;
                  user = Object.assign(user, { 'is_active': e });
                  setState((state) => ({ ...state, user }))
                }}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>InActive</Option>
              </Select>
            </div>
          </div>
          <div className="img_upload right-sec card-half">
            <h4>User’s Bio</h4>
            <blockquote className="horiz">
              <b>The customer will see this information about you!</b>
              <p className="m-0"> As a field tech, customers will see who has serviced their property; and as a customer care rep, when you reply to tickets, customers will know who they are talking to!</p>
            </blockquote>

            <div className="file_upload_section flex ai-center flex-start">
              {
                user.photo ?
                  <div className="uploded_img">
                    <img src={user.photo} alt="" className="user_img" />
                  </div>
                  :
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={(e) => { userPhotoUpload(e) }}>

                    <div className="upload_text">
                      <i className="las la-plus-circle"></i>
                      <p>Upload Photo Optional</p>
                    </div>
                  </Upload>

              }
              {user.photo ?
                <div className="action-btn">
                  <Upload
                    name="avatar"

                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={(e) => { userPhotoUpload(e) }}
                  >
                    <button className="btn btn-bdr btn-xs mr-2" type="button">Replace</button>
                  </Upload>

                  <button type="button"
                    className="btn btn-bdr btn-danger btn-xs"
                    onClick={() => {
                      let { user } = state
                      user = Object.assign(user, { 'photo': '' });
                      setState((state) => ({
                        ...state,
                        user,
                        is_new_img: true
                      }))
                    }}>Remove</button>
                </div> : ''}

            </div>

            <div className="fitem">
              <label>a little about me</label>
              <textarea
                value={user.description || ''}
                name="description"
                placeholder="Be personable! "
                rows="4"
                onChange={(e) => { onUserDetailChange(e) }}></textarea>
            </div>
          </div>
        </div>

        <div className="footer" style={{ 'width': '411px' }}>
          <button className="btn btn-primary" onClick={() => {
            props.userDrawerToggle(false)
            resetComponent()
          }}>Cancel</button>
          <button
            // className={!is_add_user_Active ? 'btn disabled' : 'btn btn-warning'}
            className="btn btn-warning"
            // disabled={!is_add_user_Active}
            onClick={() => { validateUser() }}
          >Add User</button>
        </div>
      </div>
      {
        loader ?
          <div className='loader_wrapper'>
            <div className='loader07' ></div>
          </div>
          : ''
      }
    </React.Fragment>
  );
}

export default AddUser;