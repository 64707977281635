import React, { useState } from "react";
import "../../Assets/css/calendar.css";
import HeaderMobile from "../../Components/HeaderMobile";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import { TechGlanceProvider } from "../Hooks/TechGlance.Hook";
import TechGlanceContainersV2 from "../Components/Containers/TechGlanceV2.Container";

const Tech = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const onMenuButtonClick = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <div id="wrapper">
      <div className="calendar-ca content-area">
        <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenuButtonClick}
        />
        <div className="container">
          <TechGlanceProvider>
            <TechGlanceContainersV2 />
          </TechGlanceProvider>
        </div>
      </div>
    </div>
  );
};

export default Tech;
