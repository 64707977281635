import React from "react";
import { map } from "lodash";
import {
  selectServiceOption,
  selectServiceSubOption,
} from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import "../../../Assets/css/proposalBase.css";

const ProposalServiceOption = (props) => {
  const { errors, editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state?.proposalDataSlice);
  return (
    <div>
      {proposals?.service_type_name === "service" && (
        <div className="frow">
          <h4>What service you are looking for?<sup>*</sup></h4>
          <div className="oplist">
            {map(
              proposals?.optional,
              (item, index) => (
                <div className="inline-checks" key={`service${index}`}>
                  <label
                    htmlFor={item?.uuid}
                    className={`${proposals.work_type === item?.uuid ? "active" : ""}`}
                  >
                    <input
                      type="radio"
                      id={item?.uuid}
                      name="service"
                      className=""
                      value={item?.uuid}
                      disabled={editMode}
                      onChange={(e) => dispatch(selectServiceOption(e.target.value))}
                    />
                    <span>{item?.name}</span>
                  </label>
                </div>
              )
            )}
          </div>
          {errors["work_type"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["work_type"]}
                  </label>
                ) : (
                  ""
                )}
        </div>
      )}
      {proposals?.service_type_name === "service" && proposals?.work_type ==="79684949-e6b4-3f6d-a716-122a64220873" &&
        <div className="frow">
          <h4>Diagnostic Categories<sup>*</sup></h4>
          <div className="oplist">
            {map(proposals.dignostic, (item, index) => (
              <div className="inline-checks" key={`service${index}`}>
                <label
                  htmlFor={item?.uuid}
                  className={`${proposals?.work_subtype=== item?.uuid ? "active" : ""}`}>
                  <input
                    type="radio"
                    id={item?.uuid}
                    title={item.additional_text}
                    name="worksubtype"
                    className=""
                    value={item?.uuid}
                    disabled={editMode}
                    onChange={(e) => dispatch(selectServiceSubOption(e.target.value))}
                  />
                  <span>{item?.name}</span>
                </label>
              </div>
            ))}
          </div>
          {errors["work_subtype"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["work_subtype"]}
                  </label>
                ) : (
                  ""
                )}
        </div>
      }
      {proposals?.service_type_name === "service" && proposals?.work_type ==="48920339-fb03-3675-be97-78b6bb7fa44e" &&
        <div className="frow">
          <h4>Pool inspection Categories<sup>*</sup></h4>
          <div className="oplist">
            {map(proposals?.sub_option, (item, index) => (
              <div className="inline-checks" key={`service${index}`}>
                <label
                  htmlFor={item?.uuid}
                  className={`${proposals?.work_subtype === item?.uuid ? "active" : ""}`}>
                  <input
                    type="radio"
                    id={item?.uuid}
                    title={item.additional_text}
                    name="worksubtype"
                    className=""
                    value={item?.uuid}
                    disabled={editMode}
                    onChange={(e) => dispatch(selectServiceSubOption(e.target.value))}
                  />
                  <span>{item?.name}</span>
                </label>
              </div>
            ))}
          </div>
          {errors["work_subtype"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["work_subtype"]}
                  </label>
                ) : (
                  ""
                )}
        </div>
      }
    </div>
  );
};

export default ProposalServiceOption;
