import React, { useEffect, useState } from "react";
import CustomerDetails from "./Common/CustomerDetails";
import FooterForm from "./Common/FooterForm";
import ImageUploader from "../Components/ImageUploader";
import { DownloadOutlined } from "@ant-design/icons";
import { upsertChemical } from "../Utils/infoRequests";
import { Row, Col, message, Card } from "antd";
import { saveMaintenencePoolReport, getSaveMaintenencePoolReport, postUploadImage, getUploadImageFilterClean, sendTechnicianWorkOrder } from "../Action/Customer/index";
import { useParams } from "react-router";
import config from '../Lib/Config';
import { _, map, isEqual, isEmpty } from 'lodash';
import { authLogout } from "../Action/Auth";
import { useNavigate } from "react-router-dom";
import { useActiveForm } from "./Hooks/Form.Hook";
import Loader from "../Components/Loader";
import moment from "moment-timezone";

const FilterCleanForm = () => {
  const params = useParams()
  const [client, setClient] = useState({});
  const [textButton, setTextButton] = useState("Save & Email");
  const [imageSources, setImageSources] = useState([]);
  const [imageSourcesTech, setImageSourcesTech] = useState([]);
  const [poolSize, setPoolSize] = useState(0);
  const [allData, setAllData] = useState({});
  const [loader, setLoader] = useState(false);
  const [base64Image, setBase64Image] = useState({});
  const [tempImage, setTempImage] = useState([]);
  const [tempImageTech, setTempImageTech] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const active = useActiveForm();
  const navigate = useNavigate();

  const getFormData = async () => {
    const data = await getSaveMaintenencePoolReport(params.formuuid);
    setClient({ client: data.client });
    setAllData(data)
    setPoolSize(data?.form_data?.customer_details?.pool_size)
    setTask(task => ({
      ...task,
      FilterMake: data?.form_data?.service_notes?.filter_make,
      FilterSize: data?.form_data?.service_notes?.filter_size,
      FilterType: data?.form_data?.service_notes?.filter_type,
      FilterPSIBefore: data?.form_data?.service_notes?.filter_psi_before,
      FilterPSIAfter: data?.form_data?.service_notes?.filter_psi_after,

      techNotes: data?.form_data?.tech_notes,
      comment: data?.form_data?.comment
    }))
  }

  useEffect(() => {
    getFormData();
    getImageUpload(params.formuuid);
    sendTechWO();
  }, []);

  useEffect(() => {
    let timer = null;
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    const clearLoginTimeout = () => {
      clearTimeout(timer);
    };

    const setLoginTimeout = () => {
      timer = setTimeout(() => {
        authLogout({ key: JSON.parse(localStorage.getItem("authToken")).key });
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/`);
      }, 1500000);
    };
    const resetTimeout = () => {
      clearLoginTimeout();
      setLoginTimeout();
    };

    for (var i in events) {
      if (
        _?.includes(
          ["admin", "franchise_owner", "operations_mgr"],
          JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles[0]
            ?.name
        )
      ) {
        window.addEventListener(events[i], resetTimeout);
      }
    }

    return () => {
      clearTimeout(timer);
      for (var i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, [active]);

  const sendTechWO = async ()  => {
    let url = window.location.href;
    let temp = url.substring(url.lastIndexOf("/") + 1, url.length);
    let formName = url.split("/")[3];
    const workOrderParam = {
      'form_uuid': temp,
      'form_name': formName
    }
    const resp = await sendTechnicianWorkOrder(workOrderParam);
  }

  const [task, setTask] = useState({
    FilterMake: '',
    FilterType: '',
    FilterSize: '',
    FilterPSIBefore: 0,
    FilterPSIAfter: 0,
    comment: '',
    techNotes: ''
  });

  useEffect(() => {
    getTask();
  }, []);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let tempBase64 = { ...base64Image };
      tempBase64[file.name] = reader.result;
      setBase64Image(tempBase64);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const getFilesCustomer = (list) => {
    list.forEach((each) => {
      if (Object.keys(each).includes("image_url") && !each.image) {
        getBase64(each.image_url);
      }
    });
    setImageSources(list);
  };

  const getFilesTechnical = (list) => {
    list.forEach((each) => {
      if (Object.keys(each).includes("image_url") && !each.image) {
        getBase64(each.image_url);
      }
    });
    setImageSourcesTech(list);
  };

  const getImageUpload = async (id) => {
    setIsLoading(true)
    let customerImage = [];
    let technicalImage = [];
    const data = await getUploadImageFilterClean(id);
    let response = true;
    if (!isEmpty(data)) {
      data.forEach((each) => {
        if(!each.image.includes('http'))
        {
          response = false;
        }
        if (each.image_type === 'Filter Clean Report Customer') {
          let tempObjCustomer = {
            uid: each.id,
            name: each.name,
            url: each.image,
          }
          customerImage.push(tempObjCustomer)
        }
        else if (each.image_type === 'Filter Clean Report Technical') {
          let tempObjTechnical = {
            uid: each.id,
            name: each.name,
            url: each.image,
          }
          technicalImage.push(tempObjTechnical)
        }
      })
    }

    setImageSources(customerImage);
    setImageSourcesTech(technicalImage);
    setIsLoading(false);
    return response;
  }

  const postImageUpload = async () => {
    setLoader(true)
    let payload = {};
    let uploadedImageListCustomer = [];
    let uploadedImageListTechnical = [];
    imageSources.forEach((each) => {
      if (Object.keys(each).includes('image_url')) {
        let tempObj = {};
        tempObj['id'] = 0
        tempObj['image'] = each.image ?? base64Image[each.image_url.name]
        tempObj['name'] = each.image_url.name
        tempObj['type'] = "filter clean customer"

        uploadedImageListCustomer.push(tempObj)
      } else {
        let tempObj = {};
        tempObj['id'] = each.uid
        tempObj['image'] = each.url
        tempObj['name'] = each.name
        tempObj['type'] = "filter clean customer"

        uploadedImageListCustomer.push(tempObj)
      }
    })
    imageSourcesTech.forEach((each) => {
      if (Object.keys(each).includes('image_url')) {
        let tempObjTech = {};
        tempObjTech['id'] = 0
        tempObjTech['image'] = each.image ?? base64Image[each.image_url.name]
        tempObjTech['name'] = each.image_url.name
        tempObjTech['type'] = "filter clean technical"

        uploadedImageListTechnical.push(tempObjTech)
      } else {
        let tempObjTech = {};
        tempObjTech['id'] = each.uid
        tempObjTech['image'] = each.url
        tempObjTech['name'] = each.name
        tempObjTech['type'] = "filter clean technical"

        uploadedImageListTechnical.push(tempObjTech)
      }
    })
    let resultImage = uploadedImageListCustomer.concat(uploadedImageListTechnical)
    payload["id"] = params.formuuid;
    payload["id_type"] = "filterclean";
    payload["images"] = resultImage;
    
    const data = await postUploadImage(payload);        
    if (data.message === "Formdata Images uploaded Successfully.") {
      setLoader(false);
      message.success("Image Upload Successfully!..");
      return getImageUpload(params.formuuid)
    }
  }

  function getTask() {
    setTask({
      taskId: 6,
    });
  }

  const handleFilterSize = (e) => {
    setTask(() => ({ ...task, FilterSize: e.target.value }));
  }

  const handleFilterType = (e) => {
    setTask(() => ({ ...task, FilterType: e.target.value }));
  }

  const handlePSIBefore = (e) => {
    setTask(() => ({ ...task, FilterPSIBefore: e.target.value }));
  }

  const handlePSIAfter = (e) => {
    setTask(() => ({ ...task, FilterPSIAfter: e.target.value }));
  }

  const handleFilterMake = (e) => {
    setTask(() => ({ ...task, FilterMake: e.target.value }));
  }

  const handlePoolSize = (value) => {
    setPoolSize(value)
  }

  async function updateChemicals(isComplete) {
    setLoader(true);
    if(imageSources.length <= 0){
      const data = await postImageUpload();
      updateForm(isComplete);
    }else{
      const data = await postImageUpload();
      if(data){
        updateForm(isComplete);
      }
    }
  }

  function updateForm(isComplete) {
    setLoader(true);
    let payload = {}
    let formData = {};
    formData.customer_details = {
      "customer_name": client.firstName,
      "email": client.email,
      "address": client.streetAddress,
      "phone": client.homePhone,
      "city_state_zip": client.state,
      "pool_size": poolSize
    }
    formData.service_notes = {
      "filter_make": task.FilterMake,
      "filter_size": task.FilterSize,
      "filter_type": task.FilterType,
      "filter_psi_before": task.FilterPSIBefore,
      "filter_psi_after": task.FilterPSIAfter,
    }
    formData.tech_notes = task.techNotes
    formData.comment = task.comment
    payload["form_data"] = formData    
    payload["has_attachments"] = imageSources.length > 0 ? true : false
    client.assignedTaskId = task.id;
    client.tenantId = task.tenantId;
    client.isComplete = isComplete;
    var date = moment().format("YYYY-MM-DD");
    var hour = moment().format("HH:mm");
    upsertChemical(client).then(() => {
      if (isComplete) setTextButton("Save & Resend");      
    });
    const postFormData = async () => {
      const data = await saveMaintenencePoolReport(payload, isComplete, params.formuuid, date, hour);
      if (data?.form_data?.uuid) {        
        setLoader(false);
        message.success("Form Data saved  Successfully!..");
        if (isComplete)
        if ( window !== window.parent ) {
          var event = new CustomEvent('handleCloseEvent', { detail: data })
          window.parent.document.dispatchEvent(event)
        }
        else close();
      }
    }
    postFormData()
  }

  const handleChangeChemicalTextArea = (e, type) => {
    if (type === 'comment') {
      setTask({ ...task, comment: e.target.value })
    } else if (type === 'techNotes') {
      setTask({ ...task, techNotes: e.target.value })
    }
  }

  return (
    <div id="wrapper" style={{ padding: '24px' }}>
      {loader && <Loader />}
      <Card className="middle-section" title="Filter Clean Form" headStyle={{ fontSize: '24px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }}>
        <div className="download-mpr">
          <a target="_blank" href={`${config.getHost()}form-data/as_pdf/?form_data_uuid=${params.formuuid}`}>
            <i
              className="fa fa-download"
              style={{ marginLeft: "10px" }}
            ></i>
            <DownloadOutlined />
            Download PDF
          </a>
        </div>
        <div className="mb-10" style={{ fontSize: "16px" }}>
          <p><strong>Job Date: {allData?.job_time?.split("T")[0]}</strong></p>
        </div>
        <CustomerDetails client={client} handlePoolSize={handlePoolSize} allData= {allData} poolSize={allData.pool_size} />
        <br />
        <Card className="middle-section" title="Services Notes" headStyle={{ fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }} bodyStyle={{ padding: '24px' }}>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FilterMake">
                Filter Make
              </label>
              <input
                id="task.FilterMake"
                type="text"
                name="input"
                autoComplete="off"
                value={task.FilterMake}
                onChange={handleFilterMake}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FilterSize">
                Filter Size
              </label>
              <input
                id="task.FilterSize"
                type="text"
                name="input"
                autoComplete="off"
                value={task.FilterSize}
                onChange={handleFilterSize}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FilterType">
                Filter Type
              </label>
              <Row justify="">
                <Col xs={12} sm={12} md={7} lg={7}>
                  <input
                    id="task.FilterType"
                    type="radio"
                    name="task.FilterType"
                    value="Cartridge"
                    checked={task?.FilterType === 'Cartridge'}
                    onChange={handleFilterType}
                  />
                  &nbsp; Cartridge
                </Col>
                <Col xs={12} sm={12} md={7} lg={7}>
                  <input
                    id="task.filterType"
                    type="radio"
                    name="task.FilterType"
                    value="D.E."
                    checked={task?.FilterType === 'D.E.'}
                    onChange={handleFilterType}
                  />
                  &nbsp; D.E.
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FilterPSIBefore">Filter PSI Before</label>
              <input
                id="task.FilterPSIBefore"
                type="number"
                name="input"
                autoComplete="off"
                value={task.FilterPSIBefore}
                onChange={handlePSIBefore}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FilterPSIBefore">Filter PSI After</label>
              <input
                id="task.FilterPSIAfter"
                type="number"
                name="input"
                autoComplete="off"
                value={task.FilterPSIAfter}
                onChange={handlePSIAfter}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>

            </Col>
          </Row>
        </Card>
        <div className="form-group mt-30">
          <div className="fitem">
            <label htmlFor="chemtaskical.Comments" style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Comments to Customer
            </label>
            <textarea
              id="chemtaskical.Comments"
              rows="5"
              cols="48"
              autoComplete="off"
              value={task.comment}
              onChange={(e) => handleChangeChemicalTextArea(e, 'comment')}
            />
          </div>
        </div>
        <Card className="middle-section" title="Pictures" headStyle={{ fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }} bodyStyle={{ padding: '24px' }}>
          <div className="fitem">
            <strong htmlFor="tenant.name" className="control-label">
              Upload your image:
            </strong>
            <div className="fitem">
              <span className="help-block">
                Acceptable file types are .png, .jpg, or .gif.{" "}
              </span>
            </div>
            <br />
            {!isLoading && (
              <ImageUploader
                text={"Upload image"}
                fileList={imageSources}
                getFiles={getFilesCustomer}
                accept={".png, .jpg, .gif"}
                params={{
                  level: "FilterClean",
                  task: task,
                  isTechImage: false,
                }}
              />
            )}
          </div>
        </Card>
        <br />
        <Card className="middle-section" title="Tech Notes" headStyle={{ fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }}>
          <div className="fitem">
            <div className="fitem">
              <textarea
                id="chemical.comment"
                rows="5"
                cols="48"
                autoComplete="off"
                ng-model="chemical.techNotes"
                value={task.techNotes}
                onChange={(e) => handleChangeChemicalTextArea(e, 'techNotes')}
              />
            </div>
            <strong htmlFor="tenant.name" className="control-label">
              Upload your image:
            </strong>
            <div className="fitem">
              <span className="help-block">
                Acceptable file types are .png, .jpg, or .gif.{" "}
              </span>
            </div>
            <br />
            {!isLoading && (
              <ImageUploader
                text={"Upload tech image"}
                fileList={imageSourcesTech}
                getFiles={getFilesTechnical}
                accept={".png, .jpg, .gif"}
                params={{
                  level: "FilterClean",
                  task: task,
                  isTechImage: true,
                }}
              />
            )}
          </div>
        </Card>
        <FooterForm
          textButton={textButton}
          handleClick={() => updateChemicals(false)}
          handleClickEmail={() => updateChemicals(true)}
        />
      </Card>
    </div >
  );
};
export default FilterCleanForm;