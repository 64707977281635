import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

// getDashboardSummary
export const getFieldTypes = async () => {
  try {
    const response = await axiosInstance.get(
      `/customer-territory-revenue-report/get_field_type/`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardSummary
export const getServices = async () => {
  try {
    const response = await axiosInstance.get(
      `/customer-territory-revenue-report/services/`
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardSummary
export const getTypeRevenue = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/customer-territory-revenue-report/get_type_revenue/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};

// getDashboardSummary
export const getTerritorySummaryReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/customer-territory-revenue-report/get_territory_summary/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};
// getDashboardSummary
export const getTerritoryTypeReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/customer-territory-revenue-report/get_territory_type_summary/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};
// getDashboardSummary
export const getTerritoryZipReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/customer-territory-revenue-report/get_zip_revenue/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardSummary
export const getInvoiceReconciliationReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/invoice/get_invoice_reconcilation_report`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardSummary
export const getChemicalReport = async (params) => {
  try {
    const response = await axiosInstance.get(`/chemical-report/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};
// getDashboardSummary
export const getChemicalReportSummary = async (params) => {
  try {
    const response = await axiosInstance.get(`/chemical-report/summary/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardSummary
export const getDownloadCustomerReport = async (params,exported_file_name) => {

  return axiosInstance
    .get(`/download-customer-report`, { params })
    .then((response) => {
      if (response.headers['content-type'] === 'application/json') {
        return response.data;
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${exported_file_name}.csv`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
      }
    });
};

// getDashboardSummary
export const getExpiringPaymentReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/customer-expiring-payment-report/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};

export const getWorkOrderReport = async (params) => {
  try {
    const response = await axiosInstance.get(`/service-ftech/`, { params });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};

// getDashboardSummary
export const getAgingSummaryReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/aging-summary-report/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw  error;
    }
  }
};

// getDashboardSummary
export const getChemicalPricingReport = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/chemical-report/pricing/`,
      { params }
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw  error;
    }
  }
};

export const getTechnicianNotesReport = async (params) => {
  try {
    const response = await axiosInstance.get(`/technician-notes-report/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};