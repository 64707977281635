import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import {
  updatePrice, updateFrequency
} from "../../../../Store/techJourneyDataSlice";
import CustomFrequency from '../../../../Customer/Common/CustomFrequency';

const CleaningFrequency = () => {
  const [frequency,setFrequency] = useState(null);
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);

  useEffect(()=>{
    if(frequency){
      if(techJourneyState.selectedServiceType.name.toLowerCase() !== 'service'){
        dispatch(updatePrice(frequency.target.price));
      }
      dispatch(updateFrequency({ ...frequency.target }));
  }}, [frequency])

  useEffect(() => {
    console.log(techJourneyState.isRecurring)
  }, [techJourneyState.isRecurring])

  return (
    <Row>
      <Col span={24}>
         <div>
          <h4>How often do you need your pool cleaned?</h4>          
          <CustomFrequency 
            onTextChange={setFrequency} 
            data={techJourneyState.frequency.value}
            is_recurring={techJourneyState.isRecurring}
            recurring_base_prices={techJourneyState?.serviceMatrix?.recurring_base_prices}/>
        </div>
        {/* {errors?.[`frequency.start`] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.[`frequency.start`]}</label> : ''} */}
        
      </Col>
    </Row>
  );
};

export default CleaningFrequency;
