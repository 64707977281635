import React from "react";
import { Col, Row } from "antd";
import asp_logo from "../../../Assets/images/asp-logo-white.svg";
import useResponsiveContainer from "../../../Calendar/Components/Containers/useResponsiveContainer";

const Header = () => {
  const [isMobile, isDesktopOrLaptop] = useResponsiveContainer();

  return (
    <header style={{ paddingRight: "0px" }}>
      <div className="flex payment_header">
        <Col sm={24} style={{ width: "100%" }}>
          <Row>
            {isMobile ? (
              <div class="payment-asp-logo-mobile">
                <img src={asp_logo} alt="" />
              </div>
            ) : (
              <div class="payment-asp-logo">
                <img src={asp_logo} alt="" />
              </div>
            )}
          </Row>
          <Row>
            <div className={`${isMobile ? "payment-header-bg-mobile" : "payment-header-bg"} `}></div>
          </Row>
        </Col>
      </div>
    </header>
  );
};

export default Header;
