import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";

const CalendarContext = createContext();

export function CalendarProvider({ children }) {
  const [franchise, setFranchise] = useState({
    value: null,
    label: null,
    address: {},
  });
  const [technician, setTechnician] = useState({
    value: null,
    label: null,
    address: {},
  });
  const [status, setStatus] = useState([
    "complete",
    "incomplete",
    "scheduled",
    "forecast",
    "cancelled",
  ]);
  const [offline, setOffline] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const buildCalendar = useCallback(async () => {
    let data = [];
    setLoading(true);
    // eslint-disable-next-line default-case
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    buildCalendar();
  }, [buildCalendar]);

  const value = useMemo(() => {
    return {
      loading,
      setLoading,
      franchise,
      setFranchise,
      technician,
      setTechnician,
      status,
      setStatus,
      offline,
      setOffline,
    };
  }, [
    loading,
    setLoading,
    franchise,
    setFranchise,
    technician,
    setTechnician,
    status,
    setStatus,
    offline,
    setOffline,
  ]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
}

export function useCalendar() {
  return useContext(CalendarContext);
}
