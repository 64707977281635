import React, { useEffect } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useCalendar } from "../../../Hooks/Calendar.Hook";
import { Col, Row, Select } from "antd";
import {addTaskTechnicianAction, addTaskTechnicianList} from "../../../../Store/technicianTaskDataSlice";
import { getFieldTechnician } from "../../../../Action/Franchise/index";
import { orderBy } from "lodash";
const { Option } = Select;

const AddTechnicianForTask = () => {

    const dispatch = useDispatch();
    const calendarData = useCalendar();
    const tasks = useSelector((state) => state.technicianTaskJourney);

    useEffect(() => {
        const getFieldTech = async () => {
            const data = await getFieldTechnician({
                field_office__uuid: tasks.franchiseData || calendarData?.franchise?.value,
            });
            dispatch(addTaskTechnicianList(data?.results))
        };
        if(tasks?.showTaskFromEdit === true){
            dispatch(addTaskTechnicianAction(tasks?.selectedTaskData?.technician_uuid))
        }
        
        getFieldTech();
    }, []);

    const updateTechnician = (technicianValue) => {
        let param = technicianValue ? technicianValue : "not assigned";
        dispatch(addTaskTechnicianAction(param))
    };
    return (
        <Col xl={11} xs={24}>
            <label>
                Assigned Technician
            </label>

            <Select
                showSearch
                allowClear={true}
                placeholder="Not assigned"
                optionFilterProp="children"
                defaultValue={tasks?.showTaskFromEdit === true ? tasks?.selectedTaskData?.technicianData?.name : "Not assigned"                    
                }
                onChange={(e) => {
                    updateTechnician(e);
                }}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={tasks.disableTechnicianTask}
            >
                {orderBy(tasks.technicians, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc")?.map((technician, index) => {
                    return (
                        <Option
                            key={index}
                            value={tasks.showTaskFromEdit === true ? technician.user.uuid : technician.uuid}
                        >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                    );
                })}
            </Select>
        </Col>
    );
};

export default AddTechnicianForTask;
