import React, { Component, useEffect, useState } from 'react';
import { Select, Drawer, DatePicker, Table, Menu, Dropdown, Modal, TimePicker, message, ConfigProvider,Popconfirm } from "antd";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import "moment/locale/en-gb";
import en_GB from "antd/lib/locale-provider/en_GB";
import { format, parseISO } from 'date-fns'
import { orderBy, isEmpty } from 'lodash';
import * as yup from "yup";
import { map } from 'lodash';
import _ from 'lodash';
import { CSVLink } from "react-csv";
import axios from 'axios';
import axiosInstance from '../../Lib/Axios';
import { CodeFilled, DownOutlined } from "@ant-design/icons";
import Header from '../../Components/Header'
import HeaderMobile from "../../Components/HeaderMobile";
import Sidebar from '../../Components/Sidebar'
import SidebarResponsive from "../../Components/SidebarResponsive";
import DataTable from "./Datatable";
import Loader from "../../Components/Loader";
import { EllipsisOutlined } from "@ant-design/icons";
import { FilterOutlined } from '@ant-design/icons';
import { errorHandle } from "../../Components/errorHandle"
import workOrderStatus from '../../Components/Common/workorderStatus.json';
import chevronDown from "../../Assets/images/chevron-down.png";
import { formatToCurrency } from '../../Components/Common/formatToCurrency';
import { getViewByWorkOrder, getCustomers, getLeads, getFieldOfficeStaff, bulkReassignTech,exportViewByWorkOrder } from '../../Action/BulkReassign';
import { getFranchiseSelected, setFranchiseSelected } from '../../Utils/selectedFranchise';
import { getCustomer, getCommercialCustomer } from '../../Action/Customer';
import { getFranchiseListAll } from "../../Action/Franchise";
const ViewByWorkOrder = (props) => {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const location = useLocation();
  var cond = "other";
  const workorder_status = [
    "tentative",
    "scheduled",
    "technician_assigned",
    "technician_en_route",
    "in_progress",
    "complete",
  ]
  const time_status = [
    "tentative",
    "scheduled",
    "technician_assigned",
    "technician_en_route",
    "in_progress",
    "complete",
    "incomplete"
  ]
  const DAY = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday'
  }

  const [myId, setMyId] = useState(JSON.parse(localStorage.getItem('authUser'))?.user?.uuid);
  const [customerList, setCustomerList] = useState({});
  const [franchiseList, setFranchiseList] = useState({});
  const [leadList, setLeadList] = useState({});
  const [customerListPagination, setCustomerListPagination] = useState({ showSizeChanger: false, pageSize: 15, ordering: "", current: 1 });
  const [activePagination, setActivePagination] = useState('15');
  const [leadListPagination, setLeadListPagination] = useState({ showSizeChanger: false, pageSize: 15, ordering: "", current: 1 });
  const [activeLeadPagination, setActiveLeadPagination] = useState('15');
  const [filterData, setFilterData] = useState({
    franchise: '',
    active: false,
    inactive: false,
    filterFranchise: '',
    joined: [],
    lastService: [],
    outstanding: 'all',
    satisfaction: ''
  });
  const [filterLeadData, setFilterLeadData] = useState({
    franchise: '',
    active: false,
    inactive: false,
    filterFranchise: '',
    lead_stage: 'all',
    outreach: 'all',
    first_contacted: [],
    last_contacted: []
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLeadDrawer, setOpenLeadDrawer] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [leadSearchValue, setLeadSearchValue] = useState('');
  const [showAddLeadDrawer, setShowAddLeadDrawer] = useState(false);
  const [errors, setErrors] = useState({});
  const [isCustomerFilter, setIsCustomerFilter] = useState(false);
  const [loggedInUserRole, setLoggedInUserRole] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);
  const [customerType, setCustomerType] = useState([
    {
      "id": 0,
      "label": "All",
      "value": "all",
    },
    {
      "id": 1,
      "label": "Residential",
      "value": "residential",
    },
    {
      "id": 2,
      "label": "Commercial",
      "value": "commercial",
    }]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(0);
  const [technicians, setTechnicians] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedWorkOrderStatus, setSelectedWorkOrderStatus] = useState('');
  const [selectedTechnician, setSelectedTechnician] = useState('');
  const [selectedFranchise, setSelectedFranchise] = useState('');
  const [selectedDates, setSelectedDate] = useState(moment());
  const [selectedTimes, setSelectedTime] = useState('');
  const [workOrderList, setWorkOrderList] = useState({});
  const [filterCustomerType, setFilterCustomerType] = useState([]);
  const [filterTechnician, setFilterTechnician] = useState([]);
  const [filterWorkOrderStatus, setFilterWorkOrderStatus] = useState([]);
  const [dateType, setDateType] = useState('');
  const [filterdate, setFilterdate] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [toggleStatusFilter, setToggleStatusFilter] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState('15');
  const [ordering, setOrdering] = useState('');
  const [headers, setHeaders] = useState([
    { label: "Work Order", key: "work_order" },
    { label: "Status", key: "wo_status" },
    { label: "Day", key: 'day' },
    { label: "Date", key: "dateCell" },
    { label: "Optimo Completed", key: "optimo_completed" },
    { label: "Optimo Sent", key: "optimo_sent" },
    { label: "Technician", key: "technician" },
    { label: "Customer Type", key: "customer_type" },
    { label: "Service Frequency", key: "service_frequency" },
    { label: "Customer", key: "customer" },
    { label: "Address", key: "address" },
    { label: "Zip Code", key: "zip_code" },
    { label: "City", key: "city" },
    { label: "Amount", key: "amount" },
  ]);
  const [data, setData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [readOnlySearch, setReadOnlySearch] = useState(false);
  const [condOne, setCondOne] = useState("no");
  const [filters, setfilters] = useState('');
  //  not defined
  const [toggleTechnicianFilter, setToggleTechnicianFilter] = useState(false);
  const [toggleCustomerTypeFilter, setToggleCustomerTypeFilter] = useState(false);
  const [time, setTime] = useState('');
  const [fieldStaff, setFieldStaff] = useState([]);
  const [isModalVisibleTech, setIsModalVisibleTech] = useState(false);
  const [condOneTech, setCondOneTech] = useState("no");
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [isUpdateButtonDisable, setIsUpdateButtonDisable] = useState(false);
  const [reassignFrom, setReassignFrom] = useState("");
  const [reassignTo, setReassignTo] = useState("");
  const [isCofirmationOpen, setIsCofirmationOpen] = useState(false);
  const [isCond1, setIsCond1] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportFilters, setExportFilters] = useState({});
  const onMenuButtonClick = () => {
    setOpenMenu(!openMenu);
  };


  const getFieldOfficeStaffApiCall = async (params) => {
    const response = await getFieldOfficeStaff(params);
    if (Object.keys(response)?.length > 0) {
      setTechnicians(orderBy(response?.results, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc"))
    }
  }

  const defaultState = () => {
    setReassignFrom("");
    setReassignTo("");
    setSelectedRowData([]);
    setSelectedRowKeys([]);
    setIsCond1(false);
    setIsCofirmationOpen(false);
    setIsUpdateButtonDisable(false);
    setIsModalVisibleTech(false);
  }

  const getViewByWorkOrderApiCall = async (params) => {
    setExportFilters(params);
    const response = await getViewByWorkOrder(params);
    if (Object.keys(response)?.length > 0) {
      setWorkOrderList(response);
      setLoader(false);
      setReadOnlySearch(false);
      let data = [];
      map(response?.results, record => {
        let woStatus = '';
        if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
          woStatus = "Cancelled Non Billable"
        } else if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
          woStatus = "Cancelled Billable"
        } else if (record?.status === 'cancelled' && record?.is_auto_invoice_genrated) {
          woStatus = "Cancelled Billed"
        } else {
          woStatus = record.status
        }
        let customerName = `${record?.customer?.first_name || 'NA'} ${record?.customer?.last_name || ''}`;
        data.push({ dateCell: moment(record?.date).format("MM-DD-YYYY"), work_order: record?.work_order_number || "Not Created", wo_status: woStatus, day: record?.scheduled_time ? DAY[moment(record?.scheduled_time).day()] : '', optimo_completed: record?.service_allocation.length === 0 ? "No" : record?.service_allocation?.[0]?.is_completed && record?.service_allocation?.[0]?.service_end_by_tech_ts ? moment(record?.service_allocation?.[0]?.service_end_by_tech_ts).format("MM-DD-YYYY") : record?.service_allocation?.[0]?.is_completed === false ? "No" : "No", optimo_sent: record?.service_allocation.length > 0 ? "Yes - " + moment(record?.service_allocation?.[0]?.created_at).format("MM-DD-YYYY") : "No", technician: record?.technician?.user?.first_name || 'NA', customer_type: record?.service_order?.client_type, service_frequency: record?.frequency, customer: customerName, address: record?.service_order?.service_address?.formatted_address, zip_code: record?.service_order?.service_address?.zip_code, city: record?.service_order?.service_address?.city, amount: "$" + parseFloat(record?.total_price).toFixed(2) });
      })
      setData(data)
    }
  }

  const getFranchiseApiCall = async () => {
    const response = await getFranchise();
    if (Object.keys(response)?.length > 0) {
      if (loggedInUserRole?.includes('franchise_owner') || loggedInUserRole?.includes('operations_mgr')) {
        const franchise = response?.results?.filter((franchise) => franchise.uuid === selectedFranchise);
        setFranchiseList(franchise);
      }
      else
        setFranchiseList(response?.results);
    }
  }

  const getCustomersApiCall = async (params) => {
    const response = await getCustomers(params);
    if (Object.keys(response)?.length > 0) {
      setCustomerList(response.results);
    }
  }

  const getLeadsApiCall = async (params) => {
    const response = await getLeads(params);
    if (Object.keys(response)?.length > 0) {
      setLeadList(response);
    }
  };

  const getFranchiseApi = async () => {
    setLoader(true);
    const response = await getFranchiseListAll();
    // console.log('getFranchiseApi response-->> ', response);
    // console.log('loggedInUserRole -->> ', loggedInUserRole);

    if (Object.keys(response)?.length > 0) {
      if (loggedInUserRole?.includes('franchise_owner') || loggedInUserRole?.includes('operations_mgr')) {
        // const franchise = response?.results?.filter((franchise) => franchise.uuid === selectedFranchise);
        setSelectedFranchise(getFranchiseSelected().uuid);
      }
      setFranchiseList(response);

    }
    setLoader(false);
  }

  useEffect(() => {
    setLoader(true);
    let authUser = JSON.parse(localStorage.getItem('authUser'));
    const loggedInUserRole = authUser?.user?.user_roles?.map(x => x.name);
    const selectedFranchise = loggedInUserRole?.includes('franchise_owner') || loggedInUserRole?.includes('operations_mgr') ? getFranchiseSelected().uuid : authUser?.user?.associated_field_offices[0]?.uuid;
    getFranchiseApi();
    const obj = {
      offset: offset,
      limit: limit,
      ordering: ordering,
      // service_order__field_office__uuid: selectedFranchise,
      service_order__client_type__in: filterCustomerType.join(","),
      status__in: filterWorkOrderStatus.join(","),
      technician__uuid__in: filterTechnician.join(","),
      scheduled_appointment__range: startDate !== null ? `${moment(startDate).format('YYYY-MM-DD 00:00:01')},${moment(endDate).format('YYYY-MM-DD 23:59:59')}` : null,
      q: searchValue
    };
    if (selectedFranchise !== 'all') {
      obj.service_order__field_office__uuid = selectedFranchise;
    }
    getViewByWorkOrderApiCall(obj);
    setLoggedInUserRole(loggedInUserRole, () => {
      callApi();
      closeDrawer();
    })
    setSelectedFranchise(selectedFranchise);
    const field_tech_obj = {};
    if (selectedFranchise !== 'all') {
      field_tech_obj.field_office__uuid = selectedFranchise;
    }
    // const field_tech_obj = {
    //   field_office__uuid: selectedFranchise === 'all' ? '' : selectedFranchise
    // }
    getFieldOfficeStaffApiCall(field_tech_obj)
  }, []);

  useEffect(() => {
    if (!isEmpty(reassignFrom)) {
      let temp = [...technicians];
      temp = temp.filter((each) => each.user.uuid !== 'not assigned');
      temp.push({
        user: {
          uuid: 'not assigned',
          first_name: 'Not',
          last_name: 'Assigned',
        }
      });
      setTechnicians(temp);
    } else {
      let temp = [...technicians];
      temp = temp.filter((each) => each.user.uuid !== 'not assigned');
      setTechnicians(temp);
    }
  }, [reassignFrom]);



  // get logged in user
  const getLoggedInUserRole = () => {

    let authUser = JSON.parse(localStorage.getItem('authUser'))
    const is_franchise_owner = loggedInUserRole?.includes('franchise_owner') || loggedInUserRole?.includes('operations_mgr');
    const is_account_franchise = loggedInUserRole?.includes('accounting_franchiese');
    const is_ope_mgr_franchise = loggedInUserRole?.includes('operations_mgr');
    const is_buss_dev_franchise = loggedInUserRole?.includes('business_dev_franchiese');
    const franchise_uuid = authUser?.user?.associated_field_offices?.map(x => x.uuid);
    return { is_franchise_owner, franchise_uuid: franchise_uuid?.[0], is_disable_franchise_dropdown: (is_franchise_owner || is_ope_mgr_franchise || is_account_franchise || is_buss_dev_franchise) }
  }

  // const onSearchValue = (e) => {
  //   setSearchValue(e.target.value);
  // }

  const onSearch = async (e) => {
    setLoader(true);
    setSearchValue(e.target.value);
    const obj = {
      offset: offset,
      limit: limit,
      ordering: ordering,
      service_order__field_office__uuid: selectedFranchise,
      service_order__client_type__in: filterCustomerType.join(","),
      status__in: filterWorkOrderStatus.join(","),
      technician__uuid__in: filterTechnician.join(","),
      scheduled_appointment__range: startDate !== null ? `${moment(startDate).format('YYYY-MM-DD 00:00:01')},${moment(endDate).format('YYYY-MM-DD 23:59:59')}` : null,
      q: e.target.value
    };
    getViewByWorkOrderApiCall(obj);
    // await getViewByWorkOrderApiCall(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate);
    setLoader(false);
  }

  // const onSearch = (e) => {
  //   setSearchValue(e.target.value, () => {
  //     setLoader(true);
  //     if (searchValue?.length === 1) {
  //       setReadOnlySearch(true)
  //     }
  //     if (searchValue?.length === 0 && selectedFranchise === '' && startDate === null && filterCustomerType?.length === 0 && filterTechnician?.length === 0 && filterWorkOrderStatus?.length === 0) {
  //       setTimeout(() => setWorkOrderList([]), setLoader(false), setReadOnlySearch(false), 1500)
  //     } else {
  //       setTimeout(() => onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate), 1000)
  //     }
  //   })
  // };

  // on switch tab
  const switchTab = (value) => {
    setCurrentTab(value, () => {
      callApi()
    })
  };

  const callApi = () => {
    const { is_franchise_owner, franchise_uuid } = getLoggedInUserRole()
    // setLoader(true);
    if (currentTab === 1) {
      // get list of customers
      setFilterData({
        active: false,
        inactive: false,
        franchise: '',
        joined: [],
        lastService: [],
        outstanding: [],
        satisfaction: ''
      },
        // () => getCustomersApiCall({limit: activePagination, offset: 0, q: searchValue, serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '', ordering: '-created_at', is_active: true })
        // ,() => this.props.getCustomers({limit: activePagination, offset: 0, q: searchValue, serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '', ordering: '-created_at', is_active: true })
      );
    } else if (currentTab === 0) {
      props.getLeads({ limit: activeLeadPagination, offset: 0, q: searchValue, serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '', is_active: true }); // get list of leads
    } else if (currentTab === 2) {
      setFilterData({
        active: false,
        inactive: false,
        franchise: '',
        joined: [],
        lastService: [],
        outstanding: [],
        satisfaction: ''
      },
        // () => getCustomersApiCall({limit: activePagination, offset: 0, q: searchValue, serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '', ordering: '-created_at', is_active: false })
        // , () => this.props.getCustomers({limit: activePagination, offset: 0, q: searchValue, serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '', ordering: '-created_at', is_active: false }))
      );
    }
  }

  // toggle add leader drawer
  const toggleAddLeadDrawer = (value) => {
    setShowAddLeadDrawer(value);
  };

  // add zero before number
  const leadingZeros = (value, size) => {
    let s = String(value);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  // on filter click
  const onFilterClick = () => {

    setLoader(true);
    setSearchValue('');
    const obj = {
      offset: 0,
      limit: activePagination,
      ordering: '-created_at',
      is_active: currentTab === 1,
    };
    if (filters?.franchise || (filterData?.franchise && filterData?.franchise !== 'all')) {
      setFilterData(filterData => ({
        ...filterData,
        filterFranchise: filters?.franchise?.toString() || filterData?.franchise
      }));
    }
    if (filterData?.joined?.length > 0) {
      setFilterData(filterData => ({
        ...filterData,
        created_at__lte: new Date(filterData?.joined?.[1]),
        created_at__gte: new Date(filterData?.joined?.[0])
      }));
    }
    if (filterData?.lastService?.length > 0) {
      setFilterData(filterData => ({
        ...filterData,
        last_serviced: `${moment(filterData?.lastService?.[0]).format('YYYY-MM-DD')},${moment(filterData?.lastService?.[1]).format('YYYY-MM-DD')}`
      }));
    }

    if (filterData?.satisfaction) {
      if (filterData?.satisfaction === '>=90' || filterData?.satisfaction === '>=75') {
        Object.assign(obj, { customer_satisfaction_score__gte: filterData?.satisfaction ? filterData?.satisfaction === '>=90' ? '90' : '75' : '' })
      }
      else {
        Object.assign(obj, { customer_satisfaction_score__lte: filterData?.satisfaction ? filterData?.satisfaction === '<75' ? '75' : '50' : '' })
      }
    }

    if ((filterData?.active && !filterData?.inactive) || (!filterData?.active && filterData?.inactive)) {
      Object.assign(obj, { is_active: filterData?.active === true ? true : filterData?.inactive === true && false });
    }

    closeDrawer();
    setLoader(false);
  };

  ////////////////////////////////////////// Customer /////////////////////////////
  // filter data change
  const onFilterChange = (e, type, name) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    setFilterData(filterData => ({
      ...filterData,
      [e.target.name]: value
    }));

    if (e.target.name === 'franchise') {
      onFilterClick()
      setFilterData(filterData => ({
        ...filterData,
        filterFranchise: value
      }));
    }
  };

  const onCustomerTypeChanged = (e, index) => {
    let arrStatus = [...filterCustomerType];
    if (e?.target?.checked) {
      if (index === 0) {
        customerType.forEach((element, index) => {
          element['is_change'] = true;
          if (index > 0 && arrStatus.indexOf(element.value) === -1) {
            arrStatus.push(element.value)
          }
        });
      } else {
        if (arrStatus.indexOf(e.target.id) === -1) {
          customerType[index]['is_change'] = true;
          arrStatus.push(e.target.id)
        }
      }
    } else {
      if (index === 0) {
        customerType.forEach(element => {
          element['is_change'] = false;
        });
        arrStatus = []
      } else {
        const ind = arrStatus.indexOf(e.target.id);
        if (ind > -1) {
          customerType[0]['is_change'] = false;
          customerType[index]['is_change'] = false;
          arrStatus.splice(ind, 1);
        }
      }
    }
    setFilterCustomerType(arrStatus, () => {
      // this.setState({ toggleCustomerTypeFilter: false })
      // this.onFilter(arrStatus.join(), "filterWorkOrderStatus");
    })
  }

  const onTechnicianChanged = (e, index) => {
    let arrStatus = [...filterTechnician];
    if (e?.target?.checked) {
      if (index === "aa") {
        technicians.forEach((element, index) => {
          element['is_change'] = true;
          if (index > 0 && arrStatus.indexOf(element.uuid) === -1) {
            arrStatus.push(element.uuid)
          }
        });
      } else {
        if (arrStatus.indexOf(e.target.id) === -1) {
          technicians[index]['is_change'] = true;
          arrStatus.push(e.target.id)
        }
      }
    } else {
      if (index === 'aa') {
        technicians.forEach(element => {
          element['is_change'] = false;
        });
        arrStatus = []
      } else {
        const ind = arrStatus.indexOf(e.target.id);
        if (ind > -1) {
          // technicians[0]['is_change'] = false;
          technicians[index]['is_change'] = false;
          arrStatus.splice(ind, 1);
        }
      }
    }
    setFilterTechnician(arrStatus, {
      // this.setState({ toggleTechnicianFilter : false })
      // this.onFilter(arrStatus.join(), "filterWorkOrderStatus");
    });
  }

  const onStatusChanged = (e, index) => {
    let arrStatus = [...filterWorkOrderStatus];
    if (e?.target?.checked) {
      if (index === 0) {
        workOrderStatus.WorkOrderStatus.forEach((element, index) => {
          element['is_change'] = true;
          if (index > 0 && arrStatus.indexOf(element.value) === -1) {
            arrStatus.push(element.value)
          }
        });
      } else {
        if (arrStatus.indexOf(e.target.id) === -1) {
          workOrderStatus.WorkOrderStatus[index]['is_change'] = true;
          arrStatus.push(e.target.id)
        }
      }
    } else {
      if (index === 0) {
        workOrderStatus.WorkOrderStatus.forEach(element => {
          element['is_change'] = false;
        });
        arrStatus = []
      } else {
        const ind = arrStatus.indexOf(e.target.id);
        if (ind > -1) {
          workOrderStatus.WorkOrderStatus[0]['is_change'] = false;
          workOrderStatus.WorkOrderStatus[index]['is_change'] = false;
          arrStatus.splice(ind, 1);
        }
      }
    }
    setFilterWorkOrderStatus(arrStatus, () => {
      // this.setState({ toggleStatusFilter: false })
      // this.onFilter(arrStatus.join(), "filterWorkOrderStatus");
    })
  }

  const closeStatusFilter = () => {
    setToggleStatusFilter(false);
  }
  const closeCustomerTypeFilter = () => {
    setToggleCustomerTypeFilter(false)
  }
  const closeTechnicianFilter = () => {
    setToggleTechnicianFilter(false);
  }

  const toggleStatus = () => {
    if (toggleStatusFilter) {
      setToggleStatusFilter(false);
    } else {
      setToggleStatusFilter(true);
      setToggleTechnicianFilter(false);
      setToggleCustomerTypeFilter(false)
    }
  }

  const toggleCustomerType = () => {
    if (toggleCustomerTypeFilter) {
      setToggleCustomerTypeFilter(false);
    } else {
      setToggleStatusFilter(false);
      setToggleTechnicianFilter(false);
      setToggleCustomerTypeFilter(true)
    }
  }

  const toggleTechnician = () => {
    if (toggleTechnicianFilter) {
      setToggleTechnicianFilter(false);
    } else {
      setToggleStatusFilter(false);
      setToggleTechnicianFilter(true);
      setToggleCustomerTypeFilter(false)
    }
  }

  const renderTechnicianFilters = () => {
    return (
      <div className="image-input work-order-status view-by-work-order-status">
        <div className="fitem">
          <input
            type="text"
            style={{ "background": "white", "padding": "5.5px 16px" }}
            id="txtStatus"
            value={filterTechnician?.length === 0 ? 'Select Technician' : `Technician: ${filterTechnician?.length} items selected`}
            onClick={() => { toggleTechnician() }}
          />
          <img
            style={{ "top": "0.5px" }}
            src={chevronDown}
            alt=""
            onClick={() => { toggleTechnician() }}
          />
        </div>
        {toggleTechnicianFilter && <div style={{ 'position': 'relative' }} className="field-dropdown right">
          <div className="field-dropdown-body">
            {
              map(technicians, (item, index) => {
                return (
                  <div key={index} className="fitem-check">
                    <input
                      id={item?.user?.uuid}
                      type="checkbox"
                      checked={item.is_change}
                      className="fitem-ck-input"
                      onChange={(e) => { onTechnicianChanged(e, index) }}
                    />
                    <label htmlFor={item?.user?.uuid} className="fitem-ck-txt">{`${item?.user?.first_name} ${item?.user?.last_name}`}</label>
                  </div>
                )
              })}
          </div>
        </div>}
      </div>)
  }

  const renderCustomerTypeFilters = () => {
    return (
      <div className="image-input work-order-status view-by-work-order-status">
        <div className="fitem">
          <input
            type="text"
            style={{ "background": "white", "padding": "5.5px 16px" }}
            id="txtStatus"
            value={filterCustomerType?.length === 0 ? 'Select Customer Type' : `Customer Type: ${filterCustomerType?.length} items selected`}
            onClick={() => { toggleCustomerType() }}
          />
          <img
            style={{ "top": "0.5px" }}
            src={chevronDown}
            alt=""
            onClick={() => { toggleCustomerType() }}
          />
        </div>
        {toggleCustomerTypeFilter && <div style={{ 'position': 'relative' }} className="field-dropdown right">
          <div className="field-dropdown-body">
            {
              map(customerType, (item, index) => {
                return (
                  <div key={index} className="fitem-check">
                    <input
                      id={item.value}
                      type="checkbox"
                      checked={item.is_change}
                      className="fitem-ck-input"
                      onChange={(e) => { onCustomerTypeChanged(e, index) }}
                    />
                    <label htmlFor={item.value} className="fitem-ck-txt">{item.label}</label>
                  </div>
                )
              })}
          </div>
        </div>}
      </div>)
  }

  const renderStatusFilters = () => {
    return (
      <div className="image-input work-order-status view-by-work-order-status">
        <div className="fitem">
          <input
            type="text"
            style={{ "background": "white", "padding": "5.5px 16px" }}
            id="txtStatus"
            value={filterWorkOrderStatus?.length === 0 ? 'Select Status' : `Status: ${filterWorkOrderStatus?.length} items selected`}
            onClick={() => { toggleStatus() }}
          />
          <img
            style={{ "top": "0.5px" }}
            src={chevronDown}
            alt=""
            onClick={() => { toggleStatus() }}
          />
        </div>
        {toggleStatusFilter && <div style={{ 'position': 'relative' }} className="field-dropdown right">
          <div className="field-dropdown-body">
            {
              map(workOrderStatus.WorkOrderStatus, (item, index) => {
                return (
                  <div key={index} className="fitem-check">
                    <input
                      id={item.value}
                      type="checkbox"
                      checked={item.is_change}
                      className="fitem-ck-input"
                      onChange={(e) => { onStatusChanged(e, index) }}
                    />
                    <label htmlFor={item.value} className="fitem-ck-txt">{item.label}</label>
                  </div>
                )
              })}
          </div>
        </div>}
      </div>)
  }

  const handelEditStatus = (e, record) => {
    let selected_row_keys = [];
    let cond1 = "";
    if (record.status === "forecast") {
      cond1 = "yes"
    } else {
      cond1 = "no"
    }
    selected_row_keys.push(record)
    let status = record?.status
    if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
      status = 'cancelled_non_billable';
    }
    if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
      status = 'cancelled_billable'
    }
    setIsModalVisible(true);
    setSelectedRowKeys(selected_row_keys);
    setCondOne(cond1);
    setSelectedDate(moment(record?.scheduled_time));
    setSelectedWorkOrderStatus(status);
    setSelectedTime(record?.time);
    setSelectedTechnician(record?.technician?.uuid)
  }

  const handleReassignTechnician = (e, record) => {
    let selected_row_keys_tech = [];
    let condition1 = "";
    if (record.status === "forecast") {
      condition1 = "yes"
    } else {
      condition1 = "no"
    }
    selected_row_keys_tech.push(record)
    let status = record?.status
    if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
      status = 'cancelled_non_billable';
    }
    if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
      status = 'cancelled_billable'
    }
    setIsModalVisibleTech(true);
    setSelectedRowKeys(selected_row_keys_tech);
    setCondOneTech(condition1);
    setSelectedWorkOrderStatus(status);
    setSelectedTechnician(record?.technician?.uuid)
  }

  const isAllTechnicianSame = (data) => {
    let technicianArr = data.map((each) => each?.technician?.uuid || '');
    const allEqual = technicianArr.every(v => v === technicianArr[0]);

    return allEqual;

  }

  const reassignToFilterValue = (data, uuid) => {
    let resData1 = data?.filter((f) => f?.user?.uuid === uuid)[0]?.user?.first_name || '';
    let resData2 = data?.filter((f) => f?.user?.uuid === uuid)[0]?.user?.last_name || '';

    return `${resData1} ${resData2}`
  }

  const onUpdateConfirmationScreen = () => {
    let reassignTechnicianFrom = reassignFrom;
    let reassignTechnicianTo = reassignTo === 'not assigned' ? null : reassignTo;
    // let workOrders = workOrderList?.results?.filter((r) => r?.technician?.uuid === reassignFrom).filter((f) => ((f.status === 'forecast') || (f.status === 'scheduled'))).map((each) => each.uuid);
    let workOrders = selectedRowData?.filter((f) => ((f.status === 'forecast') || (f.status === 'scheduled'))).map((each) => each.uuid);
    if (workOrders.length > 0) {
      let payload = {
        assign_from: [reassignTechnicianFrom],
        assign_to: reassignTechnicianTo,
        work_orders: workOrders,
        start_date: moment(startDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endDate).format("YYYY-MM-DD"),
        franchise_uuid: selectedFranchise,
      }
      bulkReassignTechApi(payload);
      setIsCofirmationOpen(false);
    } else {
      message.error("Only workorders with 'Forecast' or 'Secheduled' status will be updated", 10);
      setIsCofirmationOpen(false);
      defaultState();
    }

  }

  const bulkReassignTechApi = async (payload) => {
    setLoader(true)
    const data = await bulkReassignTech(payload);
    if (data?.message?.toLowerCase() === "sucessfuly assigned the technician") {
      defaultState();
      filter();
      message.success("Work orders are assigned", 10);
    } else if (data?.message?.toLowerCase() === "sucessfuly unassigned the technician") {
      defaultState();
      filter();
      message.success("Work orders are unassigned", 10);
    } else {
      defaultState();
      message.error(data?.message, 10);
    }
    setLoader(false)
  }

  useEffect(() => {
    if (selectedRowData.length === 0) {
      setIsCond1(false)
    } else {
      setIsCond1(true)
    }
  }, [selectedRowData])

  const onUpdate = () => {
    if (selectedRowData.length === 0) {
      // call api for condition 3
      const schema = yup.object().shape({

        reassignFrom: yup.string().required('Please select technician name'),
        reassignTo: yup.string().required('Please select technician name'),
      });
      const data = { reassignFrom: reassignFrom, reassignTo: reassignTo };
      schema.validate(data, { abortEarly: false }).then(() => {
        setErrors({});
        setIsCofirmationOpen(true);
        setIsModalVisibleTech(false);
      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => { err_data[item.path] = item.message });
        console.log("error", err_data)
        setErrors(err_data);
      })
    } else {
      if (selectedRowData.length > 0) {
        let reassignTechnicianFrom = selectedRowData[0]?.technician?.uuid;
        let reassignTechnicianTo = reassignTo;
        let workOrders = selectedRowData?.filter((f) => ((f.status === 'forecast') || (f.status === 'scheduled'))).map((each) => each.uuid);
        if (workOrders.length > 0) {
          let payload = {
            assign_from: [reassignTechnicianFrom],
            assign_to: reassignTechnicianTo,
            work_orders: workOrders,
            start_date: moment(startDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endDate).format("YYYY-MM-DD"),
            franchise_uuid: selectedFranchise,
          }
          // }
          //call api here for condition 1
          const schema = yup.object().shape({
            reassignTo: yup.string().required('Please select technician name'),
          });
          const data = { reassignTo: reassignTo };
          schema.validate(data, { abortEarly: false }).then(() => {
            setErrors({});
            bulkReassignTechApi(payload);
          }).catch((error) => {
            let err_data = {};
            map(error.inner, (item) => { err_data[item.path] = item.message });
            console.log("error", err_data)
            setErrors(err_data);
          })
        } else {
          message.error("Only workorders with 'Forecast' or 'Secheduled' status will be updated", 10);
          defaultState();
        }
      }
    }
  }

  const renderReassignModal = () => {
    if (selectedRowKeys.length > 0) {
      if (isAllTechnicianSame(selectedRowData)) {
        let fname = selectedRowData?.[0].technician?.first_name;
        if (fname == undefined) {
          fname = "";
        }
        let lname = selectedRowData?.[0].technician?.last_name;
        if (lname == undefined) {
          lname = "";
        }
        return (
          <>
            <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
              Bulk Reassign Technician
            </h3>
            <p style={{ textAlign: 'justify' }}>
              Reassign work order from one technician to another. Only work
              orders with 'Forecast' or 'Secheduled' status will be updated
            </p>
            <div className="field-group">
              <label>
                Reassign From <span className="danger">*</span>
              </label>
              <input
                type="text"
                placeholder={`${fname} ${lname}`}
                disabled
              />
            </div>
            <div className="field-group">
              <label>
                Reassign To <span className="danger">*</span>
              </label>
              <Select
                showSearch
                placeholder="Select Technician"
                optionFilterProp="children"
                onChange={(e) => {
                  setReassignTo(e);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={reassignTo.length > 0 ? reassignToFilterValue(technicians, reassignTo) : null}
              // disabled={selectedWorkOrderStatus === "forecast"}
              >
                {technicians?.map((technician, index) => {
                  return (
                    <Option
                      key={index}
                      value={technician?.user?.uuid}
                    >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                  );
                })}
              </Select>
              {errors?.["reassignTo"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.["reassignTo"]}
                </label>
              ) : (
                ""
              )}
            </div>
          </>
        );
      } else {
        return (
          <>
            <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
              Bulk Reassign Technician
            </h3>
            <br />
            <p>Please select the work orders with same technician to reassign the workorders</p>
          </>
        )
      }
    } else {
      return (
        <>
          <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
            Bulk Reassign Technician
          </h3>
          <p style={{ textAlign: 'justify' }}>Reassign work order from one technician to another. <br />Only work orders with 'Forecast' or 'Secheduled' status will be updated</p>
          <div className="field-group">
            <label>Reassign From <span className="danger">*</span></label>
            <Select
              showSearch
              placeholder="Select Technician"
              optionFilterProp="children"
              onChange={(e) => {
                // setSelectedTechnician(e);
                setReassignFrom(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={reassignFrom.length > 0 ? reassignToFilterValue(technicians, reassignFrom) : null}
            // disabled={selectedWorkOrderStatus === "forecast"}
            >
              {technicians?.map((technician, index) => {
                return (
                  <Option
                    key={index}
                    value={technician?.user?.uuid}
                  >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                );
              })}
            </Select>
            {errors?.["reassignFrom"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.["reassignFrom"]}
              </label>
            ) : (
              ""
            )}
          </div>
          {/* {cond === "no" && condOneTech === "no" ? null : ( */}
          <div className="field-group">
            <label>Reassign To <span className="danger">*</span></label>
            <Select
              showSearch
              placeholder="Select Technician"
              optionFilterProp="children"
              onChange={(e) => {
                setReassignTo(e);
                // setSelectedTechnician(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              value={reassignTo.length > 0 ? reassignToFilterValue(technicians, reassignTo) : null}
            // disabled={selectedWorkOrderStatus === "forecast"}
            >
              {technicians?.map((technician, index) => {
                return (
                  <Option
                    key={index}
                    value={technician?.user?.uuid}
                  >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                );
              })}
            </Select>
          </div>
          {/* )} */}
          {errors?.["reassignTo"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["reassignTo"]}
            </label>
          ) : (
            ""
          )}
        </>
      );
    }
  }
  // Render table action menu
  const renderAction = (record) => {
    const menu = (
      <Menu>
        <Menu.Item key="1"
          className={`${record?.batch_invoice_processed ? 'disabled' : ''}`}
          disabled={((record?.is_auto_invoice_genrated === true || record?.is_manual_invoice_generated === true) && (record?.status === "complete" || record?.status === "cancelled" || record?.status === "scheduled")) || selectedRowKeys.length > 1}
        ><a onClick={(e) => handelEditStatus(e, record)}>Edit Status</a></Menu.Item>
        <Menu.Item key="2"
          className={`${record?.batch_invoice_processed ? 'disabled' : ''}`}>
          <a href={'/#'}
            onClick={(e) => onActionChange(e, 'work_order', record)}>
            Edit Work Order
          </a>
        </Menu.Item>
        <Menu.Item key="3"
          className={`${record?.batch_invoice_processed ? 'disabled' : ''}`}
          disabled={(record?.service_order?.is_hide_from_display === true)}
        ><a href={'/#'} onClick={(e) => onActionChange(e, 'service_order', record)}>Edit Service Order</a></Menu.Item>
      </Menu>
    )

    return (
      <Dropdown overlay={menu} trigger={['hover']}>
        <span onClick={e => e.preventDefault()}>
          <DownOutlined />
        </span>
      </Dropdown>
    )
  }

  const onFilterWorkOrder = (selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate) => {
    const { is_franchise_owner, franchise_uuid } = getLoggedInUserRole()
    const obj = {
      offset: offset,
      limit: limit,
      ordering: ordering,
      // service_order__field_office__uuid: selectedFranchise === 'all' ? '' : selectedFranchise,
      service_order__client_type__in: filterCustomerType.join(","),
      status__in: filterWorkOrderStatus.join(","),
      technician__uuid__in: filterTechnician.join(","),
      scheduled_appointment__range: startDate !== null ? `${moment(startDate).format('YYYY-MM-DD 00:00:01')},${moment(endDate).format('YYYY-MM-DD 23:59:59')}` : null,
      q: searchValue
    };
    if (selectedFranchise !== 'all') {
      obj.service_order__field_office__uuid = selectedFranchise;
    }
    if (filters?.franchise || (filterData?.franchise && filterData?.franchise !== 'all')) {
      setFilterData(filterData => ({
        ...filterData,
        franchise: filters?.franchise?.toString() || filterData?.franchise
      }));
    }
    closeDrawer();
    getViewByWorkOrderApiCall(obj)
    // this.props.getViewByWorkOrder(obj);
    setLoader(true);
  }

  //clear filter
  const onClearFilter = () => {
    if (selectedFranchise === '' && startDate === null && searchValue?.length === 0) {
      onCustomerTypeChanged(0, 0);
      onStatusChanged(0, 0);
      onTechnicianChanged(0, 'aa');
      setFilterCustomerType([], () => {
        setTimeout(() => setWorkOrderList([]), closeDrawer(), 1000)
      });
      setFilterTechnician([], () => {
        setTimeout(() => setWorkOrderList([]), closeDrawer(), 1000)
      });
      setFilterWorkOrderStatus([], () => {
        setTimeout(() => setWorkOrderList([]), closeDrawer(), 1000)
      });
    } else {
      onCustomerTypeChanged(0, 0);
      onStatusChanged(0, 0);
      onTechnicianChanged(0, 'aa');
      setFilterCustomerType([], () => {
        setTimeout(() => onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate), 1000)
      });
      setFilterTechnician([], () => {
        setTimeout(() => onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate), 1000)
      });
      setFilterWorkOrderStatus([], () => {
        setTimeout(() => onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate), 1000)
      });
    }
  };

  // close customer filter drawer
  const closeDrawer = () => {
    setOpenDrawer(false);
    setToggleStatusFilter(false);
    setToggleTechnicianFilter(false);
    setToggleCustomerTypeFilter(false);
  };

  //Table Order and Pagination
  const onCustomerTableChange = (pagination, filters, sorter) => {
    let ordering1 = ordering
    if (sorter.column) {
      ordering1 = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering1 = ''
    }
    setOrdering(ordering1)
    setCurrent(pagination.current)
    onFilterWorkOrder(selectedFranchise, ordering1, searchValue, ((pagination.current - 1) * limit), pagination?.pageSize, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate)
  }

  // page size change
  const setCustomerPageLimit = (value) => {
    setLimit(value)
    onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, value, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate)
  };


  ////////////////////////////// Lead ///////////////////////////
  // Lead filter data change
  const onLeadFilterChange = (e, type, name) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    Object.assign(filterLeadData, { [e.target.name]: value });
    if (e.target.name === 'inactive') Object.assign(filterLeadData, { lead_stage: 'all' })

    if (e.target.name === 'franchise') {
      onLeadFilterClick()
      setFilterLeadData(filterLeadData => ({
        ...filterLeadData,
        filterFranchise: value
      }));
    }
  };

  // on Lead filter click
  const onLeadFilterClick = () => {
    const { is_franchise_owner, franchise_uuid } = getLoggedInUserRole();
    setLoader(true);
    setLeadSearchValue('');
    const obj = {
      offset: 0,
      limit: activeLeadPagination,
      ordering: '',
      serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : ''
    };

    if (filterLeadData?.franchise && filterLeadData?.franchise !== 'all') {
      Object.assign(obj, { serviceorder__field_office__uuid: filterLeadData?.franchise });
    }

    if ((filterLeadData?.active && !filterLeadData?.inactive) || (!filterLeadData?.active && filterLeadData?.inactive)) {
      Object.assign(obj, { is_active: filterLeadData?.active === true ? true : filterLeadData?.inactive === true && false });
    }

    if (filterLeadData?.lead_stage && filterLeadData?.lead_stage !== 'all') {
      Object.assign(obj, { lead_profile__stage: filterLeadData?.lead_stage })
    }

    if (filterLeadData?.outreach && filterLeadData?.outreach !== 'all') {
      Object.assign(obj, { lead_profile__outreach: filterLeadData?.outreach })
    }

    if (filterLeadData?.last_contacted?.length > 0) {
      Object.assign(obj, { lead_status_logs__created_at__range: `${moment(filterLeadData?.last_contacted?.[0]).format('YYYY-MM-DDT00:00:00')}, ${moment(filterLeadData?.last_contacted?.[1]).format('YYYY-MM-DDT23:59:59')}` })
    }

    // props.getLeads(obj);
    // getLeadsApiCall(obj)
    setLeadListPagination((leadListPagination) => ({
      ...leadListPagination,
      current: 1
    }))
    closeLeadDrawer();
  };

  //clear lead filter
  const onClearLeadFilter = () => {
    setFilterLeadData({
      active: false,
      inactive: false,
      franchise: '',
      lead_stage: 'all',
      outreach: 'all',
      first_contacted: [],
      last_contacted: []
    }, () => {
      setTimeout(() => onLeadFilterClick(), 1500)
    })
  };

  // close lead filter drawer
  const closeLeadDrawer = () => {
    setOpenDrawer(false);
  };

  // Leads Table Order and Pagination
  const onLeadTableChange = (pagination, filters, sorter) => {
    let ordering = ''
    Object.assign(leadListPagination, { current: pagination.current });
    const { is_franchise_owner, franchise_uuid } = getLoggedInUserRole()

    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = ''
    }

    setLeadListPagination(leadListPagination)
    setLoader(true)

    let obj = {
      offset: ((pagination.current - 1) * activeLeadPagination), limit: pagination?.pageSize, ordering,
      serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '', q: searchValue
    }
    if (filterLeadData?.franchise && filterLeadData?.franchise !== 'all') {
      Object.assign(obj, { serviceorder__field_office__uuid: filterLeadData?.franchise });
    }

    if ((filterLeadData?.active && !filterLeadData?.inactive) || (!filterLeadData?.active && filterLeadData?.inactive)) {
      Object.assign(obj, { is_active: filterLeadData?.active === true ? true : filterLeadData?.inactive === true && false });
    }

    if (filterLeadData?.lead_stage && filterLeadData?.lead_stage !== 'all') {
      Object.assign(obj, { lead_profile__stage: filterLeadData?.lead_stage })
    }

    if (filterLeadData?.outreach && filterLeadData?.outreach !== 'all') {
      Object.assign(obj, { lead_profile__outreach: filterLeadData?.outreach })
    }

    if (filterLeadData?.last_contacted?.length > 0) {
      Object.assign(obj, { lead_status_logs__created_at__range: `${moment(filterLeadData?.last_contacted?.[0]).format('YYYY-MM-DDThh:mm:ss')}, ${moment(filterLeadData?.last_contacted?.[1]).format('YYYY-MM-DDThh:mm:ss')}` })
    }
    // getLeadsApiCall(obj)
    // props.getLeads(obj);
  }

  // leads page size change
  const setLeadPageLimit = (value) => {
    Object.assign(leadListPagination, { current: 1, pageSize: value, ordering: '', q: searchValue });
    const { is_franchise_owner, franchise_uuid } = getLoggedInUserRole()

    setActiveLeadPagination(value);
    setLeadListPagination(leadListPagination);
    let obj = { offset: 0, limit: value, ordering: '', serviceorder__field_office__uuid: is_franchise_owner ? franchise_uuid : '' }
    if (filterLeadData?.franchise && filterLeadData?.franchise !== 'all') {
      Object.assign(obj, { serviceorder__field_office__uuid: filterLeadData?.franchise });
    }

    if ((filterLeadData?.active && !filterLeadData?.inactive) || (!filterLeadData?.active && filterLeadData?.inactive)) {
      Object.assign(obj, { is_active: filterLeadData?.active === true ? true : filterLeadData?.inactive === true && false });
    }

    if (filterLeadData?.lead_stage && filterLeadData?.lead_stage !== 'all') {
      Object.assign(obj, { lead_profile__stage: filterLeadData?.lead_stage })
    }

    if (filterLeadData?.outreach && filterLeadData?.outreach !== 'all') {
      Object.assign(obj, { lead_profile__outreach: filterLeadData?.outreach })
    }

    if (filterLeadData?.last_contacted?.length > 0) {
      Object.assign(obj, { lead_status_logs__created_at__range: `${moment(filterLeadData?.last_contacted?.[0]).format('YYYY-MM-DDThh:mm:ss')}, ${moment(filterLeadData?.last_contacted?.[1]).format('YYYY-MM-DDThh:mm:ss')}` })
    }
    // getLeadsApiCall(obj)
    // props.getLeads(obj);
  };


  // render customer filter drawer
  const renderDrawer = () => {

    return (
      <Drawer
        placement={'right'}
        closable={false}
        onClose={closeDrawer}
        visible={openDrawer}
        key={'right'}
        width={'400'}
        className="drawer-wrapper"
      >
        <div className="group_wrapper rdrawer">
          <div className="card-head">
            <h4>Filter Work Orders</h4>
          </div>
          <div className="card-row">
            <div className="card-full">
              <div className="form-group">
                <div className="fitem">
                  <label htmlFor="customerType">Customer Type</label>
                  {renderCustomerTypeFilters()}
                </div>
                <div className="fitem">
                  <label htmlFor="workOrderStatus">Work Order Status</label>
                  {renderStatusFilters()}
                </div>
                <div className="field-group">
                  <label>Technician</label>
                  {renderTechnicianFilters()}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <button className={`btn btn-bdr`} onClick={() => { closeDrawer() }}>Cancel</button>
            <button className={`btn btn-warning`} onClick={() => { onClearFilter(); }}>Clear</button>
            <button
              // type="submit"
              className={`btn btn-primary`}
              onClick={() => filter()}>   Filter WO
            </button>
          </div>
        </div>
      </Drawer>
    )
  };

  const filter = () => {
    onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate);
    setCurrent(1);
    setToggleStatusFilter(false);
    setToggleTechnicianFilter(false);
    setToggleCustomerTypeFilter(false)
  }

  const filterDate = (dateType) => {
    let startDate = null;
    let endDate = null;
    if (dateType === 'Yesterday') {
      startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
      endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    }
    if (dateType === 'Today') {
      startDate = moment().format('YYYY-MM-DD');
      endDate = moment().format('YYYY-MM-DD');
    }
    if (dateType === 'Tomorrow') {
      startDate = moment().add(1, 'days').format('YYYY-MM-DD');
      endDate = moment().add(1, 'days').format('YYYY-MM-DD');
    }
    if (dateType === 'Last Week') {
      startDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      endDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
    }
    if (dateType === 'This Week') {
      startDate = moment().startOf('week').format('YYYY-MM-DD');
      endDate = moment().endOf('week').format('YYYY-MM-DD');
    }
    if (dateType === 'Next Week') {
      startDate = moment().add(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      endDate = moment().add(1, 'weeks').endOf('week').format('YYYY-MM-DD');
    }
    setDateType(dateType)
    setStartDate(startDate)
    setEndDate(endDate)
    onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate)
  }


  const onActionChange = (event, action, row) => {
    event.preventDefault();
    (async () => {
      let data;
      let role_filter;

      if (row?.service_order?.client_type === 'residential') {
        data = await getCustomer(row?.customer.uuid);
        role_filter =
          row?.customer?.role[0] === "customer" ||
            row?.customer?.user_roles.find((i) => i.name === "customer")
            ? "customer"
            : "";
      } else {
        data = await getCommercialCustomer(row?.customer.uuid);
        role_filter =
          row?.customer?.role[0] === "commercial_customer" ||
            row?.customer?.user_roles.find((i) => i.name === "commercial_customer")
            ? "commercial_customer"
            : "";
      }

      switch (action) {
        case 'work_order':
          if (row?.customer) {
            if (role_filter === 'customer') {
              navigate(`/residential/customer-detail`, {
                state: {
                  record: {
                    ...data,
                    id: row?.customer?.uuid,
                    customer: row?.customer,
                    selectedTab: 2,
                    service: row?.service_order,
                    work_order_uuid: row?.uuid,
                    transection_type: "Update",
                    from: 'ViewByWorkOrder',
                    field_office_uuid: row?.service_order?.field_office__uuid,
                  }
                }
              });
            } else if (role_filter === 'commercial_customer') {
              navigate(`/commercial/customer-detail?redirect_to_work_order=true`, {
                state: {
                  record: {
                    ...data,
                    id: row?.customer?.uuid,
                    customer: row?.customer,
                    selectedTab: 2,
                    service: row?.service_order,
                    work_order_uuid: row?.uuid,
                    transection_type: "Edit",
                    from: 'ViewByWorkOrder',
                    field_office_uuid: row?.service_order?.field_office__uuid,
                  }
                }
              })
            }
          }
          break;
        case 'service_order':
          if (row?.customer) {
            if (role_filter === 'customer') {
              navigate(`/residential/customer-detail`, {
                state: {
                  record: {
                    ...data,
                    id: row?.customer?.uuid,
                    customer: row?.customer,
                    selectedTab: 2,
                    service: row?.service_order,
                    work_order_uuid: row?.uuid,
                    from: 'ViewByServiceOrder',
                    transection_type: "Update",
                    field_office_uuid: row?.service_order?.field_office__uuid,
                  }
                }
              });
            } else if (role_filter === 'commercial_customer') {
              navigate(`/commercial/customer-detail`, {
                state: {
                  record: {
                    ...data,
                    id: row?.customer?.uuid,
                    customer: row?.customer,
                    selectedTab: 2,
                    service: row?.service_order,
                    work_order_uuid: row?.uuid,
                    from: 'ViewByServiceOrder',
                    transection_type: "Update",
                    field_office_uuid: row?.service_order?.field_office__uuid,
                  }
                }
              });
            }
          }
          break;
        default:
          break;
      }
    })();
  }

  // on customer list row
  const onCustomerListRow = (record) => {
    return {
      onClick: () => {
        navigate(`/residential/customer-detail`, { state: { 'id': record?.uuid, hear_about_us: record?.hear_about_us, customer_satisfaction_score: record?.customer_satisfaction_score, record: record } })
      }
    }
  }

  //Selct Table Row checkbox
  const onSelectedRowKeysChange = (selectedRowKeys) => {
    if (selectedRowKeys.length === 1) {
      let record = workOrderList?.results?.filter(r => r.uuid === selectedRowKeys[0])[0]
      let status = record?.status
      if (record?.status === 'cancelled' && !record?.bill_for_cancelization) {
        status = 'cancelled_non_billable';
      }
      if (record?.status === 'cancelled' && record?.bill_for_cancelization && !record?.is_auto_invoice_genrated) {
        status = 'cancelled_billable'
      }
      setSelectedDate(moment(record?.scheduled_time));
      setSelectedWorkOrderStatus(status);
      setSelectedTime(record?.time);
      setSelectedTechnician(record?.technician?.uuid);
    } else {
      setSelectedDate(moment());
      setSelectedWorkOrderStatus('');
      setSelectedTime('');
    }
    setSelectedRowKeys(selectedRowKeys)

    let filtersData = [];
    selectedRowKeys.forEach((eachKey) => {
      let filterData = workOrderList?.results?.filter((r) => r.uuid === eachKey)[0]
      filtersData.push(filterData);

    })
    setSelectedRowData(filtersData);
    setReassignFrom(filtersData[0]?.technician?.uuid);
    setIsUpdateButtonDisable(!isAllTechnicianSame(filtersData));
  }
  // convert date time from local to utc
  const localToUTC = (date) => {
    return moment.utc(date)
  }

  const updateWorkOrder = () => {
    let arrData = [];
    map(selectedRowKeys, x => {
      arrData.push({
        bill_for_cancelization: selectedWorkOrderStatus === 'cancelled_non_billable' ? false : selectedWorkOrderStatus === 'cancelled_billable' ? true : null,
        uuid: x.uuid ? x.uuid : x,
        status: selectedWorkOrderStatus === 'cancelled_non_billable' || selectedWorkOrderStatus === 'cancelled_billable' ? "cancelled" : selectedWorkOrderStatus,
        date: selectedDates ? selectedDates?.format('YYYY-MM-DD') : null,
        technician_uuid: selectedTechnician ? selectedTechnician : '',
        time: moment(selectedTimes).format("HH:mm:ss") === "Invalid date" ? selectedTimes : moment(selectedTimes).format("HH:mm:ss"),
      })
    })
    axiosInstance.post(`/service-ftech/batch_update_service_order/`, arrData).then((response) => {
      message.success(`Status of the respective work order(s) has been changed successfully`, 10);
      setTimeout(() => {
        onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, startDate, endDate);
        setIsModalVisible(false);
        setIsModalVisibleTech(false);
        setSelectedRowKeys([]);
        setSelectedWorkOrderStatus('');
        setTime('');
        setSelectedTechnician('');
        setSelectedDate(moment().format('MM/DD/YYYY'));
        setSelectedTime('');
        setErrors({});
      }, 1000);
    }).catch(error => {
      message.error(`Error occurred while updating Work order.`, 10);
    });
  }

  const setSelectedDateRange = (e) => {
    if (e) {
      setStartDate(moment(e[0]).format('YYYY-MM-DD'));
      setEndDate(moment(e[1]).format('YYYY-MM-DD'));
      setDateType('');
    } else {
      setStartDate(null);
      setEndDate(null);
      setDateType('');
    }
    onFilterWorkOrder(selectedFranchise, ordering, searchValue, offset, limit, filterCustomerType, filterWorkOrderStatus, filterTechnician, moment(e[0]).format('YYYY-MM-DD'), moment(e[1]).format('YYYY-MM-DD'))
    setCurrent(1)
  }

  const validate = () => {
    let selectedTime = moment(selectedTimes).format("hh:mm a") === "Invalid date" ? selectedTimes : moment(selectedTimes).format("hh:mm a");
    let selectedDate = selectedWorkOrderStatus === 'forecast' ? selectedDates : `${selectedDates} ${selectedTime}`;
    let isPastDate = false;
    let isFutureDate = false;
    if (selectedWorkOrderStatus === 'forecast') {
      isPastDate = moment(selectedDates, "YYYY/MM/DD").isBefore(moment(new Date()).format("YYYY/MM/DD"));
      console.warn('forecast - isPastDate', isPastDate, 'scheduled', selectedDate)
    }
    if (selectedWorkOrderStatus === 'scheduled') {
      isPastDate = moment(selectedDates).isBefore(moment(new Date()).format("YYYY/MM/DD hh:mm a"));
      console.warn('scheduled- isPastDate', isPastDate, 'scheduled', selectedDates)
    }
    if (selectedWorkOrderStatus === 'complete' || selectedWorkOrderStatus === 'incomplete' || selectedWorkOrderStatus === 'cancelled_billable') {
      isFutureDate = !moment(selectedDates, "YYYY/MM/DD").isBefore(moment());
    }
    const schema = yup.object().shape({
      selectedDates: yup.string().required('Select date.'),
      selectedWorkOrderStatus: yup.string().required('Please select valid status.'),
      // selectedTechnician: yup.string().required('Please select a technician'),
      selectedTimes: time_status.indexOf(selectedWorkOrderStatus) > -1 && !time ? yup.string().nullable(true).required('Select time.') : null,
      time_past: isPastDate && (selectedTimes || selectedWorkOrderStatus === 'forecast') ? yup.string().required('Work orders in this status cannot have a date/time in the past.') : null,
      time_future: isFutureDate ? yup.string().required('Work orders in this status cannot have a date/time in the future.') : null,
      // technician: workorder_status.indexOf(selectedWorkOrderStatus) > -1 && !selectedTechnician ? yup.string().required('Please select assigned technician.') : null,
    });
    const data = { selectedDates: selectedDates || '', selectedTechnician, selectedTimes, selectedWorkOrderStatus };
    schema.validate(data, { abortEarly: false }).then(() => {
      setErrors({});
      updateWorkOrder();
    }).catch((error) => {
      let err_data = {};
      map(error.inner, (item) => { err_data[item.path] = item.message });
      console.log("error", err_data)
      setErrors(err_data);
    })
  }

  //render Work Order list
  const renderWorkOrderList = () => {
    const franchise = workOrderList?.results?.map((item) => ({ text: item?.name, value: item?.uuid }));
    cond = "other";
    if (selectedRowKeys?.length > 0) {
      let arr = [];
      map(selectedRowKeys, (sk, i) => {
        let WO = workOrderList?.results.filter(function (obj) {
          return obj.uuid === sk;
        });
        arr.push(WO[0])
      });
      let findArr = _.map(arr, 'status')
      let isDisabled = findArr.includes("forecast");
      if (isDisabled) {
        cond = "yes"
      } else if (isDisabled === false) {
        cond = "no"
      } else {
        cond = "other"
      }
    }
    let optimo_route_table_data;
    if (technicians[0]?.field_office?.is_optimoroute_enabled == true) {
      optimo_route_table_data = DataTable.customerColumns(renderAction);
    }
    else {
      optimo_route_table_data = DataTable.customeroptimorouteColumns(renderAction);

    }
    return (
      <div className="leadstblviewbyworkorder">
        <Table
          rowKey={record => record.uuid}
          dataSource={workOrderList?.results}
          pagination={{ showSizeChanger: false, pageSize: limit, current, total: workOrderList?.count }}
          columns={optimo_route_table_data}
          onChange={workOrderList?.results?.length > 0 ? onCustomerTableChange : null}
          // onRow={onCustomerListRow}
          className="customer-list field_serve"
          rowSelection={{
            type: 'checkbox',
            hideSelectAllCheckbox: true,
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys) => { onSelectedRowKeysChange(selectedRowKeys) },
            getCheckboxProps: (record) => {
              return {
                disabled:
                  record?.batch_invoice_processed ||
                  (cond === "yes" ?
                    ((record?.is_auto_invoice_genrated === true || record?.is_manual_invoice_generated === true)
                      || (record?.status !== "forecast"))
                    : cond === "no"
                      ?
                      ((record?.is_auto_invoice_genrated === true || record?.is_manual_invoice_generated === true)
                        || (record?.status === "forecast"))
                      : (record?.is_auto_invoice_genrated === true || record?.is_manual_invoice_generated === true) &&
                      (record?.status === "scheduled" || record?.status === "complete" || record?.status === "cancelled")),
              }
            },
          }}
          footer={() => <ul className='view_work_order_total'><li>Total</li><li>{workOrderList?.results?.length > 0 ? '$' : '$'}{formatToCurrency(workOrderList?.results?.reduce((a, b) => a + (b['total_price'] || 0), 0))}</li></ul>}
        />
        {workOrderList?.results?.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setCustomerPageLimit('15') }}
                className={limit == '15' ? 'active' : ''}
              >15</span>
            </li>
            <li>
              <span
                className={limit == '30' ? 'active' : ''}
                onClick={() => { setCustomerPageLimit('30') }}>30</span>
            </li>
            <li>
              <span
                className={limit == '60' ? 'active' : ''}
                onClick={() => { setCustomerPageLimit('60') }}>60</span>
            </li>
          </ul> : ''}
      </div>
    )
  };

  const { is_disable_franchise_dropdown } = getLoggedInUserRole()

  const filterOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDrawer(true)
  }

  const onCancelModel = () => {
    setIsCofirmationOpen(false);
    setIsUpdateButtonDisable(false);
    setIsModalVisible(false);
    setIsModalVisibleTech(false);
    setSelectedRowKeys([]);
    setSelectedWorkOrderStatus('');
    setTime('');
    setSelectedTechnician('');
    setSelectedTime('');
    setSelectedDate('');
    setErrors({});
    setReassignFrom("");
    setReassignTo("");
  }

  moment.locale("en-gb", {
    week: {
      dow: 0,
    },
  });
  const onExportTable = async (is500=0) => {
    
      let payLoad = {
        ...exportFilters,
        is_500: is500,
        is_optimarouteenabled: technicians[0]?.field_office?.is_optimoroute_enabled == true
      };
      payLoad.limit = leadList?.count;
      payLoad.offset = 0
      setLoadingExport(true);
      const response = await exportViewByWorkOrder(payLoad);
      setLoadingExport(false);      
  }
  
  return (
    <div id="wrapper abs">
      <div className="content-area" style={{ width: "100%" }}>
        {/* Header */}
        <Header
          placeholder={"Customer Name or Zip Code"}
          readOnly={false}
          search_value={searchValue}
          setSearchValue={setSearchValue}
          onSearch={onSearch}
          // disabled={readOnlySearch}
          onEnter={(e) => {
            e.target.value.length > 2 ?
              onSearch(e)
              : alert("Enter at least 3 character!")
          }}
        />
        <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenuButtonClick}
        />

        <div className="container">
          {loader && <Loader />}
          <div className="filter_wrapper">
            <div className="left_side">
              <div className="fitem">
                <Select
                  className="swhite"
                  name="franchise"
                  value={selectedFranchise}
                  onChange={(e) => {
                    if (loggedInUserRole?.includes('franchise_owner') || loggedInUserRole?.includes('operations_mgr')) {
                      setFranchiseSelected(e);
                      setSelectedFranchise(getFranchiseSelected().uuid);
                      setFilterTechnician([]);
                      e === 'all' ? getFieldOfficeStaffApiCall({}) : getFieldOfficeStaffApiCall({ field_office__uuid: e });
                      // this.props.getFieldOfficeStaff({field_office__uuid: e})
                      onFilterWorkOrder(
                        e,
                        ordering,
                        searchValue,
                        offset,
                        limit,
                        filterCustomerType,
                        filterWorkOrderStatus,
                        [],
                        startDate,
                        endDate
                      );
                      setCurrent(1);
                    } else {
                      setSelectedFranchise(e);
                      setFilterTechnician([]);
                      e === 'all' ? getFieldOfficeStaffApiCall({}) : getFieldOfficeStaffApiCall({ field_office__uuid: e });
                      // this.props.getFieldOfficeStaff({field_office__uuid: e})
                      onFilterWorkOrder(
                        e,
                        ordering,
                        searchValue,
                        offset,
                        limit,
                        filterCustomerType,
                        filterWorkOrderStatus,
                        [],
                        startDate,
                        endDate
                      );
                      setCurrent(1);
                    }

                  }}
                >
                  <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>
                  {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
                    <Option
                      key={option?.uuid}
                      value={option?.uuid}
                    >{`${leadingZeros(option?.id, 3)} ${option?.name
                      }`}</Option>
                  ))}
                </Select>
              </div>
              <div className="fitem" style={{ padding: "0px 4px" }}>
                <ConfigProvider locale={en_GB}>
                  <RangePicker
                    format={"MM/DD/YYYY"}
                    className="date_ranger dwhite"
                    dropdownClassName="dropdown_date_range"
                    prefixIcon={<i className="las la-calendar"></i>}
                    onChange={(e) => {
                      setSelectedDateRange(e);
                    }}
                    value={
                      startDate !== null
                        ? [moment(startDate), moment(endDate)]
                        : ""
                    }
                  />
                </ConfigProvider>
              </div>
            </div>
            <ul className="tabs mwhite field-service d-block">
              <li
                className={dateType === "Yesterday" ? "active" : ""}
                onClick={() => {
                  filterDate("Yesterday");
                }}
              >
                <span>{`Yesterday`}</span>
              </li>
              <li
                className={dateType === "Today" ? "active" : ""}
                onClick={() => {
                  filterDate("Today");
                }}
              >
                <span>Today</span>
              </li>
              <li
                className={dateType === "Tomorrow" ? "active" : ""}
                onClick={() => {
                  filterDate("Tomorrow");
                }}
              >
                <span>{`Tomorrow`}</span>
              </li>
            </ul>
            <ul
              className="tabs mwhite field-service d-block"
              style={{ marginLeft: "10px" }}
            >
              <li
                className={dateType === "Last Week" ? "active" : ""}
                onClick={() => {
                  filterDate("Last Week");
                }}
              >
                <span>{`Last Week`}</span>
              </li>
              <li
                className={dateType === "This Week" ? "active" : ""}
                onClick={() => {
                  filterDate("This Week");
                }}
              >
                <span>This Week</span>
              </li>
              <li
                className={dateType === "Next Week" ? "active" : ""}
                onClick={() => {
                  filterDate("Next Week");
                }}
              >
                <span>{`Next Week`}</span>
              </li>
            </ul>
            <div className="right_side">
              <span className="ico-btn">
                <FilterOutlined onClick={(e) => filterOut(e)}>
                  Filter
                </FilterOutlined>
              </span>
              {
                      workOrderList?.count> 500 ? (
                      <Popconfirm
                        onConfirm={() => !loadingExport && onExportTable(1)}
                        okText="Export"
                        cancelText="Cancel"
                        icon={<></>}
                        title={
                          <>
                            <div>
                              {currentTab === "lead"?"Leads":"Customers"} list data is more than 500 records. Data would be
                              <br />
                              exported and emailed to
                              {' '}
                              <b>{authUser?.user?.email}</b>.
                            </div>
                          </>
                        }
                      >
                        <button
                        className="btn btn-primary btn-bdr flex"
                        >
                          {loadingExport ? 'Exporting...' : 'Export'}
                        </button>
                      </Popconfirm>
                    ) : (
                      <button
                        disabled={loadingExport}
                        onClick={() => onExportTable()}
                        className="btn btn-primary btn-bdr flex"
                        >
                          {loadingExport ? 'Exporting...' : 'Export'}
                        </button>
                    )
                  } 

              <button
                className="btn btn-primary btn-bdr flex"
                onClick={() => setIsModalVisible(true)}
              >
                Edit Status
              </button>
              <button
                className="btn btn-primary btn-bdr flex"
                onClick={() => setIsModalVisibleTech(true)}
              >
                Reassign Technician
              </button>
            </div>
          </div>
          <div className="data leadstable">
            {renderWorkOrderList()}
            {renderDrawer()}
          </div>
        </div>
      </div>
      <Modal
        className=""
        width={400}
        cancelText={"Cancel"}
        visible={selectedRowKeys?.length === 0 && isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      >
        <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
          Update Work Order Status
        </h3>
        <p>{selectedRowKeys?.length + " Work Order selected."}</p>
        <div className="field-group">
          <label style={{ color: "red" }}>
            Please Select any Work Order!
          </label>
        </div>
      </Modal>
      <Modal
        className=""
        width={400}
        okText={"Update"}
        cancelText={"Cancel"}
        visible={selectedRowKeys?.length > 0 && isModalVisible}
        onOk={() => validate()}
        onCancel={() => onCancelModel()}
      >
        <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
          Update Work Order Status
        </h3>
        <p>{selectedRowKeys?.length + " Work Order selected."}</p>
        <div className="field-group">
          <label>
            Change Work Order Status <span className="danger">*</span>
          </label>
          <Select
            id={"workOrderStatus"}
            placeholder="Work Order Status"
            name="selectedWorkOrderStatus"
            onChange={(e) => {
              if (e === "complete") {
                setSelectedWorkOrderStatus(e);
                setSelectedTime(moment("12:00 AM", ["h:mm A"]));
              } else {
                setSelectedWorkOrderStatus(e);
                setSelectedTime("");
                setSelectedTechnician(
                  e === "forecast" ? "" : selectedTechnician
                );
              }
            }}
            value={selectedWorkOrderStatus || undefined}
          >
            {workOrderStatus.WorkOrderStatus?.map((option) => (
              <Option key={option?.value} value={option?.value}>
                {option?.label}
              </Option>
            ))}
          </Select>
          {errors?.["selectedWorkOrderStatus"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["selectedWorkOrderStatus"]}
            </label>
          ) : (
            ""
          )}
        </div>
        {cond === "no" && condOne === "no" ? null : (
          <div className="field-group">
            <label>Assigned Technician</label>
            <Select
              showSearch
              placeholder="Not assigned"
              optionFilterProp="children"
              onChange={(e) => {
                setSelectedTechnician(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              value={selectedTechnician || undefined}
              disabled={selectedWorkOrderStatus === "forecast"}
            >
              {technicians?.map((technician, index) => {
                return (
                  <Option
                    key={index}
                    value={technician?.user?.uuid}
                  >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                );
              })}
            </Select>
          </div>
        )}
        {errors?.["selectedTechnician"] ? (
          <label className="new_err_msg">
            <i className="las la-info-circle" />
            {errors?.["selectedTechnician"]}
          </label>
        ) : (
          ""
        )}
        <div style={{ display: "flex" }}>
          <div
            className="field-group"
            style={{ width: "50%", paddingRight: 5 }}
          >
            <label>
              Work Order Date<sup>*</sup>
            </label>
            <DatePicker
              name="work-order-date"
              format="MMM DD,YYYY"
              // format="MM/DD/YYYY"
              style={{ width: "100%" }}
              value={selectedDates ? selectedDates : moment()}
              onChange={(e) => {
                setSelectedDate(e);
              }}
            />
            {errors?.["selectedDates"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.["selectedDates"]}
              </label>
            ) : (
              ""
            )}
          </div>
          <div
            className="field-group"
            style={{ width: "50%", paddingLeft: 5 }}
          >
            <label>Time</label>
            <TimePicker
              className="swhite"
              placeholder={"--:-- AM/PM"}
              format={"h:mm A"}
              onChange={(e) => {
                setSelectedTime(e);
              }}
              // defaultValue={selected_time?moment(selected_time, "h:mm A"):undefined}
              value={
                selectedTimes ? moment(selectedTimes, "hh:mm") : undefined
              }
              allowClear={["forecast", "canceled"].includes(selectedTimes)}
              disabled={selectedWorkOrderStatus === "forecast"}
            />
            {errors?.["selectedTimes"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.["selectedTimes"]}
              </label>
            ) : (
              ""
            )}
          </div>
        </div>
        {errors?.["time_past"] ? (
          <label className="new_err_msg align-items-start lh-20">
            <i className="las la-info-circle" />
            {errors?.["time_past"]}
          </label>
        ) : (
          ""
        )}
        {errors?.["time_future"] ? (
          <label className="new_err_msg align-items-start lh-20">
            <i className="las la-info-circle" />
            {errors?.["time_future"]}
          </label>
        ) : (
          ""
        )}
      </Modal>
      {/* ======================================================================= */}

      <Modal
        style={{ marginTop: '-200px' }}
        className=""
        width={400}
        okText={"Update"}
        cancelText={"Cancel"}
        visible={isModalVisibleTech}
        onOk={onUpdate}
        onCancel={() => onCancelModel()}
        okButtonProps={{
          style: {
            display: isUpdateButtonDisable ? 'none' : 'initial',
            backgroundColor: '#ff681d',
            color: '#005D89'
          }
        }}
        cancelButtonProps={{
          style: {
            backgroundColor: '#ff681d',
            color: '#005D89'
          }
        }}
        closable={false}
      >
        {isModalVisibleTech && renderReassignModal()}
      </Modal>
      <Modal
        style={{ marginTop: '-200px' }}
        className=""
        width={400}
        okText={"Yes"}
        cancelText={"No"}
        visible={isCofirmationOpen}
        onOk={onUpdateConfirmationScreen}
        onCancel={() => onCancelModel()}
        closable={false}
      >
        <h3 style={{ paddingTop: "25px", marginBottom: "5px" }}>
          Bulk Reassign Technician
        </h3>
        <br />
        <p>Do you want to assign all the work orders from {reassignToFilterValue(technicians, reassignFrom)} to {reassignToFilterValue(technicians, reassignTo)}?</p>
      </Modal>
    </div>
  );
}

export default ViewByWorkOrder;
