import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userimg from "../Assets/images/userimg.jpg";
import { DebounceInput } from "react-debounce-input";
import userIcon from "../Assets/icons/user-placeholder.svg"
const Header = (props) => {
  return (
    <header>
      <div className="flex">
        <div className="search_bar fitem-rinner licon">
          <DebounceInput
            className="form-control"
            placeholder={props.placeholder}
            minLength={3}
            debounceTimeout={2000}
            readOnly={props.disabled}
            value={props.searchValue}
            onChange={(e) => props.setSearchValue(e.target.value)}
            onKeyPress={props.onKeyPressHandler}
          />
          <div className="fitem-ricon">
            <i className="las la-search"></i>
          </div>
        </div>
        { props?.view === "proposalTab" ? "" :
        <div className="action_menu flex flex-end ai-center">
        <a href="#" className="quick-link-home">
          <img src={userIcon} alt={""} style={{ width: "40px" }} />
        </a>
          {/* <span className="profile-wrap m-0">
            <Link to={"/settings"}>
              {" "}
              <figure className="mb-0">
                <img
                  className="userimg"
                  src={userimg}
                  alt=""
                />
              </figure>
            </Link>
          </span> */}
        </div>
        }
      </div>
    </header>
  );
};

export default Header;
