import React, { Component, useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import dclogo from "../Assets/images/logo-asp.svg";
import RightSlider from '../Login/RightSlider';
import {errorHandle} from "../Components/errorHandle";
import { forGotPWD } from '../Action/Auth';

const ForgotPassword = (props) => {
const [state,setState] = useState({
    loader: false, 
    email: '', 
    is_btn_active: false, 
    forgot_success: false
})
const navigate = useNavigate();
const emailInputRef = useRef(null);

function getState(){
    setState({
    loader: false, 
    email: '', 
    is_btn_active: false, 
    forgot_success: false
    })
}

useEffect(()=>{
    getState();
    const accessToken = JSON.parse(localStorage.getItem("authToken"))
    ? JSON.parse(localStorage.getItem("authToken")).key
    : "";
    if (accessToken) {
        navigate("/dashboard");
    }
},[])
  
//   UNSAFE_componentWillReceiveProps = (props) => {
//     // message.destroy();
//     // Register User Response
//     if (Object.keys(props.ForgotPwd.data).length > 0 && props.ForgotPwd.data !== this.props.ForgotPwd.data) {
//       // display Success messsage
//       this.setState({ forgot_success: true })
//     }
//     if (Object.keys(props.ForgotPwd.errors).length > 0 && props.ForgotPwd.errors !== this.props.ForgotPwd.errors) {
//       // display error messsage
//       this.setState({ forgot_success: false })
//       if (props.ForgotPwd.errors.email) {
//         message.error(props.ForgotPwd.errors.email, 10);
//       }
//       else {
//         errorHandle(props.ForgotPwd.errors)
//       }


//     }
//     this.setState({ loader: false })
//   }

  // set email value
  const onUserChange = (e) => {
    const REGULAR_EXPRESSION_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setState((state)=>({...state, [e.target.name]: e.target.value }))

    if (e.target.value.toString().toLowerCase().match(REGULAR_EXPRESSION_EMAIL)) {
      setState((state)=>({
        ...state,
        is_btn_disbale: true
      }))
    }
    else {
      setState((state)=>({
        ...state,
        is_btn_disbale: false
      }))
    }
  }

  // Call forgot Password API
  const forgotPassword = (e) => {
    let { email } = state;
    emailInputRef.current.select();
    e.preventDefault();
    setState((state)=>({...state, loader: true }))
    const postforgotPassword = async () => {
        const data = await forGotPWD({email, is_crm: true});
        if(data?.detail){
            setState((state)=>({...state, forgot_success: true }))
        }else if(data?.errors?.email){
            message.error(data.errors.email, 10);
        }else{
            message.error("This email-id does not exist!", 10);
        }
        setState((state)=>({
            ...state,
            loader:false
        }))
    }
    postforgotPassword();
  }

  let { loader, email, is_btn_disbale, forgot_success } = state;
    return (
      <div id="wrapper">
        <div className="auth-page">
          <div className="authform">
            <div className="auth-container">
              <img src={dclogo} alt="" className="logo" />

              {!forgot_success ?
                <React.Fragment>
                  <h1>Forgot password?</h1>
                  <h5 className="mb-30">No problem! Sit, Stay, Enter your email address.<br />Password reset instructions will be sent to your email.</h5>
                  <form className="form-group" onSubmit={(e) => { forgotPassword(e) }}>
                    <div className="fitem">
                      <label>Email Address</label>
                      <input
                        type="text"
                        value={email}
                        name="email"
                        style={{borderColor:"#78909b"}}
                        ref={emailInputRef}
                        onChange={(e) => { onUserChange(e) }}
                        autoFocus
                        placeholder="Email Address" />
                    </div>
                    <div className="btn-grp flex ai-center">
                      <Link to="/" replace className="m-0">‹ Back to Login</Link>

                      <button
                        type="submit"
                        disabled={!is_btn_disbale}
                        className={!is_btn_disbale ? 'btn btn-primary disable' : 'btn btn-primary'} >Recover</button>
                    </div>
                  </form>
                </React.Fragment> :

                <React.Fragment>
                  <h1>Password recovery email sent.</h1>
                  <label className="mb-30">Please check your emails to proceed.</label>
                  <div className="btn-grp text-right">
                    <Link to="/" replace >
                      <button
                        type="submit"
                        className="btn btn-primary"
                      >Back to Login</button>
                    </Link>
                  </div>
                </React.Fragment>}
            </div>
          </div>
          <RightSlider />
        </div>
        {
          loader ?
            <div className='loader_wrapper'>
              <div className='loader07'></div>
            </div>
            : ''
        }
      </div>
    );
}

export default ForgotPassword;