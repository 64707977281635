import React, { useState } from "react";
import { ConfigProvider, Modal, Select, Spin, Empty, message } from "antd";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getPrices } from "../../../Action/Customer";
import { setNewInvoiceDataField, setAddItemModal, setInvoiceDataField, resetCurrentInvoiceData } from "../../../Store/invoiceDataSlice";
import {numberValidate, rateValidate} from "../../Common/utils";
import _, { map } from "lodash";

import * as yup from "yup";
const AddItemModal = () => {
  const dispatch = useDispatch();
  const {
    addItemModal,
    serviceOrderNumberList,
      
    newItemModal: {
      openSelect,
      repairCodeData,
      newItemModalData,
      repairCodeValue,
      codeOptions,
      repairCodeOptions,
    },
    newInvoice,
    newInvoice: {
      rowsData,
      serviceOrderUuid,
      taxRate,
    },
  } = useSelector((state) => state.invoice);
  const [selectLoading, setSelectLoading] = useState(true);
  const [stateone, setStateone] = useState({
    errors: {},
    rate: "",
    quantity_item:""
  });
  const validatefields = (e) => {
    setTimeout(() => {
      let { rate,quantity_item } = stateone;
      const schema = yup.object().shape({
      rate:yup.number().nullable(true).typeError("Rate is required").test( 'decimal-places', 'Please Enter Valid Rate(upto two decimal places are allowed)', (value) =>/^(-?\d+(\.\d{1,2})?)?$/.test(value)), 
      quantity_item: yup.number().moreThan(0, 'Quantity is required"').typeError("Quantity is required").nullable(true).test( 'decimal-places', 'Please Enter Valid Quantity(Positive or upto two decimal places are allowed)', (value) =>/^(\d+(\.\d{1,2})?)?$/.test(value)),
    });
      const data = { rate,quantity_item };
      let IsValid;
      let err_data = {};
      schema.isValid(data).then(function (valid) {
        IsValid = valid;
      });
      setTimeout(() => {
        if (IsValid) {
          handleTableData();
        } else {
          schema.validate(data, { abortEarly: false }).catch(function (err) {
            map(err.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setStateone({
              ...stateone,
              errors: err_data,
            });
            console.log("err_data:", err_data);
          });
        }
      }, 100);
    }, 300);
  };
  let { errors } = stateone;
  const renderRepairCodeSearch = () => {
    const onChange = (e) => {
      
      let val = '';
      let selectedCode = [];
      if (e) {
        val = e.trim().split(' ')[0];
        dispatch(setAddItemModal({ repairCodeValue: val }))
        selectedCode = repairCodeOptions?.filter((item) => {
          return item.repaircode === val
        });
      } else {
        dispatch(setAddItemModal({ repairCodeValue: '' }))
      }

      if (selectedCode?.length > 0) {
        dispatch(setAddItemModal({
          newItemModalData: {
            ...newItemModalData,
            repair_code: selectedCode[0].repaircode || "",
            description: selectedCode[0].description,
            fo_adjusted_price: Math.round((Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100) / 100,
            quantity: (1).toFixed(2),
            qb_account: selectedCode[0].qb_account || "",
            totallabor: selectedCode[0].totallabor,
            price: Math.round((Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100) / 100,
          }
        }))
        setStateone({
          errors: {},
          rate:   Math.round(
            (Number(selectedCode?.[0]?.fo_adjusted_price) + Number.EPSILON) * 100
          ) / 100,
          quantity_item: (1).toFixed(2),
        })
      } else {
        message.error("The item was not found, please verify the data.");
      }
    }
    
    const onSearch = (e) => {
      dispatch(setAddItemModal({ codeOptions: [] }))
      if (e !== "") {
        getOptions(e);
        let data = ((l) => ({ ...l, repair_code: e || '' }))
        dispatch(setAddItemModal({ newItemModalData: data, repairCodeValue: e }))
      }
    }
 
    return (
      <div className="searchable">
        <ConfigProvider renderEmpty={() => selectLoading ? <div className="repairCodesSpin"><Spin tip="Loading Repair Codes..." /></div> : <Empty />}>
          <Select
            showSearch
            allowClear
            autoClearSearchValue={false}
            value={repairCodeValue}
            placeholder="Search Repair ID's"
            onChange={onChange}
            onSearch={onSearch}
            onFocus={() => getOptions('')}
            options={codeOptions}
            open={openSelect}
            onDropdownVisibleChange={(visible) => dispatch(setAddItemModal({ openSelect: visible }))}
          >
          </Select>
        </ConfigProvider>
      </div>
    );
  }

  const getLineSearch = async (params) => {
    const response = await getPrices(params);
    return response;
  }

  async function getOptions(search) {
    const allowRepeatedRepairCodes = [
      "LABORSERVICE",
      "FLATRATESUBCONTRACT",
      "INCOME",
      "LABOR",
      "SERVICE-FLATRATE",
      "RETAIL-SUPPLIES",
      "SUBCONTRACT-INCOME"
    ]
    setSelectLoading(true)
    let field_office_uuid = newInvoice?.customer?.field_office?.uuid;
    const options = await getLineSearch({ search: search, field_office_uuid: field_office_uuid });
    const _options = options?.reduce((prev, current) => {
      //Validation to not repeat repair code
      const is_added = rowsData.some(i => i.repair_code === current.repaircode)
      if (!is_added || _.includes(allowRepeatedRepairCodes, current.repaircode)) {
        prev.push({
          value: current.repaircode + " - " + current.description,
          text: current.repaircode
        })
      }
      return prev;
    }, []);
    dispatch(setAddItemModal({ repairCodeOptions: options, codeOptions: _options, }))
    setSelectLoading(false)
  }

  const handleTableData = () => {
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid[0]);
    let taxData = amount(((newItemModalData.quantity * newItemModalData.fo_adjusted_price) * taxRate) / 100);
    let tempRowData = JSON.parse(JSON.stringify(rowsData));

    if (service?.service_order_name === "Retail Invoice") {
      tempRowData = [...tempRowData, {
        "type": "Retail Invoice",
        "repair_code": newItemModalData.repair_code,
        "description": newItemModalData.description,
        "is_taxable": true,
        "tax": taxData,
        "unit_cost": newItemModalData.fo_adjusted_price,
        "quantity": parseFloat(newItemModalData.quantity).toFixed(2),
        "price": (newItemModalData.quantity * newItemModalData.fo_adjusted_price).toFixed(2),
        "qb_account": newItemModalData.qb_account,
        "is_lineitem_invoiced": false,
        "index": Date.now() + Math.random(),
        "total": ((newItemModalData.quantity * newItemModalData.fo_adjusted_price) + parseFloat(taxData)),
        "isDefault": false,
        "taxRate": taxRate,
      }];
      dispatch(setAddItemModal({ repairCodeValue: '', codeOptions: [], newItemModalData: { price: 0, fo_adjusted_price: 0, quantity: '', description: '', include_labor: false } }))
      if (newItemModalData.include_labor) {
        tempRowData = [...tempRowData, createLabor(newItemModalData)];
        if (newItemModalData.include_bundle) {
          tempRowData = [...tempRowData, createBundle(newItemModalData)];
        }
      }
    }

    dispatch(setNewInvoiceDataField({ rowsData: tempRowData }));
    dispatch(setInvoiceDataField({ addItemModal: false }))
  }

  const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const createLabor = (lineItem) => {
    let taxData = amount(((lineItem.quantity * lineItem.totallabor) * taxRate) / 100);
    return {
      type: "Retail Invoice",
      work_order_uuid: serviceOrderUuid,
      repair_code: lineItem.repair_code + "-Labor",
      description: "LBR-" + lineItem.description,
      is_taxable: true,
      unit_cost: lineItem.totallabor ? lineItem.totallabor : 0,
      quantity: lineItem.quantity,
      price: (lineItem.quantity * lineItem.totallabor),
      total: (lineItem.quantity * lineItem.totallabor) + Number(taxData),
      qb_account: lineItem.qb_account,
      isDefault: false,
      tax: taxData,
      taxRate: taxRate,
      index: Date.now() + Math.random(),
    };
  }

  const createBundle = (lineItem) => {
    const item_unitCost = parseFloat(lineItem.fo_adjusted_price);
    const labor_unitCost = lineItem.totallabor ? lineItem.totallabor : 0;

    const item_total = lineItem.fo_adjusted_price * lineItem.quantity;
    const labor_total = (lineItem.quantity * lineItem.totallabor)
    let taxData = ((+taxRate / 100) * (lineItem.fo_adjusted_price * lineItem.quantity)).toFixed(2).toString();
    let taxDataLabor = amount(((lineItem.quantity * lineItem.totallabor) * taxRate) / 100);
    return {
      type: "Retail Invoice",
      work_order_uuid: serviceOrderUuid,
      repair_code: lineItem.repair_code,
      bundle_code: lineItem.repair_code,
      description: lineItem.description,
      is_taxable: true,
      unit_cost: item_unitCost + labor_unitCost,
      quantity: lineItem.quantity,
      price: item_total + labor_total,
      total: item_total + labor_total,
      qb_account: lineItem.qb_account,
      isDefault: false,
      taxRate: taxRate,
      tax: Number(taxData) + Number(taxDataLabor),
      index: Date.now() + Math.random(),
      disabled: true,
    }
  }

  const handleNewItemModalChange = (e, type) => {
    let item = {};
    if (type === "repairCode") {
      item = repairCodeData.find((code) => code.repaircode === e);
      dispatch(setAddItemModal({ newItemModalData: { ...item, quantity: '', price: item.fo_adjusted_price, include_labor: false } }))
    } else {
      const { id, value } = e.target;
      let price = newItemModalData.price;
      if (id === "quantity") {
        setStateone({...stateone,
          errors: {},
          quantity_item:value,
        });
          price = (newItemModalData.fo_adjusted_price * value).toFixed(2);
          dispatch(setAddItemModal({ newItemModalData: { ...newItemModalData, [id]: value, price } }))
      } else if (id === "fo_adjusted_price") {
        setStateone({...stateone,
          errors: {},
          rate: value,
        });
          price = (newItemModalData.quantity * parseFloat(value)).toFixed(2);
          dispatch(setAddItemModal({ newItemModalData: { ...newItemModalData, [id]: value, price } }))
      } else {
        dispatch(setAddItemModal({ newItemModalData: { ...newItemModalData, [id]: value, price } }))
      }
    }
  }

  const cancelStateData = () => {
    dispatch(setInvoiceDataField({ addItemModal: false }))
    dispatch(resetCurrentInvoiceData());
    setStateone({
      errors: {},
      rate:"",
      quantity_item:"",
    });  }

  return (
    <Modal
      visible={addItemModal}
      cancelText={"Cancel"}
      okText="Add Item"
      onOk={validatefields}
      onCancel={cancelStateData}
      className="add_line_modal"
      width={250}
    >
      <div className="form-group">
        <div className="fitem">
          <div>
            <h5 className="mt-30" >Add Item</h5>
            <label>Repair Code</label>
            {renderRepairCodeSearch()}
          </div>
          <div>
            <label>Description</label>
            <input type="text" id="description" placeholder="Description" value={newItemModalData.description} onChange={handleNewItemModalChange} />
          </div>
          <div>
            <label>Quantity</label>
            <input name="quantity_item" type="number" id="quantity" min="0.01" step="0.1" placeholder="1.00" value={newItemModalData.quantity} onChange={handleNewItemModalChange} /> {errors["quantity_item"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["quantity_item"]}
                  </label>
                ) : (
                  ""
                )}
          </div>
          <div>
            <label>Rate</label>
            <input name="rate" type="number" id="fo_adjusted_price" placeholder="0" value={newItemModalData.fo_adjusted_price}  onChange={handleNewItemModalChange} />   {errors["rate"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["rate"]}
                  </label>
                ) : (
                  ""
                )}
          </div>
          <div>
            <label>Price</label>
            <input type="number" id="price" placeholder="0" value={newItemModalData.price} disabled />
          </div>
          {newItemModalData?.totallabor !== null && newItemModalData?.totallabor > 0 ?
            <>
              <div className="mt-10">
                <input type="checkbox" checked={newItemModalData?.include_labor}
                  onChange={() => {
                    dispatch(setAddItemModal({
                      newItemModalData: {
                        ...newItemModalData,
                        include_labor: !newItemModalData?.include_labor, include_bundle: !newItemModalData?.include_labor
                      }
                    }))
                  }}
                  style={{ 'verticalAlign': 'middle' }} />
                <label className="mr-10" style={{ marginLeft: 5 }}>Include Labor Charge</label>
              </div>
              <div className="mt-10">
                <input type="checkbox" disabled={!newItemModalData?.include_labor}
                  checked={newItemModalData?.include_bundle}
                  onChange={() => {
                    dispatch(setAddItemModal({
                      newItemModalData: {
                        ...newItemModalData,
                        include_bundle: !newItemModalData?.include_bundle
                      }
                    }))
                  }}
                  style={{ 'verticalAlign': 'middle' }} />
                <label className="mr-10" style={{ marginLeft: 5 }}>Bundle Product</label>
              </div>
            </>
            : ''
          }
        </div>
      </div>
    </Modal>
  )
}

export default AddItemModal;
