import moment from "moment-timezone";

export const getStartEndPeriod = (period) => {
  switch (period) {
    case "today": {
      return {
        start_date: moment().startOf("day").format("YYYY-MM-DD"),
        end_date: moment().endOf("day").format("YYYY-MM-DD"),
      };
    }
    case "yesterday": {
      return {
        start_date: moment()
          .startOf("day")
          .subtract(1, "day")
          .format("YYYY-MM-DD"),
        end_date: moment().endOf("day").subtract(1, "day").format("YYYY-MM-DD"),
      };
    }
    case "week": {
      return {
        start_date: moment().startOf("week").format("YYYY-MM-DD"),
        end_date: moment().endOf("week").format("YYYY-MM-DD"),
      };
    }
    case "last_week": {
      return {
        start_date: moment()
          .startOf("week")
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
        end_date: moment()
          .endOf("week")
          .subtract(1, "week")
          .format("YYYY-MM-DD"),
      };
    }
    case "month": {
      return {
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
      };
    }
    case "last_month": {
      return {
        start_date: moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
        end_date: moment()
          .endOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"),
      };
    }
    case "year": {
      return {
        start_date: moment().startOf("year").format("YYYY-MM-DD"),
        end_date: moment().endOf("year").format("YYYY-MM-DD"),
      };
    }
    default: {
      return {};
    }
  }
};
