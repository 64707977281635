import { getDownloadCustomerReport } from "../../Action/Reports";

export const getPDFReport = async ({ report_type, filters }) => {
  const link = await getDownloadCustomerReport({
    report_type,
    is_export: "base64",
    ...filters,
  });
  let byteCharacters = atob(link.pdf_data);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  let file = new Blob([byteArray], { type: "application/pdf;base64" });
  let fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const getCSVReport = async ({ report_type, filters , exported_file_name = "demo"}) => {

  await getDownloadCustomerReport({
    report_type,
    is_export: "csv",
    ...filters,
  },exported_file_name);
  
};
