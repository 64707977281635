import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useCalendar } from "../../Hooks/Calendar.Hook";
import * as yup from "yup";
import { Col, Modal, Row, Select, message, Button } from "antd";
import { map, isEmpty, isEqual } from "lodash";
import { PlusOutlined } from "@ant-design/icons";

import "../../../Assets/css/addCustomerModal.css";
import { checkZipCode } from "../../../Action/Franchise";
import Loader from "../../../Components/Loader";
import { addPhoneNumber, addEmail } from "../../../Store/techJourneyDataSlice";
import { createLead, createLeadResidential,checkEmail, convertToCommercialCustomer,ConvertToResidentialCustomer } from "../../../Action/Customer";

import appData from "../../../Utils/constants";
import { addCustomer } from "../../../Store/customerSlice";
import {
  updateCustomerDetails,
  toggleCustomerModal,
  toggleBaseJobModal,
  showCustomerModal,
  toggleSameContactAddress,
  setFieldOfficeSelectedZip,
} from "../../../Store/techJourneyDataSlice";

import AddressField from "../../../Components/AddressField";
import { getFieldTechnician } from "../../../Action/Franchise";
import {
  addTechnicianArray
} from "../../../Store/techJourneyDataSlice";
import BaseJobRequirementsModal from "./BaseJobRequirement.Modal";

const AddCustomer = ({ openModal, setOpenModal, type, handleCustomerInfo }) => {
  const REGULAR_EXPRESSION_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [sameContactAddress, setSameContactAddress] = useState(true);
  const [zipFieldUUID,setZipFieldUUID] = useState('');
  const [title, setTitle] = useState("Add Customer");
  const [zipCodeCheck, setZipCodeCheck] = useState(false);
  const [baseJobModal,setBaseJobModal] =useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [serviceInfoData, setServiceInfoData] = useState({
    value: "",
    key: false,
    name: "zipcode",
    data: [],
  });
  const { Option } = Select;

  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const calendarState = useCalendar();

  const { techJourney } = techJourneyState;

  const initialStateValues = {
    first_name: "",
    zipcode: "",
    last_name: "",
    address: { address: "", city: "", state: "", zip_code: "" },
    billing_address: { address: "", city: "", state: "", zip_code: "" },
    phone_numbers: [],
    phone: "",
    phoneType: "mobile",
    emailaddress_set: [],
    email: "",
    company_name: "",
    company_type: "commercial",
    hear_about_us: "",
    lead_stage: "New",
    lead_stage_key: "new",
    outreach: "Call Center",
    outreach_key: "call_center",
  };
  const [state, setState] = useState(initialStateValues);

  useEffect(() => {
    if (type === "lead") {
      setTitle("Add Lead");
    }
    return ()=>{
      Modal.destroyAll();
    }
  }, []);

  useEffect(() => {
    if(!isEqual(state, initialStateValues)){
      dispatch(updateCustomerDetails({...state, sameContactAddress}))
      dispatch(setFieldOfficeSelectedZip(zipFieldUUID))
    } 
  }, [state]);

  const reset = () => {
    setState({
      zipcode: "",
      first_name: "",
      last_name: "",
      address: { address: "", city: "", state: "", zip_code: "" },
      billing_address: { address: "", city: "", state: "", zip_code: "" },
      phone_numbers: [],
      phone: "",
      phoneType: "mobile",
      emailaddress_set: [],
      email: "",
      company_name: "",
      company_type: "commercial",
      lead_stage: "New",
      lead_stage_key: "new",
      outreach: "Call Center",
      outreach_key: "call_center",
      hear_about_us: "",
    });
    setSameContactAddress(true);
    setErrors({});
  };

  const toggleModal = () => {
    Modal.destroyAll();
    setOpenModal(false);
    reset();
  };

  const handleZipCode = (e) => {
    setState({ ...state, zipcode: e.target.value });
    setServiceInfoData({ data: {} });
  };
  useEffect(() => {
    let { zipcode } = state;
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    if (zipcode?.length > 3) {
      setServiceInfoData({});
      const getZipCode = async () => {
        const data = await checkZipCode({
          addressable_zip_codes__contains: zipcode,
          close: false,
        });
        setZipFieldUUID(data?.results?.[0]?.uuid)
        dispatch(setFieldOfficeSelectedZip(data?.results?.[0]?.uuid))
        let uuid = data?.results?.[0]?.uuid ? data?.results?.[0]?.uuid: franchise_data?.data?.uuid; 
        if (data?.count > 0) {
          setZipCodeCheck(true);
          setServiceInfoData({
            value: zipcode,
            key: true,
            name: "zipcode",
            data: data?.results[0],
          });
          setTimeout(async () => {
            if (uuid) {
              const params = {"field_office__uuid": uuid}
              const { results: technicians } = await getFieldTechnician(params);
              if (technicians) {
                dispatch(addTechnicianArray(technicians));
              }
            }
          }, 100);
        } else {
          setZipCodeCheck(false);
          setServiceInfoData({ value: zipcode, key: false, name: "zipcode" });
        }
      };
      getZipCode();
    }
  }, [state.zipcode]);

  const changeServiceAddress = (e) => {
    setSameContactAddress(!sameContactAddress);
    dispatch(toggleSameContactAddress(!sameContactAddress));
  };

  const handleAddressChange = (value) => {
    if(sameContactAddress){
      setState({ ...state, address: value, billing_address: value });
    }
    else{
      setState({ ...state, address: value });
    }
  };

  const handleBillingAddressChange = (value) => {
    setState({ ...state, billing_address: value });
  };

  const onAddressChanged = (e, addr_ref) => {
    const { address } = state;
    if(sameContactAddress){
      setState({ ...state, address: address, billing_address: address });
    }
    else{
      setState({ ...state, address: address });
    }
  };

  useEffect(() => {
    if (sameContactAddress) {
      setState((s) => ({ ...s, billing_address: address }));
    } else {
      setState((s) => ({ ...s, billing_address:  { address: "", city: "", state: "", zip_code: "" } }));
    }
  }, [sameContactAddress]);

  const onBillingAddressChanged = (e, addr_ref) => {
    const { billing_address } = state;
    setState({ ...state, billing_address: billing_address });
  };

  const handleFirstName = (e) => {
    setState({ ...state, first_name: e.target.value });
  };
  const handleLastName = (e) => {
    setState({ ...state, last_name: e.target.value });
  };
  const handleCompanyType = (value) => {
    setState({ ...state, company_name: "", company_type: value });
  };
  const onTextChange = (e) => {
    if (onNumericInputChange(e) && e.target.name === "phone") {
      setState({ ...state, phone: e.target.value });
      dispatch(addPhoneNumber(e.target.value));
    }
    if (e.target.name === "email") {
      setState({ ...state, email: e.target.value });
    }
    if (e.target.name === "pool_size") {
      setState({ ...state, poolSize: e.target.value });
    }
    if (e.target.name === "note") {
      setState({ ...state, special_notes: e.target.value });
    }
    if (e.target.name === "company_name") {
      setState({ ...state, company_name: e.target.value });
    }
  };

  
  const onPhonePrimaryChanged = (index) => {
    const { phone_numbers } = state;
    const updatedPhoneNumbers = phone_numbers.map((obj, i) => {
      if (i === index) {
        return { ...obj, primary: true };
      } else {
        return { ...obj, primary: false };
      }
    });
    setState({ ...state, phone_numbers: updatedPhoneNumbers });
  };

  const onPrimaryEmailChanged = (index) => {
    const { emailaddress_set } = state;
    const updatedEmailAddresses = emailaddress_set.map((email, i) => {
      if (i === index) {
        return { ...email, primary: true };
      } else {
        return { ...email, primary: false };
      }
    });
    setState({...state,emailaddress_set: updatedEmailAddresses,email: updatedEmailAddresses[0]?.email || ""});
  };

  const onNumericInputChange = (e) => {
    const reg = /^-?\d*(\.\d*)?$/;
    return (
      (!isNaN(e.target.value) && reg.test(e.target.value)) ||
      e.target.value === "" ||
      e.target.value === "-"
    );
  };
  const onAdd = async (type) => {
    const { phone_numbers, phone, phoneType, emailaddress_set, email } = state;
    if (type === "phone") {
      let obj = {};
      if (phone_numbers.length === 0) {
        obj = {
          phone: phone,
          primary: true,
          type: phoneType,
          can_send_sms: false,
        };
      } else {
        obj = {
          phone: phone,
          primary: false,
          type: phoneType,
          can_send_sms: false,
        };
      }
      setState({ ...state, phone_numbers: [...phone_numbers, obj], phone: "" });
    }
    if (type === "email") {
      let obj = {};
      const emailValidation = await checkEmail({ email: email.toLowerCase() })
      if(!emailValidation?.already_exists){
        if (emailaddress_set.length === 0) {
          obj = { email: email.toLowerCase(), primary: true };
        } else {
          obj = { email: email.toLowerCase(), primary: false };
        }
        setState({
          ...state,
          emailaddress_set: [...emailaddress_set, obj],
          email: "",
        });
      }else{
        message.error('This email is already registered.')
      }
    }
  };

  const onChange = (row, name) => {
    const { phone_numbers, phone, phoneType, emailaddress_set, email } = state;
    let data = name === "phone" ? [...phone_numbers] : [...emailaddress_set];
    data.splice(row, 1);
    if (name === "phone" || name === "email") {
      const idx = data?.findIndex((x) => x.primary);
      if (idx <= -1 && !isEmpty(data)) {
        data[0].primary = true;
      }
    }
    if (name === "phone") {
      setState({ ...state, phone_numbers: data });
    } else {
      setState({ ...state, emailaddress_set: data });
    }
  };
  
  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    phone = phone?.replace(/\D/g, "");
    return (
      "(" +
      phone?.substr(0, 3) +
      ") " +
      phone?.substr(3, 3) +
      "-" +
      phone?.substr(6)
    );
  };

  const validateLead = () => {
    setConfirmLoading(true);
    const {
      zipcode,
      first_name,
      last_name,
      address,
      billing_address,
      phone_numbers,
      phone,
      phoneType,
      emailaddress_set,
      email,
      authorized_users,
      hear_about_us,
      company_name,
      company_type,
    } = state;

    const schema = yup.object().shape({
      first_name: yup.string().required("First name is required."),
      last_name: yup.string().required("Last name is required."),
      zipcode: yup.string().required("Zipcode is required."),
      company_type: yup.string().required("Company type is required."),
      company_name:
        company_type === "commercial"
          ? yup.string().required("company name is required.")
          : null,
      phone_numbers: yup
        .array(
          yup.object({
            phone: yup.string().required("Phone is required"),
          })
        )
        .min(1)
        .required("Phone number is required"),
      emailaddress_set: yup
        .array(
          yup.object({
            email: yup.string().required("Email is required"),
          })
        )
        .min(1)
        .required("Email Address is required"),
      lead_stage: yup.string().required("Lead stage is required."),
      hear_about_us: yup.string().required("Hear about us is required."),
      outreach: yup.string().required("Outreach is required."),
    });
    const data = {
      zipcode,
      first_name,
      last_name,
      company_type,
      company_name,
      address,
      billing_address,
      phone_numbers,
      emailaddress_set,
      lead_stage,
      hear_about_us,
      outreach,
    };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setErrors({});
        saveLead();
      })
      .catch((error) => {
        setConfirmLoading(false);
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log(error);
      });
  };

  const saveLead = async () => {
    const { company_type } = state;
    let emailaddress = state.emailaddress_set.map(x => Object.fromEntries(Object.entries(x).map(([key, value]) => [key, typeof value == 'string' ? value.toLowerCase() : value])));
    
    const data = {
      properties_in_community: [],
      preferred_contact_methods: [],
      company_name: state.company_name,
      no_of_properties: "",
      no_of_pools: "",
      notes: "",
      first_name: state.first_name,
      last_name: state.last_name,
      address: [state.address],
      billing_address: [state.billing_address],
      invoice_address: state.billing_address,
      phone_numbers: state.phone_numbers,
      emailaddress_set: emailaddress,
      zip_code: state.zipcode,
      lead_stage: state.lead_stage_key,
      hear_about_us: [state.hear_about_us],
      outreach: state.outreach_key,
      email: state.emailaddress_set[0].email.toLowerCase(),
      role: ["lead"],
    };
    setLoader(true);

    let res;
    let keys = [];
    if (company_type === "residential") {
      res = await createLeadResidential(data);
      keys = Object.keys(res);
      res.type = "residential";
    } else {
      res = await createLead(data);
      keys = Object.keys(res);
    }

    try {
      if (keys.length === 1) {
        message.error("This Email Is Already Registered.", 10);
      }
      else {
        message.success("Lead created successfully.", 5);
      } 
    } catch (error) {
      message.error("Something went wrong.",10);
      console.error(error);
    }
    setLoader(false);
    setConfirmLoading(false);
    toggleModal();
    reset();
  };

  const validate = () => {
    setConfirmLoading(true);
    
    const {
      first_name,
      last_name,
      address,
      billing_address,
      phone_numbers,
      emailaddress_set,
      company_name,
      // poolSize,
    } = state;
    const schema = yup.object().shape({
      zipcode: yup.string().required("Zipcode is required."),
      first_name: yup.string().required("first name is required."),
      last_name: yup.string().required("last name is required."),
      company_type: yup.string().required("company type is required."),
      // poolSize: yup.number().integer().required('pool size is required.'),
      company_name:
        company_type === "commercial"
          ? yup.string().required("company name is required.")
          : null,
      address: yup.object().shape({
        address: yup.string().required("address is required."),
        city: yup.string().required("city is required."),
        state: yup.string().required("state is required."),
        zip_code: yup.string().required("zipcode is required."),
      }),
      billing_address: yup.object().shape({
        address: yup.string().required("address is required."),
        city: yup.string().required("city is required."),
        state: yup.string().required("state is required."),
        zip_code: yup.string().required("zipcode is required."),
      }),
      phone_numbers: yup
        .array(
          yup.object({
            phone: yup.string().required("Phone is required"),
          })
        )
        .min(1)
        .required("Phone number is required")
        .label("Phone Numbers"),
        hear_about_us: yup.string().required("Hear about us is required."),
      emailaddress_set: yup
        .array(
          yup.object({
            email: yup.string().required("Email is required"),
          })
        )
        .min(1)
        .required("Email Address is required")
        .label("Email Addresses"),
    });
    const data = {
      zipcode,
      first_name,
      last_name,
      company_type,
      company_name,
      poolSize,
      address,
      billing_address,
      phone_numbers,
      hear_about_us,
      emailaddress_set,
    };
    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        setErrors({});
        reset();
        Modal.destroyAll();
        setOpenModal(false);
        dispatch(toggleCustomerModal(false));
        dispatch(toggleBaseJobModal(true));
        setConfirmLoading(false);
      })
      .catch((error) => {
        setConfirmLoading(false);
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log(error);
      });
  };

  const renderLeadStage = () => {
    return [
      <Option
        key="new"
        value="New"
      ></Option>,
      <Option
        key="contacted"
        value="Contacted"
      ></Option>,
      <Option
        key="interested"
        value="Interested"
      ></Option>,
    ];
  };
  const renderPlanform = () => {
    return [
      <Option
        key="Online Search"
        value="Online Search"
      ></Option>,
      <Option
        key="Social Media"
        value="Social Media"
      ></Option>,
      // <Option key='Direct Email' value='Direct Email' ></Option>,
      <Option
        key="Referral"
        value="Referral"
      ></Option>,
      <Option
        key="Truck"
        value="Truck"
      ></Option>,
      <Option
        key="Post card"
        value="Post card"
      ></Option>,
      <Option
        key="Other"
        value="Other"
      ></Option>,
    ];
  };
  const renderOutReach = () => {
    return [
      <Option
        key="Franchise"
        value="Franchise"
      ></Option>,
      <Option
        key="call_center"
        value="Call Center"
      ></Option>,
    ];
  };

  const onSelectChange = (e, name, key) => {
    setState({
      ...state,
      [`${name}_key`]: key.key,
      [name]: e,
    });
  };

  const {
    zipcode,
    first_name,
    last_name,
    address,
    billing_address,
    phone_numbers,
    phone,
    phoneType,
    emailaddress_set,
    email,
    authorized_users,
    hear_about_us,
    poolSize,
    special_notes,
    company_name,
    company_type,
    lead_stage,
    outreach,
  } = state;

  return (
    <>
      <Row>
        <Col span={24}>
          <Modal
            title={<h3 style={{ marginBottom: "0px" }}>{title}</h3>}
            open={openModal}
            closable={false}
            className="addNewCustomerModal"
            footer={
              (type === "customer") ? [

                <Button
                  key="cancel"
                  className="btn btn-close"
                  onClick={() => {toggleModal(); Modal.destroyAll(); }}
                  disabled={confirmLoading}
                >
                  Cancel
                </Button>,
                <Button
                  key="upload"
                  className="btn btn-bdr"
                  onClick={() => validate()}
                  loading={confirmLoading}
                >
                  Save Customer
                </Button>,
              ]
                :
                [
                  <Button
                    key="cancel"
                    className="btn btn-close"
                    onClick={() => {toggleModal(); Modal.destroyAll(); }}
                    disabled={confirmLoading}
                  >
                    Cancel
                  </Button>,
                  <Button
                    key="upload"
                    className="btn btn-border"
                    style={{ backgroundColor: "white" }}
                    onClick={() => validateLead()}
                    loading={confirmLoading}
                  >
                    Save Lead
                  </Button>,
                  <Button
                    key="uploadAdd"
                    className="btn btn-bdr"
                    onClick={() => validate()}
                    loading={confirmLoading}
                  >
                    Convert To Customer
                  </Button>,
                ]
            }
          >
            <div className="form-group">
              {
                <div className="form-group">
                  <div className="fitem">
                    <label>Zipcode <sup>*</sup> </label>
                    <input
                      type="text"
                      placeholder="Zipcode"
                      name="zipcode"
                      max={5}
                      maxLength={5}
                      value={zipcode}
                      onChange={handleZipCode}
                    />
                    {errors?.["zipcode"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.["zipcode"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  {zipcode ? (
                    zipCodeCheck === true ? (
                      <div className="greenbox mb-30">
                        <b className="d-block">We cover this area.</b>
                        <b className="d-block">{`This is under the ${serviceInfoData.data?.name} Franchise.`}</b>
                      </div>
                    ) : (
                      <div className="dangerbox mb-30">
                        <b className="d-block">
                          We don’t currently have any franchises covering this
                          area. Save their email to notify them if we move into
                          this area.{" "}
                        </b>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                </div>
              }

              <Row>
                <div className="half-fitem fitem">
                  <Col span={11.5}>
                    <label>
                      First Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      value={first_name}
                      onChange={handleFirstName}
                    />
                    {errors["first_name"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors["first_name"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col span={1}></Col>
                  <Col span={11.5}>
                    <label>
                      Last Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      value={last_name}
                      onChange={handleLastName}
                    />
                    {errors["last_name"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors["last_name"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </Col>
                </div>
              </Row>
              <div>
                <label>
                  Customer Type<sup>*</sup>
                </label>
                <Select
                  showSearch
                  showArrow={true}
                  filterOption={false}
                  // notFoundContent={null}
                  placeholder="Customer Type"
                  name="company_type"
                  value={company_type}
                  onChange={(e) => handleCompanyType(e)}
                  options={appData.customerType}
                />
                {errors["company_type"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors["company_type"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              {company_type === "commercial" && (
                <div>
                  <label>
                    Company Name<sup>*</sup>
                  </label>
                  <input
                    id="customField"
                    enabled="true"
                    value={company_name}
                    placeholder="Company Name"
                    type="text"
                    name="company_name"
                    autoComplete="off"
                    onChange={onTextChange}
                  />
                  {errors["company_name"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors["company_name"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div>
                <label>
                  Service Address<sup>*</sup>
                </label>
                <AddressField
                  handleAddressChange={handleAddressChange}
                  onAddressChanged={onAddressChanged}
                  onAddressChange={onAddressChanged}
                  errors={errors}
                  data={address}
                  nolabel={true}
                  fieldName={"google_address1"}
                />
              </div>
              <Row>
                <Col span={24}>
                  <label>
                    Billing Address<sup>*</sup>
                  </label>
                  <div
                    className="fitem-check"
                    key={`phoneNumber`}
                  >
                    <input
                      type="checkbox"
                      className="fitem-ck-input"
                      id={`checkboxPhone`}
                      name={`checkboxPhone`}
                      checked={sameContactAddress}
                      onChange={changeServiceAddress}
                    />
                    <label
                      htmlFor={`checkboxPhone`}
                      className="fitem-ck-txt black"
                    >
                      Same as Service Address
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <AddressField
                    handleAddressChange={handleBillingAddressChange}
                    onAddressChanged={onBillingAddressChanged}
                    onAddressChange={onBillingAddressChanged}
                    errors={errors}
                    data={billing_address}
                    // nolabel={true}
                    fieldName={"google_address2"}
                  />
                </Col>
              </Row>
              <div>
                <label>
                  Phone Numbers<sup>*</sup>
                </label>
                {map(phone_numbers, (item, index) => (
                  <div
                    className="fitem-check"
                    key={`phone_numbers${index}`}
                  >
                    <input
                      type="checkbox"
                      className="fitem-ck-input"
                      id={`checkboxPhone${index}`}
                      name={`checkboxPhone${index}`}
                      checked={item?.primary}
                      onChange={(e) => onPhonePrimaryChanged(index)}
                    />
                    <label
                      htmlFor={`checkboxPhone${index}`}
                      className="fitem-ck-txt black"
                    >{`${formatPhoneNumber(item.phone)} ${
                      item.primary ? "(Primary)" : ""
                    }`}</label>
                    <a
                      className="red float-right"
                      onClick={(e) => onChange(index, "phone")}
                    >
                      <u>Remove</u>
                    </a>
                  </div>
                ))}
                <div className="fitem fitem-rinner mt-10 flex">
                  <input
                    name="phone"
                    type="text"
                    placeholder="Add New Phone"
                    value={phone}
                    maxLength={"10"}
                    max={"10"}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                          // invalid character, prevent input
                          e.preventDefault();
                      }
                    }}
                    onChange={onTextChange}
                  />
                  <button
                    className={`btn fitem-ricon  ${
                      phone.length !== 10 ? "disabled" : "btn-warning"
                    }`}
                    disabled={phone.length !== 10}
                    onClick={(e) => onAdd("phone")}
                  >
                    <PlusOutlined />
                  </button>
                </div>
                {errors?.[`phone_numbers`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`phone_numbers`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div>
                <label>
                  Email Addresses<sup>*</sup>
                </label>
                <div className="inline-check-list gcheck">
                  {map(emailaddress_set, (item, index) =>
                    item?.email && !item.email.includes("doody.com") ? (
                      <div
                        className="fitem-check"
                        key={`email${index}`}
                      >
                        <input
                          type="checkbox"
                          className="fitem-ck-input"
                          id={`checkboxEmail${index}`}
                          name={`checkboxEmail${index}`}
                          checked={item?.primary}
                          onChange={(e) => onPrimaryEmailChanged(index)}
                        />
                        <label
                          htmlFor={`checkboxEmail${index}`}
                          className="fitem-ck-txt black"
                        >{`${item?.email} ${
                          item?.primary ? "(Primary)" : ""
                        }`}</label>
                        <a
                          className="red float-right"
                          onClick={(e) => onChange(index, "email")}
                        >
                          <u>Remove</u>
                        </a>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
                <div className="fitem fitem-rinner mt-10 flex">
                  <input
                    name="email"
                    type="text"
                    placeholder="Add New Email Address"
                    value={email}
                    onChange={onTextChange}
                  />
                  <button
                    className={`btn fitem-ricon ${
                      !REGULAR_EXPRESSION_EMAIL.test(email)
                        ? "disabled"
                        : "btn-warning"
                    }`}
                    disabled={!REGULAR_EXPRESSION_EMAIL.test(email)}
                    onClick={(e) => onAdd("email")}
                  >
                    <PlusOutlined />
                  </button>
                </div>
                {errors?.[`emailaddress_set`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`emailaddress_set`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              {/*div>
                  <div>
                    <label>Pool Size<sup>*</sup></label>
                    <input
                      id="customField"
                      enabled
                      value={poolSize}
                      type="text"
                      name="pool_size"
                      placeholder="Pool Size"
                      autoComplete="off"
                      onChange={onTextChange}
                    />
                    {errors?.[`poolSize`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`poolSize`]}</label> : ''}
                  </div>
                  <div>
                    <label>Special Note</label>
                    <textarea
                      id="special_notes"
                      rows="5"
                      name="note"
                      placeholder="Add Notes"
                      cols="48"
                      autoComplete="off"
                      value={special_notes}
                      onChange={onTextChange}
                    />
                  </div>
                </div>*/}

              {(type === "lead") &&
                <div>
                  <div className="fitem">
                    <label>Lead Stage</label>
                    <Select
                      showSearch
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      placeholder="Select"
                      value={lead_stage}
                      onChange={(e, key) => { onSelectChange(e, 'lead_stage', key) }}
                    // onSearch={(e) => { getUserListData({ 'search': e }) }}
                    >
                      {renderLeadStage()}
                    </Select>
                    {/* {errors['new_franchise.owner_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.owner_uuid']}</label> : ''} */}
                  </div>
                </div>
              }  
                  <div className="fitem">
                    <label>How did you hear about us? <sup>*</sup></label>
                    <Select
                      showSearch
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      placeholder="Select"
                      value={hear_about_us}
                      onChange={(e, key) => { onSelectChange(e, 'hear_about_us', key) }}
                    // onSearch={(e) => { getUserListData({ 'search': e }) }}
                    >
                      {renderPlanform()}
                    </Select>
                    {errors['hear_about_us'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['hear_about_us']}</label> : ''}
                  </div>
                  <div className="fitem">
                    <label>Outreach</label>
                    <Select
                      showSearch
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      placeholder="Select"
                      value={outreach}
                      onChange={(e, key) => { onSelectChange(e, 'outreach', key) }}
                    // onSearch={(e) => { getUserListData({ 'search': e }) }}
                    >
                      {renderOutReach()}
                    </Select>
                    {/* {errors['new_franchise.owner_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.owner_uuid']}</label> : ''} */}
                  </div>
              
            </div>
          </Modal>
        </Col>
      </Row>
      {loader && <Loader />}
    </>
  );
};

export default AddCustomer;
