import React, { useEffect, useState } from "react";
import { getproposalsetup, proposalpostapi } from "../../Action/Franchise";
import Loader from "../../Components/Loader";
import { message } from "antd";

const ProposalSetUpFranchise = (props) => {
  const { franchise_data } = props;
  const [state, setState] = useState({
    updateEmailNotes: "",
    updateServiceAgree: "",
  });
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getProposalData();
  }, []);

  const getProposalData = async () => {
    setLoader(true);
    const response = await getproposalsetup(franchise_data?.uuid);
    setState({
      updateEmailNotes: response?.email_notes,
      updateServiceAgree: response?.service_agreement_notes,
    });
    setLoader(false);
  };

  const handleemailnoteschange = (e) => {
    setState((each) => ({
      ...each,
      updateEmailNotes: e.target.value,
    }));
  };

  const handleservicenoteschange = (e) => {
    setState((each) => ({
      ...each,
      updateServiceAgree: e.target.value,
    }));
  };

  const savenotefranchiseemail = async () => {
    const params1 = {
      field_office_uuid: franchise_data?.uuid,
      email_notes: state?.updateEmailNotes ? state?.updateEmailNotes : "",
    };
    setLoader(true);
    const results1 = await proposalpostapi(params1);
    message.success("updated successfully.")
    setState((s) => ({
      ...s,
      updateEmailNotes: results1?.result?.email_notes,
    }));
    setLoader(false);
  };
  const saveNoteFranchiseService = async () => {
    const params1 = {
      field_office_uuid: franchise_data?.uuid,
      service_agreement_notes: state?.updateServiceAgree
        ? state?.updateServiceAgree
        : "",
    };
    setLoader(true);
    const results1 = await proposalpostapi(params1);
    message.success("updated successfully")
    setState((s) => ({
      ...s,
      updateServiceAgree: results1?.result?.service_agreement_notes,
    }));
    setLoader(false);
  };
  return (
    <>
      <div className="franchise_proposal">
        {loader && <Loader />}
        <div className="fra_pro">
          <div className="btn-mail-css">
            <b>
              <h4 style={{paddingTop:"5px"}}>Email Notes</h4>
            </b>
          </div>
          <div className="fitem green-textarea " style={{ width: "93%" , height: "100%", background: "#ffffff", borderBottom: "4px solid #e1e1e5" }}>
            <textarea
              placeholder={"Start typing to add email notes..."}
              value={state?.updateEmailNotes}
              onChange={(e) => handleemailnoteschange(e)}
              rows={12}
              style={{ height: "85%" }}
            ></textarea>
            <button
              style={{ cursor: "pointer" }}
              className="btn-fra-pro"
              onClick={savenotefranchiseemail}
            >
              Save Note
            </button>
          </div>
        </div>
        <div className="fra_pro_one">
          <div className="css-pro-fra">
            <b>
              <h4 style={{paddingTop:"5px"}}>Service Agreement</h4>
            </b>
          </div>
          <div className="fitem green-textarea" style={{ width: "96%" , height: "100%", background: "#ffffff", borderBottom: "4px solid #e1e1e5" }}>
            <textarea
              placeholder={"Start typing to add service agreement..."}
              value={state?.updateServiceAgree}
              onChange={(e) => handleservicenoteschange(e)}
              rows={12}
              style={{ height: "85%" }}
            ></textarea>
            <button
              style={{ cursor: "pointer" }}
              className="btn-fra-pro"
              onClick={saveNoteFranchiseService}
            >
              Save Note
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProposalSetUpFranchise;
