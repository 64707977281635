import React from "react";
import moment from "moment-timezone";
import { formatToCurrency } from "../../Utils/formatToCurrency";
import { EyeOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

class DataTable {

  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date)
  }



  //User List
  CatalogUser = (handleViewModal, handleEditModal, handleDeleteModal) => {
    const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};

    let is_lead_tech = false;
    Object.entries(userInfo?.user?.technician_permissions).forEach((element) => {
      if (element[1]) {
        //If the user has at least one permission as true, is teach lead
        is_lead_tech = true;
      }
    });
    //Verify if the user has franchise_owner role due to dual role users
    const is_fo = userInfo?.user?.user_roles.find(
      (i) => i.name === "franchise_owner" || i.name === "operations_mgr"
    );

    const role = is_fo
      ? "franchise_owner"
      : userInfo?.user?.is_lead_technician && is_lead_tech
        ? "lead_tech"
        : userInfo?.user?.user_roles[0]?.name;

    const UTCToLocal = (date) => {
      let stillUtc = moment.utc(date).toDate();
      return moment(stillUtc).local();
    }

    let userColumns = [
      {
        title: "Part Type",
        dataIndex: "parttype",
        key: "parttype",
        sorter: true,
        className: "tname",
      },
      {
        title: "Product Code",
        dataIndex: "productcode",
        key: "productcode",
        className: "tproductcode",
        sorter: true,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        className: "temail",
        sorter: true,
        render: (text) => <span className="type">{text}</span>,
      },
      {
        title: "ASP Cost",
        dataIndex: "local_price",
        key: "local_price",
        className: "tgrp lfbdr",
        sorter: true,
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              record?.local_price
            )}`}</b>
          </span>
        ),
      },
      {
        title: "Retail Price",
        dataIndex: "retail_price",
        key: "retail_price",
        sorter: true,
        className: "trole",
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              (record?.retail_price)?.toFixed(2)
            )}`}</b>
          </span>
        ),
      },
      {
        title: "Retail Price(With Tax)",
        dataIndex: "retail_total_price_with_tax",
        key: "retail_total_price_with_tax",
        className: "tprofile",
        sorter: true,
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              (record?.retail_total_price_with_tax)?.toFixed(2)
            )}`}</b>
          </span>
        ),
      },
      {
        title: "Last Updated Date",
        dataIndex: "modified_at",
        key: "modified_at",
        className: "tdate",
        sorter: true,
        render: (text) => (
          <span>
            {text ? UTCToLocal(text).format("MMM DD, YYYY") : ""}
          </span>
        )
      },
      {
        title: "Last Updated By",
        dataIndex: "last_updated_by",
        key: "last_updated_by",
        sorter: true,
        className: "tphnum",
      },
      {
        title: "View",
        dataIndex: 'View',
        className: "tstatus",
        render: (text, rowData) => <EyeOutlined onClick={() => {
          handleViewModal(rowData)
        }} />,
      },
      {
        title: "Edit",
        dataIndex: "edit",
        render: (text, rowData) => <EditOutlined onClick={() => {
          handleEditModal(rowData)
        }}
          className={(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? "" : "disabled") : ""}
          disabled={(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? false : true) : false} />,
        className: "tstatus",
      },
      {
        title: "Delete",
        dataIndex: 'Delete',
        className: "tstatus",
        render: (text, rowData) => <DeleteOutlined onClick={() => {
          handleDeleteModal(rowData)
        }}
          className={`delete-btn-color ${(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? "" : "disabled") : ""}`} 
          disabled={(role === "field_tech" || role === "lead_tech") ? (userInfo?.user?.technician_permissions?.enable_price_catalog ? false : true) : false} />
      }
    ];

    return userColumns;
  }
}
export default new DataTable();