import React from "react";
import { DatePicker, Radio } from "antd";
import moment from "moment-timezone";
import { useReport } from "../../Hooks/Report.Hook";
import FranchiseSelector from "../Filters/Franchise.Selector";
import PeriodSelector from "../Filters/Period.Selector";
import { getStartEndPeriod } from "../../Common/getDates";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';
import TechnicianSelector from "../Filters/Technician.Selector";

const ChemicalSidebar = () => {
  const {
    franchise,
    serviceDate,
    setServiceDate,
    setFranchise,
    setServiceDatePeriod,
    reportType,
    groupBy,
    setReportType,
    setGroupBy,
    technician,
    setTechnician
  } = useReport();

  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
    setTechnician(['all']);
  };
  const handleTechnicianChange = (e, type) => {
    let selection = e;

    if (e.length > 1 && e[0] ==="all")
    {
      selection = e.filter(item => item !== "all");
    }
    else if (e.length === 0 || (e.length > 0 && e.includes('all')))
    {
      selection = ["all"];
    }
    setTechnician(selection);
  };
  const handleServiceDateChange = (e) => {
    setServiceDate(e);
    setServiceDatePeriod(getStartEndPeriod(e));
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const handleCustomDateChange = (e) => {
    setServiceDatePeriod({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
  };

  const handleRadioChange = (e) => {
    setReportType(e.target.value);
  };
  const handleGroupByRadioChange = (e) => {
    setGroupBy(e.target.value);
  };
  return (
    <>
      <div className="fitem">
        <label>
          <b>Franchise</b>
        </label>
        <FranchiseSelector
          handleChange={handleFranchiseChange}
          defaultValue={franchise}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Service Date</b>
        </label>
        <PeriodSelector
          handleChange={handleServiceDateChange}
          defaultValue={serviceDate === "day" ? "Today" : serviceDate}
        />
      </div>
      {serviceDate === "custom" ? (
        <div className="fitem">
          <label>
            <b>Service Dates (Range)</b>
          </label>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            onChange={handleCustomDateChange}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="fitem">
        <label>
          <b>Technician</b>
        </label>
        <TechnicianSelector
          handleChange={handleTechnicianChange}
          defaultValue={technician}
          />
      </div>
      <div className="fitem">
        <label>
          <b>Report Type</b>
        </label>
        <div style={{ width: "100%" }}>
          <Radio.Group name="radiogroup" defaultValue={reportType}>
            <Radio value={"detail"} onChange={handleRadioChange}>
              Detail
            </Radio>
            <Radio value={"summary"} onChange={handleRadioChange}>
              Summary
            </Radio>
          </Radio.Group>
        </div>
      </div>
     
      {(reportType === "summary")?
        <div className="fitem">
          <label>
            <b>Group By</b>
          </label>
          <div style={{ width: "100%" }}>
            <Radio.Group name="radiogroup" defaultValue={groupBy}>
              <Radio value={"customer"} onChange={handleGroupByRadioChange}>
                Customer
              </Radio>
              <Radio value={"technician"} onChange={handleGroupByRadioChange}>
                Technician
              </Radio>
            </Radio.Group>
          </div>
        </div>
      :
        <></>
    }
    
    </>
  );
};

export default ChemicalSidebar;
