import React, {useEffect} from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { Col, Row } from "antd";
import {addTaskDescriptionAction} from "../../../../Store/technicianTaskDataSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";

const TaskDescription = () => {

    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.technicianTaskJourney);

    const updateTaskDescription = (event) => {
        dispatch(addTaskDescriptionAction(event.target.value))
    };
    useEffect(() => {        
        if(tasks?.showTaskFromEdit === true){
            dispatch(addTaskDescriptionAction(tasks?.selectedTaskData?.task_description))
        }
    }, []);
    return (
        <Row>
            <Col xl={23} xs={24}>
                <div className="jobPopDateTime">
                    <label>
                        Task Description
                    </label>
                    <textarea
                        placeholder="Task Description"
                        name="message"
                        onChange={(e) => {
                            updateTaskDescription(e);
                        }}
                        value={tasks?.taskDescription}
                        rows="4"
                    ></textarea>
                </div>
            </Col>
        </Row>
    );
};

export default TaskDescription;
