import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Provider } from "react-redux";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Col, message, Modal, Row, TimePicker, Button } from "antd";
import { map, isEmpty } from "lodash";

import "../../../Assets/css/addNewCustomerBaseJobModal.css";
import store from "../../../Store/store";
import { useCalendar } from "../../Hooks/Calendar.Hook";
import {resetValues} from "../../../Store/serviceOrderDetailDataSlice";

import {
  addPoolSize,
  addPoolName,
  updatePrice,
  addRenovationNotes,
  addConstructionNotes,
  addSpecialNotes,
  toggleBaseJobModal,
  resetState,
  showLoader,
  updateFrequency,
  selectTime
} from "../../../Store/techJourneyDataSlice";
import { validateBaseJob } from "../../helper/validationHelper";
import {
  createCustomer,
  createCustomerTechJourney,
  addBillingInfo,  
} from "../../../Action/Customer";

import Loader from "../../../Components/Loader";
import ServiceSummary from "../../../Customer/Residential/NewCustomer/ServiceSummary/ServiceSummary";
import SelectService from "./SubComponents/ServiceTypes";
import RateTypes from "./SubComponents/RateTypes";
import ServiceRequired from "./SubComponents/ServiceRequired";
import PoolTypes from "./SubComponents/PoolTypes";
import CleaningFrequency from "./SubComponents/CleaningFrequency";
import Notes from "./SubComponents/Notes";
import PreferredTechnician from "./SubComponents/PreferredTechnician";
import InvoicePreferences from "./SubComponents/InvoicePreferences";
import ContactMethod from "./SubComponents/ContactMethod";
import PaymentInformation from "./SubComponents/PaymentInformation";
import BillingInfo from "../../../Customer/Common/BillingInfo";
import CommunityInformation from "../Modals/CommunityInformation.Modal";
import PreferredDateTime from "./SubComponents/PreferredDateTime";
import { EventBus } from "../../../Components/EventBus/eventBus";
import AddDurationField from "../../../Calendar/Components/Modals/SubComponents/AddDurationField";
import AddReferenceField from "../../../Calendar/Components/Modals/SubComponents/AddReferenceField";

const BaseJobRequirementsModal = ({
  inputData = {
    uuid: "",
    zip_code: "",
    first_name: "",
    last_name: "",
    address: {},
    phone_numbers: [],
    phone: "",
    emailaddress_set: [],
    payment_method: "",
    email: "",
    authorized_users: [],
    hear_about_us: [],
    outreach:'',
  },
  openModal,
  setOpenModal,
}) => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);
  const {
    franchise: { value: userID },
    client_uuid,
  } = useCalendar();

  const authUser = localStorage.getItem("authUser");
  const [associatedFieldOffice, setAssociatedFieldOffice] = useState(null);
  const [errors, setErrors] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    const timeOutID = setTimeout(async () => {
      const authUser = await JSON.parse(
        localStorage.getItem("authUser") || null
      );
      setAssociatedFieldOffice(
        authUser?.user?.associated_field_offices[0] || null
      );
    }, 100);
    return () => window.clearTimeout(timeOutID);
  }, []);
  const [data, setData] = useState({});

  const [serviceInfo, setServiceInfo] = useState({
    payment_method: {
      value: "",
    },
    cardSelect: {
      value: "",
    },
    card_holder_name: {
      value: "",
    },
    charge_invoice_card: {
      value: true,
    },
  });

  const setServiceInfoData = (data) => {
    let obj = serviceInfo;
    obj[data.name] = data;
    setServiceInfo(obj);
  };

  const validateBaseJobReq = React.useRef(null);

  const setPriceDetailData = (data) => {
    dispatch(updatePrice(data().price));
  };

  const updateNotes = (updatedNoteText, serviceTypeValue) => {
    switch (serviceTypeValue) {
      case "specialnotes":
        dispatch(addSpecialNotes(updatedNoteText));
        break;
      case "renovation":
        dispatch(addRenovationNotes(updatedNoteText));
        break;
      case "construction":
        dispatch(addConstructionNotes(updatedNoteText));
        break;
      default:
        return false;
    }
  };

  const { selectedServiceType } = techJourneyState;

  return (
    <>
      <Provider store={store}>
        <Row>
          <Col span={24}>
            <Modal
              title=""
              visible={openModal}
              // onCancel={() => setOpenModal(false)}
              className="addNewCustomerBaseJobModal"
              closable={false}
              footer={[
                <Button
                  key="cancel"
                  className="btn btn-brand btn-bdr"
                  disabled={confirmLoading}
                  onClick={() => {
                    dispatch(resetState());
                    Modal.destroyAll();
                    dispatch(toggleBaseJobModal(false));
                    setServiceInfo({
                      payment_method: {
                        value: "",
                      },
                      cardSelect: {
                        value: "",
                      },
                      card_holder_name: {
                        value: "",
                      },
                      charge_invoice_card: {
                        value: true,
                      },
                    });
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  key="upload"
                  className={`btn btn-brand btn-bdr`}
                  loading={confirmLoading}
                  onClick={async () => {
                    setConfirmLoading(true);
                    validateBaseJob(
                      techJourneyState
                    ).then(async (response)=>{
                      setErrors({});
                      const {
                        selectedServiceType,
                        selectedWorkType,
                        selectedWorkSubType,
                        frequency: frequency_settings,
                        selectedPoolType,
                        preferred_start_date,
                        schedule_time_range,
                        poolSize,
                        contactMethods,
                        price,
                        customerDetails: {
                          first_name,
                          last_name,
                          company_name,
                          emailaddress_set,
                          phone_numbers,
                          company_type: client_type,
                          address,
                          billing_address,
                          hear_about_us,
                          sameContactAddress,
                        },
                        specialNotes,
                        communityDetails: {
                          no_of_properties,
                          no_of_pools,
                          notes,
                          preferred_contact_methods,
                        },
                        invoicePreferences: {
                          is_email_invoice,
                          is_print_and_mail_invoices,
                        },
                        preferredTechnician,
                        poolName,
                        time
                      } = techJourneyState;
                      
                      if (response?.status === "success") {
                        if ((serviceInfo?.payment_method?.value === "card" ||
                          serviceInfo?.payment_method?.value === "ACH Payments") &&
                          serviceInfo?.cardSelect?.value === "")
                        { 
                          message.error(`Please add ${serviceInfo?.payment_method?.value === "card" ? "Credit Card"  : "ACH Payment"}`, 10);
                        }
                        else{
                          dispatch(showLoader(true));
                          const param = {
                            residential: {
                              lead: {
                                field_office_uuid: techJourneyState.fieldOfficeSelectedZip,
                                // field_office_uuid:"82373d31-0822-3d15-80d6-8d893170a852",
                                service_address: {
                                  ...address,
                                },
                                billing_address: {
                                  ...billing_address,
                                },
                                is_billing_address_same: sameContactAddress,
                                first_name,
                                last_name,
                                emailaddress_set,
                                email: emailaddress_set[0].email.toLowerCase(),
                                phone_numbers,
                                hear_about_us: [techJourneyState.customerDetails.hear_about_us],
                                outreach:techJourneyState.customerDetails.outreach_key,
                                is_active: true,
                                status: "active",
                                client_type,
                                special_notes:
                                  client_type === "residential" ? specialNotes : "",
                              },
                              serviceOrder: {
                                uuid: userID,
                                field_office_uuid: techJourneyState.fieldOfficeSelectedZip,
                                billing_address: billing_address,
                                is_billing_address_same: sameContactAddress,
                                is_service: selectedServiceType.name === "service",
                                proposal_date: moment().format("YYYY-MM-DD"),
                                contract_date: moment().format("YYYY-MM-DD"),
                                preferred_start_date: preferred_start_date,
                                is_terms_accepted: true,
                                schedule_time_range: schedule_time_range,
                                service_type_uuid:
                                  selectedServiceType?.name === "service"
                                    ? selectedWorkSubType !== null
                                      ? selectedWorkSubType?.uuid
                                      : selectedWorkType !== null
                                      ? selectedWorkType.uuid
                                      : selectedServiceType?.uuid
                                    : selectedServiceType.uuid,
                                pool_type_uuid: selectedPoolType?.uuid || null,
                                pool_size: poolSize || null,
                                preferred_contact_methods: Object.values(
                                  contactMethods
                                )
                                  .filter((item) => item.isChecked === true)
                                  .map((item) => item.key),
                                payment_method:
                                  serviceInfo.payment_method?.value || "cash",

                                is_flat_rate:
                                  selectedWorkType?.uuid === "flat_rate",
                                price,
                                service_price: price,
                                client_type,
                                email:
                                  emailaddress_set[0]?.email.toLowerCase(),
                                emailaddress_set,
                                zip_code:
                                  authUser?.user?.associated_field_offices[0]
                                    ?.addressable_zip_codes[0],
                                // need to implement these
                                properties_in_community: null,
                                company_name:
                                  client_type === "commercial"
                                    ? company_name
                                    : "asp",
                                no_of_properties: no_of_properties || 0,
                                no_of_pools: no_of_pools || 0,
                                notes,
                                first_name,
                                last_name,
                                address,
                                invoice_address: billing_address,
                                phone_numbers,
                                lead_stage: "new",
                                hear_about_us: [techJourneyState.customerDetails.hear_about_us],
                                outreach: techJourneyState.customerDetails.outreach_key,
                                role: ["lead"],
                                service_duration: serviceOrderNewFieldsValue?.duration || '',
                                reference_number: serviceOrderNewFieldsValue?.referenceNumber || '',
                              },
                            },
                            commercial: {
                              lead: {
                                field_office_uuid: techJourneyState.fieldOfficeSelectedZip,
                                is_billing_address_same: sameContactAddress,
                                is_service: selectedServiceType?.name === "service",
                                proposal_date: moment().format("YYYY-MM-DD"),
                                contract_date: moment().format("YYYY-MM-DD"),
                                service_type_uuid:
                                  selectedServiceType?.name === "service"
                                    ? selectedWorkSubType !== null
                                      ? selectedWorkSubType?.uuid
                                      : selectedWorkType !== null
                                      ? selectedWorkType.uuid
                                      : selectedServiceType?.uuid
                                    : selectedServiceType.uuid,
                                pool_type_uuid: selectedPoolType?.uuid,
                                pool_type: selectedPoolType?.name,
                                pool_size: poolSize || 0,
                                preferred_contact_methods: [],
                                payment_method:
                                  serviceInfo?.payment_method?.value || "cash",
                                frequency_settings:
                                  selectedServiceType?.name === "maintenance"
                                    ? {
                                        ...frequency_settings.value,
                                      }
                                    : {
                                        start: moment().format("YYYY-MM-DD"),
                                        end: moment().format("YYYY-MM-DD"),
                                        recurring: {
                                          repeat: "One Time",
                                        },
                                      },
                                is_flat_rate:
                                  selectedWorkType?.uuid === "flat_rate",
                                price,
                                service_price: price,
                                client_type,
                                special_notes:
                                  client_type === "commercial" ? specialNotes : "",
                                email:
                                  emailaddress_set[0]?.email || "sample@test.com",
                                emailaddress_set,
                                zip_code:
                                  authUser?.user?.associated_field_offices[0]
                                    ?.addressable_zip_codes[0],
                                properties_in_community: [],
                                company_name,
                                no_of_properties: no_of_properties || 0,
                                no_of_pools: no_of_pools || 0,
                                notes,
                                first_name,
                                last_name,
                                address: [address],
                                invoice_address: billing_address,
                                phone_numbers,
                                lead_stage: "new",
                                hear_about_us: [techJourneyState.customerDetails.hear_about_us],
                                outreach: techJourneyState.customerDetails.outreach_key,
                                role: ["lead"],
                              },
                              serviceOrder: {
                                field_office_uuid: techJourneyState.fieldOfficeSelectedZip,
                                is_billing_address_same: sameContactAddress,
                                client_uuid: client_uuid,
                                is_service: true,
                                proposal_date: moment().format("YYYY-MM-DD"),
                                contract_date: moment().format("YYYY-MM-DD"),
                                is_terms_accepted: true,
                                preferred_start_date: preferred_start_date
                                  ? preferred_start_date
                                  : moment().format("YYYY-MM-DD"),
                                // schedule_time_range: schedule_time_range,
                                service_type_uuid:
                                  selectedServiceType?.name === "service"
                                    ? selectedWorkSubType !== null
                                      ? selectedWorkSubType?.uuid
                                      : selectedWorkType !== null
                                      ? selectedWorkType.uuid
                                      : selectedServiceType?.uuid
                                    : selectedServiceType.uuid,
                                pool_type_uuid: selectedPoolType?.uuid,
                                pool_size: poolSize,
                                preferred_contact_methods: Object.values(
                                  contactMethods
                                )
                                  .filter((item) => item?.isChecked === true)
                                  .map((item) => item?.key),
                                payment_method:
                                  serviceInfo?.payment_method?.value || "cash",
                                charge_invoice_card:
                                  serviceInfo?.charge_invoice_card?.value || false,
                                is_flat_rate:
                                  selectedWorkType?.uuid === "flat_rate",
                                price,
                                service_price: price,
                                pool_name: poolName || "New Pool",
                                is_enquiry: false,
                                service_address: {
                                  ...address,
                                },
                                billing_address: {
                                  ...billing_address,
                                },
                                notes,
                                is_email_invoice: is_email_invoice?.isChecked,
                                is_print_and_mail_invoices:
                                  is_print_and_mail_invoices?.isChecked,
                                status: "Active Order",
                                client_type: "commercial",
                                service_duration: serviceOrderNewFieldsValue?.duration || '',
                                reference_number: serviceOrderNewFieldsValue?.referenceNumber || '',
                              },
                            },
                          };
                          if (selectedServiceType?.name === "maintenance" || selectedServiceType?.name === "service") {
                            // Need to check for start and end
                            param[client_type].serviceOrder.frequency_settings = {
                              ...frequency_settings.value,
                            };                        
                            if(time)
                              param[client_type].serviceOrder.preferred_start_time = isEmpty(time) ? "" : moment(time).format("hh:mm") || "";                          
                          }
                          if (preferredTechnician) {
                            param[
                              client_type
                            ].serviceOrder.preferred_technician_uuid =
                              preferredTechnician;
                          }
                          if (
                            serviceInfo?.payment_method?.value === "card" ||
                            serviceInfo?.payment_method?.value === "ACH Payments"
                          ) {
                            param[client_type].lead.stax_card_token =
                              serviceInfo?.cardSelect?.value;

                            if (client_type === "commercial")
                              param[client_type].serviceOrder.stax_card_token =
                                serviceInfo?.cardSelect?.value;
                          }
                          const createCustomerResult =
                            await createCustomerTechJourney(param, client_type);

                          if (createCustomerResult.uuid) {
                            dispatch(resetValues());
                            message.success("Customer created successfully.", 20);
                            Modal.destroyAll();
                            dispatch(toggleBaseJobModal(false));
                            dispatch(resetState());
                            EventBus.emit("CUSTOMER_CREATED");
                          } else {
                            if(createCustomerResult?.non_field_errors.length > 0)
                              message.error(createCustomerResult?.non_field_errors[0], 20);
                            else
                              message.error("Something went wrong.", 20);
                          }
                          dispatch(showLoader(false));
                          setServiceInfo({
                            payment_method: {
                              value: "",
                            },
                            cardSelect: {
                              value: "",
                            },
                            card_holder_name: {
                              value: "",
                            },
                          });
                        }
                      }
                      setConfirmLoading(false);
                    })
                    .catch((err)=>{
                      setConfirmLoading(false);
                      let err_data={};
                      map(err.error?.inner, (item) => {
                        err_data[item.path] = item.message;
                      });
                      setErrors(err_data);
                      message.error("Please correct errors.", 10);
                    });
                  }}
                >
                  Save & Activate
                </Button>,
              ]}
            >
              {techJourneyState.showLoader && <Loader />}
              <Row>
                <Col xxl={12} xl={12} md={12} xs={24} sm={24}>
                  <h3 className="mt-20">Base Job Requirements</h3>
                  <SelectService />
                  {selectedServiceType?.name?.trim().toLowerCase() ===
                    "maintenance" && 
                    <>
                      <RateTypes />
                      {errors?.["workType"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["workType"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </>}
                  {selectedServiceType?.name?.trim().toLowerCase() ===
                    "construction" && (
                    <>
                      <Notes
                        noteTitle="Notes: Please provide customer's request"
                        noteText={techJourneyState.constructionNotes}
                        placeholder="Customer proposal"
                        name="construction"
                        changeHandler={(noteText) =>
                          updateNotes(noteText, "construction")
                        }
                      />
                    </>
                  )}
                  {selectedServiceType?.name?.trim().toLowerCase() ===
                    "renovation" && (
                    <>
                      <Notes
                        noteTitle="Notes: Please provide customer's request"
                        noteText={techJourneyState.renovationNotes}
                        placeholder="Customer proposal"
                        name="renovation"
                        changeHandler={(noteText) =>
                          updateNotes(noteText, "renovation")
                        }
                      />
                    </>
                  )}
                  {selectedServiceType?.name?.trim().toLowerCase() ===
                    "service" && 
                    <>
                      <ServiceRequired />
                      {errors?.[`workSubType`] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.[`workSubType`]}
                        </label>
                      ) : (
                        ""
                      )}  
                  </>}
                  {(selectedServiceType?.name?.trim().toLowerCase() ===
                    "maintenance" ||
                    selectedServiceType?.name?.trim().toLowerCase() ===
                      "service") && 
                      <>
                        <CleaningFrequency />
                        {errors?.[`start`] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.[`start`]}
                          </label>
                        ) : (
                          ""
                        )}   
                    </>}               
                  {(selectedServiceType?.name?.trim().toLowerCase() === "maintenance" || selectedServiceType?.name?.trim().toLowerCase() === "service") && (
                    <>
                      <Row>
                        <div className="mb-10 so-heading" style={{ width: "50%" }}>
                          <h4>Select Time</h4>
                          <TimePicker
                            className=""
                            placeholder={"--:-- AM/PM"}
                            value={techJourneyState.time}
                            onChange={(e) => {
                              dispatch(selectTime(e))
                            }}
                            allowClear={false}
                            format={"h:mm A"}
                          />
                        </div>                        
                        <AddDurationField durationType="service" screen="soPopUp"/>                                              
                      </Row>
                      <Row>
                        <AddReferenceField type="renovationAndConstruction" screen="soPopUp"/>
                      </Row>
                    </>
                    )}
                  {["maintenance", "service"].includes(
                    selectedServiceType?.name?.trim().toLowerCase()
                  ) ? (
                    <>
                      <PoolTypes />
                      {errors?.[`poolType`] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.[`poolType`]}
                        </label>
                      ) : (
                        ""
                      )} 
                      <div className="from-group">
                        <div className="fitem poolName">
                          <Row>
                            <Col span={24} sm={24}>
                              <label>
                                Pool Name<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="poolName"
                                name="poolName"
                                placeholder="Pool Name"
                                value={techJourneyState.poolName}
                                onChange={(e) =>
                                  dispatch(addPoolName(e.target.value))
                                }
                              />
                            </Col>
                            <Col span={24} sm={24}>
                              <label>Pool size in Gallons</label>
                              <input
                                type="text"
                                id="poolSize"
                                name="poolSize"
                                placeholder="20000"
                                value={techJourneyState.poolSize}
                                onChange={(e) =>
                                  dispatch(addPoolSize(e.target.value))
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <PreferredTechnician />
                  <div className="from-group">
                    <div className="fitem">
                      <Notes
                        noteTitle="Special notes"
                        noteText={techJourneyState.specialNotes}
                        placeholder="Special Notes"
                        name="specialNotes"
                        changeHandler={(noteText) =>
                          updateNotes(noteText, "specialnotes")
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col xxl={12} xl={12} md={12} xs={24} sm={24}>
                  <div className="from-group">
                    <div className="fitem">
                      <ServiceSummary
                        serviceInfo={{
                          serviceType: {
                            ...techJourneyState?.selectedServiceType,
                            key:
                              techJourneyState?.selectedServiceType?.name ||
                              "maintenance",
                          },
                          service: {
                            key: techJourneyState?.selectedWorkType?.name,
                          },
                          worksubtype: {
                            key: techJourneyState?.selectedWorkSubType?.name,
                          },
                          poolType: {
                            key: techJourneyState?.selectedPoolType?.name,
                          },
                        }}
                        priceDetail={{ price: techJourneyState?.price }}
                        data={data}
                        setPriceDetail={setPriceDetailData}
                      />
                    </div>
                  </div>
                  <ContactMethod />
                  <InvoicePreferences />
                  <hr />
                  {(selectedServiceType?.name?.trim().toLowerCase() !== "maintenance" && selectedServiceType?.name?.trim().toLowerCase() !==
                    "service") && (
                    <>
                      <PreferredDateTime /> <hr />
                    </>
                  )}
                  {techJourneyState.customerDetails.company_type ===
                    "commercial" && (
                    <>
                      <CommunityInformation /> <hr />
                    </>
                  )}

                  <PaymentInformation
                    uuid={userID}
                    setPriceDetailData={setPriceDetailData}
                    setServiceInfoData={setServiceInfoData}
                    serviceInfo={serviceInfo}
                    data={{ ...techJourneyState.customerDetails }}
                    fieldOfficeData={associatedFieldOffice}
                    customer={{ ...techJourneyState.customerDetails }}
                    errors = { errors }
                  />
                  {/* <BillingInfo /> */}
                </Col>
              </Row>
            </Modal>
          </Col>
        </Row>
      </Provider>
    </>
  );
};

export default BaseJobRequirementsModal;
