import React, { useState } from "react";
import { MoreOutlined } from "@ant-design/icons";

const ResponsiveCell = ({ value, length = 12 }) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <div
      style={{ cursor: "pointer", }}
      onClick={() => setVisibility(!visibility)}
    >
      {!visibility ? (
        <span>
          {value.length <= length ? (
            value
          ) : (
            <>
              {value.substring(0, length)}
              <MoreOutlined style={{ color: "#005d89", fontSize: "18px" }} />
            </>
          )}
        </span>
      ) : (
        value
      )}
    </div>
  );
};

export default ResponsiveCell;
