
import axiosInstance from '../../Lib/Axios';

// Get customer detail
export const getCustomerDetail = (id) => {
    return axiosInstance
        .get(`crm/customer-user/${id}`)
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

// get commercial customers
export const getCommercialCustomer = (uuid) => {
    return axiosInstance
        .get(`crm/commercial-customer/${uuid}`)
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

// Get all Account
export const getAccountListing = (params) => {
    return axiosInstance
        .get(`/invoice/`, { params })
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

// Get Account Tab
export const getAccountListingTab = (params) => {
    return axiosInstance
        .get(`/invoice/get_overdue_open_last_invoices/`, { params })
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

// Get Workorder Count
export const getWorkorderCount = (params) => {
    return axiosInstance
        .get(`/service/get_billable_month_total/`, { params })
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

//Quickbooks force sync
export const getQuickbooksSync = (params) => {
    return axiosInstance
        .get(`/field-office/${params}/force-sync-quickbooks/`)
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

// Word Order Sync
export const getWorkOrderSync = (params) => {
    return axiosInstance
      .get(`/services-to-batch-invoice/get_work_orders/`, {params} )
    //   .get(`/services-to-batch-invoice/?status__in=complete,incomplete&scheduled_appointment__lte=2023-04-04&scheduled_appointment__gte=2023-05-04&limit=15&offset=0&ordering=scheduled_appointment&service_order__client__client_type=residential&status_invoiced=not invoiced&service_type__in=Maintenance`, params )
      .then((response) => {
        return response?.data
     
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

// Generate Invoice 
export const generateInvoice = (params) => {
    // return (dispatch) => {
    //   return axiosInstance
    //     .post(`/services-to-batch-invoice/generate-invoice/`, params)
    //     .then((response) => {
    //       dispatch({
    //         type: POST_WORK_ORDERS_GENERATE_INVOICE_SUCCESSFULLY,
    //         data: response.data,
    //       });
    //     })
    //     .catch((error) => {
    //       if (
    //               error?.response?.status === 401 ||
    //               error?.response?.status === 403
    //       ) {
    //         window.location.assign("/");
    //         localStorage.clear();
    //         sessionStorage.clear();
    //       }
    //       if (error?.response) {
    //         dispatch({
    //           type: POST_WORK_ORDERS_GENERATE_INVOICE_FAILED,
    //           data: error?.response?.data,
    //         });
    //       }
    //     });
    // };
    return axiosInstance
    .post(`/services-to-batch-invoice/generate-invoice/`, params)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  }

//Email invoice batch
export const emailInvoice = (params) => {
    return axiosInstance
        .post(`/invoice/batch_email/`, params)
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

//Payment invoice batch
export const paymentProcessInvoice = (params) => {
    return axiosInstance
        .post(`/crm/manual-payment/batch-payment/`, params)
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};
export const exportAccountList = (params) => {
    return axiosInstance.get(`/invoice/export/`, { params })
        .then((response) => {
          if (response.headers['content-type'] === 'application/json') {
            return response.data;
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const timestamp = Date.now();
            link.setAttribute('download', `accountsList_${timestamp}.csv`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              window.location.assign("/");
              localStorage.clear();
              sessionStorage.clear();
            }
          }
        });
  };

  export const exportWorkOrderSync = (params) => {
    return axiosInstance
        .get(`/services-to-batch-invoice/export`, {params} )
        .then((response) => {
            if (response.headers['content-type'] === 'application/json') {
            return response.data;
            } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const timestamp = Date.now();
            link.setAttribute('download', `workorderList_${timestamp}.csv`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            }
        })
        .catch((error) => {
            if (error && error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            }
        });
  };