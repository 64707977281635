import React, { useRef } from "react";
import moment from "moment-timezone";
import { Row, Col, Modal, Input, DatePicker, TimePicker, Checkbox } from "antd";
import StaffSelector from "../Filters/Staff.Selector";

export const ReassignModalTechGlance = (props) => {
  const { rescheduleModalOpen, onRescheduleWorkOrder, onRescheduleTask, hideRescheduleModal, onCloseRescheduleModal,
    selectedEvent, role, techPermission, franchise, setRescheduleTechnician, rescheduleDate, setRescheduleDate,
    rescheduleTimeSelection, onChangeTimePicker, technician, onChangRecurring, rescheduleRecurring,
    disabledDate } = props;
    const freqRepeatCheck = useRef('')
    if(selectedEvent?.original?.frequency_settings?.recurring?.repeat !== ''){
      freqRepeatCheck.current = selectedEvent?.original?.frequency_settings?.recurring?.repeat
    }
    else{
      freqRepeatCheck.current = 'One Time'
    }
    const rescheduleCheckValidator = () => {
      return moment(selectedEvent?.original?.start).startOf('day').diff(moment(Date.now()).startOf('day'), 'days') >=0;
    }

  return (
    <Modal
      title={`${selectedEvent?.original?.type == "Task" ? "Reassign Task" : "Reassign Job"}`}
      open={rescheduleModalOpen}
      onOk={() => {
        if (selectedEvent?.original?.type == "Task") {
          onRescheduleTask();
        } else {
          onRescheduleWorkOrder();
        }
      }}
      onCancel={() => {
        hideRescheduleModal();
      }}
      okText="Update"
      cancelText="Cancel"
      className="schedule_line_modal"
      destroyOnClose
      afterClose={onCloseRescheduleModal}
    >
      <div className="fitem">
        <label>
          <b>{`${selectedEvent?.original?.type == "Task" ? "Task Name" : "Work Order"}`}</b>
        </label>
        <Input
          placeholder={selectedEvent?.original?.title ?? "title"}
          disabled
        />
      </div>
      <div className="fitem">
        <label>
          <b>Reassign From</b>
        </label>
        <Input
          placeholder={selectedEvent?.original?.type == "Task" ? selectedEvent?.original?.technician ?? "Not assigned" : selectedEvent?.original?.technician ?? "Not assigned"}
          disabled
        />
      </div>
      <div className="fitem">
        <label>
          <b>Reassign To</b>
        </label>
        {role === "field_tech" ? <StaffSelector
          permission={props.techPermission?.includes("assign_wo")}
          autoSelection={true}
          unassign={true}
          franchise={franchise}
          onControlChange={({ value, label }) => {
            setRescheduleTechnician({ value, label });
          }}
        /> :
          <StaffSelector
            franchise={franchise}
            autoSelection={true}
            unassign={true}
            onControlChange={({ value, label }) => {
              setRescheduleTechnician({ value, label });
            }}
          />}
      </div>
      <Row className="fitem">
        <Col span={12}>
          <label>
            <b>Assign From Date</b>
          </label>
          <DatePicker
            defaultValue={
              selectedEvent?.original?.start
                ? moment(selectedEvent?.original?.start)
                : moment()
            }
            format={"YYYY-MM-DD"}
            disabled
          />
        </Col>
        <Col span={12} style={{ paddingLeft: "5px" }}>
          <label>
            <b>Time</b>
          </label>
          <TimePicker
            value={moment(selectedEvent?.original?.start).utc()}
            format={"h:mm A"}
            disabled
          />
        </Col>
      </Row>
      <Row className="fitem">
        <Col span={12}>
          <label>
            <b>Reassign Date</b>
          </label>
          <DatePicker
            value={rescheduleDate}
            onChange={(value) => {
              value
                ? setRescheduleDate(moment(value))
                : setRescheduleDate(null);
            }}
            disabledDate={disabledDate}
            format={"YYYY-MM-DD"}
            popupClassName="reassignEventDatePicker"
            disabled={
              role === "field_tech" &&
              !props.techPermission?.includes(
                "reschedule_wo"
              )
            }
          />
        </Col>
        <Col span={12} style={{ paddingLeft: "5px" }}>
          <label>
            <b>Time</b>
          </label>
          <TimePicker
            value={rescheduleTimeSelection}
            onChange={onChangeTimePicker}
            format={"h:mm A"}
            disabled={
              role === "field_tech" &&
              !props.techPermission?.includes(
                "reschedule_wo"
              )
            }
          />
        </Col>
      </Row>
      { selectedEvent?.original?.type != "Task" &&
        (selectedEvent?.original?.status === "forecast" || selectedEvent?.original?.status === "scheduled") ? (
        <>
        {rescheduleCheckValidator() && ((selectedEvent?.original?.serviceType == 'maintenance' && selectedEvent?.original?.worksubtype !== 'One Time') || (selectedEvent?.original?.serviceType == 'service' && (selectedEvent?.original?.is_recurring === true && selectedEvent?.original?.worksubtype !== 'One Time'))) && <Row className="fitem">
            <Col span={24} style={{ textAlign: "center", margin: "15px 0" }}>
              or
            </Col>
          </Row>}
          {rescheduleCheckValidator() && ((selectedEvent?.original?.serviceType == 'maintenance' && selectedEvent?.original?.worksubtype !== 'One Time') || (selectedEvent?.original?.serviceType == 'service' && (selectedEvent?.original?.is_recurring === true && selectedEvent?.original?.worksubtype !== 'One Time'))) &&
          <Row className="fitem">
            <Col span={24}>
              <Checkbox
                onChange={onChangRecurring}
                checked={rescheduleRecurring}
                disabled={
                  role === "field_tech" &&
                  !props.techPermission?.includes(
                    "assign_wo"
                  )
                }
              >
                Reassign all future recurring instances of this work order.
              </Checkbox>
            </Col>
          </Row>}
        </>
      ) : (
        <></>
      )}
      {selectedEvent?.original?.type == "Task" && freqRepeatCheck.current !== 'One Time' &&
        rescheduleCheckValidator() && (selectedEvent?.original?.status === "forecast" || selectedEvent?.original?.status === "scheduled") ? (
        <>
          <Row className="fitem">
            <Col span={24} style={{ textAlign: "center", margin: "15px 0" }}>
              or
            </Col>
          </Row>
          <Row className="fitem">
            <Col span={24}>
              <Checkbox
                onChange={onChangRecurring}
                checked={rescheduleRecurring}
                disabled={
                  (role === "field_tech" || role === "lead_tech") &&
                  !selectedEvent?.original?.permissionset?.includes(
                    "assign_wo"
                  )
                }
              >
                Reassign all future recurring instances of this task.
              </Checkbox>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </Modal>
  )
}