
import axiosInstance from '../../Lib/Axios';

//Create proposal batch
export const createProposal = (params) => {
    return axiosInstance
        .post(`/proposal/`, params)
        .then((response) => {
            return response?.data
        })
        .catch((error) => {
            if (
                error?.response?.status === 401 ||
                error?.response?.status === 403
            ) {
                window.location.assign("/");
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error?.response) {
                return error?.response?.data
            }
        });
};

export const getProposalInfo = (params) => {
    // return (dispatch) => {
    return axiosInstance
      .get(`proposal/`, { params })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
    // };
  };
  export const getProposalInfoFromService = (uuid) => {
    // return (dispatch) => {
    return axiosInstance
      .get(`proposal/${uuid}`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
    // };
  };
  export const setProposalAdditionalWorkInfo = (uuid, params) => {
    // return (dispatch) => {
    return axiosInstance
      .post(`/proposal/create_proposal_additional_work/?uuid=${uuid}`, params, {})
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
    // };
  };


  export const saveandsendproposal = (uuid,params) => {
    return axiosInstance
      .patch(`/proposal/${uuid}/`,params)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
  };