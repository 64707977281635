import React, { useEffect, useState } from "react";
import { Select } from "antd";

const AgingStatusSelector = ({
  handleChange = () => {},
  allValue = true,
  defaultValue,
}) => {
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    const getServiceList = async () => {
      const data = [
        "Open",
        "Overdue",
      ];
      setServiceList(data ? data : []);
    };
    getServiceList();
  }, []);

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        placeholder={"Select a Status"}
      >
        {allValue ? (
          <Select.Option key={"statusAll"} value={"all"} label={"All"}>
            {"All"}
          </Select.Option>
        ) : (
          <></>
        )}
        {serviceList.sort().map((option) => (
          <Select.Option key={option} value={option} label={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default AgingStatusSelector;
