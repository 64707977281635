import React from "react";
import { Select } from "antd";

const PeriodSelector = ({
  handleChange = () => {},
  defaultValue,
  error = false,
}) => {
  const dateSelector = [
    {
      name: "Today",
      value: "today",
    },
    {
      name: "Yesterday",
      value: "yesterday",
    },
    {
      name: "Current Week",
      value: "week",
    },
    {
      name: "Last Week",
      value: "last_week",
    },
    {
      name: "Current Month",
      value: "month",
    },
    {
      name: "Last Month",
      value: "last_month",
    },
    {
      name: "Current Year",
      value: "year",
    },
    {
      name: "Custom",
      value: "custom",
    },
  ];

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(e) => handleChange(e)}
        placeholder={"Time"}
        status={error ? "error" : ""}
      >
        {dateSelector.sort().map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default PeriodSelector;
