import React, {Component, useEffect, useState} from "react";
import Loader from "../../../Components/Loader";
import moment from "moment-timezone";
import {message} from "antd";
import { createNotes, getNotes } from "../../../Action/Customer";

const PrivateNotes = (props) => {
  const [state,setState] = useState({
    noteList: [],
    note: '',
    loader: false,
    errors: {}
  }) 
function getState(){
  setState({
    noteList: [],
    note: '',
    loader: false,
    errors: {}
  })
}

const getAccount = async () => {
  const data = await getNotes({user__uuid: props?.customerId})
  setState(()=>({
    ...state,
    loader:false,
    noteList: data?.results || [],
    note:''
  }
    ))
}

useEffect(()=>{
  getState();
  setState((state)=>({
    ...state,
    loader:true
  }))
  getAccount();
},[])


  // on text change
const onTextChange = (e) => {
  setState((state)=>({...state,[e.target.name]: e.target.value}))
  };

  // on save note
const onSaveNote = () => {
    const {note} = state;
    if (note) {
      setState((state)=>({
        ...state,
        loader:true
      }))
        const params = {
        notes: note,
        user: props.customerId,
        is_private_note: true,
        created_by_details: {
          uuid: props.customerId
        }
      };
      const createNotesAcc = async()=>{
        const data = await createNotes(params);
        setState((state)=>({
          ...state,
          note:''
        }))
        if(data.uuid){
          message.success("Note save successfully!",10)
          getAccount();
          setState((state)=>({
            ...state,
            loader:false,
            note:''
          }))
          setState((state)=>({...state,errors: {}}))
        }else{
          setState((state)=>({
            ...state,
            loader:false
          }))
          // setState((state)=>({...state,errors: {note: 'Note is required'}}))
        }
      }
      createNotesAcc()
    }else{
      message.error("Note is required!",10)
    }
  }
      

    const {noteList, note, loader, errors} = state;
    return (
      <div className="card order3">
        {loader && <Loader />}
        <div className="card-title flex-justify-between-align-center">
          <h5  className="private-title">Private Account Notes</h5>
          <p className="private-desc">[Internal Notes only, Not Visible to Customer]</p>
        </div>
        <div className="card-body pb-0">
          {
            noteList.filter(item => item.is_private_note).map((item, index) =>
              <div className="notes" key={`notes${index}`}>
                <span>{item?.notes}</span>
                <small>{`${moment(item?.date).format('MMM DD, YYYY')} . ${item?.created_by_details?.user_roles?.[0]?.display_name || '-'} . ${item?.created_by_details?.first_name} ${item?.created_by_details?.last_name}`}</small>
              </div>
            )
          }
        </div>
        <div className="fitem green-textarea">
          <textarea
            rows="4"
            name="note"
            type="text"
            className="form-control private_account_notes_text_area"
            placeholder={"Start typing to add private account notes ...1"}
            value={note}
            onChange={(e) => onTextChange(e)} />
          <button
            className={`btn btn-txt float-right mt-10`}
            // disabled={!note}
            onClick={onSaveNote}>
            Save Note</button>
        </div>
        {errors?.['note'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['note']}</label> : ''}
      </div>
    )
}

export default PrivateNotes