import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import PeriodSelector from "../Filters/Period.Selector";
import FranchiseSelector from "../Filters/Franchise.Selector";
import SummaryContainer from "../Container/Summary.Container";
import TimeNoteLabel from "../Labels/TimeNote.Label";
import ServiceRevenueCountContainer from "../Container/ServiceRevenueCount.Container";
import RevenueTrendContainer from "../Container/RevenueTrend.Container";
import StaffAssignment from "../Container/StaffAssignment.Container";
import WorkOrder from "../Container/WorkOrder.Container";
import plotIcon from "../../../Assets/icons/plot_icon.svg";
import calendarIcon from "../../../Assets/icons/calendar_icon.svg";
import techIcon from "../../../Assets/icons/tech_icon.svg";
import gpsIcon from "../../../Assets/icons/gps_icon.svg";
import {
  getFranchiseSelected,
  setFranchiseSelected,
} from "../../../Utils/selectedFranchise";
import { useCalendar } from "../../../Calendar/Hooks/Calendar.Hook";
import gps_tracking from "../../../Assets/images/GPS Tracking.svg";
import {
  getGpsTrackSetUp,
  getGpsTrackSetUpForFO,
} from "../../../Action/Franchise";
import { isEmpty } from "lodash";

const DashboardContainer = () => {
  const { setFranchise, franchise, setPeriod, setPeriodType } = useDashboard();
  const calendarHook = useCalendar();
  const [showGpsIcon, setShowGpsIcon] = useState(false);
  const [gpsDetails, setGpsDetails] = useState({
    gps_account_user_id: "",
    gps_account_password: "",
  });
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;

  const handlePeriodChange = (period, periodType) => {
    setPeriod(period);
    setPeriodType(periodType);
  };
  const handleFranchiseChange = (e) => {
    setFranchiseSelected(e);
    setFranchise(getFranchiseSelected().uuid);
  };

  const getGPS_configs = async () => {
    let data = {};
    if (isCorporate && franchise !== "all") {
      data = await getGpsTrackSetUp(franchise);
    }
    if (!isCorporate) {
      data = await getGpsTrackSetUpForFO(franchise);
    }
    if (!isEmpty(data.gps_account_user_id)) {
      setGpsDetails({
        gps_account_user_id: data.gps_account_user_id,
        gps_account_password: data.gps_account_password,
      });
      setShowGpsIcon(true);
    } else {
      setGpsDetails({
        gps_account_user_id: "",
        gps_account_password: "",
      });
      setShowGpsIcon(false);
    }
  };

  useEffect(() => {
    getGPS_configs();
    setFranchiseSelected(franchise)
    if(franchise==="all"){
      calendarHook.setFranchise({ value: userInfo?.user.associated_field_offices[0].uuid, label: "all"});
    }
    else calendarHook.setFranchise({ value: franchise });
  }, [franchise]);


  return (
    <>
      <Col xs={24} xxl={24} md={24} className="linkContainer">
        <Row className="quick-link-wrapper">
          <a href="/field_tech/plot_job" className="quick-link-container">
            <img src={plotIcon} alt={""} style={{ width: "60px" }} />
            <span className={"quick-link-title"} href="#">
              Plot Jobs on Map
            </span>
          </a>
          <a href="/calendar" className="quick-link-container">
            <img src={calendarIcon} alt={""} style={{ width: "60px" }} />
            <span className={"quick-link-title"} href="#">
              Calendar View
            </span>
          </a>
          <a href="/tech-glance" className="quick-link-container">
            <img src={techIcon} alt={""} style={{ width: "60px" }} />
            <span className={"quick-link-title"} href="#">
              Tech Glance
            </span>
          </a>

          {showGpsIcon ? (
            <a
              href={
                "http://opsassure.mastrack.com/maps/all_map31.php?uid=" +
                gpsDetails.gps_account_user_id +
                "&pwd=" +
                gpsDetails.gps_account_password
              }
              target="_blank"
              className="quick-link-container"
              rel="noreferrer"
            >
              <img src={gpsIcon} alt={""} style={{ width: "60px" }} />
              <span className={"quick-link-title"} href="#">
                GPS Tracking
              </span>
            </a>
          ) : (
            <></>
          )}
        </Row>
      </Col>
      <Col xs={24} xxl={24} md={24} className="dashboardContainer">
        <Row style={{ margin: "5px" }}>
          <Col xs={24} xxl={24} md={24} className="summaryContainer">
            <Row className="cardFilters">
              <Col xs={24} xxl={12} md={12} className="timeFilter">
                <PeriodSelector handleChange={handlePeriodChange} />
              </Col>
              <Col xs={24} xxl={12} md={12} className="franchiseFilter">
                <FranchiseSelector
                  handleChange={handleFranchiseChange}
                  defaultValue={franchise}
                />
              </Col>
              <Col xs={24} xxl={24} md={24} className="filterNote">
                <TimeNoteLabel />
              </Col>
            </Row>
            <SummaryContainer />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", justifyContent: "space-between" }}>
          <Col xs={24} xxl={12} md={12}>
            <RevenueTrendContainer />
          </Col>
          <Col xs={24} xxl={12} md={12}>
            <ServiceRevenueCountContainer />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", justifyContent: "space-between" }}>
          <Col xs={24} xxl={12} md={12}>
            <StaffAssignment />
          </Col>
          <Col xs={24} xxl={12} md={12}>
            <WorkOrder />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DashboardContainer;
