import axiosInstance from '../../Lib/Axios';

// get list of Invoices
export const getInvoice = (params) => {
  // return invoiceList
  // return (dispatch) => {
  return axiosInstance
    .get(`invoice/`, { params })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};

// send Invoices
export const sendInvoice = (params) => {
  // return invoiceList
  // return (dispatch) => {
  return axiosInstance
    .get(`invoice/${params}/render/?action=email`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};

// Create Manual Invoice
export const createManualInvoice = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`invoice/manual_invoice/`, params)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response
      }
    });
  // };
};


// Create Manual Invoice
export const createManualPayment = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`crm/manual-payment/`, params)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};


// Last 3 Payment list
export const getLastThreePayment = (params) => {
  // return invoiceList
  // return (dispatch) => {
  return axiosInstance
    .get(`crm/last-three-payments`, { params })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};


// Last 3 Payment list
export const getLastThreeInvoice = (params) => {
  // return invoiceList
  // return (dispatch) => {
  return axiosInstance
    .get(`invoice/last_three_invoice/`, { params })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};

// Last 3 Payment list
export const getOverdueOpenLastInvoices = (params) => {
  // return invoiceList
  // return (dispatch) => {
  return axiosInstance
    .get(`invoice/get_overdue_open_last_invoices/`, { params })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};

export const getSingleInvoice = (uuid) => {
  return axiosInstance
    .get(`service/get_service_ready_to_invoiced/?service_order_uuid=${uuid}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
};

// Create Manual Invoice
export const createSingleInvoice = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`invoice/manual_invoice/`, params)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};

// get list of Invoices
export const getInvoiceDetail = (params) => {
  // return invoiceList
  // return (dispatch) => {
  return axiosInstance
    .get(`invoice/${params}`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};

// get finvoice ile to print or download
export const getInvoiceFile = (uuid) => {
  return axiosInstance
    .get(`invoice/${uuid}/render/?action=file`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
};

// Create Manual Invoice
export const deleteInvoice = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`/invoice/bulk_delete/`, params)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
  // };
};


export const voidInvoiceApi = (uuid) => {
  return axiosInstance
    .get(`/invoice/${uuid}/void_invoice/`)
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data
      }
    });
};

// Create Manual Invoice
export const refundInvoice = (params) => {
  return axiosInstance
    .post(`crm/refund/`, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      let status;
      if(typeof error?.response.status === 'object'){
        status = error?.response.status[0]
      }else{
        status = error?.response.status
      }

      if (
        status === 401 || status === 403 
      ) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        if(error?.response?.data?.data?.message){
          return error?.response?.data
        }else{
          let err_msg = {
            data: error?.response?.data,
            status: error?.response?.status
          };
          return err_msg;
        }
      }
    });
  };

    export const getPaymentHistory = (params) => {
      return axiosInstance
        .get(`/crm/payment-history`, { params })
        .then((response) => {
          return response?.data
        })
        .catch((error) => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            window.location.assign("/");
            localStorage.clear();
            sessionStorage.clear();
          }
          if (error?.response) {
            return error?.response?.data
          }
        });
    };
