import React, { useEffect, useState } from "react";
import { Select, ConfigProvider, Empty, Spin } from "antd";
import { orderBy, isEmpty } from "lodash";
import { getFieldTechnician } from "../../../Action/Franchise/index";

const { Option } = Select;

const StaffSelector = ({
  franchise = { value: null, label: null },
  onControlChange = () => { },
  autoSelection = false,
  unassign = false,
  permission = true,
}) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const role = userInfo && userInfo?.user?.user_roles[0]?.name;
  const [seeCal, setSeeCal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);

  useEffect(() => {
    const getFieldTech = async () => {
      setLoading(true);
      setTechnicians([]);
      if (franchise.value === null) {
        setTechnicians([]);
        return;
      }
      const data = await getFieldTechnician({
        field_office__uuid: franchise.value,
      });
      let temp = data.results.filter((each) => userInfo.user.uuid === each.user.uuid);
      setSeeCal(temp[0]?.staff_profile?.permissionset?.see_calenders);
      if (isEmpty(data?.results)) {
        setTechnicians([]);
        return;
      }

      switch (true) {
        case role === "admin" || role === "franchise_owner" || role === "operations_mgr":
          if (franchise && autoSelection) {
            setSelectedTechnician({
              value: data.results[0]?.user.uuid,
              label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
            });
            onControlChange({
              value: data.results[0]?.user.uuid,
              label: `${data.results[0]?.user?.first_name} ${data.results[0]?.user?.last_name}`,
            });
            if (unassign)
              data.results.push({
                user: {
                  uuid: "unassign",
                  first_name: "Not Assigned",
                  last_name: "",
                },
              });
          }
          break;
        case role === "field_tech":
          if (franchise) {
            setSelectedTechnician({
              value: userInfo.user.uuid,
              label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
            });
            onControlChange({
              value: userInfo.user.uuid,
              label: `${userInfo.user.first_name} ${userInfo.user.last_name}`,
            });
          }
          break;
        default:
          break;
      }
      setTechnicians(data.results);
      setLoading(false);
    };
    getFieldTech();
  }, [franchise]);

  const updateTechnician = (selectedTechnician, options) => {
    setSelectedTechnician(selectedTechnician);
    onControlChange(options ?? { value: null, label: null });
  };

  const configureStaffeSelector = () => {
    switch (true) {
      case role === "admin" || role === "franchise_owner" || role === "operations_mgr":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading technicians..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              showSearch
              allowClear
              placeholder="Not assigned"
              className="swhite calendar-filter-tech"
              optionFilterProp="children"
              onChange={(value, options) => updateTechnician(value, options)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedTechnician || undefined}
              disabled={!permission}
            >
              {orderBy(technicians, "first_name", "asc")?.map((technician) => {
                return (
                  <Option
                    key={technician.user.uuid}
                    value={technician.user.uuid}
                    label={`${technician?.user?.first_name} ${technician?.user?.last_name}`}
                  >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                );
              })}
            </Select>
          </ConfigProvider>
        );
      case role === "field_tech":
        return (
          // <Select
          //   value={selectedTechnician}
          //   className="swhite calendar-filter-tech"
          //   disabled={!permission}
          // >
          //   <Option
          //     value={selectedTechnician?.uuid}
          //     label={selectedTechnician?.label}
          //   >
          //     {selectedTechnician?.label}
          //   </Option>
          // </Select>

          <Select
            showSearch
            allowClear
            placeholder="Not assigned"
            className="swhite calendar-filter-tech"
            optionFilterProp="children"
            onChange={(value, options) => updateTechnician(value, options)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedTechnician || undefined}
            disabled={!permission}
          >
            {orderBy(technicians, "first_name", "asc")?.map((technician) => {
              return (
                <Option
                  key={technician.user.uuid}
                  value={technician.user.uuid}
                  label={`${technician?.user?.first_name} ${technician?.user?.last_name}`}
                >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
              );
            })}
          </Select>
        );
      // if(seeCal) {
      //   return (
      //     <Select
      //     showSearch
      //     allowClear
      //     placeholder="Technician: Not assigned"
      //     className="swhite calendar-filter-tech"
      //     optionFilterProp="children"
      //     onChange={(value, options) => updateTechnician(value, options)}
      //     filterOption={(input, option) =>
      //       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //     }
      //     value={selectedTechnician || undefined}
      //     disabled={!permission}
      //   >
      //     {orderBy(technicians, "first_name", "asc")?.map((technician) => {
      //       return (
      //         <Option
      //           key={technician.user.uuid}
      //           value={technician.user.uuid}
      //           label={`${technician?.user?.first_name} ${technician?.user?.last_name}`}
      //         >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
      //       );
      //     })}
      //   </Select>
      //   );
      // }  else {
      //   return (
      //     <Select
      //       value={selectedTechnician}
      //       className="swhite calendar-filter-tech"
      //       disabled={!permission}
      //     >
      //       <Option
      //         value={selectedTechnician?.uuid}
      //         label={selectedTechnician?.label}
      //       >
      //         {selectedTechnician?.label}
      //       </Option>
      //     </Select>
      //   );
      // }
      default:
        break;
    }
  };

  return configureStaffeSelector();
};

export default StaffSelector;
