import React, {useState } from 'react';
import { Alert, DatePicker, message, Select, Table } from 'antd';
import moment from 'moment-timezone';
import { map } from 'lodash';
import * as yup from 'yup';
import Loader from "../../Components/Loader";
import DataTable from './DataTable';
const { Option } = Select;
const FranchiseAlerts =()=>{
    const [state, setState]=useState();
    // state = {
    //     all_alerts: [],
    //     alert_pagination: { current: 1, showSizeChanger: false, pageSize: 10, ordering: "", },
    //     alerts: [
    //       { key: 'hurricane', value: 'Hurricane' },
    //       { key: 'snow_day', value: 'Snow Day' },
    //       { key: 'no_service', value: 'No Service' },
    //       { key: 'strom', value: 'Storm' },
    //       { key: 'promotion', value: 'Promotion' },
    //     ],
    //     new_alert: {
    //       alert_type: '',
    //       start_date: '',
    //       end_date: '',
    //       alert_desccription: '',
    //       untill_further_notice: false,
    //     },
    //     delete_id: '',
    //     isNav: '',
    //     is_edit: false,
    //     openNav: false,
    //     loader: false,
    //     field_office_uuid: '',
    //     is_add_user_Active: false
    //   }
   
    //   renderAlertTable = () => {
    //     let { all_alerts, alert_pagination } = this.state;
    //     return (
    //       <Table
    //         rowKey={record => record.uuid}
    //         dataSource={all_alerts}
    //         pagination={alert_pagination}
    //         onChange={''}
    //         columns={DataTable.FranchiseAlerts(this)}
    //         className="table"
    //       />
    //     )
    //   }
    //   onAlertHandelChange = (pagination) => {
    //     let { alert_pagination } = this.state;
    //     alert_pagination = Object.assign(alert_pagination, { 'current': pagination.current });
    
    //     let data = {
    //       field_office__uuid: '',
    //       offset: (pagination.current * 10) - 10,
    //       limit: 10,
    //     }
    
    //    // this.props.getFieldAlert(data);
    
       
    //   }
    const [alerts, setAlerts] = useState([
      {
        Falert: 'Hurricane',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
        startDate: '31/10/2022',
        EndDate:'01/11/2022',
        status: 'Active'
      },
      {
        Falert: 'Snow Day',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
        startDate: '04/15/2020',
        EndDate:'05/20/2020',
        status: 'Active'
      },
      {
        Falert: 'Storm',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
        startDate: '03/15/2020',
        EndDate:'05/20/2020',
        status: 'Closed'
      },
    ]);
   const renderAlertTable=()=>{
    return(
        <Table
        className="tablebody service_descriptionTable_table"
       // rowKey={(record) => record.repair_code}
        dataSource={alerts}
        pagination={true}
        columns={DataTable.franchiseAlertTable('Hurricane','loren pas','31/10/2022','01/11/2022','status')}
      />
    )
   }
    return (
      <div className="initial_cleaning_wrapper">
        <div className="calendar_wrapper">
          { renderAlertTable() }          
          </div>
        <div className="form_wrapper">
                         <div className="card">
            <div className="card-body">
              <div className="form-group">
                <h5>Add Franchise Alert</h5>
                <div className="fitem">
                  <label>Alert Type</label>
                  <Select
                    onChange={(e) => {

                    }}
                    value={''}
                    placeholder={'Title'}
                  >

                  </Select>

                </div>
                <div className="fitem">
                  <label>Start Date</label>
                  <DatePicker
                    style={{ 'width': '100%' }}
                    placeholder="Start Date"
                    name='start_date'
                    format="MM/DD/YYYY"
                    disabledDate={current => {
                      return current && current < moment().add(-1, "day");
                    }}
                    suffixIcon={<i className="las la-calendar"></i>}
                    allowClear={'false'}
                    onChange={(e) => {

                    }}
                    value={''}
                  />
                </div>
                <div className="fitem">
                  <div className="inline-check-list gcheck">
                    <div className="fitem-check mt-10">

                      <input
                        type="checkbox"
                        className="fitem-ck-input"
                        checked={''}
                        id='untill_further_notice'
                        onChange={(e) => {

                        }}
                        name="checkboxsa" />
                      <label htmlFor='untill_further_notice' className="fitem-ck-txt">Until Further Notice</label>
                    </div>
                  </div>
                </div>
                <div className="fitem">
                  <label>End Date</label>
                  <DatePicker
                    placeholder="End Date"
                    disabled={''}
                    style={{ 'width': '100%' }}
                    name='end_date'
                    format="MM/DD/YYYY"

                    suffixIcon={<i className="las la-calendar"></i>}
                    allowClear={false}

                  />
                </div>

                <div className="fitem">
                  <label>Alert Description</label>
                  <textarea
                    placeholder="Alert  Description"
                    value={''}

                  ></textarea>
                </div>
                <div>
                  <button
                    onClick={() => {

                    }}
                    className="btn btn-bdr">Cancel </button>
                    <button className={'btn btn-warning'}>Add  </button>
                  {/* {is_edit ?
                    <button
                      disabled={!is_add_user_Active}
                      className={!is_add_user_Active ? 'btn disabled' : 'btn btn-warning'}
                      onClick={() => { this.updateAlert() }}>Update  </button>
                    :
                    <button
                      disabled={!is_add_user_Active}
                      className={!is_add_user_Active ? 'btn disabled' : 'btn btn-warning'}
                      onClick={() => { this.addNewAlert() }}>Add  </button>} */}
                  </div>
          </div>
            </div>
          </div>
       
        </div>
       
      </div>
    );
    
}
export default FranchiseAlerts;