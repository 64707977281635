import axiosInstance from '../../Lib/Axios';

//Get Technicians
export const getTechnicians = () => {
    return axiosInstance        
      .get(`ftech/field-reassign-technicians/`)     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };
  
  //Get active Technicians
  export const getActiveTechnicians = () => {
    return axiosInstance        
      .get(`ftech/field-reassign-technicians/?user__is_active=true`)     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  //Get technician form specified query
  export const getQueryTechnicians = (queryString) => {
    return axiosInstance        
      .get(`ftech/field-reassign-technicians/${queryString}`)     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  export const updateBulk = (params) => {
    return axiosInstance        
      .patch(`/reassign-technician/bulk-update/`, params)     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  export const getWeeklyAvgData= (params) => {
    return axiosInstance        
      .get(`/ftech/weekly-avg-data`, {params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  export const getServiceStatistics= (params) => {
    return axiosInstance        
      .get(`/ftech/service-allocation/service_stats/`, {params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  // get user of detail
  export const getFieldOfficeStaff= (params,StaffProfileFieldOfficeuuid) => {
    return axiosInstance        
      .get(`/ftech/field-technicians/`, {params,StaffProfileFieldOfficeuuid})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  //Get Service Allocation
  export const getServiceAllocationIntialClean = (params) => {
    return axiosInstance        
      .get(`/ftech/services/`, {params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };


  //Get Residentail Service Allocation
  export const getServiceAllocationResidentail = (params) => {
    params.service_order__field_office__uuid = params?.service_order__field_office__uuid?.toString();
    return axiosInstance        
      .get(`/ftech/services/`, {params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };


  //Get Commercial Service Allocation
  export const getServiceAllocationCommercail = (params) => {
    params.service_order__field_office__uuid = params?.service_order__field_office__uuid?.toString();
    return axiosInstance        
      .get(`/ftech/services/`, {params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };


  //Get Skill Set
  export const updateFieldStaff = (params) => {
    return axiosInstance        
      .patch(`/ftech/field-technicians/${params.uuid}/`, {...params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };
  
  //Get User of detail
  export const getViewByWorkOrder = (params) => {
    return axiosInstance        
      .get(`/service-ftech/`, {params})     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  }

  // get list of franchise
  export const getFranchise = () => {
    console.log('getFranchise');
    let url = `/field-office/?ordering=name`;
    return axiosInstance        
      .get(url)     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  }

  export const getCustomers = (params) => {
    return axiosInstance        
      .get(`crm/customer-user/`, { params })     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  }

  export const getLeads = (params) => {
    return axiosInstance        
      .get(`crm/lead-user`, { params })     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  }

  // Create Customer
  export const bulkReassignTech = (params) => {
    // return (dispatch) => {
    return axiosInstance
      .post(`service-ftech/bulk-tech-reassign/`, params)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
    // };
  };
  export const getBulkServiceOrderTechnician= (preferred_technician_uuid,ordering,franchise_uuid,serviceType) => {
    //alert(preferred_technician_uuid);
    // let preferred_technician_uuid=params.preferred_technician_uuid;
    // let ordering=params.ordering;
 
    return axiosInstance        
    .get(`reassign-techniciann/get_reassign_from/?preferred_technician_uuid=${preferred_technician_uuid}&ordering=${ordering}&field_office__uuid=${franchise_uuid}&service_type=${serviceType}`)     
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data
        }
      });
  };

  export const bulkreassigntechfinalcall = (params) => {
    
    return axiosInstance
      .post(`reassign-techniciann/get_reassign_tech/`, params)
      .then((response) => {
        return response?.message;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.message;
        }
      });
    // };
  };

 export const exportViewByWorkOrder = (params) => {
    return axiosInstance        
      .get(`/service-ftech/export/`, { params })
      .then((response) => {
        if (response.headers['content-type'] === 'application/json') {
          return response.data;
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const timestamp = Date.now();
          link.setAttribute('download', `work_orders_${timestamp}.csv`);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            window.location.assign("/");
            localStorage.clear();
            sessionStorage.clear();
          }
        }
      });
  }