import React from "react";
import ResponsiveCell from "./ResponsiveCells";
import { Table } from "antd";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";
import moment from "moment-timezone";
import { map, isEmpty, orderBy } from "lodash";

const renderContent = (value, row, index) => {
  const obj = {
    children: (
      <span className="type ellipse">{`$ ${formatToCurrency(value)}`}</span>
    ),
    props: {},
  };
  if (row.invoiced_price === undefined) {
    obj.props.colSpan = 0;
  }
  return obj;
};
const renderContentNoC = (value, row, index) => {
  const obj = {
    children: <span className="type ellipse">{formatToCurrency(value)}</span>,
    props: {},
  };
  if (row.invoiced_price === undefined) {
    obj.props.colSpan = 0;
  }
  return obj;
};
const renderContentName = (value, row, index) => {
  const obj = {
    children: <span className="type ellipse">{value}</span>,
    props: {},
  };
  if (row.invoiced_price === undefined) {
    obj.props.colSpan = 4;
    obj.props.className = "ant-table-thead invoice_rec_services";
  } else {
    obj.props.className = "inv-service-list";
  }
  return obj;
};

class DataTable {
  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  };
  InTerritoryRevenueSummaryReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "franchise_name",
        key: "franchise_name",
        className: "tname",
        render: (text, record) => <span className="type">{text}</span>,
      },
      {
        title: "Zipcode",
        dataIndex: "zipcode",
        key: "zipcode",
        className: "tname",
        render: (row = []) => {
          return (
            <ResponsiveCell value={Array.isArray(row) ? row.join(", ") : row} />
          );
        },
      },
      Table.EXPAND_COLUMN,
      {
        title: "Territory Type",
        dataIndex: "type",
        key: "type",
        className: "tname",
        render: (text, record) => ({
          children: <span className="type ellipse">{text}</span>,
          props: {},
        }),
      },
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Monthly Revenue",
        dataIndex: "monthly_revenue",
        key: "monthly_revenue",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "YTD Revenue",
        dataIndex: "ytd_revenue",
        key: "ytd_revenue",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
    ];
    return revenueReportColumns;
  };
  InTerritoryRevenueTypeReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "franchise_name",
        key: "franchise_name",
        className: "tname",
        render: (text, record) => <span className="type">{text}</span>,
      },
      {
        title: "Zipcode",
        dataIndex: "zipcode",
        key: "zipcode",
        className: "tname",
        render: (row = []) => {
          return (
            <ResponsiveCell value={Array.isArray(row) ? row.join(", ") : row} />
          );
        },
      },
      Table.EXPAND_COLUMN,
      {
        title: "Territory Type",
        dataIndex: "type",
        key: "type",
        className: "tname",
        render: (text, record) => ({
          children: <span className="type ellipse">{text}</span>,
          props: {},
        }),
      },
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Monthly Revenue",
        dataIndex: "monthly_revenue",
        key: "monthly_revenue",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "YTD Revenue",
        dataIndex: "ytd_revenue",
        key: "ytd_revenue",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
    ];
    return revenueReportColumns;
  };
  InTerritoryRevenueZipReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "franchise_name",
        key: "franchise_name",
        className: "tname",
        render: (text, record) => <span className="type">{text}</span>,
      },
      {
        title: "Zipcode",
        dataIndex: "zipcode",
        key: "zipcode",
        className: "tname",
        render: (row = []) => {
          return (
            <ResponsiveCell value={Array.isArray(row) ? row.join(", ") : row} />
          );
        },
      },
      {
        title: "Territory Type",
        dataIndex: "type",
        key: "type",
        className: "tname",
        render: (text, record) => ({
          children: <span className="type ellipse">{text}</span>,
          props: {},
        }),
      },
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Monthly Revenue",
        dataIndex: "monthly_revenue",
        key: "monthly_revenue",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "YTD Revenue",
        dataIndex: "ytd_revenue",
        key: "ytd_revenue",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
    ];
    return revenueReportColumns;
  };
  ChemicalReport = (self, handelOpenForm) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "field_office",
        key: "field_office",
        filterName: "service__client__field_office__name",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "first_name",
        filterName: "service__client__first_name",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{record.first_name + " " + record.last_name}</span>
      },      {
        title: "Address",
        dataIndex: "customer_address",
        key: "customer_address",
        filterName: "service__service_order__pool_characteristic__service_address__address",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Tech Name",
        dataIndex: "tech_name",
        key: "tech_name",
        filterName: "service__technician__first_name",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{(record.tech_name === null || record.tech_name === "")? "Not Assigned":record.tech_name  }</span>,
      },
      {
        title: "Date Serviced",
        dataIndex: "job_date",
        key: "job_date",
        filterName: "service__scheduled_appointment",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{text}</span>,
      },
      {
        title: "Chemical Cost",
        dataIndex: "chemicals_cost",
        key: "chemicals_cost",
        sorter: true,
        filterName: "chemicals_cost",
        className: "tchemical-rep",
        render: (text, record) => (
          <span className="type ellipse">
            ${text && text !== "0" ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },  
      {
        title: "Job Number",
        dataIndex: "work_order_number",
        key: "work_order_number",
        sorter: true,
        filterName: "service__id",
        className: "tchemical-rep",
        render: (text, record) => (
            <a style={{cursor: 'pointer', fontWeight: "bold"}} onClick={() =>handelOpenForm(record)}>
              {" " +record?.work_order_number}
            </a>
        ),
      },
      {
       title: "Chemical Readings",
       className: "tchemical-grp-header-rep",
       children:[      
        {
          title: "Free Chlorine",
          dataIndex: "FreeChlorine",
          key: "FreeChlorine",
          sorter: true,
          filterName: "FreeChlorine",
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.FreeChlorine ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "pH",
          dataIndex: "pH",
          key: "pH",
          sorter: true,
          filterName: "pH",
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.pH ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Total Alkalinity",
          dataIndex: "TotalAlkalinity",
          key: "TotalAlkalinity",
          filterName: "TotalAlkalinity",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.TotalAlkalinity ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Calcium Hardness",
          dataIndex: "CalciumHardness",
          key: "CalciumHardness",
          filterName: "CalciumHardness",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumHardness ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Salt Level",
          dataIndex: "SaltLevel",
          key: "SaltLevel",
          filterName: "SaltLevel",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.SaltLevel ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Phosphate",
          dataIndex: "Phosphate",
          key: "Phosphate",
          filterName: "Phosphate",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.Phosphate ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Filter Pressure",
          dataIndex: "FilterPressure",
          key: "FilterPressure",
          filterName: "FilterPressure",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.FilterPressure ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Clarity",
          dataIndex: "Clarity",
          key: "Clarity",
          filterName: "Clarity",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.Clarity ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Pool Temp",
          dataIndex: "PoolTemp",
          key: "PoolTemp",
          filterName: "PoolTemp",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.PoolTemp ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "TDS",
          dataIndex: "Tds",
          key: "Tds",
          filterName: "Tds",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.Tds ?? 0, 10).toFixed(2)}</span>
        }
      ]},
      {
       title: "Chemicals Added",
       className: "tchemical-grp-header-rep",
       children:[      
        {
          title: "Chlorine Tabs",
          dataIndex: "ChlorineTabs",
          key: "ChlorineTabs",
          filterName: "ChlorineTabs",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.ChlorineTabs ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Chlorine Liquid",
          dataIndex: "ChlorineLiquid",
          key: "ChlorineLiquid",
          filterName: "ChlorineLiquid",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.ChlorineLiquid ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Calcium Hypochlorite",
          dataIndex: "CalciumHypochloride",
          key: "CalciumHypochloride",
          filterName: "CalciumHypochloride",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumHypochloride ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Muriatic Acid",
          dataIndex: "muriatic_acid",
          key: "muriatic_acid",
          filterName: "muriatic_acid",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.muriatic_acid ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Sodium Bicarbonate",
          dataIndex: "SodiumBicarbonate",
          key: "SodiumBicarbonate",
          filterName: "SodiumBicarbonate",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.SodiumBicarbonate ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Soda Ash",
          dataIndex: "SodaAsh",
          key: "SodaAsh",
          filterName: "SodaAsh",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.SodaAsh ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Salt Bags",
          dataIndex: "SaltBags",
          key: "SaltBags",
          filterName: "SaltBags",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.SaltBags ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Cyanuric Acid",
          dataIndex: "CyanuricAcidLbs",
          key: "CyanuricAcidLbs",
          filterName: "CyanuricAcidLbs",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.CyanuricAcidLbs ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Dichlor Lbs",
          dataIndex: "DichlorLbs",
          key: "DichlorLbs",
          filterName: "DichlorLbs",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.DichlorLbs ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "DE Powder Lbs",
          dataIndex: "DEPowderLbs",
          key: "DEPowderLbs",
          filterName: "DEPowderLbs",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.DEPowderLbs ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Calcium Chloride Lbs",
          dataIndex: "CalciumChlorideLbs",
          key: "CalciumChlorideLbs",
          filterName: "CalciumChlorideLbs",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumChlorideLbs ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Algaecide Oz",
          dataIndex: "AlgaecideOz",
          key: "AlgaecideOz",
          filterName: "AlgaecideOz",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.AlgaecideOz ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Stain Scale Control",
          dataIndex: "StainScaleControl",
          key: "StainScaleControl",
          filterName: "StainScaleControl",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.StainScaleControl ?? 0, 10).toFixed(2)}</span>
        },
        {
          title: "Phosphate Remover",
          dataIndex: "PhosphateRemover",
          key: "PhosphateRemover",
          filterName: "PhosphateRemover",
          sorter: true,
          className: "tchemical-rep",
          render: (text, record) => <span className="ellipse">{parseFloat(record.PhosphateRemover ?? 0, 10).toFixed(2)}</span>
        }
       ]}
    ];
    return revenueReportColumns.map((item) =>
      item?.filterName ? item : { ...item, filterName: item.dataIndex }
    );
  };
  ChemicalSummaryGroupedByCustomer = (self) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "field_office",
        key: "field_office",
        filterName: "field_office",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "first_name",
        filterName: "first_name",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{record.first_name + " " + record.last_name}</span>
      },
      {
        title: "Address",
        dataIndex: "customer_address",
        key: "customer_address",
        filterName: "customer_address",
        sorter: true,
        className: "tchemical-rep",
      },      
      {
        title: "Total Jobs",
        dataIndex: "total_jobs",
        key: "total_jobs",    
        filterName: "total_jobs",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Chemical Cost",
        dataIndex: "chemicals_cost",
        key: "chemicals_cost",
        filterName: "chemicals_cost",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => (
          <span className="type ellipse">
            ${text && text !== "0" ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      }, 
      {
        title: "Dates Serviced",
        dataIndex: "dates_serviced",
        key: "dates_serviced",
        sorter: false,
        className: "tchemical-rep",
        render: (row = []) => {
          return (
            <ResponsiveCell
              length={10}
              value={Array.isArray(row) ? row.join(", ") : row}
            />
          );
        },
      }, 
      {
        title: "Chlorine Tabs",
        dataIndex: "ChlorineTabs",
        key: "ChlorineTabs",
        sorter: true,
        filterName: "ChlorineTabs",
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.ChlorineTabs ?? 0, 10).toFixed(2)}</span>
      }, 
      {
        title: "Chlorine Liquid",
        dataIndex: "ChlorineLiquid",
        key: "ChlorineLiquid",
        filterName: "ChlorineLiquid",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.ChlorineLiquid ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Calcium Hypochloride",
        dataIndex: "CalciumHypochloride",
        key: "CalciumHypochloride",
        filterName: "CalciumHypochloride",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumHypochloride ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Muriatic acid",
        dataIndex: "muriatic_acid",
        key: "muriatic_acid",
        filterName: "muriatic_acid",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.muriatic_acid ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Sodium Bicarbonate",
        dataIndex: "SodiumBicarbonate",
        key: "SodiumBicarbonate",
        filterName: "SodiumBicarbonate",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.SodiumBicarbonate ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Soda Ash",
        dataIndex: "SodaAsh",
        key: "SodaAsh",
        filterName: "SodaAsh",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.SodaAsh ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Salt Bags",
        dataIndex: "SaltBags",
        key: "SaltBags",
        filterName: "SaltBags",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.SaltBags ?? 0, 10).toFixed(2)}</span>
      },  
      {
        title: "Cyanuric Acid",
        dataIndex: "CyanuricAcid",
        key: "CyanuricAcid",
        filterName: "CyanuricAcid",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.CyanuricAcid ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Dichlor Lbs",
        dataIndex: "DichlorLbs",
        key: "DichlorLbs",
        filterName: "DichlorLbs",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.DichlorLbs ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "DE Powder Lbs",
        dataIndex: "DEPowderLbs",
        key: "DEPowderLbs",
        filterName: "DEPowderLbs",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.DEPowderLbs ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Calcium Chloride Lbs",
        dataIndex: "CalciumChlorideLbs",
        key: "CalciumChlorideLbs",
        filterName: "CalciumChlorideLbs",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumChlorideLbs ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Algaecide Oz",
        dataIndex: "AlgaecideOz",
        key: "AlgaecideOz",
        filterName: "AlgaecideOz",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.AlgaecideOz ?? 0, 10).toFixed(2)}</span>
      }, 
      {
        title: "Stain Scale Control",
        dataIndex: "StainScaleControl",
        key: "StainScaleControl",
        filterName: "StainScaleControl",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.StainScaleControl ?? 0, 10).toFixed(2)}</span>
      }, 
      {
        title: "Phosphate Remover",
        dataIndex: "PhosphateRemover",
        key: "PhosphateRemover",
        filterName: "PhosphateRemover",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.PhosphateRemover ?? 0, 10).toFixed(2)}</span>
      }
    ];
    return revenueReportColumns.map((item) =>
      item?.filterName ? item : { ...item, filterName: item.dataIndex }
    );
  };
  ChemicalSummaryGroupedByTechnician = (self) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "field_office",
        key: "field_office",
        filterName: "field_office",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Tech Name",
        dataIndex: "tech_first_name",
        key: "tech_first_name",
        filterName: "tech_first_name",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{(record.tech_first_name === null || record.tech_first_name === "")&&(record.tech_last_name === null || record.tech_last_name === "")? "Not Assigned":(record.tech_first_name)+ " " +(record.tech_last_name)  }</span>
      },     
      {
        title: "Total Jobs",
        dataIndex: "total_jobs",
        key: "total_jobs",  
        filterName: "total_jobs",  
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Chemical Cost",
        dataIndex: "chemicals_cost",
        key: "chemicals_cost",
        filterName: "chemicals_cost",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => (
          <span className="type ellipse">
            ${text && text !== "0" ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      }, 
      {
        title: "Dates Serviced",
        dataIndex: "dates_serviced",
        key: "dates_serviced",
        sorter: false,
        className: "tchemical-rep",
        render: (row = []) => {
          return (
            <ResponsiveCell
              length={10}
              value={Array.isArray(row) ? row.join(", ") : row}
            />
          );
        },
      }, 
      {
        title: "Chlorine Tabs",
        dataIndex: "ChlorineTabs",
        key: "ChlorineTabs",
        sorter: true,
        filterName: "ChlorineTabs",
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.ChlorineTabs ?? 0, 10).toFixed(2)}</span>
      }, 
      {
        title: "Chlorine Liquid",
        dataIndex: "ChlorineLiquid",
        key: "ChlorineLiquid",
        filterName: "ChlorineLiquid",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.ChlorineLiquid ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Calcium Hypochloride",
        dataIndex: "CalciumHypochloride",
        key: "CalciumHypochloride",
        filterName: "CalciumHypochloride",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumHypochloride ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Muriatic acid",
        dataIndex: "muriatic_acid",
        key: "muriatic_acid",
        filterName: "muriatic_acid",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.muriatic_acid ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Sodium Bicarbonate",
        dataIndex: "SodiumBicarbonate",
        key: "SodiumBicarbonate",
        filterName: "SodiumBicarbonate",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.SodiumBicarbonate ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Soda Ash",
        dataIndex: "SodaAsh",
        key: "SodaAsh",
        filterName: "SodaAsh",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.SodaAsh ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Salt Bags",
        dataIndex: "SaltBags",
        key: "SaltBags",
        filterName: "SaltBags",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.SaltBags ?? 0, 10).toFixed(2)}</span>
      },  
      {
        title: "Cyanuric Acid",
        dataIndex: "CyanuricAcid",
        key: "CyanuricAcid",
        filterName: "CyanuricAcid",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.CyanuricAcid ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Dichlor Lbs",
        dataIndex: "DichlorLbs",
        key: "DichlorLbs",
        filterName: "DichlorLbs",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.DichlorLbs ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "DE Powder Lbs",
        dataIndex: "DEPowderLbs",
        key: "DEPowderLbs",
        filterName: "DEPowderLbs",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.DEPowderLbs ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Calcium Chloride Lbs",
        dataIndex: "CalciumChlorideLbs",
        key: "CalciumChlorideLbs",
        filterName: "CalciumChlorideLbs",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.CalciumChlorideLbs ?? 0, 10).toFixed(2)}</span>
      },
      {
        title: "Algaecide Oz",
        dataIndex: "AlgaecideOz",
        key: "AlgaecideOz",
        filterName: "AlgaecideOz",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.AlgaecideOz ?? 0, 10).toFixed(2)}</span>
      }, 
      {
        title: "Stain Scale Control",
        dataIndex: "StainScaleControl",
        key: "StainScaleControl",
        filterName: "StainScaleControl",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.StainScaleControl ?? 0, 10).toFixed(2)}</span>
      }, 
      {
        title: "Phosphate Remover",
        dataIndex: "PhosphateRemover",
        key: "PhosphateRemover",
        filterName: "PhosphateRemover",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{parseFloat(record.PhosphateRemover ?? 0, 10).toFixed(2)}</span>
      }      
    ];
    return revenueReportColumns;
  };
  InvoiceReconciliationServicesReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Services",
        dataIndex: "name",
        key: "name",
        className: "tname",
        render: renderContentName,
      },
      {
        title: "Invoice",
        dataIndex: "invoiced_price",
        key: "invoiced_price",
        className: "tname",
        render: renderContent,
      },
      {
        title: "Jobs Complete",
        dataIndex: "completed_price",
        key: "completed_price",
        className: "tname",
        render: renderContent,
      },
      {
        title: "Payment",
        dataIndex: "paid_amount",
        key: "paid_amount",
        className: "tname",
        render: renderContent,
      },
    ];
    return revenueReportColumns;
  };
  InvoiceReconciliationDiscountReports = (self) => {
    const revenueReportColumns = [
      {
        title: "Discounts",
        dataIndex: "name",
        key: "name",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Invoice",
        dataIndex: "invoiced_discount",
        key: "invoiced_discount",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Jobs Complete",
        dataIndex: "completed_discount",
        key: "completed_discount",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Payment",
        dataIndex: "paid_discount",
        key: "paid_discount",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
    ];
    return revenueReportColumns;
  };
  InvoiceReconciliationTaxReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Taxes",
        dataIndex: "name",
        key: "name",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Invoice",
        dataIndex: "invoiced_tax",
        key: "invoiced_tax",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Jobs Complete",
        dataIndex: "completed_tax",
        key: "completed_tax",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Payment",
        dataIndex: "paid_tax",
        key: "paid_tax",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
    ];
    return revenueReportColumns;
  };
  
  LeadReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Lead Name",
        dataIndex: "first_name",
        key: "lead_name",
        className: "tphnum",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Franchise",
        dataIndex: "field_office__name",
        filterName: "field_office__name",
        key: "franchise",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.field_office?.name}</span>,
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },
      {
        title: "Type",
        dataIndex: "lead_type",
        key: "lead_type",
        sorter: true,
        className: "tlead-customer-rep",        
        render: (text, record) => <span>{record.lead_type}</span>,
      },
      {
        title: "Lead Status",
        dataIndex: "is_active",
        key: "is_active",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.is_active?"Active":"Inactive" }</span>,
      },
      {
        title: "Lead Creation Date",
        dataIndex: "created_at",
        key: "joined",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{record.created_at? record.created_at.split('T')[0]:""}</span>;
        },
      },
      {
        title: "Lead Stage",
        dataIndex: "lead_stage",
        key: "lead_stage",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.lead_stage}</span>,
      },
      {
        title: "Last Contacted Date",
        dataIndex: "last_contacted",
        key: "last_contacted",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? this.UTCToLocal(text).format("MMM DD, YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Outreach",
        dataIndex: "outreach",
        key: "outreach",
        className: "tlead-customer-rep",
        sorter: true,
        render: (text, record) => <span>{record?.outreach}</span>,
      },
      {
        title: "Channel Attribution",
        dataIndex: "marketing_source",
        key: "marketing_source",
        sorter: true,
        filterName: "marketing_source",
        className: "tlead-customer-rep",
      },
      {
        title: "Channel Attribution Detail",
        dataIndex: "marketing_source_detail",
        key: "marketing_source_detail",
        sorter: true,
        filterName: "marketing_source_detail",
        className: "tlead-customer-rep",
      },
      {
        title: "How did you hear about us",
        dataIndex: "hear_about_us",
        key: "hear_about_us",
        sorter: true,
        filterName: "hear_about_us",
        className: "tlead-customer-rep",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Address",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const address =
            record?.address?.length > 0
              ? `${record?.address?.[0]?.address || ""}, ${record?.address?.[0]?.city || ""
              }, ${record?.address?.[0]?.state || ""} ${record?.address?.[0]?.zip_code || ""
              }`
              : "";
            return (
              <span
                className="ellipse"
                id={`customerAddress${record.uuid}`}
                dangerouslySetInnerHTML={{ __html: `${address}` }}
              ></span>
            );
          
        },
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
    ];
    return revenueReportColumns;
  };
  ExpiringCustomerPaymentMethod = (self) => {
    const revenueReportColumns = [
      {
        title: "Franchise",
        dataIndex: "field_office",
        key: "field_office",
        sorter: true,
        filterName: "user__field_office",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        sorter: true,
        filterName: "user__first_name",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Card Type",
        dataIndex: "card_type",
        key: "card_type",
        sorter: false,
        filterName: "card_type",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Expiration Month / Year",
        dataIndex: "card_expiry_date",
        key: "card_expiry_date",
        sorter: false,
        filterName: "card_expiry_date",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Last Notification Date",
        dataIndex: "last_reminder",
        key: "last_reminder",
        sorter: true,
        filterName: "last_reminder",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">{text === null ? "---" : text}</span>
        ),
      },
      {
        title: "Account Balance",
        dataIndex: "balance",
        key: "balance",
        sorter: false,
        filterName: "balance",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text && text !== "0" ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
    ];
    return revenueReportColumns;
  };
  WorkOrderReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Work Order #",
        dataIndex: "work_order_number",
        key: "work_order_number",
        sorter: true,
        filterName: "work_order_number",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: true,
        filterName: "status",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        sorter: false,
        filterName: "customer_name",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Technician",
        dataIndex: "technician",
        key: "technician",
        sorter: false,
        filterName: "technician",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Service Address",
        dataIndex: "service_address",
        key: "service_address",
        sorter: true,
        filterName: "service_order__service_address__city",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">{text === null ? "---" : text}</span>
        ),
      },
      {
        title: "Customer Type",
        dataIndex: "customer_type",
        key: "customer_type",
        sorter: false,
        filterName: "customer_type",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Service Frequency",
        dataIndex: "frequency",
        key: "frequency",
        sorter: false,
        filterName: "frequency",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Amount",
        dataIndex: "total_price",
        key: "total_price",
        sorter: false,
        filterName: "total_price",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
    ];
    return revenueReportColumns;
  };
  AgingSummary = (self) => {
    const revenueReportColumns = [
      {
        title: "Customer Name",
        dataIndex: "customer_name",
        key: "customer_name",
        sorter: true,
        filterName: "user__first_name",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Billing Address",
        dataIndex: "billing_address",
        key: "billing_address",
        sorter: true,
        filterName: "billing_address__address",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: false,
        filterName: "status",
        className: "tname",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Acct Balance",
        dataIndex: "account_balance",
        key: "account_balance",
        sorter: false,
        filterName: "account_balance",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "1-30 Days",
        dataIndex: "first_days",
        key: "first_days",
        sorter: false,
        filterName: "first_days",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "31-60 Days",
        dataIndex: "medium_days",
        key: "medium_days",
        sorter: false,
        filterName: "medium_days",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "61-90 Days",
        dataIndex: "old_days",
        key: "old_days",
        sorter: false,
        filterName: "old_days",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "91 + Days",
        dataIndex: "older_days",
        key: "older_days",
        sorter: false,
        filterName: "older_days",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: false,
        filterName: "total",
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            ${text ? formatToCurrency(text) : "0.00"}
          </span>
        ),
      },
    ];
    return revenueReportColumns;
  };
  ChemicalPricingReport = (self) => {
    const revenueReportColumns = [
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        filterName: "service__client__first_name",
        sorter: true,
        className: "tname",
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        filterName: "service__client__last_name",
        sorter: true,
        className: "tname",
      },
      {
        title: "Job Date",
        dataIndex: "job_date",
        key: "job_date",
        filterName: "service__scheduled_appointment",
        sorter: true,
        className: "tname",
        render: (text, record) => <span className="ellipse">{text}</span>,
      },
      {
        title: "Chlorine Tabs Amount",
        dataIndex: "chlorine_tabs_amount",
        key: "chlorine_tabs_amount",
        sorter: true,
        filterName: "chlorine_tabs_amount",
        className: "tname",
      },
      {
        title: "Chlorine tabs total",
        dataIndex: "chlorine_tabs_total",
        key: "chlorine_tabs_total",
        sorter: true,
        filterName: "chlorine_tabs_total",
        className: "tname",
      },
      {
        title: "Dichlor Amount",
        dataIndex: "dichlor_amount",
        key: "dichlor_amount",
        filterName: "dichlor_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Dichlor Total",
        dataIndex: "dichlor_total",
        key: "dichlor_total",
        filterName: "dichlor_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Muriatics Aacid Amount",
        dataIndex: "muriatics_acid_amount",
        key: "muriatics_acid_amount",
        filterName: "muriatics_acid_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Muriatics Acid Total",
        dataIndex: "muriatics_acid_total",
        key: "muriatics_acid_total",
        filterName: "muriatics_acid_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Salt Amount",
        dataIndex: "salt_amount",
        key: "salt_amount",
        filterName: "salt_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Salt Total",
        dataIndex: "salt_total",
        key: "salt_total",
        filterName: "salt_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Cyanuric Acid Amount",
        dataIndex: "cyanuric_acid_amount",
        key: "cyanuric_acid_amount",
        filterName: "cyanuric_acid_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Cyanuric Acid Total",
        dataIndex: "cyanuric_acid_total",
        key: "cyanuric_acid_total",
        filterName: "cyanuric_acid_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Calcium Chloride Amount",
        dataIndex: "calcium_chloride_amount",
        key: "calcium_chloride_amount",
        filterName: "calcium_chloride_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Calcium Chloride Total",
        dataIndex: "calcium_chloride_total",
        key: "calcium_chloride_total",
        filterName: "calcium_chloride_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Soda Ash Amount",
        dataIndex: "soda_ash_amount",
        key: "soda_ash_amount",
        filterName: "soda_ash_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Soda Ash Total",
        dataIndex: "soda_ash_total",
        key: "soda_ash_total",
        filterName: "soda_ash_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Phosphate Remover Amount",
        dataIndex: "phosphate_remover_amount",
        key: "phosphate_remover_amount",
        filterName: "phosphate_remover_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Phosphate Remover Total",
        dataIndex: "phosphate_remover_total",
        key: "phosphate_remover_total",
        filterName: "phosphate_remover_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Chlorine Liquid Amount",
        dataIndex: "chlorine_liquid_amount",
        key: "chlorine_liquid_amount",
        filterName: "chlorine_liquid_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Chlorine Liquid Total",
        dataIndex: "chlorine_liquid_total",
        key: "chlorine_liquid_total",
        filterName: "chlorine_liquid_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "DE Powder Amount",
        dataIndex: "de_powder_amount",
        key: "de_powder_amount",
        filterName: "de_powder_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "DE Powder Total",
        dataIndex: "de_powder_total",
        key: "de_powder_total",
        filterName: "de_powder_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Sodium Bicarbonate Amount",
        dataIndex: "sodium_bicarbonate_amount",
        key: "sodium_bicarbonate_amount",
        filterName: "sodium_bicarbonate_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Sodium Bicarbonate Total",
        dataIndex: "sodium_bicarbonate_total",
        key: "sodium_bicarbonate_total",
        filterName: "sodium_bicarbonate_total",
        sorter: true,
        className: "tname",
      },
      {
        title: "Stain Scale Amount",
        dataIndex: "stain_scale_amount",
        key: "stain_scale_amount",
        filterName: "stain_scale_amount",
        sorter: true,
        className: "tname",
      },
      {
        title: "Stain Scale Total",
        dataIndex: "stain_scale_total",
        key: "stain_scale_total",
        filterName: "stain_scale_total",
        sorter: true,
        className: "tname",
      },
    ];
    return revenueReportColumns.map((item) =>
      item?.filterName ? item : { ...item, filterName: item.dataIndex }
    );
  };
  
  CustomersReport = (self) => {
    const revenueReportColumns = [
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "customer_name",
        className: "tphnum",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const name = record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "";
          return (
            <span
              className="ellipse"
              id={`customerName${record.uuid}`}
              dangerouslySetInnerHTML={{ __html: `<b>${name}</b>` }}
            ></span>
          );
        },
      },
      {
        title: "Franchise",
        dataIndex: "field_office__name",
        filterName: "field_office__name",
        key: "franchise",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.field_office?.name}</span>,
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const companyName = record?.company_name !== undefined && record?.company_name !== null ? record?.company_name : '';
          return (
            <span className="ellipse" dangerouslySetInnerHTML={{ __html: `<b>${companyName}</b>` }}></span>
          )
        }
      },
      {
        title: "Type",
        dataIndex: "customer_type",
        key: "customer_type",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record.customer_type}</span>,
      },
      {
        title: "Customer Status",
        dataIndex: "is_active",
        key: "is_active",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) =>{
          return <span>{record?.is_active?"Active":"Inactive" }</span>;
          
        } 
      },
      {
        title: "Service Type",
        dataIndex: "service_type",
        key: "service_type",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.service_type}</span>,
      },
      {
        title: "Maintenance Frequency",
        dataIndex: "maintenance_frequency",
        key: "maintenance_frequency",
        sorter: true,
        filterName: "maintenance_frequency",
        className: "tlead-customer-rep",
      },
      {
        title: "Joined Date",
        dataIndex: "created_at",
        key: "joined",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          const local_time = this.UTCToLocal(record?.created_at);
          return <span>{local_time.fromNow(true)}</span>;
        },
      },
      {
        title: "Last Service Date",
        dataIndex: "last_service",
        key: "last_service",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text) => (
          <span>
            {text && !isNaN(new Date(text).getTime())
              ? moment(text).format('MMM DD, YYYY')
              : ""}
          </span>
        ),
      },
      {
        title: "Corporate Address",
        dataIndex: "invoice_address",
        key: "invoice_address",
        sorter: true,
        filterName: "invoice_address",
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.invoice_address?.formatted_address}</span>,
      },
      {
        title: "Residential Service Address",
        dataIndex: "service_address",
        key: "service_address",
        sorter: true,
        filterName: "service_address",
        className: "tlead-customer-rep",
        render: (row = []) => {
          if (row.length > 1)
            return (
              <ResponsiveCell value={Array.isArray(row) ? row.join("; ") : row} />
            );
          else 
            return(
              <span>{Array.isArray(row) ? row.join(", ") : row}</span>
            );
        },
      },
      {
        title: "Outreach",
        dataIndex: "outreach",
        key: "outreach",
        sorter: true,
        filterName: "outreach",
        className: "tlead-customer-rep",
      },
      {
        title: "Channel Attribution",
        dataIndex: "marketing_source",
        key: "marketing_source",
        sorter: true,
        filterName: "marketing_source",
        className: "tlead-customer-rep",
      },
      {
        title: "How did you hear about us",
        dataIndex: "hear_about_us",
        key: "hear_about_us",
        sorter: true,
        filterName: "hear_about_us",
        className: "tlead-customer-rep",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => <span>{record?.email}</span>,
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers__phone",
        key: "phone_numbers__phone",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => {
          let phone_numbers = "";
          !isEmpty(record?.phone_numbers) &&
            map(record?.phone_numbers, (item) => {
              phone_numbers = item?.primary && item?.phone;
            });
          return (
            <span>
              {!isEmpty(record?.phone_numbers)
                ? phone_numbers
                  ? `${phone_numbers}`
                  : `${record?.phone_numbers?.[0]?.phone
                  }`
                : ""}
            </span>
          );
        },
      },
      {
        title: "Lead to Customer Conversion (days)",
        dataIndex: "lead_to_customer_conversion_time",
        key: "lead_to_customer_conversion_time",
        sorter: true,
        filterName: "lead_to_customer_conversion_time",
        className: "tlead-customer-rep",
      },
      {
        title: "Invoiced Amount",
        dataIndex: "invoiced_amount",
        key: "outstanding",
        sorter: true,
        className: "tlead-customer-rep",
        render: (text, record) => (
          <span>
            <b>{`$${formatToCurrency(
              record?.invoiced_amount
            )}`}</b>
          </span>
        ),
      },
    ];
    return revenueReportColumns;
  };
  TechnicianNotesReport = (self, handelOpenForm) => {
    const revenueReportColumns = [
      {
        title: "Franchise Name",
        dataIndex: "field_office",
        key: "field_office",
        filterName: "service_order__client__field_office__name",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Job Number",
        dataIndex: "work_order_number",
        key: "work_order_number",
        sorter: true,
        filterName: "id",
        className: "tchemical-rep",
        render: (text, record) => (
          record.form_url === null? 
            <span className="ellipse">{" " +record?.work_order_number}</span>
          :
            <a style={{cursor: 'pointer', fontWeight: "bold"}} onClick={() =>handelOpenForm(record)}>
              {" " +record?.work_order_number}
            </a>
        ),
      },
      {
        title: "Job Name",
        dataIndex: "job_name",
        key: "job_name",
        sorter: true,
        filterName: "job_name",
        className: "tchemical-rep",        
      },
      {
        title: "Customer Type",
        dataIndex: "customer_type",
        key: "customer_type",
        filterName: "customer_type",
        sorter: true,
        className: "tchemical-rep",
      },  
      {
        title: "Customer Name",
        dataIndex: "first_name",
        key: "first_name",
        filterName: "service_order__client__first_name",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{record.first_name + " " + record.last_name}</span>
      },
      {
        title: "Company Name",
        dataIndex: "company_name",
        key: "company_name",
        filterName: "company_name",
        sorter: true,
        className: "tchemical-rep",
      }, 
      {
        title: "Service Type",
        dataIndex: "service_name",
        key: "service_name",
        filterName: "service_name",
        sorter: true,
        className: "tchemical-rep",
      },
      {
        title: "Service Date",
        dataIndex: "job_date",
        key: "job_date",
        filterName: "scheduled_appointment",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{text}</span>,
      },
      {
        title: "Technician Name",
        dataIndex: "tech_name",
        key: "tech_name",
        filterName: "technician__first_name",
        sorter: true,
        className: "tchemical-rep",
        render: (text, record) => <span className="ellipse">{(record.tech_name === null || record.tech_name === "")? "Not Assigned":record.tech_name  }</span>,
      },      
      {
        title: "Tech Notes",
        dataIndex: "tech_notes",
        key: "tech_notes",
        filterName: "tech_notes",
        sorter: true,
        className: "tchemical-rep",
        render: (row = []) => {
          return (
            <ResponsiveCell
              length={100}
              value={row != null ? row : ""}
            />
          );
        }
      },
    ];
    return revenueReportColumns.map((item) =>
      item?.filterName ? item : { ...item, filterName: item.dataIndex }
    );
  };

}
export default new DataTable();
