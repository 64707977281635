import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import _ from "lodash"
import Login from './Login/Login';
import Dashboard from './Dashboard/Page/Dashboard'
import ReportsListPage from './Reports/Pages/ReportsList.Page';
import ReportPage from './Reports/Pages/Report.Page';
import CalendarPage from './Calendar/Pages/Calendar.Page';
import store from './Store/store';
import NewCustomer from './Customer/Residential/NewCustomer/NewCustomer';
import Detail from './Customer/Residential/CustomerDetails/Detail';
import CustomerList from './Customer/Common/CustomerList';
import MaintenancePoolReport from './Forms/MaintenancePoolReport';
import SwimmingPoolMaintenanceProgramForm from './Forms/SwimmingPoolMaintenanceProgramForm';
import FilterCleanForm from './Forms/FilterCleanForm';
import PoolInspectionForm from './Forms/PoolInspectionForm';
import UserList from './UserManagement/UserList';
import FranchiseList from './Franchise/FranchiseList/FranchiseList';
import FranchiseDetail from './Franchise/Common/FranchiseDetail';
import ForgotPassword from './Login/ForgotPassword';
import ResetPassword from './Login/ResetPassword';
import WeekSetup from './FieldTech/WeekSetup/WeekSetup';
import AccountList from './Accounting/AccountList';
import BulkReassign from './FieldTech/BulkReassign/BulkReassign';
import CommercialCustomerList from './Customer/Commercial/CommercialCustomerList';
import LeadDetail from './Customer/Commercial/LeadDetail';
import ServiceOrderLead from './Customer/Commercial/ServiceOrderLead';
import ViewByWorkOrder from './FieldTech/ViewByWorkOrder/ViewByWorkOrder';
import AddCommunityInfo from './Customer/Commercial/AddCommunityInfo';
import NotFound from './DefaultPages/Pages/NotFound';
import Forbidden from './DefaultPages/Pages/Forbidden';
import UnderConstruction from './DefaultPages/Pages/UnderConstruction';
import CommercialCustomerDetail from "./Customer/Commercial/CustomerDetails/Detail";
import EditWorkOrderURL from "./Customer/Residential/CustomerDetails/Services/EditWorkOrderURL";
import CaseManagement from './CaseManagement/Page/CaseManagement';
import Register from './Auth/Register/Register';
import LeadDetails from './Customer/Residential/Lead/DetailsInfo';
import PreferredTechnician from './FieldTech/PreferredTechnician/PreferredTechnician';
import TechGlancePage from './TechGlance/Pages/TechGlance.Page';
import PriceCatalog from './PriceCatalog/Pages/PriceCatalog.Page';
import WorkOrdersIndex from './Accounting/WorkOrdersIndex';
import PlotJobMap from './FieldTech/PlotJobMap/Pages/PlotJobMap.Page'
import CustomerLeadList from './Customer/CustomerLeadList';
import PaymentPage from './Payment/Page/Payment.Page';
import ProposalList from './Proposal/ProposalList/ProposalList';
import Proposal from './Components/proposal';

const App = () => {
  const authorized = ["admin", "franchise_owner", "operations_mgr"];

  function PrivateRoute({ children, redirect, fallback, isFieldTech }) {
    const auth = useAuth();
    if(auth.accessToken){
      if(_.includes(authorized, auth.authUser.name) || isFieldTech){
        return children
      }
      return <Navigate to={fallback} />
    }
    return <Navigate to="/" />;
  }

  function useAuth() {
    const accessToken = JSON.parse(localStorage.getItem("authToken"))
      ? JSON.parse(localStorage.getItem("authToken"))?.key
      : "";

      const user_roles = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles : [];
      //If the user has dual role, it will take the role different than technician      
      const authUser = user_roles.length > 1
        ? JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles.find(i => i.name !== 'field_tech')
        : user_roles.length === 1
        ? JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles?.[0]
        : "";
    return {accessToken, authUser};
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route path='/register/:token' element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} exact />
          <Route path="/reset-password/:token" element={<ResetPassword />} exact/>
          <Route path="/dashboard" element={ <PrivateRoute fallback="/403"> <Dashboard /></PrivateRoute> }exact/>
          <Route path="/residential" element={<PrivateRoute fallback="/403"><CustomerList type="residential" /> </PrivateRoute>}exact />
          <Route path="/commercial"element={<PrivateRoute fallback="/403"><CustomerList type="commercial" /></PrivateRoute> } exact/>
          <Route path="/residential/new-customer" element={<PrivateRoute fallback="/403"><NewCustomer /></PrivateRoute>}exact />
          <Route path="/commercial/new-customer" element={<PrivateRoute fallback="/403"><NewCustomer /></PrivateRoute>}exact />
          <Route path="/residential/customer-detail" element={ <PrivateRoute fallback="/403"> <Detail /> </PrivateRoute>} exact/>
          <Route path="/commercial/customer-detail" element={<PrivateRoute fallback="/403"><CommercialCustomerDetail /> </PrivateRoute>}exact />
          <Route path="/user-list"element={<PrivateRoute fallback="/403"><UserList /></PrivateRoute>}exact/>
          <Route path="/maintenance-pool-report-form/:formuuid" element={<MaintenancePoolReport />}exact />
          <Route path="/pool-maintenance-program-form/:formuuid" element={<SwimmingPoolMaintenanceProgramForm />}exact />
          <Route path="/filter-clean-form/:formuuid" element={<FilterCleanForm />} exact />
          <Route path="/pool-inspection-form/:formuuid" element={<PoolInspectionForm />}exact />
          <Route path="/franchise/franchise-list" element={ <PrivateRoute fallback="/403"><FranchiseList /></PrivateRoute>} exact />
          <Route path="/franchise/franchise-detail" element={<PrivateRoute fallback="/403"><FranchiseDetail /></PrivateRoute> } exact/>
          <Route path="/field_tech/week_setup" element={<PrivateRoute fallback="/403"> <WeekSetup /></PrivateRoute>} exact />
          <Route path="/account/account-list" element={<PrivateRoute fallback="/403"> <AccountList /></PrivateRoute>} exact />
          <Route path="/field_tech/bulk-reassign" element={<PrivateRoute fallback="/403"> <BulkReassign /></PrivateRoute>} exact  />
          <Route path="/commercial/lead-list" element={<PrivateRoute fallback="/403"> <CommercialCustomerList /></PrivateRoute>} exact />
          <Route path="/commercial/lead-list/community-info" element={<PrivateRoute fallback="/403"> <AddCommunityInfo /></PrivateRoute>} exact />
          <Route path="/commercial/lead-list/lead/detail/:uuid" element={<PrivateRoute fallback="/403"> <LeadDetail /></PrivateRoute>} exact />
          <Route path="/field_tech/view-by-work-order" element={<PrivateRoute fallback="/403"><ViewByWorkOrder /></PrivateRoute>} exact />
          <Route path="/residential/lead/detail/:uuid" element={<PrivateRoute fallback="/403"> <LeadDetail /></PrivateRoute>} exact />
          <Route path="/reports" element={ <PrivateRoute fallback="/403"> <ReportsListPage /> </PrivateRoute> } exact />
          <Route path="/reports/:id" element={<PrivateRoute fallback="/403"><ReportPage /></PrivateRoute>}exact />
          <Route path='/dashboard' element={<PrivateRoute fallback="/403"><Dashboard /></PrivateRoute>} exact />
          <Route path='/residential' element={<PrivateRoute fallback="/403"><CustomerList type='residential' /></PrivateRoute>} exact />
          <Route path='/commercial' element={<PrivateRoute fallback="/403"><CustomerList type='commercial' /></PrivateRoute>} exact />
          <Route path='/residential/new-customer' element={<PrivateRoute fallback="/403"><NewCustomer /></PrivateRoute>} exact />
          <Route path='/residential/customer-detail' element={<PrivateRoute fallback="/403"><Detail /></PrivateRoute>} exact />
          <Route path='/user-list' element={<PrivateRoute fallback="/403"><UserList /></PrivateRoute>} exact />
          <Route path='/franchise/franchise-list' element={<PrivateRoute fallback="/403"> <FranchiseList /></PrivateRoute>} exact />
          <Route path='/proposal/proposal-list' element={<PrivateRoute fallback="/403"> <ProposalList /></PrivateRoute>} exact />
          <Route path='/franchise/franchise-detail' element={<PrivateRoute fallback="/403"><FranchiseDetail /></PrivateRoute>} exact />
          <Route path='/field_tech/week_setup' element={<PrivateRoute fallback="/403"><WeekSetup /></PrivateRoute>} exact />
          <Route path='/account/account-list' element={<PrivateRoute fallback="/403"><AccountList /></PrivateRoute>} exact />
          <Route path='/field_tech/bulk-reassign' element={<PrivateRoute fallback="/403"><BulkReassign /></PrivateRoute>} exact />
          <Route path='/commercial/lead-list' element={<PrivateRoute fallback="/403"><CommercialCustomerList /></PrivateRoute>} exact />
          <Route path='/commercial/lead-list/community-info' element={<PrivateRoute fallback="/403"><AddCommunityInfo /></PrivateRoute>} exact />
          <Route path='/commercial/lead-list/lead/detail/:uuid' element={<PrivateRoute fallback="/403"><LeadDetail /></PrivateRoute>} exact />
          <Route path='/commercial/lead-list/lead/detail/:uuid/serviceorder' element={<PrivateRoute fallback="/403"><ServiceOrderLead /></PrivateRoute>} exact />
          <Route path='/field_tech/view-by-work-order' element={<PrivateRoute fallback="/403"><ViewByWorkOrder /></PrivateRoute>} exact />
          <Route path='/residential/lead/detail/:uuid' element={<PrivateRoute fallback="/403"><LeadDetail /></PrivateRoute>} exact />
          <Route path='/residential/lead-list/community-info' element={<PrivateRoute fallback="/403"><LeadDetails /></PrivateRoute>} exact />
          <Route path='/reports' element={<PrivateRoute fallback="/403"><ReportsListPage/></PrivateRoute>} exact/>
          <Route path='/reports/:id' element={<PrivateRoute fallback="/403"><ReportPage/></PrivateRoute>} exact/>
          <Route path='/case-management' element={<PrivateRoute fallback="/403"><CaseManagement/></PrivateRoute>} exact/>
          <Route path="/404" element={ <PrivateRoute fallback="/403"><NotFound /></PrivateRoute> } />
          <Route path="/403" element={ <Forbidden /> } />
          <Route path="*" element={ <Navigate to="/404" replace />} />
          <Route path="under_construction" element={ <UnderConstruction to="/under_construction" replace />} />
          <Route path="/edit-workorder/:uuid" element={<EditWorkOrderURL />} exact />
          <Route path='/field_tech/preferred-technician' element={<PrivateRoute fallback="/403"><PreferredTechnician /></PrivateRoute>} exact />
          <Route path="/calendar" element={ <PrivateRoute fallback="/403" isFieldTech={true}> <CalendarPage /></PrivateRoute> }exact/>
          <Route path="/tech-glance" element={<TechGlancePage />} exact />
          <Route path="/work-orders" element={<WorkOrdersIndex />} exact />
          <Route path="/price-catalog" element={<PriceCatalog />} exact />
          <Route path="/field_tech/plot_job" element={<PlotJobMap />} exact />
          <Route path="/customer-lead-list" element={<PrivateRoute fallback="/403"> <CustomerLeadList /></PrivateRoute>} exact />
          <Route path="/customer-lead-list/residential-customer-detail" element={ <PrivateRoute fallback="/403"> <Detail /> </PrivateRoute>} exact/>
          <Route path="/customer-lead-list/commercial-customer-detail" element={<PrivateRoute fallback="/403"><CommercialCustomerDetail /> </PrivateRoute>}exact />
          <Route path="/customer-lead-list/lead/detail/:uuid" element={<PrivateRoute fallback="/403"> <LeadDetail /></PrivateRoute>} exact />
          <Route path='/payment' element={<PaymentPage />} exact />
          <Route path='/customer-lead-list/lead/detail/:uuid/serviceorder' element={<PrivateRoute fallback="/403"><ServiceOrderLead /></PrivateRoute>} exact />
          <Route path="/proposal/:uuid" element={<Proposal />} exact />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default App;