import React from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { additionalNotes,email_notes,service_notes } from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";


const ProposalNotes = (props) => {
  const { editMode, errors } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);

  return (
    <div className="proposal_notes">
      <div>
        <h6 className = "margin-bottom-10">Additional Notes</h6>
        <textarea disabled={editMode} value={proposals.additional_notes} className="proposal_additional" onChange={(e) => dispatch(additionalNotes(e.target.value))}/>
      </div>
      <div className="margin-top-20">
        <h6 className="margin-bottom-10">Email Notes</h6>
        <textarea disabled={editMode}
        value={proposals?.email_notes}
        className="proposal_additional" onChange={(e) => dispatch(email_notes(e.target.value))}/>
      </div>
      {errors["email_notes"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["email_notes"]}
                  </label>
                ) : (
                  ""
                )}
      <div className="margin-top-20">
        <h6 className="margin-bottom-10">Service Agreement</h6>
        <textarea 
        value={proposals.service_agreement} 
        disabled={editMode} className="proposal_additional" onChange={(e) => dispatch(service_notes(e.target.value))}/>
      </div>
      {errors["service_agreement"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["service_agreement"]}
                  </label>
                ) : (
                  ""
                )}
    </div>
  );
};
export default ProposalNotes;