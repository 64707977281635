import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { map, isEmpty } from "lodash";
import * as yup from "yup";
import Script from "react-load-script";
import moment from "moment-timezone";
import { Select, Row, Col } from "antd";
import Axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';
import cross_icon from "../../Assets/images/cross_icon.svg";
import AllState from "../../Assets/data/stateList.json";
import Loader from "../../Components/Loader";
import { checkZipCode } from '../../Action/Franchise';
import serviceData from '../data.json';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { addZipCodeData } from '../../Store/zipCodeDataSlice';
import AddressField from "../../Components/AddressField";

const { Option } = Select
const REGULAR_EXPRESSION_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ServiceInfo = forwardRef(({ inputData, onDataChange, onSetData, setCurrentTab, saveFieldOfficeData }, ref) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        uuid: "",
        zip_code: "",
        first_name: "",
        last_name: "",
        address: {},
        phone_numbers: [],
        phone: "",
        emailaddress_set: [],
        payment_method:"",
        email: "",
        authorized_users: [],
        hear_about_us: [],
    });
    const renderPlanform = () => {
        return [
            <Option key='Online Search' value='Online Search' ></Option>,
            <Option key='Social Media' value='Social Media' ></Option>,
            <Option key='Referral' value='Referral' ></Option>,
            <Option key='Truck' value='Truck' ></Option>,
            <Option key='Post card' value='Post Card' ></Option>,
            <Option key='Other' value='Other' ></Option>
        ]
    }
    // const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);
    const [zipCodeCheck, setZipCodeCheck] = useState(false);
    const [userName, setUserName] = useState('');
    const [phoneType, setPhoneType] = useState('mobile');
    const [canSendSms, setCanSendSms] = useState(false);
    const [sameContactAddress, setSameContactAddress ] = useState(true);
    const [errors, setErrors] = useState({})
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({});

    useEffect(() => {
        if (!isEmpty(inputData)) { 
            setState({ ...state, ...inputData });
        }
        // setHearAboutUsOptions();
    }, []);
    useEffect(() => {
        if (inputData?.zip_code?.length > 3) {
            const getZipCode = async () => {
                const data = await checkZipCode({ addressable_zip_codes__contains: inputData.zip_code, close: false });
                if (data?.count > 0) {
                    setZipCodeCheck(true)
                    dispatch(addZipCodeData(data?.results[0])) 
                    if(setCurrentTab !== undefined){
                        setCurrentTab(0)
                    }                   
                    if(saveFieldOfficeData) saveFieldOfficeData(data?.results[0]) 
                    
                } else {
                    setZipCodeCheck(false);
                }
                setData(data.results[0]);
                setLoader(false);
            }
            getZipCode();
        }
    }, [state.zip_code])
    useEffect(() => {
        onDataChange(state);
    }, [state]);
    // useImperativeHandle(ref, () => ({
    //     validate() {
    //         const schema = yup.object().shape({
    //             zipCode: yup.string().required('Zipcode is required'),
    //             zipCodeCheck: yup.bool(),
    //             firstName: yup.string().required('First name is required'),
    //             lastName: yup.string().required('Last name is required'),
    //             address: yup.object().shape({
    //                 address: yup.string().required('address is required.'),
    //                 city: yup.string().required('city is required.'),
    //                 state: yup.string().required('state is required.'),
    //                 zip_code: yup.string().required('zipcode is required.').test("match", "Enter proper zip code", val => val && val === zipCode),
    //             }),
    //             phoneNumbers: yup.array(yup.object({
    //                 phone: yup.string().required('Phone is required')
    //             })).min(1).required("Phone number is required"),
    //             emails: yup.array(yup.object({
    //                 email: yup.string().required('Email is required')
    //             })).min(1).required("Email Address is required"),
    //         });
    //         const data = {
    //             zipCode,
    //             zipCodeCheck,
    //             firstName,
    //             lastName,
    //             address,
    //             phoneNumbers,
    //             emails,
    //             authorizedUser,
    //         };
    //         return schema.validate(data, { abortEarly: false }).then(() => {
    //             setIsBasicDetail(true);
    //             props.setServiceInfoData({ value: data, key: true, name: 'serviceArea' })
    //             setErrors({})
    //             return Promise.resolve('success')
    //         }).catch((error) => {
    //             let err_data = {};
    //             map(error?.inner, (item) => { err_data[item.path] = item.message })
    //             setIsBasicDetail(false);
    //             setErrors(err_data)
    //             return Promise.reject('fail')
    //         });
    //     }
    // }));


    useImperativeHandle(ref, () => ({
        validate() {
        
          const schema = yup.object().shape({
            zipCodeCheck: yup.bool(),
            first_name: yup.string().required('First name is required'),
            last_name: yup.string().required('Last name is required'),
            address: yup.object().shape({
              address: yup.string().required('address is required.'),
              city: yup.string().required('city is required.'),
              state: yup.string().required('state is required.'),
            }),
            phone_numbers: yup.array(yup.object({
              phone: yup.string().required('Phone is required')
            })).min(1).required("Phone number is required"),
            emailaddress_set: yup.array(yup.object({
              email: yup.string().required('Email is required')
            })).min(1).required("Email Address is required"),
          });
          const data = {
            zipCodeCheck,
            first_name,
            last_name,
            address,
            phone_numbers,
            emailaddress_set,
          };
          return schema.validate(data, { abortEarly: false }).then(() => {
            setErrors({})
            return Promise.resolve('success')
          }).catch((error) => {
            let err_data = {};
            map(error?.inner, (item) => { err_data[item.path] = item.message })
            setErrors(err_data)
            return Promise.reject('fail')
          });
        }
      }));

    const handleZipCode = (e) => {
        if (e.target.value.length > 3) {
            setLoader(true);
        }
        setState({ ...state, zip_code: e.target.value });
    }
    const handleFirstName = (e) => {
        setState({ ...state, first_name: e.target.value });
    }
    const handleLastName = (e) => {
        setState({ ...state, last_name: e.target.value });
    }
    const onAddressChanged = (e, addr_ref) => {        
        const { address } = state;
        Object.assign(address, { [e.target.name]: e.target.value })
        setState({ ...state, address: address });
    };
    const onNumericInputChange = (e) => {
        const reg = /^-?\d*(\.\d*)?$/;
        return ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-')
    }

    const onPhoneChange = (e, type) => {
        const { phone_numbers } = state;
        const value = type === 'bool' ? e.target.checked : e.target.value;
        setCanSendSms(value);
        Object.assign(phone_numbers[0], { can_send_sms: e.target.checked })
        if (e.target.name === 'type' && e.target.value === 'phone') {
            Object.assign(phone_numbers[0], { 'can_send_sms': false })
        }
        setState({ ...state, phone_numbers: [...phone_numbers] });
    }
    const onTextChange = (e) => {
        if (onNumericInputChange(e) && e.target.name === 'phone') {
            setState({ ...state, phone: e.target.value });
        }
        if (e.target.name === 'email') {
            setState({ ...state, email: e.target.value });
        }
        if (e.target.name === 'username') {
            setUserName(e.target.value);
        }
        if (e.target.name === 'phoneType') {
            setPhoneType(e.target.value)
        }
    }
    // on address change
    const onAddressChange = (e) => {
        Object.assign(address, { [e.target.name]: e.target.value });
        setState({ ...state, address: address });
    };
    // on primary phone number changed
    const onPhonePrimaryChanged = (index) => {
        const { phone_numbers } = state;
        map(phone_numbers, (obj) => obj.primary = false)
        phone_numbers[index].primary = true
        setState({ ...state, phone_numbers: [...phone_numbers] });
    }
    const onPrimaryEmailChanged = (index) => {
        const { emailaddress_set } = state;
        map(emailaddress_set, (obj) => obj.primary = false)
        emailaddress_set[index].primary = true
        setState({ ...state, emailaddress_set: [...emailaddress_set] });
    }

    const genarateGibberrishEmail = () => {
        let newEmail = [{ email: '', primary: true }]
        newEmail[0].email = this.create_UUID() + '@doody.com';
        setState({ ...state, emailaddress_set: newEmail });
    }

    //on change
    const onChange = (row, name) => {
        const { phone_numbers, authorized_users, emailaddress_set } = state;
        let data = name === 'phone' ? phone_numbers : name === 'email' ? emailaddress_set : authorized_users;
        data.splice(row, 1);
        if (name === 'phone' || name === 'email') {
            const idx = data?.findIndex(x => x.primary)
            if (idx <= -1 && !isEmpty(data)) {
                data[0].primary = true;
            }
        }
        if (name === 'phone') {
            setState({ ...state, phone_numbers: [...data] });
        } else if (name === 'email') {
            setState({ ...state, emailaddress_set: [...data] });
        } else {
            setState({ ...state, authorized_users: [...data] });
        }
        if (name === 'email') {
            if (data.length === 0) {
                genarateGibberrishEmail()
            }
        }
    };

    const onAdd = (type) => {
        const { phone_numbers, authorized_users, emailaddress_set } = state;
        if (type === 'phone') {
            let obj = {}
            if (phone_numbers.length === 0) {
                obj = { 'phone': phone, 'primary': true, 'type': phoneType, 'can_send_sms': canSendSms }
            } else {
                obj = { 'phone': phone, 'primary': false, 'type': phoneType, 'can_send_sms': canSendSms }
            }
            setState({ ...state, phone_numbers: [...phone_numbers, obj], phone: '' });
        }
        if (type === 'email') {
            let obj = {}
            if (emailaddress_set.length === 0) {
                obj = { 'email': email, 'primary': true }
            } else {
                obj = { 'email': email, 'primary': false }
            }
            setState({ ...state, emailaddress_set: [...emailaddress_set, obj], email: '' });
        }
        if (type === 'username') {
            let obj = {}
            if (emailaddress_set.length === 0) {
                obj = { 'username': userName, 'primary': true }
            } else {
                obj = { 'username': userName, 'primary': false }
            }
            setState({ ...state, authorized_users: [...emailaddress_set, obj] });
            setUserName('');
        }
    }
    // format phone number
    const formatPhoneNumber = (phone) => {
        if (!phone) return ''
        phone = phone?.replace(/\D/g, '');
        return '('+phone?.substr(0, 3) + ') ' + phone?.substr(3, 3) + '-' + phone?.substr(6);
    }


    const onHearAboutUsType = (value) => {
        setState({ ...state, hear_about_us: [value] });
    }


    const handleAddressChange = (value) => {
        setState({ ...state, address: value });
    }

    const changeContactAddress = (e) => {
        setSameContactAddress(!sameContactAddress)
        if(!sameContactAddress){
            setState((s) => ({ ...s, address: inputData.address }))
        }else{
            setState((s) => ({ ...s, address: {} }))
        }          
    }

    const { zip_code, first_name, last_name, address, phone_numbers, phone, emailaddress_set, email, authorized_users, hear_about_us, } = state;
    
    return (
        <div className="aleft">
            {loader && <Loader />}
            <h4>Do we service this area?</h4>
            <p>If you are here, this is for a new customer calling right now. First of all, take their zipcode for their service address to make sure we service this area.</p>
            <div className="form-group">
                <div className="fitem">
                    <label>Service Zipcode</label>
                    <input type="text" placeholder="Zipcode" disabled={zipCodeCheck} name="zip_code" max={5} maxLength={5} value={zip_code} onChange={handleZipCode} />
                    {/* {errors?.['zipCode'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['zipCode']}</label> : ''} */}
                </div>
                {zip_code ? zipCodeCheck === true ?
                    <div className="greenbox mb-30">
                        <b className="d-block">We cover this area.</b>
                        <b className="d-block">{`This is under the ${data?.name} Franchise.`}</b>
                    </div> :
                    <div className="dangerbox mb-30">
                        <b className="d-block">We don’t currently have any franchises covering this area. Save their email to notify them if we move into this area. </b>
                    </div> : ''
                }
            </div>
            {zipCodeCheck && <div className="form-group">
                <h4>Contact Information</h4>
                <Row>
                    <div className='half-fitem fitem'>
                        <Col span={11.5}>
                            <label>First Name</label>
                            <input type="text" placeholder="First Name" name="first_name" value={first_name} onChange={handleFirstName} />
                            {errors['first_name'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['first_name']}</label> : ''}
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11.5}>
                            <label>Last Name</label>
                            <input type="text" placeholder="Last Name" name="last_name" value={last_name} onChange={handleLastName} />
                            {errors['last_name'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['last_name']}</label> : ''}
                        </Col>
                    </div>
                </Row>
                <h4>Service Address</h4>                             
                <AddressField handleAddressChange={handleAddressChange} onAddressChanged={onAddressChanged} onAddressChange={onAddressChange} errors={errors} data={address} nolabel={true}/>
            </div>}
            <div>
                <h5 className=" mt-30">Phone Numbers</h5>
                {
                    map(phone_numbers, (item, index) =>
                        item?.phone?
                        <div className="fitem-check" key={`phone_numbers${index}`}>
                            <input type="checkbox" className="fitem-ck-input" id={`checkboxPhone${index}`} name={`checkboxPhone${index}`} checked={item?.primary} onChange={(e) => onPhonePrimaryChanged(index)} />
                            <label htmlFor={`checkboxPhone${index}`} className="fitem-ck-txt black">{` ${formatPhoneNumber(item.phone)} ${item.primary ? '(Primary)' : ''}`}</label>
                            <a className="red float-right" onClick={(e) => onChange(index, 'phone')}><u>Remove</u></a>
                        </div>
                        :"")
                }
                <Row>
                    <div className="fitem fitem-rinner mt-10 flex">
                        <Col span={11}>
                            <input name="phone" type="text" placeholder="Add New Phone" value={phone} maxLength={"10"} max={"10"} onChange={onTextChange}
                             onKeyPress={(e) => {
                                const pattern = /[0-9]/;
                                const inputChar = String.fromCharCode(e.charCode);
                                if (!pattern.test(inputChar)) {
                                    // invalid character, prevent input
                                    e.preventDefault();
                                }
                            }} />
                        </Col>
                        <Col span={1}></Col>
                        <Col span={9}>
                            <Select
                                onChange={(e) => onTextChange({ target: { name: 'phoneType', value: e } })}
                                style={{ marginRight: "10px" }}
                                name="phoneType"
                                value={phoneType}
                                disabled
                                placeholder="Type">
                                <Option value="home_phone">Home</Option>
                                <Option value="mobile">Mobile</Option>
                            </Select>
                        </Col>
                        <Col span={3} style={{ position: 'static', textAlign: 'center' }}>
                            <button style={{ right: 'auto' }} className={`btn fitem-ricon fitem-ricon-phone ${phone?.length !== 10 ? 'disabled' : 'btn-warning'}`}
                                disabled={phone?.length !== 10} onClick={(e) => onAdd('phone')} ><PlusOutlined /></button>
                        </Col>
                    </div>
                </Row>
                {phoneType === 'mobile' ? <div className="fitem-check">
                    <input
                        type="checkbox"
                        name="canSendSms"
                        className="fitem-ck-input"
                        id={`canSendSms`}
                        checked={canSendSms}
                        onChange={(e) => onPhoneChange(e, 'bool')}
                    />
                    <label htmlFor={`canSendSms`} className="fitem-ck-txt">{`Send SMS updates about my service to this number.`}</label>
                </div> : null}
                {errors?.[`phone_numbers`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`phone_numbers`]}</label> : ''}
            </div>
            <div>
                <h5 className="mt-30">Email Addresses</h5>
                <div className="inline-check-list gcheck">
                    {
                        map(emailaddress_set, (item, index) => item?.email && !item.email.includes('doody.com') ? <div className="fitem-check" key={`email${index}`}>
                            <input type="checkbox" className="fitem-ck-input" id={`checkboxEmail${index}`} name={`checkboxEmail${index}`} checked={item?.primary} onChange={(e) => onPrimaryEmailChanged(index)} />
                            <label htmlFor={`checkboxEmail${index}`} className="fitem-ck-txt black">{`${item?.email} ${item?.primary ? '(Primary)' : ''}`}</label>
                            <a className="red float-right" onClick={(e) => onChange(index, 'email')}><u>Remove</u></a>
                        </div> : ''
                        )}
                </div>
                <div className="fitem fitem-rinner mt-10 flex">
                    <input name="email" type="text" placeholder="Add New Email Address" value={email} onChange={onTextChange} />
                    <button className={`btn fitem-ricon ${!REGULAR_EXPRESSION_EMAIL.test(email) ? 'disabled' : 'btn-warning'}`}
                        disabled={!REGULAR_EXPRESSION_EMAIL.test(email)} onClick={(e) => onAdd('email')} ><PlusOutlined /></button>
                </div>
                {errors?.[`emailaddress_set`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`emailaddress_set`]}</label> : ''}
            </div>
            {/* {zipCodeCheck && <div>
                <h5 className=" mt-30">Authorized Users</h5>
                {map(authorized_users, (item, index) => <div className="fitem-check flex" key={`user${index}`}>
                    <b className="d-block">{item?.username}</b>
                    <div className="flex">
                        <span className="mr-2">{`Added ${moment(item?.created_at)?.format('MM/DD/YYYY')}`}</span>
                        <a className="red" onClick={(e) => onChange(index, 'user')}><u>Remove</u></a>
                    </div>
                </div>
                )}
                <div className="fitem fitem-rinner mt-10 flex">
                    <input name="username" type="text" placeholder="Add New Authorized User" value={userName} onChange={onTextChange} />
                    <button className={`btn fitem-ricon ${!userName ? 'disabled' : 'btn-warning'}`}
                        disabled={!userName} onClick={(e) => onAdd('username')} ><PlusOutlined /></button>
                </div>

            </div>} */}
            {zipCodeCheck && <div>
                <h5 className=" mt-30">How did you hear about us?</h5>
                {/* <Select
                    showArrow={true}
                    
                    virtual={false}
                    options={hearAboutUsOptions}
                    value={}
                ></Select> */}
                <Select
                    showSearch
                    showArrow={true}
                    filterOption={false}
                    // notFoundContent={null}
                    name="heartAboutUs"
                    placeholder="Select"
                    value={hear_about_us}
                    onChange={(e) => onHearAboutUsType(e)}
                >
                    {renderPlanform()}
                </Select>
            </div>

            }
        </div>
    )
});

export default ServiceInfo;
