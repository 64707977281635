import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

//Get invoice data
export const getInvoice = async (params) => {
  try {
    const response = await axiosInstance.get(`/invoice/is-paid/`, {
      params,
    });
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      if (error.response?.data?.detail) {
        message.error(error.response?.data?.detail);
      } else {
        message.error("Fail to request data");
      }
      return error;
    }
  }
};

//Save payment after save in Stax
export const savePayment = async (params) => {
  try {
    const response = await axiosInstance.post(`/crm/manual-payment/link-payment/`, params);
    return response;
  } catch (error) {
    if (error.status !== 200) {
      if (error.response?.data?.detail) {
        message.error(error.response?.data?.detail);
      } else {
        message.error("Fail to request data");
      }
      return error;
    }
  }
};
