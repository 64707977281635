import React, {Component} from "react";
import {Drawer, message} from "antd";
import {CloseOutlined, PlusOutlined} from '@ant-design/icons';
import {map, isEmpty} from "lodash";
import moment from "moment-timezone";
import Loader from "../../../Components/Loader";

class AddAuthorizedUsers extends Component {
  state = {
    authorizedUser: [],
    user: '',
    deletedUsers: [],
    loader: false
  }

  componentDidMount() {
    const {customerId, getAuthorizedUserList} = this.props;
    if (customerId) {
      getAuthorizedUserList({ user__uuid: customerId }) // get authorized user list
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    // get authorized user list
    if (Object.keys(nextProps?.authorizedUser?.userList).length > 0
      && JSON.stringify(nextProps?.authorizedUser?.userList?.results) !== JSON.stringify(this.state.authorizedUser)) {
      this.setState({ authorizedUser: nextProps?.authorizedUser?.userList?.results });
    }
  }

  //on change
  onChange = (row) => {
    const {authorizedUser, deletedUsers} = this.state;
    const deletedRow = authorizedUser[row];
    this.setState({deletedUsers: [...deletedUsers, deletedRow?.uuid]});
  };

  // on text change
  onTextChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  };

  // on clear
  onClear = () => {
    this.setState({user: ''});
  };

  //on add
  onAdd = () => {
    const {authorizedUser, user} = this.state;
    const {customerId} = this.props;
    authorizedUser.push({user_uuid: customerId, user_name: user});
    this.setState({authorizedUser}, () => this.onClear());
  };

  // on update
  onUpdateClick = () => {
    const {addAuthorizedUser, closeDrawer, getAuthorizedUserList, deleteAuthorizedUser, customerId} = this.props;
    const {authorizedUser, deletedUsers} = this.state;
    let promiseArr = [], deleteArr = [];
    // for add
    map(authorizedUser, (item) =>
      !item?.uuid && promiseArr.push(addAuthorizedUser && addAuthorizedUser(item)));

    Promise.all(promiseArr).then((response) => {
      if (!isEmpty(response)) {
        getAuthorizedUserList({user__uuid: customerId}) // get authorized user list
      }
      closeDrawer();
      this.onClear();
    }).catch(() => {
      message.error('something went wrong');
    });

    // for delete
    map(deletedUsers, (item) => deleteArr.push(deleteAuthorizedUser && deleteAuthorizedUser(item)));

    Promise.all(deleteArr).then((response) => {
      if (!isEmpty(response)) {
        getAuthorizedUserList({user__uuid: customerId}) // get authorized user list
      }
      closeDrawer();
      this.onClear();
    }).catch(() => {
      message.error('something went wrong');
    });
  };

  render() {
    const {openDrawer, closeDrawer} = this.props;
    const {authorizedUser, user, loader, deletedUsers} = this.state;
    return (
      <Drawer
        placement={'right'}
        closable={false}
        onClose={closeDrawer}
        visible={openDrawer}
        key={'right'}
        width={'400'}
        className="drawer-wrapper"
      >
        <div className="rdrawer filter_drawer_wrapper">
          {loader && <Loader/>}         
          <div className="card-row flex-column">
            <div className="card-full">
              <div className="flex ai-center mb-30">
                <h4 className="mb-0">Authorized Users</h4>
                <CloseOutlined onClick={closeDrawer}/>
              </div>
              {/* User List */}
              {
                map(authorizedUser, (item, index) => {
                  return (
                    !deletedUsers?.includes(item?.uuid) && <div className="flex" key={`user${index}`}>
                      <b className="d-block">{`${item?.user_name} ${item?.primary ? '(Primary)' : ''}`}</b>
                      {
                        !item?.primary &&
                        <div className="flex">
                          <span className="mr-2">{`Added ${moment(item?.created_at)?.format('MM/DD/YYYY')}`}</span>
                          <a className="red" href={'/#'} onClick={(e) => {e.preventDefault(); this.onChange(index)}}><u>Remove</u></a>
                        </div>
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className="card-full">
              <div className="form-group">
                <div className="fitem fitem-rinner">
                  <input
                    name="user"
                    type="text"
                    placeholder={"Enter Name"}
                    value={user}
                    onChange={(e) => this.onTextChange(e)} />
                  <button
                    className={`btn fitem-ricon ${!user ? 'disabled' : 'btn-warning'}`}
                    disabled={!user}
                    onClick={() => this.onAdd()}><PlusOutlined /></button>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <button className={`btn btn-bdr`} onClick={closeDrawer}>Cancel</button>
            <button
              // type="submit"
              className={`btn btn-warning`}
              onClick={() => this.onUpdateClick()}>
              Update
            </button>
          </div>
        </div>
      </Drawer>
    )
  }
}

export default AddAuthorizedUsers;