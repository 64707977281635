import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import { getServiceMatrix } from "../../../../Action/Customer/index";
import {
  addServiceMatrix,
  selectServiceType,
  showLoader
} from "../../../../Store/techJourneyDataSlice";
import { useCalendar } from "../../../Hooks/Calendar.Hook";

const SelectService = () => {
  const {
    franchise: { value: userID },
  } = useCalendar();

  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);

  useEffect(() => {
    dispatch(showLoader(true));
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
    let uuid = userID ? userID : franchise_data?.data?.uuid;
    setTimeout(async () => {
      if (uuid) {
        const data = await getServiceMatrix(uuid);
        if (data && data?.servicetypes) {
          dispatch(addServiceMatrix(data));
          dispatch(showLoader(false));
        }
      }
    }, 1000);
  }, [userID]);

  const {
    serviceMatrix: { servicetypes: serviceItems },
  } = techJourneyState;
  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <h4>Service Type</h4>
          <div className="oplist">
            {serviceItems &&
              serviceItems.map((item, index) => (
                <div
                  className="inline-checks"
                  key={`serviceType${index}`}
                >
                  <label
                    htmlFor={item?.uuid}
                    className={`${
                      item?.uuid === techJourneyState?.selectedServiceType?.uuid
                        ? "active"
                        : ""
                    }`}
                  >
                    <input
                      type="radio"
                      id={item?.uuid}
                      name="serviceType"
                      value={item?.uuid}
                      onChange={
                        () => dispatch(selectServiceType(item))
                        /*onTextChange({target: {name: 'serviceType', value: item?.uuid, key:item?.name}})*/
                      }
                    />
                    <span>{item?.name}</span>
                  </label>
                </div>
              ))}
          </div>
          {/* {errors?.[`serviceType`] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.[`serviceType`]}</label> : ''} */}
        </div>
      </Col>
    </Row>
  );
};

export default SelectService;
