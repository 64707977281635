import React from "react";
import "../../Assets/css/reports.css";
import Sidebar from "../../Components/Sidebar";
import { ReportsProvider } from "../Hooks/Report.Hook";
import  ReportContainer  from "../Components/Containers/Report.Container";

const ReportsList = () => {

  return (
    <div id="wrapper">
      <div className="report-ca content-area">
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <div className="container">
          <ReportsProvider>
            <ReportContainer />
          </ReportsProvider>
        </div>
      </div>
    </div>
  );
};

export default ReportsList;
