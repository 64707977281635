const ReportsData = [
  {
    key: 1,
    title: "Territory Revenue Reports",
    description:
      "This report will show revenue generated by Zip code for Franchise.",
    id: "in_territory_revenue_reports",
    searchPlaceHolder: "Search by Zipcode",
    display: true,
    hasSideBar: true,
  },
  {
    key: 2,
    title: "Chemical Report",
    description: "The chemical use for the job.",
    id: "chemical_reports",
    searchPlaceHolder: "Acc Number, Customer Name",
    display: true,
    hasSideBar: true,
  },
  {
    key: 3,
    title: "Lead/Customer Report",
    description: "This report will show Captured Lead/Customer Report.",
    id: "captured_lead_reports",
    searchPlaceHolder:
      "Search account number, customer address, contact details.",
    display: true,
    hasSideBar: true,
  },
  {
    key: 4,
    title: "Invoice Reconciliation Report",
    description:
      "This report will show the amount of work that was invoiced compared to the amount of work that was completed for a given time period.",
    id: "invoice_reconciliation_reports",
    searchPlaceHolder: "Search",
    display: false,
    hasSideBar: true,
  },
  {
    key: 5,
    title: "Aging Summary Report",
    description: "This report will show the Aging Summary Report.",
    id: "aging_summary_reports",
    searchPlaceHolder: "Search",
    display: true,
    hasSideBar: true,
  },
  {
    key: 6,
    title: "Expiring Customer Payment Method Report",
    description:
      "This report will show the Expiring Customer Payment Method Report.",
    id: "expiring_customer_payment_method_report",
    searchPlaceHolder: "Search",
    display: true,
    hasSideBar: false,
  },
  {
    key: 7,
    title: "Work Order Report (JOBS)",
    description: "This report will show the Work Order Report (JOBS).",
    id: "work_order_report",
    searchPlaceHolder: "Search",
    display: false,
    hasSideBar: true,
  },
  {
    key: 8,
    title: "Chemical Report Pricing",
    description: "This report will show the Chemical Report Pricing.",
    id: "chemical_report_pricing",
    searchPlaceHolder: "Search",
    display: false,
    hasSideBar: true,
  },
  {
    key: 9,
    title: "Tech Notes Report",
    description: "This report is used to track/ document technician’s note after each job.",
    id: "tech_notes",
    searchPlaceHolder:  "Acc Number, Customer Name",
    display: true,
    hasSideBar: true,
  },
  {
    key: 10,
    title: "PoolOps 1.0 Reports",
    description: "This link will take you to PoolOps 1.0 reports.",
    id: "poolops_1.0_reports",    
    display: true,
    hasExternalURL: true,
    URL: 'https://www.poolops.com/reports',
    buttunLable: "Go to PoolOps"
  }
];

export default ReportsData;
