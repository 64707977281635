import React, { useEffect, useState } from 'react'
import Total from '../Residential/NewCustomer/ServiceSummary/Total';

const ServiceSummaryLead = (props) => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        setValue(props.priceDetail?.price);
        props.setServiceInfoData({ value: value, key: value, name: 'price' });
    }, [props.priceDetail]);
    // useEffect(()=> {
    //     props.setServiceInfoData({ value: value, key: value, name: 'price' });
    // }, [value])
    const saveData = (e) => {
        setValue(e.target.value);
        props.setPriceDetail((p) => ({ ...p, price: e.target.value }));
    }
    return (
        <div className="aside-bar">
            <div className='acol'>
                <h2 className="mb-20">Service Summary</h2>
                <div>
                    <div className="flex">
                        <div className="flex-1">
                            <p className='text-capitalize'>{props?.serviceInfo?.service_type_uuid?.key}</p>
                            {props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !== 'maintenance' ? <p className='text-capitalize'>{props?.serviceInfo?.service?.key}</p> : null}
                            <p className='text-capitalize'>{props?.priceDetail?.frequency?.recurring?.repeat}</p>
                            <p className='text-capitalize'>{props?.serviceInfo?.pool_type_uuid?.key}{props?.serviceInfo?.otherPoolType?.value ? " / " + props?.serviceInfo?.otherPoolType?.value : null}</p>
                        </div>
                    </div>
                </div>
                {/* <Total priceDetail={props?.priceDetail} setPriceDetail={props.setPriceDetail} /> */}
                <div className="wlight">
                    <div className="flex ai-center">
                        <div className="flex-1">
                            <h4 className="m-0">Total{props.priceDetail?.key ? props.priceDetail?.key === 'One Time' ? "" : " (Monthly Charge) :" : ""}</h4>
                        </div>
                        <div className="pricing" style={{ 'width': '50%', 'position': 'relative' }}>
                            <span style={{ 'position': 'absolute', 'left': '20%', 'paddingTop': '9px' }} >$</span>
                            <input
                                type="text"
                                className='pricing pl-10'
                                style={{ 'width': '100%', 'backgroundColor': '#f9ffd9', 'paddingLeft': '25%', 'textAlign': 'left', 'marginLeft': '8px' }}
                                placeholder="Price"
                                name="price"
                                value={value}
                                onChange={(e) => saveData(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSummaryLead;
