import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { Select, Button, Modal, message } from "antd";
import BulkData from "../../FieldTech/PreferredTechnician/BulkData";
import { getFranchise, getFieldOfficeStaff } from "../../Action/BulkReassign";
import { orderBy } from "lodash";
import { getBulkServiceOrderTechnician } from "../../Action/BulkReassign";
import * as yup from "yup";
import { map } from "lodash";
import { bulkreassigntechfinalcall } from "../../Action/BulkReassign";
import {getFranchiseListAll} from "../../Action/Franchise";
import Loader from '../../Components/Loader';
import { getFranchiseSelected } from "../../Utils/selectedFranchise";

import noActiveService from '../../Assets/images/no-active-service.svg';
import Search from "antd/lib/transfer/search";
import Aside from "../WeekSetup/Components/Common/Aside";
import { alert } from "@mobiscroll/react";
const PreferredTechnician = () => {
  const { Option } = Select;
  const [techfromname, setTechfromname] = useState();
  const [searchtable, setSearchtable] = useState(false);
  const [franchiseList, setFranchiseList] = useState({});
  const authUserRole = JSON.parse(localStorage.getItem("authUser"));
  const loggedInUser = authUserRole?.user?.user_roles?.map((x) => x.name);
  const multiLocationUser = JSON.parse(localStorage.getItem('temp_franchise')).data.is_multi_location_franchise
  const [selectedFranchise, setSelectedFranchise] = useState(multiLocationUser ? 'All' : getFranchiseSelected().uuid === 'all' ? localStorage.getItem("breFranchise") : getFranchiseSelected().uuid);
  const [selectedServiceType, setServiceType] = useState('');
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnicianfrom, setSelectedTechnicianfrom] = useState("");
  const [selectedTechnicianfromPrevval, setselectedTechnicianfromPrevval] =
    useState("");
  const [selectedTechnicianto, setSelectedTechnicianto] = useState("");
  const [showbulksamemodal, setShowBulkSameModal] = useState(false);
  const [noorder, setNoorder] = useState(false);
  const [loader, setLoader] = useState(false);
  const [orderdata, setOrderdata] = useState();
  const [bulkReassignClicked, setBulkReassignClick] = useState(false);
  const [state, setState] = useState({
    errors: {},
    reassign_from: selectedTechnicianfrom,
  });
  const [statetwo, setStatetwo] = useState({
    errors_reassign_to: {},
    reassign_to: selectedTechnicianto,
  });

  const reassigntoaftervalidate = async (params) => {
    console.log(params);
    let result = await bulkreassigntechfinalcall(params);
    let data = result;
    setTimeout(function () {
      window.location.reload(true);
    }, 500);
    message.success("Service Orders Reassigned");
  }
  const onbulksearchagain = () => {
    setShowBulkSameModal(false);
  };

  const onselecttecfrom = (technician_uuid) => {
    if (selectedTechnicianfromPrevval == "") {
      setselectedTechnicianfromPrevval(technician_uuid);
    }
    setState({
      errors: {},
      reassign_from: technician_uuid,
    });
    setSelectedTechnicianfrom(technician_uuid);
  };

  const onselectFranchise = (franchise_uuid) => {
     setSelectedTechnicianfrom('')
     setSelectedFranchise(franchise_uuid);
     const field_tech_obj = {
      bulk_reassign_field_office_uuid: franchise_uuid,
    };
    getFieldOfficeStaffApiCall(field_tech_obj);
  };

 const onselectServiceType = (serviceType) => {
    if(serviceType !== 'All'){
      setServiceType(serviceType)
    }
    else{
      setServiceType('maintenance,service,construction,renovation')
    }
  }
  const onselecttecto = (technician_uuid) => {
    setStatetwo({
      errors_reassign_to: {},
      reassign_to: technician_uuid,
    });
    setSelectedTechnicianto(technician_uuid);
  };

  const confirmsearchorder = () => {
    // Need to pass selected Franchisee uuid and get data from API
    gettechserviceorder(selectedTechnicianfrom);
    setselectedTechnicianfromPrevval(selectedTechnicianfrom);
    setShowBulkSameModal(false);
  };

  const gettechserviceorder = async (tech_uuid) => {
    let ordering = '';
    let franchiseUUID = ''; //Commenting to check for QA merge issue
    if(selectedFranchise !== 'All'){
      franchiseUUID = selectedFranchise
    }
    else{
      franchiseUUID = ''
    }
    
    let data = await getBulkServiceOrderTechnician(tech_uuid, ordering,franchiseUUID,selectedServiceType);
    if (!data.data.length) {
      setSearchtable(false);
      setNoorder(true);
    } else {
      setSearchtable(true);
      setNoorder(false);
      setOrderdata(data.data);
    }
    setLoader(false)
  };

  const seachvalidate = (e) => {
    setLoader(true)
    e.preventDefault();
    setTimeout(() => {
      let { reassign_from } = state;
      //console.log(state);
      const schema = yup.object().shape({
        reassign_from: yup.string().required("Select A Technician"),
      });
      //console.log(schema);
      const data = { reassign_from };
      //console.log(data);
      let IsValid;
      let err_data = {};
      schema.isValid(data).then(function (valid) {
        //alert(valid);
        IsValid = valid;
      });
      setTimeout(() => {
        //alert(IsValid);
        if (IsValid) {
          onclickonsearchbtn();
        } else {
          schema.validate(data, { abortEarly: false }).catch(function (err) {
            map(err.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setState({
              ...state,
              errors: err_data,
            });
            console.log("err_data:", err_data);
          });
        }
      }, 100);
    }, 300);
  };
  let { reassign_from, reassign_to, errors } = state;



  const seachvalidatetwo = (e, params) => {
   // e.preventDefault();
    setTimeout(() => {
      let { reassign_to } = statetwo;
      //console.log(statetwo);
      const schema = yup.object().shape({
        reassign_to: yup.string().required("Select A Technician"),
      });
      //console.log(schema);
      const data = { reassign_to };
      //console.log(data);
      let IsValid;
      let err_data = {};
      schema.isValid(data).then(function (valid) {
        //alert(valid);
        IsValid = valid;
      });
      setTimeout(() => {
        console.log(IsValid);
        if (IsValid && params.uuid != undefined) {
          reassigntoaftervalidate(params);
        } else {
          schema.validate(data, { abortEarly: false }).catch(function (err) {
            map(err.inner, (item) => {
              err_data[item.path] = item.message;
            });
            setStatetwo({
              ...statetwo,
              errors_reassign_to: err_data,
            });
            console.log("err_data:", err_data);
          });
        }
      }, 100);
    }, 300);
  };

  const onclickonsearchbtn = () => {
    let techname = technicians.filter((item) => {
      if (item?.uuid === selectedTechnicianfrom) {
        const firstName = item?.user?.first_name;
        const lastName = item?.user?.last_name;
        const FullTechName = `${firstName} ${lastName}`;
        setTechfromname(FullTechName);
        return FullTechName;
      }
    });
    //onseachbtnclick();
    //alert(selectedTechnicianfrom);
    //alert(selectedTechnicianfromPrevval);
    //setselectedTechnicianfromPrevval(selectedTechnicianfrom);
    if (
      selectedTechnicianfrom != "" &&
      selectedTechnicianto != "" &&
      searchtable === true &&
      selectedTechnicianfrom != selectedTechnicianfromPrevval
    ) {
      setShowBulkSameModal(true);
      setSearchtable(true);
    } else if (selectedTechnicianfrom != "") {
      setSearchtable(true);
      gettechserviceorder(selectedTechnicianfrom);
    }



    //alert(selectedTechnicianfrom);
    //console.log(technicians);
    // if(searchtable == true){
    //   setSearchtable(false);
    // }
    // else{
    //   setSearchtable(true);
    // }

    //
  };

  useEffect(() => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    const loggedInUserRole = authUser?.user?.user_roles?.map((x) => x.name);
    if(getFranchiseSelected().uuid !== 'all'){
      localStorage.setItem("breFranchise", getFranchiseSelected().uuid);
    }
    //const selectedFranchise = authUser?.user?.associated_field_offices[0]?.uuid;
    const getFranchiseApi = async () => {
      const response = await getFranchiseListAll();
      if (Object.keys(response)?.length > 0) {
       /*  if (loggedInUserRole?.includes("franchise_owner")) {
          const franchise = response?.filter(
            (franchise) => franchise.uuid === selectedFranchise
          );
          setFranchiseList(franchise);
        } else { */
          setFranchiseList(response);
        //}
      }
    };
    getFranchiseApi();

    setSelectedFranchise(selectedFranchise);
    const field_tech_obj = {
      bulk_reassign_field_office_uuid: selectedFranchise,
    };
    getFieldOfficeStaffApiCall(field_tech_obj);
  }, []);

  const getFieldOfficeStaffApiCall = async (params) => {
    if(params.bulk_reassign_field_office_uuid === 'All'){
      params.bulk_reassign_field_office_uuid = ''
    }
    const response = await getFieldOfficeStaff(params);
    if (Object.keys(response)?.length > 0 && typeof(response) === 'object') {
      let temp = response.results;
      temp = orderBy(temp, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc");
      temp.push({
        uuid: "not_assigned",
        user: {
          first_name: "Not",
          last_name: "Assigned",
        }
      });
      setTechnicians(temp);
    }
  };
  const bulkReassignToggle = () => {
    setBulkReassignClick(true)
  }
  const bulkreassigncheck = {
    reassign_from: selectedTechnicianfrom,
    reassign_to: selectedTechnicianto,
    technicians: technicians,
    techfromname: techfromname,
  };
  let { errors_reassign_to } = statetwo;
  console.log(errors_reassign_to["reassign_to"]);
  console.log(errors_reassign_to);
  return (
    <div style={{ display: "flex" }}>
      {loader && <Loader />}
      <div
        style={{ width: "auto" }}
        className="aside-area pt-0 account_module_wrapper"
      >
        <aside
          style={{ maxWidth: "490px" }}
          className="laside fo-side d-flex flex-column justify-content-between"
        >
          <div className="tec-re-asign">
            <div className="content-area">
              <Sidebar sideBarBackgroundColor="#005d89" />
            </div>
            <div className="bulk-teck-reassign" style={{ "margin-left": "6%" }}>
              <label htmlFor="">
                <h1 className="bul-res-tec"> Bulk Reassign Technician</h1>
                <span className="p-tag-res">
                  <p style={{ marginLeft: "-42px", marginTop: "-10px" }}>
                    Reassign service order from one technician to another.
                  </p>
                </span>
              </label>
            </div>
            <div className="res-tec-fr" style={{ "margin-left": "6%" }}>
              <label>
                <h4 style={{ marginLeft: "-42px", marginTop: "-10px" }}>
                  {" "}
                  Franchise<sup>*</sup>
                </h4>
              </label>
              <div
                className="res-fr-tech-in"
                style={{
                  "webkit-tap-highlight-color": "rgba(0,0,0,0)",
                  background: "transparent",
                  width: "22%",
                  marginBottom: "50px",
                }}
              >
                <Select
                  showSearch
                  name="franchise"
                  placeholder="Search"
                  optionFilterProp="children"
                  onChange={onselectFranchise}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedFranchise}
                > {multiLocationUser && <Option key="All" value="All">All</Option>}
                  {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
                    <Option key={option?.uuid} value={option?.uuid}>{`${option?.name || ""}`}</Option>
                  ))}
                </Select>
                {errors["reassign_from"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["reassign_from"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="res-tec-fr" style={{ "margin-left": "6%" }}>
              <label>
                <h4 style={{ marginLeft: "-42px", marginTop: "-10px" }}>
                  {" "}
                  Reassign From<sup>*</sup>
                </h4>
              </label>
              <div
                className="res-fr-tech-in"
                style={{
                  "webkit-tap-highlight-color": "rgba(0,0,0,0)",
                  background: "transparent",
                  width: "22%",
                  marginBottom: "50px",
                }}
              >
                <Select
                  showSearch
                  name="reassign_from"
                  placeholder="Search"
                  optionFilterProp="children"
                  onChange={onselecttecfrom}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedTechnicianfrom || undefined}
                // disabled={selectedWorkOrderStatus === "forecast"}
                >
                  {technicians?.map((option) => (
                    <Option key={option?.uuid} value={option?.uuid}>{`${option?.user?.first_name || ""
                      } ${option?.user?.last_name || ""}`}</Option>
                  ))}
                </Select>
                {errors["reassign_from"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["reassign_from"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="res-tec-fr" style={{ "margin-left": "6%" }}>
              <label>
                <h4 style={{ marginLeft: "-42px", marginTop: "-10px" }}>
                  {" "}
                  Service Type
                </h4>
              </label>
              <div
                className="res-fr-tech-in"
                style={{
                  "webkit-tap-highlight-color": "rgba(0,0,0,0)",
                  background: "transparent",
                  width: "22%",
                  marginBottom: "50px",
                }}
              >
                <Select
                  showSearch
                  name="serviceType"
                  placeholder="Search"
                  optionFilterProp="children"
                  onChange={onselectServiceType}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="All">All</Option>
                  <Option value="Construction">Construction</Option>
                  <Option value="Maintenance">Maintenance</Option>
                  <Option value="Renovation">Renovation</Option>
                  <Option value="Service">Service</Option>
                </Select>
                {errors["reassign_from"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["reassign_from"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <span className="tec-in-with">
              {" "}
              <Button
                className="btn btn-warning"
                onClick={(e) => seachvalidate(e)}
                style={{ width: "21.3%", alignItems: "center" }}
              >
                Search
              </Button>
            </span>

            <div
              className="res-tec-fr"
              style={{ "margin-left": "6%", marginTop: "30px" }}
            >
              <label>
                <h4 style={{ marginLeft: "-42px", marginTop: "-10px" }}>
                  {" "}
                  Reassign To<sup>*</sup>
                </h4>
              </label>
              <div
                className="res-fr-tech-in"
                style={{
                  "webkit-tap-highlight-color": "rgba(0,0,0,0)",
                  background: "transparent",
                  width: "22%",
                }}
              >
                <Select
                  showSearch
                  name="reassign_to"
                  placeholder="Search"
                  optionFilterProp="children"
                  onChange={onselecttecto}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                // value={selectedTechnician || undefined}
                // disabled={selectedWorkOrderStatus === "forecast"}
                >
                  {technicians?.map((option) => (
                    <Option
                      key={option?.uuid}
                      value={option?.uuid}
                    >{`${option?.user?.first_name} ${option?.user?.last_name}`}</Option>
                  ))}
                </Select>
                {errors_reassign_to["reassign_to"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors_reassign_to["reassign_to"]}
                  </label>
                ) : (
                  ""
                )}
                {/* <Select showSearch placeholder="Search"
            onChange={(e) => {
              updateTechnician(e);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={state?.selectedTechnician || undefined}
            disabled={transection_type === "View"}
          >
            {technicians?.map((technician, index) => {
              return (
                <Option
                  key={index}
                  value={technician.user.uuid}
                >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
              );
            })}
            </Select> */}
              </div>
            </div>
            <span className="tec-in-with">
            <Button
            className="btn btn-warning"
            onClick={bulkReassignToggle}
            style={{
              width: "348px",
              backgroundColor: "#fff",
              color: "#000",
              border: "2px solid #ff681d",
            }} 
            >
              {" "}
              Reassign
            </Button>
            </span>
          </div>
        </aside>
      </div>
      {!noorder && !searchtable && (
        <div className="sec-part-service">
          <label>
            <h4 style={{ borderBottom: "none" }}>Service orders </h4>
          </label>
          <div
            style={{
              marginTop: "18%",
              textAlign: "center",
              marginLeft: "25px",
            }}
          >
            <img className="mr-5" src={noActiveService} alt="" />
            <span className="">
              <h4>No Service Orders</h4>
            </span>
            <p>
              Service orders will appear here when a technician has been selected.
            </p>
          </div>
        </div>
      )}

      {!noorder && searchtable && (
        <BulkData
          bulkreassigncheck={bulkreassigncheck}
          ordertabledata={orderdata}
          validationreassignto={seachvalidatetwo}
          bulkReassignClicked={bulkReassignClicked}
          selectedServiceType={selectedServiceType}
          selectedFranchise={selectedFranchise}
        />
      )}

      {noorder && (
        <div className="sec-part-service">
          <label>
            <h4 style={{ borderBottom: "none" }}>Service orders </h4>
          </label>
          <div
            style={{
              marginTop: "18%",
              textAlign: "center",
              marginLeft: "25px",
            }}
          ><img className="mr-5" src={noActiveService} alt="" />
            <span className="">
              <h4>No Service Orders</h4>
            </span>
            <p>The technician selected has no assigned service orders.</p>
          </div>
        </div>
      )}
      <Modal
        title="Error Modal "
        visible={showbulksamemodal}
        okText={"Search"}
        cancelText={"Cancel"}
        onCancel={() => onbulksearchagain()}
        onOk={confirmsearchorder}
        className="delete_user_modal"
        width={400}

      // footer={[
      //   <button
      //     key="cancel"
      //     className="btn btn-brand btn-bdr"
      //     onClick={() => onnoteupdate()} >
      //     Search
      //   </button>,
      //   ]}
      >
        <div>
          <h2> New Search </h2>
          <p>
            Performing a new search now will clear any progress made so far.
          </p>
          <p>Would you like to continue?</p>
        </div>
      </Modal>
    </div>
    // </div>
  );
};

export default PreferredTechnician;
