import axiosInstance from "../../Lib/Axios";

const formattedPriceCatlogList = (obj) => {
  return ({
    ...obj,
    results: obj.results.map((item) => ({
      ...item,
      available_multipliers: !(item.available_multipliers || []).includes(item.multiplier_used) ? [...item.available_multipliers, item.multiplier_used] : item.available_multipliers,
    }))
  })
}

export const getPriceCatalogApiReq = (params) => {
  return axiosInstance
    .get(`pricing-catalog/?field_office__uuid=${params.field_office__uuid || ""}&part_type=${params.part_type || ""}&search=${params.search || ""}&limit=${params.pageSize || ""}&offset=${(params.current - 1) * parseInt(params.pageSize) || ""}&ordering=${params.ordering || ""}&productcode=${params.productcode || ""}`)
    .then((response) => {
      return formattedPriceCatlogList(response?.data);
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
}; 

export const getPartTypeApiReq = () => {
  return axiosInstance
    .get("pricing-catalog/part_types/")
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const addNewPricingItems = (field_office__uuid, params) => {
  return axiosInstance
    .post(`pricing-catalog/?field_office__uuid=${field_office__uuid}`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};


export const updatePricingItems = (field_office__uuid, params) => {
  return axiosInstance
    .patch(`pricing-catalog/update_field_office_price_catalog/?field_office_price_uuid=${field_office__uuid}`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};
export const deletePriceCatalogItem = (uuid) => {
  return axiosInstance
    .patch(`pricing-catalog/delete_field_office_price_catalog/?field_office_price_uuid=${uuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};