import React from "react";
import { useParams } from "react-router-dom";
import { Tooltip, DatePicker } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import PDFIcon from "../../../Assets/images/pdf-icon.png";
import CSVIcon from "../../../Assets/images/csv-icon.png";
import FranchiseSelector from "../Filters/Franchise.Selector";
import { useReport } from "../../Hooks/Report.Hook";
import ReportsData from "../../Common/ReportList.Data";
import { getPDFReport, getCSVReport } from "../../Common/getObjectReport";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';

const WorkOrderReportTopMenu = () => {
  const { id } = useParams();
  const {
    franchise,
    serviceDatePeriod,
    superSearch,
    buildReport,
    resetReport,
    setFranchise,
    setServiceDatePeriod,
  } = useReport();

  const report_type = "service_work_order_report";

  const getFilters = () => {
    return {
      residential_lead_profile__field_office__uuid:
        franchise.value === "all" ? undefined : franchise.value,
      created_at__lte: !_.isEmpty(serviceDatePeriod)
        ? serviceDatePeriod.end_date + "T23:59:59.99Z"
        : undefined,
      created_at__gte: !_.isEmpty(serviceDatePeriod)
        ? serviceDatePeriod.start_date + "T0:0:0.0Z"
        : undefined,
      q: superSearch !== "" ? superSearch : undefined,
    };
  };

  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
  };
  const handleCustomDateChange = (e) => {
    setServiceDatePeriod({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <div className="filter_wrapper acc-wrap p-0 mb-20">
        {/*<h2 className="m-0 text-nowrap pr-10">
          {ReportsData.find((item) => item.id === id)?.title}
        </h2>*/}
        <div class="report-topmenu_title">
          <h2>{ReportsData.find((item) => item.id === id)?.title}</h2>
        </div>
        <div className="form-group flex ai-center w-100 flex-end fltr-txt">
          <Tooltip title="Download CSV" color={"#005d89"}>
            <button
              className="btn btn-warning-outline"
              onClick={() =>
                getCSVReport({ report_type, filters: getFilters() })
              }
            >
              <img
                src={CSVIcon}
                alt={"Download CSV"}
                style={{ maxWidth: "25px" }}
              />
            </button>
          </Tooltip>
          <Tooltip title="Download PDF" color={"#005d89"}>
            <button
              className="btn btn-warning"
              onClick={() =>
                getPDFReport({ report_type, filters: getFilters() })
              }
            >
              <img
                src={PDFIcon}
                alt={"Download PDF"}
                style={{ maxWidth: "21px" }}
              />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="" style={{ display: "flex", marginBottom: "20px" }}>
        <div className="fitem" style={{ width: "200px", marginRight: "10px" }}>
          <label>
            <b>Franchise</b>
          </label>
          <FranchiseSelector
            handleChange={handleFranchiseChange}
            defaultValue={franchise}
          />
        </div>
        <div className="fitem">
          <label>
            <b>Time Period</b>
          </label>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            onChange={handleCustomDateChange}
          />
        </div>
        <div className="fitem">
          <div style={{ margin: "25px 10px 0 10px" }}>
            <button
              type="button"
              className="btn btn-brand btn-bdr"
              onClick={resetReport}
            >
              Reset
            </button>
          </div>
        </div>
        <div className="fitem">
          <div style={{ margin: "25px 10px 0 10px" }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={buildReport}
            >
              Build Report
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkOrderReportTopMenu;
