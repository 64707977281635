import React, {Component} from "react";

class Loader extends Component {
  render() {
    return (
      <div className='loader_wrapper'>
        <div className='loader07'/>
      </div>
    )
  }
}

export default Loader;