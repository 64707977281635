var info = {
    url: '/api/info'
}

export function updatePoolSizeRequest(params){    
    // fetch(info.url + '/updatepoolsize', { clientId: params.clientId, poolsize: params.poolsize})
    return Promise.resolve('success');
}

export function uploadTaskImageRequest(params){
    // fetch(info.url + '/uploadtaskimage', { chemical: params.chemical })
    return Promise.resolve('success');
}

export function upsertChemical(params){
    // fetch(info.url + '/upsertchemical', { chemical: params.chemical })
    return Promise.resolve('success');
}

export function upsertServicePool(params){
    // return Info.processPromise($http.post(Info.url + '/upsertservicepool', { task: params.task }));
    return Promise.resolve('success');
}