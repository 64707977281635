import React, { Component, useEffect, useState } from 'react';

import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import HeaderMobile from '../../Components/HeaderMobile'
import SidebarResponsive from '../../Components/SidebarResponsive'
import Aside from './Components/Common/Aside';

import Step1 from './Components/Step1';
import Step2 from './Components/Step2';
// import Step3 from '../Component/Step3';
import moment from 'moment-timezone';
import { map, union, spread } from 'lodash';
import { message } from 'antd';

const WeekSetup = (props) => {
const [openMenu, setOpenMenu] = useState(false);
const [searchValue, setSearchValue] = useState('');
const [state,setState] = useState({
    current: 0,
    week_start_date: moment().day(0).format("YYYY-MM-DD"),
    is_week_setup: false,
    serach_value: "",
    residentail_value: "",
    technician_details: [],
    service_type_value: [],
    is_rerun_optimization: false,
    selected_resource : [],
    selected_franchise : ''
})

function getState(){
    setState({
        current: 0,
        week_start_date: moment().day(0).format("YYYY-MM-DD"),
        is_week_setup: false,
        serach_value: "",
        residentail_value: "",
        technician_details: [],
        service_type_value: [],
        is_rerun_optimization: false,
        selected_resource : [],
        selected_franchise : ''       
    })
}

useEffect(()=>{
    const user = JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))
    : "";
    setState((state)=>({
        ...state,
        selected_franchise: user?.user?.associated_field_offices?.[0]?.uuid
    }))
},[])
  
 
//   UNSAFE_componentWillReceiveProps = (props) => {
//     if(props && props.WeeklySetup && props.WeeklySetup.data && props.WeeklySetup.data.message){
//       message.success('Services have been sent for scheduling and optimization.', 10);
//       setTimeout(()=> {
//         this.nextStep(0);
//         this.props.resetWeeklyAllocation()
//       },2000)
//     }
//   }

  const handleWeekDate = (start) => {
    setState((state)=>({
      ...state,  
      week_start_date: start,
    }))
  }

  const handleCompeleteWeekSetup = (value) => {
    setState((state)=>({
      ...state,  
      is_week_setup: value
    }))
  }

  //Get Technician Details
  const handleTechnicianDetails = (data) => {
    setState((state)=>({...state, technician_details: data }));
  }

  // handle service checked types
  const handleServicesTypes = (value) => {
    setState((state)=>({
      ...state,  
      service_type_value: value,
    }))
  }

  //Handle Selected Technician
  const handleSelectedTechnician = (value) => {
    setState((state)=>({
      ...state,  
      selected_resource : value
    }))
  }

  //Handle Selected Franchise
  const handleSelectedFranchise = (value) => {
    setState((state)=>({
      ...state,  
      selected_franchise : value
    }))
  }

  const RenderAllStep = () => {
    let { current } = state;
    switch (current) {
    case 0:
      return <Step1
        {...props}
        nextStep={(e) => { nextStep(e) }}
        weekStartDate={state.week_start_date}
        handleTechnicianDetails={(e) => handleTechnicianDetails(e)}
        selected_franchise={state.selected_franchise}
        current={state.current}
        isWeekSetup={state.is_week_setup}
        handelRerunOptimization={(e) => handelRerunOptimization(e)}
        isRerunOptimization={state.is_rerun_optimization}
        previousStep={previousStep}
        handleCompeleteWeekSetup={(e) => handleCompeleteWeekSetup(e)}
      />
    case 1:
      return <Step2 
        {...props} 
        weekStartDate={state.week_start_date}
        selected_franchise={state.selected_franchise}
        nextStep={(e) => { nextStep(e) }}
        current={state.current}
        isWeekSetup={state.is_week_setup}
        handelRerunOptimization={(e) => handelRerunOptimization(e)}
        isRerunOptimization={state.is_rerun_optimization}
        previousStep={previousStep}
        handleCompeleteWeekSetup={(e) => handleCompeleteWeekSetup(e)}
      />
      // case 2:
      // return <Step3 {...this.props} previousStep={this.previousStep} />

      // return <Step3 {...this.props}
      //   previousStep={this.previousStep}
      //   weekStartDate={this.state.week_start_date}
      //   isWeekSetup={this.state.is_week_setup}
      //   searchValue={this.state.serach_value}
      //   residentail_value={this.state.residentail_value}
      //   service_type_value={this.state.service_type_value}
      //   technician_details={this.state.technician_details}
      //   isRerunOptimization={this.state.is_rerun_optimization}
      //   handelRerunOptimization={(e) => this.handelRerunOptimization(e)}
      //   selected_resource={this.state.selected_resource}
      //   selected_franchise={this.state.selected_franchise}
      // />
    default:
      return <Step1 
        {...props} 
        weekStartDate={state.week_start_date}
        selected_franchise={state.selected_franchise}
        nextStep={(e) => { nextStep(e) }}
      />
    }
  }

  //Next Step
  const nextStep = (e) => {
    if(e === 2){
      getWeekAllocation();
    }
    else {
      setState((state)=>({
        ...state,
        current: e
      }))
    }
  }


   //Get All Week Allocation
   const getWeekAllocation = async () => {
     setState((state)=>({
        ...state,
       loader: true
     }));
     let tem_keys = [];
     let residentail_selected_row_keys = JSON.parse(sessionStorage.getItem('residential_selected_row_keys')) || '';
     let intial_selected_row_keys = JSON.parse(sessionStorage.getItem('intial_selected_row_keys')) || '';
     let commercial_selected_row_keys = JSON.parse(sessionStorage.getItem('commercial_selected_row_keys')) || '';
     // let install_selected_row_keys = JSON.parse(sessionStorage.getItem('install_selected_row_keys')) || '';

     const fn = spread(union);
     const arrs = [residentail_selected_row_keys, intial_selected_row_keys, commercial_selected_row_keys];
     tem_keys = fn(arrs);
     let data = {
       service_uuid_list: tem_keys,
       allocation_start_date: moment(props.weekStartDate).format('YYYY-MM-DD'),
       field_office__uuid: props.selected_franchise
     }
     setState((state)=>({...state, afterMoveWeekAllocation: false }));
     await props.getWeeklyAllocation(data);
   }

  //Previous Step
  const previousStep = (e) => {
    if (e === 1) {
      setState((state)=>({...state, is_week_setup: false }))
    }
    setState((state)=>({
      ...state,  
      current: e
    }))
  }

  // handle serach value
  const handleSearchValue = (value) => {
    setState((state)=>({
      ...state,  
      serach_value: value
    }))
  }

  const residentailServices = (value) => {
    setState((state)=>({
      ...state,  
      residentail_value: value
    }))
  }

  const handelRerunOptimization = (value) => {
    setState((state)=>({
      ...state,  
      is_rerun_optimization: value
    }))
  }

  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  }


    return (
      <div id="wrapper">
        <div className="content-area">
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Header 
          placeholder={"Acc Number, Customer Name, Address(Billing), Phone Number, Email Address"} 
          readOnly={false} 
          search_value={searchValue}
          setSearchValue={setSearchValue}
        //   onSearch={onSearch}
        //   onEnter={(e) => 
        //     {
        //       e.target.value.length > 2 ?               
        //         onSearch(e)
        //       : alert("Enter at least 3 character!")}
        //     }            
        />
        <Sidebar sideBarBackgroundColor='#005d89' />
        <SidebarResponsive sideBarBackgroundColor='#005d89' openMenu={openMenu} onMenubuttonClick={onMenubuttonClick} />
          <div className="container">
            <div className="aside-area pt-0 account_module_wrapper">
              <Aside
                {...props}
                handleWeekDate={(e) => handleWeekDate(e)}
                handleCompeleteWeekSetup={(e) => handleCompeleteWeekSetup(e)}
                handleSearchValue={(e) => handleSearchValue(e)}
                residentailServices={(e) => residentailServices(e)}
                isWeekSetup={state.is_week_setup}
                nextStep={(e) => { nextStep(e) }}
                previousStep={(e) => { previousStep(e) }}
                current={state.current}
                handleServicesTypes={(event) => handleServicesTypes(event)}
                handelRerunOptimization={(e) => handelRerunOptimization(e)}
                isRerunOptimization={state.is_rerun_optimization}
                technician_details={state.technician_details}
                handleSelectedTechnician={(e) => handleSelectedTechnician(e)}
                handleSelectedFranchise={(e) => handleSelectedFranchise(e)}
                selected_franchise={state.selected_franchise}
                selected_resource={state.selected_resource}
              />

              {RenderAllStep()}
              {/* <div className="d-flex justify-content-end w-100">
                 <button className="btn btn-primary">Next Step</button>
              </div> */}

            </div>
          </div>
        </div>
      </div>
    );
}

export default WeekSetup;