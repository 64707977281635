import React from "react";

const FooterForm = (props) => {
  function handleClick() {
    props.handleClick();
  }

  function handleClickEmail(){
    props.handleClickEmail();
  }

  return (
    <div className="form-group mt-30">
      <div className="fitem  text-right">
        <button
          className={`btn btn-warning`}
          onClick={() => handleClick()}
          type="primary"
        >
          Save
        </button>
        <button className={`btn  ${props.disabled && props.textButton === "Save & Email" ? "btn-disabled" : "btn-bdr"}`} onClick={() => handleClickEmail()} >{props.textButton}</button>
      </div>
    </div>
  );
};

export default FooterForm;
