import React, { useState, useEffect } from "react";
import stax from '../../Assets/images/stax.png';
import { Select, Modal, message } from "antd";
import { isEmpty, map } from 'lodash';
import * as yup from 'yup';
import Loader from '../../Components/Loader';
import { gpsTrackSetUp, getGpsTrackSetUp } from '../../Action/Franchise';
import mas_track from "../../Assets/images/mas_track.png";
const SetupGpsTrack = ({ franchise_data }) => {
    const [reconnect, setReconnect] = useState(false);
    const [state, setState] = useState(
        {
            errors: {},
            loader: false,
            gps_user_id: '',
            gps_password:''
        }
    );
    const onTextChange = async (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    }
    const onValid = async () => {
        let { gps_user_id,gps_password } = state;
        let temp_url = await gpsTrackSetUp(franchise_data?.uuid, {
            "gps_account_user_id": gps_user_id,
            "gps_account_password": gps_password
        });
        if (!isEmpty(temp_url.gps_account_user_id)) {
            setReconnect(true);
            message.success('GPS Connected Successfully');
            onFormat();
        }
    }
    const getData = async () => {
        let data = await getGpsTrackSetUp(franchise_data?.uuid);
        if (!isEmpty(data.gps_account_user_id)) {
            setReconnect(true);
        }
    }
    const onFormat = () => {
        setState({
            errors: {},
            loader: false,
            gps_user_id: '',
            gps_password:''
        });
    }
    const onSetupClick = (e) => {
        e.preventDefault();
        setTimeout(() => {
            let { gps_user_id , gps_password} = state;
            const schema = yup.object().shape({
                gps_user_id : yup.string().nullable().required("GPS account user id is required"),
                gps_password : yup.string().nullable().required("GPS account password is required")
            })
            const data = { gps_user_id , gps_password}
            // check validity
            let IsValid;
            let err_data = {};
            schema.isValid(data)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    onValid();
                }
                else {
                    schema.validate(data, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            })
                            setState({
                                ...state,
                                errors: err_data
                            })
                            console.log("err_data:", err_data)
                        });
                }
            }, 100);
        }, 300);
    }
    useEffect(() => {
        getData();
    }, []);

    let { gps_user_id, gps_password,errors, loader } = state;

    return (
        <div className="">
            <div className="card">
                <div className="card-body">
                <img src={mas_track} alt="" className="stripe_logo" />
                <p><b>GPS Settings</b></p>
               <div className="form-group">
                {console.log(reconnect)}
                        {!reconnect ? <>
                            <div className="fitem">
                            <label style={{ fontWeight: 'bold' }}>GPS Account User Id  <sup>*</sup></label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="gps_user_id"
                                    onChange={(e) => onTextChange(e)}
                                    value={gps_user_id || ''}
                                />
                                {errors['gps_user_id'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['gps_user_id']}</label> : ''}
                            </div>
                            <div className="fitem">
                            <label style={{ fontWeight: 'bold' }}>GPS Account Password  <sup>*</sup></label>
                                <input
                                    type="text"
                                    className='form--control'
                                    // placeholder="ID"
                                    name="gps_password"
                                    onChange={(e) => onTextChange(e)}
                                    value={gps_password || ''}
                                />
                                {errors['gps_password'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['gps_password']}</label> : ''}
                            </div>
                            <br />
                            <div>
                                <button className='btn btn-primary' onClick={(e) => onSetupClick(e)}>Save GPS settings</button>
                            </div>
                        </>
                            :
                            <div>
                                <button className='btn btn-warning' >GPS Is Already Connected</button>
                                <button className='btn btn-primary' onClick={() => setReconnect(false)}>Reconnect</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                loader ?
                    <Loader />
                    : ''}
        </div >
    );
}

export default SetupGpsTrack;