import React, { Component } from 'react';
import { Table } from 'antd';
import Datatable from '../../DataTable';
class ResidentialTable extends Component {
  render() {
    let {
      residential_selected_row_keys,
      residential_staff_pagination,
      residential_allocated_service,
      handleSelectAll,
      renderHeaderCheckbox
    } = this.props;
    
    return (
      <div>
        <Table
          rowKey={record => record?.uuid}
          dataSource={residential_allocated_service}
          pagination={residential_staff_pagination}
          rowSelection={{
            type:'checkbox',
            preserveSelectedRowKeys: true,
            selectedRowKeys: residential_selected_row_keys,
            columnTitle: () => renderHeaderCheckbox(),
            onSelectAll: () => handleSelectAll("residential"),
            onChange: (residential_selected_row_keys) => {
              this.props.onResidentialSelectedRowKeysChange(residential_selected_row_keys)
            },
            getCheckboxProps: (record) => {
              return {
                disabled: record.is_selected || record.status === 'cancelled'
              };
            }
          }}
          onChange={this.props.intialPaginationChange}
          columns={Datatable.ServiceToPerform(this)}
        />
        {residential_staff_pagination.total > 0 ? this.props.residential_page_size_limit : ""}
      </div>
    );
  }
}

export default ResidentialTable;