import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Select, Drawer, DatePicker, Table, Menu, Dropdown } from "antd";
import moment from "moment-timezone";
import { orderBy, isEmpty } from "lodash";
import { FilterOutlined } from "@ant-design/icons";
import HeaderMobile from "../../Components/HeaderMobile";
import Header from "../../Components/Header";
import GlobalSearch from "../../Components/GlobalSearch";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import Loader from "../../Components/Loader";
import DataTable from "../Common/DataTable";
import { getFranchise, getCommercailCustomer } from "../../Action/Franchise";
import {
  getCommercialCustomerList,
  getCustomerListCommercial,
} from "../../Action/Customer";
import AddNewLead from "./AddNewLead";
import { getFranchiseSelected, setFranchiseSelected } from '../../Utils/selectedFranchise';

const CommercialCustomerList = () => {
  const { Option } = Select;
  const { state } = useLocation();
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [canceledCustomerList, setCanceledCustomerList] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [isAddNewLead, setIsAddNewLead] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [currentTab, setCurrentTab] = useState(state?.currentTab || 1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerCustomer, setOpenDrawerCustomer] = useState(false);
  const [activePagination, setActivePagination] = useState("15");
  const [activePagination_lead, setActivePagination_lead] = useState('15');
  const [activePagination_canceled, setActivePagination_canceled] = useState('15');
  const [current_tab, setcurrent_tab] = useState(1);
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterClickedCustomer, setFilterClickedCustomer] = useState(false);
  const [franchiseList, setFranchiseList] = useState({});
  const [commercialCustList, setCommercialCustomer] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [customerListCommercial, setCustomerListCommercial] = useState([]);
  const [current_user, setCurrent_user] = useState('');
  const [offset, setOffset] = useState(0);
  const [offset_lead, setOffset_lead] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [limit, setLimit] = useState(15);
  const [filters, setfilters] = useState('');
  const [filterData, setFilterData] = useState({
    franchise: 'all',
    active: false,
    inactive: false,
    filterFranchise: "all",
    last_contacted: [],
    lead_stage: "all",
    outreach: "all",
    created_at__lte: null,
    created_at__gte: null,
  });
  const [filterDataCustomer, setFilterDataCustomer] = useState({
    franchise: 'all',
    franchiseList: [],
    active: false,
    inactive: false,
    filterFranchise: '',
    joined: [],
    lastService: [],
    outstanding: 'all',
    satisfaction: '',
    created_at__lte: null,
    created_at__gte: null
  });
  const satisfaction_score = [{ key: '>=90', value: '90% or Greater 90%' },
  { key: '>=75', value: '75% or Greater' },
  { key: '<75', value: '75% or Less' },
  { key: '<50', value: '50% or Less' },
  ]
  const [customerListPagination, setCustomerListPagination] = useState({
    showSizeChanger: false,
    pageSize: 15,
    ordering: "-created_at",
    current: 1,
  });
  const [canceledCustomerListPagination, setCanceledCustomerListPagination] = useState({
    showSizeChanger: false,
    pageSize: 15,
    ordering: '-created_at',
    current: 1
  });
  const [leadListPagination, setLeadListPagination] = useState({
    showSizeChanger: false,
    pageSize: 15,
    ordering: '-created_at',
    current: 1
  });
  const value_lead_stage = [
    { key: "all", value: "All" },
    { key: "new", value: "New" },
    { key: "contacted", value: "Contacted" },
    { key: "interested", value: "Interested" },
  ];
  const value_outreach = [
    { key: "all", value: "All" },
    { key: "franchise_owner", value: "Franchise" },
    { key: "call_center", value: "Call Center" },
  ];
  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };
  const onSearch = async (e) => {
    if (
      (e.target.value?.length >= 3 || e.target.value?.length === 0)
    ) {
      setTimeout(() => {
        setLoader(true);
        setSearchValue(e.target.value);
        setLoader(false);
      }, 2000);
    }
  };
  useEffect(() => {
    if(searchValue) {
      currentTab === 0 ? getLead() : getCustomer();
    }
  }, [searchValue]);

  useEffect(() => {
      currentTab === 0 ? getLead() : getCustomer();
  }, [activePagination, activePagination_lead, activePagination_canceled])
  // filter data change
  const onFilterChange = (e, type, name) => {
    const value = type === "bool" ? e.target.checked : e.target.value;
    if (e.target.name === "last_contacted" && e.target.value.length > 0) {
      setFilterData((filterData) => ({
        ...filterData,
        created_at__lte: moment(e.target.value[1]).format("YYYY-MM-DD"),
        created_at__gte: moment(e.target.value[0]).format("YYYY-MM-DD"),
      }));
    } else if (e.target.name === "franchise") {
      setFilterData((filterData) => ({
        ...filterData,
        franchise: value,
      }));
      current_user === 'franchise_owner' || current_user === 'operations_mgr' && setFranchiseSelected(value);
    } else {
      setFilterData((filterData) => ({
        ...filterData,
        [e.target.name]: value,
      }));
    }
  };
  // customer filter data change
  const onFilterChangeCustomer = (e, type, name) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    if (e.target.name === "franchise") {
      setFilterDataCustomer({
        ...filterDataCustomer,
        franchise: value,
      });
      current_user === 'franchise_owner' || current_user === 'operations_mgr' && setFranchiseSelected(value);
    } else {
      setFilterDataCustomer({
        ...filterDataCustomer,
        [e.target.name]: value,
      });
    }
  };
  // on filter click
  const onFilterClickCustomer = () => {
    // const obj = {
    //   offset: 0,
    //   limit: activePagination,
    //   ordering: "-created_at",
    //   is_active: current_tab === 0,
    // };
    setFilterClickedCustomer(true);
    closeDrawerCustomer();
  };
  useEffect(() => {
    if (filterClicked) {
      setLoader(true);
      getLead();
    }
  }, [filterClicked]);
  useEffect(() => {
    if (filterClickedCustomer) {
      setLoader(true);
      getCustomer();
    }
  }, [filterClickedCustomer]);
 
  useEffect(() => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setCurrent_user(authUser?.user?.role?.[0]);
    if (state?.tab !== undefined) {
      setCurrentTab(state?.tab)
    }
  }, []);
  const onPrimaryFilterChange = (e, type, name) => {
    if (currentTab === 1) {
      setFilterDataCustomer({
        ...filterDataCustomer,
        franchise: e.target.value,
      });
      current_user === 'franchise_owner' || current_user === 'operations_mgr' && setFranchiseSelected(e.target.value);
      onFilterClickCustomer();
    } else {
      setFilterData((filterData) => ({
        ...filterData,
        franchise: e.target.value,
      }));
      current_user === 'franchise_owner' || current_user === 'operations_mgr' && setFranchiseSelected(e.target.value);
      onFilterClick();
    }
  };
  // on filter click
  const onFilterClick = () => {
    const obj = {
      offset: 0,
      limit: activePagination,
      ordering: "-created_at",
      is_active: current_tab === 0,
    };
    setFilterClicked(true);
    closeDrawer();
  };
  const getLead = async () => {
    setFilterClicked(false);
    let data = {
      offset: offset_lead,
      limit: activePagination_lead,
      ordering: leadListPagination.ordering,
      q: searchValue || "",
    };
    if (filterData?.franchise !== "all") {
      data.lead_profile__field_office__uuid = filterData?.franchise;
    }
    if (filterData?.created_at__lte && filterData?.created_at__gte) {
      data.created_at__lte = filterData?.created_at__lte;
      data.created_at__gte = filterData?.created_at__gte;
    }
    if (filterData?.outreach !== "all") {
      data.lead_profile__outreach = filterData?.outreach;
    }
    if (filterData?.lead_stage !== "all") {
      data.lead_profile__stage = filterData?.lead_stage;
    }
    if (
      (filterData.active && !filterData.inactive) ||
      (!filterData.active && filterData.inactive)
    ) {
      data.is_active = filterData.active ? filterData.active : false;
    }
    const customer_list = await getCommercialCustomerList(data);
    setCustomerList(customer_list);
    setLoader(false);
  };
  const getCustomer = async () => {
    setFilterClickedCustomer(false);
    let data = {
      is_active: currentTab === 2 ? false : true,
      offset: offset,
      limit: currentTab === 2 ? activePagination_canceled : activePagination,
      ordering: currentTab === 2 ? customerListPagination.ordering : canceledCustomerListPagination.ordering,
      q: searchValue
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser?.user?.role?.[0] === 'franchise_owner' || current_user === 'operations_mgr' && filterDataCustomer?.franchiseList.length <= 1 && filterDataCustomer?.franchise === 'all'){
      return;
    }
    if (filterDataCustomer?.franchise !== "all") {
      data.lead_profile__field_office__uuid = filterDataCustomer?.franchise;
    }
    if (filterDataCustomer?.filterFranchise.length) {
      data.lead_profile__field_office__uuid = filterDataCustomer?.filterFranchise.toString();
    }
    if (filterDataCustomer?.created_at__lte && filterDataCustomer?.created_at__gte) {
      data.created_at__lte = filterData?.created_at__lte;
      data.created_at__gte = filterData?.created_at__gte;
    }
    if (filterDataCustomer?.lastService.length) {
      data.last_serviced = filterDataCustomer.lastService.toString();
    }
    if (filterDataCustomer?.joined.length) {
      data.created_at__lte = new Date(filterDataCustomer?.joined[1]);
      data.created_at__gte = new Date(filterDataCustomer?.joined[0]);
    }
    // if (filterDataCustomer?.satisfaction) {
    //   data.satisfaction = filterDataCustomer?.satisfaction;
    // }
    if (filterDataCustomer?.satisfaction) {
      if (filterDataCustomer?.satisfaction === '>=90' || filterDataCustomer?.satisfaction === '>=75') {
        Object.assign(data, { customer_satisfaction_score__gte: filterDataCustomer?.satisfaction ? filterDataCustomer?.satisfaction === '>=90' ? '90' : '75' : '' })
      }
      else {
        Object.assign(data, { customer_satisfaction_score__lte: filterDataCustomer?.satisfaction ? filterDataCustomer?.satisfaction === '<75' ? '75' : '50' : '' })
      }
    }
    if (
      (filterData.active && !filterData.inactive) ||
      (!filterData.active && filterData.inactive)
    ) {
      data.is_active = filterData.active ? filterData.active : false;
    }
    if(currentTab === 2) {
      data.is_active = false;
    }
    const customer_list_commercial = await getCustomerListCommercial(data);
    if(currentTab === 2) {
      // let data = customer_list_commercial;
      // let temp = customer_list_commercial.results;
      // temp = temp.filter((each) => !each?.is_active);
      // data.results = temp;
      // data.count = temp.length;
      setCanceledCustomerList(customer_list_commercial);
    } else {
      setCustomerListCommercial(customer_list_commercial);
    }
    setLoader(false);
  };
  // close Drawer
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  const closeDrawerCustomer = () => {
    setOpenDrawerCustomer(false);
  }
  //render customer filter drawer
  const renderDrawer = () => {
    switch (currentTab) {
      case 0:
        return (
          <Drawer
            placement={"right"}
            closable={false}
            onClose={closeDrawer}
            visible={openDrawer}
            key={"right"}
            width={"400"}
            className="drawer-wrapper"
          >
            <div className="group_wrapper rdrawer">
              <div className="card-head">
                <h4>Filter Leads</h4>
              </div>
              <div className="card-row">
                <div className="card-full">
                  <div className="form-group">
                    <div className="fitem">
                      <label>Lead Status</label>
                      <div className="fitem-check">
                        <input
                          type="checkbox"
                          className="fitem-ck-input"
                          id={`active`}
                          name={"active"}
                          checked={filterData?.active}
                          onChange={(e) => onFilterChange(e, "bool")}
                        />
                        <label htmlFor={`active`} className="fitem-ck-txt mb-0">
                          {"Active"}
                        </label>
                      </div>
                      <div className="fitem-check">
                        <input
                          type="checkbox"
                          className="fitem-ck-input"
                          id={`inactive`}
                          name={"inactive"}
                          checked={filterData?.inactive}
                          onChange={(e) => onFilterChange(e, "bool")}
                        />
                        <label htmlFor={`inactive`} className="fitem-ck-txt mb-0">
                          {"Inactive"}
                        </label>
                      </div>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Franchise</label>
                      <Select
                        id={"customerFranchise"}
                        placeholder="Select a Franchise"
                        name="franchise"
                        className="swhite"
                        onChange={(e) =>
                          onFilterChange({
                            target: { name: "franchise", value: e },
                          })
                        }
                        value={filterData?.franchise}
                      >
                        {current_user !== 'franchise_owner' || current_user === 'operations_mgr' && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                        {orderBy(franchiseList?.results, "id", "asc")?.map(
                          (option) => (
                            <Option key={option?.uuid} value={option?.uuid}>
                              {option?.name}
                            </Option>
                          )
                        )}
                      </Select>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Last Contacted</label>
                      <RangePicker
                        style={{ width: "100%" }}
                        allowClear={true}
                        separator="-"
                        format={"MMM DD, YYYY"}
                        dropdownClassName="current_month"
                        value={
                          filterData?.last_contacted?.length > 0
                            ? [
                              filterData?.last_contacted?.[0]
                                ? moment(filterData?.last_contacted?.[0])
                                : undefined,
                              filterData?.last_contacted?.[1]
                                ? moment(filterData?.last_contacted?.[1])
                                : undefined,
                            ]
                            : undefined
                        }
                        onChange={(e) => {
                          if (e === null) {
                            onFilterChange({
                              target: { name: "last_contacted", value: [] },
                            });
                          } else {
                            onFilterChange({
                              target: {
                                name: "last_contacted",
                                value: [
                                  e?.[0]?.format("YYYY-MM-DD"),
                                  e?.[1]?.format("YYYY-MM-DD"),
                                ],
                              },
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Lead Stage</label>
                      <Select
                        placeholder="Select"
                        name="lead_stage"
                        onChange={(e) =>
                          onFilterChange({
                            target: { name: "lead_stage", value: e },
                          })
                        }
                        value={filterData?.lead_stage || undefined}
                      >
                        {value_lead_stage?.map((option) => (
                          <Option key={option?.key} value={option?.key}>
                            {option?.value}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Outreach</label>
                      <Select
                        placeholder="Select"
                        name="outreach"
                        onChange={(e) =>
                          onFilterChange({ target: { name: "outreach", value: e } })
                        }
                        value={filterData?.outreach || undefined}
                      >
                        {value_outreach?.map((option) => (
                          <Option key={option?.key} value={option?.key}>
                            {option?.value}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <button className={`btn`} onClick={closeDrawer}>
                  Cancel
                </button>
                <button className={`btn`} onClick={() => onFilterClick()}>
                  Filter Customer
                </button>
              </div>
            </div>
          </Drawer>
        );
      case 1:
        return (
          <Drawer
            placement={'right'}
            closable={false}
            onClose={closeDrawerCustomer}
            visible={openDrawerCustomer}
            key={'right'}
            width={'400'}
            className="drawer-wrapper"
          >
            <div className="group_wrapper rdrawer">
              <div className="card-head">
                <h4>Filter Customer</h4>
              </div>
              <div className="card-row">
                <div className="card-full">
                  <div className="form-group">
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Franchise</label>
                      <Select
                        // disabled={is_disable_franchise_dropdown}
                        id={"customerFranchise"}
                        placeholder="Select a Franchise"
                        name="franchise"
                        onChange={(e) => onFilterChangeCustomer({ target: { name: 'franchise', value: e } })}
                        value={filterDataCustomer?.franchise || undefined}>
                        {(current_user !== 'franchise_owner' && current_user !== 'operations_mgr') && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                        {
                          orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                            <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                        }
                      </Select>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Joined</label>
                      <RangePicker
                        style={{ 'width': '100%' }}
                        allowClear={true}
                        separator='-'
                        format={"MMM DD, YYYY"}
                        dropdownClassName='current_month'
                        value={filterDataCustomer?.joined?.length > 0 ? [filterDataCustomer?.joined?.[0] ? moment(filterDataCustomer?.joined?.[0]) : undefined, filterDataCustomer?.joined?.[1] ? moment(filterDataCustomer?.joined?.[1]) : undefined] : undefined}
                        onChange={(e) => {
                          if (e === null) {
                            onFilterChangeCustomer({ target: { name: 'joined', value: [] } });
                          } else {
                            onFilterChangeCustomer({ target: { name: 'joined', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                          }
                        }} />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Last Serviced</label>
                      <RangePicker
                        style={{ 'width': '100%' }}
                        allowClear={true}
                        separator='-'
                        format={"MMM DD, YYYY"}
                        dropdownClassName='current_month'
                        value={filterDataCustomer?.lastService?.length > 0 ? [filterDataCustomer?.lastService?.[0] ? moment(filterDataCustomer?.lastService?.[0]) : undefined, filterDataCustomer?.lastService?.[1] ? moment(filterDataCustomer?.lastService?.[1]) : undefined] : undefined}
                        onChange={(e) => {
                          if (e === null) {
                            onFilterChangeCustomer({ target: { name: 'lastService', value: [] } });
                          } else {
                            onFilterChangeCustomer({ target: { name: 'lastService', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                          }
                        }} />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Satisfaction Score</label>
                      <Select
                        placeholder="Enter Satisfaction Score"
                        name="satisfaction"
                        onChange={(e) => onFilterChangeCustomer({ target: { name: 'satisfaction', value: e } })}
                        value={filterDataCustomer?.satisfaction || undefined}>
                        {
                          satisfaction_score?.map((option) =>
                            <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                        }
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <button className={`btn`} onClick={() => { closeDrawerCustomer() }}>Cancel</button>
                <button
                  // type="submit"
                  className={`btn`}
                  onClick={() => onFilterClickCustomer()}>
                  Filter Customer
                </button>
              </div>
            </div>
          </Drawer>
        )
    }
  };
  // on lead list row
  const onLeadListRow = (record) => {
    return {
      onClick: () => {
        navigate(`lead/detail/${record?.uuid}`, {
          state: { uuid: record?.uuid, view: "commercial" },
        });
      },
    };
  };

  const onCustomerDetailRow = (record) => {
    return {
      onClick: () => {
        navigate(`/commercial/customer-detail`, {
          state: {
            id: record?.uuid,
            hear_about_us: record?.hear_about_us,
            customer_satisfaction_score: record?.customer_satisfaction_score,
            record: record,
          },
        });
      },
    };
  };
  //Render commercial custmoer tab table list
  const renderCommercialCustTable = () => {
    const franchise = commercialCustList?.results?.map((item) => ({
      text: item?.name,
      value: item?.uuid,
    }));
    return (
      <div className="leadstbl">
        <Table
          rowKey={(record) => record?.uuid}
          dataSource={customerListCommercial?.results || []}
          pagination={{
            ...customerListPagination,
            total: customerListCommercial?.count,
          }}
          columns={DataTable.commercialCustomerTableColumns(
            franchise,
            customerListCommercial
          )}
          onChange={onCustomerTableChange}
          onRow={onCustomerDetailRow}
          className="customer-list"
        />
        {customerListCommercial?.results?.length > 0 ? (
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => {
                  setCustomerPageLimit("15");
                }}
                className={activePagination === "15" ? "active" : ""}
              >
                15
              </span>
            </li>
            <li>
              <span
                className={activePagination === "30" ? "active" : ""}
                onClick={() => {
                  setCustomerPageLimit("30");
                }}
              >
                30
              </span>
            </li>
            <li>
              <span
                className={activePagination === "60" ? "active" : ""}
                onClick={() => {
                  setCustomerPageLimit("60");
                }}
              >
                60
              </span>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  };
  //end here
  //render customer list
  const renderCustomerList = () => {
    const franchise = franchiseList?.results?.map((item) => ({
      text: item?.name,
      value: item?.uuid,
    }));
    return (
      <div className="leadstbl">
        <Table
          rowKey={(record) => record?.uuid}
          dataSource={customerList?.results || []}
          pagination={{ ...leadListPagination, total: customerList?.count }}
          columns={DataTable.commercialCustomerColumns(franchise, customerList)}
          onChange={onLeadTableChange}
          onRow={onLeadListRow}
          className="customer-list"
        />
        {customerList?.results?.length > 0 ? (
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => {
                  setLeadPageLimit("15");
                }}
                className={activePagination_lead === "15" ? "active" : ""}
              >
                15
              </span>
            </li>
            <li>
              <span
                className={activePagination_lead === "30" ? "active" : ""}
                onClick={() => {
                  setLeadPageLimit("30");
                }}
              >
                30
              </span>
            </li>
            <li>
              <span
                className={activePagination_lead === "60" ? "active" : ""}
                onClick={() => {
                  setLeadPageLimit("60");
                }}
              >
                60
              </span>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  };
  const renderCanceledCustomerList = () => {
    const franchise = franchiseList?.results?.map((item) => ({ text: item?.name, value: item?.uuid }));
    return (
      <div className="leadstbl">
        <Table
          rowKey={record => record?.uuid}
          dataSource={canceledCustomerList?.results || []}
          // pagination={{ ...customerListPagination, total: customerList?.count }}
          columns={DataTable.customerColumns(franchise, customerList)}
          onChange={onCanceledCustomerTableChange}
          onRow={onCustomerDetailRow}
          className="customer-list"
        />
        {
          canceledCustomerList?.results?.length > 0 ?
            <ul className="pagination_page_size">
              <li>
                <span>Show:</span>
              </li>
              <li>
                <span
                  onClick={() => { setCanceledCustomerPageLimit('15') }}
                  className={activePagination_canceled === '15' ? 'active' : ''}
                >15</span>
              </li>
              <li>
                <span
                  className={activePagination_canceled === '30' ? 'active' : ''}
                  onClick={() => { setCanceledCustomerPageLimit('30') }}>30</span>
              </li>
              <li>
                <span
                  className={activePagination_canceled === '60' ? 'active' : ''}
                  onClick={() => { setCanceledCustomerPageLimit('60') }}>60</span>
              </li>
            </ul>
            : ''
        }
      </div>
    )
  };
  //Table Order and Pagination
  const onCustomerTableChange = (pagination, filters, sorter) => {
    setLoader(true);
    let ordering = '';
    Object.assign(customerListPagination, { current: pagination.current });
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }
    pagination.ordering = ordering;
    Object.assign(filterDataCustomer, { franchise: filterDataCustomer?.franchise })
    setCustomerListPagination(pagination);    
    if (filters?.franchise || (filterDataCustomer?.franchise && filterDataCustomer?.franchise !== 'all')) {
      filterDataCustomer.filterFranchise = filters?.franchise?.toString() || filterDataCustomer?.franchise;
    }else
    filterDataCustomer.filterFranchise = '';

    setFilterDataCustomer(filterDataCustomer);
    setOffset((pagination.current - 1) * activePagination);
    setLimit(pagination?.pageSize);

    if (currentTab === 1 || currentTab === 2) {
      getCustomer();
    }
    if (currentTab === 0) {
      getLead();
    }
  }

  const onCanceledCustomerTableChange = (pagination, filters, sorter) => {
    setLoader(true);
    let ordering = '';
    Object.assign(canceledCustomerListPagination, { current: pagination.current });
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }
    pagination.ordering = ordering;
    setCanceledCustomerListPagination(pagination);
    setIsActive(currentTab === 2);
    setOffset((pagination.current - 1) * activePagination_canceled);
    setLimit(pagination?.pageSize);
    if(currentTab === 0) {
      getLead();
    } else {
      getCustomer();
    }
  }
  useEffect(() => {
      if (currentTab === 1 || currentTab === 2) {
        getCustomer();
      }
      if (currentTab === 0) {
        getLead();
      }   
  }, [offset, offset_lead]);
  //Table Order and Pagination
  const onLeadTableChange = (pagination, filters, sorter) => {
    setLoader(true);
    let ordering = "";
    Object.assign(leadListPagination, { current: pagination.current });
    if (sorter.column) {
      ordering =
        sorter.order === "ascend" ? `${sorter.field}` : `-${sorter.field}`;
    } else {
      ordering = "-created_at";
    }
    pagination.ordering = ordering;
    Object.assign(filterData, { franchise: filterData?.franchise });
    setLeadListPagination(pagination);    
    if (
      filters?.franchise ||
      (filterData?.franchise && filterData?.franchise !== "all")
    ) {
      filterData.filterFranchise =
        filters?.franchise?.toString() || filterData?.franchise;
    }else
      filterData.filterFranchise = '';
      
    setFilterData(filterData);
    // setIsActive(currentTab === 1);
    setOffset_lead((pagination.current - 1) * activePagination_lead);
    setLimit(pagination?.pageSize);
    // setLoader(false);    
    if (currentTab === 1 || currentTab === 2) {
      getCustomer();
    }
    if (currentTab === 0) {
      getLead();
    } 
  };
  const setCustomerPageLimit = (value) => {
    setActivePagination(value);
    setCustomerListPagination((p) => ({ ...p, pageSize: value }));
  };
  const setCanceledCustomerPageLimit = (value) => {
    setActivePagination_canceled(value);
    setCanceledCustomerListPagination((p) => ({ ...p, pageSize: value }))
  };
  const setLeadPageLimit = (value) => {
    setActivePagination_lead(value);
    setLeadListPagination((p) => ({ ...p, pageSize: value }))
  }
  const getFranchiseList = async () => {
    setLoader(true);
    const data = await getFranchise();
    setFranchiseList(data ? data : []);
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setCurrent_user(authUser?.user?.role?.[0]);
    if(data?.results.length && authUser?.user?.role?.[0] === 'franchise_owner' || data?.results.length && authUser?.user?.role?.[0] === 'operations_mgr') {
      // let temp = data.results.filter((result)=>result?.uuid === authUser?.user?.field_offices[0]?.uuid);
      setFilterData({
        ...filterData,
        franchise: getFranchiseSelected().uuid === 'all'? '' : getFranchiseSelected().uuid,
      });
      setFilterDataCustomer({
        ...filterDataCustomer,
        franchise: getFranchiseSelected().uuid === 'all'? '' : getFranchiseSelected().uuid,
      });
    } else {
      getCustomer();
      getLead();
    }
    setLoader(false);
  };
  const getCommCustomerList = async () => {
    setLoader(true);
    const data = await getCommercailCustomer();
    setCommercialCustomer(data ? data : []);
    setLoader(false);
  };
  //Close drawer
  const closeAddDrawer = (type) => {
    setIsAddNewLead(type);
    getLead();
  };
  useEffect(() => {
    setSearchValue("");
    getFranchiseList();
    getCommCustomerList();
    setCustomerListPagination({
      showSizeChanger: false,
      pageSize: 15,
      ordering: "-created_at",
      current: 1,
    });
    setFilterDataCustomer({
      franchise: current_user === 'franchise_owner' || current_user === 'operations_mgr' ? filterDataCustomer.franchise : 'all',
      franchiseList: [],
      active: false,
      inactive: false,
      filterFranchise: '',
      joined: [],
      lastService: [],
      outstanding: 'all',
      satisfaction: '',
      created_at__lte: null,
      created_at__gte: null
    });
    setFilterData({
      franchise: current_user === 'franchise_owner' || current_user === 'operations_mgr' ? filterDataCustomer.franchise : 'all',
      active: false,
      inactive: false,
      filterFranchise: "all",
      last_contacted: [],
      lead_stage: "all",
      outreach: "all",
      created_at__lte: null,
      created_at__gte: null,
    });
    // setOffset(0);
    // setLimit(15);
    // getLead();
    // getCustomer();
  }, [currentTab]);

  useEffect(()=>{
    setLoader(true);
    getLead();
    getCustomer();
  }, [filterData, filterDataCustomer]);

  return (
    <div id="wrapper">
      {loader && <Loader />}
      <div className="content-area" style={{width:"100%"}}>
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Header
          placeholder={"Acc Number, Customer Name, Address(Billing), Phone Number, Email Address"}
          readOnly={false}
          search_value={searchValue}
          setSearchValue={setSearchValue}
          onSearch={onSearch}
          onEnter={(e) => {
            e.target.value.length > 2
              ? onSearch(e)
              : alert("Enter at least 3 character!");
          }}
        />
        {/*<GlobalSearch/>*/}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenubuttonClick}
        />
        <div className="container">
          <div className="filter_wrapper customer_list">
            <div className="left_side">
              <div className="fitem" style={{ marginRight: "10px" }}>
                <Select
                  id={"customerFranchise"}
                  className="swhite"
                  placeholder="Select a Franchise"
                  name="franchise"
                  onChange={(e) =>
                    onPrimaryFilterChange({
                      target: { name: "franchise", value: e },
                    })
                  }
                  value={currentTab === 0 ? filterData?.franchise : filterDataCustomer?.franchise}
                >
                  {(current_user !== 'franchise_owner' && current_user !== 'operations_mgr') && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                  {orderBy(franchiseList?.results, "id", "asc")?.map(
                    (option) => (
                      <Option key={option?.uuid} value={option?.uuid}>
                        {option?.name}
                      </Option>
                    )
                  )}
                </Select>
              </div>
              <ul className="tabs">
                <li
                  style={{ marginRight: "10px" }}
                  className={currentTab === 0 ? "active" : ""}
                  onClick={() => {
                    setCurrentTab(0);
                  }}
                >
                  <span>{`LEADS`}</span>
                </li>
                <li
                  style={{ marginRight: "10px" }}
                  className={currentTab === 1 ? "active" : ""}
                  onClick={() => {
                    setCurrentTab(1);
                  }}
                >
                  <span>CUSTOMERS</span>
                </li>
                <li
                  style={{ marginRight: "10px" }}
                  className={currentTab === 2 ? "active" : ""}
                  onClick={() => {
                    setCurrentTab(2);
                  }}
                >
                  <span>CANCELLED CUSTOMERS</span>
                </li>
              </ul>
            </div>
            <div className="right_side">
              <div className="fitem">
                {/* <div className='dis_res'>
                                    <Select
                                        id={"customerFranchise"}
                                        className="status_Customer_list swhite filter_type"
                                        placeholder="Select a Franchise"
                                        name="franchise"
                                        onChange={(e) => onFilterChange({ target: { name: 'franchise', value: e } })}
                                        value={filterData?.franchise || undefined}>
                                        <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>
                                        {
                                            orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                                                <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                                        }
                                    </Select>
                                </div> */}
              </div>
              <span className={currentTab === 2 ? "ico-btn disable-field" : "ico-btn"}>
                <FilterOutlined
                  onClick={() =>
                    currentTab === 0
                      ? setOpenDrawer(true)
                      : setOpenDrawerCustomer(true)
                  }
                >
                  Filter
                </FilterOutlined>
              </span>
              {currentTab === 0 && (
                <div className="right_side" style={{ flex: "none" }}>
                  <button
                    disabled={isAddNewLead}
                    onClick={() => closeAddDrawer(true)}
                    className={
                      !isAddNewLead
                        ? "btn btn-warning"
                        : "btn btn-brand btn-bdr disbale_btn"
                    }
                  >
                    Add New Lead
                  </button>
                </div>
              )
                // : 
              // : 
                // : 
              // : 
                // : 
              // : 
                // : 
                // (
                //   <Link className="link-button" to={"/commercial/new-customer"}>
                //     <button className="btn btn-warning">Add New Customer</button>
                //   </Link>
                // )
              }
            </div>
          </div>
          <div className="data leadstable">
            {currentTab === 0
              ? renderCustomerList()
              : currentTab === 1
                ? renderCommercialCustTable()
                : renderCanceledCustomerList()}
          </div>
          {renderDrawer()}
        </div>
        <Drawer
          visible={isAddNewLead}
          width={"440"}
          closable={false}
          className="drawer-wrapper create_ticket_drawer"
        >
          <AddNewLead
            closeAddDrawer={closeAddDrawer}
            startLoader={setLoader}
            view={"commercial"}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default CommercialCustomerList;
