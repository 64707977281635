import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class TechnicianOutsideAlerter extends Component {

  constructor(props) {
    super(props);
    this.setTechnicianRef = this.setTechnicianRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setTechnicianRef(node) {
    this.technicianWrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.technicianWrapperRef && !this.technicianWrapperRef.contains(event.target)) {
      this.props.closeTechnicianFilter();
    }
  }

  render() {
    return <div ref={this.setTechnicianRef}>{this.props.children}</div>;
  }
}

TechnicianOutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
};
