import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div id="wrapper">
      <div className="content-area">
        {/* Header */}
        <Header
          placeholder="Search"
          search_value={""}
          onSearch={(e) => e}
          onEnter={(e) => e}
          readOnly={true}
        />
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <div className="container">
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited is under construction."
            extra={<Button type="primary" onClick={ () => navigate("/")}>Back Home</Button>}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
