import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import * as constants from "../../Components/Constants";
import Loader from "../../Components/Loader";
import moment from "moment-timezone";
import {
  addAutoScheduleDate,
  getAutoScheduleDate,
} from "../../Action/Franchise/index";
import { message } from "antd";
import { map } from "lodash";
import { Select } from "antd";

const AutoScheduling = (props) => {
  const { Option } = Select;
  const [invoiceDate, setInvoiceDate] = useState(0);
  const [paymentDate, setPaymentDate] = useState(0);
  const [loader, setLoader] = useState(false);
  const [days, setDays] = useState(["Select day", ...Array.from({ length: 31 }, (_, i) => i + 1)]);

  useEffect(() => {    
    getDates();
  }, []);

  const getDates = async () => {
    setLoader(true);
    const response = await getAutoScheduleDate(props.franchise_data.uuid);
    
    if (response) {
      setInvoiceDate(response.auto_invoice_day ?? 0);
      setPaymentDate(response.auto_payment_day ?? 0);
    } else {
      message.error("Something went wrong!");
    }
    setLoader(false);
  };

  const updateDates = async () => {    
    setLoader(true);
    
    //It should be 0 because BE is expecting that value for empty string. EAP-2995
    const response = await addAutoScheduleDate(props.franchise_data.uuid, {
      auto_invoice_day: invoiceDate === '' ? 0 : invoiceDate,
      auto_payment_day: paymentDate === '' ? 0 : paymentDate,
    });
    
    if (response) {
      message.success("Dates are saved successfully.");
    } else {
      message.error("Something went wrong!");
    }
    setLoader(false);
  };

  return (
    <>
      <div className="flex singleservice">
        {loader && <Loader />}
        <div className="card-col service-order-card">
          <div className="card" style={{ width: "70%" }}>
            <div className="card-body">
              <div>
                <label>
                  <b>{constants.AUTOSCHEDULEINVOICEDATE}</b>
                </label>
              </div>
              <div>
                <Select
                  className="md-filter"                  
                  value={invoiceDate}
                  onChange={(e) => {
                    if (e) {
                      setInvoiceDate(e);
                    } else {
                      setInvoiceDate(0);
                    }
                  }}
                >
                  {map(days, (item, i) => {
                    if(item === 'Select day')
                      return (
                        <Option value={i}>{item}</Option>
                      )    
                    else
                      return (                      
                        <Option key={i} value={item}>
                          {item}
                          {item === 1 ? "st" : item === 2 ? "nd" : item === 3 ? "rd" : "th"} of every month
                        </Option>
                      );
                  })}
                </Select>
              </div>
              <div>
                <label>
                  <b>{constants.AUTOSCHEDULEPAYMENTDATE}</b>
                </label>
              </div>
              <div>
                <Select
                  className="md-filter"
                  value={paymentDate}
                  onChange={(e) => {
                    if (e) {
                        setPaymentDate(e);
                    } else {
                        setPaymentDate(0);
                    }
                  }}
                >
                  {map(days, (item, i) => {
                    if(item === 'Select day')
                      return (
                        <Option value={i}>{item}</Option>
                      )    
                    else
                      return (
                        <Option key={i} value={item}>
                          {item}
                          {item === 1 ? "st" : item === 2 ? "nd" : item === 3 ? "rd" : "th"} of every month
                        </Option>
                      );
                  })}
                </Select>
              </div>
              <br />
              <div style={{ textAlign: "right" }}>
                {" "}
                <button className="btn btn-warning" onClick={updateDates}>
                  {constants.UPDATEBTNTEXT}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AutoScheduling;
