import React, { useEffect } from "react";
import { DatePicker, ConfigProvider } from "antd";
import moment from 'moment-timezone';
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setEndDate, setStartDate, setDateType } from "../Store/proposalTableSlice";

const ProposalWeekFilter = () => {
    const dispatch = useDispatch();
    const proposalData = useSelector((state) => state.proposalsData);

    useEffect(() => {

    }, [])

    const filterDate = (dateType) => {
        let startDate = null;
        let endDate = null;
        if (dateType === 'Yesterday') {
            startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        }
        if (dateType === 'Today') {
            startDate = moment().format('YYYY-MM-DD');
            endDate = moment().format('YYYY-MM-DD');
        }
        if (dateType === 'Tomorrow') {
            startDate = moment().add(1, 'days').format('YYYY-MM-DD');
            endDate = moment().add(1, 'days').format('YYYY-MM-DD');
        }
        if (dateType === 'Last Week') {
            startDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
            endDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
        }
        if (dateType === 'This Week') {
            startDate = moment().startOf('week').format('YYYY-MM-DD');
            endDate = moment().endOf('week').format('YYYY-MM-DD');
        }
        if (dateType === 'Next Week') {
            startDate = moment().add(1, 'weeks').startOf('week').format('YYYY-MM-DD');
            endDate = moment().add(1, 'weeks').endOf('week').format('YYYY-MM-DD');
        }
        dispatch(setStartDate(startDate))
        dispatch(setEndDate(endDate))
        dispatch(setDateType(dateType))
    }

    return (
        <>
            <ul className="tabs mwhite field-service d-block">
                <li
                    className={proposalData.dateType === "Yesterday" ? "active" : ""}
                    onClick={() => {
                        filterDate("Yesterday");
                    }}
                >
                    <span>{`Yesterday`}</span>
                </li>
                <li
                    className={proposalData.dateType === "Today" ? "active" : ""}
                    onClick={() => {
                        filterDate("Today");
                    }}
                >
                    <span>Today</span>
                </li>
                <li
                    className={proposalData.dateType === "Tomorrow" ? "active" : ""}
                    onClick={() => {
                        filterDate("Tomorrow");
                    }}
                >
                    <span>{`Tomorrow`}</span>
                </li>
            </ul>
            <ul
                className="tabs mwhite field-service d-block"
                style={{ marginLeft: "10px" }}
            >
                <li
                    className={proposalData.dateType === "Last Week" ? "active" : ""}
                    onClick={() => {
                        filterDate("Last Week");
                    }}
                >
                    <span>{`Last Week`}</span>
                </li>
                <li
                    className={proposalData.dateType === "This Week" ? "active" : ""}
                    onClick={() => {
                        filterDate("This Week");
                    }}
                >
                    <span>This Week</span>
                </li>
                <li
                    className={proposalData.dateType === "Next Week" ? "active" : ""}
                    onClick={() => {
                        filterDate("Next Week");
                    }}
                >
                    <span>{`Next Week`}</span>
                </li>
            </ul>
        </>
    )
}

export default ProposalWeekFilter;