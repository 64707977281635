import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux/es/hooks/useSelector";

export const DeleteJobModalCalendarContainer = (props) => {
    const { deleteModalOpen, onDeleteWorkOrder, setDeleteModalOpen, updateTaskStatus } = props;
    const tasks = useSelector((state) => state.technicianTaskJourney.selectedTaskData);

    return (
        <Modal
            title={`${tasks?.original?.type == "Task" ? "Delete Task" : "Delete Job"}`}
            open={deleteModalOpen}
            onOk={() => {
                if (tasks?.original?.type == "Task") {
                    updateTaskStatus(tasks?.original, 'cancelled')
                } else {
                  onDeleteWorkOrder();
                }
              }}
            onCancel={() => setDeleteModalOpen(false)}
            okText={`${tasks?.original?.type == "Task" ? "Yes" : "Delete"}`}
            cancelText={`${tasks?.original?.type == "Task" ? "No" : "Cancel"}`}
            className="schedule_delete_modal"
            maskClosable={false}
            closable={false}
        >
            <p>{`${tasks?.original?.type == "Task" ? "Task will be cancelled" : `Are you sure you would like to delete this work order?`}`}</p>
            <p>{`${tasks?.original?.type == "Task" ? "" : "Any changes made to this work order will be lost."}`}</p>
        </Modal>
    )
}