import React, { Component, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Select, message } from 'antd';
import { map } from 'lodash';
import NewTicket from './Tickets/NewTicket';
import TicketReply from './Tickets/TicketReply';
import Loader from '../../../Components/Loader';
import EmptyData from "../../../Components/EmptyData";
const { Option } = Select;

const Tickets = (props) => {
  console.log('props-->> ', props);
  const [state, setState] = useState({
    messageData: [],
    AllUser: [],
    GroupList: [],
    ticket_details: {},
    message_list_filter: {
      user__serviceorder__field_office__uuid: '',
      reason: '',
      assigned_user__uuid: '',
      status: '',
      priority: '',
      ordering: ''
    },
    is_new_ticket: false,
    is_read_ticket: false,
    loader: false
  })

  useEffect(() => {
    setState(s => ({
        ...s,
      loader: true
    }))
    //Get User message and tickets
    getAllMessages({ 'user__uuid': props.customerId })
    //Get all user list
    getUserList({ 'limit': 1000, 'offset': 0, 'ordering': 'first_name', 'is_case_mgmt': true})

    //get Group 
    getGroupList({ 'limit': 15, offset: 0 })
    //  props.onClearFromOverview();
    let is_ticket_details = JSON.parse(localStorage.getItem('is_ticket_details')) || null;
    if (is_ticket_details) {
      setState(s => ({
        ...s,
        ticket_details: is_ticket_details.record,
        is_read_ticket: true
      }))
      localStorage.removeItem('is_ticket_details');
    }

  },[])

//   useEffect(() => {
//     //get Message
//     if (Object.keys(props.AllMessages.data).length > 0 && props.AllMessages.data !==  props.AllMessages.data) {
//         setState(s => ({
            // ...s,
//           messageData: props.AllMessages.data.results,
//           loader: false
//         }))
//       }
  
//       if (Object.keys(props.AllMessages.errors).length > 0 && props.AllMessages.errors !==  props.AllMessages.errors) {
//         message.error('Something went wrong', 10);
//         setState({
//           loader: false,
//         })
//       }
  
//       //get User
//       if (Object.keys(props.UserList.data).length > 0 && props.UserList.data !==  props.UserList.data) {
//         setState({
//           AllUser: props.UserList.data.results,
//           loader: false
//         })
//       }
  
//       if (Object.keys(props.UserList.errors).length > 0 && props.UserList.errors !==  props.UserList.errors) {
//         message.error('Something went wrong', 10);
//         setState({
//           loader: false,
//         })
//       }
//       //Get Group list
//       if (Object.keys(props.GroupList.data).length > 0 && props.GroupList.data !==  props.GroupList.data) {
//         setState({
//           loader: false,
//           GroupList: props.GroupList.data.results
//         })
//       }
  
//       if (Object.keys(props.GroupList.errors).length > 0 && props.GroupList.errors !==  props.GroupList.errors) {
//         message.error('Something went wrong', 10);
//         setState({
//           loader: false,
//         })
//       }
  
//   }, [props])

  // convert date time from utc to local time
  const UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  const localToUTC = (date) => {
    return moment.utc(date)
  }

  // calulate Date 
  const calulateDate = (created_timestamp) => {
    let currentDate = moment();

    if (moment(created_timestamp).isSame(currentDate, "day")) {
      return moment(created_timestamp).fromNow();

    } else if (
      moment(created_timestamp).isSame(
        moment()
          .subtract(1, "days")
          .startOf("day"),
        "d"
      )
    ) {
      return "Yesterday";
    } else {
      return moment(created_timestamp).format("MM-DD-YYYY");
    }
  }

  const getAllMessages = async (params) => {
    setState(s => ({...s, loader: false}));
  }

  const getUserList = async (params) => {

  }

  const getGroupList = async (params) => {

  }

  // Render All Ticket
  const renderAllTicket = () => {
    let { messageData } = state;
    return map(messageData, (item, i) => {
      return (
        <div
          onClick={() => {
            setState(s => ({ ...s, is_read_ticket: true, ticket_details: messageData[i] }));
            props.readTickets({ 'uuid': messageData[i].uuid, 'read_by_staff_user': true })
          }}
          className={item.read_by_staff_user === false ? 'trow open_ticket' : 'trow close_ticket'}
          key={i}>
          <span className="title" >{item.title}</span>
          {item.priority ?
            <span
              className={
                item.priority === "3" ? 'status priority_high'
                  :
                  item.priority === "4" ? 'status priority_medium' : ''
              }

            >
              {
                item.priority === "4" ? <span className="status_before medium" style={{fontWeight: "normal"}}>Standard Priority</span>
                  : item.priority === "3" ? <span className="status_before high" style={{fontWeight: "normal"}}>High Priority </span> : ''
              } </span> : <span className="status low">No Priority </span>}
          <span className='assigned_user ellipse'><i className="las la-user"></i> {item.assigned_user_details ? `${item.assigned_user_details.first_name} ${item.assigned_user_details.last_name}` : 'Not Assigned'}</span>
          {item.status ? <span className={item.status === "open" ? 'status status_open' : 'status status_closed'}>{item.status}  </span> : ''}
          <span className="time">Created {calulateDate(UTCToLocal(item.created_at))}</span>
        </div>
      )

    })

  }

  //Message listing filter
  const filterOnChange = (e, type) => {
    let { message_list_filter } =  state;
    message_list_filter = Object.assign(message_list_filter, { [type]: e });
     setState(s => ({
        ...s,
      message_list_filter,
      loader: true
    }));
    let data = {
      user__uuid:  props.customerId,
      ...message_list_filter,
    }
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
     getAllMessages(data)
  }

  //Hide Create new ticket
  const closeCreteTicket = (is_new_ticket) => {
    setState(s => ({
        ...s,
      is_new_ticket
    }))
  }

  //Hide Replies ticket
  const closeReplieTicket = (is_read_ticket) => {
    setState(s => ({
        ...s,
      is_read_ticket
    }));
  }

  const render = () => {
    let { message_list_filter, AllUser, loader, is_new_ticket, is_read_ticket } = state;
    const noData = state.messageData?.length <= 0

    return (
      <React.Fragment >
        <div className={props?.customer?.is_active ? "block-row p-0" : "block-row p-0 disable-field"}>
          {is_new_ticket || is_read_ticket ?
            <div className="back_link flex">
              <span onClick={() => {
                if (is_new_ticket) {
                  closeCreteTicket(false);
                }
                if (is_read_ticket) {
                  closeReplieTicket(false)
                }
                getAllMessages({ 'user__uuid': props.customerId })

              }}> Back to all tickets</span>
              {is_read_ticket ? <button
                className="btn btn-warning mr-30"
                onClick={() => {
                  setState(s => ({
                    ...s,
                    is_read_ticket: false,
                    is_new_ticket: true,
                    message_list_filter: {
                      user__serviceorder__field_office__uuid: '',
                      reason: '',
                      assigned_user__uuid: '',
                      status: '',
                      priority: '',
                      ordering: ''
                    }
                  }))
                }}
              >
                  Add New Ticket
              </button> : ''}
            </div>
            : ''}
          {!is_new_ticket && !is_read_ticket ?
            <div className="block-row pb-0">
              <div className="filter_wrapper p-0" style={{ 'background': '#F4F7F9' }}>
                <div className='left_side form-group'>
                  <div className="fitem">
                    <Select
                      defaultValue=""
                      className="filter_ant_select filter_by_sort swhite"
                      disabled={noData}
                      onChange={(e) => { filterOnChange(e, 'ordering') }}
                      value={message_list_filter.ordering}
                      virtual={false}
                    >
                      <Option value="">All</Option>
                      <Option value="created_at"><i className="las la-long-arrow-alt-up sort_by_icon"></i>Created at</Option>
                      <Option value="-created_at"><i className="las la-long-arrow-alt-down sort_by_icon"></i>Created at</Option>
                    </Select>
                  </div>
                  <div className="fitem">
                    <Select
                      className="filter_ant_select filter_by_assign swhite"
                      virtual={false}
                      showSearch
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      onChange={(e) => { filterOnChange(e, 'assigned_user__uuid') }}
                      onSearch={(e) => { getUserList({ 'search': e, 'limit': 1000, 'offset': 0, 'ordering': 'first_name', 'is_case_mgmt': true}) }}
                      onSelect={() => { getUserList({ 'limit': 1000, 'offset': 0, 'ordering': 'first_name', 'is_case_mgmt': true}) }}
                      value={message_list_filter.assigned_user__uuid}
                      disabled={noData}
                    >
                      <Option value="">All</Option>
                      {
                        map(AllUser, (item) => {
                          if (item.first_name && item.last_name) {
                            return (<Option value={item.uuid} key={item.uuid}>{item.first_name} {item.last_name}</Option>)
                          }

                        })
                      }

                    </Select>
                  </div>
                  <div className="fitem">
                    <Select
                      className="filter_ant_select filter_by_status swhite"
                      virtual={false}
                      onChange={(e) => { filterOnChange(e, 'status') }}
                      value={message_list_filter.status}
                      disabled={noData}
                    >
                      <Option value="">All</Option>
                      <Option key='open' value="open"><span className="open prty">Open</span></Option>
                      <Option key='closed' value="closed"><span className="closed prty">Closed</span></Option>
                    </Select>
                  </div>
                  <div className="fitem">
                    <Select
                      className="filter_ant_select filter_by_Priority  swhite"
                      virtual={false}
                      onChange={(e) => { filterOnChange(e, 'priority') }}
                      value={message_list_filter.priority}
                      dropdownClassName="sm-filter"
                      disabled={noData}
                    >
                      <Option value="">All</Option>
                      <Option key="4" value="4"><span className="medium prty">Standard Priority</span></Option>
                      <Option key="3" value="3"><span className="high prty">High Priority</span></Option>
                    </Select>
                  </div>
                </div>
                <div className="right_side">
                  {state.messageData?.length > 0 ?<div>
                    <span className="ico-btn"
                      onClick={() => {
                        props.getCSVData({ 'format': 'csv', 'user__uuid': props.customerId });
                      }}
                    >
                      <i className="las la-file-csv"></i>
                    </span>

                  </div> : ''}
                  <div>
                    <button
                      className="btn btn-warning"
                      onClick={() => {
                        setState( s => ({
                            ...s,
                          is_new_ticket: true,
                          message_list_filter: {
                            user__serviceorder__field_office__uuid: '',
                            reason: '',
                            assigned_user__uuid: '',
                            status: '',
                            priority: '',
                            ordering: ''
                          }
                        }))
                      }}
                    >
                      Add New Ticket
                    </button>
                  </div>
                </div>
              </div>
            </div>
            : ''}
          {!is_new_ticket && !is_read_ticket ?
            <div className="display_my_tickets block-row">
              <div className="message_list">
                <div className="msg-tbl-block">
                  {state.messageData?.length > 0 ? renderAllTicket() : <EmptyData style={{ padding: "20px" }} />}
                </div>
              </div>
            </div>
            : ''}
          {/* New Tickets */}
          {is_new_ticket ?
            <NewTicket
              AllUser={props.AllUser}
              GroupList={props.GroupList}
              UserList={props.UserList}
              getUserList={getUserList}
              getGroupList={ getGroupList}
              customerId={ props.customerId}
              createNewTickets={ props.createNewTickets}
              getAllMessages={ getAllMessages}
              NewTicket={ props.NewTicket}
              closeCreteTicket={ closeCreteTicket}
              {...props}
              getMessagesCount={ props.getMessagesCount}              
            /> : ''}
          {is_read_ticket ?
            <TicketReply
              AllUser={ props.AllUser}
              GroupList={ props.GroupList}
              getUserList={ getUserList}
              UserList={ props.UserList}
              getGroupList={ getGroupList}
              customerId={ props.customerId}
              messageReplay={ props.messageReplay}
              ticket_details={ state.ticket_details}
              MessageReplies={ props.MessageReplies}
              RepliesonTicket={ props.RepliesonTicket}
              UpdateTickets={ props.UpdateTickets}
              UpdateMesssage={ props.UpdateMesssage}
              closeReplieTicket={ closeReplieTicket}
              getAllMessages={ getAllMessages}
              {... props}
            />
            : ''}
          {
            loader && <Loader />
          }
        </div>
      </React.Fragment>
    );
  }

  return render();
}

export default Tickets;