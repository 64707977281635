import React, { useEffect, useState } from "react";
import { Detector } from "react-detect-offline";
import ImageUploader from "../Components/ImageUploader";
import CustomerDetails from "./Common/CustomerDetails";
import FooterForm from "./Common/FooterForm";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { DownloadOutlined } from "@ant-design/icons";
import {
  calculateAllChemicals,
  calculateChemicals,
} from "../Utils/globalFunction";
import { calculateLSI } from "../Utils/globalFunction";
import { upsertChemical } from "../Utils/infoRequests";
import {
  saveMaintenencePoolReport,
  getSaveMaintenencePoolReport,
  postUploadImage,
  getUploadImage,
  sendTechnicianWorkOrderM,
  getChimicalDatas,
} from "../Action/Customer/index";
import {
  sendOfflineRequests,
  saveOfflineRequest,
  init,
} from "../Calendar/helper/apiOfflineHelper";
import { authLogout } from "../Action/Auth";
import { Row, Col, message, Card, Alert } from "antd";
import { useParams } from "react-router";
import Loader from "../Components/Loader";
import config from "../Lib/Config";
import { isEmpty } from "lodash";
import { useActiveForm } from "./Hooks/Form.Hook";
import moment from "moment-timezone";
import ChemicalModel from "./Common/ChemicalModel";

const MaintenancePoolReport = (props) => {
  const params = useParams();
  const [offline, setOffline] = useState(undefined);
  const [allData, setAllData] = useState({});
  const [loader, setLoader] = useState(false);
  const [textButton, setTextButton] = useState("Save & Email");
  const [poolSize, setPoolSize] = useState(0);
  const [imageSources, setImageSources] = useState([]);
  const [imageSourcesTech, setImageSourcesTech] = useState([]);

  const [base64Image, setBase64Image] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const active = useActiveForm();
  const navigate = useNavigate();
  const [showChemical, setShowChemical] = useState(false);
  const [chemicalData, setChemicalData] = useState(false);
  const [chemicalType, setChemicalType] = useState(false);
  const [chemicalTitle, setChemicalTitle] = useState(false);

  const getFormData = async () => {
    let data = null;
    if (offline || offline === undefined) {
      let dataEvent = JSON.parse(localStorage.getItem("events")).find(
        (event) => event.formID === params.formuuid
      );
      data = dataEvent.formData;
    } else {
      data = await getSaveMaintenencePoolReport(params.formuuid);
      let dataEvents = JSON.parse(localStorage.getItem("events")).map(
        (event) => {
          if (event.formID === params.formuuid) {
            return {
              ...event,
              formData: data,
            };
          } else {
            return event;
          }
        }
      );
      localStorage.setItem("events", JSON.stringify(dataEvents));
    }
    setClient({ client: data.client });
    setAllData(data);
    setPoolSize(data?.pool_size);
    setChemical((chemical) => ({
      ...chemical,
      tds: data?.form_data?.chemical_readings?.tds || "",
      calciumHardness:
        data?.form_data?.chemical_readings?.calcium_hardness || "",
      clarity: data?.form_data?.chemical_readings?.clarity || "",
      cyanuricAcid: data?.form_data?.chemical_readings?.cyanuric_acid || "",
      filterPressure: data?.form_data?.chemical_readings?.filter_pressure || "",
      freeChlorine: data?.form_data?.chemical_readings?.free_chlorine || "",
      pH: data?.form_data?.chemical_readings?.ph || "",
      phosphate: data?.form_data?.chemical_readings?.phosphate || "",
      poolTemp: data?.form_data?.chemical_readings?.pool_temperature || "",
      saltLevel: data?.form_data?.chemical_readings?.salt_level || "",
      totalAlkalinity:
        data?.form_data?.chemical_readings?.total_alkalinity || "",
      algaecide: data?.form_data?.chemicals_added?.algaecide || "",
      calciumChloride: data?.form_data?.chemicals_added?.calcium_chloride || "",
      chlorineLiq: data?.form_data?.chemicals_added?.chlorine_liquid || "",
      chlorineTab: data?.form_data?.chemicals_added?.chlorine_tabs || "",
      cyanuricAcidAdded: data?.form_data?.chemicals_added?.cyanuric_acid || "",
      dichlor: data?.form_data?.chemicals_added?.dichlor || "",
      muriaticAcid: data?.form_data?.chemicals_added?.muriatic_acid || "",
      phosphateRemover:
        data?.form_data?.chemicals_added?.phosphate_remover || "",
      salt: data?.form_data?.chemicals_added?.salt || "",
      sodaAsh: data?.form_data?.chemicals_added?.soda_ash || "",
      sodiumBicarbonate:
        data?.form_data?.chemicals_added?.sodium_bicarbonate || "",
      stainScaleControl:
        data?.form_data?.chemicals_added?.stain_scale_control || "",
      calciumHypochlorite:
        data?.form_data?.chemicals_added?.calcium_hypochlorite || "",
      dePowder: data?.form_data?.chemicals_added?.de_powder || "",

      techNotes: data?.form_data?.tech_notes ?? "",
      comment: data?.form_data?.comment ?? "",
    }));
    setDosages((dose) => ({
      ...dose,
      CalciumChloride:
        data?.form_data?.recommended_doses?.CalciumChloride ?? null,
      CalciumHypochlorite:
        data?.form_data?.recommended_doses?.CalciumHypochlorite ?? null,
      CyanuricAcid: data?.form_data?.recommended_doses?.CyanuricAcid ?? null,
      LSI: data?.form_data?.recommended_doses?.LSI ?? null,
      LSIRecommendation:
        data?.form_data?.recommended_doses?.LSIRecommendation ?? null,
      LiquidChlorine:
        data?.form_data?.recommended_doses?.LiquidChlorine ?? null,
      MuriaticAcid: data?.form_data?.recommended_doses?.MuriaticAcid ?? null,
      SodaAsh: data?.form_data?.recommended_doses?.SodaAsh ?? null,
      SodiumBicarbonate:
        data?.form_data?.recommended_doses?.SodiumBicarbonate ?? null,
    }));
  };

  useEffect(() => init(), []);

  useEffect(() => {
    getFormData();
    getImageUpload(params.formuuid);
    sendTechWO();
    //getClient();
    getTask();
  }, [offline]);

  useEffect(() => {
    let timer = null;
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    const clearLoginTimeout = () => {
      clearTimeout(timer);
    };

    const setLoginTimeout = () => {
      timer = setTimeout(() => {
        authLogout({ key: JSON.parse(localStorage.getItem("authToken")).key });
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/`);
      }, 1500000);
    };
    const resetTimeout = () => {
      clearLoginTimeout();
      setLoginTimeout();
    };

    for (var i in events) {
      if (
        _?.includes(
          ["admin", "franchise_owner", "operations_mgr"],
          JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles[0]
            ?.name
        )
      ) {
        window.addEventListener(events[i], resetTimeout);
      }
    }

    return () => {
      clearTimeout(timer);
      for (var i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, [active]);

  useEffect(() => {
    if (poolSize && poolSize > 100) {
      let dosagesValues = calculateAllChemicals(
        chemical,
        client,
        dosages,
        poolSize
      );
      setDosages({ ...dosages, dosagesValues });
    }
  }, [poolSize]);

  const sendTechWO = async () => {
    let url = window.location.href;
    let temp = url.substring(url.lastIndexOf("/") + 1, url.length);
    let formName = url.split("/")[3];
    const workOrderParam = {
      form_uuid: temp,
      form_name: formName,
    };
    console.log("sendTechnicianWorkOrderM", {
      name: "sendTechnicianWorkOrderM",
      api: `technician-form-details/`,
      method: "post",
      payload: workOrderParam,
      offline: offline,
    });
    if (offline) {
      saveOfflineRequest({
        name: "sendTechnicianWorkOrderM",
        api: `technician-form-details/`,
        method: "post",
        payload: workOrderParam,
      });
    } else {
      try {
        const resp = await sendTechnicianWorkOrderM(workOrderParam);
      } catch (error) {
        setOffline(true);
      }
    }
  };

  async function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let tempBase64 = { ...base64Image };
      tempBase64[file.name] = reader.result;
      setBase64Image(tempBase64);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const getFilesCustomer = (list) => {
    list.forEach(async (each) => {
      if (Object.keys(each).includes("image_url") && !each.image) {
        await getBase64(each.image_url);
      }
    });
    setImageSources(list);
  };

  const getFilesTechnical = (list) => {
    list.forEach(async (each) => {
      if (Object.keys(each).includes("image_url") && !each.image) {
        await getBase64(each.image_url);
      }
    });
    setImageSourcesTech(list);
  };

  const getImageUpload = async (id) => {
    setIsLoading(true);
    let customerImage = [];
    let technicalImage = [];
    let data = null;
    if (offline || offline === undefined) {
      let dataEvent = JSON.parse(localStorage.getItem("events")).find(
        (event) => event.formID === id
      );
      data = dataEvent.formImages;
    } else {
      data = await getUploadImage(id);
      let dataEvents = JSON.parse(localStorage.getItem("events")).map(
        (event) => {
          if (event.formID === id) {
            return {
              ...event,
              formImages: data,
            };
          } else {
            return event;
          }
        }
      );
      localStorage.setItem("events", JSON.stringify(dataEvents));
    }
    let response = true;
    if (!isEmpty(data)) {
      data.forEach((each) => {
        if (!each.image.includes("http")) {
          response = false;
        }
        if (each.image_type === "Pool Maintenance Report Customer") {
          let tempObjCustomer = {
            uid: each.id,
            name: each.name,
            url: each.image,
            // image_type: each.image_type
          };
          customerImage.push(tempObjCustomer);
        } else if (each.image_type === "Pool Maintenance Report Technical") {
          let tempObjTechnical = {
            uid: each.id,
            name: each.name,
            url: each.image,
            // image_type: each.image_type
          };
          technicalImage.push(tempObjTechnical);
        }
      });
    }

    setImageSources(customerImage);
    setImageSourcesTech(technicalImage);
    setIsLoading(false);
    return response;
  };

  const postImageUpload = async () => {
    setLoader(true);
    let payload = {};
    let uploadedImageListCustomer = [];
    let uploadedImageListTechnical = [];
    imageSources.forEach((each) => {
      if (Object.keys(each).includes("image_url")) {
        let tempObj = {};
        tempObj["id"] = 0;
        tempObj["image"] = each.image ?? base64Image[each.image_url.name];
        tempObj["name"] = each.image_url.name;
        tempObj["type"] = "pool maint customer";

        uploadedImageListCustomer.push(tempObj);
      } else {
        let tempObj = {};
        tempObj["id"] = each.uid;
        tempObj["image"] = each.url;
        tempObj["name"] = each.name;
        tempObj["type"] = "pool maint customer";

        uploadedImageListCustomer.push(tempObj);
      }
    });
    imageSourcesTech.forEach((each) => {
      if (Object.keys(each).includes("image_url")) {
        let tempObjTech = {};
        tempObjTech["id"] = 0;
        tempObjTech["image"] = each.image ?? base64Image[each.image_url.name];
        tempObjTech["name"] = each.image_url.name;
        tempObjTech["type"] = "pool maint technical";

        uploadedImageListTechnical.push(tempObjTech);
      } else {
        let tempObjTech = {};
        tempObjTech["id"] = each.uid;
        tempObjTech["image"] = each.url;
        tempObjTech["name"] = each.name;
        tempObjTech["type"] = "pool maint technical";

        uploadedImageListTechnical.push(tempObjTech);
      }
    });
    let resultImage = uploadedImageListCustomer.concat(
      uploadedImageListTechnical
    );
    payload["id"] = params.formuuid;
    payload["id_type"] = "formdata";
    payload["images"] = resultImage;
    console.log("postUploadImage", {
      name: "sendTechnicianWorkOrderM",
      api: `technician-form-details/`,
      method: "post",
      payload: payload,
      offline: offline,
    });
    if (offline) {
      saveOfflineRequest({
        name: "postUploadImage",
        api: `images/upload_images/`,
        method: "post",
        payload: payload,
      });
      setLoader(false);
      let dataEvents = JSON.parse(localStorage.getItem("events")).map(
        (event) => {
          if (event.formID === params.formuuid) {
            return {
              ...event,
              formImages: payload.images.length
                ? payload.images
                : [payload.images],
            };
          } else {
            return event;
          }
        }
      );
      localStorage.setItem("events", JSON.stringify(dataEvents));
      message.success(
        "Image will be uploaded when internet access is restore "
      );
      return getImageUpload(params.formuuid);
    } else {
      const data = await postUploadImage(payload); //aqui
      if (data.message === "Formdata Images uploaded Successfully.") {
        setLoader(false);
        message.success("Image Upload Successfully!..");
        return getImageUpload(params.formuuid);
      }
    }
  };

  const [client, setClient] = useState({});
  const [task, setTask] = useState({});
  const [chemical, setChemical] = useState({
    algaecide: 0,
    calciumChloride: 0,
    calciumChlorideLbs: 0,
    calciumHardness: 0,
    calciumHypochlorite: 0,
    chlorineLiq: 0,
    chlorineTab: 0,
    clarity: 0,
    comment: "",
    cyanuricAcid: 0,
    cyanuricAcidAdded: 0,
    cyanuricAcidLbs: 0,
    dePowder: 0,
    dichlor: 0,
    filterPressure: 0,
    freeChlorine: 0,
    id: 0,
    muriaticAcid: 0,
    pH: 0,
    phIncreaserLbs: 0,
    phosphate: 0,
    phosphateRemover: 0,
    poolTemp: 0,
    salt: 0,
    saltBags: 0,
    saltLevel: 0,
    sodaAsh: 0,
    sodiumBicarbonate: 0,
    stainScaleControl: 0,
    taskId: 0,
    taskImage: 0,
    tds: 0,
    tenantId: 52,
    totalAlkalinity: 0,
    totalJobCount: 0,
    techNotes: "",
  });

  const [dosages, setDosages] = useState({
    LiquidChlorine: null,
    CalciumHypochlorite: null,
    CalciumChloride: null,
    CyanuricAcid: null,
    SodiumBicarbonate: null,
    MuriaticAcid: null,
    SodaAsh: null,
    LSI: null,
    LSIRecommendation: null,
  });

  function getTask() {
    setTask({
      taskId: 6,
    });
  }

  function getClient() {
    setClient({
      adSource: null,
      automationYN: null,
      cellPhone: null,
      chlorinatorType: null,
      city: "Charleston",
      comments: null,
      createdDateUtc: "2022-04-07T07:55:41",
      customField1: "11000",
      customField2: null,
      customField3: null,
      customField4: null,
      customField5: null,
      customField6: null,
      customField7: null,
      customField8: null,
      customField9: null,
      customField10: null,
      customField11: null,
      customerTypeFlags: null,
      email: "shrutika.kapse@capgemini.com",
      endDate: null,
      filterType: null,
      firstName: "Test",
      heaterYN: null,
      homePhone: "",
      id: 469,
      isDeleted: false,
      isSyncWithQB: false,
      lastFilterClean: null,
      lastName: "Client1",
      maintenanceFee: null,
      poolCleanerType: null,
      pumpType1: null,
      pumpType2: null,
      qbId: "223",
      startDate: null,
      state: "SC",
      streetAddress: "351 King Street",
      tenantId: 52,
      updatedDateUtc: "2022-04-07T08:21:35",
      zip: "29401",
    });
  }

  function calculateLSIForm(event, type) {
    let tmpChemical = { ...chemical };
    tmpChemical[type] = event.target.value;
    setChemical(tmpChemical);
    let LSIValues = calculateLSI(tmpChemical, client);
    let tempLSI = { ...dosages };
    tempLSI.LSI = LSIValues.LSI;
    tempLSI.LSIRecommendation = LSIValues.LSIRecommendation;
    setDosages(tempLSI);
  }

  function calculateChemicalsForm(event, type, reading) {
    let tmpChemical = { ...chemical };
    tmpChemical[type] = event.target.value;
    setChemical(tmpChemical);
    let dosagesValues = calculateChemicals(
      tmpChemical,
      client,
      reading,
      dosages,
      poolSize
    );
    setDosages(dosagesValues);
  }

  const updateChemicals = async (isComplete) => {
    setLoader(true);
    if (imageSources.length <= 0) {
      const data = await postImageUpload();
      updateForm(isComplete);
    } else {
      const data = await postImageUpload();
      if (data) {
        updateForm(isComplete);
      }
    }
  };

  const updateForm = async (isComplete) => {
    let payload = {};
    let formData = {};
    formData.customer_details = {
      customer_name: client.firstName,
      email: client.email,
      address: client.streetAddress,
      phone: client.homePhone,
      city_state_zip: client.state,
      pool_size: poolSize,
    };
    formData.chemical_readings = {
      free_chlorine: chemical.freeChlorine || "",
      phosphate: chemical.phosphate || "",
      ph: chemical.pH || "",
      filter_pressure: chemical.filterPressure || "",
      total_alkalinity: chemical.totalAlkalinity || "",
      clarity: chemical.clarity || "",
      calcium_hardness: chemical.calciumHardness || "",
      pool_temperature: chemical.poolTemp || "",
      cyanuric_acid: chemical.cyanuricAcid || "",
      tds: chemical.tds || "",
      salt_level: chemical.saltLevel || "",
    };
    formData.chemicals_added = {
      chlorine_tabs: chemical.chlorineTab || "",
      cyanuric_acid: chemical.cyanuricAcidAdded || "",
      chlorine_liquid: chemical.chlorineLiq || "",
      dichlor: chemical.dichlor || "",
      calcium_hypochlorite: chemical.calciumHypochlorite || "",
      de_powder: chemical.dePowder || "",
      muriatic_acid: chemical.muriaticAcid || "",
      calcium_chloride: chemical.calciumChloride || "",
      sodium_bicarbonate: chemical.sodiumBicarbonate || "",
      algaecide: chemical.algaecide || "",
      soda_ash: chemical.sodaAsh || "",
      stain_scale_control: chemical.stainScaleControl || "",
      salt: chemical.salt || "",
      phosphate_remover: chemical.phosphateRemover || "",
    };
    formData.recommended_doses = dosages;
    formData.tech_notes = chemical.techNotes;
    formData.comment = chemical.comment;
    payload["form_data"] = formData;
    payload["has_attachments"] = imageSources.length > 0 ? true : false;
    chemical.assignedTaskId = task.id;
    chemical.tenantId = task.tenantId;
    chemical.isComplete = isComplete;
    var date = moment().format("YYYY-MM-DD");
    var hour = moment().format("HH:mm");
    upsertChemical(chemical).then(() => {
      if (isComplete) setTextButton("Save & Resend");
    });

    const postFormData = async () => {
      console.log("saveMaintenencePoolReport", {
        name: "saveMaintenencePoolReport",
        api: `/form-data/save_and_complete/?form_data_uuid=${params.formuuid}&send_email=${isComplete}&report_date=${date}&report_time=${hour}`,
        method: "post",
        payload: payload,
        offline: offline,
      });
      if (offline) {
        saveOfflineRequest({
          name: "saveMaintenencePoolReport",
          api: `/form-data/save_and_complete/?form_data_uuid=${params.formuuid}&send_email=${isComplete}&report_date=${date}&report_time=${hour}`,
          method: "post",
          payload: payload,
        });
      } else {
        const data = await saveMaintenencePoolReport(
          payload,
          isComplete,
          params.formuuid,
          date,
          hour
        );

        if (data?.form_data?.uuid) {
          message.success("Form Data saved  Successfully!..");
          if (isComplete)
            if (window !== window.parent) {
              var event = new CustomEvent("handleCloseEvent", { detail: data });
              window.parent.document.dispatchEvent(event);
            } else {
              close();
            }
        } else {
          message.error("Form Data was not saved please try again");
        }
      }
      let dataEvents = JSON.parse(localStorage.getItem("events")).map(
        (event) => {
          if (event.formID === params.formuuid) {
            return {
              ...event,
              formData: { ...event.formData, form_data: formData },
            };
          } else {
            return event;
          }
        }
      );
      localStorage.setItem("events", JSON.stringify(dataEvents));
      setLoader(false);
    };
    postFormData();
  };

  const openChemicalModel = async (data) => {
    let param = {
      report_type: data.type,
      service_order_uuid: allData.service_order_uuid,
      service_uuid: allData.service_uuid,
    };
    // Call API to get Chemival data and set the data in local state and pass as param to ChemicalModel
    let chemicalData = await getChimicalDatas(param);
    setChemicalData(chemicalData);
    setChemicalType(data.type);
    setChemicalTitle(data.title);
    setShowChemical(true);
  };

  const handlePoolSize = (value) => {
    setPoolSize(value);
  };

  const handleChangeChemicalTextArea = (e, type) => {
    if (type === "comment") {
      setChemical({ ...chemical, comment: e.target.value });
    } else if (type === "techNotes") {
      setChemical({ ...chemical, techNotes: e.target.value });
    }
  };
  const offlineRender = ({ online }) => {
    console.log();
    if (offline === undefined) {
      setOffline(!online);
    }
  };
  const offlineDetection = (online) => {
    localStorage.setItem("offline", JSON.parse(!online));
    if (online === false) {
      setOffline(!online);
    }
    if (online === true) {
      debugger
      sendOfflineRequests();
      setTimeout(() => {
        setOffline(!online);
        //window.location.reload();
      }, 2000);
    }
  };

  return (
    <div id="wrapper" style={{ padding: "24px" }}>
      {offline ? (
        <Alert
          message={
            <span style={{ fontSize: "18px" }}>
              You are on offline mode,{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                see more
              </span>
            </span>
          }
          banner
        />
      ) : (
        <></>
      )}
      {loader && <Loader />}
      <ChemicalModel
        data={chemicalData}
        type={chemicalType}
        title={chemicalTitle}
        show={showChemical}
        close={() => setShowChemical(false)}
      />
      <Card
        className="middle-section"
        title="Maintenance Pool Report"
        headStyle={{
          fontSize: "24px",
          fontWeight: "bold",
          borderBottom: "1px solid #E1E1E5",
        }}
      >
        <div className="download-mpr">
          {offline ? (
            <span>
              <i className="fa fa-download" style={{ marginLeft: "10px" }}></i>
              <DownloadOutlined />
              Download PDF
            </span>
          ) : (
            <a
              target="_blank"
              href={`${config.getHost()}form-data/as_pdf/?form_data_uuid=${
                params.formuuid
              }`}
              rel="noopener noreferrer"
            >
              <i className="fa fa-download" style={{ marginLeft: "10px" }}></i>
              <DownloadOutlined />
              Download PDF
            </a>
          )}
        </div>
        <div className="mb-10" style={{ fontSize: "16px" }}>
          <p>
            <strong>Job Date: {allData?.job_time?.split("T")[0]}</strong>
          </p>
        </div>
        <CustomerDetails
          client={client}
          handlePoolSize={handlePoolSize}
          poolSize={allData.pool_size}
          showPoolSize={false}
          allData={allData}
        />
        <br />
        <Card
          className="middle-section"
          title="Chemical Readings"
          extra={
            <button
              className={`chemical_button ${offline ? "disabled" : ""}`}
              onClick={(e) =>
                openChemicalModel({
                  type: "chemicals_readings",
                  title: "Previous Chemical Readings",
                })
              }
              disabled={offline ? "disabled" : ""}
            >
              Previous Chemical Readings
            </button>
          }
          headStyle={{
            fontSize: "16px",
            fontWeight: "bold",
            borderBottom: "1px solid #E1E1E5",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <div className="form-gorup mb-10">
            <span className="danger">*</span> Required for LSI Calculation
          </div>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.freeChlorine">Free Chlorine</label>
              <input
                id="chemical.freeChlorine"
                onChange={(e) =>
                  calculateChemicalsForm(e, "freeChlorine", "freechlorine")
                }
                type="number"
                name="input"
                step="0.01"
                autoComplete="off"
                inputMode="decimal"
                value={chemical.freeChlorine}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.phosphate">Phosphate</label>
              <input
                id="chemical.phosphate"
                type="number"
                name="input"
                step="0.01"
                autoComplete="off"
                inputMode="decimal"
                defaultValue={chemical.phosphate}
                value={chemical.phosphate}
                onChange={(e) =>
                  calculateChemicalsForm(e, "phosphate", "phosphate")
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.pH" style={{ width: "100%" }}>
                * pH
              </label>
              <input
                id="chemical.pH"
                onChange={(e) => calculateChemicalsForm(e, "pH", "ph")}
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                value={chemical.pH}
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.FilterPressure">Filter Pressure</label>
              <input
                id="chemical.FilterPressure"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.filterPressure}
                value={chemical.filterPressure}
                onChange={(e) =>
                  calculateChemicalsForm(e, "filterPressure", "filterPressure")
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.TotalAlkalinity">
                <span className="danger">*</span> Total Alkalinity
              </label>
              <input
                id="chemical.Total Alkalinity"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "totalAlkalinity",
                    "totalalkalinity"
                  )
                }
                type="number"
                name="input"
                inputMode="decimal"
                step="0.01"
                autoComplete="off"
                value={chemical.totalAlkalinity}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.Clarity">Clarity (1-10)</label>
              <input
                id="chemical.Clarity"
                type="number"
                name="input"
                inputMode="decimal"
                step="0.01"
                autoComplete="off"
                defaultValue={chemical.clarity}
                value={chemical.clarity}
                onChange={(e) =>
                  calculateChemicalsForm(e, "clarity", "clarity")
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.CalciumHardness">
                <span className="danger">*</span> Calcium Hardness
              </label>
              <input
                id="chemical.CalciumHardness"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "calciumHardness",
                    "calciumhardness"
                  )
                }
                type="number"
                name="input"
                inputMode="decimal"
                step="0.01"
                autoComplete="off"
                value={chemical.calciumHardness}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.PoolTemp">
                <span className="danger">*</span> Pool Temperature{" "}
              </label>
              <input
                id="chemical.PoolTemp"
                onChange={(e) => calculateLSIForm(e, "poolTemp")}
                type="number"
                name="input"
                inputMode="decimal"
                step="1"
                autoComplete="off"
                value={chemical.poolTemp}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.CyanuricAcid">Cyanuric Acid</label>
              <input
                id="chemical.CyanuricAcid"
                onChange={(e) =>
                  calculateChemicalsForm(e, "cyanuricAcid", "cyanuricacid")
                }
                type="number"
                name="input"
                inputMode="decimal"
                step="0.01"
                autoComplete="off"
                value={chemical.cyanuricAcid}
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.tds">TDS (ppm)</label>
              <input
                id="chemical.tds"
                onChange={(e) => calculateLSIForm(e, "tds")}
                type="number"
                name="input"
                inputMode="decimal"
                step="1"
                autoComplete="off"
                value={chemical.tds}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.SaltLevel">Salt Level</label>
              <input
                id="chemical.SaltLevel"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.saltLevel}
                value={chemical.saltLevel}
                onChange={(e) =>
                  calculateChemicalsForm(e, "saltLevel", "saltLevel")
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}></Col>
          </Row>
        </Card>
        <br />
        <Card
          className="middle-section"
          title="Recommended Dosages"
          headStyle={{
            fontSize: "16px",
            fontWeight: "bold",
            borderBottom: "1px solid #E1E1E5",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <Row justify="space-around">
            <Col xs={24} sm={24} md={11} lg={11}>
              <Row className="mb-10">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>Liquid Chlorine (gal) :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.LiquidChlorine}</label>
                </Col>
              </Row>
              <Row className="mb-10">
                <Col xs={23} sm={23} md={8} lg={8}>
                  <strong>Calcium Hypochlorite (Shock) (lbs) :</strong>
                </Col>
                <Col xs={1} sm={1} md={8} lg={8}>
                  <label>{dosages.CalciumHypochlorite}</label>
                </Col>
              </Row>
              <Row className="mb-10">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>Muriatic Acid (gal) :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.MuriaticAcid}</label>
                </Col>
              </Row>
              <Row className="mb-10">
                <Col xs={18} sm={18} md={8} lg={8}>
                  <strong>Sodium Bicarbonate (lbs) :</strong>
                </Col>
                <Col xs={6} sm={6} md={8} lg={8}>
                  <label>{dosages.SodiumBicarbonate}</label>
                </Col>
              </Row>
              <Row className="mb-10">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>LSI Recommendation :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.LSIRecommendation}</label>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11}>
              <Row className="mb-10">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>Soda Ash (lbs) :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.SodaAsh}</label>
                </Col>
              </Row>
              <Row className="mb-10">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>Cyanuric Acid (lbs) :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.CyanuricAcid}</label>
                </Col>
              </Row>
              <Row className="mb-10">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>Calcium Chloride (lbs) :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.CalciumChloride}</label>
                </Col>
              </Row>
              <Row className="">
                <Col xs={16} sm={16} md={8} lg={8}>
                  <strong>LSI :</strong>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <label>{dosages.LSI}</label>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <br />
        <Card
          className="middle-section"
          title="Chemicals Added"
          extra={
            <button
              className={`chemical_button ${offline ? "disabled" : ""}`}
              onClick={(e) =>
                openChemicalModel({
                  type: "chemicals_added",
                  title: "Previous Chemicals Added",
                })
              }
              disabled={offline ? "disabled" : ""}
            >
              Previous Chemicals Added
            </button>
          }
          headStyle={{
            fontSize: "16px",
            fontWeight: "bold",
            borderBottom: "1px solid #E1E1E5",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.chlorineTabs">Chlorine Tabs</label>
              <input
                id="chemical.chlorineTabs"
                type="number"
                name="input"
                step="0.01"
                autoComplete="off"
                defaultValue={chemical.chlorineTab}
                value={chemical.chlorineTab}
                inputMode="decimal"
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "chlorineTab", "chlorineTab")
                }
              />
              <label style={{ width: "10px" }}>ea.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.Other">Cyanuric Acid</label>
              <input
                id="chemical.cyanuricAcidLbs"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.cyanuricAcidAdded}
                value={chemical.cyanuricAcidAdded}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "cyanuricAcidAdded",
                    "cyanuricAcidAdded"
                  )
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.ChlorineLiquid">Chlorine Liquid</label>
              <input
                id="chemical.ChlorineLiquid"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.chlorineLiq}
                value={chemical.chlorineLiq}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "chlorineLiq", "chlorineLiq")
                }
              />
              <label style={{ width: "10px" }}>gals.</label>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.DichlorLbs" style={{ width: "100%" }}>
                Dichlor
              </label>
              <input
                id="chemical.DichlorLbs"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.dichlor}
                value={chemical.dichlor}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "dichlor", "dichlor")
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.CalciumHypochlorite">
                Calcium Hypochlorite
              </label>
              <input
                id="chemical.CalciumHypochlorite"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.calciumHypochlorite}
                value={chemical.calciumHypochlorite}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "calciumHypochlorite",
                    "calciumHypochlorite"
                  )
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.DEPowder">DE Powder</label>
              <input
                id="chemical.DEPowder"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.dePowder}
                value={chemical.dePowder}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "dePowder", "dePowder")
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.MuriaticAcid">Muriatic Acid</label>
              <input
                id="chemical.MuriaticAcid"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.muriaticAcid}
                value={chemical.muriaticAcid}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "muriaticAcid", "muriaticAcid")
                }
              />
              <label style={{ width: "10px" }}> gals.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.CalciumChloride">Calcium Chloride</label>
              <input
                id="chemical.CalciumChloride"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.calciumChloride}
                value={chemical.calciumChloride}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "calciumChloride",
                    "calciumChloride"
                  )
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.SodiumBicarbonate">
                Sodium Bicarbonate
              </label>
              <input
                id="chemical.SodiumBicarbonate"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.sodiumBicarbonate}
                value={chemical.sodiumBicarbonate}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "sodiumBicarbonate",
                    "sodiumBicarbonate"
                  )
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.Algaecide">Algaecide</label>
              <input
                id="chemical.Algaecide"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.algaecide}
                value={chemical.algaecide}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "algaecide", "algaecide")
                }
              />
              <label style={{ width: "10px" }}>ozs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.SodaAsh">Soda Ash</label>
              <input
                id="chemical.SodaAsh"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.sodaAsh}
                value={chemical.sodaAsh}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(e, "sodaAsh", "sodaAsh")
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.StainScaleControl">
                Stain Scale Control
              </label>
              <input
                id="chemical.StainScaleControl"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.stainScaleControl}
                value={chemical.stainScaleControl}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "stainScaleControl",
                    "stainScaleControl"
                  )
                }
              />
              <label style={{ width: "10px" }}>ozs.</label>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.SaltBags" style={{ width: "100%" }}>
                Salt
              </label>
              <input
                id="chemical.SaltBags"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.salt}
                value={chemical.salt}
                className="form-input"
                onChange={(e) => calculateChemicalsForm(e, "salt", "salt")}
              />
              <label style={{ width: "10px" }}>bags.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="chemical.PhosphateRemover">
                Phosphate Remover
              </label>
              <input
                id="chemical.PhosphateRemover"
                type="number"
                name="input"
                step="0.01"
                inputMode="decimal"
                autoComplete="off"
                defaultValue={chemical.phosphateRemover}
                value={chemical.phosphateRemover}
                className="form-input"
                onChange={(e) =>
                  calculateChemicalsForm(
                    e,
                    "phosphateRemover",
                    "phosphateRemover"
                  )
                }
              />
              <label style={{ width: "10px" }}>lbs.</label>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}></Col>
          </Row>
        </Card>
        <div className="form-group mt-30">
          <div className="fitem">
            <label
              htmlFor="chemical.comment"
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              Comments to Customer
            </label>
            <textarea
              id="chemical.comment"
              rows="5"
              cols="48"
              autoComplete="off"
              value={chemical.comment}
              onChange={(e) => handleChangeChemicalTextArea(e, "comment")}
            />
          </div>
          <Card
            className="middle-section"
            title="Pictures"
            headStyle={{
              fontSize: "16px",
              fontWeight: "bold",
              borderBottom: "1px solid #E1E1E5",
            }}
            bodyStyle={{ padding: "24px" }}
          >
            <div className="fitem">
              <strong htmlFor="tenant.name" className="control-label">
                Upload your image:
              </strong>
              <div className="fitem">
                <span className="help-block">
                  Acceptable file types are .png, .jpg, or .gif.{" "}
                </span>
              </div>
              {!isLoading && (
                <ImageUploader
                  text={"Upload image"}
                  fileList={imageSources}
                  getFiles={getFilesCustomer}
                  accept={".png, .jpg, .gif"}
                  params={{
                    level: "MaintenanceReport",
                    chem: chemical,
                    task: task,
                    isTechImage: false,
                  }}
                />
              )}
            </div>
          </Card>
          <br />
          <Card
            className="middle-section"
            title="Tech Notes"
            headStyle={{
              fontSize: "16px",
              fontWeight: "bold",
              borderBottom: "1px solid #E1E1E5",
            }}
          >
            <div className="fitem">
              <div className="fitem">
                <textarea
                  id="chemical.comment"
                  rows="5"
                  cols="48"
                  autoComplete="off"
                  ng-model="chemical.techNotes"
                  value={chemical.techNotes}
                  onChange={(e) => handleChangeChemicalTextArea(e, "techNotes")}
                />
              </div>
              <strong htmlFor="tenant.name" className="control-label">
                Upload your image:
              </strong>
              <div className="fitem">
                <span className="help-block">
                  Acceptable file types are .png, .jpg, or .gif.{" "}
                </span>
              </div>
              {!isLoading && (
                <ImageUploader
                  text={"Upload tech image"}
                  fileList={imageSourcesTech}
                  getFiles={getFilesTechnical}
                  accept={".png, .jpg, .gif"}
                  params={{
                    level: "MaintenanceReport",
                    chem: chemical,
                    task: task,
                    isTechImage: true,
                  }}
                />
              )}
            </div>
          </Card>
        </div>
        <FooterForm
          textButton={textButton}
          handleClick={() => updateChemicals(false)}
          handleClickEmail={() => updateChemicals(true)}
        />
      </Card>
      <Detector render={offlineRender} onChange={offlineDetection} />
    </div>
  );
};

export default MaintenancePoolReport;
