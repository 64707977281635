import React, { useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { orderBy } from "lodash";
import { Table, Select, Row, Col,Input,Tooltip,Button } from 'antd';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import Sidebar from '../../Components/Sidebar';
import DataTable from '../Common/DataTable';
import { SearchOutlined } from '@ant-design/icons';


const { Option } = Select;
const PricingList =()=>{
    const PriceListTable=()=>{
        return(
            <Table
            className="tablebody service_descriptionTable_table"
           // rowKey={(record) => record.repair_code}
            dataSource={[]}
            pagination={true}
            columns={DataTable.PriceListTable()}
          />
        )
       }
    return(
    <>
    <div>
        <div>
            <Row>
                <Col span={4}>                    
                    <Select 
                        className="swhite"
                        placeholder="Select a Franchise"
                        name="franchise"
                        value={'Select'}>
                        <Option key={'priceType'} value={'all'}>{'PriceType:Motor'}</Option>
                    </Select></Col>
                <Col span={4}>
                 {/* <Tooltip title="search">
                    <Button shape="circle" icon={<SearchOutlined />} />
                </Tooltip> */}
                    <Input type="search" placeholder='Search for Price item'/>
                </Col> 
                <Col span={4}></Col>
                <Col span={4}><button style={{textAlign:'right'}} className={'btn btn-warning'}>Add New Pricing Item </button></Col>
            </Row>
        </div>
        <div style={{width:'50%',marginTop:'2%'}}>
          {PriceListTable()}
        </div>
    </div>
        {/* <div className="container">
            <div className="filter_wrapper">
                <div className="left_side">
                    <Select
                        className="swhite"
                        placeholder="Select a Franchise"
                        name="franchise"
                        value={'Select'}>
                        <Option key={'priceType'} value={'all'}>{'PriceType:Motor'}</Option>
                    </Select>
                </div>
                <div className='right_side'>
                    
                </div>
            </div>
        </div> */}
    </>)
}
export default PricingList;