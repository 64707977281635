import React from "react";
import { Row, Col, Tooltip, Dropdown } from "antd";
import { Popup } from "@mobiscroll/react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {toggleTaskModal, setSelectedTaskData, setTaskFlagFromEdit, setDisableTechnicianTask} from "../../../Store/technicianTaskDataSlice";
import moment from "moment-timezone";
import poolIcon from "../../../Assets/icons/pool_icon_black.svg";
import phoneIcon from "../../../Assets/icons/phone-call.svg";
import { ReconciliationOutlined, EllipsisOutlined  } from "@ant-design/icons";
import {editDuration, addDuration} from "../../../Store/serviceOrderDetailDataSlice";

export const PopupCalendarContainer = (props) => {
  const { isOpen, anchor, setOpen, bgColor, currentEvent, shouldOpenPopup,
    showPoolCharacteristicsModal, showWOModal, role, userInfo, showRescheduleModal,
    showDeleteModal, location, dualRole, onStartJobTime, updateTaskStatus, onCompleteJobTime,
    showActivityLogModal,createItems, isMobile } = props;
    const dispatch = useDispatch();

    const renderOptList = (data) => {
      if (
        data?.original?.type == "Task" &&
        data?.original?.status == "complete"
      ) {
        return false;
      }
      return true;
    };

  return (
    <Popup
      display="bubble"
      isOpen={isOpen}
      anchor={anchor}
      touchUi={false}
      showOverlay={true}
      contentPadding={false}
      closeOnOverlayClick={true}
      closeOnOverlayTap={true}
      onClose={() => setOpen(false)}
      width={400}
      cssClass="md-tooltip"
    >
      <div>
        <div
          className="md-tooltip-header"
          style={{ backgroundColor: bgColor }}
        >
          <span>{currentEvent?.serviceType ?? "service_type"}</span>
          <span
            className="mbsc-font-icon mbsc-icon-close"
            onClick={() => setOpen(false)}
          ></span>
        </div>
        <div className="md-tooltip-info">
          <div className="md-tooltip-time">
            <span className="md-tooltip-text">
              {moment(currentEvent?.start).format("dddd, MMMM DD, YYYY")}
            </span>
            {
              !isMobile ? 
              (
              <span className="md-tooltip-actions">
              {currentEvent?.type == 'Service' || currentEvent?.type == 'Maintenance' ?
                <Tooltip title="Pool Characteristics">
                  <img
                    src={poolIcon}
                    alt=""
                    onClick={() => {
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      showPoolCharacteristicsModal({ original: currentEvent });
                    }}
                  />
                </Tooltip>
                : null}
              {currentEvent?.job_forms?.length > 0 ? (
                <Tooltip title="Job Form">
                  <span
                    className="mbsc-font-icon mbsc-icon-file4"
                    onClick={() => {
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      showWOModal({ original: currentEvent });
                      /*window.open(
                        currentEvent.job_forms[0].form_url,
                        "_blank"
                      );*/
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
              {currentEvent?.edit_work_order_url &&
                !currentEvent?.is_invoiced ? (
                <Tooltip title="Edit Work Order">
                  <span
                    className="mbsc-font-icon mbsc-icon-pencil"
                    onClick={() => {
                      dispatch(addDuration(currentEvent?.service_duration));
                      dispatch(editDuration(true))
                      shouldOpenPopup.current = false;
                      setTimeout(() => (shouldOpenPopup.current = true), 200);
                      window.open(currentEvent?.edit_work_order_url, "_blank");
                    }}
                  ></span>
                </Tooltip>
              ) : (
                <></>
              )}
              {currentEvent?.status !== "complete" ? (
                <>
                  {role === "field_tech" || role === "lead_tech" ? (
                    (((currentEvent?.permissionset?.includes("assign_wo") ||
                      currentEvent?.permissionset?.includes(
                        "reschedule_wo"
                      )) &&
                      userInfo?.user?.technician_permissions
                        ?.reschedule_wo) ||
                      userInfo?.user?.technician_permissions?.assign_wo) && (
                      <Tooltip title="Reschedule">
                        <span
                          className="mbsc-font-icon mbsc-icon-line-calendar"
                          // className={currentEvent?.permissionset?.includes("reschedule_wo") ? "mbsc-font-icon mbsc-icon-line-calendar" : "mbsc-font-icon mbsc-icon-line-calendar wrapper-pointer-not-allowed"}
                          onClick={() => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            showRescheduleModal({ original: currentEvent });
                          }}
                        ></span>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="Reschedule">
                      <span
                        className="mbsc-font-icon mbsc-icon-line-calendar"
                        // className={currentEvent?.permissionset?.includes("reschedule_wo") ? "mbsc-font-icon mbsc-icon-line-calendar" : "mbsc-font-icon mbsc-icon-line-calendar wrapper-pointer-not-allowed"}
                        onClick={() => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          showRescheduleModal({ original: currentEvent });
                        }}
                      ></span>
                    </Tooltip>
                  )}
                  {currentEvent?.type == 'Task' ? (
                    <Tooltip title="Edit Task">
                      <span
                        className="mbsc-font-icon mbsc-icon-pencil"
                        onClick={() => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          setOpen(false)
                          dispatch(setSelectedTaskData(currentEvent))
                          dispatch(toggleTaskModal(true))
                          dispatch(setTaskFlagFromEdit(true))
                          if(role === "field_tech" && currentEvent?.permissionset?.length == 0){
                            dispatch(setDisableTechnicianTask(true))
                          }
                        }}
                      ></span>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  {role === "field_tech" || role === "lead_tech" ? (
                    currentEvent?.permissionset?.includes("delete_so") &&
                    userInfo?.user?.technician_permissions?.delete_so && (
                      <Tooltip title="Delete">
                        <span
                          className="mbsc-font-icon mbsc-icon-remove"
                          onClick={() => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            showDeleteModal({ original: currentEvent });
                          }}
                        ></span>
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="Delete">
                      <span
                        className="mbsc-font-icon mbsc-icon-remove"
                        onClick={() => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          showDeleteModal({ original: currentEvent });
                        }}
                      ></span>
                    </Tooltip>
                  )}
                </>
              ) : (
                <></>
              )}
              {currentEvent?.type != "Task" ?
                <ReconciliationOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    shouldOpenPopup.current = false;
                    setTimeout(
                      () => (shouldOpenPopup.current = true),
                      200
                    );
                    showActivityLogModal({ original: currentEvent });
                  }}
                /> : null}
              </span>
              ) : (
                <span>
                {renderOptList(currentEvent) ? (
                  <Col
                    span={12}
                    className="event-mobile-actions"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Row
                      justify="end"
                      gutter={[10, 5]}
                      className="calendar-label-icons"
                    >
                      <Dropdown
                        menu={{
                          items: createItems(currentEvent),
                          onClick: ((data, e) => {
                            shouldOpenPopup.current = false;
                            setTimeout(
                              () => (shouldOpenPopup.current = true),
                              200
                            );
                            switch (e.key) {
                              case "pool_characteristics":
                                showPoolCharacteristicsModal(currentEvent);
                                return;
                              case "job_form":
                                /*window.open(
                                  currentEvent?.original?.job_forms[0]
                                    ?.form_url,
                                  "_blank"
                                );*/
                                showWOModal(currentEvent)
                                return;
                              case "work_order":
                                window.open(
                                  currentEvent?.original?.edit_work_order_url,
                                  "_blank"
                                );
                                return;
                              case "reschedule":
                                showRescheduleModal(currentEvent);
                                return;
                              case "delete":
                                showDeleteModal(currentEvent);
                                return;
                              case "edit_task":
                                dispatch(
                                  setSelectedTaskData(currentEvent?.original)
                                );
                                dispatch(toggleTaskModal(true));
                                dispatch(setTaskFlagFromEdit(true));
                                if (
                                  role === "field_tech" &&
                                  currentEvent.original?.permissionset
                                    ?.length == 0
                                ) {
                                  dispatch(setDisableTechnicianTask(true));
                                }
                                return;
                              case "activity_log":
                                showActivityLogModal(currentEvent);
                                return;
                              default:
                                return;
                            }
                          }).bind(this, currentEvent),
                        }}
                        trigger={["click"]}
                        >
                          <span
                            onClick={(e) => {
                              shouldOpenPopup.current = false;
                              setTimeout(
                                () => (shouldOpenPopup.current = true),
                                200
                              );
                              e.preventDefault();
                            }}
                            style={{ fontSize: "25px" }}
                          >
                            <EllipsisOutlined />
                          </span>
                        </Dropdown>
                      </Row>
                    </Col>
                  ) : null}
                </span>
              )
            }            
          </div>
          <div className="md-tooltip-time">
            <span className="md-tooltip-text">
              {moment(currentEvent?.start).utc().format("hh:mm A")}
            </span>
          </div>
          <div className="md-tooltip-name">
            <span>{currentEvent?.title}</span>
          </div>
          <div className="md-tooltip-status">
            <span className="md-tooltip-title">Technician:</span>
            <span className="md-tooltip-text" style={{ textTransform:'capitalize' }}>
              {(currentEvent?.type === 'Task' ? currentEvent?.technician?.name: currentEvent?.technician) || 'Not assigned'}
            </span>
          </div>
          <div className="md-tooltip-status">
            <span className="md-tooltip-title">Customer:</span>
            <span className="md-tooltip-text md-tooltip-notes">
              {currentEvent?.type == 'Task' ? currentEvent?.customer?.name : currentEvent?.client_name}
              <a href={`tel:${currentEvent?.phone_number}`}>
                <span className="md-tooltip-status">
                  <img
                    src={phoneIcon}
                    alt=""
                    className="phone-icon"
                  /> 
                  {currentEvent?.phone_number}
                </span>
              </a>
            </span>
          </div>
          <div className="md-tooltip-status">
            <span className="md-tooltip-title">Status:</span>
            <span className="md-tooltip-status md-tooltip-text">
              {currentEvent?.status}
            </span>
          </div>
          <div className="md-tooltip-notes">
            <span className="md-tooltip-title">Special Notes:</span>
            <span className="md-tooltip-text">
              {currentEvent?.special_notes}
            </span>
          </div>           
          <div className="md-tooltip-notes">
            {!currentEvent?.address?.formatted_address.toLowerCase().includes('none') ?
              <>
                <span className="md-tooltip-title">Address:</span>
                <span className="md-tooltip-text">
                  <Row>
                    <Col span={3}>
                      <img src={location} alt="" className="calendar-location" />
                    </Col>
                    <Col span={21}>
                      <a
                        target={"_blank"}
                        rel="noreferrer"
                        href={`https://www.google.com/maps/search/?api=1&query=${currentEvent?.address?.formatted_address}`}
                        style={{ color: "#000", textDecoration: "underline" }}
                      >
                        {currentEvent?.address?.formatted_address}
                      </a>
                    </Col>
                  </Row>
                </span>
              </> : null}
            <>
              {(role === "field_tech" || role === "lead_tech" || dualRole) && currentEvent?.type != "Task" ? (
                <div>
                  <Row className="button-row">
                    <Col span={7}>
                      <button
                        className={
                          currentEvent?.status === "complete" ||
                            currentEvent?.status === "cancelled" ||
                            currentEvent?.start_job_datetime !== null ||
                            currentEvent?.technician_uuid == null ||
                            currentEvent?.technician_uuid == undefined
                            ? " btn button-start-disabled"
                            : "btn btn-bdr button-start-enabled"
                        }
                        disabled={
                          currentEvent?.status === "complete" ||
                          currentEvent?.status === "cancelled" ||
                          currentEvent?.start_job_datetime !== null ||
                          currentEvent?.technician_uuid == null  ||
                          currentEvent?.technician_uuid == undefined
                        }
                        onClick={() => onStartJobTime(currentEvent)}
                      >
                        Start Job
                      </button>
                    </Col>
                    <Col span={7}>
                      <button
                        type="submit"
                        className={
                          currentEvent?.status === "complete" ||
                            currentEvent?.status === "cancelled" ||
                            (currentEvent?.start_job_datetime !== null &&
                              currentEvent?.end_job_datetime !== null) ||
                            currentEvent?.start_job_datetime === null ||
                            currentEvent?.technician_uuid == null ||
                            currentEvent?.technician_uuid == undefined
                            ? " btn button-start-disabled"
                            : "btn btn-bdr button-start-enabled"
                        }
                        disabled={
                          currentEvent?.status === "complete" ||
                          currentEvent?.status === "cancelled" ||
                          (currentEvent?.start_job_datetime !== null &&
                            currentEvent?.end_job_datetime !== null) ||
                          currentEvent?.start_job_datetime === null ||
                          currentEvent?.technician_uuid == null ||
                          currentEvent?.technician_uuid == undefined
                        }
                        onClick={() => onCompleteJobTime(currentEvent)}
                      >
                        Complete Job
                      </button>
                    </Col>
                  </Row>
                </div>
              ) : (
                currentEvent?.type == "Task" ?
                  <div>
                    <Row className="button-row">
                      <Col span={7}>
                        <button
                          className={
                            currentEvent?.status === "complete" ||
                              currentEvent?.status === "scheduled" ||
                              currentEvent?.status === "cancelled" ||
                              currentEvent?.start_job_datetime !== null ||
                              currentEvent?.technician_uuid == null ||
                              currentEvent?.technician_uuid == undefined
                              ? " btn button-start-disabled"
                              : "btn btn-bdr button-start-enabled"
                          }
                          disabled={
                            currentEvent?.status === "complete" ||
                            currentEvent?.status === "cancelled" ||
                            currentEvent?.status === "scheduled" ||
                            currentEvent?.start_job_datetime !== null ||
                            currentEvent?.technician_uuid == null ||
                            currentEvent?.technician_uuid == undefined
                          }
                          onClick={() => updateTaskStatus(currentEvent, 'scheduled')}
                        >
                          Start Job
                        </button>
                      </Col>
                      <Col span={7}>
                        <button
                          type="submit"
                          className={
                            currentEvent?.status === "scheduled"
                              ? "btn btn-bdr button-start-enabled"
                              : "btn button-start-disabled"
                          }
                          onClick={() => updateTaskStatus(currentEvent, 'complete')}
                        >
                          Complete Job
                        </button>
                      </Col>
                    </Row>
                  </div>
                  : null
              )}
            </>
          </div>
        </div>
      </div>
    </Popup>
  )
}