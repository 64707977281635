import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import ReportsData from "../../Common/ReportList.Data";
import { useReport } from "../../Hooks/Report.Hook";
import Sidebar from "../Sidebar/";
import ExtraSidebarContent from "../ExtraSidebarContent";

const SidebarContainer = () => {
  const { id } = useParams();
  const [menuFold, setMenuFold] = useState(false);
  const { buildReport, resetReport, loading , stage, setErrors} = useReport();

  const handleFold = () => {
    setMenuFold(!menuFold);
  };
  const validateReport = ()=>{
    if(id == "captured_lead_reports"){
      if(stage === undefined){
        setErrors({"stage_error":"Please select stage."});
        return false;
      }else{
        setErrors({})
      }
    }
    return true;
  }
  return (
    <>
      <aside
        className="laside fo-side d-flex flex-column justify-content-start"
        style={{
          willChange: "max-width",
          maxWidth: menuFold ? "20px" : "20%",
          minWidth: menuFold ? "20px" :"20%",
          padding: menuFold ? "5px" : undefined,
          transition: "max-width 300ms cubic-bezier(0.2, 0, 0, 1) 0s",
        }}
      >
        {menuFold ? (
          <></>
        ) : (
          <>
            <div className="w-100" >
              <h4>{ReportsData.find((item) => item.id === id)?.title}</h4>
              <div className="form-group">
                <Sidebar />
              </div>
            </div>
            <div className="d-flex justify-content-end w-100">
              <div className="btn-grp">
                <button
                  type="button"
                  className="btn btn-brand btn-bdr"
                  onClick={resetReport}
                  disabled={loading}
                >
                  Reset
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>{ 
                      if(validateReport())                   
                        buildReport(true);
                    }
                  }
                  disabled={loading}
                >
                  Build Report
                </button>
              </div>
            </div>
            <div className="w-100">
              <ExtraSidebarContent />
            </div>
          </>
        )}
      </aside>
      {ReportsData.find((item) => item.id === id)?.hasSideBar?
        <LeftOutlined
          type={menuFold ? "menu-unfold" : "menu-fold"}
          className="PlayGround-module--trigger--3uH44"
          style={{minWidth: "2%"}}
          onClick={() => handleFold()}
        />
      :
        <LeftOutlined
        type={menuFold ? "menu-unfold" : "menu-fold"}
        className="PlayGround-module--trigger--3uH44"
        style={{visibility: "hidden"}}
        onClick={() => handleFold()}
        />
      }
    </>
  );
};

export default SidebarContainer;
