import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

// getDashboardSummary
export const getDashboardSummary = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/summary/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};
// getDashboardSummary
export const getDashboardSummaryInvoiced = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/to_be_invoiced/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardServiceSummary
export const getDashboardServiceSummary = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/services/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardServiceTrend
export const getDashboardServiceTrend = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/services-trend/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardServiceTrend
export const getStaffAssignment = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/staff-assignment/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardServiceTrend
export const getWorkOrder = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/work_orders/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};


// getDashboardServiceSummary
export const getDashboardProgressByJob = async (body) => {
  try {
    const response = await axiosInstance.post(
      `/crm/dashboard/job-progress/`,
      body,
      {}
    );
    return response;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};
