const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  mobileMenu: false,
}
const appDataSlice = createSlice({
  name: "appData",
  initialState,
  reducers: {
    setAppDataField(state, action) {
      return (state = {
        ...state,
        ...action.payload,
      });
    },
  }
})
export const { setAppDataField } = appDataSlice.actions;

export default appDataSlice.reducer;