import React from "react";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";
import chevronDown from '../../../Assets/images/chevron-down.png';

class DataTable {
   DescriptionWork = (page) => {
    return [
      {
        title: 'Bundle Product',
        dataIndex: 'bundle_code',
        key: 'bundle_code',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record?.bundle_code}</b>)
      },
      {
        title: 'Repair Code',
        dataIndex: 'repair_code',
        key: 'repair_code',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record?.repair_code}</b>)
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: false,
      },
      {
        title: 'Apply tax',
        dataIndex: 'is_taxable',
        key: 'is_taxable',
        sorter: false,
        render: (text, record) => (          
          <b className="no-wrap">
            <input type="checkbox" 
            className="fitem-ck-input"
            id="checkbox"
            checked={ record?.is_taxable}
            onChange={(e) => { page.applyTax(record); page.removeBundleTax(record) }}
            name="checkboxs" 
            disabled = { page.transection_type === "View" || (record?.bundle_code !== undefined && record?.bundle_code !== null) }
            ></input>
          </b>)
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        key: 'unit_cost',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">${formatToCurrency(page.amount(record?.unit_cost))}</b>)
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">{record?.quantity}</b>)
      },
      {
        title: 'Total',
        dataIndex: 'quantity',
        key: 'quantity',
        sorter: false,
        render: (text, record) => (
          <b className="no-wrap">${formatToCurrency(page.totalPriceDescription(record))}</b>
          )
      },
      {
        title: 'Action',
        dataIndex: 'repair_code',
        key: 'repair_code',
        sorter: false,
        render: (text, record) => (
          
          <div className="position-relative status-dropdown">
             <div className="flex ai-center">
              Select
               <span className="status-d-arrow">
                 <img src={chevronDown} alt=""/>
               </span>
             </div>
             { page.transection_type !== "View" ? 
             <ul className="status-dropdownmenu">               
               <li><a href="/#" onClick={(e) => { e.preventDefault(); page.editLine(record) }}>Edit</a></li>               
               <li><a href="/#" onClick={(e) => { e.preventDefault(); page.deleteLine(record)}}>Delete</a></li>
             </ul>
             : '' }
           </div>                    
        )
      },
    ]
   }
}

export default new DataTable()

