import React from "react";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import { useReport } from "../../Hooks/Report.Hook";
import FranchiseSelector from "../Filters/Franchise.Selector";
import PeriodSelector from "../Filters/Period.Selector";
import { getStartEndPeriod } from "../../Common/getDates";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';

const InvoiceReconciliationSidebar = () => {
  const {
    franchise,
    serviceDate,
    setFranchise,
    setServiceDate,
    setServiceDatePeriod,
  } = useReport();

  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
  };
  const handleServiceDateChange = (e) => {
    setServiceDate(e);
    setServiceDatePeriod(getStartEndPeriod(e));
  };
  const handleCustomDateChange = (e) => {
    setServiceDatePeriod({
      start_date: e[0].format("YYYY-MM-DD"),
      end_date: e[1].format("YYYY-MM-DD"),
    });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <div className="fitem">
        <label>
          <b>
            Franchise <span className="required">*</span>
          </b>
        </label>
        <FranchiseSelector
          handleChange={handleFranchiseChange}
          error={franchise === "error" ? true : false}
          allValue={false}
        />
      </div>
      <div className="fitem">
        <label>
          <b>
            Time Period <span className="required">*</span>
          </b>
        </label>
        <PeriodSelector
          handleChange={handleServiceDateChange}
          error={serviceDate === "error" ? true : false}
        />
      </div>
      {serviceDate === "custom" ? (
        <div className="fitem">
          <label>
            <b>
              Custom Range <span className="required">*</span>
            </b>
          </label>
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            onChange={handleCustomDateChange}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InvoiceReconciliationSidebar;
