import React, { useEffect, useState, useCallback } from "react";
import { map } from "lodash";
import moment from "moment-timezone";
import { Row, Col, Select, Input, DatePicker, Spin, TimePicker } from "antd";
import * as yup from "yup";
import {
  getServiceOrderByClient,
  getPoolCharacteristics,
  getServiceMatrix,
  getCustomer,
  getCommercialCustomer,
  getServicesAddress
} from "../../../Action/Customer/index";
import {
  initialCleaningDays,
  checkZipCode,
  getFieldTechnician,
} from "../../../Action/Franchise";
import CustomFrequency from "../../../Customer/Common/CustomFrequency";
import ServiceSummary from "../../../Customer/Residential/NewCustomer/ServiceSummary/ServiceSummary";
import AddDurationField from "../../../Calendar/Components/Modals/SubComponents/AddDurationField";
import AddReferenceField from "../../../Calendar/Components/Modals/SubComponents/AddReferenceField";
import AddressField from "../../../Components/AddressField";
const { Option } = Select;

const MaintenancePop = (props) => {
  const [state, setState] = useState({
    frequency: "",
    errors: {},
    loader: false,
  });
  const [agreementData, setAgreementData] = useState({
    value: true,
    key: "",
    name: "isAgreed",
  });
  const [frequencyData, setFrequencyData] = useState({});
  const [maintenanceCategoryData, setMaintenanceCategoryData] = useState({});
  const [notesData, setNotesData] = useState({});
  const [otherPoolTypeData, setOtherPoolTypeData] = useState({});
  const [poolSizeData, setPoolSizeData] = useState({});
  const [poolTypeData, setPoolTypeData] = useState({});
  const [preferredDateData, setPreferredDateData] = useState({});
  const [serviceData, setServiceData] = useState({});
  const [serviceTypeData, setServiceTypeData] = useState({});
  const [timeRangeData, setTimeRangeData] = useState({});
  const [worksubtypeData, setWorksubtypeData] = useState({});
  const [disablePoolTypeSelect, setdisablePoolTypeSelect] = useState(false);
  const [serviceTypeTitle, setServiceTypeTitle] = useState("");
  const [serviceDetails, setServiceDetails] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [loader, setLoader] = useState(false);
  const [poolSize, setPoolSize] = useState(0);
  const [poolName, setPoolName] = useState({});
  const [poolType, setPoolType] = useState(null);
  const [services, setServices] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [workSubTypeList, setWorkSubTypeList] = useState([]);
  const [customFrequency, setcustomFrequency] = useState(false);
  const [priceDetail, setPriceDetail] = useState({});
  const [maintenanceCategories, setMaintenanceCategories] = useState([]);
  const [isRenovationOrConstruction, setIsRenovationOrConstruction] = useState(false);
  const [service, setService] = useState("");
  const [preferredStartDate, setPreferredStartDate] = useState("");
  const [displayPreferredStartDate, setDisplayPreferredStartDate] = useState("");
  const [notes, setNotes] = useState("");
  const [otherPoolType, setOtherPoolType] = useState("");
  const [isAgreed, setIsAgreed] = useState(true);
  const [data, setData] = useState([]);
  const [checkZipCodeData, setcheckZipCodeData] = useState({});
  const [technicians, setTechnicians] = useState([]);
  const [serviceAddress, setServiceAddress] = useState("New");
  const [addressOption, setAddressOption] = useState([]);
  const [addressDetails, setAddressDetails] = useState([]);
  const [pools, setPools] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({});
  const [time, setTime] = useState(moment("8:00", "HH:mm"));
  const [poolOption, setPoolOption] = useState([]);
  const [pool, setPool] = useState("New");
  const [poolDetails, setPoolDetails] = useState([]);
  const [isRecurring, setisRecurring] = useState(true);
  const [temp_address, settemp_Address] = useState({
    uuid: "New",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    address2: "",
  });
  const [address, setAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    address2: "",
  });

  useEffect(() => {
    if (props?.selectedCustomer) {
      if (props?.selectedCustomer?.uuid) {
        serviceOrderApi();
      }
      getCustomerDetails();
      // getZipCodeDetails();
      const getInitialCleanningDays = async () => {
        const data = await initialCleaningDays({
          zip_code: checkZipCodeData,
        });
        if (data?.data.length) {
          setData(data?.data);
        } else {
          let startdate = moment().format("MM-DD-YYYY");
          let new_date = [];
          var new_date1 = moment(startdate, "MM-DD-YYYY").add(1, "days");
          var new_date2 = moment(startdate, "MM-DD-YYYY").add(2, "days");
          var new_date3 = moment(startdate, "MM-DD-YYYY").add(3, "days");
          new_date.push(startdate);
          new_date.push(new_date1);
          new_date.push(new_date2);
          new_date.push(new_date3);
          new_date.sort(function (a, b) {
            return new Date(a) - new Date(b);
          });
          setData(new_date);
        }
        setLoader(false);
      };
      getInitialCleanningDays();
      setIsAgreed(serviceInfo?.isAgreed?.value ?? true);
      setPreferredStartDate(serviceInfo?.preferredDate?.value ?? "");
    }
  }, [props?.selectedCustomer]);

  useEffect(() => {
    const filteredPoolResult = pools.filter(
      (item) => item.pool_name !== null
    );
    if (filteredPoolResult.length === 1 ) {
      const filtered_address = filteredPoolResult[0]?.service_address;
      if (!filtered_address?.uuid) {
        return;
      }
      settemp_Address({
        uuid: filtered_address.uuid,
        address: filtered_address.address,
        city: filtered_address.city,
        state: filtered_address.state,
        zip_code: filtered_address.zip_code,
        address2: filtered_address.address2,
      });
    }
  }, [pools])

  useEffect(() => {
    reloadPools();
  }, [temp_address])

  const validate = (status, serviceDataInfo, agree) => {
    setServiceInfoData({ value: data, key: true, name: "baseJobReq" });

    setState((s) => ({ ...s, errors: {} }));
    props.onSave(status, serviceDataInfo, priceDetail, agree);
  };

  const serviceOrderApi = async () => {
    const temp_address = [<Option key='New'>New</Option>];
    let data = await getServicesAddress(props?.selectedCustomer?.uuid);
    if (data.length <= 0){
      data = props?.selectedCustomer?.service_address;
    }

    data.map(item => temp_address.push(<Option key={item.uuid}>{item.formatted_address}</Option>));      
    setAddressDetails(data);            
    setAddressOption(temp_address);
  };

  const getCustomerDetails = async () => {
    let customerdata = [];
    if (props?.selectedCustomer?.client_type.toLowerCase() === "residential") {
      customerdata = await getCustomer(props?.selectedCustomer?.uuid);
    } else if (props?.selectedCustomer?.client_type.toLowerCase() === "commercial") {
      customerdata = await getCommercialCustomer(props?.selectedCustomer?.uuid);
    }
    if (customerdata?.field_office) {
      setLoader(false);
      getServiceDetails(customerdata?.field_office?.uuid);
      getZipCodeDetails(customerdata?.field_office?.addressable_zip_codes[0]);
      getFieldTech(customerdata?.field_office?.uuid);
      getPool();
    } else setLoader(true);
  };

  const getFieldTech = async (officeUuid) => {
    const data = await getFieldTechnician({
      field_office__uuid: officeUuid,
    });
    setTechnicians(data?.results);
  };

  const getServiceDetails = async (officeUuid) => {
    const serviceData = await getServiceMatrix(officeUuid);
    setServiceDetails(serviceData);
  };

  const getZipCodeDetails = async (zipCode) => {
    const data = await checkZipCode({
      addressable_zip_codes__contains: zipCode,
      close: false,
    });
    if (data?.count > 0) {
      setcheckZipCodeData(data?.results[0]);
    }
  };

  const getPools = useCallback(async () => {
    if (props?.selectedCustomer && props?.selectedCustomer?.uuid) {
      const poolResults = await getPoolCharacteristics(
        props?.selectedCustomer?.uuid
      );
      setPools(poolResults ? poolResults : []);
    }
  }, [setPools]);


  const getPool = async () => {
    if (!props?.selectedCustomer?.uuid) {
      return;
    }
    let data = [];
    setLoader(true);
    data = await getPoolCharacteristics(props?.selectedCustomer?.uuid);
    setPools(data ? data : []);
    setServiceInfoData({
      key: service.name,
      name: "poolType",
      value: data[0]?.pool_type_uuid,
      uuid: data[0]?.pool_type_uuid,
    });
    setServiceInfoData({
      key: service.name,
      name: "poolSize",
      value: data[0]?.pool_size ?? 0,
    });
    setPoolSizeData({
      key: service.name,
      name: "poolSize",
      value: data[0]?.pool_size ?? 0,
    });
    setServiceInfoData({
      key: service.name,
      name: "poolName",
      value: data[0]?.pool_name ?? "",
    });
    setPoolTypeData({
      key: service.name,
      name: "poolType",
      value: data[0]?.pool_type_uuid,
      uuid: data[0]?.pool_type_uuid,
    });
    setLoader(false);
  };

  const onDataChange = (e) => {
    setPreferredStartDate(e.target.checked ? e.target.value : null);
    setDisplayPreferredStartDate(null);
    setServiceInfoData({
      value: e.target.value,
      key: true,
      name: "preferredDate",
    });
    // validate('active', serviceInfo);
    setPreferredDateData({
      value: e.target.value,
      key: true,
      name: "preferredDate",
    });
  };

  const onSelectDisplayPreferredStartDate = (e) => {
    setDisplayPreferredStartDate(e);
    setPreferredStartDate(moment(e).format("MM-DD-YYYY"));
    setServiceInfoData({
      value: moment(e).format("MM-DD-YYYY"),
      key: true,
      name: "preferredDate",
    });
    setPreferredDateData({
      value: moment(e).format("MM-DD-YYYY"),
      key: true,
      name: "preferredDate",
    });
  };

  useEffect(() => {
    if (!serviceInfo.baseJobReq) {
      let service = serviceDetails?.servicetypes?.filter(
        (item) => item.name.toLowerCase() === "maintenance"
      )[0];

      setServiceType(service?.uuid);
      setMaintenanceCategories(service?.worktypes);
      setcustomFrequency(true);
      setIsRenovationOrConstruction(false);
      setServiceInfoData({
        key: service?.name,
        name: "serviceType",
        value: service?.uuid,
      });
      setServiceTypeData({
        key: service?.name,
        name: "serviceType",
        value: service?.uuid,
      });
      setServiceInfoData({
        value: time,
        key: true,
        name: "time",
      });
    } else {
      const data = serviceInfo;
      setServiceType(data?.baseJobReq?.value?.serviceType ?? "");
      let service = serviceDetails?.servicetypes?.filter(
        (item) => item.uuid === data?.baseJobReq?.value?.serviceType
      )[0];

      if (service?.name.toLowerCase() === "service") {
        setcustomFrequency(true);

        setServices(service?.worktypes);
        setService(data?.baseJobReq?.value?.service ?? "");

        let subWork = service?.worktypes?.filter(
          (item) => item.uuid === data?.baseJobReq?.value?.service
        )[0];
        setWorkSubTypeList(subWork?.worksubtypes);
        setServiceInfoData({
          value: time,
          key: true,
          name: "time",
        });
      }

      if (service?.name.toLowerCase() === "maintenance") {
        setcustomFrequency(true);
        setServices([]);
        setWorkSubTypeList([]);
        setFrequency(data?.baseJobReq?.value?.frequency ?? "");
        setServiceInfoData({
          value: time,
          key: true,
          name: "time",
        });
      }

      if (
        service?.name.toLowerCase() === "renovation" ||
        service?.name.toLowerCase() === "construction"
      ) {
        setIsRenovationOrConstruction(false);
        setcustomFrequency(false);
        setServices([]);
        setWorkSubTypeList([]);
      }
    }
    if (props?.selectedCustomer) {
      getPools();
    }
  }, [getPools, serviceDetails]);

  const setServiceInfoData = (data) => {
    if (data) {
      let obj = serviceInfo;
      obj[data.name] = data;
      setServiceInfo(obj);
    }
  };

  const setPriceDetailData = (data) => {
    setPriceDetail(data);
  };

  const resetValues = () => {
    setAgreementData({
      value: true,
      key: "",
      name: "isAgreed",
    });
    setFrequencyData({});
    setMaintenanceCategoryData({});
    setNotesData({});       
    setPreferredDateData({});
    setServiceData({});
    setTimeRangeData({});
    setWorksubtypeData({});            
  };

  const onTextChange = (e, val) => {
    if (e.target.name === "serviceType") {
      setServiceType(e.target.value);
      // validate('active', serviceInfo);
      let obj = serviceDetails?.servicetypes?.filter(
        (item) => item.uuid === e.target.value
      )[0];
      if (obj.name.toLowerCase() === "service") {
        resetValues();
        setServices(obj?.worktypes);
        setFrequency("");
        setWorkSubTypeList([]);
        setcustomFrequency(true);
        setServiceInfoData({ frequency: "", price: 0, key: "service" });
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setPriceDetailData({ frequency: "", price: 0, key: "" });
        setIsRenovationOrConstruction(false);
        setPreferredStartDate([]);
        setMaintenanceCategories([]);
        setNotes("");
        setIsAgreed();
        setDisplayPreferredStartDate("");
        props.setJobPopupSubmitBtnDisable(true);
        setisRecurring(false)
      }
      if (obj.name.toLowerCase() === "maintenance") {
        resetValues();
        setcustomFrequency(true);
        setServices([]);
        setWorkSubTypeList([]);
        setService("");
        setPreferredStartDate([]);
        setMaintenanceCategories(obj?.worktypes);
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setPriceDetailData({ frequency: "", price: 0, key: "" });
        setIsRenovationOrConstruction(false);
        setNotes("");
        setFrequency("");
        setIsAgreed();
        setDisplayPreferredStartDate("");
        // validate('active', {});
        props.setJobPopupSubmitBtnDisable(true);
        setisRecurring(true)
      }

      if (
        obj.name.toLowerCase() === "renovation" ||
        obj.name.toLowerCase() === "construction"
      ) {
        resetValues();
        // resetFunction()
        setcustomFrequency(false);
        setServices([]);
        setWorkSubTypeList([]);
        setService("");
        setFrequency("");
        setIsRenovationOrConstruction(true);
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setPriceDetailData({ frequency: "", price: 0, key: "" });
        setPreferredStartDate([]);
        setMaintenanceCategories([]);
        setNotes("");
        setIsAgreed();
        setDisplayPreferredStartDate("");
        // validate('active', {});
        props.setJobPopupSubmitBtnDisable(true);
      }
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setServiceTypeData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }

    if (e.target.name === "frequency") {
      setFrequency(e.target.value);    
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setFrequencyData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });

      if(serviceInfo?.serviceType?.key?.toLowerCase() !== "service"){
        setPriceDetail({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        setPriceDetailData({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
      }      
    }

    if (e.target.name === "serviceAddress") {
      setServiceAddress(e.target.value);
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      if (e.target.value !== "New") {
        let temp = addressDetails.find(
          (each) => each.uuid === e.target.value
        );
        settemp_Address(temp);
      } else {        
        settemp_Address({
          uuid: "New",
          address: "",
          city: "",
          state: "",
          zip_code: "",
          address2: "",
        });
      }
      onTextChange({ target: { name: 'pool', value: 'new' } })
    }

    if (e.target.name === "service") {
      var servicetypeDetail = val.split("@");
      setService(servicetypeDetail[0]);
      setFrequency("");
      let obj = services?.filter(
        (item) => item.uuid === servicetypeDetail[0]
      )[0];
      let serviceName = services?.filter(
        (item) => item.uuid === servicetypeDetail[0]
      )[0];
      setServiceTypeTitle(serviceName?.name);
      setWorkSubTypeList(obj?.worksubtypes);
      if (obj?.worksubtypes.length === 0) {
        setServiceInfoData({
          value: servicetypeDetail[0],
          key: servicetypeDetail[1],
          name: e.target.name,
        });
        setServiceData({
          value: servicetypeDetail[0],
          key: servicetypeDetail[1],
          name: e.target.name,
          subtypelength: 0,
        });
        setPriceDetail({
          frequency: servicetypeDetail[0],
          price: servicetypeDetail[2],
          key: e.target?.key,
        });
        setPriceDetailData({
          frequency: servicetypeDetail[0],
          price: servicetypeDetail[2],
          key: e.target?.key,
        });
        if(obj?.is_recurring !== undefined && obj?.is_recurring){
          setisRecurring(true)
        }else{
          setisRecurring(false)
        }
      } else {
        setServiceInfoData({
          value: servicetypeDetail[0],
          key: servicetypeDetail[1],
          name: e.target.name,
        });
        setServiceData({
          value: servicetypeDetail[0],
          key: servicetypeDetail[1],
          name: e.target.name,
        });
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setPriceDetailData({ frequency: "", price: 0, key: "" });
        setisRecurring(false)
      }
    }

    if (e.target.name === "worksubtype") {
      var workSubtypeDetail = val.split("@");
      setFrequency("");
      setServiceInfoData({
        value: workSubtypeDetail[0],
        //key: e.target.key,
        name: e.target.name,
      });
      setWorksubtypeData({
        value: workSubtypeDetail[0],
        //key: e.target.key,
        name: e.target.name,
      });
      setPriceDetail({
        frequency: workSubtypeDetail[0],
        price: workSubtypeDetail[1],
        key: e.target?.key,
      });
      setPriceDetailData({
        frequency: workSubtypeDetail[0],
        price: workSubtypeDetail[1],
        key: e.target?.key,
      });
      
      const obj = services?.filter((item) => item.uuid === service)[0];
      const subWorkType = obj.worksubtypes.find(i => i.uuid === workSubtypeDetail[0])
      if(subWorkType?.is_recurring !== undefined && subWorkType?.is_recurring){
        setisRecurring(true)
      }else{
        setisRecurring(false)
      }
    }

    if (e.target.name === "poolType") {
      const selectedPoolType = serviceDetails?.pool_types?.find(
        (pooltype) => pooltype.uuid == val
      );
      var poolDetail = [selectedPoolType.name, selectedPoolType.uuid];
      setPoolType(poolDetail[1]);
      setServiceInfoData({
        value: poolDetail[0],
        key: poolDetail[0],
        name: e.target.name,
        uuid: poolDetail[1],
      });
      setPoolTypeData({
        value: poolDetail[0],
        key: poolDetail[0],
        name: e.target.name,
        uuid: poolDetail[1],
      });
      setOtherPoolType("");
      setServiceInfoData({
        value: null,
        key: "otherPoolType",
        name: "otherPoolType",
      });
      setOtherPoolTypeData({
        value: null,
        key: "otherPoolType",
        name: "otherPoolType",
      });
    }

    if (e.target.name === "poolSize") {
      //const result = e.target.value.replace(/\D/g, "");
      setPoolSize(e.target.value);
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setPoolSizeData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "poolName") {
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setPoolName({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "pool") {
      setPool(e.target.value);
      if (e.target.value !== "new") {
        let filteredPoolDetails = pools.filter(
          (each) => each.pool_name === e.target.value
          );
        setPoolType(filteredPoolDetails[0].pool_type_uuid);
        setPoolName({
          value: filteredPoolDetails[0].pool_name,
          key: undefined,
          name: "poolName",
        });
        setServiceInfoData({
          value: filteredPoolDetails[0].pool_name,
          key: filteredPoolDetails[0].pool_name,
          name:"poolName",
        });
        setPoolSize(filteredPoolDetails[0].pool_size);
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        setdisablePoolTypeSelect(true);
      } else {
        setdisablePoolTypeSelect(false);
        setPoolName("");
        setPoolSize(0);
        setPoolType(null);
      }
    }
    if (e.target.name === "maintenanceCategory") {
      setServiceInfoData({
        value: val,
        key: e.target.key,
        name: e.target.name,
      });
      setMaintenanceCategoryData({
        value: val,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "notes") {
      setNotes(e.target.value);
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setNotesData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "technician") {
      setServiceInfoData({
        value: val,
        key: e.target.key,
        name: e.target.name,
        technicianData: technicians,
      });
    }
    if (e.target.name === "isAgreed") {
      setIsAgreed(e.target.checked);
      setServiceInfoData({
        value: e.target.checked,
        key: e.target.key,
        name: e.target.name,
      });
      setAgreementData({
        value: e.target.checked,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "time") {
      setTime(e.target.value);
      setServiceInfoData({
        value: e.target.value,
        key: true,
        name: "time",
      });
    }
  };

  const reloadPools = async () => {
    setState((s) => ({ ...s, loader: true }));
    const temp_pool = [<Option key="new">New</Option>];
    const data = await getPoolCharacteristics(props?.selectedCustomer?.uuid);
    if (data && data.length > 0) {
      let pool_list = [...data].filter(
        (i) => i.service_address?.uuid === temp_address?.uuid
      );
      pool_list.map((item) =>{
        if(item.pool_name && item.pool_type_uuid){
          temp_pool.push(
            <Option key={item.pool_name} value={item.pool_name}></Option>
          )
        }
      });
      setPoolOption(temp_pool);
      if (pool_list.length > 0) {
        setPool(pool_list[0]?.pool_name);
        setPoolName({value: pool_list[0]?.pool_name});
        setPoolType(pool_list[0]?.pool_type_uuid ?? "");
        setPoolSize(pool_list[0]?.pool_size ?? 0);
        setdisablePoolTypeSelect(true);
      }
    }

    setState((s) => ({ ...s, loader: false }));
  }

  const convertToTitleCase = (str) => {
    return str
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(" ");
  };

  const renderExtraFooter = () => {
    return (
      <div className="data_indicator">
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn"
        >
          Available
        </a>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn unavailable"
        >
          Unavailable
        </a>
      </div>
    );
  };

  useEffect(() => {
    if (
      serviceTypeData?.key === "maintenance" &&
      frequencyData?.value &&
      maintenanceCategoryData?.value &&
      agreementData?.value === true
    ) {
      validate("active", serviceInfo, agreementData?.value);
      props.setJobPopupSubmitBtnDisable(false);
    } else if (
      serviceTypeData?.key === "service" &&
      ((serviceData?.subtypelength === 0 && serviceData?.value) ||
        (serviceData?.value && worksubtypeData?.value)) &&
      frequencyData?.value &&
      agreementData?.value === true
    ) {
      validate("active", serviceInfo, agreementData?.value);
      props.setJobPopupSubmitBtnDisable(false);
    } else if (
      serviceTypeData?.key === "construction" &&
      preferredDateData?.value &&
      agreementData?.value === true
    ) {
      validate("active", serviceInfo, agreementData?.value);
      props.setJobPopupSubmitBtnDisable(false);
    } else if (
      serviceTypeData?.key === "renovation" &&
      preferredDateData?.value &&
      agreementData?.value === true
    ) {
      validate("active", serviceInfo, agreementData?.value);
      props.setJobPopupSubmitBtnDisable(false);
    } else {
      props.setJobPopupSubmitBtnDisable(true);
    }
  }, [
    agreementData,
    poolTypeData,
    poolSizeData,
    serviceTypeData,
    serviceData,
    frequencyData,
    timeRangeData,
    preferredDateData,
    maintenanceCategoryData,
    notesData,
    otherPoolTypeData,
    worksubtypeData,
    serviceAddress,
    poolName,
  ]);

  const handleAddressChange = (value) => {
    setAddress(value);
    setServiceInfoData({
      value: value,
      key: undefined,
      name: "address",
    });
  };

  const onAddressChange = (e) => {
    const addr = { ...address };
    Object.assign(addr, { [e.target.name]: e.target.value });
    setAddress(addr);
    setServiceInfoData({
      value: e.target.value,
      key: undefined,
      name: "address",
    });
  };

  const render = () => {
    const { errors, loader } = state;

    return (
      <>
        <Row>
          <Col xxl={12} xl={12} md={12} xs={24} sm={24}>
            <h3 className="mt-20">Base Job Requirements</h3>
            <div className="frow">
              <label>
                Service type<sup>*</sup>
              </label>
              {!Object?.keys(serviceDetails)?.length ? (
                <Spin
                  tip="Loading Service Type..."
                  size="small"
                  style={{ height: "100px", position: "relative" }}
                >
                  <div className="content" />
                </Spin>
              ) : (
                <div className="oplist">
                  {map(serviceDetails?.servicetypes, (item, index) => (
                    <div className="inline-checks" key={`serviceType${index}`}>
                      <label
                        htmlFor={item?.uuid}
                        className={`${
                          serviceType === item?.uuid ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id={item?.uuid}
                          name="serviceType"
                          className=""
                          value={item?.uuid}
                          onChange={() =>
                            onTextChange({
                              target: {
                                name: "serviceType",
                                value: item?.uuid,
                                key: item?.name,
                              },
                            })
                          }
                        />
                        <span>{item?.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
              {errors?.["serviceType"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.["serviceType"]}
                </label>
              ) : (
                ""
              )}
            </div>
            {customFrequency &&
            serviceInfo?.serviceType?.key?.toLowerCase() === "maintenance" ? (
              <div className="oplist">
                <label>Rate Type <sup>*</sup></label>
                <Select
                  dropdownClassName="sm-filter"
                  showArrow
                  name={"maintenanceCategory"}
                  virtual={false}
                  style={{ width: "100%" }}
                  // value={item?.uuid}
                  placeholder={"Select Rate Type"}
                  onChange={(e) =>
                    onTextChange({ target: { name: "maintenanceCategory" } }, e)
                  }
                >
                  {map(maintenanceCategories, (item, index) => (
                    <Option value={item?.name} key={item?.uuid}>
                      {item?.name} ({item.additional_text})
                    </Option>
                  ))}
                </Select>
              </div>
            ) : null}
            {services.length > 0 ? (
              <div className="frow">
                <label>
                  What service you are looking for?<sup>*</sup>
                </label>
                <div className="oplist">
                  <Select
                    dropdownClassName="sm-filter"
                    showArrow
                    name={"service"}
                    virtual={false}
                    style={{ width: "100%" }}
                    placeholder={"Select Service Type"}
                    onChange={(e) =>
                      onTextChange(
                        {
                          target: {
                            name: "service",
                          },
                        },
                        e
                      )
                    }
                  >
                    {map(services, (item, index) => (
                      <Option
                        value={`${item?.uuid}@${item?.name}@${item?.price}`}
                        key={item?.name}
                      >
                        {convertToTitleCase(item?.name)}
                      </Option>
                    ))}
                  </Select>
                </div>
                {errors?.[`service`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`service`]}
                  </label>
                ) : (
                  ""
                )}
                {workSubTypeList.length > 0 ? (
                  <div className="frow">
                    <label>
                      {serviceTypeTitle} Categories<sup>*</sup>
                    </label>
                    <div className="oplist">
                      <Select
                        dropdownClassName="sm-filter"
                        showArrow
                        name={"worksubtype"}
                        virtual={false}
                        style={{ width: "100%" }}
                        //value={item?.uuid}
                        placeholder={"Select Category Type"}
                        onChange={(e) =>
                          onTextChange(
                            {
                              target: {
                                name: "worksubtype",
                              },
                            },
                            e
                          )
                        }
                      >
                        {map(workSubTypeList, (item, index) => (
                          <Option
                            value={`${item?.uuid}@${item?.price}`}
                            key={item?.uuid}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                ) : null}
                {errors?.[`workSubType`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`workSubType`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            ) : null}
            {customFrequency ? (
              <div className="margin-top-10">
                {serviceInfo?.serviceType?.key?.toLowerCase() ===
                "maintenance" ? (
                  <h4>How often do you need your pool cleaned?</h4>
                ) : (
                  <h4> How often do you need your service?</h4>
                )}
                <CustomFrequency
                  onTextChange={onTextChange}
                  is_recurring={isRecurring}
                  data={frequency}
                  recurring_base_prices={serviceDetails?.recurring_base_prices}
                />
                {errors?.[`frequency`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`frequency`]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {serviceInfo?.serviceType?.key?.toLowerCase() === "maintenance" ||
            serviceInfo?.serviceType?.key?.toLowerCase() === "service" ? (
              <>
                <Row>
                  <div className="mb-10 so-heading" style={{ width: "50%" }}>
                    <h4>Select Time</h4>
                    <TimePicker
                      className=""
                      placeholder={"--:-- AM/PM"}
                      value={time}
                      onChange={(e) => {
                        onTextChange({
                          target: {
                            name: "time",
                            value: e,
                            key: "time",
                          },
                        });
                      }}
                      allowClear={false}
                      format={"h:mm A"}
                    />
                  </div>
                  <AddDurationField durationType="service" screen="soPopUp" />
                </Row>
                <Row>
                  <AddReferenceField
                    type="renovationAndConstruction"
                    screen="soPopUp"
                  />
                </Row>
              </>
            ) : null}
            {isRenovationOrConstruction ? (
              <div className="frow">
                <label>Notes: Please provide customer's request</label>
                <textarea
                  placeholder="Customer proposal"
                  name="message"
                  rows="5"
                  value={notes}
                  onChange={(e) => {
                    onTextChange({
                      target: {
                        name: "notes",
                        value: e.target.value,
                        key: "notes",
                      },
                    });
                  }}
                ></textarea>
              </div>
            ) : (
              ""
            )}

            {Object?.keys(serviceDetails)?.length &&
            serviceInfo?.serviceType?.key?.toLowerCase() !== "maintenance" &&
            serviceInfo?.serviceType?.key?.toLowerCase() !== "service" ? (
              <>
                <div className="frow">
                  <label>
                    Preferred Start Date<sup>*</sup>
                  </label>
                  <div className="oplist">
                    {map(
                      data
                        ?.sort(function (a, b) {
                          return new Date(a) - new Date(b);
                        })
                        ?.slice(0, 4),
                      (item, i) => {
                        return (
                          <div
                            className="inline-checks flex-1"
                            key={`preferred_start_dates${i}`}
                          >
                            <label
                              htmlFor={item?.value}
                              className={
                                preferredStartDate ===
                                moment(item).format("MM-DD-YYYY")
                                  ? "active"
                                  : ""
                              }
                            >
                              <input
                                type="radio"
                                id={item?.value}
                                name="preferredStartDate"
                                checked={
                                  preferredStartDate ===
                                  moment(item).format("MM-DD-YYYY")
                                }
                                value={moment(item).format("MM-DD-YYYY")}
                                className=""
                                onChange={onDataChange}
                              />
                              <span>
                                {item === moment().format("MM-DD-YYYY")
                                  ? "Today"
                                  : moment(item, "MM-DD-YYYY").format(
                                      "ddd, MMM DD"
                                    )}
                              </span>
                            </label>
                          </div>
                        );
                      }
                    )}
                    <div className="inline-checks flex-1">
                      <DatePicker
                        renderExtraFooter={(e) => renderExtraFooter}
                        inputReadOnly
                        mode={"date"}
                        className={
                          displayPreferredStartDate ? "active" : "box_opacity"
                        }
                        placeholder={"Select another date"}
                        format={"ddd, MMM DD"}
                        value={displayPreferredStartDate}
                        onChange={onSelectDisplayPreferredStartDate}
                        disabledDate={(current) => {
                          return current && current <= moment().add(3, "days");
                        }}
                      />
                    </div>
                  </div>
                  {errors?.["preferredStartDate"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["preferredStartDate"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <AddReferenceField
                  type="renovationAndConstruction"
                  screen="soPopUp"
                />
              </>
            ) : null}
            <div className="frow md-label">
              <label style={{ fontWeight: "bold" }}>
                Select Service Address
              </label>
              <Select
                showArrow={true}
                style={{ width: "100%" }}
                name="serviceAddress"
                placeholder="Select"
                virtual={false}
                value={temp_address?.uuid}
                onChange={(value) =>
                  onTextChange({
                    target: { name: "serviceAddress", value: value },
                  })
                }
              >
                {addressOption}
              </Select>
              {errors?.[`serviceAddress`] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.[`serviceAddress`]}
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="frow md-label">
              <label>
                Select Pool<sup>*</sup>
              </label>
              <Select
                showArrow={true}
                style={{ width: "100%" }}
                name="pool"
                placeholder="Select"
                virtual={false}
                value={pool}
                onChange={(value) =>
                  onTextChange({ target: { name: "pool", value: value } })
                }
              >
                {poolOption}
              </Select>
            </div>
            <div className="frow md-label">
              <label>Pool Name</label>
              <Input
                type="text"
                id="poolName"
                name="poolName"
                placeholder="Pool Name"
                value={poolName.value}
                onChange={onTextChange}
              />
            </div>
            <div className="frow md-label">
              <label>Pool size in gallons</label>
              {props?.customer?.uuid ? (
                <Input
                  type="text"
                  id="poolSize"
                  name="poolSize"
                  //placeholder="20000"
                  disabled
                  value={poolSize}
                />
              ) : (
                <Input
                  type="text"
                  id="poolSize"
                  name="poolSize"
                  // placeholder="20000"
                  value={poolSize}
                  onChange={onTextChange}
                />
              )}
            </div>
            {!isRenovationOrConstruction ? (
              <>
                <div className="frow">
                  <label>Pool type</label>
                  <div className="oplist">
                    <Select
                      dropdownClassName="sm-filter"
                      showArrow
                      name={"poolType"}
                      virtual={false}
                      style={{ width: "100%" }}
                      value={poolType}
                      placeholder={"Select Pool Type"}
                      disabled={disablePoolTypeSelect}
                      onChange={(e) =>
                        onTextChange(
                          {
                            target: {
                              name: "poolType",
                            },
                          },
                          e
                        )
                      }
                    >
                      {map(serviceDetails?.pool_types, (item, index) => (
                        <Option value={`${item?.uuid}`} key={item?.uuid}>
                          {convertToTitleCase(item?.name)}
                        </Option>
                      ))}
                    </Select>

                    {errors?.[`poolType`] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.[`poolType`]}
                      </label>
                    ) : (
                      ""
                    )}
                    {poolType === "179a4155-9268-3633-ad49-0a7e14af60a2" && (
                      <div className="from-group w-100">
                        <div className="fitem">
                          <input
                            type="text"
                            id="other_pool_type"
                            name="otherPoolType"
                            placeholder="Additional other pool type"
                            value={otherPoolType}
                            onChange={(e) => {
                              setOtherPoolType(e.target.value);
                              setServiceInfo({
                                value: e.target.value,
                                key: "otherPoolType",
                                name: e.target.name,
                              });
                              setOtherPoolTypeData({
                                value: e.target.value,
                                key: "otherPoolType",
                                name: e.target.name,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {errors?.[`otherPoolType`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`otherPoolType`]}
                    </label>
                  ) : (
                    ""
                  )}
                  {errors?.[`otherPoolType`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`otherPoolType`]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <div>
              <label>
                Address<sup>*</sup>
              </label>
              <AddressField
                handleAddressChange={handleAddressChange}
                onAddressChanged={onAddressChange}
                onAddressChange={onAddressChange}
                errors={errors}
                data={temp_address}
                nolabel
              />
            </div>

            <div className="field-group">
              <label>Preferred Technician</label>
              <Select
                showSearch
                allowClear={true}
                name="technician"
                placeholder="Not assigned"
                optionFilterProp="children"
                onChange={(e) => {
                  onTextChange(
                    {
                      target: {
                        name: "technician",
                      },
                    },
                    e
                  );
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={state?.selectedTechnician || undefined}
                //disabled={transection_type === "View"}
              >
                {technicians?.map((technician, index) => {
                  return (
                    <Option
                      key={index}
                      value={`${technician?.user?.first_name} ${technician?.user?.last_name}`}
                    >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                  );
                })}
              </Select>
              {errors?.["technician_uuid"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.["technician_uuid"]}
                </label>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col xxl={12} xl={12} md={12} xs={24} sm={24}>
            <ServiceSummary
              popClass={"border-box-pop"}
              serviceInfo={serviceInfo}
              priceDetail={priceDetail}
              data={data}
              setPriceDetail={setPriceDetail}
            />
            <hr style={{ margin: "20px 0" }} />
            <div className="frow" style={{ margin: "0px" }}>
              <label>
                <b>
                  Terms of Service<sup>*</sup>
                </b>
              </label>
              <br />
              <span>
                By signing up, I grant my permission for America's Swimming Pool
                Co. to access my property and agree to the full
                <a> Terms of Service</a> and
                <a href="/#"> Privacy Policy.</a>
              </span>
              <div className="fitem-check mt-10">
                <input
                  id="isAgreed"
                  name="isAgreed"
                  checked={isAgreed}
                  onChange={(e) => onTextChange(e, "bool")}
                  type="checkbox"
                  className="fitem-ck-input"
                />
                <label htmlFor="isAgreed" className="fitem-ck-txt">
                  Customer verbally agreed
                </label>
              </div>
              {errors?.["isAgreed"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.["isAgreed"]}
                </label>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return render();
};
export default MaintenancePop;