import { Modal, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  verifyCustomerToDelete,
  deleteCustomer,
} from "../../Action/Customer/index";

const DeleteCustomer = ({ record = {} }, props) => {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);

  const onDeleteCustomer = async () => {
    //Check if the customer has any outstanding invoices
    const response = await verifyCustomerToDelete(record.uuid);

    if (response === "Customer UUID not valid.") {
      message.error(response);
      return;
    }

    const ready_to_delete = response === "True" ? false : true;
    //if not, delete it
    if (ready_to_delete) {
      setDeleteModal(true);
      
    } else {
      //If yes is not possible to delete it
      setMessageModal(true);
    }
  };

  const goDeleteCustomer = async () => {
    setDeleteModal(false);
    //Request to delete customer
    const response = await deleteCustomer(record.uuid);
    
    if (response === "Customer marked as deleted.") {
      message.success("Customer deleted correctly.");
      if(props?.source === "customers-lead-list" && record.is_active===false){
      navigate('/customer-lead-list', { state: {currentTab: 'inactive_customer' }});
      }else if (record.client_type==="residential" && record.is_active===false) {
        navigate(`/residential`, { state: { currentTab: 2 } });
      } else {
        if(record.client_type!=="residential" && record.is_active===false){
          navigate(`/commercial/lead-list`, { state: { currentTab: 2 } });
        }
      }
      if(props?.source === "customers-lead-list" && record.is_active===true){
        navigate('/customer-lead-list', { state: {currentTab: 'customer' }});
      }else if (record.client_type==="residential" && record.is_active===true) {
        navigate(`/residential`, { state: { currentTab: 1 } });
      } else {
        if(record.client_type!=="residential" && record.is_active===true){
          navigate(`/commercial/lead-list`, { state: { currentTab: 1 } });
        }
      }
      //const pathname = window.location.pathname.split("/")[1];
      //navigate(`/${pathname}`);
    } else {
      message.error("Something went wrong, please try again.");
    }
  };

  const returnPage = async() =>{
    setMessageModal(false)
    if(props?.source === "customers-lead-list" && record.is_active===false){
      navigate('/customer-lead-list', { state: {currentTab: 'inactive_customer' }});
    }else if (record.client_type==="residential" && record.is_active===false) {
      navigate(`/residential`, { state: { currentTab: 2 } });
    } else {
      if(record.client_type!=="residential" && record.is_active===false){
        navigate(`/commercial/lead-list`, { state: { currentTab: 2 } });
      }
    }
    if(props?.source === "customers-lead-list" && record.is_active===true){
      navigate('/customer-lead-list', { state: {currentTab: 'customer' }});
    }else if (record.client_type==="residential" && record.is_active===true) {
      navigate(`/residential`, { state: { currentTab: 1 } });
    } else {
      if(record.client_type!=="residential" && record.is_active===true){
        navigate(`/commercial/lead-list`, { state: { currentTab: 1 } });
      }
    }
  }

  return (
    <>
      <button
        className="btn btn-warning view-all-btn"
        onClick={(e) => {
          onDeleteCustomer();
        }}
      >
        Delete Customer
      </button>

      <Modal
        open={deleteModal}
        okText={"Delete"}
        mask={true}
        onOk={goDeleteCustomer}
        onCancel={() => {
          setDeleteModal(false);
        }}
        cancelText={"Cancel"}
        className="delete_customer_modal"
        width={400}
      >
        <div>
          <span>
            <b>
              Are you sure you want to delete this customer?
              <br /> All customer details will be permanently deleted.
            </b>
          </span>
        </div>
      </Modal>

      <Modal
        open={messageModal}
        mask={true}
        onCancel={() => {
          setMessageModal(false);
        }}
        cancelText={"Cancel"}
        className="delete_customer_modal"
        width={400}
        footer={[
          <button
            key="ok"
            className="btn btn-warning"
            onClick={() => 
              returnPage()
            }
          >
            Ok
          </button>,
        ]}
      >
        <div>
          <span>
            <b>
              Please resolve the outstanding invoices before deleting the
              customer.
            </b>
          </span>
        </div>
      </Modal>
    </>
  );
};

export default DeleteCustomer;
