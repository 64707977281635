import React,{useState ,useRef} from "react";
import { Table, Row, Col } from "antd";
import moment from "moment-timezone";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";
import Loader from "../../../Components/Loader";
import { Modal } from "antd";

const ChemicalTable = (props) => {
  const {
    reportData,
    current,
    total,
    setCurrent,
    setPageSize,
    pageSize,
    setSorter,
    reportMainType,
    reportSubType,
    loading,
  } = useReport();

  const [woModalOpen,setWOModalOpen] = useState(false);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [selectedRecord,setSelectedRecord] = useState(null);

  const handleTableChange = (pagination, filters, sorter, extra) =>
    extra.action === "sort"
      ? sorter.order !== undefined
        ? setSorter(sorter)
        : setSorter(null)
      : null;
  const setUserPageLimit = (custom_limit) => {
    setCurrent(1);
    setPageSize(custom_limit);
  }
  const handelOpenForm = (record) => {
    setWOModalOpen(true);
    setSelectedRecord(record);
  }
  const handleWOSave = (saveWOResponse) => {
    setWOModalOpen(saveWOResponse);
  };
  /*const handleChemicalType = () => {
    const total = reportData.reduce((result, item) => {
      return (result += item.total_jobs);
    }, 0);
    if (reportType === "detail" && total === 0) {
      return true;
    }
    if (total > 0) return false;
    return true;
  };*/
  

  return (
    <>
      <Row>
      {loading && <Loader />}
        {(reportSubType === "technician" && reportMainType !== "detail")?

            <Col span={24} className={"chemical-summary-table-container"}>
            <Table
              dataSource={
                reportMainType == "detail"
                  ? reportData.map((item, idx) => ({ key: idx, ...item }))
                  : reportData.map((item, idx) => ({
                      ...item,
                      key: idx,
                      dates_serviced: item.dates_serviced.map((date) =>
                        moment(date).format("YYYY/MM/DD")
                      ),
                    }))
              }
              columns={
                 DataTable.ChemicalSummaryGroupedByTechnician(this)
              }
              bordered
              className="table hscroll"
              onChange={handleTableChange}
              pagination={{
                showSizeChanger: false,
                pageSize: pageSize,
                ordering: "",
                current,
                total,
                onChange(page, pageSize) {
                  setCurrent(page);
                  setPageSize(pageSize);
                },
              }}
            />
              <ul className="pagination_page_size">
              <li>
                <span>Show:</span>
              </li>
              <li>
                <span
                  onClick={() => { setUserPageLimit('15') }}
                  className={pageSize === '15' || pageSize === 15  ? 'active' : ''}
                >15</span>
              </li>
              <li>
                <span
                  className={pageSize === '30' || pageSize === 30 ? 'active' : ''}
                  onClick={() => { setUserPageLimit('30') }}>30</span>
              </li>
              <li>
                <span
                  className={pageSize === '60' || pageSize === 60 ? 'active' : ''}
                  onClick={() => { setUserPageLimit('60') }}>60</span>
              </li>
            </ul>
            </Col>
        :
          <Col span={24} className={"chemical-table-container"}>
            <Table
              dataSource={
                reportMainType == "detail"
                  ? reportData.map((item, idx) => ({ key: idx, ...item }))
                  : reportData.map((item, idx) => ({
                      ...item,
                      key: idx,
                      dates_serviced: item.dates_serviced.map((date) =>
                        moment(date).format("YYYY/MM/DD")
                      ),
                    }))
              }
              columns={
                reportMainType == "detail"
                  ? DataTable.ChemicalReport(this,handelOpenForm)
                  :DataTable.ChemicalSummaryGroupedByCustomer(this)
              }
              bordered
              className="table hscroll"
              onChange={handleTableChange}
              pagination={{
                showSizeChanger: false,
                pageSize: pageSize,
                ordering: "",
                current,
                total,
                onChange(page, pageSize) {
                  setCurrent(page);
                  setPageSize(pageSize);
                },
              }}
            />
            <ul className="pagination_page_size">
                  <li>
                    <span>Show:</span>
                  </li>
                  <li>
                    <span
                      onClick={() => { setUserPageLimit('15') }}
                      className={pageSize === '15' || pageSize === 15  ? 'active' : ''}
                    >15</span>
                  </li>
                  <li>
                    <span
                      className={pageSize === '30' || pageSize === 30 ? 'active' : ''}
                      onClick={() => { setUserPageLimit('30') }}>30</span>
                  </li>
                  <li>
                    <span
                      className={pageSize === '60' || pageSize === 60 ? 'active' : ''}
                      onClick={() => { setUserPageLimit('60') }}>60</span>
                  </li>
                </ul>
          </Col>
    }
      </Row>
      {/*
      title={`Work Order Form ${selectedEvent?.original?.title}`}
      */
      }
      <Modal
      title={"Work Order Form"+" "+selectedRecord?.work_order_name}
      open={woModalOpen}
      onOk={() => setWOModalOpen(false)}
      onCancel={() => setWOModalOpen(false)}
      destroyOnClose
      className="schedule_wo_modal"
      style={{
        top: 20,
      }}
      footer={null}
      onResponse={handleWOSave}
      maskClosable={false}
    >
      <iframe
        src={selectedRecord?.form_url+selectedRecord?.uuid}
        width={"100%"}
        height={windowSize.current[1] * 0.8}
        title="testa"
      />
    </Modal>
    </>
  );
};

export default ChemicalTable;
