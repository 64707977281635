import React from "react";
import { Table, Row, Col } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";
import Loader from "../../../Components/Loader";
const CaptureLeadTable = () => {
  const {
    reportData,
    current,
    total,
    query,
    setCurrent,
    setPageSize,
    pageSize,
    setSorter,
    leadReportStageType,
    leadReportclientType,
    loading,
  } = useReport();

  const handleTableChange = (pagination, filters, sorter, extra) =>
    extra.action === "sort"
      ? sorter.order !== undefined
        ? setSorter(sorter)
        : setSorter(null)
      : null;

  const processData = reportData.map((item, idx) =>{

    const process_outreachValue = (value)=>{
      if(value === "website")
        return "Website";
      if(value === "call_center")
        return "Call Center";
      if(value === "franchise_owner" || value === "operations_mgr")
        return "Franchise";
      if(value === "email")
        return "Email";
      return value;
    };
    if(leadReportStageType === "Customer")
    return {
            ...item,
            lead_created: moment(item.created_at).format("YYYY-MM-DD"),
            customer_address: item.address? item.address.map((item) => `${item.formatted_address}`):"",
            service_address: item.service_address? item.service_address.map((item) => `${item.formatted_address}`)
                                                                        .filter((c, index) => {
                                                                          return item.service_address.map((item) => `${item.formatted_address}`).indexOf(c) === index;
                                                                      }) :"",
            phone: item.phone_numbers.map(
              (item) =>
                `+1 ${
                  item.phone?.substr(0, 3) +
                  " " +
                  item.phone?.substr(3, 3) +
                  " " +
                  item.phone?.substr(6)
                }`
            ),
            email: item.email,
            last_contacted: item.lead_status_logs?item.lead_status_logs.at(-1)?moment(item.lead_status_logs.at(-1).created_at).format(
              "YYYY-MM-DD"
            ):" ":" ",
            lead_days: moment().diff(moment(item.created_at), "days"),
            key: idx,
            stage: leadReportStageType === "Lead"?"Lead" :"Customer",
            status: item.status? item.status.charAt(0).toUpperCase()+ item.status.slice(1):"",
            lead_stage: item.lead_stage? item.lead_stage.charAt(0).toUpperCase()+ item.lead_stage.slice(1):"",
            joined_date: item.date_joined? item.date_joined.split('T')[0]:"",
            last_service_date: item.last_service_date? item.last_service_date.split('T')[0] === "1960-07-15"? " ":item.last_service_date.split('T')[0] :"",
          };
      else{
        return {
          ...item,
        };
      }
   } );

   const setUserPageLimit = (custom_limit) => {
    setCurrent(1);
    setPageSize(custom_limit);
  }
  
  return (
    <>
      <Row>
        {loading && <Loader />}
        {console.log(leadReportStageType)}
          {
            (leadReportStageType === undefined || leadReportStageType === "Lead")?
            <Col span={24} className={"lead-table-container"}>            
                <Table
                dataSource={processData}
                columns={DataTable.LeadReport(this)}
                className="table hscroll"
                onChange={handleTableChange}                
                pagination={{
                  showSizeChanger: false,
                  pageSize: pageSize,
                  ordering: "",
                  current,
                  total,
                  onChange(page, pageSize) {
                    setCurrent(page);
                    setPageSize(pageSize);
                  },
                }}
                />
              </Col>
            :(leadReportStageType === "Customer")?
            <Col span={24} className={"customer-table-container"}>
                <Table
                dataSource={processData}
                columns={DataTable.CustomersReport(this)}
                className="table hscroll"
                onChange={handleTableChange}              
                pagination={{
                  showSizeChanger: false,
                  pageSize: pageSize,
                  ordering: "",
                  current,
                  total,
                  onChange(page, pageSize) {
                    setCurrent(page);
                    setPageSize(pageSize);
                  },
                }}
              />
            </Col>
          :
          <></>
          }
          {(leadReportStageType === undefined || leadReportStageType === "Lead" || leadReportStageType === "Customer")?
            <ul className="pagination_page_size">
                    <li>
                      <span>Show:</span>
                    </li>
                    <li>
                      <span
                        onClick={() => { setUserPageLimit('15') }}
                        className={pageSize === '15' || pageSize === 15  ? 'active' : ''}
                      >15</span>
                    </li>
                    <li>
                      <span
                        className={pageSize === '30' || pageSize === 30 ? 'active' : ''}
                        onClick={() => { setUserPageLimit('30') }}>30</span>
                    </li>
                    <li>
                      <span
                        className={pageSize === '60' || pageSize === 60 ? 'active' : ''}
                        onClick={() => { setUserPageLimit('60') }}>60</span>
                    </li>
                  </ul>
            :
            <></>
        }

       
      </Row>
    </>
  );
};

export default CaptureLeadTable;
