import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import Header from "../../../Components/Header";
import HeaderMobile from "../../../Components/HeaderMobile";
import Sidebar from "../../../Components/Sidebar";
import SidebarResponsive from "../../../Components/SidebarResponsive";
import Breadcrumb from "../../../Components/Breadcrumb";
import Loader from "../../../Components/Loader";
import { MoreOutlined } from "@ant-design/icons";
import _, { map } from "lodash";
import moment from "moment-timezone";
import AccountOverview from "./AccountOverview";
import AccountDetails from "./AccountDetails";
import Services from "./Services";
import Tickets from "./Tickets";
import PoolCharacteristics from "./PoolCharacteristics";
import Invoices from "./Invoices";
import { getOverdueOpenLastInvoices } from '../../../Action/Invoice';
import { formatToCurrency } from "../../../Utils/formatToCurrency";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux/es/exports';
import { add } from '../../../Store/invoiceSummarySlice';
import { addCustomer } from '../../../Store/customerSlice';
import { getCustomer } from '../../../Action/Customer';
import  PulseNotification from './PulseNotification';
import AddNewInvoice from '../Invoices/AddNewInvoice';
import GlobalSearch from '../../../Components/GlobalSearch';
import ProposalPage from '../../Common/ProposalTab'
const tabs = [
  "Account Overview",
  "Account Details",
  "Services",
  "Proposal",
  "Pool Characteristics",
  "Invoices",
  // "Customer Tickets",
  "PulseM Notification",
];

const Detail = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [subCurrentTab, setSubCurrentTab] = useState(0);
  const [reviewServiceOrder, setreviewServiceOrder] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [dateNextService, setDataNextService] = useState();
  const [dateLastService, setDataLastService] = useState();

  const onMenuButtonClick = () => {
    setOpenMenu(!openMenu);
  };
  // const [invoiceSummary,setInvoiceSummary] = useState({});
  const invoiceSummary = useSelector((state)=>state.invoiceSummary);
  const customer = useSelector((state)=>state.customer);
  
  const NextServiceDateMethod = (e) => {
    if(e) {
    setDataNextService(e?.date ? moment(e?.date).format('MMM DD, YYYY') : null);
    setDataLastService(e?.lastServiceDate ? moment(e?.lastServiceDate).format('MMM DD, YYYY') : null);
    }
  }

  useEffect(() => {
    setDataLastService(state?.record?.last_service ? moment(state?.record?.last_service).format('MMM DD, YYYY') : null);
  },[])

  useEffect(()=>{
    const getOverdueOpen = async () => {
      const data = await getOverdueOpenLastInvoices({user__uuid:state?.record?.uuid});
      // setInvoiceSummary(useSelector((state)=>state.invoiceSummary?.[0]));
      dispatch(add(data));
       setCurrentTab(state?.record?.selectedTab)
    }
    getOverdueOpen();

    //customer detail
    const getCustomerInfo = async () => {
      const data = await getCustomer(state?.record?.uuid);
      dispatch(addCustomer(data));
      window.localStorage.setItem("customerUUID", data?.uuid);
    }
    getCustomerInfo();
  },[])

  useEffect(() => {
    if (customer.uuid !== state?.record?.uuid) {
      const getOverdueOpen = async () => {
        const data = await getOverdueOpenLastInvoices({user__uuid:state?.record?.uuid});
        dispatch(add(data));
         setCurrentTab(state?.record?.selectedTab)
      }
      getOverdueOpen();

      const getCustomerInfo = async () => {
        const data = await getCustomer(state?.record?.uuid);
        dispatch(addCustomer(data));
        window.localStorage.setItem("customerUUID", data?.uuid);
      }
      getCustomerInfo();
    }
  }, [state.record, state?.record?.uuid]);

    // tab change
  const tabChange = (e) => {
    setCurrentTab(e);
      // this.setState({
      //   currentTab: e,
      //   showSidebar: true,
  
      // });
      // const id = this.props.history.location.state.query.id;
      // this.props.getMessagesCount({ user__uuid: id });
    };

  //render Tab
  const renderTab = () => {
    switch (currentTab) {
      case 0:
        return <AccountOverview customer={customer} tabChange={tabChange} record={state?.record} source={state?.source}/>
      case 1:
        return <AccountDetails customer={customer} record={state?.record} source={state?.source}/>
      case 2:
        return <Services nextService={NextServiceDateMethod} customer={state?.record} getWorkOrderFilter={props.getWorkOrderFilter} reviewServiceOrder={reviewServiceOrder} currentTab={subCurrentTab} setCurrentTab={setSubCurrentTab}  tabChange={tabChange} />;
      case 3:
        return <ProposalPage customer={state?.record} tabChange={tabChange} />;  
      case 4:
        return <PoolCharacteristics customer={state?.record} />;
      case 5:
        return <Invoices tabChange={tabChange} customer={customer} record={state?.record} isRetailSale={state?.record?.isRetailSale} currentTab={subCurrentTab} setCurrentTab={setSubCurrentTab} />;
      case 6:
      //   return <Tickets customer={state?.record}/>;
      // case 7:
          return <PulseNotification customer={customer}/>;
      default:
        return <AccountOverview tabChange={tabChange} record={state?.record} source={state?.source}/>;
    }
  };

function monthDiff(d1, d2) {
    var months;
    months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    months -= new Date(d1).getMonth();
    months += new Date(d2).getMonth();
    return months <= 0 ? 0 : months;
}

function getWords(monthCount) {
  function getPlural(number, word) {
      return number === 1 && word.one || word.other;
  }

const getDaysInMonth = function(month,year) {
   return new Date(year, month, 0).getDate();
  };


  function getAge(date_1, date_2)
  {
    
  //convert to UTC
  var date2_UTC = new Date(Date.UTC(date_2.getUTCFullYear(), date_2.getUTCMonth(), date_2.getUTCDate()));
  var date1_UTC = new Date(Date.UTC(date_1.getUTCFullYear(), date_1.getUTCMonth(), date_1.getUTCDate()));
  
  
  var yAppendix, mAppendix, dAppendix;
  
  
  //--------------------------------------------------------------
  var days = date2_UTC.getDate() - date1_UTC.getDate();
  if (days < 0)
  {
  
      date2_UTC.setMonth(date2_UTC.getMonth() - 1);
      days += DaysInMonth(date2_UTC);
  }
  //--------------------------------------------------------------
  var months = date2_UTC.getMonth() - date1_UTC.getMonth();
  if (months < 0)
  {
      date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
      months += 12;
  }
  //--------------------------------------------------------------
  var years = date2_UTC.getFullYear() - date1_UTC.getFullYear();
  
  
  
  
  if (years > 1) yAppendix = " years";
  else yAppendix = " year";
  if (months > 1) mAppendix = " months";
  else mAppendix = " month";
  if (days > 1) dAppendix = " days";
  else dAppendix = " day";
  
  
  return years + yAppendix + ", " + months + mAppendix + ", and " + days + dAppendix + " old.";
  }
  
  
  function DaysInMonth(date2_UTC)
  {
  var monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
  var monthEnd = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth() + 1, 1);
  var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
  return monthLength;
  }

  var months = { one: 'm', other: 'm' },years = { one: 'y', other: 'y' },days = { one: 'd', other: 'd' }, m = monthCount % 12,
      y = Math.floor(monthCount / 12),
      d = Math.floor(monthCount/getDaysInMonth(1, 2012)),
      result = [];

  result.push(y + '' + getPlural(y, years));
  result.push(m + '' + getPlural(m, months));
  result.push(d + '' + getPlural(d, days));
  return result.join(' ');
}



function yearDiff(dt1, dt2) 
 {
  var diffYear =(new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
   diffYear /= (60 * 60 * 24);
  return Math.abs(Math.round(diffYear/365.25));
 }


 function getAge(date_1, date_2)
{
//convert to UTC
var date2_UTC = new Date(Date.UTC(new Date(date_2).getUTCFullYear(), new Date(date_2).getUTCMonth(), new Date(date_2).getUTCDate()));
var date1_UTC = new Date(Date.UTC(new Date(date_1).getUTCFullYear(), new Date(date_1).getUTCMonth(), new Date(date_1).getUTCDate()));
var yAppendix, mAppendix, dAppendix;
//--------------------------------------------------------------
var days = date2_UTC.getDate() - date1_UTC.getDate();
if (days < 0)
{
    date2_UTC.setMonth(date2_UTC.getMonth() - 1);
    days += DaysInMonth(date2_UTC);
}
//--------------------------------------------------------------
var months = date2_UTC.getMonth() - date1_UTC.getMonth();
if (months < 0)
{
    date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
    months += 12;
}
//--------------------------------------------------------------
var years = date2_UTC.getFullYear() - date1_UTC.getFullYear();
if (years > 1) yAppendix = "y";
else yAppendix = "y";
if (months > 1) mAppendix = "m";
else mAppendix = "m";
if (days > 1) dAppendix = "d";
else dAppendix = "d";

if(days===0){
  return 0
}
return years + yAppendix + " " + months + mAppendix + " " + Math.round(days) + dAppendix;
}

function DaysInMonth(date2_UTC)
{
var monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
var monthEnd = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth() + 1, 1);
var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
return monthLength;
}

  // convert date time from utc to local time
const UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
}

  // render basic detail
  const renderBasicDetails = ({status}) => {
      let a = moment(state?.record?.last_service), b = moment();
      let c = moment(state?.record?.next_follow_up)
      let d = moment(state?.record?.created_at)
      let nextServiceDate = state?.record?.next_follow_up ? c.format('MMM DD, YYYY') : ''
      let lastServiceDate = state?.record?.last_service ? a.format('MMM DD, YYYY') : ''

      const local_time = UTCToLocal(state?.record?.created_at)

      const basicDetail = [
        { key: 'Account #', value: state?.record?.account_number },
        { key: 'Franchise', value: state?.record?.field_office?.name},
        { key: 'Joined', value: getAge(d,b) == 0 ? local_time.fromNow(true):getAge(d,b) },
        { key: 'Last Service', value: dateLastService ? dateLastService : lastServiceDate},
        { key: 'Next Service', value: dateNextService ? dateNextService : nextServiceDate},
        { key: 'Open Balance', value: `$ ${invoiceSummary?.total_open_balance ? formatToCurrency(invoiceSummary?.total_open_balance) : '0.00'}`},
      ]
  
      return map(basicDetail, (item, index) =>
        <div key={`basic${index}`} className={"dcell"}>
          <span><b>{item?.key}</b></span>
          <span>{item?.value}</span>
        </div>
      )
    };

  return (
    <div id="wrapper">
      <div className="content-area">
        {/*<Header placeholder={'Acc Number, Customer Name, Address(Billing), Phone Number, Email Address'} />*/}
        <GlobalSearch/>
        <Sidebar sideBarBackgroundColor='#005d89'/>
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenuButtonClick}
        />
        <div className='container'>
        {loader && <Loader/>}
        <div className="filter_wrapper">
          <ul className="breadcrumb">
            <li className={"cursor_pointer"}><span onClick={() =>{
              if(state?.source === "customers-lead-list")
                window.location.assign('/customer-lead-list');
              else
                window.location.assign('/residential');
              }
            }>Customer</span></li>
            <li><span>{customer?.first_name}</span></li>
          </ul>
        </div>        
        <div className='residential-wrapper'>
          <div className='res-head pb-0'>
            <div className='flex ai-center mb-20'>
            <div className="user-info">
              <h3>{customer?.first_name} {customer?.last_name}</h3>
              <span className={`status info-status drk`}>
              {state?.record?.status}
              </span>
            </div>
            <div className="user-detail">
            {renderBasicDetails('active')}
              {/* {customer?.status?.toLowerCase() !== 'pending' && */}
              {/* <div className="position-relative status-dropdown">
                <span className="status-d-arrow">
                  <MoreOutlined />
                </span>
                <ul className="status-dropdownmenu">
                  <li><a href="#" onClick={(e) => { e.preventDefault(); this.deActivateCustomerConfirm();}} >Deactivate</a></li>
                </ul>              
              </div> */}
            </div>
            </div>
          <ul className="tabs dark">
              {
                map(tabs, (item, index) =>
                  <li key={`tab${index}`} className={index===currentTab?'active':''} onClick={(e) =>{ setCurrentTab(index); setSubCurrentTab(0); } }>
                    <span className="flex ai-center">
                      {item}
                      {item.toLowerCase()?.includes('tickets') && <div className="count">0</div>}
                    </span>
                  </li>
                )
              }
            </ul>
          </div>
          <div className="res-body">
            {renderTab()}
          </div>
        </div>
        </div>      
      </div>
    </div>     
  )
}

export default Detail;

