import React, { useEffect } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Col } from "antd";
import {addReferenceNumber} from "../../../../Store/serviceOrderDetailDataSlice";

const AddReferenceField = (props) => {
    const dispatch = useDispatch();
    const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);
    const handleReferenceNumberChange = (event) => {
        dispatch(addReferenceNumber(event.target.value));
    };
    useEffect(() => {
    }, []);
    return (
        <Col xl = {24} xs = {24}>
            <div className="serviceDurationInput">
                <h4 className={props.screen === "soPopUp" ? "" : "serviceDuration"}>
                    Reference Number
                </h4>
                <input
                    defaultValue={serviceOrderNewFieldsValue?.editDurationField === true ? serviceOrderNewFieldsValue?.referenceNumber : ""}
                    type="text"
                    maxLength={50}
                    placeholder="Reference Number"
                    name="referenceNumber"
                    onChange={(e) => handleReferenceNumberChange(e)}
                />
            </div>
        </Col>
    );
};
export default AddReferenceField;