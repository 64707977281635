import React from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "antd";
import PDFIcon from "../../../Assets/images/pdf-icon.png";
import CSVIcon from "../../../Assets/images/csv-icon.png";
import { useReport } from "../../Hooks/Report.Hook";
import ReportsData from "../../Common/ReportList.Data";
import { getPDFReport, getCSVReport } from "../../Common/getObjectReport";
import { withJSONColumnOrder } from "../../Common/getOrderingObject";
import moment from "moment-timezone";

const TechnicianNotesTopMenu = () => {
  const { id } = useParams();
  const { franchise, superSearch, sorter,leadReportclientType, serviceDatePeriod ,technician,serviceType,isMultiLocation} =
    useReport();
  const exported_file_name = id;
  const report_type = "tech_notes";
  const getFilters = () => {
    return {
      service_order__field_office__uuid:
        franchise.value === "all" ? undefined : franchise.value,
      ...withJSONColumnOrder(sorter),
      search: superSearch !== "" ? superSearch : undefined,
      start_date: !_.isEmpty(serviceDatePeriod)
        ? serviceDatePeriod.start_date
        : moment().format("YYYY-MM-DD"),
      end_date: !_.isEmpty(serviceDatePeriod)
        ? serviceDatePeriod.end_date
        : moment().format("YYYY-MM-DD"),
      technician: technician.toString(),
      account_type: leadReportclientType,
      service_type: serviceType === "all" ? undefined : serviceType.toLowerCase().replace(/ /g, "_"),
      isMultiLocation: isMultiLocation
    };
  };

  return (
      <div className="filter_wrapper acc-wrap p-0 mb-20">

        <div class="report-long-topmenu_title">
          <h2>{ReportsData.find((item) => item.id === id)?.title}</h2>
        </div>
      
      <div className="form-group flex ai-center w-100 fltr-txt" style={{justifyContent: "left"}}>
        <Tooltip title="Download CSV" color={"#005d89"}>
          <button
            className="btn btn-warning-outline"
            onClick={() => getCSVReport({report_type, filters: getFilters() ,exported_file_name: exported_file_name })}
          >
            <img
              src={CSVIcon}
              alt={"Download CSV"}
              style={{ maxWidth: "25px" }}
            />
          </button>
        </Tooltip>
          <Tooltip title="Download PDF" color={"#005d89"}>
            <button
              className="btn btn-warning"
              onClick={() => getPDFReport({report_type, filters: getFilters() })}
            >
              <img
                src={PDFIcon}
                alt={"Download PDF"}
                style={{ maxWidth: "21px" }}
              />
            </button>
          </Tooltip>
      </div>
    </div>
  );
};

export default TechnicianNotesTopMenu;
