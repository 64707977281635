import React, { useEffect, useState } from "react";
import { Select } from "antd";

const OutreachSelector = ({
  handleChange = () => {},
  allValue = true,
  defaultValue,
}) => {

  return (
    <>
      <Select
        value={defaultValue}
        onChange={(value, options) => handleChange(value, options)}
        placeholder={"Select an option"}
      >
        {allValue ? (
          <Select.Option key={"All"} value={"all"} label={"All"}>
            {"All"}
          </Select.Option>
        ) : (
          <></>
        )}
          <Select.Option key="call_center" value="call_center" label="Call Center">
            Call Center
          </Select.Option>
          <Select.Option key="Franchise" value="franchise_owner" label="Franchise">
            Franchise
          </Select.Option>
          <Select.Option key="website" value="website" label="Website">
            Website 
          </Select.Option>
      </Select>
    </>
  );
};

export default OutreachSelector;
