import React from "react";
import { useState,useEffect,useRef } from "react";
import { Divider, Button, Table, Radio, message } from "antd";
import { bulkreassigntechfinalcall,getBulkServiceOrderTechnician } from "../../Action/BulkReassign";
import DataTable from "../BulkReassign/Datatable";
import { isEmpty } from "lodash";

const BulkData = (props) => {
  const techfromname = props.bulkreassigncheck.techfromname;
  const bulkReassignClicked = props.bulkReassignClicked
  const selectedServiceType = props.selectedServiceType
  const selectedFranchise = props.selectedFranchise
  const order_table_data = [props.ordertabledata];
  var allorders = [];
  allorders.push(order_table_data);
  const checks_data = props.bulkreassigncheck;
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedserviceorder, setselectedserviceorder] = useState([]);
  const selectedSorter = useRef('');
  // const selectserviceorders={
  //   selectSO:selectedserviceorder
  // };
  //const [bulktechFrom, setBulkTechFrom] = useState(props.bulkreassigncheck.reassign_from);
  const bulktechFrom = props.bulkreassigncheck.reassign_from;
  const [sortetbl,setsorttbl]=useState('');
  const [tblbulkData, setTblBulkData] = useState(props.ordertabledata);
  const [active_pagination, setActivePagination] = useState('15');

  const onclickonbulkreassign = async (e) => {
    //console.log(selectedserviceorder);
    if (selectedserviceorder.length > 0) {
      var service_orders_ids = selectedserviceorder.map((item) => {
        return item.uuid;
      });
    } 
    else {
      message.error("Please Select The Service Orders");
    }
    //console.log(checks_data)
    const params = {
      uuid: service_orders_ids,
      assign_to: checks_data.reassign_to,
    };

    //console.log(params);
    let valreassignto = props.validationreassignto(e, params);
    console.log(valreassignto);
    if (valreassignto) {
      // if (
      //   checks_data.reassign_from != "" &&
      //   checks_data.reassign_to != "" &&
      //   selectedserviceorder != ""
      // ) {
      //   let result = await bulkreassigntechfinalcall(params);
      //   console.log(result);
      //   let data = result;
      //   setTimeout(function () {
      //     window.location.reload(true);
      //   }, 500);
      //   message.success("Service Orders Reassigned");
      // }
    }
    console.log(selectedserviceorder);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setselectedserviceorder(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  
  const getbulktchtbldata=async()=>{
   console.log(selectedSorter.current);
    let params={
      preferred_technician_uuid: bulktechFrom,
      ordering:selectedSorter.current,
    }
    let franchiseUUID = '';
    if(selectedFranchise !== 'All'){
      franchiseUUID = selectedFranchise
    }
    else{
      franchiseUUID = ''
    }
    let data = await getBulkServiceOrderTechnician(bulktechFrom,selectedSorter.current,franchiseUUID,selectedServiceType);
    console.log('getData-->> ',data);
   if (typeof(data) === 'object' && data.data.length) {
      setTblBulkData(data.data);
   } 
  }

  useEffect(()=>{
    getbulktchtbldata();
  },[]);

  useEffect(()=>{
    if (!isEmpty(props.ordertabledata)) {
    setTblBulkData(props.ordertabledata);
  }
  },[props.ordertabledata]);

  useEffect((e)=>{
    if(bulkReassignClicked !== false){
      onclickonbulkreassign(e);
    }
  },[bulkReassignClicked]);

   const onbulktblchange=(pagination, filters, sorter)=>{
    let ordering = ''
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`
    }
    else {
      ordering = '';
    }
    selectedSorter.current = ordering
    setsorttbl(ordering);
    getbulktchtbldata();
   }
  return (
    <div className="tec-re-btn" style={{ paddingBottom: "4%" }}>
      {/* <Button
        className="btn btn-warning"
        onClick={onclickonbulkreassign}
        style={{
          position: "absolute",
          right: "0",
          height: "50px",
          width: "200px",
          marginRight: "4%",
        }}
      >
        {" "}
        Reassign Technician
      </Button> */}
      <span style={{ textAlign: "left" }}>
        <h2>Service Order</h2>
        <h6>
          Viewing service orders with a preferred technician of{" "}
          {`${techfromname}`}
        </h6>
      </span>

      <Radio.Group
        onChange={({ target: { value } }) => {
          setSelectionType(value);
        }}
        value={selectionType}
      ></Radio.Group>

      {/* <Divider style={{"marginLeft":"1%"}}  /> */}

      <Table
        pagination={{ pageSize: active_pagination, showSizeChanger: false}}
        size="small"
        style={{ width: "100%" }}
        rowKey={(record) => record.service_order_number}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={DataTable.bulkColums()}
        dataSource={tblbulkData}
        onChange={onbulktblchange}
        showSorterTooltip={false}
      />

      {tblbulkData?.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setActivePagination('15') }}
                className={active_pagination === '15' ? 'active' : ''}
              >15</span>
            </li>
            <li className={tblbulkData?.length > 14 ? 'show' : 'hide'}>
              <span
                className={active_pagination === '30' ? 'active' : ''}
                onClick={() => { setActivePagination('30') }}
                >30</span>
            </li>
            <li className={tblbulkData?.length > 29 ? 'show' : 'hide'}>
              <span
                className={active_pagination === '60' ? 'active' : ''}
                onClick={() => { setActivePagination('60') }}
                >60</span>
            </li>
      </ul> : ''}
    </div>
  );
};

export default BulkData;
