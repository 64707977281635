import React, { useState } from "react";
import "../../Assets/css/reports.css";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import Sidebar from "../../Components/Sidebar";
import ReportsListContainers from "../Components/Containers/ReportsList.Container";
import HeaderMobile from "../../Components/HeaderMobile";
import SidebarResponsive from "../../Components/SidebarResponsive";
const ReportsList = () => {
  const [loader, setLoader] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  }
  return (
    <div id="wrapper">
      <div className="report-ca content-area">
      <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        {/* Header */}
        <Header
          placeholder="Search"
          search_value={""}
          onSearch={(e) => e}
          onEnter={(e) => e}
          readOnly={true}
        />
         <SidebarResponsive
            sideBarBackgroundColor="#005d89"
            openMenu={openMenu}
            onMenubuttonClick={onMenubuttonClick}
          />
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <div className="container">
          <ReportsListContainers />
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default ReportsList;
