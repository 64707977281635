import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { Select, Row, Col, message } from "antd";
import moment from 'moment-timezone';
import Script from 'react-load-script';
import AllState from "../../Assets/data/stateList.json";
import { map, isEmpty } from 'lodash';
import * as yup from 'yup';
import cross_icon from "../../Assets/images/cross_icon.svg";
import { errorHandle } from "../../Components/errorHandle";
import { PlusOutlined } from '@ant-design/icons';
import { createLead, createLeadResidential } from "../../Action/Customer";
import { checkZipCode } from '../../Action/Franchise';
import AddressField from "../../Components/AddressField";
import Axios from 'axios';
const { Option } = Select;
const REGULAR_EXPRESSION_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddNewLead = ({ closeAddDrawer, type, data, updateLeadDetails, startLoader, view }) => {
    const navigate = useNavigate();
    const [leadData, setLeadData] = useState({});
    const [isSaveAndAdd, setIsSaveAndAdd] = useState(false);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [userList, setUserList] = useState([]);
    const [address_ref, setAddress_ref] = useState('');
    const [state, setState] = useState({
        zipcode: '',
        firstName: '',
        lastName: '',
        address: { address: '', city: '', state: '', zip_code: '' },
        lead_stage: 'New',
        how_did_you_hear_about_us: '',
        outreach: 'Call Center',
        lead_stage_key: 'new',
        how_did_you_hear_about_us_key: '',
        outreach_key: 'call_center',
        is_address_manually: false,
        customer_type: 'Residential'
    });
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [phone, setPhone] = useState('');
    const [emails, setEmails] = useState([]);
    const [email, setEmail] = useState('');
    const [phoneType, setPhoneType] = useState('mobile');
    const [canSendSms, setCanSendSms] = useState(false);
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        isSaveAndAdd && ValidateTicket();
    }, [isSaveAndAdd]);

    //Close Drawer
    const handleCancel = () => {
        clearAll();
        closeAddDrawer(false);
    }
    const handleAddressChange = (value) => {
        setState({ ...state, address: value });
    }
    //render User list
    const renderOutReach = () => {
        return [
            <Option key='Franchise' value='Franchise' ></Option>,
            <Option key='call_center' value='Call Center' ></Option>
        ]
    }
    const renderLeadStage = () => {
        return [
            <Option key='new' value='New' ></Option>,
            <Option key='contacted' value='Contacted' ></Option>,
            <Option key='interested' value='Interested' ></Option>
        ]
    }
    const renderPlanform = () => {
        return [
            <Option key='Online Search' value='Online Search' ></Option>,
            <Option key='Social Media' value='Social Media' ></Option>,
            // <Option key='Direct Email' value='Direct Email' ></Option>,
            <Option key='Referral' value='Referral' ></Option>,
            <Option key='Truck' value='Truck' ></Option>,
            <Option key='Post card' value='Post Card' ></Option>,
            <Option key='Other' value='Other' ></Option>
        ]
    }
    //Set First Name
    const onCustomerNameChange = (e, name) => {
        setState({
            ...state,
            [name]: e.target.value
        });
    }
    //Set Franchise Select value
    const onSelectChange = (e, name, key) => {
        console.log('');
        setState({
            ...state,
            [`${name}_key`]: key.key,
            [name]: e
        });
    }
    // on address change
    const onAddressChange = (e, addr_ref) => {
        setAddress_ref(addr_ref);
        const { address } = state;
        Object.assign(address, { [e.target.name]: e.target.value })
        setState({ ...state, address: address });
    };
    // on primary phone number changed
    const onPhonePrimaryChanged = (index) => {
        map(phoneNumbers, (obj) => obj.primary = false)
        phoneNumbers[index].primary = true
        setPhoneNumbers([...phoneNumbers])
    }
    // on primary email changed
    const onPrimaryEmailChanged = (index) => {
        map(emails, (obj) => obj.primary = false)
        emails[index].primary = true
        emails[index].email = emails[index].email.toLowerCase();
        setEmails([...emails])
    }
    // format phone number
    const formatPhoneNumber = (phone) => {
        if (!phone) return ''
        phone = phone?.replace(/\D/g, '');
        return '(' + phone?.substr(0, 3) + ') ' + phone?.substr(3, 3) + '-' + phone?.substr(6);
    }
    const genarateGibberrishEmail = () => {
        let newEmail = [{ email: '', primary: true }]
        newEmail[0].email = this.create_UUID() + '@doody.com'
        setEmails(newEmail);
    }
    //on change
    const onChange = (row, name) => {
        let data = name === 'phone' ? phoneNumbers : emails;
        // if(name === 'phone'){
        //   data['type'] = phoneType;
        // }
        data.splice(row, 1);
        if (name === 'phone' || name === 'email') {
            const idx = data?.findIndex(x => x.primary)
            if (idx <= -1 && !isEmpty(data)) {
                data[0].primary = true;
            }
        }
        name === 'phone' ? setPhoneNumbers([...data]) : setEmails([...data])
        if (name === 'email') {
            if (data.length === 0) {
                genarateGibberrishEmail()
            }
        }
    };
    const onTextChange = (e) => {
        if (onNumericInputChange(e) && e.target.name === 'phone') {
            setPhone(e.target.value)
        }
        if (e.target.name === 'email') {
            let lowercase_email = e.target.value.toLowerCase();
            setEmail(lowercase_email)
        }
        if (e.target.name === 'phoneType') {
            setPhoneType(e.target.value)
        }
        if(e.target.name === 'companyName'){
            setCompanyName(e.target.value);
        }
    }
    const onNumericInputChange = (e) => {
        const reg = /^-?\d*(\.\d*)?$/;
        return ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-')
    }
    const isEmailDuplicate = (objdata, email) => {
        let result = false;
        objdata?.forEach((each) => {
          if (each.email === email) {
            result = true;
          }
        });
        return result;
      };
    const onAdd = (type) => {
        if (type === 'phone') {
            let obj = {}
            if (phoneNumbers.length === 0) {
                obj = { 'phone': phone, 'primary': true, 'type': phoneType, 'can_send_sms': canSendSms }
            } else {
                obj = { 'phone': phone, 'primary': false, 'type': phoneType, 'can_send_sms': canSendSms }
            }
            setPhoneNumbers([...phoneNumbers, obj])
            setPhone('')
        }
        // if (checkMail(email) && type === 'email') {
            if (email && type === 'email') {
                let obj = {};
                if (emails.length === 0) {
                  obj = { 'email': email.toLowerCase(), primary: true };
                  setEmails([...emails,obj])
                } else {
                  if (!isEmailDuplicate(emails, email)) {
                    obj = { 'email': email.toLowerCase(), primary: false };
                    setEmails([...emails,obj])
                  } else {
                    message.error("This Email is already Exist");
                  }
                } 
                setEmail('')
              }
        setPhone('')
    }
    const onPhoneChange = (e, type) => {
        const value = type === 'bool' ? e.target.checked : e.target.value;
        setCanSendSms(value);
        Object.assign(phoneNumbers[0], { can_send_sms: e.target.checked })
        if (e.target.name === 'type' && e.target.value === 'phone') {
            Object.assign(phoneNumbers[0], { 'can_send_sms': false })
        }
        setPhoneNumbers([...phoneNumbers]);
    }
    //Validate Data
    const ValidateTicket = () => {
        setTimeout(() => {
            let { zipcode, firstName, lastName, how_did_you_hear_about_us } = state;
            const FranchiseSchema = yup.object().shape({
                firstName: yup.string().required("Customer First Name is required"),
                lastName: yup.string().required("Customer Last Name is required"),
                how_did_you_hear_about_us: yup.string().required("This field is required"),
                zipcode: yup.string().required("Zipcode is required"),
                address: yup.object().shape({
                    address: yup.string().required('address is required.'),
                    city: yup.string().required('city is required.'),
                    state: yup.string().required('state is required.'),
                    zip_code: yup.string().required('zipcode is required.'),

                  }),
                // phoneNumbers: yup.array(yup.object({
                //     phone: yup.string().required('Phone is required')
                // })).min(1).required("Phone number is required"),
                emails: yup.array(yup.object({
                    email: yup.string().required('Email is required')
                })).min(1).required("Email Address is required"),
                companyName: view === 'commercial' || (view === "show_customer_type" && customer_type === "Commercial") ? yup.string().required("Company Name is required") : ''
            });
            const FranchiseData = {
                firstName, lastName, how_did_you_hear_about_us, zipcode, emails, companyName,address
            }
            //phoneNumbers, emails
            let IsValid;
            let err_data = {};
            
            FranchiseSchema.isValid(FranchiseData)
                .then(function (valid) {
                    IsValid = valid
                });
            setTimeout(() => {
                if (IsValid) {
                    setErrors({});
                    setState({
                        ...state,
                        is_add_user_Active: true,
                    });
                    saveData();
                } else {
                    FranchiseSchema.validate(FranchiseData, { abortEarly: false })
                        .catch(function (err) {
                            map(err.inner, (item) => {
                                err_data[item.path] = item.message;
                            });
                            setErrors(err_data);
                            setState({
                                ...state,
                                is_checkout_btn_active: false,
                                is_add_user_Active: false
                            })
                            setIsSaveAndAdd(false)
                        });
                }
            }, 100);
        }, 300);
    }
    //Call New Franchise API
    const saveData = () => {
        const { address, phone_number, zipcode, lead_stage_key, how_did_you_hear_about_us_key, outreach_key, firstName, lastName } = state;
        if (type === 'edit') {
            const obj = {
                uuid: data?.uuid,
                first_name: firstName,
                last_name: lastName,
                address: [address],
                emailaddress_set: emails,                
                authorizedUser: data?.authorizedUser,
                phone_numbers: phoneNumbers,
                lead_stage: lead_stage_key,
                outreach: outreach_key,
                company_name: companyName,
                hear_about_us: [how_did_you_hear_about_us_key],
                role: ['lead'],
                zip_code: zipcode
            }
            startLoader(true);
            updateLeadDetails(obj);
            closeAddDrawer();
        } else {
            const data = {};
            // const field_office_address = (address?.street_number && address?.route) ? { ...address, address: `${address?.street_number} ${address?.route}` } : { ...address, address: addr };
            // data.
            let emailaddress = emails.map(x => Object.fromEntries(Object.entries(x).map(([key, value]) => [key, typeof value == 'string' ? value.toLowerCase() : value])));

            data.properties_in_community = [];
            data.preferred_contact_methods = [];
            data.company_name = "";
            data.no_of_properties = "";
            data.no_of_pools = "";
            data.notes = "";
            data.first_name = firstName;
            data.last_name = lastName;
            data.address = [address];
            data.phone_numbers = phoneNumbers;
            data.emailaddress_set = emailaddress;
            data.zip_code = zipcode;
            data.lead_stage = lead_stage_key;
            data.hear_about_us = [how_did_you_hear_about_us_key];
            data.outreach = outreach_key;
            data.email = emails[0].email.toLowerCase();
            data.company_name = companyName;
            data.role = ["lead"];
            startLoader(true);            
            createNewLead(data);
        }
    }
    const createNewLead = async (params) => {
        let data;
        let keys = [];
        let is_residential = (view === 'residential') || (view === 'show_customer_type' && customer_type === 'Residential');
        if (is_residential) {
            data = await createLeadResidential(params);
            keys = Object.keys(data);
            data.type = 'residential';
        } else {
            data = await createLead(params);
            keys = Object.keys(data);
        }
        setLeadData(data);
        
        if (keys.length === 1) {
            message.error(" This Email Is Already Registered.");
        } else {
            let t = isSaveAndAdd;
            if (isSaveAndAdd) {
                let is_residential = (view === 'residential') || (view === 'show_customer_type' && customer_type === 'Residential');
                if (is_residential) {
                    navigate(`/residential/lead-list/community-info`, { state: { data: data, source: 'customers-lead-list', staxKey: serviceInfoData.data.stax_public_apikey}});
                }else{
                    navigate(`/commercial/lead-list/community-info`, { state: { data: data , source: 'customers-lead-list'}});
                }
                
            }
        }
        clearAll();
        closeAddDrawer();
    }
    const clearAll = () => {
        setState({
            zipcode: '',
            firstName: '',
            lastName: '',
            address: { address: '', city: '', state: '', zip_code: '' },
            lead_stage: 'New',
            how_did_you_hear_about_us: '',
            outreach: 'Call Center',
            lead_stage_key: 'new',
            how_did_you_hear_about_us_key: '',
            outreach_key: 'call_center',
            is_address_manually: false,
        });
        setPhoneNumbers([]);
        setPhone('');
        setEmails([]);
        setEmail('');
        setPhoneType('mobile');
        setCanSendSms(false);
        setErrors({});
        setAddress_ref('');
        setCompanyName('');
    }
    const handleZipCode = (e) => {
        if (e.target.value.length > 3) {
            setLoader(true);
        }
        setServiceInfoData({ data: {} });
        setState({ ...state, zipcode: e.target.value });
    }
    const convertToTitleCase = (str) => {
        return str.split(' ')
            .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(' ');
    }
    const convertToAddressString = (address) => {
        return (address?.street_number && address?.route) ?
            `${address?.street_number || ''} ${address?.route || ''} ${address?.city || ''}, ${address?.state || ''} ${address?.zip_code || ''}` :
            `${address?.address || ''}, ${address?.city || ''}, ${address?.state || ''} ${address?.zip_code || ''}`;
    }
    const [serviceInfoData, setServiceInfoData] = useState({ value: '', key: false, name: 'zipcode', data: [] });
    const [zipCodeCheck, setZipCodeCheck] = useState(false);
    useEffect(() => {
        let { zipcode } = state;
        if (zipcode.length > 3) {
            setServiceInfoData({});
            const getZipCode = async () => {
                const data = await checkZipCode({ addressable_zip_codes__contains: zipcode, close: false });
                if (data?.count > 0) {
                    setZipCodeCheck(true);
                    // dispatch(addZipCodeData(data?.results[0]));
                    // props.onSetData(data?.results[0])
                    setServiceInfoData({ value: zipcode, key: true, name: 'zipcode', data: data?.results[0] });

                } else {
                    setZipCodeCheck(false);
                    setServiceInfoData({ value: zipcode, key: false, name: 'zipcode' });
                }
                 // getHearAboutUsOptions(data.results[0].service_matrix?.hear_about_us_choices);
                setLoader(false);
                // setData(data);
            }
            getZipCode();
        }
    }, [state.zipcode])
    useEffect(() => {
        if (type === 'edit' && !isEmpty(data)) {
            const { address, city, state, zip_code } = data.address.length > 1 ? data.address[1] : data.address[0];
            let primaryphone;
            let emailaddress =  data?.emailaddress_set.map(x => Object.fromEntries(Object.entries(x).map(([key, value]) => [key, typeof value == 'string' ? value.toLowerCase() : value])));

            setState({
                zipcode: data.zip_code,
                firstName: data.first_name,
                lastName: data.last_name,
                address: { address: address, city: city, state: state, zip_code: zip_code },
                lead_stage: convertToTitleCase(data.lead_stage),
                how_did_you_hear_about_us: convertToTitleCase(data.hear_about_us[0]),
                outreach: convertToTitleCase(data.outreach),
                lead_stage_key: data.lead_stage,
                how_did_you_hear_about_us_key: data.hear_about_us[0],
                outreach_key: data.outreach,
                is_address_manually: false,
            });
            setPhoneNumbers(data?.phone_numbers);
            if (data?.phone_numbers.length > 0) {
                primaryphone = data?.phone_numbers.filter((ph) => {
                    return ph.primary === true
                })
            }
            setPhone(primaryphone[0]?.primary && primaryphone[0]?.phone);
            setEmails(emailaddress);
            setEmail(data?.email?.toLowerCase());
            setCompanyName(data?.company_name);
            setPhoneType('mobile');
            setCanSendSms(false);
            setErrors({});
            if (address_ref) {
                address_ref.current.value = data.address[0].formatted_address;
            }
        }
    }, []);

    let { phone_number, address, firstName, lastName, zipcode, lead_stage, outreach, how_did_you_hear_about_us ,customer_type} = state;
    return (
        <div className="group_wrapper rdrawer">
            <div className="card-row">
                <div className="card-full">
                    {type === 'edit' ? <h4>Update Lead</h4> : <h4>Add New Lead</h4>}
                    <div className="form-group">
                            <div className="form-group">
                                {view === "show_customer_type"?
                                <div className="fitem">
                                <label>Customer Type</label>
                                <Select
                                    showArrow={true}
                                    filterOption={false}
                                    notFoundContent={null}
                                    placeholder="Select"
                                    value={customer_type}
                                    onChange={(e, key) => { onSelectChange(e, 'customer_type', key) }}
                                // onSearch={(e) => { getUserListData({ 'search': e }) }}
                                >
                                    <Option key='residential' value='Residential' ></Option>,
                                    <Option key='commercial' value='Commercial' ></Option>
                                </Select>
                            </div>
                            :<></>}
                            <div className="fitem">
                                <label>Zipcode</label>
                                <input type="text" placeholder="Zipcode" name="zipcode" max={5} maxLength={5} value={zipcode} onChange={handleZipCode} />
                                {errors?.['zipcode'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['zipcode']}</label> : ''}
                            </div>
                            {zipcode ? zipCodeCheck === true ?
                                <div className="greenbox mb-30">
                                    <b className="d-block">We cover this area.</b>
                                    <b className="d-block">{`This is under the ${serviceInfoData.data?.name} Franchise.`}</b>
                                </div> :
                                <div className="dangerbox mb-30">
                                    <b className="d-block">We don’t currently have any franchises covering this area. Save their email to notify them if we move into this area. </b>
                                </div> : ''
                            }
                        </div>
                        <div className="form-group">
                            <Row>
                                <div className='half-fitem fitem'>
                                    <Col span={11.5}>
                                        <label>First Name</label>
                                        <input type="text" placeholder="First Name" name="firstName" value={firstName} onChange={(e) => onCustomerNameChange(e, 'firstName')} />
                                        {errors['firstName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['firstName']}</label> : ''}
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={11.5}>
                                        <label>Last Name</label>
                                        <input type="text" placeholder="Last Name" name="lastName" value={lastName} onChange={(e) => onCustomerNameChange(e, 'lastName')} />
                                        {errors['lastName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['lastName']}</label> : ''}
                                    </Col>
                                </div>
                            </Row>

                        </div>
                        <AddressField handleAddressChange={handleAddressChange} onAddressChanged={onAddressChange} onAddressChange={onAddressChange} errors={errors} data={address} />
                        <div className="form-group">
                            <label>Phone Numbers</label>
                            {
                                map(phoneNumbers, (item, index) =>
                                    <div className="fitem-check" key={`phoneNumber${index}`}>
                                        <input type="checkbox" className="fitem-ck-input" id={`checkboxPhone${index}`} name={`checkboxPhone${index}`} checked={item?.primary} onChange={(e) => onPhonePrimaryChanged(index)} />
                                        <label htmlFor={`checkboxPhone${index}`} className="fitem-ck-txt black">{`${formatPhoneNumber(item.phone)} ${item.primary ? '(Primary)' : ''}`}</label>
                                        <a className="red float-right" onClick={(e) => onChange(index, 'phone')}><u>Remove</u></a>
                                    </div>)
                            }
                            <div className="fitem fitem-rinner mt-10 flex">
                                <input name="phone" type="text" placeholder="Add New Phone" value={phone} maxLength={"10"} max={"10"} onChange={onTextChange} 
                                 onKeyPress={(e) => {
                                    const pattern = /[0-9]/;
                                    const inputChar = String.fromCharCode(e.charCode);
                                    if (!pattern.test(inputChar)) {
                                        // invalid character, prevent input
                                        e.preventDefault();
                                    }
                                }}/>
                                <button className={`btn fitem-ricon ${phone?.length !== 10 ? 'disabled' : 'btn-warning'}`}
                                    disabled={phone?.length !== 10} onClick={(e) => onAdd('phone')} ><PlusOutlined /></button>
                            </div>
                            {/* {errors?.[`phoneNumbers`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`phoneNumbers`]}</label> : ''} */}
                        </div>
                        <div>
                            <label>Email Addresses</label>
                            <div className="inline-check-list gcheck">
                                {
                                    map(emails, (item, index) => item?.email && !item.email.includes('doody.com') ?
                                        <div className="fitem-check" key={`email${index}`}>
                                            <input type="checkbox" className="fitem-ck-input" id={`checkboxEmail${index}`} name={`checkboxEmail${index}`} checked={item?.primary} onChange={(e) => onPrimaryEmailChanged(index)} />
                                            <label htmlFor={`checkboxEmail${index}`} className="fitem-ck-txt black">{`${item?.email} ${item?.primary ? '(Primary)' : ''}`}</label>
                                            <a className="red float-right" onClick={(e) => onChange(index, 'email')}><u>Remove</u></a>
                                        </div> : ''
                                    )
                                }
                            </div>
                            <div className="fitem fitem-rinner mt-10 flex">
                                <input name="email" type="text" placeholder="Add New Email Address" value={email} onChange={onTextChange} />
                                <button className={`btn fitem-ricon ${!REGULAR_EXPRESSION_EMAIL.test(email) ? 'disabled' : 'btn-warning'}`}
                                    disabled={!REGULAR_EXPRESSION_EMAIL.test(email)} onClick={(e) => onAdd('email')} ><PlusOutlined /></button>
                            </div>
                            {errors?.[`emails`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`emails`]}</label> : ''}
                        </div> 
                        { view === 'commercial' ||(view === "show_customer_type" && customer_type === "Commercial")?
                        <div>
                            <label>Company name</label>
                            <input type="text" placeholder="Company Name" name="companyName" value={companyName} onChange={(e) => onTextChange(e, 'companyName')} />
                            {errors['companyName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors['companyName']}</label> : ''}
                        </div>
                        : '' }
                        <div className="fitem">
                            <label>Lead Stage</label>
                            <Select
                                showSearch
                                showArrow={true}
                                filterOption={false}
                                notFoundContent={null}
                                placeholder="Select"
                                value={lead_stage}
                                onChange={(e, key) => { onSelectChange(e, 'lead_stage', key) }}
                            // onSearch={(e) => { getUserListData({ 'search': e }) }}
                            >
                                {renderLeadStage()}
                            </Select>
                            {/* {errors['new_franchise.owner_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.owner_uuid']}</label> : ''} */}
                        </div>
                        <div className="fitem">
                            <label>How did you hear about us?</label>
                            <Select
                                showSearch
                                showArrow={true}
                                filterOption={false}
                                notFoundContent={null}
                                placeholder="Select"
                                value={how_did_you_hear_about_us}
                                onChange={(e, key) => { onSelectChange(e, 'how_did_you_hear_about_us', key) }}
                            // onSearch={(e) => { getUserListData({ 'search': e }) }}
                            >
                                {renderPlanform()}
                            </Select>
                            {errors['how_did_you_hear_about_us'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['how_did_you_hear_about_us']}</label> : ''}
                        </div>
                        <div className="fitem">
                            <label>Outreach</label>
                            <Select
                                showSearch
                                showArrow={true}
                                filterOption={false}
                                notFoundContent={null}
                                placeholder="Select"
                                value={outreach}
                                onChange={(e, key) => { onSelectChange(e, 'outreach', key) }}
                            // onSearch={(e) => { getUserListData({ 'search': e }) }}
                            >
                                {renderOutReach()}
                            </Select>
                            {/* {errors['new_franchise.owner_uuid'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_franchise.owner_uuid']}</label> : ''} */}
                        </div>
                    </div>
                    <div className="footer" style={(type === 'edit') ? { 'width': '420px' } : { 'width': '440px' }}>
                        <button
                            onClick={handleCancel}
                            className="btn btn-close">Cancel
                        </button>                        
                        {/*(type === 'edit' || view === 'residential') &&*/
                        type !== 'edit' && (view === 'commercial' || (view === 'show_customer_type' && customer_type === 'Commercial'))?
                            <></>
                        :
                            <button
                                className={`btn btn-bdr ${type === 'edit' ? 'updateLeadButton': 'addLeadButton'}`}
                                type='submit'
                                onClick={(e) => {
                                    e.preventDefault();
                                    ValidateTicket();
                                }}>{type === 'edit' ? 'Update Lead' : 'Add Lead'}
                            </button>
                        }
                        {
                            (view === 'commercial' || view === 'residential' || view === "show_customer_type") &&
                            <button
                                // className='btn btn-save-add'
                                className={`btn btn-bdr ${type === 'edit' ? 'updateLeadButton': 'addLeadButton'}`}
                                type='button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    sessionStorage.setItem("CACHED_INITIAL_RENDERED", "true");
                                    setIsSaveAndAdd(true);
                                }}>Save & Add Service
                            </button>
                        }
                    </div>
                </div>
                {loader && < Loader />}
            </div>
        </div>
    );
}

export default AddNewLead;