import axiosInstance from "../../Lib/Axios";
import { isEmpty } from "lodash";
export async function getCustomerDetails(customer) {
  console.log("From UTILS:\n", customer);

  let customerData = {
    firstname: customer.firstName,
    lastname: customer.lastName,
    company: "ASP",
    email: customer?.emails?.[0] ? customer?.emails[0]?.email : customer?.email,
    phone: customer?.phoneNumbers[0]?.phone,
    address_1: customer?.address?.address,
    address_city: customer?.address?.city,
    address_state:
      customer?.address?.state?.length > 2
        ? formatState(customer.address.state)
        : customer.address.state,
    address_zip: customer?.zip_code || customer?.address?.zip_code,
    address_country: "USA",
  };
  return customerData;
}

export async function createCustomerInstax(data) {
  if (!isEmpty(data?.phone)) {
    data.phone = data.phone.replace(/[^0-9]/g, "");
  }
  try {
    const response = await axiosInstance.post(
      "/crm/create-customer-in-stax",
      data
    );
    return response?.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}
export function formatState(data) {
  switch (data.toUpperCase()) {
    case "ALABAMA":
      return "AL";
      break;
    case "ALASKA":
      return "AK";
      break;
    case "ARIZONA":
      return "AZ";
      break;
    case "CALIFORNIA":
      return "CA";
      break;
    case "COLORADO":
      return "CO";
      break;
    case "CONNECTICUT":
      return "CT";
      break;
    case "DELAWARE":
      return "DE";
      break;
    case "DISTRICT OF COLUMBIA":
      return "DC";
      break;
    case "FLORIDA":
      return "FL";
      break;
    case "GEORGIA":
      return "GA";
      break;
    case "GUAM":
      return "GU";
      break;
    case "HAWAII":
      return "HI";
      break;
    case "IDAHO":
      return "ID";
      break;
    case "ILLINOIS":
      return "IL";
      break;
    case "INDIANA":
      return "IN";
      break;
    case "IOWA":
      return "IA";
      break;
    case "KANSAS":
      return "KS";
      break;
    case "KENTUCKY":
      return "KY";
      break;
    case "LOUISIANA":
      return "LA";
      break;
    case "MAINE":
      return "ME";
      break;
    case "MARYLAND":
      return "MD";
      break;
    case "MASSACHUSETTS":
      return "MA";
      break;
    case "MICHIGAN":
      return "MI";
      break;
    case "MINNESOTA":
      return "MN";
      break;
    case "MISSISSIPPI":
      return "MS";
      break;
    case "MISSOURI":
      return "MO";
      break;
    case "MONTANA":
      return "MT";
      break;
    case "NEBRASKA":
      return "NE";
      break;
    case "NEVADA":
      return "NV";
      break;
    case "NEW HAMPSHIRE":
      return "NH";
      break;
    case "NEW JERSEY":
      return "NJ";
      break;
    case "NEW MEXICO":
      return "NM";
      break;
    case "NEW YORK":
      return "NY";
      break;
    case "NORTH CAROLINA":
      return "NC";
      break;
    case "NORTH DAKOTA":
      return "ND";
      break;
    case "NORTHERN MARIANA IS":
      return "MP";
      break;
    case "OHIO":
      return "OH";
      break;
    case "OKLAHOMA":
      return "OK";
      break;
    case "OREGON":
      return "OR";
      break;
    case "PENNSYLVANIA":
      return "PA";
      break;
    case "PUERTO RICO":
      return "PR";
      break;
    case "RHODE ISLAND":
      return "RI";
      break;
    case "SOUTH CAROLINA":
      return "SC";
      break;
    case "SOUTH DAKOTA":
      return "SD";
      break;
    case "TENNESSEE":
      return "TN";
      break;
    case "TEXAS":
      return "TX";
      break;
    case "UTAH":
      return "UT";
      break;
    case "VERMONT":
      return "VT";
      break;
    case "VIRGINIA":
      return "VA";
      break;
    case "VIRGIN ISLANDS":
      return "VI";
      break;
    case "WASHINGTON":
      return "WA";
      break;
    case "WEST VIRGINIA":
      return "WV";
      break;
    case "WISCONSIN":
      return "WI";
      break;
    case "WYOMING":
      return "WY";
      break;
    default:
    // code block
  }
}

export async function createAscPaymentData(
  AccountHolderName,
  AccountNumber,
  RoutingNumber,
  BankHolderType,
  AccountType,
  id
) {
  const ach_data = {
    method: "bank",
    person_name: AccountHolderName || "",
    bank_account: AccountNumber || "",
    bank_routing: RoutingNumber || "",
    bank_holder_type: BankHolderType.toLowerCase() || "",
    bank_type: AccountType.toLowerCase() || "",
    customer_id: id,
  };
  return ach_data;
}

export async function createCardPaymentData(
  CardHolderName,
  FirstName,
  LastName,
  CardNumber,
  Month,
  Year,
  id
) {
  const ach_data = {
    method: "card",
    first_name: FirstName,
    last_name: LastName,
    person_name: CardHolderName || "",
    card_number: CardNumber || "",
    month: Month || "",
    year: Year || "",
    customer_id: id,
  };
  return ach_data;
}

export function createPaymentMethodInStax(data) {
  const url = "https://apiprod.fattlabs.com/payment-method/";
  return axiosInstance
    .post(url, data)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.log(error);
      return error.data;
    });
}

export function  numberValidate(e){
  let inputValue = e.target.value;
    const decimalIndex = inputValue.indexOf('.');
    const decimalPlaces = decimalIndex !== -1 ? inputValue.length - decimalIndex - 1 : 0;
    if (decimalPlaces > 1 || (e.target.value.length === 0 && (e.which === 48 || e.which === 45))) {
      e.preventDefault();
    } 
}

export function  rateValidate(e){
    if (e.target.value.length === 0 && e.which === 45) {
      e.preventDefault();
    } 
}