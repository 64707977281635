import React, { Component, useState, useEffect } from 'react';
import * as yup from 'yup';
import { message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import dclogo from "../Assets/images/logo-asp.svg";
import RightSlider from '../Login/RightSlider';
import {errorHandle} from "../Components/errorHandle";
import { newPassword}  from '../Action/Auth/index';
import { encode as base64_encode } from 'base-64';
import { Base64 } from 'js-base64';
import { useLocation, useParams } from 'react-router';

const ResetPassword = (props) => {
  const location = useLocation();
  const params = useParams()
const [state,setState] = useState({
      new_password1: "",
      new_password2: "",
      uid: "",
      token: "",
      validPassword: {
        containsUppercase: false,
        containsNumber: false,
        checkLength: false,
      },
      is_btn_active: false,
      new_password_eye1: false,
      new_password_eye2: false,
      is_password_match: false,
      loader: false
})
const navigate = useNavigate();

function getState(){
    setState({
        new_password1: "",
        new_password2: "",
        uid: "",
        token: "",
        validPassword: {
          containsUppercase: false,
          containsNumber: false,
          checkLength: false,
        },
        is_btn_active: false,
        new_password_eye1: false,
        new_password_eye2: false,
        is_password_match: false,
        loader: false
    })
}

useEffect(()=>{
    getState();
    const accessToken = JSON.parse(localStorage.getItem("authToken"))
    ? JSON.parse(localStorage.getItem("authToken")).key
    : "";
    if (accessToken) {
        navigate("/dashboard");
    }
},[])

    const onPwChange = (e) => {
     if(state.new_password1 === e.target.value){
        setState((state)=>({...state,is_password_match:true,is_btn_active:true }))
      }else if (e.target.value ===''){
        setState((state)=>({...state,is_password_match:false }))
      }else{
        setState((state)=>({...state,is_password_match:false }))
      }
      setState((state)=>({...state,new_password2:e.target.value,is_btn_active:true }))
    }
    // Password Change
   const onPasswordChange = (e) => {
      let { validPassword } = state;
      //Password validation
      if (e.target.name === 'new_password1') {
        validPassword = Object.assign(validPassword, {
          'containsUppercase': (/[A-Z]/).test(e.target.value),
          'containsNumber': (/\d/).test(e.target.value),
          'checkLength': e.target.value.length >= 8,
        });
      }

      setState((state)=>({...state, [e.target.name]: e.target.value, validPassword }))
      validateUser();
    }

    // UNSAFE_componentWillReceiveProps = (props) => {
    //   if (Object.keys(props.resetPWD.data).length > 0 && props.resetPWD.data !== this.props.resetPWD.data) {

    //     message.success('Password Change successfully');
    //     this.setState({
    //       new_password1: "",
    //       new_password2: "",
    //       is_btn_active: false,
    //       loader: true
    //     })
    //     setTimeout(() => {
    //       this.props.history.push('/')
    //     }, 1500);

    //   }
    //   if (Object.keys(props.resetPWD.errors).length > 0 && props.resetPWD.errors !== this.props.resetPWD.errors) {
    //     // Display error message 
    //     if (props.resetPWD.errors.token) {
    //       message.error(`TOKEN:${props.resetPWD.errors.token}`)
    //     }
    //     else if (props.resetPWD.errors.uid) {
    //       message.error(`UID:${props.resetPWD.errors.uid}`, 1000)
    //     }

    //     else if (props.resetPWD.errors.new_password2) {
    //       message.error(props.resetPWD.errors.new_password2)
    //     }
    //     else {
    //       errorHandle(props.resetPWD.errors)
    //     }
    //     this.setState({
    //       loader: false
    //     })
    //   }
    // }


    // Validate Form
    const validateUser = () => {
      setTimeout(() => {
        let { new_password1, new_password2, validPassword } = state;
        const UserSchema = yup.object().shape({
          new_password1: yup.string().required("Password is required"),
          new_password2: yup.string()
            .oneOf([yup.ref('new_password1'), null], 'Passwords must match'),
          validPassword: yup.object().shape({
            containsUppercase: yup.bool().oneOf([true], 'containsUppercase'),
            containsNumber: yup.bool().oneOf([true], 'containsNumber'),
            checkLength: yup.bool().oneOf([true], 'checkLength'),
          })
        })

        const UserData = {
          new_password1,
          new_password2,
          validPassword
        }
        // check validity
        let IsValid;
        UserSchema.isValid(UserData)
          .then(function (valid) {
            IsValid = valid
          });

        setTimeout(() => {
          if (IsValid) {
            setState((state)=>({
              ...state,
              errors: {}
            }))

            setState((state)=>({
              ...state,  
              is_btn_active: true,
              is_password_match: true
            }))
          }
          else {
            UserSchema.validate(UserData, { abortEarly: false })
              .catch(function () {
                setState((state)=>({
                  ...state,  
                  is_btn_active: false,
                  is_password_match: false
                }))
              });
          }
        }, 100);
      }, 300);
    }
 

//Update PAssword
const UpdatePassword = (e) => {
  let url = window.location.href;
  let tokenSplit = url.substring(url.lastIndexOf("/") + 1, url.length);
  
  let token=tokenSplit.split('?')[0];
  let { new_password1, new_password2, } = state;
  let uid = url.split('=')[1];
  
  e.preventDefault();
  let encoded = uid;
  let data = {
    "new_password1": new_password1,
    "new_password2": new_password2,
    "token": token,
    "uid": uid,
  }
  
  setState((state)=>({
    ...state,
    loader: true
  }))
  let postResponse=newPassword(encoded,token,data);
  Promise.any([postResponse])
  .then((response) => {
    if(response.detail!=''){
      message.success(response.detail);
      navigate("/");
    }else{
      message.error("something went wrong");
    }
    setState((state)=>({
      ...state,
      loader: false
    }))
  })
  .catch((error) => {
    console.error(`Failed to fetch: ${error}`)
    setState((state)=>({
      ...state,
      loader: false
    }))
  });
}


  let { new_password1, new_password2, new_password_eye1, is_btn_active, new_password_eye2, is_password_match, validPassword, loader } = state;
  return (
    <div id="wrapper">
      <div className="auth-page">
        <div className="authform">
          <div className="auth-container">
            <img src={dclogo} alt="" className="logo" />
            <h1>Set your new password</h1>
            <label className="mb-30">Choose and confirm your new password.</label>
            <form className="form-group" >
              <div className="fitem fitem-rinner">
                <label>Password</label>
                <input
                  type={new_password_eye1 ? "text" : "password"}
                  name="new_password1"
                  value={state.new_password1}
                  onChange={(e) => { onPasswordChange(e) }}
                  placeholder="Start typing…" />
                <span className="fitem-ricon">
                  <i
                    className={new_password_eye1 ? 'lar la-eye' : 'las la-low-vision'}
                    onClick={() => {
                      setState((state)=>({...state, new_password_eye1: !state.new_password_eye1 }))
                    }}
                  ></i>
                </span>
              </div>

              <div className="fitem validate">
                <span className={validPassword.checkLength ? 'circle success_bg' : 'circle'}></span>At leasts 8 characters<br />
                <span className={validPassword.containsUppercase ? 'circle success_bg' : 'circle'}></span>At least one capital letter<br />
                <span className={validPassword.containsNumber ? 'circle success_bg' : 'circle'}></span>At least one number<br />
                <span className={is_password_match ? 'circle success_bg' : 'circle'}></span>Passwords match<br />
              </div>
              <div className="fitem fitem-rinner">
                <label>Password</label>
                <input
                  type={new_password_eye2 ? "text" : "password"}
                  name="new_password2"
                  value={state.new_password2}
                  onChange={(e) => { onPwChange(e) }}
                  placeholder="Start typing…" />
                <span className="fitem-ricon">
                  <i
                    className={new_password_eye2 ? 'lar la-eye' : 'las la-low-vision'}
                    onClick={() => {
                      setState((state)=>({...state, new_password_eye2: !state.new_password_eye2 }))
                    }}
                  ></i>
                </span>
              </div>

              <div className="btn-grp text-right">
                <button onClick={(e) => { UpdatePassword(e) }}
                  type="submit"
                  className={!is_btn_active ? 'disable btn ' : 'btn btn-secondary'}
                  disabled={!is_btn_active}
                >Confirm new password</button>
              </div>
            </form>
          </div>
        </div>
        <RightSlider />
      </div>
      {
        loader ?
          <div className='loader_wrapper'>
            <div className='loader07'></div>
          </div>
          : ''
      }
    </div>
  );
}

export default ResetPassword;