import React from "react";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { initialCleaningDays } from "../../../Action/Franchise";
import { checkZipCode } from "../../../Action/Franchise";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { prefered_date } from "../../../Store/ProposalDataSlice";
import { apiResponse } from "../../../Store/ProposalDataSlice";

const PreferedStartDate = (props) => {
  const { errors, editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [data, setData] = useState([]);
  const [checkZipCodeData, setCheckZipCodeData] = useState({});
  const [displayPreferredStartDate, setDisplayPreferredStartDate] = useState("");
  const [preferredStartDate, setPreferredStartDate] = useState("");
  useEffect(() => {
    getZipCodeDetails();
    if (Object.keys(checkZipCodeData).length > 0) {
      const getInitialCleanningDays = async () => {
        let zip_codes = checkZipCodeData?.field_office_address?.zip_code;
        const data = await initialCleaningDays({
          zip_code: zip_codes,
        });
        if (data?.data?.length) {
          dispatch(apiResponse(data.data));
        }
      };
      getInitialCleanningDays();
    } else {
      let startdate = moment().format("YYYY-MM-DD");
      let new_date = [];
      var new_date1 = moment(startdate, "YYYY-MM-DD").add(1, "days");
      var new_date2 = moment(startdate, "YYYY-MM-DD").add(2, "days");
      var new_date3 = moment(startdate, "YYYY-MM-DD").add(3, "days");
      new_date.push(startdate);
      new_date.push(new_date1);
      new_date.push(new_date2);
      new_date.push(new_date3);
      new_date.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      dispatch(apiResponse(new_date));
      const diff = moment(proposals.preferred_start_date).diff(moment(startdate), "days");
      if(diff > 4) {
        setDisplayPreferredStartDate(moment(proposals.preferred_start_date));
      }
    }
    setPreferredStartDate(props.serviceInfo?.preferredDate?.value ?? "");
  }, []);

  const getZipCodeDetails = async () => {
    const data = await checkZipCode({
      addressable_zip_codes__contains:
        props?.matrixdata?.service_address[0].zip_code,
    });
    if (data?.count > 0) {
      setCheckZipCodeData(data?.results[0]);
    }
  };
  const onDataChange = (e) => {
dispatch(prefered_date(e.target.value))
setDisplayPreferredStartDate("");
  };
  const onSelectDisplayPreferredStartDate = (e) => {
    dispatch(prefered_date(moment(e).format("YYYY-MM-DD")))

    setDisplayPreferredStartDate(e);
    setPreferredStartDate(moment(e).format("MM-DD-YYYY"));
    // props.setServiceInfoData({
    //   value: moment(e).format("MM-DD-YYYY"),
    //   key: true,
    //   name: "preferredDate",
    // });
  };
  const renderExtraFooter = () => {
    return (
      <div className="data_indicator">
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn"
        >
          Available
        </a>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn unavailable"
        >
          Unavailable
        </a>
      </div>
    );
  };

  useEffect(() => {
    let arrayForSort = [...proposals.data_save]
    arrayForSort = arrayForSort ?.sort(function (a, b) {
      return new Date(a) - new Date(b);
    }) ?.slice(0, 4);
    setData(arrayForSort);
  }, [proposals.data_save])

  return (
    <div className="frow">
      <h4>Preferred Start Date <sup>*</sup></h4>
      <div className="oplist">
        {data ? data.map(
          (item, i) => {
            return (
              <div
                className="inline-checks flex-1"
                key={`preferred_start_dates${i}`}
              >
                <label
                  htmlFor={item}
                className={
                  proposals.preferred_start_date ===
                  moment(item).format("YYYY-MM-DD")
                    ? "active"
                    : ""
                }
                >
                  <input
                    type="radio"
                    id={item}
                    name="preferredStartDate"
                    disabled = {editMode}
                    value={moment(item).format("YYYY-MM-DD")}
                    className=""
                    onChange={onDataChange}
                  />
                  <span>
                    {item === moment().format("YYYY-MM-DD")
                      ? "Today"
                      : moment(item, "YYYY-MM-DD").format(
                        "ddd, MMM DD"
                      )}
                  </span>
                </label>
              </div>
            );
          }
        ): <></>}
        <div className="inline-checks flex-1">
          <DatePicker
            renderExtraFooter={(e) => renderExtraFooter}
            inputReadOnly
            mode={"date"}
            className={
              moment(proposals.preferred_start_date).diff(moment(moment().format("YYYY-MM-DD")), "days") > 4 ? "active" : "box_opacity"
            }
            placeholder={"Select another date"}
            format={"ddd, MMM DD"}
          value={displayPreferredStartDate}
          onChange={onSelectDisplayPreferredStartDate}
          disabled= {editMode}
          disabledDate={(current) => {
            return current && current <= moment().add(3, "days");
          }}
          />
        </div>
      </div>
      {errors["preferred_start_date"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["preferred_start_date"]}
                  </label>
                ) : (
                  ""
                )}
    </div>
  );
};
export default PreferedStartDate;
