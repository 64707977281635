import React from "react";
import "../../../Assets/css/proposalBase.css";

const Proposalcreate = (props) => {
  const { onnewproposalclick } = props;
  return (
    <>
      <div className="propsal_tab_css">
        <div className="propsal_body">
          <div>
            <div className="pro_label">
              <h3>Create a new Proposal</h3>
            </div>
            <div className="proposal_lable">
              <label>For additional Businesses under the same company</label>
            </div>
            <div className="pro_btn">
              <button className="btn btn-bdr" onClick={onnewproposalclick}>
                Add New Proposal
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Proposalcreate;
