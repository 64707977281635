import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { message } from "antd";
import Sidebar from "../../../Components/Sidebar";
import HeaderMobile from "../../../Components/HeaderMobile";
import SidebarResponsive from "../../../Components/SidebarResponsive";
import Breadcrumb from "../../../Components/Breadcrumb";
import ServiceArea from "../../Common/ServiceArea";
import BaseJobRequirements from "../../Common/BaseJobRequirements";
import ServiceSummary from "./ServiceSummary/ServiceSummary";
import Footer from "./Footer";
import Loader from "../../../Components/Loader";
import BillingInfo from "../../Common/BillingInfo";
import {
  addBillingInfo,
  createCustomer,
  createServiceOrder,
  updateBillingInfo,
} from "../../../Action/Customer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { resetValues } from "../../../Store/serviceOrderDetailDataSlice";
import StaxModal from "../../Common/Stax.Modal";
import StaxModalACH from "../../Common/StaxACH.Modal";
const NewCustomer = () => {
  const serviceAreaFunc = React.useRef(null);
  const validateBaseJobReq = React.useRef(null);
  const billingInfoFunc = React.useRef(null);
  const [serviceInfo, setServiceInfo] = useState({});
  const [priceDetail, setPriceDetail] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [btnLabel, setBtnLabel] = useState("Next: GO TO BILLING");
  const [data, setData] = useState({});
  const [uuid, setUiid] = useState("");
  const [loader, setLoader] = useState(false);
  const [customer, setCustomer] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const [standardRate, setStandardRate] = useState(false);
  const [fieldOfficeData, setfieldOfficeData] = useState({});
  const [openStax, setOpenStax] = useState(false);
  const [openStaxModalACH, setOpenStaxModalACH] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const serviceOrderNewFieldsValue = useSelector(
    (state) => state.serviceOrderAddedData
  );
  const dispatch = useDispatch();

  const onSetData = (data) => {
    setData(data);
    setfieldOfficeData(data);
  };
  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };
  useEffect(() => {
    setUiid(data.uuid);
  }, [data]);

  useEffect(() => {}, [serviceInfo]);

  const renderTabs = () => {
    switch (currentTab) {
      case 1:
        return (
          <BillingInfo
            ref={billingInfoFunc}
            uuid={uuid}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            serviceInfo={serviceInfo}
            data={data}
          />
        );
      case 0:
        return serviceInfo?.zipCode?.key ? (
          <BaseJobRequirements
            ref={validateBaseJobReq}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            data={data}
            standardRate={standardRate}
            fieldOfficeData={fieldOfficeData}
          />
        ) : (
          <div className="adetail">
            <h3>Base Job Requirements</h3>
            <div className="frow">
              <h6>
                Please insert a Zipcode to get the Service Types provided by the
                Franchise
              </h6>
            </div>
          </div>
        );
      default:
        return (
          <div className="adetail">
            <h3>
              <span className="danger">Please Enter Valid Zip Code !</span>
            </h3>
          </div>
        );
    }
  };

  const setServiceInfoData = (data) => {
    let obj = serviceInfo;
    obj[data.name] = data;
    setServiceInfo(obj);
  };

  const setPriceDetailData = (data) => {
    setPriceDetail(data);
  };

  const openPayment = () => {
    if (currentTab === 1) {
      if (serviceInfo?.payment_method?.value === "card"
      || serviceInfo?.payment_method?.value === "ACH Payments") {
        setLoader(true);
        billingInfoFunc.current.validate("save_lead").then((res) => {
          if (res === "success") {
            setLoader(false);
            if (serviceInfo?.payment_method?.value === "card") {
              setOpenStax(true);
            } else {
              setOpenStaxModalACH(true);
            }
          } else{
            setLoader(false);
            message.error("Something went wrong!");
          }
        }).catch(() =>{
          setLoader(false);
          message.error("Please correct errors");
        });
      }
      else {
          validate();
      }
    } else {
      validate();
    }
  };

  const validate = (action, staxProps) => {
    serviceAreaFunc.current.validate().then((res) => {
      if (res === "success") {
        if (currentTab === 0) {
          validateBaseJobReq.current.validate().then((res) => {
            if (res === "success") {
              setCurrentTab(1);
              setBtnLabel("Convert to Customer");
            }
          });
        } else {
          billingInfoFunc.current.validate(undefined, staxProps).then((res) => {
            const primaryEmail = serviceInfo?.serviceArea?.value?.emails.filter(
              (email) => {
                return email.primary;
              }
            );
            let emailaddress = serviceInfo?.serviceArea?.value?.emails.map(
              (x) =>
                Object.fromEntries(
                  Object.entries(x).map(([key, value]) => [
                    key,
                    typeof value == "string" ? value.toLowerCase() : value,
                  ])
                )
            );
            if (res === "success") {
              setLoader(true);
              let customerObj = {};
              customerObj.field_office_uuid = serviceInfo?.zipCode?.data?.uuid;
              customerObj.service_address =
                serviceInfo?.serviceArea?.value?.address;
              customerObj.billing_address =
                serviceInfo?.billingInfo?.value?.billingAddress;
              customerObj.is_billing_address_same =
                serviceInfo?.billingInfo?.value?.isBillingAddressSame;
              customerObj.first_name =
                serviceInfo?.serviceArea?.value?.firstName;
              customerObj.last_name = serviceInfo?.serviceArea?.value?.lastName;
              customerObj.email =
                (primaryEmail && primaryEmail[0] && primaryEmail[0].email) ||
                null;
              customerObj.emailaddress_set = emailaddress;
              customerObj.phone_numbers =
                serviceInfo?.serviceArea?.value?.phoneNumbers;
              customerObj.stax_card_token = staxProps?.id;
              customerObj.payment_method = serviceInfo?.paymentMethod?.value;
              customerObj.charge_invoice_card = serviceInfo?.charge_invoice_card?.value || false;
              customerObj.card_holder_name = staxProps?.person_name;
              customerObj.hear_about_us = serviceInfo?.hear_about_us
                ? [serviceInfo?.hear_about_us?.value]
                : [];
              customerObj.outreach =
                serviceInfo?.outreach?.value === "Franchise"
                  ? "Franchise"
                  : "call_center";
              customerObj.is_active = true;
              customerObj.status = "active";
              customerObj.client_type = "residential";
              console.log(emailaddress, "customerObj.emailaddress_set");

              const postCustomerCreate = async () => {
                const data = await createCustomer(customerObj);
                if (data?.uuid) {
                  var serviceTypeUUID = null;
                  if (serviceInfo?.worksubtype?.value) {
                    serviceTypeUUID = serviceInfo?.worksubtype?.value;
                  } else if (
                    !serviceInfo?.worksubtype &&
                    serviceInfo?.baseJobReq?.value?.service
                  ) {
                    serviceTypeUUID = serviceInfo?.baseJobReq?.value?.service;
                  } else {
                    serviceTypeUUID =
                      serviceInfo?.baseJobReq?.value?.serviceType;
                  }
                  setCustomer(data?.data);
                  let serviceOrderObj = {};
                  serviceOrderObj.service_address_uuid =
                    data?.service_address_uuid;
                  serviceOrderObj.field_office_uuid = data?.field_office_uuid;
                  serviceOrderObj.is_billing_address_same =
                    serviceInfo?.billingInfo?.value?.isBillingAddressSame;
                  serviceOrderObj.client_uuid = data?.uuid;
                  serviceOrderObj.is_service = true;
                  serviceOrderObj.proposal_date = moment().format("YYYY-MM-DD");
                  serviceOrderObj.contract_date = moment().format("YYYY-MM-DD");
                  serviceOrderObj.preferred_start_date = serviceInfo
                    ?.billingInfo?.value?.preferredStartDate
                    ? moment(
                        serviceInfo?.billingInfo?.value?.preferredStartDate
                          ? serviceInfo?.billingInfo?.value?.preferredStartDate
                          : null
                      ).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD");
                  serviceOrderObj.service_type_uuid = serviceTypeUUID;
                  serviceOrderObj.pool_type_uuid =
                    serviceInfo?.baseJobReq?.value?.poolType;
                  serviceOrderObj.pool_size = parseInt(
                    serviceInfo?.baseJobReq?.value?.poolSize
                  );
                  serviceOrderObj.preferred_contact_methods =
                    serviceInfo?.billingInfo?.value?.contactMethods;
                  serviceOrderObj.payment_method =
                    serviceInfo?.billingInfo?.value?.paymentMethod;
                  serviceOrderObj.charge_invoice_card =
                    serviceInfo?.charge_invoice_card?.value || false;
                  if (
                    serviceInfo?.serviceType?.key.toLowerCase() ===
                      "maintenance" ||
                    serviceInfo?.serviceType?.key.toLowerCase() === "service"
                  ) {
                    serviceOrderObj.frequency_settings = {
                      recurring: serviceInfo?.frequency.value.recurring,
                      start: moment(serviceInfo?.frequency.value.start).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    if (
                      serviceInfo?.frequency.value.end !== undefined &&
                      serviceInfo?.frequency.value.end !== null
                    )
                      serviceOrderObj.frequency_settings.end = moment(
                        serviceInfo?.frequency.value.end
                      ).format("YYYY-MM-DD");
                    if (serviceInfo?.time?.value)
                      serviceOrderObj.preferred_start_time =
                        serviceInfo?.time?.value <= 0
                          ? ""
                          : moment(serviceInfo?.time.value).format("hh:mm") ||
                            "";
                  }
                  if (serviceInfo.maintenanceCategory)
                    serviceOrderObj.is_flat_rate =
                      serviceInfo.maintenanceCategory?.value.toLowerCase() ===
                      "flat_rate"
                        ? true
                        : false;

                  serviceOrderObj.price = +priceDetail.price;
                  serviceOrderObj.service_price = +priceDetail.price;
                  serviceOrderObj.billing_address=serviceInfo?.billingInfo?.value?.billingAddress;
                  serviceOrderObj.notes = serviceInfo?.notes?.value;
                  serviceOrderObj.client_type = "residential";
                  serviceOrderObj.service_duration =
                    serviceOrderNewFieldsValue?.duration || "";
                  serviceOrderObj.reference_number =
                    serviceOrderNewFieldsValue?.referenceNumber || "";
                  const postServiceOrder = async () => {
                    const serviceData = await createServiceOrder(
                      serviceOrderObj
                    );
                    if (serviceData?.uuid) {
                      dispatch(resetValues());
                      setLoader(false);
                      message.success("Service Order is created successfully.");
                      /*const billingInfoData = await addBillingInfo({
                        user_uuid: data?.uuid,
                        address: {
                          address: data.invoice_address.address,
                          city: data.invoice_address.city,
                          zip_code: data.invoice_address.zip_code,
                          state: data.invoice_address.state,
                        },
                        stax_card_token: staxProps.id || null,
                        card_holder_name: staxProps.person_name,
                        charge_invoice_card/charge_invoice_bank: true,
                      });*/
                      if (state?.source === "customers-lead-list") {
                        sessionStorage.setItem(
                          "CACHED_INITIAL_RENDERED",
                          "true"
                        );
                        navigate("/customer-lead-list");
                      } else navigate("/residential");
                    } else {
                      setLoader(false);
                      message.error("Something went wrong!");
                    }
                  };
                  postServiceOrder();
                } else {
                  setLoader(false);
                  message.error("This email is already registered.");
                }
                // setCustomer(data?.data);
                // setLoader(false);
              };
              postCustomerCreate();
            }
          });
        }
      }
    });
  };

  return (
    <div id="wrapper">
      {loader && <Loader />}
      <div className="content-area">
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Link
          to={
            state?.source === "customers-lead-list"
              ? "/customer-lead-list"
              : "/residential"
          }
        >
          <Breadcrumb value={"Back to the Customer List"} />
        </Link>
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenubuttonClick}
        />
        <div className="fluid">
          <div className="container new-cust">
            <div className="fluid-block">
              <div className="article-block">
                <ServiceArea
                  onSetData={onSetData}
                  ref={serviceAreaFunc}
                  setServiceInfoData={setServiceInfoData}
                  serviceInfo={serviceInfo}
                  data={data}
                  setStandardRate={setStandardRate}
                />
                {renderTabs()}
                <ServiceSummary
                  serviceInfo={serviceInfo}
                  priceDetail={priceDetail}
                  data={data}
                  setPriceDetail={setPriceDetail}
                />
              </div>
            </div>
            <Footer
              btnLabel={btnLabel}
              validate={openPayment}
              source={state?.source}
            />
            {openStax ? (
              <StaxModal
                open={openStax}
                setOpen={(operation) => setOpenStax(operation)}
                onSuccessCall={validate}
                customer={{
                  address: serviceInfo?.serviceArea?.value?.address,
                  emails: serviceInfo?.serviceArea?.value?.emails || "",
                  firstName: serviceInfo?.serviceArea?.value?.firstName || "",
                  lastName: serviceInfo?.serviceArea?.value?.lastName || "",
                  phoneNumbers: serviceInfo?.serviceArea?.value?.phoneNumbers,
                }}
                staxKey={data.stax_public_apikey}
              />
            ) : (
              <></>
            )}
            {openStaxModalACH ? (
              <StaxModalACH
                open={openStaxModalACH}
                setOpen={(operation) => setOpenStaxModalACH(operation)}
                onSuccessCall={validate}
                customer={{
                  address: serviceInfo?.serviceArea?.value?.address,
                  emails: serviceInfo?.serviceArea?.value?.emails || "",
                  firstName: serviceInfo?.serviceArea?.value?.firstName || "",
                  lastName: serviceInfo?.serviceArea?.value?.lastName || "",
                  phoneNumbers: serviceInfo?.serviceArea?.value?.phoneNumbers,
                }}
                staxKey={data.stax_public_apikey}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCustomer;
