import moment from "moment-timezone";
const {createSlice} = require('@reduxjs/toolkit');

const initialState = {
    showTaskModal : false,
    selected_category_value: "General",
    customerDetails: {
        address: {
          address:"",
          state:"",
          city:"",
          zip_code:"",
       }
    },
    selected_category_name: "",
    customer:[],
    selected_task_name: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    time: "09:00:00",
    duration:30,
    selectedTechnician:"",
    taskDescription:"",
    frequency: {
        start:moment(new Date()).format("YYYY-MM-DD"),
        end: null,
        recurring: { repeat: "One Time", month: "" }
    },
    field_office_uuid:"",
    manualAddressFlag: false,
    technicians:[],
    taskDurationData:[],
    taskCategoryData:[],
    showTaskFromEdit:false,
    disableTechnicianTask:false,
    recurrenceFlag:false,
    onTaskDurationChange:false
};

const technicianTaskDataSlice = createSlice({
    name:'technicianTaskJourney',
    initialState,
    reducers:{
        addTaskCategory(state,action){
            return (state = {
                ...state,
                selected_category_value: action.payload,
              });
        },
        addTaskCategoryCustomValue(state,action){
            return (state = {
                ...state,
                selected_category_name: action.payload,
              });
        },
        toggleTaskModal(state, action) {
            return (state = {
              ...state,
              showTaskModal: action.payload,
            });
          },
        resetValueAction(state, action) {
            return (state ={
                ...state,
                selected_category_value: ""
            })
        },
        addAddressForTask(state,action){
            return (state = {
                ...state,
                customerDetails:{
                    address: action.payload
                }
            })
        },
        addManualAddress(state,action){
            return (state = {
                ...state,
                manualAddressFlag: action.payload
            })
        },
        addTaskNameAction(state, action){
            return (state = {
                ...state,
                selected_task_name: action.payload,
            })
        },
        addCustomerAction(state, action){
            return (state = {
                ...state,
                customer: action.payload,
            })
        },
        addTaskDateAction(state, action){
            return (state = {
                ...state,
                date: action.payload,
            })
        },
        addTaskTimeAction(state, action){
            return (state = {
                ...state,
                time: action.payload,
            })
        },
        addTaskDurationAction(state, action){
            return (state = {
                ...state,
                duration: action.payload,
            })
        },
        addTaskTechnicianAction(state, action){
            return (state = {
                ...state,
                selectedTechnician: action.payload,
            })
        },
        addTaskDescriptionAction(state, action){
            return (state = {
                ...state,
                taskDescription: action.payload,
            })
        },
        taskFrequencyAction(state, action){
            return (state = {
                ...state,
                frequency: action.payload,
            })
        },
        addFieldOfficeUuid(state, action){
            return (state = {
                ...state,
                field_office_uuid: action.payload,
            })
        },
        addTaskTechnicianList(state, action){
            return (state = {
                ...state,
                technicians: action.payload,
            })
        },
        getTaskDurationData(state, action){
            return (state = {
                ...state,
                taskDurationData: action.payload,
            })
        },
        getTaskCategoryData(state, action){
            return (state = {
                ...state,
                taskCategoryData: action.payload,
            })
        },
        setServiceTaskData(state, action){
            return (state = {
                ...state,
                ServiceTaskData: action.payload,
            })
        },
        setSelectedTaskData(state, action){
            return (state = {
                ...state,
                selectedTaskData: action.payload,
            })
        },
        setTaskFlagFromEdit(state, action){
            return (state = {
                ...state,
                showTaskFromEdit: action.payload,
            })
        },
        setDisableTechnicianTask(state, action){
            return (state = {
                ...state,
                disableTechnicianTask: action.payload,
            })
        },
        setFranchiseData(state, action){
            return (state = {
                ...state,
                franchiseData: action.payload,
            })
        },
        addRecurrenceAction(state, action){
            return (state = {
                ...state,
                recurrenceFlag: action.payload,
            })
        },
        updateTaskDuration(state, action) {
            return (state = {
                ...state,
                onTaskDurationChange: action.payload,
            });
        },
        resetState(state, action) {
            return (state = {
                ...state,
                ...initialState
            })
        }
    }
})

export const { addTaskCategory,
    toggleTaskModal,
    addAddressForTask,
    addManualAddress,
    addTaskNameAction,
    addTaskDateAction,
    addTaskTimeAction,
    addCustomerAction,
    resetValueAction,
    addTaskDurationAction,
    addTaskTechnicianAction,
    addTaskDescriptionAction,
    taskFrequencyAction,
    addFieldOfficeUuid,
    addTaskTechnicianList,
    getTaskDurationData,
    getTaskCategoryData,
    addTaskCategoryCustomValue,
    setServiceTaskData,
    setSelectedTaskData,
    resetState,
    setTaskFlagFromEdit,
    setFranchiseData,
    setDisableTechnicianTask,
    addRecurrenceAction,
    updateTaskDuration
} = technicianTaskDataSlice.actions;
export default technicianTaskDataSlice.reducer;