import React from "react";
import { useReport } from "../../Hooks/Report.Hook";
import FranchiseSelector from "../Filters/Franchise.Selector";
import FieldTypeSelector from "../Filters/FieldType.Selector";
import ServicesSelector from "../Filters/Services.Selector";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';

const TerritorySidebar = () => {
  const {
    franchise,
    fieldType,
    setFieldType,
    setFranchise,
    serviceType,
    setServiceType,
  } = useReport();

  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
  };
  const handleFieldTypeChange = (e) => {
    setFieldType(e);
  };

  const handleServicesChange = (e) => {
    setServiceType(e);
  };

  return (
    <>
      <div className="fitem">
        <label>
          <b>Franchise</b>
        </label>
        <FranchiseSelector
          handleChange={handleFranchiseChange}
          defaultValue={franchise}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Territory Type</b>
        </label>
        <FieldTypeSelector
          handleChange={handleFieldTypeChange}
          defaultValue={fieldType}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Services</b>
        </label>
        <ServicesSelector
          handleChange={handleServicesChange}
          defaultValue={serviceType}
        />
      </div>
    </>
  );
};

export default TerritorySidebar;
