import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useReport } from "../../Hooks/Report.Hook";
import Header from "../../../Components/Header";
import SidebarContainer from "../Containers/Sidebar.Container";
import TopMenu from "../TopMenu";
import ReportTable from "../Tables";
import ReportsData from "../../Common/ReportList.Data";

const ReportContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setSuperSearch, superSearch, buildReport } = useReport();

  const getPlaceHolder = () => {
    return ReportsData.find(item => item.id === id )?.searchPlaceHolder;
  }

  const handleSuperSearch = (e) => {
    setSuperSearch(e)
  };
  const handleSuperSearchEnter = (e) => {
    buildReport(e)
  }

  return (
    <>
      <Header
        placeholder={getPlaceHolder()}
        search_value={superSearch}
        setSearchValue={(e) => handleSuperSearch(e)}
        onSearch={(e) => e}
        onEnter={(e) => handleSuperSearchEnter(e)}
      />
      <div className={`aside-area pt-0 account_module_wrapper ${id}_container`}>
        <SidebarContainer />
        <div className="main p-0">
          <div className="block-row pt-10">
            <ul className="breadcrumb">
              <li onClick={() => navigate(-1)}>
                <span className="pointer">
                  <LeftOutlined />
                  <span style={{ marginLeft: "5px" }}>Back to all reports</span>
                </span>
              </li>
            </ul>
            {/* <div className="filter_wrapper acc-wrap p-0 mb-20"> */}
              <TopMenu />
            {/* </div> */}
            <div className="report-table" style={{width:"fit-content"}}>
              <ReportTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportContainer;
