/*eslint no-unused-vars: "off"*/
import React from "react";
import { map, isEmpty } from "lodash";
import moment from "moment-timezone";
import { Modal } from "antd";
import chevronDown from '../../Assets/images/chevron-down.png';

// maping role for hardcoded
const ROLE_MAPPING = {
  franchise_owner: "Franchise Owner",
  field_tech: "Field Tech",
  customer: "Customer",
  lead: "Lead",
  commercial_customer: "Commercial Customer",
  commercial_lead: "Commercial Lead",
  business_dev_franchiese: 'Business Dev Franchiese',
  accounting_franchiese: 'Accounting Franchise',
  accounting_corporate: 'Accounting Corporate',
  service_rep: "Call Center",
  operations_mgr: "Operations Manager",
  admin: "Corporate",
};

class DataTable {
  // // format phone number
  // formatPhoneNumber = (phone) => {
  //   phone = phone?.replace(/\D/g, "");
  //   return (
  //     phone?.substr(0, 3) + " " + phone?.substr(3, 3) + " " + phone?.substr(6)
  //   );
  // };

  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date)
  }

  //User List
  UserList = (state,setState,ShowNav,editUSer,deleteUSer, resetPwd) => {
    const userColumns = [
      {
        title: "Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: true,
        className: "tname",
        render: (text, record) => (
          <span className="type ellipse">
            {record.first_name} {record.last_name}
          </span>
        ),
      },
      {
        title: "Phone Number",
        dataIndex: "phone_numbers",
        key: "phone_numbers__phone",
        className: "tphnum",
        sorter: true,
        render: (text, record) => {
          let phone_numbers = "", item = record?.phone_numbers
          if (!isEmpty(record?.phone_numbers)) {
            for( let i = 0; i < record?.phone_numbers?.length; i++) {
              if (item[i]?.primary) {
                phone_numbers = item[i]?.phone
                break
              }
            }
          }

          return (
            <span>
              {phone_numbers ? `${phone_numbers}` : ""}
            </span>
          );
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        className: "temail",
        sorter: true,
        render: (text) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Group",
        dataIndex: "group",
        key: "group",
        className: "tgrp lfbdr",
        sorter: false,
        render: (text, record) => (
          <span className="type ellipse">
            {record.group && record.group.name && record.group.name}
          </span>
        ),
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        sorter: false,
        className: "trole",
        render: (text, record) => (
          <span className="type ellipse">
            {
            (record?.role.includes("franchise_owner") && record?.role.includes("field_tech")) || (record?.role.includes("operations_mgr") && record?.role.includes("field_tech")) ? 
              ROLE_MAPPING["franchise_owner"] : ROLE_MAPPING[text.join(",")]
          }
          </span>
        ),
        // render: (text) => (
        //   <span className="type ellipse">{ROLE_MAPPING[text.join(",")]}</span>
        // ),
      },
      {
        title: "Franchise",
        dataIndex: "staff_profile",
        key: "staff_profile",
        className: "tprofile",
        sorter: false,
        render: (text, record) => (
          <span className="type ellipse">
            {record.staff_profile &&
            record.staff_profile.field_office &&
            record.staff_profile.field_office.name
              ? record.staff_profile.field_office.name
              : ""}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "is_active",
        key: "is_active",
        className: "tstatus",
        sorter: false,
        render: (text, record) => (
          <span className="type">
            {record.is_active ? (
              <span className="text-successs">Active</span>
            ) : (
              <span style={{ color: "#d12619" }}>InActive</span>
            )}
          </span>
        ),
      },
      {
        title: "Joined",
        dataIndex: "date_joined",
        key: "date_joined",
        className: "tdate",
        sorter: false,
        render: (text) => {
          return <span className="type no-wrap">{this.UTCToLocal(text).fromNow(true)}</span>
        },
      },
      {
        title: "",
            dataIndex: "uuid",
            key: "uuid",
            className: "taction",
            sorter: false,
            render: (text, record) => (
          <div
            className="table_action_wrapper cursor_pointer"
            ref={(node) => {
              ShowNav.Node = node;
            }}
          >
            <span
              className={
                state.isNav === record.uuid && state.openNav
                  ? "las la-ellipsis-v las_success"
                  : "las la-ellipsis-v"
              }
              onClick={(e) => {
                e.stopPropagation();
                ShowNav(record, text);
              }}
            ></span>
            <ul
              className={
                state.isNav === record.uuid && state.openNav
                  ? "table_Action_menu"
                  : "d-none"
              }
            >
              <li>
                <span
                  className={state.is_edit_user ? "" : "disbale_btn"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (state.is_edit_user) {
                      editUSer(record);
                    }
                  }}
                >
                  Edit
                </span>
              </li>
              <li>
                <span
                  className={state.is_delete_user ? "" : "disbale_btn"}
                  onClick={(e) => {
                    if (state.is_delete_user) {
                      setState((state)=>({...state, visible: true, delete_id: record.uuid }));
                    }
                  }}
                >
                  Delete
                </span>
              </li>
              <li>
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    resetPwd(record);
                  }}
                >
                  Reset Password
                </span>
              </li>
            </ul>            
           </div>
          // <div
          //   className="table_action_wrapper cursor_pointer"
          // >
          //   <span
          //     className={
          //       state.isNav === record.uuid && state.openNav
          //         ? "las la-ellipsis-v las_success"
          //         : "las la-ellipsis-v"
          //     }
          //     onClick={() => {
          //       ShowNav(record, text);
          //     }}
          //   ></span>
          //   <ul
          //     className={
          //       state.isNav === record.uuid && state.openNav
          //         ? "table_Action_menu"
          //         : "table_Action_menu"
          //     }
          //   >
          //     <li>
          //       <span
          //         className={state.is_edit_user ? "" : "disbale_btn"}
          //         onClick={(e) => {
          //           e.preventDefault();
          //           if (state.is_edit_user) {
          //             editUSer(record);
          //           }
          //         }}
          //       >
          //         Edit
          //       </span>
          //     </li>
          //     <li>
          //       <span
          //         className={state.is_delete_user ? "" : "disbale_btn"}
          //         onClick={(e) => {
          //           if (state.is_delete_user) {
          //             setState((state)=>({...state, visible: true, delete_id: record.uuid }));
          //           }
          //         }}
          //       >
          //         Delete
          //       </span>
          //     </li>
          //   </ul>
          //   <Modal
          //     title="Delete User"
          //     visible={state.visible}
          //     okText={"Yes"}
          //     cancelText={"No"}
          //     onOk={deleteUSer}
          //     onCancel={() => {
          //       setState((state)=>({...state, visible: false }));
          //     }}
          //     className="delete_user_modal"
          //     width={400}
          //   >
          //     <h2> Are You Sure ?</h2>
          //   </Modal>
          // </div>
        ),
      },
    ];
    return userColumns;
  };

  //Group List
  GroupList = (self) => {
    // const {is_call_center, is_admin, is_account_corporate} = this.getLoggedInUserRole()
    const groupColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: true,
        className: "tname",
        render: (text, record) => <span className="type">{text}</span>,
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "user_counts",
        sorter: true,
        className: "tuser",
        render: (text, record) => <span className="type">{record.user_counts}</span>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: false,
        className: "tdesc",
        render: (text, record) => <span className="type ellipse">{text}</span>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: false,
        className: "tstatus lfbdr",
        filterIcon: (filtered) => <i className="las la-filter"></i>,
        filterDropdown: (
          <div className="action-dropdown">
            <div className="aditem fitem">
              <div className="fitem-check">
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id="active_grp"
                  // checked={self.state.group_list_filter.status === "active"}
                  onChange={(e) => {
                    let { group_list_filter } = self.state;
                    if (e.target.checked) {
                      group_list_filter = Object.assign(group_list_filter, {
                        status: "active",
                      });
                    } else {
                      group_list_filter = Object.assign(group_list_filter, {
                        status: "",
                      });
                    }

                    let data = {
                      offset: 0,
                      limit: 15,
                      ...self.state.pagination,
                      ...group_list_filter,
                    };

                    Object.keys(data).forEach(
                      (key) =>
                        (data[key] === null || data[key] === "") &&
                        delete data[key]
                    );

                    self.props.getGroupList(data);

                    self.setState({ group_list_filter, loader: true });
                  }}
                />
                <label htmlFor="active_grp" className="fitem-ck-txt">
                  Active
                </label>
              </div>
              <div className="fitem-check">
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id="in_active_grp"
                  // checked={self.state.group_list_filter.status === "inactive"}
                  onChange={(e) => {
                    let { group_list_filter } = self.state;
                    if (e.target.checked) {
                      group_list_filter = Object.assign(group_list_filter, {
                        status: "inactive",
                      });
                    } else {
                      group_list_filter = Object.assign(group_list_filter, {
                        status: "",
                      });
                    }
                    let data = {
                      offset: 0,
                      limit: 15,
                      ...self.state.pagination,
                      ...group_list_filter,
                    };

                    Object.keys(data).forEach(
                      (key) =>
                        (data[key] === null || data[key] === "") &&
                        delete data[key]
                    );

                    self.props.getGroupList(data);

                    self.setState({ group_list_filter, loader: true });
                  }}
                />
                <label htmlFor="in_active_grp" className="fitem-ck-txt">
                  InActive
                </label>
              </div>
            </div>
          </div>
        ),

        render: (text, record) => (
          <span className="type">
            {record.status === "active" ? (
              <span className="text-successs">Active</span>
            ) : (
              <span style={{ color: "#d12619" }}>InActive</span>
            )}
          </span>
        ),
      },
      {
        title: "Date Created",
        dataIndex: "created_at",
        key: "created_at",
        sorter: false,
        className: "tdate",
        render: (text) => {
          return <span className="type">{this.UTCToLocal(text).format("MMM, DD, yyy")}</span>
        },
      },
      // {
      //   title: "",
      //   dataIndex: "uuid",
      //   key: "uuid",
      //   className: "taction",
      //   sorter: false,
      //   render: (text, record) => {
      //     if (!is_call_center) {
      //       return (
      //         <div
      //           className="table_action_wrapper cursor_pointer"
      //           ref={(node) => {
      //             self.node = node;
      //           }}
      //         >
      //           <span
      //             className={
      //               self.state.isNav === record.uuid && self.state.openNav
      //                 ? "las la-ellipsis-v las_success"
      //                 : "las la-ellipsis-v"
      //             }
      //             onClick={() => {
      //               self.ShowNav(record, text);
      //             }}
      //           ></span>

      //           <ul
      //             className={
      //               self.state.isNav === record.uuid && self.state.openNav
      //                 ? "table_Action_menu"
      //                 : "d-none"
      //             }
      //           >
      //             <li>
      //               <span
      //                 onClick={(e) => {
      //                   e.preventDefault();
      //                   self.editGroup(record);
      //                 }}
      //               >
      //             Edit
      //               </span>
      //             </li>
      //             <li>
      //               <span
      //                 onClick={(e) => {
      //                   self.setState({ visible: true, delete_id: record.uuid });
      //                 }}
      //               >
      //             Delete
      //               </span>
      //             </li>
      //           </ul>
      //           <Modal
      //             title="Delete User"
      //             visible={self.state.visible}
      //             okText={"Yes"}
      //             cancelText={"No"}
      //             onOk={() => {
      //               self.deleteGroup();
      //             }}
      //             onCancel={() => {
      //               self.setState({ visible: false });
      //             }}
      //             className="delete_user_modal"
      //             width={400}
      //           >
      //             <h2> Are You Sure ?</h2>
      //           </Modal>
      //         </div>
      //       )
      //     } else return null
      //   },
      // },
    ];

    // if (is_admin || is_account_corporate || is_call_center) {
    //   groupColumns.splice(3, 0, {
    //     title: "Franchise",
    //     dataIndex: "field_office",
    //     key: "field_office",
    //     sorter: false,
    //     className: "tuser",
    //     render: (text, record) => <span className="type ellipse">{text?.name ? text?.name : 'All Franchise'}</span>,
    //   })
    // }
    return groupColumns;
  };
}

export default new DataTable();
