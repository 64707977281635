import moment from "moment-timezone";
import _ from "lodash";
import { createWorkOrder, getAvalaraTaxValue, createServiceOrder, getPoolCharacteristics } from "../../Action/Customer/index";
import {createTask , editTask} from  "../../Action/Technician/index";


const formatTime = (time) => {
  let splitedTime = time?.split(' ');
  let [hours, minutes] = splitedTime?.[0]?.split(':')
  if (splitedTime?.[1] === 'am') {
    return `${hours}:${minutes}`
  } else {
    return `${parseInt(hours) + 12}:${minutes}`
  }
}

export const createWorkOrderHelper = async (params) => {
  // Filter the selected service order from list
  const filterIt = (collection, value) => {
    return collection.filter((item) => { if (item.service_order_name === value) return item });
  }
  let getSelectedService = filterIt(params?.serviceOrderList, params?.selectedServiceOrder);

  // Format time
  let selectedTime = '';
  if (params?.time) {
    selectedTime = !params?.time ? '12:00:00' : moment(params?.time).format("HH:mm:ss") === "Invalid date" ? formatTime(params?.time) : moment(params?.time).format("HH:mm:ss");
  }

  // Get tax rate from zip code
  const getZipCode = await getAvalaraTaxValue(getSelectedService[0].service_address.zip_code);

  // Payload
  let data = {
    status: "forecast",
    scheduled_appointment: params?.date,
    problem_description: params?.description_notes,
    completion_notes: null,
    customer_sign: "",
    work_order_name: getSelectedService[0]?.service_order_name,
    services_lineitem: [],
    update_services_lineitem: [],
    delete_services_lineitem: [],
    pool_size: getSelectedService[0]?.pool_characteristic.pool_size,
    tax_rate: getZipCode.tax_rate,
    email: false,
    bill_for_cancelization: null,
    service_order_number: getSelectedService[0]?.uuid,
    time: selectedTime,
    technician_uuid: params?.preferredTechnician?.user?.uuid
  };

  // service-ftech API
  let response = []
  return response = await createWorkOrder(data);
}

export const createServiceOrderHelper = async (
  customerData,
  serviceOrderData,
  serviceOrderPriceData,
  serviceOrderFields
) => {
  const filterData = (collection, value) => {
    return collection?.filter((item) => {
      if (item?.user?.first_name + " " + item?.user?.last_name === value)
        return item;
    });
  };
  let getSelectedTechnician = filterData(
    serviceOrderData?.technician?.technicianData,
    serviceOrderData?.technician?.value
  );

  let startDate = "";
  let frequencySetting = "";
  let rateType = false;
  let serviceType = "";
  let userNotes = "";

  if (serviceOrderData.serviceType.key == "maintenance") {
    startDate = serviceOrderData?.frequency?.value?.start;
    frequencySetting = serviceOrderData?.frequency?.value;
    rateType =
      serviceOrderData?.maintenanceCategory?.value == "Flat_Rate"
        ? true
        : false;
    serviceType = serviceOrderData?.serviceType?.value;
  } else if (serviceOrderData.serviceType.key == "service") {
    startDate = serviceOrderData?.frequency?.value?.start;
    frequencySetting = serviceOrderData?.frequency?.value;

    rateType = false;
    if (serviceOrderData?.worksubtype?.value) {
      serviceType = serviceOrderData?.worksubtype?.value;
    } else {
      serviceType = serviceOrderData?.service?.value;
    }
  } else if (
    serviceOrderData?.serviceType?.key === "construction" ||
    serviceOrderData?.serviceType?.key == "renovation"
  ) {
    startDate = new Date(
      serviceOrderData?.preferredDate?.value
    ).toLocaleDateString("fr-CA");
    frequencySetting = {
      recurring: {
        repeat: "One Time",
      },
      start: startDate,
    };
    rateType = false;
    serviceType = serviceOrderData?.serviceType?.value;
    userNotes = serviceOrderData?.notes?.value || "";
  }

  // Payload
  let data = {
    status: customerData?.customer?.status,
    service_address_uuid: customerData?.address?.uuid || undefined,
    field_office_uuid: customerData?.customer?.field_office?.uuid,
    client_uuid: customerData?.customer?.uuid,
    is_service: true,
    proposal_date: startDate,
    contract_date: startDate,
    preferred_start_date: startDate,
    service_type_uuid: serviceType,
    is_terms_accepted: true,
    pool_type_uuid: serviceOrderData?.poolType?.uuid || null,
    pool_size: serviceOrderData?.poolSize?.value,
    pool_name: serviceOrderData?.poolName?.value,
    frequency_settings: frequencySetting,
    is_flat_rate: rateType,
    price: serviceOrderPriceData?.price || 0,
    service_price: serviceOrderPriceData?.price || 0,
    client_type: customerData?.customer?.client_type,
    notes: userNotes,
    preferred_technician_uuid: getSelectedTechnician?.[0]?.user?.uuid,
    service_duration: serviceOrderFields?.duration || null,
    reference_number: serviceOrderFields?.referenceNumber || null,
    service_address: serviceOrderData?.address?.value,
  };

  if (
    serviceOrderData?.serviceType?.key === "maintenance" ||
    serviceOrderData?.serviceType?.key === "service"
  )
    data.preferred_start_time =
      serviceOrderData.time?.value <= 0
        ? ""
        : moment(serviceOrderData.time.value).format("hh:mm") || "";
  // service-ftech API
  let response = [];
  return (response = await createServiceOrder(data));
};

export const createTaskHelper = async (params) => {

  // Format time
  let address ={};
  let frequency ={};
  
  if (params?.manualAddressFlag) {
    address = {
      "address": params?.customerDetails?.address?.address,
      "address2": null,
      "city": params?.customerDetails?.address?.city,
      "state": params?.customerDetails?.address?.state,
      "zip_code": params?.customerDetails?.address?.zip_code
    }
  }
  else {
    address = {
      "address": params?.customer?.address?.[0]?.address,
      "address2": null,
      "city": params?.customer?.address?.[0]?.city,
      "state": params?.customer?.address?.[0]?.state,
      "zip_code": params?.customer?.address?.[0]?.zip_code
    }
  }

  if(params?.frequency){
    if (params?.frequency?.start) {
      frequency = {
        start: params?.frequency?.start || null,
        end: params?.frequency?.end || null,
        recurring: {
          ...params?.frequency?.recurring,
          "repeat": params?.frequency?.recurring?.repeat || "One Time"
        }
      }
    }
    else{
      frequency = {
        start: params?.date,
        end: null,
        recurring: { "repeat": "One Time" }
      }
    }
  }
  // Payload
  let data = {
    "task_category": params?.selected_category_value === "Add Category" ? params?.selected_category_name : params?.selected_category_value,
    "task_name": params?.selected_task_name,
    "scheduled_date": frequency?.start ? frequency?.start : params?.date,
    "scheduled_time": params?.time,
    "frequency_settings": frequency || null,
    "duration": params?.duration,
    "technician_uuid": params?.selectedTechnician || null,
    "customer_uuid": params?.customer?.uuid || null,
    "address": address || null,
    "description": params?.taskDescription || null,
    "status": "Forecast",
    "field_office_uuid": params?.field_office_uuid
  };
  let editTaskData = {};

  if(params?.showTaskFromEdit === true){
    editTaskData = {
      "frequency_settings": _.isEqual(params?.selectedTaskData?.frequency_settings, params?.frequency) ? null : frequency,
      "task_status": params?.selectedTaskData?.status || null,
      "task_category": params?.selectedTaskData?.task_category == params?.selected_category_value ? params?.selectedTaskData?.task_category : params?.selected_category_value === "Add Category" ? params?.selected_category_name : params?.selected_category_value,
      "task_name": params?.selected_task_name ? params?.selected_task_name : params?.selectedTaskData?.title,
      "task_duration": params?.selectedTaskData?.duration == params?.duration ? params?.selectedTaskData?.duration : params?.duration,
      "task_description": params?.taskDescription || null,
      "customer_uuid": params?.customer?.uuid ? params?.customer?.uuid : params?.selectedTaskData?.customer?.uuid || null,
      "reassign_to": params?.selectedTechnician || null,
      "reassign_date": params?.date,
      "reassign_time": params?.time,
      "address": address || null,
      "is_reassign_future_tasks" : params?.recurrenceFlag
    };
  }

  let response = []
  if (params?.showTaskFromEdit === true) {
      return response = await editTask(params?.selectedTaskData?.work_order_uuid,editTaskData);
  }
  else
    return response = await createTask(data);
}