import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Checkbox } from "antd";
import _ from "lodash";
import { FilterOutlined } from "@ant-design/icons";

const StaffSelector = ({ handleChange = () => {}, staffList = [] }) => {
  const [filterList, setFilterList] = useState([]);

  const handleCheckboxClick = (e) => {
    let checkedList = [];
    if (e.target.data.toLowerCase() === "all") {
      if (e.target.checked) {
        checkedList = staffList.map((staff) => staff.name);
      } else {
        checkedList = [];
      }
    } else {
      checkedList = e.target.checked
        ? [...filterList, e.target.data]
        : filterList.filter((item) => item !== e.target.data && item !== "All");
    }
    if (
      _.isEqual(
        ["All", ...checkedList].sort(),
        staffList.map((staff) => staff.name).sort()
      )
    ) {
      checkedList = staffList.map((staff) => staff.name);
    }

    setFilterList(checkedList);
    handleChange(checkedList);
  };

  useEffect(() => {
    let userTags = staffList.map((staff) => staff.name);
    setFilterList(userTags);
  }, [staffList]);

  const WorkerMenu = (
    <Menu
    className="staffAssignMenu"
      items={staffList.map((item) => {
        return {
          key: item.name,
          label: (
            <Checkbox
              onChange={handleCheckboxClick}
              onClick={(e) => e.stopPropagation()}
              checked={filterList.includes(item.name)}
              data={item.name}
            >
              {item.name}
            </Checkbox>
          ),
        };
      })}
    ></Menu>
  );

  return (
    <>
      <Dropdown overlay={WorkerMenu} trigger={["click"]}>
        <button className="btn">
          <FilterOutlined />
        </button>
      </Dropdown>
    </>
  );
};

export default StaffSelector;
