import React from "react";
import { Row, Col } from "antd";
import FranchiseSelector from "../Filters/Franchise.Selector";
import StaffSelector from "../Filters/Staff.Selector";
import { useCalendar } from "../../Hooks/Calendar.Hook";
import ToggleAddList from '../PopupView/ToggleAddList';
const FilterHeaderMobile = ({ setEvents = () => ({}) }) => {
  const { franchise, setFranchise, setTechnician, setStatus } = useCalendar();
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const role = userInfo && userInfo?.user?.user_roles[0]?.name;
  const leadTechnician = userInfo && userInfo?.user?.is_lead_technician;

  return (
    <>
      <Row justify="start" style={{ width: "100%"}} className={`list-group ${!(role?.includes("field_tech") && !leadTechnician) ? "" : "list-view-mobile-double"}`} align="middle">
        <Col span={8} md={5} xs={10}>
          <FranchiseSelector
            //allValue={true}
            onControlChange={({ value, label, address }) => {
              setEvents([]);
              setFranchise({ value, label, address });
            }}
          />
        </Col>
        <Col span={8} md={5} xs={!(role?.includes("field_tech") && !leadTechnician) ? { span: 10, offset: 1 } : {span: 12, offset: 1}}>
          <StaffSelector
            franchise={franchise}
            autoSelection={true}
            onControlChange={({ value, label, start_location, stop_location }) => {
              setEvents([]);
              setTechnician({ value, label, start_location, stop_location });
            }}
            unassign={true}
          />
        </Col>
        { !(role?.includes("field_tech") && !leadTechnician) ?
        <Col md={12} xs={3}>
          <ToggleAddList isCalendar={true}/>
        </Col>: <Col></Col>}
      </Row>
    </>
  );
};

export default FilterHeaderMobile;
