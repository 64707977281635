import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from "antd";
import { Modal, Divider } from "antd";
import "../../Assets/css/customerMaintenanceRport.css";


const ChemicalModel = (props) => {
  const excludes = ['date', 'service_uuid', 'work_order_number'];
  return (
    <div>
      <Modal
        title={props.title}

        visible={props.show}
        onOk={true}
        confirmLoading={props.show}
        onCancel={props.close}
        className="chemical_data_modal"
        style={{
          top: 20,
          backdropFilter: blur
        }}
        footer={null}
      >

        {props.show ?
          <>
            {props.data?.length > 0 ? (props.data?.map((data, i) => (
              <>
                <b className="no-wrap"></b>
                <Card
                  className="middle-section"
                  title={(props.type === 'chemicals_readings') ? 'Chemical Readings' : 'Chemicals Added'}
                  extra={<span className="chemical_header_date">{moment(props.data[i]?.date).format('MM/DD/YYYY')}</span>}
                  headStyle={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    borderBottom: "1px solid #E1E1E5",
                  }}
                  bodyStyle={{
                    padding: "24px",
                  }}
                  key={i}
                >
                  {Object.entries(data).map((value) => (
                    (!excludes.includes(value[0]) &&
                      <Row justify="space-around" className="chemical_row ">
                        <Col xs={12} sm={24} md={11} lg={11}>{value[0]}</Col>
                        <Col className="chemical_col" xs={12} sm={24} md={11} lg={11}>{value[1]}</Col>
                      </Row>
                    )
                  ))}
                </Card>
                <br />
              </>
            ))) :
              (
                <>
                  <Card
                    className="middle-section-no-data"
                    title={""}
                    headStyle={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      borderBottom: "1px solid #E1E1E5",
                      paddingLeft: "20px !important",
                      marginTop: "12px !important",
                    }}
                    bodyStyle={{
                      padding: "0px", fontSize: "16px",
                      fontWeight: "bold"
                    }}
                  >
                    <Divider style={{ 'margin-top': '0px', 'margin-bottom': '24px' }} />
                    {'No previous workorder data available'}
                  </Card>
                </>
              )
            }
          </>
          : null}

      </Modal>
    </div>
  );
};
export default ChemicalModel;
