import axiosInstance from "../../Lib/Axios";

// get list of technicians
export const getTechnicians = () => {
  return axiosInstance
    .get(`ftech/field-reassign-technicians/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const createTask = (params) => {
  return axiosInstance
    .post(`/tasks/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getTaskDuration = () => {
  return axiosInstance
    .get(`tasks/task_duration/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const getTaskCategory = (params) => {
  return axiosInstance
  .get(`tasks/task_category/`, { params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const editTask = (TaskId, params) => {

  return axiosInstance
    .patch(`/tasks/task_update/?task_uuid=${TaskId}`, {...params} )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};