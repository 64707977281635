import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { orderBy } from "lodash";
import { getFieldOfficeStaff } from "../../../Action/BulkReassign";
import { getFranchiseSelected } from '../../../Utils/selectedFranchise';
import { useReport } from "../../Hooks/Report.Hook";
const TechnicianSelector = ({
  handleChange,
  defaultValue,
  allValue = true,
  error = false,
}) => {
  const {
    franchise
  } = useReport();
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo.user.user_roles[0].display_name === "Corporate"
      ? true
      : false;

  const [technicianList, setTechnicianList] = useState([]);
  const getTechnicainList = async () => {
    let field_tech_obj = {field_office__uuid: null};
    if(getFranchiseSelected()!== null && getFranchiseSelected()?.uuid !== 'all')
      field_tech_obj = {
        field_office__uuid: getFranchiseSelected().uuid,
      };
    const data = await getFieldOfficeStaff(field_tech_obj);
    setTechnicianList(data ? data.results : []);    
  };
  useEffect(() => {
    getTechnicainList();
  }, []);
  useEffect(() => {
    getTechnicainList();
  }, [franchise]);
  return (
    <>
      {(
        <div className="mb-10 field_search_bar select-multiple">
          <Select value={(technicianList.length === 1)?technicianList[0].uuid :defaultValue}
          mode={"multiple"}
          onChange={(value, options) => handleChange(value, options)}>
            {technicianList.length > 1 ?
              <Select.Option key={"technicianAll"} value={"all"} label={"All"}>
                {"All"}
              </Select.Option>
              :
              <></>
            }
            {orderBy(technicianList, [item => (item?.user?.first_name||"").toLowerCase()], "asc")?.map((option) => (
              <Select.Option
                key={option?.uuid}
                value={option?.uuid}
                label= {`${option?.user?.first_name || ""} ${option?.user?.last_name || ""}`}
                
              >
                {`${option?.user?.first_name || ""} ${option?.user?.last_name || ""}`}
              </Select.Option>
            ))}
            {/* <Select.Option
              value={franchiseList[0]?.uuid}
              label={franchiseList[0]?.name}
            >
              {franchiseList[0]?.name}
            </Select.Option> */}
          </Select>
        </div>
      )}
    </>
  );
};

export default TechnicianSelector;
