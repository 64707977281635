import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { map, isEmpty } from "lodash";
import moment from "moment-timezone";
import { DatePicker, message, Modal, Popconfirm, Select } from "antd";
import * as yup from "yup";
import visa from "../../Assets/images/visa_dark.svg";
import bank from "../../Assets/images/Bank.png";
import american from "../../Assets/images/american_express_dark.svg";
import plus_icon_green from "../../Assets/images/add-circle-outline-green.svg";
import AllState from "../../Assets/data/stateList.json";
import Loader from "../../Components/Loader";
import { initialCleaningDays } from "../../Action/Franchise";
import AddressField from "../../Components/AddressField";
import {
  addBillingInfo,
  getUserBillingInfo,
  updateBillingInfo,
  deleteBillingInfo,
} from "../../Action/Customer";
import {
  getCustomerDetails,
  createCustomerInstax,
  createAscPaymentData,
  createCardPaymentData,
} from "../Common/utils";
const { Option } = Select;

const BillingInfoLead = forwardRef((props, ref) => {
  const [errors, setErrors] = useState({});
  const [isBasicDetail, setIsBasicDetail] = useState(false);
  const [preferredStartDate, setPreferredStartDate] = useState("");
  const [timeRange, setTimeRange] = useState("");
  const [displayPreferredStartDate, setDisplayPreferredStartDate] =
    useState("");
  const [contactMethods, setContactMethods] = useState([]);
  const [isPrintAndMailInvoices, setIsPrintAndMailInvoices] = useState(false);
  const [isEmailInvoice, setIsEmailInvoice] = useState(false);
  let checkForBillingServiceAddress = '';
  if(Object.keys(props.serviceInfo?.billing_address?.value).length > 0) {
    if(JSON.stringify(props.serviceInfo?.address?.value?.formatted_address) === JSON.stringify(props.serviceInfo?.billing_address?.value?.formatted_address)){
      checkForBillingServiceAddress = true;
  }
    else checkForBillingServiceAddress = false
  }
  else {
    checkForBillingServiceAddress = true;
  }
  
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(checkForBillingServiceAddress);
  const [billingAddress, setBillingAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    address2: "",
  });
  const [serviceAddress, setServiceAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    address2: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cardList, setCardList] = useState([]);
  const [cardSelect, setCardSelect] = useState("");
  const [chargeInvoiceCard, setChargeInvoiceCard] = useState(true);
  const [isAgreed, setIsAgreed] = useState(true);
  const [isAddNewCard, setIsAddNewCard] = useState(false);
  const [staxPublicApikey, setStaxPublicApikey] = useState("");
  const [card_expiry_month, setCardExpiryMonth] = useState("");
  const [card_expiry_year, setCardExpiryYear] = useState("");
  const [card_holder_name, set_card_holder_name] = useState("");
  const [fattJs, setFattJs] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [userBillingInfoUuid, setUserBillingInfoUuid] = useState("");
  const [address, setAddress] = useState("");
  const card_number = "";
  const card_cvv = "";

  const [isNewACHPament, setisNewACHPament] = useState(false);
  const [BankName, setBankName] = useState("");
  const [RoutingNumber, setRoutingNumber] = useState("");
  const [AccountHolderName, setAccountHolderName] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [AccountType, setAccountType] = useState("");
  const [BankHolderType, setBankHolderType] = useState("");
  const [stax_customer_id, setstax_customer_id] = useState("");
  const [charge_invoices_ACHPayment, setcharge_invoices_ACHPayemnt] =
    useState(true);
  //   const address='';
  // mapping contact method
  const time_range = [
    { key: "AM (8:00 to 12:00)", value: "am" },
    { key: "PM (1:00 to 5:00)", value: "pm" },
    { key: "Flexible", value: "flexible" },
  ];
  const contact_methods = [
    { key: "By Text", value: "text" },
    { key: "Call Home Phone", value: "home_phone" },
    { key: "Call Mobile", value: "mobile" },
    { key: "By Email", value: "email" },
  ];
  const invoice_preferences = [
    { key: "Print Invoice", value: "is_print_and_mail_invoices" },
    { key: "Email Invoice", value: "is_email_invoice" },
  ];
  const preferred_service_days = [
    { key: "Any Day", value: "any" },
    { key: "Sunday", value: "sunday" },
    { key: "Monday", value: "monday" },
    { key: "Tuesday", value: "Tuesday" },
    { key: "Wednesday", value: "wednesday" },
    { key: "Thursday", value: "thursday" },
    { key: "Friday", value: "friday" },
    { key: "Saturday", value: "saturday" },
  ];
  const payment_methods = [
    { key: "Cash", value: "cash" },
    { key: "Check", value: "Check" },
    { key: "Credit Card", value: "card" },
    { key: "ACH Payments", value: "ACH Payments" },
  ];

  useImperativeHandle(ref, () => ({
    // card validation
    validate(action, staxProps) {
      const schema = yup.object().shape({
        contactMethods: yup
          .array()
          .min(1)
          .defined("Contact method is required"),
        preferredStartDate:
          props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !==
            "maintenance" &&
          props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !== "service"
            ? yup.string().required("Preferred Start Date is required")
            : null,
        timeRange:
          props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !==
            "maintenance" &&
          props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !== "service"
            ? yup.string().required("Time Range is required")
            : null,
        isAgreed: yup.bool().oneOf([true], "Field must be checked"),
        isPrintAndMailInvoices: 
          (isPrintAndMailInvoices === false && isEmailInvoice === false) ?
            yup.bool().oneOf([true], "Invoice preference is required")
            : null,
        paymentMethod: yup.string().required("Please select a payment method."),
        cardSelect:
          action !== "save_lead" &&
          (paymentMethod === "card" || paymentMethod === "ACH Payments")
            ? yup
                .string()
                .required(
                  paymentMethod === "card"
                    ? "Please select a card"
                    : "Please select an ach payment"
                )
            : null,
        isBillingAddressSame: yup.bool(),
        billingAddress: yup.object().when("isBillingAddressSame", {
          is: false,
          then: yup.object().shape({
            address: yup.string().required("Address is required"),
            state: yup.string().required("State is required"),
            city: yup.string().required("City is required"),
            zip_code: yup
              .string()
              .required("Zipcode is required")
              .min(5, "Your ZIP code is invalid."),
          }),
        }),
      });
      const dataObj = {
        isAgreed,
        contactMethods,
        preferredStartDate,
        timeRange,
        paymentMethod,
        cardSelect: staxProps?.id,
        billingAddress,
        isBillingAddressSame,
        isPrintAndMailInvoices,
        isEmailInvoice,
      };
      // preferredStartDate,
      return schema
        .validate(dataObj, { abortEarly: false })
        .then(() => {
          setErrors({});
          setLoader(false);
          props.setServiceInfoData({
            value: dataObj,
            key: true,
            name: "billingInfo",
          });
          return Promise.resolve("success");
          // this.props.createServiceOrder()
        })
        .catch((error) => {
          let err_data = {};
          map(error?.inner, (item) => {
            err_data[item.path] = item.message;
          });
          setErrors(err_data);
          setLoader(false);
          return Promise.reject("fail");
        });
    },
  }));

  useEffect(() => {
    //console.log("card",cardList.length);
    setLoader(true);
    const stax_public_apikey = props.data?.stax_public_apikey
      ? props.data?.stax_public_apikey
      : " ";
    setStaxPublicApikey(stax_public_apikey);
    setstax_customer_id(props?.customer?.stax_customer);
    const getInitialCleanningDays = async () => {
      const data = await initialCleaningDays({
        zip_code: props.leadData?.zip_code,
      });
      if (data?.data.length) {
        setData(data?.data);
      } else {
        let startdate = moment().format("MM-DD-YYYY");
        let new_date = [];
        var new_date1 = moment(startdate, "MM-DD-YYYY").add("days", 1);
        var new_date2 = moment(startdate, "MM-DD-YYYY").add("days", 2);
        var new_date3 = moment(startdate, "MM-DD-YYYY").add("days", 3);
        new_date.push(moment(startdate, "MM-DD-YYYY"));
        new_date.push(new_date1);
        new_date.push(new_date2);
        new_date.push(new_date3);
        setData(new_date);
      }

      setLoader(false);
      if (
        props.dataServiceOrder.length > 0 &&
        props.dataServiceOrder?.[0].uuid
      ) {
        loadData();
      }
    };
    getInitialCleanningDays();
  }, []);

  useEffect(() => {
    billingAddress.address &&
      props.setServiceInfoData({
        value: billingAddress,
        key: "",
        name: "billing_address",
      });
  }, [billingAddress]);

  useEffect(() => {
    paymentMethod &&
      props.setServiceInfoData({
        value: paymentMethod,
        key: true,
        name: "payment_method",
      });
  }, [paymentMethod]);

  useEffect(() => {
    preferredStartDate &&
      props.setServiceInfoData({
        value: preferredStartDate,
        key: true,
        name: "preferred_start_date",
      });
  }, [preferredStartDate]);

  useEffect(() => {
    card_holder_name &&
      props.setServiceInfoData({
        value: card_holder_name,
        key: true,
        name: "card_holder_name",
      });
  }, [card_holder_name]);

  useEffect(() => {
    contactMethods &&
      props.setServiceInfoData({
        value: contactMethods,
        key: true,
        name: "preferred_contact_methods",
      });
  }, [contactMethods]);

  useEffect(() => {
    !isEmpty(timeRange) &&
      props.setServiceInfoData({
        value: timeRange,
        key: true,
        name: "time_range",
      });
  }, [timeRange]);

  useEffect(() => {
    if (isBillingAddressSame === true)  {
      setBillingAddress(props.serviceInfo?.address?.value);
      setServiceAddress(props.serviceInfo?.address?.value);
      props.setServiceInfoData({
        value: true,
        key: true,
        name: "is_billing_address_same",
      });
    } else {
      setBillingAddress({
        address: JSON.stringify(props.serviceInfo?.address?.value?.formatted_address) !== JSON.stringify(props.serviceInfo?.billing_address?.value?.formatted_address) && props.serviceInfo?.billing_address?.value != undefined ? props.serviceInfo?.billing_address?.value?.address : '',
        city: JSON.stringify(props.serviceInfo?.address?.value?.formatted_address) !== JSON.stringify(props.serviceInfo?.billing_address?.value?.formatted_address) && props.serviceInfo?.billing_address?.value != undefined ? props.serviceInfo?.billing_address?.value?.city : '',
        state: JSON.stringify(props.serviceInfo?.address?.value?.formatted_address) !== JSON.stringify(props.serviceInfo?.billing_address?.value?.formatted_address) && props.serviceInfo?.billing_address?.value != undefined ? props.serviceInfo?.billing_address?.value?.state : '',
        zip_code: JSON.stringify(props.serviceInfo?.address?.value?.formatted_address) !== JSON.stringify(props.serviceInfo?.billing_address?.value?.formatted_address) && props.serviceInfo?.billing_address?.value != undefined ? props.serviceInfo?.billing_address?.value?.zip_code : '',
        address2: JSON.stringify(props.serviceInfo?.address?.value?.formatted_address) !== JSON.stringify(props.serviceInfo?.billing_address?.value?.formatted_address) && props.serviceInfo?.billing_address?.value != undefined ? props.serviceInfo?.billing_address?.value?.address2 : '',
      });
      setServiceAddress({
        address: "",
        city: "",
        state: "",
        zip_code: "",
        address2: "",
      });
    }
  }, [isBillingAddressSame]);

  const loadData = () => {
    setContactMethods(
      props.dataServiceOrder?.[0]?.preferred_contact_methods
        ? props.dataServiceOrder?.[0]?.preferred_contact_methods
        : []
    );
    setPreferredStartDate(
      props.dataServiceOrder?.[0]?.preferred_start_dates ?? []
    );
    // preferred_start_dates
    setTimeRange(props.dataServiceOrder?.[0]?.schedule_time_range ?? []);
    setPaymentMethod(
      props.dataServiceOrder?.[0]?.payment_method
        ? props.dataServiceOrder?.[0]?.payment_method
        : ""
    );
    // setTimeRange(props.dataServiceOrder?.[0]?.schedule_time_range);
    let date = props.dataServiceOrder?.[0]?.preferred_start_date
      ? moment(props.dataServiceOrder?.[0]?.preferred_start_date).format(
          "MM-DD-YYYY"
        )
      : null;
    onDataChange({ target: { checked: true, value: date } });
    const serviceInfo = [];
    serviceInfo.push({
      value: props.dataServiceOrder?.[0]?.schedule_time_range,
      key: true,
      name: "time_range",
    });
    // await userBillingInfo();
    if (
      props.dataServiceOrder?.[0]?.is_print_and_mail_invoices &&
      !props.dataServiceOrder?.[0]?.is_email_invoice
    ) {
      setIsPrintAndMailInvoices(true);
      serviceInfo.push({
        name: "invoice_preference",
        key: true,
        value: [
          { value: true, key: true, name: "is_print_and_mail_invoices" },
          { value: false, key: true, name: "is_email_invoice" },
        ],
      });
    }
    if (
      props.dataServiceOrder?.[0]?.is_email_invoice &&
      !props.dataServiceOrder?.[0]?.is_print_and_mail_invoices
    ) {
      setIsEmailInvoice(true);
      serviceInfo.push({
        name: "invoice_preference",
        key: true,
        value: [
          { value: false, key: true, name: "is_print_and_mail_invoices" },
          { value: true, key: true, name: "is_email_invoice" },
        ],
      });
    }
    if (
      props.dataServiceOrder?.[0]?.is_email_invoice &&
      props.dataServiceOrder?.[0]?.is_print_and_mail_invoices
    ) {
      setIsEmailInvoice(true);
      setIsPrintAndMailInvoices(true);
      serviceInfo.push({
        name: "invoice_preference",
        key: true,
        value: [
          { value: true, key: true, name: "is_print_and_mail_invoices" },
          { value: true, key: true, name: "is_email_invoice" },
        ],
      });
    }
    props.setServiceInfoList(serviceInfo);
  };

  const onAddressChanged = (e) => {
    setBillingAddress((address) => ({
      ...address,
      [e.target.name]: e.target.value,
    }));
  };

  /*
   * card validation
   */
  const cardValidation = () => {
    const cardSchema = yup.object().shape({
      card_number: fattJs.validNumber
        ? null
        : yup.string().nullable().required("Card Number is required"),
      card_expiry_month: yup
        .string()
        .nullable()
        .required("Card expire month is required"),
      card_expiry_year: yup
        .string()
        .nullable()
        .required("Card expire year is required")
        .min(4, "Card expire year is invalid.")
        .max(4, "Card expire year is invalid."),
      card_cvv: fattJs.validCvv
        ? null
        : yup.string().nullable().required("Card CVV is required"),
      card_holder_name: yup
        .string()
        .nullable()
        .required("Card Holder Name is required"),
      isBillingAddressSame: yup.bool(),
      billingAddress: yup.object().when("isBillingAddressSame", {
        is: false,
        then: yup.object().shape({
          address: yup.string().required("Address is required"),
          state: yup.string().required("State is required"),
          city: yup.string().required("City is required"),
          zip_code: yup
            .string()
            .required("Zipcode is required")
            .min(5, "Your ZIP code is invalid."),
        }),
      }),
    });
    const cardData = {
      isBillingAddressSame,
      billingAddress,
      card_number,
      card_expiry_month,
      card_expiry_year,
      card_cvv,
      card_holder_name,
    };

    cardSchema
      .validate(cardData, { abortEarly: false })
      .then(() => {
        setErrors({});
        setLoader(true);
        addNewCard();
        // })
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log("Errors");
        console.log(errors);
        message.error(
          `Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`
        );
      });
  };

  /**
   * Add New Card
   */
  const addNewCard = async () => {
    if (!billingAddress?.geo_location) {
      delete billingAddress?.geo_location;
    }
    const customer_data = {
      address: billingAddress ? billingAddress : serviceAddress,
      emails: props?.leadData?.emailaddress_set || "",
      firstName: props?.leadData?.first_name || "",
      lastName: props?.leadData?.last_name || "",
      method: "bank",
      phoneNumbers: props?.leadData?.phone_numbers,
    };
    const token_obj = {
      // address_1: billingAddress ? billingAddress.address : serviceAddress.address,
      // address_city: billingAddress ? billingAddress.city : serviceAddress.city,
      // address_state: billingAddress ? billingAddress.state : serviceAddress.state,
      // address_zip: billingAddress ? billingAddress.zip_code : serviceAddress.zip_code,
      month: card_expiry_month ? card_expiry_month : null,
      year: card_expiry_year ? card_expiry_year : null,
      card_number: card_number ? card_number : null,
      // email: props?.leadData?.email || '',
      firstname: card_holder_name?.split(" ")[0] || "",
      lastname: card_holder_name?.split(" ")[1] || "",
      method: "card",
      // phone: props?.leadData?.phone_numbers?.[0]?.phone || '',
      company: "ASP",
    };
    let customerData = await getCustomerDetails(
      customer_data.firstName
        ? customer_data
        : props?.serviceInfo?.serviceArea?.value
    );
    let createCustomerResp = await createCustomerInstax(customerData);
    if (createCustomerResp.id) {
      let card_data = await createCardPaymentData(
        card_holder_name,
        token_obj.firstname,
        token_obj.lastname,
        token_obj.card_number,
        token_obj.month,
        token_obj.year,
        createCustomerResp.id
      );
      fattJs
        .tokenize(card_data)
        .then((res) => {
          if (res && Object.keys(res).length > 0) {
            setCardSelect(res.id);
            setCardList([...cardList, res]);
            setLoader(false);
            setIsAddNewCard(false);

            props.setServiceInfoData({
              value: res.id,
              key: true,
              name: "cardSelect",
            });
            // cardList.push(res)
            // this.setState({isAddNewCard: false, cardList: cardList, loader : false}, () =>
            //   this.onCardClear()
            // );
          }
        })
        .catch((err) => {
          setLoader(false);
          map(err.fieldErrors, (item) => {
            message.error(item.message, 10);
          });
          message.error(err.message, 10);
        });
    }
  };

  // on preferred date changed
  // const onDataChange = (e) => {
  //   setPreferredStartDate(e.target.checked ? e.target.value : null);
  //   setDisplayPreferredStartDate(null);
  // }

  // on address change
  const onAddressChange = (e) => {
    Object.assign(billingAddress, { [e.target.name]: e.target.value });
    setBillingAddress(billingAddress);
  };

  const handleAddressChange = (value) => {
    setBillingAddress(value);
  };

  // render all state
  const renderAllState = () => {
    return map(AllState?.AllState, (item, i) => {
      return (
        <Option value={item.abbreviation} key={i}>
          {item.name}
        </Option>
      );
    });
  };

  const onTimeRangeChange = (e) => {
    setTimeRange(e.target.checked ? e.target.value : null);
    props.setServiceInfoData({
      value: e.target.value,
      key: true,
      name: "time_range",
    });
  };

  const onDataChange = (e) => {
    setPreferredStartDate(e.target.checked ? e.target.value : null);
    setDisplayPreferredStartDate(null);
    props.setServiceInfoData({
      value: e.target.value,
      key: true,
      name: "preferredDate",
    });
  };
  const onSelectDisplayPreferredStartDate = (e) => {
    setDisplayPreferredStartDate(e);
    setPreferredStartDate(moment(e).format("MM-DD-YYYY"));
  };

  // on contact method changed
  const onContactMethodChange = (e) => {
    const idx = contactMethods?.indexOf(e.target.value);
    if (idx > -1) {
      setContactMethods((prev) =>
        prev.filter((fruit) => fruit !== e.target.value)
      );
    } else {
      setContactMethods((prev) => [...prev, e.target.value]);
    }
  };

  // render extra footer
  const renderExtraFooter = () => {
    return (
      <div className="data_indicator">
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn"
        >
          Available
        </a>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn unavailable"
        >
          Unavailable
        </a>
      </div>
    );
  };

  // on billing info change
  const onTextChange = (e, type) => {
    const value = type === "bool" ? e.target.checked : e.target.value;
    if (e.target.name === "invoiceType") {
      if (value === "is_print_and_mail_invoices") {
        setIsPrintAndMailInvoices(e?.target?.checked);
        props.setServiceInfoData({
          name: "invoice_preference",
          key: true,
          value: [
            {
              value: e?.target?.checked,
              key: value,
              name: "is_print_and_mail_invoices",
            },
            { value: isEmailInvoice, key: value, name: "is_email_invoice" },
          ],
        });
      }
      if (value === "is_email_invoice") {
        setIsEmailInvoice(e?.target?.checked);
        props.setServiceInfoData({
          name: "invoice_preference",
          key: true,
          value: [
            {
              value: isPrintAndMailInvoices,
              key: value,
              name: "is_print_and_mail_invoices",
            },
            { value: e?.target?.checked, key: value, name: "is_email_invoice" },
          ],
        });
      }
    }
    if (e.target.name === "charge_invoice_card") {
      props.setServiceInfoData({
        name: "charge_invoice_card",
        key: "charge_invoice_card",
        value: e?.target?.checked
      });
      setChargeInvoiceCard(value);
    }
    if (e.target.name === "isAgreed") {
      setIsAgreed(value);
    }
  };

  // const onSelectDisplayPreferredStartDate = (e) => {
  //   setDisplayPreferredStartDate(e);
  //   setPreferredStartDate(moment(e).format('MM-DD-YYYY'));
  // }

  const handleIsBillingAddressSame = () => {
    setIsBillingAddressSame(!isBillingAddressSame);
  };
  const userBillingInfo = async () => {
    const data = await getUserBillingInfo({
      user__uuid: props?.leadData?.uuid,
    });
    setCardList(data);
  };
  // on billing value change
  const onBillingOptionsChanged = (e) => {
    const idx = cardList?.findIndex((obj) => obj.id === e);
    props.setServiceInfoData({
      value: cardList?.[idx]?.id,
      key: true,
      name: "cardSelect",
    });
    setCardSelect(cardList?.[idx]?.id);
    setUserBillingInfoUuid(cardList?.[idx]?.id);
    setAddress(cardList?.[idx]?.address);
  };

  const loadStax = () => {
    setIsAddNewCard(true);
    set_card_holder_name("");
    setCardExpiryMonth("");
    setCardExpiryYear("");
    setErrors({});
    if (staxPublicApikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(staxPublicApikey, {
        number: {
          id: "card-number",
          placeholder: "Card Number",
          style:
            "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;", // 35px is the height of my card-number div
        },
        cvv: {
          id: "card-cvv",
          placeholder: "CVV",
          style:
            "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;", // 35px is the height of my card-cvv div
        },
      });
      fattJsObj
        .showCardForm()
        .then((handler) => {
          // console.log('form loaded', handler);
        })
        .catch((err) => {
          console.error("error occur", err);
        });
      setFattJs(fattJsObj);
    } else {
      message.error(
        "Error occur while loading stax. please contact to admin.",
        10
      );
    }
  };
  const loadStaxACH = () => {
    let stax_public_apikey = props.data?.stax_public_apikey;
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {});
      setFattJs(fattJsObj);
    } else {
      message.error(
        "Error occur while loading stax. please contact to admin.",
        10
      );
    }
  };
  // ACH PAYMENT VALIDATION
  const ACHPaymentValidation = () => {
    const ACHSchema = yup.object().shape({
      BankName: yup.string().required("Please enter correct value!"),
      RoutingNumber: yup
        .string()
        .nullable()
        .required("Please enter correct value!"),
      AccountHolderName: yup
        .string()
        .nullable()
        .required("Please enter correct value!"),
      AccountNumber: yup
        .string()
        .nullable()
        .required("Please enter correct value!"),
      AccountType: yup.string().required("Please enter correct value!"),
      BankHolderType: yup.string().required("Please enter correct value!"),
    });

    const ACHData = {
      BankName,
      RoutingNumber,
      AccountHolderName,
      AccountNumber,
      AccountType,
      BankHolderType,
    };
    ACHSchema.validate(ACHData, { abortEarly: false })
      .then(() => {
        //setErrors({})
        setLoader(true);
        addNewACHPayment();
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
      });
  };
  const addNewACHPayment = async () => {
    setErrors({});
    const customer = props.customer;
    setLoader(true);
    const token_obj = {
      address: billingAddress ? billingAddress : serviceAddress,
      emails: props?.leadData?.emailaddress_set || "",
      firstName: props?.leadData?.first_name || "",
      lastName: props?.leadData?.last_name || "",
      method: "bank",
      phoneNumbers: props?.leadData?.phone_numbers,
    };
    let customerData = await getCustomerDetails(token_obj);
    let createCustomerResp = await createCustomerInstax(customerData);
    let ach_data = await createAscPaymentData(
      AccountHolderName,
      AccountNumber,
      RoutingNumber,
      BankHolderType,
      AccountType,
      createCustomerResp.id
    );
    fattJs
      .tokenize(ach_data)
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCardSelect(res.id);
          setCardList([...cardList, res]);
          setLoader(false);
          setisNewACHPament(false);
          formatModalAch();
          props.setServiceInfoData({
            value: res.id,
            key: true,
            name: "cardSelect",
          });
          setErrors({});
        }
      })
      .catch((err) => {
        console.log(err.errors[0]);
        message.error(err.errors[0]);
        setLoader(false);
      });
  };
  // const addNewACHPayment = () => {
  //   setErrors({})

  //   const customer = props.customer;
  //   setLoader(true);
  //   const token_obj = {

  //     address_1: billingAddress ? billingAddress.address : serviceAddress.address,

  //     address_city: billingAddress ? billingAddress.city : serviceAddress.city,

  //     address_state: "FL",

  //     address_zip: billingAddress ? billingAddress.zip_code : serviceAddress.zip_code,

  //     email: props?.leadData?.email || '',

  //     firstname: props?.leadData?.first_name || '',
  //     lastname: props?.leadData?.last_name || '',

  //     method: 'bank',

  //     person_name: AccountHolderName || '',

  //     phone: customer?.phone_numbers?.[0]?.phone,

  //     bank_account: AccountNumber || '',

  //     bank_routing: RoutingNumber || '',

  //     bank_holder_type: BankHolderType.toLowerCase() || '',

  //     bank_type: AccountType.toLowerCase() || '',

  //   }

  //   if (stax_customer_id) {

  //     token_obj['customer_id'] = stax_customer_id

  //   }

  //   fattJs.tokenize(token_obj).then((res) => {

  //     if (res && Object.keys(res).length > 0) {
  //       setCardSelect(res.id);
  //       setCardList([...cardList, res]);
  //       setLoader(false);
  //       formatModalAch();
  //       props.setServiceInfoData({ value: res.id, key: true, name: 'cardSelect' })
  //       setisNewACHPament(false)

  //     }

  //   }).catch((err) => {
  //     console.log(err.errors[0]);
  //     // formatModalAch();
  //     message.error(err.errors[0]);
  //     setLoader(false);

  //   });

  // }

  const choosePaymentMethod = (e) => {
    setPaymentMethod(e);
  };
  const loadACHPayment = () => {
    loadStaxACH();
    setisNewACHPament(true);
  };

  // render add card modal
  const renderAddCardModal = () => {
    return (
      <Modal
        title="Add Card"
        visible={isAddNewCard}
        onCancel={() => {
          setIsAddNewCard(false);
        }}
        footer={[
          <button
            key="cancel"
            className="btn btn-brand btn-bdr"
            onClick={() => {
              setIsAddNewCard(false);
            }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            className={`btn btn-warning`}
            // disabled={!emailText}
            onClick={cardValidation}
          >
            Add
          </button>,
        ]}
      >
        <div>
          <div className={"flex mt-10"}>
            <div className={"flex-1"}>
              <div className="form-group">
                <div className="fitem">
                  <label>
                    Card Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <div id="card-number" className="form--control"></div>
                </div>
                <div className="fitem">
                  <label>
                    CVV <span style={{ color: "red" }}>*</span>
                  </label>
                  <div id="card-cvv" className="form--control"></div>
                </div>
                <div className="fitem">
                  <label>
                    Card Holder Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="card_holder_name"
                    className="form--control"
                    placeholder="Card Holder Name"
                    value={card_holder_name}
                    onChange={(e) => set_card_holder_name(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  {errors?.["card_holder_name"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["card_holder_name"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="half-fitem fitem">
                  <div className="half-fitem fitem">
                    <label>
                      Expiration Month <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="card-exp-month"
                      type="text"
                      className="form--control"
                      placeholder="MM"
                      maxLength={2}
                      inputMode="numeric"
                      onPaste={(event) => {
                        if (
                          event.clipboardData.getData("Text").match(/[^\d]/)
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setCardExpiryMonth(e.target.value);
                      }}
                      value={card_expiry_month}
                      style={{ width: "100%" }}
                    />
                    {errors?.["card_expiry_month"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.["card_expiry_month"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="half-fitem fitem">
                    <label>
                      Expiration Year <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form--control"
                      placeholder="YYYY"
                      maxLength={4}
                      inputMode="numeric"
                      onPaste={(event) => {
                        if (
                          event.clipboardData.getData("Text").match(/[^\d]/)
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setCardExpiryYear(e.target.value);
                      }}
                      style={{ width: "100%" }}
                    />
                    {errors?.["card_expiry_year"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.["card_expiry_year"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  const formatModalAch = () => {
    setBankName("");
    setRoutingNumber("");
    setAccountHolderName("");
    setAccountNumber("");
    setAccountType("");
    setBankHolderType("");
  };
  // reender ACH payment modal
  const onCancelAch = () => {
    setErrors({});
    formatModalAch();
    setisNewACHPament(false);
  };
  const renderPaymentModal = () => {
    return (
      <Modal
        title="Bank Account"
        visible={isNewACHPament}
        onCancel={() => {
          onCancelAch(false);
        }}
        footer={[
          <button
            key="cancel"
            className="btn btn-brand btn-bdr"
            onClick={() => {
              onCancelAch(false);
            }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            className={`btn btn-warning`}
            // disabled={!emailText}
            onClick={ACHPaymentValidation}
          >
            Add
          </button>,
        ]}
      >
        <div>
          <div className={"flex mt-10"}>
            <div className={"flex-1"}>
              <div className="form-group">
                <div className="fitem">
                  <label>Bank Name</label>
                  <label style={{ color: "red" }}> &nbsp;*</label>
                  <input
                    type="text"
                    value={BankName}
                    className="form--control"
                    placeholder="Bank Name"
                    maxLength={15}
                    inputMode="text"
                    onChange={(e) => {
                      setBankName(e.target.value);
                    }}
                  />
                  {errors?.["BankName"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["BankName"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem">
                  <label>Routing Number</label>
                  <label style={{ color: "red" }}> &nbsp;*</label>
                  <input
                    type="text"
                    value={RoutingNumber}
                    className="form--control"
                    placeholder="Routing Number"
                    maxLength={9}
                    inputMode="number"
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        // invalid character, prevent input
                        e.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setRoutingNumber(e.target.value);
                    }}
                  />
                  {errors?.["RoutingNumber"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["RoutingNumber"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem">
                  <label>Account Holder Name</label>
                  <label style={{ color: "red" }}> &nbsp;*</label>
                  <input
                    type="text"
                    className="form--control"
                    value={AccountHolderName}
                    placeholder="Account Holder Name"
                    maxLength={18}
                    inputMode="number"
                    onChange={(e) => {
                      setAccountHolderName(e.target.value);
                    }}
                  />
                  {errors?.["AccountHolderName"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["AccountHolderName"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem">
                  <label>Account Number</label>
                  <label style={{ color: "red" }}> &nbsp;*</label>
                  <input
                    type="text"
                    id="account-number"
                    value={AccountNumber}
                    className="form--control"
                    placeholder="Account Number"
                    maxLength={15}
                    inputMode="number"
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        // invalid character, prevent input
                        e.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                    }}
                  />
                  {errors?.["AccountNumber"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["AccountNumber"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem">
                  <label>Account Type</label>
                  <label style={{ color: "red" }}> &nbsp;*</label>
                  <div>
                    <input
                      type="radio"
                      id="achecking"
                      name="AccountType"
                      checked={AccountType === "checking"}
                      onChange={(e) => setAccountType(e.target.value)}
                      value={"checking"}
                    />
                    &nbsp;&nbsp;
                    <label for="html">Checking</label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <input
                      type="radio"
                      id="asaving"
                      checked={AccountType === "savings"}
                      name="AccountType"
                      onChange={(e) => setAccountType(e.target.value)}
                      value="savings"
                    />
                    &nbsp;&nbsp;
                    <label for="html">Saving </label>
                  </div>
                  {errors?.["AccountType"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["AccountType"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem">
                  <label>Bank Holder Type</label>
                  <label style={{ color: "red" }}> &nbsp;*</label>
                  <div>
                    <input
                      type="radio"
                      id="checking"
                      checked={BankHolderType === "Personal"}
                      name="BankHolderType"
                      onChange={(e) => setBankHolderType(e.target.value)}
                      value="Personal"
                    />
                    &nbsp;&nbsp;
                    <label for="html">Personal</label>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <input
                      type="radio"
                      id="saving"
                      checked={BankHolderType === "Business"}
                      name="BankHolderType"
                      onChange={(e) => setBankHolderType(e.target.value)}
                      value="Business"
                    />
                    &nbsp;&nbsp;
                    <label for="html">Business</label>
                  </div>
                  {errors?.["BankHolderType"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["BankHolderType"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  //ACH PAYMENT MODAL END HERE
  return (
    <div className="adetail">
      {loader && <Loader />}
      <h3>Work Order Information</h3>
      <div className="form-block">
        {props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !==
          "maintenance" &&
        props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !==
          "service" ? (
          <div className="frow">
            <h4>Preferred Start Date</h4>
            <div className="oplist">
              {map(
                data
                  ?.sort(function (a, b) {
                    return new Date(a) - new Date(b);
                  })
                  ?.slice(0, 4),
                (item, i) => {
                  return (
                    <div
                      className="inline-checks flex-1"
                      key={`preferred_start_dates${i}`}
                    >
                      <label
                        htmlFor={item?.value}
                        className={
                          preferredStartDate ===
                          moment(item).format("MM-DD-YYYY")
                            ? "active"
                            : ""
                        }
                      >
                        <input
                          type="radio"
                          id={item?.value}
                          name="preferredStartDate"
                          checked={
                            preferredStartDate ===
                            moment(item).format("MM-DD-YYYY")
                          }
                          value={moment(item).format("MM-DD-YYYY")}
                          className=""
                          onChange={onDataChange}
                        />
                        <span>
                          {moment(item).format("MM-DD-YYYY") ===
                          moment().format("MM-DD-YYYY")
                            ? "Today"
                            : moment(item, "MM-DD-YYYY").format("ddd, MMM DD")}
                        </span>
                      </label>
                    </div>
                  );
                }
              )}
              <div className="inline-checks flex-1">
                <DatePicker
                  renderExtraFooter={(e) => renderExtraFooter}
                  inputReadOnly
                  mode={"date"}
                  className={
                    displayPreferredStartDate ? "active" : "box_opacity"
                  }
                  placeholder={"Select another date"}
                  format={"ddd, MMM DD"}
                  value={displayPreferredStartDate}
                  onChange={onSelectDisplayPreferredStartDate}
                  disabledDate={(current) => {
                    return current && current <= moment().add("days", 3);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        {errors?.[`preferredStartDate`] ? (
          <label className="new_err_msg">
            <i className="las la-info-circle" />
            {errors?.[`preferredStartDate`]}
          </label>
        ) : (
          ""
        )}
        {props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !==
          "maintenance" &&
        props?.serviceInfo?.service_type_uuid?.key.toLowerCase() !==
          "service" ? (
          <div className="frow">
            <h4>Select a time range for the visit</h4>
            <div className="oplist">
              {map(time_range, (item, i) => {
                return (
                  <div className="inline-checks flex-1" key={`timeRange${i}`}>
                    <label
                      htmlFor={item?.value}
                      className={timeRange === item.value ? "active" : ""}
                    >
                      <input
                        type="radio"
                        id={item?.value}
                        name="timeRange"
                        //  disabled={!zipcodeCheck}
                        checked={timeRange === item}
                        value={item.value}
                        className=""
                        onChange={onTimeRangeChange}
                      />
                      <span>{item?.key}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        {errors?.[`timeRange`] ? (
          <label className="new_err_msg">
            <i className="las la-info-circle" />
            {errors?.[`timeRange`]}
          </label>
        ) : (
          ""
        )}
        <div className="frow">
          <h4>Contact Method</h4>
          <div className="oplist">
            {map(contact_methods, (item, index) => {
              const isInclude = contactMethods?.includes(item?.value);
              return (
                <div
                  className="inline-checks flex-1"
                  key={`contactMethod${index}`}
                >
                  <label
                    htmlFor={item?.value}
                    className={isInclude ? "active" : ""}
                  >
                    <input
                      type="checkbox"
                      id={item?.value}
                      name="contactMethod"
                      className=""
                      value={item?.value}
                      onChange={onContactMethodChange}
                    />
                    <span>{item?.key}</span>
                  </label>
                </div>
              );
            })}
          </div>
          {errors?.[`contactMethods`] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.[`contactMethods`]}
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="frow">
          <h4>Invoice Preferences</h4>
          <div className="oplist">
            {map(invoice_preferences, (item, index) => {
              return (
                <div
                  className="inline-checks flex-1"
                  key={`invoivePreference${index}`}
                >
                  <label
                    htmlFor={item?.value}
                    className={
                      item?.value === "is_print_and_mail_invoices" &&
                      isPrintAndMailInvoices
                        ? "active"
                        : item?.value === "is_email_invoice" && isEmailInvoice
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      type="checkbox"
                      id={item?.value}
                      value={item?.value}
                      name={item?.value}
                      onChange={(e) => {
                        onTextChange({
                          target: {
                            name: "invoiceType",
                            checked: e.target.checked,
                            value: e.target.value,
                          },
                        });
                      }}
                    />
                    <span>{item?.key}</span>
                  </label>
                </div>
              );
            })}
          </div>
          {errors?.[`isPrintAndMailInvoices`] ? (
            <div>
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.[`isPrintAndMailInvoices`]}
              </label>
            </div>
          ) : (
            ""
          )}
          {/* <div className="oplist">
            {map(invoice_preferences, (item, index) => {
              return (
                <div className="inline-checks flex-1" key={`contactMethod${index}`}>
                  <label htmlFor={item?.value} className={(item?.value === 'is_print_and_mail_invoices' && isPrintAndMailInvoices) ? 'active' : (item?.value === 'is_email_invoice' && isEmailInvoice) ? 'active' : ''}>
                    <input
                      type="checkbox"
                      id={item?.value}
                      name={item?.value}
                      value={item?.value}
                      onChange={(e) => {
                        if (e.target.value === 'is_print_and_mail_invoices') {
                          setIsPrintAndMailInvoices(e?.target?.checked);
                        }
                        if (e.target.value === 'is_email_invoice') {
                          setIsEmailInvoice(e?.target?.checked)
                        }
                      }}
                    />
                    <span>{item?.key}</span>
                  </label>
                </div>
              )
            })}
          </div> */}
        </div>
        <hr />
        <div className="frow pt-10">
          <h3>Billing Address</h3>
          <div className="max-w-328">
            <div className="fitem-check mt-10 mb-10">
              <input
                type="checkbox"
                className="fitem-ck-input"
                checked={isBillingAddressSame}
                id="isBillingAddress"
                name="isBillingAddress"
                onChange={handleIsBillingAddressSame}
              />
              <label htmlFor="isBillingAddress" className="fitem-ck-txt">
                Same as Service Address
              </label>
            </div>
            <AddressField
              handleAddressChange={handleAddressChange}
              onAddressChanged={onAddressChange}
              onAddressChange={onAddressChange}
              errors={errors}
              data={billingAddress}
            />
          </div>
        </div>
        <hr />
        {/* Payment Information Block */}
        <div className="frow pt-10">
          <h3>Payment Information</h3>
          <div className="pi-block max-w-328">
            {/* Payment Method Block */}
            <div className="field-group">
              <label>
                Payment Method <sup>*</sup>
              </label>
              <div className="mb-10">
                <Select
                  placeholder="Select"
                  value={paymentMethod || undefined}
                  onChange={(e) => choosePaymentMethod(e)}
                >
                  {map(payment_methods, (item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.key}
                      </Option>
                    );
                  })}
                </Select>
                {errors?.["paymentMethod"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.["paymentMethod"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              {(paymentMethod === "card" || paymentMethod === "ACH Payments") &&
               <div className="fitem-check mt-10">
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id="charge"
                  name="charge_invoice_card"
                  value={chargeInvoiceCard}
                  onChange={(e) => onTextChange(e, 'bool')}
                />
                <label htmlFor="charge" className="fitem-ck-txt">
                  Charge invoices to {paymentMethod}
                </label>
              </div> 
              }
            </div>
          </div>
        </div>
        <hr style={{ margin: "20px 0" }} />
        <div className="frow">
          <h4> Terms of Service</h4>
          {/*TODO: Add or delete Terms of Service link */}
          <span>
            By signing up, I grant my permission for America's Swimming Pool Co.
            to access my property and agree to the full
            <a> Terms of Service</a> and
            <a href="/#"> Privacy Policy.</a>
          </span>
          <div className="fitem-check mt-10">
            <input
              id="isAgreed"
              name="isAgreed"
              checked={isAgreed}
              onChange={(e) => onTextChange(e, "bool")}
              type="checkbox"
              className="fitem-ck-input"
              //  disabled={!zipcodeCheck}
            />
            <label htmlFor="isAgreed" className="fitem-ck-txt">
              Customer verbally agreed
            </label>
          </div>
          {errors?.["isAgreed"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["isAgreed"]}
            </label>
          ) : (
            ""
          )}
        </div>
        {renderAddCardModal()}
        {paymentMethod === "ACH Payments" ? renderPaymentModal() : ""}
      </div>
    </div>
  );
});

export default BillingInfoLead;
