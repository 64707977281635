import React, { useContext, useEffect, useState,useRef } from "react";
import { useParams } from 'react-router-dom';
import * as yup from "yup";
import { map, cloneDeep, isEqual } from "lodash";
import moment from "moment-timezone";
import FileUploader from "../../../../Components/FileUploader";
import chevronLeft from "../../../../Assets/images/chevron-left.png";
import Loader from "../../../../Components/Loader";
import { isEmpty } from "lodash";
import {
  Select,
  Table,
  Input,
  DatePicker,
  TimePicker,
  Modal,
  message,
  Card,
  Row,
  Col
} from "antd";
import workOrderStatus from "../../../../Components/Common/workorderStatus.json";
import { getFieldTechnician } from "../../../../Action/Franchise/index";
import { formatToCurrency } from "../../../../Components/Common/formatToCurrency";
import DataTable from "./DataTable";
import { 
  createServiceLineItem, 
  getPrices, 
  getLines, 
  getWorkOrder, 
  getService, 
  getServiceOrderByClient, 
  sendTechnicianWorkOrder, 
  getUploadImageEditWorkOrder, 
  postUploadImage, 
} from "../../../../Action/Customer/index";
import { PlusCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import appData from "../../../../Utils/constants";
import ImageUploadWorkOrder from '../../../../Components/ImageUploadWorkOrder';
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import {resetValues, addDuration, editDuration} from "../../../../Store/serviceOrderDetailDataSlice";
import AddDurationField from "../../../../Calendar/Components/Modals/SubComponents/AddDurationField";
import {numberValidate, rateValidate} from "../../../Common/utils";

const { Option } = Select;
const { TextArea } = Input;

const PageContext = React.createContext();

const EditWorkOrderURL = (props) => {
  const ref=useRef();
  let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
  const [state, setState] = useState({
    status: "",
    technician_uuid: "",
    date: "",
    time: "",
    services_lineitem: [],
    update_services_lineitem: [],
    delete_services_lineitem: [],
    problem_description: "",
    work_order_name: "",
    completion_notes: "",
    tech_notes: "",
    tech_picture: [],
    customer_sign: "",
    customer_picture: [],
    addons: [],
    errors: {},
    email: false,
    field_office_uuid: "",
    client_uuid: "",
    service_type: "",
    pool_type: "",
    pool_size: "",
    worksubtype: "",
    worktype: "",
    price: "",
    work_order_uuid: "",
    service_order_uuid: "",
    tax_rate: "",
    avalara_tax_rate: "",
    base_price: "",
    is_invoiced: "",
    service_duration:franchise_data?.data?.default_job_duration
    // bill_for_cancelization: false
  });
  const [stateone, setStateone] = useState({
    errors: {},
    rate: "",
    quantity_item: ""
  });
  const [lineItem, setlineItem] = useState({
    include_labor: false,
    include_bundle: false
  });
  const [services_lineitem, setservices_lineitem] = useState([]);
  const [errormessagestate , seterrormessagestate] = useState(false);
  const [price, setPrice] = useState({});
  const [editMode, seteditMode] = useState(false);
  const [techForm, setTechForm] = useState([]);
  const [isAddLineVisible, setisAddLineVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalNoTax, setTotalNoTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [is_description_Edit, set_is_description_Edit] = useState(false);
  const [is_quantity_Edit, set_is_quantity_Edit] = useState(false);
  const [is_unitCost_Edit, set_is_unitCost_Edit] = useState(false);
  const [is_price_Edit, set_is_price_Edit] = useState(false);
  const [is_billable_Edit, set_is_billable_Edit] = useState(false);
  const [taxRate, settaxRate] = useState(0);
  const [addons, setAddons] = useState([]);
  const [take_diagnostic, settake_diagnostic] = useState(true);
  const [buttonLabel, setbuttonLabel] = useState('Save & Email');
  const [loader, setLoader] = useState(false);
  const [sendEmail, setsendEmail] = useState({ send: false, request: false });
  const [updateWorkOrderState, setUpdateWorkOrderState] = useState(false);
  const [uuid, setUUID] = useState('');
  const [newServiceItem, setNewServiceItem] = useState([]);

  const [imageSources, setImageSources] = useState([]);
  const [imageSourcesTech, setImageSourcesTech] = useState([])
  const [base64Image, setBase64Image] = useState({});
  const dispatch = useDispatch();
  const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);

  const clearState = () => {
    setState(() => ({ ...state, 
      cleaningAreas: [], 
      addons: [], 
      errors: {},
      update_services_lineitem:[],
      delete_services_lineitem: [],
      services_lineitem: []  
    }));
    dispatch(resetValues());
  };

  function ServiceDetails_() {
    const { currentRecord, transection_type, goback } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [field_office__uuid, setField_office__uuid] = useState("");

    const {
      date,
      time,
      lastClean,
      cleaningAreas,
      rows,
      is_initial_cleaning,
      otherCleaning,
      status,
      selectedTechnician,
      lockdate,
    } = state;
    const seachvalidate = (e) => {
      e.preventDefault();
      setTimeout(() => {
        let { rate,quantity_item } = stateone;
        const schema = yup.object().shape({
        rate:yup.number().nullable(true).typeError("Rate is required").test( 'decimal-places', 'Please Enter Valid Rate(upto two decimal places are allowed)', (value) =>/^(-?\d+(\.\d{1,2})?)?$/.test(value)),    
        quantity_item: yup.number().moreThan(0, 'Quantity is required"').typeError("Quantity is required").nullable(true).test( 'decimal-places', 'Please Enter Valid Quantity(Positive or upto two decimal places are allowed)', (value) =>/^(\d+(\.\d{1,2})?)?$/.test(value)),
      });
        const data = { rate,quantity_item };
        let IsValid;
        let err_data = {};
        schema.isValid(data).then(function (valid) {
          IsValid = valid;
        });
        setTimeout(() => {
          if (IsValid) {
            onokbtn();
          } else {
            schema.validate(data, { abortEarly: false }).catch(function (err) {
              map(err.inner, (item) => {
                err_data[item.path] = item.message;
              });
              setStateone({
                ...stateone,
                errors: err_data,
              });
              console.log("err_data:", err_data);
            });
          }
        }, 100);
      }, 300);
    };
    let { errors } = stateone;
    const onokbtn=(event)=>{
        if (editMode) {
          updateLine(event);
        } else {
          addLineItem();
        }
        setisAddLineVisible(false);
        clearLineItem();
    }
    useEffect(() => {
      if(updateWorkOrderState){
        updateWorkOrder();
        setUpdateWorkOrderState(false);
      }
    }, [updateWorkOrderState]);

    const formatTime = (time) => {
      let splitedTime = time?.split(' ');
      let [hours, minutes] = splitedTime?.[0]?.split(':')
      if (splitedTime?.[1] === 'am') {
        return `${hours}:${minutes}`
      } else {
        return `${parseInt(hours) + 12}:${minutes}`
      }
    }
    useEffect(() => {
      setLoader(true);
      const serviceList = async () => {
        let url = window.location.href;
        let temp = url.substring(url.lastIndexOf("/") + 1, url.length);
        let formName = url.split("/")[3];
        setUUID(temp);
        const workOrderParam = {
          'form_uuid': temp,
          'form_name': formName
        }
        const resp = await sendTechnicianWorkOrder(workOrderParam)
        const woInfo = await getService(temp);

        getTaxRate(woInfo);
        const wo_time = moment.tz(woInfo?.scheduled_appointment, 'UTC')
        // console.log(wo_time <= 0 ? "" : moment(wo_time).format("hh:mm a") || "")
        setState((s) => ({
          ...s,
          status: woInfo.status,
          work_order_name: woInfo.work_order_name,
          technician_uuid: woInfo.technician_uuid,
          problem_description: woInfo.problem_description,
          completion_notes: woInfo.completion_notes,
          tech_notes: woInfo.tech_notes,
          customer_sign: woInfo.customer_sign,
          customer_picture: woInfo.customer_picture,
          tech_picture: woInfo.tech_picture,
          avalara_tax_rate: woInfo.avalara_tax_rate,
          field_office_uuid: woInfo.field_office_uuid,
          client_uuid: woInfo.client_uuid,
          base_price: woInfo.base_price,
          is_invoiced: woInfo.is_invoiced,
          date: wo_time,
          time: wo_time <= 0 ? "" : moment(wo_time).format("hh:mm a") || "",
          service_order_uuid: woInfo.service_order_uuid,
          service_duration:woInfo?.service_duration
        }));
        dispatch(addDuration(woInfo?.service_duration));
        dispatch(editDuration(true));
        getServiceOrder(woInfo.field_office_uuid, woInfo.client_uuid, woInfo.service_order_uuid);
        getOptions('', woInfo.field_office_uuid);
        getImageUpload(temp);
        
      }
      serviceList();
      getWorkOrderLines();
      setTechForm(state.job_forms);
    }, []);

    const getServiceOrder = async (field_office_uuid, client_uuid, service_order_uuid) => {
      const data = await getServiceOrderByClient({
        field_office_uuid: field_office_uuid,
        client_uuid: client_uuid
      });
      const so_selected = data.find(i => i.uuid === service_order_uuid);

      setState((s) => ({
        ...s,
        service_type: so_selected?.service_type,
        pool_type: so_selected?.pool_type,
        pool_size: so_selected?.pool_size,
        worktype: so_selected?.worktype,
        worksubtype: so_selected?.worksubtype,
        price: so_selected?.price,
        service_order_uuid: so_selected?.uuid,
      }));
 
      setLoader(false);
    }

    useEffect(() => {
      CalculateTotal();
      calculateNoTax();
      calculateTax();
    }, [services_lineitem])

    useEffect(() => {
      //Remove Diagnostic quote
      removeDiagnostic();
      const index = services_lineitem.map(e => e.repair_code).indexOf(state.service_type);

      let serviceLine = services_lineitem.find(line => line.repair_code?.toLowerCase() === state.service_type?.toLowerCase());
      const updateLine = {
        ...serviceLine,
        billable: take_diagnostic
      }

      let lines = services_lineitem;
      lines[index] = updateLine;
      setservices_lineitem(lines);
      setNewServiceItem(lines);
    }, [take_diagnostic])

    const getTaxRate = (data) => {
      if (data.tax_rate) {
        settaxRate(amount(data.tax_rate));
      }
      else {
        settaxRate(amount(data.avalara_tax_rate));
      }
    }

    const updateWorkOrder = async () => {
      let SL = [];
      setLoader(true);
      let newUpadteLineItem = [];
      let updatedServiceLineItem = [];

      if(state.services_lineitem.length > 0) {
          updatedServiceLineItem = state.services_lineitem
        }else{       
        updatedServiceLineItem = []
        newUpadteLineItem = services_lineitem
        }
        dispatch(addDuration(state?.service_duration || franchise_data?.data?.default_job_duration));

        updatedServiceLineItem?.forEach(element => {
        SL.push({
          work_order_uuid: element.work_order_uuid,
          repair_code: element.repair_code,
          bundle_code: element.bundle_code ?? null,
          description: element.description,
          quantity: parseInt(element.quantity),
          unit_cost: parseFloat(element.unit_cost),
          is_taxable: element.is_taxable,
          tax: element.tax ? element.tax : "0.00",
          qb_account: element.qb_account ? element.qb_account : '',
        })
      });
      const update_services_lineitem_temp  = cloneDeep(state.update_services_lineitem);
      for (let index = update_services_lineitem_temp.length - 1; index >= 0; index--) {
        const exist = SL.find((sl)=> {return sl.repair_code == update_services_lineitem_temp[index].repair_code})
        if(exist){
          update_services_lineitem_temp.splice(index, 1);
        }
      }
      let data = {
        status: state.status,
        //date: moment().format('YYYY-MM-DD'),
        date: moment(state?.date).format('YYYY-MM-DD'),
        // time: moment().format("hh:mm a"),
        work_order_name: state.work_order_name,
        technician_uuid: state.technician_uuid,
        services_lineitem: SL,
        update_services_lineitem: update_services_lineitem_temp,
        delete_services_lineitem: state.delete_services_lineitem,
        problem_description: state.problem_description,
        completion_notes: state.completion_notes,
        tech_notes: state.tech_notes,
        customer_sign: state.customer_sign,
        customer_picture: state.customer_picture,
        tech_picture: state.tech_picture,
        // pool_size: state.pool_size,
        // tax_rate: (state.tax_rate).toString(),
        // avalara_tax_rate:state.avalara_tax_rate,
        // base_price:state.base_price,
        // is_invoiced:"",
        email: sendEmail.send,
        service_duration: serviceOrderNewFieldsValue?.duration || "",
      };
      data['time'] = !state?.time ? '12:00:00' : moment(state?.time).format("HH:mm:ss") === "Invalid date" ? formatTime(state?.time) : moment(state?.time).format("HH:mm:ss");
      const response = await createServiceLineItem(data, uuid);
      message.success(`Work order ${state.work_order_name || ""} updated.`, 10);
      if(state?.service_type?.toLowerCase() === "maintenance"){
        close();
      }
      clearState();
      setLoader(false);
      if(sendEmail.request){
        setsendEmail({ send: false, request: false });
        setbuttonLabel("Save & Re-send");
      }
    };

    const time_status = [
      "tentative",
      "scheduled",
      "technician_assigned",
      "technician_en_route",
      "in_progress",
      "complete",
      "incomplete",
    ];

    const validate = () => {
      postImageUpload();
      // updateWorkOrder();
      // console.log("inside validate")
      // let isFutureDate = false;
      // let isPastDate = false;
      // let selectTime = false;

      // let selectedTime =
      //   moment(state.time).format("hh:mm a") === "Invalid date"
      //     ? state.time
      //     : moment(state.time).format("hh:mm a");

      // if (state.status.toLocaleLowerCase() === "complete" || state.status.toLowerCase() === "incomplete") {        
      //   isFutureDate = !moment(date, "YYYY/MM/DD").isBefore(moment());        
      //   // selectTime = true;
      // }

      // if (state.status.toLocaleLowerCase() === 'forecast' || state.status.toLocaleLowerCase() === "scheduled") {
      //   isPastDate = moment(date, "YYYY/MM/DD").isBefore(moment(new Date()).format("YYYY/MM/DD"));      
      // }

      // const schema = yup.object().shape({
      //   status: yup.string().required("Please select valid status."),
      //   technician_uuid:
      //     state.status === "complete" || state.status === "scheduled"
      //       ? yup.string().required("Please select assigned technician.")
      //       : null,
      //   time_past: isPastDate && (time || status === 'forecast') ? yup.string().required('Work orders in this status cannot have a date/time in the past.') : null,
      //   time_future: isFutureDate ? yup.string().required('Work orders in this status cannot have a date/time in the future.') : null,
      //   date: yup.string().required("Please select date."),
      //   time: state.status === "complete" || state.status === "incomplete" ? yup.string().required("Please select time.") : null,
      // });

      // const data = { date: date || "", time, status: state.status, technician_uuid: state.selectedTechnician };
      // schema
      //   .validate(data, { abortEarly: false })
      //   .then(() => {
      //     setState((s) => ({ ...s, errors: {} }));
      //     updateWorkOrder();
      //     })
      //   .catch((error) => {
      //     let err_data = {};
      //     console.log(error);
      //     map(error.inner, (item) => {
      //       err_data[item.path] = item.message;
      //     });
      //     setState((s) => ({ ...s, errors: err_data }));
      //   });
    };

    const totalPriceDescription = (row) => {
      return amount(row.unit_cost * row.quantity);
    };

    function getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let tempBase64 = { ...base64Image };
        tempBase64[file.name] = reader.result;
        setBase64Image(tempBase64);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    const getFilesCustomer = (list) => {
      list.forEach((each) => {
        if (Object.keys(each).includes("image_url") && !each.image) {
          getBase64(each.image_url);
        }
      });
      setImageSources(list);
    };

    const getFilesTechnical = (list) => {
      list.forEach((each) => {
        if (Object.keys(each).includes("image_url") && !each.image) {
          getBase64(each.image_url);
        }
      });
      setImageSourcesTech(list);
    };

    const getImageUpload = async (id) => {
      let customerImage = [];
      let technicalImage = [];
      const data = await getUploadImageEditWorkOrder(id);
      if (!isEmpty(data)) {
        data.forEach((each) => {
          if (each.image_type === 'Service Work Order Customer') {
            let tempObjCustomer = {
              uid: each.id,
              name: each.name,
              url: each.image,
            }
            customerImage.push(tempObjCustomer)
          }
          else if (each.image_type === 'Service Work Order Technical') {
            let tempObjTechnical = {
              uid: each.id,
              name: each.name,
              url: each.image,
            }
            technicalImage.push(tempObjTechnical)
          }
        })
      }

      setImageSources(customerImage);
      setImageSourcesTech(technicalImage);
    }

    const postImageUpload = async () => {
      setLoader(true);
      let payload = {};
      let uploadedImageListCustomer = [];
      let uploadedImageListTechnical = [];
      imageSources.forEach((each) => {
        if (Object.keys(each).includes('image_url')) {
          let tempObj = {};
          tempObj['id'] = 0
          tempObj['image'] = each.image ?? base64Image[each.image_url.name]
          tempObj['name'] = each.image_url.name
          tempObj['type'] = "customer"

          uploadedImageListCustomer.push(tempObj)
        } else {
          let tempObj = {};
          tempObj['id'] = each.uid
          tempObj['image'] = each.url
          tempObj['name'] = each.name
          tempObj['type'] = "customer"

          uploadedImageListCustomer.push(tempObj)
        }
      })
      imageSourcesTech.forEach((each) => {
        if (Object.keys(each).includes('image_url')) {
          let tempObjTech = {};
          tempObjTech['id'] = 0
          tempObjTech['image'] = each.image ?? base64Image[each.image_url.name]
          tempObjTech['name'] = each.image_url.name
          tempObjTech['type'] = "technical"

          uploadedImageListTechnical.push(tempObjTech)
        } else {
          let tempObjTech = {};
          tempObjTech['id'] = each.uid
          tempObjTech['image'] = each.url
          tempObjTech['name'] = each.name
          tempObjTech['type'] = "technical"

          uploadedImageListTechnical.push(tempObjTech)
        }
      })
      let resultImage = uploadedImageListCustomer.concat(uploadedImageListTechnical)
      payload["id"] = uuid;
      payload["id_type"] = "service";
      payload["images"] = resultImage;

      const data = await postUploadImage(payload);
      if (data?.id) {
        setLoader(false);
        message.success("Image Upload Successfully!..");
        getUploadImageEditWorkOrder(uuid)
      }
      setUpdateWorkOrderState(true);
    }

    const getWorkOrderLines = async () => {
      let url = window.location.href;
      let temp = url.substring(url.lastIndexOf("/") + 1, url.length);
      let descriptionListTable = await getLines({ service_uuid: temp });
      setservices_lineitem(descriptionListTable);
      setNewServiceItem(descriptionListTable);
      calculateNoTax();
      CalculateTotal();
    };

    const editLine = (record) => {
      let temp_Obj = {
        errors: {},
        rate: record?.unit_cost,
        quantity_item: record?.quantity,
      };
      ref.current = record;
      setStateone(temp_Obj);
      seterrormessagestate(true);
    };
    useEffect(() => {
      if (  errormessagestate == true &&  stateone["rate"] != "" && stateone["quantity_item"] != "" ) {
        let temp = ref.current;
        setisAddLineVisible(true);
        setlineItem(temp);
        seteditMode(true);
        setvalue(temp.repair_code || "");
        CalculateTotal();
        calculateNoTax();
        seterrormessagestate(false);
        ref.current = "";
      }
    }, [stateone, errormessagestate]);

    const addLineItem = () => {
      lineItem.work_order_uuid = state.service_order_uuid;
      lineItem.total = lineItem.unit_cost * lineItem.quantity;
      lineItem.quantity = parseFloat(lineItem.quantity).toFixed(2) || 1;
      lineItem.description = lineItem.description || "";
      lineItem.unit_cost = parseFloat(lineItem.unit_cost) || "0.00";
      lineItem.is_taxable = lineItem.is_taxable ? lineItem.is_taxable : false;
      let state_items = state.services_lineitem;
      state_items.push(lineItem);
      setState((s) => ({ ...s, services_lineitem: state_items }));
      let items = services_lineitem;
      items.push(lineItem);
      setservices_lineitem(items);
      CalculateTotal();
      calculateNoTax();
      calculateTax();
      if (lineItem.include_labor) {
        createLabor();
        if (lineItem.include_bundle) {
          createBundle();
        }
      }

      if (lineItem.is_taxable) {
        applyTax(lineItem, true);
      }
    }
    const createBundle = () => {
      const item_unitCost = parseFloat(lineItem.unit_cost);
      const labor_unitCost = lineItem.totallabor ? lineItem.totallabor : 0;

      const item_total = lineItem.unit_cost * lineItem.quantity;
      const labor_total = (lineItem.quantity * lineItem.totallabor)

      const bundleLine = {
        work_order_uuid: state.service_order_uuid,
        repair_code: lineItem.repair_code,
        bundle_code: lineItem.repair_code,
        description: lineItem.description,
        is_taxable: lineItem.is_taxable || lineItem.apply_labor_tax,
        unit_cost: item_unitCost + labor_unitCost,
        quantity: lineItem.quantity,
        total: item_total + labor_total,
        qb_account: lineItem.qb_account
      }

      let state_items = state.services_lineitem;
      state_items.push(bundleLine);

      setState((s) => ({ ...s, services_lineitem: state_items }));

      let items = services_lineitem;
      const isAdded = items.indexOf(bundleLine);
      if(isAdded < 0)
        items.push(bundleLine);
      
      setservices_lineitem(items);

      if (bundleLine.is_taxable) {
        applyTax(bundleLine, false, true);
      }

      CalculateTotal();
      calculateNoTax();
      calculateTax();
    }

    const createLabor = () => {
      const laborLine = {
        work_order_uuid: state.service_order_uuid,
        repair_code: lineItem.repair_code + "-Labor",
        description: "LBR-" + lineItem.description,
        is_taxable: lineItem.apply_labor_tax ? lineItem.apply_labor_tax : 0,
        unit_cost: lineItem.totallabor ? lineItem.totallabor : 0,
        quantity: lineItem.quantity,
        total: (lineItem.quantity * lineItem.totallabor)
      }
      let state_items = state.services_lineitem;
      state_items.push(laborLine);
      setState((s) => ({ ...s, services_lineitem: state_items }));
      let items = services_lineitem;
      items.push(laborLine);
      setservices_lineitem(items);
      if (laborLine.is_taxable) {
        applyTax(laborLine, true);
      }
      CalculateTotal();
      calculateNoTax();
      calculateTax();
    }

    const updateLine = (updatedBillable = false) => {
      const index = services_lineitem.map(e => e.repair_code).indexOf(lineItem.repair_code);
      let updateLine = { ...services_lineitem.at(index), ...lineItem };
      let lines = services_lineitem;
      const removeBundle = lines[index].include_bundle !== updateLine.include_bundle;
      const removeLabor = lines[index].include_labor !== updateLine.include_labor;
      lines[index] = updateLine;
      setservices_lineitem(lines);
      if (updateLine.uuid) {
        let currentUpdatingLineItem = (state.update_services_lineitem).filter((curr) => { return curr.uuid === updateLine.uuid});
        let nonUpdatingLineItem = (state.update_services_lineitem).filter((curr) => { return curr.uuid !== updateLine.uuid});

        let updatedValues = {
          uuid: updateLine.uuid,
          is_taxable:false
        };
        updatedValues["work_order_uuid"] = state.service_order_uuid;

        if (is_description_Edit) {
          if (currentUpdatingLineItem?.length > 0)
            currentUpdatingLineItem[0].description = updateLine.description;
          else updatedValues["description"] = updateLine.description;
        }
        if (is_quantity_Edit) {
          if (currentUpdatingLineItem?.length > 0) {
            currentUpdatingLineItem[0].quantity = parseFloat(updateLine.quantity).toFixed(2);
            currentUpdatingLineItem[0].total = updateLine.quantity * updateLine.unit_cost;
          }
          else {
            updatedValues["quantity"] = parseFloat(updateLine.quantity).toFixed(2);
            updatedValues["total"] = 
              updateLine.quantity * updateLine.unit_cost
            ;
          }
        }
        if (is_unitCost_Edit) {
          if (currentUpdatingLineItem?.length > 0) {
            currentUpdatingLineItem[0].unit_cost = updateLine.unit_cost;
            currentUpdatingLineItem[0].total = updateLine.quantity * updateLine.unit_cost;
          }
          else {
            updatedValues["unit_cost"] = updateLine.unit_cost;
            updatedValues["total"] = 
              updateLine.quantity * updateLine.unit_cost;
          }
        }
        if (is_price_Edit) {
          if (currentUpdatingLineItem?.length > 0)
            currentUpdatingLineItem[0].price = updateLine.pricet;
          else updatedValues["price"] = updateLine.price;
        }
        if (is_billable_Edit || updatedBillable) {
          if (currentUpdatingLineItem?.length > 0)
            currentUpdatingLineItem[0].billable = updateLine.billable;
          else updatedValues["billable"] = updateLine.billable;
        }
        let updatedLineitem = state.update_services_lineitem;

        if(currentUpdatingLineItem?.length == 0){
        updatedLineitem.push(updatedValues);}
        else {
          nonUpdatingLineItem.push(currentUpdatingLineItem[0])
        }
        setState((s) => ({
          ...s,
          update_services_lineitem: updatedLineitem || nonUpdatingLineItem,
        }));
      } else {
        const _index = state.services_lineitem.map(e => e.repair_code).indexOf(lineItem.repair_code);
        let services_lineitem = state.services_lineitem;
        services_lineitem[_index] = updateLine;
        setState((s) => ({ ...s, services_lineitem: services_lineitem }));
      }
      if (removeBundle || removeLabor) {
        removeBundleLabor(lineItem);
      }
      CalculateTotal();
      calculateNoTax();
    }
    const removeBundleLabor = (row) => { 
      let lines = services_lineitem;
      if (!lineItem.include_labor) {//remove Labor line
        let labor_line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code + "-Labor"
        });

        if (labor_line) {
          lines = lines.filter((item) => {
            return item.repair_code !== row.repair_code + '-Labor'
          });
          if (labor_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(labor_line.uuid);
            setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
          } else {
            let services_lineitem = state.services_lineitem.filter((item) => {
              return item.repair_code !== labor_line.repair_code
            });
            setState((s) => ({ ...s, services_lineitem: services_lineitem }));
          }
        }
      }

      if (!lineItem.include_bundle) {//remove Bundle line        
        let bundle_line = services_lineitem.find((item) => {
          return item.bundle_code !== undefined && item.bundle_code === row.repair_code
        });

        if (bundle_line) {
          lines = lines.filter((item) => {
            return item.bundle_code !== row.repair_code
          });
          if (bundle_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(bundle_line.uuid);
            setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
          } else {
            let services_lineitem = state.services_lineitem.filter((item) => {
              return item.repair_code !== bundle_line.repair_code
            });
            setState((s) => ({ ...s, services_lineitem: services_lineitem }));
          }
        }
      }
      
      setservices_lineitem(lines);
      CalculateTotal();
      calculateTax();
      calculateNoTax();
    }
    const updateDiagnostic = (record) => {
      set_is_billable_Edit(true);
      settake_diagnostic(!record.billable);
      record.billable = !record.billable;
      setlineItem(record);
      updateLine(true);
    }

    const clearLineItem = () => {
      setlineItem({
        repair_code: "",
        description: "",
        unit_cost: 0,
        quantity: (1).toFixed(2),
        price: 0,
        include_labor: false
      })
      setvalue('');
      setStateone({
        errors: {},
        rate:"",
        quantity_item:"",
      });
    }

    // const deleteLine = (row) => {
    //   const line = services_lineitem.find((item) => {
    //     return item.repair_code === row.repair_code
    //   });
    //   let lines = services_lineitem.filter((item) => {
    //     return item.repair_code !== row.repair_code
    //   });
    //   if (line.uuid) {
    //     let delete_services_lineitem = state.delete_services_lineitem;
    //     delete_services_lineitem.push(line.uuid);
    //     setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
    //   } else {
    //     let services_lineitem = state.services_lineitem.filter((item) => {
    //       return item.repair_code !== row.repair_code
    //     });
    //     setState((s) => ({ ...s, services_lineitem: services_lineitem }));
    //   }
    //   if (!row.repair_code?.includes('Labor')) {//Has Labor line
    //     let labor_line = services_lineitem.find((item) => {
    //       return item.repair_code === row.repair_code + "-Labor"
    //     });
    //     if (labor_line) {
    //       lines = lines.filter((item) => {
    //         return item.repair_code !== row.repair_code + '-Labor'
    //       });
    //       if (labor_line.uuid) {
    //         let delete_services_lineitem = state.delete_services_lineitem;
    //         delete_services_lineitem.push(labor_line.uuid);
    //         setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
    //       } else {
    //         let services_lineitem = state.services_lineitem.filter((item) => {
    //           return item.repair_code !== labor_line.repair_code
    //         });
    //         setState((s) => ({ ...s, services_lineitem: services_lineitem }));
    //       }
    //     }
    //   }
    //   setservices_lineitem(lines);
    //   CalculateTotal();
    //   calculateTax();
    //   calculateNoTax();
    // }
    const deleteLine = async (row) => {
      let line = {};
      let lines = [];
      if (row.bundle_code) {
        line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code && item.bundle_code === row.bundle_code
        });     

        let index = services_lineitem.indexOf(line);
        services_lineitem.splice(index, 1); //Remove bundle        

        lines = services_lineitem;        
        
        setservices_lineitem(lines);
      } else {
        line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code && 
          (item.bundle_code === undefined || item.bundle_code === null)
        });

        let index = services_lineitem.indexOf(line);
        services_lineitem.splice(index, 1);

        lines = services_lineitem;        
        
        setservices_lineitem(lines);
      }

      if (line?.uuid) {
        let delete_services_lineitem = state.delete_services_lineitem;
        delete_services_lineitem.push(line.uuid);
        setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
      }

      if (row.bundle_code || !row.repair_code?.includes('Labor')) {//Delete Labor line
        let labor_line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code + "-Labor"
        });

        if (labor_line) {
          let indexLabor = lines.indexOf(labor_line);
          lines.splice(indexLabor, 1);

          if (labor_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(labor_line.uuid);
            setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
          } else {
            setState((s) => ({ ...s, services_lineitem: lines }));
          }
        }
      }
      
      const code = row.repair_code.includes('Labor') ? row.repair_code.split('-Labor')[0] : row.repair_code;
      let bundle_line = services_lineitem.find((item) => {
        return item.bundle_code === code
      });

      if (!row.bundle_code && (row.include_bundle || bundle_line)) {//Delete Bundle line      
        if (bundle_line) {
          let indexbundle = lines.indexOf(bundle_line);
          lines.splice(indexbundle, 1);
          if (bundle_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(bundle_line.uuid);
            setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
          } else {
            setState((s) => ({ ...s, services_lineitem: lines }));
          }
        }
      }      

      if (row.bundle_code) {//Delete Product line      
        let product_line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code
        });

        if (product_line) {
          let indexbundle = lines.indexOf(product_line);
          lines.splice(indexbundle, 1);
          if (product_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(product_line.uuid);
            setState((s) => ({ ...s, delete_services_lineitem: delete_services_lineitem }));
          } else {
            setState((s) => ({ ...s, services_lineitem: lines }));
          }
        }
      }

      return new Promise((resolve, reject) => {        
        setservices_lineitem(lines);
        CalculateTotal();
        calculateTax();
        calculateNoTax();
        resolve(true);
      })
    }
    const CalculateTotal = () => {
      let _total = 0;
      let _addons = [];
      services_lineitem.forEach((item) => {
        if (!item.bundle_code)
        _total += +item.total > 0 ? +item.total : (+item.quantity * +item.unit_cost);
      })
      
      
      setAddons(_addons);
      services_lineitem.forEach((item) => {
        if (item.repair_code && item.repair_code !== state.servicetype) {
          let repairCode = item.repair_code.includes('Labor') ? item.repair_code.split('-Labor')[0] : item.repair_code;
          const bundleItem = services_lineitem.find(i => i.repair_code === repairCode && i.bundle_code === repairCode);
          
          if (!item.include_bundle) {
            if (!bundleItem) {
              _addons.push({
                name: item.description,
                price: +item.quantity * +item.unit_cost
              });
            }
          } else {
            if (bundleItem)
              _addons.push({
                name: bundleItem.description,
                price: +bundleItem.quantity * +bundleItem.unit_cost
              });
          }
          setAddons(_addons);
        }
      })

      if (!take_diagnostic)
        _total -= +state.price

      setTotal(_total);
    }
    const removeDiagnostic = () => {
      const lines = services_lineitem.filter((item) => {
        return item.repair_code?.toLowerCase() !== state.servicetype?.toLowerCase()
      });

    }

    const isBundleEnable = () => {
      const nonBundle = services_lineitem.filter((f) => (f.bundle_code === null || f.bundle_code === undefined)).map((each) => each.is_taxable);
      
      return nonBundle.every((each) => each === true)
      
    }

    const updateLineItem = (lineItems, lineItem) => {
      const lineItemUuid = lineItem.repair_code.slice(0,9);
      const copyLineItems = cloneDeep(lineItems);
      const nonBundleItems = copyLineItems.filter((ff) => ff.repair_code.slice(0,9) === lineItemUuid).filter((f) => (f.bundle_code === null || f.bundle_code === undefined));
      const bundleItems = copyLineItems.filter((ff) => ff.repair_code.slice(0,9) === lineItemUuid).find((f) => (f?.bundle_code !== null && f?.bundle_code !== undefined)) || {}
      const isBundleEnable = nonBundleItems.map((e) => e?.is_taxable).every((each) => each === true);
      if(isBundleEnable) {
        bundleItems.is_taxable = true
      } else {
        bundleItems.is_taxable = false
      }

        bundleItems.tax = 0;      
        bundleItems.total = 0;
        nonBundleItems.filter((f) => f.is_taxable === true).forEach((each) => {
          bundleItems.tax += ((+taxRate / 100) * (each.unit_cost * each.quantity));        
          bundleItems.total += ((each.unit_cost * each.quantity));
        })
        bundleItems.tax = bundleItems.tax.toString();      
        bundleItems.total = bundleItems.total.toString();
 
      return copyLineItems;
     }

    const applyTax = (lineItem, isLabor = false, isBundle = false, updateTax = false) => { 
      let index;
      if (!isBundle) {
        index = services_lineitem.indexOf(lineItem);
      } else {
        index = services_lineitem.map(e => e.bundle_code).indexOf(lineItem?.repair_code);
      }
      
      let updateLine = services_lineitem.at(index);
 
      if(updateTax)
      {
        updateLine.is_taxable = lineItem?.is_taxable;
      }else{
        updateLine.is_taxable = isLabor || isBundle ? lineItem?.is_taxable : updateLine?.is_taxable ? !updateLine?.is_taxable : true;
      }

      let lines = services_lineitem;
      let updatedLines = [];
      updateLine.work_order_uuid = state.service_order_uuid;

      if (updateLine.is_taxable) {
        updateLine.is_taxable = true;
        updateLine.tax = ((+taxRate / 100) * (lineItem.unit_cost * lineItem.quantity)).toString();
        updateLine.total = (((taxRate * (lineItem.unit_cost * lineItem.quantity)) / 100) + (lineItem.unit_cost * lineItem.quantity)).toString();
        lines[index] = updateLine;
        updatedLines = updateLineItem(lines, lineItem)
      } else {
        updateLine.is_taxable = false;
        updateLine.tax = "0.00";
        updateLine.total = (lineItem.unit_cost * lineItem.quantity).toString();
        lines[index] = updateLine;
        updatedLines = updateLineItem(lines, lineItem)
      }

      if (updateLine.is_taxable !== lineItem.is_taxable) {
        if (updateLine.uuid) {
          let update_services_lineitem = structuredClone(state.update_services_lineitem);
          update_services_lineitem.push({
            work_order_uuid: updateLine.work_order_uuid,
            uuid: updateLine.uuid,
            tax: updateLine.tax,
            total: updateLine.total,
            is_taxable: updateLine.is_taxable
          });

          setState((s) => ({ ...s, update_services_lineitem: update_services_lineitem }));
        } else {
          let services_lines_added = state.services_lineitem;
          const indexEdited = services_lines_added.map(e => e.repair_code).indexOf(lineItem.repair_code);
          let updateLineEdited = services_lines_added.at(indexEdited);

          updateLineEdited.work_order_uuid = props.currentRecord.uuid;
          updateLineEdited.tax = updateLine.tax;
          updateLineEdited.total = updateLine.total;
          services_lines_added[indexEdited] = updateLineEdited;
          
          setservices_lineitem((s) => ({ ...s, services_lines_added }));
          setState((s) => ({ ...s, services_lineitem: services_lines_added }));
        }
      }
      
      setservices_lineitem(updatedLines);

      calculateNoTax();
      CalculateTotal();
      calculateTax();
    }             
    // const applyTax = (lineItem, isLabor = false,isBundle = false, updateTax = false) => {
    //   const index = services_lineitem.map(e => e.repair_code).indexOf(lineItem.repair_code);
    //   let updateLine = services_lineitem.at(index);
    //   updateLine.is_taxable = isLabor ? lineItem.is_taxable : updateLine.is_taxable ? !updateLine.is_taxable : true;

    //   let lines = services_lineitem;
    //   updateLine.work_order_uuid = state.service_order_uuid;

    //   if (updateLine.is_taxable) {
    //     updateLine.is_taxable = true;
    //     updateLine.tax = ((+taxRate / 100) * (lineItem.unit_cost * lineItem.quantity)).toString();
    //     updateLine.total = (((taxRate * (lineItem.unit_cost * lineItem.quantity)) / 100) + (lineItem.unit_cost * lineItem.quantity)).toString();
    //     lines[index] = updateLine;
    //   } else {
    //     updateLine.is_taxable = false;
    //     updateLine.tax = "0.00";
    //     updateLine.total = (lineItem.unit_cost * lineItem.quantity).toString();
    //     lines[index] = updateLine;
    //   }
    //   if (updateLine.uuid) {
    //     let update_services_lineitem = state.update_services_lineitem;
    //     update_services_lineitem.push({
    //       work_order_uuid: updateLine.work_order_uuid,
    //       uuid: updateLine.uuid,
    //       tax: updateLine.tax,
    //       total: updateLine.total,
    //       is_taxable: updateLine.is_taxable
    //     });
    //     setState((s) => ({ ...s, update_services_lineitem: update_services_lineitem }));
    //   } else {
    //     let services_lines_added = state.services_lineitem;
    //     const indexEdited = services_lines_added.map(e => e.repair_code).indexOf(lineItem.repair_code);
    //     let updateLineEdited = services_lines_added.at(indexEdited);
    //     updateLineEdited.work_order_uuid = state.service_order_uuid;
    //     updateLineEdited.tax = updateLine.tax;
    //     updateLineEdited.total = updateLine.total;
    //     services_lines_added[indexEdited] = updateLineEdited;
    //     setservices_lineitem((s) => ({ ...s, services_lines_added }));
    //     setState((s) => ({ ...s, services_lineitem: services_lines_added }));
    //   }
    //   setservices_lineitem(lines);
    //   calculateNoTax();
    //   CalculateTotal();
    //   calculateTax();
    // }

    const calculateNoTax = () => {
      let totalNoTax = 0;
      services_lineitem.forEach((line) => {
        const _total = (line.unit_cost * line.quantity);
        if (!line.bundle_code)
          totalNoTax += +_total;
      })
      if (!take_diagnostic)
        totalNoTax -= +state.price
      setTotalNoTax(totalNoTax);
    }
    const removeBundleTax = (item) => {
      const repairCode = item.repair_code.includes('Labor') ? item.repair_code.split('-Labor')[0] : item.repair_code;
      const repairLaborCode = item.repair_code.includes('Labor') ? item.repair_code : item.repair_code + '-Labor';

      const product = services_lineitem.find(i => i.repair_code === repairCode && (i.bundle_code === null || i.bundle_code === undefined));
      const labor = services_lineitem.find(i => i.repair_code === repairLaborCode);
      let bundle = services_lineitem.find(i => i.bundle_code === repairCode);

      if (product.is_taxable || labor?.is_taxable) {
        if(bundle)
        bundle.is_taxable = true;
      } else if (!product.is_taxable && !labor?.is_taxable) {
        bundle.is_taxable = false;
      }

      applyTax(bundle, false, true);
    }
    const calculateTax = () => {
      let totalTax = 0;
      services_lineitem.forEach((line) => {
        if (!line.bundle_code)
          if (line.is_taxable) {
            totalTax += +line.tax;
          }
      })
      setTotalTax(totalTax);
    }
    
    const getAvalaraTax = () => {
      settaxRate(amount(state.avalara_tax_rate));
    }

    const quantityValidation = (e) => {
      numberValidate(e)
    }

    const rateValidation = (e) => {
      rateValidate(e)
    }

    useEffect(() => {
      let serviceTaxable = services_lineitem.filter(item => item.is_taxable);
      serviceTaxable.forEach((line) => {
        line.is_taxable = false;
        applyTax(line)
      });
      calculateTax();
      setState((s) => ({ ...s, tax_rate: amount(taxRate) }));
    }, [taxRate]);

    const renderDescription = () => {
      return (
        <div className="mt-20">
          <Card className="middle-section" title={state.servicetype?.toLowerCase() === "service" ? 'Description of Work' : 'Additional Work'} headStyle={{ fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }} bodyStyle={{ padding: '0px' }}>
            {renderDescriptionTable()}
            <Row style={{ margin: '6px' }}>
              <Col xs={24} sm={24} md={20} lg={20}>
                <label className="mr-10"> Tax Rate: </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "25%" }}
                  value={taxRate}
                  onChange={(e) => { settaxRate(e.target.value) }}
                  disabled={transection_type === "View"}
                />
                <u>
                  <a role="button" href="/#" onClick={(e) => { e.preventDefault(); getAvalaraTax() }} className="ml-10" disabled={transection_type === "View"} >
                    {" "}
                    Reset Tax Rate to local
                  </a>
                </u>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} className="add-item-button">
                {transection_type !== "View" ?
                  <span role="button" className="orange" style={{ 'cursor': 'pointer' }} onClick={(e) => { setisAddLineVisible(true); seteditMode(false) }}>
                    <PlusCircleOutlined style={{ marginRight: "6px" }} /> Add Item
                  </span>
                  :
                  <span role="button" className="orange" style={{ 'cursor': 'pointer' }} >
                    <PlusCircleOutlined style={{ marginRight: "6px" }} /> Add Item
                  </span>
                }
              </Col>
            </Row>
          </Card>
        </div>
      );
    };

    const renderNotesCustomer = () => {
      if (state.service_type?.toLowerCase() === "service") {
        return (
          // <React.Fragment>
          <>
            <br />
            <Card className="middle-section" title="Service Notes | Customer" headStyle={{ fontSize: '16px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }} >
              <div className="">
                <p className="mb-0">Completion Notes</p>
                <TextArea
                  value={state.completion_notes}
                  placeholder=""
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  style={{ width: "750px" }}
                  onChange={(e) => {
                    setState((s) => ({
                      ...s,
                      completion_notes: e.target.value,
                    }));
                  }}
                />
                <div className="mt-20">
                  <h6 className="mb-5">Pictures</h6>
                  <p className="mb-5">Upload your images</p>
                  <div className="upload-image-section">
                    {/* <FileUploader getFiles={getImages} /> */}
                    <ImageUploadWorkOrder
                      fileList={imageSources}
                      getFiles={getFilesCustomer}
                      accept={".png, .jpg, .gif"}
                      params={{
                        level: "EditWorkOrder",
                        isTechImage: false,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <h4>Tech Notes</h4>
                <TextArea
                  disabled={transection_type === "View"}
                  value={state.tech_notes}
                  placeholder=""
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  style={{ width: "750px" }}
                  onChange={(e) => {
                    setState((s) => ({
                      ...s,
                      tech_notes: e.target.value,
                    }));
                  }}
                />
                <div>
                  <div className="mt-20">
                    <h6 className="mb-5">Pictures</h6>
                    <p className="mb-5">Upload your images</p>
                    <div className="upload-image-section">
                      {/* <FileUploader getFiles={getTechImages} /> */}
                      <ImageUploadWorkOrder
                        text={"Upload tech image"}
                        fileList={imageSourcesTech}
                        getFiles={getFilesTechnical}
                        accept={".png, .jpg, .gif"}
                        params={{
                          level: "EditWorkOrder",
                          isTechImage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <hr />
                <h4>Client Approval</h4>
                <div className="fitem half-fitem mt-10">
                  <div className="fitem half-fitem">
                    <strong className="mr-10">Owner Date</strong>
                    <div className="fitem">
                      <input
                        id="task.contractChangeDateOwner"
                        type="date"
                        name="input"
                        style={{ width: "60%" }}
                      />
                    </div>
                  </div>
                  <div
                    className="fitem half-fitem mt-10"
                    style={{ marginRight: "20px" }}
                  >
                    <img
                      className="sign-image-section"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Card>
          </>
        );
      }
    };

    const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

    const renderDescriptionTable = () => {
      return (
        <React.Fragment>
          <Table
            className="tablebody wo_descriptionTable_table_url"
            rowKey={(record) => `${record.repair_code} - ${record?.bundle_code}`}
            dataSource={[...services_lineitem]}
            pagination={false}
            columns={DataTable.DescriptionWork({ totalPriceDescription, setisAddLineVisible, setlineItem, seteditMode, deleteLine, editLine, applyTax, service_type: state.service_type, take_diagnostic, set_is_billable_Edit, updateDiagnostic, transection_type, amount,removeBundleTax, isBundleEnable })}
          />
          <div >
            <Modal
              title={editMode ? 'Edit Item' : 'Add Item'}
              visible={isAddLineVisible}
              okText={!editMode ? "Add Item" : "Save Item"}
              cancelText={"Cancel"}
              onOk={seachvalidate}
              onCancel={() => {
                setisAddLineVisible(false);
                clearLineItem();
              }}
              className="add_line_modal"
              width={400}
            >
              <div className="form-group">
                <div className="fitem">
                  <div>
                    <label>Repair Code</label>
                    {renderRepairCodeSearch()}
                  </div>
                  <div>
                    <label>Description</label>
                    <input type="text" placeholder="Search Repair Descriptions" value={lineItem.description} onChange={(e) => { set_is_description_Edit(true); setlineItem((l) => ({ ...l, description: e.target.value })) }} />
                  </div>
                  <div>
                    <label>Quantity</label>
                    <input
                      name="quantity_item"
                      type="number"
                      placeholder="1.00"
                      min="0.01"
                      step="0.1"
                      value={lineItem.quantity}
                      onChange={(e) => {
                        setStateone({...stateone,
                          errors: {},
                          quantity_item: e.target.value,
                        });
                        set_is_quantity_Edit(true);
                        setlineItem((l) => ({
                          ...l,
                          quantity: e.target.value,
                        }));
                      }}
                    />
                    {errors["quantity_item"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle"></i>
                        {errors["quantity_item"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label>Rate</label>
                    <input
                      name="rate"
                      type="number"
                      placeholder="0.00"
                      value={lineItem.unit_cost}
                      onChange={(e) => {
                        setStateone({...stateone,
                          errors: {},
                          rate: e.target.value,
                        });
                        set_is_unitCost_Edit(true);
                        setlineItem((l) => ({
                          ...l,
                          unit_cost: e.target.value,
                        }));
                      }}
                      onBlur={(e) =>
                        setlineItem((l) => ({
                          ...l,
                          unit_cost:e.target.value,
                        }))
                      }
                    />
                    {errors["rate"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle"></i>
                        {errors["rate"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                  <label>Price</label>
                  <input type="number" placeholder="0" value={totalPriceDescription(lineItem)} onChange={(e) => { set_is_price_Edit(true); setlineItem((l) => ({ ...l, price: e.target.value })) }} />
                  </div>
                  {lineItem.totallabor !== null && lineItem.totallabor > 0 ? 
                    <>
                      <div className="mt-10">
                      <input type="checkbox" checked={lineItem.include_labor} onChange={(e) => { setlineItem((l) => ({ ...l, include_labor: !lineItem.include_labor, include_bundle: !lineItem.include_labor })) }} style={{ 'verticalAlign': 'middle' }} />
                        <label className="mr-10">Include Labor Charge</label>
                      </div>
                      <div className="mt-10">
                        <input type="checkbox" disabled={!lineItem.include_labor} checked={lineItem.include_bundle} onChange={(e) => { setlineItem((l) => ({ ...l, include_bundle: !lineItem.include_bundle })) }} style={{ 'verticalAlign': 'middle' }} />
                        <label className="mr-10">Bundle Product</label>
                      </div>
                    </>
                    : ''
                  }
                </div>
              </div>
            </Modal>
          </div>
        </React.Fragment>
      );
    };

    return (
      <div className="block-row p-0">
        <div className="container background">
          <div className="container left-section">
            <div className="wrap-padding">
              <Card className="middle-section" title="Service Requirements" headStyle={{ fontSize: '18px', fontWeight: 'bold', borderBottom: '1px solid #E1E1E5' }}>
                <h4 className="mb-10">Service type</h4>
                <label>{state.service_type}</label>
                <div className="mb-20">{renderWorkType()}</div>
                <div className="">
                  <h4 className="mb-5">Pool type</h4>
                  <label>{state.pool_type}</label>
                  <div className="mt-20">
                    <p className="mb-0">Pool size in gallons</p>
                    <Input
                      value={state.pool_size}
                      style={{ width: "40%" }}
                      onChange={(e) => {
                        setState((s) => ({
                          ...s,
                          pool_size: e.target.value,
                        }));
                      }}
                      disabled
                    />
                  </div>
                  <div className="mt-20">                    
                      <AddDurationField durationType="service" />                      
                  </div>
                  <div className="mt-20">
                    <p className="mb-0">Problem Description Notes</p>
                    <TextArea
                      value={state.problem_description}
                      placeholder=""
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setState((s) => ({
                          ...s,
                          problem_description: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {renderDescription()}
                  {renderNotesCustomer()}
                  {renderTechnicalForm()}
                </div>
              </Card>
            </div>
            <div
              className="right-section"
            >
              {/* {ServiceOrderSummary()} */}
              {WorkOrderSummary()}
            </div>
          </div>
        </div>
        <div className="fix-btn-grp-wo">
          <button
            className="btn btn-white"
            onClick={close}
          >
            Cancel
          </button>
          <button className={`btn btn-warning`} onClick={() => validate()}>
            {((state.service_type?.toLowerCase() === "service") && appData.workOrderTypes.includes(state.worktype?.toLowerCase())) ? "Save" : "Update Work Order"}
          </button>
          {(state.service_type?.toLowerCase() === "service" && appData.workOrderTypes.includes(state.worktype?.toLowerCase())) && (
            <button
              className={`btn btn-warning`}
              onClick={() => {
                setsendEmail({ send: true, request: true });
                validate();
              }}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    );
  }

  //   function WorkOrderDetails() {
  //     const [technicians, setTechnicians] = useState([]);
  //     const { errors } = state;
  //     useEffect(() => {
  //       const getFieldTech = async () => {
  //         const data = await getFieldTechnician({
  //           field_office__uuid: props?.record?.field_office?.uuid,
  //         });
  //         setTechnicians(data?.results);
  //       };
  //       getFieldTech();
  //     }, [props]);
  //     const updateTechnician = (selectedTechnician) => {
  //       setState((s) => ({ ...s, selectedTechnician }));
  //     };
  //     const updateIsLockdate = (lockdate) => {
  //       setState((s) => ({ ...s, lockdate: lockdate }));
  //     };
  //     return (
  //       <>
  //         <div className="flex">
  //           <h4> Work Order Details </h4>
  //         </div>
  //         <div className="field-group">
  //           <label>
  //             status<sup>*</sup>
  //           </label>
  //           <Select
  //             value={state?.status}
  //             disabled={transection_type === "View"}
  //             onChange={(e) => {
  //               // message.destroy();
  //               if (e === "complete") {
  //                 setState((s) => ({
  //                   ...s,
  //                   status: e,
  //                   time: moment("12:00 AM", ["h:mm A"]),
  //                 }));
  //               } else {
  //                 setState((s) => ({
  //                   ...s,
  //                   status: e,
  //                   time: "",
  //                   selectedTechnician:
  //                     e === "forecast" ? "" : state?.selectedTechnician,
  //                 }));
  //               }
  //             }}
  //           >
  //             {map(workOrderStatus.WorkOrderStatus, (item, index) => {
  //               if (index > 0) {
  //                 return (
  //                   <Option key={index} value={item.value}>
  //                     {item.label}
  //                   </Option>
  //                 );
  //               }
  //             })}
  //           </Select>
  //           {errors?.["status"] ? (
  //             <label className="new_err_msg">
  //               <i className="las la-info-circle" />
  //               {errors?.["status"]}
  //             </label>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //         <div className="field-group">
  //           <label>Assigned Technician</label>
  //           <Select
  //             showSearch
  //             placeholder="Not assigned"
  //             optionFilterProp="children"
  //             onChange={(e) => {
  //               updateTechnician(e);
  //             }}
  //             filterOption={(input, option) =>
  //               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //             }
  //             value={state?.selectedTechnician || undefined}
  //             disabled={transection_type === "View"}
  //           >
  //             {technicians?.map((technician, index) => {
  //               return (
  //                 <Option
  //                   key={index}
  //                   value={technician.user.uuid}
  //                 >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
  //               );
  //             })}
  //           </Select>
  //           {errors?.["technician_uuid"] ? (
  //             <label className="new_err_msg">
  //               <i className="las la-info-circle" />
  //               {errors?.["technician_uuid"]}
  //             </label>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //         <div className="field-group">
  //           <label>Job Name</label>
  //           <Input
  //             value={state.work_order_name}
  //             onChange={(e) => {
  //               setState((s) => ({ ...s, work_order_name: e.target.value }));
  //             }}
  //             disabled={transection_type === "View"}
  //           />
  //           {errors?.["work_order_name"] ? (
  //             <label className="new_err_msg">
  //               <i className="las la-info-circle" />
  //               {errors?.["work_order_name"]}
  //             </label>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //         <div style={{ display: "flex" }}>
  //           <div
  //             className="field-group"
  //             style={{ width: "50%", paddingRight: 5 }}
  //           >
  //             <label>
  //               Work Order Date<sup>*</sup>
  //             </label>
  //             <DatePicker
  //               style={{ width: "100%" }}
  //               disabled={transection_type === "View"}
  //               format="MMM DD,YYYY"
  //               suffixIcon={<i className="las la-calendar"></i>}
  //               allowClear={true}
  //               value={state.date ? moment(state.date) : null}
  //               onChange={(e) => {
  //                 if (e) {
  //                   setState((s) => ({
  //                     ...s,
  //                     date: moment(e).format("YYYY-MM-DD"),
  //                   }));
  //                 } else {
  //                   setState((s) => ({ ...s, date: null }));
  //                 }
  //               }}
  //             />
  //             {errors?.["date"] ? (
  //               <label className="new_err_msg">
  //                 <i className="las la-info-circle" />
  //                 {errors?.["date"]}
  //               </label>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //           <div className="field-group" style={{ width: "50%", paddingLeft: 5 }}>
  //             <label>
  //               Time
  //               {(state?.status === "complete" ||
  //                 state?.status === "incomplete") && <sup>*</sup>}
  //             </label>
  //             <TimePicker
  //               className="swhite"
  //               placeholder={"--:-- AM/PM"}
  //               value={state?.time ? moment(state?.time, "hh:mm A") : ""}
  //               onChange={(e) => {
  //                 setState((s) => ({ ...s, time: e }));
  //               }}
  //               allowClear={["forecast", "canceled"].includes(state?.status)}
  //               format={"h:mm A"}
  //               disabled={transection_type === "View"}
  //             />
  //             {errors?.["time"] ? (
  //               <label className="new_err_msg">
  //                 <i className="las la-info-circle" />
  //                 {errors?.["time"]}
  //               </label>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //         </div>
  //         {errors?.["time_past"] ? (
  //           <label className="new_err_msg align-items-start lh-20">
  //             <i className="las la-info-circle" />
  //             {errors?.["time_past"]}
  //           </label>
  //         ) : (
  //           ""
  //         )}
  //         {errors?.["time_future"] ? (
  //           <label className="new_err_msg align-items-start lh-20">
  //             <i className="las la-info-circle" />
  //             {errors?.["time_future"]}
  //           </label>
  //         ) : (
  //           ""
  //         )}
  //       </>
  //     );
  //   }

  const getLineSearch = async (params) => {
    const response = await getPrices(params);
    return response;
  }
  const [codeOptions, setcodeOptions] = useState([]);
  const [repairCodes, setrepairCodes] = useState([]);
  const [value, setvalue] = useState('');

  async function getOptions(search, uuid) {
    let field_office_uuid = uuid ? uuid : state.field_office_uuid;
    const options = await getLineSearch({ search: search, field_office_uuid: field_office_uuid });
    setrepairCodes(options);
    const _options = options.map((item) => {
      //Validation to not repeat repair code
      const is_added = services_lineitem.some(i => i.repair_code === item.repaircode)
      if (!is_added) {
        return {
          value: item.repaircode + " - " + item.description,
          text: item.repaircode
        }
      }
      return {};
    });
    setcodeOptions(_options);
  }

  const renderRepairCodeSearch = () => {
    const onChange = (e) => { 
      let val = '';
      let selectedCode = [];
      if (e) {
        val = e.trim().split(' ')[0];
        setvalue(val);
        selectedCode = repairCodes.filter((item) => {
          return item.repaircode === val
        });
      } else {
        setvalue('');
      }
      if (selectedCode.length > 0) {
        setlineItem({
          repair_code: selectedCode[0].repaircode || "",
          description: selectedCode[0].description,
          unit_cost: Math.round((Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100) / 100,
          quantity: (1).toFixed(2),
          price: selectedCode[0].fo_adjusted_price,
          is_taxable: selectedCode[0].is_taxed_product,
          apply_labor_tax: selectedCode[0].is_taxed_labor,
          totallabor: selectedCode[0].totallabor,
          qb_account: selectedCode[0].qb_account
        });
        setStateone({
          errors: {},
          rate:   Math.round(
            (Number(selectedCode?.[0]?.fo_adjusted_price) + Number.EPSILON) * 100
          ) / 100,
          quantity_item: (1).toFixed(2),
        })
      }else{
        message.error("The item was not found, please verify the data.");
      }
    }
    const onSearch = (e) => {
      if (e !== '') {
        getOptions(e);
        setvalue(e);
        setlineItem((l) => ({ ...l, repair_code: e || '' }))
      }
    }
    return (
      <div className="searchable">
        <Select
          showSearch
          allowClear
          autoClearSearchValue={false}
          value={value}
          defaultValue={' '}
          placeholder="Search Repair ID's"
          onChange={onChange}
          onSearch={onSearch}
          options={codeOptions}
          disabled={editMode}
        >
        </Select>
      </div>
    );
  }

  const renderTechnicalForm = () => {
    if (techForm?.length > 0) {
      const form = techForm[0];
      const url = "".concat(
        form.form_url.replace(/\/$/, ""),
        "/",
        form.form_data_uuid
      );
      return (
        <div className="mt-20">
          <div className="fitem half-fitem"></div>
          <h4 className="mb-15">Technical Form</h4>
          <a href={url} target="_blank" rel="noreferrer" className="techFormWO">
            <FileTextOutlined className="orange" /> {form.form_name}
          </a>
        </div>
      );
    }
  };

  const renderWorkType = () => {
    switch (state?.servicetype?.toLowerCase()) {
      case "maintenance":
        return (
          <div>
            <h4 className="mb-10 mt-20">
              How often do you need your pool cleaned?
            </h4>
            <label>
              {state.worktype}: {state.worksubtype}
            </label>
          </div>
        );
      case "service":
        return (
          <div>
            <h4 className="mb-10 mt-20">What Service are you looking for?</h4>
            <label>
              {state.worktype}: {state.worksubtype}
            </label>
          </div>
        );
      default:
        break;
    }
    if (state.servicetype === "maintenance") {
    }
  };

  // function ServiceOrderSummary() {
  //   const { price, service_type } = state;
  //   let total_price_per_service = price;
  //   const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);
  //   const setService = (service) => {
  //     if (service)
  //       switch (service.toLowerCase()) {
  //         case "service":
  //           return "Service";
  //         case "maintenance":
  //           return "Maintenance";
  //         default:
  //       }
  //   };

  //   const setRecurring = (frequency) => {
  //     if (frequency)
  //       switch (frequency.toLowerCase()) {
  //         case "weekly":
  //           return "Weekly";
  //         case "bi-weekly":
  //           return "Bi-Weekly";
  //         case "monthly":
  //           return "Monthly";
  //         case "once":
  //           return "One-Time";
  //         default:
  //           return "";
  //       }
  //   };

  //   return (
  //     <div style={{ background: "white", padding: "25px 30px" }}>
  //       <h2 className="mb-20">Service Order Summary</h2>
  //       <h5 className="mb-10">
  //         <b>Subscription details</b>
  //       </h5>
  //       <div>
  //         <div className="fs-13 mb-10">
  //           <div className="summary-label">Subscription</div>
  //           <div className="flex summary-detail">
  //             <span>
  //               {setService(state.service_type)}
  //               {state.service_type !== "maintenance"
  //                 ? state.worktype === "frequency"
  //                   ? " | " + setRecurring(state.worksubtype) + " Charge"
  //                   : state.worktype
  //                 : ""}{" "}
  //             </span>
  //             {take_diagnostic ?
  //               <span>${formatToCurrency(amount(price))}</span> : ''
  //             }
  //           </div>
  //           <div className="flex summary-detail">
  //             <span>
  //               {state.worktype === "frequency"
  //                 ? setRecurring(state.worksubtype)
  //                 : state.worktype}
  //             </span>
  //           </div>
  //           <div className="flex summary-detail">
  //             <span>{state.pool_type}</span>
  //           </div>
  //         </div>

  //         {state.service_type.toLowerCase() === 'service' && addons?.length > 0 && (
  //           <div className="fs-13 mb-10">
  //             <div className="summary-label">Addons</div>
  //             {addons?.map((item, index) => (
  //               <div className="flex summary-detail" key={index}>
  //                 <span>{item?.name}</span>
  //                 <span>${formatToCurrency(amount(item?.price))}</span>
  //               </div>
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //       <div className="flex summary-total">
  //         <label>Total to Pay (excl. tax)</label>
  //         <span>${
  //           state.service_type.toLowerCase() === 'service' ?
  //             formatToCurrency(amount(totalNoTax))
  //             : formatToCurrency(amount(price))
  //         }</span>
  //       </div>
  //       <div className="flex summary-total">
  //         <label>Total Tax</label>
  //         <span>${
  //           state.service_type.toLowerCase() === 'service' ?
  //             formatToCurrency(amount(totalTax))
  //             : 0
  //         }</span>
  //       </div>
  //       <div className="flex summary-total">
  //         <span>Total to Pay</span>
  //         <span>${
  //           state.service_type.toLowerCase() === 'service' ?
  //             formatToCurrency(amount(total))
  //             : formatToCurrency(amount(price))
  //         }</span>
  //       </div>
  //     </div>
  //   );
  // }

  function WorkOrderSummary() {
    const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);
    if (state.service_type.toLowerCase() === 'maintenance') {
      return (
        <div className="mt-30" style={{ background: "white", padding: "25px 30px" }}>
          <h2 className="mb-20">Work Order Summary</h2>
          <h5 className="mb-10">
            <b>Additional Work</b>
          </h5>
          <div className="fs-13 mb-10">
            {addons?.length > 0 && (
              <div className="fs-13 mb-10">
                <div className="summary-label">Addons</div>
               {addons?.map((item, index) => (
                  <div style={{ display: 'flex' }} key={index}>
                    <div className="long-description">{item?.name}</div>
                     <div style={{ marginLeft: '20px' }}>${formatToCurrency(amount(item?.price))}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex summary-total mt-20">
            <label>Total to Pay (excl. tax)</label>
            <span>${formatToCurrency(amount(totalNoTax))}</span>
          </div>
          <div className="flex summary-total">
            <label>Total Tax</label>
            <span>${formatToCurrency(amount(totalTax))}</span>
          </div>
          <div className="flex summary-total">
            <span>Total to Pay</span>
            <span>${formatToCurrency(amount(total))}</span>
          </div>
        </div>
      )
    }
  }

  return <div> {loader && <Loader />}{ServiceDetails_()} </div>;
};

export default EditWorkOrderURL;
