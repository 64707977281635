const {createSlice} = require('@reduxjs/toolkit');

const initialState = [];

const zipCodeDataSlice = createSlice({
    name:'zipCodeData',
    initialState,
    reducers:{
        addZipCodeData(state,action){
            return state=action.payload
        }
    }
})

export const {addZipCodeData} = zipCodeDataSlice.actions;
export default zipCodeDataSlice.reducer;