import React, { useEffect } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Col, Select } from "antd";
import { map } from "lodash";
import { addTaskDurationAction, getTaskDurationData, updateTaskDuration } from "../../../../Store/technicianTaskDataSlice";
import { getTaskDuration } from "../../../../Action/Technician/index";
import { addDuration, updateDuration } from "../../../../Store/serviceOrderDetailDataSlice";
const { Option } = Select;

const AddDurationField = (props) => {

    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.technicianTaskJourney);
    const serviceOrderNewFieldsValue = useSelector((state) => state.serviceOrderAddedData);
    let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));

    const getTaskDurationCall = async () => {
        const durationData = await getTaskDuration();
        durationData?.durations?.sort(function(a, b){return a-b});
        dispatch(getTaskDurationData(durationData));
    };

    useEffect(() => {
        getTaskDurationCall();
        if(tasks?.showTaskFromEdit === true){
            dispatch(addTaskDurationAction(tasks?.selectedTaskData?.duration))
        }
    }, []);

    const getDefaultValue = () => {
        if (tasks?.onTaskDurationChange === true && (tasks?.showTaskFromEdit  || !tasks?.showTaskFromEdit)) {
            return tasks?.duration;
        }
        if (tasks?.showTaskFromEdit === true) {
          return tasks?.selectedTaskData?.duration;
        }
        if (serviceOrderNewFieldsValue?.editDurationField === true) {
          return serviceOrderNewFieldsValue?.duration;
        }
        if (serviceOrderNewFieldsValue?.editDurationField === false && serviceOrderNewFieldsValue?.onDurationChange === true) {
            return serviceOrderNewFieldsValue?.duration;
        }
        return franchise_data?.data?.default_job_duration;
      };

    const onDurationUpdate = (e) => {
        dispatch(addDuration(e));
        dispatch(updateDuration(true));
    }

    const onTaskDurationUpdate = (e) => {
        dispatch(addTaskDurationAction(e));
        dispatch(updateTaskDuration(true));
    }

    return (
        <Col xl={props.visible === "workorderScreen" ? 24 : 11} xs={24}>
            {props.durationType === 'service' ? props.visible === "workorderScreen" ?  <label className={props.screen === "soPopUp" ? "" : "serviceDuration"}>Service Duration</label> : <h4 className={props.screen === "soPopUp" ? "" : "serviceDuration"}>Service Duration</h4> : <label>Task Duration<sup>*</sup></label>}
            <div className={props.durationType === 'service' ? "serviceDurationInput" : ""}>
                <Select
                    defaultValue={getDefaultValue()}
                    dropdownClassName="sm-filter"
                    showArrow={true}
                    name={'taskDuration'}
                    onChange={(e) => {
                        props.durationType === 'service' ? onDurationUpdate(e) : onTaskDurationUpdate(e)
                    }}
                    value={getDefaultValue()}
                >
                    {map(tasks.taskDurationData?.durations, (item, index) => <Option value={item} key={item}>{item} Minutes</Option>)}
                </Select>
            </div>
        </Col>
    );
};

export default AddDurationField;
