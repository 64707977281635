import axiosInstance from "../../Lib/Axios";
import { message } from "antd";

// Login
export const postLogin = (params) => {
  // return (dispatch) => {
  return axiosInstance
    .post(`auth/login/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  // };
};

// Forgot Password
export const forGotPWD = (params) => {
  return axiosInstance
    .post(`/auth/password/reset-custom/`, params, {})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error.response) {
        return error?.response?.data;
      }
    });
};

export const newPassword = (uidb64,token,data) => {
   return axiosInstance
    .post(`/auth/reset/${uidb64}/${token}/`,data,{})
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error && error.response) {
        return error?.response?.data;
        
      }
    });
};

// Register User
export const userRegister = async (params, uuid) => {
  try {
    //Edit when API is ready
    const response = await axiosInstance.post(
      `/crm/staff-user/${uuid}/update_user_details/`,
      params,
      {}
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// verify user of detail
export const verifyRegisterUser = async (params) => {
  try {
    //Edit when API is ready
    const response = await axiosInstance.post(
      `/crm/staff-user/add_user_confirmation/`,
      params,
      {}
    );
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// get user by key
export const getUserByKey = async (body) => {
  try {
    const response = await axiosInstance.post(`/user/get_by_key/`, body, {});
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      return error;
    }
  }
};

// getDashboardSummary
export const authLogout = async (body) => {
  try {
    const response = await axiosInstance.post(`/auth/logout/`, body, {});
    return response?.data;
  } catch (error) {
    if (error.status !== 200) {
      message.error("Fail to request data");
      throw error;
    }
  }
};
