import React, { useState, useCallback, useEffect } from "react";
import { debounce, map,orderBy } from "lodash";
import moment from "moment-timezone";
import { PlusCircleOutlined } from "@ant-design/icons";
import { searchCustomer, getServiceOrderByClient, searchTechnician } from "../../../Action/Customer/index";
import {
  ConfigProvider,
  Select,
  Empty,
  Spin,
  DatePicker,
  TimePicker,
  Input
} from "antd";
import { getFieldOfficeStaff } from "../../../Action/BulkReassign";

const { TextArea } = Input;

const JobPop = ({ jobPopUpDataRef, setJobPopupSubmitBtnDisable, setcreateServiceOrder, setcreateCustomer, franchise }) => {
  const [state, setState] = useState({
    status: "",
    date: "",
    time: "",
    description_notes: "",
    services_lineitem: [],
    customer: [],
    serviceOrderList: [],
    selectedServiceOrder: '',
    preferredTechnician: ''
  });
  const [codeOptions, setcodeOptions] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [selectLoading, setSelectLoading] = useState(true);
  const [value, setvalue] = useState('');

  const [preferredTech, setPreferredTech] = useState('')
  const [techOptions, setTechOptions] = useState([]);
  const [openTechSelect, setOpenTechSelect] = useState(false);
  const [techData, setTechData] = useState([]);


  const formatTime = (time) => {
    let splitedTime = time?.split(' ');
    let [hours, minutes] = splitedTime?.[0]?.split(':')
    if (splitedTime?.[1] === 'am') {
      return `${hours}:${minutes}`
    } else {
      return `${parseInt(hours) + 12}:${minutes}`
    }
  }
  
  useEffect(() => {
    jobPopUpDataRef.current = state;
    let selectedTime = '';
    if (jobPopUpDataRef?.current?.time) {
      selectedTime = !jobPopUpDataRef?.current?.time ? '12:00:00' : moment(jobPopUpDataRef?.current?.time).format("HH:mm:ss") === "Invalid date" ? formatTime(jobPopUpDataRef?.current?.time) : moment(jobPopUpDataRef?.current?.time).format("HH:mm:ss");
    }
    if (state?.selectedServiceOrder && Object.keys(state?.customer)?.length && state?.date?.length > 0 && selectedTime?.length > 0) {
      setJobPopupSubmitBtnDisable(false);
    } else {
      setJobPopupSubmitBtnDisable(true);
    }
    getPreferredTechnician();
  }, [state]);

  useEffect(() => {
    if (Object.keys(state.customer).length) {
      (async () => {
        const data = await getServiceOrderByClient({
          field_office_uuid: state?.customer?.field_office?.uuid,
          client_uuid: state?.customer?.uuid,
        });
        const filteredData = data.filter((item) => item.service_order_name !== null && item.service_order_name !== "Retail Invoice" && (item.is_active === true && item.is_hide_from_display === false));

        setState((s) => ({
          ...s,
          serviceOrderList: filteredData,
        }));
      })();
    }
  }, [state?.customer]);

  const getOptions = async (search) => {
    setSelectLoading(true)
    const options = await searchCustomer(search);
    setCustomerData(options.results);
    const _options = options.results.map((result) => {
      return {
        value: result.first_name + " " + result.last_name,
        label: result.first_name + " " + result.last_name
      }
    });
    setSelectLoading(false)
    setcodeOptions(_options);
  }

  ///On Functions Preferred Technician
  const getPreferredTechnician = async () => {
    setSelectLoading(true);
    let StaffProfileFieldOfficeuuid = franchise?.value ? franchise.value : "";
    const optionsTech = await getFieldOfficeStaff({ "field_office__uuid": StaffProfileFieldOfficeuuid });
    setTechData(optionsTech.results);
    const optionsTechnician = orderBy(optionsTech.results, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc").map((result) => {
      return {
        value: result?.user?.first_name + " " + result?.user?.last_name,
        label: result?.user?.first_name + " " + result?.user?.last_name,
      }
    })
    setSelectLoading(false);
    setTechOptions(optionsTechnician);
  }
  const onTechChange = (e) => {
    let selectedTechCode = [];
    if (e) {
      setPreferredTech(e);
      selectedTechCode = techData.filter((item) => {
        return item?.user?.first_name + ' ' + item?.user?.last_name == e
      });
    } else { setPreferredTech(''); }
    if (selectedTechCode.length > 0) {
      setState((s) => ({
        ...s,
        preferredTechnician: selectedTechCode[0]
      }));
    } else {
      setState((s) => ({
        ...s,
        preferredTechnician: ''
      }));
    }
  }

  const onTechSearch = (e) => {
    setTechOptions([]);
    if (e !== "") {
      setPreferredTech(e);
      onTechSearchDebounce(e);
    }
    else {
      setPreferredTech('');
    }
  }


  const onTechSearchDebounce = useCallback(
    debounce(e => {
      getPreferredTechnician(e);
    }, 1000),
    []
  );

  const onChange = (e) => {
    let selectedCode = [];
    if (e) {
      setvalue(e);
      selectedCode = customerData.filter((item) => {
        return item.first_name + ' ' + item.last_name == e
      });
    } else { setvalue(''); }
    if (selectedCode.length > 0) {
      setState((s) => ({
        ...s,
        customer: selectedCode[0],
      }));
    } else {
      setState((s) => ({
        ...s,
        customer: [],
        serviceOrderList: [],
        selectedServiceOrder: ''
      }));
    }
  }

  const onSearch = (e) => {
    setcodeOptions([]);
    if (e !== "") {
      onSearchDebounce(e);
    } else {
      setvalue('');
    }
  }

  const onSearchDebounce = useCallback(
    debounce(e => {
      getOptions(e);
    }, 1000),
    []
  );

  const onChangeJobName = (e, item) => {
    const service_order_selected = state.serviceOrderList.find(
      (i) => i.uuid === e
    );

    setState((s) => ({
      ...s,
      selectedServiceOrder: service_order_selected?.service_order_name ?? "",
      date: service_order_selected?.preferred_start_date ?? "",
      time: service_order_selected?.schedule_time_range ?? "",
      preferredTechnician: service_order_selected?.preferred_technician ?? "",
    }));

    if (service_order_selected?.preferred_technician?.uuid) {
      setPreferredTech(`${service_order_selected?.preferred_technician?.user?.first_name} ${service_order_selected?.preferred_technician?.user?.last_name}`);
    } else {
      setPreferredTech("");
      getPreferredTechnician();
    }
  }

  const renderCustomerNameSearch = () => {
    return (
      <div className="searchable">
        <ConfigProvider renderEmpty={() => selectLoading ? <div className="searchableCustomerNameSpin">
          <Spin tip="Loading Customer Names..." /></div> : <Empty />}>
          <Select
            placeholder="Customer Name"
            showSearch
            allowClear={true}
            autoClearSearchValue={true}
            value={value || undefined}
            onChange={onChange}
            onSearch={onSearch}
            suffixIcon={null}
            options={codeOptions}
            open={openSelect}
            onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
          >
          </Select>
        </ConfigProvider>
      </div>
    );
  }

  const renderTechnicianSearch = () => {
    return (
      <div className="searchable">
        <ConfigProvider renderEmpty={() => selectLoading ? <div className="searchableCustomerNameSpin">
          <Spin tip="Loading Technicians, please type a name..." /></div> : <Empty />}>
          <Select
            placeholder="Technician Name"
            showSearch
            allowClear={true}
            autoClearSearchValue={true}
            value={preferredTech || undefined}
            onChange={onTechChange}
            onSearch={onTechSearch}
            suffixIcon={null}
            options={techOptions}
            open={openTechSelect}
            onDropdownVisibleChange={(visible) => setOpenTechSelect(visible)}
            disabled={!Object.keys(state?.customer || {}).length > 0}
            showArrow
          >
          </Select>
        </ConfigProvider>
      </div>
    );
  }

  return (
    <>
      <div className="jobPopCustomer">
        <label>
          Customer<sup>*</sup>
        </label>
        {renderCustomerNameSearch()}
        {!Object.keys(state.customer).length ?
          <a role="button" className="flex ai-center justify-content-start"
            onClick={(e) => { setcreateCustomer(true); }}
          >
            <PlusCircleOutlined style={{ marginRight: "6px" }} />{" "}
            Add New Customer
          </a> : null}
        {Object.keys(state.customer).length ? <div className="customerDetails">
          <span className="address">
            <label><b>Address:</b>&nbsp;{state?.customer?.address[0]?.formatted_address}</label>
          </span>
          <span className="email-id">
            <label><b>Email:</b>&nbsp;{state?.customer?.emailaddress_set[0]?.email.toLowerCase()}</label>
          </span>
        </div> : null}
      </div>
      <div className="jobPopJobName">
        <label>
          Job Name<sup>*</sup>
        </label>
        <Select
          dropdownClassName="sm-filter"
          showArrow
          name={'category'}
          virtual={false}
          style={{ width: '100%' }}
          placeholder={'Job Name'}
          disabled={
            !Object.keys(state?.customer || {}).length > 0 ||
            !state.serviceOrderList.length > 0
          }
          onChange={(e, item) => {
            onChangeJobName(e, item)
          }}
        >
          {map(state.serviceOrderList, (item, index) => <Option value={item?.uuid} key={item?.uuid}>{item?.service_order_name}</Option>)}
        </Select>
        <a role="button" className="flex ai-center justify-content-start"
          style={{ pointerEvents: `${Object.keys(state?.customer || {}).length > 0 ? 'auto' : 'none'}` }}
          onClick={(e) => { setcreateServiceOrder(true); }}
        >
          <PlusCircleOutlined style={{ marginRight: "6px" }} />{" "}
          Create New Job Name
        </a>
      </div>
      <div className="jobPopJobName">
        <label>
          Preferred Technician
        </label>
        {renderTechnicianSearch()}
      </div>
      <div className="jobPopDateTime">
        <label>
          Assign Date<sup>*</sup>
        </label>
        <label>
          Time<sup>*</sup>
        </label>
        <DatePicker
          style={{ width: "47%", marginRight: "5px" }}
          disabled={!Object.keys(state?.customer || {}).length > 0}
          disabledDate={(current) => {
            return moment().add(-1, 'days') >= current
          }}
          format="MMM DD,YYYY"
          suffixIcon={<i className="las la-calendar"></i>}
          allowClear={true}
          value={state.date ? moment(state.date) : null}
          onChange={(e, item) => {
            if (e) {
              setState((s) => ({
                ...s,
                date: moment(e).format("YYYY-MM-DD"),
              }));
            } else {
              setState((s) => ({ ...s, date: null }));
            }
          }}
        />
        <TimePicker
          style={{ width: "47%", marginLeft: "5px" }}
          className="swhite"
          placeholder={"--:-- AM/PM"}
          value={state?.time ? moment(state?.time, "hh:mm A") : ""}
          onChange={(e) => {
            setState((s) => ({ ...s, time: e }));
          }}
          format={"h:mm A"}
          disabled={!state.date || !Object.keys(state?.customer || {}).length > 0}
        />
      </div>
      <div className="jobPopDesc">
        <label>
          Description:
        </label>
        <TextArea
          value={state.description_notes}
          placeholder="Description"
          style={{ width: "750px", minHeight: "130px" }}
          onChange={(e) => {
            setState((s) => ({
              ...s,
              description_notes: e.target.value,
            }));
          }}
        />
      </div>
    </>
  )
}
export default JobPop;