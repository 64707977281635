import React from "react";
import { Table, Row, Col } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import DataTable from "./DataColumns";
import { useReport } from "../../Hooks/Report.Hook";

const ExpiringCustomerPaymentMethodTable = () => {
  const {
    reportData,
    current,
    total,
    query,
    setCurrent,
    setPageSize,
    pageSize,
    setSorter,
  } = useReport();

  const handleTableChange = (pagination, filters, sorter, extra) =>
    extra.action === "sort"
      ? sorter.order !== undefined
        ? setSorter(sorter)
        : setSorter(null)
      : null;

  const processData = reportData.map((item, idx) => ({
    ...item,
    key: idx,
  }));

  return (
    <>
      <Row>
        <Col span={24} className={"residential-lead-table-container"}>
          <div className="card">
            <div className="card-title">
              <div className="form-group ai-center w-100 fltr-txt pt-10 ccr-block">
                <div className="fitem half-fitem d-block franch-min-w franch-min-w-inv">
                  <label className="d-block helper-text">Franchise</label>
                  <h6 className="mb-0 font-weight-bold">
                    {!_.isEmpty(query?.franchise)
                      ? query?.franchise?.label
                      : "--"}
                  </h6>
                </div>
                <div
                  className="fitem half-fitem d-block"
                  style={{ marginLeft: "20px" }}
                >
                  <label className="d-block helper-text">Time Period</label>
                  <h6 className="mb-0 font-weight-bold">
                    {!_.isEmpty(query?.period)
                      ? `${moment(query.period?.start_date).format(
                          "ll"
                        )} - ${moment(query.period?.end_date).format("ll")}`
                      : "--"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <Table
            dataSource={processData}
            columns={DataTable.ExpiringCustomerPaymentMethod(this)}
            className="table hscroll"
            onChange={handleTableChange}
            pagination={{
              showSizeChanger: false,
              pageSize: pageSize,
              ordering: "",
              current,
              total,
              onChange(page, pageSize) {
                setCurrent(page);
                setPageSize(pageSize);
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExpiringCustomerPaymentMethodTable;
