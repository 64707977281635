import React, { useEffect } from "react";
import { Row, Col, Card } from "antd";
import { useState } from "react";
const CustomerDetails = (props) => {
  const [formName, setFormName] = useState("");
  const [getPoolsize, setPoolSize] = useState(0);
  useEffect(() => {
    let url = window.location.href;
    url.substring(url.lastIndexOf("/") + 1, url.length);
    let formName = url.split("/")[3];
    setFormName(formName);
  }, []);
  const handleChange = (e) => {
    setPoolSize(e.target.value);
  };
  useEffect(() => {
    props?.handlePoolSize(getPoolsize);
  }, [getPoolsize]);
  return (
    <Card
      className="middle-section"
      title="Customer Details"
      headStyle={{
        fontSize: "16px",
        fontWeight: "bold",
        borderBottom: "1px solid #E1E1E5",
      }}
      bodyStyle={{ padding: "24px" }}
    >
      <Row justify="space-around">
        <Col xs={24} sm={24} md={11} lg={11}>
          <Row className="mb-10">
            <Col xs={10} sm={10} md={6} lg={6}>
              <strong>Customer Name :</strong>
            </Col>
            <Col xs={14} sm={14} md={6} lg={6}>
              <strong>{props?.client?.client?.name}</strong>
            </Col>
          </Row>
          <Row className="mb-10">
            <Col xs={10} sm={10} md={6} lg={6}>
              <strong>Address1 :</strong>
            </Col>
            <Col xs={14} sm={14} md={6} lg={6}>
              <strong>{props?.client?.client?.service_address?.address}</strong>
            </Col>
          </Row>
          <Row className="mb-10">
            <Col xs={10} sm={10} md={6} lg={6}>
              <strong>City/State/Zip :</strong>
            </Col>
            <Col xs={14} sm={14} md={6} lg={6}>
              <strong>
                {props.client?.client?.service_address?.city},{" "}
                {props?.client?.client?.service_address?.state}{" "}
                {props?.client?.client?.service_address?.zip_code}
              </strong>
            </Col>
          </Row>
          <Row className="">
            <Col xs={10} sm={10} md={6} lg={6}>
              <strong>Tech Name :</strong>
            </Col>
            <Col xs={14} sm={14} md={6} lg={6}>
              <strong>
                {props?.allData?.technician
                  ? props?.allData?.technician?.name
                  : ""}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11}>
          <Row className="mb-10">
            <Col xs={10} sm={10} md={6} lg={6}>
              <strong>Email :</strong>
            </Col>
            <Col xs={14} sm={14} md={6} lg={6}>
              <strong>{props?.client?.client?.email}</strong>
            </Col>
          </Row>
          <Row className="mb-10">
            <Col xs={10} sm={10} md={6} lg={6}>
              <strong>Phone:</strong>
            </Col>
            <Col xs={14} sm={14} md={6} lg={6}>
              <strong>{props?.client?.client?.phone_numbers?.phone}</strong>
            </Col>
          </Row>
          {formName === "maintenance-pool-report-form" ? (
            <Row>
              <Col xs={10} sm={10} md={6} lg={6}>
                <strong>Pool Size : </strong>
              </Col>
              <Col xs={14} sm={14} md={6} lg={6}>
                <div>
                  {getPoolsize === 0 ? (
                    <input
                      id="customField"
                      enabled
                      value={props?.poolSize}
                      type="text"
                      name="input"
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      id="customField"
                      enabled
                      value={getPoolsize}
                      type="text"
                      name="input"
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={10} sm={10} md={6} lg={6}>
                <strong>Pool Size : </strong>
              </Col>
              <Col xs={14} sm={14} md={6} lg={6}>
                <div>
                  <input
                    id="client.customField1"
                    disabled
                    value={props?.poolSize}
                    type="text"
                    name="input"
                    autoComplete="off"
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default CustomerDetails;
