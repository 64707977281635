import React from "react";
import { Row, Col, Dropdown } from "antd";
import { Eventcalendar, localeEn } from "@mobiscroll/react";
import moment from "moment-timezone";
import { EllipsisOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {toggleTaskModal, setSelectedTaskData, setTaskFlagFromEdit, setDisableTechnicianTask} from "../../../Store/technicianTaskDataSlice";

export const MobileEventCalendarContainer = (props) => {
  const { onSelectedDateChange, currentDate, customWithNavButtons, myEvents,
    status, onEventClick, viewMobileData, createItems, showPoolCharacteristicsModal,
    showRescheduleModal, showDeleteModal, shouldOpenPopup, role, openAccountOverview, showActivityLogModal, taskIcon, showWOModal } = props;

  const tasks = useSelector((state) => state.technicianTaskJourney.ServiceTaskData);
  let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
  const dispatch = useDispatch();
  const renderOptList = (data) => {
    if (data?.original?.type == 'Task' && data?.original?.status == 'complete') {
      return false;
    }
    return true;
  }
  return (
    <Eventcalendar
      class="main-calendar-mobile"
      theme="ios"
      themeVariant="light"
      onSelectedDateChange={onSelectedDateChange}
      selectedDate={currentDate}
      clickToCreate={false}
      dragToCreate={false}
      dragToMove={false}
      dragToResize={false}
      eventDelete={false}
      dateFormat="MM/YYYY"
      renderHeader={customWithNavButtons}
      data={Array.isArray(myEvents) ? myEvents?.filter((item) => _.includes(status, item?.status)) : []}
      onEventClick={onEventClick}
      locale={{
        ...localeEn,
        moreEventsText: "+ {count} more",
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        dateFormatLong: "DDDD, MMMM D",
      }}
      view={viewMobileData}
      renderEvent={(data) => (
        <>
        <div className={`duration-${data?.original?.duration ? data?.original?.duration : franchise_data?.data?.default_job_duration}`}>
          <Row className={`event-mobile ${data?.original?.status}`}>
            <Row className={`event-mobile-row`}>
              <Col span={12} className="event-mobile-time">
                {`${moment(data?.original?.start).utc().format("LT")}`}-{`${moment(data?.original?.start).add(data?.original?.duration ? data?.original?.duration : franchise_data?.data?.default_job_duration, 'minutes').utc().format("LT")}`}
              </Col>
              {renderOptList(data) ?
                <Col
                  span={12}
                  className="event-mobile-actions"
                  onClick={(e) => e.preventDefault()}
                >
                  <Row
                    justify="end"
                    gutter={[10, 5]}
                    className="calendar-label-icons"
                  >
                    <Dropdown
                      menu={{
                        items: createItems(data),
                        onClick: ((data, e) => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          switch (e.key) {
                            case "pool_characteristics":
                              showPoolCharacteristicsModal(data);
                              return;
                            case "job_form":
                              /*window.open(
                                data?.original?.job_forms[0]?.form_url.replace('https://crm.dev2.poolops.com', 'https://classy-strudel-0352a1.netlify.app'),
                                "_blank"
                              );*/
                              showWOModal(data)
                              return;
                            case "work_order":
                              window.open(
                                data?.original?.edit_work_order_url,
                                "_blank"
                              );
                              return;
                            case "reschedule":
                              showRescheduleModal(data);
                              return;
                            case "delete":
                              showDeleteModal(data);
                              return;
                            case "edit_task":
                              dispatch(setSelectedTaskData(data?.original))
                              dispatch(toggleTaskModal(true))
                              dispatch(setTaskFlagFromEdit(true))
                              if(role === "field_tech" && data.original?.permissionset?.length == 0){
                                dispatch(setDisableTechnicianTask(true))
                              }
                              return;
                            case "activity_log":
                              showActivityLogModal(data);
                              return;
                            default:
                              return;
                          }
                        }).bind(this, data),
                      }}
                      trigger={["click"]}
                    >
                      <span
                        onClick={(e) => {
                          shouldOpenPopup.current = false;
                          setTimeout(
                            () => (shouldOpenPopup.current = true),
                            200
                          );
                          e.preventDefault();
                        }}
                        style={{ fontSize: "25px" }}
                      >
                        <EllipsisOutlined />
                      </span>
                    </Dropdown>
                  </Row>
                </Col> : null}
            </Row>
            <Row justify="space-between" gutter={[10]}>
              <Col xs={24} xl ={12} xxl={12} className="event-mobile-service-type">
                  <div className={`${data?.original?.type == "Task" ? 'task-tile-chip' : ''}`}>
                      {data?.original?.type == "Task" ? <img src={taskIcon} alt="" /> : null}
                      {data?.original?.serviceType?.toLowerCase() === "service" ?
                          <span>{data?.original?.serviceType}_{data?.original?.workType.charAt(0).toUpperCase() + data?.original?.workType.slice(1)}</span>
                          : <span>{data?.original?.serviceType}</span>}
                  </div>
              </Col>
          </Row>
            <Row className="event-mobile-row">
              <Col span={24} className="event-mobile-title">
                {data?.original?.client_name}
              </Col>
            </Row>
            <Row className="event-mobile-row">
              {data?.original?.type == "Task" ?
                <Col
                  span={18}
                  className="event-mobile-client"
                  onClick={() => {
                    shouldOpenPopup.current = false;
                    setTimeout(() => (shouldOpenPopup.current = true), 200);
                    if (role !== "lead_tech" && role !== "field_tech" && data?.original?.client_uuid) {
                      openAccountOverview(data);
                    }
                  }}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data?.original?.customer ? (
                    <span
                      className={`${role !== "field_tech" && role !== "lead_tech"
                        ? "link-title"
                        : ""
                        }`}>
                      {data?.original?.customer ? data?.original?.customer?.name : ''}
                    </span>
                  ) : (
                    <></>
                  )}
                </Col> : null}
              <Col
                span={18}
                className="event-mobile-client"
                onClick={() => {
                  shouldOpenPopup.current = false;
                  setTimeout(() => (shouldOpenPopup.current = true), 200);
                  if (role !== "lead_tech" && role !== "field_tech" && data?.original?.type != "Task") {
                    openAccountOverview(data);
                  }
                }}
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {role !== "field_tech" && role !== "lead_tech" && data?.original?.type != "Task" ? (
                  <span className="mbsc-font-icon mbsc-icon-link" />
                ) : (
                  <></>
                )}
                {data?.original?.title ? (
                  <span
                    className={`${role !== "field_tech" && role !== "lead_tech" && data?.original?.type != "Task"
                      ? "link-title"
                      : ""
                      }`}
                  >
                    {data?.original?.title}
                  </span>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Row>
          </div>
        </>
      )}
    />
  )
}