import React from 'react'
import { Link } from 'react-router-dom';

const Footer = (props) => {
  return (
    <div className="fix-btn-grp" style={{width: "100%"}}>
        <Link to={props?.source === "customers-lead-list"? "/customer-lead-list":"/residential"} className='btn-cancel'><button className="btn btn-white">Cancel</button></Link>
        <button className={`btn btn-bdr`} onClick={props.validate}>{props.btnLabel}</button>
    </div>
  )
}

export default Footer;
