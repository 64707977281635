import React from "react";
import { Row, Col } from "antd";
import GlobalSearch from "../../../Components/GlobalSearch";
import ToggleAddList from "../../../Calendar/Components/PopupView/ToggleAddList";
import { useCalendar } from "../../../Calendar/Hooks/Calendar.Hook";
import homeIcon from "../../../Assets/icons/home.svg";
import userIcon from "../../../Assets/icons/user-placeholder.svg";

const DashboardGlobalSearchContainer = () => {
  const { franchise } = useCalendar();
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const isCorporate =
    userInfo && userInfo?.user?.user_roles?.[0]?.display_name === "Corporate"
      ? true
      : false;

  return (
    <Row>
      <Col flex={7}>
        <GlobalSearch />
      </Col>
      <Col
      className="global-links-wrapper"
        flex={3}
      >
        <ToggleAddList franchise={franchise} isCalendar={false} disabled={(franchise.label === "all" && isCorporate) ? true : false} />
        <a href="/" className="quick-link-home">
          <img src={homeIcon} alt={""} style={{ width: "40px" }} />
        </a>
        <a href="#" className="quick-link-home">
          <img src={userIcon} alt={""} style={{ width: "40px" }} />
        </a>
      </Col>
    </Row>
  );
};

export default DashboardGlobalSearchContainer;
