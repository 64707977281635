import React,{useState} from "react";
import { DatePicker, Radio } from "antd";
import moment from "moment-timezone";
import { useReport } from "../../Hooks/Report.Hook";
import FranchiseSelector from "../Filters/Franchise.Selector";
import PeriodSelector from "../Filters/Period.Selector";
import { getStartEndPeriod } from "../../Common/getDates";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';
import TechnicianSelector from "../Filters/Technician.Selector";
import ClientTypeSelector from "../Filters/ClientType.Selector";
import ServiceTypeSelector from "../Filters/Services.Selector"

const TechnitionNotesSidebar = () => {
  const {
    franchise,
    serviceDate,
    setServiceDate,
    setFranchise,
    setServiceDatePeriod,
    technician,
    setTechnician,
    leadReportclientType,
    setLeadReportclientType,
    serviceType,
    setServiceType, 
  } = useReport();
  const [customServiceDate, setCustomServiceDate] = useState([moment().startOf("week"),moment().endOf("week")]);

  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
    setTechnician(['all']);
  };
  const handleTechnicianChange = (e, type) => {
    let selection = e;

    if (e.length > 1 && e[0] ==="all")
    {
      selection = e.filter(item => item !== "all");
    }
    else if (e.length === 0 || (e.length > 0 && e.includes('all')))
    {
      selection = ["all"];
    }
    setTechnician(selection);
  };
  const handleServiceDateChange = (e) => {
    setServiceDate(e);
    if(e === "custom")
        if(customServiceDate[0] === null){
          setCustomServiceDate([moment().startOf("week"),moment().endOf("week")])
          setServiceDatePeriod({start_date:moment().startOf("week").format("YYYY-MM-DD"), end_date:moment().endOf("week").format("YYYY-MM-DD")});
        }
        else
          setServiceDatePeriod({start_date:customServiceDate[0]?.format("YYYY-MM-DD"), end_date:customServiceDate[1]?.format("YYYY-MM-DD")});
    else
      setServiceDatePeriod(getStartEndPeriod(e));    
    
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const handleCustomDateChange = (e) => {
    if(e == null){
      setServiceDatePeriod({
        start_date: null,
        end_date: null,
      });
      setCustomServiceDate([null,null]);          
    }else{
      setServiceDatePeriod({
        start_date: e[0].format("YYYY-MM-DD"),
        end_date: e[1].format("YYYY-MM-DD"),
      });
      setCustomServiceDate([e[0],e[1]]);
    }
  };
  const handleLeadReportclientType = (value, { label }) => {
    setLeadReportclientType(value);
  };
  const handleServiceTypeChange = (e) => {
    setServiceType(e);
  };

  return (
    <>
      <div className="fitem">
        <label>
          <b>Franchise</b>
        </label>
        <FranchiseSelector
          handleChange={handleFranchiseChange}
          defaultValue={franchise}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Customer type</b>
        </label>
        <ClientTypeSelector
          handleChange={handleLeadReportclientType}
          defaultValue={leadReportclientType}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Service Type</b>
        </label>
        <ServiceTypeSelector
          handleChange={handleServiceTypeChange}
          defaultValue={serviceType}
        />
      </div>
      <div className="fitem">
        <label>
          <b>Technician</b>
        </label>
        <TechnicianSelector
          handleChange={handleTechnicianChange}
          defaultValue={technician}
          />
      </div>   
      <div className="fitem">
        <label>
          <b>Service Date</b>
        </label>
        <PeriodSelector
          handleChange={handleServiceDateChange}
          defaultValue={serviceDate === "day" ? "Today" : serviceDate}
        />
      </div>
      
      <div className="fitem" hidden={serviceDate === "custom"?false:true}>
        <label>
          <b>Service Dates (Range)</b>
        </label>
        <DatePicker.RangePicker          
          onChange={handleCustomDateChange}
          value={ customServiceDate}
        />
      </div>
    
      

    
    </>
  );
};

export default TechnitionNotesSidebar;
