import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _, { map, isEmpty, orderBy } from "lodash";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import {
  getLeadById,
  getLeadByIdResidential,
  deleteCommercialLead,
  deleteResidentialLead,
} from "../../Action/Customer";
import { checkZipCode } from "../../Action/Franchise";
import Loader from "../../Components/Loader";
import Header from "../../Components/Header";
import HeaderMobile from "../../Components/HeaderMobile";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import {
  updateLead,
  updateLeadResidential,
  getServiceOrderLeadById,
  getServiceOrderByLead,
} from "../../Action/Customer";
import { Steps, Drawer, Divider, message, Modal } from "antd";
import { errorHandle } from "../../Components/errorHandle";
import AddNewLead from "./AddNewLead";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import GlobalSearch from "../../Components/GlobalSearch";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {
  editDuration,
  addDuration,
  addReferenceNumber,
} from "../../Store/serviceOrderDetailDataSlice";

const { Step } = Steps;
const leadStage = ["new", "contacted", "interested", "customer"];
const LeadDetail = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState({});
  const [serviceOrderData, setServiceOrderData] = useState([]);
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [serviceTypeDataLoader, setServiceTypeDataLoader] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [currentLeadStep, setCurrentLeadStep] = useState(0);
  const [openUpdateDrawer, setOpenUpdateDrawer] = useState(false);
  const [serviceOrder, setServiceOrder] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [uuid, setUuid] = useState("");
  const dispatch = useDispatch();
  let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));

  const closeupdateDrawer = () => {
    setOpenUpdateDrawer(!openUpdateDrawer);
  };
  const getLeadDetail = async (param) => {
    let data;
    if (state.view === "commercial") {
      data = await getLeadById(param);
    } else {
      data = await getLeadByIdResidential(param);
    }

    setLeadData(data);
    setCurrentLeadStep(leadStage.indexOf(data?.lead_stage) || 0);
    setLoader(false);
  };
  const getLeadServiceOrderDetail = async (param) => {
    let data;
    if (state.view === "commercial") {
      data = await getServiceOrderLeadById(param);
    } else {
      data = await getServiceOrderByLead({
        field_office_uuid: leadData?.field_office?.uuid,
        client_uuid: leadData?.uuid,
      });
    }
    setServiceOrderData(data);
    setLoader(false);
  };
  const getLeadServiceTypeData = async () => {
    let data = await checkZipCode({
      addressable_zip_codes__contains: leadData?.zip_code,
      close: false,
    });
    setServiceTypeData(data);
    setServiceTypeDataLoader(false);
  };
  const updateLeadDetails = async (param) => {
    let data;
    if (state.view === "commercial") {
      data = await updateLead(param);
    } else {
      data = await updateLeadResidential(param);
    }
    setLeadData(data);
    setCurrentLeadStep(leadStage.indexOf(data?.lead_stage) || 0);
    setLoader(false);
  };
  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };
  // format phone number
  const formatPhoneNumber = (phone) => {
    phone = phone?.replace(/\D/g, "");
    return (
      // (XXX) XXX-XXXX
      `(${phone?.substr(0, 3)})` +
      " " +
      phone?.substr(3, 3) +
      "-" +
      phone?.substr(6)
    );
  };
  // render basic detail
  const renderBasicDetails = () => {
    let contact_log = leadData.lead_status_logs?.filter(
      (x) => x.stage === "contacted"
    );
    contact_log = orderBy(contact_log, "created_at", "asc");

    const basicDetail = [
      {
        key: "Franchise",
        value: !isEmpty(leadData?.field_office)
          ? leadData?.field_office?.name
          : "",
      },
      { key: "Outreach", value: `${leadData?.outreach}` },
      {
        key: "First Contacted",
        value:
          contact_log[0] && contact_log[0].created_at
            ? moment(contact_log[0].created_at).format("MMM DD, YYYY")
            : "",
      },
    ];

    return map(basicDetail, (item, index) => (
      <div key={`basic${index}`} className={"dcell"}>
        <span>
          <b>{item?.key}</b>
        </span>
        <span>{item?.value}</span>
      </div>
    ));
  };
  // render primary info
  const renderPrimaryInfo = () => {
    const name = leadData?.first_name
      ? `${leadData?.first_name} ${leadData?.last_name}`
      : "";
    let phone_numbers = "",
      primaryEmail = "";
    if (!isEmpty(leadData?.phone_numbers)) {
      map(leadData?.phone_numbers, (item) => {
        if (item?.primary) phone_numbers = item?.phone;
      });
    }

    !isEmpty(leadData?.emailaddress_set) &&
      map(leadData?.emailaddress_set, (item) => {
        primaryEmail =
          item?.primary && !item.email.includes("doody.com") && item?.email?.toLowerCase();
      });

    let authorizedUserName = leadData?.authorizedUser?.map(
      (item) => item?.user_name
    );

    const address =
    leadData?.address?.length > 1 
      ? 
        leadData?.address?.[1]?.formatted_address ??
        leadData?.address?.[1]?.address +
        ", " +
        leadData?.address?.[1]?.city +
        ", " +
        leadData?.address?.[1]?.state +
        leadData?.address?.[1]?.zip_code
      :
        leadData?.address?.[0]?.formatted_address ??
        leadData?.address?.[0]?.address +
        ", " +
        leadData?.address?.[0]?.city +
        ", " +
        leadData?.address?.[0]?.state +
        leadData?.address?.[0]?.zip_code;
    return (
      <div className="card">
        <div className="card-title">
          <h5>Primary Information</h5>
        </div>
        <div className="card-body">
          <div className="flex flex-start mb-10">
            <div className="label" style={{ maxWidth: "128px" }}>
              <b>Primary User:</b>
              {state.view === "commercial" && (
                <>
                  <div>Company name:</div>
                  <div>Number of Properties: {leadData?.no_of_properties}</div>
                  <div>Number of Pools: {leadData?.no_of_pools}</div>
                  <br />
                </>
              )}
              {/* <span>Authorized Users: </span> */}
            </div>
            <div className="value">
              <b>{name}</b>
              <p>{leadData?.company_name}</p>
              {/* <span>{authorizedUserName?.join(", ")}</span> */}
            </div>
          </div>
          <div className="mb-10">
            <b>{address}</b>
          </div>
          <div className="mb-10">
            <b>
              {!isEmpty(leadData?.phone_numbers)
                ? phone_numbers
                  ? `${formatPhoneNumber(phone_numbers)} (Primary)`
                  : `${formatPhoneNumber(leadData?.phone_numbers?.[0]?.phone)}`
                : ""}
            </b>
          </div>
          {!leadData?.is_dummy_email && (
            <div>
              <b>
                {!isEmpty(leadData?.emailaddress_set)
                  ? `${
                      primaryEmail
                        ? primaryEmail
                        : leadData.email &&
                          !leadData.email?.includes("doody.com")
                        ? `${leadData.email} (Primary)`
                        : ""
                    } `
                  : "-"}
              </b>{" "}
            </div>
          )}
          <br />
          <span className="success mt-10" onClick={() => closeupdateDrawer()}>
            <b>
              <a>
                <u>{"Update Contact Details"}</u>
              </a>
            </b>
          </span>
        </div>
      </div>
    );
  };
  // render primary info
  const renderSourceAttributionInfo = () => {
    return (
      <div className="card">
        <div className="card-title">
          <h5>Source & Attribution</h5>
        </div>
        <div className="card-body">
          <div style={{ display: "grid" }}>
            <span>
              Outreach: <b>{leadData.outreach}</b>
            </span>
            <span>
              How did you hear about us: <b>{leadData.hear_about_us?.[0]}</b>
            </span>
            <span>
              Service looking for: <b>{leadData.service_looking_for}</b>
            </span>
            <span>
              Attribution: <b>{leadData.marketing_source}</b>
            </span>
            <span>
              Attribution details: <b>{leadData.marketing_source_detail}</b>
            </span>
          </div>
        </div>
      </div>
    );
  };
  const setRecurring = (frequency) => {
    switch (frequency) {
      case "twice_weekly":
        return "Twice Weekly";
      case "weekly":
        return "Weekly";
      case "bi_monthly":
        return "Every Other Week";
      case "monthly":
        return "Monthly";
      case "once":
        return "One-Time";
      default:
        return "";
    }
  };
  const renderActiveServices = () => {
    return (
      <div>
        {serviceOrderData?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!isEmpty(item?.service_address) ? (
                <div className="service-status p-0 aoa-service">
                  <div className="active_service_list color-252730">
                    <div className="subs">
                      <div className="flex justify-content-start position-relative">
                        <strong className="snum">
                          {item?.service_order_number}
                        </strong>
                        <span className="color-1F1F25 opacity-5 fs-12">
                          {item?.frequency ? item?.frequency : ""}
                        </span>
                      </div>
                      <div className="position-relative">
                        <span className="service-arrow ">
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOBAMAAADgeEClAAAAAXNSR0IB2cksfwAAAB5QTFRFAAAAdHR0b29vcHBwbm5ub29vb29vbm5ua2trbm5uzpGQqQAAAAp0Uk5TAAtOOfVlRWQ5T+9n3DQAAAAkSURBVHicYxBiAAKTACDB5gpipqAz3WEEiAuWgDNaQAokgRgAy9cGGr87WN8AAAAASUVORK5CYII="
                            alt=""
                          />
                        </span>
                        <h5 className="sadd mb-4">
                          {!isEmpty(item?.service_address)
                            ? `${item?.service_address?.address}, ${item?.service_address?.city}, ${item?.service_address?.state} ${item?.service_address?.zip_code}`
                            : "-"}
                        </h5>
                        <span className="altude pl-20 opacity-5 fs-11">
                          {!isEmpty(item?.service_address?.geo_location)
                            ? `${item?.service_address?.geo_location?.latitude}, ${item?.service_address?.geo_location?.longitude}`
                            : ``}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <Divider style={{ marginTop: "15px", marginBottom: "10px" }} />
              <div>
                <div>
                  <b>Subscription</b>
                  <div className="flex summary-detail" key={index}>
                    <span>{item?.service_type}</span>
                    <span>
                      {item?.service_price >= 0
                        ? `$${item?.service_price}`
                        : ""}
                    </span>
                  </div>
                  {item.service_type.toLowerCase() === "maintenance" ? (
                    <div className="flex summary-detail" key={index}>
                      <span>{item?.frequency}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.service_type.toLowerCase() === "service" ? (
                    <div className="flex summary-detail" key={index}>
                      <span>{item?.worktype}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.service_type.toLowerCase() === "renovation" ||
                  item.service_type.toLowerCase() === "construction" ? (
                    <div className="flex summary-detail" key={index}>
                      <span>{item?.notes}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  <span>
                    {!isEmpty(item?.pool_type)
                      ? `Pool Type: ${item?.pool_type
                          .charAt(0)
                          .toUpperCase()}${item?.pool_type.slice(1)}`
                      : ""}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const navigateToServiceOrder = () => {
    dispatch(editDuration(true));
    dispatch(
      addDuration(
        serviceOrderData?.[0]?.service_duration ||
          franchise_data?.data?.default_job_duration
      )
    );
    dispatch(addReferenceNumber(serviceOrderData?.[0]?.reference_number || ""));
    let source = state?.source;
    if (state.view === "commercial") {
      navigate(`serviceorder`, {
        state: {
          source: source,
          data: serviceOrderData,
          serviceTypeData: serviceTypeData,
          leadData: leadData,
          staxKey: serviceTypeData?.results[0]?.stax_public_apikey
        },
      });
    } else {
      navigate(`/residential/lead-list/community-info`, {
        state: {
          source: source,
          data: leadData,
          serviceOrder: serviceOrderData,
          staxKey: serviceTypeData?.results[0]?.stax_public_apikey
        },
      });
    }
  };

  const renderEnquiredService = () => {
    return (
      <div className="card">
        <div className="card-title flex">
          {serviceOrderData.length ? (
            <>
              <h5>Enquired Services</h5>
              <button
                className="btn btn-warning view-all-btn"
                onClick={() => navigateToServiceOrder()}
              >
                Edit Service
              </button>
            </>
          ) : (
            <button
              className="btn btn-warning view-all-btn"
              onClick={() => navigateToServiceOrder()}
            >
              {"Add New Service"}
            </button>
          )}
        </div>
        <div className="card-body">
          {serviceOrderData.length ? (
            <>
              {renderActiveServices()}
              <Divider style={{ marginTop: "5px", marginBottom: "10px" }} />
            </>
          ) : (
            <div style={{ padding: "0px 8px" }}>
              <ExclamationCircleOutlined className="exclamation_icon" />
              <i>
                {" "}
                Please{" "}
                {/* <a href="#" className="exclamation_icon" >
                                create first service
                            </a>{" "} */}
                create first service to convert the lead to customer
              </i>
              <br />
              <br />
              <i>
                Additional services can be added from the services tab from
                customer profile once Lead is converted to the customer
              </i>
            </div>
          )}
        </div>
      </div>
    );
  };
  // render lead stage
  const renderLeadStage = () => {
    // const { leadData, currentLeadStep, enquiryList } = this.state;
    const isConvertToCustomer = true;
    const leadLogs = [];
    map(leadData?.lead_status_logs, (obj) => {
      leadLogs[leadStage.indexOf(obj.stage)] = obj;
    });
    let contact_log = leadData?.lead_status_logs?.filter(
      (x) => x.stage === "contacted"
    );
    contact_log = orderBy(contact_log, "created_at", "asc");
    let interested = leadData?.lead_status_logs?.filter(
      (x) => x.stage === "interested"
    );
    interested = orderBy(interested, "created_at", "desc");

    return (
      <div>
        <Steps
          size="small"
          current={currentLeadStep}
          onChange={(current) => onLeadStepChange(current)}
          className="site-navigation-steps main-nav"
        >
          <Step
            className="current"
            title="New Lead"
            style={{ backgroundColor: currentLeadStep >= 0 ? "#F6CD31" : "" }}
          />
          <Step
            className="green"
            title="Contacted"
            style={{ backgroundColor: currentLeadStep >= 1 ? "#29b6e2" : "" }}
          />
          <Step
            className="red"
            title="Interested"
            style={{ backgroundColor: currentLeadStep >= 2 ? "#ABC318" : "" }}
          />
        </Steps>
        <div className={"flex flex-start"} style={{ marginTop: "5px" }}>
          {currentLeadStep >= 0 ? (
            <div className={"flex-1 txt-blck"}>
              <span className="d-block">
                {leadData?.created_at
                  ? moment(leadData.created_at).format("MMM DD, YYYY")
                  : moment().format("MMM DD, YYYY")}
              </span>
              <span>{leadData?.outreach ? leadData?.outreach : ""}</span>
            </div>
          ) : (
            <div />
          )}
          {currentLeadStep >= 1 ? (
            <div className={"flex-1 txt-blck"}>
              <span className="d-block">{`Contacted ${
                contact_log[0] && contact_log[0].created_at
                  ? moment(contact_log[0].created_at).format("MMM DD, YYYY")
                  : moment().format("MMM DD, YYYY")
              }\nby ${contact_log[0]?.contacted_by?.first_name || ""}`}</span>
            </div>
          ) : (
            <div />
          )}
          {currentLeadStep >= 2 ? (
            <div className={"flex-1 txt-blck"}>
              <span className="d-block">{`Interested ${
                interested[0] && interested[0].created_at
                  ? moment(interested[0].created_at).format("MMM DD, YYYY")
                  : moment().format("MMM DD, YYYY")
              }\nby ${interested[0]?.contacted_by?.first_name || ""}`}</span>
            </div>
          ) : (
            <div />
          )}
          <div className="flex-1 txt-blck">
            {currentLeadStep === 0 && (
              <button
                className="btn"
                style={{ width: "100%" }}
                onClick={() => onLeadStepChange(1)}
              >
                Moved to Contacted
              </button>
            )}
            {currentLeadStep === 1 && (
              <button
                className="btn"
                style={{ width: "100%" }}
                onClick={() => onLeadStepChange(2)}
              >
                Moved to Interested
              </button>
            )}
            {currentLeadStep === 2 && (
              <button
                disabled={isConvertToCustomer}
                style={{ width: "100%" }}
                className={`btn ${
                  isConvertToCustomer ? "disabled" : "btn-primary"
                }`}
                onClick={() => onLeadStepChange(3)}
              >
                Converted to Customer
              </button>
            )}
          </div>
        </div>
        <br />
        {currentLeadStep >= 3 ? <span>{""}</span> : <div />}
        <span className="success mt-10">
          <b>
            {leadData?.is_active
              ? "if this user no longer interested,"
              : "if this user is interested,"}
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                onDeactivate();
              }}
            >
              <u>{leadData?.is_active ? "Deactivate Lead" : "Activate Lead"}</u>
            </a>
            or
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                toggleDeleteModal();
              }}
            >
              <u>{" Delete Lead"}</u>
            </a>
          </b>
        </span>
      </div>
    );
  };
  // on lead step change
  const onLeadStepChange = (step) => {
    setLoader(true);
    if (!leadData?.address?.[0]?.geo_location) {
      delete leadData?.address?.[0]?.geo_location;
    }
    let obj = {
      uuid: leadData?.uuid,
      first_name: leadData?.first_name,
      last_name: leadData?.last_name,
      address: leadData?.address,
      authorizedUser: leadData?.authorizedUser,
      phone_numbers: leadData?.phone_numbers,
      lead_stage: leadStage[step],
      outreach: leadData?.outreach,
      role: leadStage[step] === "customer" ? ["customer"] : ["lead"],
    };
    if (leadStage[step] === "customer") {
      Promise.allSettled([setTimeout(() => updateLeadDetails(obj), 1000)]).then(
        (result) => {
          const isReject = result.filter((x) => x.status === "rejected");
          if (isReject?.length > 0) {
            setTimeout(() => errorHandle(isReject?.[0]?.reason), 1000);
          } else {
            if (leadStage[step] === "customer") {
              this.props.history.replace("/residential");
            }
          }
        }
      );
    } else {
      updateLeadDetails(obj);
    }
    if (leadStage[step] !== "customer") {
      setTimeout(() => getLeadDetail(leadData?.uuid), 500);
    }
  };
  //on deactivate lead
  const onDeactivate = () => {
    const id = state.uuid;
    setLoader(true);
    updateLeadDetails({ uuid: id, is_active: !leadData?.is_active });
  };

  // toggle delete modal
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  //Request to delete lead
  const onDeleteLead = async () => {
    setLoader(true);
    let response;
    if (state.view === "commercial") {
      response = await deleteCommercialLead(state.uuid);
    } else {
      response = await deleteResidentialLead(state.uuid);
    }

    if (response === "Customer marked as deleted.") {
      message.success("The Lead was deleted successfully.");

      state?.source === "customers-lead-list"
        ? navigate("/customer-lead-list", { state: { currentTab: "lead" } })
        : state.view === "commercial"
        ? navigate("/commercial/lead-list", { state: { tab: 0 } })
        : navigate(`/residential`, { state: { currentTab: 0 } });
    } else {
      message.error(response);
    }

    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    setUuid(state.uuid);
    getLeadDetail(state.uuid);
  }, []);

  useEffect(() => {
    //refresh upon global search
    if (uuid !== state.uuid && uuid !== "") {
      setLoader(true);
      setUuid(state.uuid);
      getLeadDetail(state.uuid);
    }
  }, [state?.uuid]);

  useEffect(() => {
    if (!isEmpty(leadData)) {
      const data = {
        field_office_uuid: leadData?.field_office?.uuid,
        client_uuid: state.uuid,
      };
      setLoader(true);
      getLeadServiceOrderDetail(data);
      // setLoader(true);
      setServiceTypeDataLoader(true);
      getLeadServiceTypeData();
    }
  }, [leadData]);

  return (
    <div id="wrapper">
      {(serviceTypeDataLoader || loader) && <Loader />}
      <div className="content-area">
        <GlobalSearch />
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenubuttonClick}
        />
        <div className="md-container">
          <div className="filter_wrapper">
            <ul className="breadcrumb">
              <li className={"cursor_pointer"}>
                <span
                  onClick={() => {
                    if (state?.source === "customers-lead-list")
                      navigate("/customer-lead-list", {
                        state: { currentTab: "lead" },
                      });
                    else if (state.view === "commercial")
                      navigate("/commercial/lead-list", { state: { tab: 0 } });
                    else navigate("/residential");
                  }}
                >
                  Leads
                </span>
              </li>
              <li>
                <span>{leadData.first_name}</span>
              </li>
            </ul>
          </div>
          <div className="residential-wrapper">
            <div className="res-head pb-0">
              <div className="flex ai-center mb-20">
                <div className="user-info">
                  <h3>{leadData.first_name}</h3>
                  <span className="">{leadData?.account_number || ""}</span>
                </div>

                <div className="user-detail">{renderBasicDetails()}</div>
              </div>
            </div>
            <div className="res-body">
              <div className="block-row">
                <div className="aside-area pt-0">
                  {loader && <Loader />}
                  <aside className="laside">
                    {renderPrimaryInfo()}
                    <br />
                    {renderSourceAttributionInfo()}
                    <br />

                    {renderEnquiredService()}
                  </aside>
                  <div className="main p-0">
                    <div className="card">
                      <div className="card-body">{renderLeadStage()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Delete Lead"
          open={showDelete}
          onCancel={() => toggleDeleteModal()}
          className="delete_lead_modal"
          width={400}
          footer={[
            <button
              key="cancel"
              className="btn btn-brand btn-bdr"
              onClick={() => toggleDeleteModal()}
            >
              Cancel
            </button>,
            <button
              key="upload"
              className={`btn btn-waning`}
              onClick={() => onDeleteLead()}
            >
              Delete
            </button>,
          ]}
        >
          <div>
            <b>
              Are you sure you want to Delete this Lead?
              <br /> All Lead details will be permanently deleted.
            </b>
          </div>
        </Modal>
      </div>
      {openUpdateDrawer && (
        <Drawer
          visible={openUpdateDrawer}
          width={"420"}
          closable={false}
          className="drawer-wrapper create_ticket_drawer"
        >
          <AddNewLead
            closeAddDrawer={closeupdateDrawer}
            type={"edit"}
            data={leadData}
            updateLeadDetails={updateLeadDetails}
            startLoader={setLoader}
            view={state.view}
          />
        </Drawer>
      )}
    </div>
  );
};

export default LeadDetail;