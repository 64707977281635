import React, { useEffect, useState } from "react";
import CustomerDetails from "./Common/CustomerDetails";
import FooterForm from "./Common/FooterForm";
import ImageUploader from "../Components/ImageUploader";
import { Row, Col, message, Select, Card } from "antd";
import { DownloadOutlined, LinkOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { authLogout } from "../Action/Auth";
import { getSaveMaintenencePoolReport, saveMaintenencePoolReport, postUploadImage, getUploadImagePoolInspection, sendTechnicianWorkOrder } from "../Action/Customer/index";
import Loader from "../Components/Loader";
import config from '../Lib/Config';
import { _, map, isEqual, isEmpty } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useActiveForm } from "./Hooks/Form.Hook";
import moment from "moment-timezone";

const PoolInspectionForm = () => {
  const params = useParams()
  const [client, setClient] = useState({});
  const [textButton, setTextButton] = useState("Save & Email");
  const [imageSources, setimageSources] = useState([]);
  const [imageAppearanceSources, setimageAppearanceSources] = useState([]);
  const [imageEquipmentSources, setimageEquipmentSources] = useState([]);
  const [imagePicturesSources, setimagePicturesSources] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ChoicesNA, setChoicesNA] = useState([
    // {value: null, label: "Select"},
    { value: "Pass", label: "Pass" },
    { value: "Needs Attention", label: "Needs Attention" },
    { value: "N/A", label: "N/A" },
  ]);
  
  const [imageAppearanceSource, setImageAppearanceSource] = useState([]);
  const [imageEquipmentSource, setImageEquipmentSource] = useState([]);
  const [imagePicturesSource, setImagePicturesSource] = useState([]);
  const [base64Image, setBase64Image] = useState({});
  const [tempImageAppearanceSource, setTempImageAppearanceSource] = useState([]);
  const [tempImageEquipmentSource, setTempImageEquipmentSource] = useState([]);
  const [tempImagePicturesSource, setTempImagePicturesSource] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState({});
  const active = useActiveForm();
  const navigate = useNavigate();

  useEffect(() => {
    let timer = null;
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    const clearLoginTimeout = () => {
      clearTimeout(timer);
    };

    const setLoginTimeout = () => {
      timer = setTimeout(() => {
        authLogout({ key: JSON.parse(localStorage.getItem("authToken")).key });
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/`);
      }, 1500000);
    };
    const resetTimeout = () => {
      clearLoginTimeout();
      setLoginTimeout();
    };

    for (var i in events) {
      if (
        _?.includes(
          ["admin", "franchise_owner", "operations_mgr"],
          JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles[0]
            ?.name
        )
      ) {
        window.addEventListener(events[i], resetTimeout);
      }
    }

    return () => {
      clearTimeout(timer);
      for (var i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, [active]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let tempBase64 = { ...base64Image };
      tempBase64[file.name] = reader.result;
      setBase64Image(tempBase64);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const getFilesAppearance = (list) => {
    list.forEach((each) => {
      if (Object.keys(each).includes("image_url")) {
        getBase64(each.image_url);
      }
    });
    setImageAppearanceSource(list);
  };

  const getFilesEquipment = (list) => {
    list.forEach((each) => {
      if (Object.keys(each).includes("image_url") && !each.image) {
        getBase64(each.image_url);
      }
    });
    setImageEquipmentSource(list);
  };

  const getFilesPictures = (list) => {
    list.forEach((each) => {
      if (Object.keys(each).includes("image_url") && !each.image) {
        getBase64(each.image_url);
      }
    });
    setImagePicturesSource(list);
  };

  const getImageUpload = async (id) => {
    setIsLoading(true)
    let appearanceImage = [];
    let equipmentImage = [];
    let picturesImage = []
    const data = await getUploadImagePoolInspection(id);
    if(!isEmpty(data)) {
      data.forEach((each) => {
        if(each.image_type === 'Pool Inspection Report Appearance') {
          let tempObjAppearance = {
            uid: each.id,
            name: each.name,
            url: each.image,
          }
          appearanceImage.push(tempObjAppearance)
        }
        else if(each.image_type === 'Pool Inspection Report Equipment') {
          let tempObjEquipment = {
            uid: each.id,
            name: each.name,
            url: each.image,
          }
          equipmentImage.push(tempObjEquipment)
        }
        else if(each.image_type === 'Pool Inspection Report Chemical') {
          let tempObjPictures = {
            uid: each.id,
            name: each.name,
            url: each.image,
          }
          picturesImage.push(tempObjPictures)
        }
    })}
    
    setImageAppearanceSource(appearanceImage);
    setImageEquipmentSource(equipmentImage);
    setImagePicturesSource(picturesImage);
    setIsLoading(false);
  }

  const postImageUpload = async () => {
    setLoader(true)
    let payload = {};
    let uploadedImageListAppearance = [];
    let uploadedImageListEuipment = [];
    let uploadImageListPictures = [];
    imageAppearanceSource.forEach((each) => {
      if(Object.keys(each).includes('image_url')) {
        let tempObjAppearance = {};
        tempObjAppearance['id'] = 0
        tempObjAppearance['image'] = each.image ?? base64Image[each.image_url.name]
        tempObjAppearance['name'] = each.image_url.name
        tempObjAppearance['type'] = "pool inspect appearance"

        uploadedImageListAppearance.push(tempObjAppearance)
      } else {
        let tempObjAppearance = {};
        tempObjAppearance['id'] = each.uid
        tempObjAppearance['image'] = each.url
        tempObjAppearance['name'] = each.name
        tempObjAppearance['type'] = "pool inspect appearance"

        uploadedImageListAppearance.push(tempObjAppearance)
      }
    })
    imageEquipmentSource.forEach((each) => {
      if(Object.keys(each).includes('image_url')) {
        let tempObjEquipment = {};
        tempObjEquipment['id'] = 0
        tempObjEquipment['image'] = each.image ?? base64Image[each.image_url.name]
        tempObjEquipment['name'] = each.image_url.name
        tempObjEquipment['type'] = "pool inspect equipment"

        uploadedImageListEuipment.push(tempObjEquipment)
      } else {
        let tempObjEquipment = {};
        tempObjEquipment['id'] = each.uid
        tempObjEquipment['image'] = each.url
        tempObjEquipment['name'] = each.name
        tempObjEquipment['type'] = "pool inspect equipment"

        uploadedImageListEuipment.push(tempObjEquipment)
      }
    })
    imagePicturesSource.forEach((each) => {
      if(Object.keys(each).includes('image_url')) {
        let tempObjPictures = {};
        tempObjPictures['id'] = 0
        tempObjPictures['image'] = each.image ?? base64Image[each.image_url.name]
        tempObjPictures['name'] = each.image_url.name
        tempObjPictures['type'] = "pool inspect chemical"

        uploadImageListPictures.push(tempObjPictures)
      } else {
        let tempObjPictures = {};
        tempObjPictures['id'] = each.uid
        tempObjPictures['image'] = each.url
        tempObjPictures['name'] = each.name
        tempObjPictures['type'] = "pool inspect chemical"

        uploadImageListPictures.push(tempObjPictures)
      }
    })
    let resultImage = uploadedImageListAppearance.concat(uploadedImageListEuipment.concat(uploadImageListPictures))
    payload["id"] = params.formuuid;
    payload["id_type"] = "poolinspect";
    payload["images"] = resultImage;

    const data = await postUploadImage(payload);
    if (data.message === "Formdata Images uploaded Successfully.") {
      setLoader(false);
      message.success("Image Upload Successfully!..");
      getImageUpload(params.formuuid)
    }
  }

  const [task, setTask] = useState({
    SurfaceDescription: '',
    SurfaceType: 0,
    ApproximateAge: 0,
    Tile: 0,
    Dimensions: '',
    DeckingDescription: '',
    DeckingType: 0,
    Shape: '',
    CopingDescription: '',
    CopingType: 0,
    Gallons: '',
    Mastic: 0,
    Fence: 0,
    NotesAppearance: '',
    PumpModelDescription: '',
    PumpModel: 0,
    Light: 0,
    FilterDescription: '',
    FilterModel: 0,
    BoosterDescription: '',
    BoosterPumpModel: 0,
    HeaterDescription: '',
    HeaterModel: 0,
    Skimmers: 0,
    MainDrainCovers: '',
    SpaBlowerDescription: '',
    SpaBlower: 0,
    AutomaticPoolCleaner: 0,
    Ladders: 0,
    Valves: 0,
    Handrails: 0,
    ChlorinatorDescription: '',
    ChlorinatorModel: 0,
    SaltSystemDescription: '',
    SaltSystemModel: 0,
    TimerController: 0,
    Actuators: 0,
    GFCI: 0,
    NotesEquipment: '',
    TotalChlorine: 0,
    CalciumHardness: 0,
    FreeChlorine: 0,
    CyanuricAcid: 0,
    pH: 0,
    Clarity: 0,
    TotalAlkalinity: 0,
    SaltLevel: 0,
    NotesChemical: '',
    pool_size: 0
  });

  useEffect(() => {
    getFormData();  
    getImageUpload(params.formuuid);
    sendTechWO();
  }, []);

  const sendTechWO = async ()  => {
    let url = window.location.href;
    let temp = url.substring(url.lastIndexOf("/") + 1, url.length);
    let formName = url.split("/")[3];
    const workOrderParam = {
      'form_uuid': temp,
      'form_name': formName
    }
    const resp = await sendTechnicianWorkOrder(workOrderParam);
  }


  const getFormData = async () => {
    const data = await getSaveMaintenencePoolReport(params.formuuid);
    setClient({ client: data.client });
    setTask(data.form_data);
    setAllData(data)
    setTask((t) => ({ ...t, id: params.formuuid, pool_size: data.pool_size }))
    setTask((t) => ({ ...t, pool_size: data.pool_size }));
    if(typeof data.attachments !='undefined'){
      setimageAppearanceSources(data.attachments.filter(i => i.image_type === 1));
      setimageEquipmentSources(data.attachments.filter(i => i.image_type === 1));
      setimagePicturesSources(data.attachments.filter(i => i.image_type === 1));
    }else{
      setimageAppearanceSources('');
      setimageEquipmentSources('');
      setimagePicturesSources('');
    }
    
  }

  const handleSurfaceDescription = (e) => {
    setTask((t) => ({ ...t, SurfaceDescription: e.target.value }));
  }

  const handleApproximateAge = (e) => {
    setTask((t) => ({ ...t, ApproximateAge: e.target.value }));
  }

  const handleDimensions = (e) => {
    setTask((t) => ({ ...t, Dimensions: e.target.value }));
  }

  const handleDeckingDescription = (e) => {
    setTask((t) => ({ ...t, DeckingDescription: e.target.value }));
  }

  const handleShape = (e) => {
    setTask((t) => ({ ...t, Shape: e.target.value }));
  }

  const handleCopingDescription = (e) => {
    setTask((t) => ({ ...t, CopingDescription: e.target.value }));
  }

  const handleApproximateGallons = (e) => {
    setTask((t) => ({ ...t, Gallons: e.target.value }));
  }

  const handlePumpModelDescription = (e) => {
    setTask((t) => ({ ...t, PumpModelDescription: e.target.value }));
  }

  const handleFilterDescription = (e) => {
    setTask((t) => ({ ...t, FilterDescription: e.target.value }));
  }

  const handleBoosterDescription = (e) => {
    setTask((t) => ({ ...t, BoosterDescription: e.target.value }));
  }

  const handleHeaterDescription = (e) => {
    setTask((t) => ({ ...t, HeaterDescription: e.target.value }));
  }

  const handleSpaBlowerDescription = (e) => {
    setTask((t) => ({ ...t, SpaBlowerDescription: e.target.value }));
  }

  const handleChlorinatorDescription = (e) => {
    setTask((t) => ({ ...t, ChlorinatorDescription: e.target.value }));
  }

  const handleSaltSystemDescription = (e) => {
    setTask((t) => ({ ...t, SaltSystemDescription: e.target.value }));
  }

  const handleTotalChlorine = (e) => {
    setTask((t) => ({ ...t, TotalChlorine: e.target.value }));
  }

  const handleCalciumHardness = (e) => {
    setTask((t) => ({ ...t, CalciumHardness: e.target.value }));
  }

  const handleFreeChlorine = (e) => {
    setTask((t) => ({ ...t, FreeChlorine: e.target.value }));
  }

  const handleCyanuricAcid = (e) => {
    setTask((t) => ({ ...t, CyanuricAcid: e.target.value }));
  }

  const handlepH = (e) => {
    setTask((t) => ({ ...t, pH: e.target.value }));
  }

  const handleClarity = (e) => {
    setTask((t) => ({ ...t, Clarity: e.target.value }));
  }

  const handleTotalAlkalinity = (e) => {
    setTask((t) => ({ ...t, TotalAlkalinity: e.target.value }));
  }

  const handleSaltLevel = (e) => {
    setTask((t) => ({ ...t, SaltLevel: e.target.value }));
  }

  const handlePoolSize = (value) => {
    setTask((t) => ({ ...t, pool_size:value}));
  }

  const getFiles = (list) => {
    const type = list[0]?.type;
    switch (type) {
      case 'pictures':
        setimagePicturesSources(list);
        break;
      case "equipment":
        setimageEquipmentSources(list);
        break;
      case "appearance":
        setimageAppearanceSources(list);
        break;
      default:
        break;
    }
    setimageSources(imagePicturesSources.concat(imageEquipmentSources.concat(imageAppearanceSources)));
  }

  const save = async(isComplete) => {
    setLoader(true);
    if(imageSources.length <= 0){
      const data = await postImageUpload();
      updateForm(isComplete);
    }else{
      const data = await postImageUpload();
      if(data){
        updateForm(isComplete);
      }
    }
  }

  function updateForm(isComplete) {
    setLoader(true);
    let payload = {};

    let formData = {};

    formData = {
      "SurfaceDescription": task.SurfaceDescription,
      "SurfaceType": task.SurfaceType,
      "ApproximateAge": task.ApproximateAge,
      "Tile": task.Tile,
      "Dimensions": task.Dimensions,
      "DeckingDescription": task.DeckingDescription,
      "DeckingType": task.DeckingType,
      "Shape": task.Shape,
      "CopingDescription": task.CopingDescription,
      "CopingType": task.CopingType,
      "Gallons": task.Gallons,
      "Mastic": task.Mastic,
      "Fence": task.Fence,
      "NotesAppearance": task.NotesAppearance,
      "PumpModelDescription": task.PumpModelDescription,
      "PumpModel": task.PumpModel,
      "Light": task.Light,
      "FilterDescription": task.FilterDescription,
      "FilterModel": task.FilterModel,
      "BoosterDescription": task.BoosterDescription,
      "BoosterPumpModel": task.BoosterPumpModel,
      "HeaterDescription": task.HeaterDescription,
      "HeaterModel": task.HeaterModel,
      "Skimmers": task.Skimmers,
      "MainDrainCovers": task.MainDrainCovers,
      "SpaBlowerDescription": task.SpaBlowerDescription,
      "SpaBlower": task.SpaBlower,
      "AutomaticPoolCleaner": task.AutomaticPoolCleaner,
      "Ladders": task.Ladders,
      "Valves": task.Valves,
      "Handrails": task.Handrails,
      "ChlorinatorDescription": task.ChlorinatorDescription,
      "ChlorinatorModel": task.ChlorinatorModel,
      "Bonding": task.Bonding,
      "SaltSystemDescription": task.SaltSystemDescription,
      "SaltSystemModel": task.SaltSystemModel,
      "TimerController": task.TimerController,
      "Actuators": task.Actuators,
      "GFCI": task.GFCI,
      "NotesEquipment": task.NotesEquipment,
      "TotalChlorine": task.TotalChlorine,
      "CalciumHardness": task.CalciumHardness,
      "FreeChlorine": task.FreeChlorine,
      "CyanuricAcid": task.CyanuricAcid,
      "pH": task.pH,
      "Clarity": task.Clarity,
      "TotalAlkalinity": task.TotalAlkalinity,
      "SaltLevel": task.SaltLevel,
      "NotesChemical": task.NotesChemical
    }
    
    payload["form_data"] = formData;
    // payload["attachments"] = imageSources;
    payload["has_attachments"] = imageSources.length > 0 ? true : false    
    var date = moment().format("YYYY-MM-DD");
    var hour = moment().format("HH:mm");

    postImageUpload();

    const postFormData = async () => {
      const data = await saveMaintenencePoolReport(
        payload, 
        isComplete, 
        params.formuuid,
        date,
        hour
      );
      setLoader(false);
      if (data?.form_data?.uuid) {
        message.success("Form Data saved  Successfully!..");
        getFormData();
        if(isComplete)
        if ( window !== window.parent ) {
          var event = new CustomEvent('handleCloseEvent', { detail: data })
          window.parent.document.dispatchEvent(event)
        }
        else close();
      } else {
        console.log(data);
      }
    }
    postFormData()
  }

  return (
    <div id="wrapper" style={{ padding: "24px" }}>
      {loader && <Loader />}
      <Card
        className="middle-section"
        title="Pool Inspection Report"
        headStyle={{
          fontSize: "24px",
          fontWeight: "bold",
          borderBottom: "1px solid #E1E1E5",
        }}
      >
        <div className="download-mpr">
          <a
            target="_blank"
            href={`${config.getHost()}form-data/as_pdf/?form_data_uuid=${
              params.formuuid
            }`}
          >
            <i className="fa fa-download" style={{ marginLeft: "10px" }}></i>
            <DownloadOutlined />
            Download PDF
          </a>
        </div>
        <div className="form-group mb-20">
          <label htmlFor="txtGreeting">
            <u>
              THIS INSPECTION IS A VISUAL INSPECTION ONLY AND DOES NOT CONSIDER
              STRUCTURAL ISSUES, LEAKS, ETC. THIS INSPECTION EVALUATES THE
              SWIMMING POOL’S PHYSICAL APPEARANCE, INCLUDING VISIBLE APPEARANCE,
              DECK EQUIPMENT, WATER CHEMISTRY, AND THE POOL’S OPERATING
              EQUIPMENT.
            </u>
          </label>
        </div>
        <div className="mb-10" style={{ fontSize: "16px" }}>
          <p>
            <strong>Job Date: {allData?.job_time?.split("T")[0]}</strong>
          </p>
        </div>
        <CustomerDetails
          client={client}
          handlePoolSize={handlePoolSize}
          poolSize={allData.pool_size}
          showPoolSize={false}
          allData= {allData}
        />
        <br />
        <Card
          className="middle-section"
          title="Pool Appearance"
          headStyle={{
            fontSize: "16px",
            fontWeight: "bold",
            borderBottom: "1px solid #E1E1E5",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.SurfaceDescription">Surface Type</label>
              <input
                id="task.SurfaceDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.SurfaceDescription || ''}
                onChange={handleSurfaceDescription}
              />
              <Select
                id="task.SurfaceType"
                name="task.SurfaceType"
                showArrow={true}
                virtual={false}
                value={task.SurfaceType}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, SurfaceType: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.ApproximateAge">Approximate Age</label>
              <input
                id="task.ApproximateAge"
                type="number"
                name="input"
                autoComplete="off"
                value={task.ApproximateAge || ''}
                onChange={handleApproximateAge}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Tile" style={{ width: "100%" }}>
                Tile
              </label>
              <Select
                id="task.Tile"
                name="task.Tile"
                showArrow={true}
                virtual={false}
                value={task.Tile}
                options={ChoicesNA}
                onChange={(value) => setTask(() => ({ ...task, Tile: value }))}
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Dimensions">Dimensions</label>
              <input
                id="task.Dimensions"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.Dimensions ||''}
                onChange={handleDimensions}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.DeckingType">Decking Type</label>
              <input
                id="task.DeckingDescription"
                type="text"
                name="input"
                autoComplete="off"
                className="mb-10"
                value={task.DeckingDescription || ''}
                onChange={handleDeckingDescription}
              />
              <Select
                id="task.DeckingType"
                name="task.DeckingType"
                showArrow={true}
                virtual={false}
                value={task.DeckingType}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, DeckingType: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Shape" style={{ width: "100%" }}>
                Shape
              </label>
              <input
                id="task.Shape"
                type="text"
                name="input"
                autoComplete="off"
                value={task.Shape || ''}
                onChange={handleShape}
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.CopingType">Coping Type</label>
              <input
                id="task.CopingDescription"
                type="text"
                name="input"
                autoComplete="off"
                className="mb-10"
                value={task.CopingDescription || ''}
                onChange={handleCopingDescription}
              />
              <Select
                id="task.CopingType"
                name="task.CopingType"
                showArrow={true}
                virtual={false}
                value={task.CopingType}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, CopingType: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Gallons">Approximate Gallons</label>
              <input
                id="task.Gallons"
                type="text"
                name="input"
                autoComplete="off"
                value={task.Gallons}
                onChange={handleApproximateGallons}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Mastic" style={{ width: "100%" }}>
                Mastic
              </label>
              <Select
                id="task.Mastic"
                name="task.Mastic"
                showArrow={true}
                virtual={false}
                value={task.Mastic}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Mastic: value }))
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Fence" style={{ width: "100%" }}>
                Fence / Gate
              </label>
              <Select
                id="task.Fence"
                name="task.Fence"
                showArrow={true}
                virtual={false}
                value={task.Fence}
                options={ChoicesNA}
                onChange={(value) => setTask((t) => ({ ...t, Fence: value }))}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}></Col>
            <Col xs={24} sm={24} md={7} lg={7}></Col>
          </Row>
          <div className="form-group mt-30">
            <div className="fitem">
              {/* <label htmlFor="task.NotesAppearance" style={{ 'width': '100%' }}>Notes Appearance</label> */}
              <label
                htmlFor="task.NotesAppearance"
                style={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Notes Appearance
              </label>
              <textarea
                id="task.NotesAppearance"
                rows="5"
                cols="48"
                autoComplete="off"
                value={task.NotesAppearance}
                onChange={(value) =>
                  setTask((t) => ({
                    ...t,
                    NotesAppearance: value.target.value,
                  }))
                }
              />
            </div>
            <div className="form-group">
              <div className="fitem maintenance-title">
                <h5>Appearance Pictures</h5>
                <hr />
                <div className="fitem">
                  <label htmlFor="tenant.name" className="control-label">
                    Upload your appearance image:
                  </label>
                  <div className="fitem">
                    <span className="help-block">
                      Acceptable file types are .png, .jpg, or .gif.{" "}
                    </span>
                  </div>
                  {!isLoading && (
                    <ImageUploader
                      text={"Upload image"}
                      fileList={imageAppearanceSource}
                      accept={".png, .jpg, .gif"}
                      // getFiles= {getFiles}
                      getFiles={getFilesAppearance}
                      params={{
                        level: "PoolInspection",
                        task: task,
                        isTechImage: false,
                        image_type: "appearance",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <br />
        <Card
          className="middle-section"
          title="Pool Equipment"
          headStyle={{
            fontSize: "16px",
            fontWeight: "bold",
            borderBottom: "1px solid #E1E1E5",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.PumpModelDescription">Pump Model</label>
              <input
                id="task.PumpModelDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.PumpModelDescription}
                onChange={handlePumpModelDescription}
              />
              <Select
                id="task.PumpModel"
                name="task.PumpModel"
                showArrow={true}
                virtual={false}
                value={task.PumpModel}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, PumpModel: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.ApproximateAge" style={{ width: "100%" }}>
                Light
              </label>
              <Select
                id="task.Light"
                name="task.Light"
                showArrow={true}
                virtual={false}
                value={task.Light}
                options={ChoicesNA}
                onChange={(value) => setTask(() => ({ ...task, Light: value }))}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FilterModel" style={{ width: "100%" }}>
                Filter Model
              </label>
              <input
                id="task.FilterDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.FilterDescription}
                onChange={handleFilterDescription}
              />
              <Select
                id="task.FilterModel"
                name="task.FilterModel"
                showArrow={true}
                virtual={false}
                value={task.FilterModel}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, FilterModel: value }))
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.BoosterPumpModel">Booster Pump Model</label>
              <input
                id="task.BoosterDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.BoosterDescription}
                onChange={handleBoosterDescription}
              />
              <Select
                id="task.BoosterPumpModel"
                name="task.BoosterPumpModel"
                showArrow={true}
                virtual={false}
                value={task.BoosterPumpModel}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, BoosterPumpModel: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Skimmers" style={{ width: "100%" }}>
                Skimmers
              </label>
              <Select
                id="task.Skimmers"
                name="task.Skimmers"
                showArrow={true}
                virtual={false}
                value={task.Skimmers}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Skimmers: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.HeaterModel" style={{ width: "100%" }}>
                Heater Model
              </label>
              <input
                id="task.HeaterDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.HeaterDescription}
                onChange={handleHeaterDescription}
              />
              <Select
                id="task.HeaterModel"
                name="task.HeaterModel"
                showArrow={true}
                virtual={false}
                value={task.HeaterModel}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, HeaterModel: value }))
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.MainDrainCovers">Main Drain Covers</label>
              <Select
                id="task.MainDrainCovers"
                name="task.MainDrainCovers"
                showArrow={true}
                virtual={false}
                value={task.MainDrainCovers}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, MainDrainCovers: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.SpaBlower" style={{ width: "100%" }}>
                Spa Blower
              </label>
              <input
                id="task.SpaBlowerDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.SpaBlowerDescription}
                onChange={handleSpaBlowerDescription}
              />
              <Select
                id="task.SpaBlower"
                name="task.SpaBlower"
                showArrow={true}
                virtual={false}
                value={task.SpaBlower}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, SpaBlower: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label
                htmlFor="task.AutomaticPoolCleaner"
                style={{ width: "100%" }}
              >
                Automatic Pool Cleaner
              </label>
              <Select
                id="task.AutomaticPoolCleaner"
                name="task.AutomaticPoolCleaner"
                showArrow={true}
                virtual={false}
                value={task.AutomaticPoolCleaner}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, AutomaticPoolCleaner: value }))
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Ladders" style={{ width: "100%" }}>
                Ladders
              </label>
              <Select
                id="task.Ladders"
                name="task.Ladders"
                showArrow={true}
                virtual={false}
                value={task.Ladders}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Ladders: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Valves" style={{ width: "100%" }}>
                Valves
              </label>
              <Select
                id="task.Valves"
                name="task.Valves"
                showArrow={true}
                virtual={false}
                value={task.Valves}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Valves: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Handrails" style={{ width: "100%" }}>
                Handrails
              </label>
              <Select
                id="task.Handrails"
                name="task.Handrails"
                showArrow={true}
                virtual={false}
                value={task.Handrails}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Handrails: value }))
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.ChlorinatorModel" style={{ width: "100%" }}>
                ChlorinatorModel
              </label>
              <input
                id="task.ChlorinatorDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.ChlorinatorDescription}
                onChange={handleChlorinatorDescription}
              />
              <Select
                id="task.ChlorinatorModel"
                name="task.ChlorinatorModel"
                showArrow={true}
                virtual={false}
                value={task.ChlorinatorModel}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, ChlorinatorModel: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Bonding" style={{ width: "100%" }}>
                Bonding
              </label>
              <Select
                id="task.Bonding"
                name="task.Bonding"
                showArrow={true}
                virtual={false}
                value={task.Bonding}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Bonding: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.SaltSystemModel" style={{ width: "100%" }}>
                Salt System
              </label>
              <input
                id="task.SaltSystemDescription"
                type="text"
                name="input"
                className="mb-10"
                autoComplete="off"
                value={task.SaltSystemDescription}
                onChange={handleSaltSystemDescription}
              />
              <Select
                id="task.SaltSystemModel"
                name="task.SaltSystemModel"
                showArrow={true}
                virtual={false}
                value={task.SaltSystemModel}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, SaltSystemModel: value }))
                }
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.TimerController" style={{ width: "100%" }}>
                TimerController
              </label>
              <Select
                id="task.TimerController"
                name="task.TimerController"
                showArrow={true}
                virtual={false}
                value={task.TimerController}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, TimerController: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Actuators" style={{ width: "100%" }}>
                Actuators
              </label>
              <Select
                id="task.Actuators"
                name="task.Actuators"
                showArrow={true}
                virtual={false}
                value={task.Actuators}
                options={ChoicesNA}
                onChange={(value) =>
                  setTask(() => ({ ...task, Actuators: value }))
                }
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.GFCI" style={{ width: "100%" }}>
                GFCI
              </label>
              <Select
                id="task.GFCI"
                name="task.GFCI"
                showArrow={true}
                virtual={false}
                value={task.GFCI}
                options={ChoicesNA}
                onChange={(value) => setTask(() => ({ ...task, GFCI: value }))}
              />
            </Col>
          </Row>
          <div className="form-group mt-30">
            <div className="fitem">
              <label
                htmlFor="task.NotesEquipment"
                style={{ fontSize: "14px", fontWeight: "bold" }}
              >
                Notes Equipment
              </label>
              <textarea
                id="task.NotesEquipment"
                rows="5"
                cols="48"
                autoComplete="off"
                value={task.NotesEquipment}
                onChange={(value) =>
                  setTask((t) => ({ ...t, NotesEquipment: value.target.value }))
                }
              />
            </div>
            <div className="form-group">
              <div className="fitem maintenance-title">
                <h5>Equipment Pictures</h5>
                <hr />
                <div className="fitem">
                  <label htmlFor="tenant.name" className="control-label">
                    Upload your image:
                  </label>
                  <div className="fitem">
                    <span className="help-block">
                      Acceptable file types are .png, .jpg, or .gif.{" "}
                    </span>
                  </div>
                  {!isLoading && (
                    <ImageUploader
                      text={"Upload image"}
                      fileList={imageEquipmentSource}
                      accept={".png, .jpg, .gif"}
                      // getFiles= {getFiles}
                      getFiles={getFilesEquipment}
                      params={{
                        level: "PoolInspection",
                        task: task,
                        isTechImage: false,
                        image_type: "equipment",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
        <br />
        <Card
          className="middle-section"
          title="Water Chemistry"
          headStyle={{
            fontSize: "16px",
            fontWeight: "bold",
            borderBottom: "1px solid #E1E1E5",
          }}
          bodyStyle={{ padding: "24px" }}
        >
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.TotalChlorine">Total Chlorine</label>
              <input
                id="task.TotalChlorine"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.TotalChlorine}
                onChange={handleTotalChlorine}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.CalciumHardness" style={{ width: "100%" }}>
                Calcium Hardness
              </label>
              <input
                id="task.CalciumHardness"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.CalciumHardness}
                onChange={handleCalciumHardness}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.FreeChlorine" style={{ width: "100%" }}>
                Free Chlorine
              </label>
              <input
                id="task.FreeChlorine"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.FreeChlorine}
                onChange={handleFreeChlorine}
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.pH">Cyanuric Acid</label>
              <input
                id="task.CyanuricAcid"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.CyanuricAcid}
                onChange={handleCyanuricAcid}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.pH" style={{ width: "100%" }}>
                pH
              </label>
              <input
                id="task.pH"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.pH}
                onChange={handlepH}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.Clarity" style={{ width: "100%" }}>
                Clarity (1-10)
              </label>
              <input
                id="task.Clarity"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.Clarity}
                onChange={handleClarity}
              />
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.TotalAlkalinity">Total Alkalinity</label>
              <input
                id="task.TotalAlkalinity"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.TotalAlkalinity}
                onChange={handleTotalAlkalinity}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.SaltLevel" style={{ width: "100%" }}>
                Salt Level
              </label>
              <input
                id="task.SaltLevel"
                type="number"
                name="input"
                className="mb-10"
                autoComplete="off"
                step="0.1"
                value={task.SaltLevel}
                onChange={handleSaltLevel}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <label htmlFor="task.NotesChemical" style={{ width: "100%" }}>
                Chemical Notes
              </label>
              <textarea
                id="task.NotesChemical"
                rows="5"
                cols="48"
                autoComplete="off"
                value={task.NotesChemical}
                onChange={(value) =>
                  setTask((t) => ({ ...t, NotesChemical: value.target.value }))
                }
              />
            </Col>
          </Row>
          <div className="form-group">
            <div className="fitem maintenance-title">
              <h5>Pictures</h5>
              <hr />
              <div className="fitem">
                <label htmlFor="tenant.name" className="control-label">
                  Upload your Chemical image:
                </label>
                <div className="fitem">
                  <span className="help-block">
                    Acceptable file types are .png, .jpg, or .gif.{" "}
                  </span>
                </div>
                {!isLoading && (
                  <ImageUploader
                    text={"Upload image"}
                    fileList={imagePicturesSource}
                    accept={".png, .jpg, .gif"}
                    // getFiles= {getFiles}
                    getFiles={getFilesPictures}
                    params={{
                      level: "PoolInspection",
                      task: task,
                      isTechImage: false,
                      image_type: "pictures",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </Card>
        <FooterForm
          textButton={textButton}
          handleClick={() => save(false)}
          handleClickEmail={() => save(true)}
        />
      </Card>
    </div>
  );
};

export default PoolInspectionForm;
