import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import HeaderMobile from '../../Components/HeaderMobile'
import SidebarResponsive from "../../Components/SidebarResponsive";
import Loader from "../../Components/Loader";
import DataTable from '../Common/Datatable';
import CreateNewTicket from './CreateNewTicket';

import { Select, Drawer, Table } from "antd";
import { map } from "lodash";
import { useNavigate } from 'react-router';

const CaseManagement = () => {
    const { Option } = Select;
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        messageData: [],
        franchiseList: [],
        UserList: [],
        GroupList: [],
        reason: [
          { label: 'Complaint', value: 'complaint' },          
          { label: 'Missed service', value: 'missed_service' },
          { label: 'Schedule Problem', value: 'schedule_problem' },
          { label: 'Issue With Tech', value: 'issue_with_tech' },
          { label: 'Unhappy With Service', value: 'unhappy_with_service' },
          { label: 'Driving', value: 'driving' },
          { label: 'Billing', value: 'billing' },
          { label: 'Missed SVC', value: 'missed_svc' },
          { label: 'Edit existing Service', value: 'edit_existing_service' },
          { label: 'Request one-time service', value: 'request_one_time_service' },
          { label: 'Other', value: 'other' },
        ],
        is_add_new_ticket: false,
        // is_message_table: this.props?.history?.location?.state?.table === 'assignedTickets' ? false : true,
        is_message_table: true,
        loader: false,
        active_pagination: '15',
        search_value: '',
        message_list_pagination: { current: 1, showSizeChanger: false, pageSize: 15, ordering: "", },
        message_list_filter: {
          user__serviceorder__field_office__uuid: '',
          reason: '',
          assigned_user__uuid: '',
          status: 'open',
          priority: '',
          assigned_group__uuid: ''
        },
        assigned_user: { uuid: '', first_name: '', last_name: '' },
        is_disable_franchise : false,
    })

    useEffect(() => {
        let { message_list_filter, is_message_table } = state;
        setLoader(true);

        //Get User role
        const user = JSON.parse(localStorage.getItem("authUser"));
        var roles = user?.user?.role;
        
        if (roles.includes(`business_dev_franchiese`) || roles.includes(`accounting_franchiese`) || roles.includes(`franchise_owner`) || roles.includes(`operations_mgr`)) {
            setState(s => ({ ...s, is_disable_franchise: true }))
        }

        //get staff user
        getUserList({ 'limit': 1000, offset: 0, 'ordering': 'first_name', 'is_case_mgmt': true });

        let data = {
            ...message_list_filter,
            limit: 15,
            offset: 0
        }

        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);

        //Get All the messages
        if (is_message_table) {            
            getAllMessages(data)

        }
        else {
            const accessToken = JSON.parse(localStorage.getItem("authUser"))
            ? JSON.parse(localStorage.getItem("authUser")).user
            : "";

            getAllMessages({...data, assigned_user__uuid: accessToken.uuid, status: 'open'})
        }

        //Franchise List
        getFranchiseList();

        //get Group
        getGroupList({ 'limit': 1000, offset: 0 })
    }, []);

    const onMenubuttonClick = () => {
      setOpenMenu(!openMenu);
    }

    const getFranchiseList = () => {}

    const getAllMessages = () => {        
        let data = [
            {
              title: 'Customer Reported a Complaint',
              user: "3b60c036-5e16-3e59-9193-b3c25368a1f8",
              user_details: {
                role: 'customer',
                first_name: 'Aarti',
                last_name: 'Kumthekar',
                account_number: '0007794'
              },
              field_office:{
                name: 'Conference Demo'
              },
              reason: 'unhappy_with_service',
              assigned_user_details:{
                uuid: 65465431,
                first_name: 'Florida',
                last_name: 'Owner'
              },
              status: 'open',
              priority: "4",
              follow_up_date: new Date('11/04/2022'),
              created_at: new Date('11/04/2022'),
            },
            {
              title: 'Accounting to update last invoice',
              user: "3b60c036-5e16-3e59-9193-b3c25368a1f8",
              user_details: {
                role: 'customer',
                first_name: 'Dale',
                last_name: 'Earnhardt',
                account_number: '0007794'
              },
              field_office:{
                name: 'Conference Demo'
              },
              reason: 'schedule_problem',
              assigned_user_details:{
                uuid: 65465431,
                first_name: 'Corporate',
                last_name: 'Dev'
              },
              status: 'open',
              priority: "4",
              follow_up_date: new Date('11/04/2022'),
              created_at: new Date('11/04/2022'),
            },
            {
              title: 'Reported incorrect date',
              user: "3b60c036-5e16-3e59-9193-b3c25368a1f8",
              user_details: {
                role: 'customer',
                first_name: '	Kevin',
                last_name: 'Noggle',
                account_number: '0007794'
              },
              field_office:{
                name: 'Conference Demo'
              },
              reason: 'schedule_problem',
              assigned_user_details:{
                uuid: 65465431,
                first_name: 'Corporate',
                last_name: 'Dev'
              },
              status: 'open',
              priority: "3",
              follow_up_date: new Date('11/01/2022'),
              created_at: new Date('11/01/2022'),
            },
            {
              title: 'Customer not happy with last clean',
              user: "3b60c036-5e16-3e59-9193-b3c25368a1f8",
              user_details: {
                role: 'customer',
                first_name: '	Hulk',
                last_name: 'Hogan',
                account_number: '0007794'
              },
              field_office:{
                name: 'Conference Demo'
              },
              reason: 'issue_with_tech',
              assigned_user_details:{
                uuid: 65465431,
                first_name: 'Corporate',
                last_name: 'Dev'
              },
              status: 'open',
              priority: "3",
              follow_up_date: new Date('10/31/2022'),
              created_at: new Date('10/31/2022'),
            },
            {
              title: 'Customer wants to contact Franchise',
              user: "3b60c036-5e16-3e59-9193-b3c25368a1f8",
              user_details: {
                role: 'customer',
                first_name: '	Mac',
                last_name: 'Leskosky',
                account_number: '0007794'
              },
              field_office:{
                name: 'Conference Demo'
              },
              reason: 'unhappy_with_service',
              assigned_user_details:{
                uuid: 65465431,
                first_name: 'Corporate',
                last_name: 'Dev'
              },
              status: 'open',
              priority: "4",
              follow_up_date: new Date('10/18/2022'),
              created_at: new Date('10/18/2022'),
            },
        ]
        setState(s => ({
            ...s,
            messageData: data
        }))
        setLoader(false);
    }

    const getGroupList = () => {}

    const getUserList = () => {}

    //Togel New Ticket Drawer
    const newTicketToggle = (key) => {
        setState(s => ({
            ...s,
            is_add_new_ticket: key
        }))
    }

    // message filter
    const messageFilterChange = (e, type) => {
        let { message_list_filter, active_pagination, message_list_pagination } = state;
            message_list_filter = Object.assign(message_list_filter, { [type]: e, });
            message_list_pagination = Object.assign(message_list_pagination, { 'current': 1 })
        setState(s => ({
            ...s,
            message_list_filter,
            message_list_pagination,
        }))
        setLoader(true);

        let data = {
        ...message_list_filter,
        limit: active_pagination,
        offset: (message_list_pagination.current * 15) - 15,
        }
        // delete data.mess
        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
        getAllMessages(data)
    }

    //Render Filter
    const renderFilter = () => {
        let { franchiseList, message_list_filter, reason, UserList,
            is_message_table, assigned_user, active_pagination,
            message_list_pagination, GroupList, is_disable_franchise } = state;
        var input = 100; //input any number
        var len = input.toString().length;
        const accessToken = JSON.parse(localStorage.getItem("authUser"))
            ? JSON.parse(localStorage.getItem("authUser")).user
            : "";

        return (
        <React.Fragment>
        {!is_message_table ?
          <div className="fitem">
            <span style={{ fontSize: '13px', color: ' #297C78', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                setState(s => ({
                    ...CaseManagement,
                  message_list_filter: {
                    user__serviceorder__field_office__uuid: '',
                    reason: '',
                    assigned_user__uuid: accessToken.uuid,
                    status: '',
                    priority: ''
                  },
                  assigned_user: {
                    uuid: accessToken.uuid,
                    first_name: accessToken.first_name,
                    last_name: accessToken.last_name
                  }
                }))
                let data = {
                  limit: active_pagination,
                  offset: (message_list_pagination.current * 15) - 15,
                  assigned_user__uuid: accessToken.uuid
                }
                // delete data.mess
                Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
                getAllMessages(data);
              }}

            >Clear All Filters</span>
          </div> : ''}


        <div className="fitem">
            <Select
            defaultValue=""
            value={message_list_filter?.user__serviceorder__field_office__uuid}
            onChange={(e) => {
              messageFilterChange(e, 'user__serviceorder__field_office__uuid')
              messageFilterChange('', 'assigned_group__uuid')
              getGroupList({field_office__uuid: e, 'limit': 15, offset: 0 })
            }}
            dropdownClassName="md-filter"
            disabled={is_disable_franchise ? true : false}
            className="filter_ant_select filter_by_franchise swhite ">
            <Option value="">All Franchise</Option>
            {map(franchiseList, (item, i) => {
              return (
                <Option key={i} value={item.uuid}><span className="pre_digit">{('000000000000000' + parseInt(i + 1)).slice(-len)}</span> {item.name}</Option>
              )
            })}
            </Select>
        </div>

        <div className="fitem">
          <Select
            defaultValue=""
            value={message_list_filter?.reason}
            onChange={(e) => {
              messageFilterChange(e, 'reason')
            }}
            dropdownClassName="md-filter"
            className="filter_ant_select filter_by_disposition swhite ">
            <Option value="">All <span className="pre_digit">Disposition</span> </Option>
            {
              map(reason, (item, i) => {
                return (
                  <Option key={i} value={item.value}>{item.label}</Option>
                )
              })
            }

          </Select>
        </div>

        {/* {!is_message_table ? */}
          <div className="fitem">
            <Select
              showSearch
              showArrow={true}
              filterOption={false}
              notFoundContent={null}
              defaultValue=""
              onChange={(e, i) => {
                messageFilterChange(e.key, 'assigned_user__uuid')

                setState(s => ({
                    ...s,
                  assigned_user: { uuid: e.key, first_name: i.children.length > 0 ? i.children[0] : '', last_name: i.children.length > 0 ? i.children[2] : '' }
                }))
              }}
            //   value={{ value: assigned_user.uuid ?? message_list_filter?.assigned_user__uuid, label: `${assigned_user.first_name} ${assigned_user.last_name}` }}
              value = {message_list_filter?.assigned_user__uuid}              
              onSearch={(e) => { getUserList({ 'limit': 1000, 'search': e, 'ordering': 'first_name', 'is_case_mgmt': true }) }}
              onBlur={() => { getUserList({ 'offset': 0, 'limit': 1000, 'ordering': 'first_name', 'is_case_mgmt': true }); }}
              dropdownClassName="xs-filter"
              className="filter_ant_select filter_by_assigned_to swhite ">
              <Option value="">All</Option>
              {
                map(UserList, (item, i) => {
                  return (
                    <Option value={item.uuid} key={i}>{item.first_name} {item.last_name} </Option>
                  )
                })
              }
            </Select>
          </div>
          {/* : ''} */}

        <div className="fitem">
          <Select
            showSearch
            showArrow={true}
            filterOption={false}
            notFoundContent={null}
            defaultValue=""
            onChange={(e) => {
              messageFilterChange(e, 'assigned_group__uuid')
            }}
            value={message_list_filter?.assigned_group__uuid}

            onSearch={(e) => { getGroupList({ 'search': e }) }}
            dropdownClassName="xs-filter"
            className="filter_ant_select filter_by_group swhite">
            <Option value="">All</Option>
            {
              map(GroupList, (item, i) => {
                return (
                  <Option value={item.uuid} key={i}>{item.name}</Option>
                )
              })
            }
          </Select>
        </div>


        <div className="fitem">
          <Select
            defaultValue=""
            value={message_list_filter?.status}
            onChange={(e) => {
              messageFilterChange(e, 'status')
            }}
            dropdownClassName="xs-filter"
            className="filter_ant_select filter_by_status swhite ">
            <Option value="">All <span className="pre_digit">Status</span></Option>
            <Option key='open' value="open">Open Tickets</Option>
            <Option key='closed' value="closed">Closed Tickets</Option>
          </Select>
        </div>

        <div className="fitem">
          <Select
            defaultValue=""
            value={message_list_filter?.priority}
            onChange={(e) => {
              messageFilterChange(e, 'priority')
            }}
            dropdownClassName="sm-filter"
            className="filter_ant_select filter_by_priority swhite ">
            <Option value="">All <span className="pre_digit">Priority</span></Option>
            <Option key="4" value="4">Standard Priority</Option>
            <Option key="3" value="3">High Priority</Option>
          </Select>
        </div>

        <div className="fitem">
          <button
            onClick={() => {
              newTicketToggle(true)
            }}
            className="btn btn-warning"
          >Create New Ticket</button>

        </div>

      </React.Fragment >
    )
  }

  // Message Pagination and ordering
  const messageOnChange = (pagination, filters, sorter) => {
    let { message_list_pagination, active_pagination, search_value, message_list_filter } = state;

    message_list_pagination = Object.assign(message_list_pagination, { 'current': pagination.current });

    let ordering = ''
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `${sorter.field}-`
    }
    else {
      ordering = ''
    }

    setState(s => ({
        ...s,
      message_list_pagination
    }))
    
    setLoader(false);

    let data = {
      offset: (pagination.current * 15) - 15,
      limit: active_pagination,
      ordering,
      ...message_list_filter,
      search: search_value,
    }
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getAllMessages(data)
  }

  const clearCustomerDetail = () => {}

  const clearCommercialCustomer = () => {}

  //set page limit
  const setMessagePageLimit = (custom_limit) => {
    let { search_value, message_list_pagination, message_list_filter } = state;
    message_list_pagination = Object.assign(message_list_pagination, { 'current': 1, 'pageSize': custom_limit });


    setState(s => ({
        ...s,
      message_list_pagination,
      active_pagination: custom_limit,
    }))
    setLoader(true);

    let data = {
      offset: 0, limit: custom_limit, ordering: '', search: search_value, ...message_list_filter
    }
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getAllMessages(data);
  }

  // Message Pagination and ordering
  const onAssignToMeChange = (pagination, filters, sorter) => {
    let { search_value } = state;

    const accessToken = JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser")).user
      : "";

    let ordering = ''
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `${sorter.field}-`
    }
    else {
      ordering = ''
    }

    setLoader(true);

    let data = {
      offset: 0,
      limit: 15,
      ordering,
      search: search_value,
      assigned_user__uuid: accessToken.uuid,
    }
    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
    getAllMessages(data)
  }

  // Change The Tab
  const switchTab = (type) => {
    const accessToken = JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser")).user
      : "";

    let { message_list_pagination, message_list_filter } = state;

    if (type) {
      message_list_pagination = Object.assign(message_list_pagination, {
        showSizeChanger: false, pageSize: 15, ordering: ""
      })
      message_list_filter = Object.assign(message_list_filter, {
        user__serviceorder__field_office__uuid: '',
        reason: '',
        assigned_user__uuid: '',
        status: 'open',
        priority: '',


      })
      let data = {
        ...message_list_filter,
        limit: 15,
        offset: 0,
        // assigned_user__uuid: accessToken.uuid
      }
      data.status = 'open';
      getAllMessages(data);

      setState( s => ({
        ...s,
        loader: true,
        is_message_table: type,
        message_list_pagination,
        search_value: '',
        message_list_filter,
        active_pagination: '15'
      }));
    }
    else {
      let data = {
        ...message_list_filter,
        limit: 15,
        offset: 0,
        assigned_user__uuid: accessToken.uuid
      }

      data.status = 'open';

      Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
      getAllMessages(data);

      message_list_filter = Object.assign(message_list_filter, {
        user__serviceorder__field_office__uuid: '',
        reason: '',
        status: 'open',
        priority: '',
        assigned_user__uuid: accessToken.uuid
      })

      setState(s => ({
        ...s,
        is_message_table: type,
        message_list_filter,
        loader: true,
        search_value: '',
        assigned_user: {
          uuid: accessToken.uuid,
          first_name: accessToken.first_name,
          last_name: accessToken.last_name
        }
      }));
    }
  }

  //Render My messages
  const renderMyMessage = () => {
    let { messageData } = state;
    return (
      <React.Fragment>
        <Table
          rowKey={record => record.uuid}
          dataSource={messageData}
          pagination={false}
          columns={DataTable.AllMessageList(this)}
          onChange={onAssignToMeChange}
          className="case_management_table"
          position={false}
          onRow={(record) => {
            return {
              onClick: () => { 
                if (record && record.user) {
                  if (record?.user_details?.role?.includes('customer')) {
                    clearCustomerDetail()
                    // this.props.history.push(`/residential/detail`, { query: { 'id': record.user } })
                    navigate(`/residential/customer-detail`, { query: { 'id': record.user } })
                  } else if (record?.user_details?.role?.includes('commercial_customer')) {
                    clearCommercialCustomer()
                    // this.props.history.push(`/commercial/customer-detail`, { query: { 'id': record.user } })
                    navigate(`/residential/customer-detail`, { query: { 'id': record.user } })
                  }
                }
                //Set Required  Value in Storage 
                localStorage.setItem('is_ticket_details', JSON.stringify({ record }));
              }
            }
          }}
          showSorterTooltip={false}

        />
      </React.Fragment>
    )
  }

  //Render All Messages Table
  const renderAllMessage = () => {
    let { messageData, message_list_pagination, active_pagination } = state;

    return (
      <React.Fragment>
        <Table
          rowKey={record => record.uuid}
          dataSource={messageData}
          pagination={message_list_pagination}
          onChange={messageOnChange}
          columns={DataTable.AllMessageList(this)}
          onRow={(record) => {
            return {
              onClick: () => {                
                if (record && record.user) {
                  if (record?.user_details?.role?.includes('customer')) {
                    clearCustomerDetail()                              
                    navigate(`/residential/customer-detail`, { state: {
                      record: {     
                        selectedTab: 5,
                        ...record,                  
                        uuid: record?.user,
                        first_name: record.user_details.first_name,
                        last_name: record.user_details.last_name,
                        account_number: record.user_details.account_number
                      }
                    }});
                  } else if (record?.user_details?.role?.includes('commercial_customer')) {
                    clearCommercialCustomer()
                    // this.props.history.push(`/commercial/customer-detail`, { query: { 'id': record.user } })                    
                    navigate(`/commercial/customer-detail`, { state: { 'id': record?.uuid, record: record } })
                  }
                }
                //Set Required  Value in Storage 
                localStorage.setItem('is_ticket_details', JSON.stringify({ record }));
              }
            }
          }}
          className="case_management_table"
          position={false}
          showSorterTooltip={false}

        />


        {messageData.length > 0 ?
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => { setMessagePageLimit('15') }}
                className={active_pagination === '15' ? 'active' : ''}
              >15</span>
            </li>
            <li>
              <span
                className={active_pagination === '30' ? 'active' : ''}
                onClick={() => { setMessagePageLimit('30') }}>30</span>
            </li>
            <li>
              <span
                className={active_pagination === '60' ? 'active' : ''}
                onClick={() => { setMessagePageLimit('60') }}>60</span>
            </li>
          </ul> : ''}
      </React.Fragment>

    )
  }

  return (    
    <div id="wrapper">
        <div className="content-area">
            {/* Header */}
            <Header
              placeholder="Search"
              search_value={""}
              onSearch={(e) => e}
              onEnter={(e) => e}
              readOnly={true}
            />
            <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
            {/* Sidebar */}
            <Sidebar sideBarBackgroundColor='#005d89' />
            <SidebarResponsive sideBarBackgroundColor='#005d89' openMenu={openMenu} onMenubuttonClick={onMenubuttonClick} />
            <div className="container">
                {loader && <Loader/>}
                <div className="filter_wrapper">
                    <div className="left_side">                       
                        <ul className="tabs">
                            <li style={{ marginRight: '10px' }} className={state.is_message_table ? 'active' : ''} onClick={() => { switchTab(true); }}>
                                <span>ALL TICKETS</span>
                            </li>
                            <li className={state.is_message_table ? '' : 'active'} onClick={() => { switchTab(false); }}>
                                <span>ASSIGNED TO ME</span>
                            </li>
                        </ul>
                    </div>  
                    <div className="right_side">
                        <div className="form-group flex ai-center fltr-txt flex-wrap justify-content-end">
                          {
                            renderFilter()
                          }
                        </div>
                    </div>                                  
                </div>
                <div className="data">
                    {
                        state.is_message_table ? renderAllMessage() : renderMyMessage()
                    }
                </div>  
            </div>
        </div>

        {/* Create New ticketFilter */}
        <Drawer
          visible={state.is_add_new_ticket}
          width={'690'}
          closable={false}
          className="drawer-wrapper create_ticket_drawer"
        >

          <CreateNewTicket
            getGroupList = { getGroupList }
            UserList = { state.UserList }
            GroupList = { state.GroupList }
            AllCustomer = { state.AllCustomer }
            is_message_table={state.is_message_table}
            // NewTicket = { NewTicket }
            newTicketToggle={() => { newTicketToggle() }}
          />


        </Drawer>

    </div>
  )
}

export default CaseManagement;
