import React, { Component } from 'react';
import {map, uniqBy} from 'lodash';
import { Select, DatePicker, message } from 'antd';
import * as yup from 'yup';
// import ScrollArea from 'react-scrollbar';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import moment from 'moment-timezone';
import Loader from '../../../../Components/Loader';

const { Option } = Select;

// maping Disposition for hardcoded
const DISPOSITION_MAPPING = {
  complaint: 'Complaint',
  missed_service: 'Missed service',
  schedule_problem: 'Schedule Problem',
  unhappy_with_service: 'Unhappy With Service',
  issue_with_tech: 'Issue With Tech',
  driving: 'Driving',
  billing: 'Billing',
  missed_svc: 'Missed SVC',
  edit_existing_service: 'Edit existing Service',
  other: 'Other'
};

class TicketReplay extends Component {
  state = {
    message_replies: [],
    reason: [
      { label: 'Complaint', value: 'complaint' },
      { label: 'Missed service', value: 'missed_service' },
      { label: 'Schedule Problem', value: 'schedule_problem' },
      { label: 'Issue With Tech', value: 'issue_with_tech' },
      { label: 'Unhappy With Service', value: 'unhappy_with_service' },
      { label: 'Driving', value: 'driving' },
      { label: 'Billing', value: 'billing' },
      { label: 'Missed SVC', value: 'missed_svc' },
      { label: 'Edit existing Service', value: 'edit_existing_service' },
      { label: 'Request one-time service', value: 'request_one_time_service' },
      { label: 'Other', value: 'other' },
    ],
    new_ticket: {
      uuid: '',
      title: '',
      message: '',
      reason: '',
      follow_up_date: '',
      status: '',
      priority: '',
      assigned_user: '',
      user: this.props.customerId,
      cc_to_customer: false,
      assigned_group: '',
      satisfaction: ''
    },
    assigned_group_details: { uuid: '', name: '' },
    assigned_user_details: { uuid: '', first_name: '', last_name: '' },
    replay_message: { message: '', parent_uuid: '' },
    status: 'close',
    is_internal: true,
    is_btn_Active: false,
    errors: {},
    AllUser: [],
    GroupList: []
  }



  componentDidMount = () => {
    let { ticket_details } = this.props;
    let { new_ticket, replay_message } = this.state;



    new_ticket = Object.assign(new_ticket,
      {
        uuid: ticket_details.uuid,
        assigned_group: ticket_details.assigned_group,
        satisfaction: ticket_details.satisfaction,
        assigned_user: ticket_details.assigned_user,
        assigned_user_details: ticket_details.assigned_user_details,
        user_details: ticket_details.user_details,
        user: this.props.customerId,
        title: ticket_details.title,
        message: ticket_details.message,
        reason: ticket_details.reason,
        follow_up_date: ticket_details.follow_up_date ? this.UTCToLocal(ticket_details.follow_up_date) : null,
        created_at: ticket_details.created_at ? this.UTCToLocal(ticket_details.created_at) : null,
        status: ticket_details.status,
        priority: ticket_details.priority,
        cc_to_customer: ticket_details.cc_to_customer,

      }
    )
    replay_message = Object.assign(replay_message, { parent_uuid: ticket_details.uuid });

    this.setState({
      errors: {},
      new_ticket,
      message_replies: ticket_details.replies,
      assigned_group_details: ticket_details.assigned_group_details || { uuid: '', name: '' },
      assigned_user_details: ticket_details.assigned_user_details || { uuid: '', first_name: '', last_name: '' },
      replay_message,
      status: ticket_details.status
    })

    this.props.getUserList({ 'offset': 0, 'limit': 1000, group__uuid: ticket_details?.assigned_group_details?.uuid ? ticket_details?.assigned_group_details?.uuid : '', 'ordering': 'first_name', 'is_case_mgmt': true });

    //get Group 
    this.props.getGroupList({ 'limit': 15, offset: 0 })
  }



  UNSAFE_componentWillReceiveProps = (props) => {
    let { replay_message, message_replies } = this.state;
    // message.destroy()
    //Replies Message
    if (props.RepliesonTicket && Object.keys(props.RepliesonTicket.data).length > 0 && props.RepliesonTicket.data !== this.props.RepliesonTicket.data) {

      //Get User message and tickets
      message_replies.push(props.RepliesonTicket.data)
      replay_message = Object.assign(replay_message, { 'message': '' });
      this.setState({
        replay_message,
        loader: false
      })
      setTimeout(() => {
        if (this.refs['message-replies-scroll']) {
          this.refs['message-replies-scroll']['scrollArea'].scrollBottom();
        }
      }, 500);
    }

    if (props.RepliesonTicket && Object.keys(props.RepliesonTicket.errors).length > 0 && props.RepliesonTicket.errors !== this.props.RepliesonTicket.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }

    // Update Message
    if (props.UpdateMesssage && Object.keys(props.UpdateMesssage.data).length > 0 && props.UpdateMesssage.data !== this.props.UpdateMesssage.data) {

      //Get User message and tickets
      this.setState({
        loader: false
      });
      this.props.closeReplieTicket(false);
      //Get User message and tickets
      this.props.getAllMessages({ 'user__uuid': this.props.customerId })
    }

    if (props.UpdateMesssage && Object.keys(props.UpdateMesssage.errors).length > 0 && props.UpdateMesssage.errors !== this.props.UpdateMesssage.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }

    //Get Group list
    if (props.GroupList && Object.keys(props.GroupList.data).length > 0 && props.GroupList.data !== this.props.GroupList.data) {
      this.setState({
        loader: false,
        GroupList: props.GroupList.data.results
      })
    }

    if (props.GroupList && Object.keys(props.GroupList.errors).length > 0 && props.GroupList.errors !== this.props.GroupList.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }



    //get User

    if (props.UserList && Object.keys(props.UserList.data).length > 0 && props.UserList.data !== this.props.UserList.data) {
      this.setState({
        AllUser: props.UserList.data.results,
        loader: false
      })
    }

    if (props.UserList && Object.keys(props.UserList.errors).length > 0 && props.UserList.errors !== this.props.UserList.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }

  }


  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date)
  }

  // Render All Replies
  renderReplies = () => {
    let { message_replies, is_internal } = this.state;

    return map(message_replies, (item, i) => {
      if (message_replies[i].is_internal === is_internal) {
        return (
          <div className="main_msg" key={i}>
            <div className="body">
              <div className="user_details">
                <label>{item.user_details && item.user_details.first_name} {item.user_details && item.user_details.last_name}</label>
                <span>{moment(item.created_at).format("DD/MM/YYYY")} at {moment(item.created_at).format("hh:mm a")} </span>
              </div>
              <p className="msg_text">
                {item.message}
              </p>
            </div>
          </div>
        )
      }
      if (!message_replies[i].is_internal === !is_internal) {
        return (
          <div className="main_msg" key={i}>
            <div className="body">
              <div className="user_details">
                <label>{item.user_details && item.user_details.first_name} {item.user_details && item.user_details.last_name}</label>
                <span>{moment(item.created_at).format("DD/MM/YYYY")} at {moment(item.created_at).format("hh:mm a")} </span>
              </div>
              <p className="msg_text">
                {item.message}
              </p>
            </div>
          </div>
        )
      }
    })

  }

  //Text Change for new ticket
  onnewTicketChange = (e) => {
    let { new_ticket } = this.state;
    new_ticket = Object.assign(new_ticket, { [e.target.name]: e.target.value });
    this.setState({
      new_ticket
    });
  }


  //Validation for required field
  ValidateTicket = () => {
    var self = this;
    setTimeout(() => {
      let { new_ticket, errors } = this.state;
      const TicketSchema = yup.object().shape({
        new_ticket: yup.object().shape({
          title: yup.string().required("Title is required"),
          message: yup.string().required("Message is required"),
          // assigned_user: yup.string().required("Assign user is required").nullable('Assign user is required'),
          user: yup.string().required("user is required"),
          // follow_up_date: yup.string().required("Follow up date is required").nullable('Follow up date is required'),
          status: yup.string().required("Status is required"),
          reason: yup.string().required("Reason is required"),
          // assigned_group: yup.string().nullable('Assign Group is required').required("Assign Group is required")
          assigned_group: yup.string().nullable(),
          assigned_user: yup.string().nullable().test('match', 'Assign user or Assign Group is required', function(assigned_user) {
            return !!(assigned_user || this.parent.assigned_group);
          }),
        })
      })

      const TicketData = {
        new_ticket
      }
      let IsValid;
      let err_data = {}
      TicketSchema.isValid(TicketData)
        .then(function (valid) {
          IsValid = valid
        });

      setTimeout(() => {
        if (IsValid) {
          self.setState({
            errors: {}
          })
          //Update ticket
          self.updateTicket()
        }
        else {
          TicketSchema.validate(TicketData, { abortEarly: false })
            .catch((err) => {
              map(err.inner, (item) => {
                err_data[item.path] = item.message;
              })

              self.setState({
                errors: err_data,
              })
              console.log("err_data:", err_data)
            });
        }
      }, 100);

    }, 300);
  }

  //Create ticket new change
  onSelectChange = (e, type) => {
    let { new_ticket } = this.state
    new_ticket = Object.assign(new_ticket, { [type]: e });
    this.setState({ new_ticket });
  }

  //Replies on Tickets
  replieOnChange = (e) => {
    let { replay_message } = this.state;
    replay_message = Object.assign(replay_message, { message: e.target.value });
    this.setState({
      replay_message
    })
  }

  // Replies on Ticket API call
  repliesTicket = () => {
    let { replay_message, is_internal } = this.state;
    this.setState({
      loader: true
    })
    let data = {
      is_internal,
      ...replay_message
    }
    if (replay_message.message) {
      this.props.MessageReplies(data)
    }

  }

  //Update Tickets
  updateTicket = () => {
    let { new_ticket } = this.state;
    this.setState({
      loader: true
    })
    let data = {
      ...new_ticket,
      // id: new_ticket.uuid
    }

    console.warn('data', data);

    if (!data.follow_up_date) {
      delete data.follow_up_date
    }
    if (this.props?.CustomerList?.customer?.address?.[0]?.zip_code) {
      data.zip_code = this.props?.CustomerList?.customer?.address?.[0]?.zip_code
    }

    if (data.satisfaction === '') {
      delete data.satisfaction
    }

    if (data.assigned_user === '' || data.assigned_user === null) {
      delete data.assigned_user
    }

    if (data.assigned_group === '' || data.assigned_group === null) {
      delete data.assigned_group
    }

    

    if (data.follow_up_date) {
      data.follow_up_date = this.localToUTC(data.follow_up_date).format('YYYY-MM-DD')
    }

    if (data.created_at) {
      data.created_at = this.localToUTC(data.created_at).format('YYYY-MM-DD')
    }

    const params = this.filterParams(data)
    this.props.UpdateTickets(params)
  }

  filterParams = (params) => {
    for (var propName in params) {
      if (params[propName] === "" || params[propName] === null || params[propName] === "null" || params[propName] === undefined || params[propName] === "undefined") {
        delete params[propName];
      }
    }
    return params
  }

  render() {
    let { reason, new_ticket, assigned_group_details, is_internal, replay_message, loader, status, assigned_user_details, errors, AllUser, GroupList } = this.state;
    let scrollbarStyles = { borderRadius: 3.5 };
    const local = this.UTCToLocal(new_ticket.created_at)

    return (
      <div className="block-row">
        <div className="new_ticket_wrapper flex">
          <div className="ticket_detail">

            {/* Main message Details */}
            <div className="main_msg">
              <div className="header">
                <b>{new_ticket.title} <span>New</span></b>
                <p><b>{DISPOSITION_MAPPING[new_ticket.reason]}</b>  · {new_ticket.assigned_user_details && new_ticket.assigned_user_details.first_name} {new_ticket.assigned_user_details && new_ticket.assigned_user_details.last_name} Submitted</p>
              </div>
              <div className="body">
                <div className="user_details">
                  <label>{new_ticket.user_details && new_ticket.user_details.first_name} {new_ticket.user_details && new_ticket.user_details.last_name}</label>
                  <span>{local.format("DD/MM/YYYY")} at {local.format("hh:mm a")} </span>
                </div>
                <p className="msg_text">
                  {new_ticket.message} {new_ticket?.cc_to_customer ? <b>{'(visible to customer)'}</b> : ''}
                </p>
              </div>
            </div>

            {/* Replay Tab Section */}

            <ul className="tabs dark mt-30 mb-10">
              <li
                onClick={() => {
                  this.setState({ is_internal: true });
                  setTimeout(() => {
                    if (this.refs['message-replies-scroll']) {
                      this.refs['message-replies-scroll']['scrollArea'].scrollBottom();
                    }
                  }, 500);
                }}
                className={is_internal ? 'active' : ''}
                style = {{ 'marginRight': '10px'}}
              ><span>INTERNAL REPLIES</span></li>
              <li
                onClick={() => {
                  this.setState({ is_internal: false });
                  setTimeout(() => {
                    if (this.refs['message-replies-scroll']) {
                      this.refs['message-replies-scroll']['scrollArea'].scrollBottom();
                    }
                  }, 300);
                }}
                className={!is_internal ? 'active' : ''}><span>CUSTOMER FACING</span></li>
            </ul>
            <div className="message_replies">
              {/* <PerfectScrollbar

                className="message-replies-scroll"
                contentClassName="content"
                ref="message-replies-scroll"
                horizontal={false}
                speed={0.8}
                verticalScrollbarStyle={scrollbarStyles}
                verticalContainerStyle={scrollbarStyles}
                horizontalScrollbarStyle={scrollbarStyles}
                horizontalContainerStyle={scrollbarStyles}
                smoothScrolling={true}
                minScrollSize={40}
              >
                {this.renderReplies()}
              </PerfectScrollbar> */}
            </div>

            <div className="replies_ticket">
              <h5>Reply to ticket</h5>
              {!is_internal ?
                <blockquote className="horiz danger_quote">
                  <b>This is visible to the customer. </b> &nbsp;&nbsp;
                  <p className="m-0">Please make sure your message is customer-safe</p>
                </blockquote>
                : ''}

              {status === 'open' ?
                <React.Fragment>
                  <div className="fitem">
                    <textarea
                      placeholder="Add your message here"
                      value={replay_message.message}
                      onChange={(e) => { this.replieOnChange(e) }}
                      rows="5"
                    ></textarea>
                  </div>
                  <button
                    disabled={replay_message.message ? false : true}
                    className={replay_message.message ? 'btn btn-warning' : 'btn disabled'}
                    onClick={() => { this.repliesTicket() }}
                  >Reply to Ticket</button>
                </React.Fragment>
                : ''}
            </div>

          </div>

          <div className="card">
            <div className="card-title"><h5>Ticket Details</h5></div>
            <div className="card-body">
              <div className="form-group">
                <div className="fitem">
                  <label>Disposition</label>
                  <Select
                    placeholder="Disposition"
                    value={new_ticket.reason}
                    onChange={(e) => {
                      this.onSelectChange(e, 'reason')
                    }}
                    defaultValue=""
                  >
                    <Option value=""></Option>
                    {
                      map(reason, (item, i) => {
                        return (<Option value={item.value} key={i}>{item.label}</Option>)
                      })
                    }
                  </Select>
                  {errors['new_ticket.reason'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.reason']}</label> : ''}
                </div>

                <div className="fitem">
                  <label>Follow Up Date</label>
                  <DatePicker

                    disabledDate={(current) => { return current && current <= moment().endOf('day'); }}
                    name='follow_up_date'
                    format="MM/DD/YYYY"
                    suffixIcon={<i className="las la-calendar"></i>}
                    allowClear={false}
                    onChange={(e) => {
                      new_ticket = Object.assign(new_ticket, { 'follow_up_date': moment(e).format('YYYY-MM-DD') });
                      this.setState({
                        new_ticket
                      })
                    }}
                    value={new_ticket.follow_up_date ? moment(new_ticket.follow_up_date) : null}
                  />
                  {errors['new_ticket.follow_up_date'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.follow_up_date']}</label> : ''}
                </div>

                <div className=" fitem">
                  <label>Priority</label>
                  <Select
                    defaultValue=""
                    value={new_ticket.priority}
                    onChange={(e) => {
                      this.onSelectChange(e, 'priority')
                    }}
                  >
                    <Option value="">All</Option>
                    <Option key="4" value="4"><span className="medium prty">Standard Priority</span></Option>
                    <Option key="3" value="3"><span className="high prty">High Priority</span></Option>
                  </Select>
                  {errors['new_ticket.priority'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.priority']}</label> : ''}
                </div>
                <div className=" fitem">
                  <label>Status</label>
                  <Select
                    defaultValue=""
                    value={new_ticket.status}
                    onChange={(e) => {
                      this.onSelectChange(e, 'status')
                    }}
                  >
                    <Option value="">All</Option>
                    <Option key='open' value="open"><span className="open prty">Open</span></Option>
                    <Option key='closed' value="closed"><span className="closed prty">Closed</span></Option>
                  </Select>
                  {errors['new_ticket.status'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.status']}</label> : ''}
                </div>
                <div className="fitem">
                  <b>Assigned to:</b>
                </div>
                <div className="fitem half-fitem">
                  <div className="fitem half-fitem">
                    <label>Group</label>
                    <Select
                      showSearch={true}
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      style={{ 'width': '100%' }}
                      placeholder="Please Select"
                      defaultValue={{ value: '', label: '' }}
                      value={{ value: assigned_group_details.uuid || undefined, label: assigned_group_details.name }}
                      labelInValue
                      onSearch={(e) => this.props.getGroupList({ 'search': e })}
                      onChange={(a, b) => {
                        assigned_group_details = Object.assign(assigned_group_details, { name: a.value, uuid: b.key });
                        new_ticket = Object.assign(new_ticket, { 'assigned_group': b.key })



                        //Get Staff based on group
                        this.props.getUserList({ 'limit': 1000, offset: 0, group__uuid: b.key, 'ordering': 'first_name', 'is_case_mgmt': true });
                        new_ticket = Object.assign(new_ticket, { assigned_user: '' });
                        assigned_user_details = Object.assign(assigned_user_details,

                          assigned_user_details = {
                            uuid: '',
                            first_name: '',
                            last_name: ''
                          }
                        )


                        this.setState({
                          assigned_user_details,
                          assigned_group_details,
                          new_ticket,
                          AllUser:[]
                        })
                      }}
                      onBlur={() => { this.props.getGroupList({ 'offset': 0, 'limit': 15 }); }}
                    >
                      {uniqBy(GroupList.filter(item => item.status === 'active'), 'uuid', 'asc').map((item) => {
                        return (
                          <Option key={item.uuid} value={item.name}>{item.name}</Option>
                        )
                      })}
                      <Option value="" key="">Assign to Group</Option>
                    </Select>
                    {errors['new_ticket.assigned_group'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.assigned_group']}</label> : ''}
                  </div>

                  <div className="fitem half-fitem">
                    <label>User</label>
                    <Select
                      disabled={new_ticket?.assigned_group}
                      showSearch
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      placeholder="Please Select"
                      // value={new_ticket.assigned_user || undefined}
                      value={{ value: assigned_user_details.uuid || '', label: `${assigned_user_details.first_name} ${assigned_user_details.last_name}` }}
                      labelInValue
                      onChange={(e, i) => {
                        new_ticket = Object.assign(new_ticket, { assigned_user: i.key });
                        assigned_user_details = Object.assign(assigned_user_details,

                          assigned_user_details = {
                            uuid: i.key,
                            first_name: i.children.length > 0 ? i.children[0] : '',
                            last_name: i.children.length > 0 ? i.children[2] : ''
                          }

                        )
                        this.setState({ assigned_user_details, new_ticket })

                      }}
                      onSearch={(e) => {
                        if (assigned_group_details.uuid) {
                          this.props.getUserList({ 'limit': 1000, 'search': e, group__uuid: assigned_group_details.uuid, 'ordering': 'first_name', 'is_case_mgmt': true})
                        } else {
                          this.props.getUserList({ 'limit': 1000, 'search': e, 'ordering': 'first_name', 'is_case_mgmt': true})
                        }
                      }}
                      onBlur={() => {
                        if (assigned_group_details.uuid) {
                          this.props.getUserList({ 'offset': 0, 'limit': 1000, group__uuid: assigned_group_details.uuid, 'ordering': 'first_name', 'is_case_mgmt': true });
                        }
                      }
                      }
                    >
                      <Option value="">Assign to ASP Individual</Option>
                      {
                        map(AllUser, (item) => {
                          return (
                            <Option value={`{${item.first_name} ${item.last_name}}`} key={item.uuid}>{item.first_name} {item.last_name} </Option>
                          )
                        })
                      }

                    </Select>
                    {errors['new_ticket.assigned_user'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.assigned_user']}</label> : ''}
                  </div>
                </div>
                <button
                  // disabled={!is_btn_Active}
                  // className={is_btn_Active ? 'btn btn-warning btn-full' : 'btn disabled btn-full'}
                  className="btn btn-warning btn-full"
                  onClick={() => { this.ValidateTicket() }}

                >Update Ticket</button>
              </div>
            </div>

          </div>
          {
            loader && <Loader />
          }
        </div >
      </div >
    );
  }
}

export default TicketReplay;
