import React from "react";
import { useMediaQuery } from 'react-responsive';

const useResponsiveContainer = () => {

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 769 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return [isMobile, isDesktopOrLaptop];
};

export default useResponsiveContainer;
