import { useEffect, useRef } from "react";
import { EventBus } from "./eventBus";

export function useEventBus(eventName, handler) {
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const eventListener = event => savedHandler.current(event);
        EventBus.on(eventName, eventListener);
        return () => {
            EventBus.off(eventName, eventListener);
        };
    }, [eventName]);
}
