import React, { useEffect, useState, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import moment from "moment-timezone";
import { message, notification } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { getCustomerDetail, getCommercialCustomer } from "../../../Action/Accounting";
import { useNavigate } from "react-router-dom";
import { deleteService, rescheduleService, rescheduleRecurringService, updateTaskInfo, reassignTaskInfo } from "../../../Action/Calendar";
import { useCalendar } from "../../Hooks/TechGlance.Hook";
import { DeleteJobModalTechGlance } from './DeleteJob.Modal.TechGlance';
import { PopupModalTechGlance } from './Popup.Modal.TechGlance';
import { ReassignModalTechGlance } from './Reassign.Modal.TechGlance';
import { WorkOrderFormModalTechGlance } from './WorkOrderForm.Modal.TechGlance';
import { DragChipTechGlance } from './DragChip.TechGlance';
import { setServiceTaskData, setSelectedTaskData, toggleTaskModal, setTaskFlagFromEdit } from "../../../Store/technicianTaskDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";

const WorkOrder = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const role = userInfo && userInfo?.user?.user_roles[0]?.name;
  const [woTaskInfo, setWoTaskInfo] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const shouldOpenPopup = React.useRef(true);
  const [isOpen, setOpen] = React.useState(false);
  const [woModalOpen, setWOModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [rescheduleRecurring, setRescheduleRecurring] = useState(false);
  const [bgColor, setBgColor] = React.useState("");
  const [anchor, setAnchor] = React.useState(null);
  const [rescheduleTechnician, setRescheduleTechnician] = useState({ value: null, label: null });
  const [rescheduleDate, setRescheduleDate] = useState(null);
  const [rescheduleTimeSelection, setRescheduleTimeSelection] = useState(moment("9:00", "HH:mm:A"));
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { franchise, technician } = useCalendar();
  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };
  const timerRef = React.useRef(null);
  
  const showWOModal = (item) => {
    setSelectedEvent(item);
    setOpen(false);
    setWOModalOpen(true);
  };

  const onChangeTimePicker = (time) => {
    setRescheduleTimeSelection(time);
  };

  const openAccountOverview = async (data) => {
    if (data?.client_type) {
      let record = {};
      setOpen(false);
      const customer_id = data?.client_uuid ?? data?.original?.client_uuid;
      if (data?.client_type === "residential") {
        record = await getCustomerDetail(customer_id); // get customer detail
      } else {
        record = await getCommercialCustomer(customer_id); // get customer detail
      }
      navigate(`/${data?.client_type}/customer-detail`, {
        state: {
          id: record?.uuid,
          hear_about_us: record?.hear_about_us,
          customer_satisfaction_score: record?.customer_satisfaction_score,
          record: record,
        },
      });
    }else {
      message.error("Client type not available for this customer.", 5);
    }
  };

  const onChangRecurring = (e) => {
    setRescheduleRecurring(e.target.checked);
    if (e.target.checked) {
      setRescheduleTimeSelection(moment("9:00", "HH:mm:A"));
      setRescheduleDate(null);
    }
  };

  const showRescheduleModal = (item) => {
    setSelectedEvent(item);
    setOpen(false);
    setRescheduleModalOpen(true);
    dispatch(setSelectedTaskData(item))
  };
  const hideRescheduleModal = () => {
    setRescheduleModalOpen(false);
  };
  const onCloseRescheduleModal = () => {
    // setRescheduleTimeSelection(moment("9:00", "HH:mm"));
    // setRescheduleDate(null);
    // setRescheduleRecurring(false);
    // setRescheduleTechnician({ value: null, label: null });
  };

  const onRescheduleTask = async () => {
    try {
      await reassignTaskInfo({
        task_uuid: selectedEvent?.original?.work_order_uuid,
        reassign_to: rescheduleTechnician?.value == "unassign" ? "Not Assigned" : rescheduleTechnician?.value,
        reassign_date: rescheduleDate ? rescheduleDate.format("YYYY-MM-DD") : undefined,
        reassign_time: rescheduleTimeSelection ? rescheduleTimeSelection.format("HH:mm:ss") : "9:00:00",
        is_reassign_future_tasks: rescheduleRecurring
      });
      openNotificationWithIcon("success", "Reschedule Task:", selectedEvent.original.title);
      setRescheduleModalOpen(false);
      await props.getWorkOrder();
    } catch (error) {
      setRescheduleModalOpen(false);
      openNotificationWithIcon("error", "Could not remove event");
    }
  }

  const onRescheduleWorkOrder = async () => {
    try {
      if (rescheduleRecurring) {
        await rescheduleRecurringService({
          service_uuid: selectedEvent.original.work_order_uuid,
          technician_uuid: rescheduleTechnician.value ?? undefined,
        });
      } else {
        await rescheduleService({
          service_uuid: selectedEvent.original.work_order_uuid,
          date_to: rescheduleDate
            ? rescheduleDate.format("YYYY-MM-DD")
            : undefined,
          time_to: rescheduleTimeSelection
            ? rescheduleTimeSelection.format("HH:mm")
            : "9:00",
            technician_uuid: rescheduleTechnician?.value !== "unassign" ? rescheduleTechnician?.value : "not assigned",
          });
      }
      openNotificationWithIcon(
        "success",
        "Reschedule work order:",
        selectedEvent.original.title
      );
      setRescheduleModalOpen(false);
      await props.getWorkOrder();
    } catch (error) {
      setRescheduleModalOpen(false);
      openNotificationWithIcon("error", "Could not remove event");
    }
  };
  const showDeleteModal = (item) => {
    setSelectedEvent(item);
    setOpen(false);
    setDeleteModalOpen(true);
    dispatch(setSelectedTaskData(item))
  };
  const onDeleteWorkOrder = async () => {
    try {
      await deleteService(selectedEvent.original.work_order_uuid);
      openNotificationWithIcon(
        "error",
        "Delete work order:",
        selectedEvent.original.title
      );
      await props.getWorkOrder();
      setDeleteModalOpen(false);
    } catch (error) {
      message.error("Could not remove event");
      setDeleteModalOpen(true);
    }
  };

  const openNotificationWithIcon = (type, title, text) => {
    notification[type]({
      message: title,
      description: text,
      icon:
        type === "success" ? (
          <CheckCircleFilled
            style={{
              color: "#52c41a",
            }}
          />
        ) : (
          <CloseCircleFilled
            style={{
              color: "#ff4d4f",
            }}
          />
        ),
    });
  };

  const onEventClick = React.useCallback((args) => {
    if (!shouldOpenPopup.current) {
      return;
    }
    const event = args.event;
    setBgColor(event.color);
    setCurrentEvent(event);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setAnchor(args.domEvent.target);
    setOpen(true);
    shouldOpenPopup.current = true;
  }, []);

  useEffect(() => {
    setWoTaskInfo(props.workOrder);
  }, [props]);

  const updateTaskStatus = async (currData, setStatus) => {
    try {
      const updateTask = await updateTaskInfo({
        task_uuid: currData?.type == 'Task' ? currData?.work_order_uuid : currentEvent?.work_order_uuid,
        task_status: setStatus
      });
      setOpen(false);
      await props.getWorkOrder();
    } catch (error) {
      message.error("Something went wrong.");
    }
  };

  return (
    <>
      <Draggable
        key={woTaskInfo.work_order_uuid}
        draggableId={`${woTaskInfo.work_order_uuid}_${props.index}`}
        index={props.index}
      >
        {(provided, snapshot) => {
          return (
            <DragChipTechGlance
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              provided={provided}
              woTaskInfo={woTaskInfo}
              onEventClick={onEventClick}
              shouldOpenPopup={shouldOpenPopup}
              showWOModal={showWOModal}
              techPermission={props?.techPermission}
              role={role}
              showRescheduleModal={showRescheduleModal}
              showDeleteModal={showDeleteModal}
              openAccountOverview={openAccountOverview}
            />
          );
        }}
      </Draggable>
      <WorkOrderFormModalTechGlance
        selectedEvent={selectedEvent}
        woModalOpen={woModalOpen}
        setWOModalOpen={setWOModalOpen}
        windowSize={windowSize}
      />
      <ReassignModalTechGlance
        rescheduleModalOpen={rescheduleModalOpen}
        onRescheduleWorkOrder={onRescheduleWorkOrder}
        onRescheduleTask={onRescheduleTask}
        hideRescheduleModal={hideRescheduleModal}
        onCloseRescheduleModal={onCloseRescheduleModal}
        selectedEvent={selectedEvent}
        role={role}
        techPermission={props.techPermission}
        franchise={franchise}
        setRescheduleTechnician={setRescheduleTechnician}
        rescheduleDate={rescheduleDate}
        setRescheduleDate={setRescheduleDate}
        rescheduleTimeSelection={rescheduleTimeSelection}
        onChangeTimePicker={onChangeTimePicker}
        technician={technician}
        onChangRecurring={onChangRecurring}
        rescheduleRecurring={rescheduleRecurring}
        disabledDate={disabledDate}
      />
      <DeleteJobModalTechGlance
        deleteModalOpen={deleteModalOpen}
        onDeleteWorkOrder={onDeleteWorkOrder}
        setDeleteModalOpen={setDeleteModalOpen}
        updateTaskStatus={updateTaskStatus}
      />
      <PopupModalTechGlance
        isOpen={isOpen}
        anchor={anchor}
        setOpen={setOpen}
        bgColor={bgColor}
        currentEvent={currentEvent}
        shouldOpenPopup={shouldOpenPopup}
        showWOModal={showWOModal}
        woTaskInfo={woTaskInfo}
        showRescheduleModal={showRescheduleModal}
        showDeleteModal={showDeleteModal}
        updateTaskStatus={updateTaskStatus}
        techPermission={props.techPermission}
        role={role}
      />
    </>
  );
};

export default WorkOrder;