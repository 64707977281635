import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Provider, useSelector, useDispatch } from "react-redux";
import { Col, Row, Select, message, Skeleton, Empty, Result } from "antd";
import { BankOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import AcceptedCards from "../../../Assets/images/credit-cards.png";
import { _, isEmpty, map } from "lodash";
import {
  selectPayment,
  updateBankAccount,
  updateBillingAddress,
  updateChargePermission,
  updateCreditCard,
  getInvoiceData,
  savePaymentData,
} from "../../../Store/paymentSlice";
import store from "../../../Store/store";
import AllState from "../../../Assets/data/stateList.json";
import { useSearchParams } from "react-router-dom";
import useResponsiveContainer from "../../../Calendar/Components/Containers/useResponsiveContainer";
import successCircle from "../../../Assets/icons/success circle.svg";

const { Option } = Select;

const PaymentContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isMobile, isDesktopOrLaptop] = useResponsiveContainer();
  const [paymentData, setPaymentData] = useState({
    invoice_uuid: "",
    balance_due: null,
    prev_balance: null,
    stax_customer: null,
    stax_public_apikey: null,
    tax: null,
    total: null,
  });
  const [displayBank, setDisplayBank] = useState(false);
  const [displayCC, setDisplayCC] = useState(false);
  const [checked_permissions, setchecked_permissions] = useState(true);
  const [accountType, setAccountType] = useState("");
  const [bankHolderType, setBankHolderTyp] = useState("");
  const [staxPublicApikey, setStaxPublicApikey] = useState("");
  const [errors, setErrors] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [fattJs, setFattJs] = useState(null);
  const [paymentSucessPage, setPaymentSuccessPage] = useState(false);

  const paymentState = useSelector(selectPayment);
  const {
    bankAccountData,
    creditCardData,
    billingAddress,
    invoice,
    status,
    messageResponse,
  } = paymentState;

  //Once the component is created, we get the params
  useEffect(() => {
    getUrlParams();
  }, []);

  //Once we have invoice uuid, we get the complete invoice data
  useEffect(() => {
    if (!isEmpty(paymentData.invoice_uuid)) {
      getInvoice();
    }
  }, [paymentData.invoice_uuid]);

  //Check if  invoice is paid and prev_balance calculation
  useEffect(() => {
    if (invoice && invoice.is_paid) {
      setIsDisable(true);
      setPaymentData({
        balance_due: 0,
        prev_balance: 0,
        tax: 0,
        total: 0,
      });
      message.success("Account paid in full and no pending balance");
      setPaymentSuccessPage(true);
    } else if (invoice?.is_paid !== undefined) {
      setPaymentData((s) => ({
        ...s,
        ...invoice,
        prev_balance: parseFloat(
          invoice?.balance_due - (invoice?.total + invoice?.tax)
        ).toFixed(2),
      }));
      setIsDisable(false);
    }
  }, [invoice]);

  //Get params
  const getUrlParams = () => {
    if (document.location.search && document.location.search.includes("?")) {
      let encoded = document.location.search?.split("?")[1];

      // Decode
      const params_data = atob(encoded);
      const data = JSON.parse(
        '{"' +
          decodeURI(params_data)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      const params = {
        invoice_uuid: data.invoice_uuid,
        stax_customer: data.stax_customer,
        stax_public_apikey: data.stax_public_apikey,
      };
      setPaymentData(params);
      setStaxPublicApikey(data.stax_public_apikey);
    }
  };

  //Get invoice data
  const getInvoice = () => {
    dispatch(
      getInvoiceData({
        invoice_uuid: paymentData.invoice_uuid,
      })
    );
  };

  //Load stax to do payment
  const loadStax = () => {
    setErrors({});
    if (staxPublicApikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(staxPublicApikey, {
        number: {
          id: "card-number",
          placeholder: "Card Number",
          style:
            "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;", // 35px is the height of my card-number div
        },
        cvv: {
          id: "card-cvv",
          placeholder: "CVV",
          style:
            "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;", // 35px is the height of my card-cvv div
        },
      });
      fattJsObj
        .showCardForm()
        .then((handler) => {
          //console.log('form loaded', handler);
        })
        .catch((err) => {
          console.error("error occur", err);
        });
      setFattJs(fattJsObj);
    } else {
      message.error(
        "Error occur while loading stax. please contact to admin.",
        10
      );
    }
  };

  // Load Stax ACH
  const loadStaxACH = () => {
    let stax_public_apikey = staxPublicApikey;
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {});
      setFattJs(fattJsObj);
    } else {
      message.error(
        "Error occur while loading stax. please contact to admin.",
        10
      );
    }
  };

  //Payment Method
  const onBankCCChange = (e) => {
    if (e === "creditCard") {
      setDisplayCC(true);
      setDisplayBank(false);
      loadStax();
    } else if (e === "bankAccount") {
      loadStaxACH();
      setDisplayBank(true);
      setDisplayCC(false);
    }
  };

  //Save Bank Account data
  const onAccountChange = (e) => {
    setAccountType(e.target.value);
    dispatch(
      updateBankAccount({
        ...bankAccountData,
        accountType: e.target.value,
      })
    );
  };

  //Save Bank Holder
  const onBankHolderChange = (e) => {
    setBankHolderTyp(e.target.value);
    dispatch(
      updateBankAccount({
        ...bankAccountData,
        bankHolderType: e.target.value,
      })
    );
  };

  //Display states
  const renderAllState = () => {
    return map(AllState?.AllState, (item, i) => {
      return (
        <Option value={item.abbreviation} key={i}>
          {item.name}
        </Option>
      );
    });
  };

  //Validate payment data
  const onValidate = () => {
    setErrors({});
    const paymentSchema = yup.object().shape({
      paymentMethod:
        !displayBank && !displayCC
          ? yup.bool().required("Payment Method is required")
          : null,
      bankAccount: displayBank
        ? yup.string().required("Bank Account is required")
        : null,
      routingNumber: displayBank
        ? yup.string().required("Route Account is required")
        : null,
      accountNumber: displayBank
        ? yup.string().required("Account Number is required")
        : null,
      accountType: displayBank
        ? yup.string().required("Account Type is required")
        : null,
      bankHolderType: displayBank
        ? yup.string().required("Bank Holder Type is required")
        : null,
      ccNumber: displayCC
        ? fattJs.validNumber
          ? null
          : yup
              .number()
              .required("Credit Card is required")
              .moreThan(0, "Credit Card is required")
        : null,
      expirationMonth:
        displayCC && creditCardData.expirationMonth <= 0
          ? yup.string().required("Expiration Month is required")
          : null,
      expirationYear: displayCC
        ? yup.string().required("Expiration Year is required")
        : null,
      cvv: displayCC
        ? fattJs.validCvv
          ? null
          : yup
              .number()
              .required("CVV is required")
              .moreThan(0, "CVV is required")
        : null,
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      address: yup.string().required("Address is required"),
      state: yup.string().required("State is required"),
      zipCode: yup
        .string()
        .required("Zipcode is required")
        .min(5, "Zipcode is required."),
      city: yup.string().required("City is required"),
    });

    const data = {
      displayBank,
      ...bankAccountData,
      ...creditCardData,
      ...billingAddress,
    };

    paymentSchema
      .validate(data, { abortEarly: false })
      .then(async () => {
        setIsDisable(true);
        //add payment to Stax
        if (displayBank) {
          addPaymentForACH();
        } else if (displayCC) {
          addPaymentForCC();
        }
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setErrors(err_data);
        console.log(err_data);
        setIsDisable(false);
      });
  };

  //Save Credit card payment to Stax
  const addPaymentForCC = async () => {
    const token_obj = {
      address_1: billingAddress.address,
      address_2: "",
      address_city: billingAddress.city,
      address_state: billingAddress.state,
      address_zip: billingAddress.zipCode,
      month: creditCardData.expirationMonth,
      year: creditCardData.expirationYear,
      card_number: creditCardData.card_number,
      firstname: billingAddress.firstName,
      lastname: billingAddress.lastName,
      method: "card",
      customer_id: paymentData.stax_customer,
      person_name: billingAddress.firstName + " " + billingAddress.lastName,
    };

    fattJs
      .tokenize(token_obj)
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          let params = {
            total: paymentData.balance_due,
            invoice_uuid: paymentData.invoice_uuid,
            stax_card_token: res["id"],
            stax_customer: paymentData.stax_customer,
            save_card: checked_permissions,
            card_holder_name: token_obj.person_name,
            payment_method: 'cc',
            address: {
              address: billingAddress.address,
              address2: null,
              city: billingAddress.city,
              state: billingAddress.state,
              zip_code: billingAddress.zipCode,
              formatted_address: `${billingAddress.address}, ${billingAddress.city}, ${billingAddress.state}., ${billingAddress.zipCode}`,
            },
          };
          //Save Payment
          dispatch(savePaymentData(params))
          .then((response) =>{
            if(response?.payload?.code === "ERR_BAD_RESPONSE" || response?.payload?.status !== 200){
              const message_error = response?.payload?.response?.data?.error ?? "Payment Failed, please try again.";
              message.error(message_error, 10);
              setIsDisable(false);
              console.log(response);
            }
            else{
              setPaymentSuccessPage(true);
            }
          });
        } else {
          message.error("Something went wrong, please try again.");
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.error("Error : ", err);
        setPaymentSuccessPage(false);
        setIsDisable(false);
      });
  };

  //Save ACH payment to Stax
  const addPaymentForACH = async () => {
    const token_obj = {
      address_1: billingAddress.address,
      address_city: billingAddress.city,
      address_state: billingAddress.state,
      address_zip: billingAddress.zipCode,
      firstname: billingAddress.firstName,
      lastname: billingAddress.lastName,
      bank_account: bankAccountData.accountNumber,
      bank_routing: bankAccountData.routingNumber,
      bank_holder_type: bankAccountData.bankHolderType,
      bank_type: bankAccountData.accountType,
      method: "bank",
      customer_id: paymentData.stax_customer,
      person_name: billingAddress.firstName + " " + billingAddress.lastName,
    };

    fattJs
      .tokenize(token_obj)
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          let params = {
            total: paymentData.balance_due,
            invoice_uuid: paymentData.invoice_uuid,
            stax_card_token: res["id"],
            stax_customer: paymentData.stax_customer,
            save_card: checked_permissions,
            card_holder_name: token_obj.person_name,
            payment_method: 'ach',
            address: {
              address: billingAddress.address,
              address2: null,
              city: billingAddress.city,
              state: billingAddress.state,
              zip_code: billingAddress.zipCode,
              formatted_address: `${billingAddress.address}, ${billingAddress.city}, ${billingAddress.state}., ${billingAddress.zipCode}`,
            },
          };
          //Save Payment
          dispatch(savePaymentData(params))
          .then((response) =>{
            if(response?.payload?.code === "ERR_BAD_RESPONSE" || response?.payload?.status !== 200){
              message.error("Payment Failed", 10);
              setIsDisable(false);
            }
            else{
              setPaymentSuccessPage(true);
            }
          });
        } else {
          message.error("Payment Failed", 10);
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.error("Error : ", err);
        message.error("Payment Failed", 10);
        map(err.fieldErrors, (item) => {
          message.error(item.message, 10);
        });
        setPaymentSuccessPage(false);
        setIsDisable(false);
      });
  };

  const setPaymentContent = () => {
    switch (status) {
      case "loading":
        return <Skeleton active />;
      case "succeeded":
        if (isEmpty(invoice)) {
          return <Empty description={"No invoice found"} />;
        }
        break;
      case "failed":
        return (
          <Result
            status="error"
            title="Request Failed"
            subTitle="There was an error on trying to get invoice data"
          />
        );
      default:
        break;
    }
  };

  return (
    <Provider store={store}>
    {paymentSucessPage ? 
      <div className="payment-success-section">
        <img src={successCircle}/>
        <h3 className="payment-success-message">Payment Made Successfully</h3>
      </div>
       :
      <div>
        <div className={`${isMobile ? "block-row-mobile" : "block-row"} `}>
          <div
            className={`${
              isMobile
                ? "payment-white-container-mobile"
                : "payment-white-container"
            } `}
          >
          <div>
            <div className="payment-section">
              <h3 className="payment-title-section">Invoice summary</h3>
              <hr />
              <div className="payment-content-section">
                {paymentData.prev_balance > 0 ? (
                  <div className="flex">
                    <span>Previous Balance</span>
                    <span>${paymentData.prev_balance} </span>
                  </div>
                ) : null}
                <div className="flex">
                  <span>Current Invoice Balance</span>
                  <span>${(paymentData.total !== undefined && paymentData.tax  !== undefined) ? (paymentData.total + paymentData.tax).toFixed(2) : ''} </span>
                </div>              
                <div className="flex mt-10" style={{ fontSize: "18px" }}>
                  <span>Balance Due</span>
                  <span>${paymentData?.balance_due ? (paymentData?.balance_due).toFixed(2) : ''} </span>
                </div>
              </div>
            </div>
            <div className="mb-15">
              <h3 className="payment-title">Payment Methods</h3>
            </div>
            {errors?.["paymentMethod"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.["paymentMethod"]}
              </label>
            ) : (
              ""
            )}
            <div className="payment-section" id="bank-account">
              <h3 className="payment-title-section">
                <input
                  type="radio"
                  name="bank-account-check"
                  className="mr-10"
                  checked={displayBank}
                  disabled={isDisable}
                  onClick={() => onBankCCChange("bankAccount")}
                />
                Bank Account
                <BankOutlined className="payment-icon" />
              </h3>
              {displayBank ? (
                <React.Fragment>
                  <hr />
                  <div className="payment-content-section form-group">
                    <div className="fitem">
                      <label>
                        Bank Account <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-group"
                        style={{ width: "100%" }}
                        value={bankAccountData.bankAccount}
                        placeholder="Bank Account"
                        onChange={(e) =>
                          dispatch(
                            updateBankAccount({
                              ...bankAccountData,
                              bankAccount: e.target.value,
                            })
                          )
                        }
                      />
                      {errors?.["bankAccount"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["bankAccount"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label>
                          Routing Number<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-group"
                          style={{ width: "100%" }}
                          value={bankAccountData.routingNumber}
                          placeholder="Routing Number"
                          onChange={(e) =>
                            dispatch(
                              updateBankAccount({
                                ...bankAccountData,
                                routingNumber: e.target.value,
                              })
                            )
                          }
                        />
                        {errors?.["routingNumber"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["routingNumber"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="fitem half-fitem">
                        <label>
                          Account Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-group"
                          style={{ width: "100%" }}
                          value={bankAccountData.accountNumber}
                          placeholder="Account Number"
                          onChange={(e) =>
                            dispatch(
                              updateBankAccount({
                                ...bankAccountData,
                                accountNumber: e.target.value,
                              })
                            )
                          }
                        />
                        {errors?.["accountNumber"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["accountNumber"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        <label>
                          Account Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Row>
                          <Col md={12} xs={24}>
                            <input
                              type="radio"
                              className="form-group"
                              style={{ width: "20%" }}
                              value={"checking"}
                              checked={accountType === "checking"}
                              onChange={(e) => onAccountChange(e)}
                            />
                            <span style={{ fontSize: "13px" }}>Checking</span>
                          </Col>
                          <Col md={12} xs={24}>
                            <input
                              type="radio"
                              className="form-group"
                              style={{ width: "20%" }}
                              value={"savings"}
                              checked={accountType === "savings"}
                              onChange={(e) => onAccountChange(e)}
                            />
                            <span style={{ fontSize: "13px" }}>Savings</span>
                          </Col>
                          {errors?.["accountType"] ? (
                            <label className="new_err_msg">
                              <i className="las la-info-circle" />
                              {errors?.["accountType"]}
                            </label>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                      <div className="fitem half-fitem">
                        <label>
                          Bank Holder Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <Row>
                          <Col md={12} xs={24}>
                            <input
                              type="radio"
                              className="form-group"
                              style={{ width: "20%" }}
                              value={"personal"}
                              checked={bankHolderType === "personal"}
                              onChange={(e) => onBankHolderChange(e)}
                            />
                            <span style={{ fontSize: "13px" }}>Personal</span>
                          </Col>
                          <Col md={12} xs={24}>
                            <input
                              type="radio"
                              className="form-group"
                              style={{ width: "20%" }}
                              value={"business"}
                              checked={bankHolderType === "business"}
                              onChange={(e) => onBankHolderChange(e)}
                            />
                            <span style={{ fontSize: "13px" }}>Business</span>
                          </Col>
                          {errors?.["bankHolderType"] ? (
                            <label className="new_err_msg">
                              <i className="las la-info-circle" />
                              {errors?.["bankHolderType"]}
                            </label>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </div>
                    <div
                      className="checkbox_wrapper"
                      style={{
                        justifyContent: "flex-start",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        id={"checked_permissions"}
                        type="checkbox"
                        checked={checked_permissions}
                        style={{ marginRight: "10px" }}
                        onChange={(e) => {
                          setchecked_permissions(e.target.checked);
                          dispatch(updateChargePermission(e.target.checked));
                        }}
                      />
                      <p style={{ fontSize: "11px", marginBottom: "0px" }}>
                        By selecting this box I grant permission for America's
                        Swimming Pool Co to charge my bank account on a monthly
                        basis.
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="payment-section" id="credit-card">
              <h3 className="payment-title-section">
                <input
                  type="radio"
                  name="credit-card-check"
                  className="mr-10"
                  checked={displayCC}
                  disabled={isDisable}
                  onClick={() => onBankCCChange("creditCard")}
                />
                Credit Card
                <img
                  src={AcceptedCards}
                  className={`payment-icon ${
                    isMobile ? "cc-img-mobile" : "cc-img"
                  } `}
                  alt="Accepted cards"
                ></img>
              </h3>
              {displayCC ? (
                <React.Fragment>
                  <hr />
                  <div className="payment-content-section form-group">
                    <div className="fitem">
                      <label>
                        Credit Card Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        id="card-number"
                        className="form--control"
                        style={{ paddingTop: "2px" }}
                      ></div>
                      {errors?.["ccNumber"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["ccNumber"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="fitem half-fitem">
                      <div className="fitem half-fitem">
                        {isMobile ? (
                          <label>
                            Exp Month <span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          <label>
                            Expiration Month{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        )}
                        <input
                          inputMode="numeric"
                          className="form--control"
                          placeholder="MM"
                          maxLength={2}
                          style={{ width: "100%" }}
                          value={creditCardData.expirationMonth}
                          onPaste={(event) => {
                            if (
                              event.clipboardData.getData("Text").match(/[^\d]/)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onKeyPress={(e) => {
                            const pattern = /[0-9]/;
                            const inputChar = String.fromCharCode(e.charCode);
                            if (!pattern.test(inputChar)) {
                              // invalid character, prevent input
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            dispatch(
                              updateCreditCard({
                                ...creditCardData,
                                expirationMonth: e.target.value,
                              })
                            );
                          }}
                        />
                        {errors?.["expirationMonth"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["expirationMonth"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="fitem half-fitem">
                        {isMobile ? (
                          <label>
                            Exp Year <span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          <label>
                            Expiration Year{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                        )}
                        <input
                          type="text"
                          className="form--control"
                          placeholder="YYYY"
                          maxLength={4}
                          style={{ width: "100%" }}
                          value={creditCardData.expirationYear}
                          onPaste={(event) => {
                            if (
                              event.clipboardData.getData("Text").match(/[^\d]/)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onKeyPress={(e) => {
                            const pattern = /[0-9]/;
                            const inputChar = String.fromCharCode(e.charCode);
                            if (!pattern.test(inputChar)) {
                              // invalid character, prevent input
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            dispatch(
                              updateCreditCard({
                                ...creditCardData,
                                expirationYear: e.target.value,
                              })
                            )
                          }
                        />
                        {errors?.["expirationYear"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["expirationYear"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="fitem half-fitem">
                        <label>
                          CVV <span style={{ color: "red" }}>*</span>
                        </label>
                        <div
                          id="card-cvv"
                          className="form--control"
                          style={{ paddingTop: "2px" }}
                        ></div>
                        {errors?.["cvv"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["cvv"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="checkbox_wrapper"
                      style={{
                        justifyContent: "flex-start",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        id={"checked_permissions"}
                        type="checkbox"
                        checked={checked_permissions}
                        style={{ marginRight: "10px" }}
                        onChange={(e) => {
                          setchecked_permissions(e.target.checked);
                          dispatch(updateChargePermission(e.target.checked));
                        }}
                      />
                      <p style={{ fontSize: "11px", marginBottom: "0px" }}>
                        By selecting this box I grant permission for America's
                        Swimming Pool Co to charge my credit card on a monthly
                        basis.
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="form-group mb-15">
              <h3 className="payment-title">Billing Address</h3>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>
                    First Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-group"
                    style={{ width: "100%" }}
                    value={billingAddress.firstName}
                    placeholder="Fisrt Name"
                    disabled={isDisable}
                    onChange={(e) =>
                      dispatch(
                        updateBillingAddress({
                          ...billingAddress,
                          firstName: e.target.value,
                        })
                      )
                    }
                  />
                  {errors?.["firstName"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["firstName"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem half-fitem">
                  <label>
                    Last Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-group"
                    style={{ width: "100%" }}
                    value={billingAddress.lastName}
                    placeholder="Last Name"
                    disabled={isDisable}
                    onChange={(e) =>
                      dispatch(
                        updateBillingAddress({
                          ...billingAddress,
                          lastName: e.target.value,
                        })
                      )
                    }
                  />
                  {errors?.["lastName"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["lastName"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>
                    Billing Address <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-group"
                    style={{ width: "100%" }}
                    value={billingAddress.address}
                    placeholder="Billing Address"
                    disabled={isDisable}
                    onChange={(e) =>
                      dispatch(
                        updateBillingAddress({
                          ...billingAddress,
                          address: e.target.value,
                        })
                      )
                    }
                  />
                  {errors?.["address"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["address"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem half-fitem">
                  <label>
                    State <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select
                    showArrow={true}
                    name="state"
                    placeholder="State"
                    virtual={false}
                    value={billingAddress.state || undefined}
                    disabled={isDisable}
                    onChange={(e) => {
                      dispatch(
                        updateBillingAddress({
                          ...billingAddress,
                          state: e,
                        })
                      );
                    }}
                  >
                    {renderAllState()}
                  </Select>
                  {errors?.["state"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["state"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <label>
                    Zip Code <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-group"
                    style={{ width: "100%" }}
                    value={billingAddress.zipCode}
                    placeholder="Zip Code"
                    disabled={isDisable}
                    onChange={(e) =>
                      dispatch(
                        updateBillingAddress({
                          ...billingAddress,
                          zipCode: e.target.value,
                        })
                      )
                    }
                  />
                  {errors?.["zipCode"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["zipCode"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <div className="fitem half-fitem">
                  <label>
                    City <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-group"
                    style={{ width: "100%" }}
                    value={billingAddress.city}
                    placeholder="City"
                    disabled={isDisable}
                    onChange={(e) =>
                      dispatch(
                        updateBillingAddress({
                          ...billingAddress,
                          city: e.target.value,
                        })
                      )
                    }
                  />
                  {errors?.["city"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["city"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-group mb-15">
              <div className="fitem half-fitem">
                <div className="fitem half-fitem">
                  <button
                    className={`btn ${
                      isDisable ? "btn-disabled" : "btn btn-warning"
                    }`}
                    style={{ width: "100%" }}
                    onClick={() => onValidate()}
                    disabled={isDisable}
                  >
                    Make a Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <section className={""}>{setPaymentContent()}</section>
        </div>
      }
    </Provider>
  );
};

export default PaymentContainer;
