import React from "react";
import { useParams } from "react-router-dom";
import TerritorySidebar from "./Territory.Sidebar";
import ChemicalSidebar from "./Chemical.Sidebar";
import InvoiceReconciliationSidebar from "./InvoiceReconciliation.Sidebar";
import ChemicalReportPricingSidebar from "./ChemicalReportPricing.Sidebar"
import AgingSummarySidebar from "./AgingSummary.Sidebar";
import LeadCustomerReportSidebar from "./LeadCustomerReport.Sidebar";
import TechnicianNotes from "./TechnicianNotes.Sidebar";

const Sidebar = () => {
  const { id } = useParams();

  const selectSidebar = (id) => {
    switch (id) {
      case "in_territory_revenue_reports": {
        return <TerritorySidebar />;
      }
      case "chemical_reports": {
        return <ChemicalSidebar />;
      }
      case "invoice_reconciliation_reports": {
        return <InvoiceReconciliationSidebar />;
      }
      case "aging_summary_reports": {
        return <AgingSummarySidebar />;
      }
      case "chemical_report_pricing": {
        return <ChemicalReportPricingSidebar />;
      }
      case "captured_lead_reports":{
        return <LeadCustomerReportSidebar />;
      }
      case "tech_notes":{
        return <TechnicianNotes />;
      }
      default: {
        return;
      }
    }
  };
  return <>{selectSidebar(id)}</>;
};

export default Sidebar;
