import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment-timezone";
import { map, orderBy } from "lodash";
import CustomFrequency from "../../../../Common/CustomFrequency";
import SideBar from "./SideBar";
import Loader from "../../../../../Components/Loader";
import { initialCleaningDays } from "../../../../../Action/Franchise";
import * as yup from "yup";
import { Select, ConfigProvider, Spin, Empty, TimePicker, Row, Col } from "antd";
import AddressField from "../../../../../Components/AddressField";
import {
  getPoolCharacteristics,
  getServiceMatrix,
  getServicesAddress
} from "../../../../../Action/Customer/index";
import { getFieldOfficeStaff } from "../../../../../Action/BulkReassign/index"
import { Input, DatePicker } from "antd";
import AddDurationField from "../../../../../Calendar/Components/Modals/SubComponents/AddDurationField";
import AddReferenceField from "../../../../../Calendar/Components/Modals/SubComponents/AddReferenceField";
import { useSelector } from "react-redux/es/hooks/useSelector";
const ServiceInfo = (props) => {
  const fieldTechnicianList = useSelector((state) => state.fieldTechnicianListSlice);
  const [state, setState] = useState({
    frequency: {},    
    errors: {},
    loader: false,    
  });
  const [service, setService] = useState("");
  const [serviceTypeTitle, setServiceTypeTitle] = useState("");
  const [services, setServices] = useState([]);
  const [workSubType, setWorkSubType] = useState("");
  const [workSubTypeList, setWorkSubTypeList] = useState([]);
  const [serviceType, setServiceType] = useState("");
  const [poolType, setPoolType] = useState([]);
  const [poolSize,setPoolSize] = useState(0);  
  const [frequency,setFrequency] = useState({});
  const [customFrequency, setcustomFrequency] = useState(false);
  const [serviceTypeName, setServiceTypeName] = useState("");
  const [otherPoolType,setOtherPoolType] = useState('');
  const [serviceInfo, setServiceInfo] = useState({});
  const [priceDetail, setPriceDetail] = useState({})
  const [maintenanceCategories, setMaintenanceCategories] = useState([]);
  const [maintenanceCategory,setMaintenanceCategory] = useState('');
  const [isRenovationOrConstruction, setIsRenovationOrConstruction] = useState(false);
  const [notes, setNotes] = useState('');
  const [isAgreed, setIsAgreed] = useState(true);
  const [loader, setLoader] = useState(false);
  const [preferredStartDate, setPreferredStartDate] = useState("");
  const [displayPreferredStartDate, setDisplayPreferredStartDate] = useState("");
  const [data, setData] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [timeRange, setTimeRange] = useState("");
  const [poolTypes, setpoolTypes] = useState([]);
  const [pool_typesUUID, setPoolTypesUUID] = useState();
  const [disablePoolType, setDisablePoolType] = useState("");

  const [serviceAddress, setServiceAddress] = useState('New');
  const [pool, setPool] = useState('New');
  const [poolOption, setPoolOption] = useState([]);
  const [poolName, setPoolName] = useState("");
  const [addressOption, setAddressOption] = useState([]);
  const [poolDetails, setPoolDetails] = useState([]);
  const [addressDetails, setAddressDetails] = useState([]);
  const [temp_address, settemp_Address] = useState({ uuid: "New", address: '', city: '', state: '', zip_code: '', address2: '' });
  const [address, setAddress] = useState({ address: '', city: '', state: '', zip_code: '', address2: '' });

   //Preferred Technician
  const [preferredTech, setPreferredTech] = useState('')
  const [techOptions, setTechOptions] = useState([]);
  const [selectLoading, setSelectLoading] = useState(false);
  const [time, setTime] = useState(moment("8:00", "HH:mm"));
  const [isRecurring, setisRecurring] = useState(true);
  
  const time_range = [
    { key: "AM (8:00 to 12:00)", value: "am" },
    { key: "PM (1:00 to 5:00)", value: "pm" },
    { key: "Flexible", value: "flexible" },
  ];
 
  // pre papulate data like pool size and pool type in new service order
  const [pools, setPools] = useState([]);
  const [selectedPool, setSelectedPool] = useState("");

  const { Option } = Select;

  const currentServiceType = useRef('');

  const getPools = useCallback(async () =>{
    const poolResults = await getPoolCharacteristics(props.customerUUID);
    setPools(poolResults ? poolResults : []);
    const filteredPoolResult = [...poolResults].filter(
      (item) => item.pool_name !== null
    );
    if (filteredPoolResult.length === 1) {
      const address = filteredPoolResult[0]?.service_address;
      if (!address?.uuid) {
        return;
      }
      settemp_Address({
        uuid: address.uuid,
        address: address.address,
        city: address.city,
        state: address.state,
        zip_code: address.zip_code,
        address2: address.address2,
      });
    }
  }, [setPools])

  const handleAddressChange = (value) => {
    setAddress(value);
  }
  const onAddressChange = (e) => {
    const addr = { ...address };
    Object.assign(addr, { [e.target.name]: e.target.value })
    setAddress(addr);
  };

  useEffect(()=>{
    if(Object.keys(props.proposalData).length !== 0) {
      let listofServiceTypes = props.serviceTypeData?.service_matrix?.servicetypes
      let proposalSelectedService = ''
      let proposalServiceName = ''
      listofServiceTypes.forEach((item) => {
          if (item.name === props.proposalData.service_type){
            proposalSelectedService = item.uuid
            proposalServiceName = item.name
          }
      })
      //setServiceType(proposalSelectedService)
      onTextChange({
        target: {
          name: "serviceType",
          value: proposalSelectedService,
          key: proposalServiceName,
        },
      })
      currentServiceType.current = proposalServiceName
      /* setServiceTypeName(proposalServiceName);
      debugger; 
      if(proposalServiceName === 'renovation' || proposalServiceName === 'construction'){
        setIsRenovationOrConstruction(true)
      } */
      
      if(props.proposalData.is_flat_rate){
        setMaintenanceCategory('Flat_Rate')
      }
      else{
        setMaintenanceCategory('Standard')
      }
      setPoolName(props.proposalData.pool_name)
      setPoolSize(props.proposalData.pool_size)
    }
    getPoolTypes();
    getTechnicians()
    
    const temp_pool = [<Option key='New'>New</Option>];    
    setPoolOption(temp_pool);
    
    getServicesAddressList();
    const getInitialCleanningDays = async () => {
      const data = await initialCleaningDays({
        zip_code: props?.serviceTypeData?.field_office_address?.zip_code,
      });
      if (data?.data.length) {
        setData(data?.data);
      } else {
        let startdate = moment().format("MM-DD-YYYY");
        let new_date = [];
        var new_date1 = moment(startdate, "MM-DD-YYYY").add("days", 1);
        var new_date2 = moment(startdate, "MM-DD-YYYY").add("days", 2);
        var new_date3 = moment(startdate, "MM-DD-YYYY").add("days", 3);
        new_date.push(moment(startdate, "MM-DD-YYYY"))
        new_date.push(new_date1);
        new_date.push(new_date2);
        new_date.push(new_date3);
        new_date.sort(function(a, b) { return new Date(a) - new Date(b) });
        setData(new_date);
      }
      setLoader(false);
    };    
    getInitialCleanningDays();
    setIsAgreed(props.serviceInfo?.isAgreed?.value ?? true); 
    setPreferredStartDate(props.serviceInfo?.preferredDate?.value ?? ''); 
   },[])

   useEffect(() => {
    if(temp_address?.uuid)
      reloadPools();
    else    
      setPoolOption([<Option key='New'>New</Option>])    
  }, [temp_address])

  const getServicesAddressList = async () => {
    const temp_address = [<Option key='New'>New</Option>];
    const data = await getServicesAddress(props?.customer?.uuid);
    if (data.length > 0) {      
      data.map(item => temp_address.push(<Option key={item.uuid}>{item.formatted_address}</Option>));
      if(Object.keys(props.proposalData).length !== 0) {
        temp_address.push(<Option key={props.proposalData.service_address.uuid}>{props.proposalData.service_address.formatted_address}</Option>)
      }
      setAddressOption(temp_address);
      if(Object.keys(props.proposalData).length !== 0) {
        settemp_Address({ uuid: props.proposalData.service_address.uuid, address: props.proposalData.service_address.address, city: props.proposalData.service_address.city, state: props.proposalData.service_address.state, zip_code: props.proposalData.service_address.zip_code, address2: props.proposalData.service_address.address2 });
      }
      
      setAddressDetails(data);
    } else {
      message.error("The customer does not have Services Addresses.");
    }
  }

   // on preferred date changed
  const onDataChange = (e) => {
    setPreferredStartDate(e.target.checked ? e.target.value : null);
    setDisplayPreferredStartDate(null);
    props.setServiceInfoData({
      value: e.target.value,
      key: true,
      name: "preferredDate",
    });
  };

  const onSelectDisplayPreferredStartDate = (e) => {
    setDisplayPreferredStartDate(e);
    setPreferredStartDate(moment(e).format("MM-DD-YYYY"));
    props.setServiceInfoData({
      value: moment(e).format("MM-DD-YYYY"),
      key: true,
      name: "preferredDate",
    });
  };

  const onTimeRangeChange = (e) => {
    setTimeRange(e.target.checked ? e.target.value : null);
  };

  // render extra footer
  const renderExtraFooter = () => {
    return (
      <div className="data_indicator">
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn"
        >
          Available
        </a>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
          }}
          className="btn unavailable"
        >
          Unavailable
        </a>
      </div>
    );
  };

  const getPoolTypes = async () => {    
    const data = await getServiceMatrix(props.customer.field_office.uuid);
    setpoolTypes(data.pool_types);
  }

  //Get technicians list
  const getTechnicians = async () => {
    setSelectLoading(true)
    const field_tech_obj = {
      field_office__uuid: props?.customer?.field_office?.uuid,
    };
    if (fieldTechnicianList?.fieldTechnicians?.length) {
      setTechOptions(fieldTechnicianList?.fieldTechnicians)   
    }else {
      const response = await getFieldOfficeStaff(field_tech_obj);
      setTechOptions(response.results);
    }
    setSelectLoading(false);
  }

  useEffect(() => { 
    if(!props.serviceInfo.baseJobReq){
      let service = props.serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) => item.name.toLowerCase() === "maintenance"
      )[0];    
      if(Object.keys(props.proposalData).length === 0) {
        setServiceType(service?.uuid);
      } 
      setServiceTypeName("maintenance");      
      setMaintenanceCategories(service?.worktypes);
      setcustomFrequency(true);   
      setIsRenovationOrConstruction(false);
      onTextChange({
        target: {
          name: "time",
          value: moment("8:00", "HH:mm"),
          key: "time",
        },
      });

      props.setServiceInfoData({ key: service?.name, name: "serviceType", value: service?.uuid })
    }else{      
      const data = props.serviceInfo;
      if(Object.keys(props.proposalData).length === 0) {
        setServiceType(data?.baseJobReq?.value?.serviceType ?? '');
      }       
      let service = props.serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) => item.uuid === data?.baseJobReq?.value?.serviceType
      )[0]; 
      
      if(service?.name?.toLowerCase() === "service")
      {        
        setcustomFrequency(true);

        setServices(service?.worktypes);        
        setService(data?.baseJobReq?.value?.service ?? '');    

        let subWork = service?.worktypes?.filter((item) => item.uuid === data?.baseJobReq?.value?.service)[0];       
        setWorkSubTypeList(subWork?.worksubtypes);
        setWorkSubType(data?.baseJobReq?.value?.workSubType ?? '');
      }

      if(service?.name?.toLowerCase() === "maintenance"){
        setcustomFrequency(true);
        setServices([]);
        setWorkSubTypeList([]);
        setFrequency(data?.baseJobReq?.value?.frequency ?? {});      
      }      

      if(service?.name?.toLowerCase() === "renovation" || service?.name?.toLowerCase() === "construction"){
        setIsRenovationOrConstruction(false);
        setcustomFrequency(false);
        setServices([]);
        setWorkSubTypeList([]);     
      }      
     
      setPoolType(data?.baseJobReq?.value?.poolType ?? '');
      setPoolSize(data?.baseJobReq?.value?.poolSize ?? 0);      
    }
    getPools();
  }, [getPools]);

  useEffect(() => {    
    props.setPriceDetailData(priceDetail);
  }, [priceDetail])

  const setServiceInfoData = (data) => {
    let obj = serviceInfo;
    obj[data.name] = data;
    setServiceInfo(obj)
  }

  const onTextChange = (e) => { 
    if (e.target.name === "serviceType") {
      setServiceType(e.target.value);
      let obj = props.serviceTypeData?.service_matrix?.servicetypes.filter(
        (item) => item.uuid === e.target.value
      )[0];
      if (obj?.name?.toLowerCase() === "service") {
        setServices(obj?.worktypes);
        setFrequency("");
        setWorkSubTypeList([]);
        setWorkSubType("");
        setServiceTypeName("service");
        setcustomFrequency(true);
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });
        setServiceInfoData({ frequency: "", price: 0, key: "" });
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setIsRenovationOrConstruction(false);
        setPreferredStartDate([]);
        setTimeRange([]);
        setMaintenanceCategories([]);
        // setIsAgreed(false);
        setMaintenanceCategory("");
        setNotes("");
        setDisplayPreferredStartDate("");
        setisRecurring(false);
        currentServiceType.current = "service"
      }
      if (obj?.name?.toLowerCase() === "maintenance") {
        setcustomFrequency(true);
        setServices([]);
        setWorkSubTypeList([]);
        setService("");
        setWorkSubType("");
        setFrequency("");
        setServiceTypeName("maintenance");
        setPreferredStartDate([]);
        setTimeRange([]);
        setMaintenanceCategories(obj?.worktypes);
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });        
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setIsRenovationOrConstruction(false);
        // setIsAgreed(false);
        setMaintenanceCategory("");
        setNotes("");
        setDisplayPreferredStartDate("");
        setisRecurring(true);
        currentServiceType.current = "maintenance"
      }
      
      if (obj?.name?.toLowerCase() === "renovation" || obj?.name?.toLowerCase() === "construction") {
        setcustomFrequency(false);
        setServices([]);
        setWorkSubTypeList([]);
        setService("");
        setWorkSubType("");
        setFrequency("");
        obj?.name?.toLowerCase() === "renovation" ? setServiceTypeName("renovation") : setServiceTypeName("construction");
        setIsRenovationOrConstruction(true);
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });        
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setPreferredStartDate([]);
        setTimeRange([]);
        setMaintenanceCategories([]);
        // setIsAgreed(false);
        setMaintenanceCategory("");
        setNotes("");
        setDisplayPreferredStartDate("");
        currentServiceType.current = obj?.name?.toLowerCase()
      }

      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "frequency") {
      setFrequency(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      
      if(serviceTypeName.toLocaleLowerCase() !== "service" ){
        props.setPriceDetailData({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        setPriceDetail({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
      }
    }
    if (e.target.name === "service") {
      setService(e.target.value);
      setServiceTypeTitle(
        e.target.key.charAt(0).toUpperCase() + e.target.key.slice(1)
      );
      setFrequency("");
      setWorkSubType("");
      let obj = services?.filter((item) => item.uuid === e.target.value)[0];
      setWorkSubTypeList(obj?.worksubtypes);
      if (obj?.worksubtypes.length === 0) {
        props.setServiceInfoData({ value: "", key: "", name: e.target.name });
        setServiceInfoData({ value: "", key: "", name: e.target.name });
        setPriceDetail({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        props.setPriceDetailData({
          frequency: e.target?.value,
          price: e.target?.price,
          key: e.target?.key,
        });
        if(obj?.is_recurring !== undefined && obj?.is_recurring){
          setisRecurring(true)
        }else{
          setisRecurring(false)
        }
      } else {
        props.setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        props.setPriceDetailData({ frequency: "", price: 0, key: "" });
        setPriceDetail({ frequency: "", price: 0, key: "" });
        setisRecurring(false)
      }
    }
    if (e.target.name === "worksubtype") {
      setWorkSubType(e.target.value);
      setFrequency("");
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      props.setPriceDetailData({
        frequency: e.target?.value,
        price: e.target?.price,
        key: e.target?.key,
      });
      setPriceDetail({
        frequency: e.target?.value,
        price: e.target?.price,
        key: e.target?.key,
      });
      const obj = services?.filter((item) => item.uuid === service)[0];
      const subWorkType = obj.worksubtypes.find(i => i.uuid === e.target?.value)
      if(subWorkType?.is_recurring !== undefined && subWorkType?.is_recurring){
        setisRecurring(true)
      }else{
        setisRecurring(false)
      }
    }
    if (e.target.name === "serviceAddress") {
      setServiceAddress(e.target.value);      
      if (e.target.value !== 'New') {
        let temp = addressDetails.find((each) => each?.uuid === e.target.value);        
        settemp_Address(temp);
      } else {
        settemp_Address({ uuid: 'New', address: '', city: '', state: '', zip_code: '', address2: '' });                        
      }
      onTextChange({ target: { name: 'pool', value: 'New' } })
    }
    if (e.target.name === "poolName") {
      setPoolName(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "poolSize") {
      const result = e.target.value.replace(/\D/g, "");
      setPoolSize(result);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if (e.target.name === "pool") {
      setPool(e.target.value);
      if (e.target.value !== 'New') {
        let temp = pools.filter((each) => each.pool_name === e.target.value);
        setPoolName(temp[0].pool_name);
        setPoolSize(temp[0].pool_size);
        setPoolType(temp[0].pool_type_uuid);
        setPoolTypesUUID(temp[0].pool_type_uuid);
        setDisablePoolType("disabled");
        props.setServiceInfoData({
          value: e.target.value,
          key: e.target.key,
          name: e.target.name,
        });
        props.setServiceInfoData({ key: service.name, name: "poolType", value: temp[0].pool_type_uuid })
        props.setServiceInfoData({ key: service.name, name: "poolSize", value: temp[0].pool_size ?? 0 })
      } else {
        setPoolName("");
        setPoolSize(0);
        setPoolType("");
        setPoolTypesUUID(""); 
        setDisablePoolType("");
        props.setServiceInfoData({ key: service.name, name: "poolType", value: "" })
        props.setServiceInfoData({ key: service.name, name: "poolSize", value: 0 })
      }
    }
    if (e.target.name === "poolType") {
      setPoolType(e.target.value);
      setPoolTypesUUID(e.target.value);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
      setOtherPoolType("");
      props.setServiceInfoData({
        value: null,
        key: "otherPoolType",
        name: "otherPoolType",
      });
      setServiceInfoData({
        value: null,
        key: "otherPoolType",
        name: "otherPoolType",
      });
    }
    if (e.target.name === "poolSize") {
      const result = e.target.value.replace(/\D/g, "");
      //setPoolSize(result);
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
    if(e.target.name === 'maintenanceCategory'){      
      setMaintenanceCategory(e.target.value);
      props.setServiceInfoData({value:e.target.value,key:e.target.key,name:e.target.name});
    }    
    if(e.target.name === 'notes'){
      setNotes(e.target.value);
      props.setServiceInfoData({value:e.target.value,key:e.target.key,name:e.target.name});
    }
    if (e.target.name === "isAgreed") {
      setIsAgreed(e.target.checked);
      props.setServiceInfoData({value:e.target.value,key:e.target.key,name:e.target.name});
    }
    if (e.target.name === "time") {
      setTime(e.target.value);
      
      props.setServiceInfoData({
        value: e.target.value,
        key: e.target.key,
        name: e.target.name,
      });
    }
  };

  const reloadPools = async () => {
    setState((s) => ({ ...s, loader: true }));
    const temp_pool = [<Option key='New'>New</Option>];
    const data = await getPoolCharacteristics(props?.customerUUID);
    if (data.length > 0) {
      let pool_list = data.filter(i => i.service_address?.uuid === temp_address?.uuid)
      pool_list.map((item) => {
        if(item.pool_name && item.pool_type_uuid){
          temp_pool.push(
            <Option key={item.pool_name} value={item.pool_name}></Option>
          )
        }
      });
      if(Object.keys(props.proposalData).length !== 0) {
        temp_pool.push(
          <Option key={props.proposalData.pool_name} value={props.proposalData.pool_name}></Option>
        )
      }
      setPoolOption(temp_pool);
      if (pool_list.length > 0) {
        setPool(pool_list[0]?.pool_name);
        setPoolName(pool_list[0]?.pool_name);
        setPoolType(pool_list[0]?.pool_type_uuid ?? "");
        setPoolSize(pool_list[0]?.pool_size ?? 0);
        setPoolTypesUUID(pool_list[0]?.pool_type_uuid ?? "");
      }
      if(Object.keys(props.proposalData).length !== 0) {
        setPool(props.proposalData.pool_name)
        setPoolType(props.proposalData.pool_type_uuid)
        setPoolTypesUUID(props.proposalData.pool_type_uuid)
      }
    }

    setState((s) => ({ ...s, loader:false }));
  }

  // validate required field
  const validate = (status) => {
    const schema = yup.object().shape({
      maintenanceCategory: serviceTypeName==='maintenance' ? yup.string().required('Rate Type Is Required.') : null,
      frequency: serviceTypeName==='maintenance' || serviceTypeName==='service' ?
        yup.object().shape({
          start: yup.string().required('Frequency Is Required.')            
        }).nullable(false)
        :null,
      serviceType: yup.string().required('Service Type Is Required.'),
      preferredStartDate: serviceTypeName !=='maintenance' && serviceTypeName !=='service' ? yup.string().required('Preferred Start Date is required.'): null,
      // timeRange: serviceTypeName==='service' ? yup.string().required('Time Range is required.'): null,
      // service_preferredStartDate: serviceTypeName==='service' ? yup.string().required('Preferred Start Date is required.'): null,
      // serivce_timeRange: serviceTypeName==='service' ? yup.string().required('Time Range is required.'): null,
      // construction_preferredStartDate: serviceTypeName==='construction' ? yup.string().required('Preferred Start Date is required.'):null,
      //poolType:yup.string().required('Pool Type Is Required.'),
      poolType: !isRenovationOrConstruction ? yup.string().required('Pool Type Is Required.') : '',
      poolName: yup.string().required('Pool Name Is Required.'),
      serviceAddress: yup.string().required("Address is required"),
      address: yup.object().shape({
        address: yup.string().required("Address is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
        zip_code: yup.string().required("Zipcode is required")
      }),
      isAgreed: yup.bool().oneOf([true], "Field must be checked"),
      otherPoolType: poolType==='-1' && (otherPoolType === undefined || otherPoolType === '') ? yup.string().required('Please enter the Other Pool Type.') : null,
      service: serviceTypeName==='service'?yup.string().required('Service Is Required.'):null,
      workSubType: serviceTypeName==='service' && workSubTypeList.length>0?yup.string().required('Work Sub Type Is Required.'):null,    
    });

    const data = {
      frequency,
      maintenanceCategory,
      serviceType,
      preferredStartDate,
      timeRange,
      service,
      workSubType,
      selectedPool,
      isAgreed,
      preferredTech,
      poolType,
      poolSize,
      poolName,
      pool,
      serviceAddress,
      address,
    };

    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        props.setServiceInfoData({value:data,key:true,name:'baseJobReq'})
        
        setState((s) => ({ ...s, errors: {} }));   
        props.onSave(status);
      })
      .catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, loader:false, errors: err_data }));
        console.log(err_data);
      });
  };

  const onCancel = () => {
    props.onChangeView(0);
  };

  const render = () => {
    const {
      errors,
      loader,
    } = state;

    const renderPreferredTech = () => {
      return (
        <div className="searchable">
          <ConfigProvider
            renderEmpty={() =>
              selectLoading ? (
                <div className="searchableCustomerNameSpin">
                  <Spin tip="Loading Technicians..." />
                </div>
              ) : (
                <Empty />
              )
            }
          >
          <Select
            placeholder="Technician Name"
            showSearch
            allowClear={true}
            value={preferredTech || undefined}
            onChange={onTechChange}
            suffixIcon={null}
            showArrow
            filterOption = {(input, option) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            {orderBy(techOptions, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc")?.map((option) => (
              <Option
                key={option?.user.uuid}
                value={option?.user.uuid}
                label={`${option?.user?.first_name} ${option?.user?.last_name}`}
              >
                {`${option?.user?.first_name} ${option?.user?.last_name}`}
              </Option>
            ))}
          </Select>
          </ConfigProvider>
        </div>
      );
    };
    
  const onTechChange = (e) => {
    if (e) {
      setPreferredTech(e);
    } else {
      setPreferredTech("");
    }
  };

    return (
      <div className="container">
        {loader && <Loader />}
        <div className="fluid-block-add-service">
          <div className="article-block" style={{ width: "75%" }}>
            <div style={{ padding: "30px" }}>
              <h3>Base Job Requirements</h3>
              <div className="form-block">
                <div className="frow">
                  <h4>Service type</h4>
                  <div className="oplist">
                    {map(
                      props.serviceTypeData?.service_matrix?.servicetypes,
                      (item, index) => (
                        <div
                          className="inline-checks"
                          key={`serviceType${index}`}
                        >
                          <label
                            htmlFor={item?.uuid}
                            className={`${
                              serviceType === item?.uuid ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id={item?.uuid}
                              name="serviceType"
                              className=""
                              value={item?.uuid}
                              onChange={() =>
                                onTextChange({
                                  target: {
                                    name: "serviceType",
                                    value: item?.uuid,
                                    key: item?.name,
                                  },
                                })
                              }
                            />
                            <span>{item?.name}</span>
                          </label>
                        </div>
                      )
                    )}
                  </div>
                  {errors?.["serviceType"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["serviceType"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                {
                  //Add Maintenance Category
                  customFrequency ? <div className="frow">
                    <h4>Select Rate Type<sup>*</sup></h4>
                   {currentServiceType.current === 'maintenance' || currentServiceType.current === '' ? <div className="oplist">
                      {map(maintenanceCategories,(item,index)=> <div className="inline-checks" key={`category${index}`}>
                        <label htmlFor={item.id} className={`${maintenanceCategory === item.name ? 'active' : ''}`}>
                          <input type="radio" id={item?.id} name="maintenanceCategory"  className="" value={item.uuid} onChange={() => onTextChange({target: {name: 'maintenanceCategory', value: item.name, price: 0,key:item.name }})}/>
                          <div className='form-group'>
                            <div className='fitem'>
                              <span>{item.name}</span><br/>
                              <span style={{'fontSize': '12px'}}>({item.additional_text})</span>
                            </div>
                          </div>                    
                       </label>
                    </div>)}
                  </div> : null}
                  {errors?.["maintenanceCategory"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.["maintenanceCategory"]}
                    </label>
                  ) : (
                    ""
                  )}
                  </div>: null
                }                
                {
                  (isRenovationOrConstruction || currentServiceType.current === 'renovation' || currentServiceType.current === 'construction') ? <div className="frow">
                    <label>Notes: Please provide customer's request</label>
                    <textarea
                      placeholder="Customer proposal"
                      name="message"
                      rows="5"
                      value={notes}
                      onChange={(e) => {                        
                        onTextChange({
                          target: {
                            name: "notes",
                            value: e.target.value,
                            key: "notes"
                          },
                        })
                      }}></textarea>
                  </div>
                  :''
                }
                {(props.serviceInfo?.serviceType?.key?.toLowerCase() !== "maintenance" && props.serviceInfo?.serviceType?.key?.toLowerCase() !== "service") ? (
                <>
                <div className="frow">
                  <h4>Preferred Start Date</h4>
                  <div className="oplist">
                    {map(data?.sort(function(a, b) { return new Date(a) - new Date(b) })?.slice(0, 4), (item, i) => {
                      return (
                        <div
                          className="inline-checks flex-1"
                          key={`preferred_start_dates${i}`}
                        >
                          <label
                            htmlFor={item?.value}
                            className={
                              preferredStartDate ===
                              moment(item).format("MM-DD-YYYY")
                                ? "active"
                                : ""
                            }
                          >
                            <input
                              type="radio"
                              id={item?.value}
                              name="preferredStartDate"
                              checked={
                                preferredStartDate ===
                                moment(item).format("MM-DD-YYYY")
                              }
                              value={moment(item).format("MM-DD-YYYY")}
                              className=""
                              onChange={onDataChange}
                            />
                            <span>
                              {item.format("MM-DD-YYYY") === moment().format("MM-DD-YYYY") ? "Today" : moment(item, "MM-DD-YYYY").format("ddd, MMM DD")}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                    <div className="inline-checks flex-1">
                      <DatePicker
                        renderExtraFooter={(e) => renderExtraFooter}
                        inputReadOnly
                        mode={"date"}
                        className={
                          displayPreferredStartDate ? "active" : "box_opacity"
                        }
                        placeholder={"Select another date"}
                        format={"ddd, MMM DD"}
                        value={displayPreferredStartDate}
                        onChange={onSelectDisplayPreferredStartDate}
                        disabledDate={(current) => {
                          return current && current <= moment().add("days", 3);
                        }}
                      />
                    </div>
                  </div>
                  {errors?.['preferredStartDate'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['preferredStartDate']}</label> : ''}
                </div>
                <AddReferenceField />
                <div className="frow md-label">
                  <h4>Enter Service Address and Pool Name</h4>
                  <br />
                  <div>
                    <div className="form-group mb-20">
                      <div className="half-fitem fitem">
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Select Service Address
                          </label>                          
                          <Select
                            showArrow={true}
                            style={{ width: '100%' }}
                            name="serviceAddress"
                            placeholder="Select"
                            virtual={false}
                            value={temp_address?.uuid}
                            onChange={(value) => onTextChange({ target: { name: 'serviceAddress', value: value } })}
                          >
                            {addressOption}
                          </Select>
                          {errors?.[`serviceAddress`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`serviceAddress`]}</label> : ''}
                        </div>
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Select Pool
                          </label>
                          <Select
                            showArrow={true}
                            style={{ width: '100%' }}
                            name="pool"
                            placeholder="Select"
                            virtual={false}
                            value={pool}
                            onChange={(value) => onTextChange({ target: { name: 'pool', value: value } })}
                          >
                            {poolOption}
                          </Select>
                          {errors?.[`pool`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`pool`]}</label> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-20">
                      <div className="half-fitem fitem">
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Pool Name <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            placeholder="Pool Name"
                            name="poolName"
                            value={poolName}
                            style={{ width: '100%' }}
                            onChange={onTextChange}
                          />
                          {errors?.[`poolName`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`poolName`]}</label> : ''}
                        </div>
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Pool size in Gallons
                          </label>
                          <input
                            type="text"
                            id="poolSize"
                            style={{ width: '100%' }}
                            name="poolSize"
                            placeholder="20000"
                            value={poolSize}
                            onChange={onTextChange}
                          />
                          {errors?.[`poolSize`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`poolSize`]}</label> : ''}
                        </div>
                      </div>
                    </div>
                    <label style={{ fontWeight: 'bold' }}>
                      Address <sup>*</sup>
                    </label>
                    <AddressField handleAddressChange={handleAddressChange} onAddressChanged={onAddressChange} onAddressChange={onAddressChange} errors={errors} data={temp_address} nolabel />
                  </div>
                  </div>
                <div style={{paddingTop: '10px'}}>
                  <label style={{ fontWeight: 'bold' }}>
                    Preferred Technician
                  </label>
                  {renderPreferredTech()}
                </div>
                <hr style={{ margin: "20px 0" }} />
                 <div className="frow" style={{margin: "0px"}}>
                 <h4> Terms of Service</h4>
                 <span>
                   By signing up, I grant my permission for America's Swimming
                   Pool Co. to access my property and agree to the full
                   <a> Terms of Service</a> and
                   <a href="/#"> Privacy Policy.</a>
                 </span>
                 <div className="fitem-check mt-10">
                   <input
                     id="isAgreed"
                     name="isAgreed"
                     checked={isAgreed}
                     onChange={(e) => onTextChange(e, "bool")}
                     type="checkbox"
                     className="fitem-ck-input"
                   />
                   <label htmlFor="isAgreed" className="fitem-ck-txt">
                     Customer verbally agreed
                   </label>
                 </div>
                 {errors?.["isAgreed"] ? (
                   <label className="new_err_msg">
                     <i className="las la-info-circle" />
                     {errors?.["isAgreed"]}
                   </label>
                 ) : (
                   ""
                 )}
               </div>
               </>
              ) : null}
                {services.length > 0 ? (
                  <div className="frow">
                    <h4>What service you are looking for?</h4>
                    <div className="oplist">
                      {map(services, (item, index) => (
                        <div className="inline-checks" key={`service${index}`}>
                          <label
                            htmlFor={item?.uuid}
                            className={`${
                              service === item?.uuid ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id={item?.uuid}
                              name="service"
                              className=""
                              value={item?.uuid}
                              onChange={() =>
                                onTextChange({
                                  target: {
                                    name: "service",
                                    value: item?.uuid,
                                    price: item?.price,
                                    key: item?.name,
                                  },
                                })
                              }
                            />
                            <span>{item?.name}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                    {errors?.[`service`] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.[`service`]}
                      </label>
                    ) : (
                      ""
                    )}
                    {workSubTypeList.length > 0 ? (
                      <div className="frow">
                        <h4>{serviceTypeTitle} Categories</h4>
                        <div className="oplist">
                          {map(workSubTypeList, (item, index) => (
                            <div
                              className="inline-checks"
                              key={`service${index}`}
                            >
                              <label
                                htmlFor={item?.uuid}
                                className={`${
                                  workSubType === item?.uuid ? "active" : ""
                                }`}
                              >
                                <input
                                  type="radio"
                                  id={item?.uuid}
                                  title={item.additional_text}
                                  name="worksubtype"
                                  className=""
                                  value={item?.uuid}
                                  onChange={() =>
                                    onTextChange({
                                      target: {
                                        name: "worksubtype",
                                        value: item?.uuid,
                                        price: item?.price,
                                        key: item?.name,
                                      },
                                    })
                                  }
                                />
                                <span>{item?.name}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {errors?.[`workSubType`] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle" />
                        {errors?.[`workSubType`]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null
                }
                {customFrequency ? (
                  <div>
                    {serviceTypeName === "maintenance" ? (
                      <h4>How often do you need your pool cleaned?</h4>
                    ) : (
                      <h4> How often do you need your service?</h4>
                    )}
                    <CustomFrequency
                      data={frequency}
                      onTextChange={onTextChange}
                      is_recurring={isRecurring}
                      recurring_base_prices={
                        props.serviceTypeData?.service_matrix?.recurring_base_prices
                      }
                    />
                    {errors?.[`frequency.start`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`frequency.start`]}
                    </label>
                  ) : (
                    ""
                  )}
                  </div>
                  

                ) : ''                                
                }
                {customFrequency ? (
                <>
                <Row>
                  <div className="mb-10" style={{ width: "50%" }}>
                  <h4>Select Time</h4>
                  <TimePicker
                    className=""
                    placeholder={"--:-- AM/PM"}
                    value={time}
                    onChange={(e) => {
                      onTextChange({
                        target: {
                          name: "time",
                          value: e,
                          key: "time",
                        },
                      });
                    }}
                    allowClear={false}
                    format={"h:mm A"}
                  />
                </div>
                <Col span={1}></Col>
                <AddDurationField durationType="service" />
                  </Row>
                {errors?.[`time`] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.[`time`]}
                  </label>
                ) : (
                  ""
                )}
                <Row>
                  <AddReferenceField />
                </Row>
                </>
                ) : null}
                {
                  !isRenovationOrConstruction ?
                <>
                <div className="frow md-label">
                  <h4>Enter Service Address and Pool Name</h4>
                  <br />
                  <div>
                    <div className="form-group mb-20">
                      <div className="half-fitem fitem">
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Select Service Address
                          </label>
                          <Select
                            showArrow={true}
                            style={{ width: '100%' }}
                            name="serviceAddress"
                            placeholder="Select"
                            virtual={false}
                            value={temp_address?.uuid}
                            onChange={(value) => onTextChange({ target: { name: 'serviceAddress', value: value } })}
                          >
                            {addressOption}
                          </Select>
                          {errors?.[`serviceAddress`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`serviceAddress`]}</label> : ''}
                        </div>
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Select Pool
                          </label>
                          <Select
                            showArrow={true}
                            style={{ width: '100%' }}
                            name="pool"
                            placeholder="Select"
                            virtual={false}
                            value={pool}
                            onChange={(value) => onTextChange({ target: { name: 'pool', value: value } })}
                          >
                            {poolOption}
                          </Select>
                          {errors?.[`pool`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`pool`]}</label> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-20">
                      <div className="half-fitem fitem">
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Pool Name <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            placeholder="Pool Name"
                            name="poolName"
                            value={poolName}
                            style={{ width: '100%' }}
                            onChange={onTextChange}
                          />
                          {errors?.[`poolName`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`poolName`]}</label> : ''}
                        </div>
                        <div className="half-fitem fitem">
                          <label style={{ fontWeight: 'bold' }}>
                            Pool size in Gallons
                          </label>
                          <input
                            type="text"
                            id="poolSize"
                            style={{ width: '100%' }}
                            name="poolSize"
                            placeholder="20000"
                            value={poolSize}
                            onChange={onTextChange}
                          />
                          {errors?.[`poolSize`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`poolSize`]}</label> : ''}
                        </div>
                      </div>
                    </div>
                  <div className="frow">
                  <h4>Pool type</h4>
                  <div className="oplist">
                  
                    {map(
                      props.serviceTypeData?.service_matrix?.pool_types,
                      (item, index) => (
                        <div className="inline-checks" key={`poolType${index}`}>
                         <label
                            htmlFor={item?.uuid}
                            className={`${pool_typesUUID} ${
                              pool_typesUUID === item?.uuid ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              id={item?.uuid}
                              name="poolType"
                              className=""
                              value={item?.uuid}
                              onChange={() =>
                                onTextChange({
                                  target: {
                                    name: "poolType",
                                    value: item?.uuid,
                                    key: item?.name,
                                  },
                                })
                              }
                              disabled = {disablePoolType ? true : false}
                            />
                            <span>{item?.name}</span>
                           </label>
                           
                        </div>
                        
                        
                      )
                      
                    )}
                    {errors?.[`poolType`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`poolType`]}
                    </label>
                  ) : (
                    ""
                  )}
                    {poolType === "179a4155-9268-3633-ad49-0a7e14af60a2" && (
                      <div className="from-group w-100">
                        <div className="fitem">
                          <input
                            type="text"
                            id="other_pool_type"
                            name="otherPoolType"
                            placeholder="Additional other pool type"
                            value={otherPoolType}
                            onChange={(e) => {
                              setOtherPoolType(e.target.value);
                              props.setServiceInfoData({
                                value: e.target.value,
                                key: "otherPoolType",
                                name: e.target.name,
                              });
                              setServiceInfo({
                                value: e.target.value,
                                key: "otherPoolType",
                                name: e.target.name,
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {errors?.[`otherPoolType`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`otherPoolType`]}
                    </label>
                  ) : (
                    ""
                  )}
                  {errors?.[`otherPoolType`] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle" />
                      {errors?.[`otherPoolType`]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                    <label style={{ fontWeight: 'bold' }}>
                      Address <sup>*</sup>
                    </label>
                    <AddressField handleAddressChange={handleAddressChange} onAddressChanged={onAddressChange} onAddressChange={onAddressChange} errors={errors} data={temp_address} nolabel />
                  </div>
                  {props.serviceInfo?.serviceType?.key?.toLowerCase() !== "maintenance" && props.serviceInfo?.serviceType?.key?.toLowerCase() !== "service" ? (
                <div className="frow">
                  <h4>Preferred Start Date</h4>
                  <div className="oplist">
                    {map(data?.sort(function(a, b) { return new Date(a) - new Date(b) })?.slice(0, 4), (item, i) => {
                      return (
                        <div
                          className="inline-checks flex-1"
                          key={`preferred_start_dates${i}`}
                        >
                          <label
                            htmlFor={item?.value}
                            className={
                              preferredStartDate ===
                              moment(item).format("MM-DD-YYYY")
                                ? "active"
                                : ""
                            }
                          >
                            <input
                              type="radio"
                              id={item?.value}
                              name="preferredStartDate"
                              //  disabled={!zipcodeCheck}
                              checked={
                                preferredStartDate ===
                                moment(item).format("MM-DD-YYYY")
                              }
                              value={moment(item).format("MM-DD-YYYY")}
                              className=""
                              onChange={onDataChange}
                            />
                            <span>
                            {item.format("MM-DD-YYYY") === moment().format("MM-DD-YYYY") ? "Today" : moment(item, "MM-DD-YYYY").format("ddd, MMM DD")}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                    <div className="inline-checks flex-1">
                      <DatePicker
                        renderExtraFooter={(e) => renderExtraFooter}
                        inputReadOnly
                        mode={"date"}
                        className={
                          displayPreferredStartDate ? "active" : "box_opacity"
                        }
                        // disabled={!zipcodeCheck}
                        placeholder={"Select another date"}
                        format={"ddd, MMM DD"}
                        value={displayPreferredStartDate}
                        onChange={onSelectDisplayPreferredStartDate}
                        disabledDate={(current) => {
                          return current && current <= moment().add("days", 3);
                        }}
                        // disabledDate={current => {
                        //     return !props.data?.pref_date?.sort()?.slice(3, props.data?.pref_date.length).includes(moment(current).format('MM-DD-YYYY'))
                        // }}
                      />
                    </div>
                    
                  </div>
                </div>
              ) : null}
              {errors?.['preferredStartDate'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['preferredStartDate']}</label> : ''}

               {/* {props?.serviceInfo?.serviceType?.key === "service" ? (
                <div className="frow">
                  <h4>Select a time range For the visit</h4>
                  <div className="oplist">
                    {map(time_range, (item, i) => {
                      return (
                        <div
                          className="inline-checks flex-1"
                          key={`timeRange${i}`}
                        >
                          <label
                            htmlFor={item?.value}
                            className={timeRange === item.value ? "active" : ""}
                          >
                            <input
                              type="radio"
                              id={item?.value}
                              name="timeRange"
                              //  disabled={!zipcodeCheck}
                              checked={timeRange === item}
                              value={item.value}
                              className=""
                              onChange={onTimeRangeChange}
                            />
                            <span>{item?.key}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null} */}
              {errors?.['timeRange'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['timeRange']}</label> : ''}
              <div style={{paddingTop: '10px'}}>
                <label style={{ fontWeight: 'bold' }}>
                  Preferred Technician
                </label>
                {renderPreferredTech()}
              </div>
                 <hr style={{ margin: "20px 0" }} />
                    <div className="frow" style={{margin: "0px"}}>
                      <h4> Terms of Service</h4>
                      <span>
                        By signing up, I grant my permission for America's Swimming
                        Pool Co. to access my property and agree to the full
                        <a> Terms of Service</a> and
                        <a href="/#"> Privacy Policy.</a>
                      </span>
                      <div className="fitem-check mt-10">
                        <input
                          id="isAgreed"
                          name="isAgreed"
                          checked={isAgreed}
                          onChange={(e) => onTextChange(e, "bool")}
                          type="checkbox"
                          className="fitem-ck-input"
                        />
                        <label htmlFor="isAgreed" className="fitem-ck-txt">
                          Customer verbally agreed
                        </label>
                      </div>
                      {errors?.["isAgreed"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["isAgreed"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>                
                </div>
                </>
                 : ''
                }
              </div>
            </div>
          </div>
          <SideBar            
            serviceInfo={serviceInfo}
            priceDetail={props.priceDetail}
            setPriceDetail={(e) => { setPriceDetail((p) => ({ ...p, price: e })) }}
          />
        </div>
        {/* <div className="fix-btn-grp">
          <button className="btn btn-white" onClick={() => onCancel()}>
            Cancel
          </button>
          <button className={`btn btn-warning`} onClick={() => validate()}>
            Next: GO TO BILLING
          </button>
        </div> */}
        <div className="fix-btn-grp">
          <button className="btn btn-white" onClick={() => onCancel()}>
            Cancel
          </button>
          <button className={`btn btn-save-add`} onClick={() => { setState((s) => ({ ...s, loader:true})); validate('paused') }} disabled={state.loader}>
            Save
          </button>
          <button className={`btn btn-warning`} onClick={() => { setState((s) => ({ ...s, loader:true})); validate('active') }} disabled={state.loader}>
            Save & Activate
          </button>
        </div>
      </div>
    );
  };

  return render();
};

export default ServiceInfo;
