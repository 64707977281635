import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { message, Input } from "antd";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import RightSlider from "../Login/RightSlider";
import dclogo from "../Assets/images/logo-asp.svg";
import { map } from "lodash";
import { postLogin, getUserByKey } from "../Action/Auth";
import { setFranchiseSelected } from "../Utils/selectedFranchise";

const Login = (props) => {
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const emailInputRef = useRef(null);
  const [state, setState] = useState({
    email: "",
    password: "",
    loader: false,
    rememberUser: false,
    validPassword: {
      containsUppercase: false,
      containsNumber: false,
      checkLength: false,
    },
    is_btn_active: false,
    errors: {},
  });
  function getState() {
    setState({
      email: "",
      password: "",
      loader: false,
      rememberUser: false,
      validPassword: {
        containsUppercase: false,
        containsNumber: false,
        checkLength: false,
      },
      is_btn_active: false,
      errors: {},
    });
  }

  useEffect(() => {
    getState();
    setState((state) => ({
      ...state,
      // loader:true
    }));
    initialConfig();

    window.FreshWidget.init("", {
      queryString: "&widgetType=popup",
      widgetType: "popup",
      buttonType: "text",
      buttonText: "Support",
      buttonColor: "white",
      buttonBg: "#006063",
      alignment: "4",
      offset: "-1500px",
      formHeight: "500px",
      url: "https://infinitynetworks.freshdesk.com",
    });
  }, []);

  const initialConfig = () => {
    const Secure = Cookies.get("Secure-SSID_REMEMBER")
      ? JSON.parse(Cookies.get("Secure-SSID_REMEMBER"))
      : "";
    const accessUser = JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser"))
      : "";
    if (accessUser.key) {
      localStorage.clear();
      sessionStorage.clear();
      navigate(`/`);
    }
    const accessToken = JSON.parse(localStorage.getItem("authToken"))
      ? JSON.parse(localStorage.getItem("authToken")).key
      : "";
    if (accessToken) {
      const role = accessUser && accessUser?.user?.user_roles[0]?.name;
      if (role === "field_tech") {
        navigate(`/calendar/`);
      } else {
        navigate("/dashboard");
      }
    }

    if (Secure) {
      setState((state) => ({
        ...state,
        rememberUser: true,
        email: Secure.email,
        password: Secure.password,
        validPassword: Secure.validPassword,
        is_btn_active: true,
      }));
    }
    // wrapperRef.focus();
  };

  //set user name and password
  const onUserChange = (e) => {
    let { validPassword } = state;
    if (e.target.name === "password") {
      validPassword = Object.assign(validPassword, {
        containsUppercase: /[A-Z]/.test(e.target.value),
        containsNumber: /\d/.test(e.target.value),
        checkLength: e.target.value.length >= 8,
      });
    }
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
      validPassword,
    }));
  };

  //login User
  const Login = (e) => {
    setState((state) => ({
      ...state,
      loader: true,
    }));
    e.preventDefault();
    let { email, password, rememberUser, validPassword } = state;
    if (rememberUser) {
      Cookies.set(
        "Secure-SSID_REMEMBER",
        JSON.stringify({
          rememberUser: rememberUser,
          email: email,
          password: password,
          validPassword: validPassword,
        })
      );
    } else {
      Cookies.remove("Secure-SSID_REMEMBER", { path: "" });
    }

    // call login API
    let is_crm = true;
    const login = async () => {
      emailInputRef.current.select();
      const { key } = await postLogin({
        username: email,
        email,
        password,
        is_crm,
      });
      if (key) {
        localStorage.setItem("authToken", JSON.stringify({ key }));
        const user = await getUserByKey({ key });
        if (!user) {
          setState((state) => ({ ...state, password: "", loader: false }));
          message.error("Unable to get user information.", 10);
        }
        localStorage.setItem("authUser", JSON.stringify({ user }));
        setState((state) => ({ ...state, loader: false }));
        localStorage.setItem(
          "temp_franchise",
          JSON.stringify({ data: user.field_offices[0] })
        );
        console.log("USER--->>> ", user);
        if (
          user?.role.includes("franchise_owner") ||
          user?.role.includes("operations_mgr")
        ) {
          if (user?.associated_field_offices[0]?.is_multi_location_franchise) {
            setFranchiseSelected("all");
          } else {
            setFranchiseSelected(
              user?.field_offices[0].uuid,
              user?.field_offices[0].name
            );
          }
        }
        if (user.qb_url) {
          window.open(user.qb_url, "_blank");
        }

        let params = new URL(document.location).searchParams;
        let ref = params.get("ref");
        if (user.user_roles[0]["name"] === "field_tech") {
          if (ref) {
            navigate(`${ref}`);
          } else {
            navigate(`/calendar/`);
          }
          if (user.tech_form_uuid) {
            //navigate(`/${user.tech_form_name}/${user.tech_form_uuid}`);
            //navigate(`/calendar/`);
          } else {
            //navigate(`/calendar/`);
          }
        } else {
          if (ref) {
            navigate(`${ref}`);
          } else {
            navigate("/dashboard");
          }
        }
      } else {
        setState((state) => ({ ...state, password: "", loader: false }));
        message.error("Unable to log in with provided credentials.", 10);
      }
    };
    login();
  };

  // Validate Form
  const validateUser = (e) => {
    e.preventDefault();
    let { email, password, validPassword } = state;
    const UserSchema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required")
        .email("Your Email is invalid."),
      password: yup.string().required("Password is required"),
      // validPassword: yup.object().shape({
      //   containsUppercase: yup.bool().oneOf([true], 'Password contains one uppercase letter'),
      //   containsNumber: yup.bool().oneOf([true], 'Password contains number'),
      //   checkLength: yup.bool().oneOf([true], 'Minimum length is 8')
      // })
    });

    const UserData = {
      email,
      password,
      validPassword,
    };

    UserSchema.validate(UserData, { abortEarly: false })
      .then(() => {
        setState((state) => ({ ...state, is_btn_active: true, errors: {} }));
        Login(e);
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setState((state) => ({
          ...state,
          errors: err_data,
          is_btn_active: false,
        }));
      });
  };

  //remember
  const onRemember = (e) => {
    if (e.target.checked) {
      setState((state) => ({
        ...state,
        rememberUser: e.target.checked,
      }));
    } else {
      setState((state) => ({
        ...state,
        rememberUser: e.target.checked,
      }));
      Cookies.remove("Secure-SSID_REMEMBER", { path: "" });
    }
  };

  let { email, password, loader, rememberUser, errors } = state;
  return (
    <div id="wrapper">
      <div className="auth-page">
        <div className="authform">
          <div className="auth-container">
            <img src={dclogo} alt="" className="logo" />
            <h1 className="Welcome-to-PoolOps-S">
              Welcome to PoolOps
              <br />
              Please sign in to get started
            </h1>
            <label className="mb-30 Description-Copy">
              Enter your details to proceed
            </label>
            <form className="form-group" onSubmit={(e) => validateUser(e)}>
              <div className="fitem">
                <label className="Field-title">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  style={{ borderColor: "#78909b" }}
                  ref={emailInputRef}
                  onChange={(e) => {
                    onUserChange(e);
                  }}
                  autoFocus
                  // ref={(input) => { wrapperRef = input; }}
                  placeholder="Email"
                />
                {errors?.["email"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {errors?.["email"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div className="fitem">
                <label className="Field-title">Password</label>
                <Input.Password
                  value={password}
                  name="password"
                  onChange={(e) => {
                    onUserChange(e);
                  }}
                  style={{ borderColor: "#78909b", borderRadius: "4px" }}
                  placeholder="Start typing…"
                />
                {errors?.["password"] ||
                errors?.["validPassword.containsUppercase"] ||
                errors?.["validPassword.checkLength"] ||
                errors?.["validPassword.containsNumber"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {"Invalid password"}
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div className="fitem half-fitem inline-check-list flex">
                <div className="fitem-check">
                  <input
                    type="checkbox"
                    className="fitem-ck-input"
                    id="checkbox"
                    checked={rememberUser}
                    onChange={(e) => {
                      onRemember(e);
                    }}
                    name="checkboxs"
                  />
                  <label htmlFor="checkbox" className="fitem-ck-txt">
                    <span className="Remember-me">Remember me</span>
                  </label>
                </div>
                <Link to="/forgot-password" replace className="m-0">
                  <strong>
                    <u>Recover Password</u>
                  </strong>
                </Link>
              </div>
              <div className="btn-grp text-right">
                <button type="submit" className={"btn btn-warning"}>
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <RightSlider />
      </div>
      {loader ? (
        <div className="loader_wrapper">
          <div className="loader07"></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Login;
