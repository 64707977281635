import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import RenovationConstructionNotes from "./RenovationNotes";
import { getServiceMatrix } from "../../../Action/Customer";
import {
  serviceMatrixApi,
  addServiceType,
  addServiceTypeName,
  resetState,
  flag_state,
  email_notes,
  service_notes,
  resetServiceData,
} from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";
import { getproposalsetup } from "../../../Action/Franchise";
import AdditionalProposal from "./AdditionalWork";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import PropsalRateType from "./ProposalRateType";
import PoolNoFile from "./proposalPool";
import NumberOfPool from "./PoolNumber";
import PoolName from "./PoolName";
import ProposalPoolTypes from "./ProposalPoolType";
import { map } from "lodash";
import ProposalServiceOption from "./PropsalSelectService";
import ServiceAddressForProposal from "./AddServiceAddress";
import ProposalButtons from "./ProposalButton";
import ProposalNotes from "./ProposalNotes";
import ProposalFrequnecy from "./ProposalFrequency";
import PreferedStartDate from "./ProposalPreferedStartDate";
const BaseJobFormProposal = (props) => {
  const dispatch = useDispatch();
  const { matrixdata, seachvalidate, previewvalidate, errors, editMode } = props;
  const [loader, setLoader] = useState(false);
  const proposals = useSelector((state) => state.proposalDataSlice);
  useEffect(() => {
    let fuuid = props.customer.field_office.uuid;
    setTimeout(async () => {
      if (fuuid) {
        const data = await getServiceMatrix(fuuid);
        dispatch(serviceMatrixApi(data));
      }
    }, 1000);
  }, []);

  const onservicechange = (e) => {
    if (e.target.name === "serviceType") {
      dispatch(resetServiceData());
      setTimeout(() => {
        dispatch(addServiceType(e.target.value));
        dispatch(addServiceTypeName(e.target.key));
      }, 0);
    }
  };

  return (
    <div className="main_pro_css">
    <div className="sec_pro_css">
        {loader && <Loader />}
        <div className="three_pro_css">
          <h3>Base Job Requirements </h3>
        </div>
        <div className="foure_pro_css">
          <h4>What Service are you interested In? <sup>*</sup></h4>
        </div>
        <div className="oplist">
          {map(proposals?.response_data?.servicetypes, (item) => (
            <div className="inline-checks">
              <label
                htmlFor={item?.uuid}
                className={`${
                  proposals.service_type_uuid === item?.uuid ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  id={item?.uuid}
                  name="serviceType"
                  className=""
                  value={item?.uuid}
                  disabled={editMode}
                  onChange={() =>
                    onservicechange({
                      target: {
                        name: "serviceType",
                        value: item?.uuid,
                        key: item?.name,
                      },
                    })
                  }
                />
                <span>{item?.name}</span>
              </label>
            </div>
          ))}
        </div>
        
        <div>
          <PropsalRateType
            errors={errors}
            matrixdata={props.customer}
            typedata={matrixdata}
            editMode={editMode}
          />
        </div>
        <div>
          <RenovationConstructionNotes
            matrixdata={props.customer}
            typedata={matrixdata}
            errors={errors}
            editMode={editMode}
          />
        </div>
        {(proposals.service_type_name === "construction" ||
          proposals.service_type_name === "renovation") && (
          <div>
            <PreferedStartDate
              matrixdata={props.customer}
              typedata={matrixdata}
              errors={errors}
              editMode={editMode}
            />
          </div>
        )}

        <div>
          <ProposalServiceOption
            matrixdata={props.customer}
            typedata={matrixdata}
            errors={errors}
            editMode={editMode}
          />
        </div>
        {( proposals.service_type_name === "maintenance" ||  proposals.service_type_name === "service") &&<div>
          <ProposalFrequnecy
            matrixdata={props.customer}
            typedata={matrixdata}
            errors={errors}
            editMode={editMode}
          />
        </div>}

        <div>
          {proposals.service_type_name === "maintenance" && (
            <NumberOfPool matrixdata={props.customer} typedata={matrixdata}  errors={errors} editMode={editMode}/>
          )}{" "}
        </div>
        <div>
          <PoolNoFile matrixdata={props.customer} typedata={matrixdata}  errors={errors} editMode={editMode}/>
        </div>
        <div>
          <PoolName
            matrixdata={props.customer}
            typedata={matrixdata}
            errors={errors}
            editMode={editMode}
          />
        </div>
        <div>
          <ServiceAddressForProposal
            matrixdata={props.customer}
            typedata={matrixdata}
            errors={errors}
            editMode={editMode}
          />
        </div>
        <div>
          <ProposalPoolTypes
            matrixdata={props.customer}
            typedata={matrixdata}
            errors={errors}
            editMode={editMode}
          />
        </div>
        <div>
          <AdditionalProposal customer={props.customer} typedata={matrixdata} editMode={editMode} errorsData={errors} proposalRedirectData={props.state.proposalData}/>
        </div>
      </div>
      <ProposalNotes editMode={editMode} errors={errors}/>
      <ProposalButtons
        seachvalidate={seachvalidate}
        previewvalidate={previewvalidate}
        editMode={editMode}
        tabChange={props.tabChange}
        
      />
    </div>
  );
};
export default BaseJobFormProposal;
