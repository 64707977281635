import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Col, Row } from "antd";
import StaffAssignmentChart from "../Charts/StaffAssignment.Chart";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import { getStaffAssignment } from "../../../Action/Dashboard";
import StaffSelector from "../Filters/Staff.Filter";

const StaffAssignmentContainer = () => {
  const [staffAssignList, setStaffAssignList] = useState([]);
  const { franchise, period } = useDashboard();
  const [serviceOrderFilter, setServiceOrderFilter] = useState([]);

  const handleServiceTypeChange = (filterList) => {
    setServiceOrderFilter(filterList);
  };

  const getStaffAssignmentList = useCallback(async () => {
    const data = {
      end_date: period.created_at__lte,
      start_date: period.created_at__gte,
      franchise_filter: franchise,
    };

    const response = await getStaffAssignment(data);
    if (response.status !== 200) {
      return;
    }

    const processResponse = response.data.reduce((result, assignment) => {
      const { total_assignments, services, technician, tech_id } = assignment;

      if (total_assignments === 0) return [...result];

      const processAssignment = technician.reduce((result, tech) => {
        const technicianName = `${tech.first_name} ${tech.last_name}`;
        const serviceByTech = services.map((service) => ({
          technicianName,
          techID: tech_id,
          serviceType: service.service_status,
          count: service.count,
        }));
        return [...result, ...serviceByTech];
      }, []);

      return [...result, ...processAssignment];
    }, []);
    setServiceOrderFilter(processResponse.map((staff) => staff.technicianName));
    setStaffAssignList(processResponse);
  }, [franchise, period, setStaffAssignList, setServiceOrderFilter]);

  const value = useMemo(
    () =>
      [
        ...new Set(
          [{ technicianName: "All" }, ...staffAssignList].map(
            (assignment) => assignment.technicianName
          )
        ),
      ].map((name) => ({ name: name })),
    [staffAssignList]
  );

  useEffect(() => {
    getStaffAssignmentList();
  }, [getStaffAssignmentList]);

  return (
    <div
      className="cardMetric"
      style={{ padding: "0 0 10px 0", maxHeight: "400px" }}
    >
      <Row>
        <Col xs={24} xxl={24} md={24} className="serviceChart cardFilters">
          <span className="cardTitle">Staff Assignment</span>
          <StaffSelector
            handleChange={handleServiceTypeChange}
            staffList={value}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          xxl={24}
          md={24}
          style={{ padding: "10px", height: "300px" }}
        >
          <StaffAssignmentChart
            data={staffAssignList.filter((staff) =>
              serviceOrderFilter.includes(staff.technicianName)
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StaffAssignmentContainer;
