import React from "react";
import { useNavigate } from "react-router-dom";
import ReportCard from "../../Components/Cards/Report.Card";
import ReportsData from "../../Common/ReportList.Data";

const ReportsListContainers = () => {
  const navigate = useNavigate();

  const go2Report = (id) =>{
    let report =ReportsData.find(item => item.id == id);
    if(report?.hasExternalURL){
      window.open(report.URL)
    }else{
      navigate(`/reports/${id}`);
    }
  } 

  return (
    <div className="block-row">
      <div className="flist">
        {ReportsData.map((report) => (
          <ReportCard
            key={report.key}
            title={report.title}
            description={report.description}
            go2Report={go2Report}
            id={report.id}
            display={report.display}
            buttonLabel={report.hasExternalURL? report.buttunLable :"Run Report"}
          />
        ))}
      </div>
    </div>
  );
};

export default ReportsListContainers;
