const appData = {};
appData.customerType = [
  { value: "residential", label: "Residential" },
  { value: "commercial", label: "Commercial" },
  // { value: "corporate", label: "Corporate" }, //As per the EAP-3002 requirement removing this option
];
appData.serviceTypes = "maintenance,service,construction,renovation";
appData.invoicePreferences = [
  {
    label: "Print Invoice",
    key: "is_print_and_mail_invoices",
    isChecked: false,
  },
  { label: "Email Invoice", key: "is_email_invoice", isChecked: false },
];
appData.contactMethods = [
  { label: "By Text", key: "text", isChecked: false },
  { label: "Call Home Phone", key: "home_phone", isChecked: false },
  { label: "Call Mobile", key: "mobile", isChecked: false },
  { label: "By Email", key: "email", isChecked: false },
];

appData.paymentMethods = [
  { key: 'Cash', value: 'cash' },
  { key: 'Check', value: 'Check' },
  { key: 'Credit Card', value: 'card' },
  { key: 'ACH Payments', value: 'ACH Payments' },
]

appData.notInvoiced = "not invoiced";
appData.workOrderTypes = [
  "acid wash",
  "diagnostic",
  "pool opening",
  "pool closing",
  "salt cleaning",
  "other",
];

appData.initialPriceCatlogModalState = {
  national_price: 0,
  national_labor: 0,
  national_labor_time: 0,
  local_price: 0,
  multiplier_used: 1, //Default for new price catlog
  labor_rate_fo: 0,
  labor_time_fo: 0,
  retail_price: 0,
  parttype: "",
  productcode: "",
  description: "",
  retail_total_price_without_tax: 0,
  retail_total_price_with_tax: 0,
  available_multipliers: [1],
  is_taxed_labor: false,
  is_taxed_product: false,
}

appData.proposalCustomerType= [{ key: "all", value: "All" },
{ key: 'residential', value: 'Residential' },
{ key: 'commercial', value: 'Commercial' },
{ key: 'lead_residential', value: 'Lead Residential' },
{ key: 'lead_commercial', value: 'Lead Commercial' }
]
appData.proposalStatus= [{ key: "all", value: "All" },
{ key: 'sent', value: 'Sent' },
{ key: 'accepted', value: 'Accepted' },
{ key: 'closed', value: 'Closed' },
{ key: 'declined', value: 'Declined' },
{ key: 'needs_review', value: 'Needs Review' },
{ key: 'deactivated', value: 'Deactivated' },
{ key: 'expired', value: 'Expired' }
]
appData.proposalStatusFromEdit= [
{ key: 'accepted', value: 'Accepted' },
{ key: 'closed', value: 'Closed' },
{ key: 'declined', value: 'Declined' },
{ key: 'needs_review', value: 'Needs Review' },
{ key: 'expired', value: 'Expired' }
]
appData.proposalServiceTypes= [{ key: "all", value: "All" },
{ key: 'maintenance', value: 'Maintenance' },
{ key: 'service', value: 'Service' },
{ key: 'construction', value: 'Construction' },
{ key: 'renovation', value: 'Renovation' },
]

appData.completeCancelBillableWorkOrder = "complete,cancelled_billable";
export default appData;
