import React, { useEffect, useState } from "react";
import AddFranchise from "./AddFranchise";
import { Drawer, message } from "antd";
import Loader from "../../Components/Loader";
import { updateFranchise, getFranchiseData } from "../../Action/Franchise";
import * as constants from "../../Components/Constants";
import { object } from "yup";

const AccountOverviewFranchise = ({ tabChange, data, onFranchiseUpdate }) => {
  //console.log("data--->",data);
  const [franchise_uuid, setFranchise_uuid] = useState("");
  const [loader, setLoader] = useState(false);
  const [is_crud_allow, setIs_crud_allow] = useState(true);
  const [is_edit_franchise, setIs_edit_franchise] = useState(false);
  const [franchise_data, setFranchise_data] = useState({});

  // Get Franchise datas for Table
  const getDataFranchise = async (data) => {
    setLoader(true);
    const franchise_list = await getFranchiseData(data);
    // setFranchiseData(franchise_list);
    setLoader(false);
  };
  //Call Update Franchise API
  const updateFranchiseDetails = async (newData) => {
    newData.uuid = franchise_uuid;
    closeEditDrawer(false);
    setLoader(true);

    const response = await updateFranchise(
      { ...newData, name: newData?.name || data?.name},
      newData.uuid
    );

    if (response?.name) {
      let phone_data = {
        ...franchise_data.phone_number,
      };
      phone_data['phone'] = newData.phone_number.phone;
      // let owner_data = ;
      setFranchise_data((f) => ({
        ...f,
        name: newData.name,
        city: newData.city,
        state: newData.state,
        owner: newData.owner,
        owned_zip_codes: newData.owned_zip_codes,//?
        address: newData.address,
        field_office_address: newData.field_office_address,
        phone_number: phone_data,
        remittance_address: newData.remittance_address,
        remittance_city: newData.remittance_city,
        remittance_state: newData.remittance_state,
        remittance_zip_code: newData.remittance_zip_code
      }));
      let old_data = JSON.parse(localStorage.getItem("temp_franchise"));
      let new_obj = {};
      Object.assign(new_obj, old_data);
      new_obj.data["name"] = newData.name;
      new_obj.data["city"] = newData.city;
      new_obj.data["state"] = newData.state;
      new_obj.data["owner"] = newData.owner;
      new_obj.data["owned_zip_codes"] = newData.owned_zip_codes;
      new_obj.data["address"] = newData.address;
      new_obj.data["field_office_address"] = newData.field_office_address;
      new_obj.data["phone_number"] = phone_data;
      new_obj.data["remittance_address"] = newData.remittance_address;
      new_obj.data["remittance_city"] = newData.remittance_city;
      new_obj.data["remittance_state"] = newData.remittance_state;
      new_obj.data["remittance_zip_code"] = newData.remittance_zip_code;
      new_obj.data["addressable_zip_codes"] = newData.addressable_zip_codes;
      localStorage.setItem("temp_franchise", JSON.stringify(new_obj));
      setLoader(false);
      getDataFranchise({
        limit: 15,
        offset: 0,
      });
      message.success("Franchise updated successfully.");
      onFranchiseUpdate(new_obj);
    } else {
      setLoader(false);
      message.error({
        content: (
          <>
            {data.map((item) => (
              <>
                <span>{item}</span>
                <br />
              </>
            ))}
          </>
        ),
      });
    }
  };
  //Close Edit drawer
  const closeEditDrawer = () => {
    setIs_edit_franchise(false);
  };
  // format phone number
  const formatPhoneNumber = (phone) => {
    phone = phone?.replace(/\D/g, "");
    return (
      // (XXX) XXX-XXXX
      `(${phone?.substr(0, 3)})` +
      " " +
      phone?.substr(3, 3) +
      "-" +
      phone?.substr(6)
    );
  };
  const renderFranchiseInfo = () => {
    // const { franchise_detail, is_crud_allow } = this.state;
    return (
      <div className="card">
        <div className="card-title flex ai-center">
          <h5>Franchise Information</h5>
          <span
            className={`btn ${
              is_crud_allow ? 'btn-warning' : 'disabled'
            } btn-small`}
            onClick={() => setIs_edit_franchise(true)}
          >
            {constants.UPDATEBTNTEXT}
          </span>
          {/* onClick={is_crud_allow ?  setIs_edit_franchise(true) : null} */}
        </div>
        <div className="card-body">
          <ul className="details_list">
            <li>
              <label>
                <b>Owner:</b>
              </label>
              <span>
                <b>
                  {franchise_data?.owner?.first_name
                    ? `${franchise_data?.owner?.first_name || ''} ${
                        franchise_data?.owner?.last_name || ''
                      }`
                    : ''}
                </b>
              </span>
            </li>
            <li>
              <label>Year Started: </label>
              <span>
                {franchise_data?.year_started
                  ? franchise_data?.year_started
                  : ''}
              </span>
            </li>
            <li>
              <label>Users:</label>
              <span>{franchise_data?.users}</span>
            </li>
            <li>
              <label>Customers: </label>
              <span>
                {franchise_data?.customers ? franchise_data?.customers : '0'}
              </span>
            </li>
          </ul>
          <h6>Franchise Contact Details</h6>
          <b className="d-block">Address</b>
          <p className="mb-10">
            {franchise_data?.address ?? ''}, {franchise_data?.city ?? ''}{' '}
            {franchise_data?.state ?? ''} {franchise_data?.field_office_address?.zip_code ?? ''}
          </p>
          <b className="d-block">Remittance Address</b>
          {franchise_data?.remittance_address ? <p className="mb-10">
            {franchise_data?.remittance_address ?? ''}, {franchise_data?.remittance_city ?? ''}{' '}
            {franchise_data?.remittance_state ?? ''} {franchise_data?.remittance_zip_code ?? ''}
          </p> : <p/>}
          <b className="d-block">Number</b>
          <p className="mb-10">
            {franchise_data?.phone_number?.phone
              ? `${formatPhoneNumber(franchise_data?.phone_number?.phone)}`
              : ''}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (data) {
      setFranchise_data(data);
      console.log(franchise_data)
    }
  }, [])

  useEffect(() => {
    if (data) {
      setFranchise_uuid(data.uuid);
      setFranchise_data(data);
    }
  }, [data]);
  return (
    <div className="overview_wrapper">
      <div className="aside-area pt-0">
        <aside className="">{renderFranchiseInfo()}</aside>
      </div>
      <div className="main p-0"></div>
        <Drawer
          open={is_edit_franchise}
          width={'420'}
          closable={false}
          className="drawer-wrapper create_ticket_drawer"
        >
          <AddFranchise
            closeAddDrawer={closeEditDrawer}
            inputData={franchise_data}
            type={'edit'}
            createNewFranchise={updateFranchiseDetails}
            isOpen={is_edit_franchise}
          />
        </Drawer>
      {loader && <Loader />}
    </div>
  );
};

export default AccountOverviewFranchise;
