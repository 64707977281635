import React from "react";
import { Radio } from "antd";
import { creationFramePeriod } from "../../Common/creationFramePeriod.function";

const PeriodSelector = ({ handleChange = () => {}, defaultValue = "day" }) => {
  const changeTimeFilter = (e) => {
    const value = e.target.value || "day";
    handleChange(creationFramePeriod(value), value);
  };
  return (
    <Radio.Group onChange={changeTimeFilter} defaultValue={defaultValue}>
      <Radio.Button value="day">Today</Radio.Button>
      <Radio.Button value="week">Week</Radio.Button>
      <Radio.Button value="month">Month</Radio.Button>
      <Radio.Button value="year">Year</Radio.Button>
    </Radio.Group>
  );
};

export default PeriodSelector;
