import React from "react";
import { Row, Col, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import Compressor from 'compressorjs';

const ImageUploader = (props) => {
  let imagesList = [];

  async function imgRequest({ file, onSuccess }) {
    let dataUrl = await getUrl(file);
    imagesList = props.fileList;

    if (!file) {
      return;
    }
    
    let img = {};
    switch (props.params.level) {
      case "MaintenanceReport":
        img = {
          image_url: file,
          is_tech_image: props.params.isTechImage,
          type: props.params.image_type,
        };
        break;
      case "PoolInspection":
        img = {
          image_url: file,
          is_tech_image: props.params.isTechImage,
          type: props.params.image_type,
        };
        break;
      case "FilterClean":
        img = {
          image_url: file,
          is_tech_image: props.params.isTechImage,
          type: props.params.image_type,
        };
        break;
      default:
        break;
    }    
    await compressImage(img);          
  }
  
  const compareFiles = (fileList, file) => {
    fileList.forEach((each, index) => {
      if (
        Object.keys(each).includes("image_url") &&
        each.image_url.uid === file.uid
      ) {
        return index;
      }
    });
    return -1;
  };

  async function compressImage(img) {
    const file = img.image_url;    
    
    new Compressor(file, {
      quality: 0.7,
      maxHeight: "800",
      maxWidth: "800",
      retainExif: true, 
      mimeType: "image/jpeg",
      async success(result) {
        img.image_url = result;           
        await getBase64(img);
      },
      error(err) {
        console.log(err.message);
      },
    });
  }

  async function getBase64(img) {
    var reader = new FileReader();    
    reader.readAsDataURL(img.image_url);
    reader.onload = function () {      
      const image2Process = document.createElement("img");
      image2Process.src = reader.result;
      image2Process.onload = async () => {    
        img.image = reader.result;       
        imagesList.push(img);
        props.getFiles(imagesList);
      };
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const fileProps = {
    onPreview: (file) => {    
      var fileURL = file.url;
      if (file.thumbUrl) {
        fileURL = URL.createObjectURL(file.originFileObj);
      }
      window.open(fileURL);
    },
    beforeUpload: async (file, success) => {
      file.url = file;
      imgRequest({ file, success });
      return false;
    },
    onRemove: (file) => {
      const index = props.fileList.indexOf(file);
      const newFileList = props.fileList.slice();
      newFileList.splice(index, 1);
      if (index === -1) {
        let index2 = compareFiles(props.fileList, file);
        const newFileList = props.fileList.slice();
        newFileList.splice(index2, 1);
      }
      props.getFiles(newFileList);
    },
  };

  async function getUrl(file) {
    return new Promise(function (resolve) {
      if (!file.type) {
        resolve("");
        return;
      }
      let src = window.URL.createObjectURL(file);
      resolve(src);
      return src;
    });
  }

  return (
    <div className="fitem">
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Upload
            {...fileProps}
            customRequest={imgRequest}
            listType="picture"
            accept={props.accept}
            defaultFileList={[...props.fileList]}
            multiple={true}
          >
            <Button type="primary" icon={<UploadOutlined />} size="large">
              {props.text}
            </Button>
          </Upload>
        </Col>
      </Row>
    </div>
  );
};

export default ImageUploader;
