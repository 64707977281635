const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
import { getCustomer, getCommercialCustomer } from '../Action/Customer';

//residential
export const getDates = createAsyncThunk(
    'customer/getDates',
    async (params) => {
      const data = await getCustomer(params);
      return data ? data : {};
    }
)

//commercial
export const getCommercialDates = createAsyncThunk(
  'customer/getCommercialDates',
  async (params) => {
    const response = await getCommercialCustomer(params);
    return response ? response : {};
  }
)

const initialState = {
    date: null,
    lastServiceDate: null,
    nextServiceDateComm: null,
    lastServiceDateComm: null
}

const dateSlice = createSlice({
  name: "setDateSlice",
  initialState,
  extraReducers(builder) {
    builder
    .addCase(getDates.fulfilled, (state, action) => {
      let { next_follow_up, last_service } = action.payload
      return (state = {
        ...state,
        date: next_follow_up,
        lastServiceDate: last_service,
      });
    })

    .addCase(getCommercialDates.fulfilled, (state, action) => {
      let { next_follow_up, last_service } = action.payload
      return (state = {
        ...state,
        nextServiceDateComm: next_follow_up,
        lastServiceDateComm: last_service,
      });
    })
  },
})
export const { setDateSlice } = dateSlice.actions;

export default dateSlice.reducer;
