import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInvoice, savePayment } from "../Action/Payment/index";

const initialState = {
  invoice:{},
  bankAccountData: {
    bankAccount: "",
    routingNumber: "",
    accountNumber:"",
    accountType: "",
    bankHolderType: "",
  },
  creditCardData: {
    ccNumber: 0,
    expirationMonth: "",
    expirationYear: "",
    cvv: 0,
  },
  billingAddress: {
    firstName: "",
    lastName: "",
    address: "",
    state: "",
    zipCode: "",
    city: "",
  },
  chargePermission: true,
  status: "idle",
  messageResponse:'',
  error: null,
};

export const getInvoiceData = createAsyncThunk(
  "payment/getInvoice",
  async (params) => {
    const response = await getInvoice(params);
    return response ? response : {};
  }
);

export const savePaymentData = createAsyncThunk(
  "payment/savePayment",
  async (params) => {
    const response = await savePayment(params);
    return response ? response : {};
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updateBankAccount(state, action) {
      return (state = {
        ...state,
        bankAccountData: action.payload,
      });
    },
    updateChargePermission(state, action) {
      return (state = {
        ...state,
        chargePermission: action.payload,
      });
    },
    updateBillingAddress(state, action) {
      return (state = {
        ...state,
        billingAddress: action.payload,
      });
    },
    updateCreditCard(state, action) {
      return (state = {
        ...state,
        creditCardData: action.payload,
      });
    },
  },
  extraReducers(builder) {
    builder
    .addCase(getInvoiceData.pending, (state, action) => {
      state.status = "loading";      
    })
    .addCase(getInvoiceData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.invoice = action.payload;
    })
    .addCase(getInvoiceData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
    .addCase(savePaymentData.pending, (state, action) => {
      state.status = "loading";      
    })
    .addCase(savePaymentData.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.messageResponse = action.payload;
    })
    .addCase(savePaymentData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    })
  },
});

export const {
  updateBankAccount,
  updateChargePermission,
  updateBillingAddress,
  updateCreditCard,
} = paymentSlice.actions;
export const selectPayment = (state) => state.payment;

export default paymentSlice.reducer;
