import React, { useState, useEffect } from "react";
import Loader from "../../../Components/Loader";
import { ConfigProvider, DatePicker, Empty, message, Modal, Select, Spin } from "antd";
import moment from "moment-timezone";
import { map, isEmpty } from "lodash";
import _ from 'lodash';
import { formatToCurrency } from "../../../Utils/formatToCurrency";

import * as yup from "yup";
import axios from "../../../Lib/Axios";
import { getServiceOrderList, getWorkOrderList, getCustomerRepairCode, getServiceOrderByClient, createServiceOrder, getPrices, getAvalaraTaxValue } from "../../../Action/Customer";
import { createManualInvoice, getSingleInvoice, createSingleInvoice } from "../../../Action/Invoice";
import { setBundleVal } from "../../invoiceHelper";

const { Option } = Select;
var confirm = true;
moment.locale('en-us', { week: { dow: 0 } }) // 0-6 (Sunday-Saturday)

const AddNewInvoice = (props) => {
  const [rowsData, setRowsData] = useState([]);
  const [serviceOrderResponse, setServiceOrderResponse] = useState([]);
  const [workOrderSelected, setWorkOrderSelected] = useState([]);
  const [abc, setAbc] = useState({});
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenClearAll, setIsOpenClearAll] = useState(false)
  const [selectedDeleteItem, setSelectedDeleteItem] = useState('');
  const [selectedDeleteRetailItem, setSelectedDeleteRetailItem] = useState({});
  const [selectedService, setSelectedService] = useState('');
  const [serviceOrderUuid, setServiceOrderUuid] = useState('')
  const [newItemModalData, setNewItemModalData] = useState({
    price: 0, fo_adjusted_price: 0, quantity: 1, description: '',
    include_labor: false,
    include_bundle: false
  })
  const [addItemModal, setAddItemModal] = useState(false);
  const [firstRender, setFirstRender] = useState(0);
  const [selectLoading, setSelectLoading] = useState(true);
  const [openSelect, setOpenSelect] = useState(false);
  const [codeOptions, setcodeOptions] = useState([]);
  const [repairCodeValue, setRepairCodeValue] = useState('');
  const [repairCodes, setRepairCodes] = useState([]);
  const [invoiceCustomer, setInvoiceCustomer] = useState({});
  const [taxRate, settaxRate] = useState(1);
  const [newRetailServieName, setNewRetailServieName] = useState(false);


  const [state, setState] = useState({
    loader: false,
    invoice_date: moment(),
    due_date: '',
    rows: [{ service_uuid: '', type: '', date: '', description: '', quantity: 1, price: '', taxChecked: false, index: new Date().getTime(), tax: '', isEditRow: false, isDefault: false }],
    description: '',
    serviceOrderNumberList: [],
    total: '',
    sales_tax: '',
    balance_due: '',
    refund_amount: '',
    quantity: 1,
    amount_received: '0',
    serviceOrder: {},
    addInvoice: {},
    btn_active: false,
    isApprove: false,
    errors: {},
    description_data: [],
    service_order_uuid: '',
    serviceList: [],
    changedLineItemIndex: -1,
    workOrderDetail: {},
    service_uuid: '',
    selectWorkOrder: false,
    invoiceDetail: {},
    showDelete: false,
    selectedDeletedItem: null,
    selectedDeletedType: null,
    deletedWorkOrder: [],
    is_manual_invoice: true,
    isGenerateInvoiceVisible: false,
    workOrderNoList: [],
  });

  function getState() {
    setState({
      loader: false,
      invoice_date: props?.invoiceDetail?.invoice_date || moment(),
      due_date: props?.invoiceDetail?.due_date || '',
      rows: [{ service_uuid: '', type: '', date: '', description: '', quantity: 1, price: '', taxChecked: false, tax: '', isEditRow: false }],
      description: props?.invoiceDetail?.notes || '',
      serviceOrderNumberList: [],
      total: props?.invoiceDetail?.total || '',
      sales_tax: props?.invoiceDetail?.sales_tax || '',
      balance_due: props?.invoiceDetail?.balance_due || '',
      refund_amount: '',
      quantity: 1,
      amount_received: props?.invoiceDetail?.amount_received || '0',
      serviceOrder: {},
      addInvoice: {},
      btn_active: false,
      isApprove: false,
      errors: {},
      description_data: [],
      service_order_uuid: props?.invoiceDetail?.service_order_uuid || '',
      serviceList: [],
      changedLineItemIndex: -1,
      workOrderDetail: {},
      service_uuid: '',
      selectWorkOrder: false,
      invoiceDetail: props?.invoiceDetail || {},
      showDelete: false,
      selectedDeletedItem: null,
      selectedDeletedType: null,
      deletedWorkOrder: [],
      is_manual_invoice: true,
      isGenerateInvoiceVisible: false,
      serviceOrderListTemp: []
    })
    setServiceOrderUuid(props?.invoiceDetail?.service_order_uuid || '')
  }



  const initialLoad = () => {
    const serviceOrderList = async () => {
      const data = await getServiceOrderByClient({
        field_office_uuid: props?.record?.field_office?.uuid ?? props.record.service_order?.field_office_uuid,
        client_uuid: props?.customerId ?? props?.record?.uuid
      });
      let retailService = {};
      let filterData = data?.filter(function (d) {
        if (d.service_order_name === "Retail Invoice") {
          retailService = d;
        }
        if (d.service_type.toLowerCase() === "maintenance" && d.is_invoice_generated === true) {
          return true
        } else if (d.is_invoice_generated === false) {
          return true
        }
      });
      if (!retailService.uuid) {
        setNewRetailServieName(true);
      }
      serviceOrderSelection(filterData);
      setState((state) => (
        {
          ...state,
          serviceOrderNumberList: filterData,
          serviceOrderListTemp: data
        }
      ))
    }

    getTaxValue();
    getState();
    serviceOrderList();
    if (props?.invoiceDetail?.uuid) {
      setLineItems()
    }
  }

  useEffect(() => {
    setInvoiceCustomer(props?.customer);
    initialLoad();
  }, []);

  useEffect(() => {
    if (invoiceCustomer.uuid !== props?.record?.uuid) {
      initialLoad();
    }
  }, [props.record, props?.record?.uuid])

  useEffect(() => {
    if (newRetailServieName) {
      const serviceOrderList = async () => {
        const data = await getServiceOrderByClient({
          field_office_uuid: props?.record?.field_office?.uuid ?? props.record.service_order?.field_office_uuid,
          client_uuid: props?.customerId ?? props?.record?.uuid
        });

        let filterData = data?.filter((d) => {
          if (d.service_type?.toLowerCase() === "maintenance" && d.is_invoice_generated === true) {
            return true
          } else if (d.is_invoice_generated === false) {
            return true
          }
        });
        if (filterData?.[0]?.uuid) {
          const dataWorkOrder = await getSingleInvoice(filterData?.[0]?.uuid);

          setServiceOrderResponse(dataWorkOrder)
        }

        setTimeout(() => {
          serviceOrderSelection(filterData);
        }, 1000);

        setState((state) => (
          {
            ...state,
            serviceOrderNumberList: filterData,
            serviceOrderListTemp: data
          }
        ))
      }

      const createReq = async () => {
        try {
          await createRetailServiceRequest();
          serviceOrderList();
        } catch (e) {
        }
        setNewRetailServieName(false);
      }

      createReq();
    }
  }, [newRetailServieName]);

  useEffect(() => {
    setInvoiceCustomer(props?.customer);
  }, []);

  const serviceOrderSelection = (serviceList) => {
    if (props?.invoice_uuid === '') {
      if (props?.isRetailSale) {
        let retailSelectedService = serviceList?.find((d) => d.service_order_name === "Retail Invoice");
        setTimeout(() => {
          onServiceOrderChange(retailSelectedService?.uuid)
            .then(() => {
            })
            .catch((error) => {
              console.error(error);
            });
        }, 1000);

      } else {
        let filterData = serviceList?.filter(function (d) {
          return d.is_invoice_generated === false && d.show_service_order === true;
        });
        if (filterData?.length === 1) {
          setTimeout(() => {
            onServiceOrderChange(filterData?.[0]?.uuid, serviceList)
              .then(() => {
              })
              .catch((error) => {
                console.error(error);
              });
          }, 1000);
        }
      }
    }
  }

  const modalToggleDelete = (deletedWorkOrder) => {
    setSelectedDeleteItem(deletedWorkOrder);
    setIsOpenDelete(true);
  }

  const modalToggleRetailDelete = (item) => {
    setSelectedDeleteRetailItem(item);
    setIsOpenDelete(true)
  }

  const modalToggleClearAll = () => {
    setIsOpenClearAll(true)
  }

  const createRetailServiceRequest = async () => {
    const retailSaleObj = props?.record;
    const response = await createServiceOrder({
      "status": retailSaleObj?.status,
      "service_address_uuid": retailSaleObj?.service_address[0].uuid,
      "field_office_uuid": retailSaleObj?.field_office.uuid,
      "client_uuid": retailSaleObj?.uuid,
      "is_service": false,
      "is_retail": true,
      "is_hide_from_display": true,
      "proposal_date": null,
      "contract_date": null,
      "preferred_start_date": null,
      "service_type_uuid": null,
      "is_terms_accepted": true,
      "pool_type_uuid": "",
      "pool_size": null,
      "frequency_settings": null,
      "is_flat_rate": false,
      "price": 0,
      "service_price": 0,
      "client_type": retailSaleObj?.client_type,
    });
    return response;
  }

  const createLabor = (lineItem) => {
    let taxData = amount(((lineItem.quantity * lineItem.totallabor) * taxRate) / 100);
    const laborLine = {
      type: "Retail Invoice",
      work_order_uuid: serviceOrderUuid,
      repair_code: lineItem.repair_code + "-Labor",
      description: "LBR-" + lineItem.description,
      is_taxable: true,
      unit_cost: lineItem.totallabor ? lineItem.totallabor : 0,
      quantity: lineItem.quantity,
      price: (lineItem.quantity * lineItem.totallabor),
      total: (lineItem.quantity * lineItem.totallabor) + Number(taxData),
      qb_account: lineItem.qb_account,
      isDefault: false,
      tax: taxData,
      taxRate: taxRate,
      index: new Date().getTime(),
    }
    rowsData.push(laborLine);
    setRowsData(rowsData);

    // let state_items = state.services_lineitem;
    // state_items.push(laborLine);

    // setState((s) => ({ ...s, services_lineitem: state_items }));

    // let items = services_lineitem;
    // const isAdded = items.indexOf(laborLine);
    // if(isAdded < 0)
    //   items.push(laborLine);
    // setservices_lineitem(items);

    // CalculateTotal();
    // calculateNoTax();
    // calculateTax();
  }

  const createBundle = (lineItem) => {
    const item_unitCost = parseFloat(lineItem.fo_adjusted_price);
    const labor_unitCost = lineItem.totallabor ? lineItem.totallabor : 0;

    const item_total = lineItem.fo_adjusted_price * lineItem.quantity;
    const labor_total = (lineItem.quantity * lineItem.totallabor)
    let taxData = ((+taxRate / 100) * (lineItem.fo_adjusted_price * lineItem.quantity)).toFixed(2).toString();
    let taxDataLabor = amount(((lineItem.quantity * lineItem.totallabor) * taxRate) / 100);
    const bundleLine = {
      type: "Retail Invoice",
      work_order_uuid: serviceOrderUuid,
      repair_code: lineItem.repair_code,
      bundle_code: lineItem.repair_code,
      description: lineItem.description,
      is_taxable: true,
      unit_cost: item_unitCost + labor_unitCost,
      quantity: lineItem.quantity,
      price: item_total + labor_total,
      total: item_total + labor_total,
      qb_account: lineItem.qb_account,
      isDefault: false,
      tax: Number(taxData) + Number(taxDataLabor),
      taxRate: taxRate,
      index: new Date().getTime(),
      disabled: true,
    }

    rowsData.push(bundleLine);
    setRowsData(rowsData);
    // let state_items = state.services_lineitem;
    // state_items.push(bundleLine);

    // setState((s) => ({ ...s, services_lineitem: state_items }));

    // let items = services_lineitem;
    // const isAdded = items.indexOf(bundleLine);
    // if (isAdded < 0)
    //   items.push(bundleLine);

    // setservices_lineitem(items);

    // if (bundleLine.is_taxable) {
    //   applyTax(bundleLine, false, true);
    // }else if(lineItem.is_taxable || lineItem.apply_labor_tax){
    //   applyBundleTax(bundleLine);
    // }

    // CalculateTotal();
    // calculateNoTax();
    // calculateTax();
  }


  const handleNewItemModalChange = (e) => {
    const { id, value } = e.target;
    let price = newItemModalData.price;
    if (id === "quantity") {
      if (value > 0) {
        price = newItemModalData.fo_adjusted_price * parseInt(value);
        setNewItemModalData({ ...newItemModalData, [id]: parseInt(value), price });
      }
    } else if (id === "fo_adjusted_price") {
      if (value > 0) {
        price = newItemModalData.quantity * parseFloat(value);
        setNewItemModalData({ ...newItemModalData, [id]: value, price });
      }
    } else {
      setNewItemModalData({ ...newItemModalData, [id]: value, price });
    }
  }

  const cancelStateData = () => {
    setNewItemModalData({ price: 0, fo_adjusted_price: 0, quantity: 1, description: '' });
    setAddItemModal(false);
  }

  const setLineItems = () => {
    const rows = []
    map(props?.invoiceDetail?.line_items, (x) => {
      rows.push({
        uuid: x.uuid,
        service_uuid: x?.service_uuid,
        type: x.item_type,
        date: x.date,
        description: x.description,
        rate: x.rate,
        price: x.rate,
        quantity: x.quantity,
        tax: x?.tax ? parseFloat(x?.tax).toFixed(2) : 0,
        is_taxable: x?.is_taxable ? x.is_taxable : true,
        isEditRow: false,
        other_description: x.sub_description,
        service_code: x.description.toLowerCase() === "maintenance" ? '--' : x.service_code,        
        total: +(+x?.unit_cost || +x?.rate * +x?.quantity).toFixed(2) + +parseFloat(x?.tax).toFixed(2),
        service_type: x.service_type,
        worktype: x.worktype,
        worksubtype: x.worksubtype,
        is_flat_rate: x.is_flat_rate,
        service_order_number: x?.service_order_number
      })
    })
    setRowsData(rows)
    setState((state) => ({
      ...state,
      rows
    }))

  }

  const toggleDeleteModal = (item, type) => {
    setState({ ...state, selectedDeletedItem: item, selectedDeletedType: type });
    setState((prevState) => ({ ...state, showDelete: !prevState.showDelete }));
    setAbc(item);
    setSelectedService(item);
  }
  const search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        return myArray[i];
      }
    }
  }

  const deleteWorkOrder = (index) => {
    if (selectedDeleteRetailItem.index) {
      let filterRowsData = [];
      if (selectedDeleteRetailItem.repair_code.includes("Labor")) {
        filterRowsData = rowsData.filter((f) => f.repair_code !== selectedDeleteRetailItem.repair_code && f.repair_code !== f.bundle_code);
      } else {
        filterRowsData = rowsData.filter((f) => f.index !== selectedDeleteRetailItem.index);
      }
      setRowsData(filterRowsData);
      setSelectedDeleteRetailItem({});
    } else if (selectedDeleteItem) {
      onRowDataChange(selectedDeleteItem);
      let filterRowsData = rowsData.filter((f) => f.work_order_number !== selectedDeleteItem);
      let updatedWorkOrder = [];
      workOrderSelected.map((each) => {
        if (each.work_order_number !== selectedDeleteItem) {
          updatedWorkOrder.push(each)
        }
      })
      setWorkOrderSelected(updatedWorkOrder);
      setSelectedDeleteItem('');
      setRowsData(filterRowsData);
      calculateTotal(filterRowsData);
    }
    else {
      const filterRowsData = rowsData.filter((f) => f.index !== index)
      setRowsData(filterRowsData)
      calculateTotal(filterRowsData)
    }
    setIsOpenDelete(false);
  }

  // add line items
  const addLineItems = () => {
    const { addInvoiceLineItem, onChangeView, getInvoiceList } = props;
    const { addInvoice, rows } = state
    let arr = [];
    setState((s) => ({ ...s, loader: true }))
    map(rows, x => {
      // const per_idx = x?.tax?.indexOf('%');
      // const org_tax = 2.90
      // const org_tax = x?.taxChecked ? per_idx > -1 ? x?.tax?.replace('%', '') : x?.tax : 0;
      // const tax = (parseFloat(x.price) * (org_tax / 100))
      arr.push(addInvoiceLineItem({
        service_uuid: x?.service_uuid,
        item_type: x.type,
        date: x.date ? localToUTC(x.date).format('YYYY-MM-DD') : null,
        description: x.description,
        sub_description: x?.description === 'other' ? x.other_description : '',
        price: x.price,
        quantity: x.quantity ? x.quantity : 1,
        // tax: tax || 0,
        // tax_percent: x.tax || 0,
        invoice_uuid: addInvoice?.uuid
      }))
    })

    Promise.all(arr).then((response) => {
      if (!isEmpty(response)) {
        onChangeView(0)
        getInvoiceList()
      }
    })
  }

  // convert date time from utc to local time
  const UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  const localToUTC = (date) => {
    return moment.utc(date)
  }

  // validate numeric input
  const onNumericInputChange = (value) => {
    const reg = /^-?\d*(\.\d*)?$/;
    return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
  };

  // on invoice detail change
  const onTextChange = (e, type) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    setState((state) => (
      {
        ...state,
        [e.target.name]: value
      }
    ))
  }

  const isGenerateButtonDisable = () => {
    if (rowsData.length > 0) {
      if (rowsData.find((each) => each.description !== '' && each.price !== '' && each.tax !== '')) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  const renderWorkOrder = (workOrderArray) => {
    const resultArray = Array.isArray(workOrderArray) ? workOrderArray?.filter((f) => ((f.status === 'complete') || (f.status === "cancelled")) && f.is_invoiced === false) : []
    if (workOrderSelected.length > 0) {
      let selectedWorkOrderNo = workOrderSelected.map((each) => each.work_order_number)
      let res = resultArray.filter((r) => !selectedWorkOrderNo.includes(r.work_order_number))
      return res;
    } else {
      return resultArray;
    }
  }

  // on item row change
  const onRowDataChange = (e, index, type) => {
    const { rows, workOrderNoList } = state;
    let tempRows = [...rowsData]?.filter((f) => f.type !== "");
    let isExist = false;
    let selectedWorkOrder = [];
    if (e.target) {
      selectedWorkOrder = serviceOrderResponse.filter((f) => f.work_order_uuid === e.target.value)?.[0]
    } else
      selectedWorkOrder = serviceOrderResponse.filter((f) => f.work_order_number === e)
    setWorkOrderSelected([...workOrderSelected, selectedWorkOrder])
    selectedWorkOrder?.lineitems?.map((x) => {
      tempRows.push({
        uuid: x?.uuid,
        service_uuid: x?.work_order_uuid,
        type: selectedWorkOrder?.servicetype,
        date: x?.date,
        description: x?.description,
        price: x?.unit_cost || 0,
        quantity: x?.quantity || 1,
        // taxChecked: x?.tax_percent ? true : false,
        tax: x?.tax || '0',
        isEditRow: true,
        other_description: x?.sub_description,
        service_code: x?.service_code,        
        total: +(+x?.unit_cost || +x?.rate * +x?.quantity).toFixed(2) + +parseFloat(x?.tax).toFixed(2),
        service_info: x,
        isDefault: false,
        work_order_number: selectedWorkOrder?.work_order_number,
        invoice_date: state.invoice_date,
        index: new Date().getTime(),
        service_order_number: x?.service_order_number
      })
    })
    let tempRows2 = tempRows.slice(1)
    let defaultObj = tempRows[0]
    if (tempRows2?.[0]?.type?.toLowerCase() !== "maintenance") {
      tempRows2.forEach((each) => {
        if (each?.type?.toLowerCase() === defaultObj?.service_info?.service_type?.toLowerCase()) {
          isExist = true;
        }
      })
    }
    if (isExist) {
      tempRows = [...tempRows2];
    }
    // calculateTotal(tempRows)
    if (tempRows.some(tempRows => e.target ? tempRows.service_uuid === e.target.value : tempRows.service_order_uuid === selectedWorkOrder[0].service_order_uuid
    )) {
      let arr = tempRows.filter(function (obj) {
        return obj.type !== '';
      });
      setState({
        ...state,
        rows: tempRows
      })
      calculateTotal(tempRows)
      setRowsData(tempRows)
      // alert("You have already selected that Work Order! Please select another Work Order!");
    }
    // else{
    //   const value = type === 'bool' ? e.target.checked : e.target.value;
    //   Object.assign(tempRows[index], { [e.target.name]: value, work_order: value })
    //   // rows.push({ service_uuid: e.target.value, type: "service", date: '', description:'',quantity:1, price: 0, taxChecked: false, tax: 0, sub_description:'',isEditRow:true })
    //   setState((state)=>(
    //     { 
    //     ...state,
    //       rows, changedLineItemIndex: index 
    //     }
    //   ))
    //   //   , () => {
    //   //   const {rows} = state;
    //   //   // if (rows[index].service_uuid && rows[index].price && rows[index].type) {
    //   //   //   props.getSalesTax({service_uuid: rows[index].service_uuid, amount: rows[index].price*(rows[index].quantity?rows[index].quantity:1), item_type: rows[index].type})
    //   //   // } else if (isEmpty(rows[index].price)) {
    //   //   //   rows[index].tax = 0
    //   //   //   setState({rows}, () => calculateTotal())
    //   //   // }
    //   // })
    //   if(e.target.name==="service_uuid"){
    //     setState((state)=>({
    //       ...state,
    //       service_uuid:e.target.value,
    //       selectWorkOrder:true,
    //       // loader:true
    //     }))
    //     const params = filterParams(e.target.value);
    //     // props.getWorkOrderDetail(params)
    //   }
    // }
  }

  //Remove null object properties
  const filterParams = (params) => {
    for (var propName in params) {
      if (params[propName] === "" || params[propName] === null || params[propName] === "null" || params[propName] === undefined || params[propName] === "undefined") {
        delete params[propName];
      }
    }
    return params
  }
  // calculate total
  const calculateTotal = (rowsData) => {
    // This will be called from useEffect similar to retail
    let { amount_received, credit_memo_amount_applied } = state;
    let new_total = 0, salesTax = 0, balance_due = state?.balance_due || 0, quantity = 1, refund_amount = 0;
    map(rowsData, x => {
      salesTax = parseFloat(salesTax) + parseFloat(x?.tax)      
      new_total = parseFloat(new_total) + +(parseFloat(x?.rate || x?.price) * parseFloat(x?.quantity || 1)).toFixed(2)
      // balance_due = parseFloat(balance_due) + ((parseFloat(x.price || 0)*parseInt(x.quantity || 1)) + parseFloat(x.tax || 0))
      quantity = parseInt(x.quantity || 1)
      refund_amount = parseFloat(refund_amount) + parseFloat(x.refund_amount ? x.refund_amount : 0)
    })
    balance_due = (parseFloat(new_total) + parseFloat(salesTax)) - parseFloat(amount_received);
    setState((s) => ({ ...s, balance_due, total: new_total, sales_tax: salesTax, quantity: quantity, refund_amount: refund_amount.toFixed(2) }))
  }
  // on delete row
  const onDeleteRow = (item) => {
    if (item === "clear") {
      onClearAll();
      setState((prevState) => ({ ...state, showDelete: !prevState.showDelete }));
      // setTimeout(() => { toggleDeleteModal() }, 500)
    } else {
      let deleteRow = state.rows.filter((f) => f.index !== item.index);
      setState({ ...state, rows: deleteRow });
      setRowsData(deleteRow);
      // const { rows,deletedWorkOrder } = state;
      // let DW  = deletedWorkOrder;
      // let filterWorkOrder = rows.filter(function( obj ) { 
      //   return obj.service_uuid ===item.service_uuid;
      // });
      // DW = DW.concat(filterWorkOrder);
      // let filterRows = rows.filter(function( obj ) { 
      //   return obj.service_uuid !==item.service_uuid;
      // });
      // if(filterRows.length===0){
      //   filterRows =  [{ service_uuid: '', type: '', date: '', description: '',quantity:1, price: '', taxChecked: false, tax: '',isEditRow:true }]
      // }
      // setState((state)=>({...state, rows:filterRows , deletedWorkOrder:DW }), () => { calculateTotal(); })
      // setTimeout(() => { toggleDeleteModal() }, 500)
      // setState((state)=>({...state,isGenerateInvoiceVisible:true}))
    }
  }

  const onDiscardServiceOrder = (e) => {
    setState({
      ...state,
      service_order_uuid: selectedService
    })
    setServiceOrderUuid(selectedService);
    const getInvoiceData = async () => {
      const data = await getSingleInvoice(selectedService);
      let workOrderNoList = data
      setState((state) => ({ ...state, workOrderNoList: workOrderNoList }))
      setServiceOrderResponse(workOrderNoList)
    }
    getInvoiceData()
    onServiceOrderChange(selectedService)
    setTimeout(() => { toggleDeleteModal() }, 500)
  }

  const handleAddItemClick = () => {
    const { serviceOrderNumberList } = state;
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid);
    if (service?.service_order_name === "Retail Invoice") {
      setAddItemModal(true);
    } else {
      handleTableData();
    }
  }

  useEffect(() => {
    calculateRetailPrice();
  }, [rowsData.length]);

  const calculateRetailPrice = () => {
    const { serviceOrderNumberList } = state;
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid);
    if (service?.service_order_name === "Retail Invoice") {
      let balance_due = 0,
        total = 0,
        sales_tax = 0;
      rowsData.forEach((item) => {
        if (!item?.bundle_code) {
          balance_due += item.total;
          total += item.price;
          if (item.is_taxable) {
            sales_tax += parseFloat(item?.tax || 0);
          }
        }
      });

      setState((state) => ({
        ...state,
        balance_due,
        total,
        sales_tax,
      }));
    }
  }

  const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const getTaxValue = async () => {
    const response = await getAvalaraTaxValue(props?.customer?.service_address[0]?.zip_code);
    if (response?.tax_rate === 0) {
      settaxRate(amount(1));
    } else {
      settaxRate(amount(response?.tax_rate ?? taxRate));
    }
  }

  const handleTableData = () => {
    const { rows, serviceOrderNumberList } = state;
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid);
    let taxData = amount(((newItemModalData.quantity * newItemModalData.fo_adjusted_price) * taxRate) / 100);

    if (service?.service_order_name === "Retail Invoice") {
      rowsData.push({
        "type": "Retail Invoice",
        "repair_code": newItemModalData.repair_code,
        "description": newItemModalData.description,
        "is_taxable": true,
        "tax": taxData,
        "unit_cost": newItemModalData.fo_adjusted_price,
        "quantity": newItemModalData.quantity,
        "price": newItemModalData.quantity * newItemModalData.fo_adjusted_price,
        "qb_account": newItemModalData.qb_account,
        "is_lineitem_invoiced": false,
        "index": new Date().getTime(),
        "total": ((newItemModalData.quantity * newItemModalData.fo_adjusted_price) + parseFloat(taxData)),
        "isDefault": false,
        "taxRate": taxRate,
      });
      setNewItemModalData({ price: 0, fo_adjusted_price: 0, quantity: 1, description: '', include_labor: false });
      setAddItemModal(false);
      setcodeOptions([]);
      setRepairCodeValue('');
      if (newItemModalData.include_labor) {
        createLabor(newItemModalData);
        if (newItemModalData.include_bundle) {
          createBundle(newItemModalData);
        }
      }
    } else {
      rowsData.push({ service_uuid: '', type: '', date: '', description: '', quantity: 1, price: '', taxChecked: false, tax: '', index: new Date().getTime(), isDefault: false });
    }
    setRowsData(rowsData);
    setState((state) => (
      {
        ...state,
        rows: rowsData,
      }
    ));
  }
  const datafilter = serviceOrderNumberList?.filter((item) => {
    return item?.display_for_invoice == true || item?.service_order_name == "Retail Invoice"
  });
  const applyTaxCheck = (e, item) => {
    let newRowData = rowsData.map((row) => {
      if (row.index === item.index) {
        if (e.target.checked) {
          let taxData = amount(((item.price) * parseFloat(item.taxRate)) / 100);
          item.tax = taxData
          item.total = (item.price + parseFloat(taxData))
        } else {
          item.tax = 0
          item.total = item.price
        }
        item.is_taxable = e.target.checked
      }
      return row
    });

    if (newRowData.filter(item => item.bundle_code).length > 0) {
      let getBundleData = setBundleVal(newRowData);
      setRowsData(getBundleData);
    }
    else setRowsData(newRowData);
    calculateRetailPrice();
  }

  const handleChange = event => {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    const filteredVal = event.target.value.match(regex)[0];
    settaxRate(filteredVal);
  }
  
  // on add item
  const onClearAll = () => {
    let filterData = rowsData?.filter((f) => f.isDefault !== false)
    setRowsData(filterData)
    calculateTotal(filterData)
    let filterDataWithWo = rowsData?.filter((f) => Object.keys(f).includes('work_order_number'))
    if (filterDataWithWo && filterDataWithWo.length > 0) {
      setRowsData([])
      calculateTotal([])
    }

    setWorkOrderSelected([])
    setIsOpenClearAll(false)
  }

  // on service order change
  const onServiceOrderChange = async (e, serviceListdata) => {
    let uuid = e;
    const rows = []
    const WorkRows = []
    const getInvoiceData = async () => {
      const data = await getSingleInvoice(e);
      // let workOrderNoList = renderWorkOrder(data)
      let workOrderNoList = data
      setState((state) => ({ ...state, workOrderNoList: workOrderNoList }))
      const woArray = Array.isArray(workOrderNoList) ? workOrderNoList?.filter((f) => ((f.status === 'complete') || (f.status === "cancelled")) && (f.is_invoiced === false)) : []
      setServiceOrderResponse(workOrderNoList)
      setWorkOrderSelected(workOrderNoList);
      map(woArray, (elem) => {
        if ((elem.lineitems).length > 0) {
          elem?.lineitems?.map((x) => {
            WorkRows.push({
              uuid: x?.uuid,
              service_uuid: x?.work_order_uuid,
              type: elem?.servicetype,
              date: x?.date,
              description: x?.description,
              price: x?.unit_cost || 0,
              quantity: x?.quantity || 1,
              // taxChecked: x?.tax_percent ? true : false,
              tax: x?.tax || '0',
              isEditRow: true,
              other_description: x?.sub_description,
              service_code: x?.service_code,              
              total: +(+x?.unit_cost || +x?.rate * +x?.quantity).toFixed(2) + +parseFloat(x?.tax).toFixed(2),
              service_info: x,
              isDefault: false,
              work_order_number: elem?.work_order_number,
              invoice_date: state.invoice_date,
              index: new Date().getTime(),
              service_order_number: x?.service_order_number
            })
          })
        }
        else {
          WorkRows.push({
            ...WorkRows,
            uuid: elem?.lineitems[0]?.uuid,
            service_uuid: elem?.service_order_uuid,
            type: elem?.servicetype,
            date: elem?.date,
            description: elem?.lineitems[0]?.description,
            // description:"Swimming Pool/Maintenance - Flat Rate",
            price: elem?.price || 0,
            quantity: elem?.lineitems[0]?.quantity || 1,
            // taxChecked: x?.tax_percent ? true : false,
            tax: elem?.lineitems[0]?.tax || '0',
            isEditRow: true,
            // other_description: x?.sub_description,
            // service_code : x?.service_code,
            total: elem?.price,
            // service_info:x,
            isDefault: true,
            workOrder: elem?.work_order_number,
            index: new Date().getTime()
          })
        }
      })
      setState((state) => (
        {
          ...state,
          WorkRows,
          service_order_uuid: e
        }
      ))
      setServiceOrderUuid(e);
      let new_total = 0, salesTax = 0, balance_due = 0;
      map(WorkRows, x => {
        salesTax = parseFloat(salesTax) + parseFloat(x?.tax)
        new_total = parseFloat(new_total) + (parseFloat(x?.price || 0) * parseInt(x?.quantity || 1))
        balance_due = parseFloat(balance_due) + ((parseFloat(x?.price || 0) * parseInt(x?.quantity || 1)) + parseFloat(x?.tax || 0))
      })
      setState((state) => (
        {
          ...state,
          balance_due,
          total: new_total,
          sales_tax: salesTax
        }
      ))
    }
    await getInvoiceData();

    let { serviceOrderNumberList } = state;
    if (serviceListdata) {
      serviceOrderNumberList = serviceListdata;
    }
    const serviceList = serviceOrderNumberList?.filter(x => x.uuid === e)
    map(serviceList, (x) => {
      if (x?.service_type?.toLowerCase() === 'maintenance' && x?.is_invoice_generated === false) {
        let des = `${x?.worktype}/${x?.service_type}-${x?.is_flat_rate ? "Flat Rate" : ''}`
        const taxesVal = x.is_apply_tax
          ? x.tax
            ? x.tax_amount + x.tax
            : x.tax_amount
          : "0"
        let taxes = taxesVal !== "0" ? taxesVal.toFixed(2) : taxesVal;
        rows.push({
          uuid: x.uuid,
          service_uuid: x?.service_uuid,
          type: x?.service_type,
          date: x.date,
          description: des,
          // description:"Swimming Pool/Maintenance - Flat Rate", 
          price: x?.is_recurring ? x?.service_price : x?.price || 0,
          quantity: x.quantity || 1,
          // taxChecked: x.tax_percent ? true : false,
          tax: taxes,
          isEditRow: true,
          other_description: x.sub_description,
          service_code: x.service_code,
          total: x?.is_recurring ? x?.service_price + parseFloat(taxes) : x?.price ? x?.price + parseFloat(taxes) : 0,
          service_info: x,
          isDefault: true,
          work_order_number: "--",
          index: new Date().getTime(),
          service_order_number: x?.service_order_number
        })
      }
    })
    setRowsData(rows)
    setState((state) => (
      {
        ...state,
        rows,
        service_order_uuid: uuid
      }
    ))
    setServiceOrderUuid(uuid)
    let new_total = 0, salesTax = 0, balance_due = 0;
    map(rows, x => {
      salesTax = parseFloat(salesTax) + parseFloat(x?.service_info?.tax)
      new_total = parseFloat(new_total) + (parseFloat(x?.service_info?.price || 0) * parseInt(x?.service_info?.quantity || 1))
      balance_due = parseFloat(balance_due) + ((parseFloat(x?.service_info?.price || 0) * parseInt(x?.service_info?.quantity || 1)) + parseFloat(x.tax || 0))
    })
    setState((state) => (
      {
        ...state,
        balance_due,
        total: new_total,
        sales_tax: salesTax
      }))
    let filterData = rows?.filter((f) => f.isDefault !== false)
    const finalArr = WorkRows.concat(filterData)
    calculateTotal(finalArr)
    setRowsData(finalArr)
  }

  // validate required fields
  const validate = () => {
    const { rows, invoice_date, due_date, service_order_uuid } = state;

    const schema = yup.object().shape({
      // service_order_uuid: yup.string().required('Service order# is required'),
      serviceOrderUuid: yup.string().required('Service order# is required'),
      // rows: yup.array(yup.object({
      // service_uuid: yup.string().required('Service#  is required'),
      // type: yup.string().required('Type is required'),
      // date: yup.string().required('Date is required'),
      // description: yup.string().required('Description is required'),
      // other_description: yup.string().when('description', {
      //   is: 'other',
      //   then: yup.string().required('Other Description is required'),
      // }),
      // price: yup.string().test('match', 'Price should have a valid format', (price) => {
      //   return !isNaN(parseFloat(price))
      // })
      // })),
      invoice_date: yup.string().required('Invoice Date is required'),
      due_date: yup.string().required('Due Date is required').test("compare", "Invalid due date", (due_date) => moment(state.invoice_date).isBefore(moment(due_date))),
    });

    const schema1 = yup.object().shape({
      // service_order_uuid: yup.string().required('Service order# is required'),
      serviceOrderUuid: yup.string().required('Service order# is required'),
      invoice_date: yup.string().required('Invoice Date is required'),
      due_date: yup.string().required('Due Date is required').test("compare", "Invalid due date", (due_date) => moment(state.invoice_date).isBefore(moment(due_date))),
    });

    const data = { serviceOrderUuid, rows, invoice_date, due_date };


    setTimeout(() => {
      props.invoice_uuid ? schema1.validate(data, { abortEarly: false }).then(() => {
        setState((state) => (
          {
            ...state,
            btn_active: true, errors: {}
          }
        ))
        onSave();
      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => { err_data[item.path] = item.message })
        setState((state) => (
          {
            ...state,
            errors: err_data, is_btn_active: false
          }))
      }) : schema.validate(data, { abortEarly: false }).then(() => {
        setState((state) => (
          {
            ...state,
            btn_active: true, errors: {}, loader: true
          }
        ))
        generateInvoice()
        // onSave();
      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => { err_data[item.path] = item.message })
        // console.log(err_data)
        setState((state) => (
          { ...state, errors: err_data, is_btn_active: false }
        ))
      });
    }, 500)
  };

  // on save
  const onSave = () => {
    const { invoice_date, due_date, description, serviceOrder, quantity, balance_due, amount_received, service_order_uuid, rows, deletedWorkOrder, isApprove } = state;
    const { addInvoice, customerId, addManualInvoice } = props;
    const address = !isEmpty(serviceOrder?.billing_address) ? serviceOrder?.billing_address : !isEmpty(serviceOrder?.service_address) ? serviceOrder?.service_address : {};

    if (!address?.geo_location) {
      delete address?.geo_location
    }
    map(rows, (r, i) => {
      r.date = localToUTC(due_date).format('YYYY-MM-DD')
      r.item_type = r.type
    })
    let uniqueRows = [...new Set(rows.map(item => item.service_uuid))];
    let workOrders = [];
    map(uniqueRows, (ur, i1) => {
      let obj = {};
      obj.work_order = ur;
      let lineItems = rows.filter(function (obj) {
        return obj.service_uuid === ur;
      });
      obj.isEditRow = lineItems[0].isEditRow;
      obj.line_items = lineItems;
      workOrders.push(obj);
    });
    let deleteFilter = deletedWorkOrder.filter(function (df) {
      return df.isEditRow === false;
    });
    let DelWorkOrder = _.uniqBy(deleteFilter, 'service_uuid');
    DelWorkOrder = _.map(DelWorkOrder, 'service_uuid');
    let isInvoiceProcessed = false;
    if (props.invoice_uuid === '' && isApprove === false) {
      isInvoiceProcessed = false;
    }
    if (props.invoice_uuid === '' && isApprove === true) {
      isInvoiceProcessed = true;
    }
    if (props.invoice_uuid !== '' && isApprove === false) {
      isInvoiceProcessed = false;
    }
    if (props.invoice_uuid !== '' && isApprove === true) {
      isInvoiceProcessed = true;
    }
    addManualInvoice({
      user_uuid: customerId,
      invoice_date: invoice_date ? localToUTC(invoice_date).format('YYYY-MM-DD') : null,
      due_date: due_date ? localToUTC(due_date).format('YYYY-MM-DD') : null,
      // total: parseFloat(total || '0').toFixed(2),
      balance_due: parseFloat(balance_due || '0').toFixed(2),
      quantity: quantity,
      amount_received: parseFloat(amount_received || '0').toFixed(2),
      // sales_tax: parseFloat(sales_tax || '0').toFixed(2),
      status: 'open',
      billing_address: address,
      billing_type: 'email',
      notes: description,
      service_order_uuid: serviceOrderUuid,
      invoice_uuid: props.invoice_uuid ? props.invoice_uuid : null,
      is_process: isInvoiceProcessed,
      work_orders: workOrders.filter(function (wo) {
        return wo.isEditRow === true && wo.work_order !== "";
      }),
      delete_work_orders: props.invoice_uuid ? DelWorkOrder : []
    })
    // addInvoice({
    //   user_uuid: customerId,
    //   invoice_date: invoice_date ? localToUTC(invoice_date).format('YYYY-MM-DD') : null,
    //   due_date: due_date ? localToUTC(due_date).format('YYYY-MM-DD') : null,
    //   // total: parseFloat(total || '0').toFixed(2),
    //   balance_due: parseFloat(balance_due || '0').toFixed(2),
    //   quantity:quantity,
    //   amount_received: parseFloat(amount_received || '0').toFixed(2),
    //   // sales_tax: parseFloat(sales_tax || '0').toFixed(2),
    //   status: 'open',
    //   billing_address: address,
    //   billing_type: 'email',
    //   notes: description,
    //   service_order_uuid: service_order_uuid,
    // })
    setState({ description_data: [], selectWorkOrder: false, loader: true })
  }

  const generateInvoice = () => {
    const { invoice_date, due_date, service_order_uuid, description, balance_due } = state
    let uniqueRows = [...new Set(rowsData.map(item => item?.work_order_number))];
    let workOrders = [];
    map(serviceOrderResponse, (ur, i1) => {
      if (uniqueRows.includes(ur?.work_order_number)) {
        workOrders.push(serviceOrderResponse[i1])
      }
    });
    const createInvoice = async (obj) => {
      const data = await createManualInvoice(obj);
      if (data.status) {
        if (data.status === 400) {
          setState((state) => ({
            ...state,
            loader: false
          }))
          props.onChangeView(0);
          props.getOverdueOpen();
          message.info(`${data.data.message}`, 10);
        } else {
          setState((state) => ({
            ...state,
            loader: false
          }))
          props.onChangeView(0);
          props.getOverdueOpen();
          message.error("Something went wrong..!", 10);
        }
      }
      if (data.invoice_uuid) {
        setState((state) => ({
          ...state,
          loader: false
        }))
        props.onChangeView(0);
        props.getOverdueOpen();
        message.success(`${data.message}`, 10);
      }
    }
    let obj = {};
    if (rowsData[0].type === "Retail Invoice") {
      obj.user_uuid = props?.record?.uuid;;
      obj.invoice_date = localToUTC(invoice_date).format('YYYY-MM-DD');
      obj.due_date = localToUTC(due_date).format('YYYY-MM-DD');
      obj.invoice_type = "retail";
      obj.quantity = rowsData.length;
      obj.billing_type = "email";
      obj.notes = description;
      obj.service_order_uuid = serviceOrderUuid;
      obj.invoice_uuid = null;
      obj.is_process = false;
      obj.lineitems = rowsData.map((item) => ({
        bundle_code: item?.bundle_code || null,
        repair_code: item.repair_code,
        description: item.description,
        is_taxable: item.is_taxable,
        tax: parseFloat(item.tax || 0),
        unit_cost: item.unit_cost,
        quantity: item.quantity,
        price: item.price ? item.price : item.total,
        qb_account: item.qb_account,
        is_lineitem_invoiced: false,
      }));
      obj.delete_work_orders = [];
      obj.balance_due = balance_due;
    } else {
      obj.user_uuid = props?.record?.uuid;
      obj.invoice_date = localToUTC(invoice_date).format('YYYY-MM-DD');
      obj.due_date = localToUTC(due_date).format('YYYY-MM-DD');
      obj.quantity = 1;
      obj.billing_type = "email";
      obj.notes = description;
      obj.service_order_uuid = serviceOrderUuid;
      obj.invoice_uuid = props.invoice_uuid ? props.invoice_uuid : null;
      obj.is_process = false;
      obj.work_orders = workOrders;
      obj.delete_work_orders = [];
      obj.balance_due = balance_due
    }
    createInvoice(obj);
  }

  // render Item Row
  const renderItemRow = (item, index) => {
    const is_payment_recieved = Number(state.amount_received || "0") > 0
    const { serviceList, errors, description_data, serviceOrderNumberList } = state;
    // let workOrder = serviceList;
    let workOrder = serviceList ? serviceList.find(element => element.uuid === item.service_uuid) : null;
    var getKeys = _.map(state.rows, 'service_uuid');
    getKeys = [...new Set(getKeys)];
    let filterServiceList = serviceList ? serviceList.filter(x => !getKeys.includes(x.uuid)) : [];
    const is_not_editable = ((is_payment_recieved) && ((is_payment_recieved) && state.invoiceDetail?.status !== 'draft') || state.invoiceDetail?.status === 'open');
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid);
    return (
      <div className="divrow editrow" key={`items${index}`} style={{ marginLeft: "0px" }}>
        {
        <div className="dcell serviceno">{item?.service_order_number}</div>
        }
        {
          item ? <div className="dcell sno">{item?.service_code === '-' ? 'N/A' : item?.service_code}</div>
            :
            <div className="dcell sno">
              <Select
                disabled={item?.service_uuid ? true : false}
                dropdownClassName="sm-filter"
                showArrow
                name={'service_uuid'}
                virtual={false}
                style={{ width: '100%' }}
                value={item?.service_uuid ? item?.service_uuid : undefined}
                placeholder={'Select'}
                onChange={(e) => {
                  onRowDataChange({ target: { name: 'service_uuid', value: e } }, index)
                }}
              >
                {map(filterServiceList, (obj) => <Option value={obj?.uuid} key={obj?.uuid}>{obj?.work_order_number}</Option>)}
              </Select>
              {errors?.[`rows[${index}].service_uuid`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].service_uuid`]}</label> : ''}
            </div>
        }
        <div className="dcell sdate">{state.invoice_date ? localToUTC(state.invoice_date).format('YYYY-MM-DD') : null || "--"}
          {/* <DatePicker
            disabled={true}
            inputReadOnly
            style={{ 'width': '100%' }}
            name='date'
            format="MM/DD/YYYY"
            allowClear={false}
            value={item?.date ? UTCToLocal(item?.date) : null}
            onChange={(e) => onRowDataChange({ target: { name: 'date', value: e } }, index)}
          />
          {errors?.[`rows[${index}].date`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].date`]}</label> : ''} */}
        </div>
        <div className="dcell stype">{item?.service_type ? item?.service_type : "Retail Invoice"}
          {/* <Select
            disabled={true}
            dropdownClassName="sm-filter"
            showArrow
            name={'type'}
            virtual={false}
            style={{ width: '100%' }}
            value={item?.type ? item?.type : undefined}
            placeholder={'Product/Service'}
            onChange={(e) => { onRowDataChange({ target: { name: 'type', value: e } }, index) }}
          >
            <Option value="product">Product</Option>
            <Option value="service">Service</Option>
          </Select>
          {errors?.[`rows[${index}].type`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].type`]}</label> : ''} */}
        </div>
        <div className="dcell sdesc">{item?.description ? item?.description : "--"}
          {/* <Select
            disabled={true}
            value={item.description ? item.description : "Please Select"}
            placeholder="Please Select"
            onChange={(e) => { onRowDataChange({ target: { name: 'description', value: e } }, index) }}
          >
            {description_data?.data?.filter(data => data?.type === item?.type).map((item) => {
              return (
                <Option key={item.item}>{item.item}</Option>
              )
            })}
            <Option key="other">Other</Option>
          </Select>
          {errors?.[`rows[${index}].description`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].description`]}</label> : ''}
          {item.description === "other" ?
            <>
              <textarea
                disabled={true}
                className="sdesc mt-20"
                value={item.other_description}
                onChange={(e) => { onRowDataChange({ target: { name: 'other_description', value: e.target.value } }, index) }}
              ></textarea>
              {errors?.[`rows[${index}].other_description`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].other_description`]}</label> : ''}
            </>
            : null
          } */}
        </div>
        {<div className="dcell squantity">{item?.quantity || "--"}
          {/* <div className="sprice">
            <input
              readOnly={true}
              type="text"
              className="form-control"
              value={1}
              name="quantity"
              onChange={(e) => onNumericInputChange(e.target.value) && onRowDataChange({ target: { name: 'quantity', value: e.target.value } }, index)}
              placeholder="Quantity" />
            {errors?.[`rows[${index}].quantity`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].quantity`]}</label> : ''}
          </div> */}
        </div>}
        <div className="dcell sprice">${formatToCurrency(item?.rate)}
          {/* <div className="sprice">
            <input
              readOnly={true}
              type="text"
              className="form-control"
              value={item?.price}
              name="price"
              onChange={(e) => onNumericInputChange(e.target.value) && onRowDataChange(e, index)}
              placeholder="Price" />
            {errors?.[`rows[${index}].price`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].price`]}</label> : ''}
          </div> */}
        </div>
        <div className="dcell stax">
          <div className="flex ai-center">

            <div className="inline-check-list sm-checks">
              <div className="flex ai-center">
                <div className="sprice">
                  ${`${formatToCurrency(item?.tax)}`}
                </div>
              </div>
              {/* <div className="fitem-check">
                <input
                  type="checkbox"
                  className="fitem-ck-input"
                  id={`taxChecked${index}`}
                  name="taxChecked"
                  checked={item?.taxChecked}
                  onChange={(e) => { onRowDataChange(e, index, 'bool'); onRowDataChange({ target: { name: 'tax', value: '' } }, index) }} />
                <label htmlFor={`taxChecked${index}`} className="fitem-ck-txt"></label>
              </div>
              {
                item?.taxChecked ? <input
                  type="text"
                  className="form-control"
                  value={item?.tax}
                  name="tax"
                  onChange={(e) => (e.target.value.indexOf('%') > 0 || onNumericInputChange(e.target.value)) && onRowDataChange(e, index)}
                  placeholder="Tax" /> : ''} */}
            </div>
          </div>
          {(props.invoice_uuid && state.invoiceDetail?.status !== 'draft') || (state.rows.length === 1 && props.invoice_uuid === '') ? null : <i className="las la-trash-alt" onClick={() => toggleDeleteModal(item, "wd")} />}
        </div>
        <div className="dcell sprice">${formatToCurrency(+(parseFloat(item?.price) * parseFloat(item?.quantity)).toFixed(2) + +parseFloat(item?.tax).toFixed(2))}
          {/* <div className="sprice">
            <input
              readOnly={true}
              type="text"
              className="form-control"
              value={item?.price}
              name="price"
              onChange={(e) => onNumericInputChange(e.target.value) && onRowDataChange(e, index)}
              placeholder="Price" />
            {errors?.[`rows[${index}].price`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rows[${index}].price`]}</label> : ''}
          </div> */}
        </div>
      </div>
    )
  }

  // render Item Row
  const renderItemRow1 = (item, index) => {
    const { serviceList, errors, description_data, serviceOrderNumberList, invoice_date } = state;
    // let workOrder = serviceList;
    // let workOrder = serviceList?serviceList.find(element => element.uuid === item.service_uuid):null;
    var getKeys = _.map(state.rows, 'service_uuid');
    getKeys = [...new Set(getKeys)];
    let filterServiceList = serviceList ? serviceList.filter(x => !getKeys.includes(x.uuid)) : [];
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid);
    return (
      <div className="divrow editrow" key={`items${index}`} style={{ marginLeft: "0px" }}>
        {
          <div className="dcell serviceno">{item?.service_order_number}</div>
        }
        {service?.service_order_name === "Retail Invoice" ? <div className="dcell sno">N/A</div> :
          <>
            {
              item?.work_order_number ? <div className="dcell sno">{item?.work_order_number}</div> :
                <div className="dcell sno">
                  <Select
                    disabled={item?.service_uuid ? true : false}
                    dropdownClassName="sm-filter"
                    showArrow
                    name={'service_uuid'}
                    virtual={false}
                    style={{ width: '100%' }}
                    value={item?.service_uuid ? item?.service_uuid : undefined}
                    placeholder={'Select'}
                    onChange={(e) => {
                      onRowDataChange({ target: { name: 'service_uuid', value: e } }, index)
                    }}
                  >
                    {map(renderWorkOrder(serviceOrderResponse), (obj) => <Option hidden={!obj?.has_lineitems} value={obj?.work_order_uuid} key={obj?.work_order_uuid}>{obj?.work_order_number}</Option>)}
                  </Select>
                  {errors?.[`rowsData[${index}].service_uuid`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rowsData[${index}].service_uuid`]}</label> : ''}
                </div>
            }
          </>
        }
        {/* <div className="dcell sno">{item?.service_info?.workOrder || "--"}</div> */}
        <div className="dcell sdate">{invoice_date ? localToUTC(invoice_date).format('YYYY-MM-DD') : null || "--"}</div>
        <div className="dcell stype">{item?.type ? item?.type : "--"}</div>
        <div className="dcell sdesc">{item?.description}</div>
        <div className="dcell sprice">{item?.quantity ? item?.quantity || 1 : "--"}</div>
        {
          props?.invoiceDetail?.uuid ? 
          (
            <div className="dcell sprice">{"$" + formatToCurrency(item?.price || "--")}</div>    
          ) : (
            <div className="dcell sprice">{"$" + formatToCurrency(item?.price || "--")}</div>
          )
        }
        
        {service?.service_order_name === "Retail Invoice" && !props?.invoiceDetail?.uuid ? <div className="dcell sprice">
          <input type="checkbox"
            className="fitem-ck-input"
            id="checkbox"
            checked={item?.is_taxable}
            onChange={(e) => { applyTaxCheck(e, item) }}
            name="checkboxs"
            disabled={item?.disabled}
          ></input>
        </div> : null}
        <div className="dcell stax">
          <div className="flex ai-center">
            <div className="inline-check-list sm-checks">
              <div className="flex ai-center">
                <div className="sprice">
                  {"$" + formatToCurrency(item?.tax)}
                </div>
              </div>
            </div>
          </div>
          {/* {(props.invoice_uuid && state.invoiceDetail?.status!=='draft') || (state.rows.length===1 && props.invoice_uuid==='') || (item.isDefault) ? null:<i className="las la-trash-alt" onClick={() => item?.type !== "" ? modalToggleDelete(item?.work_order_number) : deleteWorkOrder(item?.index)} />} */}
        </div>
        <div style={{ display: 'flex' }}>
          <div className="dcell sprice">{"$" + formatToCurrency(item?.total)}</div>
          {(props.invoice_uuid && state.invoiceDetail?.status !== 'draft') || (item.isDefault) ? null : <i className="las la-trash-alt" onClick={() => item?.type === "Retail Invoice" ? modalToggleRetailDelete(item) : item?.type !== "" ? modalToggleDelete(item?.work_order_number) : deleteWorkOrder(item?.index)} />}
        </div>
      </div>
    )
  }

  const getLineSearch = async (params) => {
    const response = await getPrices(params);
    return response;
  }

  async function getOptions(search) {
    const allowRepeatedRepairCodes = [
      "LABORSERVICE",
      "FLATRATESUBCONTRACT",
      "INCOME",
      "LABOR",
      "SERVICE-FLATRATE",
      "RETAIL-SUPPLIES",
      "SUBCONTRACT-INCOME"
    ]
    setSelectLoading(true)
    let field_office_uuid = props?.customer?.field_office?.uuid;
    const options = await getLineSearch({ search: search, field_office_uuid: field_office_uuid });

    setRepairCodes(options);

    const _options = options.reduce((prev, current) => {
      //Validation to not repeat repair code
      const is_added = rowsData.some(i => i.repair_code === current.repaircode);
      if (!is_added || _.includes(allowRepeatedRepairCodes, current.repaircode)) {
        prev.push({
          value: current.repaircode + " - " + current.description,
          text: current.repaircode
        })
      }
      return prev;
    }, []);
    setSelectLoading(false)
    setcodeOptions(_options);
  }

  const renderRepairCodeSearch = () => {
    const onChange = (e) => {
      let val = '';
      let selectedCode = [];
      if (e) {
        val = e.trim().split(' ')[0];
        setRepairCodeValue(val);
        selectedCode = repairCodes.filter((item) => {
          return item.repaircode === val
        });
      } else {
        setRepairCodeValue('');
      }
      if (selectedCode.length > 0) {
        setNewItemModalData({
          ...newItemModalData,
          repair_code: selectedCode[0].repaircode || "",
          description: selectedCode[0].description,
          fo_adjusted_price: Math.round((Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100) / 100,
          quantity: 1,
          qb_account: selectedCode[0].qb_account || "",
          totallabor: selectedCode[0].totallabor,
          price: Math.round((Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100) / 100,
        });
      } else {
        message.error("The item was not found, please verify the data.");
      }
    }
    const onSearch = (e) => {
      setcodeOptions([]);
      if (e !== "") {
        getOptions(e);
        setRepairCodeValue(e);
        setNewItemModalData((l) => ({ ...l, repair_code: e || '' }))
      }
    }

    return (
      <div className="searchable">
        <ConfigProvider renderEmpty={() => selectLoading ? <div className="repairCodesSpin"><Spin tip="Loading Repair Codes..." /></div> : <Empty />}>
          <Select
            showSearch
            allowClear
            autoClearSearchValue={false}
            value={repairCodeValue}
            placeholder="Search Repair ID's"
            onChange={onChange}
            onSearch={onSearch}
            onFocus={() => getOptions('')}
            options={codeOptions}
            open={openSelect}
            onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
          >
          </Select>
        </ConfigProvider>
      </div>
    );
  }

  // render table
  const renderItemTable = () => {
    const { rows, description, total, sales_tax, amount_received, balance_due, refund_amount, credit_memo_amount_applied, serviceOrderNumberList } = state;
    const is_payment_recieved = Number(amount_received || "0") > 0;
    const is_not_editable = (is_payment_recieved) && ((is_payment_recieved) && state.invoiceDetail?.status !== 'draft') || state.invoiceDetail?.status === 'open';
    let service = serviceOrderNumberList.find((item) => item.uuid === serviceOrderUuid);
    return (
      <React.Fragment>
        <div className="table-block">
          <div className="div-table maintenance-table" style={props.invoice_uuid ? null : { 'display': 'block' }}>
            <div className="divhead">
              <div className="dcell serviceno">Service Order #</div>
              <div className="dcell sno">Work Order #</div>
              <div className="dcell sdate">Date</div>
              <div className="dcell stype">Type</div>
              <div className="dcell sdesc">Description</div>
              <div className="dcell squantity">Quantity</div>
              <div className="dcell sprice">Price</div>
              {service?.service_order_name === "Retail Invoice" && !props?.invoiceDetail?.uuid ? <div className="dcell stax">Apply tax</div> : null}
              <div className="dcell stax">Tax</div>
              <div className="dcell stax">Total</div>
            </div>
            <div className="divbody">
              {props?.invoiceDetail?.uuid ? map(rows, renderItemRow) : map(rowsData, renderItemRow1)}
              <div className="divrow dfoot">
                <div className="dcell maintenance-cell-margin padding-r-remove">
                  {service?.service_order_name === "Retail Invoice" && !props?.invoiceDetail?.uuid ? <div className="half-fitem fitem">
                    <label className="mr-10"> Tax Rate: </label>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "20%" }}
                      value={taxRate}
                      onChange={(e) => { handleChange(e) }}
                    />
                    <u>
                      <a role="button" href="/#" onClick={(e) => { e.preventDefault(); getTaxValue() }} className="ml-10"
                      >
                        {" "}
                        Reset Tax Rate to local
                      </a>
                    </u>
                  </div> : null}
                  <div className="btn-group">
                    <button className={`btn btn-warning ${(state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft') || !service?.service_order_name ? "disbale_btn" : ""}`} disabled={(state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft') || !service?.service_order_name} onClick={handleAddItemClick}><i className="las la-plus-circle"></i> Add Item</button>
                    <a className={`clear-item btn-warning  ${(state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft') || rowsData?.length === 0 ? "disbale_btn" : ""}`} disabled={state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft'} onClick={() => (state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft') || rowsData?.length === 0 ? null : modalToggleClearAll()}>Clear All Items</a>

                  </div>
                  <div className="form-group">
                    <div className="fitem">
                      <label><b>Message</b></label>
                      <textarea
                        disabled={state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft'}
                        rows="2"
                        className="form-control"
                        name="description"
                        placeholder={"Add a message to your invoice !"}
                        value={description}
                        onChange={(e) => onTextChange(e)} />
                    </div>
                  </div>
                </div>
                <div className="dcell flex ai-start flex-end">
                  <div className="price-block">
                    <span>Total</span>
                    <span>Sales Tax</span>
                    <span>Amount Received</span>
                    <span>Credit Applied</span>
                    {state.invoiceDetail?.status !== 'draft' && props?.invoice_uuid !== '' ? <span>Balance Due</span> : null}
                    <span>Amount Refunded</span>
                  </div>
                  <div className="pricing">
                    <span>${total ? formatToCurrency(total) : '0.00'}</span>
                    <span>${sales_tax ? formatToCurrency(sales_tax) : '0.00'}</span>
                    <span>${amount_received ? formatToCurrency(amount_received) : '0.00'}</span>
                    <span>${credit_memo_amount_applied ? formatToCurrency(credit_memo_amount_applied) : '0.00'}</span>
                    {state.invoiceDetail?.status !== 'draft' && props?.invoice_uuid !== '' ? <span>${balance_due ? formatToCurrency(balance_due) : '0.00'}</span> : null}
                    <span>${props.invoiceDetail ? props.invoiceDetail.refund_amount ? formatToCurrency(props.invoiceDetail.refund_amount) : '0.00' : '0.00'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  //   render() {
  const { loader, invoice_date, due_date, serviceOrder, errors, serviceOrderNumberList, service_order_uuid, rows, selectedDeletedType, selectedDeletedItem, serviceOrderListTemp } = state;
  const { onChangeView, customer } = props;
  const is_payment_recieved = Number(state.amount_received || "0") > 0
  const is_service_order = !isEmpty(serviceOrder?.billing_address) || !isEmpty(serviceOrder?.service_address)
  const address = !isEmpty(serviceOrder?.billing_address) ? serviceOrder?.billing_address : !isEmpty(serviceOrder?.service_address) ? serviceOrder?.service_address : {};
  let rowsFilter = rows?.filter(function (rf) {
    return rf.isEditRow === true;
  });
  let SOTemp = serviceOrderListTemp?.filter(function (so) {
    return so.uuid === serviceOrderUuid;
  });

  return (
    <React.Fragment>
      {loader && <Loader />}
      <div className="filter_wrapper">
        <ul className="breadcrumb">
          <li className={"cursor_pointer"}><span onClick={() => { onChangeView(0) }}>Back to all invoices</span></li>
        </ul>
      </div>
      <div className="invoice-container">
        <div className="flex flex-service">
          <div className="flex-1">
            <div className="bill-add">
              <b>Bill to:</b>
              <p>{props?.customer?.first_name} {props?.customer?.last_name}, <br />{is_service_order ? `${address?.address},` : ''} <br />{is_service_order ? `${address?.city}, ${address?.state}, ${address?.zip_code}` : ''}</p>
            </div>
          </div>
          <div className="flex-1">
            <div className="form-group">
              <div className="half-fitem fitem">
                <div className="half-fitem fitem" style={{ width: '100%' }}>
                  <label><b>Service Order + Job Name <span style={{ 'color': 'red' }}>*</span></b></label>
                  <Select
                    dropdownClassName="sm-filter"
                    disabled={props.invoice_uuid ? true : false}
                    showArrow
                    name={'service_order'}
                    virtual={false}
                    style={{ width: 400 }}
                    value={props.invoice_uuid ? SOTemp?.[0]?.service_order_number + '   ' + SOTemp?.[0]?.service_order_name : serviceOrderUuid ? serviceOrderUuid : null}
                    placeholder={'Service Order'}
                    onChange={(e) => {
                      if (!firstRender) {
                        onServiceOrderChange(e)
                      }
                      else {
                        toggleDeleteModal(e, "sd")
                      }
                      setFirstRender(2)
                    }}
                  >
                    {map(datafilter, (obj) => <Option hidden={obj.service_order_name === "Retail Invoice" ? false : !obj?.show_service_order} value={obj?.uuid} key={obj?.uuid}>{obj?.service_order_number} {obj?.service_order_name}</Option>)}
                  </Select>
                  {errors?.['serviceOrderUuid'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['serviceOrderUuid']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <label><b>Invoice Date</b></label>
                  <DatePicker
                    disabled={state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft'}
                    inputReadOnly
                    style={{ 'width': '100%' }}
                    name='date'
                    format="MM/DD/YYYY"
                    allowClear={false}
                    value={serviceOrderUuid && invoice_date ? UTCToLocal(invoice_date) : null}
                    onChange={(e) => onTextChange({ target: { name: 'invoice_date', value: e } })}
                  />
                  {errors?.['invoice_date'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['invoice_date']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <label><b>Due Date</b></label>
                  <DatePicker
                    disabled={state.invoiceDetail?.status && state.invoiceDetail?.status !== 'draft'}
                    inputReadOnly
                    style={{ 'width': '100%' }}
                    disabledDate={(current) => { return current && current <= moment().startOf('day'); }}
                    name='date'
                    format="MM/DD/YYYY"
                    allowClear={false}
                    value={due_date ? UTCToLocal(due_date) : null}
                    onChange={(e) => onTextChange({ target: { name: 'due_date', value: e } })}
                  />
                  {errors?.['due_date'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['due_date']}</label> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderItemTable()}
        <div className="sticky-btn-grp">
          <div className="flex ai-center">
            <div className="flex-1">
              <button className={props.invoice_uuid || rowsData.length === 0 || isGenerateButtonDisable() ? 'btn btn-disabled disbale_btn' : 'btn btn-warning'} onClick={props.invoice_uuid ? null : validate} disabled={(props.invoice_uuid || rowsData.length === 0 || isGenerateButtonDisable())}>Generate Invoice</button>
              {/* <button className={(parseFloat(state.total)>0 && rowsFilter?.length>0) && (state.invoiceDetail?.status==='draft' || props.invoice_uuid==='')?`btn btn-warning`:`btn btn-disabled disbale_btn`} onClick={() => rowsFilter?.length>0 || state.invoiceDetail?.status!=='draft'?validate():null}>Generate Invoice</button> */}
              {/* <button className={props.invoice_uuid===''?parseFloat(state.total)>0?`btn btn-warning`:`btn btn-disabled disbale_btn`:rowsFilter?.length>0 || state.invoiceDetail?.status!=='draft'?`btn btn-disabled disbale_btn`:`btn btn-warning`} onClick={() => parseFloat(state.total)>0?setState({ isApprove: true }, () => validate()):null}>Generate,Process & Email Invoice</button> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Delete Work Order"
        visible={state.showDelete}
        onCancel={() => toggleDeleteModal()}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => toggleDeleteModal()}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={() => selectedDeletedType === "wd" ? onDeleteRow(abc) : onDiscardServiceOrder(selectedDeletedItem)}>Yes</button>,
        ]}
      >
        <div>
          <p>{state.selectedDeletedType === "wd" ? 'Are you sure you want to delete the selected Work Order from this invoice?' : 'Retail Sales and Services cannot be invoiced together. This selection will discard the existing changes. Do you wish to continue?'}</p>
        </div>
      </Modal>
      <br />
      <Modal
        title="Delete Work Order"
        visible={isOpenDelete}
        onCancel={() => setIsOpenDelete(false)}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => setIsOpenDelete(false)}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={deleteWorkOrder}>Yes</button>,
        ]}
      >
        <div>
          <p>{isOpenDelete ? 'Are you sure you want to delete the selected Work Order from this invoice?' : ''}</p>
        </div>
      </Modal>
      <br />
      <Modal
        title="Clear All Items"
        visible={isOpenClearAll}
        onCancel={() => setIsOpenClearAll(false)}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => setIsOpenClearAll(false)}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={onClearAll}>Yes</button>,
        ]}
      >
        <div>
          <p>{isOpenClearAll ? 'Are you sure you want to delete the selected Work Order from this invoice?' : ''}</p>
        </div>
      </Modal>
      <Modal
        visible={addItemModal}
        cancelText={"Cancel"}
        okText="Add Item"
        onOk={handleTableData}
        onCancel={cancelStateData}
        className="add_line_modal"
        width={250}
      >
        <div className="form-group">
          <div className="fitem">
            <div>

              <h5 className="mt-30" >Add Item</h5>
              <label>Repair Code</label>
              {renderRepairCodeSearch()}
            </div>
            <div>
              <label>Description</label>
              <input type="text" id="description" placeholder="Description" value={newItemModalData.description} onChange={handleNewItemModalChange} />
            </div>
            <div>
              <label>Quantity</label>
              <input type="number" id="quantity" placeholder="1" value={newItemModalData.quantity} onChange={handleNewItemModalChange} />
            </div>
            <div>
              <label>Unit cost</label>
              <input type="number" id="fo_adjusted_price" placeholder="0" value={newItemModalData.fo_adjusted_price} onChange={handleNewItemModalChange} />
            </div>
            <div>
              <label>Price</label>
              <input type="number" id="price" placeholder="0" value={newItemModalData.price} disabled />
            </div>
            {newItemModalData?.totallabor !== null && newItemModalData?.totallabor > 0 ?
              <>
                <div className="mt-10">
                  <input type="checkbox" checked={newItemModalData?.include_labor}
                    onChange={() => {
                      setNewItemModalData({
                        ...newItemModalData,
                        include_labor: !newItemModalData?.include_labor, include_bundle: !newItemModalData?.include_labor
                      });
                    }}
                    style={{ 'verticalAlign': 'middle' }} />
                  <label className="mr-10" style={{ marginLeft: 5 }}>Include Labor Charge</label>
                </div>
                <div className="mt-10">
                  <input type="checkbox" disabled={!newItemModalData?.include_labor}
                    checked={newItemModalData?.include_bundle}
                    onChange={() => {
                      setNewItemModalData({
                        ...newItemModalData,
                        include_bundle: !newItemModalData?.include_bundle
                      });
                    }}
                    style={{ 'verticalAlign': 'middle' }} />
                  <label className="mr-10" style={{ marginLeft: 5 }}>Bundle Product</label>
                </div>
              </>
              : ''
            }
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default AddNewInvoice;
