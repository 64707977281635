import React, { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const options = [
  { value: 30, label: "30min" },
  { value: 60, label: "60min" },
  { value: 90, label: "90min" },
  { value: 120, label: "120min" },
  { value: 150, label: "150min" },
  { value: 180, label: "180min" },
  { value: 210, label: "210min" },
  { value: 240, label: "240min" },
];

const DurationFilter = ({
  onControlChange = () => {},
  defaultValue = null,
}) => {
  const [selectedDuration, setSelectedDuration] = useState(defaultValue);

  const changeSelection = (value, options) => {
    setSelectedDuration(value);
    onControlChange(value);
  };

  return (
    <Select
      allowClear
      placeholder="Duration options"
      className="duration-filter"
      optionFilterProp="children"
      onChange={(value, options) => changeSelection(value, options)}
      value={selectedDuration}
    >
      {options.map((option) => {
        return (
          <Option
            key={option.value}
            value={option.value}
            label={`${option.label}`}
          ></Option>
        );
      })}
    </Select>
  );
};

export default DurationFilter;
