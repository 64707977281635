import React, { Component, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { map, isEmpty } from 'lodash';
import Loader from "../../../Components/Loader";
import workOrderStatus from '../../../Components/Common/workorderStatus.json';
import CommercialTable from './Common/ServiceToPerform/CommercialTable';
import StatusOutsideAlerter from "../../../Components/Common/statusOutsideAlerter";
import ResidentialTable from './Common/ServiceToPerform/ResidentialTable';
import { DebounceInput } from 'react-debounce-input';
import { message, Checkbox } from 'antd';
import close from '../../../Assets/images/close.png';
import chevronDown from '../../../Assets/images/chevron-down.png';
import { getFranchise } from '../../../Action/BulkReassign';
import { getServiceAllocationResidentail, getServiceAllocationCommercail } from '../../../Action/BulkReassign';
import { sendToOptimoRoute } from '../../../Action/Customer';
import { getFranchiseSelected } from "../../../Utils/selectedFranchise";

// const { Option } = Select;
const Step2 = (props) => {
  const [state, setState] = useState({
    current_tab: 1,
    intial_selected_row_keys: [],
    residential_selected_row_keys: [],
    commercial_selected_row_keys: [],
    // install_selected_row_keys: [],
    status: [],
    toggleStatusFilter: false,
    toggleFranchiseFilter: false,
    search_value: '',
    franchiseList: [],

    intial_allocated_service: [],
    residential_allocated_service: [],
    commercial_allocated_service: [],
    // install_allocated_service: [],

    intial_staff_pagination: { showSizeChanger: false, pageSize: 15, ordering: "", current: 1 },
    residential_staff_pagination: { showSizeChanger: false, pageSize: 15, current: 1 },
    commercial_staff_pagination: { showSizeChanger: false, pageSize: 15, current: 1 },
    // install_staff_pagination: { showSizeChanger: false, pageSize: 15, current: 1 },

    activePagination: '15',
    intialActivePagination: '15',
    residentialActivePagination: '15',
    commercialActivePagination: '15',
    // installActivePagination: '15',

    // All selection
    is_all_selected_residential: false,
    is_all_selected_commercial: false,
    all: [],

    loader: false,
    schedule_start_time: '',
    schedule_end_time: ''
  })

  const [residentialData, setResidentialData] = useState([]);
  const [commercialData, setCommercialData] = useState([]);
  const [initialDataTab, setInitialDataTab] = useState({});
  const [residentialDataTab, setResidentialDataTab] = useState({});
  const [commercialDataTab, setCommercialDataTab] = useState({});
  const [activePagination, setActivePagination] = useState('15');
  const [loaders, setLoaders] = useState(false);
  const [workStatusData, setWorkStatusData] = useState(workOrderStatus.WorkOrderStatus);
  const [searchValue, setSearchValue] = useState('');
  const [is_all_selected_residential, set_is_all_selected_residential] = useState(false);
  const [residential_selected_row_keys, set_residential_selected_row_keys] = useState([]);
  const [is_all_selected_commercial, set_is_all_selected_commercial] = useState(false);
  const [commercial_selected_row_keys, set_commercial_selected_row_keys] = useState([]);
  const [residentialWOKeys, setResidentialWOKeys] = useState([]);
  const [commercialWOKeys, setCommercialWOKeys] = useState([]);
  const [fieldStaff, setFieldStaff] = useState([]);
  const [checkedFranchiseUuid, setCheckedFranchiseUuid] = useState([]);
  const [checkedFranchiseName, setCheckedFranchiseName] = useState([]);
  const [allFranchiseUuid, setAllFranchiseUuid] = useState([]);
  const [allFranchiseName, setAllFranchiseName] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState([]);
  const [allFranchise, setAllFranchise] = useState([]);

  const getFieldOfficeStaffApiCall = async (params) => {
    const response = await getFranchise(params);
    if (!isEmpty(response.results)) {
      let temp = [...response.results];
      let temp_uuid = [temp.filter(each => getFranchiseSelected().uuid === each.uuid)[0]?.uuid];
      let temp_name = [temp.filter(each => getFranchiseSelected().uuid === each.uuid)[0]?.name];
      temp = temp.map(each => {
        if (getFranchiseSelected().uuid === each.uuid) {
          return {
            ...each,
            is_change: true,
          }
        } else {
          return {
            ...each,
            is_change: false,
          }
        }
      });
      setFieldStaff(temp);
      setCheckedFranchiseUuid(temp_uuid);
      setCheckedFranchiseName(temp_name);
      setAllFranchiseName(temp.map(each => each.name));
      setAllFranchiseUuid(temp.map(each => each.uuid));
      // setAllFranchise(true);
    }

  };
  function getState() {
    setState({
      current_tab: 1,
      intial_selected_row_keys: [],
      residential_selected_row_keys: [],
      commercial_selected_row_keys: [],
      // install_selected_row_keys: [],
      status: [],
      toggleStatusFilter: false,
      toggleFranchiseFilter: false,
      search_value: '',
      franchiseList: [],

      intial_allocated_service: [],
      residential_allocated_service: [],
      commercial_allocated_service: [],
      // install_allocated_service: [],

      intial_staff_pagination: { showSizeChanger: false, pageSize: 15, ordering: "", current: 1 },
      residential_staff_pagination: { showSizeChanger: false, pageSize: 15, current: 1 },
      commercial_staff_pagination: { showSizeChanger: false, pageSize: 15, current: 1 },
      // install_staff_pagination: { showSizeChanger: false, pageSize: 15, current: 1 },

      activePagination: '15',
      intialActivePagination: '15',
      residentialActivePagination: '15',
      commercialActivePagination: '15',
      // installActivePagination: '15',

      // All selection
      is_all_selected_residential: false,
      is_all_selected_commercial: false,
      all: [],

      loader: false,
      schedule_start_time: '',
      schedule_end_time: ''
    })
  }

  // const getServiceAllocationIntialCleanApiCall = async (params) => {
  //   const response = await getServiceAllocationIntialClean(params);
  //   setInitialDataTab(response)
  //   let { intial_staff_pagination } = state;

  //   //get Intial Service
  //   if (Object.keys(response).length > 0) {
  //     intial_staff_pagination.total = response.count;
  //     let data = response.results.filter(item => item.is_selected === true).map(item => item.uuid);
  //     sessionStorage.setItem('already_scheduled_intial_selected_row_keys', JSON.stringify(data));
  //     setState({
  //       ...state,
  //       loader: false,
  //       intial_staff_pagination,
  //       intial_allocated_service: response?.results,
  //       intial_selected_row_keys: JSON.parse(sessionStorage.getItem('intial_selected_row_keys')) || [],
  //     })
  //   }
  //   setLoaders(false)
  // }

  const getServiceAllocationResidentailApiCall = async (params) => {
    const response = await getServiceAllocationResidentail(params);
    setResidentialDataTab(response)

    let { residential_staff_pagination, all } = state;
    if (Object.keys(response).length > 0) {
      residential_staff_pagination.total = response.count;
      if (!is_all_selected_residential && residential_selected_row_keys.length === 0) {
        let keys = response.results.map(row => row.uuid);
        setResidentialWOKeys(keys)
        sessionStorage.setItem('residential_selected_row_keys', JSON.stringify(keys));

        setState({
          ...state,
          loader: false,
          all: keys.length,
          residential_staff_pagination,
          residential_selected_row_keys: [],
          residential_allocated_service: response.results
        });
        // set_residential_selected_row_keys(keys);
        setResidentialData(response.results)
      }
      else {
        let residential_selected_row_keys = JSON.parse(sessionStorage.getItem('residential_selected_row_keys')) || [];
        let data = response.results.filter(item => item.is_selected === true).map(item => item.uuid);
        sessionStorage.setItem('already_scheduled_residential_selected_row_keys', JSON.stringify(data));
        setState({
          ...state,
          loader: false,
          residential_staff_pagination,
          residential_selected_row_keys,
          // residential_allocated_service: response.results
        });
        set_residential_selected_row_keys(residential_selected_row_keys)
        setResidentialData(response.results)
      }
    }
    setLoaders(false)
  }

  const getServiceAllocationCommercailApiCall = async (params) => {
    const response = await getServiceAllocationCommercail(params);
    setCommercialDataTab(response)
    let { commercial_staff_pagination, all } = state;
    if (Object.keys(response).length > 0) {
      commercial_staff_pagination.total = response.count;
      if (!is_all_selected_commercial && commercial_selected_row_keys.length === 0) {
        let keys = response?.results.map(row => row.uuid);
        setCommercialWOKeys(keys);
        sessionStorage.setItem('commercial_selected_row_keys', JSON.stringify(keys));
        setState({
          ...state,
          loader: false,
          all: keys.length,
          commercial_staff_pagination,
          commercial_selected_row_keys: [],
          commercial_allocated_service: response.results,
        });
        setCommercialData(response.results)
      }
      else {
        let commercial_selected_row_keys = JSON.parse(sessionStorage.getItem('commercial_selected_row_keys')) || []
        let data = response?.results.filter(item => item.is_selected === true).map(item => item.uuid);
        sessionStorage.setItem('already_scheduled_commercial_selected_row_keys', JSON.stringify(data));
        setState({
          ...state,
          loader: false,
          commercial_staff_pagination,
          commercial_allocated_service: response.results,
          commercial_selected_row_keys
        });
        setCommercialData(response.results);
        set_commercial_selected_row_keys(commercial_selected_row_keys);
      }
    }
    setLoaders(false)
  }

  useEffect(() => {
    getState();
    set_residential_selected_row_keys([]);
    let tempArr = [...workStatusData]
    tempArr.map((each_c) => {
      each_c.is_change = false
    })
    setWorkStatusData(tempArr)
    sessionStorage.setItem('residential_selected_row_keys', JSON.stringify([]));
    setState((state) => ({ ...state, loader: false }));

    let { intial_staff_pagination, residential_staff_pagination, commercial_staff_pagination } = state;
    const schedule_start_time = props.weekStartDate + 'T00:00:00';
    let currentDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
    let startDate = schedule_start_time
    if (schedule_start_time < currentDate) {
      startDate = currentDate
    }
    const schedule_end_time = moment(props.weekStartDate).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00';

    setState((state) => ({
      ...state,
      schedule_start_time: startDate,
      schedule_end_time: schedule_end_time
    }));
    //Residential Clean
    // getServiceAllocationResidentailApiCall({
    //   limit: 15,
    //   offset: (residential_staff_pagination.current * 15) - 15,
    //   scheduled_appointment__gte: startDate,
    //   scheduled_appointment__lte: schedule_end_time,
    //   ordering: 'service_order__client',
    //   service_order__field_office__uuid: checkedFranchiseUuid,
    //   service_order__client_type: 'residential'
    // });

    //Commercial
    // getServiceAllocationCommercailApiCall({
    //   limit: 15,
    //   offset: (commercial_staff_pagination.current * 15) - 15,
    //   scheduled_appointment__gte: startDate,
    //   scheduled_appointment__lte: schedule_end_time,
    //   ordering: 'service_order__client',
    //   service_order__field_office__uuid: checkedFranchiseUuid,
    //   service_order__client_type: 'commercial'
    // });
    getFieldOfficeStaffApiCall({
      start_date: moment().day(0).format("YYYY-MM-DD"),
      field_office__uuid: props.selected_franchise,
      ordering: "user__first_name",
    });
    const user = JSON.parse(localStorage.getItem("authUser"));
    setCurrentUserRole(user?.user?.role[0]);
  }, [])

  useEffect(() => {
    if (checkedFranchiseUuid.length) {
      const {
        schedule_end_time,
        schedule_start_time,
      } = state;
      if (checkedFranchiseUuid.length === fieldStaff.length) {
        setAllFranchise(true);
      } else {
        setAllFranchise(false);
      }
      getServiceAllocationCommercailApiCall({
        limit: 15,
        offset: 0,
        scheduled_appointment__gte: schedule_start_time,
        scheduled_appointment__lte: schedule_end_time,
        ordering: 'service_order__client',
        service_order__field_office__uuid: checkedFranchiseUuid,
        service_order__client_type: 'commercial'
      });
      getServiceAllocationResidentailApiCall({
        limit: 15,
        offset: 0,
        scheduled_appointment__gte: schedule_start_time,
        scheduled_appointment__lte: schedule_end_time,
        ordering: 'service_order__client',
        service_order__field_office__uuid: checkedFranchiseUuid,
        service_order__client_type: 'residential'
      });
    } else {
      setAllFranchise(false);
    }
  }, [checkedFranchiseUuid]);

  // Intial Table  Pagination
  const intialPaginationChange = (pagination, filters, sorter) => {
    let { intial_staff_pagination } = state;
    intial_staff_pagination = pagination ? Object.assign(intial_staff_pagination, { 'current': pagination?.current }) : intial_staff_pagination;

    let ordering;

    if (sorter?.column) {
      ordering = sorter?.order === 'ascend' ? `-${sorter?.columnKey}` : `${sorter?.columnKey}`
    }

    let q;
    if (state.search_value) {
      q = state.search_value;
    }
    let status__in;
    if (state.status && state.status.length > 0) {
      status__in = state.status.toString();
    }

    const offset = pagination ? (pagination.current - 1) * pagination.pageSize : (intial_staff_pagination.current - 1) * intial_staff_pagination.pageSize;
    const limit = pagination ? pagination.pageSize : intial_staff_pagination.pageSize;

    // getServiceAllocationIntialCleanApiCall({
    //   limit: limit,
    //   offset: offset,
    //   // service_skill_type__skill_name__in: 'residential_init_clean',
    //   scheduled_time__gte: state.schedule_start_time,
    //   scheduled_time__lte: state.schedule_end_time,
    //   // status: "tentative",
    //   ordering,
    //   q,
    //   status__in,
    //   service_order__field_office__uuid: props.selected_franchise
    // });

    setState({
      ...state,
      loader: true,
      intial_staff_pagination
    })
  }

  //Residential Table  Pagination
  const residentialPaginationChange = (pagination, filters, sorter) => {
    let { residential_staff_pagination } = state;
    residential_staff_pagination = pagination ? Object.assign(residential_staff_pagination, { 'current': pagination?.current }) : residential_staff_pagination;
    const schedule_start_time = props.weekStartDate + 'T00:00:00';
    let currentDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
    let startDate = schedule_start_time
    if (schedule_start_time < currentDate) {
      startDate = currentDate
    }
    const schedule_end_time = moment(props.weekStartDate).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00';
    const status__in = workStatusData?.filter((f) => f.is_change)?.filter((fil) => fil.value !== 'all')?.map((each) => each.value)?.join(',')

    let ordering = '';

    if (sorter?.column) {
      ordering += sorter?.order === 'ascend' ? `-${sorter?.columnKey}` : `${sorter?.columnKey}`
    }

    // let q;
    // if (state.search_value) {
    //   q = state.search_value;
    // } 

    // let status__in;
    // if(state.status && state.status.length > 0){
    //   status__in = state.status.toString();
    // }

    const offset = pagination ? (pagination.current - 1) * pagination.pageSize : (residential_staff_pagination.current - 1) * residential_staff_pagination.pageSize;
    const limit = pagination ? pagination.pageSize : residential_staff_pagination.pageSize;

    getServiceAllocationResidentailApiCall({
      limit: limit,
      offset: offset,
      // service_skill_type__skill_name__in: 'residential_regular',
      scheduled_appointment__gte: startDate,
      scheduled_appointment__lte: schedule_end_time,
      // status: "tentative",
      ordering,
      q: searchValue,
      status__in,
      service_order__field_office__uuid: checkedFranchiseUuid,
      service_order__client_type: 'residential'
    });

    setState((state) => ({
      ...state,
      loader: true,
      residential_staff_pagination
    }))
  }

  const residentialPaginationChangeV2 = (searchValue) => {
    const schedule_start_time = props.weekStartDate + 'T00:00:00';
    let currentDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
    let startDate = schedule_start_time
    if (schedule_start_time < currentDate) {
      startDate = currentDate
    }
    const schedule_end_time = moment(props.weekStartDate).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00';
    const status__in = workStatusData?.filter((f) => f.is_change)?.filter((fil) => fil.value !== 'all')?.map((each) => each.value)?.join(',')

    getServiceAllocationResidentailApiCall({
      limit: 15,
      offset: 0,
      // service_skill_type__skill_name__in: 'residential_regular',
      scheduled_appointment__gte: startDate,
      scheduled_appointment__lte: schedule_end_time,
      // status: "tentative",
      ordering: 'service_order__client__first_name',
      q: searchValue,
      status__in,
      service_order__field_office__uuid: checkedFranchiseUuid,
      service_order__client_type: 'residential'
    });

    setState((state) => ({
      ...state,
      loader: true
    }))
  }

  //Residential Table  Pagination
  const commercialPaginationChange = (pagination, filters, sorter) => {
    let { commercial_staff_pagination } = state;
    commercial_staff_pagination = pagination ? Object.assign(commercial_staff_pagination, { 'current': pagination?.current }) : commercial_staff_pagination;
    const schedule_start_time = props.weekStartDate + 'T00:00:00';
    let currentDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
    let startDate = schedule_start_time
    if (schedule_start_time < currentDate) {
      startDate = currentDate
    }
    const schedule_end_time = moment(props.weekStartDate).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00';
    const status__in = workStatusData?.filter((f) => f.is_change)?.filter((fil) => fil.value !== 'all')?.map((each) => each.value)?.join(',')

    let ordering = '';

    if (sorter?.column) {
      ordering = sorter?.order === 'ascend' ? `-${sorter?.columnKey}` : `${sorter?.columnKey}`;
    }

    // let q;
    // if (state.search_value) {
    //   q = state.search_value;
    // }

    // let status__in;
    // if(state.status && state.status.length > 0) {
    //   status__in = state.status.toString();
    // }

    const offset = pagination ? (pagination.current - 1) * pagination.pageSize : (commercial_staff_pagination.current - 1) * commercial_staff_pagination.pageSize;
    const limit = pagination ? pagination.pageSize : commercial_staff_pagination.pageSize;

    getServiceAllocationCommercailApiCall({
      limit: limit,
      offset: offset,
      // service_skill_type__skill_name__in: 'common_area_cleaning,pet_waste_station,pet_waste_station_install',
      scheduled_appointment__gte: startDate,
      scheduled_appointment__lte: schedule_end_time,
      // status: "tentative",
      ordering,
      q: searchValue,
      status__in,
      service_order__field_office__uuid: props.selected_franchise,
      service_order__client_type: 'commercial'
    });
    setState((state) => ({
      ...state,
      loader: true,
      commercial_staff_pagination
    }))
  }

  const commercialPaginationChangeV2 = (searchValue) => {
    const schedule_start_time = props.weekStartDate + 'T00:00:00';
    let currentDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
    let startDate = schedule_start_time
    if (schedule_start_time < currentDate) {
      startDate = currentDate
    }
    const schedule_end_time = moment(props.weekStartDate).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00';
    const status__in = workStatusData?.filter((f) => f.is_change)?.filter((fil) => fil.value !== 'all')?.map((each) => each.value)?.join(',')

    getServiceAllocationCommercailApiCall({
      limit: 15,
      offset: 0,
      // service_skill_type__skill_name__in: 'residential_regular',
      scheduled_appointment__gte: startDate,
      scheduled_appointment__lte: schedule_end_time,
      // status: "tentative",
      ordering: 'service_order__client__first_name',
      q: searchValue,
      status__in,
      service_order__field_office__uuid: checkedFranchiseUuid,
      service_order__client_type: 'commercial'
    });

    setState((state) => ({
      ...state,
      loader: true
    }))
  }

  // Install Table  Pagination
  // installPaginationChange = (pagination, filters, sorter) => {
  //   let { install_staff_pagination } = this.state;
  //   install_staff_pagination = Object.assign(install_staff_pagination, { 'current': pagination.current });

  //   let ordering = '';

  //   if (sorter.column) {
  //     ordering = sorter.order === 'ascend' ? `-${sorter.columnKey}` : `${sorter.columnKey}`
  //   }
  //   else {
  //     ordering = ''
  //   }
  //   const offset = (pagination.current - 1) * pagination.pageSize;
  //   const limit = pagination.pageSize;

  //   this.props.getServiceAllocationInstall({
  //     limit: limit,
  //     offset: offset,
  //     service_skill_type__skill_name__in: 'pet_waste_station_install',
  //     scheduled_time__gte: this.state.schedule_start_time,
  //     scheduled_time__lte: this.state.schedule_end_time,
  //     status: "tentative",
  //     ordering,
  //     service_order__field_office__uuid: this.props.selected_franchise
  //   });
  //   this.setState({
  //     loader: true,
  //     install_staff_pagination
  //   })
  // }

  //Select Intial User
  const onIntialSelectedRowKeysChange = (intial_selected_row_keys) => {
    setState((state) => ({ ...state, intial_selected_row_keys }));
    sessionStorage.setItem('intial_selected_row_keys', JSON.stringify(intial_selected_row_keys));
  }

  //Testing
  const onResidentialSelectedRowKeysChange = (residential_selected_row_keys) => {
    setState((state) => ({ ...state, residential_selected_row_keys }));
    set_residential_selected_row_keys(residential_selected_row_keys);
    sessionStorage.setItem('residential_selected_row_keys', JSON.stringify(residential_selected_row_keys));
  }

  //Select Commercial User
  const onCommercialSelectedRowKeysChange = (commercial_selected_row_keys) => {
    setState((state) => ({ ...state, commercial_selected_row_keys }));
    set_commercial_selected_row_keys(commercial_selected_row_keys);
    sessionStorage.setItem('commercial_selected_row_keys', JSON.stringify(commercial_selected_row_keys));
  }

  //Change Tab
  const ChangeTab = (tabValue) => {
    // if (tabValue === 1){ 
    //   sessionStorage.setItem('intial_selected_row_keys', JSON.stringify([]));
    //   setState((state)=>({...state,intial_selected_row_keys:[]}))
    //   setState((state)=>({...state, activePagination: state.intialActivePagination, all: 0 }));
    // } 
    // else
    if (tabValue === 1) {
      sessionStorage.setItem('residential_selected_row_keys', JSON.stringify([]));
      setState((state) => ({ ...state, residential_selected_row_keys: [], is_all_selected_residential: false }))
      set_is_all_selected_residential(false)
      set_residential_selected_row_keys([])
      setState((state) => ({ ...state, activePagination: state.residentialActivePagination, all: 0 }));
      setActivePagination('15');
    }
    else if (tabValue === 2) {
      sessionStorage.setItem('commercial_selected_row_keys', JSON.stringify([]));
      setState((state) => ({ ...state, commercial_selected_row_keys: [], is_all_selected_commercial: false }))
      set_is_all_selected_commercial(false);
      set_commercial_selected_row_keys([]);
      setState((state) => ({ ...state, activePagination: state.commercialActivePagination, all: 0 }));
      setActivePagination('15');
    }
    // else if (tabValue === 4) { this.setState({ activePagination: this.state.installActivePagination }) }

    setState((state) => ({ ...state, current_tab: tabValue }));
  }

  const onFilterChange = () => {
    // const { customerId } = this.props;
    // const { scheduled_time__range, service_order__uuid, work_order_number, technician__uuid, status, ordering, scheduled_time__gte, scheduled_time__lt, isServiceDetail, selectedService } = this.state;
    // const queryParams = {
    //   service_order__user__uuid:`${customerId}`,
    //   scheduled_time__range: `${scheduled_time__range}`,
    //   service_order__uuid__in: service_order__uuid && service_order__uuid.length > 0 ? `${service_order__uuid.join()}` : null,
    //   work_order_number__in: work_order_number && work_order_number.length > 0 ? `${work_order_number}` : null,
    //   technician__uuid__in: technician__uuid && technician__uuid.length > 0 ? `${technician__uuid.join()}` : null,
    //   status__in: status && status.length > 0 ? `${status.join()}` : null,
    //   ordering: `${ordering}`,
    //   scheduled_time__gte: `${scheduled_time__gte}`,
    //   scheduled_time__lt: `${scheduled_time__lt}`
    // }
    // if (isServiceDetail) {
    //   delete queryParams.service_order__uuid__in;
    //   queryParams['service_order__uuid'] = selectedService?.uuid;
    // } else {
    //   delete queryParams.service_order__uuid;
    // }
    // const params = this.filterParams(queryParams);
    // this.props.getWorkOrderList(params);
    // if (scheduled_time__lt) {
    //   this.props.getWorkOrderFilter({service_order__user__uuid: customerId, scheduled_time__lt: `${scheduled_time__lt}`});
    // }
  }

  const toggleStatus = () => {
    const { toggleStatusFilter } = state;
    if (toggleStatusFilter) {
      setState((state) => ({ ...state, toggleStatusFilter: false }));
    } else {
      setState((state) => ({ ...state, toggleStatusFilter: true, }));
    }
  }

  const toggleFranchise = () => {
    const { toggleFranchiseFilter } = state;
    if (toggleFranchiseFilter) {
      setState((state) => ({ ...state, toggleFranchiseFilter: false }));
    } else {
      setState((state) => ({ ...state, toggleFranchiseFilter: true, }));
    }
  }

  const clearFilter = () => {
    setState({ ...state, toggleFranchiseFilter: false });
    let temp = [...fieldStaff];
    temp = temp.map(each => ({ ...each, is_change: false, }));
    setFieldStaff(temp);
    setCheckedFranchiseUuid([]);
    setCheckedFranchiseName([]);
  }

  const clearStatus = () => {
    workOrderStatus.WorkOrderStatus.forEach(element => {
      element['is_change'] = false;
    });
    setState((state) => ({ ...state, status: [], toggleStatusFilter: false }, () => {
      onFilterChange();
      // if(state.current_tab === 1){
      //   intialPaginationChange();
      // }
      // else
      if (state.current_tab === 1) {
        residentialPaginationChange();
      }
      else if (state.current_tab === 2) {
        commercialPaginationChange();
      }
    }));
  }

  const clearServiceOrder = () => {
    const { serviceOrderFilter } = state;
    if (document.getElementById("allServiceOrder")) {
      document.getElementById("allServiceOrder").checked = false
    }
    serviceOrderFilter.forEach(element => {
      element['is_change'] = false;
    });
    setState((state) => ({ ...state, service_order__uuid: [], toggleServiceOrderFilter: false, allServiceOrderSelected: false }, () => {
      onFilterChange();
    }));
  }

  const closeStatusFilter = () => {
    setState((state) => ({ ...state, toggleStatusFilter: false }));
  }
  const closeFranchiseFilter = () => {
    setState((state) => ({ ...state, toggleFranchiseFilter: false }));
  }
  const isCheckedStatus = (arr, value) => {
    let result = false
    arr.forEach((each) => {
      if (each.value === value) {
        result = each.is_change
      }
    })
    return result
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")) : "";
    const default_franchise_uuid = user?.user?.associated_field_offices?.[0]?.uuid;
    let { residential_staff_pagination, commercial_staff_pagination } = state;
    const schedule_start_time = props.weekStartDate + 'T00:00:00';
    let currentDate = moment().format('YYYY-MM-DD') + 'T00:00:00';
    let startDate = schedule_start_time
    if (schedule_start_time < currentDate) {
      startDate = currentDate
    }
    const schedule_end_time = moment(props.weekStartDate).add(6, 'days').format('YYYY-MM-DD') + 'T23:59:00';
    const status__in = workStatusData?.filter((f) => f.is_change)?.filter((fil) => fil.value !== 'all')?.map((each) => each.value)?.join(',')
    getServiceAllocationResidentailApiCall({
      limit: 15,
      offset: (residential_staff_pagination.current * 15) - 15,
      scheduled_appointment__gte: startDate,
      scheduled_appointment__lte: schedule_end_time,
      status__in: status__in,
      ordering: 'service_order__client__first_name',
      service_order__field_office__uuid: default_franchise_uuid,
      service_order__client_type: 'residential'
    });
    getServiceAllocationCommercailApiCall({
      offset: (commercial_staff_pagination.current * 15) - 15,
      limit: 15,
      scheduled_appointment__gte: startDate,
      scheduled_appointment__lte: schedule_end_time,
      status__in: status__in,
      ordering: 'service_order__client__first_name',
      service_order__field_office__uuid: default_franchise_uuid,
      service_order__client_type: 'commercial'
    });
  }, [workStatusData])

  const onFranchiseChanged = (e, index) => {
    let temp_id = [...checkedFranchiseUuid];
    let temp_name = [...checkedFranchiseName];
    if (e.target.value === 'all') {
      if (e.target.checked) {
        // setAllFranchise(true);
        setCheckedFranchiseUuid(allFranchiseUuid);
        setCheckedFranchiseName(allFranchiseName);
        let temp = [...fieldStaff];
        temp = temp.map(each => ({ ...each, is_change: true, }));
        setFieldStaff(temp);
      } else {
        // setAllFranchise(false);
        setCheckedFranchiseUuid([]);
        setCheckedFranchiseName([]);
        let temp = [...fieldStaff];
        temp = temp.map(each => ({ ...each, is_change: false, }));
        setFieldStaff(temp);
      }
    } else {
      if (temp_name.includes(e.target.value) && !e.target.checked) {
        temp_id.splice(temp_id.indexOf(e.target.id), 1);
        temp_name.splice(temp_name.indexOf(e.target.value), 1);
      }
      if (e.target.checked && !temp_name.includes(e.target.value)) {
        temp_id.push(e.target.id);
        temp_name.push(e.target.value);
      }

      setCheckedFranchiseUuid(temp_id);
      setCheckedFranchiseName(temp_name);
      let temp = [...fieldStaff];
      temp = temp.map(each => {
        if (each.uuid === e.target.id) {
          return ({ ...each, is_change: e.target.checked, });
        } else {
          return each;
        }
      });
      setFieldStaff(temp);
    }
  }

  const onStatusChanged = (e, index) => {
    const { status } = state;
    let arrStatus = [...status];
    if (e.target.id === 'all') {
      if (!e.target.checked) {
        let tempArr = [...workStatusData]
        tempArr.map((each_c) => {
          each_c.is_change = false
        })
        setWorkStatusData(tempArr)
      } else {
        let tempArr = [...workStatusData]
        tempArr.map((each_c) => {
          each_c.is_change = true
        })
        setWorkStatusData(tempArr)
      }
    } else if (e.target.id === 'forecast') {
      if (!e.target.checked) {
        let tempArr = [...workStatusData]
        tempArr.map((each_c) => {
          if (each_c.value === 'forecast') {
            each_c.is_change = false
          }
          if (each_c.value === 'all' && each_c.is_change) {
            each_c.is_change = false;
          }
          if (each_c.value === 'incomplete' || each_c.value === 'incomplete' || each_c.value === 'complete' || each_c.value === 'complete' || each_c.value === 'cancelled_non_billable' || each_c.value === 'cancelled_billable') {
            each_c.is_change = false;
          }
        })
        setWorkStatusData(tempArr)
      } else {
        let tempArr = [...workStatusData]
        tempArr.map((each_c) => {
          if (each_c.value === 'forecast') {
            each_c.is_change = true
          }
        })
        setWorkStatusData(tempArr)
      }
    } else if (e.target.id === 'scheduled') {
      let isAllChecked = false;
      if (!e.target.checked) {
        let tempArr = [...workStatusData]
        tempArr.map((each_c) => {
          if (each_c.value === 'scheduled') {
            each_c.is_change = false
          }
          if (each_c.value === 'all' && each_c.is_change) {
            each_c.is_change = false
          }
          if (each_c.value === 'incomplete' || each_c.value === 'incomplete' || each_c.value === 'complete' || each_c.value === 'complete' || each_c.value === 'cancelled_non_billable' || each_c.value === 'cancelled_billable') {
            each_c.is_change = false;
          }
        })
        setWorkStatusData(tempArr)
      } else {
        let tempArr = [...workStatusData]
        tempArr.map((each_c) => {
          if (each_c.value === 'scheduled') {
            each_c.is_change = true
          }
        })
        setWorkStatusData(tempArr)
      }
    }
    // if (e.target.checked) {
    //   if (index === 0) {
    //     workOrderStatus.WorkOrderStatus.forEach((element, index) => {
    //       element['is_change'] = true;
    //       if (index > 0 && arrStatus.indexOf(element.value) === -1) {
    //         arrStatus.push(element.value)
    //       }
    //     });
    //   } else {
    //     if (arrStatus.indexOf(e.target.id) === -1) {
    //       workOrderStatus.WorkOrderStatus[index]['is_change'] = true;
    //       arrStatus.push(e.target.id)
    //     }
    //   }
    // } else {
    //   if (index === 0) {
    //     workOrderStatus.WorkOrderStatus.forEach(element => {
    //       element['is_change'] = false;
    //     });
    //     arrStatus = []
    //   } else {
    //     const ind = arrStatus.indexOf(e.target.id);
    //     if (ind > -1) {
    //       workOrderStatus.WorkOrderStatus[0]['is_change'] = false;
    //       workOrderStatus.WorkOrderStatus[index]['is_change'] = false;
    //       arrStatus.splice(ind, 1);
    //     }
    //   }
    // }
    // setState((state)=>({...state, status: arrStatus }, () => {
    //   // if(state.current_tab === 1){
    //   //   intialPaginationChange();
    //   // }
    //   // else
    //    if(state.current_tab === 1){
    //     residentialPaginationChange();
    //   }
    //   else if(state.current_tab === 2){
    //     commercialPaginationChange();
    //   }
    // }));
  }

  //#region "Status Filter"
  const renderStatusFilters = () => {
    const { toggleStatusFilter, status } = state;
    return (
      <StatusOutsideAlerter
        closeStatusFilter={closeStatusFilter}
      >
        <div className="form-group image-input stat-input border-color background-color">
          <div className="fitem mb-0">
            {/* <input type="text" readOnly id="txtStatus" placeholder={status && status.length === 0 ? 'Status: All' : `Status: ${status}`} onFocus={() => { this.toggleStatus() }} /> */}
            <div className="selected-drop-down-value fs-14" onClick={() => { toggleStatus() }}>
              {status && status.length === 0 && <div className=""><strong>Status: </strong> All</div>}
              {status && status.length > 0 && <div className="flex justify-content-start"> <strong>Status: </strong><div className="selected-value-filter">{`${status}`}</div> </div>}
            </div>

            {status && status.length === 0 &&
              <img className="cursor-pointer" src={chevronDown} alt="" onClick={() => { toggleStatus() }} />
            }
            {status && status.length > 0 &&
              <img src={close} alt="" onClick={() => { clearStatus() }} />
            }
          </div>
          {toggleStatusFilter && <div className="field-dropdown  custom-width">
            <div className="field-dropdown-body custom-height-optimo-routing">
              {map(workStatusData, (item, index) => {
                if (item.value !== 'complete' && item.value !== 'incomplete' && item.value !== 'cancelled_non_billable' && item.value !== 'cancelled_billable')
                  return (
                    <div key={index} className="fitem-check">
                      <input id={item.value} type="checkbox" className="fitem-ck-input" onChange={(e) => { onStatusChanged(e, index) }} checked={item.is_change} />
                      <label htmlFor={item.value} className="fitem-ck-txt">{item.label}</label>
                    </div>
                  )
              })}
            </div>
          </div>}
        </div>
      </StatusOutsideAlerter>
    )
  }

  const renderFranchiseFilters = () => {
    const { toggleFranchiseFilter } = state;
    return (
      <StatusOutsideAlerter
        closeStatusFilter={closeFranchiseFilter}
      >
        <div className="form-group image-input stat-input border-color background-color">
          <div className="fitem mb-0">
            <div className="selected-drop-down-value fs-14" onClick={() => { toggleFranchise() }}>
              {checkedFranchiseName?.length === 0 && <div className=""><strong>Franchise: </strong></div>}
              {checkedFranchiseName?.length > 0 && <div className="flex justify-content-start"> <strong>Franchise: </strong>
                <div className="selected-value-filter">{`${checkedFranchiseName}`}</div> </div>}
            </div>
            {checkedFranchiseName?.length === 0 &&
              <img className="cursor-pointer" src={chevronDown} alt="" onClick={() => { toggleStatus() }} />
            }

            {checkedFranchiseName?.length > 0 &&
              <img src={close} alt="" onClick={() => { clearFilter() }} />
            }
          </div>
          {toggleFranchiseFilter && <div className="field-dropdown  custom-width">
            <div className="field-dropdown-body custom-height-optimo-routing">
              <div key={'all'} className="fitem-check">
                <input id={'all'} value='all' type="checkbox" className="fitem-ck-input" onChange={(e) => { onFranchiseChanged(e) }} checked={allFranchise} />
                <label htmlFor={'all'} className="fitem-ck-txt">All</label>
              </div>
              {map(fieldStaff, (item, index) => {
                return (
                  <div key={index} className="fitem-check">
                    <input id={item.uuid} value={item.name} type="checkbox" className="fitem-ck-input" onChange={(e) => { onFranchiseChanged(e, index) }} checked={item.is_change} />
                    <label htmlFor={item.uuid} className="fitem-ck-txt">{item.name}</label>
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
      </StatusOutsideAlerter>
    )
  }

  const renderHeaderCheckbox = (all_selected, table) => {
    return <Checkbox
      checked={all_selected}
      onChange={() => handleSelectAll(table)}
    />
  }

  const handleSelectAll = (currentTable) => {
    const {
      schedule_end_time,
      schedule_start_time,
      // is_all_selected_commercial,
      // is_all_selected_residential,
    } = state;

    switch (currentTable) {
      case "residential":
        if (!is_all_selected_residential) {
          setState((state) => ({
            ...state,
            loader: true,
            is_all_selected_residential: true,
            residential_selected_row_keys: []
          }));
          set_is_all_selected_residential(true);
          set_residential_selected_row_keys(residentialWOKeys);

          getServiceAllocationResidentailApiCall({
            offset: 0,
            limit: 50,
            // service_skill_type__skill_name__in: 'residential_regular',
            scheduled_appointment__gte: schedule_start_time,
            scheduled_appointment__lte: schedule_end_time,
            ordering: 'service_order__client__first_name',
            service_order__field_office__uuid: checkedFranchiseUuid,
            service_order__client_type: 'residential'
          });
        }
        else {
          sessionStorage.setItem('residential_selected_row_keys', JSON.stringify([]));

          setState((state) => ({
            ...state,
            all: 0,
            is_all_selected_residential: false,
            residential_selected_row_keys: []
          }));
          set_is_all_selected_residential(false);
          set_residential_selected_row_keys([]);
        }
        break;
      case "commercial":
        if (!is_all_selected_commercial) {
          setState((state) => ({
            ...state,
            loader: true,
            is_all_selected_commercial: true,
            commercial_selected_row_keys: []
          }));
          set_is_all_selected_commercial(true);
          set_commercial_selected_row_keys(commercialWOKeys);

          getServiceAllocationCommercailApiCall({
            offset: 0,
            limit: 5000,
            // service_skill_type__skill_name__in: 'common_area_cleaning,pet_waste_station,installation_repair,pet_waste_station_install',
            scheduled_appointment__gte: schedule_start_time,
            scheduled_appointment__lte: schedule_end_time,
            ordering: 'service_order__client__first_name',
            service_order__field_office__uuid: props.selected_franchise,
            service_order__client_type: 'commercial'
          });
        }
        else {
          sessionStorage.setItem('commercial_selected_row_keys', JSON.stringify([]));

          setState((state) => ({
            ...state,
            all: 0,
            is_all_selected_commercial: false,
            commercial_selected_row_keys: []
          }));
          set_is_all_selected_commercial(false);
          set_commercial_selected_row_keys([]);
        }
        break;
    }
  };

  const renderTable = () => {
    let {
      current_tab,
      // is_all_selected_commercial,
      // is_all_selected_residential,

      //Selected Key
      intial_selected_row_keys,
      // residential_selected_row_keys,
      // commercial_selected_row_keys,

      //Pagination
      intial_staff_pagination,
      residential_staff_pagination,
      commercial_staff_pagination,

      //Datasource
      intial_allocated_service,
      residential_allocated_service,
      commercial_allocated_service
    } = state;

    // if (current_tab === 1) {
    //   return (
    //     <IntialTable
    //       intial_selected_row_keys={intial_selected_row_keys}
    //       intial_staff_pagination={intial_staff_pagination}
    //       intial_allocated_service={intial_allocated_service}
    //       intialPaginationChange={intialPaginationChange}
    //       onIntialSelectedRowKeysChange={onIntialSelectedRowKeysChange}
    //       intial_staff_page_size_limit={renderPagination()}
    //     />
    //   )
    // }

    // else 
    if (current_tab === 1) {
      return (
        <ResidentialTable
          residential_staff_pagination={residential_staff_pagination}
          residential_selected_row_keys={residential_selected_row_keys}
          residential_allocated_service={residentialData}

          handleSelectAll={handleSelectAll}
          residential_page_size_limit={renderPagination()}
          intialPaginationChange={residentialPaginationChange}
          onResidentialSelectedRowKeysChange={onResidentialSelectedRowKeysChange}
          renderHeaderCheckbox={() => renderHeaderCheckbox(is_all_selected_residential, "residential")}
        />
      )
    }
    else if (current_tab === 2) {
      return (
        <CommercialTable
          commercial_selected_row_keys={commercial_selected_row_keys}
          commercial_staff_pagination={commercial_staff_pagination}
          commercial_allocated_service={commercialData}

          handleSelectAll={handleSelectAll}
          commercialPaginationChange={commercialPaginationChange}
          commercial_staff_page_size_limit={renderPagination()}
          onCommercialSelectedRowKeysChange={onCommercialSelectedRowKeysChange}
          renderHeaderCheckbox={() => renderHeaderCheckbox(is_all_selected_commercial, "commercial")}
        />
      )
    }
    // else if (current_tab === 4) {
    //   return (
    //     <InstallTable
    //       install_selected_row_keys={install_selected_row_keys}
    //       install_staff_pagination={install_staff_pagination}
    //       install_allocated_service={install_allocated_service}
    //       installPaginationChange={this.installPaginationChange}
    //       onInstallSelectedRowKeysChange={this.onInstallSelectedRowKeysChange}
    //       install_staff_page_size_limit={this.renderPagination()}
    //     />
    //   )
    // }
  }
  // page size change
  const setStaffPageLimit = (value) => {
    const { intial_staff_pagination, residential_staff_pagination, commercial_staff_pagination, current_tab } = state;

    // if (current_tab === 1) {
    //   setState((state)=>({...state, loader: true }));
    //   Object.assign(intial_staff_pagination, { current: 1, pageSize: value, ordering: '' });
    //   setState({ activePagination: value, intial_staff_pagination, intialActivePagination: value });
    //   getServiceAllocationIntialCleanApiCall({
    //     limit: value,
    //     offset: (intial_staff_pagination.current * 15) - 15,
    //     // service_skill_type__skill_name__in: 'residential_init_clean',
    //     scheduled_time__gte: state.schedule_start_time,
    //     scheduled_time__lte: state.schedule_end_time,
    //     // status: "tentative",
    //     ordering: 'service_order__client__first_name',
    //     service_order__field_office__uuid: props.selected_franchise
    //   })
    // }
    // else
    if (current_tab === 1) {
      setState((state) => ({ ...state, loader: true }));
      setLoaders(true)
      Object.assign(residential_staff_pagination, { current: 1, pageSize: value, ordering: '' });
      setState({ ...state, activePagination: value, residential_staff_pagination, residentialActivePagination: value });
      setActivePagination(value)
      getServiceAllocationResidentailApiCall({
        limit: value,
        offset: (residential_staff_pagination.current * 15) - 15,
        // service_skill_type__skill_name__in: 'residential_regular',
        scheduled_appointment__gte: state.schedule_start_time,
        scheduled_appointment__lte: state.schedule_end_time,
        // status: "tentative",
        ordering: 'service_order__client__first_name',
        service_order__field_office__uuid: checkedFranchiseUuid,
        service_order__client_type: 'residential'
      })
    }
    else if (current_tab === 2) {
      setState((state) => ({ ...state, loader: true }));
      setLoaders(true)
      Object.assign(commercial_staff_pagination, { current: 1, pageSize: value, ordering: '' });
      setState((state) => ({ ...state, activePagination: value, commercial_staff_pagination, commercialActivePagination: value }));
      setActivePagination(value)
      getServiceAllocationCommercailApiCall({
        limit: value,
        offset: (commercial_staff_pagination.current * 15) - 15,
        // service_skill_type__skill_name__in: 'common_area_cleaning,pet_waste_station,installation_repair,pet_waste_station_install',
        scheduled_appointment__gte: state.schedule_start_time,
        scheduled_appointment__lte: state.schedule_end_time,
        // status: "tentative",
        ordering: 'service_order__client__first_name',
        service_order__field_office__uuid: props.selected_franchise,
        service_order__client_type: 'commercial'
      })
    }
    // else if (current_tab === 4) {
    //   this.setState({ loader: true });
    //   Object.assign(install_staff_pagination, { current: 1, pageSize: value, ordering: '' });
    //   this.setState({ activePagination: value, install_staff_pagination, installActivePagination: value });
    //   this.props.getServiceAllocationInstall({
    //     limit: value,
    //     offset: (install_staff_pagination.current * 15) - 15,
    //     service_skill_type__skill_name__in: 'pet_waste_station_install',
    //     scheduled_time__gte: this.state.schedule_start_time,
    //     scheduled_time__lte: this.state.schedule_end_time,
    //     // status: "tentative",
    //     ordering: 'scheduled_time',
    //     service_order__field_office__uuid: this.props.selected_franchise
    //   })
    // }
  };

  const renderPagination = () => {
    // let { activePagination } = state;
    return <><ul className="pagination_page_size">
      <li>
        <span>Show:</span>
      </li>
      <li>
        <span
          onClick={() => { setStaffPageLimit('15') }}
          className={activePagination === '15' ? 'active' : ''}
        >15</span>
      </li>
      <li>
        <span
          className={activePagination === '30' ? 'active' : ''}
          onClick={() => { setStaffPageLimit('30') }}
        > 30</span>
      </li>
      <li>
        <span
          className={activePagination === '60' ? 'active' : ''}
          onClick={() => { setStaffPageLimit('60') }}
        >60</span>
      </li>
    </ul>
    </>
  }

  const validateSelectedRowKeys = async (current_tab) => {
    if (current_tab === 1) {
      if (state.intial_selected_row_keys.length || residential_selected_row_keys.length || commercial_selected_row_keys.length) {
        let params = {
          service_uuid_list: residential_selected_row_keys,
          allocation_start_date: moment(props.weekStartDate).format('YYYY-MM-DD'),
        }
        let response = await sendToOptimoRoute(params);
        if (response) {
          message.success('Services have been sent for scheduling and optimization.', 5);

        }
        setState((state) => ({
          ...state,
          all: 0,
          is_all_selected_commercial: false,
          is_all_selected_residential: false,
          commercial_selected_row_keys: [],
          residential_selected_row_keys: [],
        }));
        props.nextStep(0);
        set_residential_selected_row_keys([]);
      }
      else {
        message.error('You must select at least one service.', 10);
      }
    } else if (current_tab === 2) {
      if (state.intial_selected_row_keys.length || residential_selected_row_keys.length || commercial_selected_row_keys.length) {
        let params = {
          service_uuid_list: commercial_selected_row_keys,
          allocation_start_date: moment(props.weekStartDate).format('YYYY-MM-DD'),
        }
        let response = await sendToOptimoRoute(params);
        if (response) {
          message.success('Services have been sent for scheduling and optimization.', 5);

        }
        setState((state) => ({
          ...state,
          all: 0,
          is_all_selected_commercial: false,
          is_all_selected_residential: false,
          commercial_selected_row_keys: [],
          residential_selected_row_keys: [],
        }));
        props.nextStep(0);
        set_is_all_selected_commercial([])
      }
      else {
        message.error('You must select at least one service.', 10);
      }
    }
  }

  let { current_tab, loader, search_value, franchiseList } = state;
  // let { current } = this.props;

  return (
    <div className="main">
      <div className="filter_wrapper acc-wrap flex no-background p-0">
        <div className="left_side mr-2 d-block">
          <h6 className="mb-5">Step 2 of 2: Select Services</h6>
          <h3>OptimoRoute Scheduling</h3>
        </div>

        <div className="right_side">
          <div className="form-group flex ai-center w-100 flex-end">
            <div className="fitem max-width-83">
              <div className="justify-content-end">
                {
                  <button

                    className="btn btn-primary"
                    onClick={() => {
                      props.nextStep(props.current === 1 ? 0 : null);
                    }}
                  >&laquo;
                    Back
                  </button>
                }
              </div>
            </div>
            <div className="fitem fitem-rinner licon custom-input">
              <div className="fitem-ricon">
                <i className="las la-search"></i>
              </div>
              <DebounceInput
                className="form-control"
                placeholder="Search Requests"
                minLength={3}
                debounceTimeout={1000}
                onChange={(event) => {
                  setState((state) => ({
                    ...state,
                    search_value: event.target.value,
                  }));
                  // if (current_tab === 1) {
                  //   intialPaginationChange()
                  // }
                  // else
                  if (current_tab === 1) {
                    // residentialPaginationChange()
                    residentialPaginationChangeV2(event.target.value);
                  } else if (current_tab === 2) {
                    // commercialPaginationChange()
                    commercialPaginationChangeV2(event.target.value);
                  }
                }}
              />
            </div>
            {
              currentUserRole === 'franchise_owner' || currentUserRole === 'operations_mgr' && <div className="fitem custom-drop-down">
                {renderFranchiseFilters()}
              </div>
            }
            <div className="fitem custom-drop-down">
              {renderStatusFilters()}
            </div>
            <div className="fitem">
              <div className="justify-content-end">
                {
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      validateSelectedRowKeys(state.current_tab);
                    }}
                  >
                    Send to OptimoRoute
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul className="tabs">
        {/* <li className={current_tab === 1 ? 'active ml-0' : 'ml-0'} onClick={() => ChangeTab(1, 'residential_init_clean')}>
            <span>INITIAL CLEANS</span>
          </li> */}
        <li
          className={current_tab === 1 ? "active ml-0" : "ml-0"}
          onClick={() => ChangeTab(1, "residential_regular")}
        >
          <span>RESIDENTIAL</span>
        </li>
        <li
          style={{ position: "relative", left: "10px" }}
          className={current_tab === 2 ? "active ml-0" : "ml-0"}
          onClick={() =>
            ChangeTab(2, "common_area_cleaning,pet_waste_station")
          }
        >
          <span>COMMERCIAL</span>
        </li>
        {/* <li className={current_tab === 4 ? 'active' : ''} onClick={() => this.ChangeTab(4, 'pet_waste_station_install')}>
            <span>INSTALLS</span>
          </li> */}
      </ul>
      <div>{renderTable()}</div>
      {loaders || loader ? <Loader /> : ""}
    </div>
  );
}

export default Step2;