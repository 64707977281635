import React from "react";
import {
  CaretUpFilled,
  CaretDownFilled,
  MinusOutlined,
} from "@ant-design/icons";
import { Row, Col, Skeleton, Tooltip } from "antd";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import { formatToCurrencyNoDecimal } from "../../../Components/Common/formatToCurrency";

import commercialIcon from "../../../Assets/icons/commercial-black.svg";
import residentialIcon from "../../../Assets/icons/residential-black.svg";

const SummaryCard = ({
  title = 0,
  total_count = 0,
  total_residential = 0,
  total_commercial = 0,
  compared_percentage = 0,
  text_color = "#f4b5c2",
  icon_src = null,
  linkTo = "#",
  loading = true,
  currency = false,
  slug = "",
}) => {
  const { periodType } = useDashboard();
  const compared_percentage_color =
    compared_percentage < 0
      ? "#df3d31"
      : compared_percentage === 0
      ? "#403d3d"
      : "#4a9afb";

  function m(n, d) {
    let n_trunc = Math.trunc(n);
    let x = ("" + n_trunc).length;
    let p = Math.pow;
    let den = p(10, d);
    x -= x % 3;
    return Math.round((n_trunc * den) / p(10, x)) / den + " kMGTPE"[x / 3];
  }

  return (
    <div className="cardMetric">
      <Row style={{ alignItems: "center" }}>
        <Col span={4}>
          <img src={icon_src} alt={""} />
        </Col>
        <Col span={20} style={{ paddingLeft: "15px" }}>
          <Row>
            <Col
              span={24}
              style={{
                marginBottom: "5px",
              }}
            >
              <span
                className="cardMetricTotalCount"
                style={{ color: text_color }}
              >
                {loading ? (
                  <Skeleton.Input active={true} size={"small"} />
                ) : (
                  total_count
                )}
              </span>
              <a
                onClick={() => {
                  if (title.includes("New Lead")) {
                    sessionStorage.setItem("CACHED_CURRENT_TAB", "lead");
                  } else {
                    sessionStorage.setItem("CACHED_CURRENT_TAB", "customer");
                  }
                  window.location.pathname = linkTo;
                }}
              >
                <span className="cardMetricTitle" style={{ color: text_color }}>
                  {title}
                </span>
              </a>
            </Col>
          </Row>
          {loading ? (
            <Skeleton.Input
              className={"comparison-skeleton"}
              active={true}
              size={"small"}
            />
          ) : (
            <Row>
              <Col>
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {compared_percentage < 0 ? (
                    <CaretDownFilled
                      style={{
                        fontSize: "15px",
                        marginRight: "5px",
                        color: compared_percentage_color,
                        lineHeight: "1",
                        height: "15px",
                        verticalAlign: "baseline",
                      }}
                    />
                  ) : compared_percentage === 0 ? (
                    <MinusOutlined
                      style={{
                        fontSize: "15px",
                        marginRight: "5px",
                        color: compared_percentage_color,
                        lineHeight: "1",
                        height: "15px",
                        verticalAlign: "baseline",
                      }}
                    />
                  ) : (
                    <CaretUpFilled
                      style={{
                        fontSize: "15px",
                        marginRight: "5px",
                        color: compared_percentage_color,
                        lineHeight: "1",
                        height: "15px",
                        verticalAlign: "top",
                      }}
                    />
                  )}
                  <span
                    className="cardMetricTotalSection"
                    style={{
                      color: compared_percentage_color,
                    }}
                  >
                    {Math.trunc(compared_percentage)} %
                  </span>
                </span>
                <span style={{ fontSize: "14px", marginLeft: "12px" }}>
                  Compared to
                  {periodType === "today" || periodType === "day"
                    ? " yesterday"
                    : ` previous ${periodType}`}
                </span>
              </Col>
            </Row>
          )}
          <Row style={{ margin: "8px 0 0 0" }}>
            {loading ? (
              <Skeleton.Input
                className={"commercial-residential-skeleton"}
                active={true}
                size={"small"}
              />
            ) : (
              <>
                <Col className="commercial-count">
                  <img src={commercialIcon} alt="" />
                  <span className="cardMetricTotalSection">
                    {`${total_commercial}`.length > 3 ? (
                      <Tooltip
                        title={
                          currency
                            ? `$ ${formatToCurrencyNoDecimal(total_commercial)}`
                            : total_commercial
                        }
                        color={"#005d89"}
                        key={"#fff"}
                      >{`${currency ? "$" : ""}${m(
                        total_commercial,
                        1
                      )}`}</Tooltip>
                    ) : (
                      total_commercial
                    )}
                  </span>
                  <span className="cardMetricTotalUnit">Commercial</span>
                </Col>
                <Col className="residential-count">
                  <img src={residentialIcon} alt="" />
                  <span className="cardMetricTotalSection">
                    {`${total_residential}`.length > 3 ? (
                      <Tooltip
                        title={
                          currency
                            ? `$ ${formatToCurrencyNoDecimal(
                                total_residential
                              )}`
                            : total_residential
                        }
                        color={"#005d89"}
                        key={"#fff"}
                      >{`${currency ? "$" : ""}${m(
                        total_residential,
                        1
                      )}`}</Tooltip>
                    ) : (
                      total_residential
                    )}
                  </span>
                  <span className="cardMetricTotalUnit">Residential</span>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ justifyContent: "end" }}>
            <Col>
              <a className="cardMetricGoTo">Go to Report</a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SummaryCard;
