import React, { useContext, useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as yup from "yup";
import _, { map, isEmpty, orderBy } from "lodash";
import moment from "moment-timezone";
import FileUploader from "../../../../Components/FileUploader";
import chevronLeft from "../../../../Assets/images/chevron-left.png";
import Loader from "../../../../Components/Loader";
import {
  ConfigProvider,
  Select,
  Table,
  Input,
  DatePicker,
  TimePicker,
  Modal,
  message,
  Divider,
  Space,
  Button,
  Empty,
  Spin,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import workOrderStatus from "../../../../Components/Common/workorderStatus.json";
import { getFieldTechnician } from "../../../../Action/Franchise/index";
import { formatToCurrency } from "../../../../Components/Common/formatToCurrency";
import DataTable from "./DataTable";
import {
  createServiceLineItem,
  getPrices,
  getLines,
  getWorkOrder,
  getServiceOrderByUser,
  editServiceOrder,
  createWorkOrder,
  getAvalaraTaxValue,
  getUploadImageEditWorkOrder,
  postUploadImage,
} from "../../../../Action/Customer/index";
import { PlusCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import appData from "../../../../Utils/constants";
import ImageUploadWorkOrder from "../../../../Components/ImageUploadWorkOrder";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import {
  resetValues,
  addDuration,
  editDuration
} from "../../../../Store/serviceOrderDetailDataSlice";
import AddDurationField from "../../../../Calendar/Components/Modals/SubComponents/AddDurationField";
import {numberValidate, rateValidate} from "../../../Common/utils";
import { cloneDeep } from "lodash";
import { getCommercialDates } from "../../../../Store/dateSlice";

const { Option } = Select;
const { TextArea } = Input;

const PageContext = React.createContext();

const EditWorkOrder = (props) => {
  const ref=useRef()
  let franchise_data = JSON.parse(
    localStorage.getItem("temp_franchise")
  );
  const [stateone, setStateone] = useState({
    errors: {},
    rate: "",
    quantity_item:""
  });
  const [state, setState] = useState({
    status: "",
    technician: "",
    date: "",
    time: "",
    services_lineitem: [],
    update_services_lineitem: [],
    delete_services_lineitem: [],
    problem_description: "",
    work_order_name: "",
    completion_notes: "",
    tech_notes: "",
    tech_picture: [],
    customer_sign: "",
    customer_picture: [],
    addons: [],
    errors: {},
    email: false,
    // bill_for_cancelization: false
  });
  const [lineItem, setlineItem] = useState({
    include_labor: false,
  });
  const [updateLineItem, setUpdateLineItem] = useState({
    include_labor: false,
    include_bundle: false,
  });
  const [services_lineitem, setservices_lineitem] = useState([]);
  const [price, setPrice] = useState({});
  const [editMode, seteditMode] = useState(false);
  const [techForm, setTechForm] = useState([]);
  const [isAddLineVisible, setisAddLineVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalNoTax, setTotalNoTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalTaxSO, setTotalTaxSO] = useState(0);
  const [totalPlusTaxSO, setTotalPlusTaxSO] = useState(0);
  const [is_description_Edit, set_is_description_Edit] = useState(false);
  const [is_quantity_Edit, set_is_quantity_Edit] = useState(false);
  const [is_unitCost_Edit, set_is_unitCost_Edit] = useState(false);
  const [is_price_Edit, set_is_price_Edit] = useState(false);
  const [is_billable_Edit, set_is_billable_Edit] = useState(false);
  const [taxRate, settaxRate] = useState(0);
  const [addons, setAddons] = useState([]);
  const [take_diagnostic, settake_diagnostic] = useState(true);
  const [errormessagestate , seterrormessagestate] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // const [techImages, settechImages] = useState([]);
  // const [images, setImages] = useState([]);
  // const [imageSources, setImageSources] = useState([]);
  const [buttonLabel, setbuttonLabel] = useState("Save & Email");
  const [loader, setLoader] = useState(false);
  const [sendEmail, setsendEmail] = useState({ send: false, request: false });
  const [service_order, setservice_order] = useState({
    price: 0,
    servicetype: "",
  });
  const [imageSources, setImageSources] = useState([]);
  const [imageSourcesTech, setImageSourcesTech] = useState([]);
  const [base64Image, setBase64Image] = useState({});
  const [tempImage, setTempImage] = useState([]);
  const [tempImageTech, setTempImageTech] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const durationFlag = useRef(false);
  const durationFlagValidator = useRef(false);
  const dispatch = useDispatch();
  const serviceOrderNewFieldsValue = useSelector(
    (state) => state.serviceOrderAddedData
  );

  let serviceStatus =
    props.transection_type === "Create"
      ? workOrderStatus.CreateWorkOrderStatus
      : workOrderStatus.WorkOrderStatus;

  function ServiceDetails_() {
    const { currentRecord, customer, transection_type, goback } = props;
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [field_office__uuid, setField_office__uuid] = useState("");
    const { date, time, status } = state;

    const clearState = () => {
      setState(() => ({
        ...state,
        cleaningAreas: [],
        addons: [],
        errors: {},
        services_lineitem: [],
        update_services_lineitem: [],
        delete_services_lineitem: [],
      }));
      setservices_lineitem([]);
      dispatch(resetValues());
      getData();
    };

    const formatTime = (time) => {
      let splitedTime = time?.split(" ");
      let [hours, minutes] = splitedTime?.[0]?.split(":");
      if (splitedTime?.[1] === "am") {
        return `${hours}:${minutes}`;
      } else {
        return `${parseInt(hours) + 12}:${minutes}`;
      }
    };

    const seachvalidate = (e) => {
      e.preventDefault();
      setTimeout(() => {
        let { rate,quantity_item } = stateone;
        const schema = yup.object().shape({
        rate:yup.number().nullable(true).typeError("Rate is required").test( 'decimal-places', 'Please Enter Valid Rate(upto two decimal places are allowed)', (value) =>/^(-?\d+(\.\d{1,2})?)?$/.test(value)), 
        quantity_item: yup.number().moreThan(0, 'Quantity is required"').typeError("Quantity is required").nullable(true).test( 'decimal-places', 'Please Enter Valid Quantity(Positive or upto two decimal places are allowed)', (value) =>/^(\d+(\.\d{1,2})?)?$/.test(value)),
      });
        const data = { rate,quantity_item };
        let IsValid;
        let err_data = {};
        schema.isValid(data).then(function (valid) {
          IsValid = valid;
        });
        setTimeout(() => {
          if (IsValid) {
            onokbtn();
          } else {
            schema.validate(data, { abortEarly: false }).catch(function (err) {
              map(err.inner, (item) => {
                err_data[item.path] = item.message;
              });
              setStateone({
                ...stateone,
                errors: err_data,
              });
              console.log("err_data:", err_data);
            });
          }
        }, 100);
      }, 300);
    };
    let { errors } = stateone;

    const onokbtn = (event) => {
        if (editMode) {
          updateLine(event);
        } else {
          addLineItem();
        }
        setisAddLineVisible(false);
        clearLineItem();
    }
    useEffect(() => {
      getData();
    }, []);

    useEffect(() => {
      if (
        durationFlagValidator.current ||
        serviceOrderNewFieldsValue.newWOFlag
      ) {
        setLoader(false);
        durationFlag.current = true;
        durationFlagValidator.current = false;
      }
      durationFlagValidator.current = true;
    }, [serviceOrderNewFieldsValue]);

    const getData = () => {
      setLoader(true);
      if (
        location?.state?.record?.from === "ViewByWorkOrder" ||
        location?.state?.record?.from === "workOrder"
      ) {
        let response = {
          data: props.currentRecord,
        }; // get work order

        const getWorkOrderData = async () => {
          let woInfo = await getWorkOrder(props?.currentRecord?.uuid);
          response.data = woInfo;
          getServiceOrder(woInfo?.service_order_uuid);

          let status = "";
          if (
            response?.data?.status === "cancelled" &&
            !response?.data?.bill_for_cancelization
          ) {
            status = "cancelled_non_billable";
          } else if (
            response?.data?.status === "cancelled" &&
            response?.data?.bill_for_cancelization &&
            !response?.data?.is_auto_invoice_genrated
          ) {
            status = "cancelled_billable";
          } else if (
            response?.data?.status === "cancelled" &&
            response?.data?.is_auto_invoice_genrated
          ) {
            status = "Cancelled Billed";
          } else {
            status = response?.data?.status;
          }

          const wo_time = moment.tz(
            response?.data?.scheduled_appointment,
            "UTC"
          );

          setState((s) => ({
            ...s,
            status: status,
            date: moment.tz(response?.data?.scheduled_appointment, "UTC"),
            time: wo_time <= 0 ? "" : moment(wo_time).format("hh:mm a") || "",
            work_order_name: response?.data?.work_order_name,
            worktype: service_order?.worktype,
            worksubtype: service_order?.worksubtype,
            servicetype: service_order?.service_type,
            pool_type: service_order?.pool_type,
            pool_size: service_order?.pool_size,
            field_office_uuid: props.customer?.field_office?.field_office__uuid,
            selectedTechnician: response?.data?.technician_uuid
              ? response?.data?.technician_uuid
              : "",
            completion_notes: response?.data?.completion_notes,
            customer_sign: response?.data?.customer_sign,
            problem_description: response?.data?.problem_description,
            tech_notes: response?.data?.tech_notes,
            avalara_tax_rate: response?.data?.avalara_tax_rate,
            ...response.data,
          }));

          setField_office__uuid(
            props.customer?.field_office?.field_office__uuid
          );
          getWorkOrderLines();
          setTechForm(response?.data?.job_forms);
          getOptions("");
        };
        getWorkOrderData();
      } else if (transection_type === "Update" || transection_type === "View") {
        getServiceOrder(props?.currentRecord?.service_order_uuid);

        const getWorkOrderData = async () => {
          let woInfo = await getWorkOrder(props.currentRecord.uuid);
          getTaxRate(woInfo);
          const wo_time_1 = moment.tz(woInfo?.scheduled_appointment, "UTC");

          setState((s) => ({
            ...s,
            completion_notes: woInfo.completion_notes,
            customer_sign: woInfo.customer_sign,
            problem_description: woInfo.problem_description,
            tech_notes: woInfo.tech_notes,
            avalara_tax_rate: woInfo.avalara_tax_rate,
            date: moment.tz(woInfo?.scheduled_appointment, "UTC"),
            time:
              wo_time_1 <= 0 ? "" : moment(wo_time_1).format("hh:mm a") || "",
            work_order_name: woInfo?.work_order_name,
            selectedTechnician: woInfo?.technician_uuid
              ? woInfo?.technician_uuid
              : "",
            ...woInfo,
          }));
          dispatch(
            addDuration(
              woInfo?.service_duration ||
                franchise_data?.data?.default_job_duration
            )
          );
          dispatch(editDuration(true));
        };
        getWorkOrderData();

        let response = {
          data: props.currentRecord,
        };
        let status = "";
        if (
          response?.data?.status === "cancelled" &&
          !response?.data?.bill_for_cancelization
        ) {
          status = "cancelled_non_billable";
        } else if (
          response?.data?.status === "cancelled" &&
          response?.data?.bill_for_cancelization &&
          !response?.data?.is_auto_invoice_genrated
        ) {
          status = "cancelled_billable";
        } else if (
          service_order?.status === "cancelled" &&
          response?.data?.is_auto_invoice_genrated
        ) {
          status = "Cancelled Billed";
        } else {
          status = response?.data?.status;
        }

        const wo_time = moment.tz(response?.data?.scheduled_appointment, "UTC");

        setState((s) => ({
          ...s,
          status: status,
          date: moment.tz(response?.data?.scheduled_appointment, "UTC"),
          time: wo_time <= 0 ? "" : moment(wo_time).format("hh:mm a") || "",
          work_order_name: response?.data?.work_order_name,
          worktype: service_order?.worktype,
          worksubtype: service_order?.worksubtype,
          servicetype: service_order?.service_type,
          pool_type: service_order?.pool_type,
          pool_size: service_order?.pool_size,
          field_office_uuid: props.customer?.field_office?.field_office__uuid,
          selectedTechnician: response?.data?.technician_uuid
            ? response?.data?.technician_uuid
            : "",
          completion_notes: service_order?.notes,
          problem_description: response?.data?.problem_description,
          tech_notes: response?.data?.tech_notes,
        }));
        setField_office__uuid(props.customer?.field_office?.field_office__uuid);
        getWorkOrderLines();
        setTechForm(response?.data?.job_forms);

        getOptions("");
      } else if (transection_type === "Create") {
        getServiceOrder(props?.service?.uuid);
        getAvalaraTax();
        const service_order = props?.service;
        setState((s) => ({
          ...s,
          status: "Forecast",
          date: moment.tz(new Date(), "UTC"),
          time: moment("12").format("hh:mm a") || "",
          field_office_uuid: props.customer?.field_office?.uuid,
          problem_description: service_order?.notes,
          tech_notes: service_order?.tech_notes,
          service_duration:service_order?.service_duration,
          work_order_name:
            props.customer?.first_name +
            " " +
            props.customer?.last_name +
            " " +
            service_order?.worktype,
        }));
        dispatch(
          addDuration(
            service_order?.service_duration
          )
        );
        dispatch(editDuration(true));
      }
      setLoader(false);
    };

    useEffect(() => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      const redirectToWorkOrder = params.redirect_to_work_order;
      let data;

      if (transection_type === "Update" || transection_type === "View") {
        if (redirectToWorkOrder) {
          data = {
            field_office_uuid: customer?.field_office?.field_office__uuid,
            worktype: service_order?.worktype,
            worksubtype: service_order?.worksubtype,
          };
          setField_office__uuid(customer?.field_office?.field_office__uuid);
        } else {
          data = {
            field_office_uuid: customer?.field_office?.field_office__uuid,
            worktype: service_order?.worktype,
            worksubtype: service_order?.worksubtype,
          };
          setField_office__uuid(customer?.field_office?.field_office__uuid);
        }

        setPrice(currentRecord?.price);
      } else if (transection_type === "Create") {
        setField_office__uuid(customer?.field_office?.field_office__uuid);
        setPrice(service_order?.price);
      }
    }, [state]);

    useEffect(() => {
      CalculateTotal();
      calculateNoTax();
      calculateTax();
    }, [services_lineitem]);

    useEffect(() => {
      //Remove Diagnostic quote
      removeDiagnostic();
      const index = services_lineitem
        .map((e) => e.repair_code)
        .indexOf(state.servicetype);

      let serviceLine = services_lineitem.find(
        (line) =>
          line.repair_code?.toLowerCase() === state.servicetype?.toLowerCase()
      );
      const updateLine = {
        ...serviceLine,
        billable: take_diagnostic,
      };

      let lines = services_lineitem;
      lines[index] = updateLine;
      setservices_lineitem(lines);
    }, [take_diagnostic]);

    useEffect(() => {
      if (sendEmail.request) validate();
    }, [sendEmail]);

    useEffect(() => {
      CalculateTotalSO();
    }, [totalTaxSO, service_order]);

    const getServiceOrder = async (service_order_uuid) => {
      let data = await getServiceOrderByUser(service_order_uuid);

      setState((s) => ({
        ...s,
        worktype: data?.work_type ?? "", //this
        worksubtype: data?.work_subtype ?? "", //This
        servicetype: data?.service_type,
        pool_type: data?.pool_type,
        pool_size: data?.pool?.pool_size ?? data?.pool_size,
        completion_notes: data?.notes,
      }));
      data.price = data.service_price;

      setservice_order(data);
    };

    const getTaxRate = (data) => {
      if (data.tax_rate) {
        settaxRate(amount(data.tax_rate));
      } else {
        settaxRate(amount(data.avalara_tax_rate));
      }
    };

    const createNewWorkOrder = async () => {
      let SL = [];
      setLoader(true);

      state.services_lineitem.forEach((element) => {
        SL.push({
          work_order_uuid: element.work_order_uuid,
          repair_code: element.repair_code,
          bundle_code: element.bundle_code ?? null,
          description: element.description,
          quantity: parseFloat(element.quantity).toFixed(2),
          unit_cost: parseFloat(element.unit_cost),
          is_taxable: element.is_taxable,
          tax: element.tax ? parseFloat(element.tax).toFixed(2) : "0.00",
          qb_account: element.qb_account ? element.qb_account : "",
        });
      });

      let data = {
        status:
          state?.status === "cancelled_non_billable" ||
          state?.status === "cancelled_billable"
            ? "cancelled"
            : state?.status.toLowerCase(),
        scheduled_appointment: state?.date
          ? moment(state?.date).format("YYYY-MM-DD")
          : null,
        problem_description: state.problem_description,
        completion_notes: state.completion_notes,
        tech_notes: state.tech_notes,
        customer_sign: state.customer_sign,
        work_order_name: state.work_order_name,
        services_lineitem: SL,
        update_services_lineitem: state.update_services_lineitem,
        delete_services_lineitem: state.delete_services_lineitem,
        pool_size: state.pool_size,
        tax_rate: state.tax_rate.toString(),
        email: sendEmail.send,
        bill_for_cancelization:
          state?.status === "cancelled_non_billable"
            ? false
            : state?.status === "cancelled_billable"
            ? true
            : null,
        service_order_number: currentRecord?.uuid,
        service_duration: serviceOrderNewFieldsValue?.duration || "",
      };
      // check technician
      if (state?.selectedTechnician) {
        data["technician_uuid"] = state?.selectedTechnician;
      }
      // check time
      if (state?.time) {
        data["time"] = !state?.time
          ? "12:00:00"
          : moment(state?.time).format("HH:mm:ss") === "Invalid date"
          ? formatTime(state?.time)
          : moment(state?.time).format("HH:mm:ss");
      }
      if (state?.status === "complete_and_billed") {
        data["is_auto_invoice_genrated"] = true;
      }
      if (state?.status === "complete_and_not_billed") {
        data["is_auto_invoice_genrated"] = false;
      }
      if (state?.status === "cancelled_non_billable") {
        data["bill_for_cancelization"] = false;
      }
      if (state?.status === "cancelled_billable") {
        data["bill_for_cancelization"] = true;
        data["is_auto_invoice_genrated"] = false;
      }
      // /service-ftech API
      const response = await createWorkOrder(data);

      if (response.uuid) {
        message.success(
          `Work order ${props.currentRecord?.work_order_number || ""} updated.`,
          10
        );
        clearState();
        setLoader(false);
        goback();
        dispatch(getCommercialDates(props?.customer?.uuid))
      } else
        message.error("The work order habe not been save, please try again.");
    };

    const updateWorkOrder = async () => {
      let SL = [];
      setLoader(true);

      state.services_lineitem.forEach((element) => {
        SL.push({
          work_order_uuid: element.work_order_uuid,
          repair_code: element.repair_code,
          bundle_code: element.bundle_code ?? null,
          description: element.description,
          quantity: parseFloat(element.quantity).toFixed(2),
          unit_cost: parseFloat(element.unit_cost),
          is_taxable: element.is_taxable,
          tax: element.tax ? parseFloat(element.tax).toFixed(2) : "0.00",
          qb_account: element.qb_account ? element.qb_account : "",
        });
      });

      const update_services_lineitem_temp  = cloneDeep(state.update_services_lineitem);
      for (let index = update_services_lineitem_temp.length - 1; index >= 0; index--) {
        const exist = SL.find((sl)=> {return sl.repair_code == update_services_lineitem_temp[index].repair_code})
        if(exist){
          update_services_lineitem_temp.splice(index, 1);
        }
      }

      let data = {
        status:
          state?.status === "cancelled_non_billable" ||
          state?.status === "cancelled_billable"
            ? "cancelled"
            : state?.status,
        date: moment(state?.date).format("YYYY-MM-DD"),
        problem_description: state.problem_description,
        completion_notes: state.completion_notes,
        tech_notes: state.tech_notes,
        customer_sign: state.customer_sign,
        customer_picture: state.customer_picture,
        tech_picture: state.tech_picture,
        work_order_name: state.work_order_name,
        services_lineitem: SL,
        update_services_lineitem: update_services_lineitem_temp,
        delete_services_lineitem: state.delete_services_lineitem,
        pool_size: state.pool_size,
        tax_rate: state.tax_rate.toString(),
        email: sendEmail.send,
        bill_for_cancelization:
          state?.status === "cancelled_non_billable"
            ? false
            : state?.status === "cancelled_billable"
            ? true
            : null,
        service_duration: serviceOrderNewFieldsValue?.duration || "",
      };

      data["time"] = !state?.time
        ? "12:00:00"
        : moment(state?.time).format("HH:mm:ss") === "Invalid date"
        ? formatTime(state?.time)
        : moment(state?.time).format("HH:mm:ss");

      // check technician
      // if (state?.selectedTechnician) {
      data["technician_uuid"] = state?.selectedTechnician;
      const a = services_lineitem.find(
        (item) =>
          item.repair_code === "service" ||
          item.repair_code === "renovation" ||
          item.repair_code === "construction"
      );
      const isUpdated =
        a !== undefined
          ? update_services_lineitem_temp.find((item) => item.uuid === a.uuid)
          : false;
      if (isUpdated) {
        // Commented below API here as is creating duplicate Work orders on updating it.
        // const replyEditService = await editServiceOrder(
        //   props.currentRecord.service_order_uuid,
        //   {
        //     client_uuid: props.currentRecord.client_uuid,
        //     service_type_uuid:
        //       props.currentRecord.service_order.service_type_uuid,
        //     service_price: isUpdated.total,
        //     service_address: props.currentRecord.service_order.service_address,
        //     service_order_name:
        //       props.currentRecord.service_order.service_order_name,
        //     frequency_settings:
        //       props.currentRecord.service_order.frequency_settings,
        //     notes:
        //       props.currentRecord.service_order.notes === null
        //         ? ""
        //         : props.currentRecord.service_order.notes,
        //     pool_characteristics_uuid:
        //       props.currentRecord.service_order.pool_characteristics_uuid,
        //   }
        // );
        props.setSelectedService({
          ...props.service,
          service_price: isUpdated.total,
        });
      }
      const response = await createServiceLineItem(
        data,
        props.currentRecord.uuid
      );

      message.success(
        `Work order ${props.currentRecord?.work_order_number || ""} updated.`,
        10
      );
      clearState();
      setLoader(false);
      if (sendEmail.request) {
        setsendEmail({ send: false, request: false });
        setbuttonLabel("Save & Re-send");
      } else {
        goback();
      }
      dispatch(getCommercialDates(props?.customer?.uuid))
    };

    const validate = () => {
      let isFutureDate = false;
      let isPastDate = false;
      let selectTime = false;

      let selectedTime =
        moment(state.time).format("hh:mm a") === "Invalid date"
          ? state.time
          : moment(state.time).format("hh:mm a");

      if (
        state.status.toLocaleLowerCase() === "complete" ||
        state.status.toLowerCase() === "incomplete"
      ) {
        isFutureDate = !moment(date, "YYYY/MM/DD").isBefore(moment());
        // selectTime = true;
      }

      if (
        state.status.toLocaleLowerCase() === "forecast" ||
        state.status.toLocaleLowerCase() === "scheduled"
      ) {
        isPastDate = moment(date, "YYYY/MM/DD").isBefore(
          moment(new Date()).format("YYYY/MM/DD")
        );
      }

      const schema = yup.object().shape({
        status: yup.string().required("Please select valid status."),
        technician_uuid:
          state.status === "complete" || state.status === "scheduled"
            ? yup.string().required("Please select assigned technician.")
            : null,
        time:
          state.status === "complete" || state.status === "incomplete"
            ? yup.string().required("Please select time.")
            : null,
        time_past:
          isPastDate && (time || status === "forecast")
            ? yup
                .string()
                .required(
                  "Work orders in this status cannot have a date/time in the past."
                )
            : null,
        time_future: isFutureDate
          ? yup
              .string()
              .required(
                "Work orders in this status cannot have a date/time in the future."
              )
          : null,
        date: yup.string().required("Please select date."),
        date_past:
          isPastDate && (date || status === "forecast")
            ? yup
                .string()
                .required(
                  "Work orders in this status cannot have a date/time in the past."
                )
            : null,
        work_order_name: yup.string().required("Please add Job Name."),
      });

      const data = {
        date: date || "",
        time,
        status: state.status,
        technician_uuid: state.selectedTechnician,
        work_order_name: state.work_order_name,
      };
      schema
        .validate(data, { abortEarly: false })
        .then(() => {
          setState((s) => ({ ...s, errors: {} }));
          if (transection_type === "Update") {
            postImageUpload();
          } else if (transection_type === "Create") {
            createNewWorkOrder();
          }
          setButtonDisabled(true);
        })
        .catch((error) => {
          let err_data = {};
          console.log(error);
          map(error.inner, (item) => {
            err_data[item.path] = item.message;
          });
          setState((s) => ({ ...s, errors: err_data }));
        });
    };

    const totalPriceDescription = (row) => {
      return amount(row.unit_cost * row.quantity);
    };

    function getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let tempBase64 = { ...base64Image };
        tempBase64[file.name] = reader.result;
        setBase64Image(tempBase64);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    const getFilesCustomer = (list) => {
      list.forEach((each) => {
        if (Object.keys(each).includes("image_url") && !each.image) {
          getBase64(each.image_url);
        }
      });
      setImageSources(list);
    };

    const getFilesTechnical = (list) => {
      list.forEach((each) => {
        if (Object.keys(each).includes("image_url") && !each.image) {
          getBase64(each.image_url);
        }
      });
      setImageSourcesTech(list);
    };

    const getImageUpload = async (id) => {
      setIsLoading(true);
      let customerImage = [];
      let technicalImage = [];
      const data = await getUploadImageEditWorkOrder(id);
      if (!isEmpty(data)) {
        data.forEach((each) => {
          if (each.image_type === "Service Work Order Customer") {
            let tempObjCustomer = {
              uid: each.id,
              name: each.name,
              url: each.image,
            };
            customerImage.push(tempObjCustomer);
          } else if (each.image_type === "Service Work Order Technical") {
            let tempObjTechnical = {
              uid: each.id,
              name: each.name,
              url: each.image,
            };
            technicalImage.push(tempObjTechnical);
          }
        });
      }

      setImageSources(customerImage);
      setImageSourcesTech(technicalImage);
      setIsLoading(false);
    };

    const postImageUpload = async () => {
      let payload = {};
      let uploadedImageListCustomer = [];
      let uploadedImageListTechnical = [];
      imageSources.forEach((each) => {
        if (Object.keys(each).includes("image_url")) {
          let tempObj = {};
          tempObj["id"] = 0;
          tempObj["image"] = each.image ?? base64Image[each.image_url.name];
          tempObj["name"] = each.image_url.name;
          tempObj["type"] = "customer";

          uploadedImageListCustomer.push(tempObj);
        } else {
          let tempObj = {};
          tempObj["id"] = each.uid;
          tempObj["image"] = each.url;
          tempObj["name"] = each.name;
          tempObj["type"] = "customer";

          uploadedImageListCustomer.push(tempObj);
        }
      });
      imageSourcesTech.forEach((each) => {
        if (Object.keys(each).includes("image_url")) {
          let tempObjTech = {};
          tempObjTech["id"] = 0;
          tempObjTech["image"] = each.image ?? base64Image[each.image_url.name];
          tempObjTech["name"] = each.image_url.name;
          tempObjTech["type"] = "technical";

          uploadedImageListTechnical.push(tempObjTech);
        } else {
          let tempObjTech = {};
          tempObjTech["id"] = each.uid;
          tempObjTech["image"] = each.url;
          tempObjTech["name"] = each.name;
          tempObjTech["type"] = "technical";

          uploadedImageListTechnical.push(tempObjTech);
        }
      });
      // }
      let resultImage = uploadedImageListCustomer.concat(
        uploadedImageListTechnical
      );
      payload["id"] = props?.currentRecord?.uuid;
      payload["id_type"] = "service";
      payload["images"] = resultImage;

      const data = await postUploadImage(payload);
      if (data?.id) {
        setLoader(false);
        message.success("Image Upload Successfully!..");
        getUploadImageEditWorkOrder(props?.currentRecord?.uuid);
      }
      updateWorkOrder();
    };

    useEffect(() => {
      getImageUpload(props?.currentRecord?.uuid);
    }, []);

    const getWorkOrderLines = async () => {
      let descriptionListTable = await getLines({
        service_uuid: props.currentRecord.uuid,
      });

      setservices_lineitem(descriptionListTable);

      calculateNoTax();
      CalculateTotal();
    };

    const editLine = (record) => {
      let temp_Obj = {
        errors: {},
        rate: record?.unit_cost,
        quantity_item: record?.quantity,
      };
      ref.current = record;
      setStateone(temp_Obj);
      seterrormessagestate(true);
    };
    useEffect(() => {
      if (  errormessagestate == true &&  stateone["rate"] != "" && stateone["quantity_item"] != "" ) {
        let temp = ref.current;
        setisAddLineVisible(true);
        setlineItem(temp);
        setUpdateLineItem({ ...temp });
       seteditMode(true);
        setvalue(temp.repair_code || "");

        CalculateTotal();
        calculateNoTax();
        seterrormessagestate(false);
        ref.current = "";
      }
    }, [stateone, errormessagestate]);

    const addLineItem = () => {
      lineItem.work_order_uuid = props.currentRecord.uuid;
      lineItem.total = lineItem.unit_cost * lineItem.quantity;
      lineItem.quantity = parseFloat(lineItem.quantity).toFixed(2);
      lineItem.unit_cost = parseFloat(lineItem.unit_cost);
      lineItem.is_taxable = lineItem.is_taxable ? lineItem.is_taxable : false;
      lineItem.qb_account = lineItem.qb_account ? lineItem.qb_account : "";
      const alreadyExist = services_lineitem.find((item) => {
        return (
          item.repairCode == lineItem.repairCode &&
          item.description == lineItem.description &&
          item.unit_cost == lineItem.unit_cost
        );
      });
      if (alreadyExist) {
        message.warn(
          "The repair code must be different in at least unit cost or description"
        );
        return;
      }

      let state_items = state.services_lineitem;
      let items = services_lineitem;

      items.push(lineItem);

      const isAdded = state_items.indexOf(lineItem);
      if (isAdded < 0) state_items.push(lineItem);

      setservices_lineitem(items);
      setState((s) => ({ ...s, services_lineitem: state_items }));

      CalculateTotal();
      calculateNoTax();
      calculateTax();

      if (lineItem.include_labor) {
        createLabor();
        if (lineItem.include_bundle) {
          createBundle();
        }
      }
      if (lineItem.is_taxable) {
        applyTax(lineItem, true);
      }
      setcodeOptions([]);
    };

    const createBundle = () => {
      const item_unitCost = parseFloat(lineItem.unit_cost);
      const labor_unitCost = lineItem.totallabor ? lineItem.totallabor : 0;

      const item_total = lineItem.unit_cost * lineItem.quantity;
      const labor_total = lineItem.quantity * lineItem.totallabor;

      const bundleLine = {
        work_order_uuid: props.currentRecord.uuid,
        repair_code: lineItem.repair_code,
        bundle_code: lineItem.repair_code,
        description: lineItem.description,
        is_taxable: lineItem.is_taxable && lineItem.apply_labor_tax,
        unit_cost: item_unitCost + labor_unitCost,
        quantity: lineItem.quantity,
        total: item_total + labor_total,
        qb_account: lineItem.qb_account,
      };

      let state_items = state.services_lineitem;
      state_items.push(bundleLine);

      setState((s) => ({ ...s, services_lineitem: state_items }));

      let items = services_lineitem;
      const isAdded = items.indexOf(bundleLine);
      if (isAdded < 0) items.push(bundleLine);

      setservices_lineitem(items);

      if (bundleLine.is_taxable) {
        applyTax(bundleLine, false, true);
      } else if (lineItem.is_taxable || lineItem.apply_labor_tax) {
        applyBundleTax(bundleLine);
      }

      CalculateTotal();
      calculateNoTax();
      calculateTax();
    };

    const createLabor = () => {
      const laborLine = {
        work_order_uuid: props.currentRecord.uuid,
        repair_code: lineItem.repair_code + "-Labor",
        description: "LBR-" + lineItem.description,
        is_taxable: lineItem.apply_labor_tax ? lineItem.apply_labor_tax : 0,
        unit_cost: lineItem.totallabor ? lineItem.totallabor : 0,
        quantity: lineItem.quantity,
        total: lineItem.quantity * lineItem.totallabor,
        qb_account: lineItem.qb_account,
      };

      let state_items = state.services_lineitem;
      state_items.push(laborLine);

      setState((s) => ({ ...s, services_lineitem: state_items }));

      let items = services_lineitem;
      const isAdded = items.indexOf(laborLine);
      if (isAdded < 0) items.push(laborLine);
      setservices_lineitem(items);

      if (laborLine.is_taxable) {
        applyTax(laborLine, true);
      }

      CalculateTotal();
      calculateNoTax();
      calculateTax();
    };

    const updateLine = (updatedBillable = false) => {
      if (
        updateLineItem.repairCode == lineItem.repairCode &&
        updateLineItem.description == lineItem.description &&
        updateLineItem.unit_cost == lineItem.unit_cost &&
        updateLineItem.quantity == lineItem.quantity
      ) {
        return;
      }
      const alreadyExist = services_lineitem.find((item) => {
        return (
          item.repairCode == lineItem.repairCode &&
          item.description == lineItem.description &&
          item.unit_cost == lineItem.unit_cost
        );
      });
      if (alreadyExist) {
        if (
          updateLineItem.repairCode != alreadyExist.repairCode ||
          updateLineItem.description != alreadyExist.description ||
          updateLineItem.unit_cost != alreadyExist.unit_cost
        ) {
          message.warn(
            "You already have that same item with that price added, please modify the qty"
          );
          return;
        }
      }
      //const index = services_lineitem.map(e => e.repair_code).indexOf(lineItem.repair_code);
      let updateLine = { ...alreadyExist, ...lineItem };
      let lines = services_lineitem.map((item) => {
        if (
          item.repairCode == updateLineItem.repairCode &&
          item.description == updateLineItem.description &&
          item.unit_cost == updateLineItem.unit_cost
        ) {
          return { ...alreadyExist, ...lineItem };
        }
        return item;
      });
      const removeBundle =
        alreadyExist?.include_bundle !== updateLine.include_bundle;
      const removeLabor =
        alreadyExist?.include_labor !== updateLine.include_labor;

      //lines[index] = updateLine;
      setservices_lineitem(lines);
      if (updateLine.uuid) {
        let currentUpdatingLineItem = (state.update_services_lineitem).filter((curr) => { return curr.uuid === updateLine.uuid});
        let nonUpdatingLineItem = (state.update_services_lineitem).filter((curr) => { return curr.uuid !== updateLine.uuid});

        let updatedValues = {
          uuid: updateLine.uuid,
          is_taxable:false
        };
        updatedValues["work_order_uuid"] = props.currentRecord.uuid;

        if (is_description_Edit) {
          if (currentUpdatingLineItem?.length > 0)
            currentUpdatingLineItem[0].description = updateLine.description;
          else updatedValues["description"] = updateLine.description;
        }
        if (is_quantity_Edit) {
          if (currentUpdatingLineItem?.length > 0) {
            currentUpdatingLineItem[0].quantity = parseFloat(updateLine.quantity).toFixed(2);
            currentUpdatingLineItem[0].total = updateLine.quantity * updateLine.unit_cost;
          }
          else {
            updatedValues["quantity"] = parseFloat(updateLine.quantity).toFixed(2);
            updatedValues["total"] = 
              updateLine.quantity * updateLine.unit_cost
            ;
          }
        }
        if (is_unitCost_Edit) {
          if (currentUpdatingLineItem?.length > 0) {
            currentUpdatingLineItem[0].unit_cost = updateLine.unit_cost;
            currentUpdatingLineItem[0].total = updateLine.quantity * updateLine.unit_cost;
          }
          else {
            updatedValues["unit_cost"] = updateLine.unit_cost;
            updatedValues["total"] = 
              updateLine.quantity * updateLine.unit_cost;
          }
        }
        if (is_price_Edit) {
          if (currentUpdatingLineItem?.length > 0)
            currentUpdatingLineItem[0].price = updateLine.pricet;
          else updatedValues["price"] = updateLine.price;
        }
        if (is_billable_Edit || updatedBillable) {
          if (currentUpdatingLineItem?.length > 0)
            currentUpdatingLineItem[0].billable = updateLine.billable;
          else updatedValues["billable"] = updateLine.billable;
        }
        let updatedLineitem = state.update_services_lineitem;

        if(currentUpdatingLineItem?.length == 0){
        updatedLineitem.push(updatedValues);}
        else {
          nonUpdatingLineItem.push(currentUpdatingLineItem[0])
        }
        setState((s) => ({
          ...s,
          update_services_lineitem: updatedLineitem || nonUpdatingLineItem,
        }));
      } else {
        //const _index = state.services_lineitem.map(e => e.repair_code).indexOf(lineItem.repair_code);
        //let services_lineitem = state.services_lineitem;
        //services_lineitem[_index] = updateLine;

        setState((s) => ({ ...s, services_lineitem: lines }));
      }

      if (removeBundle || removeLabor) {
        removeBundleLabor(lineItem);
      }

      CalculateTotal();
      calculateNoTax();
    };

    const updateDiagnostic = (record) => {
      set_is_billable_Edit(true);
      settake_diagnostic(!record.billable);
      record.billable = !record.billable;
      setlineItem(record);
      updateLine(true);
    };

    const clearLineItem = () => {
      setlineItem({
        repair_code: "",
        description: "",
        unit_cost: 0,
        quantity: (1).toFixed(2),
        price: 0,
        include_labor: false,
      });
      setvalue("");
      setStateone({
        errors: {},
        rate:"",
        quantity_item:"",
      });
    };

    const deleteLine = async (row) => {
      let line = {};
      let lines = [];
      if (row.bundle_code) {
        line = services_lineitem.find((item) => {
          return (
            item.repair_code === row.repair_code &&
            item.bundle_code === row.bundle_code
          );
        });

        let index = services_lineitem.indexOf(line);
        services_lineitem.splice(index, 1); //Remove bundle

        lines = services_lineitem;

        setservices_lineitem(lines);
      } else {
        line = services_lineitem.find((item) => {
          return (
            _.isEqual(item, row) &&
            (item.bundle_code === undefined || item.bundle_code === null)
          );
        });
        lines = services_lineitem.filter((item) => !_.isEqual(item, row));
        setservices_lineitem(lines);
      }

      if (line.uuid) {
        let delete_services_lineitem = state.delete_services_lineitem;
        delete_services_lineitem.push(line.uuid);
        setState((s) => ({
          ...s,
          delete_services_lineitem: delete_services_lineitem,
        }));
      } else {
        setState((s) => ({
          ...s,
          services_lineitem: lines.filter((item) => (item.uuid ? false : true)),
        }));
      }

      if (row.bundle_code || !row.repair_code?.includes("Labor")) {
        //Delete Labor line
        let labor_line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code + "-Labor";
        });

        if (labor_line) {
          let indexLabor = lines.indexOf(labor_line);
          lines.splice(indexLabor, 1);

          if (labor_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(labor_line.uuid);
            setState((s) => ({
              ...s,
              delete_services_lineitem: delete_services_lineitem,
            }));
          } else {
            setState((s) => ({
              ...s,
              services_lineitem: lines.filter((item) =>
                item.uuid ? false : true
              ),
            }));
          }
        }
      }

      const code = row.repair_code.includes("Labor")
        ? row.repair_code.split("-Labor")[0]
        : row.repair_code;
      let bundle_line = services_lineitem.find((item) => {
        return item.bundle_code === code;
      });

      if (!row.bundle_code && (row.include_bundle || bundle_line)) {
        //Delete Bundle line
        if (bundle_line) {
          let indexbundle = lines.indexOf(bundle_line);
          lines.splice(indexbundle, 1);
          if (bundle_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(bundle_line.uuid);
            setState((s) => ({
              ...s,
              delete_services_lineitem: delete_services_lineitem,
            }));
          } else {
            setState((s) => ({ ...s, services_lineitem: lines }));
          }
        }
      }

      if (row.bundle_code) {
        //Delete Product line
        let product_line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code;
        });

        if (product_line) {
          let indexbundle = lines.indexOf(product_line);
          lines.splice(indexbundle, 1);
          if (product_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(product_line.uuid);
            setState((s) => ({
              ...s,
              delete_services_lineitem: delete_services_lineitem,
            }));
          } else {
            setState((s) => ({
              ...s,
              services_lineitem: lines.filter((item) =>
                item.uuid ? false : true
              ),
            }));
          }
        }
      }

      return new Promise((resolve, reject) => {
        setservices_lineitem(lines);
        CalculateTotal();
        calculateTax();
        calculateNoTax();
        resolve(true);
      });
    };

    const CalculateTotal = () => {
      let _total = 0;

      services_lineitem.forEach((item) => {
        if (!item.bundle_code) {
          const base_price = +item.quantity * +item.unit_cost;
          if (item.is_taxable)
            _total += (taxRate * base_price) / 100 + base_price;
          else _total += base_price;
        }
      });

      let _addons = [];
      setAddons(_addons);

      services_lineitem.forEach((item) => {
        let repairCode = item?.repair_code?.includes("Labor")
          ? item.repair_code.split("-Labor")[0]
          : item.repair_code;
        const bundleItem = services_lineitem.find(
          (i) => i.repair_code === repairCode && i.bundle_code === repairCode
        );

        if (!item.include_bundle) {
          if (!bundleItem) {
            _addons.push({
              name: item.description,
              price: item.total,
            });
          }
        } else {
          if (bundleItem)
            _addons.push({
              name: bundleItem.description,
              price: bundleItem.total,
            });
        }
        setAddons(_addons);
      });

      if (!take_diagnostic)
        _total -= service_order?.is_recurring
          ? +service_order?.price
          : +props?.currentRecord?.base_price;

      setTotal(_total);
    };
    const CalculateTotalSO = () => {
      let _total = service_order.price + parseFloat(totalTaxSO);
      setTotalPlusTaxSO(_total);
    };

    const removeDiagnostic = () => {
      const lines = services_lineitem.filter((item) => {
        return (
          item.repair_code?.toLowerCase() !== state.servicetype?.toLowerCase()
        );
      });
    };

    const applyBundleTax = (lineItem) => {
      const index = services_lineitem
        .map((e) => e.bundle_code)
        .indexOf(lineItem.repair_code);
      let updateLine = services_lineitem.at(index);

      let tax_value = 0;
      let total_value = 0;
      services_lineitem
        .filter((f) => f.is_taxable === true)
        .forEach((each) => {
          tax_value += (+taxRate / 100) * (+each.unit_cost * +each.quantity);
          total_value += each.unit_cost * each.quantity;
        });
      updateLine.tax = tax_value.toString();
      updateLine.total = total_value.toString();

      let services_lines_added = state.services_lineitem;
      const indexEdited = services_lines_added
        .map((e) => e.repair_code)
        .indexOf(lineItem.repair_code);
      let updateLineEdited = services_lines_added.at(indexEdited);

      updateLineEdited.work_order_uuid = props.currentRecord.uuid;
      updateLineEdited.tax = updateLine.tax;
      updateLineEdited.total = updateLine.total;
      services_lines_added[indexEdited] = updateLineEdited;

      setservices_lineitem((s) => ({ ...s, services_lines_added }));
      setState((s) => ({ ...s, services_lineitem: services_lines_added }));
    };
    const applyTax = (
      lineItem,
      isLabor = false,
      isBundle = false,
      updateTax = false,
      originValue = null
    ) => {
      let index;
      const original_value = originValue ?? lineItem.is_taxable;

      if (!isBundle) {
        index = services_lineitem.indexOf(lineItem);
      } else {
        index = services_lineitem
          .map((e) => e.bundle_code)
          .indexOf(lineItem.repair_code);
      }

      let updateLine = services_lineitem.at(index);

      if (updateTax) {
        updateLine.is_taxable = lineItem.is_taxable;
      } else {
        updateLine.is_taxable =
          isLabor || isBundle
            ? lineItem.is_taxable
            : updateLine.is_taxable
            ? !updateLine.is_taxable
            : true;
      }

      let lines = services_lineitem;
      updateLine.work_order_uuid = props.currentRecord.uuid;

      if (updateLine.is_taxable) {
        updateLine.is_taxable = true;
        updateLine.tax = (
          (+taxRate / 100) *
          (lineItem.unit_cost * lineItem.quantity)
        )
          .toFixed(2)
          .toString();
        updateLine.total = (lineItem.unit_cost * lineItem.quantity)
          .toFixed(2)
          .toString();
        lines[index] = updateLine;
      } else {
        updateLine.is_taxable = false;
        updateLine.tax = "0.00";
        updateLine.total = (lineItem.unit_cost * lineItem.quantity)
          .toFixed(2)
          .toString();
        lines[index] = updateLine;
        // if(isBundle){
        //   let tax_value = 0;
        //   let total_value = 0;
        //   services_lineitem.filter((f) => f.is_taxable === true).forEach((each) => {
        //     tax_value += ((+taxRate / 100) * (+each.unit_cost * +each.quantity));
        //     total_value += ((each.unit_cost * each.quantity));
        //   })
        //   updateLine.tax = tax_value.toString();
        //   updateLine.total = total_value.toString();
        // }
      }

      if (updateLine.is_taxable !== original_value || isBundle) {
        if (updateLine.uuid) {
            let update_services_lineitem = []
           /*  let update_services_lineitem = structuredClone(
              state.update_services_lineitem
            );
            update_services_lineitem.push({
              work_order_uuid: updateLine.work_order_uuid,
              uuid: updateLine.uuid,
              tax: updateLine.tax,
              total: updateLine.total,
              is_taxable: updateLine.is_taxable,
            }); */
            for (let up = 0; up < lines.length; up++){
              update_services_lineitem.push({
                  work_order_uuid: lines[up].work_order_uuid,
                  uuid: lines[up].uuid,
                  tax: lines[up].tax,
                  total: lines[up].total,
                  is_taxable: lines[up].is_taxable,
                  billable:lines[up].billable,
                  quantity:lines[up].quantity,
                  unit_cost:lines[up].unit_cost,
                  repair_code: lines[up].repair_code,
                });
              }
            setState((s) => ({
              ...s,
              update_services_lineitem: update_services_lineitem,
            }
            ));
          } else {
            let services_lines_added = state.services_lineitem;
            const indexEdited = services_lines_added
              .map((e) => e.repair_code)
              .indexOf(lineItem.repair_code);
            let updateLineEdited = services_lines_added.at(indexEdited);
  
            updateLineEdited.work_order_uuid = props.currentRecord.uuid;
            updateLineEdited.tax = updateLine.tax;
            updateLineEdited.total = updateLine.total;
            services_lines_added[indexEdited] = updateLineEdited;
            setservices_lineitem((s) => ({ ...s, services_lines_added }));
            setState((s) => ({ ...s, services_lineitem: services_lines_added }));
          }
      }

      setservices_lineitem(lines);

      calculateNoTax();
      CalculateTotal();
      calculateTax();
    };

    const removeBundleTax = (item) => {
      const repairCode = item.repair_code.includes("Labor")
        ? item.repair_code.split("-Labor")[0]
        : item.repair_code;
      const repairLaborCode = item.repair_code.includes("Labor")
        ? item.repair_code
        : item.repair_code + "-Labor";

      const product = services_lineitem.find(
        (i) =>
          i.repair_code === repairCode &&
          (i.bundle_code === null || i.bundle_code === undefined)
      );
      const labor = services_lineitem.find(
        (i) => i.repair_code === repairLaborCode
      );
      let bundle = services_lineitem.find((i) => i.bundle_code === repairCode);
      if (bundle) {
        const originValueBundle = bundle.is_taxable;
        if (product.is_taxable && labor.is_taxable) {
          bundle.is_taxable = true;
        } else if (!product.is_taxable || !labor.is_taxable) {
          bundle.is_taxable = false;
        }
        applyTax(bundle, false, true, originValueBundle);
      }
    };

    const calculateNoTax = () => {
      let totalNoTax = 0;
      services_lineitem.forEach((line) => {
        const _total = line.unit_cost * line.quantity;
        if (!line.bundle_code) totalNoTax += +_total;
      });

      if (!take_diagnostic)
        totalNoTax -= service_order?.is_recurring
          ? +service_order?.price
          : +props?.currentRecord?.base_price;

      setTotalNoTax(totalNoTax);
    };

    const calculateTax = () => {
      let totalTax = 0;

      if (service_order?.service_type === "maintenance") {
        setTotalTaxSO(
          service_order?.tax_amount ? service_order?.tax_amount : 0
        );
      }
      services_lineitem.forEach((line) => {
        if (!line.bundle_code)
          if (line.is_taxable) {
            const tax = (+taxRate / 100) * (line.unit_cost * line.quantity);
            totalTax += +tax;
          }
      });
      setTotalTax(totalTax);
    };

    const getAvalaraTax = async () => {
      const response = await getAvalaraTaxValue(
        props.service?.service_address?.zip_code
      );
      settaxRate(amount(response?.tax_rate ?? state.avalara_tax_rate));
    };

    const quantityValidation = (e) => {
      numberValidate(e)
    }

    const rateValidation = (e) => {
      rateValidate(e)
    }

    useEffect(() => {
      let serviceTaxable = services_lineitem.filter((item) => item.is_taxable);
      serviceTaxable.forEach((line) => {
        line.is_taxable = false;
        applyTax(line);
      });
      calculateTax();

      setState((s) => ({ ...s, tax_rate: amount(taxRate) }));
    }, [taxRate, service_order]);

    const removeBundleLabor = (row) => {
      let lines = services_lineitem;
      if (!lineItem.include_labor) {
        //remove Labor line
        let labor_line = services_lineitem.find((item) => {
          return item.repair_code === row.repair_code + "-Labor";
        });

        if (labor_line) {
          lines = lines.filter((item) => {
            return item.repair_code !== row.repair_code + "-Labor";
          });
          if (labor_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(labor_line.uuid);
            setState((s) => ({
              ...s,
              delete_services_lineitem: delete_services_lineitem,
            }));
          } else {
            let services_lineitem = state.services_lineitem.filter((item) => {
              return item.repair_code !== labor_line.repair_code;
            });
            setState((s) => ({ ...s, services_lineitem: services_lineitem }));
          }
        }
      }

      if (!lineItem.include_bundle) {
        //remove Bundle line
        let bundle_line = services_lineitem.find((item) => {
          return (
            item.bundle_code !== undefined &&
            item.bundle_code === row.repair_code
          );
        });

        if (bundle_line) {
          lines = lines.filter((item) => {
            return item.bundle_code !== row.repair_code;
          });
          if (bundle_line.uuid) {
            let delete_services_lineitem = state.delete_services_lineitem;
            delete_services_lineitem.push(bundle_line.uuid);
            setState((s) => ({
              ...s,
              delete_services_lineitem: delete_services_lineitem,
            }));
          } else {
            let services_lineitem = state.services_lineitem.filter((item) => {
              return item.repair_code !== bundle_line.repair_code;
            });
            setState((s) => ({ ...s, services_lineitem: services_lineitem }));
          }
        }
      }

      setservices_lineitem(lines);
      CalculateTotal();
      calculateTax();
      calculateNoTax();
    };

    const renderDescription = () => {
      return (
        <div className="mt-20">
          <h4 className="mb-5">
            {state.servicetype?.toLowerCase() === "service"
              ? "Description of Work"
              : "Aditional Work"}
          </h4>
          {renderDescriptionTable()}
          <div className="form-group">
            <div className="half-fitem fitem">
              <div className="half-fitem fitem">
                <label className="mr-10"> Tax Rate: </label>
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "20%" }}
                  value={taxRate}
                  onChange={(e) => {
                    settaxRate(e.target.value);
                  }}
                  disabled={transection_type === "View"}
                />
                <u>
                  <a
                    role="button"
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      getAvalaraTax();
                    }}
                    className="ml-10"
                    disabled={transection_type === "View"}
                  >
                    {" "}
                    Reset Tax Rate to local
                  </a>
                </u>
              </div>

              <div className="half-fitem fitem" style={{ textAlign: "right" }}>
                {transection_type !== "View" ? (
                  <span
                    role="button"
                    className="orange"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setisAddLineVisible(true);
                      seteditMode(false);
                    }}
                  >
                    <PlusCircleOutlined style={{ marginRight: "6px" }} /> Add
                    Item
                  </span>
                ) : (
                  <span
                    role="button"
                    className="orange"
                    style={{ cursor: "pointer" }}
                  >
                    <PlusCircleOutlined style={{ marginRight: "6px" }} /> Add
                    Item
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    };

    const renderNotesCustomer = () => {
      if (state.servicetype?.toLowerCase() === "service") {
        return (
          <React.Fragment>
            <div className="mt-20">
              <hr />
              <h4>Service Notes | Customer</h4>
              <p className="mb-0">Completion Notes</p>
              <TextArea
                disabled={transection_type === "View"}
                value={state.completion_notes}
                placeholder=""
                autoSize={{ minRows: 2, maxRows: 6 }}
                style={{ width: "750px" }}
                onChange={(e) => {
                  setState((s) => ({
                    ...s,
                    completion_notes: e.target.value,
                  }));
                }}
              />
              <div className="mt-20">
                <h6 className="mb-5">Pictures</h6>
                <p className="mb-5">Upload your images</p>
                <div style={{ width: "60%" }}>
                  {!isLoading && (
                    <ImageUploadWorkOrder
                      fileList={imageSources}
                      getFiles={getFilesCustomer}
                      accept={".png, .jpg, .gif"}
                      params={{
                        level: "EditWorkOrder",
                        isTechImage: false,
                      }}
                    />
                  )}
                  {/* <FileUploader getFiles={getImages} /> */}
                </div>
              </div>
            </div>
            <div className="mt-20">
              <h4>Tech Notes</h4>
              <TextArea
                disabled={transection_type === "View"}
                value={state.tech_notes}
                placeholder=""
                autoSize={{ minRows: 2, maxRows: 6 }}
                style={{ width: "750px" }}
                onChange={(e) => {
                  setState((s) => ({
                    ...s,
                    tech_notes: e.target.value,
                  }));
                }}
              />
              <div>
                <div className="mt-20">
                  <h6 className="mb-5">Pictures</h6>
                  <p className="mb-5">Upload your images</p>
                  <div style={{ width: "60%" }}>
                    {!isLoading && (
                      <ImageUploadWorkOrder
                        text={"Upload tech image"}
                        fileList={imageSourcesTech}
                        getFiles={getFilesTechnical}
                        accept={".png, .jpg, .gif"}
                        params={{
                          level: "EditWorkOrder",
                          isTechImage: true,
                        }}
                      />
                    )}
                    {/* <FileUploader getFiles={getTechImages} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <hr />
              <h4>Client Approval</h4>
              <div className="fitem half-fitem mt-10">
                <div className="fitem half-fitem">
                  <strong className="mr-10">Owner Date</strong>
                  <div className="fitem">
                    <input
                      id="task.contractChangeDateOwner"
                      type="date"
                      name="input"
                      style={{ width: "50%" }}
                    />
                  </div>
                </div>
                <div
                  className="fitem half-fitem mt-10"
                  style={{ marginRight: "20px" }}
                >
                  <img
                    style={{ minHeight: "200px", minWidth: "400px" }}
                    className="rounded img-fluid img-thumbnail"
                    // src={task.signatureOwnerURL}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    };

    const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

    const renderDescriptionTable = () => {
      return (
        <React.Fragment>
          <Table
            className="tablebody service_descriptionTable_table"
            rowKey={(record) =>
              `${record.repair_code} - ${record?.bundle_code} - ${record?.description} - ${record?.unit_cost}`
            }
            dataSource={[...services_lineitem]}
            pagination={false}
            columns={DataTable.DescriptionWork({
              totalPriceDescription,
              setisAddLineVisible,
              setlineItem,
              seteditMode,
              deleteLine,
              editLine,
              applyTax,
              service_type: state.servicetype,
              take_diagnostic,
              set_is_billable_Edit,
              updateDiagnostic,
              transection_type,
              amount,
              removeBundleTax,
            })}
          />
          <div>
            <Modal
              title={editMode ? "Edit Item" : "Add Item"}
              visible={isAddLineVisible}
              okText={!editMode ? "Add Item" : "Save Item"}
              cancelText={"Cancel"}
              onOk={seachvalidate}
              onCancel={() => {
                setisAddLineVisible(false);
                clearLineItem();
              }}
              className="add_line_modal"
              width={400}
            >
              <div className="form-group">
                <div className="fitem">
                  <div>
                    <label>Repair Code</label>
                    {renderRepairCodeSearch()}
                  </div>
                  <div>
                    <label>Description</label>
                    <input
                      type="text"
                      placeholder="Search Repair Descriptions"
                      value={lineItem.description}
                      onChange={(e) => {
                        set_is_description_Edit(true);
                        setlineItem((l) => ({
                          ...l,
                          description: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    <label>Quantity</label>
                    <input
                      name="quantity_item"
                      type="number"
                      placeholder="1.00"
                      min="0.01"
                      step="0.1"
                      value={lineItem.quantity}
                      onChange={(e) => {
                        setStateone({...stateone,
                          errors: {},
                          quantity_item: e.target.value,
                        });
                        set_is_quantity_Edit(true);
                        setlineItem((l) => ({
                          ...l,
                          quantity: e.target.value,
                        }));
                      }}
                    />
                    {errors["quantity_item"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle"></i>
                        {errors["quantity_item"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label>Rate</label>
                    <input
                      name="rate"
                      type="number"
                      placeholder="0.00"
                      value={lineItem.unit_cost}
                      onChange={(e) => {
                        setStateone({...stateone,
                          errors: {},
                          rate:e.target.value,
                        });
                        set_is_unitCost_Edit(true);
                        setlineItem((l) => ({
                          ...l,
                          unit_cost: e.target.value,
                        }));
                      }}
                      onBlur={(e) =>
                        setlineItem((l) => ({
                          ...l,
                          unit_cost:e.target.value,
                        }))
                      }
                    />
                    {errors["rate"] ? (
                      <label className="new_err_msg">
                        <i className="las la-info-circle"></i>
                        {errors["rate"]}
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <label>Price</label>
                    <input
                      type="number"
                      placeholder="0"
                      value={totalPriceDescription(lineItem)}
                      onChange={(e) => {
                        set_is_price_Edit(true);
                        setlineItem((l) => ({ ...l, price: e.target.value }));
                      }}
                    />
                  </div>
                  {lineItem.totallabor !== null && lineItem.totallabor > 0 ? (
                    <>
                      <div className="mt-10">
                        <input
                          type="checkbox"
                          checked={lineItem.include_labor}
                          onChange={(e) => {
                            setlineItem((l) => ({
                              ...l,
                              include_labor: !lineItem.include_labor,
                              include_bundle: !lineItem.include_labor,
                            }));
                          }}
                          style={{ verticalAlign: "middle" }}
                        />
                        <label className="mr-10">Include Labor Charge</label>
                      </div>
                      <div className="mt-10">
                        <input
                          type="checkbox"
                          disabled={!lineItem.include_labor}
                          checked={lineItem.include_bundle}
                          onChange={(e) => {
                            setlineItem((l) => ({
                              ...l,
                              include_bundle: !lineItem.include_bundle,
                            }));
                          }}
                          style={{ verticalAlign: "middle" }}
                        />
                        <label className="mr-10">Bundle Product</label>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal>
          </div>
        </React.Fragment>
      );
    };

    return (
      <div className="block-row p-0">
        <div className="filter_wrapper">
          <span
            role="button"
            className="backbtn"
            onClick={(e) => {
              clearState();
              goback();
            }}
          >
            <img src={chevronLeft} alt="" /> Back to all services
          </span>
        </div>
        <div
          className="container"
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="container" style={{ display: "flex" }}>
            <div style={{ minWidth: 380, width: 380, padding: "2em" }}>
              {WorkOrderDetails()}
            </div>
            <div
              style={{
                padding: "2em",
                borderLeft: "1px solid rgb(28, 29, 33, 0.1)",
              }}
            >
              <h3>Service Requirements</h3>
              <hr className="my-20" />
              <h4 className="mb-10">Service type</h4>
              <label>{state.servicetype}</label>
              <div className="mb-20">{renderWorkType()}</div>
              <div className="mb-60">
                <h4 className="mb-5">Pool type</h4>
                <label>{state.pool_type}</label>
                <div className="mt-20">
                  <p className="mb-0">Pool size in gallons</p>
                  <Input
                    disabled={transection_type === "View"}
                    value={state.pool_size}
                    style={{ width: "40%" }}
                    onChange={(e) => {
                      setState((s) => ({
                        ...s,
                        pool_size: e.target.value,
                      }));
                    }}
                    readOnly={true}
                  />
                </div>
                <div className="mt-20">
                  <p className="mb-0">Problem Description Notes</p>
                  <TextArea
                    disabled={transection_type === "View"}
                    value={state.problem_description}
                    placeholder=""
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    style={{ width: "750px" }}
                    onChange={(e) => {
                      setState((s) => ({
                        ...s,
                        problem_description: e.target.value,
                      }));
                    }}
                  />
                </div>
                {renderDescription()}
                {renderNotesCustomer()}
                {renderTechnicalForm()}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#e4eaed",
                minWidth: 425,
                padding: "2em",
              }}
            >
              {ServiceOrderSummary()}
              {WorkOrderSummary()}
            </div>
          </div>
        </div>
        <div className="fix-btn-grp">
          <button
            className="btn btn-white"
            onClick={() => {
              setIsModalVisible(true);
              setcodeOptions([]);
            }}
          >
            Exit Creator
          </button>
          {transection_type === "Update" && (
            <React.Fragment>
              <button
                // className={`btn btn-warning`}
                className={`btn ${
                  buttonDisabled ? "btn-disabled disabledBtn" : "btn-warning"
                }`}
                onClick={() => validate()}
                disabled={buttonDisabled} // Disable if buttonDisabled is true
              >
                Update Work Order
              </button>
              {state.servicetype?.toLowerCase() === "service" &&
                appData.workOrderTypes.includes(
                  state.worktype?.toLowerCase()
                ) && (
                  <button
                    className={`btn btn-warning`}
                    onClick={() => {
                      setsendEmail({ send: true, request: true });
                    }}
                  >
                    {buttonLabel}
                  </button>
                )}
            </React.Fragment>
          )}
          {transection_type === "Create" && (
            <button className={`btn btn-warning`} onClick={() => validate()}>
              Create Work Order
            </button>
          )}
          <Modal
            title="Exit Creator?"
            visible={isModalVisible}
            okText={"Exit Creator"}
            cancelText={"Cancel"}
            onOk={() => {
              clearState();
              goback();
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            className="delete_user_modal"
            width={400}
          >
            <h3>Exit Creator?</h3>
            <p>Are you sure you would like to exit Creating a Work Order ?</p>
            <p>All progress will be lost</p>
          </Modal>
        </div>
      </div>
    );
  }

  function WorkOrderDetails() {
    const { transection_type } = props;
    const [technicians, setTechnicians] = useState([]);
    const { errors } = state;

    useEffect(() => {
      const getFieldTech = async () => {
        const data = await getFieldTechnician({
          field_office__uuid: props?.customer?.field_office?.uuid,
        });
        setTechnicians(data?.results);
      };
      getFieldTech();
    }, [props]);

    const updateTechnician = (selectedTechnician) => {
      setState((s) => ({ ...s, selectedTechnician }));
    };

    return (
      <>
        <div className="flex">
          <h4> Work Order Details </h4>
        </div>
        <div className="field-group">
          <label>
            status<sup>*</sup>
          </label>
          <Select
            value={state?.status}
            disabled={transection_type === "View"}
            onChange={(e) => {
              if (e === "complete") {
                setState((s) => ({
                  ...s,
                  status: e,
                  time: moment("12:00 AM", ["h:mm A"]),
                }));
              } else {
                setState((s) => ({
                  ...s,
                  status: e,
                  time: "",
                  selectedTechnician:
                    e === "forecast" ? "" : state?.selectedTechnician,
                }));
              }
            }}
          >
            {map(serviceStatus, (item, index) => {
              if (index > 0) {
                return (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                );
              }
            })}
          </Select>
          {errors?.["status"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["status"]}
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="field-group">
          <label>Assigned Technician</label>
          <Select
            showSearch
            allowClear={true}
            placeholder="Not assigned"
            optionFilterProp="children"
            onChange={(e) => {
              updateTechnician(e);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={state?.selectedTechnician || undefined}
            disabled={transection_type === "View"}
          >
            {orderBy(technicians, [item => item?.user?.first_name.toLowerCase()+" "+item?.user?.last_name.toLowerCase()], "asc")?.map((technician, index) => {
              return (
                <Option
                  key={index}
                  value={technician.user.uuid}
                >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
              );
            })}
          </Select>
          {errors?.["technician_uuid"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["technician_uuid"]}
            </label>
          ) : (
            ""
          )}
        </div>
        <div className="field-group">
          <label>Job Name</label>
          <Input
            value={state.work_order_name}
            onChange={(e) => {
              setState((s) => ({ ...s, work_order_name: e.target.value }));
            }}
            disabled={transection_type === "View"}
          />
          {errors?.["work_order_name"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle" />
              {errors?.["work_order_name"]}
            </label>
          ) : (
            ""
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div
            className="field-group"
            style={{ width: "50%", paddingRight: 5 }}
          >
            <label>
              Work Order Date<sup>*</sup>
            </label>
            <DatePicker
              style={{ width: "100%" }}
              disabled={transection_type === "View"}
              format="MMM DD,YYYY"
              suffixIcon={<i className="las la-calendar"></i>}
              allowClear={true}
              value={state.date ? moment(state.date) : null}
              onChange={(e) => {
                if (e) {
                  setState((s) => ({
                    ...s,
                    date: moment(e).format("YYYY-MM-DD"),
                  }));
                } else {
                  setState((s) => ({ ...s, date: null }));
                }
              }}
            />
            {errors?.["date"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.["date"]}
              </label>
            ) : (
              ""
            )}
          </div>
          <div className="field-group" style={{ width: "50%", paddingLeft: 5 }}>
            <label>
              Time
              {(state?.status === "complete" ||
                state?.status === "incomplete") && <sup>*</sup>}
            </label>
            <TimePicker
              className="swhite"
              placeholder={"--:-- AM/PM"}
              value={state?.time ? moment(state?.time, "hh:mm A") : ""}
              onChange={(e) => {
                setState((s) => ({ ...s, time: e }));
              }}
              allowClear={["forecast", "canceled"].includes(state?.status)}
              format={"h:mm A"}
              disabled={transection_type === "View"}
            />
            {errors?.["time"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errors?.["time"]}
              </label>
            ) : (
              ""
            )}
          </div>
        </div>
        {errors?.["date_past"] ? (
          <label className="new_err_msg align-items-start lh-20">
            <i className="las la-info-circle" />
            {errors?.["date_past"]}
          </label>
        ) : (
          ""
        )}
        {errors?.["time_past"] ? (
          <label className="new_err_msg align-items-start lh-20">
            <i className="las la-info-circle" />
            {errors?.["time_past"]}
          </label>
        ) : (
          ""
        )}
        {errors?.["time_future"] ? (
          <label className="new_err_msg align-items-start lh-20">
            <i className="las la-info-circle" />
            {errors?.["time_future"]}
          </label>
        ) : (
          ""
        )}
        {durationFlag.current ? (
          <AddDurationField durationType="service" visible="workorderScreen" />
        ) : (
          ""
        )}
      </>
    );
  }

  const getLineSearch = async (params) => {
    const response = await getPrices(params);
    return response;
  };

  const [codeOptions, setcodeOptions] = useState([]);
  const [repairCodes, setrepairCodes] = useState([]);
  const [value, setvalue] = useState("");

  async function getOptions(search) {
    const allowRepeatedRepairCodes = [
      "LABORSERVICE",
      "FLATRATESUBCONTRACT",
      "INCOME",
      "LABOR",
      "SERVICE-FLATRATE",
      "RETAIL-SUPPLIES",
      "SUBCONTRACT-INCOME",
    ];
    setSelectLoading(true);
    let field_office_uuid = props?.customer?.field_office?.uuid;
    const options = await getLineSearch({
      search: search,
      field_office_uuid: field_office_uuid,
    });

    setrepairCodes(options);

    const _options = options.reduce((prev, current) => {
      //Validation to not repeat repair code
      const is_added = services_lineitem.some(
        (i) => i.repair_code === current.repaircode
      );
      if (
        !is_added ||
        _.includes(allowRepeatedRepairCodes, current.repaircode)
      ) {
        prev.push({
          value: current.repaircode + " - " + current.description,
          text: current.repaircode,
        });
      }
      return prev;
    }, []);
    setSelectLoading(false);

    setcodeOptions(_options);
  }

  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const [openSelect, setOpenSelect] = useState(false);
  const [selectLoading, setSelectLoading] = useState(true);
  const renderRepairCodeSearch = () => {
    const onChange = (e) => {
      let val = "";
      let selectedCode = [];
      if (e) {
        val = e.trim().split(" ")[0];
        setvalue(val);
        selectedCode = repairCodes.filter((item) => {
          return item.repaircode === val;
        });
      } else {
        setvalue("");
      }
      if (selectedCode.length > 0) {
        setlineItem({
          repair_code: selectedCode[0].repaircode || "",
          description: selectedCode[0].description,
          unit_cost:
            Math.round(
              (Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100
            ) / 100,
          quantity: (1).toFixed(2),
          price: selectedCode[0].fo_adjusted_price,
          is_taxable: selectedCode[0].is_taxed_product,
          apply_labor_tax: selectedCode[0].is_taxed_labor,
          totallabor: selectedCode[0].totallabor,
          qb_account: selectedCode[0].qb_account,
        });
        setStateone({
          errors: {},
          rate:   Math.round(
            (Number(selectedCode?.[0]?.fo_adjusted_price) + Number.EPSILON) * 100
          ) / 100,
          quantity_item: (1).toFixed(2),
        })
      } else {
        message.error("The item was not found, please verify the data.");
      }
    };
    const onSearch = (e) => {
      setcodeOptions([]);
      if (e !== "") {
        getOptions(e);
        setvalue(e);
        setlineItem((l) => ({ ...l, repair_code: e || "" }));
      }
    };

    const onNameChange = (event) => {
      setName(event.target.value);
    };
    const addItem = (e) => {
      e.preventDefault();
      setlineItem({
        repair_code: name,
        description: name,
        unit_cost: 0,
        quantity: 1,
        price: 0,
        is_taxable: false,
        apply_labor_tax: undefined,
        totallabor: undefined,
        qb_account: "",
        bundle_code: null,
      });
      setOpenSelect(false);
      setvalue(name);
      setName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    return (
      <div className="searchable">
        <ConfigProvider
          renderEmpty={() =>
            selectLoading ? (
              <div className="repairCodesSpin">
                <Spin tip="Loading Repair Codes..." />
              </div>
            ) : (
              <Empty />
            )
          }
        >
          <Select
            showSearch
            allowClear
            autoClearSearchValue={false}
            value={value}
            placeholder="Search Repair ID's"
            onChange={onChange}
            onSearch={onSearch}
            onFocus={() => getOptions("")}
            options={codeOptions}
            disabled={editMode}
            open={openSelect}
            onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
          ></Select>
        </ConfigProvider>
      </div>
    );
  };

  const renderTechnicalForm = () => {
    if (techForm?.length > 0) {
      const form = techForm[0];
      const url = "".concat(
        form.form_url.replace(/\/$/, ""),
        "/",
        form.form_data_uuid
      );
      return (
        <div className="mt-20">
          <div className="fitem half-fitem"></div>
          <h4 className="mb-15">Technical Form</h4>
          <a href={url} target="_blank" rel="noreferrer" className="techFormWO">
            <FileTextOutlined className="orange" /> {form.form_name}
          </a>
        </div>
      );
    }
  };

  const renderWorkType = () => {
    switch (state?.servicetype?.toLowerCase()) {
      case "maintenance":
        return (
          <div>
            <h4 className="mb-10 mt-20">
              How often do you need your pool cleaned?
            </h4>
            <label>
              {state.worktype}: {state.worksubtype}
            </label>
          </div>
        );
      case "service":
        return (
          <div>
            <h4 className="mb-10 mt-20">What Service are you looking for?</h4>
            <label>
              {state.worktype}: {state.worksubtype}
            </label>
          </div>
        );
      default:
        break;
    }
    if (state.servicetype === "maintenance") {
    }
  };

  function ServiceOrderSummary() {
    const { service_type } = service_order;
    const price =
      state.servicetype !== "maintenance"
        ? services_lineitem.find((item) => item.repair_code === service_type)
          ? services_lineitem.find((item) => item.repair_code !== "maintenance")
              ?.unit_cost *
            services_lineitem.find((item) => item.repair_code !== "maintenance")
              ?.quantity
          : 0.0
        : service_order?.is_recurring
        ? service_order?.price
        : props?.currentRecord?.base_price;

    const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

    const setService = (service) => {
      if (service)
        switch (service.toLowerCase()) {
          case "service":
            return "Service";
          case "maintenance":
            return "Maintenance";
          case "construction":
            return "Construction";
          case "renovation":
            return "Renovation";
          default:
        }
    };

    const setRecurring = (frequency) => {
      if (frequency)
        switch (frequency.toLowerCase()) {
          case "weekly":
            return "Weekly";
          case "bi-weekly":
            return "Bi-Weekly";
          case "monthly":
            return "Monthly";
          case "once":
            return "One-Time";
          default:
            return "";
        }
    };

    return (
      <div style={{ background: "white", padding: "25px 30px" }}>
        <h2 className="mb-20">Service Order Summary</h2>
        <h5 className="mb-10">
          <b>Subscription details</b>
        </h5>
        <div>
          <div className="fs-13 mb-10">
            <div className="summary-label">Subscription</div>
            <div className="flex summary-detail">
              <span>
                {setService(state.servicetype)}
                {state.servicetype !== "maintenance"
                  ? state.worktype === "frequency"
                    ? " | " + setRecurring(state.worksubtype) + " Charge"
                    : state.servicetype !== "renovation" &&
                      state.servicetype !== "construction"
                    ? " " + state.worktype
                    : ""
                  : ""}{" "}
              </span>
              {take_diagnostic ? (
                <span>${formatToCurrency(amount(price))}</span>
              ) : (
                ""
              )}
            </div>
            <div className="flex summary-detail">
              <span>
                {state.worktype === "frequency"
                  ? setRecurring(state.worksubtype)
                  : state.worktype === "other"
                  ? ""
                  : state.worktype}
              </span>
            </div>
            <div className="flex summary-detail">
              <span>{state.pool_type}</span>
            </div>
          </div>

          {service_order?.service_type?.toLowerCase() !== "maintenance" &&
            addons?.length > 0 && (
              <div className="fs-13 mb-10">
                <div className="summary-label">Addons</div>
                {services_lineitem?.map((item, index) => (
                  <div className="flex summary-detail" key={index}>
                    <span>{item?.description}</span>
                    <span>
                      $
                      {formatToCurrency(
                        amount(
                          parseFloat(item?.unit_cost ? item?.unit_cost : 0) *
                            parseFloat(item?.quantity ? item?.quantity : 0)
                        )
                      )}
                    </span>
                  </div>
                ))}
              </div>
            )}
        </div>
        <div className="flex summary-total">
          <label>Total to Pay (excl. tax)</label>
          <span>
            $
            {service_type?.toLowerCase() !== "maintenance"
              ? formatToCurrency(amount(totalNoTax))
              : formatToCurrency(amount(price))}
          </span>
        </div>
        <div className="flex summary-total">
          <label>Total Tax</label>
          <span>
            $
            {service_type?.toLowerCase() !== "maintenance"
              ? formatToCurrency(amount(totalTax))
              : service_type?.toLowerCase() === "maintenance"
              ? formatToCurrency(amount(totalTaxSO))
              : 0}
          </span>
        </div>
        <div className="flex summary-total">
          <span>Total to Pay</span>
          <span>
            $
            {service_type?.toLowerCase() !== "maintenance"
              ? formatToCurrency(amount(total))
              : service_type?.toLowerCase() === "maintenance"
              ? formatToCurrency(amount(totalPlusTaxSO))
              : formatToCurrency(amount(price))}
          </span>
        </div>
      </div>
    );
  }

  function WorkOrderSummary() {
    const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

    if (service_order?.service_type?.toLowerCase() === "maintenance") {
      return (
        <div
          className="mt-30"
          style={{ background: "white", padding: "25px 30px" }}
        >
          <h2 className="mb-20">Work Order Summary</h2>
          <h5 className="mb-10">
            <b>Additional Work</b>
          </h5>
          <div className="fs-13 mb-10">
            {addons?.length > 0 && (
              <div className="fs-13 mb-10">
                <div className="summary-label">Addons</div>
                {addons?.map((item, index) => (
                  <div style={{ display: "flex" }} key={index}>
                    <div className="long-description">{item?.name}</div>
                    <div style={{ marginLeft: "20px" }}>
                      ${formatToCurrency(amount(item?.price))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex summary-total mt-20">
            <label>Total to Pay (excl. tax)</label>
            <span>${formatToCurrency(amount(totalNoTax))}</span>
          </div>
          <div className="flex summary-total">
            <label>Total Tax</label>
            <span>${formatToCurrency(amount(totalTax))}</span>
          </div>
          <div className="flex summary-total">
            <span>Total to Pay</span>
            <span>${formatToCurrency(amount(total))}</span>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      {" "}
      {loader && <Loader />}
      {ServiceDetails_()}{" "}
    </div>
  );
};

export default EditWorkOrder;
