import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Select, Drawer, DatePicker, Table, Menu, Dropdown } from "antd";
import moment from 'moment-timezone';
import DataTable from "./DataTable";
import Loader from "../../Components/Loader";
import { orderBy, isEmpty } from 'lodash';
import { FilterOutlined } from '@ant-design/icons';
import AddNewLead from '../Commercial/AddNewLead';
import Header from '../../Components/Header'
import GlobalSearch from '../../Components/GlobalSearch'
import Sidebar from '../../Components/Sidebar'
import HeaderMobile from '../../Components/HeaderMobile'
import SidebarResponsive from '../../Components/SidebarResponsive'
import { getFranchise } from '../../Action/Franchise';
import { getCustomerList, getResidentialLeadList } from '../../Action/Customer';
import { getFranchiseSelected, setFranchiseSelected } from '../../Utils/selectedFranchise';
import DATA from "../data.json";

const CustomerList = (props) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const role = userInfo && userInfo?.user?.user_roles[0]?.name;

  const { Option } = Select;
  const location = useLocation();
  const params = useParams()
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [openMenu, setOpenMenu] = useState(false);
  const [isAddNewLead, setIsAddNewLead] = useState(false);
  const [currentTab, setCurrentTab] = useState(location?.state?.currentTab || 1);
  const [loader, setLoader] = useState(false);
  const [franchiseList, setFranchiseList] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerLead, setOpenDrawerLead] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [canceledCustomerList, setCanceledCustomerList] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [current_user, setCurrent_user] = useState('');
  const [filterData, setFilterData] = useState({
    franchise: 'all',
    franchiseList: [],
    active: false,
    inactive: false,
    filterFranchise: '',
    joined: [],
    lastService: [],
    outstanding: 'all',
    satisfaction: '',
    created_at__lte: null,
    created_at__gte: null
  });
  const [filterDataLead, setFilterDataLead] = useState({
    franchise: 'all',
    active: false,
    inactive: false,
    filterFranchise: '',
    last_contacted: [],
    lead_stage: 'all',
    outreach: 'all',
    created_at__lte: null,
    created_at__gte: null
  });
  const [filterClicked, setFilterClicked] = useState(false);
  const value_lead_stage = [
    { key: 'all', value: 'All' },
    { key: 'new', value: 'New' },
    { key: 'contacted', value: 'Contacted' },
    { key: 'interested', value: 'Interested' },
  ]
  const value_outreach = [
    { key: 'all', value: 'All' },
    { key: 'franchise_owner', value: 'Franchise' },
    { key: 'call_center', value: 'Call Center' },
  ]
  const [customerListPagination, setCustomerListPagination] = useState({
    showSizeChanger: false,
    pageSize: 15,
    ordering: '-created_at',
    current: 1
  });
  const [canceledCustomerListPagination, setCanceledCustomerListPagination] = useState({
    showSizeChanger: false,
    pageSize: 15,
    ordering: '-created_at',
    current: 1
  });
  const [leadListPagination, setLeadListPagination] = useState({
    showSizeChanger: false,
    pageSize: 15,
    ordering: '-created_at',
    current: 1
  });
  const [activePagination, setActivePagination] = useState('15');
  const [activePagination_canceled, setActivePagination_canceled] = useState('15');
  const [activePagination_lead, setActivePagination_lead] = useState('15');
  const [searchValue, setSearchValue] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [offset, setOffset] = useState(0);
  const [offset_lead, setOffset_lead] = useState(0);
  const [limit, setLimit] = useState(15);
  const [filters, setfilters] = useState('');
  const [current_tab, setcurrent_tab] = useState(1);
  const satisfaction_score = [{ key: '>=90', value: '90% or Greater 90%' },
  { key: '>=75', value: '75% or Greater' },
  { key: '<75', value: '75% or Less' },
  { key: '<50', value: '50% or Less' },
  ]

  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  }

  useEffect(() => {
    // setLoader(true);
    // getCustomer();
    // getLead();
    // getFranchiseList();
    if (location?.state?.currentTab !== undefined) {
      setCurrentTab(location?.state?.currentTab)
    }
  }, []);

  const getFranchiseList = async () => {
    setLoader(true);
    const data = await getFranchise();
    setFranchiseList(data ? data : []);
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setCurrent_user(authUser?.user?.role?.[0]);
    if (data?.results.length && authUser?.user?.role?.[0] === 'franchise_owner' || data?.results.length && authUser?.user?.role?.[0] === 'operations_mgr') {
      // let temp = data.results.filter((result)=>result?.uuid === authUser?.user?.field_offices[0]?.uuid);
      setFilterData({
        ...filterData,
        franchise: getFranchiseSelected().uuid === 'all'? authUser?.user?.field_offices[0]?.uuid : getFranchiseSelected().uuid,
        filterFranchise: getFranchiseSelected().uuid === 'all'? authUser?.user?.field_offices[0]?.uuid : getFranchiseSelected().uuid,
      });
      setFilterDataLead({
        ...filterDataLead,
        franchise: getFranchiseSelected().uuid === 'all'? authUser?.user?.field_offices[0]?.uuid : getFranchiseSelected().uuid,
        filterFranchise: getFranchiseSelected().uuid === 'all'? authUser?.user?.field_offices[0]?.uuid : getFranchiseSelected().uuid,
      });
    } else {
      getCustomer();
      getLead();
    }
    setLoader(false);
  }

  const getCustomer = async () => {
    setLoader(true);
    let data = {
      is_active: currentTab === 2 ? false : true,
      offset: offset,
      limit: currentTab === 2 ? activePagination_canceled : activePagination,
      ordering: customerListPagination.ordering,
      q: searchValue
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser?.user?.role?.[0] === 'franchise_owner' || authUser?.user?.role?.[0] === 'operations_mgr' && filterData?.franchiseList.length <= 1 && filterData?.franchise === 'all'){
      return;
    }
    if (filterData?.filterFranchise !== 'all') {
      data.serviceorder__field_office__uuid = filterData?.filterFranchise; //Added to filter by franchise, waiting for API
    }
    if (filterData?.created_at__lte && filterData?.created_at__gte) {
      data.created_at__lte = filterData?.created_at__lte;
      data.created_at__gte = filterData?.created_at__gte;
    }

    if (filterData?.last_serviced) {
      data.last_serviced = filterData?.last_serviced;
    }

    if (filterData?.satisfaction) {
      data.satisfaction = filterData?.satisfaction;
    }
    if(currentTab === 2) {
      data.is_active = false;
    }
    const customer_list = await getCustomerList(data);
    if(currentTab === 2) {
      // let data = customer_list;
      // let temp = DATA;
      // temp = temp.filter((each) => !each?.is_active);
      // data.results = temp;
      // data.count = temp.length;
      setCanceledCustomerList(customer_list);
    } else {
      setCustomerList(customer_list);
    }
    setLoader(false);
  }

  const getLead = async () => {
    setFilterClicked(false);
    let data = {
      // is_active: true,
      offset: offset_lead,
      limit: activePagination_lead,
      ordering: leadListPagination.ordering,
      q: searchValue || ''
    }
    if (filterDataLead?.franchise !== 'all') {
      data.residential_lead_profile__field_office__uuid = filterDataLead?.franchise;
    }
    if (filterDataLead?.created_at__lte && filterDataLead?.created_at__gte) {
      data.created_at__lte = filterDataLead?.created_at__lte;
      data.created_at__gte = filterDataLead?.created_at__gte;
    }
    if (filterDataLead?.outreach !== 'all') {
      data.residential_lead_profile__outreach = filterDataLead?.outreach;
    }
    if (filterDataLead?.lead_stage !== 'all') {
      data.residential_lead_profile__stage = filterDataLead?.lead_stage;
    }
    if ((filterDataLead.active && !filterDataLead.inactive || !filterDataLead.active && filterDataLead.inactive)) {
      data.is_active = filterDataLead.active ? filterDataLead.active : false;
    }
    const customer_list = await getResidentialLeadList(data);
    setLeadList(customer_list);
    setLoader(false);
  }

  useEffect(() => {
    // if (customerList.length || leadList.lenght) {
      if(currentTab === 0) {
        getLead();
      } else if (currentTab === 1) {
        getCustomer();
      } else {
        getCustomer();
      }
    // }
  }, [offset, offset_lead])


  useEffect(() => {
    // if (customerList.length || leadList.lenght) {
      if(currentTab === 0) {
        getLead();
      } else if (currentTab === 1) {
        getCustomer();
      } else {
        getCustomer();
      }
    // }
  }, [activePagination, activePagination_lead, activePagination_canceled])

  useEffect(() => {
    // if(filterData.franchise || filterDataLead.franchise) {
    setLoader(true);
    getCustomer();
    getLead();
    // }
  }, [filterData, filterDataLead])

  useEffect(()=> {
    setLoader(true);
    // getCustomer();
    // getLead();
    getFranchiseList();
  },[currentTab])

  // useEffect(() => {
  //   if(!isEmpty(selectedFranchiseData)) {
  //     setFilterData({
  //       ...filterData,
  //       franchise: selectedFranchiseData
  //     });
  //     setFilterDataLead({
  //       ...filterDataLead,
  //       franchise: selectedFranchiseData
  //     });
  //     getCustomer();
  //     getLead();
  //   }
  // }, [selectedFranchiseData])


  // filter data change
  const onFilterChange = (e, type, name) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    setFilterData(filterData => ({
      ...filterData,
      [e.target.name]: value
    }));

    if (e.target.name === 'franchise') {
      onFilterClick()
      setFilterData(filterData => ({
        ...filterData,
        filterFranchise: value
      }));
      current_user === 'franchise_owner' || current_user === 'operations_mgr' && setFranchiseSelected(value);
    }
  };
  const onFilterChangeLead = (e, type, name) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    if (e.target.name === 'last_contacted' && e.target.value.length > 0) {
      setFilterDataLead(filterData => ({
        ...filterData,
        created_at__lte: moment(e.target.value[1]).format('YYYY-MM-DD'),
        created_at__gte: moment(e.target.value[0]).format('YYYY-MM-DD')
      }));
    }
    else if (e.target.name === 'franchise') {
      setFilterDataLead(filterData => ({
        ...filterData,
        franchise: value
      }));
      current_user === 'franchise_owner' || current_user === 'operations_mgr'  && setFranchiseSelected(value);
      // current_user === 'franchise_owner' &&  dispatch(add(value));
    } else {
      setFilterDataLead(filterData => ({
        ...filterData,
        [e.target.name]: value
      }));
    }
  };
  // on lead filter click
  const onFilterClickLead = () => {
    const obj = {
      offset: 0,
      limit: activePagination,
      ordering: '-created_at',
      is_active: current_tab === 0,
    };
    setFilterClicked(true);
    closeDrawerLead();
  };
  useEffect(() => {
    if (filterClicked) {
      setLoader(true);
      getLead();
    }
  }, [filterClicked]);
  // on filter click
  const onFilterClick = () => {
    // const { is_franchise_owner, franchise_uuid } = this.getLoggedInUserRole()
    setLoader(true);
    // setSearchValue('');
    const obj = {
      offset: 0,
      limit: activePagination,
      ordering: '-created_at',
      is_active: current_tab === 1,
    };
    if (filters?.franchise || (filterData?.franchise && filterData?.franchise !== 'all')) {
      setFilterData(filterData => ({
        ...filterData,
        filterFranchise: filters?.franchise?.toString() || filterData?.franchise
      }));
    }
    if (filterData?.joined?.length > 0) {
      setFilterData(filterData => ({
        ...filterData,
        created_at__lte: new Date(filterData?.joined?.[1]),
        created_at__gte: new Date(filterData?.joined?.[0])
      }));
    }
    if (filterData?.lastService?.length > 0) {
      setFilterData(filterData => ({
        ...filterData,
        last_serviced: `${moment(filterData?.lastService?.[0]).format('YYYY-MM-DD')},${moment(filterData?.lastService?.[1]).format('YYYY-MM-DD')}`
      }));
    }

    if ((filterData?.active && !filterData?.inactive) || (!filterData?.active && filterData?.inactive)) {
      Object.assign(obj, { is_active: filterData?.active === true ? true : filterData?.inactive === true && false });
    }
    closeDrawer();
    setLoader(false);
  };

  // close Drawer
  const closeDrawer = () => {
    setOpenDrawer(false);
  }

  // close Drawer
  const closeDrawerLead = () => {
    setOpenDrawerLead(false);
  }
  const closeAddDrawer = (type) => {
    setIsAddNewLead(type);
    getLead();
  }
  const onClearFilter = () => {

  }

  //Table Order and Pagination
  const onCustomerTableChange = (pagination, filters, sorter) => {
    setLoader(true);
    // const {is_franchise_owner, franchise_uuid} = this.getLoggedInUserRole()
    let ordering = '';
    Object.assign(customerListPagination, { current: pagination.current });

    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }

    pagination.ordering = ordering;
    Object.assign(filterData, { franchise: filterData?.franchise })
    setCustomerListPagination(pagination);

    if (filters?.franchise || (filterData?.franchise && filterData?.franchise !== 'all')) {
      filterData.filterFranchise = filters?.franchise?.toString() || filterData?.franchise;
    }else
      filterData.filterFranchise = '';

    setFilterData(filterData);
    setIsActive(currentTab === 1);
    setOffset((pagination.current - 1) * activePagination);
    setLimit(pagination?.pageSize);
    if(currentTab === 0) {
      getLead();
    } else {
      getCustomer();
    }
  }

  const onCanceledCustomerTableChange = (pagination, filters, sorter) => {
    setLoader(true);
    let ordering = '';
    Object.assign(canceledCustomerListPagination, { current: pagination.current });
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }
    pagination.ordering = ordering;
    setCanceledCustomerListPagination(pagination);
    setIsActive(currentTab === 2);
    setOffset((pagination.current - 1) * activePagination_canceled);
    setLimit(pagination?.pageSize);
    if(currentTab === 0) {
      getLead();
    } else {
      getCustomer();
    }
  }
  //handle filter button click
  const onFilterButtonClick = () => {
    if (currentTab === 1 || currentTab === 2) {
      setOpenDrawer(true);
      setOpenDrawerLead(false);
    } else {
      setOpenDrawer(false);
      setOpenDrawerLead(true);
    }
  }

  //Table Order and Pagination
  const onLeadTableChange = async(pagination, filters, sorter) => {
    setLoader(true);
    let ordering = '';
    Object.assign(leadListPagination, { current: pagination.current });
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }

    pagination.ordering = ordering;
    Object.assign(filterData, { franchise: filterData?.franchise })
    setLeadListPagination(pagination);

    if (filters?.franchise || (filterData?.franchise && filterData?.franchise !== 'all')) {
      filterData.filterFranchise = filters?.franchise?.toString() || filterData?.franchise;
    }else
      filterData.filterFranchise = '';

    setFilterData(filterData);
    setIsActive(currentTab === 1);
    setOffset_lead((pagination.current - 1) * activePagination_lead);
    setLimit(pagination?.pageSize);
    // setLoader(false);
    if (currentTab === 1) {
      await getCustomer();
    } else {
      await getLead();
    }
  }

  const setCustomerPageLimit = (value) => {
    setActivePagination(value);
    setCustomerListPagination((p) => ({ ...p, pageSize: value }))
  };

  const setCanceledCustomerPageLimit = (value) => {
    setActivePagination_canceled(value);
    setCanceledCustomerListPagination((p) => ({ ...p, pageSize: value }))
  };

  const setLeadPageLimit = (value) => {
    setActivePagination_lead(value);
    setLeadListPagination((p) => ({ ...p, pageSize: value }))
  }

  //render customer list
  const renderCustomerList = () => {
    const franchise = franchiseList?.results?.map((item) => ({ text: item?.name, value: item?.uuid }));
    return (
      <div className="leadstbl">
        <Table
          rowKey={record => record?.uuid}
          dataSource={customerList?.results || []}
          pagination={{ ...customerListPagination, total: customerList?.count }}
          columns={DataTable.customerColumns(franchise, customerList)}
          onChange={onCustomerTableChange}
          onRow={onCustomerListRow}
          className="customer-list"
        />
        {
          customerList?.results?.length > 0 ?
            <ul className="pagination_page_size">
              <li>
                <span>Show:</span>
              </li>
              <li>
                <span
                  onClick={() => { setCustomerPageLimit('15') }}
                  className={activePagination === '15' ? 'active' : ''}
                >15</span>
              </li>
              <li>
                <span
                  className={activePagination === '30' ? 'active' : ''}
                  onClick={() => { setCustomerPageLimit('30') }}>30</span>
              </li>
              <li>
                <span
                  className={activePagination === '60' ? 'active' : ''}
                  onClick={() => { setCustomerPageLimit('60') }}>60</span>
              </li>
            </ul>
            : ''
        }
      </div>
    )
  };

  const renderCanceledCustomerList = () => {
    const franchise = franchiseList?.results?.map((item) => ({ text: item?.name, value: item?.uuid }));
    return (
      <div className="leadstbl">
        <Table
          rowKey={record => record?.uuid}
          dataSource={canceledCustomerList?.results || []}
          // pagination={{ ...customerListPagination, total: customerList?.count }}
          columns={DataTable.customerColumns(franchise, customerList)}
          onChange={onCanceledCustomerTableChange}
          onRow={onCustomerListRow}
          className="customer-list"
        />
        {
          canceledCustomerList?.results?.length > 0 ?
            <ul className="pagination_page_size">
              <li>
                <span>Show:</span>
              </li>
              <li>
                <span
                  onClick={() => { setCanceledCustomerPageLimit('15') }}
                  className={activePagination_canceled === '15' ? 'active' : ''}
                >15</span>
              </li>
              <li>
                <span
                  className={activePagination_canceled === '30' ? 'active' : ''}
                  onClick={() => { setCanceledCustomerPageLimit('30') }}>30</span>
              </li>
              <li>
                <span
                  className={activePagination_canceled === '60' ? 'active' : ''}
                  onClick={() => { setCanceledCustomerPageLimit('60') }}>60</span>
              </li>
            </ul>
            : ''
        }
      </div>
    )
  };

  // on customer list row
  const onCustomerListRow = (record) => {
    return {
      onClick: () => {
        navigate(`/residential/customer-detail`, { state: { 'id': record?.uuid, hear_about_us: record?.hear_about_us, customer_satisfaction_score: record?.customer_satisfaction_score, record: record } })
      }
    }
  }

  //render customer filter drawer
  const renderDrawer = () => {
    return (
      <Drawer
        placement={'right'}
        closable={false}
        onClose={closeDrawer}
        visible={openDrawer}
        key={'right'}
        width={'400'}
        className="drawer-wrapper"
      >
        <div className="group_wrapper rdrawer">
          <div className="card-head">
            <h4>Filter Customer</h4>
          </div>
          <div className="card-row">
            <div className="card-full">
              <div className="form-group">
                <div className="fitem">
                  <label htmlFor="customerFranchise">Franchise</label>
                  <Select
                    // disabled={is_disable_franchise_dropdown}
                    id={"customerFranchise"}
                    placeholder="Select a Franchise"
                    name="franchise"
                    onChange={(e) => onFilterChange({ target: { name: 'franchise', value: e } })}
                    value={filterData?.franchise || undefined}>
                    {current_user !== 'franchise_owner' && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                    {
                      orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                        <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                    }
                  </Select>
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Joined</label>
                  <RangePicker
                    style={{ 'width': '100%' }}
                    allowClear={true}
                    separator='-'
                    format={"MMM DD, YYYY"}
                    dropdownClassName='current_month'
                    value={filterData?.joined?.length > 0 ? [filterData?.joined?.[0] ? moment(filterData?.joined?.[0]) : undefined, filterData?.joined?.[1] ? moment(filterData?.joined?.[1]) : undefined] : undefined}
                    onChange={(e) => {
                      if (e === null) {
                        onFilterChange({ target: { name: 'joined', value: [] } });
                      } else {
                        onFilterChange({ target: { name: 'joined', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                      }
                    }} />
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Last Serviced</label>
                  <RangePicker
                    style={{ 'width': '100%' }}
                    allowClear={true}
                    separator='-'
                    format={"MMM DD, YYYY"}
                    dropdownClassName='current_month'
                    value={filterData?.lastService?.length > 0 ? [filterData?.lastService?.[0] ? moment(filterData?.lastService?.[0]) : undefined, filterData?.lastService?.[1] ? moment(filterData?.lastService?.[1]) : undefined] : undefined}
                    onChange={(e) => {
                      if (e === null) {
                        onFilterChange({ target: { name: 'lastService', value: [] } });
                      } else {
                        onFilterChange({ target: { name: 'lastService', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                      }
                    }} />
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Satisfaction Score</label>
                  <Select
                    placeholder="Enter Satisfaction Score"
                    name="satisfaction"
                    onChange={(e) => onFilterChange({ target: { name: 'satisfaction', value: e } })}
                    value={filterData?.satisfaction || undefined}>
                    {
                      satisfaction_score?.map((option) =>
                        <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                    }
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <button className={`btn`} onClick={() => { closeDrawer(); onClearFilter(); }}>Cancel</button>
            <button
              // type="submit"
              className={`btn`}
              onClick={() => onFilterClick()}>
              Filter Customer
            </button>
          </div>
        </div>
      </Drawer>
    )
  };
  //render lead filter drawer
  const renderDrawerLead = () => {
    return (
      <Drawer
        placement={'right'}
        closable={false}
        onClose={closeDrawerLead}
        visible={openDrawerLead}
        key={'rightLead'}
        width={'400'}
        className="drawer-wrapper"
      >
        <div className="group_wrapper rdrawer">
          <div className="card-head">
            <h4>Filter Leads</h4>
          </div>
          <div className="card-row">
            <div className="card-full">
              <div className="form-group">
                <div className="fitem">
                  <label>Lead Status</label>
                  <div className="fitem-check">
                    <input
                      type="checkbox"
                      className="fitem-ck-input"
                      id={`active`}
                      name={'active'}
                      checked={filterDataLead?.active}
                      onChange={(e) => onFilterChangeLead(e, 'bool')} />
                    <label htmlFor={`active`} className="fitem-ck-txt mb-0">{'Active'}</label>
                  </div>
                  <div className="fitem-check">
                    <input
                      type="checkbox"
                      className="fitem-ck-input"
                      id={`inactive`}
                      name={'inactive'}
                      checked={filterDataLead?.inactive}
                      onChange={(e) => onFilterChangeLead(e, 'bool')} />
                    <label htmlFor={`inactive`} className="fitem-ck-txt mb-0">{'Inactive'}</label>
                  </div>
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Franchise</label>
                  <Select
                    id={"customerFranchise"}
                    placeholder="Select a Franchise"
                    name="franchise"
                    onChange={(e) => onFilterChangeLead({ target: { name: 'franchise', value: e } })}
                    value={filterDataLead?.franchise || undefined}>
                    {(current_user !== 'franchise_owner' && current_user !== 'operations_mgr') && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                    {
                      orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                        <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                    }
                  </Select>
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Last Contacted</label>
                  <RangePicker
                    style={{ 'width': '100%' }}
                    allowClear={true}
                    separator='-'
                    format={"MMM DD, YYYY"}
                    dropdownClassName='current_month'
                    value={filterDataLead?.last_contacted?.length > 0 ? [filterDataLead?.last_contacted?.[0] ? moment(filterDataLead?.last_contacted?.[0]) : undefined, filterDataLead?.last_contacted?.[1] ? moment(filterDataLead?.last_contacted?.[1]) : undefined] : undefined}
                    onChange={(e) => {
                      if (e === null) {
                        onFilterChangeLead({ target: { name: 'last_contacted', value: [] } });
                      } else {
                        onFilterChangeLead({ target: { name: 'last_contacted', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                      }
                    }} />
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Lead Stage</label>
                  <Select
                    placeholder="Select"
                    name="lead_stage"
                    onChange={(e) => onFilterChangeLead({ target: { name: 'lead_stage', value: e } })}
                    value={filterDataLead?.lead_stage || undefined}>
                    {
                      value_lead_stage?.map((option) =>
                        <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                    }
                  </Select>
                </div>
                <div className="fitem">
                  <label htmlFor="customerFranchise">Outreach</label>
                  <Select
                    placeholder="Select"
                    name="outreach"
                    onChange={(e) => onFilterChangeLead({ target: { name: 'outreach', value: e } })}
                    value={filterDataLead?.outreach || undefined}>
                    {
                      value_outreach?.map((option) =>
                        <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                    }
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <button className={`btn`} onClick={closeDrawerLead}>Cancel</button>
            <button
              className={`btn`}
              onClick={() => onFilterClickLead()}>
              Filter Customer
            </button>
          </div>
        </div>
      </Drawer>
    )
  };
  // on lead list row
  const onLeadListRow = (record) => {
    return {
      onClick: () => {
        navigate(`lead/detail/${record?.uuid}`, { state: { uuid: record?.uuid, view: 'residential' } });
      }
    }
  }
  const renderLeadsList = () => {
    const franchise = franchiseList?.results?.map((item) => ({ text: item?.name, value: item?.uuid }));
    return (
      <div className="leadstbl">
        <Table
          rowKey={record => record?.uuid}
          dataSource={leadList?.results || []}
          pagination={{ ...leadListPagination, total: leadList?.count }}
          columns={DataTable.residentialCustomerColumns(franchise, leadList)}
          onChange={onLeadTableChange}
          onRow={onLeadListRow}
          className="customer-list"
        />
        {
          leadList?.results?.length > 0 ?
            <ul className="pagination_page_size">
              <li>
                <span>Show:</span>
              </li>
              <li>
                <span
                  onClick={() => { setLeadPageLimit('15') }}
                  className={activePagination_lead === '15' ? 'active' : ''}
                >15</span>
              </li>
              <li>
                <span
                  className={activePagination_lead === '30' ? 'active' : ''}
                  onClick={() => { setLeadPageLimit('30') }}>30</span>
              </li>
              <li>
                <span
                  className={activePagination_lead === '60' ? 'active' : ''}
                  onClick={() => { setLeadPageLimit('60') }}>60</span>
              </li>
            </ul>
            : ''
        }
      </div>
    )
  };
  const onPrimaryFilterChange = (e, type, name) => {
    setFilterDataLead(filterDataLead => ({
      ...filterDataLead,
      franchise: e.target.value
    }));
    current_user === 'franchise_owner' || current_user === 'operations_mgr' && setFranchiseSelected(e.target.value);
    onFilterClickLead();
  }
  const renderCancelledCustomer = () => {

  }

  const onSearch = async (e) => {
    setLoader(true);
    setSearchValue(e.target.value);

    if (currentTab === 1) {
      await getCustomer();
    } else {
      await getLead();
    }

    setLoader(false);
  }

  return (
    <div id="wrapper">
      {loader && <Loader />}
      <div className="content-area">
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Header
          placeholder={"Acc Number, Customer Name, Address(Billing), Phone Number, Email Address"}
          readOnly={false}
          search_value={searchValue}
          setSearchValue={setSearchValue}
          onSearch={onSearch}
          onEnter={(e) => {
            e.target.value.length > 2 ?
              onSearch(e)
              : alert("Enter at least 3 character!")
          }
          }
        />
        {/*<GlobalSearch />*/}
        <Sidebar sideBarBackgroundColor='#005d89' />
        <SidebarResponsive sideBarBackgroundColor='#005d89' openMenu={openMenu} onMenubuttonClick={onMenubuttonClick} />
        <div className='container'>
          <div className='filter_wrapper customer_list'>
            <div className='left_side'>
              <div className="fitem" style={{ marginRight: '10px' }}>
                {currentTab === 0 ?
                  <Select
                    id={"customerFranchise"}
                    className="swhite"
                    placeholder="Select a Franchise"
                    name="franchise"
                    onChange={(e) => onPrimaryFilterChange({ target: { name: 'franchise', value: e } })}
                    value={filterDataLead?.franchise || undefined}>
                    {(current_user !== 'franchise_owner' || current_user !== 'operations_mgr') && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                    {
                      orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                        <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                    }
                  </Select> : <Select
                    // disabled={is_disable_franchise_dropdown}
                    id={"customerFranchise"}
                    className="swhite"
                    placeholder="Select a Franchise"
                    name="franchise"
                    onChange={(e) => onFilterChange({ target: { name: 'franchise', value: e } })}
                    value={filterData?.franchise || undefined}>
                    {(current_user !== 'franchise_owner' || current_user !== 'operations_mgr') && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                    {
                      orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                        <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                    }
                  </Select>}

              </div>
              <ul className="tabs">
                <li style={{ marginRight: '10px' }} className={currentTab === 0 ? 'active' : ''} onClick={() => { setCurrentTab(0) }}>
                  <span>{`LEADS`}</span>
                </li>
                <li style={{ marginRight: '10px' }} className={currentTab === 1 ? 'active' : ''} onClick={() => { setCurrentTab(1) }}>
                  <span>CUSTOMERS</span>
                </li>
                <li className={currentTab === 2 ? 'active' : ''} onClick={() => { setCurrentTab(2) }}>
                  <span>{`CANCELLED CUSTOMERS`}</span>
                </li>
              </ul>
            </div>
            <div className='right_side'>
              <div className="fitem">
                {/* <div className='dis'>
                  <Select
                    style={{ 'width': '150px' }}
                    className="status_Customer_list swhite filter_type"
                    defaultValue=""
                    // value={account_list_filter.status}
                    // onChange={(e) => {
                    //   this.customerAndStatusOnChange(e, 'status')
                    // }}
                    suffixIcon={<i className="las la-angle-down"></i>}
                  >
                    <Option value="">All</Option>
                    <Option value="active">Active</Option>
                    <Option value="in-active">In-active</Option>
                  </Select>
                </div> */}
                {/* <div className="fitem"> */}
                <div className='dis_res'>
                  <Select
                    // disabled={is_disable_franchise_dropdown}
                    id={"customerFranchise"}
                    className="status_Customer_list swhite filter_type"
                    placeholder="Select a Franchise"
                    name="franchise"
                    onChange={(e) => onFilterChange({ target: { name: 'franchise', value: e } })}
                    value={filterData?.franchise || undefined}>
                    {(current_user !== 'franchise_owner' || current_user !== 'operations_mgr') && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                    {
                      orderBy(franchiseList?.results, 'id', 'asc')?.map((option) =>
                        <Option key={option?.uuid} value={option?.uuid}>{option?.name}</Option>)
                    }
                  </Select>
                  {/* </div> */}
                </div>
              </div>
              <span className={currentTab === 2 ? "ico-btn disable-field" : "ico-btn"}>
                <FilterOutlined onClick={onFilterButtonClick}>Filter</FilterOutlined>
              </span>
              {currentTab === 0 ? <div className="right_side" style={{ flex: 'none' }}>
                <button
                  disabled={isAddNewLead}
                  onClick={() => closeAddDrawer(true)}
                  className={!isAddNewLead ? "btn btn-warning" : "btn btn-brand btn-bdr disbale_btn"}
                >Add New Lead</button>
              </div> : <Link className="link-button" to={'/residential/new-customer'}><button className='btn btn-warning'>Add New Customer</button></Link>}
            </div>
          </div>
          <div className="data leadstable">
            {currentTab === 1 ? renderCustomerList() : currentTab === 0 ? renderLeadsList() : renderCanceledCustomerList()}
          </div>
          {renderDrawer()}
          {renderDrawerLead()}
          <Drawer
            visible={isAddNewLead}
            width={'440'}
            closable={false}
            className="drawer-wrapper create_ticket_drawer"
          >
            <AddNewLead closeAddDrawer={closeAddDrawer} startLoader={setLoader} view={'residential'} />
          </Drawer>
        </div>
      </div>
    </div>
  )
}

export default CustomerList
