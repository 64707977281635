import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { _, isEmpty, isString } from "lodash";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HeaderMobile from "../../../Components/HeaderMobile";
import Sidebar from "../../../Components/Sidebar";
import SidebarResponsive from "../../../Components/SidebarResponsive";
import Loader from "../../../Components/Loader";
import ServiceInfo from "../../Commercial/ServiceInfo";
import BaseJobRequirements from "../../Common/BaseJobRequirements";
import BillingInfo from "../../Common/BillingInfo";
import { updateLead } from "../../../Action/Customer";
import {
  updateLeadResidential,
  createServiceOrder,
  editServiceOrder,
} from "../../../Action/Customer";
import { createLeadResidential } from "../../../Action/Customer";
import { useForm } from "rc-field-form";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { resetValues } from "../../../Store/serviceOrderDetailDataSlice";
import StaxModal from "../../Common/Stax.Modal";
import StaxModalACH from "../../Common/StaxACH.Modal";

const DetailsInfo = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const serviceAreaFunc = React.useRef(null);
  const billingInfoFunc = React.useRef(null);
  const validateBaseJobReq = React.useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const [btnLabel, setBtnLabel] = useState("Next: Go to billing");
  const [infoData, setInfoData] = useState({
    uuid: state.serviceOrder?.[0]?.uuid || null,
    zip_code: state?.data?.zip_code || "",
    first_name: state.data.first_name || "",
    last_name: state.data.last_name || "",
    address: state.data.address.length > 1 ? state.data.address[1] : state.data.address[0],
    invoice_address: state.data.invoice_address,
    phone_numbers: state.data.phone_numbers || [],
    emailaddress_set:
      state.data.emailaddress_set.length > 0
        ? state.data.emailaddress_set
        : [{ email: state.data.email, primary: true }],
    authorized_users: state.data.authorized_users || [],
    hear_about_us: state.data.hear_about_us || [],
    payment_method: state.serviceOrder?.[0]?.payment_method || "",
    charge_invoice_card: state.serviceOrder?.[0]?.charge_invoice_card?.value || false,
    preferred_contact_methods:
      state.serviceOrder?.[0]?.preferred_contact_methods || [],
    preferred_start_date: state.serviceOrder?.[0]?.preferred_start_date || "",
    preferred_start_time: state.serviceOrder?.[0]?.preferred_start_time || "",
    properties_in_community: state.data.properties_in_community || [],
    schedule_time_range: state.serviceOrder?.[0]?.schedule_time_range || "",
    is_print_and_mail_invoices:
      state.serviceOrder?.[0]?.is_print_and_mail_invoices || false,
    is_email_invoice: state.serviceOrder?.[0]?.is_email_invoice || false,
    company_name: state.data.company_name || "",
    no_of_properties: state.data.no_of_properties || "",
    no_of_pools: state.data.no_of_pools || "",
    notes: state.data.notes || "",
  });
  const [data, setData] = useState({});
  const [priceDetail, setPriceDetail] = useState({});
  const [serviceInfo, setServiceInfo] = useState({});
  const [currentTab, setCurrentTab] = useState(-1);
  const [standardRate, setStandardRate] = useState(false);
  const [pricevalue, setPricevalueValue] = useState(0);
  const [leadNotes, setleadNotes] = useState();
  const [leadCallBackDate, setleadCallBackDate] = useState("");
  const [leadPurposeCallBack, setleadPurposeCallBack] = useState("");
  const [fieldOfficeData, setFieldOfficeData] = useState({});
  const [openStax, setOpenStax] = useState(false);
  const [openStaxModalACH, setOpenStaxModalACH] = useState(false);
  const dispatch = useDispatch();
  const serviceOrderNewFieldsValue = useSelector(
    (state) => state.serviceOrderAddedData
  );

  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };
  useEffect(() => {
    if (state.serviceOrder?.length > 0) {
      const datainfo = state.serviceOrder[0];
      setServiceInfo((s)=>({...s, frequency: {value:datainfo.frequency_settings}}));
      setData((s) => ({ ...s, datainfo }));
      loadSO(datainfo);
    }
  }, []);

  const loadSO = (datainfo) => {
    if (datainfo.callback_date) setleadCallBackDate(datainfo.callback_date);
    if (datainfo.callback_purpose)
      setleadPurposeCallBack(datainfo.callback_purpose);
    if (datainfo.callback_notes) setleadNotes(datainfo.callback_notes);
    setPricevalueValue(datainfo.service_price);
    // setPriceDetail((p) => ({ price: datainfo.service_price }));
  };

  const onDataChange = (incomingData) => {
    setData((data) => ({
      ...data,
      ...infoData,
      ...incomingData,
    }));
    console.log(data);
    if (incomingData?.zip_code)
      setServiceInfo((s) => ({
        ...s,
        zipCode: { value: incomingData?.zip_code },
        // serviceType: { key: 'service' }
      }));
  };

  const saveFieldOfficeData = (data) => {
    setFieldOfficeData(data);
  };

  const updateLeadDetails = async () => {
    setLoader(true);
    let isValid = false;
    try {
      const isValidated = await serviceAreaFunc.current.validate();
      if (currentTab === 0) {
        isValid = await validateBaseJobReq.current.validate();
      } else {
        isValid = await billingInfoFunc.current.validate("save_lead");
      }

      if (isValid === "success") {
        let temp = { ...data };
        temp.uuid = state.data.uuid;
        temp.phone = data.phone_numbers[0]?.phone;
        temp.email = data.emailaddress_set[0].email.toLowerCase();
        temp.zip_code = serviceInfo?.zipCode?.value;
        temp.address = isString(temp.address)
          ? state.data.address
          : [temp.address];
        temp.invoice_address =serviceInfo?.billingInfo?.value?.billingAddress;
        temp.callback_date = leadCallBackDate;
        temp.callback_purpose = leadPurposeCallBack;
        temp.callback_notes = leadNotes;

        const result = await updateLeadResidential(temp);

        if (result.uuid) {
          //Save service order
          var serviceTypeUUID = null;
          if (serviceInfo?.worksubtype?.value) {
            serviceTypeUUID = serviceInfo?.worksubtype?.value;
          } else if (!serviceInfo?.worksubtype && serviceInfo?.service?.value) {
            serviceTypeUUID = serviceInfo?.service?.value;
          } else {
            serviceTypeUUID = serviceInfo?.serviceType?.value;
          }

          let serviceOrderObj = await createServiceObj(result, serviceTypeUUID);
          serviceOrderObj.status = "Enquiry";
          if (data.datainfo && data.datainfo.uuid) {
            serviceOrderObj.uuid = data.datainfo.uuid;
          }

          const postServiceOrder = async () => {
            let so_response;
            if (
              serviceOrderObj.uuid !== undefined &&
              serviceOrderObj.uuid !== null
            ) {
              so_response = await editServiceOrder(
                serviceOrderObj.uuid,
                serviceOrderObj
              );
            } else {
              so_response = await createServiceOrder(serviceOrderObj);
            }

            if (so_response?.uuid) {
              dispatch(resetValues());
              setLoader(false);
              message.success("Saved successfully.");
              if (state?.source === "customers-lead-list")
                navigate("/customer-lead-list", {
                  state: { currentTab: "lead" },
                });
              else navigate(`/residential`, { state: { currentTab: 0 } });
            } else {
              setLoader(false);
              message.error("Something went wrong!");
            }
          };
          postServiceOrder();
        }
      }
    } catch (error) {
      setLoader(false);
    }
    setLoader(false);
  };

  const setServiceInfoData = (data) => {
    let obj = serviceInfo;
    obj[data.name] = data;
    setServiceInfo(obj);
    console.log(obj);
  };

  const setServiceInfoList = (list) => {
    let obj = { ...serviceInfo };
    list.forEach((element) => {
      obj[element.name] = element;
    });

    setServiceInfo(obj);
  };

  const setPriceDetailData = (data) => {
    setPriceDetail(data);
    setPricevalueValue(data.price);
  };

  const saveData = (e) => {
    setPricevalueValue(e.target.value);
    setPriceDetail((p) => ({ price: e.target.value }));
  };

  const openPayment = () => {
    if (currentTab === 1) {
      if (serviceInfo?.payment_method?.value === "card"
        || serviceInfo?.payment_method?.value === "ACH Payments") {
        setLoader(true);
        billingInfoFunc.current.validate("save_lead").then((res) => {
          if (res === "success") {
            setLoader(false);
            if (serviceInfo?.payment_method?.value === "card") {
              setOpenStax(true);
            } else {
              setOpenStaxModalACH(true);
            }
          } else{
            setLoader(false);
            message.error("Something went wrong!");
          }
        }).catch(() =>{
          setLoader(false);
          message.error("Please correct errors");
        });
      }
      else {
        validate();
      }
    } else {
      validate();
    }
  };


  const validate = (action, staxProps) => {
    serviceAreaFunc.current.validate().then((res) => {
      if (res === "success") {
        if (currentTab === 0) {
          validateBaseJobReq.current.validate().then((res) => {
            if (res === "success") {
              setCurrentTab(1);
              setBtnLabel("Convert to Customer");
            }
          });
        } else {
          billingInfoFunc.current.validate(undefined, staxProps).then((res) => {
            const primaryEmail = data?.emailaddress_set?.filter((email) => {
              return email.primary;
            });
            let emailaddress = data?.emailaddress_set.map(x => Object.fromEntries(Object.entries(x).map(([key, value]) => [key, typeof value == 'string' ? value.toLowerCase() : value])));

            if (res === "success") {
              setLoader(true);

              let customerObj = { ...data };
              customerObj.uuid = state.data.uuid;
              customerObj.role = ["customer"];
              customerObj.address = state.data.address;
              customerObj.invoice_address = serviceInfo?.billingInfo?.value?.billingAddress;
              customerObj.emailaddress_set = emailaddress;
              customerObj.lead_stage = state.data.lead_stage;
              customerObj.outreach = state.data.outreach;
              customerObj.email =
                (primaryEmail && primaryEmail[0] && primaryEmail[0].email.toLowerCase()) ||
                null;
              customerObj.phone = data.phone_numbers[0]?.phone;
              customerObj.stax_card_token = staxProps?.id;
              customerObj.card_holder_name = staxProps?.person_name;
              customerObj.callback_date = leadCallBackDate;
              customerObj.callback_purpose = leadPurposeCallBack;
              customerObj.callback_notes = leadNotes;
              customerObj.zip_code = state?.data?.zip_code;
              const postCustomerCreate = async () => {
                const response = await updateLeadResidential(customerObj); //To DO: implement correct API
                if (response?.uuid) {
                  var serviceTypeUUID = null;
                  if (serviceInfo?.worksubtype?.value) {
                    serviceTypeUUID = serviceInfo?.worksubtype?.value;
                  } else if (
                    !serviceInfo?.worksubtype &&
                    serviceInfo?.baseJobReq?.value?.service
                  ) {
                    serviceTypeUUID = serviceInfo?.baseJobReq?.value?.service;
                  } else {
                    serviceTypeUUID =
                      serviceInfo?.baseJobReq?.value?.serviceType;
                  }

                  let serviceOrderObj = await createServiceObj(
                    response,
                    serviceTypeUUID
                  );
                  serviceOrderObj.status = "Active Order";
                  const postServiceOrder = async () => {
                    let so_response;
                    serviceOrderObj.is_enquiry = false;
                    if (
                      serviceOrderObj.uuid !== undefined &&
                      serviceOrderObj.uuid !== null
                    ) {
                      so_response = await editServiceOrder(
                        serviceOrderObj.uuid,
                        serviceOrderObj
                      );
                    } else {
                      so_response = await createServiceOrder(serviceOrderObj);
                    }
                    if (so_response?.uuid) {
                      dispatch(resetValues());
                      setLoader(false);
                      message.success("Service Order is created successfully.");
                      if (state?.source === "customers-lead-list") {
                        sessionStorage.setItem(
                          "CACHED_CURRENT_TAB",
                          "customer"
                        );
                        navigate("/customer-lead-list");
                      } else navigate("/residential");
                    } else {
                      setLoader(false);
                      message.error("Something went wrong!");
                    }
                  };
                  postServiceOrder();
                } else {
                  setLoader(false);
                  message.error("Something went wrong!");
                }
                // setCustomer(data?.data);
                // setLoader(false);
              };
              postCustomerCreate();
            }
          });
        }
      }
    });
  };

  const createServiceObj = (response, serviceTypeUUID) => {
    let invoiceAddrFromResp = response?.invoice_address?.uuid
    let serviceAddrFromResp = (response.address).filter((curr) => { return invoiceAddrFromResp !== curr.uuid});
    let serviceOrderObj = {};
    serviceOrderObj.uuid = infoData?.uuid ?? null;
    serviceOrderObj.service_address_uuid = serviceAddrFromResp?.[0]?.uuid;
    serviceOrderObj.service_address = serviceAddrFromResp[0];
    serviceOrderObj.field_office_uuid = response?.field_office?.uuid;
    serviceOrderObj.is_billing_address_same =
      serviceInfo?.billingInfo?.value?.isBillingAddressSame;
    serviceOrderObj.billing_address=serviceInfo?.billingInfo?.value?.billingAddress;
    serviceOrderObj.client_uuid = response?.uuid;
    serviceOrderObj.is_service = true;
    serviceOrderObj.proposal_date = moment().format("YYYY-MM-DD");
    serviceOrderObj.contract_date = moment().format("YYYY-MM-DD");
    serviceOrderObj.preferred_start_date = serviceInfo?.preferred_start_date
      ?.value
      ? moment(serviceInfo?.preferred_start_date?.value ?? "").format(
          "YYYY-MM-DD"
        )
      : moment().format("YYYY-MM-DD");
    serviceOrderObj.schedule_time_range = serviceInfo.time_range?.value ?? "";
    serviceOrderObj.pool_type_uuid = serviceInfo?.poolType?.value
      ? serviceInfo?.poolType?.value
      : serviceInfo?.otherPoolType?.value;
    serviceOrderObj.pool_size = parseInt(serviceInfo?.poolSize?.value);
    serviceOrderObj.preferred_contact_methods =
      serviceInfo?.preferred_contact_methods?.value ?? [];
    serviceOrderObj.payment_method = serviceInfo.payment_method?.value ?? "";
    serviceOrderObj.charge_invoice_card = serviceInfo?.charge_invoice_card?.value || false;
    serviceOrderObj.is_print_and_mail_invoices =
      serviceInfo.invoice_preference?.value.find(
        (i) => i.name === "is_print_and_mail_invoices"
      ).value;
    serviceOrderObj.is_email_invoice =
      serviceInfo.invoice_preference?.value.find(
        (i) => i.name === "is_email_invoice"
      ).value;
    serviceOrderObj.is_enquiry = true;
    if (
      serviceInfo?.serviceType?.key?.toLowerCase() === "maintenance" ||
      serviceInfo?.serviceType?.key?.toLowerCase() === "service"
    ) {
      serviceOrderObj.frequency_settings = {
        recurring: serviceInfo?.frequency?.value?.recurring,
        start: moment(serviceInfo?.frequency?.value?.start).format(
          "YYYY-MM-DD"
        ),
      };
      if (
        serviceInfo?.frequency?.value?.end !== undefined &&
        serviceInfo?.frequency?.value?.end !== null
      )
        serviceOrderObj.frequency_settings.end = moment(
          serviceInfo?.frequency.value.end
        ).format("YYYY-MM-DD");
      if (serviceInfo?.time?.value)
        serviceOrderObj.preferred_start_time =
          serviceInfo?.time?.value <= 0
            ? ""
            : moment(serviceInfo?.time.value).format("hh:mm") || "";
    }
    if (serviceInfo.maintenanceCategory)
      serviceOrderObj.is_flat_rate =
        serviceInfo?.maintenanceCategory?.value?.toLowerCase() === "flat_rate"
          ? true
          : false;

    serviceOrderObj.price = +priceDetail.price;
    serviceOrderObj.service_price = +priceDetail.price;
    serviceOrderObj.notes = serviceInfo?.notes?.value;
    serviceOrderObj.client_type = "residential";
    serviceOrderObj.service_duration =
      serviceOrderNewFieldsValue?.duration || "";
    serviceOrderObj.reference_number =
      serviceOrderNewFieldsValue?.referenceNumber || "";

    if (
      serviceInfo?.worksubtype?.value &&
      serviceInfo?.serviceType?.key === "service" &&
      (serviceInfo?.service?.key === "Diagnostic" ||
        serviceInfo?.service?.key === "pool inspection")
    ) {
      serviceOrderObj.service_type_uuid = serviceInfo?.worksubtype?.value;
    } else if (
      serviceInfo?.service?.value &&
      serviceInfo?.serviceType?.key === "service"
    ) {
      serviceOrderObj.service_type_uuid = serviceInfo?.service?.value;
    } else {
      serviceOrderObj.service_type_uuid = serviceInfo?.serviceType?.value;
    }
    if (serviceInfo?.serviceType?.key?.toLowerCase() === "maintenance") {
      serviceOrderObj.frequency_settings = serviceInfo?.frequency?.value;
      serviceOrderObj.is_service = false;
    }

    return serviceOrderObj;
  };

  const cancel = () => {
    dispatch(resetValues());
    if (state?.source === "customers-lead-list")
      navigate("/customer-lead-list", { state: { currentTab: "lead" } });
    else navigate(`/residential`, { state: { currentTab: 0 } });
  };

  const renderTabs = () => {
    switch (currentTab) {
      case 1:
        return (
          <BillingInfo
            ref={billingInfoFunc}
            uuid={state.data.uuid}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            setServiceInfoList={setServiceInfoList}
            fieldOfficeData={fieldOfficeData}
            serviceInfo={serviceInfo}
            data={data}
            infoData={infoData}
          />
        );
      case 0:
        return state?.data?.zip_code ? (
          <BaseJobRequirements
            ref={validateBaseJobReq}
            setPriceDetailData={setPriceDetailData}
            setServiceInfoData={setServiceInfoData}
            setServiceInfoList={setServiceInfoList}
            data={data}
            standardRate={standardRate}
            fieldOfficeData={fieldOfficeData}
          />
        ) : (
          <div className="adetail">
            <h3>Base Job Requirements</h3>
            <div className="frow">
              <h6>
                Please insert a Zipcode to get the Service Types provided by the
                Franchise
              </h6>
            </div>
          </div>
        );
      default:
        return (
          <div className="adetail">
            <h3>
              <span className="danger">Please Enter Valid Zip Code !</span>
            </h3>
          </div>
        );
    }
  };

  const renderSummary = () => {
    return (
      <div className="aside-bar">
        <div className="acol">
          <h2 className="mb-20">Service Summary</h2>
          <h4>Subscription details</h4>
          <div className="flex">
            <div className="flex-1">
              <p className="text-capitalize">{serviceInfo?.serviceType?.key}</p>
              {serviceInfo?.serviceType?.key?.toLowerCase() !==
              "maintenance" ? (
                <p className="text-capitalize">{serviceInfo?.service?.key}</p>
              ) : null}
              <p className="text-capitalize">
                {priceDetail?.frequency?.recurring?.repeat}
              </p>
              <p className="text-capitalize">
                {serviceInfo?.poolType?.key}
                {serviceInfo?.otherPoolType?.value
                  ? " / " + serviceInfo?.otherPoolType?.value
                  : null}
              </p>
            </div>
          </div>
          <hr />
          {serviceInfo?.serviceType?.key?.toLowerCase() === "maintenance" ? (
            <p style={{ textAlign: "justify" }}>
              Pool must be clear and balanced before we begin maintenance. Price
              may change once we visit pool.
            </p>
          ) : serviceInfo?.service?.key || priceDetail?.key ? (
            <p style={{ textAlign: "justify" }}>{`Price is for ${
              serviceInfo?.service?.key || priceDetail?.key
            } only. Price for repair to be given once ${
              serviceInfo?.service?.key || priceDetail?.key
            } has been completed`}</p>
          ) : null}
          <div className="wlight">
            <div className="flex ai-center">
              <div className="flex-1">
                <h4 className="m-0">
                  Total
                  {priceDetail?.key
                    ? priceDetail?.key === "One Time"
                      ? ""
                      : " (Monthly Charge) :"
                    : ""}
                </h4>
              </div>
              <div
                className="pricing"
                style={{ width: "50%", position: "relative" }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "20%",
                    paddingTop: "9px",
                  }}
                >
                  $
                </span>
                <input
                  type="text"
                  className="pricing pl-10"
                  style={{
                    width: "100%",
                    backgroundColor: "#f9ffd9",
                    paddingLeft: "25%",
                    textAlign: "left",
                    marginLeft: "8px",
                  }}
                  placeholder="Price"
                  name="price"
                  value={pricevalue}
                  onChange={(e) => saveData(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="acol">
          <h2 className="mb-20">Save as lead?</h2>
          <div className="flex">
            <div className="flex-1">
              <label>Callback date</label>
              <input
                type="date"
                className="fitem-ck-input"
                value={leadCallBackDate}
                onChange={(e) => {
                  setleadCallBackDate(e.target.value);
                }}
              />
            </div>
            <div className="flex-1 ml-10">
              <label>Purpose of Callback</label>
              <input
                type="text"
                className="fitem-ck-input"
                value={leadPurposeCallBack}
                onChange={(e) => {
                  setleadPurposeCallBack(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex-1">
              <label>Notes</label>
              <textarea
                placeholder="Notes"
                name="Notes"
                rows="5"
                value={leadNotes}
                onChange={(e) => {
                  setleadNotes(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="wrapper">
      {loader && <Loader />}
      <div className="content-area">
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenubuttonClick}
        />
        <div className="fluid">
          <div className="container new-cust">
            <div className="fluid-block">
              <div className="article-block">
                <ServiceInfo
                  ref={serviceAreaFunc}
                  inputData={infoData}
                  onDataChange={onDataChange}
                  onSetData={onDataChange}
                  setCurrentTab={setCurrentTab}
                  saveFieldOfficeData={saveFieldOfficeData}
                />
                {renderTabs()}
                {renderSummary()}
                {/* <div className="aside-bar">
                                    <div className='acol'>
                                        <h2 className="mb-20">Service summary</h2>
                                        <h5>Subscription details</h5>
                                        <div>{`${state.data.first_name} ${state.data.last_name}`} </div>
                                        <div>{state.data.email}</div>
                                        <div>{state.data.phone_number}</div>
                                        <hr />
                                        <h5>Community Information</h5>
                                        <div>{(state.data.address[0]?.street_number && state.data.address[0]?.route) ?
                                            `${state.data.address[0]?.street_number || ''} ${state.data.address[0]?.route || ''} ` :
                                            `${state.data.address[0]?.address || ''}`}
                                        </div>
                                        <div>{(state.data.address[0]?.street_number && state.data.address[0]?.route) ?
                                            `${state.data.address[0]?.city || ''}, ${state.data.address[0]?.state || ''} ${state.data.address[0]?.zip_code || ''}` :
                                            `${state.data.address[0]?.city || ''}, ${state.data.address[0]?.state || ''} ${state.data.address[0]?.zip_code || ''}`}
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="fix-btn-grp" style={{ width: "100%" }}>
              <button onClick={cancel} className="btn btn-white">
                Cancel
              </button>
              <button
                className={`btn btn-bdr`}
                type="button"
                onClick={updateLeadDetails}
              >
                {"Save Lead"}
              </button>
              <button
                className={`btn btn-bdr`}
                type="button"
                onClick={openPayment}
              >
                {btnLabel}
              </button>
            </div>
            {openStax ? (
              <StaxModal
                open={openStax}
                setOpen={(operation) => setOpenStax(operation)}
                onSuccessCall={validate}
                customer={{
                  address: data?.address,
                  emails: data?.emailaddress_set || "",
                  firstName: data?.first_name || "",
                  lastName: data?.last_name || "",
                  phoneNumbers: data?.phone_numbers,
                }}
                staxKey={state.staxKey}
              />
            ) : (
              <></>
            )}
            {openStaxModalACH ? (
              <StaxModalACH
                open={openStaxModalACH}
                setOpen={(operation) => setOpenStaxModalACH(operation)}
                onSuccessCall={validate}
                customer={{
                  address: data?.address,
                  emails: data?.emailaddress_set || "",
                  firstName: data?.first_name || "",
                  lastName: data?.last_name || "",
                  phoneNumbers: data?.phone_numbers,
                }}
                staxKey={state.staxKey}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsInfo;
