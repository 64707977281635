export const setBundleVal = (newRowsData) => {
  let bundles = [];
  if (newRowsData?.length > 0) {
    bundles = newRowsData.map(item => {
      if (item?.bundle_code) {
        let tax = 0;
        newRowsData.forEach(row => {
          if (!row?.bundle_code && row?.repair_code?.includes(item?.bundle_code)) {
            if (row?.is_taxable) {
              tax += parseFloat(row?.tax || 0);
            }
          }
        });
        return {
          ...item, tax, total: item.price + tax,
        }
      } else {
        return item;
      }
    });
  }
  return bundles;
}