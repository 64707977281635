import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../../Components/Loader";
import { Table, Dropdown, Menu, Select, message, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { map, isEmpty } from "lodash";
import DataTable from "../../Common/DataTable";
import TypeOutsideAlerter from "../../../Components/TypeOutsideAlerter";
import AddNewInvoice from "../Invoices/AddNewInvoice";
import LogPayment from "../Invoices/LogPayment";
import "../Invoices/invoice.css";
import {
  getInvoice,
  getOverdueOpenLastInvoices,
  getInvoiceDetail,
  sendInvoice,
  deleteInvoice,
  voidInvoiceApi,
  getPaymentHistory,
} from "../../../Action/Invoice";
import { formatToCurrency } from "../../../Utils/formatToCurrency";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux/es/exports";
import { add } from "../../../Store/invoiceSummarySlice";
import config from "../../../Lib/Config";
import Refund from "../Invoices/Refund";
import { getUserBillingInfo, printInvoiceRequest } from "../../../Action/Customer";

const Invoices = (props) => {
  const [state, setState] = useState({
    refundTypeValue: null,
    list: "",
  });

  const dispatch = useDispatch();
  const { Option } = Select;
  const status = [
    { key: "All", value: "all" },
    { key: "Open", value: "open, open-emailed, open-printed" },
    { key: "Overdue", value: "overdue" },
    { key: "Paid", value: "paid" },
  ];
  const row_action = {
    none: "",
    print: "Print",
    view: "View/Edit",
    resend: "Resend",
    log: "Log Payment",
    refund: "Refund Payment",
    delete: "Delete",
    void: "Void",
    payment_history: "Payment History",
    actionIssueRefund: false,
    workOrderList: [],
  };
  const row_action_credit_memo = {
    "view-credit-memo": "View/Edit",
  };
  const [loader, setLoader] = useState(false);
  const { currentTab, setCurrentTab } = props;
  const [toggleTypeFilter, settoggleTypeFilter] = useState(false);
  const [showManualLogPayment, setshowManualLogPayment] = useState(false);
  const [showPaymentHistory, setPaymentHistory] = useState(false);
  const [showLogPayment, setshowLogPayment] = useState(false);
  const [showRefund, setshowRefund] = useState(false);
  const [actionValue, setActionValue] = useState(null);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoice_uuid, setInvoiceuuid] = useState("");
  const [action, setaction] = useState("");
  const [active_action_uuid, setactive_action_uuid] = useState("");
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [invoiceRecord, setInvoiceRecord] = useState({});
  // const [invoiceSummary, setInvoiceSummary] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [voidInvoice, setVoidInvoice] = useState({});
  const [invoicePaymentHistory, setInvoicePaymentHistory] = useState([]);
  const [cardList, setCardList] = useState();
  const [isAch, setIsAch] = useState(false);
  const invoiceSummary = useSelector((state) => state.invoiceSummary);

  const getInvoiceList = async () => {
    const data = await getInvoice({ user__uuid: props?.record?.uuid });
    setInvoiceList(data?.results);
    setLoader(false);
  };
  const userBillingInfo = async () => {
    const data = await getUserBillingInfo({ user__uuid: props?.record?.uuid });
    var size = Object.keys(data.data).length;
    if (data.data.length) {
      let temp_ach = data.data.filter((each) => each.is_ach);
      let temp_card = data.data.filter((each) => each.card_expiry_date);
      if (temp_card.length) {
        setCardList(temp_card.length);
      }
      if (temp_ach.length) {
        setIsAch(true);
      }
    }
  };

  useEffect(() => {
    if (props?.isRetailSale) {
      getInvoiceDetailsApi(props?.record?.invoice_address?.uuid);
      addNewInvoice();
    }
  }, [props?.isRetailSale]);

  const getVoidInvoice = async (uuid) => {
    setLoader(true);
    const data = await voidInvoiceApi(uuid);
    if (data?.message !== undefined) {
      message.error(data.message, 10);
    } else {
      message.success("Void Invoice Successfully");
      getInvoiceList();
      getOverdueOpen();
    }
    setLoader(false);
  };

  const sendEmailInvoice = async (uuid) => {
    const data = await sendInvoice(uuid);
    if (!isEmpty(data)) {
      message.success(data[1], 10);
    } else {
      message.error("Something went wrong", 10);
    }
    setLoader(false);
  };

  const getOverdueOpen = async () => {
    const data = await getOverdueOpenLastInvoices({
      user__uuid: props?.record?.uuid,
    });
    // setInvoiceSummary(data);
    dispatch(add(data));
  };

  const getInvoiceDetailsApi = async (uuid) => {
    if(uuid){
      const data = await getInvoiceDetail(uuid);
      console.log("invoice Details", data);
      setInvoiceDetail(data);
    }
  };

  const getPaymentHistoryApi = async (params) => {
    const data = await getPaymentHistory({ invoice__uuid: params });
    setInvoicePaymentHistory(data);

  };

  useEffect(() => {
    // console.log("props",props.customer,props.record);
    // userBillingInfo();
    getInvoiceList();
    getOverdueOpen();
    userBillingInfo();
  }, []);

  useEffect(() => {
    if (invoiceDetail?.uuid) {
      onChangeView(1);
    }
  }, [invoiceDetail]);

  useEffect(() => { }, [showPaymentHistory]);
  useEffect(() => { }, [showRefund]);

  useEffect(() => { }, [showPaymentHistory]);

  const closeTypeFilter = () => {
    settoggleTypeFilter(false);
  };

  // toggle log payment drawer
  const toggleLog = (value) => {
    setshowLogPayment(value);
    getOverdueOpen();
    setTimeout(() => {
      getInvoiceList();
    }, 1000);
  };
  const toggleRefund = (value) => {
    setshowRefund(value);
    // getInvoiceList();
    // getOverdueOpen();
  };
  // toggle manual log payment drawer
  const toggleManualLog = (value) => {
    setshowManualLogPayment(value);
    setActionValue(null);
  };

  const addNewInvoice = () => {
    setInvoiceDetail({});
    setInvoiceuuid("");
    onChangeView(1);
  };

  const printInvoice = async (invoice_uuid) => {
    setLoader(true);
    const response = await printInvoiceRequest(invoice_uuid);
    let byteCharacters = atob(response.pdf_data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    setLoader(false);
    window.open(fileURL, "_blank");
  };

  const renderPrint = (invoice_uuid) => {
    return (
      <a
        target="_blank"
        href={`${config.getHost()}invoice/${invoice_uuid}/render/?action=file`}
        rel="noreferrer"
      >
        Print
      </a>
    );
  };

  // toggle delete modal
  const toggleDeleteModal = () => {
    setShowDelete(!showDelete);
  };

  // delete invoice
  const onDeleteInvoice = async () => {
    setLoader(true);
    const data = await deleteInvoice({ multiple_invoices_uuid: invoice_uuid });
    if (
      !isEmpty(data) &&
      data?.message?.toLowerCase() === "invoice deleted successfully"
    ) {
      getInvoiceList();
      getOverdueOpen();
      message.success(data.message, 10);
    } else {
      message.error(data.message, 10);
    }
    setLoader(false);

    setTimeout(() => {
      toggleDeleteModal();
    }, 1000);
  };

  const changeAction = (value) => {
    switch (value) {
      case "payment":
        setshowManualLogPayment(true);
        setActionValue(value);
        // this.setState({showManualLogPayment: true, actionValue: value}, () => this.toggleManualLog(true));
        break;
      default:
        break;
    }
  };

  const addNewInvoiceHandle = () => {
    getInvoiceDetailsApi(invoice_uuid?.uuid);
    addNewInvoice();
  }

  const renderList = () => {
    return (
      <TypeOutsideAlerter closeTypeFilter={closeTypeFilter}>
        <div>
          <div className="flex">
            <div className="inleft flex">
              <div className="tblock">
                <div className="trow">
                  <b>Open Invoice Balance</b>
                  <b className="tcell">
                    $
                    {!isEmpty(invoiceSummary?.open_invoices_amount?.total__sum)
                      ? formatToCurrency(
                        invoiceSummary?.open_invoices_amount?.total__sum
                      )
                      : "0.00"}
                  </b>
                </div>
                <div className="trow">
                  <span>Credit Balance</span>
                  <span>
                    $
                    {!isEmpty(invoiceSummary?.credit_memo_balance)
                      ? formatToCurrency(invoiceSummary?.credit_memo_balance)
                      : "0.00"}
                  </span>
                </div>
                <div className="trow">
                  <span>Pre-pay Balance</span>
                  <span>
                    $
                    {invoiceSummary?.pre_pay_amount
                      ? formatToCurrency(invoiceSummary?.pre_pay_amount)
                      : "0.00"}
                  </span>
                </div>
                <div className="trow">
                  <span>Paid to Date</span>
                  <span>
                    $
                    {!isEmpty(invoiceSummary?.paid_to_date)
                      ? formatToCurrency(invoiceSummary?.paid_to_date)
                      : "0.00"}
                  </span>
                </div>
              </div>
              <div className="tblock">
                <b className="mb-10 d-block">
                  {invoiceSummary?.open_invoices_count || 0} Invoices.{" "}
                  <span className="danger">
                    <u>{invoiceSummary?.overdue_invoices_count || 0} Overdue</u>
                  </span>{" "}
                </b>
              </div>
            </div>
            <div className="inright flex flex-end">
              <div className={props.record?.is_active ? "flex flex-column dropdown-sel" : "flex flex-column dropdown-sel disable-field"}>
                <Select
                  onChange={changeAction}
                  className="btn-bdr dark-background swhite"
                  disabled="true"
                  value={actionValue ? actionValue : "Action"}
                  placeholder="Action"
                  style={{ width: 160 }}
                >
                  <Option value="memo">Create Credit Memo</Option>
                  <Option value="payment">Log Payment</Option>
                  <Option value="refund">Issue Refund</Option>
                </Select>{" "}
              </div>
              <div className={props.record?.is_active ? "flex flex-column ml-30" : "flex flex-column ml-30 disable-field"}>
                <button
                  className={`btn swhite ${1 ? "disabled" : "btn-brand btn-bdr"
                    } ml-0`}
                  disabled={false}
                >
                  Export Statement
                </button>
              </div>
              <div className={props.record?.is_active ? "flex flex-column ml-30" : "flex flex-column ml-30 disable-field"}>
                <button
                  className={`btn btn-warning mb-10`}
                  disabled={false}
                  onClick={addNewInvoiceHandle}
                >
                  Add New Invoice
                </button>
              </div>
            </div>
          </div>
          <div className="inright flex flex-end mb-10">
            <div className="form-group image-input type-border">
              <div className="">
                <input type="text" id="txtStatus" placeholder="Type : All" />
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGBAMAAAAI3hL1AAAAAXNSR0IB2cksfwAAABhQTFRFAAAAHR0iHR0gHBwgHBwgHBwiHBwhHB0hNAFBfQAAAAh0Uk5TADSGiIeJiouH2FuSAAAAKElEQVR4nGMQYGBgYGRQBpJGDCwBDKwODAxuDClALks5kMnA4MLAAAAopwKpSq6gTAAAAABJRU5ErkJggg=="
                  alt=""
                />
              </div>
              {/* <div className="field-dropdown right type-body" style={{minHeight: '125 px !important',}}>
                <div className="field-dropdown-body type-body">
                  <div className="fitem-check">
                    <input id="All" type="checkbox"  value={"null"}  className="fitem-ck-input"/>
                    <label htmlFor="All" className="fitem-ck-txt">All</label>
                  </div>
                  <div className="fitem-check">
                    <input id="paused" type="checkbox" value={"invoice"}  className="fitem-ck-input"/>
                    <label htmlFor="paused" className="fitem-ck-txt">Invoice</label>
                  </div>
                  <div className="fitem-check">
                    <input id="technician_assigned" value={"creditmemo"} type="checkbox" className="fitem-ck-input"/>
                    <label htmlFor="technician_assigned" className="fitem-ck-txt">Credit Memo</label>
                  </div>
                </div>
              </div> */}
            </div>
            <ul className="tabs mwhite ml-30">
              {map(status, (obj, index) => (
                <li key={`li${index}`}>
                  <span>{obj.key}</span>
                </li>
              ))}
            </ul>
          </div>
          <Table
            rowKey={(record) => record.uuid}
            dataSource={invoiceList}
            // pagination={invoicePagination}
            columns={DataTable.InvoiceWeb(renderAction)}
          // onChange={this.onInvoiceChange}
          />
        </div>
      </TypeOutsideAlerter>
    );
  };

  const onActionChange = (
    e,
    invoice_uuid,
    service_order_uuid = "",
    record1
  ) => {
    console.log("refund click");
    setaction(e);
    setactive_action_uuid(invoice_uuid);
    // this.setState({ action: e, active_action_uuid: invoice_uuid }, () => {
    // const { action } = this.state;
    switch (e) {
      case "print":
        printInvoice(invoice_uuid);
        break;
      case "view":
        setInvoiceuuid(invoice_uuid?.uuid);
        getInvoiceDetailsApi(invoice_uuid?.uuid);
        // this.setState({ invoice_uuid, service_order_uuid }, () => this.onChangeView(1))
        break;
      case "email":
        // this.setState({ loader: true }, () => this.props.resendInvoice(invoice_uuid))
        // setTimeout(() => this.setState({ loader: false }), 1000)
        setLoader(true);
        sendEmailInvoice(invoice_uuid);
        break;
      case "log":
        setInvoiceuuid(invoice_uuid);
        setInvoiceRecord(record1);
        toggleLog(true);
        // this.setState({ invoice_uuid }, () => this.toggleLog(true))
        break;

      case "refund":
        setInvoiceuuid(invoice_uuid);
        setInvoiceRecord(record1);
        toggleRefund(true);
        break;
      case "delete":

      // case 'refund':
      //   this.setState({ invoice_uuid,actionIssueRefund:false }, () => this.toggleRefund(true))
      //   break;
      case "delete":
        // if(parseFloat(record1.amount_recieved) > 0) {
        //   message.error("Please select valid invoice")
        // } else {
        setInvoiceuuid(invoice_uuid);
        toggleDeleteModal();
        // }
        break;
      case "void":
        //   this.props.voidInvoice(invoice_uuid)
        getVoidInvoice(invoice_uuid);
        break;
      case "payment_history":
        // console.log("inside payment history");
        // setInvoiceuuid(invoice_uuid);
        onChangeView(3);
        getPaymentHistoryApi(invoice_uuid);
        // setPaymentHistory(true);
        break;
      // case 'view-credit-memo':
      //   this.setState({ creditMemo_uuid:invoice_uuid }, () => this.onChangeView(4));
      //   break;
      default:
        console.log("no action");
    }
    // })
  };

  // render table action
  const renderAction = (
    invoice_uuid = "",
    service_order_uuid = "",
    status = "",
    record1,
    creditMemo_id
  ) => {
    // setInvoiceRecord(record1);
    const { customer } = props;
    const { action, active_action_uuid } = props;
    // const {is_call_center, field_office_uuid_list, is_business_dev_franchiese} = this.getLoggedInUserRole()
    // check that current logged in user field office uuid and selected customer field office uuid is same or not
    // const is_field_office_uuid = field_office_uuid_list?.includes(customer?.field_office_uuid)

    const menu = (
      <Menu className={!props?.record?.is_active && "disable-field"}>
        {/*<Menu.Item key="-1" onClick={() => this.onActionChange('')}>&nbsp;</Menu.Item>*/}
        <Menu.Item key="0" onClick={() => onActionChange("view", record1)}>
          View
        </Menu.Item>
        <Menu.Item
          key="1"
          disabled={record1?.status === "void"}
          onClick={() => onActionChange("print", invoice_uuid)}
        >
          Print
        </Menu.Item>
        <Menu.Item
          key="2"
          disabled={record1?.status === "void"}
          onClick={() => onActionChange("email", invoice_uuid)}
        >
          Email / Resend
        </Menu.Item>
        {/* <Menu.Item key="1" onClick={() => this.onActionChange('view', invoice_uuid, service_order_uuid)}>View/Edit</Menu.Item> */}
        {/* <Menu.Item key="2" disabled={(is_call_center || is_business_dev_franchiese || record?.status === "draft")} onClick={() => this.onActionChange('resend', invoice_uuid)}>Resend</Menu.Item> */}
        <Menu.Item
          key="3"
          disabled={
            parseFloat(record1?.balance_due) <= 0 &&
            (record1?.status === "paid" || record1?.status === "void")
          }
          onClick={() => onActionChange("log", invoice_uuid, "", record1)}
        >
          Log Payment
        </Menu.Item>
        <Menu.Item key="4" disabled={record1?.status === "void"} onClick={() => onActionChange("refund", invoice_uuid, "", record1)}>
          Refund Payment
        </Menu.Item>
        <Menu.Item
          key="5"
          disabled={record1?.status === "void"}
          // disabled={is_call_center || is_business_dev_franchiese}
          onClick={() => onActionChange("delete", invoice_uuid, "", record1)}
        >
          Delete
        </Menu.Item>
        <Menu.Item
          key="6"
          disabled={record1?.status === "void"}
          // disabled={is_call_center || is_business_dev_franchiese}
          onClick={() => onActionChange("void", invoice_uuid, "", record1)}
        >
          Void
        </Menu.Item>
        {/* <Menu.Item key="7" disabled={(is_call_center || is_business_dev_franchiese || record1?.status === "draft")} onClick={() => this.onActionChange('payment_history', invoice_uuid)}>Payment History</Menu.Item> */}
        <Menu.Item
          key="7"
          disabled={record1?.status === "void"}
          onClick={() =>
            onActionChange("payment_history", invoice_uuid, "", record1)
          }
        >
          Payment History
        </Menu.Item>
      </Menu>
    );
    const cm_menu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => onActionChange("view-credit-memo", invoice_uuid)}
        >
          View/Edit
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={creditMemo_id ? cm_menu : menu} trigger={["click"]}>
        <span onClick={(e) => e.preventDefault()}>
          {active_action_uuid === invoice_uuid
            ? creditMemo_id
              ? row_action_credit_memo[action]
              : row_action[action]
            : "Select"}{" "}
          <DownOutlined style={{ marginLeft: "25px" }} />
        </span>
      </Dropdown>
    );
  };

  const onChangeView = (value) => {
    if (value === 0) {
      setInvoiceDetail({});
      getInvoiceList();
    }
    setCurrentTab(value);
  };

  // toggle loader
  const toggleLoader = (value) => {
    setLoader(value);
  };

  const renderViews = () => {
    switch (currentTab) {
      case 0:
        return renderList();
      case 1:
        return (
          <AddNewInvoice
            invoice_uuid={invoice_uuid}
            record={props?.record}
            isRetailSale={props?.isRetailSale}
            // service_order_uuid={service_order_uuid}
            onChangeView={onChangeView}
            customerId={props?.record?.uuid}
            customer={props?.record}
            invoiceDetail={invoiceDetail}
            getOverdueOpen={getOverdueOpen}
          />
        );
      case 3:
        return PaymentHistory();
      default:
        return renderList();
    }
  };

  // const clearRefundTypeValue = (value)=>{
  //   setState({
  //     refundTypeValue:null
  //   })
  // }

  const PaymentHistory = () => {
    return (
      <>
        <ul className="breadcrumb">
          <li
            onClick={() => {
              onChangeView(0);
            }}
          >
            <span className="pointer">
              <i className="las la-angle-left"></i> Back to All Accounts
            </span>
          </li>
        </ul>
        <h2 style={{ padding: "0 25px 0 25px", margin: "0" }}>
          Payment History
        </h2>
        <div className="block-row pt-10">
          <Table
            rowKey="uuid"
            dataSource={invoicePaymentHistory}
            // onChange={this.onHistoryChange}
            // pagination={payment_history_list_pagination}
            columns={DataTable.InvoiceHistory()}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`block-row`}>
      <div className="container">
        {loader && <Loader />}
        {renderViews()}
      </div>
      {showLogPayment && (
        <LogPayment
          customer={props?.record}
          invoice_uuid={invoice_uuid}
          customerId={props?.record?.uuid}
          visible={showLogPayment}
          closeDrawer={toggleLog}
          toggleLoader={toggleLoader}
          manualLog={false}
          invoiceRecord={invoiceRecord}
          getOverdueOpen={getOverdueOpen}
          getInvoiceList={getInvoiceList}
        // API
        // getInvoiceList={this.getInvoiceList}
        // getInvoiceSummary={this.getInvoiceSummary}
        // getCustomers={this.props.getCustomers}
        // addPaymentLog={this.props.addPaymentLog}
        // getCardList={this.props.getCardList}
        // addNewCard={this.props.addNewCard}
        // getStaffUsers={this.props.getStaffUsers}
        // addManualyPayment={this.props.addManualyPayment}
        // Reducers
        // customerList={this.props.CustomerList}
        // cardList={this.props.cardList}
        // addCard={this.props.addCard}
        // customer={this.props.customer}
        // addedLog={this.props.GetManualy}
        // Invoice={this.props.Invoice}
        />
      )}
      {showManualLogPayment && (
        <LogPayment
          // invoice_uuid={invoice_uuid}
          // customerId={customerId}
          visible={showManualLogPayment}
          closeDrawer={toggleManualLog}
          // toggleLoader={this.toggleLoader}
          // updateSummary={this.props.updateSummary}
          // manualLog={true}
          // API
          invoiceRecord={invoiceRecord}
          getOverdueOpen={getOverdueOpen}
          getInvoiceList={getInvoiceList}

        // getInvoiceList={this.getInvoiceList}
        // getInvoiceSummary={this.getInvoiceSummary}
        // getCustomers={this.props.getCustomers}
        // addPaymentLog={this.props.addManualPaymentLog}
        // getCardList={this.props.getCardList}
        // addNewCard={this.props.addNewCard}
        // getStaffUsers={this.props.getStaffUsers}
        // addManualyPayment={this.props.addManualyPayment}
        // addUnattachedPayment={this.props.addUnattachedPayment}
        // Reducers
        // customerList={this.props.CustomerList}
        // cardList={this.props.cardList}
        // addCard={this.props.addCard}
        // customer={this.props.customer}
        // addedLog={this.props.GetManualy}
        // Invoice={this.props.Invoice}
        />
      )}
      {showRefund && (
        <Refund
          customer={props?.record}
          invoice_uuid={invoice_uuid}
          customerId={props?.record?.uuid}
          visible={showLogPayment}
          closeDrawer={toggleRefund}
          toggleLoader={toggleLoader}
          manualLog={false}
          invoiceRecord={invoiceRecord}
          getOverdueOpen={getOverdueOpen}
          actionIssueRefund
          cardList={cardList}
          isAch={isAch}
          // clearRefund ={clearRefundTypeValue}
          //getStaffUsers={props.getStaffUsers}

          //
          invoiceList={invoiceList}
          getInvoiceList={getInvoiceList}
          getInvoiceById={props.getInvoiceById}
          // getInvoiceSummary={getInvoiceSummary}
          refundTypeValue="invoice"
          refundPayment={props.refundPayment}
          List={state.list}
        // cardList ={state.cardList}
        //actionValue={state.actionValue}
        //refundTypeValue={state.refundTypeValue}
        //remainingCreditBalance={props.remainingCreditBalance}
        />
      )}
      <Modal
        title="Delete Customer"
        visible={showDelete}
        onCancel={() => toggleDeleteModal()}
        className="delete_user_modal"
        width={400}
        footer={[
          <button
            key="cancel"
            className="btn btn-brand btn-bdr"
            onClick={() => toggleDeleteModal()}
          >
            No
          </button>,
          <button
            key="upload"
            className={`btn btn-waning`}
            onClick={() => onDeleteInvoice()}
          >
            Yes
          </button>,
        ]}
      >
        <div>
          <h2> Are You Sure! You want to delete selected Invoice! ?</h2>
        </div>
      </Modal>
      {/* {showPaymentHistory && (<PaymentHistory/>)} */}
    </div>
  );
};

export default Invoices;
