const {createSlice} = require('@reduxjs/toolkit');

const initialState = {
    fieldTechnicians : []
};

const fieldTechnicianListSlice = createSlice({
    name:'fieldTechniciansResponse',
    initialState,
    reducers:{
        fieldTechniciansData(state, action) {
            return (state = {
              ...state,
              fieldTechnicians: action.payload,
            });
          },
    }
})

export const { fieldTechniciansData } = fieldTechnicianListSlice.actions;
export default fieldTechnicianListSlice.reducer;