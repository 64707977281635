import React, { useState } from "react";
import { Checkbox } from "antd";

const CheckboxGroup = Checkbox.Group;

const StatusSelector = ({ onControlChange = () => {} }) => {
  const [selectedStatus, setSelectedStatus] = useState([
    "complete",
    "incomplete",
    "scheduled",
    "forecast",
    "cancelled",
  ]);

  return (
    <div className="statusSelector">
      <span className="statusSelectorTitle">Status:</span>
      <CheckboxGroup
        defaultValue={selectedStatus}
        onChange={(selection) => {
          setSelectedStatus(selection);
          onControlChange(selection);
        }}
      >
        <Checkbox value={"complete"} className="status_complete">
          Completed
        </Checkbox>
        <Checkbox value={"incomplete"} className="status_incomplete">
          Incomplete
        </Checkbox>
        <Checkbox value={"scheduled"} className="status_scheduled">
          Scheduled
        </Checkbox>
        <Checkbox value={"forecast"} className="status_forecast">
          Forecast
        </Checkbox>
        <Checkbox value={"cancelled"} className="status_cancelled">
          Cancelled
        </Checkbox>
      </CheckboxGroup>
    </div>
  );
};

export default StatusSelector;
