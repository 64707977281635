import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Drawer, message, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { map, orderBy, filter, isEmpty, cloneDeep } from "lodash";
import plus_icon from "../../../Assets/images/plus_icon.svg";
import cross_icon from "../../../Assets/images/cross_icon.svg";
import * as yup from "yup";
import Loader from "../../../Components/Loader";
import Script from "react-load-script";
import AllState from "../../../Assets/data/stateList.json";
import Axios from "axios";
import { updateCustomerCommercial } from "../../../Action/Customer/index";
const { Option } = Select;
const REGULAR_EXPRESSION_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let updateClick = false;

const notificationOrderList = [
  { key: "technician_assigned", index: 0 },
  { key: "technician_enroute", index: 1 },
  { key: "service_complete", index: 2 },
  { key: "service_issues", index: 3 },
  { key: "billing_notifications", index: 4 },
];

// class UpdateContact extends Component {
const UpdateContact = forwardRef((props, ref) => {
  const [addressStr, setAddressStr] = useState("");
  const [isAddressManually, setIsAddressManually] = useState(false);
  const [state, setState] = useState({
    phone_numbers: [],
    emails: [],
    org_phone_numbers: [],
    org_emails: [],
    phone: "",
    email: "",
    sms: "",
    uuid: "",
    is_btn_active: false,
    loader: false,
    notificationList: [],
    first_name: props.customer?.first_name,
    last_name: props.customer?.last_name,
    address: {},
    is_print_and_mail_invoices: props.customer?.is_print_and_mail_invoices,
    is_email_invoice: props.customer?.is_email_invoice,
    errors: {},
    is_address_manually: false,
    is_dummy_email: false,
    companyName: "",
    special_notes: props?.customer?.special_notes
  });

  function getState() {
    setState({
      phone_numbers: [],
      emails: [],
      org_phone_numbers: [],
      org_emails: [],
      phone: "",
      email: "",
      sms: "",
      uuid: "",
      is_btn_active: false,
      loader: false,
      notificationList: [],
      first_name: props.customer?.first_name,
      last_name: props.customer?.last_name,
      address: {},
      is_print_and_mail_invoices: props.customer?.is_print_and_mail_invoices,
      is_email_invoice: props.customer?.is_email_invoice,
      errors: {},
      is_address_manually: false,
      is_dummy_email: false,
      companyName: props.customer.company_name,
      special_notes: props?.customer?.special_notes
    });
  }
 
  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (props?.customer && Object.keys(props?.customer).length > 0) {
      let temp = props?.customer?.invoice_address?.address
        ? `${props?.customer?.invoice_address?.address}`
        : "";
      temp += props?.customer?.invoice_address?.city
        ? `, ${props?.customer?.invoice_address?.city}`
        : "";
      temp += props?.customer?.invoice_address?.state
        ? `, ${props?.customer?.invoice_address?.state}`
        : "";
      temp += props?.customer?.invoice_address?.zip_code
        ? `, ${props?.customer?.invoice_address?.zip_code}`
        : "";
      setAddressStr(temp);
      address_ref.current.value = temp;
      const phone_numbers =
        props?.customer?.phone_numbers?.length > 0
          ? orderBy([...props?.customer?.phone_numbers], "primary", "desc")
          : [
              {
                phone: "",
                type: "home_phone",
                can_send_sms: false,
                primary: true,
              },
            ];
      const emails =
        props?.customer?.emailaddress_set?.length > 0
          ? orderBy([...props?.customer?.emailaddress_set], "primary", "desc")
          : [{ email: "", primary: true }];
      setState((each) => ({
        ...each,
        phone_numbers: phone_numbers,
        emails: emails,
        address: {
          address: props?.customer?.invoice_address?.address,
          state: props?.customer?.invoice_address?.state,
          city: props?.customer?.invoice_address?.city,
          zip_code: props?.customer?.invoice_address?.zip_code,
        },
      }));
    }
  }, [props]);

  useEffect(() => {
    if (isAddressManually) {
      address_ref.current.value = "";
    }
  }, [isAddressManually]);

  useEffect(() => {
    let { address } = state;
    let temp = address?.address ? `${address?.address}` : "";
    temp += address?.city ? `, ${address?.city}` : "";
    temp += address?.state ? `, ${address?.state}` : "";
    temp += address?.zip_code ? `, ${address?.zip_code}` : "";
    if (temp) {
      address_ref.current.value = temp;
      // setAddressStr(temp);
    }
  }, [state]);

  const address_ref = useRef({});
  // let addressStr = props?.record?.invoice_address?.address ? `${props?.record?.invoice_address?.address}` : '';
  // addressStr += props?.record?.invoice_address?.city ? `, ${props?.record?.invoice_address?.city}` : '';
  // addressStr += props?.record?.invoice_address?.state ? `, ${props?.record?.invoice_address?.state}` : '';
  // addressStr += props?.record?.invoice_address?.zip_code ? `, ${props?.record?.invoice_address?.zip_code}` : '';
  // console.log("addressStr", addressStr)
  // if (addressStr && !isAddressManually) {
  //   address_ref.current.value = addressStr || '';
  // }

  // genarate gibberrish email address
  const genarateGibberrishEmail = () => {
    let newEmail = [{ email: "", primary: true }];
    newEmail[0].email = create_UUID() + "@doody.com";
    setState((each) => ({
      ...each,
      emails: newEmail,
      is_dummy_email: true,
    }));
  };

  const create_UUID = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : r & (0x3 | 0x8)).toString(16);
      }
    );
    return uuid;
  };

  // set address
  const setAddress = (addressObject) => {
    if (!isEmpty(addressObject)) {
      if (addressObject?.status === "OK") {
        let obj = {},
          components = addressObject?.results?.[0];
        map(components?.address_components, (item) => {
          if (item?.types?.includes("administrative_area_level_2")) {
            obj = Object.assign(obj, { address: item.short_name });
          }
          if (item?.types?.includes("route")) {
            obj = Object.assign(obj, { route: item.short_name });
          }
          if (item?.types?.includes("street_number")) {
            obj = Object.assign(obj, { street_number: item.short_name });
          }
          if (item?.types?.includes("administrative_area_level_1")) {
            obj = Object.assign(obj, { state: item.short_name });
          }
          if (item?.types?.includes("locality")) {
            obj = Object.assign(obj, { city: item.long_name });
          }
          if (item?.types?.includes("postal_code")) {
            obj = Object.assign(obj, { zip_code: item.short_name });
          }
        });
        setState({ address: obj }, () => {
          if (!obj?.address || !obj?.state || !obj.city) {
            message.error("Please enter valid address");
          }
        });
      } else {
        message.error("Something went wrong while getting address");
      }
    }
  };

  // format phone number
  // const formatPhoneNumber = (phone) => {
  //   if (!phone) return "";
  //   phone = phone?.replace(/\D/g, "");
  //   return (
  //     phone?.substr(0, 3) + " " + phone?.substr(3, 3) + " " + phone?.substr(6)
  //   );
  // };

  //on change
  const onChange = (row) => {
    const { emails } = state;
    emails.splice(row, 1);
    setState((each) => ({ ...each, emails: emails }));
  };

  // on primary email changed
  const onEmailPrimaryChanged = (item) => {
    const { emails } = state;
    const index = emails.findIndex((obj) => obj.email === item.email);
    emails.splice(index, 1);
    setState((each) => ({ ...each, emails: emails }));
  };

  // on text change
  const onTextChange = (e) => {
    let lowercase_email = e.target.value;
    if(e.target.name === 'email'){
      lowercase_email = lowercase_email.toLowerCase();
    }
    setState((each) => ({ ...each, [e.target.name]: lowercase_email}));
  };

  // on print invoice change
  const onPrintInvoiceChange = (e) => {
    setState((each) => ({
      ...each,
      is_print_and_mail_invoices: e.target.checked,      
    }));
  };

  // on email invoice change
  const onEmailInvoiceChange = (e) => {
    if (e.target.checked) {
      setState((each) => ({
        ...each,
        is_email_invoice: e.target.checked,
      }));
    } else {
      setState((each) => ({
        ...each,
        is_email_invoice: e.target.checked,
      }));
    }
  };

  const isEmailDuplicate = (emailList, email) => {
    let result = false;
    emailList.forEach((each) => {
      if (each.email === email) {
        result = true;
      }
    });
    return result;
  };

  //on add
  const onAdd = (name, type) => {
    const { phone_numbers, emails, phone, sms, email } = state;
    if (name === "phone") {
      phone_numbers.push({
        phone: type === "phone" ? phone : sms,
        can_send_sms: false,
        primary: false,
      });
      setState(
        (each) => ({ ...each, phone_numbers: phone_numbers }),
        () => onClear()
      );
    }
    // else {

    //   let NewEmails = emails && emails.length && emails.filter(email => {
    //     return !email.email.includes('doody.com');
    //   });
    //   NewEmails.push({ email, primary: NewEmails?.length === 0 });
    //   emails.push({ NewEmails, primary: false });
    //   setState((each) => ({
    //     ...each,
    //     emails: NewEmails, is_dummy_email: false
    //   }, () => { onClear() }));
    // }
    if (name === "email") {
      let addedEmails = [];
      let obj = {};
      if (emails.length === 0) {
        obj = { email: email.toLowerCase(), primary: true };
        addedEmails = [...emails, obj];
      } else {
        if (!isEmailDuplicate(emails, email)) {
          obj = { email: email.toLowerCase(), primary: false };
          addedEmails = [...emails, obj];
        } else {
          addedEmails = [...emails];
          message.error("This Email is already Exist");
        }
      }
      setState((each) => ({ ...each, emails: addedEmails }));
      setState((each) => ({ ...each, email: "" }));
    }
  };
  
  // on clear
  const onClear = () => {
    setState((each) => ({
      ...each,
      phone: "",
      email: "",
      sms: "",
      address: {},
    }));
  };

  // on clear
  const onClearAll = () => {
    setState((each) => ({
      ...each,
      phone: "",
      email: "",
      sms: "",
      notificationList: [],
      first_name: "",
      last_name: "",
      address: {},
    }));
  };

  const onNotificationChanged = (e, permissionType) => {
    const { notificationList } = state;

    const idx = notificationList?.findIndex(
      (obj) => obj.type === permissionType
    );
    if (idx > -1) {
      notificationList[idx] = Object.assign(notificationList[idx], {
        [e.target.name]: e.target.checked,
      });
      setState((each) => ({
        ...each,
        notificationList: notificationList,
      }));
    }
  };

  const onUpdate = async (id, params) => {
    try{
      const { closeDrawer, updateNotificationById } = props;
      const data = await updateCustomerCommercial(id, params);
      if (data) {
        message.success("Updated Contact Detail Successfully");
      }
      setState((each) => ({
        ...each,
        loader: false,
      }));
      closeDrawer(false, true);
      address_ref.current.value = "";
    }catch(err)
    {
      console.log(err)
    }
   
  };

  // on update
  const onUpdateClick = () => {
    const { closeDrawer, updateNotificationById } = props;
    const {
      phone_numbers,
      emails,
      uuid,
      notificationList,
      first_name,
      last_name,
      address,
      is_print_and_mail_invoices,
      is_email_invoice,
      is_dummy_email,
      companyName,
      special_notes
    } = state;
    setState((each) => ({
      ...each,
      loader: true,
    }));
    let isPrimaryExist = false;

    filter(phone_numbers, (obj) => {
      if (obj.primary) {
        isPrimaryExist = true;
      }
    });

    if (!isPrimaryExist) {
      phone_numbers[0].primary = true;
    }

    const list = notificationList?.map((x) => ({
      uuid: x.uuid,
      email: x?.email,
      text: x?.text,
    }));
    setTimeout(() => {
      onUpdate(props?.customer?.uuid, {
        phone_numbers,
        emailaddress_set: emails,
        is_dummy_email,
        first_name,
        last_name,
        invoice_address:
          address?.street_number && address?.route
            ? {
                ...address,
                address: `${address?.street_number} ${address?.route}`,
              }
            : address,
        is_print_and_mail_invoices: is_print_and_mail_invoices,
        is_email_invoice: is_email_invoice,
        company_name_set: companyName,
        special_notes
      });
      // updateNotificationById(list);
      closeDrawer(false, false);
      onClearAll();
    }, 1000);
  };

  //on cancel
  const onCancel = () => {
    const { closeDrawer } = props;
    const { org_phone_numbers, org_emails } = state;
    setState((each) => ({
      ...each,
      phone_numbers: org_phone_numbers,
      emails: org_emails,
    }));
    closeDrawer(false, false);
    onClearAll();
  };

  // validate numeric input
  const onNumericInputChange = (value) => {
    const reg = /^-?\d*(\.\d*)?$/;
    console.log(
      (!isNaN(value) && reg.test(value)) || value === "" || value === "-"
    );
    return (!isNaN(value) && reg.test(value)) || value === "" || value === "-";
  };

  // on phone number change
  const onPhoneChange = (e, index) => {
    const { phone_numbers } = state;
    let pNumbers =  cloneDeep(phone_numbers);
    pNumbers[index][e.target.name] = e.target.value;
    if (e.target.name === "type" && e.target.value === "home_phone") {
      pNumbers[index]["can_send_sms"] = false;
    }
    setState((each) => ({
      ...each,
      phone_numbers: pNumbers,
    }));
  };

  //Add multiple phone number
  const addMorePhone = (e) => {
    e.preventDefault();
    let { phone_numbers } = state;
    let temp_phone = { phone: "", type: "home_phone", can_send_sms: false };
    phone_numbers.push(temp_phone);
    setState((each) => ({
      ...each,
      phone_numbers: phone_numbers,
    }));
  };

  //remove phone number
  const removePhone = (e, index) => {
    e.preventDefault();
    let { phone_numbers } = state;
    phone_numbers.splice(index, 1);
    setState((each) => ({
      ...each,
      phone_numbers: phone_numbers,
    }));
  };

  const handleSpecialNotes = (e) => {
    setState((each) => ({
      ...each,
      special_notes: e.target.value
    }))
  }

  // validate required fields
  const validate = () => {
    console.log("UPDATE state-->> ", state);
    const {
      phone_numbers,
      emails,
      first_name,
      last_name,
      address,
      is_print_and_mail_invoices,
      is_email_invoice,
      companyName,
    } = state;
    const schema = yup.object().shape({
      first_name: yup.string().required("First name is required."),
      last_name: yup.string().required("Last name is required."),
      address: yup.object().shape({
        address: is_print_and_mail_invoices
          ? yup.string().required("address is required.")
          : "",
        city: is_print_and_mail_invoices
          ? yup.string().required("city is required.")
          : "",
        state: is_print_and_mail_invoices
          ? yup.string().required("state is required.")
          : "",
        zip_code: is_print_and_mail_invoices
          ? yup.string().required("zip code is required.")
          : "",
      }),
      phone_numbers: yup
        .array(
          yup.object({
            phone: yup.string().required("Phone is required"),
            type: yup.string().required("Type is required"),
          })
        )
        .required("Phone number is required"),
      emails: yup
        .array(
          yup.object({
            email: yup.string().required("Email is required"),
          })
        )
        .required("Email Address is required"),
      companyName: yup.string().required("Company name is required."),
    });
    const data = {
      phone_numbers,
      emails,
      first_name,
      last_name,
      address,
      is_print_and_mail_invoices,
      is_email_invoice,
      companyName,
    };
    setTimeout(() => {
      schema
        .validate(data, { abortEarly: false })
        .then(() => {
          setState((each) => ({
            ...each,
            is_btn_active: true,
            errors: {},
          }));
          onUpdateClick();
        })
        .catch((err) => {
          let err_data = {};
          map(err.inner, (item) => {
            err_data[item.path] = item.message;
          });
          setState((each) => ({
            ...each,
            is_btn_active: false,
            errors: err_data,
          }));
          message.error("Please enter valid invoice address");
        });
    }, 500);
  };

  // render phone numbers
  const renderPhone = (item, index) => {
    return (
      <div key={`phone_update${index}`} className="form-group">
        <div className={"half-fitem fitem"}>
          <div className="half-fitem fitem">
            <input
              type="text"
              name={"phone"}
              value={item?.phone?.replace(/\D/g, '') || ''}
              onChange={(e) =>
                onNumericInputChange(e.target.value) && onPhoneChange(e, index)
              }
              className="form--control"
              placeholder="Phone Number"
              max={10}
              maxLength={10}
            />
          </div>
          <div className={"half-fitem fitem flex ai-center"}>
            <Select
              defaultValue={"home_phone"}
              showArrow
              name={"type"}
              virtual={false}
              style={{ width: "100%" }}
              value={item?.type}
              onChange={(e) =>
                onPhoneChange({ target: { name: "type", value: e } }, index)
              }
            >
              <Option value="home_phone">Home</Option>
              <Option value="mobile">Mobile</Option>
            </Select>
            {index === 0 ? (
              <a
                href="/#"
                onClick={(e) => {
                  addMorePhone(e);
                }}
              >
                <img className="icoimg" src={plus_icon} alt="" />
              </a>
            ) : (
              <a
                href="/#"
                onClick={(e) => {
                  removePhone(e, index);
                }}
              >
                <img className="icoimg" src={cross_icon} alt="" />
              </a>
            )}
          </div>
        </div>
        {item?.type === "mobile" ? (
          <div className="fitem-check">
            <input
              type="checkbox"
              name="can_send_sms"
              className="fitem-ck-input"
              checked={item?.can_send_sms}
              id={`sendSMS${index}`}
              onChange={(e) =>
                onPhoneChange(
                  { target: { name: "can_send_sms", value: e.target.checked } },
                  index
                )
              }
            />
            <label htmlFor={`sendSMS${index}`} className="fitem-ck-txt">
              Accept text messages at this number
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderNotification = (item, index) => {
    return (
      <div className={"flex ai-center mb-10"} key={`notification${index}`}>
        <label>
          <b>{item?.type_display}</b>
        </label>
        <div className="inline-check-list rcheck flex sm-checks gcheck">
          <div className="fitem-check">
            <input
              type="checkbox"
              className="fitem-ck-input"
              id={`custEmail${index}`}
              name={`email`}
              checked={item?.email}
              onChange={(e) => onNotificationChanged(e, item?.type)}
            />
            <label htmlFor={`custEmail${index}`} className="fitem-ck-txt" />
          </div>
          <div
            className="fitem-check"
            style={{ marginLeft: "22px", marginRight: "5px" }}
          >
            <input
              type="checkbox"
              className="fitem-ck-input"
              id={`custText${index}`}
              name={`text`}
              checked={item?.text}
              onChange={(e) => onNotificationChanged(e, item?.type)}
            />
            <label htmlFor={`custText${index}`} className="fitem-ck-txt" />
          </div>
        </div>
      </div>
    );
  };

  // // validate numeric input
  // const onNumericInputChange = (value) => {
  //   const reg = /^-?\d*(\.\d*)?$/;
  //   return ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
  // };

  // on address change
  const onAddressChange = (e) => {
    const { address } = state;
    Object.assign(address, { [e.target.name]: e.target.value });
    setState((each) => ({
      ...each,
      address: address,
    }));
  };

  // render all state
  const renderAllState = () => {
    return map(AllState?.AllState, (item, i) => {
      return (
        <Option value={item.abbreviation} key={i}>
          {item.name}
        </Option>
      );
    });
  };

  // Google Map Place API
  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: "us" } };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const newAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_address"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    newAutoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);

    // Fire Event when a suggested name is selected
    newAutoComplete.addListener("place_changed", handlePlaceSelect);
  };
  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null && v?.trim() !== "")
    );
  };
  // Close mannual address field
  const handleAddressManuallyRemove = (e) => {
    e.preventDefault();
    if (!address_ref.current.value) {
      address_ref.current.value = addressStr;
    }
    setIsAddressManually(false);
    setState((each) => ({
      ...each,
      address: { address: "", state: "", city: "", zip_code: "" },
    }));
  };
  //clean Invoice address field once mannual address field opens
  const handleAddressManually = (e) => {
    e.preventDefault();
    setIsAddressManually(true);
    setState((each) => ({
      ...each,
      address: { address: "", state: "", city: "", zip_code: "" },
    }));
  };

  // on start place change
  const handlePlaceSelect = () => {
    const address = document?.getElementById("google_address")?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      }),
    })
      .then((res) => {
        const addressObject = res?.data?.results[0];
        let obj = {};
        map(addressObject?.address_components, (item) => {
          if (item?.types?.includes("administrative_area_level_2")) {
            obj = Object.assign(obj, { address: item.short_name });
          }
          if (item?.types?.includes("route")) {
            obj = Object.assign(obj, { route: item.long_name });
          }
          if (item?.types?.includes("street_number")) {
            obj = Object.assign(obj, { street_number: item.short_name });
          }
          if (item?.types?.includes("administrative_area_level_1")) {
            obj = Object.assign(obj, { state: item.short_name });
          }
          if (item?.types?.includes("locality")) {
            obj = Object.assign(obj, { city: item.long_name });
          }
          if (item?.types?.includes("postal_code")) {
            obj = Object.assign(obj, { zip_code: item.short_name });
          }
          // location = { lat: addressObject.geometry.location.lat(), lng: addressObject.geometry.location.lng() }
        });
        obj.address = `${obj?.street_number || ""} ${obj?.route || ""}`;
        setState((each) => ({ ...each, address: obj }));
        // , () => {
        if (!obj?.address || !obj?.state || !obj.city) {
          message.error("Please enter valid address");
        }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    // setState({address: obj}, () => {
    // const address_obj = (obj?.street_number || obj?.route) ? {...obj, address: `${obj?.street_number || ''} ${obj?.route || ''}`} : obj
    // const address = `${address_obj.address} ${address_obj.city} ${address_obj.state} ${address_obj.zip_code}`
    // props.resetGeocodeAddress()
    // props.getGeocodeAddress({key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY, address})
  };

  // return (
  const { openDrawer } = props;
  const {
    is_dummy_email,
    emails,
    phone_numbers,
    email,
    loader,
    notificationList,
    first_name,
    last_name,
    errors,
    address,
    is_address_manually,
    is_print_and_mail_invoices,
    is_email_invoice,
    companyName,
  } = state;
  return (
    <Drawer
      placement={"right"}
      closable={false}
      forceRender
      afterOpenChange={() => handleScriptLoad()}
      onClose={() => onCancel()}
      visible={openDrawer}
      key={"right"}
      width={"400"}
      className="drawer-wrapper"
    >
      <div className="rdrawer filter_drawer_wrapper">
        {loader && <Loader />}
        <div className="card-row">
          <div className="card-full">
            <div className="flex ai-center">
              <h4 className="mb-0">Update Contact Details</h4>
              <CloseOutlined onClick={() => onCancel()} />
            </div>

            {/* <h5 className="mt-30">{`Customer's Name`}</h5> */}
            <div className={"flex"}>
              <div className={"half-fitem fitem"}>
                <h5 className="mt-30">{`First Name`}</h5>
                <input
                  name="first_name"
                  type="text"
                  placeholder={"First Name"}
                  value={first_name}
                  onChange={(e) => onTextChange(e)}
                />
                {errors?.["first_name"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["first_name"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div
                className={"half-fitem fitem"}
                style={{ marginLeft: "10px" }}
              >
                <h5 className="mt-30">{`Last Name`}</h5>
                <input
                  name="last_name"
                  type="text"
                  placeholder={"Last Name"}
                  value={last_name}
                  onChange={(e) => onTextChange(e)}
                />
                {errors?.["last_name"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["last_name"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>

            <h5 className=" mt-30">Phone Number</h5>
            {map(phone_numbers, renderPhone)}

            {/* Emails */}
            <h5 className=" mt-30">Email Addresses</h5>
            {map(emails, (item, index) =>
              item?.email && !is_dummy_email ? (
                <div className="fitem-check" key={`checkboxEmail${index}`}>
                  {item?.primary ? (
                    <input
                      type="checkbox"
                      className="fitem-ck-input"
                      id={`checkboxEmail${index}`}
                      name={`email${index}`}
                      checked={item?.primary}
                      readOnly
                    />
                  ) : (
                    ""
                  )}
                  <label
                    htmlFor={`checkboxEmail${index}`}
                    className="fitem-ck-txt black text-lowercase"
                  >
                    {`${item?.email?.toLowerCase()} ${
                      item?.primary ? "(Primary)" : ""
                    }`}
                  </label>
                  {!item?.primary ? (
                    <a
                      className="red float-right"
                      href={"/#"}
                      onClick={(e) => {
                        e.preventDefault();
                        onEmailPrimaryChanged(item);
                      }}
                    >
                      Remove
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )
            )}
            <div className="fitem fitem-rinner mt-10">
              <input
                name="email"
                type="text"
                placeholder={"Add New Email Address"}
                value={email}
                onChange={(e) => onTextChange(e)}
              />
              <button
                className={`btn fitem-ricon ${
                  !REGULAR_EXPRESSION_EMAIL.test(email)
                    ? "disabled"
                    : "btn-warning"
                }`}
                disabled={!REGULAR_EXPRESSION_EMAIL.test(email)}
                onClick={() => onAdd("email")}
              >
                <PlusOutlined />
              </button>
            </div>
            {/* { is_print_and_mail_invoices && */}
            <div className="fitem">
              <h5 className=" mt-10">Company Name</h5>
              <input
                name="companyName"
                type="text"
                placeholder={"Company Name"}
                value={companyName}
                onChange={(e) => onTextChange(e)}
              />
              {errors?.["companyName"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle"></i>
                  {errors["companyName"]}
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="fitem">
              <h5 htmlFor="address" className=" mt-10">
                Billing Address
              </h5>
              <div className="google_search_place">
                <Script
                  url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`}
                  onLoad={handleScriptLoad}
                />
                <input
                  autoComplete="off"
                  type="text"
                  ref={address_ref}
                  placeholder={"Search for Invoice Address"}
                  name="google_address"
                  id="google_address"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setState((each) => ({
                        ...each,
                        address: {
                          address: "",
                          state: "",
                          city: "",
                          zip_code: "",
                        },
                      }));
                    }
                  }}
                  disabled={isAddressManually}
                />
                <b>
                  or
                  <a href="/" onClick={(e) => handleAddressManually(e)}>
                    <u>enter address manually</u>
                  </a>
                </b>
              </div>
              {isAddressManually && (
                <div className="manually_address">
                  <a href="/#" onClick={(e) => handleAddressManuallyRemove(e)}>
                    <img
                      src={cross_icon}
                      alt=""
                      className="float-right mb-10"
                    />
                  </a>
                  <br />
                  <div className="form-group mb-20">
                    <div className="fitem">
                      <input
                        type="text"
                        placeholder="Address"
                        name="address"
                        value={address?.address}
                        onChange={(e) => onAddressChange(e)}
                      />
                    </div>
                    <div className="half-fitem fitem">
                      <div className="half-fitem fitem">
                        <input
                          type="text"
                          placeholder="City"
                          name="city"
                          value={address?.city}
                          onChange={(e) => onAddressChange(e)}
                        />
                      </div>
                      <div className="half-fitem fitem">
                        <Select
                          showArrow={true}
                          name="state"
                          placeholder="State"
                          onChange={(value) =>
                            onAddressChange({
                              target: { name: "state", value: value },
                            })
                          }
                          value={address?.state || undefined}
                          virtual={false}
                        >
                          {renderAllState()}
                        </Select>
                      </div>
                      <div className="half-fitem fitem">
                        <input
                          type="text"
                          className="form--control"
                          placeholder="ZIP Code"
                          name="zip_code"
                          maxLength={5}
                          minLength={5}
                          onChange={(e) =>
                            onNumericInputChange(e.target.value) &&
                            onAddressChange(e)
                          }
                          value={address?.zip_code || ""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* } */}
            <div className="fitem-check mt-10">
              <input
                type="checkbox"
                className="fitem-ck-input"
                id={`printInvoice`}
                name={`is_print_and_mail_invoices`}
                checked={is_print_and_mail_invoices}
                onChange={(e) => onPrintInvoiceChange(e)}
              />
              <label htmlFor={`printInvoice`} className="fitem-ck-txt">
                Print and Mail Invoice
              </label>
            </div>
            <div className="fitem-check mt-10">
              <input
                type="checkbox"
                className="fitem-ck-input"
                id={`emailInvoice`}
                name={`is_email_invoice`}
                checked={is_email_invoice}
                onChange={(e) => onEmailInvoiceChange(e)}
              />
              <label htmlFor={`emailInvoice`} className="fitem-ck-txt">
                Email invoices
              </label>
            </div>
            <div className="form-group mt-30">
              <div className="fitem">
                <label
                  htmlFor="special_notes"
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                >
                  Special Notes
                </label>
                <textarea
                  id="special_notes"
                  rows="5"
                  cols="48"
                  autoComplete="off"
                  value={state.special_notes}
                  onChange={ handleSpecialNotes}
                />
              </div>
            </div>
            <div className="card-body mt-20">
              <label>Notifications</label>
              <div className="fitem-check text-right">
                <label htmlFor="checkbox" className="fitem-ck-txt text-right">
                  <b>Email</b>
                </label>
                <label
                  htmlFor="checkbox"
                  className="fitem-ck-txt text-right"
                  style={{ marginLeft: "22px" }}
                >
                  <b>Text</b>
                </label>
              </div>

              {map(notificationList, renderNotification)}
            </div>
          </div>
        </div>
        <div className="footer">
          <button className={`btn btn-bdr`} onClick={() => onCancel()}>
            Cancel
          </button>
          <button
            // type="submit"
            // disabled={!is_btn_active}
            className={`btn btn-warning`}
            onClick={() => validate()}
          >
            Update
          </button>
        </div>
      </div>
    </Drawer>
  );
});
export default UpdateContact;
