import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { map } from "lodash";
import { message, Checkbox, Button, Table, Select, Modal } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";

import StatusOutsideAlerter from "../../Components/Common/statusOutsideAlerter";
import chevronDown from "../../Assets/images/chevron-down.png";
import close from "../../Assets/images/close.png";
import DataTable from "./Datatable";

import { getTechnicians, getActiveTechnicians, getQueryTechnicians, updateBulk } from "../../Action/BulkReassign";
import Loader from "../../Components/Loader";

const { Option } = Select;

const CustomerList = () => {
  const [state, setState] = useState({
    errors: {},
    reassignFrom: "",
    reassignTo: "",
    status: ["active"],
    ordering: "",
    selectedRow: [],
    reassignName: "",
  });
  const [customerList, setCustomerList] = useState([]);
  const [customerListStatus, setCustomerListStatus] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [inactiveTechnicians, setInactiveTechnicians] = useState([{ id: 1 }]);
  const [activeTechnicians, setActiveTechnicians] = useState([]);
  const [loader, setLoader] = useState(false);
  const [statusFilter, setStatusFilter] = useState(false);
  const [serviceOrderCount, setServiceOrderCount] = useState(0);
  const [customerStatus, setCustomerStatus] = useState([]);
  const {
    reassignFrom,
    reassignTo,
    status,
    ordering,
    selectedRow,
    reassignName = "[From Technician]",
  } = state;
  const filterStatus = [
    { id: 1, key: "Active", value: "active", is_checked: true },
    { id: 2, key: "Pending", value: "pending", is_checked: false },
    { id: 3, key: "Inactive", value: "inactive", is_checked: false },
  ];

  // Reassign From
  useEffect(() => {
    setState((s) => ({
      ...s,
      reassignFrom: "",
      reassignTo: "",
      status: ["active"],
      ordering: "",
      selectedRow: [],
    }));
    setCustomerStatus(filterStatus);

    setLoader(true);
    const getTechs = async () => {
      const data = await getTechnicians();
      setInactiveTechnicians(data ? data.results : []);
      setLoader(false);
    };
    getTechs();
  }, []);

  useEffect(() => {
    let queryString = "";

    if (reassignFrom) {
      queryString = `?preferred_technician__uuid=${reassignFrom}`;
    }
    if (status && status.length > 0 && reassignFrom) {
      queryString = `${queryString}&status__in=${status.join()}`;
    }
    if (ordering) {
      queryString = `${queryString}&ordering=${ordering}`;
    }
    if (queryString) {
      const getTech = async () => {
        const response = await getQueryTechnicians(queryString);
        setServiceOrderCount(response?.count);
        setCustomerList(response?.results);
        console.log(customerList);
      }

      getTech();
    }

    if (reassignFrom) {
      const getTech = async () => {
        const response = await getQueryTechnicians(`?preferred_technician__uuid=${reassignFrom}`);
        setCustomerListStatus(response?.results);
      }

      getTech();
    }
  }, [state]);

  const reAssignServiceOrder = () => {
    const schema = yup.object().shape({
      reassignTo: yup.string().required("Select a technician."),
      reassignTo_1:
        state.reassignFrom === state.reassignTo
          ? yup
            .string()
            .required("This technician already owns these customers.")
          : null,
    });
    const data = { reassignTo };

    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        if (state.selectedRow && state.selectedRow.length > 0) {
          bulkUpdate();
        } else {
          message.error(
            `You must select at least one customer to transfer.`,
            10
          );
        }
      })
      .catch((error) => {
        let err_data = {};
        map(error.inner, (item) => {
          err_data[item.path] = item.message;
        });
        setState((s) => ({ ...s, errors: err_data }));
      });
  };

  const bulkUpdate = () => {
    const params = {
      preferred_technician_uuid: state.reassignTo,
      user_uuids: state.selectedRow,
    };

    const update = async () => {
      const response = await updateBulk(params);
      console.log(response);
      message.success(`Customers reassigned`, 10);
      setState((s) => ({
        ...s,
        reassignFromLocal: "",
        reassignFrom: "",
        reassignTo: "",
        status: [],
        ordering: "",
        selectedRow: [],
        errors: {},
      }));
      setCustomerList([]);
      setCustomerListStatus([]);
    }

    update();
  };

  const toggleStatus = () => {
    if (statusFilter) {
      setStatusFilter(false);
    } else {
      setStatusFilter(true);
    }
  };

  const closeStatusFilter = () => {
    setStatusFilter(false);
  };

  const onStatusChanged = (is_checked, item, index) => {
    if (is_checked) {
      customerStatus[index]["is_checked"] = true;
      status.push(item.value);
    } else {
      customerStatus[index]["is_checked"] = false;
      const ind = status.indexOf(item.value);
      if (ind > -1) {
        status.splice(ind, 1);
      }
    }
    setState((s) => ({
      ...s,
      status,
      customerStatus,
    }));
  };

  const clearFilter = () => {
    setState((s) => ({ ...s, status: [] }));
  };

  const renderStatusFilters = () => {
    return (
      <StatusOutsideAlerter closeStatusFilter={closeStatusFilter}>
        <div className="form-group image-input">
          <div className="fitem mb-0">
            <input
              type="text"
              placeholder={`Status: ${status ? status.join() : ""}`}
              onFocus={() => {
                toggleStatus();
              }}
            />
            {status && status.length === 0 && (
              <img
                src={chevronDown}
                alt=""
                onClick={() => {
                  toggleStatus();
                }}
              />
            )}
            {status && status.length > 0 && (
              <img
                src={close}
                alt=""
                onClick={() => {
                  clearFilter();
                }}
              />
            )}
          </div>
          {statusFilter && (
            <div className="field-dropdown right">
              <div className="field-dropdown-body">
                {map(customerStatus, (item, index) => {
                  return (
                    <div key={index} className="fitem-check">
                      <Checkbox
                        defaultChecked={item.is_checked}
                        checked={item.is_checked}
                        onChange={(e) => {
                          onStatusChanged(e.target.checked, item, index);
                        }}
                      >
                        {item.key}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </StatusOutsideAlerter>
    );
  };

  function SideBar() {
    const { reassignFromLocal } = state;
    // Reassign To
    useEffect(() => {
      const getTechs = async () => {
        const data = await getActiveTechnicians();
        setActiveTechnicians(data ? data.results : []);
        setLoader(false);
      };
      getTechs();
    }, []);

    const search = () => {
      const schema = yup.object().shape({
        reassignFromLocal: yup.string().required("Select a technician."),
      });
      const data = { reassignFromLocal };
      schema
        .validate(data, { abortEarly: false })
        .then(() => {
          if (state.selectedRow && state.selectedRow.length > 0) {
            setConfirm(true);
          } else {
            let reassignName = "[From Technician]";
            const reassignArr = activeTechnicians.filter((x) => {
              return x.uuid === reassignFromLocal;
            });
            if (reassignArr && reassignArr.length > 0) {
              reassignName = `${reassignArr[0].user.first_name} ${reassignArr[0].user.last_name}`;
            }
            setState((s) => ({
              ...s,
              reassignFrom: reassignFromLocal,
              reassignName: reassignName,
              errors: {},
            }));
          }
        })
        .catch((error) => {
          let err_data = {};
          map(error.inner, (item) => {
            err_data[item.path] = item.message;
          });
          setState((s) => ({ ...s, errors: err_data }));
        });
    };

    return (
      <aside className="laside">
        <div className="card">
          <div className="card-body">
            <h2>Bulk Reassign Technician</h2>
            <p>Reassign customers from one technician to another</p>
            <div className="form-group">
              <div className="fitem">
                <label>
                  Reassign From<sup>*</sup>
                </label>
                <Select
                  showSearch
                  placeholder="Search"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setState((s) => ({ ...s, reassignFromLocal: e }));
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={state?.reassignFromLocal || undefined}
                >
                  {inactiveTechnicians?.map((technician, index) => {
                    return <Option key={index} value={technician.uuid}>{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                  })}
                </Select>
                {state.errors?.["reassignFromLocal"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {state.errors?.["reassignFromLocal"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              <div className="fitem">
                <Button
                  className="btn btn-warning btn-full"
                  onClick={(e) => {
                    search();
                  }}
                >
                  Search
                </Button>
              </div>
              <div className="fitem">
                <label>
                  Reassign To<sup>*</sup>
                </label>
                <Select
                  showSearch
                  placeholder="Search"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setState((s) => ({ ...s, reassignTo: e }));
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={state?.reassignTo || undefined}
                >
                  {activeTechnicians?.map((technician, index) => {
                    return (
                      <Option
                        key={index}
                        value={technician.uuid}
                      >{`${technician?.user?.first_name} ${technician?.user?.last_name}`}</Option>
                    );
                  })}
                </Select>
                {state.errors?.["reassignTo"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {state.errors?.["reassignTo"]}
                  </label>
                ) : (
                  ""
                )}
                {state.errors?.["reassignTo_1"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle" />
                    {state.errors?.["reassignTo_1"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="New Search"
          visible={confirm}
          okText={"Search"}
          cancelText={"Cancel"}
          onOk={() => {
            setConfirm(false);
            let reassignName = "[From Technician]";
            const reassignArr = activeTechnicians.filter((x) => {
              return x.uuid === state.reassignFromLocal;
            });
            if (reassignArr && reassignArr.length > 0) {
              reassignName = `${reassignArr[0].user.first_name} ${reassignArr[0].user.last_name}`;
            }
            setState((s) => ({
              ...s,
              selectedRow: [],
              reassignFrom: state.reassignFromLocal,
              reassignName: reassignName,
              errors: {},
            }));
          }}
          onCancel={() => {
            setConfirm(false);
          }}
          className="delete_user_modal"
          width={400}
        >
          <h3>New Search</h3>
          <p>
            Performing a new search now will clear any progress made so far.
          </p>
          <p>Would you like to continue?</p>
        </Modal>
      </aside>
    );
  }

  return (
    <React.Fragment>
      <div id="container">
        <div className="franchisee">
          <div className="aside-area pt-0">
            {SideBar()}
            <div className={`main pl-0 pr-0 flex-1`}>
              <div className="card">
                <div className="card-title bdr-b-none flex ai-center">
                  <div className="pt-10 pb-10">
                    <h3>Customers</h3>
                    {customerList && customerList.length > 0 && (
                      <p>
                        Viewing customers with a preferred technician of{" "}
                        {reassignName}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    {customerListStatus && customerListStatus.length > 0 ? (
                      <div className="fitem half-fitem mb-0">
                        {renderStatusFilters()}
                        <div
                          className="fitem half-fitem mb-0"
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            className="btn btn-warning btn-lg"
                            onClick={() => {
                              reAssignServiceOrder();
                            }}
                          >
                            Reassign Customer
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="card-body pl-0 pr-0">
                  <React.Fragment>
                    {customerList && customerList.length > 0 ? (
                      <>
                        <Table
                          rowSelection={{
                            type: "select",
                            onChange: (selectedRowKeys) => {
                              setState((s) => ({
                                ...s,
                                selectedRow: selectedRowKeys,
                              }));
                            },
                          }}
                          rowKey={(record) => record.user.uuid}
                          dataSource={customerList}
                          columns={DataTable.customerColumns(this)}
                          className="tablebody"
                          position={false}
                          //locale={locale}
                          pagination={{
                            ...{
                              showSizeChanger: false,
                              pageSize: 10,
                              ordering: "",
                            },
                            total: serviceOrderCount,
                          }}
                          onChange={(pagination, filters, sorter) => {
                            let ordering = "";
                            if (sorter.column) {
                              ordering =
                                sorter.order === "ascend"
                                  ? `${sorter.field}`
                                  : `-${sorter.field}`;
                            } else {
                              ordering = "";
                            }
                            if (ordering) {
                              setState((s) => ({ ...s, ordering: ordering }));
                            } else {
                              setState((s) => ({ ...s, ordering: "" }));
                            }
                          }}
                        />
                      </>
                    ) : customerList &&
                      customerList.length === 0 &&
                      !reassignFrom ? (
                      <div style={{ textAlign: "center" }}>
                        <UsergroupAddOutlined
                          style={{ fontSize: "40px", color: "#feaf32" }}
                        />
                        <p>
                          <strong
                            style={{ fontWeight: "bold", marginBottom: "5px" }}
                          >
                            {" "}
                            No Customers{" "}
                          </strong>
                          <br />
                          <span>
                            {" "}
                            Customers will appear here when a technician has
                            been selected.
                          </span>
                        </p>
                      </div>
                    ) : customerList &&
                      customerList.length === 0 &&
                      reassignFrom ? (
                      <div style={{ textAlign: "center" }}>
                        <UsergroupAddOutlined
                          style={{ fontSize: "40px", color: "#feaf32" }}
                        />
                        <p>
                          <strong
                            style={{ fontWeight: "bold", marginBottom: "5px" }}
                          >
                            {" "}
                            No Customers{" "}
                          </strong>
                          <br />
                          <span>
                            {" "}
                            The technician selected has no assigned customers.
                          </span>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerList;
