import React from "react";
import "../../Assets/css/calendar.css";
import HeaderMobile from "../../Components/HeaderMobile";
import Sidebar from "../../Components/Sidebar";
import SidebarResponsive from "../../Components/SidebarResponsive";
import PriceCatalogContainer from "../Components/PriceCatalog.Container";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { setAppDataField } from "../../Store/appDataSlice";

const priceCatalog = () => {
  const dispatch = useDispatch();
  const { mobileMenu } = useSelector((state) => state.appData);
  
  const onMenuButtonClick = () => {
    dispatch(setAppDataField({ mobileMenu: !mobileMenu }));
  }

  return (
    <div id="wrapper">
      <div className="calendar-ca content-area">
        <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={mobileMenu}
          onMenubuttonClick={onMenuButtonClick}
        />
        <div className="container">
          <PriceCatalogContainer />
        </div>
      </div>
    </div>
  )
}

export default priceCatalog;
