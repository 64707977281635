import React from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { addSize, addName } from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";

const PoolName = (props) => {
  const { errors, editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);

  return (
    <div className="form-group mb-20">
      <div className="half-fitem fitem">
        <div className="half-fitem fitem">
          <label style={{ fontWeight: "bold" }}>Pool Name<sup>*</sup></label>
          <input
            type="text"
            placeholder="Pool Name"
            style={{ width: "100%" }}
            value={proposals.pool_name}
            disabled={editMode}
            onChange={(e) => dispatch(addName(e.target.value))}
          />
          {errors["pool_name"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["pool_name"]}
                  </label>
                ) : (
                  ""
                )}
        </div>
        <div className="half-fitem fitem">
          <label style={{ fontWeight: "bold" }}>Pool size in Gallons </label>
          <input
            type="text"
            placeholder="Pool size"
            style={{ width: "100%" }}
            value={proposals.pool_size}
            onChange={(e) => dispatch(addSize(e.target.value))}
            disabled={editMode}
            onPaste={(event) => {
              if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                event.preventDefault();
              }
            }}
            onKeyPress={(e) => {
              const pattern = /[0-9]/;
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                // invalid character, prevent input
                e.preventDefault();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PoolName;
