import axiosInstance from "../../Lib/Axios";

export const getProposal = (params) => {
    // return (dispatch) => {
    return axiosInstance
      .get(`proposal/`, { params })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
    // };
  };

  export const updateProposalStatus = (params) => {
    return axiosInstance
    .patch(`proposal/update_proposal_status/`, { ...params })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
  };

export const getProposalReport = (params) => {
  return axiosInstance
    .get(`/proposal/export_proposal/`, { params })
    .then((response) => {
      if (response.headers['content-type'] === 'application/json') {
        return response.data;
      }
      else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const timestamp = Date.now();
        link.setAttribute('download', `proposals_${timestamp}.csv`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
      }
    });
}

export const sendBulkEmailProposal = (params) => {
  return axiosInstance
    .post(`email-proposal/bulk_email/`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

export const sendEmailProposal = (params) => {
  return axiosInstance
    .get(`/email-proposal/${params}/proposal/`)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.assign("/");
        localStorage.clear();
        sessionStorage.clear();
      }
      if (error?.response) {
        return error?.response?.data;
      }
    });
};

  export const getDuplicate = (proposal_uuid) => {
    // return (dispatch) => {
    return axiosInstance
      .post(`proposal/duplicate_proposal/?uuid=${proposal_uuid}`)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          window.location.assign("/");
          localStorage.clear();
          sessionStorage.clear();
        }
        if (error?.response) {
          return error?.response?.data;
        }
      });
    // };
  };
  
  export const getPrintProposal = (proposal_uuid) => {
    return axiosInstance        
      .get(`/print-proposal/render/?proposal_uuid=${proposal_uuid}&render_action=pdf_format`)
      .then((response) => {
            const link = response?.data;
            let byteCharacters = atob(link.pdf_data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: "application/pdf;base64" });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);          
        })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            window.location.assign("/");
            localStorage.clear();
            sessionStorage.clear();
          }
        }
      });
  }