import React, { useEffect, useState } from "react";
import { Switch, Modal } from "antd";
import { Checkbox } from "antd";
import { Button, Space, message} from "antd";
import { updatePulseCustomerAccount, getPulseCustomerAccount } from "../../../Action/Customer/index";
import { addCustomer } from '../../../Store/customerSlice';
import { useDispatch } from 'react-redux/es/exports';
import { getCommercialCustomer } from '../../../Action/Customer';


const PulseNotification = ({customer}) => {
  const dispatch = useDispatch();
  const [isErrorOpennot, setIsErrorOpennot] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkone, setCheckone] = useState(false);
  const [checktwo, setChecktwo] = useState(false);
  const [checkthree, setCheckthree] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const customerFieldOfficeData = customer?.field_office;
  useEffect(()=> {
    const getCustomerInfo = async () => {
      const data = await getCommercialCustomer(customer?.uuid);
      dispatch(addCustomer(data));
      setShowLabel(true);
      window.localStorage.setItem("customerUUID", data?.uuid);
    }
    getCustomerInfo();
    return () => {
      setShowLabel(false);
    }
  }, []);

  const validateToggleData = () => {
    if(toggled || clicked || checked || checkone || checktwo || checkthree) {
      return true;
    } else return false;
  }

  const validatePulse = (data) => {
    if(!data) return false;
    const pulseData = ["batch_email", "batch_sms", "bio_email", "bio_sms", "check_email", "check_sms"];
    const pulseDataStatus = [];
    for (let index = 0; index < pulseData.length; index++) {
      if (pulseData?.[index] in data && data?.[pulseData?.[index]]) {
        pulseDataStatus.push(true);
      } else {
        pulseDataStatus.push(false);
      }
    }
    if (pulseDataStatus.includes(true)) {
      return true;
    } else return false;
  }


  const preference = async() => {
    const params = {
      bio_sms: checkone,
      bio_email: checked,
      check_sms: checkthree,
      check_email: checktwo,
      type: "customer",
    };
    if(toggled && clicked) {
      if((toggled && checked || checkone) && (clicked && checktwo || checkthree)) {
        let result = await updatePulseCustomerAccount(customer?.uuid, params);
        message.success('Updated Successfully');
         
      } else {
        setIsErrorOpennot(true);
      }
    } else {
      if((toggled && checked || checkone) || (clicked && checktwo || checkthree) || (!toggled && !clicked)) {
        let result = await updatePulseCustomerAccount(customer?.uuid, params);
        message.success('Updated Successfully');
      } else {
        setIsErrorOpennot(true);
      }
    }
    
  };
  const onpulstoggled = (checked) => {
    if(!checked) {
      setChecked(false);
      setCheckone(false);
    }
    setToggled(checked);
  };

  const onchangeswitched = (checked) => {
    if(!checked) {
      setChecktwo(false);
      setCheckthree(false);
    }
    setClicked(checked);
  };

  const togglepulsChecked = (e) => {
    setChecked(e.target.checked);
  };
  const togglepulsCheckone = (e) => {
    setCheckone(e.target.checked);
  };
  const togglepulsChecktwo = (e) => {
    setChecktwo(e.target.checked);
  };
  const togglepulsCheckthree = (e) => {
    setCheckthree(e.target.checked);
  };
  const getData = async () => {
    let data = await getPulseCustomerAccount(customer?.uuid);
    let data_notification = {...data.notification}
    if (data.uuid) {
      setChecked(data_notification.bio_email);
      setCheckone(data_notification.bio_sms);
      setChecktwo(data_notification.check_email);
      setCheckthree(data_notification.check_sms);
      if(data_notification.bio_email || data_notification.bio_sms) {
        setToggled(true);
      }
      if(data_notification.check_email || data_notification.check_sms) {
        setClicked(true);
      }
    }
  }
  useEffect(()=>{
    getData();
  }, []);
  return (
    <div>
      <div className={customer?.is_active ? "pulsitem" : "pulsitem disable-field"} >
        <label htmlFor="">
          <h5 className="pulsem-notifi-resid">PulseM Notification</h5>
          {!validatePulse(customerFieldOfficeData) && validateToggleData() && showLabel &&<label className="blink-label"><span className="blink-label-anim"><sup>*</sup></span>Notifications are disabled at franchise level.</label>}
        </label>
        <div className="pre-btn-spc">
          <label htmlFor="">
            <b>PulseM Pre Service Notification Bio</b>
          </label>
          <span className="toggle1-res-cus">
            <Switch size="medium" onChange={onpulstoggled} className={toggled ? 'switch-active' : 'switch-nonactive'} checked={toggled}/>
          </span>
        </div>
        {toggled && (
          <>
            <div className="check2-res-cus">
              <Checkbox
                checked={checked}
                onChange={(e) => togglepulsChecked(e)}
              >
                Email
              </Checkbox>
              <span className="space1-btw-box">
                {" "}
                <Checkbox
                  checked={checkone}
                  onChange={(e) => togglepulsCheckone(e)}
                >
                  SMS
                </Checkbox>
              </span>
            </div>
          </>
        )}

        <div className="btn-space-tog">
          <label htmlFor="">
            <b>PulseM Post Service Notification Check</b>
          </label>
          <span className="toggle2-res-cus">
            <Switch size="medium" className={clicked ? 'switch-active' : 'switch-nonactive'} onChange={onchangeswitched} checked={clicked}/>{" "}
          </span>
        </div>
        {clicked && (
          <>
            <div className="check1-res-cus">
              <Checkbox
                checked={checktwo}
                onChange={(e) => togglepulsChecktwo(e)}
              >
                Email
              </Checkbox>
              <span className="space2-btw-box">
                <Checkbox
                  checked={checkthree}
                  onChange={(e) => togglepulsCheckthree(e)}
                >
                  SMS
                </Checkbox>
              </span>
            </div>
          </>
        )}

        <div className="pulscheck">
          <Space
            direction="horizontal"
            style={{
              width: "20%",
            }}
          >
            <Button className="btn btn-warning" onClick={preference}>
              Update Preference
            </Button>
          </Space>
        </div>
        <Modal
          title="Error Modal "
          visible={isErrorOpennot}
          onCancel={() => setIsErrorOpennot(false)}
          onOk={() => setIsErrorOpennot(false)}
          className="delete_user_modal"
          width={400}
          // footer={[
          //   <button
          //     key="cancel"
          //     className="btn btn-brand btn-bdr"
          //     onClick={() => onnoteupdate()} >
          //     OK
          //   </button>,
          //   ]}
        >
          <div>
            <h2> Please Select The PulseM Notification !! </h2>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PulseNotification;
