import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import WorkOrder from "./workOrder";

const TechColumn = (props)=>{
    const [techInfo, setTechInfo] = useState([]);
    const [techPermission, setTechPermission] = useState([]);
    const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
    const role = userInfo && userInfo?.user?.user_roles[0]?.name;

    useEffect(()=> {
        setTechInfo(props.technicianInfo);
    }, [props]);

    useEffect(()=> {
        if(role === "field_tech") {
            let per = userInfo?.user?.technician_permissions;
            let temp = [];
            for (var key in per) {
            if (per[key]) {
              temp.push(key);
            }
            setTechPermission(temp);
          }
        }
      }, []);

    return(
        <div
            style={{
            alignItems: "center",
            height: '100%'
            }}
            key={props.index}
        >
            <h4 
            style={{
                marginBottom: '0px',
                textAlign: 'center',
                textTransform:'capitalize',
                height: '2.5em',
                padding: '0 0.5em',
                background: '#f7f7f7'
            }}
            >{techInfo?.name || 'Not assigned'}</h4>
            <div style={{height:'100%'}}>
                <Droppable droppableId={`${techInfo.id}`} key={techInfo.id} isDropDisabled={false}>
                    {(provided, snapshot) => {
                    return (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            background: snapshot.isDraggingOver ? "#fff0e8" : "white",
                            padding: 4,
                            width: 250,
                            height: '100%',
                        }}
                        >
                        {(techInfo.tList && techInfo.tList.length>0) &&
                            techInfo.tList.map((wOrder, index)=>{
                                return <WorkOrder workOrder={wOrder} index={index} key={wOrder.work_order_uuid} getWorkOrder={props.getWorkOrder} techPermission={techPermission}/>
                            }) 
                        }
                        {provided.placeholder} 
                        </div>
                    );
                    }}
                </Droppable>
            </div>
        </div>
    );
};

export default TechColumn;