import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { map, isEqual } from "lodash";
import AllState from "../../../Assets/data/stateList.json";
import { Col, Modal, Row, Select } from "antd";

import appData from "../../../Utils/constants";
import { addPoolSize, updateCommunityDetails } from "../../../Store/techJourneyDataSlice";

import store from "../../../Store/store";
import ServiceSummary from "../../../Customer/Residential/NewCustomer/ServiceSummary/ServiceSummary";
import SelectService from "./SubComponents/ServiceTypes";
import RateTypes from "./SubComponents/RateTypes";
import ServiceRequired from "./SubComponents/ServiceRequired";
import PoolTypes from "./SubComponents/PoolTypes";
import CleaningFrequency from "./SubComponents/CleaningFrequency";

const contact_methods = [
  { key: "Text", value: "text" },
  { key: "Home Phone", value: "home_phone" },
  { key: "Office", value: "office" },
  { key: "Email", value: "email" },
];

const community_preferences = [
  { key: "Townhomes", value: "townhomes" },
  { key: "Condominium", value: "condo" },
  { key: "Apartment/HOA", value: "apartment" },
  { key: "Other", value: "detached" },
];

const CommunityInformation = ({
  inputData = {
    uuid: "",
    zip_code: "",
    first_name: "",
    last_name: "",
    address: {},
    phone_numbers: [],
    phone: "",
    emailaddress_set: [],
    payment_method: "",
    email: "",
    authorized_users: [],
    hear_about_us: [],
  },
  openModal,
  setOpenModal,
  handleAddJob,
}) => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const [errors, setErrors] = useState({});
  const [preferred_contact_methods, setContactMethods] = useState([]);
  const [properties_in_community, setCommunityPreference] = useState("");
  const [priceDetail, setPriceDetail] = useState({});
  const [serviceInfo, setServiceInfo] = useState({});
  const [standardRate, setStandardRate] = useState(false);
  const [fieldOfficeData, setfieldOfficeData] = useState({});
  const [company_name, setCompanyName] = useState("");
  const [no_of_properties, setNoOfProperties] = useState("");
  const [no_of_pools, setNoOfPools] = useState("");
  const [notes, setNote] = useState("");
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(true);
  const [billingAddress, setBillingAddress] = useState({
    address: "",
    city: "",
    state: "",
    zip_code: "",
    address2: "",
  });
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const initialValues = {
    uuid: "",
    zip_code: "",
    first_name: "",
    last_name: "",
    address: {},
    phone_numbers: [],
    phone: "",
    emailaddress_set: [],
    payment_method: "",
    email: "",
    authorized_users: [],
    hear_about_us: [],
  }

  const { selectedServiceType, addPhoneNumber, addEmail, customerDetails, communityDetails } = techJourneyState;

  const validateBaseJobReq = React.useRef(null);

  const setPriceDetailData = (data) => {
    setPriceDetail(data);
  };

  const onContactMethodChange = (e) => {
    let tempMethods = communityDetails.preferred_contact_methods;
    const idx = tempMethods?.indexOf(e.target.value);
    if (idx > -1) {
        tempMethods = tempMethods.filter((fruit) => fruit !== e.target.value);
    } else {
      tempMethods = [...tempMethods, e.target.value];
    }
    dispatch(updateCommunityDetails({...communityDetails, preferred_contact_methods: tempMethods }));
  };

  const onAddressChanged = (e) => {
    setData({ ...data, address: [e.target.value] });
    setBillingAddress((address) => ({
      ...address,
      [e.target.name]: e.target.value,
    }));
  };
  // on address change
  const onAddressChange = (e) => {
    Object.assign(billingAddress, { [e.target.name]: e.target.value });
    setBillingAddress(billingAddress);
    setData({ ...data, [e.target.name]: billingAddress });
  };
  // render all state
  // const renderAllState = () => {
  //   return map(AllState?.AllState, (item, i) => {
  //     return (
  //       <Option
  //         value={item.abbreviation}
  //         key={i}
  //       >
  //         {item.name}
  //       </Option>
  //     );
  //   });
  // };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    dispatch(updateCommunityDetails({...communityDetails, [e.target.name]: e.target.value }));
    // if (e.target.name === "company_name") {
    //   setCompanyName(e.target.value);
    // } else if (e.target.name === "no_of_properties") {
    //   setNoOfProperties(e.target.value);
    // } else if (e.target.name === "no_of_pools") {
    //   setNoOfPools(e.target.value);
    // } else {
    //   setNote(e.target.value);
    // }
  };

  const onCommunityPreferenceChange = (e) => {
    setData({...data, community_preferences: e.target.value})
    setCommunityPreference(e.target.value);
  };

  const setServiceInfoData = (data) => {
    let obj = serviceInfo;
    obj[data.name] = data;
    setServiceInfo(obj);
  };

  const validate = () => {
    alert("VALIDATION CLICK! API TBD");
    handleAddJob(data);
  };

  const communityInfoSummay = {billingAddress: customerDetails?.billing_address, phone:customerDetails?.phone_numbers[0]?.phone, email: customerDetails?.emailaddress_set[0]?.email.toLowerCase()}

  return (
    <>
      <Provider store={store}>
        <Row>
          <Col span={24}>
            <h3>Community Information</h3>
            <div className="frow">
              <div style={{ marginBottom: "15px" }}>
                <h4 style={{ marginBottom: "2px" }}>
                  How would you like us to reach out to you?
                </h4>
                {` (Select all that apply) `}
              </div>
              <div className="oplist">
                {map(contact_methods, (item, index) => {
                  const isInclude = communityDetails.preferred_contact_methods?.includes(
                    item?.value
                  );
                  return (
                    <div
                      className="inline-checks flex-1"
                      key={`contact_methods${index}`}
                    >
                      <label
                        htmlFor={item?.value}
                        className={isInclude ? "active" : ""}
                      >
                        <input
                          type="checkbox"
                          id={item?.value}
                          name="contactMethod"
                          className=""
                          value={item?.value}
                          onChange={onContactMethodChange}
                        />
                        <span>{item?.key}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* {errors?.[`preferred_contact_methods`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`preferred_contact_methods`]}</label> : ''} */}
            </div>
            <div className="frow">
              <h4>What's in your community?</h4>
              <div className="oplist">
                {map(community_preferences, (item, index) => {
                  return (
                    <div
                      className="inline-checks flex-1"
                      key={`community_preferences${index}`}
                    >
                      <label
                        htmlFor={item?.value}
                        className={
                          properties_in_community === item?.value
                            ? "active"
                            : ""
                        }
                      >
                        <input
                          type="radio"
                          id={item?.value}
                          name="communityPreference"
                          className=""
                          value={item?.value}
                          onChange={onCommunityPreferenceChange}
                        />
                        <span>{item?.key}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <hr />
            <div className="frow pt-10">
              <div className="">
                <div className="form-group mb-20">
                    <div className="fitem">
                      <span>Number of Properties</span>
                      <input
                        type="text"
                        placeholder="Number of Properties"
                        name="no_of_properties"
                        value={communityDetails.no_of_properties}
                        onChange={onTextChange}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="fitem">
                      <span>Number of Pools</span>
                      <input
                        type="text"
                        placeholder="Number of Pools"
                        name="no_of_pools"
                        value={communityDetails.no_of_pools}
                        onChange={onTextChange}
                        style={{ width: "100%" }}
                      />
                    </div>
                  
                </div>
                <div className="form-group mb-20">
                  <div className="fitem">
                    <span>Note</span>
                    <input
                      type="text"
                      placeholder="Note"
                      name="notes"
                      value={communityDetails.notes}
                      onChange={onTextChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="aside-bar">
              <div
                className="acol"
                style={{ backgroundColor: "#f4f7f9" }}
              >
                <h2 className="mb-20">Community Summary</h2>
                <h5>Contact Information</h5>
                <div>{communityInfoSummay?.company_name}</div>
                <div>
                  {
                    communityInfoSummay?.email || ""
                  }
                </div>
                <div>
                  {communityInfoSummay?.phone || ''}
                </div>
                <hr />
                <h5>Community Information</h5>
                <div>
                  {communityInfoSummay?.billingAddress
                    ?.street_number &&
                  communityInfoSummay?.billingAddress?.route
                    ? `${
                        communityInfoSummay?.billingAddress
                          ?.street_number || ""
                      } ${
                        communityInfoSummay?.billingAddress
                          ?.route || ""
                      } `
                    : `${
                        communityInfoSummay?.billingAddress
                          ?.address || ""
                      }`}
                </div>
                <div>
                  {communityInfoSummay?.billingAddress
                    ?.street_number &&
                  communityInfoSummay?.billingAddress?.route
                    ? `${
                        communityInfoSummay?.billingAddress
                          ?.city || ""
                      }, ${
                        communityInfoSummay?.billingAddress
                          ?.state || ""
                      } ${
                        communityInfoSummay?.billingAddress
                          ?.zip_code || ""
                      }`
                    : `${
                        communityInfoSummay?.billingAddress
                          ?.city || ""
                      }, ${
                        communityInfoSummay?.billingAddress
                          ?.state || ""
                      } ${
                        communityInfoSummay?.billingAddress
                          ?.zip_code || ""
                      }`}
                </div>
              </div>
            </div>
            <br />
          </Col>
        </Row>
      </Provider>
    </>
  );
};

export default CommunityInformation;
