import React, { useEffect, useState } from "react";
import { Select, ConfigProvider, Empty, Spin } from "antd";
import { orderBy, isEmpty } from "lodash";
import { getFranchise } from "../../../Action/Franchise";
import { getFranchiseListAll } from '../../../Action/Franchise';
import { getFranchiseSelected, setFranchiseSelected } from "../../../Utils/selectedFranchise";

const { Option } = Select;

const FranchiseSelector = ({ onControlChange = () => {} }) => {
  const userInfo = JSON.parse(localStorage.getItem("authUser")) || {};
  const role = userInfo && userInfo?.user?.user_roles[0]?.name;

  const [loading, setLoading] = useState(true);
  const [franchiseList, setFranchiseList] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [allValue, setAllValue] = useState(role === "admin" ? false : true);

  useEffect(() => {
    const getFranchiseList = async () => {
      setLoading(true);
      setFranchiseList([]);
      const data = await getFranchiseListAll();
      if (isEmpty(data)) {
        setFranchiseList([]);
        return;
      }
      
      let franSelected =  getFranchiseSelected()?.uuid ? {value: getFranchiseSelected().uuid} : null;
      if(franSelected?.value === "all"){
        franSelected.label = "all";
        franSelected.all = data;
      }
      if (allValue && data.length > 0) {
        setSelectedFranchise(franSelected || "all");

        onControlChange(franSelected || {
          value: data[0]?.uuid,
          label: "all",
          address: {},
          all: data,
        });
      } else {
        switch (true) {
          case role === "admin":
            const franchise_user = userInfo.user.associated_field_offices[0];
            setSelectedFranchise(franSelected || {
              value: franchise_user?.uuid,
              label: franchise_user?.name,
              address: {
                address: franchise_user?.address,
                city: franchise_user?.city,
                state: franchise_user?.state,
                zip_code: franchise_user?.zip_code,
              },
            });
            onControlChange(franSelected || {
              value: franchise_user?.uuid,
              label: franchise_user?.name,
              address: {
                address: franchise_user?.address,
                city: franchise_user?.city,
                state: franchise_user?.state,
                zip_code: franchise_user?.zip_code,
              },
            });
            break;
          case role === "franchise_owner":
          case role === "operations_mgr":
            setSelectedFranchise(franSelected || {
              value: data[0]?.uuid,
              label: data[0]?.name,
            });
            onControlChange(franSelected || {
              value: data[0]?.uuid,
              label: data[0]?.name,
            });
            break;
          case role === "field_tech":
            setSelectedFranchise(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
            });
            onControlChange(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
            });
            break;
          default:
            setSelectedFranchise(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
            });
            onControlChange(franSelected || {
              value: userInfo.user.associated_field_offices[0]?.uuid,
              label: userInfo.user.associated_field_offices[0]?.name,
            });
            break;
        }
      }
      setFranchiseList(data);
      setLoading(false);
    };
    getFranchiseList();
  }, []);

  const updateFranchise = (selectedFranchise, options) => {
    if (selectedFranchise === "all") {
      setSelectedFranchise("all");

      onControlChange({
        value: franchiseList[0].uuid,
        label: "all",
        address: {},
        all: franchiseList,
      });
    } else {
      setSelectedFranchise(selectedFranchise);
      onControlChange(options);
    }
    setFranchiseSelected(selectedFranchise);
  };

  const configureFranchiseSelector = () => {
    switch (true) {
      case role === "admin" || role === "franchise_owner" || role === "operations_mgr":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading franchise..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              value={selectedFranchise || undefined}
              onChange={(value, options) => updateFranchise(value, options)}
              placeholder={allValue ? "Franchise: All" : "Select a franchise"}
              className="swhite calendar-filter-franchise"
              disabled={franchiseList.length === 1}
            >
              {(allValue && franchiseList?.length>1) ? (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {"All"}
                </Option>
              ) : (
                <></>
              )}
              {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                  label={option?.name}
                >
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        );
      case role === "field_tech":
        return (
          <ConfigProvider
            renderEmpty={() =>
              loading ? (
                <div className="selectorSpin">
                  <Spin tip="Loading franchise..." />
                </div>
              ) : (
                <div className="selectorSpin">
                  <Empty />
                </div>
              )
            }
          >
            <Select
              value={selectedFranchise || undefined}
              onChange={(value, options) => updateFranchise(value, options)}
              placeholder={allValue ? "Franchise: All" : "Select a franchise"}
              className="swhite calendar-filter-franchise"
              disabled={franchiseList.length === 1}
            >
              {(allValue && franchiseList.length>1) ? (
                <Option key={"franchiseAll"} value={"all"} label={"all"}>
                  {"All"}
                </Option>
              ) : (
                <></>
              )}
              {orderBy(franchiseList, "id", "asc")?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                  label={option?.name}
                >
                  {option?.name}
                </Option>
              ))}
            </Select>
          </ConfigProvider>
        );
      default:
        return <></>;
    }
  };

  return configureFranchiseSelector();
};

export default FranchiseSelector;
