const {createSlice} = require('@reduxjs/toolkit');

const initialState = {
    loggedInUserDetails: [],
    loggedInUserEmail:"",
    franchiseSelected: "",
    franchiseList: [],
    startDate: null,
    endDate: null,
    startDateDrawer:null,
    endDateDrawer: null,
    dateType:'',
    openDrawer: false,
    customerTypeSelected:"all",
    proposalStatusSelected:"all",
    proposalServiceTypeSelected:"all",
    drawerFranchiseSelected:"",
    searchValue:"",
    filterButtonFlag:false,
    selectedRowsCount: [],
    proposalStatusEdit:"",
    exportFilters:{},
    proposalResponse: {}    
};

const proposalTableSlice = createSlice({
    name:'proposalsData',
    initialState,
    reducers:{
        addUserDetails(state,action){
            return (state = {
                ...state,
                loggedInUserDetails: action.payload,
              });
        },
        addSelectedFranchise(state,action){
            return (state = {
                ...state,
                franchiseSelected: action.payload,
              });
        },
        addFranchiseFromDrawer(state,action){
            return (state = {
                ...state,
                drawerFranchiseSelected: action.payload,
              });
        },
        addFranchiseList(state,action){
            return (state = {
                ...state,
                franchiseList: action.payload,
              });
        },
        setStartDate(state,action){
            return (state = {
                ...state,
                startDate: action.payload,
              });
        },
        setEndDate(state,action){
            return (state = {
                ...state,
                endDate: action.payload,
              });
        },
        setStartDateDrawer(state,action){
            return (state = {
                ...state,
                startDateDrawer: action.payload,
              });
        },
        setEndDateDrawer(state,action){
            return (state = {
                ...state,
                endDateDrawer: action.payload,
              });
        },
        setDateType(state,action){
            return (state = {
                ...state,
                dateType: action.payload,
              });
        },
        setProposalDrawer(state,action){
            return (state = {
                ...state,
                openDrawer: action.payload,
              });
        },
        addCustomerType(state,action){
            return (state = {
                ...state,
                customerTypeSelected: action.payload,
              });
        },
        addProposalStatus(state,action){
            return (state = {
                ...state,
                proposalStatusSelected: action.payload,
              });
        },
        addProposalServiceType(state,action){
            return (state = {
                ...state,
                proposalServiceTypeSelected: action.payload,
              });
        },
        addProposalSearchValue(state,action){
            return (state = {
                ...state,
                searchValue: action.payload,
              });
        },
        resetState(state) {
            return (state = {
                ...state,
                ...initialState
            })
        },
        setFilterFlag(state,action){
            return (state = {
                ...state,
                filterButtonFlag: action.payload,
              });
        },
        setProposalResponse(state,action){
            return (state = {
                ...state,
                proposalResponse: action.payload,
              });
        },
        setSelectedRowCount(state,action){
            return (state = {
                ...state,
                selectedRowsCount: action.payload,
              });
        },
        setProposalStatusFromEdit(state,action){
            return (state = {
                ...state,
                proposalStatusEdit: action.payload,
              });
        },
        setLoggedInUserEmail(state,action){
            return (state = {
                ...state,
                loggedInUserEmail: action.payload,
              });
        },
        setExportFilters(state,action){
            return (state = {
                ...state,
                exportFilters: action.payload,
              });
        }
    }
})

export const { 
    addUserDetails,
    addSelectedFranchise,
    addFranchiseList,
    setStartDate,
    setEndDate,
    setDateType,
    setProposalDrawer,
    addCustomerType,
    addProposalStatus,
    addProposalServiceType,
    setStartDateDrawer,
    setEndDateDrawer,
    addFranchiseFromDrawer,
    addProposalSearchValue,
    setProposalResponse,
    setFilterFlag,
    setSelectedRowCount,
    setProposalStatusFromEdit,
    setLoggedInUserEmail,
    setExportFilters,
    resetState
} = proposalTableSlice.actions;
export default proposalTableSlice.reducer;