const {createSlice} = require('@reduxjs/toolkit');
let franchise_data = JSON.parse(localStorage.getItem("temp_franchise"));
const initialState = {
    duration : franchise_data?.data?.default_job_duration,
    referenceNumber: "",
    editDurationField: false,
    newWOFlag: false,
    onDurationChange: false,
    serviceUUIDMap: [],
};
const serviceOrderDetailDataSlice = createSlice({
    name:'serviceOrderAddedData',
    initialState,
    reducers: {
        addDuration(state, action) {
            return (state = {
                ...state,
                duration: action.payload,
            });
        },
        addReferenceNumber(state, action) {
            return (state = {
                ...state,
                referenceNumber: action.payload,
            });
        },
        editDuration(state, action) {
            return (state = {
                ...state,
                editDurationField: action.payload,
            });
        },
        resetValues(state, action) {
            return (state = {
                ...state,
                duration: franchise_data?.data?.default_job_duration,
                referenceNumber: "",
                editDurationField: false,
                newWOFlag: false,
                onDurationChange: false
            });
        },
        setNewWOFlag(state, action) {
            return (state = {
                ...state,
                newWOFlag: action.payload,
            });
        },
        updateDuration(state, action) {
            return (state = {
                ...state,
                onDurationChange: action.payload,
            });
        },
        updateServiceUUIDMap(state,action){
            return (state = {
                ...state,
                serviceUUIDMap: action.payload,
            });
        }       
    }
})
export const {addDuration, addReferenceNumber, resetValues, editDuration, setNewWOFlag, updateDuration,updateServiceUUIDMap} = serviceOrderDetailDataSlice.actions;
export default serviceOrderDetailDataSlice.reducer;