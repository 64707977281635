
import React, { Component } from 'react';
import { map, uniqBy } from 'lodash';
import { Select, DatePicker, message } from 'antd';
import * as yup from 'yup';
import moment from 'moment-timezone';
import Loader from '../../../../Components/Loader';
import confused from '../../../../Assets/images/confused.png';
import sad from '../../../../Assets/images/sad.png';
import smile from '../../../../Assets/images/smile.png';
const { Option } = Select;

class NewTicket extends Component {

  state = {
    reason: [
      { label: 'Complaint', value: 'complaint' },
      { label: 'Missed service', value: 'missed_service' },
      { label: 'Schedule Problem', value: 'schedule_problem' },
      { label: 'Issue With Tech', value: 'issue_with_tech' },
      { label: 'Unhappy With Service', value: 'unhappy_with_service' },
      { label: 'Driving', value: 'driving' },
      { label: 'Billing', value: 'billing' },
      { label: 'Missed SVC', value: 'missed_svc' },
      { label: 'Edit existing Service', value: 'edit_existing_service' },
      { label: 'Request one-time service', value: 'request_one_time_service' },
      { label: 'Other', value: 'other' },
    ],
    new_ticket: {
      title: '',
      message: '',
      reason: '',
      assigned_user: '',
      request_callback: moment(),
      status: 'open',
      priority: '4',
      user: this.props.customerId,
      assigned_group: '',
      satisfaction: '',
      cc_to_customer: false,
      log_as_livecall: false,
    },
    loader: false,
    is_btn_Active: false,
    assigned_group_details: { uuid: '', name: '' },
    errors: {},
    is_user_selected: false,
    is_group_selected: false,
    AllUser: [],
    GroupList: []
  }

  componentDidMount = () => {
    //get Group 
    this.props.getGroupList({ 'limit': 15, offset: 0 })
    this.props.getUserList({ 'limit': 1000, offset: 0, 'ordering': 'first_name', 'is_case_mgmt': true });
  }

  UNSAFE_componentWillReceiveProps = (props) => {

    //Create New Ticket
    if (Object.keys(props.NewTicket.data).length > 0 && props.NewTicket.data !== this.props.NewTicket.data) {
      this.setState({
        loader: false,
      });

      this.props.getAllMessages({ offset: 0, limit: 15, user__uuid: this.props.customerId });

      this.props.closeCreteTicket(false);
    }

    if (Object.keys(props.NewTicket.errors).length > 0 && props.NewTicket.errors !== this.props.NewTicket.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }

    //Get Group list
    if (Object.keys(props.GroupList.data).length > 0 && props.GroupList.data !== this.props.GroupList.data) {
      this.setState({
        loader: false,
        GroupList: props.GroupList.data.results
      })
    }

    if (Object.keys(props.GroupList.errors).length > 0 && props.GroupList.errors !== this.props.GroupList.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }

    //get User
    if (Object.keys(props.UserList.data).length > 0 && props.UserList.data !== this.props.UserList.data) {
      this.setState({
        AllUser: props.UserList.data.results,
        loader: false
      })
    }

    if (Object.keys(props.UserList.errors).length > 0 && props.UserList.errors !== this.props.UserList.errors) {
      message.error('Something went wrong', 10);
      this.setState({
        loader: false,
      })
    }
  }

  // convert date time from utc to local time
  UTCToLocal = (date) => {
    let stillUtc = moment.utc(date).toDate();
    return moment(stillUtc).local();
  }

  // convert date time from local to utc
  localToUTC = (date) => {
    return moment.utc(date)
  }

  //Text Change for new ticket
  onnewTicketChange = (e) => {
    let { new_ticket } = this.state;
    new_ticket = Object.assign(new_ticket, { [e.target.name]: e.target.value });
    this.setState({
      new_ticket
    });
  }

  //Validation for required field
  ValidateTicket = () => {
    var self = this;
    setTimeout(() => {
      let { new_ticket, errors } = this.state;
      const TicketSchema = yup.object().shape({
        new_ticket: yup.object().shape({
          title: yup.string().required("Title is required"),
          message: yup.string().required("Message is required"),
          // assigned_user: yup.string().required("Assign user is required"),
          user: yup.string().required("user is required"),
          // follow_up_date: yup.string().required("Follow up date is required"),
          status: yup.string().required("Status is required"),
          reason: yup.string().required("Reason is required"),
          // assigned_group: yup.string().required("Assign Group is required").nullable('Assign Group is required'),
          assigned_group: yup.string(),
          assigned_user: yup.string().test('match', 'Assign user or Assign Group is required', function(assigned_user) {
            return !!(assigned_user || this.parent.assigned_group);
          }),
          request_callback: yup.string().required("Request Callback date is required"),
        })
      })

      const TicketData = {
        new_ticket
      }

      // check validity
      let IsValid;
      let err_data = {};
      TicketSchema.isValid(TicketData)
        .then(function (valid) {
          IsValid = valid
        });

      setTimeout(() => {
        if (IsValid) {

          self.setState({
            errors: {}
          })
          //Create new ticket
          this.createNewTicket(); 

        }
        else {
          TicketSchema.validate(TicketData, { abortEarly: false })
            .catch((err) => {
              map(err.inner, (item) => {
                err_data[item.path] = item.message;
              })

              self.setState({
                errors: err_data,
                is_btn_Active: false
              })
              console.log("err_data:", err_data)
            });
        }
      }, 100);

    }, 300);
  }

  //Create ticket new change
  onSelectChange = (e, type) => {
    let { new_ticket } = this.state
    new_ticket = Object.assign(new_ticket, { [type]: e });
    this.setState({ new_ticket });
  }

  //Create NewTicket
  createNewTicket = () => {
    let { new_ticket, assigned_group_details } = this.state;
    let data = {
      ...new_ticket,
      assigned_group_details,
    }

    if (this.props?.CustomerList?.customer?.address?.[0]?.zip_code) {
      data.zip_code = this.props?.CustomerList?.customer?.address?.[0]?.zip_code
    }
    if (data.satisfaction === '') {
      delete data.satisfaction
    }
    if (data.assigned_user === '') {
      delete data.assigned_user
    }
    if (!data.follow_up_date) {
      delete data.follow_up_date
    }
    if (data.assigned_group === '' || data.assigned_group === null) {
      delete data.assigned_group
    }

    if (data.assigned_user === '' || data.assigned_user === null) {
      delete data.assigned_user
    }

    if (data.follow_up_date) {
      data.follow_up_date = this.localToUTC(data.follow_up_date).format('YYYY-MM-DD')
    }

    if (data.request_callback) {
      data.request_callback = this.localToUTC(data.request_callback).format('YYYY-MM-DDTHH:mm:ss')
    }
    this.setState({
      loader: true
    })
    Promise.allSettled([
      this.props.createNewTickets(data)
    ]).then(result => {
      const { customerId, getMessagesCount } = this.props;
      getMessagesCount({ user__uuid: customerId });
    });
  }

  render() {
    let { reason, new_ticket, assigned_group_details, loader, errors, AllUser, GroupList } = this.state;
    return (
      <div className="block-row">
        <div className="new_ticket_wrapper flex">
          <div className="ticket_detail form-group">
            <div className="fitem">
              <input
                type="text"
                value={new_ticket.title}
                onChange={(e) => { this.onnewTicketChange(e) }}
                name="title"
                placeholder="Add your Ticket Title" />
              {errors['new_ticket.title'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.title']}</label> : ''}
            </div>
            <div className="fitem">
              <textarea
                value={new_ticket.message}
                onChange={(e) => { this.onnewTicketChange(e) }}
                name="message"
                placeholder="Add your message here">

              </textarea>
              {errors['new_ticket.message'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.message']}</label> : ''}
            </div>
            <div className="fitem">
              <div className="fitem inline-check-list gcheck">
                <div className="fitem-check d-inline" style={{ paddingRight: '15px' }}>
                  <input
                    type="checkbox"
                    className="fitem-ck-input"
                    id="log_as_livecall"
                    onChange={(e) => {
                      new_ticket = Object.assign(new_ticket, { 'log_as_livecall': e.target.checked });
                      this.setState({
                        new_ticket
                      })
                    }}
                    name="checkboxs" />
                  <label htmlFor="log_as_livecall" className="fitem-ck-txt mr-30">Log ticket as a live call</label>
                </div>
                <div className="fitem-check d-inline">
                  <input
                    onChange={(e) => {
                      new_ticket = Object.assign(new_ticket, { 'cc_to_customer': e.target.checked });
                      this.setState({
                        new_ticket
                      })
                    }}
                    checked={new_ticket.cc_to_customer}
                    type="checkbox"
                    className="fitem-ck-input"
                    id="cc_to_customer" />
                  <label htmlFor="cc_to_customer" className="fitem-ck-txt" style={{ 'color': 'red' }}>CC ticket to  Customer (Visible to Customer)</label>
                </div>
              </div>
            </div>


            <div className="fitem half-fitem">
              <div className="fitem half-fitem inline-check-list">
                <div className="review_images flex">
                  <img
                    src={smile}
                    alt=""
                    className={new_ticket.satisfaction === 'happy' || new_ticket.satisfaction === '' ? '' : 'disable_img'}
                    onClick={() => {
                      if (new_ticket.satisfaction === 'happy') {
                        new_ticket = Object.assign(new_ticket, { 'satisfaction': '' });
                      }
                      else {
                        new_ticket = Object.assign(new_ticket, { 'satisfaction': 'happy' });
                      }

                      this.setState({ new_ticket })
                    }}
                  />
                  <img
                    src={confused}
                    alt=""
                    className={new_ticket.satisfaction === 'neutral' || new_ticket.satisfaction === '' ? '' : 'disable_img'}
                    onClick={() => {
                      if (new_ticket.satisfaction === 'neutral') {
                        new_ticket = Object.assign(new_ticket, { 'satisfaction': '' });
                      }
                      else {
                        new_ticket = Object.assign(new_ticket, { 'satisfaction': 'neutral' });
                      }

                      this.setState({ new_ticket })
                    }}
                  />
                  <img
                    src={sad}
                    className={new_ticket.satisfaction === 'sad' || new_ticket.satisfaction === '' ? '' : 'disable_img'}
                    onClick={() => {
                      if (new_ticket.satisfaction === 'sad') {
                        new_ticket = Object.assign(new_ticket, { 'satisfaction': '' });
                      }
                      else {
                        new_ticket = Object.assign(new_ticket, { 'satisfaction': 'sad' });
                      }
                      this.setState({ new_ticket })
                    }}

                    alt="" />
                </div>
              </div>
              <div className="fitem half-fitem">
                <DatePicker
                  disabledDate={(current) => { return current < moment().startOf('day'); }}
                  name='request_callback'
                  format="MM/DD/YYYY hh:mm"
                  showTime
                  minuteStep={15}
                  inputReadOnly
                  suffixIcon={<i className="las la-calendar"></i>}
                  allowClear={false}
                  onChange={(e) => {
                    new_ticket = Object.assign(new_ticket, { 'request_callback': moment(e).format('YYYY-MM-DDThh:mm:ss') });
                    this.setState({
                      new_ticket
                    })
                  }}
                  value={new_ticket.request_callback ? moment(new_ticket.request_callback) : null}
                />
                {errors['new_ticket.request_callback'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.request_callback']}</label> : ''}
              </div>
            </div>

          </div>
          <div className="card">
            <div className="card-title">
              <h5>Ticket Details</h5>
            </div>
            <div className="card-body">
              <div className="form-group">
                <div className="fitem">
                  <label>Disposition</label>
                  <Select
                    placeholder="Disposition"
                    value={new_ticket.reason}
                    onChange={(e) => {
                      this.onSelectChange(e, 'reason')
                    }}
                    defaultValue=""
                  >
                    <Option value=""></Option>
                    {
                      map(reason, (item, i) => {
                        return (<Option value={item.value} key={i}>{item.label}</Option>)
                      })
                    }
                  </Select>
                  {errors['new_ticket.reason'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.reason']}</label> : ''}
                </div>

                <div className="fitem">
                  <label>Follow Up Date</label>
                  <DatePicker
                    name='follow_up_date'
                    format="MM/DD/YYYY"
                    suffixIcon={<i className="las la-calendar"></i>}
                    allowClear={false}
                    disabledDate={(current) => { return current < moment().startOf('day'); }}
                    onChange={(e) => {
                      new_ticket = Object.assign(new_ticket, { 'follow_up_date': moment(e).format('YYYY-MM-DD') });
                      this.setState({
                        new_ticket
                      })
                    }}
                    value={new_ticket.follow_up_date ? moment(new_ticket.follow_up_date) : null}
                  />
                  {errors['new_ticket.follow_up_date'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.follow_up_date']}</label> : ''}
                </div>

                <div className=" fitem">
                  <label>Priority</label>
                  <Select
                    defaultValue=""
                    value={new_ticket.priority}
                    onChange={(e) => {
                      this.onSelectChange(e, 'priority')
                    }}

                  >
                    <Option value="">All</Option>
                    <Option key="4" value="4"><span className="medium prty">Standard Priority</span></Option>
                    <Option key="3" value="3"><span className="high prty">High Priority</span></Option>
                  </Select>
                  {errors['new_ticket.priority'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.priority']}</label> : ''}
                </div>
                <div className=" fitem">
                  <label>Status</label>
                  <Select
                    defaultValue=""
                    value={new_ticket.status}
                    onChange={(e) => {
                      this.onSelectChange(e, 'status')
                    }}
                  >
                    <Option value="">All</Option>
                    <Option key='open' value="open"><span className="open prty">Open</span></Option>
                    <Option key='closed' value="closed"><span className="closed prty">Closed</span></Option>
                  </Select>
                  {errors['new_ticket.status'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.status']}</label> : ''}
                </div>
                <div className="fitem">
                  <b>Assigned to:</b>
                </div>
                <div className="fitem half-fitem">
                  <div className="fitem half-fitem">
                    <label>Group</label>
                    <Select
                      showSearch={true}
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      style={{ 'width': '100%' }}
                      className="mt-10"
                      placeholder="Please Select"
                      value={assigned_group_details.name || ''}
                      onChange={(a, b) => {
                        let { assigned_group_details } = this.state;
                        assigned_group_details = Object.assign(assigned_group_details, { name: a, uuid: b.key })
                        new_ticket = Object.assign(new_ticket, { 'assigned_group': b.key, assigned_user: '' })

                        //Get Staff based on group
                        this.props.getUserList({ 'limit': 1000, offset: 0, group__uuid: b.key, 'ordering': 'first_name', 'is_case_mgmt': true });
                        new_ticket = Object.assign(new_ticket, { assigned_user: '' });

                        this.setState({
                          assigned_group_details,
                          new_ticket,
                          AllUser:[]
                        })
                      }}
                      onBlur={() => { this.props.getGroupList({ 'offset': 0, 'limit': 15 }); }}
                      onSearch={(e) => this.props.getGroupList({ 'search': e })}
                    >
                      {uniqBy(GroupList.filter(item => item.status === 'active'), 'uuid', 'asc').map((item) => {
                        return (
                          <Option key={item.uuid} value={item.name}>{item.name}</Option>
                        )
                      })}
                      <Option value="">Assign to Group</Option>
                    </Select>
                    {errors['new_ticket.assigned_group'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.assigned_group']}</label> : ''}
                  </div>

                  <div className="fitem half-fitem">
                    <label>User</label>
                    <Select
                      disabled={new_ticket?.assigned_group}
                      showSearch
                      showArrow={true}
                      filterOption={false}
                      notFoundContent={null}
                      defaultValue=""
                      value={new_ticket.assigned_user || ""}
                      onChange={(e) => {
                        this.onSelectChange(e, 'assigned_user');
                      }}
                      onSearch={(e) => {
                        if (assigned_group_details.uuid) {
                          this.props.getUserList({ 'limit': 1000, 'search': e, group__uuid: assigned_group_details.uuid, 'ordering': 'first_name', 'is_case_mgmt': true})
                        } else {
                          this.props.getUserList({ 'limit': 1000, 'search': e, 'ordering': 'first_name', 'is_case_mgmt': true})
                        }
                      }
                      }
                      onBlur={() => {
                        if (assigned_group_details.uuid) {
                          this.props.getUserList({ 'offset': 0, 'limit': 1000, group__uuid: assigned_group_details.uuid, 'ordering': 'first_name', 'is_case_mgmt': true  });
                        }
                      }
                      }

                      className=" mt-10"
                    >
                      <Option value="">Assign to ASP Individual</Option>
                      {
                        map(AllUser, (item, i) => {
                          return (
                            <Option value={item.uuid} key={i}>{item.first_name} {item.last_name} </Option>
                          )
                        })
                      }

                    </Select>
                    {errors['new_ticket.assigned_user'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['new_ticket.assigned_user']}</label> : ''}
                  </div>
                </div>

                <button
                  // disabled={!is_btn_Active}
                  // className={is_btn_Active ? 'btn btn-warning btn-full' : 'btn btn-full disabled'}
                  className='btn btn-warning btn-full'
                  onClick={() => { this.ValidateTicket() }}
                >Create New Ticket</button>
              </div>
            </div>
          </div>
          {loader && <Loader />}
        </div >
      </div>
    );
  }
}

export default NewTicket;