import React, {useState }  from "react";
import { DatePicker, Radio } from "antd";
import moment from "moment-timezone";
import { useReport } from "../../Hooks/Report.Hook";
import LeadStageSelector from "../Filters/LeadStage.Selector";
import OutreachSelector from "../Filters/Outreach.Selector"
import HearAboutUs from "../Filters/HearAboutUs.Selector"
import ChannelAttribution from "../Filters/ChannelAttribution.Selector"
import StageSelector from "../Filters/Stage.Selector"
import ServiceTypeSelector from "../Filters/Services.Selector"
import FranchiseSelector from "../Filters/Franchise.Selector";
import ClientTypeSelector from "../Filters/ClientType.Selector";
import { setFranchiseSelected, getFranchiseSelected } from '../../../Utils/selectedFranchise';

const LeadCustomerReportSidebar = () => {
  const {
    leadReportclientType,
    setLeadReportclientType,
    leadStage,
    setLeadStage,
    outreach,
    setOutreach,
    channelAttribution,
    setChannelAttribution,
    hearAboutUs,
    setHearAboutUs,
    setCreationDatePeriod,
    setContactedDatePeriod,
    setLastServiceDatePeriod,
    setJoinedDatePeriod,
    stage,
    setStage,
    serviceType,
    setServiceType,
    franchise,
    setFranchise,
    errors,
  } = useReport();
  const handleLeadReportclientType = (value, { label }) => {
    setLeadReportclientType(value);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
 
  const handleLeadStageChange = (value, { label }) => {
    setLeadStage({ value, label });
  };
  const handleOutreachChange = (value, { label }) => {
    setOutreach({ value, label });
  };
  const handleChannelAttributionChange = (value, { label }) => {
    setChannelAttribution({ value, label });
  };
  const handleHearAboutUsChange = (value, { label }) => {
    setHearAboutUs({ value, label });
  };
  const handleStageChange = (value, { label }) => {
    setStage({ value, label });
  };
  const handleServiceTypeChange = (e) => {
    setServiceType(e);
  };

  const handleCreationDateChange = (e) => {

    if(e != null){
      setJoinedDatePeriod({
        start_date: e[0].format("YYYY-MM-DD"),
        end_date: e[1].format("YYYY-MM-DD"),
      });
      setCreationDatePeriod({
        creation_start_date: e[0].format("YYYY-MM-DD"),
        creation_end_date: e[1].format("YYYY-MM-DD"),
      });
    }else{
       setJoinedDatePeriod({
        start_date: undefined,
        end_date: undefined,
      });
      setCreationDatePeriod({
        creation_start_date: undefined,
        creation_end_date: undefined,
      });
    }
  };
  const handleContactedDateChange = (e) => {

    if(e != null){
      setContactedDatePeriod({
        contacted_start_date: e[0].format("YYYY-MM-DD"),
        contacted_end_date: e[1].format("YYYY-MM-DD"),
      });
    }else{
      setContactedDatePeriod({
        contacted_start_date: undefined,
        contacted_end_date: undefined,
      });
    }
  };
  
  const handleJoinedDateChange = (e) => {

    handleCreationDateChange(e);
    if(e != null){
      setJoinedDatePeriod({
        start_date: e[0].format("YYYY-MM-DD"),
        end_date: e[1].format("YYYY-MM-DD"),
      });
      setCreationDatePeriod({
        creation_start_date: e[0].format("YYYY-MM-DD"),
        creation_end_date: e[1].format("YYYY-MM-DD"),
      });
    }else{
      setJoinedDatePeriod({
        start_date: undefined,
        end_date: undefined,
      });
      setCreationDatePeriod({
        creation_start_date: undefined,
        creation_end_date: undefined,
      });
    }
  };
   
  const handleLastServiceDateChange = (e) => {

    if(e != null){
      setLastServiceDatePeriod({
        start_date: e[0].format("YYYY-MM-DD"),
        end_date: e[1].format("YYYY-MM-DD"),
      });
    }else{
      setLastServiceDatePeriod({
        start_date: undefined,
        end_date: undefined,
      });
    }
  };
  const handleFranchiseChange = (value, { label }) => {
    setFranchiseSelected(value, label);
    setFranchise({
      value: getFranchiseSelected().uuid,
      label: getFranchiseSelected().name,
    });
  };
  return (
      <>
          <div className="fitem">
            <label>
              <b>Franchise</b>
            </label>
            <FranchiseSelector
              handleChange={handleFranchiseChange}
              defaultValue={franchise}
            />
          </div>
          <div className="fitem">
            <label>
              <b>Stage</b>
            </label>
            <StageSelector
              handleChange={handleStageChange}
              defaultValue= {stage}
            />
            <label className="new_err_msg">
              {errors["stage_error"]}
            </label>
          </div>
          {
          (stage !== undefined)?
             (stage.value === "Lead")? 
                <div>
                    <div className="fitem">
                      <label>
                        <b>Type</b>
                      </label>
                      <ClientTypeSelector
                        handleChange={handleLeadReportclientType}
                        defaultValue={leadReportclientType}
                      />
                    </div>

                    <div className="fitem">
                      <label>
                        <b>Lead Creation Date (Range)</b>
                      </label>
                      <DatePicker.RangePicker
                        disabledDate={disabledDate}
                        onChange={handleCreationDateChange}
                      />
                    </div>
                    <div className="fitem">
                    <label>
                        <b>Lead Stage</b>
                      </label>
                      <LeadStageSelector
                        handleChange={handleLeadStageChange}
                        defaultValue={leadStage}
                      />
                    </div>

                    <div className="fitem">
                      <label>
                        <b>Lead Contacted Date (Range)</b>
                      </label>
                      <DatePicker.RangePicker
                        disabledDate={disabledDate}
                        onChange={handleContactedDateChange}
                      />
                    </div>

                    <div className="fitem">
                      <label>
                        <b>Outreach</b>
                      </label>
                      <OutreachSelector
                        handleChange={handleOutreachChange}
                        defaultValue={outreach}
                      />
                    </div>
                    <div className="fitem">
                      <label>
                        <b>Channel Attribution</b>
                      </label>
                      <ChannelAttribution
                        handleChange={handleChannelAttributionChange}
                        defaultValue={channelAttribution}
                      />
                    </div>
                    <div className="fitem">
                      <label>
                        <b>How did you hear about us?</b>
                      </label>
                      <HearAboutUs
                        handleChange={handleHearAboutUsChange}
                        defaultValue={hearAboutUs}
                      />
                    </div>
                </div>
            :(stage.value === "Customer")?
                <div>
                  <div className="fitem">
                    <label>
                      <b>Type</b>
                    </label>
                    <ClientTypeSelector
                        handleChange={handleLeadReportclientType}
                        defaultValue={leadReportclientType}
                      />
                  </div>
                  <div className="fitem">
                    <label>
                      <b>Joined Date (Range)</b>
                    </label>
                    <DatePicker.RangePicker
                      disabledDate={disabledDate}
                      onChange={handleJoinedDateChange}
                    />
                  </div>
                  <div className="fitem">
                    <label>
                      <b>Last Service Date (Range)</b>
                    </label>
                    <DatePicker.RangePicker
                      disabledDate={disabledDate}
                      onChange={handleLastServiceDateChange}
                    />
                  </div>
                  <div className="fitem">
                    <label>
                      <b>Service Type</b>
                    </label>
                    <ServiceTypeSelector
                      handleChange={handleServiceTypeChange}
                      defaultValue={serviceType}
                    />
                  </div>
                  <div className="fitem">
                      <label>
                        <b>Outreach</b>
                      </label>
                      <OutreachSelector
                        handleChange={handleOutreachChange}
                        defaultValue={outreach}
                      />
                  </div>
                  <div className="fitem">
                      <label>
                        <b>Channel Attribution</b>
                      </label>
                      <ChannelAttribution
                        handleChange={handleChannelAttributionChange}
                        defaultValue={channelAttribution}
                      />
                    </div>
                    <div className="fitem">
                      <label>
                        <b>How did you hear about us?</b>
                      </label>
                      <HearAboutUs
                        handleChange={handleHearAboutUsChange}
                        defaultValue={hearAboutUs}
                      />
                    </div>
                </div>
            :
            <></>
          :<></>
        }
    </>
  );
};

export default LeadCustomerReportSidebar;
