import React,{useEffect} from "react";
import { Select } from "antd";
import { orderBy, _ } from 'lodash';
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getFranchiseListAll } from '../Action/Franchise';
import { getFranchiseSelected } from '../Utils/selectedFranchise';
import { addUserDetails, addSelectedFranchise, addFranchiseList, addFranchiseFromDrawer, setLoggedInUserEmail } from "../Store/proposalTableSlice";

const ProposalFranchiseFilter = (props) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const proposalData = useSelector((state) => state.proposalsData);

  useEffect(() =>{
    let authUser = JSON.parse(localStorage.getItem('authUser'));
    const loggedInUserRole = authUser?.user?.user_roles?.map(x => x.name);
    dispatch(addUserDetails(loggedInUserRole));
    dispatch(setLoggedInUserEmail(authUser?.user?.email));
    const selectedFranchise = loggedInUserRole?.includes('franchise_owner') ? getFranchiseSelected().uuid : authUser?.user?.associated_field_offices[0]?.uuid;
    dispatch(addSelectedFranchise(selectedFranchise));
    dispatch(addFranchiseFromDrawer(selectedFranchise));
    getFranchiseApi();
  },[])

  const getFranchiseApi = async () => {
    const response = await getFranchiseListAll();
    dispatch(addFranchiseList(response))
  }

  const leadingZeros = (value, size) => {
    let s = String(value);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
  }

  const onFranchiseChange = (e) =>{
    if(props.view === "drawer") {
      dispatch(addFranchiseFromDrawer(e))
    }
    else{
      dispatch(addSelectedFranchise(e));
    }
  }

  return (
        <>
          <div className="fitem">
            <Select
              className={props?.view === "drawer" ? "" : "swhite franchise_type" }
              name="franchise"
              value={props.view === "drawer" ? proposalData.drawerFranchiseSelected : proposalData.franchiseSelected}
              onChange={(e) => {onFranchiseChange(e)}}
            >
              <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>
              {orderBy(proposalData?.franchiseList, [item => item.name.toLowerCase()], "asc")?.map((option) => (
                <Option
                  key={option?.uuid}
                  value={option?.uuid}
                >{`${leadingZeros(option?.id, 3)} ${option?.name}`}</Option>
              ))}
            </Select>
          </div>
        </>
  )   
}

export default ProposalFranchiseFilter;