import React, { useEffect } from "react";
import { DatePicker, ConfigProvider } from "antd";
import moment from 'moment-timezone';
import "moment/locale/en-gb";
import en_GB from "antd/lib/locale-provider/en_GB";
import { map, orderBy, _ } from 'lodash';
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setEndDate, setStartDate, setDateType, setStartDateDrawer, setEndDateDrawer } from "../Store/proposalTableSlice";

const ProposalDateFilter = (props) => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const proposalData = useSelector((state) => state.proposalsData);

  const setSelectedDateRange = (e) => {
    if (e) {
      if(props?.view === "drawer"){
        dispatch(setStartDateDrawer(moment(e[0]).format('YYYY-MM-DD')))
        dispatch(setEndDateDrawer(moment(e[1]).format('YYYY-MM-DD')))
      }
      else{
      dispatch(setStartDate(moment(e[0]).format('YYYY-MM-DD')))
      dispatch(setEndDate(moment(e[1]).format('YYYY-MM-DD')))
      }
      dispatch(setDateType(''))
    } else {
      dispatch(setStartDate(null))
      dispatch(setEndDate(null))
      dispatch(setStartDateDrawer(null))
      dispatch(setEndDateDrawer(null))
      dispatch(setDateType(''))
    }
  }

  return (
    <>
      <div className="fitem" style={{ padding: "0px 4px" }}>
        <ConfigProvider locale={en_GB}>
          <RangePicker
            format={"MM/DD/YYYY"}
            className="date_ranger dwhite"
            dropdownClassName="dropdown_date_range"
            prefixIcon={<i className="las la-calendar"></i>}
            onChange={(e) => {
              setSelectedDateRange(e);
            }}
            value={
              proposalData.startDate !== null && props.view !== "drawer"
                ? [moment(proposalData.startDate), moment(proposalData.endDate)] : 
                proposalData.startDateDrawer !== null && props.view === "drawer" 
                ? [moment(proposalData.startDateDrawer), moment(proposalData.endDateDrawer)] 
                : ""
            }
          />
        </ConfigProvider>
      </div>
    </>
  )
}

export default ProposalDateFilter;