import React from "react";
import { map } from "lodash";
import { Col, Row, Checkbox } from "antd";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import appData from "../../../../Utils/constants";

import { updateInvoicePreference } from "../../../../Store/techJourneyDataSlice";

const InvoicePreferences = () => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const { invoicePreferences } = techJourneyState;

  return (
    <>
      <h4 className="mt-10">Invoice Preferences</h4>
      <Row>
        {map(invoicePreferences, (item, index) => {
          return (
            <>
              <Col
                span={12}
                order={1}
              >
                <Checkbox
                  name={item.key}
                  key={item.key}
                  checked={
                    (invoicePreferences &&
                      invoicePreferences[item.key].isChecked) ||
                    false
                  }
                  onChange={(e) => {
                    const payload = {};
                    payload[item.key] = {
                      ...item,
                      isChecked: e.target.checked,
                    };
                    dispatch(updateInvoicePreference(payload));
                  }}
                  
                >
                  {item?.label}
                </Checkbox>
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};

export default InvoicePreferences;
