import { DatePicker } from "antd";
import React from "react";
import "../../../Assets/css/proposalBase.css";
import { getCustomerDetail } from "../../../Action/Accounting";
import { useEffect } from "react";
import { useState } from "react";
import { map, isEmpty } from "lodash";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { primaryNumberProposal } from "../../../Store/ProposalDataSlice";
import { due_date_select } from "../../../Store/ProposalDataSlice";
import moment from "moment-timezone";
const UserProposalInfo = (props) => {
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);

  const { customer } = props;
  const [durdate, setDuedate] = useState("");
  const [primaryEmail, setprimaryEmail] = useState("");

  const getCustomerData = async () => {
    const result = await getCustomerDetail(customer.uuid);
  };
  useEffect(() => {
    getCustomerData();
    renderPrimaryInfo();
  }, []);
  const renderPrimaryInfo = () => {
    // let  primaryEmail = '';
    if (!isEmpty(customer?.phone_numbers)) {
      for (let i = 0; i < customer?.phone_numbers?.length; i++) {
        const item = customer?.phone_numbers?.[i];
        if (item?.primary) {
          dispatch(primaryNumberProposal(item?.phone));
          break;
        }
      }
    }

    !isEmpty(customer?.emailaddress_set) &&
      map(customer?.emailaddress_set, (item) => {
        setprimaryEmail(item?.primary && item?.email);
      });
  };
  const onProposaLDueDateSelect = (e) => {
    dispatch(due_date_select(moment(e).format("YYYY-MM-DD")))
    
  };
  return (
    <div className="proposal_info">
      <div className="maim_data">
        <span className="number_date">
          <label className="text___color">Proposal Number</label>
          <input style={{ border: "0" }}
          value={proposals.proposal_number}
          disabled={true}></input>
        </span>
        <span className="number_date">
          <label className="text___color">Proposal Date</label>
          <DatePicker
            disabled={proposals?.datepicker_state==false}  
            style={{ width: "100%", padding: "2px" }}
            name='date'
            format="YYYY-MM-DD"
            allowClear={false}
            value={moment(proposals.proposal_due_date)}
            className={`proposal-date-picker ${proposals.datepicker_state==false ? 'disabled': ''}`}
            onChange={onProposaLDueDateSelect}
            disabledDate={(current) => {
              return current && current <= moment().startOf("day");
            }}
          />
        </span>
      </div>
      <div className="main_sec_data">
        <span>
          <label>
            <b>Customer Name</b>
          </label>
          <p>{`${customer.first_name} ${customer.last_name}`}</p>
        </span>
        <span>
          <label>
            <b>Email</b>
          </label>
          <p>{`${primaryEmail ? primaryEmail : customer?.email}`}</p>
        </span>
        <span>
          <label>
            <b>Phone Number</b>
          </label>
          <p>
            {!isEmpty(customer?.phone_numbers)
              ? proposals.primary_no
                ? `${proposals.primary_no} `
                : `${customer?.phone_numbers?.[0]}`
              : ""}
          </p>
        </span>
      </div>
      <div className="main_thee_data">
        <label>
          <b>Service Address</b>
        </label>
        <p>{customer?.service_address[0]?.formatted_address}</p>
      </div>
    </div>
  );
};
export default UserProposalInfo;
