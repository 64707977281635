import React, { useEffect, useState, useRef } from "react";
import * as yup from "yup";
import _, { map, cloneDeep } from "lodash";
import Loader from "../../../Components/Loader";
import "../../../Assets/css/proposalBase.css";
import {
  ConfigProvider,
  Select,
  Table,
  Modal,
  message,
  Empty,
  Spin,
} from "antd";
import DataTable from "./DataTable";
import {
  getPrices,
  getAvalaraTaxValue,
} from "../../../Action/Customer/index";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { additionalWork, updateAdditionalWork, deleteAdditionalWork } from "../../../Store/ProposalDataSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";


const AdditionalProposal = (props) => {
    const proposals = useSelector((state) => state.proposalData);
    const ref = useRef();
    const [stateone, setStateone] = useState({
      errors: {},
      rate: "",
      quantity_item: ""
    });
    const [state, setState] = useState({
      status: "",
      technician: "",
      date: "",
      time: "",
      services_lineitem: [],
      update_services_lineitem: [],
      delete_services_lineitem: [],
      problem_description: "",
      work_order_name: "",
      completion_notes: "",
      tech_notes: "",
      tech_picture: [],
      customer_sign: "",
      customer_picture: [],
      addons: [],
      errors: {},
      email: false,
      // bill_for_cancelization: false
    });
    const [lineItem, setlineItem] = useState({
      include_labor: false,
      include_bundle: false,
    });
    const [updateLineItem, setUpdateLineItem] = useState({
      include_labor: false,
      include_bundle: false,
    });
    const [services_lineitem, setservices_lineitem] = useState([]);
    const [editMode, seteditMode] = useState(false);
    const [isAddLineVisible, setisAddLineVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalNoTax, setTotalNoTax] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [totalTaxSO, setTotalTaxSO] = useState(0);
    const [totalPlusTaxSO, setTotalPlusTaxSO] = useState(0);
    const [is_description_Edit, set_is_description_Edit] = useState(false);
    const [is_quantity_Edit, set_is_quantity_Edit] = useState(false);
    const [is_unitCost_Edit, set_is_unitCost_Edit] = useState(false);
    const [is_price_Edit, set_is_price_Edit] = useState(false);
    const [taxRate, settaxRate] = useState(0);
    const [addons, setAddons] = useState([]);
    const [take_diagnostic, settake_diagnostic] = useState(true);
    const [loader, setLoader] = useState(false);
    const [service_order, setservice_order] = useState({
      price: 0,
      servicetype: "",
    });
    const [errormessagestate , seterrormessagestate] = useState(false);
    const dispatch = useDispatch();

  
    function ServiceDetails_() {
      const seachvalidate = (e) => {
        e.preventDefault();
        setTimeout(() => {
          let { rate,quantity_item } = stateone;
          const schema = yup.object().shape({
          rate:yup.number().nullable(true).typeError("Rate is required").test( 'decimal-places', 'Please Enter Valid Rate(upto two decimal places are allowed)', (value) =>/^(-?\d+(\.\d{1,2})?)?$/.test(value)),   
          quantity_item: yup.number().moreThan(0, 'Quantity is required"').typeError("Quantity is required").nullable(true).test( 'decimal-places', 'Please Enter Valid Quantity(Positive or upto two decimal places are allowed)', (value) =>/^(\d+(\.\d{1,2})?)?$/.test(value)),
        });
          const data = { rate, quantity_item };
          let IsValid;
          let err_data = {};
          schema.isValid(data).then(function (valid) {
            IsValid = valid;
          });
          setTimeout(() => {
            if (IsValid) {
              onokbtn();
            } else {
              schema.validate(data, { abortEarly: false }).catch(function (err) {
                map(err.inner, (item) => {
                  err_data[item.path] = item.message;
                });
                setStateone({
                  ...stateone,
                  errors: err_data,
                });
                console.log("err_data:", err_data);
              });
            }
          }, 100);
        }, 300);
      };
      let { errors } = stateone;
  
    const onokbtn=(event)=>{
        if (editMode) {
          updateLine(event);
        } else {
          addLineItem();
        }
        setisAddLineVisible(false);
        clearLineItem();
    }
  
      useEffect(() => {
        CalculateTotal();
        calculateNoTax();
        calculateTax();
      }, [services_lineitem]);

      useEffect(()=> {
        if(props?.proposalRedirectData?.additional_work?.length> 0){
          setservices_lineitem(props.proposalRedirectData.additional_work);
        }
      }, [props?.proposalRedirectData])

      useEffect(()=> {
        dispatch(additionalWork(cloneDeep(services_lineitem)));
      }, [JSON.stringify(services_lineitem)]);
  
      useEffect(()=> {
        dispatch(updateAdditionalWork(cloneDeep(state.update_services_lineitem)));
      }, [JSON.stringify(state.update_services_lineitem)]);
  
      useEffect(()=> {
        dispatch(deleteAdditionalWork(cloneDeep(state.delete_services_lineitem)));
      }, [JSON.stringify(state.delete_services_lineitem)]);

      useEffect(() => {
        getAvalaraTax();
      },[]);

      // useEffect(() => {
      //   console.log('state', state)
      // }, [state])
  
      useEffect(() => {
        //Remove Diagnostic quote
        removeDiagnostic();
        const index = services_lineitem
          .map((e) => e.repair_code)
          .indexOf(state.servicetype);
  
        let serviceLine = services_lineitem.find(
          (line) =>
            line.repair_code?.toLowerCase() === state.servicetype?.toLowerCase()
        );
        const updateLine = {
          ...serviceLine,
          billable: take_diagnostic,
        };
  
        let lines = services_lineitem;
        lines[index] = updateLine;
        setservices_lineitem(lines);
      }, [take_diagnostic]);
  
      useEffect(() => {
        CalculateTotalSO();
      }, [totalTaxSO, service_order]);

      const totalPriceDescription = (row) => {
        return amount(row.unit_cost * row.quantity);
      };
  
      const editLine = (record) => {
        let temp_Obj = {
          errors: {},
          rate: record?.unit_cost,
          quantity_item: record?.quantity,
        };
        ref.current = record;
        setStateone(temp_Obj);
        seterrormessagestate(true);
      };

      useEffect(() => {
        if (  errormessagestate == true &&  stateone["rate"] != "" && stateone["quantity_item"] != "" ) {
          let temp = ref.current;
          setisAddLineVisible(true);
          setlineItem(temp);
          setUpdateLineItem({ ...temp });
          seteditMode(true);
          setvalue(temp.repair_code || "");
          CalculateTotal();
          calculateNoTax();
          seterrormessagestate(false);
          ref.current = "";
        }
      }, [stateone, errormessagestate]);

      const addLineItem = () => {
        // console.log('lineItem', lineItem)
        lineItem.total = lineItem.unit_cost * lineItem.quantity;
        lineItem.quantity = parseFloat(lineItem.quantity).toFixed(2);
        lineItem.unit_cost = parseFloat(lineItem.unit_cost);
        lineItem.is_taxable = lineItem.is_taxable ? lineItem.is_taxable : false;
        lineItem.qb_account = lineItem.qb_account ? lineItem.qb_account : "";
        const alreadyExist = services_lineitem.find((item) => {
          return (
            item.repairCode == lineItem.repairCode &&
            item.description == lineItem.description &&
            item.unit_cost == lineItem.unit_cost
          );
        });
        if (alreadyExist) {
          message.warn(
            "The repair code must be different in at least unit cost or description"
          );
          return;
        }
  
        let state_items = state.services_lineitem;
        let items = services_lineitem;
        items.push(lineItem);
  
        const isAdded = state_items.indexOf(lineItem);
        if (isAdded < 0) state_items.push(lineItem);
  
        setservices_lineitem(items);
        setState((s) => ({ ...s, services_lineitem: state_items }));
  
        CalculateTotal();
        calculateNoTax();
        calculateTax();
  
        if (lineItem.include_labor) {
          createLabor();
          if (lineItem.include_bundle) {
            createBundle();
          }
        }
  
        if (lineItem.is_taxable) {
          applyTax(lineItem, true);
        }
        setcodeOptions([]);
      };
  
      const createLabor = () => {
        const laborLine = {
          repair_code: lineItem.repair_code + "-Labor",
          description: "LBR-" + lineItem.description,
          is_taxable: lineItem.apply_labor_tax ? lineItem.apply_labor_tax : 0,
          unit_cost: lineItem.totallabor ? lineItem.totallabor : 0,
          quantity: lineItem.quantity,
          total: lineItem.quantity * lineItem.totallabor,
          qb_account: lineItem.qb_account,
        };
  
        let state_items = state.services_lineitem;
        state_items.push(laborLine);
  
        setState((s) => ({ ...s, services_lineitem: state_items }));
  
        let items = services_lineitem;
        const isAdded = items.indexOf(laborLine);
        if (isAdded < 0) items.push(laborLine);
  
        setservices_lineitem(items);
  
        if (laborLine.is_taxable) {
          applyTax(laborLine, true);
        }
  
        CalculateTotal();
        calculateNoTax();
        calculateTax();
      };
  
      const createBundle = () => {
        const item_unitCost = parseFloat(lineItem.unit_cost);
        const labor_unitCost = lineItem.totallabor ? lineItem.totallabor : 0;
  
        const item_total = lineItem.unit_cost * lineItem.quantity;
        const labor_total = lineItem.quantity * lineItem.totallabor;
  
        const bundleLine = {
          repair_code: lineItem.repair_code,
          bundle_code: lineItem.repair_code,
          description: lineItem.description,
          is_taxable: lineItem.is_taxable && lineItem.apply_labor_tax,
          unit_cost: item_unitCost + labor_unitCost,
          quantity: lineItem.quantity,
          total: item_total + labor_total,
          qb_account: lineItem.qb_account,
        };
  
        let state_items = state.services_lineitem;
        state_items.push(bundleLine);
  
        setState((s) => ({ ...s, services_lineitem: state_items }));
  
        let items = services_lineitem;
        const isAdded = items.indexOf(bundleLine);
        if (isAdded < 0) items.push(bundleLine);
  
        setservices_lineitem(items);
  
        if (bundleLine.is_taxable) {
          applyTax(bundleLine, false, true);
        } else if (lineItem.is_taxable || lineItem.apply_labor_tax) {
          applyBundleTax(bundleLine);
        }
  
        CalculateTotal();
        calculateNoTax();
        calculateTax();
      };
  
      const updateLine = async (updatedBillable = false) => {
        if (
          updateLineItem.repairCode == lineItem.repairCode &&
          updateLineItem.description == lineItem.description &&
          updateLineItem.unit_cost == lineItem.unit_cost &&
          updateLineItem.quantity == lineItem.quantity
        ) {
          return;
        }
        const alreadyExist = services_lineitem.find((item) => {
          return (
            item.repairCode == lineItem.repairCode &&
            item.description == lineItem.description &&
            item.unit_cost == lineItem.unit_cost
          );
        });
        if (alreadyExist) {
          if (
            updateLineItem.repairCode != alreadyExist.repairCode ||
            updateLineItem.description != alreadyExist.description ||
            updateLineItem.unit_cost != alreadyExist.unit_cost
          ) {
            message.warn(
              "You already have that same item with that price added, please modify the qty"
            );
            return;
          }
        }
        let updateLine = { ...alreadyExist, ...lineItem };
        let lines = services_lineitem.map((item) => {
          if (
            item.repairCode == updateLineItem.repairCode &&
            item.description == updateLineItem.description &&
            item.unit_cost == updateLineItem.unit_cost
          ) {
            return { ...alreadyExist, ...lineItem };
          }
          return item;
        });
        const removeBundle = alreadyExist?.include_bundle !== updateLine.include_bundle;
        const removeLabor = alreadyExist?.include_labor !== updateLine.include_labor;

        setservices_lineitem(lines);
  
        if (updateLine.uuid) {
          let currentUpdatingLineItem = (state.update_services_lineitem).filter((curr) => { return curr.uuid === updateLine.uuid});
          let nonUpdatingLineItem = (state.update_services_lineitem).filter((curr) => { return curr.uuid !== updateLine.uuid});
  
          let updatedValues = {
            uuid: updateLine.uuid,
            is_taxable:false
          };
  
          if (is_description_Edit) {
            if (currentUpdatingLineItem?.length > 0)
              currentUpdatingLineItem[0].description = updateLine.description;
            else updatedValues["description"] = updateLine.description;
          }
          if (is_quantity_Edit) {
            if (currentUpdatingLineItem?.length > 0) {
              currentUpdatingLineItem[0].quantity = parseFloat(updateLine.quantity).toFixed(2);
              currentUpdatingLineItem[0].total = updateLine.quantity * updateLine.unit_cost;
            }
            else {
              updatedValues["quantity"] = parseFloat(updateLine.quantity).toFixed(2);
              updatedValues["total"] = 
                updateLine.quantity * updateLine.unit_cost
              ;
            }
          }
          if (is_unitCost_Edit) {
            if (currentUpdatingLineItem?.length > 0) {
              currentUpdatingLineItem[0].unit_cost = updateLine.unit_cost;
              currentUpdatingLineItem[0].total = updateLine.quantity * updateLine.unit_cost;
            }
            else {
              updatedValues["unit_cost"] = updateLine.unit_cost;
              updatedValues["total"] = 
                updateLine.quantity * updateLine.unit_cost;
            }
          }
          if (is_price_Edit) {
            if (currentUpdatingLineItem?.length > 0)
              currentUpdatingLineItem[0].price = updateLine.pricet;
            else updatedValues["price"] = updateLine.price;
          }
          if (updatedBillable) {
            if (currentUpdatingLineItem?.length > 0)
              currentUpdatingLineItem[0].billable = updateLine.billable;
            else updatedValues["billable"] = updateLine.billable;
          }
          let updatedLineitem = state.update_services_lineitem;
  
          if(currentUpdatingLineItem?.length == 0){
          updatedLineitem.push(updatedValues);}
          else {
            nonUpdatingLineItem.push(currentUpdatingLineItem[0])
          }
          setState((s) => ({
            ...s,
            update_services_lineitem: updatedLineitem || nonUpdatingLineItem,
          }));
        } else { 
          setState((s) => ({ ...s, services_lineitem: lines }));
        }
  
        if (removeBundle || removeLabor) {
          removeBundleLabor(lineItem);
        }
        CalculateTotal();
        calculateNoTax();
      };
  
      const clearLineItem = () => {
        setlineItem({
          repair_code: "",
          description: "",
          unit_cost: 0,
          quantity: (1).toFixed(2),
          price: 0,
          include_labor: false,
        });
        setStateone({
          errors: {},
          rate:"",
          quantity_item:"",
        });
        setvalue("");
      };
  
      const deleteLine = async (row) => {
        // console.log('row', row)
        let line = {};
        let lines = [];
        if (row.bundle_code) {
          line = services_lineitem.find((item) => {
            return (
              item.repair_code === row.repair_code &&
              item.bundle_code === row.bundle_code
            );
          });
  
          let index = services_lineitem.indexOf(line);
          services_lineitem.splice(index, 1); //Remove bundle
          lines = services_lineitem;
  
          setservices_lineitem(lines);
        } else {
          line = services_lineitem.find((item) => {
            return (
              _.isEqual(item, row) &&
              (item.bundle_code === undefined || item.bundle_code === null)
            );
          });
          lines = services_lineitem.filter((item) => !_.isEqual(item, row));
          setservices_lineitem(lines);
        }
        // console.log('line', line)
        if (line.uuid) {
          let delete_services_lineitem = state.delete_services_lineitem;
          delete_services_lineitem.push(line.uuid);
          setState((s) => ({
            ...s,
            delete_services_lineitem: delete_services_lineitem,
          }));
        } else {
          setState((s) => ({
            ...s,
            services_lineitem: lines.filter((item) => (item.uuid ? false : true)),
          }));
        }
  
        if (row.bundle_code || !row.repair_code?.includes("Labor")) {
          //Delete Labor line
          let labor_line = services_lineitem.find((item) => {
            return item.repair_code === row.repair_code + "-Labor";
          });
  
          if (labor_line) {
            let indexLabor = lines.indexOf(labor_line);
            lines.splice(indexLabor, 1);
  
            if (labor_line.uuid) {
              let delete_services_lineitem = state.delete_services_lineitem;
              delete_services_lineitem.push(labor_line.uuid);
              setState((s) => ({
                ...s,
                delete_services_lineitem: delete_services_lineitem,
              }));
            } else {
              setState((s) => ({
                ...s,
                services_lineitem: lines.filter((item) =>
                  item.uuid ? false : true
                ),
              }));
            }
          }
        }
  
        const code = row.repair_code.includes("Labor")
          ? row.repair_code.split("-Labor")[0]
          : row.repair_code;
        let bundle_line = services_lineitem.find((item) => {
          return item.bundle_code === code;
        });
  
        if (!row.bundle_code && (row.include_bundle || bundle_line)) {
          //Delete Bundle line
          if (bundle_line) {
            let indexbundle = lines.indexOf(bundle_line);
            lines.splice(indexbundle, 1);
            if (bundle_line.uuid) {
              let delete_services_lineitem = state.delete_services_lineitem;
              delete_services_lineitem.push(bundle_line.uuid);
              setState((s) => ({
                ...s,
                delete_services_lineitem: delete_services_lineitem,
              }));
            } else {
              setState((s) => ({
                ...s,
                services_lineitem: lines.filter((item) =>
                  item.uuid ? false : true
                ),
              }));
            }
          }
        }
  
        if (row.bundle_code) {
          //Delete Product line
          let product_line = services_lineitem.find((item) => {
            return item.repair_code === row.repair_code;
          });
  
          if (product_line) {
            let indexbundle = lines.indexOf(product_line);
            lines.splice(indexbundle, 1);
            if (product_line.uuid) {
              let delete_services_lineitem = state.delete_services_lineitem;
              delete_services_lineitem.push(product_line.uuid);
              setState((s) => ({
                ...s,
                delete_services_lineitem: delete_services_lineitem,
              }));
            } else {
              setState((s) => ({
                ...s,
                services_lineitem: lines.filter((item) =>
                  item.uuid ? false : true
                ),
              }));
            }
          }
        }
  
        return new Promise((resolve, reject) => {
          setservices_lineitem(lines);
          CalculateTotal();
          calculateTax();
          calculateNoTax();
          resolve(true);
        });
      };
  
      const CalculateTotal = () => {
        let _total = 0;
  
        services_lineitem.forEach((item) => {
          if (!item.bundle_code) {
            const base_price = +item.quantity * +item.unit_cost;
            if (item.is_taxable)
              _total += (taxRate * base_price) / 100 + base_price;
            else _total += base_price;
          }
        });
  
        let _addons = [];
        setAddons(_addons);
  
        services_lineitem.forEach((item) => {
          if (item.repair_code && item.repair_code !== state.servicetype) {
            let repairCode = item.repair_code.includes("Labor")
              ? item.repair_code.split("-Labor")[0]
              : item.repair_code;
            const bundleItem = services_lineitem.find(
              (i) => i.repair_code === repairCode && i.bundle_code === repairCode
            );
  
            if (!item.include_bundle) {
              if (!bundleItem) {
                _addons.push({
                  name: item.description,
                  price: +item.quantity * +item.unit_cost,
                });
              }
            } else {
              if (bundleItem)
                _addons.push({
                  name: bundleItem.description,
                  price: +bundleItem.quantity * +bundleItem.unit_cost,
                });
            }
            setAddons(_addons);
          }
        });
  
        if (!take_diagnostic)
          _total -=  +service_order?.price;
  
        setTotal(_total);
      };
      const CalculateTotalSO = () => {
        let _total = service_order.price + parseFloat(totalTaxSO);
        setTotalPlusTaxSO(_total);
      };
  
      const removeDiagnostic = () => {
        const lines = services_lineitem.filter((item) => {
          return (
            item.repair_code?.toLowerCase() !== state.servicetype?.toLowerCase()
          );
        });
      };
  
      const applyBundleTax = (lineItem) => {
        const index = services_lineitem
          .map((e) => e.bundle_code)
          .indexOf(lineItem.repair_code);
        let updateLine = services_lineitem.at(index);
  
        let tax_value = 0;
        let total_value = 0;
        services_lineitem
          .filter((f) => f.is_taxable === true)
          .forEach((each) => {
            tax_value += (+taxRate / 100) * (+each.unit_cost * +each.quantity);
            total_value += each.unit_cost * each.quantity;
          });
        updateLine.tax = tax_value.toString();
        updateLine.total = total_value.toString();
  
        let services_lines_added = state.services_lineitem;
        const indexEdited = services_lines_added
          .map((e) => e.repair_code)
          .indexOf(lineItem.repair_code);
        let updateLineEdited = services_lines_added.at(indexEdited);
        updateLineEdited.tax = updateLine.tax;
        updateLineEdited.total = updateLine.total;
        services_lines_added[indexEdited] = updateLineEdited;
  
        setservices_lineitem((s) => ({ ...s, services_lines_added }));
        setState((s) => ({ ...s, services_lineitem: services_lines_added }));
      };
  
      const applyTax = (
        lineItem,
        isLabor = false,
        isBundle = false,
        updateTax = false,
        originValue = null
      ) => {
        let index;
  
        const original_value = originValue ?? lineItem.is_taxable;
        if (!isBundle) {
          index = services_lineitem.indexOf(lineItem);
        } else {
          index = services_lineitem
            .map((e) => e.bundle_code)
            .indexOf(lineItem.repair_code);
        }
  
        let updateLine = services_lineitem.at(index);
  
        if (updateTax) {
          updateLine.is_taxable = lineItem.is_taxable;
        } else {
          updateLine.is_taxable =
            isLabor || isBundle
              ? lineItem.is_taxable
              : updateLine.is_taxable
              ? !updateLine.is_taxable
              : true;
        }
  
        let lines = services_lineitem;
  
        if (updateLine.is_taxable) {
          updateLine.is_taxable = true;
          updateLine.tax = (
            (+taxRate / 100) *
            (lineItem.unit_cost * lineItem.quantity)
          )
            .toFixed(2)
            .toString();
          updateLine.total = (lineItem.unit_cost * lineItem.quantity)
            .toFixed(2)
            .toString();
          lines[index] = updateLine;
        } else {
          updateLine.is_taxable = false;
          updateLine.tax = "0.00";
          updateLine.total = (lineItem.unit_cost * lineItem.quantity)
            .toFixed(2)
            .toString();
          lines[index] = updateLine;
        }
  
        if (updateLine.is_taxable !== original_value || isBundle) {
          if (updateLine.uuid) {
            let update_services_lineitem = []
            for (let up = 0; up < lines.length; up++){
                update_services_lineitem.push({
                  uuid: lines[up].uuid,
                  tax: lines[up].tax,
                  total: lines[up].total,
                  is_taxable: lines[up].is_taxable,
                  billable:lines[up].billable,
                  quantity:lines[up].quantity,
                  unit_cost:lines[up].unit_cost,
                  repair_code: lines[up].repair_code,
                });
            }
            setState((s) => ({
              ...s,
              update_services_lineitem: update_services_lineitem,
            }));
          } else {
            let services_lines_added = state.services_lineitem;
            const indexEdited = services_lines_added
              .map((e) => e.repair_code)
              .indexOf(lineItem.repair_code);
            let updateLineEdited = services_lines_added.at(indexEdited);
  
            updateLineEdited.tax = updateLine.tax;
            updateLineEdited.total = updateLine.total;
            services_lines_added[indexEdited] = updateLineEdited;
  
            setservices_lineitem((s) => ({ ...s, services_lines_added }));
            setState((s) => ({ ...s, services_lineitem: services_lines_added }));
          }
        }
  
        setservices_lineitem(lines);
        calculateNoTax();
        CalculateTotal();
        calculateTax();
      };
  
      const removeBundleTax = (item) => {
        const repairCode = item.repair_code.includes("Labor")
          ? item.repair_code.split("-Labor")[0]
          : item.repair_code;
        const repairLaborCode = item.repair_code.includes("Labor")
          ? item.repair_code
          : item.repair_code + "-Labor";
  
        const product = services_lineitem.find(
          (i) =>
            i.repair_code === repairCode &&
            (i.bundle_code === null || i.bundle_code === undefined)
        );
        const labor = services_lineitem.find(
          (i) => i.repair_code === repairLaborCode
        );
        let bundle = services_lineitem.find((i) => i.bundle_code === repairCode);
        if (bundle) {
          const originValueBundle = bundle.is_taxable;
          if (product.is_taxable && labor.is_taxable) {
            bundle.is_taxable = true;
          } else if (!product.is_taxable || !labor.is_taxable) {
            bundle.is_taxable = false;
          }
  
          applyTax(bundle, false, true, originValueBundle);
        }
      };
  
      const calculateNoTax = () => {
        let totalNoTax = 0;
  
        services_lineitem.forEach((line) => {
          const _total = line.unit_cost * line.quantity;
          if (!line.bundle_code) totalNoTax += +_total;
        });
  
        if (!take_diagnostic)
          totalNoTax -= +service_order?.price

  
        setTotalNoTax(totalNoTax);
      };
  
      const calculateTax = () => {
        let totalTax = 0;
  
        if (service_order?.service_type === "maintenance") {
          setTotalTaxSO(
            service_order?.tax_amount ? service_order?.tax_amount : 0
          );
        }
        services_lineitem.forEach((line) => {
          if (!line.bundle_code)
            if (line.is_taxable) {
              const tax = (+taxRate / 100) * (line.unit_cost * line.quantity);
              totalTax += +tax;
            }
        });
        setTotalTax(totalTax);
      };
  
      const getAvalaraTax = async () => {
        const response = await getAvalaraTaxValue(
          props.customer?.field_office?.zip_code
        );
        settaxRate(amount(response?.tax_rate ?? state.avalara_tax_rate));
      };
  
      useEffect(() => {
        let serviceTaxable = services_lineitem.filter((item) => item.is_taxable);
        serviceTaxable.forEach((line) => {
          line.is_taxable = false;
          applyTax(line);
        });
        calculateTax();
  
        setState((s) => ({ ...s, tax_rate: amount(taxRate) }));
      }, [taxRate, service_order]);
  
      const renderDescription = () => {
        return (
          <div className="mt-20">
            <h4 className="mb-15">Aditional Work</h4>
            {renderDescriptionTable()}
            {props.errorsData["additional_work"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {props.errorsData["additional_work"]}
                  </label>
                ) : (
                  ""
                )}
            <div className="form-group">
              <div className="half-fitem fitem">
                <div className="half-fitem fitem">
                  <label className="mr-10"> Tax Rate: </label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "20%" }}
                    value={taxRate}
                    disabled={props.editMode}
                    onChange={(e) => {
                      settaxRate(e.target.value);
                    }}
                  />
                  <u>
                    <a
                      role="button"
                      href="/#"
                      disabled={props.editMode}
                      onClick={(e) => {
                        e.preventDefault();
                        getAvalaraTax();
                      }}
                      className={`ml-10 ${props.editMode ? 'proposal-link-disabled':''}`}
                    >
                      Reset Tax Rate to local
                    </a>
                  </u>
                </div>
  
                <div className="half-fitem fitem" style={{ textAlign: "right" }}>
                    <button
                      className="orange proposal-add-item-button"
                      style={{ cursor: "pointer" }}
                      disabled={props.editMode}
                      onClick={(e) => {
                        setisAddLineVisible(true);
                        seteditMode(false);
                      }}
                    >
                      <PlusCircleOutlined style={{ marginRight: "6px" }} /> Add Item
                    </button>
                </div>
              </div>
            </div>
          </div>
        );
      };
      const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);
  
      const removeBundleLabor = (row) => {
        let lines = services_lineitem;
        if (!lineItem.include_labor) {
          //remove Labor line
          let labor_line = services_lineitem.find((item) => {
            return item.repair_code === row.repair_code + "-Labor";
          });
  
          if (labor_line) {
            lines = lines.filter((item) => {
              return item.repair_code !== row.repair_code + "-Labor";
            });
            if (labor_line.uuid) {
              let delete_services_lineitem = state.delete_services_lineitem;
              delete_services_lineitem.push(labor_line.uuid);
              setState((s) => ({
                ...s,
                delete_services_lineitem: delete_services_lineitem,
              }));
            } else {
              let services_lineitem = state.services_lineitem.filter((item) => {
                return item.repair_code !== labor_line.repair_code;
              });
              setState((s) => ({ ...s, services_lineitem: services_lineitem }));
            }
          }
        }
  
        if (!lineItem.include_bundle) {
          //remove Bundle line
          let bundle_line = services_lineitem.find((item) => {
            return (
              item.bundle_code !== undefined &&
              item.bundle_code === row.repair_code
            );
          });
  
          if (bundle_line) {
            lines = lines.filter((item) => {
              return item.bundle_code !== row.repair_code;
            });
            if (bundle_line.uuid) {
              let delete_services_lineitem = state.delete_services_lineitem;
              delete_services_lineitem.push(bundle_line.uuid);
              setState((s) => ({
                ...s,
                delete_services_lineitem: delete_services_lineitem,
              }));
            } else {
              let services_lineitem = state.services_lineitem.filter((item) => {
                return item.repair_code !== bundle_line.repair_code;
              });
              setState((s) => ({ ...s, services_lineitem: services_lineitem }));
            }
          }
        }
  
        setservices_lineitem(lines);
        CalculateTotal();
        calculateTax();
        calculateNoTax();
      };
  
      const renderDescriptionTable = () => {
        return (
          <React.Fragment>
            <Table
              className="tablebody service_descriptionTable_table"
              rowKey={(record) =>
                `${record.repair_code} - ${record?.bundle_code} - ${record?.description} - ${record?.unit_cost}`
              }
              dataSource={[...services_lineitem]}
              pagination={false}
              columns={DataTable.DescriptionWork({
                totalPriceDescription,
                deleteLine,
                editLine,
                applyTax,
                amount,
                removeBundleTax,
              })}
            />
            <div>
              <Modal
                title={editMode ? "Edit Item" : "Add Item"}
                visible={isAddLineVisible}
                okText={!editMode ? "Add Item" : "Save Item"}
                cancelText={"Cancel"}
               onOk={seachvalidate}
                onCancel={() => {
                  setisAddLineVisible(false);
                  clearLineItem();
                }}
                className="schedule_line_modal"
                width={400}
                disabled={props.editMode}
              >
                <div className="form-group">
                  <div className="fitem">
                    <div>
                      <label>Repair Code</label>
                      {renderRepairCodeSearch()}
                    </div>
                    <div>
                      <label>Description</label>
                      <input
                        type="text"
                        placeholder="Search Repair Descriptions"
                        value={lineItem.description}
                        onChange={(e) => {
                          set_is_description_Edit(true);
                          setlineItem((l) => ({
                            ...l,
                            description: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div>
                      <label>Quantity</label>
                      <input
                      name="quantity_item"
                        type="number"
                        placeholder="1.00"
                        value={lineItem.quantity}
                        min="0.01"
                        step="0.1"
                        onChange={(e) => {
                          setStateone({...stateone,
                            errors: {},
                            quantity_item:e.target.value,
                          });
                          set_is_quantity_Edit(true);
                          setlineItem((l) => ({
                            ...l,
                            quantity:e.target.value ,
                          }));
                        }}
                      />
                       {errors["quantity_item"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle"></i>
                      {errors["quantity_item"]}
                    </label>
                  ) : (
                    ""
                  )}
                    </div>
                    <div>
                      <label>Rate</label>
                      <input
                      name="rate"
                        type="number"
                        placeholder="0.00"
                        value={lineItem.unit_cost}
                        onChange={(e) => {
                          setStateone({...stateone,
                            errors: {},
                            rate:e.target.value ,
                          });
                          set_is_unitCost_Edit(true);
                          setlineItem((l) => ({
                            ...l,
                            unit_cost: e.target.value,
                          }));
                        }}
                        onBlur={(e) =>
                          setlineItem((l) => ({
                            ...l,
                            unit_cost:e.target.value,
                          }))
                        }
                      />
                       {errors["rate"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle"></i>
                      {errors["rate"]}
                    </label>
                  ) : (
                    ""
                  )}
                    </div>
                    <div>
                      <label>Price</label>
                      <input
                        type="number"
                        placeholder="0.00"
                        value={totalPriceDescription(lineItem)}
                        onChange={(e) => {
                          set_is_price_Edit(true);
                          setlineItem((l) => ({ ...l, price: e.target.value }));
                        }}
                        style={{ cursor: "not-allowed" }}
                        readOnly
                      />
                    </div>
                    {lineItem.totallabor !== null && lineItem.totallabor > 0 ? (
                      <>
                        <div className="mt-10">
                          <input
                            type="checkbox"
                            checked={lineItem.include_labor}
                            onChange={(e) => {
                              setlineItem((l) => ({
                                ...l,
                                include_labor: !lineItem.include_labor,
                                include_bundle: !lineItem.include_labor,
                              }));
                            }}
                            style={{ verticalAlign: "middle" }}
                          />
                          <label className="mr-10">Include Labor Charge</label>
                        </div>
                        <div className="mt-10">
                          <input
                            type="checkbox"
                            disabled={!lineItem.include_labor}
                            checked={lineItem.include_bundle}
                            onChange={(e) => {
                              setlineItem((l) => ({
                                ...l,
                                include_bundle: !lineItem.include_bundle,
                              }));
                            }}
                            style={{ verticalAlign: "middle" }}
                          />
                          <label className="mr-10">Bundle Product</label>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Modal>
            </div>
          </React.Fragment>
        );
      };
  
      return (
        <div className="block-row p-0">
          <div className="mb-60">
            {renderDescription()}
          </div>
        </div>
      );
    }
  
    const getLineSearch = async (params) => {
      const response = await getPrices(params);
      return response;
    };
  
    const [codeOptions, setcodeOptions] = useState([]);
    const [repairCodes, setrepairCodes] = useState([]);
    const [value, setvalue] = useState("");
  
    async function getOptions(search) {
      const allowRepeatedRepairCodes = [
        "LABORSERVICE",
        "FLATRATESUBCONTRACT",
        "INCOME",
        "LABOR",
        "SERVICE-FLATRATE",
        "RETAIL-SUPPLIES",
        "SUBCONTRACT-INCOME",
      ];
      setSelectLoading(true);
      let field_office_uuid = props?.customer?.field_office?.uuid;
      const options = await getLineSearch({
        search: search,
        field_office_uuid: field_office_uuid,
      });
  
      setrepairCodes(options);
  
      const _options = options.reduce((prev, current) => {
        //Validation to not repeat repair code
        const is_added = services_lineitem.some(
          (i) => i.repair_code === current.repaircode
        );
        if (
          !is_added ||
          _.includes(allowRepeatedRepairCodes, current.repaircode)
        ) {
          prev.push({
            value: current.repaircode + " - " + current.description,
            text: current.repaircode,
          });
        }
        return prev;
      }, []);
      setSelectLoading(false);
  
      setcodeOptions(_options);
    }
  
    const [name, setName] = useState("");
    const inputRef = useRef(null);
    const [openSelect, setOpenSelect] = useState(false);
    const [selectLoading, setSelectLoading] = useState(true);
    const renderRepairCodeSearch = () => {
      const onChange = (e) => {
        let val = "";
        let selectedCode = [];
        if (e) {
          val = e.trim().split(" ")[0];
          setvalue(val);
          selectedCode = repairCodes.filter((item) => {
            return item.repaircode === val;
          });
        } else {
          setvalue("");
        }
        if (selectedCode.length > 0) {
          setlineItem({
            repair_code: selectedCode[0].repaircode || "",
            description: selectedCode[0].description,
            unit_cost:
              Math.round(
                (Number(selectedCode[0].fo_adjusted_price) + Number.EPSILON) * 100
              ) / 100,
            quantity: (1).toFixed(2),
            price: selectedCode[0].fo_adjusted_price,
            is_taxable: selectedCode[0].is_taxed_product,
            apply_labor_tax: selectedCode[0].is_taxed_labor,
            totallabor: selectedCode[0].totallabor,
            qb_account: selectedCode[0].qb_account,
          });
          setStateone({
            errors: {},
            rate:   Math.round(
              (Number(selectedCode?.[0]?.fo_adjusted_price) + Number.EPSILON) * 100
            ) / 100,
            quantity_item:(1).toFixed(2),
          })
        } else {
          message.error("The item was not found, please verify the data.");
        }
      };
      const onSearch = (e) => {
        setcodeOptions([]);
        if (e !== "") {
          getOptions(e);
          setvalue(e);
          setlineItem((l) => ({ ...l, repair_code: e || "" }));
        }
      };
  
      const onNameChange = (event) => {
        setName(event.target.value);
      };
      const addItem = (e) => {
        e.preventDefault();
        setlineItem({
          repair_code: name,
          description: name,
          unit_cost: 0,
          quantity: 1,
          price: 0,
          is_taxable: false,
          apply_labor_tax: undefined,
          totallabor: undefined,
          qb_account: "",
          bundle_code: null,
        });
        setOpenSelect(false);
        setvalue(name);
        setName("");
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      };
  
      return (
        <div className="searchable">
          <ConfigProvider
            renderEmpty={() =>
              selectLoading ? (
                <div className="repairCodesSpin">
                  <Spin tip="Loading Repair Codes..." />
                </div>
              ) : (
                <Empty />
              )
            }
          >
            <Select
              showSearch
              allowClear
              autoClearSearchValue={false}
              value={value}
              placeholder="Search Repair ID's"
              onChange={onChange}
              onSearch={onSearch}
              onFocus={() => getOptions("")}
              options={codeOptions}
              disabled={editMode}
              open={openSelect}
              onDropdownVisibleChange={(visible) => setOpenSelect(visible)}
            ></Select>
          </ConfigProvider>
        </div>
      );
    };
  
    return (
      <div>
        {" "}
        {loader && <Loader />}
        {ServiceDetails_()}{" "}
      </div>
    );
  };
export default AdditionalProposal;