import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Drawer, Space, Row, Col, Select, message, Input, Switch, Tooltip } from "antd";
import Loader from "../../../Components/Loader";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { setPriceCatalogModalState, setPriceCatalogField } from "../../../Store/priceCatalogDataSlice";
import { addNewPricingItems, getPriceCatalogApiReq, updatePricingItems } from "../../../Action/PriceCatalog/index";

const AddNewItemPriceCatalouge = () => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isCustomValue, setIsCustomValue] = useState(false);
  const [otherMultiplier, setOtherMultiplier] = useState("");
  const dispatch = useDispatch();
  const { managingModal: { type, show, data }, partTypeList, filter, loader, errorMessage } = useSelector((state) => state.priceCatalog);

  const toggleTooltip = (value) => {
    setOpenTooltip(value)
  }

  const closeModal = () => {
    dispatch(setPriceCatalogModalState({ show: false, data: {}, type: "" }));
    dispatch(setPriceCatalogField({ errorMessage: {} }));
  };

  const handleProductSearch = async (e) => {
    if (!e.target.value) return;
    dispatch(setPriceCatalogField({ loader: true }));
    let response = await getPriceCatalogApiReq({
      field_office__uuid: filter.field_office__uuid,
      productcode: e.target.value,
    });
    if (response.results.length > 0) {
      dispatch(setPriceCatalogField({ errorMessage: { productcode: "Product already exists" } }));
    } else {
      dispatch(setPriceCatalogField({ errorMessage: {} }));
    }
    dispatch(setPriceCatalogField({ loader: false }));
  }

  const getPriceCatalog = async () => {
    dispatch(setPriceCatalogField({ loader: true }));
    const response = await getPriceCatalogApiReq(filter);
    dispatch(setPriceCatalogField({ loader: false, priceCatalogList: response.results, filter: { ...filter, total: response.count } }));
  }

  useEffect(() => {
    if (type !== "view") {
      calculatePrice();
    }
  }, [data.local_price, data.estimated_tax, data.labor_rate_fo, data.labor_time_fo, data.multiplier_used, data.is_taxed_labor, data.is_taxed_product]);

  const calculatePrice = () => {
    const retail_price_without_tax = (data.local_price * (data.multiplier_used === null ? 1 : data.multiplier_used));
    const labour_charge_withoutTax = ((data.labor_rate_fo || 0) * (data.labor_time_fo || 0));
    const labour_charge = (1 + parseFloat(data.is_taxed_labor ? (data.estimated_tax || 0) : 0)) * labour_charge_withoutTax;
    const retail_total_price_without_tax = retail_price_without_tax + labour_charge_withoutTax;
    const retail_total_price_with_tax = (retail_price_without_tax * (1 + parseFloat(data.is_taxed_product ? (data.estimated_tax || 0) : 0))) + labour_charge;
    dispatch(setPriceCatalogModalState({ data: { ...data, retail_price: retail_price_without_tax, retail_total_price_without_tax, retail_total_price_with_tax } }));
  };

  const getFormattedDataAPIReq = () => ({
    "parttype": data.parttype,
    "productcode": data.productcode,
    "description": data.description,
    "local_price": data.local_price,
    "multiplier_used": data.multiplier_used,
    "labor_rate_fo": data.labor_rate_fo,
    "labor_time_fo": data.labor_time_fo,
    "estimated_tax": data.estimated_tax || 0,
    "is_taxed_labor": data.is_taxed_labor,
    "is_taxed_product": data.is_taxed_product,
  });

  const validateNewItem = () => {
    const err = {};
    if (!data.parttype) {
      err.parttype = "Part Type Required!"
    }
    if (!data.productcode) {
      err.productcode = "Product Code Required!"
    }
    if (!data.description) {
      err.description = "Description Required!"
    }
    dispatch(setPriceCatalogField({ errorMessage: err }));
    return err;
  };

  const addNewItem = async () => {
    const err = validateNewItem();
    if (!Object.keys(err).length) {
      let response = await addNewPricingItems(filter.field_office__uuid, getFormattedDataAPIReq());
      if (response?.uuid) {
        message.success(
          `Item added successfully`,
          5
        );
        getPriceCatalog();
        closeModal();
      } else {
        message.error(
          "Item is not added, please try again."
        );
        dispatch(setPriceCatalogField({ errorMessage: { 'validationError': response?.message?.[0] } }));
      }
    }
  }
  const getupdatedFormattedDataAPIReq = () => ({
    "description": data.description,
    "local_price": data.local_price,
    "multiplier_used": data.multiplier_used,
    "labor_rate_fo": data.labor_rate_fo,
    "labor_time_fo": data.labor_time_fo,
    "estimated_tax": data.estimated_tax,
    "is_taxed_labor": data.is_taxed_labor,
    "is_taxed_product": data.is_taxed_product,
  })


  const updatePriceCatalogItem = async () => {
    const err = validateNewItem();
    if (!Object.keys(err).length) {
      let response = await updatePricingItems(data.uuid, getupdatedFormattedDataAPIReq())
      if (response) {
        message.success(
          `Item updated successfully`,
          5
        );
        getPriceCatalog();
      }
      closeModal();
    }
  }

  const handleSwitch = (name, value) => {
    dispatch(setPriceCatalogModalState({ data: { ...data, [name]: value } }));
  }
  const handleToggleCustomValue = () => {
    setIsCustomValue(!isCustomValue);
  };

  const handleChange = (e) => {
    const pattern = (/^[a-zA-Z\d-]+$/);
    const { name, value } = e.target;
    if (name === 'productcode' && value && (!pattern.test(value))) {
      return;
    } else if (name === "estimated_tax") {
      if (Number(value) <= 1) {
        dispatch(setPriceCatalogModalState({ data: { ...data, [name]: value } }));
      }
    } else if (name === "multiplier_used" && value === "new") {
      return;
    } else {
      dispatch(setPriceCatalogModalState({ data: { ...data, [name]: value } }));
    }
  }

  const numberValidate = (e) => {
    const pattern = /[0-9.]/;
    const inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      e.preventDefault();
    } else if (e.target.name !== "estimated_tax") {
      let inputValue = e.target.value;
      const decimalIndex = inputValue.indexOf('.');
      const decimalPlaces = decimalIndex !== -1 ? inputValue.length - decimalIndex - 1 : 0;
      if (decimalPlaces > 1) {
        e.preventDefault();
      }
    }
  }

  const handleMultiplier = () => {
    if (otherMultiplier && otherMultiplier !== "") {
      dispatch(setPriceCatalogModalState({ data: { ...data, available_multipliers: data.available_multipliers.includes(otherMultiplier) ? data.available_multipliers : [...data.available_multipliers, otherMultiplier], multiplier_used: otherMultiplier } }));
      setOtherMultiplier("");
    }
    setIsCustomValue(false)
  }

  const handleOtherMultiplierChange = (e) => {
    setOtherMultiplier(e.target.value);
  }
  return (
    <div>
      {loader && <Loader />}
      <Drawer
        open={show}
        closable={false}
        title={type === "edit" ? <h3 className="mb-0">Pricing | Edit Pricing Item</h3> : <h3 className="mb-0">Pricing | {type === "add" ? " Add Pricing Item" : " View Pricing Item"}</h3>}
        extra={
          <Space>
            <CloseOutlined onClick={closeModal} />
          </Space>
        }
        width={"400"}
        footer={
          <div className="modal-btn-footer">
            {type !== "view" ? <button
              key="cancel"
              className="btn btn-close"
              onClick={closeModal}
            >
              Cancel
            </button> : null
            }
            {type !== "view" ? <button
              key="upload"
              disabled={errorMessage?.["productcode"] ? true : false}
              className={`btn btn-bdr`}
              onClick={type === "add" ? addNewItem : updatePriceCatalogItem}
            >
              {type === "edit" ? "Update" : "Add"} Item
            </button> : null}
          </div>
        }
        footerStyle={{ backgroundColor: "#f4f7f9" }}
      >
        {errorMessage?.["validationError"] ? (
          <label className="new_err_msg pb-10">
            <i className="las la-info-circle" />
            {errorMessage?.["validationError"]}
          </label>
        ) : null}
        <div className="form-group">
          <div className="fitem">
            <label>Part Type</label>
            <div className="disabled-cataloge">
              <Select
                name="parttype"
                className="fw-bold"
                type="text" disabled={type === "view" || type === "edit"}
                value={data.parttype}
                onChange={(e) => handleChange({ target: { name: "parttype", value: e } })}
              >
                {partTypeList.filter((item) => item.value !== "").map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </div>
            {errorMessage?.["parttype"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errorMessage?.["parttype"]}
              </label>
            ) : null}

          </div>
          <div className="fitem">
            <label>Product Code</label>
            <Input
              name="productcode"
              type="text"
              disabled={type === "view" || type === "edit"}
              value={data.productcode}
              onChange={handleChange}
              onBlur={handleProductSearch}
            />
            {errorMessage?.["productcode"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errorMessage?.["productcode"]}
              </label>
            ) : (
              ""
            )}
          </div>
          <div className="fitem">
            <label>Description</label>
            <Input
              type="text"
              disabled={type === "view"}
              name="description"
              onChange={handleChange}
              value={data.description}
            />
            {errorMessage?.["description"] ? (
              <label className="new_err_msg">
                <i className="las la-info-circle" />
                {errorMessage?.["description"]}
              </label>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <Row>
              <div className="half-fitem fitem gap-5">
                <Col>
                  <label>National Price</label>
                  <Input
                    type="text"
                    prefix={"$"}
                    readOnly={true}
                    disabled={type === "view"}
                    value={data.national_price}
                  />
                </Col>
                <Col>
                  <label>National Labour</label>
                  <Input
                    type="text"
                    readOnly={true}
                    prefix={"$"}
                    disabled={type === "view"}
                    value={data.national_labor}
                  />
                </Col>
              </div>
            </Row>
          </div>
          <div className="form-group">
            <Row className="display-block">
              <Col className="width-47">
                <label>National Labor Time</label>
                  <Input
                    type="text"
                    readOnly={true}
                    disabled={type === "view"}
                    value={data.national_labor_time}
                  />
              </Col>
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <div className="half-fitem fitem gap-5 w-100">
                <Col className="width-49 flex-1">
                  <label>ASP Cost</label>
                  <Input
                    type="text"
                    name="local_price"
                    prefix={"$"}
                    disabled={type === "view"}
                    value={data.local_price}
                    onChange={handleChange}
                    onKeyPress={numberValidate}
                  />
                </Col>
                <Col className="width-49 flex-1">
                  <label>Location Multiplier</label>
                  {isCustomValue ? (
                    <Input type="text"
                      name="multiplier_used"
                      onKeyPress={numberValidate}
                      onBlur={handleMultiplier}
                      disabled={type === "view"}
                      value={otherMultiplier}
                      onChange={handleOtherMultiplierChange}
                    />
                  ) : (
                    <div className="disabled-cataloge">
                      <Select
                        type="text"
                        disabled={type === "view"}
                        className="fw-bold"
                        name="multiplier_used"
                        value={`${data.multiplier_used}`}
                        onChange={(e) => handleChange({ target: { name: "multiplier_used", value: e } })}
                      >
                        {(data.available_multipliers || []).map((item) =>
                          <Option key={item} value={item}>{(parseFloat(item).toFixed(2)).toString()}</Option>
                        )}
                        <Option key="new" value="new">
                          <span onClick={handleToggleCustomValue}>Enter Other value</span>
                        </Option>
                      </Select>
                    </div>
                  )}
                </Col>
              </div>
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <div className="half-fitem fitem gap-5 disabled-productCataloge">
                <Col>
                  <label>Location Labor Rate</label>
                  <Input
                    type="text"
                    name="labor_rate_fo"
                    prefix={"$"}
                    disabled={type === "view"}
                    value={data.labor_rate_fo}
                    onChange={handleChange}
                    onKeyPress={numberValidate}
                  />
                </Col>
                <Col>
                  <label>Location Labor Time</label>
                  <Input
                    type="text"
                    name="labor_time_fo"
                    disabled={type === "view"}
                    value={data.labor_time_fo}
                    onChange={handleChange}
                    onKeyPress={numberValidate}
                  />
                </Col>
              </div>
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <div className="half-fitem fitem gap-5">
                <Col className="flex-1">
                  <label>
                    Estimated Tax
                    <Tooltip open={openTooltip} placement="right" color="#f4f7f9" title={<span className="black" >Ex: Enter 0.08 to apply 8% Tax</span>} arrow>
                      <QuestionCircleOutlined onClick={() => toggleTooltip(true)} onMouseEnter={() => toggleTooltip(true)} onMouseLeave={() => toggleTooltip(false)} className="ml-5 success" />
                    </Tooltip>
                  </label>
                  <Input
                    type="text"
                    name="estimated_tax"
                    prefix={"$"}
                    disabled={type === "view"}
                    placeholder="Ex: Enter 0.08 to apply 8% Tax"
                    value={data.estimated_tax}
                    onChange={handleChange}
                    onKeyPress={numberValidate}
                  />
                </Col>
                <Col className="flex-1">
                  <label>Retail Price</label>
                  <Input
                    type="text"
                    readOnly={true}
                    prefix={"$"}
                    disabled={type === "view"}
                    value={data.retail_price?.toFixed(2)}
                  />
                </Col>
              </div>
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <div className="half-fitem fitem gap-5">
                <Col>
                  <label>Retail Total Price(No Tax)</label>
                  <Input
                    type="text"
                    readOnly={true}
                    prefix={"$"}
                    disabled={type === "view"}
                    value={data.retail_total_price_without_tax?.toFixed(2)}
                  />
                </Col>
                <Col>
                  <label>Retail Total Price(With Tax)</label>
                  <Input
                    type="text"
                    readOnly={true}
                    prefix={"$"}
                    disabled={type === "view"}
                    value={data.retail_total_price_with_tax?.toFixed(2)}
                  />
                </Col>
              </div>
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <div className="fitem">
                <Col>
                  <Switch
                    disabled={type === "view"}
                    name="is_taxed_labor"
                    checked={data.is_taxed_labor}
                    onChange={(e) => handleSwitch("is_taxed_labor", e)}
                  />
                  <strong className="ml-10 fs-14">Tax on Labor</strong>
                </Col>
              </div>
            </Row>
          </div>
          <div className="form-group">
            <Row>
              <div className="fitem">
                <Col>
                  <Switch
                    name="is_taxed_product"
                    disabled={type === "view"}
                    checked={data.is_taxed_product}
                    onChange={(e) => handleSwitch("is_taxed_product", e)}
                  />
                  <strong className="ml-10 fs-14">Tax on Product</strong>
                </Col>
              </div>
            </Row>
          </div>
        </div >
      </Drawer >
    </div >
  );
};

export default AddNewItemPriceCatalouge;
