import React, { useState } from "react";
import { PicCenterOutlined } from "@ant-design/icons";
import { Checkbox, Popover, Col, Row } from "antd";

const CheckboxGroup = Checkbox.Group;

const StatusSelector = ({ onControlChange = () => {} }) => {
  const [selectedStatus, setSelectedStatus] = useState([
    "complete",
    "incomplete",
    "scheduled",
    "forecast",
    "cancelled",
  ]);
  const [openMobileView, setOpenMobileView] = useState(false);
  const hide = () => {
    setOpenMobileView(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpenMobileView(newOpen);
  };

  return (
    <>
      <Popover
        content={
          <CheckboxGroup
            defaultValue={selectedStatus}
            onChange={(selection) => {
              setSelectedStatus(selection);
              onControlChange(selection);
            }}
          >
            <Row style={{ flexDirection: "column" }}>
              <Col>
                <Checkbox
                  value={"complete"}
                  style={{ margin: "5px 0" }}
                  className="status_complete"
                >
                  Completed
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  value={"incomplete"}
                  style={{ margin: "5px 0" }}
                  className="status_incomplete"
                >
                  Incomplete
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  value={"scheduled"}
                  style={{ margin: "5px 0" }}
                  className="status_scheduled"
                >
                  Scheduled
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  value={"forecast"}
                  style={{ margin: "5px 0" }}
                  className="status_forecast"
                >
                  Forecast
                </Checkbox>
              </Col>
              <Col>
                <Checkbox
                  value={"cancelled"}
                  style={{ margin: "5px 0" }}
                  className="status_cancelled"
                >
                  Cancelled
                </Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        }
        title="Status"
        trigger="click"
        placement="bottomRight"
        open={openMobileView}
        onOpenChange={handleOpenChange}
      >
        <button className="responsive-icon">
          <PicCenterOutlined />
        </button>
      </Popover>
      <div className="statusSelector">
        <span className="statusSelectorTitle">Status:</span>
        <CheckboxGroup
          defaultValue={selectedStatus}
          onChange={(selection) => {
            setSelectedStatus(selection);
            onControlChange(selection);
          }}
        >
          <Checkbox value={"complete"} className="status_complete">
            Completed
          </Checkbox>
          <Checkbox value={"incomplete"} className="status_incomplete">
            Incomplete
          </Checkbox>
          <Checkbox value={"scheduled"} className="status_scheduled">
            Scheduled
          </Checkbox>
          <Checkbox value={"forecast"} className="status_forecast">
            Forecast
          </Checkbox>
          <Checkbox value={"cancelled"} className="status_cancelled">
            Cancelled
          </Checkbox>
        </CheckboxGroup>
      </div>
    </>
  );
};

export default StatusSelector;
