import React from "react";
import { useState } from "react";
import { Row, Col } from "antd";
import { map } from "lodash";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { proposalFrequency } from "../../../Store/ProposalDataSlice";
const ProposalFrequnecy = (props) => {
  const { errors, editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);
  const [state, setsate] = useState([
    {
      value: "One Time",
      text: "One Time",
    },
    {
      value: "weekly",
      text: "Weekly",
    },
    {
      value: "bi-weekly",
      text: "Bi-Weekly",
    },
    {
      value: "monthly",
      text: "Monthly",
    },
    {
      value: "yearly",
      text: "Yearly",
    },
    {
      value: "Other",
      text: "Other",
    },

  ]);
  return (
    <Row>
      <Col span={24}>
        <div className="frow">
          <h4>Frequency<sup>*</sup></h4>
          <div className="oplist">
            {map(state, (item, index) => (
              <div className="inline-checks" key={`frequencyType${index}`}>
                <label htmlFor={item?.value} className={`${proposals?.frequency === item?.value ? 'active' : ''}`}>
                  <input
                    type="radio"
                    id={item?.value}
                    name="frequencyType"
                    className=""
                    value={item?.value}
                    disabled = {editMode}
                    onChange={() => dispatch(proposalFrequency(item.value))}
                  />
                  <span>{item?.value}</span>
                </label>
              </div>
            ))}
          </div>
          {errors["frequency"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["frequency"]}
                  </label>
                ) : (
                  ""
                )}
        </div>
      </Col>
    </Row>
  )
}
export default ProposalFrequnecy