import React, { useState, useEffect, useCallback } from "react";
import { Empty } from "antd";
import { Pie } from "@ant-design/plots";
import { formatToCurrencyNoDecimal } from "../../../Components/Common/formatToCurrency";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import { getDashboardServiceSummary } from "../../../Action/Dashboard";

const ServiceRevenueCountChart = ({ type = "service" }) => {
  const [serviceRevenueMetrics, setServiceRevenueMetrics] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const { franchise, period } = useDashboard();

  const buildPieChart = (data) =>
    data.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        {
          ...currentValue,
          value:
            type === "revenue"
              ? currentValue.total_revenue
              : currentValue.total_count,
          format:
            type === "revenue"
              ? `$${formatToCurrencyNoDecimal(currentValue.total_revenue)}`
              : `${formatToCurrencyNoDecimal(currentValue.total_count)}`,
        },
      ],
      []
    );

  const getServiceRevenueMetrics = useCallback(async () => {
    const data = {
      ...period,
      franchise_filter: franchise,
    };

    const response = await getDashboardServiceSummary(data);
    if (response.status !== 200) {
      return;
    }

    const process_dashboard_metrics = buildPieChart(response.data);
    const buildMetrics = [
      ...process_dashboard_metrics.reduce((prevValue, currentValue) => {
        if (currentValue.value > 0) {
          prevValue = [...prevValue, currentValue];
        }
        return prevValue;
      }, []),
    ];
    setIsEmpty(
      buildMetrics.reduce((prev, item) => (item.value > 0 ? false : true), true)
    );
    setServiceRevenueMetrics(buildMetrics);
  }, [period, franchise, setServiceRevenueMetrics, type]);

  useEffect(() => {
    getServiceRevenueMetrics();
  }, [getServiceRevenueMetrics]);

  const config = {
    appendPadding: 18,
    height: 300,
    width: 300,
    data: serviceRevenueMetrics,
    angleField: "value",
    colorField: "service",
    color: ["#FF681D", "#005D89", "#4FCFF6", "#758F9A", "7E7D7D"],
    tooltip: {
      formatter: ({ service, value }) => ({
        name:
          service.charAt(0).toUpperCase() + service.slice(1).replace("_", " "),
        value: `${value}`,
      }),
    },
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} $`,
      },
    },
    label: {
      type: "spider",
      layout: "limitInShape",
      labelHeight: 38,
      //offset: '-50%',
      style: {
        textAlign: "right",
        fill: "#000",
        fillOpacity: 1,
        fontSize: 16,
      },
      autoRotate: true,
      content: ({ format, percent }) =>
        `${format}\n(${(percent * 100).toFixed(1)}%)`,
    },
    legend: {
      position: "top",
      itemName: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 16 },
        formatter: (text, item, index) =>
          text.charAt(0).toUpperCase() + text.slice(1).replace("_", " "),
      },
    },
    statistic: "",
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };
  return isEmpty ? (
    <Empty className="no-data-empty" description={false} />
  ) : (
    <Pie {...config} />
  );
};

export default ServiceRevenueCountChart;
