import React from "react";
import { Empty } from "antd";
import { Column } from "@ant-design/plots";
import { useReport } from "../../Hooks/Report.Hook";
import { formatToCurrency } from "../../../Components/Common/formatToCurrency";

const InvoiceReconciliationExtraSidebar = () => {
  const { chartData } = useReport();
  const config = {
    data: chartData,
    appendPadding: [0, 0, 20, 0],
    isStack: true,
    xField: "type",
    yField: "revenue",
    seriesField: "service",
    color: ["#FF681D", "#005D89", "#4FCFF6", "#758F9A"],
    xAxis: false,
    yAxis: {
      label: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 12 },
        formatter: (v) => `$${formatToCurrency(v)}`,
      },
    },
    legend: {
      position: "bottom",
      layout: "horizontal",
      maxRow: 4,
      pageNavigator: {
        marker: {
          style: {
            size: 0,
          },
        },
        text: {
          style: {
            fontSize: 0,
          },
        },
      },
      itemName: {
        style: { fill: "#000", fillOpacity: 1, fontSize: 14 },
        formatter: (text, item, index) =>
          text.charAt(0).toUpperCase() + text.slice(1),
      },
    },
    tooltip: {
      formatter: ({ type, revenue }) => ({
        name: type.charAt(0).toUpperCase() + type.slice(1),
        value: `$ ${formatToCurrency(revenue)}`,
      }),
    },
    meta: {
      sales: {
        alias: "Amount",
      },
    },
  };
  return (
    <div className="territory-chart">
      <label style={{ margin: "20px 0px", width: "100%", textAlign: "center" }}>
        <b>Invoice Chart</b>
      </label>
      <>
        {chartData.length > 0 ? (
          <Column {...config} />
        ) : (
          <Empty className="no-data-empty" description={false} />
        )}
      </>
    </div>
  );
};

export default InvoiceReconciliationExtraSidebar;
