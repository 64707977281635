import React from "react";
import { map } from "lodash";
import { Col, Row, Checkbox } from "antd";

import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import appData from "../../../../Utils/constants";

import { updateContactMethods } from "../../../../Store/techJourneyDataSlice";

const ContactMethod = () => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);
  const { contactMethods } = techJourneyState;

  return (
    <>
      <h4 className="mt-10">Contact Method</h4>
      <Row>
        {map(contactMethods, (item, index) => {
          return (
            <>
              <Col
                span={12}
                order={1}
              >
                <Checkbox
                name={item.key}
                checked={
                  (contactMethods &&
                    contactMethods[item.key].isChecked) ||
                  false
                }
                  onChange={(e) => {
                    const payload = {};
                    payload[item.key] = {
                      ...item,
                      isChecked: e.target.checked,
                    };
                    dispatch(updateContactMethods(payload));
                  }}
                  key={`${item.key}`}
                >
                  {item?.label}
                </Checkbox>
              </Col>
            </>
          );
        })}
      </Row>
    </>
  );
};

export default ContactMethod;
