import React from "react";
import { Row, Col, Tag } from "antd";
import moment from "moment-timezone";
import ActivityLogNote from "./ActivityLog.Note";

const ActivityLogCard = ({ note }) => {
  const { scheduled_appointment = null, notes = [] } = note;

  return (
    <div className="note">
      <Row justify="space-between" className="note-title">
        <Col span={12} className={"text-left"}>
          Service Date
        </Col>
        <Col span={12} className={"text-right"}>
          {moment(scheduled_appointment).utc().format("MM/DD/YYYY")}
        </Col>
      </Row>
      {notes.map((note, key) => (
        <ActivityLogNote note={note} key={key} />
      ))}
    </div>
  );
};

export default ActivityLogCard;
