import React from "react";

const STATUS_COLOR_CLASS = {
  active: "success-status",
  inactive: "gray-status",
  pending: "warning-status"
};

const SERVICE_STATUS = {
  active: "ACTIVE",
  inactive: 'INACTIVE',
  pending: 'PENDING',
};

const CUSTOMER_TYPE = {
  residential: "Residential",
  commercial: 'Commercial'
};

class ProposalDataTable {
    proposalColumns = () => {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const logged_in_user_role = authUser?.user?.user_roles?.map((x) => x.name);
      const columns = [
        {
          title: 'Proposal',
          dataIndex: 'service_order_number',
          key: 'service_order_number',
          sorter: true,
          render: (text, record) => <span>{record?.service_order_number}</span>
        },
        {
          title: 'Interested in',
          dataIndex: 'service_type_name',
          key: 'service_type_name',
          sorter: true,
          render: (text, record) => (<span> {record?.service_type_name}</span>)
        },
        {
          title: 'Proposal Date',
          dataIndex: 'field_office_name',
          key: 'field_office_name',
          sorter: true,
          render: (text, record) => (<span> {record?.field_office_name}</span>)
        },
        {
          title: 'Proposal Status',
          dataIndex: 'customer_name',
          key: 'customer_name',
          sorter: true,
          render: (text, record) => (<span>{record?.customer_name ? record?.customer_name : "-"}</span>)
        },
        {
          title: 'Proposal Due Date',
          dataIndex: 'service_order_name',
          key: 'service_order_name',
          sorter: true,
          render: (text, record) => (<span>{record?.service_order_name}</span>)
        },
        {
          title: 'Actions',
          dataIndex: 'status',
          key: 'status',
          sorter: false,
          render: (text, record) => (<span className={`status ${STATUS_COLOR_CLASS[record.status]}`}>{record.is_active ? 'Active': ''}</span>)
        },
      ];
      return columns;
    }
}


export default new ProposalDataTable();