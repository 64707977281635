import React, { Component, useEffect, useState } from "react";
import { message, Select } from "antd";
import {
  Datepicker,
} from "@mobiscroll/react";
import moment from "moment-timezone";
import {
  getWeeklyAvgData,
  getServiceStatistics,
  getFieldOfficeStaff,
} from "../../../../Action/BulkReassign";
// import { map } from 'lodash';
import Loader from "../../../../Components/Loader";
import { errorHandle } from "../../../../Components/errorHandle";
import { DownOutlined } from "@ant-design/icons";
const { Option } = Select;

moment.updateLocale("en", { week: { dow: 1 } });

const Aside = (props) => {
  const [state, setState] = useState({
    last_step: false,
    weekly_avg_data: {},
    weekly_service_statistics: {},
    earch_param: "",
    start_date: null,
    end_date: null,
    field_staff: {},
    loader: false,
    weekDate: "",
    is_week_setup: false,
    is_res_sub_checked_all: true,
    is_commercial_sub_checked_all: true,
    is_pws_sub_checked_all: true,
    selected_franchise: "",
  });
  const [lastStep, setLastStep] = useState(false);
  const [weeklyAvgData, setWeeklyAvgData] = useState([]);
  const [weeklyServiceStatistics, setWeeklyServiceStatistics] = useState({});
  const [earchParam, setEarchParam] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fieldStaff, setFieldStaff] = useState({});
  const [loaders, setLoader] = useState(false);
  const [weekDate, setWeekDate] = useState("");
  const [isWeekSetup, setIsWeekSetup] = useState(false);
  const [isResSubCheckedAll, setIsResSubCheckedAll] = useState(true);
  const [isCommercialSubCheckedAll, setIsCommercialSubCheckedAll] =
    useState(true);
  const [isPwsSubCheckedAll, setIsPwsSubCheckedAll] = useState(true);
  const [selectedFranchise, setSelectedFranchise] = useState("");
  const [weeklyAvgDataModified, setWeeklyAvgDataModified] = useState({});

  const getWeeklyDataApiCall = async (params) => {
    const response = await getWeeklyAvgData(params);
    setWeeklyAvgData(response);
  };

  const getFieldOfficeStaffApiCall = async (params) => {
    const response = await getFieldOfficeStaff(params);
    setFieldStaff(response);
  };

  const getServiceStatisticsApiCall = async (params) => {
    const response = await getServiceStatistics(params);
    setWeeklyServiceStatistics(response);
  };

  useEffect(() => {
    if (weeklyAvgData && Object.keys(weeklyAvgData).length > 0) {
      let res_sub_services = [],
        commercial_sub_servcies = [],
        pws_sub_services = [];
      res_sub_services.push(
        // {
        //   "display_name": "Twice-Weekly Service",
        //   "name": "twice_weekly",
        //   "value": weeklyAvgData?.residential_cleaning?.twice_weekly || 0,
        //   "is_checked": true,
        //   "id": 1,
        // },
        // {
        //   "display_name": "Weekly Service",
        //   "name": "weekly",
        //   "value": weeklyAvgData?.residential_cleaning?.weekly || 0,
        //   "is_checked": true,
        //   "id": 2,
        // },
        // {
        //   "display_name": "Every Other Week",
        //   "name": "other_week",
        //   "value": weeklyAvgData?.residential_cleaning?.every_other_week || 0,
        //   "is_checked": true,
        //   "id": 3,
        // },
        // {
        //   "display_name": "Monthly",
        //   "name": "bi_monthly",
        //   "value": weeklyAvgData?.residential_cleaning?.monthly || 0,
        //   "is_checked": true,
        //   "id": 4,
        // },
        // {
        //   "display_name": "One Time Cleans",
        //   "name": "once",
        //   "value": weeklyAvgData?.residential_cleaning?.one_time || 0,
        //   "is_checked": true,
        //   "id": 5,
        // },
        {
          display_name: "Total",
          name: "total",
          value: weeklyAvgData?.residential_cleaning?.total || 0,
          is_checked: true,
          id: 6,
        }
      );

      commercial_sub_servcies.push(
        // {
        //   "display_name": "Pet Waste Station Cleaning ",
        //   "name": "pet_waste_station",
        //   "value": weeklyAvgData?.commercial_cleaning?.pet_wet_station_cleaning || 0,
        //   "is_checked": true,
        //   "id": 6,
        // },
        // {
        //   "display_name": "Common Area Cleaning",
        //   "name": "common_area_cleaning",
        //   "value": weeklyAvgData?.commercial_cleaning?.common_area_cleaning || 0,
        //   "is_checked": true,
        //   "id": 7,
        // },

        {
          display_name: "Total ",
          name: "total",
          value: weeklyAvgData?.commercial_cleaning?.installation_repairs || 0,
          is_checked: true,
          id: 8,
        }
      );

      pws_sub_services.push({
        display_name: " Pet Waste Station Installations",
        name: "pet_waste_station_install",
        value: weeklyAvgData?.pws_installation || 0,
        is_checked: true,
        id: 9,
      });
      setWeeklyAvgDataModified({
        res_sub_services,
        commercial_sub_servcies,
        pws_sub_services,
      });
    }
  }, [weeklyAvgData]);

  useEffect(() => {
    var weekStart =
      moment().day(0).format("YYYY-MM-DD") + "T00:00:00";
    var weekEnd =
      moment().day(6).format("YYYY-MM-DD") + "T23:59:59";
    let currentDate = moment().format("YYYY-MM-DD") + "T00:00:00";

    let startDate = weekStart;
    if (weekStart < currentDate) {
      startDate = currentDate;
    }

    // const dashboard_value = props?.history?.location?.state?.query?.value;
    const user = JSON.parse(localStorage.getItem("authUser"))
      ? JSON.parse(localStorage.getItem("authUser"))
      : "";
    const default_franchise_uuid =
      user?.user?.associated_field_offices?.[0]?.uuid;
    setState({
      ...state,
      start_date: weekStart,
      end_date: weekEnd,
      selected_franchise: default_franchise_uuid,
    });
    setStartDate(startDate);
    setEndDate(weekEnd);
    setSelectedFranchise(default_franchise_uuid);
    let start_week_date = moment().day(0).format("DD");
    let end_week_date = moment().day(6).format("DD");
    let weekString = start_week_date + "-" + end_week_date;
    setState({
      ...state,
      weekDate: weekString,
    });
    setWeekDate(weekString);
    //Get Weeekly Avg
    getWeeklyDataApiCall({
      start_date: moment().format("YYYY-MM-DD") + "T00:00:00",
      end_date: moment().day(6).format("YYYY-MM-DD") + "T23:59:59",
      field_office__uuid: default_franchise_uuid,
    });
    //Service statistics
    getServiceStatisticsApiCall({
      start_date: moment().format("YYYY-MM-DD") + "T00:00:00",
      end_date: moment().day(6).format("YYYY-MM-DD") + "T23:59:59",
      field_office__uuid: default_franchise_uuid,
    });

    let start = moment(weekStart).format("DD");
    let end = moment(weekEnd).format("DD");
    let string = start + "-" + end;
    setState({
      ...state,
      weekDate: string,
    });
    setWeekDate(string);

    let currentDates = moment().format("YYYY-MM-DD");
    var weekStarts = moment().clone().startOf("isoWeek").format("YYYY-MM-DD");
    let startDates = moment(weekStarts).format("YYYY-MM-DD");
    if (weekStarts < currentDates) {
      startDates = currentDates;
    }

    //Get Staff list
    getFieldOfficeStaffApiCall({
      start_date: moment().day(0).format("YYYY-MM-DD"),
      field_office__uuid: default_franchise_uuid,
      ordering: "user__first_name",
    });
  }, []);

  useEffect(() => {
    if (props.current === 2) {
      setState({
        // ...state,
        last_step: true,
        technicians: props.technician_details,
      });
      setLastStep(true);
    } else {
      setState({
        ...state,
        last_step: false,
      });
      setLastStep(false);
    }
  }, []);

  const defaultWeekValue = () => {
    let [start, end] = weekDate.split("-").map((each) => parseInt(each));
    if (start > end) {
      return moment().subtract(1, "month");
    } else {
      return moment();
    }
  };

  const getDisabledDate = () => {
    let [start, end] = weekDate.split("-").map((each) => parseInt(each));
    let [year, month, day] = moment()
      .format("YYYY-MM-DD")
      .split("-")
      .map((each) => parseInt(each));

    return start - day - 1;
  };

  const disabledDate = (current) => {
    // Can not select days before this week
    return current && current < moment().startOf("isoWeek");
  };

  //Get Service ORder
  const getServiceOrder = (start, end) => {
    //Intial Clean
    props.getServiceAllocationIntialClean({
      limit: 15,
      offset: 0,
      service_skill_type__skill_name__in: "residential_init_clean",
      scheduled_appointment__gte: start,
      scheduled_appointment__lte: end,
      status: "tentative",
      ordering: "scheduled_appointment",
    });

    //Residentail Clean
    props.getServiceAllocationResidentail({
      limit: 15,
      offset: 0,
      service_skill_type__skill_name__in: "residential_regular",
      scheduled_appointment__gte: start,
      scheduled_appointment__lte: end,
      status: "tentative",
      ordering: "scheduled_appointment",
    });

    //Commercial
    props.getServiceAllocationCommercail({
      limit: 15,
      offset: 0,
      service_skill_type__skill_name__in:
        "common_area_cleaning,pet_waste_station,install_repair,pet_waste_station_install",
      scheduled_appointment__gte: start,
      scheduled_appointment__lte: end,
      status: "tentative",
      ordering: "scheduled_appointment",
    });

    //Install
    props.getServiceAllocationInstall({
      limit: 15,
      offset: 0,
      service_skill_type__skill_name__in: "pet_waste_station_install",
      scheduled_appointment__gte: start,
      scheduled_appointment__lte: end,
      status: "tentative",
      ordering: "scheduled_appointment",
    });
    setState((state) => ({
      ...state,
      loader: true,
    }));
    setLoader(true);
  };

  const handleResidentialService = (e) => {
    if (e.target.name === "twice-weekly") {
      props.residentailServices(e.target.value);
    }
  };

  const handleCheckedResidentialServices = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    // setState(prevState => {
    //   let { res_sub_services, is_res_sub_checked_all } = prevState;
    //   if (itemName === "check_all") {
    //     is_res_sub_checked_all = checked;
    //     res_sub_services = res_sub_services.map(item => ({ ...item, is_checked: checked }));
    //     props.handleServicesTypes(res_sub_services)

    //   } else {
    //     res_sub_services = res_sub_services.map(item =>
    //       item?.name === itemName ? { ...item, is_checked: checked } : item
    //     );
    //     is_res_sub_checked_all = res_sub_services.every(item => item.is_checked);
    //     props.handleServicesTypes(res_sub_services)
    //   }
    //   return { res_sub_services, is_res_sub_checked_all };
    // });
  };

  const handleCheckedCommercialServices = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    // setState(prevState => {
    //   let { commercial_sub_servcies, is_commercial_sub_checked_all } = prevState;
    //   if (itemName === "check_all") {
    //     is_commercial_sub_checked_all = checked;
    //     commercial_sub_servcies = commercial_sub_servcies.map(item => ({ ...item, is_checked: checked }));
    //     props.handleServicesTypes(commercial_sub_servcies)

    //   } else {
    //     commercial_sub_servcies = commercial_sub_servcies.map(item =>
    //       item?.name === itemName ? { ...item, is_checked: checked } : item
    //     );
    //     is_commercial_sub_checked_all = commercial_sub_servcies.every(item => item.is_checked);
    //     props.handleServicesTypes(commercial_sub_servcies)

    //   }
    //   return { commercial_sub_servcies, is_commercial_sub_checked_all };
    // });
  };

  const handleCheckedPWsServices = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    // setState(prevState => {
    //   let { pws_sub_services, is_pws_sub_checked_all } = prevState;
    //   if (itemName === "check_all") {
    //     is_pws_sub_checked_all = checked;
    //     pws_sub_services = pws_sub_services.map(item => ({ ...item, is_checked: checked }));
    //     props.handleServicesTypes(pws_sub_services)

    //   } else {
    //     pws_sub_services = pws_sub_services.map(item =>
    //       item?.name === itemName ? { ...item, is_checked: checked } : item
    //     );
    //     is_pws_sub_checked_all = pws_sub_services.every(item => item.is_checked);
    //     props.handleServicesTypes(pws_sub_services)
    //   }
    //   return { pws_sub_services, is_pws_sub_checked_all };
    // });
  };

  let { last_step, weekly_service_statistics, loader, start_date } = state;
  let { current } = props;

  const [myWeek, setMyWeek] = React.useState(`${moment().day(0).format("YYYY-MM-DD")} - ${moment().day(6).format("YYYY-MM-DD")}`);

  // const [myWeek, setMyWeek] = React.useState("Viewing Weekly (MMMM " + weekDate + ",YYYY)");
  const pickerChange = (ev) => {
    setMyWeek(ev.value);
    // let currentDate = moment().format("YYYY-MM-DD") + "T00:00:00";
    // let currentDates = moment().format("YYYY-MM-DD");

    if(ev.value.length) {
      if(moment(ev.value[0]).format("YYYY-MM-DD") === moment().day(0).format("YYYY-MM-DD")) {
        props.handleWeekDate(
          moment().day(0).format("YYYY-MM-DD")
        );
        getWeeklyDataApiCall({
          start_date: moment().format("YYYY-MM-DD") + "T00:00:00",
          end_date: moment().day(6).format("YYYY-MM-DD") + "T23:59:59",
          field_office__uuid: selectedFranchise,
        });
        getFieldOfficeStaffApiCall({
          start_date: moment().day(0).format("YYYY-MM-DD"),
          field_office__uuid: selectedFranchise,
          ordering: "user__first_name",
        });
        getServiceStatisticsApiCall({ 
          start_date: moment().format("YYYY-MM-DD") + "T00:00:00",
          end_date: moment().day(6).format("YYYY-MM-DD") + "T23:59:59",
          field_office__uuid: selectedFranchise 
        });
      } else {
        props.handleWeekDate(
          moment(ev.value[0]).format("YYYY-MM-DD")
        );
        getWeeklyDataApiCall({
          start_date: moment(ev.value[0]).format("YYYY-MM-DD") + "T00:00:00",
          end_date: moment(ev.value[1]).format("YYYY-MM-DD") + "T23:59:59",
          field_office__uuid: selectedFranchise,
        });
        getServiceStatisticsApiCall({ 
          start_date: moment(ev.value[0]).format("YYYY-MM-DD") + "T00:00:00",
          end_date: moment(ev.value[1]).format("YYYY-MM-DD") + "T23:59:59",
          field_office__uuid: selectedFranchise
         });
        getFieldOfficeStaffApiCall({
          start_date: moment(ev.value[0]).format("YYYY-MM-DD"),
          field_office__uuid: selectedFranchise,
          ordering: "user__first_name",
        });
      }
    }
    let start_week_date = moment().day(0).format("DD");
    let end_week_date = moment().day(6).format("DD");
    let weekString = start_week_date + "-" + end_week_date;
    setState((state) => ({
      ...state,
      weekDate: weekString,
    }));
    setWeekDate(weekString);
  }
  return (
    <aside className="laside fo-side d-flex flex-column justify-content-between">
      <div className="w-100">
        <h3>Field Ops</h3>
        <div className="form-group mb-10">
          {weekDate.length > 0 && (
            <Datepicker
              // controls={['calendar']}
              select="preset-range"
              firstSelectDay={0}
              selectSize={7}
              value={myWeek}
              moment={moment}
              buttons={<DownOutlined />}
              disabled = {current}
              // renderCalendarHeader={"[Viewing Weekly (]MMMM " + weekDate + ",YYYY[)]"}
              // format=
              // defaultValue = {moment()}
              // calendarType = 'week'
              // returnFormat="moment"
              onChange={pickerChange}
              min={moment().day(0).format("YYYY-MM-DD")}
              // dateFormat={"Viewing Weekly (MMMM " + weekDate + ",YYYY)"}
            />
          )}
        </div>
        {lastStep && props.isWeekSetup ? (
          <div className="mb-10 field_search_bar select-multiple">
            <Select
              placeholder="Search Technician"
              // filterOption={true}
              // onSelect={(value, option) => {
              //   props.handleSelectedTechnician(option.key);
              // }}
              onChange={(value) => {
                if (value === "" || value === undefined) {
                  const selected_value = "";
                  props.handleSelectedTechnician(selected_value);
                } else {
                  props.handleSelectedTechnician(value);
                }
              }}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // style={{ 'width': '150px' }}
              // className="multi_select_technician"
              allowClear={true}
              // value={props.selected_resource}
              // labelInValue
              // optionLabelProp="label"
              mode="multiple"
            >
              {state.technicians.map((item) => (
                <Option
                  key={item.technician_uuid}
                  label={item.technician_name}
                  value={item.technician_uuid}
                >
                  {item.title}
                </Option>
              ))}
            </Select>
            <div className="fitem-ricon">
              <i className="las la-search"></i>{" "}
            </div>
          </div>
        ) : (
          ""
        )}
        {lastStep && !props.isWeekSetup ? (
          <div className="mb-20 field_search_bar ">
            <input
              type="text"
              // onChange={(e) => { console.log(e) }}
              onChange={(e) => {
                props.handleSearchValue(e.target.value);
              }}
              placeholder="Search for Customers"
            />
            <div className="fitem-ricon">
              <i className="las la-search"></i>{" "}
            </div>
          </div>
        ) : (
          ""
        )}
        {lastStep && props.isWeekSetup ? (
          <React.Fragment>
            <div className="mb-10 side-status-block red-status d-flex justify-content-start ai-center">
              <span className="number">
                {weeklyServiceStatistics?.missed_services}
              </span>
              <span className="txt">MISSED SERVICES</span>
            </div>
            <div className="mb-10 side-status-block grey-status d-flex justify-content-start ai-center">
              <span className="number">
                {weeklyServiceStatistics?.awaiting_completion}
              </span>
              <span className="txt">AWAITING COMPLETION</span>
            </div>
            <div className="mb-10 side-status-block green-status d-flex justify-content-start ai-center">
              <span className="number">
                {weeklyServiceStatistics?.completed_services}
              </span>
              <span className="txt">COMPLETE</span>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
      {loaders ? <Loader /> : ""}
    </aside>
  );
};

export default Aside;
