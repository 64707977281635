import { sendRequest } from "../../Action/Offline/index";

const offlineActions = (name) => JSON.parse(localStorage.getItem(name));
const setOfflineActions = (name, data) =>
  localStorage.setItem(name, JSON.stringify(data));

export const init = () => {
  if (!JSON.parse(localStorage.getItem("actions"))) {
    localStorage.setItem("actions", JSON.stringify([]));
  }
};

export const saveOfflineRequest = ({ name, api, method, payload }) => {
  try {
    const requestOfflineActions = offlineActions("actions");
    requestOfflineActions.push({
      name,
      api,
      method,
      payload,
      id: Date.now(),
    });
    setOfflineActions("actions", requestOfflineActions);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const saveFailOfflineRequest = ({ name, api, method, payload, id }) => {
  try {
    const requestOfflineActions = offlineActions("actions_fails");
    requestOfflineActions.push({
      name,
      api,
      method,
      payload,
      id,
    });
    setOfflineActions("actions_fails", requestOfflineActions);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const removeOfflineRequest = ({ id }) => {
  try {
    const requestOfflineActions = offlineActions("actions");
    setOfflineActions(
      "actions",
      requestOfflineActions.filter((request) => request.id !== id)
    );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const sendOfflineRequests = async () => {
  const requestOfflineActions = offlineActions("actions");
  setOfflineActions("actions", []);
  for (let action of requestOfflineActions) {
    //removeOfflineRequest(action);
    try {
      console.log(action);
      const response = await sendRequest(action);
      console.log(response);
    } catch (error) {
      console.error(error);
      saveFailOfflineRequest(action);
    }
  }
};
