import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react';
import { map, isEmpty } from "lodash";


import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";

import { selectPaymentMethod } from "../../../../Store/techJourneyDataSlice";

import moment from "moment-timezone";
import { DatePicker, message, Modal, Popconfirm, Select } from "antd";
import * as yup from "yup";
import visa from "../../../../Assets/images/visa_dark.svg";
import bank from "../../../../Assets/images/Bank.png"
import american from "../../../../Assets/images/american_express_dark.svg";
import plus_icon_green from "../../../../Assets/images/add-circle-outline-green.svg";
import AllState from "../../../../Assets/data/stateList.json";
import Loader from "../../../../Components/Loader";
import { initialCleaningDays } from '../../../../Action/Franchise';
import { getCustomerDetails, createCustomerInstax, createAscPaymentData, createCardPaymentData } from '../../../../Customer/Common/utils';
import {
  addBillingInfo, getUserBillingInfo,
  updateBillingInfo,
  deleteBillingInfo,
} from '../../../../Action/Customer';

const { Option } = Select;

const BillingInfo = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const techJourneyState = useSelector((state) => state.techJourney);

  const [errors, setErrors] = useState({});
  const [isBasicDetail, setIsBasicDetail] = useState(false);
  const [preferredStartDate, setPreferredStartDate] = useState("");
  const [timeRange, setTimeRange] = useState('');
  const [displayPreferredStartDate, setDisplayPreferredStartDate] = useState('');
  const [contactMethods, setContactMethods] = useState([]);
  const [isPrintAndMailInvoices, setIsPrintAndMailInvoices] = useState(false);
  const [isEmailInvoice, setIsEmailInvoice] = useState(false)
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(true)
  const [billingAddress, setBillingAddress] = useState({ address: '', city: '', state: '', zip_code: '', address2: '' });
  const [serviceAddress, setServiceAddress] = useState({ address: '', city: '', state: '', zip_code: '', address2: '' });
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardList, setCardList] = useState([]);
  const [cardSelect, setCardSelect] = useState('');
  const [chargeInvoiceCard, setChargeInvoiceCard] = useState(true)
  const [isAgreed, setIsAgreed] = useState(true);
  const [isAddNewCard, setIsAddNewCard] = useState(false)
  const [staxPublicApikey, setStaxPublicApikey] = useState('');
  const [card_expiry_month, setCardExpiryMonth] = useState('');
  const [card_expiry_year, setCardExpiryYear] = useState('');  
  const [card_holder_name, set_card_holder_name] = useState('');
  const [fattJs, setFattJs] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [userBillingInfoUuid, setUserBillingInfoUuid] = useState('');
  const [address, setAddress] = useState('')
  const card_number = '';
  const card_cvv = '';
  const [isNewACHPament, setisNewACHPament] = useState(false)
  const [BankName, setBankName] = useState('');
  const [RoutingNumber, setRoutingNumber] = useState('');
  const [AccountNumber, setAccountNumber] = useState('');
  const [AccountHolderName, setAccountHolderName] = useState('');
  const [AccountType, setAccountType] = useState('');
  const [BankHolderType, setBankHolderType] = useState('');
  const [stax_customer_id, setstax_customer_id] = useState("");
  const [charge_invoices_ACHPayment, setcharge_invoices_ACHPayemnt] = useState(true);
  //   const address='';
  // mapping contact method
  const time_range = [
    { key: 'AM (8:00 to 12:00)', value: 'am' },
    { key: 'PM (1:00 to 5:00)', value: 'pm' },
    { key: 'Flexible', value: 'flexible' },
  ]
  const contact_methods = [
    { key: 'By Text', value: 'text' },
    { key: 'Call Home Phone', value: 'home_phone' },
    { key: 'Call Mobile', value: 'mobile' },
    { key: 'By Email', value: 'email' },
  ]
  const invoice_preferences = [
    { key: 'Print Invoice', value: 'is_print_and_mail_invoices' },
    { key: 'Email Invoice', value: 'is_email_invoice' },
  ]
  const preferred_service_days = [
    { key: 'Any Day', value: 'any' },
    { key: 'Sunday', value: 'sunday' },
    { key: 'Monday', value: 'monday' },
    { key: 'Tuesday', value: 'Tuesday' },
    { key: 'Wednesday', value: 'wednesday' },
    { key: 'Thursday', value: 'thursday' },
    { key: 'Friday', value: 'friday' },
    { key: 'Saturday', value: 'saturday' },
  ]
  const payment_methods = [
    { key: 'Cash', value: 'cash' },
    { key: 'Check', value: 'Check' },
    { key: 'Credit Card', value: 'card' },
    { key: 'ACH Payments', value: 'ACH Payments' },
  ]


  useImperativeHandle(ref, () => ({
    // card validation
    validate(action) {
      const schema = yup.object().shape({
        contactMethods: yup.array().min(1).defined('Contact method is required'),
        isAgreed: yup.bool().oneOf([true], 'Field must be checked'),
        preferredStartDate: props?.serviceInfo?.serviceType?.key.toLowerCase() !== 'maintenance' ?  yup.string().required('Preferred Start Date is required') :null,
        timeRange:  props?.serviceInfo?.serviceType?.key.toLowerCase() !== 'maintenance' ? yup.string().required('Time Range is required') : null,
        paymentMethod: yup.string().required('Please select a payment method.'),
        cardSelect: action !== 'save_lead' && (paymentMethod === "card" || paymentMethod === "ACH Payments") ? yup.string().required(paymentMethod === "card" ? 'Please select a card' : 'Please select an ach payment') : null,
        isBillingAddressSame: yup.bool(),
        billingAddress: yup.object().when("isBillingAddressSame", {
          is: false, then: yup.object().shape({
            address: yup.string().required("Address is required"),
            state: yup.string().required("State is required"),
            city: yup.string().required("City is required"),
            zip_code: yup.string().required("Zipcode is required").min(5, 'Your ZIP code is invalid.')
          })
        }),
      })
      const dataObj = { 
        isAgreed, 
        contactMethods, 
        paymentMethod, 
        cardSelect, 
        billingAddress, 
        isBillingAddressSame, 
        preferredStartDate, 
        timeRange,
        isPrintAndMailInvoices, 
        isEmailInvoice };
      return schema.validate(dataObj, { abortEarly: false }).then(() => {
        setErrors({});
        setLoader(false);        
        props.setServiceInfoData({ value: dataObj, key: true, name: 'billingInfo' })
        return Promise.resolve('success');
        // this.props.createServiceOrder()
      }).catch((error) => {
        let err_data = {};
        map(error?.inner, (item) => { err_data[item.path] = item.message })
        setErrors(err_data);
        setLoader(false);
        return Promise.reject('fail');
      });
    }
  }));

  useEffect(() => {
    setLoader(true);
    const stax_public_apikey = 
      props.fieldOfficeData !== undefined ?
       props.fieldOfficeData?.stax_public_apikey ? props.fieldOfficeData?.stax_public_apikey : ''
      : props.data?.stax_public_apikey ? props.data?.stax_public_apikey : ' ';
    setStaxPublicApikey(stax_public_apikey);
    setstax_customer_id(props?.customer?.stax_customer);
  }, [props.fieldOfficeData]);

  useEffect(() => {
    setPaymentMethod(props.serviceInfo?.payment_method?.value);
  }, [props.serviceInfo?.payment_method?.value]);

  const loadData = () => {    
    setContactMethods(props.data?.preferred_contact_methods ?? []);
    setPreferredStartDate(props.data?.preferred_start_dates ?? "");
    setTimeRange(props.data?.setTimeRange ?? [])
    // setPaymentMethod(props.data?.payment_method ?? '');
    const billingData = props.data;
    const { address } = props.infoData;      
    const serviceInfo = [];

    if(address.address){
      setBillingAddress(address);
    }else{
      setBillingAddress({ address: address });
    }
    setPreferredStartDate((billingData?.preferred_start_date !== "") ? moment(billingData?.preferred_start_date).format('MM-DD-YYYY') : '');
    setTimeRange(billingData?.schedule_time_range ?? '');      
    serviceInfo.push({value: billingData?.schedule_time_range , key: true, name: "time_range" });       
    serviceInfo.push({value: billingData?.preferred_contact_methods, key: true, name: "preferred_contact_methods" });  
    serviceInfo.push({value: billingData?.preferred_start_date, key: true, name: "preferred_start_date" });          
    serviceInfo.push({value: billingData?.payment_method, key: true, name: "payment_method" });
    setCardList(billingData?.cardList?.value ?? '');
    setCardSelect(billingData?.cardSelect?.value ?? '');
    setChargeInvoiceCard(billingData?.charge_invoice_card?.value ?? false);
    setIsAgreed(billingData?.isAgreed?.value ?? true);      
    if (!billingData?.cardList?.value)
      userBillingInfo();          
    if(billingData?.is_print_and_mail_invoices){
      setIsPrintAndMailInvoices(true);
      serviceInfo.push({name:'invoice_preference', key: true, value: [
        { value: true, key: true, name: 'is_print_and_mail_invoices' },
        { value: false, key: false, name: 'is_email_invoice' }
      ]})
    }else if (billingData?.is_email_invoice) {
        setIsEmailInvoice(true);
        setIsPrintAndMailInvoices(false);
        serviceInfo.push({name:'invoice_preference', key: true, value: [
          { value: false, key: false, name: 'is_print_and_mail_invoices' },
          { value: true, key: true, name: 'is_email_invoice' }
        ]})
    } else {
        setIsEmailInvoice(false);
        setIsPrintAndMailInvoices(false);
        serviceInfo.push({name:'invoice_preference', key: true, value: [
          { value: false, key: false, name: 'is_print_and_mail_invoices' },
          { value: false, key: false, name: 'is_email_invoice' }
        ]})
    }

    props.setServiceInfoList(serviceInfo); 
  }

  useEffect(() => {
    card_holder_name && props.setServiceInfoData({ value: card_holder_name, key: true, name: 'card_holder_name' })
  }, [card_holder_name])

  useEffect(() => {
    if (isBillingAddressSame) {            
      if(props.serviceInfo || props.infoData){
        const address = props.serviceInfo?.serviceArea?.value?.address ?? props?.infoData?.address ?? '';
        if(address.address){
          setBillingAddress(address);
          setServiceAddress(address);
        }else{
          setBillingAddress({ address: address, city: props.data?.city, state: props.data?.state, zip_code: props.data?.zip_code ?? props.serviceInfo?.zipCode?.value });
          setServiceAddress({ address: address, city: props.data?.city, state: props.data?.state, zip_code: props.data?.zip_code ?? props.serviceInfo?.zipCode?.value });
        }
      }
    } else {
      setBillingAddress({ address: '', city: '', state: '', zip_code: '', address2: '' });
      setServiceAddress({ address: '', city: '', state: '', zip_code: '', address2: '' });
    }
  }, [isBillingAddressSame]);

  const userBillingInfo = async () => {
    const data = await getUserBillingInfo({ user__uuid: props?.customer?.uuid });
    // console.log("data",data);
    let cards = []
    data.data.forEach(element => {
      const card_data = JSON.parse(element.stax_payment_object)
      let card = {
        id: element.uuid,
        card_type: card_data.card_type,
        card_last_four: card_data.card_last_four
      }
      cards.push(card);
    });

    setCardList([...cardList, ...cards]);
  };

  const onAddressChanged = (e) => {
    setBillingAddress(address => ({
      ...address,
      [e.target.name]: e.target.value,
    }))
  }

  /*
 * card validation
 */
  const cardValidation = () => {
    const cardSchema = yup.object().shape({
      card_number: fattJs.validNumber ? null : yup.string().nullable().required('Card Number is required'),
      card_expiry_month: yup.string().nullable().required('Card expire month is required'),
      card_expiry_year: yup.string().nullable().required('Card expire year is required').min(4, 'Card expire year is invalid.').max(4, 'Card expire year is invalid.'),
      card_cvv: fattJs.validCvv ? null : yup.string().nullable().required('Card CVV is required'),      
      card_holder_name : yup.string().nullable().required('Card Holder Name is required'),
      isBillingAddressSame: yup.bool(),
      billingAddress: yup.object().when("isBillingAddressSame", {
        is: false, then: yup.object().shape({
          address: yup.string().required("Address is required"),
          state: yup.string().required("State is required"),
          city: yup.string().required("City is required"),
          zip_code: yup.string().required("Zipcode is required").min(5, 'Your ZIP code is invalid.')
        })
      }),
    })

    const cardData = { isBillingAddressSame, billingAddress, card_number, card_expiry_month, card_expiry_year, card_cvv, card_holder_name  };
    cardSchema.validate(cardData, { abortEarly: false }).then(() => {
      //setErrors({})
      setLoader(true);
      addNewCard()
    }).catch((error) => {
      let err_data = {};
      map(error?.inner, (item) => { err_data[item.path] = item.message })
      setErrors(err_data)
      //console.log("Errors");
      //console.log(errors);
      message.error(`Valid ${err_data.card_number}. Valid ${err_data.card_cvv}`);
    });
  }
  // ACH PAYMENT VALIDATION
  const ACHPaymentValidation = () => {
    const ACHSchema = yup.object().shape({
      BankName: yup.string().required("Please enter correct value!"),
      RoutingNumber: yup.string().nullable().required('Please enter correct value!'),
      AccountHolderName: yup.string().nullable().required('Please enter correct value!'),
      AccountNumber: yup.string().nullable().required('Please enter correct value!'),
      AccountType: yup.string().required("Please enter correct value!"),
      BankHolderType: yup.string().required("Please enter correct value!"),
    })

    const ACHData = { BankName, RoutingNumber, AccountNumber, AccountHolderName, AccountType, BankHolderType };
    ACHSchema.validate(ACHData, { abortEarly: false }).then(() => {
      setErrors({})
      setLoader(true);
      addNewACHPayment();
    }).catch((error) => {
      let err_data = {};
      map(error?.inner, (item) => { err_data[item.path] = item.message })
      setErrors(err_data)
      //console.log("Errors");
      //console.log(errors);
      //message.error(error.message);
    });
  }
  
  const addNewACHPayment = async () => {
    setErrors({})
    const customer = props.customer;
    setLoader(true);
    const token_obj = {
      // address: billingAddress ? billingAddress : serviceAddress,
      address: props?.data?.sameContactAddress
        ? props?.data?.address
        : props?.data?.billingAddress,
      emails: props?.data?.emailaddress_set|| '',
      firstName: props?.data?.first_name || '',
      lastName: props?.data?.last_name || '',
      method: 'bank',
      phoneNumbers: props?.data?.phone_numbers,
    }
    let customerData = await getCustomerDetails(token_obj.firstName ? token_obj : props?.serviceInfo?.serviceArea?.value)
    let createCustomerResp = await createCustomerInstax(customerData)
    if (createCustomerResp.id) {
      let ach_data = await createAscPaymentData(AccountHolderName, AccountNumber, RoutingNumber, BankHolderType, AccountType, createCustomerResp.id);
      fattJs.tokenize(ach_data).then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCardSelect(res.id);
          setCardList([...cardList, res]);
          setLoader(false);
          setisNewACHPament(false);
          formatModalAch();
          props.setServiceInfoData({ value: res.id, key: true, name: 'cardSelect' })
          setErrors({})
        }
      }).catch((err) => {
        console.log(err.errors[0]);
        message.error(err.errors[0]);
        // formatModalAch();
        setLoader(false);
      });
    }
  }

  /**
 * Add New Card
 */
  const addNewCard = async () => {
    // if (!billingAddress?.geo_location) {
    //   delete billingAddress?.geo_location
    // }

    
    const customer_data = {
      address: props?.data?.sameContactAddress
        ? props?.data?.address
        : props?.data?.billingAddress,
      emails: props?.data?.emailaddress_set || "",
      firstName: props?.data?.first_name || "",
      lastName: props?.data?.last_name || "",
      method: "card",
      phoneNumbers: props?.data?.phone_numbers,
    };
    const token_obj = {
      month: card_expiry_month ? card_expiry_month : null,
      year: card_expiry_year ? card_expiry_year : null,
      card_number: card_number ? card_number : null,
      firstname: card_holder_name?.split(' ')[0] || '',
      lastname: card_holder_name?.split(' ')[1] || '',
      method: 'card',
      company: 'ASP',     
    }
    let customerData = await getCustomerDetails(customer_data.firstName ? customer_data : props?.serviceInfo?.serviceArea?.value);
    let createCustomerResp = await createCustomerInstax(customerData);
    if(createCustomerResp.id) {
    let card_data = await createCardPaymentData(card_holder_name, token_obj.firstname, token_obj.lastname, token_obj.card_number, token_obj.month, token_obj.year, createCustomerResp.id);
    if (createCustomerResp.id) { 

    }
   fattJs.tokenize(card_data).then((res) => {
      if (res && Object.keys(res).length > 0) {
        setCardSelect(res.id);
        setCardList([...cardList, res]);
        setLoader(false);
        setIsAddNewCard(false)

        props.setServiceInfoData({ value: res.id, key: true, name: 'cardSelect' })
      }
    }).catch((err) => {
      setLoader(false)
      map(err.fieldErrors, (item) => {
        message.error(item.message, 10)
      });
      message.error(err.message, 10);
    });
  }
  }

  // on preferred date changed
  const onDataChange = (e) => {
    setPreferredStartDate(e.target.checked ? e.target.value : "");
    setDisplayPreferredStartDate(null);
    props.setServiceInfoData({ value: e.target.checked ? e.target.value : null, key: true, name: 'preferred_start_date' })
  }

  // on address change
  const onAddressChange = (e) => {
    Object.assign(billingAddress, { [e.target.name]: e.target.value })
    setBillingAddress(billingAddress)
  };

  // render all state
  const renderAllState = () => {
    return map(AllState?.AllState, (item, i) => {
      return (<Option value={item.abbreviation} key={i}>{item.name}</Option>)
    })
  }


  const onTimeRangeChange = (e) => {
    setTimeRange(e.target.checked ? e.target.value : null);
    props.setServiceInfoData({ value: e.target.value, key: true, name: 'time_range' })
  }

  // on contact method changed
  const onContactMethodChange = (e) => {
    const idx = contactMethods?.indexOf(e.target.value);
    let contacts = [];
    if (idx > -1) {
      contacts = contactMethods.filter(i => i !== e.target.value);
      setContactMethods(prev => prev.filter(fruit => fruit !== e.target.value))
    } else {
      contacts = [...contactMethods, e.target.value]
      setContactMethods(prev => [...prev, e.target.value])
    }    
    props.setServiceInfoData({ value: contacts, key: true, name: 'preferred_contact_methods' })
  };

  // render extra footer
  const renderExtraFooter = () => {
    return (
      <div className="data_indicator">
        <a href="/#" onClick={(e) => { e.preventDefault(); }} className="btn">Available</a>
        <a href="/#" onClick={(e) => { e.preventDefault(); }} className="btn unavailable">Unavailable</a>
      </div>
    )
  }

  // on billing info change
  const onTextChange = (e, type) => {
    const value = type === 'bool' ? e.target.checked : e.target.value;
    if (e.target.name === 'charge_invoice_card') {
      setChargeInvoiceCard(value);
      props.setServiceInfoData({ value: value, key: true, name: 'charge_invoice_card' })
    }
    if (e.target.name === 'isAgreed') {
      setIsAgreed(value);
    }
  }

  const onSelectDisplayPreferredStartDate = (e) => {
    setDisplayPreferredStartDate(e);
    setPreferredStartDate(moment(e).format("MM-DD-YYYY"));
    props.setServiceInfoData({
      value: moment(e).format("MM-DD-YYYY"),
      key: true,
      name: "preferred_start_date",
    });
  };

  const handleIsBillingAddressSame = () => {
    setIsBillingAddressSame(!isBillingAddressSame)
  }

  // on billing value change
  const onBillingOptionsChanged = (e) => {
    const idx = cardList?.findIndex(obj => obj.id === e);
    props.setServiceInfoData({ value: cardList?.[idx]?.id, key: true, name: 'cardSelect' })
    setCardSelect(cardList?.[idx]?.id);
    setUserBillingInfoUuid(cardList?.[idx]?.id);
    setAddress(cardList?.[idx]?.address)
  };

  const loadStax = () => {
    setIsAddNewCard(true);    
    set_card_holder_name('');
    setCardExpiryMonth('');
    setCardExpiryYear('');
    setErrors({});
    if (staxPublicApikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(staxPublicApikey, {
        number: {
          id: "card-number",
          placeholder: "Card Number",
          style: "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;" // 35px is the height of my card-number div
        },
        cvv: {
          id: "card-cvv",
          placeholder: "CVV",
          style: "height: 35px; width: 100%; font-size: 15px; font-family: Helvetica Neue, Helvetica; color: #31325f; font-weight: 300;" // 35px is the height of my card-cvv div
        }
      });
      fattJsObj.showCardForm().then(handler => {
        //console.log('form loaded', handler);
      }).catch(err => {
        console.error('error occur', err);
      });
      setFattJs(fattJsObj);
    } else {
      message.error('Error occur while loading stax. please contact to admin.', 10);
    }
  }
  const loadStaxACH = () => {
    let stax_public_apikey =
      props.fieldOfficeData !== undefined ?
        props.fieldOfficeData?.stax_public_apikey ? props.fieldOfficeData?.stax_public_apikey : ' '
      : props.data?.stax_public_apikey ? props.data?.stax_public_apikey : ' ';
    if (stax_public_apikey) {
      const FattJs = window.FattJs;
      const fattJsObj = new FattJs(stax_public_apikey, {});
      setFattJs(fattJsObj);
    } else {
      message.error('Error occur while loading stax. please contact to admin.', 10);
    }
  }
  const choosePaymentMethod = (e) => {
    setPaymentMethod(e)
    dispatch(selectPaymentMethod({ value: e, key: true, name: 'payment_method' }))
    props.setServiceInfoData({ value: e, key: true, name: 'payment_method' })
  }
  const loadACHPayment = () => {
    loadStaxACH();
    setisNewACHPament(true);
  }
  const getBankHolderType = (e) => {
    console.log(e);
  }
  // render add card modal
  const renderAddCardModal = () => {
    return (
      <Modal
        title="Add Card"
        visible={isAddNewCard}
        onCancel={() => { setIsAddNewCard(false) }}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => {
            setIsAddNewCard(false)
          }}>Cancel</button>,
          <button
            key="submit"
            className={`btn btn-warning`}
            // disabled={!emailText}
            onClick={cardValidation}
          >Add</button>
        ]}
      >
        <div>
          <div className={"flex mt-10"}>
            <div className={"flex-1"}>
              <div className="form-group">

                <div className="fitem">
                  <label>Card Number <span style={{'color': 'red'}}>*</span></label>
                  <div id="card-number" className="form--control"></div>
                </div>              
                <div className="fitem">
                  <label>CVV <span style={{'color': 'red'}}>*</span></label>
                  <div id="card-cvv" className="form--control"></div>
                </div>                
                <div className="fitem">                
                  <label>Card Holder Name<span style={{'color': 'red'}}>*</span></label>
                  <input type="text" id="card_holder_name" className="form--control" placeholder="Card Holder Name" value={card_holder_name} onChange={(e)=>set_card_holder_name(e.target.value)} style={{ 'width': '100%'}}/>
                  {errors?.['card_holder_name'] ? <label className="new_err_msg"><i className="las la-info-circle"/>{errors?.['card_holder_name']}</label> : ''}                               
                </div>
                <div className="half-fitem fitem">
                  <div className="half-fitem fitem">
                    <label>Expiration Month <span style={{'color': 'red'}}>*</span></label>
                    <input type="text" className="form--control" placeholder="MM" maxLength={2}
                      inputMode="numeric"
                      onPaste={(event) => {
                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)
                        ) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setCardExpiryMonth(e.target.value) }}
                      value={card_expiry_month}
                      style={{ 'width': '100%'}}
                    />
                    {errors?.['card_expiry_month'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['card_expiry_month']}</label> : ''}
                  </div>
                  <div className="half-fitem fitem">
                    <label>Expiration Year <span style={{'color': 'red'}}>*</span></label>
                    <input type="text" className="form--control" placeholder="YYYY" maxLength={4}
                      inputMode="numeric"
                      onPaste={(event) => {
                        if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)
                        ) {
                          // invalid character, prevent input
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => { setCardExpiryYear(e.target.value) }}
                      value={card_expiry_year}
                      style={{ 'width': '100%'}}
                    />
                    {errors?.['card_expiry_year'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['card_expiry_year']}</label> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
  const formatModalAch = () => {
    setBankName('');
    setRoutingNumber('');
    setAccountHolderName('');
    setAccountNumber('');
    setAccountType('');
    setBankHolderType('');
  }
  // reender ACH payment modal
  const onCancelAch = () => {
    setErrors({});
    formatModalAch();
    setisNewACHPament(false)
  }
  const renderPaymentModal = () => {
    return (
      <Modal
        title="Bank Account"
        visible={isNewACHPament}
        onCancel={() => { onCancelAch() }}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => {
            onCancelAch()
          }}>Cancel</button>,
          <button
            key="submit"
            className={`btn btn-warning`}
            // disabled={!emailText}
            onClick={ACHPaymentValidation}
          >Add</button>
        ]}
      >
        <div>
          <div className={"flex mt-10"}>
            <div className={"flex-1"}>
              <div className="form-group">

                <div className="fitem">
                  <label>Bank Name</label><label style={{ color: 'red' }}> &nbsp;*</label>
                  <input type="text" className="form--control" value={BankName} placeholder="Bank Name" maxLength={15}
                    inputMode="text"
                    onChange={(e) => { setBankName(e.target.value) }}
                  />
                  {errors?.['BankName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['BankName']}</label> : ''}
                </div>
                <div className="fitem">
                  <label>Routing Number</label><label style={{ color: 'red' }}> &nbsp;*</label>
                  <input type="text" className="form--control" value={RoutingNumber} placeholder="Routing Number" maxLength={9}
                    inputMode="number"
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)
                      ) {
                        // invalid character, prevent input
                        e.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => { setRoutingNumber(e.target.value) }}
                  />
                  {errors?.['RoutingNumber'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['RoutingNumber']}</label> : ''}
                </div>
                <div className="fitem">
                  <label>Account Holder Name</label><label style={{ color: 'red' }}> &nbsp;*</label>
                  <input type="text" className="form--control" value={AccountHolderName} placeholder="Account Holder Name" maxLength={18}
                    inputMode="number"
                    onChange={(e) => { setAccountHolderName(e.target.value) }}
                  />
                  {errors?.['AccountHolderName'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['AccountHolderName']}</label> : ''}
                </div>
                <div className="fitem">
                  <label>Account Number</label><label style={{ color: 'red' }}> &nbsp;*</label>
                  <input type="text" id="account-number" value={AccountNumber} className="form--control" placeholder="Account Number" maxLength={15}
                    inputMode="number"

                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)
                      ) {
                        // invalid character, prevent input
                        e.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      if (event.clipboardData.getData('Text').match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => { setAccountNumber(e.target.value) }}
                  />
                  {errors?.['AccountNumber'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['AccountNumber']}</label> : ''}
                </div>
                <div className="fitem">
                  <label>Account Type</label><label style={{ color: 'red' }}> &nbsp;*</label>
                  <div>
                    <input type="radio" id="achecking" checked={AccountType === 'checking'} name="AccountType" onChange={e => setAccountType(e.target.value)} value={"checking"} />&nbsp;&nbsp;
                    <label for="html">Checking</label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <input type="radio" id="asaving" checked={AccountType === 'savings'} name="AccountType" onChange={e => setAccountType(e.target.value)} value="savings" />&nbsp;&nbsp;
                    <label for="html">Savings </label>
                  </div>
                  {errors?.['AccountType'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['AccountType']}</label> : ''}
                </div>
                <div className="fitem">
                  <label>Bank Holder Type</label><label style={{ color: 'red' }}> &nbsp;*</label>
                  <div>
                    <input type="radio" id="checking" checked={BankHolderType === 'Personal'} name="BankHolderType" onChange={e => setBankHolderType(e.target.value)} value="Personal" />&nbsp;&nbsp;
                    <label for="html">Personal</label><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <input type="radio" id="saving" checked={BankHolderType === 'Business'} name="BankHolderType" onChange={e => setBankHolderType(e.target.value)} value="Business" />&nbsp;&nbsp;
                    <label for="html">Business</label>
                  </div>
                  {errors?.['BankHolderType'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['BankHolderType']}</label> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
  //ACH PAYMENT MODAL END HERE
  return (
    <div className="adetail">
      {/* {loader && <Loader />} */}

      <div className="form-block">
        {/* 
        Not required as per discussion with Vaishali/Sayali - Teams chat (Delivery - Discussion - EAP 3002-29-05-2023)
        <div className="frow pt-10">
          <h3>Billing Address</h3>
          <div className="max-w-328">
            <div className="fitem-check mt-10 mb-10">
              <input
                type="checkbox"
                className="fitem-ck-input"
                checked={isBillingAddressSame}
                id="isBillingAddress"
                name="isBillingAddress"
                onChange={handleIsBillingAddressSame}
              />
              <label htmlFor="isBillingAddress" className="fitem-ck-txt">Same as Service Address</label>
            </div>
            <div className="form-group mb-20">
              <div className="fitem">
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={billingAddress?.address}
                  onChange={onAddressChanged}
                />
                {errors?.['billingAddress.address'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.address']}</label> : ''}
              </div>
              <div className="fitem">
                <input
                  type="text"
                  placeholder="Address Line 2 (Optional)"
                  name="address2"
                  value={billingAddress?.address2 || ''}
                  onChange={onAddressChanged}
                />
              </div>
              <div className="half-fitem fitem">
                <div className="half-fitem fitem">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={billingAddress?.city}
                    onChange={onAddressChanged}
                  />
                  {errors?.['billingAddress.city'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.city']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <Select
                    showArrow={true}
                    name="state"
                    placeholder="State"
                    virtual={false}
                    value={billingAddress?.state || undefined}
                    onChange={(value) => onAddressChange({ target: { name: 'state', value: value } })}
                  >
                    {renderAllState()}
                  </Select>
                  {errors?.['billingAddress.state'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.state']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <input
                    type="text"
                    className='form--control'
                    placeholder="ZIP Code"
                    name="zip_code"
                    maxLength={5}
                    minLength={5}
                    onChange={onAddressChanged}
                    value={billingAddress?.zip_code || ''}
                  />
                  {errors?.['billingAddress.zip_code'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['billingAddress.zip_code']}</label> : ''}
                </div>
              </div>
            </div>
          </div>
        <hr />
        </div> */}
        {/* Payment Information Block */}
        <div className="frow pt-10">
          <h3>Payment Information</h3>
          <div className="pi-block max-w-328">
            {/* Payment Method Block */}
            <div className="field-group">
              <label>Payment Method <sup>*</sup></label>
              <div className="mb-10">
                <Select
                  placeholder="Select"
                  value={paymentMethod || undefined}
                  onChange={(e) => choosePaymentMethod(e)}
                >
                  {map(payment_methods, (item, index) => {
                    return (
                      <Option key={index} value={item.value}>{item.key}</Option>
                    )
                  })}
                </Select>
                {props.errors?.['paymentMethod'] ? <label className="new_err_msg"><i className="las la-info-circle" />{props.errors?.['paymentMethod']}</label> : ''}
              </div>
              {paymentMethod === "card" ? <div>
                <Select
                  placeholder="Select"
                  disabled={cardList.length === 0}
                  value={cardSelect || undefined}
                  onChange={(e) => onBillingOptionsChanged(e)}
                >
                  {map(cardList, (item, index) => {
                    return (
                      <Option key={index} value={item?.id}>
                        <React.Fragment>
                          <div className="card_img flex ai-center flex-1 ml-10">
                            {item.card_type === 'visa' ?
                              <img height="25" src={visa} alt="" /> :
                              <img height="25" src={american} alt="" />
                            } &nbsp;&nbsp;
                            <div className={'flex-1'}>
                              <div>
                                <label className="m-0">{item.method === 'card' ? !isEmpty(item.card_type) ? `${item.card_type} ending in ${item.card_last_four}` : '' : ''}</label>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      </Option>
                    )
                  })}
                </Select>
                {errors?.['cardSelect'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['cardSelect']}</label> : ''}
              </div> : ''}
            </div>
            {paymentMethod === "ACH Payments" ? <div>
              <Select
                placeholder="Select"
                disabled={cardList.length === 0}
                value={cardSelect || undefined}
                onChange={(e) => onBillingOptionsChanged(e)}
              >
                {map(cardList, (item, index) => {
                  return (
                    <Option key={index} value={item?.id}>
                      <React.Fragment>
                        <div className="card_img flex ai-center flex-1 ml-10">
                          {item.method === 'bank' ?
                            <img height="25" src={bank} alt="" /> : ''}
                          &nbsp;&nbsp;
                          <div className={'flex-1'}>
                            <div>
                              <label className="m-0">{`ACH Ending XXXX ${item?.card_last_four}`}</label>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    </Option>
                  )
                })}
              </Select>
              {errors?.['cardSelect'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['cardSelect']}</label> : ''}
            </div> : ''}
            {paymentMethod === "card" ? <div className="fitem-check mt-10">
              <input
                type="checkbox"
                className="fitem-ck-input"
                id="charge"
                name="charge_invoice_card"
                value={chargeInvoiceCard}
                onChange={(e) => onTextChange(e, 'bool')}
              />
              <label htmlFor="charge" className="fitem-ck-txt">Charge invoices to card</label>
            </div> : ''}

            {paymentMethod === "ACH Payments" ? <div className="fitem-check mt-10">
              <input
                type="checkbox"
                className="fitem-ck-input"
                id="charge"
                name="charge_invoice_card"
                value={chargeInvoiceCard}
                onChange={(e) => onTextChange(e, 'bool')}
              />
              <label htmlFor="charge" className="fitem-ck-txt">Charge invoices to ACH Payment</label>
            </div> : ''}

            {paymentMethod === "card" ? <a className="flex ai-center justify-content-start mt-10" style={{ 'color': '#ff9649' }} onClick={loadStax}><img className="icoimg ml-0 mr-5" src={plus_icon_green} alt="" /> <strong>Add Card</strong></a>
              : ''}
            {paymentMethod === "ACH Payments" ? <a className="flex ai-center justify-content-start mt-10" style={{ 'color': '#ff9649' }} onClick={loadACHPayment}><img className="icoimg ml-0 mr-5" src={plus_icon_green} alt="" /> <strong>Add ACH Payment</strong></a>
              : ''}
          </div>
        </div>
        <hr style={{ margin: '20px 0' }} />
        <div className="frow">
          <h4> Terms of Service</h4>
          {/*TODO: Add or delete Terms of Service link */}
          <span>By signing up, I grant my permission for America's Swimming Pool Co. to access my property and agree to the full
            <a> Terms of Service</a> and
            <a href="/#"> Privacy Policy.</a></span>
          <div className="fitem-check mt-10">
            <input
              id="isAgreed"
              name="isAgreed"
              checked={isAgreed}
              onChange={(e) => onTextChange(e, 'bool')}
              type="checkbox" className="fitem-ck-input"
            //  disabled={!zipcodeCheck}
            />
            <label htmlFor="isAgreed" className="fitem-ck-txt">Customer verbally agreed</label>
          </div>
          {errors?.['isAgreed'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['isAgreed']}</label> : ''}
        </div>
        {renderAddCardModal()}
        {paymentMethod === "ACH Payments" ? renderPaymentModal() : ''}

      </div>
    </div>
  )
})

export default BillingInfo