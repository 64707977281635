import moment from "moment-timezone";

export const creationFramePeriod = (value = "day") => ({
  created_at__lte: moment().endOf(value).format("YYYY-MM-DD"),
  created_at__gte: moment().startOf(value).format("YYYY-MM-DD"),
  created_at__lte_prev: moment()
    .endOf(value)
    .subtract(1, value)
    .format("YYYY-MM-DD"),
  created_at__gte_prev: moment()
    .startOf(value)
    .subtract(1, value)
    .format("YYYY-MM-DD"),
});

export const createCurrentFramePeriod = (type) =>
  type === "day" || type === "today" || type === "week"
    ? {
        created_at__lte: moment().format("YYYY-MM-DD"),
        created_at__gte: moment()
          .subtract(1, type === "day" || type === "today" ? "week" : type)
          .add(1, "day")
          .format("YYYY-MM-DD"),
      }
    : type === "year" ? {
        created_at__lte: moment().format("YYYY-MM-DD"),
        created_at__gte: moment().subtract(1, type).add(1, "month").format("YYYY-MM-DD"),
      }:{
        created_at__lte: moment().format("YYYY-MM-DD"),
        created_at__gte: moment().subtract(1, type).format("YYYY-MM-DD"),
      };
