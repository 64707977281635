import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { _, map, isEqual, isEmpty } from "lodash";
import "../../Assets/css/customFrequency.css";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Select,
  setOptions,
  Input,
  Checkbox,
  Radio,
  RadioGroup,
  Datepicker,
  SegmentedGroup,
  SegmentedItem,
  formatDate,
  localeEn,
} from "@mobiscroll/react";
import { Modal } from "antd";

setOptions({
  locale: localeEn,
  theme: "ios",
  themeVariant: "light",
});

const selectResponsive = {
  xsmall: {
    touchUi: true,
  },
  small: {
    touchUi: false,
  },
};

const days = [
  {
    name: "Sunday",
    short: "SU",
    checked: true,
  },
  {
    name: "Monday",
    short: "MO",
    checked: false,
  },
  {
    name: "Tuesday",
    short: "TU",
    checked: false,
  },
  {
    name: "Wednesday",
    short: "WE",
    checked: false,
  },
  {
    name: "Thursday",
    short: "TH",
    checked: false,
  },
  {
    name: "Friday",
    short: "FR",
    checked: false,
  },
  {
    name: "Saturday",
    short: "SA",
    checked: false,
  },
];

const months = [
  {
    value: 1,
    text: "January",
  },
  {
    value: 2,
    text: "February",
  },
  {
    value: 3,
    text: "March",
  },
  {
    value: 4,
    text: "April",
  },
  {
    value: 5,
    text: "May",
  },
  {
    value: 6,
    text: "June",
  },
  {
    value: 7,
    text: "July",
  },
  {
    value: 8,
    text: "August",
  },
  {
    value: 9,
    text: "September",
  },
  {
    value: 10,
    text: "October",
  },
  {
    value: 11,
    text: "November",
  },
  {
    value: 12,
    text: "December",
  },
];

const ordinalList = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
};

const dayInputProps = {
  className: "custom-repeat-input custom-repeat-select-nr",
  inputStyle: "outline",
};

const monthInputProps = {
  className: "custom-repeat-input custom-repeat-select-month",
  inputStyle: "outline",
};

const dateInputProps = {
  className: "custom-repeat-input custom-specific-date",
  inputStyle: "outline",
};

// returns the weeknumber of the passed date
function getWeekDayNum(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  let count = 0;
  for (
    let d = firstDayOfMonth;
    d < lastDayOfMonth;
    d.setDate(d?.getDate() + 7)
  ) {
    if (date >= d) {
      count++;
    } else {
      break;
    }
  }

  return Math.max(1, count);
}

const CustomFrequency = (props) => {
  const [recurringString, setrecurringString] = useState("");
  const [defaultDescription, setDefaultDescription] = useState(null);
  const [useDefaultDesc, setUseDefaultDesc] = useState(false);
  const [frequency_for_price, setfrequency_for_price] = useState("one time");
  const [frequency, setFrequency] = useState({
    event: {
      allDay: true,
      end: null,
      start: new Date().setDate(new Date().getDate() + 1),
      _days: [new Date()],
    },
  });
  const [tempEvent, setTempEvent] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [start, startRef] = React.useState(null);
  const [end, endRef] = React.useState(null);
  const [popupEventDate, setDate] = React.useState([]);
  const [minDate, setMinDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);

  // recurring editor data
  const [repeatData, setRepeatData] = React.useState([
    {
      value: "norepeat",
      text: "Does not repeat",
    },
    {
      value: "daily",
      text: "Daily",
    },
    {
      value: "weekly",
      text: "Weekly",
    },
    {
      value: "monthly",
      text: "Monthly",
    },
    {
      value: "monthly-pos",
      text: "Monthly",
    },
    {
      value: "One Time",
      text: "Yearly",
    },
    {
      value: "One Time-pos",
      text: "Yearly",
    },
    {
      value: "weekday",
      text: "Every weekday (Monday to Friday)",
    },
    {
      value: "custom",
      text: "Custom",
    },
  ]);
  const [selectedRepeat, setSelectedRepeat] = React.useState("norepeat");
  const [repeatType, setRepeatType] = React.useState("daily");
  const [repeatNr, setRepeatNr] = React.useState("1");
  const [condition, setCondition] = React.useState("never");
  const [untilDate, setUntilDate] = React.useState();
  const [occurrences, setOccurrences] = React.useState("1");
  const [selectedMonth, setMonth] = React.useState(1);
  const [monthlyDays, setMonthlyDays] = React.useState(["1"]);
  const [monthlyDay, setMonthlyDay] = React.useState("1");
  const [yearlyDays, setYearlyDays] = React.useState(["1"]);
  const [yearlyDay, setYearlyDay] = React.useState("1");
  const [weekDays, setWeekDays] = React.useState([...days]);

  const [editFromPopup, setEditFromPopup] = React.useState(false);

  useEffect(() => {
    if (props.data) {
      setFrequency({
        event: props.data,
      });
      loadPopupForm(props.data);
      setTempEvent({ ...props.data });
    } else {
      resetCustomValues();
      setrecurringString("");
      setFrequency({
        event: {
          allDay: true,
          end: null,
          start: new Date().setDate(new Date().getDate()),
          _days: [new Date()],
        },
      });
      loadPopupForm({
        allDay: true,
        end: null,
        start: new Date().setDate(new Date().getDate() + 1),
        _days: [new Date()],
      });
      setTempEvent(null);
    }
  }, [props.data]);

  useEffect(()=>{
    if(props.description && props.description !== ""){
      setDefaultDescription(props.description);
      setUseDefaultDesc(true);
    }
  },[props.description]);

  useEffect(() => {
    if (props.data && tempEvent) getCustomRule();
  }, [tempEvent]);

  useEffect(() => {
    console.log(props.is_recurring)
  }, [props.is_recurring])

  // set custom values to default
  const resetCustomValues = React.useCallback(() => {
    setRepeatType("daily");
    setRepeatNr("1");
    setCondition("never");
    setOccurrences("1");
    setMonth(1);
    setMonthlyDay(1);
    setYearlyDay(1);
    const newWeekDays = [...days];
    for (let i = 0; i < newWeekDays.length; i++) {
      const day = newWeekDays[i];
      day.checked = day.short === "SU";
    }
    setWeekDays(newWeekDays);
    setSelectedRepeat("norepeat");
    setRepeatData(repeatData.filter((item) => item.value !== "custom-value"));
  }, [repeatData]);

  const getCustomRule = React.useCallback(() => {
    let recurringRule;
    let recurring = "";
    let from = editFromPopup ? popupEventDate[0] : tempEvent.start;

    if (from?.toString().includes(" ")) {
      from = moment(from)?.format("YYYY-MM-DD");
    }
    var date_numbers = from?.toString().match(/\d+/g);
    const d =
      date_numbers?.length > 1
        ? new Date(date_numbers[0], date_numbers[1] - 1, date_numbers[2])
        : new Date(from);

    const weekday = d?.getDay();
    const monthday = d?.getDate();
    const weekNr = getWeekDayNum(d);
    if (selectedRepeat === "norepeat" || (props.is_taskEdit && selectedRepeat === "")) {
      recurring += "One time on " + moment(d)?.format("MMMM Do, YYYY") + ".";
      recurringRule = {
        repeat: "One Time",
      };
    }

    if (editFromPopup && tempEvent.start && tempEvent.recurring) {
      switch (selectedRepeat) {
        case "daily":
          recurringRule = { repeat: "daily" };
          recurring += "Daily";
          break;
        case "weekly":
          const newWeekDays = [...days];
          const weekD = tempEvent.recurring?.weekDays?.split(",");

          for (let i = 0; i < newWeekDays.length; i++) {
            const day = newWeekDays[i];
            day.checked = weekD?.includes(day.short);
          }

          recurringRule = {
            repeat: "weekly",
            weekDays:
              weekDays[
                tempEvent.recurring?.weekDays !== undefined
                  ? weekday
                  : d?.getDay()
              ].short,
          };
          recurring += "Weekly";

          recurring +=
            " on " +
            newWeekDays
              .filter((i) => i.checked)
              .map((i) => i.name)
              .join(", ");
          break;
        case "monthly":
          recurringRule = {
            repeat: "monthly",
            day: monthday,
          };
          if (monthday) recurring += "Monthly on day " + monthday + ".";
          else recurring += "Monthly   ";
          break;
        case "monthly-pos":
          recurringRule = {
            repeat: "monthly",
            weekDays: days[weekday].short,
            pos: weekNr,
          };
          recurring +=
            "Monthly on the " + ordinalList[weekNr] + " " + days[weekday]?.name;
          break;
        case "yearly":
        case "One Time":
          recurringRule = {
            repeat: "One Time",
            day: monthday,
            month: d?.getMonth() + 1,
          };
          recurring +=
            "One time on " + months[d?.getMonth()]?.text + " " + monthday;
          break;
        case "yearly-pos":
        case "One Time-pos":
          tempEvent.recurring = {
            repeat: "One Time",
            month: d?.getMonth() + 1,
            weekDays: days[weekday].short,
            pos: weekNr,
          };
          recurringRule = {
            repeat: "One Time",
            month: d.getMonth() + 1,
            weekDays: days[weekday].short,
            pos: weekNr,
          };
          recurring +=
            "One time on the " +
            ordinalList[weekNr] +
            " " +
            days[weekday]?.name +
            " of " +
            months[d?.getMonth()]?.text;
          break;
        case "weekday":
          recurringRule = { repeat: "weekly", weekDays: "MO,TU,WE,TH,FR" };
          recurring += "Weekly: Monday, Tuesday, Wednesday, Thursday, Friday";
          break;
        case "custom":
        case "custom-value":
          recurringRule = {
            repeat: repeatType,
            interval: repeatNr,
          };
          switch (repeatType) {
            case "daily":
              if (repeatNr > 1) {
                recurring += "Every " + repeatNr + " day(s)";
              } else {
                recurring += "Daily ";
              }
              break;
            case "weekly":
              recurringRule.weekDays = weekDays
                .filter((i) => i.checked)
                .map((i) => i.short)
                .join(",");
              if (repeatNr > 1) {
                recurring +=
                  "Every " +
                  repeatNr +
                  " week(s) on " +
                  weekDays
                    .filter((i) => i.checked)
                    .map((i) => i.name)
                    .join(", ");
              } else {
                recurring +=
                  "Weekly on " +
                  weekDays
                    .filter((i) => i.checked)
                    .map((i) => i.name)
                    .join(", ");
              }
              break;
            case "monthly":
              recurringRule.day = monthlyDay;
              if (repeatNr > 1) {
                recurring += "Every " + repeatNr + " month(s)";
                if (monthlyDay) recurring += " on day " + monthlyDay;
              } else {
                if (monthlyDay) recurring += "Monthly on day " + monthlyDay;
                else recurring += "Monthly";
              }
              break;
            case "yearly":
            case "One Time":
              recurringRule.day = yearlyDay;
              recurringRule.month = selectedMonth;
              if (repeatNr > 1) {
                recurring +=
                  "Every " +
                  repeatNr +
                  " year(s) on " +
                  months[selectedMonth - 1].text +
                  " " +
                  (yearlyDay ? yearlyDay : "");
              } else {
                recurring +=
                  "One time on " +
                  months[selectedMonth - 1].text +
                  " " +
                  (yearlyDay ? yearlyDay : "");
              }
              break;

            default:
          }

          switch (condition) {
            case "until":
              recurringRule.until = moment(untilDate).format("YYYY-MM-DD");
              recurring += "; " + moment(untilDate).format("MMMM Do, YYYY");
              break;
            case "count":
              recurringRule.count = occurrences;
              recurring += "; it reaches " + occurrences + "  ocurrences";
              break;
            case "never":
              recurring += "; Never stop";
              break;
            default:
          }
          break;
        default:
      }
    } else {
      switch (selectedRepeat) {
        case "daily":
          recurringRule = { repeat: "daily" };
          recurring += "Daily";
          break;
        case "weekly":
          const newWeekDays = [...days];
          const weekD = tempEvent.recurring?.weekDays?.split(",");

          for (let i = 0; i < newWeekDays.length; i++) {
            const day = newWeekDays[i];
            day.checked = weekD?.includes(day.short);
          }

          recurringRule = {
            repeat: "weekly",
            weekDays:
              weekDays[
                tempEvent.recurring?.weekDays !== undefined
                  ? weekday
                  : d?.getDay()
              ].short,
          };
          recurring +=
            "Weekly on " +
            newWeekDays
              .filter((i) => i.checked)
              .map((i) => i.name)
              .join(", ");
          break;
        case "monthly":
          recurringRule = {
            repeat: "monthly",
            day:
              tempEvent.recurring?.weekDays !== undefined
                ? weekday
                : d?.getDate(),
          };
          if (d?.getDate()) recurring += "Monthly on day " + d?.getDate();
          else recurring += "Monthly.";
          break;
        case "monthly-pos":
          recurringRule = {
            repeat: "monthly",
            weekDays: days[weekday].short,
            pos: weekNr,
          };
          recurring +=
            "Monthly on the " + ordinalList[weekNr] + " " + days[weekday]?.name;
          break;
        case "yearly":
        case "One Time":
          recurringRule = { repeat: "One Time", month: d.getMonth() + 1 };
          recurring +=
            "One time on " + months[d?.getMonth()]?.text + " " + monthday;
          break;
        case "yearly-pos":
        case "One Time-pos":          
          recurringRule = {
            repeat: "One Time",
            month: d?.getMonth() + 1,
            weekDays: days[weekday].short,
            pos: weekNr,
          };
          recurring +=
            "One time on the " +
            ordinalList[weekNr] +
            " " +
            days[weekday]?.name +
            " of " +
            months[d?.getMonth()]?.text;
          break;
        case "weekday":
          recurringRule = { repeat: "weekly", weekDays: "MO,TU,WE,TH,FR" };
          recurring += "Weekly: Monday, Tuesday, Wednesday, Thursday, Friday";
          break;
        case "custom":
        case "custom-value":
          recurringRule = {
            repeat: repeatType,
            interval: repeatNr,
          };
          switch (repeatType) {
            case "daily":
              if (repeatNr > 1) {
                recurring += "Every " + repeatNr + " day(s)";
              } else {
                recurring += "Daily ";
              }
              break;
            case "weekly":
              recurringRule.weekDays = weekDays
                .filter((i) => i.checked)
                .map((i) => i.short)
                .join(",");
              if (repeatNr > 1) {
                recurring +=
                  "Every " +
                  repeatNr +
                  " week(s) on " +
                  weekDays
                    .filter((i) => i.checked)
                    .map((i) => i.name)
                    .join(", ");
              } else {
                recurring +=
                  "Weekly on " +
                  weekDays
                    .filter((i) => i.checked)
                    .map((i) => i.name)
                    .join(", ");
              }
              break;
            case "monthly":
              recurringRule.day = monthlyDay;
              if (repeatNr > 1) {
                recurring += "Every " + repeatNr + " month(s)";
                if (monthlyDay) recurring += " on day " + monthlyDay;
              } else {
                if (monthlyDay) recurring += "Monthly on day " + monthlyDay;
                else recurring += "Monthly";
              }
              break;
            case "yearly":
            case "One Time":
              recurringRule.day = yearlyDay;
              recurringRule.month = selectedMonth;
              if (repeatNr > 1) {
                recurring +=
                  "Every " +
                  repeatNr +
                  " year(s) on " +
                  months[selectedMonth - 1].text +
                  " " +
                  (yearlyDay ? yearlyDay : "");
              } else {
                recurring +=
                  "One time on " +
                  months[selectedMonth - 1].text +
                  " " +
                  (yearlyDay ? yearlyDay : "");
              }
              break;

            default:
          }

          let tmp_condition = "";
          if (tempEvent?.end) {
            setCondition("until");
            tmp_condition = "until";
            setUntilDate(tempEvent?.end);
          } else if (tempEvent?.count) {
            setCondition("count");
            tmp_condition = "count";
            setOccurrences(tempEvent?.count);
          } else {
            setCondition("never");
            tmp_condition = "never";
          }

          switch (tmp_condition) {
            case "until":
              recurringRule.until = moment(untilDate).format("YYYY-MM-DD");
              recurring += "; " + moment(untilDate).format("MMMM Do, YYYY");
              break;
            case "count":
              recurringRule.count = occurrences;
              recurring += "; it reaches " + occurrences + "  ocurrences";
              break;
            case "never":
              recurring += "; Never stop";
              break;
            default:
          }
          break;
        default:
      }
    }

    if (popupEventDate[1] === undefined || popupEventDate[1] === null) {
      recurring += "; Never stop";
    } else if (!selectedRepeat.includes("norepeat")) {
      recurring += "; " + moment(popupEventDate[1]).format("MMMM Do, YYYY");
    }
    setrecurringString(recurring);
    return recurringRule;
  }, [
    condition,
    monthlyDay,
    occurrences,
    popupEventDate,
    repeatNr,
    repeatType,
    selectedMonth,
    untilDate,
    weekDays,
    yearlyDay,
    selectedRepeat,
    editFromPopup,
    tempEvent,
  ]);

  const saveFrequency = React.useCallback(() => {
    setUseDefaultDesc(false);
    let eventEndDate =
      (popupEventDate[1] === null || popupEventDate[1] === undefined)
        ? popupEventDate[1]
        : moment(popupEventDate[1]).format("YYYY-MM-DD");
    const newFrequency = {
      start: moment(popupEventDate[0]).format("YYYY-MM-DD"),
      end: eventEndDate,
      recurring: getCustomRule(),
    };
    setFrequency({ event: newFrequency });
    const base_prices = props.recurring_base_prices;

    const price_selected =
      base_prices !== undefined
        ? base_prices.find(
            (price) =>
              price.name.toLowerCase() === frequency_for_price.toLowerCase()
          )
        : 0;

    const data = {
      target: {
        name: "frequency",
        value: newFrequency,
        price: price_selected !== undefined ? price_selected?.price : 0,
        key: "custom _frequency",
      },
    };
    props.onTextChange(data);
    // close the popup
    setOpen(false);
  }, [popupEventDate, getCustomRule, props, frequency_for_price]);

  const updateOptionDates = React.useCallback(
    (d) => {
      const weekday = d?.getDay();
      const monthday = d?.getDate();
      const newData = repeatData.slice(0);
      const weekNr = getWeekDayNum(d);

      for (let i = 0; i < newData.length; ++i) {
        const item = newData[i];
        switch (item.value) {
          case "weekly":
            item.text = "Weekly on " + days[weekday]?.name;
            break;
          case "monthly":
            item.text = "Monthly on day " + monthday;
            break;
          case "monthly-pos":
            item.text =
              "Monthly on the " +
              ordinalList[weekNr] +
              " " +
              days[weekday]?.name;
            break;
          case "One Time":
            item.text =
              "One time on " + months[d?.getMonth()]?.text + " " + monthday;
            break;
          case "One Time-pos":
            item.text =
              "One time on the " +
              ordinalList[weekNr] +
              " " +
              days[weekday]?.name +
              " of " +
              months[d?.getMonth()]?.text;
            break;
          default:
        }
      }
      setRepeatData(newData);
    },
    [repeatData]
  );

  const loadPopupForm = React.useCallback(
    (event) => {
      if (event && event.start) {
        const startDate = event.start;
        setDate([startDate, event.end]);
        setMinDate(startDate);
        setUntilDate(event.end);

        var date_numbers = event.start?.toString()?.match(/\d+/g);
        const d =
          date_numbers?.length > 1
            ? new Date(date_numbers[0], date_numbers[1] - 1, date_numbers[2])
            : new Date(startDate);

        const weekday = d?.getDay();
        const monthday = d?.getDate();
        const newData = repeatData.slice(0);
        const weekNr = getWeekDayNum(d) > 4 ? 4 : getWeekDayNum(d);
        // update select texts by selected date
        for (let i = 0; i < newData.length; ++i) {
          const item = newData[i];
          switch (item.value) {
            case "weekly":
              const newWeekDays = [...days];
              const weekD = event.recurring?.weekDays?.split(",");

              
              for (let i = 0; i < newWeekDays.length; i++) {
                const day = newWeekDays[i];
                day.checked = weekD?.includes(day.short);
              }

              if(weekD !== undefined)
                item.text =
                  "Weekly on " +
                  newWeekDays
                    .filter((i) => i.checked)
                    .map((i) => i.name)
                    .join(", ");
              else
              item.text =
              "Weekly on " + days[weekday]?.name
              break;
            case "monthly":
              item.text = "Monthly on day " + monthday;
              break;
            case "monthly-pos":
              item.text =
                "Monthly on the " +
                ordinalList[weekNr] +
                " " +
                days[weekday]?.name;
              break;
            case "One Time":
            case "yearly":
              item.text =
                "One time on " + months[d?.getMonth()]?.text + " " + monthday;
              break;
            case "yearly-pos":
            case "One Time-pos":
              item.text =
                "One time on the " +
                ordinalList[weekNr] +
                " " +
                days[weekday]?.name +
                " of " +
                months[d?.getMonth()]?.text;
              break;
            case "norepeat":
              item.text = "Does not repeat";
              break;
            default:
          }
        }

        setRepeatData(newData);

        const rec = event.recurring;

        if (rec) {
          setRepeatType(rec.repeat);
          if (rec.interval) {
            // set custom text
            let customText = "";
            const nr = rec.interval;

            setRepeatNr(nr);
            
            if(rec.repeat === "One Time"){
              if(rec.weekDays !== undefined){
                setSelectedRepeat("One Time-pos");
              }else{
                setSelectedRepeat(rec.repeat);
              }
            }else{
              setSelectedRepeat(rec.repeat);
            }

            switch (rec.repeat) {
              case "daily":
                customText = nr > 1 ? "Every " + nr + " days" : "Daily";
                break;
              case "weekly":
                const newWeekDays = [...days];
                const weekD = rec.weekDays.split(",");

                for (let i = 0; i < newWeekDays.length; i++) {
                  const day = newWeekDays[i];
                  day.checked = weekD?.includes(day.short);
                }

                setWeekDays(newWeekDays);
                customText = nr > 1 ? "Every " + nr + " weeks" : "Weekly";
                customText +=
                  " on " +
                  newWeekDays
                    .filter((i) => i.checked)
                    .map((i) => i.name)
                    .join(", ");
                break;
              case "monthly":
                if (rec.day) {
                  setMonthlyDay(rec.day);
                  setSelectedRepeat("monthly");
                  customText = nr > 1 ? "Every " + nr + " months" : "Monthly";
                  customText += " on day " + rec.day;
                } else if (rec.weekDays) {
                  setWeekDays(weekday);
                  setSelectedRepeat("monthly-pos");
                  setMonthlyDay(null);
                  customText =
                    "Monthly on the " +
                    ordinalList[rec.pos] +
                    " " +
                    days[weekday]?.name;
                }

                break;
              case "yearly":
              case "One Time":
                customText = nr > 1 ? "Every " + nr + " years" : "One Time";
                if (rec.day) {
                  setYearlyDay(rec.day);
                  if (rec.month) setMonth(d?.getMonth());
                  customText +=
                    " on " + months[d?.getMonth()]?.text + " " + rec.day;
                } else {
                  customText +=
                    "One time on the " +
                    ordinalList[rec.pos] +
                    " " +
                    days[weekday]?.name +
                    " of " +
                    months[d?.getMonth()]?.text;
                }
                break;
              default:
            }

            if (rec.until) {
              setCondition("until");
              setUntilDate(rec.until);
              customText +=
                " until " + formatDate("MMMM D, YYYY", new Date(rec.until));
            } else if (rec.count) {
              setCondition("count");
              setOccurrences(rec.count);
              customText += ", " + rec.count + " times";
            } else {
              setCondition("never");
            }

            // add custom value
            if (
              !isEmpty(customText) &&
              repeatData?.find((i) => i.text === customText) === undefined
            )
              setRepeatData([
                ...repeatData,
                { value: "custom-value", text: customText },
              ]);

            // set custom value
            if (rec.interval > 1) setSelectedRepeat("custom-value");
          } else if (rec.weekDays === "MO,TU,WE,TH,FR") {
            setSelectedRepeat("weekday");
          } else if (rec.repeat === "One Time") {
            if (rec.month || rec.day) {
              setSelectedRepeat("One Time");
            } else {
              setSelectedRepeat("norepeat");
            }
          } else {
            setSelectedRepeat(rec.repeat + (rec.pos ? "-pos" : ""));
          }
        } else {
          resetCustomValues();
        }
      }
    },
    [repeatData, resetCustomValues, popupEventDate]
  );

  // handle popup form changes

  const dateChange = React.useCallback(
    (args) => {
      const d = args.value;
      if (d != null) {
        setDate(d);
        updateOptionDates(d[0]);
        setUntilDate(d[1]); //Custom
        setEditFromPopup(true);
        setDateRangeOpen(false);
      }
    },
    [updateOptionDates]
  );

  // popuplate data for months
  const populateMonthDays = React.useCallback(
    (month, type) => {
      const day30 = [2, 4, 6, 9, 11];
      let newValues = [];

      for (let i = 1; i <= 31; i++) {
        if (
          !(i === 31 && day30.includes(month)) &&
          !(i === 30 && month === 2)
        ) {
          newValues.push(i.toString());
        }
      }

      if (type === "monthly") {
        setMonthlyDays(newValues);
        setMonthlyDay(1);
      } else {
        setYearlyDays(newValues);
        setYearlyDay(1);
      }
    },
    [setMonthlyDays, setYearlyDays]
  );

  const repeatChange = React.useCallback((ev) => {
    if (ev.value != null || ev.value != "undefined") {
      setSelectedRepeat(ev.value);
      setrecurringString(ev.value);
      setEditFromPopup(true);
    }
    switch (ev.value) {
      case "One Time":
      case "One Time-pos":
      case "yearly":
      case "yearly-pos":
      case "norepeat":
        setfrequency_for_price("one time");
        break;
      case "monthly-pos":
        setfrequency_for_price("monthly");
        break;
      case "weekday":
        setfrequency_for_price("weekly");
        break;
      case "custom":
      case "custom-value":
        setfrequency_for_price("daily");
        break;
      default:
        setfrequency_for_price(ev.value);
        break;
    }
  }, []);

  const repeatTypeChange = React.useCallback((ev) => {
    setRepeatType(ev.target.value);

    if (ev.target.value === "yearly" || ev.target.value === "One Time")
      setfrequency_for_price("one time");
    else setfrequency_for_price(ev.target.value);
  }, []);

  const repeatNrChange = React.useCallback((ev) => {
    setRepeatNr(ev.target.value);
  }, []);

  const conditionChange = React.useCallback((ev) => {
    setCondition(ev.target.value);
    if (ev.target.value.includes("never")) setUntilDate(null);
    setDate([popupEventDate[0] ? popupEventDate[0] : new Date(), ev.value]);
  }, []);

  const untilDateChange = React.useCallback((ev) => {
    if (ev.value !== "") {
      setUntilDate(ev.value);
      setDate([popupEventDate[0] ? popupEventDate[0] : new Date(), ev.value]);
    }
  }, []);

  const occurrancesChange = React.useCallback((ev) => {
    setOccurrences(ev.target.value);
  }, []);

  const monthsChange = React.useCallback(
    (ev) => {
      setMonth(ev.value);
      populateMonthDays(ev.value, "yearly");
    },
    [populateMonthDays]
  );

  const monthlyDayChange = React.useCallback((ev) => {
    setMonthlyDay(ev.value);
  }, []);

  const yearlyDayChange = React.useCallback((ev) => {
    setYearlyDay(ev.value);
  }, []);

  const weekDayChange = React.useCallback(
    (ev) => {
      weekDays.find((i) => i.short === ev.target.value).checked =
        ev.target.checked;
      setWeekDays([...weekDays]);
    },
    [weekDays]
  );

  // scheduler options

  const onAddFrequencyClick = React.useCallback(
    (args) => {
      const event = args.event;
      if(JSON.stringify(event) === '{}'){
        event.start = new Date().setDate(new Date().getDate());
       }
      setTempEvent({ ...args.event });
      // recurring event
      loadPopupForm(event);
      setOpen(true);
    },
    [loadPopupForm]
  );

  // datepicker options
  const controls = "calendar";
  const respSetting = {
    xsmall: {
      controls: ["date"],
    },
    medium: {
      controls: ["calendar"],
      touchUi: false,
    },
  };

  // popup options
  const headerText = "Select a Recurrence";

  const onPopupClose = React.useCallback(() => {
    setRepeatData(repeatData.filter((item) => item.value !== "custom-value"));
    setEditFromPopup(false);
    setOpen(false);
  }, [repeatData]);

  React.useEffect(() => {
    populateMonthDays(1, "monthly");
    setMonthlyDay(1);
    populateMonthDays(1, "yearly");
    setYearlyDay(1);
  }, [populateMonthDays]);

  return (
    <div className="fitem mb-20">
      <div className={(props.jobCreateTask === "jobCreateTask") ? "jobPopFrequencyForTask whitebox form-group" : "whitebox form-group"}>
        <div className="fitem half-fitem">
          {useDefaultDesc && defaultDescription !== null && defaultDescription !== "" ? 
            <div className="fitem half-item" style={{ width: "100%" }}>
              {defaultDescription?.includes("Invalid date") ? (
                "Select frequency"
              ) : defaultDescription?.includes("One time") ? (
                <p>
                  Occurs <strong>{defaultDescription?.split(";")[0]}</strong>.
                </p>
              ) : (
                <p>
                  Occurs <strong>{defaultDescription?.split(";")[0]}</strong>{" "}
                  starting
                  <strong>
                    {" "}
                    {defaultDescription?.split(";")[1]}
                  </strong>
                  {defaultDescription?.split(";")[1]?.includes("Never")
                    ? ""
                    : "; Run until"}
                  <strong> {defaultDescription?.split(";")[2]}</strong>
                </p>
              )}
            </div> 
            :
            <div className="fitem half-item" style={{ width: "100%" }}>
              {recurringString === "" || recurringString?.includes("Invalid date") ? (
                "Select frequency"
              ) : recurringString?.includes("One time") ? (
                <p>
                  Occurs <strong>{recurringString?.split(";")[0]}</strong>.
                </p>
              ) : (
                <p>
                  Occurs <strong>{recurringString?.split(";")[0]}</strong>{" "}
                  starting
                  <strong>
                    {" "}
                    {moment(popupEventDate[0]).format("MMMM Do, YYYY")}
                  </strong>
                  ;
                  {recurringString?.split(";")[1]?.includes("Never")
                    ? ""
                    : " Run until"}
                  <strong> {recurringString?.split(";")[1]}.</strong>
                </p>
              )}
            </div>
          }
          <div className="fitem half-item">
            <button
              className="select_frequecy_btn"
              onClick={() => onAddFrequencyClick(frequency)}
            >
              Select
            </button>
          </div>
        </div>
      </div>
      <Modal
        title={headerText}
        visible={isOpen}
        onCancel={async() => {
          await setDateRangeOpen(false);
          onPopupClose();
        }}
        footer={[
          <button
            key="cancel"
            className="btn btn-white-background"
            onClick={async() => {
              await setDateRangeOpen(false);
              onPopupClose();
            }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            className={`btn btn-warning`}
            onClick={async () => {
              await setDateRangeOpen(false);
              saveFrequency();
            }}
          >
            Save
          </button>,
        ]}
        className={(props.jobCreateTask === "jobCreateTask") ? "task_custom_frequency" : "custom_frequency"}
        width={500}
      >
        <div className="mbsc-form-group">
          <Input ref={startRef} label="Starts" onClick={() => setDateRangeOpen(!dateRangeOpen)} />

          <Input ref={endRef} label="Ends" onClick={() => setDateRangeOpen(!dateRangeOpen)} disabled={!props.is_recurring} />
          <Datepicker
            select="range"
            controls={controls}
            touchUi={true}
            startInput={start}
            endInput={end}
            showRangeLabels={false}
            responsive={respSetting}
            onChange={dateChange}
            min={minDate}
            value={popupEventDate}
            isOpen={dateRangeOpen}
            onClose={() => {
              setDateRangeOpen(false);
            }}
          />
          <Select
            data={repeatData}
            label="Repeats"
            value={(props.is_taskEdit && selectedRepeat === "") ? 'norepeat' : selectedRepeat}
            responsive={selectResponsive}
            onChange={repeatChange}
            disabled={!props.is_recurring}
          />
          {(selectedRepeat === "custom" ||
            selectedRepeat === "custom-value") && (
            <div>
              <div>
                <SegmentedGroup onChange={repeatTypeChange}>
                  <SegmentedItem value="daily" checked={repeatType === "daily"}>
                    Daily
                  </SegmentedItem>
                  <SegmentedItem
                    value="weekly"
                    checked={repeatType === "weekly"}
                  >
                    Weekly
                  </SegmentedItem>
                  <SegmentedItem
                    value="monthly"
                    checked={repeatType === "monthly"}
                  >
                    Monthly
                  </SegmentedItem>
                  <SegmentedItem
                    value="One Time"
                    checked={
                      repeatType === "yearly" || repeatType === "One Time"
                    }
                  >
                    One time
                  </SegmentedItem>
                </SegmentedGroup>

                <div className="custom-repeat-settings">
                  Repeat every
                  <Input
                    className="custom-repeat-input"
                    min="1"
                    value={repeatNr}
                    onChange={repeatNrChange}
                    inputStyle="outline"
                  />
                  {repeatType === "daily" && <span>day(s)</span>}
                  {repeatType === "weekly" && <span>week(s)</span>}
                  {repeatType === "monthly" && (
                    <span>
                      months on day
                      <Select
                        className="custom-repeat-input custom-repeat-select-month"
                        data={monthlyDays}
                        value={monthlyDay}
                        onChange={monthlyDayChange}
                        inputProps={dayInputProps}
                      />
                    </span>
                  )}
                  {(repeatType === "yearly" || repeatType === "One Time") && (
                    <span>
                      years on
                      <Select
                        className="custom-repeat-input custom-repeat-select-nr"
                        data={months}
                        value={selectedMonth}
                        onChange={monthsChange}
                        inputProps={monthInputProps}
                      />
                      <Select
                        className="custom-repeat-input custom-repeat-select-month"
                        data={yearlyDays}
                        value={yearlyDay}
                        onChange={yearlyDayChange}
                        inputProps={dayInputProps}
                      />
                    </span>
                  )}
                  {repeatType === "daily" && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every day or every x days,
                      depending on the value
                    </p>
                  )}
                  {repeatType === "weekly" && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every x weeks on specific
                      weekdays
                    </p>
                  )}
                  {repeatType === "monthly" && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every x month on specific day
                      of the month
                    </p>
                  )}
                  {(repeatType === "yearly" || repeatType === "One Time") && (
                    <p className="custom-repeat-desc">
                      The event will be repeated every x years on specific day
                      of a specific month
                    </p>
                  )}
                  {repeatType === "weekly" && (
                    <div className="custom-repeat-checkbox-cont">
                      {days.map((day) => {
                        return (
                          <Checkbox
                            value={day.short}
                            key={day.short}
                            checked={day.checked}
                            onChange={(e) => weekDayChange(e)}
                          >
                            {day.name.substring(0, 3)}
                          </Checkbox>
                        );
                      })}
                    </div>
                  )}
                  <div>Stop condition</div>
                  <div className="custom-condition-cont">
                    <RadioGroup>
                      <Radio
                        label="Never stop"
                        description="The event will repeat indefinitely"
                        checked={condition === "never"}
                        onChange={conditionChange}
                        value="never"
                      />
                      <Radio
                        checked={condition === "until"}
                        onChange={conditionChange}
                        value="until"
                      >
                        Run until a specific date
                        <Datepicker
                          inputProps={dateInputProps}
                          controls={["calendar"]}
                          display="anchored"
                          touchUi={false}
                          dateFormat="YYYY-MM-DD"
                          returnFormat="iso8601"
                          value={untilDate}
                          onChange={untilDateChange}
                          onOpen={() => setCondition("until")}
                          min={new Date()}
                        />
                        <span className="mbsc-description">
                          The event will run until it reaches a specific date
                        </span>
                      </Radio>
                      <Radio
                        checked={condition === "count"}
                        onChange={conditionChange}
                        value="count"
                      >
                        Run until it reaches
                        <Input
                          className="custom-repeat-input"
                          inputStyle="outline"
                          value={occurrences}
                          onChange={occurrancesChange}
                          onClick={() => setCondition("count")}
                        />
                        occurrences
                        <span className="mbsc-description">
                          The event will repeat until it reaches a certain
                          amount of occurrences
                        </span>
                      </Radio>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomFrequency;
