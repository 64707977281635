import React from 'react'

const Breadcrumb = (props) => {
  return (
    <div className="mt-10 mb-10 ml-30">
        <ul className="breadcrumb">
            <li className="fs-14">
            <span className="pointer">
                <i className="las la-angle-left"></i>
                 {props.value}
            </span>
            </li>
        </ul>
    </div>  
    )
}

export default Breadcrumb