import React from "react";
import { Modal } from "antd";
import PoolCharacteristicsResidential from "../../../Customer/Residential/CustomerDetails/PoolCharacteristics";
import PoolCharacteristicsCommercial from "../../../Customer/Commercial/CustomerDetails/PoolCharacteristics";

export const PoolCharactersticsModalCalendarContainer = (props) => {
  const { pcModalOpen, setPCModalOpen, selectedEvent, franchise, handlePoolCancel } = props;

  return (
    <Modal
      open={pcModalOpen}
      onOk={() => setPCModalOpen(false)}
      onCancel={() => setPCModalOpen(false)}
      destroyOnClose
      closable={false}
      className="schedule_pc_modal poolCharactersticsModal"
      style={{
        top: 20,
        width: "100%",
      }}
      footer={null}
      maskClosable={false}
    >
      {selectedEvent?.original?.client_type === "residential" ? (
        <PoolCharacteristicsResidential
          customer={{
            uuid: selectedEvent?.original?.client_uuid,
            field_office: { uuid: franchise.value },
            is_active: true,
            address: selectedEvent?.original?.address,
            pool_characteristic: selectedEvent?.original?.pool_characteristic
          }}
          address={selectedEvent?.original?.address}
          onResponse={handlePoolCancel}
        />
      ) : (
        <PoolCharacteristicsCommercial
          customer={{
            uuid: selectedEvent?.original?.client_uuid,
            field_office: { uuid: franchise.value },
            is_active: true,
            address: selectedEvent?.original?.address,
            pool_characteristic: selectedEvent?.original?.pool_characteristic
          }}
          address={selectedEvent?.original?.address}
          onResponse={handlePoolCancel}
        />
      )}
    </Modal>
  )
}