import * as yup from 'yup';
import { Table, Input } from 'antd';
import moment from "moment-timezone";
import { map, isEmpty, cloneDeep } from "lodash";
import React, { useState, useRef, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import { useSelector } from "react-redux/es/hooks/useSelector";
import "../../../Assets/css/proposalPreview.css";
import previewHeader from "../../../Assets/icons/ProposalHeaderPrint.svg";
import{ proposal_base_price } from "../../../Store/ProposalDataSlice";
import { useDispatch } from "react-redux/es/hooks/useDispatch";


const ProposalPreview = (props) => {
    const { customer } = props;
    const dispatch = useDispatch();
    const proposals = useSelector((state) => state.proposalDataSlice);
    const [primaryEmail, setprimaryEmail] = useState("");
    const [total, setTotal] = useState("");
    const [price, setPrice] = useState("");
    const [tax, setTax] = useState("");
    const [addition_work_lines, setAddition_work_lines] = useState([]);
    
    useEffect(() => {
        renderPrimaryEmail();
        calculateFrequencyPrice();
    }, []);

    useEffect(()=> {
        let tempAdditional = cloneDeep(proposals.additional_work);
        let output = tempAdditional.filter(obj => !Object.keys(obj).includes("bundle_code"));
        setAddition_work_lines(output);
        let calTotal = calculateTotal(output);
        calTotal = calTotal + proposals.proposalBasePrice;
        setTotal(calTotal);
        let calPrice = calculateSubPrice(output);
        calPrice = calPrice + proposals.proposalBasePrice;
        setPrice(calPrice);
        let calTax = calculateTax(output);
        setTax(calTax);
    }, [proposals.proposalBasePrice])
    
    const calculateFrequencyPrice = () => {
        if(proposals.frequency == 'One Time'){
            dispatch(proposal_base_price(proposals?.response_data?.recurring_base_prices[2].price));
        }else if(proposals.frequency == 'weekly'){
            dispatch(proposal_base_price(proposals?.response_data?.recurring_base_prices[1].price));
        }else if(proposals.frequency == 'bi-weekly'){
            dispatch(proposal_base_price(proposals?.response_data?.recurring_base_prices[3].price));
        }else if(proposals.frequency == 'monthly'){
            dispatch(proposal_base_price(proposals?.response_data?.recurring_base_prices[0].price));
        }else if(proposals.frequency == 'yearly'){
            dispatch(proposal_base_price(100));
        }else if(proposals.frequency == 'Other'){
            dispatch(proposal_base_price(100));
        }
    }

    const renderPrimaryEmail = () => {
        !isEmpty(customer?.emailaddress_set) &&
            map(customer?.emailaddress_set, (item) => {
                setprimaryEmail(item?.primary && item?.email);
            });
    };
    //checkbox array
    const checkboxes = [
        { id: 'checkbox1', label: 'Chemicals added from asp’s inventory', value: 'checkbox1Value' },
        { id: 'checkbox2', label: 'Customer supplies chemicals/supplement from asp as needed*', value: 'checkbox2Value' },
    ];

    //canvas
    const ownerSignRef = useRef();// Create a first ref for the second canvas
    const franchiseOwnerRef = useRef(); // Create a second ref for the second canvas
    const [ownerSignature, setOwnerSignature] = useState();
    const [franchiseOwnerSignature, setFranchiseOwnerSignature] = useState();

    const handleOwnerSignature = () => {
        setOwnerSignature(ownerSignRef.current.toDataURL());
    };

    const handleFranchiseOwnerSignature = () => {
        setFranchiseOwnerSignature(franchiseOwnerRef.current.toDataURL());
    };

    const clearOwnerSignature = () => {
        ownerSignRef.current.clear();
        setOwnerSignature(null);
    };

    const clearFranchiseOwnerSignature = () => {
        franchiseOwnerRef.current.clear();
        setFranchiseOwnerSignature(null);
    };

    const signatures = [
        { id: 'owner', name: 'Owner Signature', ref: ownerSignRef, onEnd: handleOwnerSignature, onClick: clearOwnerSignature },
        { id: 'franchiseOwner', name: 'Franchise Owner Signature', ref: franchiseOwnerRef, onEnd: handleFranchiseOwnerSignature, onClick: clearFranchiseOwnerSignature },
    ];

    //form states
    const [formData, setFormData] = useState({
        ownerName: '',
        franchiseOwnerName: '',
        checkedList: [], // Checkbox state
    });

    //errors
    const [errors, setErrors] = useState({});

    //validations
    const validationSchema = yup.object().shape({
        ownerName: yup.string().required('*Owner name is required'),
        franchiseOwnerName: yup.string().required('*Franchise owner name is required'),
        checkedList: yup.array().min(1, '*Select at least one checkbox').of(yup.boolean()),// At least one checkbox must be selected
    });

    //Implement Yup validation
    const validateForm = async () => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            return true; // Validation passed
        } catch (error) {
            const newErrors = {};

            error.inner.forEach((e) => {
                newErrors[e.path] = e.message;
            });

            setErrors(newErrors);
            return false; // Validation failed
        }
    };

    //Display error messages
    const renderError = (field) => {
        return errors[field] ? <div className="error">{errors[field]}</div> : null;
    };

    //onchange checkboxes
    const handleCheckboxChange = (value) => {
        const updatedCheckboxes = formData.checkedList.includes(value)
            ? formData.checkedList.filter((item) => item !== value)
            : [...formData.checkedList, value];

        setFormData({ ...formData, checkedList: updatedCheckboxes });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = await validateForm();

        if (isValid) {
            // Handle successful form submission
            // e.g., send data to the server or perform some action
            console.log('Form data:', formData);
        }
    };

    //input fields
    const nameInputField = [
        {
            id: 1, title: 'Owner Name', placeholder: 'Owner Name', name: "ownerName", value: formData.ownerName, onChange: (e) => setFormData({ ...formData, ownerName: e.target.value })
        },

        {
            id: 2, title: 'Franchise Owner Name', placeholder: 'Franchise Owner Name', name: "franchiseOwnerName", value: formData.franchiseOwnerName, onChange: (e) => setFormData({ ...formData, franchiseOwnerName: e.target.value })
        }
    ]

    //Billing table
    const billingColumns = [
        {
            title: 'Proposal#',
            dataIndex: 'proposalNumber',
            key: 'proposalNumber',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Rate',
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const billingData = [
        {
            key: '1',
            proposalNumber: proposals?.proposal_number,
            date: `${proposals.proposal_due_date}`,
            total: `$${total}`,
        },
    ];

    //Amount-table
    const amountColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const amountData = [
        {
            key: '1',
            date: `${proposals.proposal_due_date}`,
            description: <span>Service: ${proposals.service_type_name} <br /> Rate: ${proposals.select_rate} <br /> Frequency: ${proposals.frequency} <br /> ${proposals.additional_notes !== '' ? `Notes:` + proposals.additional_notes : ''}</span>,
            rate: `$${proposals.proposalBasePrice}`,
            amount: `$${proposals.proposalBasePrice}`,
        }
    ];

    // Additional work table
    const additionalWorkColumns = [
        {
            title: 'Repair Code',
            dataIndex: 'repair_code',
            key: 'repair_code',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Unit Cost',
            dataIndex: 'unit_cost',
            key: 'unit_cost',
        },
        {
            title: 'QTY',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Amount',
            dataIndex: 'total',
            key: 'total',
        },
    ];
    const additionalWorkData = addition_work_lines;

    const calculateTotal = (data) => {
        return data.reduce((accumulator, object) => {
            return Number(accumulator) + Number(object.total);
        }, 0);
    }

    const calculateSubPrice = (data) => {
        return data.reduce((accumulator, object) => {
            return Number(accumulator) + Number(object.total - object.tax);
        }, 0);
    }

    const calculateTax = (data) => {
        return data.reduce((accumulator, object) => {
            return Number(accumulator) + Number(object.tax);
        }, 0);
    }


    return (
        <div className="proposal">
            <img className="proposal-title mb-0" src={previewHeader} />

            <section className="proposal-container">
                {/* proposal address description section*/}
                <section className="proposal-desc">
                    <div className="proposal-address">
                        <p>ASP of Jupiter 2507 North Ocean Avenue Rivera Beach, FL 33404</p>
                        <p className="mb-15">(866) 253-0455</p>
                        <p className="mb-15">info@aswimmingpool.com</p>
                        <p className="mb-15">www.ASPpoolco.com</p>
                    </div>

                    <button type="button"><img src="/media/asp-logo.png" alt="" /></button>
                </section>

                {/* proposal address description with billing table section*/}
                <section className="proposal-desc mt-50 align-center">
                    <div className="proposal-billing-address">
                        <p className="mb-15"><b>Billing/Service Address</b></p>
                        <p>{proposals.address.address}</p>
                        <p>{proposals.address.address2}</p>
                        <p>{proposals.address.city}, {proposals.address.state} {proposals.address.zip_code}</p>
                        <p></p>
                        <p>{proposals.primary_no}</p>
                        <p>{`${primaryEmail ? primaryEmail : customer?.email}`}</p>
                    </div>

                    <div className="billing-table">
                        <Table columns={billingColumns} dataSource={billingData} pagination={false} />
                    </div>
                </section>

                {/* proposal application section*/}
                <section className="proposal-app">
                    <h2 className="proposal-app-title">This Proposal Expires on {proposals.proposal_due_date}</h2>
                    <p className="proposal-app-notes border-bottom">Notes</p>
                    <div className="proposal-application">
                        <p className="proposal-letter">
                            <br />
                            {proposals?.email_notes}
                            <br />
                            <br />
                            Please review and sign below if everything is agreed upon. We look forward to
                            hearing from you!
                        </p>

                        <div className="amount-table">
                            <Table columns={amountColumns} dataSource={amountData} pagination={false} />
                        </div>

                        <div className="additional-work-table mt-20">
                            <p className="proposal-app-notes mb-0">Additional Work</p>

                            <Table columns={additionalWorkColumns} dataSource={additionalWorkData} pagination={false} />

                            <div className="proposal-count mt-50">
                                <div className="dp-flex mb-10">
                                    <b className="mr-100">Subtotal</b>
                                    <b>${price}</b>
                                </div>

                                <div className="dp-flex">
                                    <b className="mr-100">SALES TAX</b>
                                    <b>${tax}</b>
                                </div>

                                <div className="dp-flex mt-20">
                                    <b className="mr-100">Total</b>
                                    <b>${total}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* proposal service agreement section*/}
                <section className="proposal-service-agreement">
                    <p className="proposal-app-notes border-bottom">Service agreements</p>

                    <form className="proposal-form relative" onSubmit={handleSubmit}>
                        <p>
                            <b>Flat Rate Billing</b>
                            <p>
                                With flat rate billing, we charge a flat rate for our program,
                                including basic chemicals to adjust chlorine, ph, and alkalinity
                                to not include salt. specialty chemicals such as algaecide and phosphate
                                remover are not included in this fee & are sold only if needed.
                            </p>
                        </p>

                        <p>
                            <b>Standard Billing</b>
                            <p>
                                The cost of any chemicals added to the swimming pool. your total bill
                                will vary depending on the chemicals added to maintain the proper chemical
                                balances. this fee does not include chemicals.
                            </p>
                        </p>

                        <>
                            {checkboxes.map((checkbox) => (
                                <div key={checkbox.id}>
                                    <input
                                        type="checkbox"
                                        value={checkbox.value}
                                        name="checkedList"
                                        checked={formData.checkedList.includes(checkbox.value)}
                                        onChange={() => handleCheckboxChange(checkbox.value)}
                                    />
                                    <label className="ml-5 checkbox-label">{checkbox.label}</label>
                                </div>
                            ))}
                            {renderError('checkedList')}
                        </>


                        <p>
                            <b>*NOTE:</b>
                            If the customer supplies all chemicals and chemicals needed are not available,
                            asp will use our chemicals and customer will be billed accordingly.
                        </p>

                        <p>
                            <b>Service Agreement</b>
                            <p>{proposals.service_agreement}</p>
                        </p>

                        <p className="mb-0">
                            <b>Client Approval</b>
                        </p>

                        <div className="proposal-information dp-flex justity-btwn align-center mt-5">
                            {signatures.map((data) => (
                                <div className="proposal-franchise-owner-signature width-40-percent" key={data.id}>
                                    <div className="dp-flex justity-btwn align-center">
                                        <p className="name mb-0">{data.name}</p>
                                        <div className="dp-flex align-center">
                                            <span>Date: </span>
                                            <span className="date">{moment().format("MMMM DD, yyyy")}</span>
                                        </div>
                                    </div>
                                    <div className="proposal-input mt-5">
                                        <div className="dp-flex align-center relative">
                                            <SignatureCanvas
                                                penColor="Black"
                                                canvasProps={{ className: 'signature' }}
                                                ref={data.ref}
                                                onEnd={data.onEnd}
                                            />
                                            <div onClick={data.onClick} className="clear-signature"><i className="las la-sync"></i></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="proposal-information dp-flex justity-btwn align-center mt-20">
                            {nameInputField.map((data) => (
                                <div className="proposal-franchise-owner-name width-40-percent" key={data.id}>
                                    <div className="dp-flex justity-btwn align-center">
                                        <p className="name mb-0">{data.title}</p>
                                        <div className="dp-flex align-center">
                                            <span>Date: </span>
                                            <span className="date">{moment().format("MMMM DD, yyyy")}</span>
                                        </div>
                                    </div>
                                    <div className="proposal-input mt-5 relative">
                                        <Input
                                            placeholder={data.placeholder}
                                            type="text"
                                            name={data.name}
                                            value={data.value}
                                            onChange={data.onChange}
                                        />
                                        <div className="absolute">{renderError(data.name)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* <div className="proposal-action-btns">
                            <button className="btn-outline">Decline</button>
                            <button className="btn btn-warning">Need to Review</button>
                            <button className="btn btn-warning">Accept & Submit</button>
                        </div> */}
                    </form>
                </section>
            </section>
        </div>)
}

export default ProposalPreview;
