import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import { DatePicker, message, Modal, Select } from "antd";
import moment from "moment-timezone";
import { map, isEmpty } from "lodash";
import _ from 'lodash';
import { formatToCurrency } from "../../../Utils/formatToCurrency";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import * as yup from "yup";
import { getServiceOrderByClient, createServiceOrder, getAvalaraTaxValue } from "../../../Action/Customer";
import { createManualInvoice, getSingleInvoice } from "../../../Action/Invoice";
import { setNewInvoiceDataField, setInvoiceDataField, resetInvoiceData } from "../../../Store/invoiceDataSlice";
import AddItemModal from "./AddItemModal";
import { setBundleVal } from "../../invoiceHelper";


const { Option } = Select;
moment.locale('en-us', { week: { dow: 0 } }) // 0-6 (Sunday-Saturday)

const AddNewInvoice = (props) => {
  const dispatch = useDispatch();
  const {
    serviceOrderNumberList,
    isOpenClearAll,
    loading,
    errors,
    selectedItem,
    isOpenDelete,
    newInvoice,
    selectedWorkOrders,
  } = useSelector((state) => state.invoice);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmSO, setOpenConfirmSO] = useState(false);
  const [confirmArgument, setConfirmArgument] = useState(false);
  const [newRetailServiceName, setNewRetailServiceName] = useState(false);
  const [previousName, setPreviousName] = useState("")
  function getState() {
    if (props?.invoiceDetail?.uuid) {
      const rows = [];      
      map(props?.invoiceDetail?.line_items, (x) => {
        rows.push({
          uuid: x.uuid,
          service_uuid: x?.service_uuid,
          type: x.service_type !== "" ? x.service_type : "Retail Invoice",
          item_type: x.item_type,
          date: x.date,
          description: x.description,
          rate: x.rate,
          price: x.price,
          unit_cost: x.rate,
          quantity: x.quantity,
          tax: x?.tax ? parseFloat(x?.tax).toFixed(2) : 0,
          is_taxable: x?.is_taxable ? x.is_taxable : true,
          isEditRow: false,
          other_description: x.sub_description,
          work_order_number: x.description?.toLowerCase() === "maintenance" ? '--' : x.service_code,
          total: +(+x?.unit_cost || +x?.rate * +x?.quantity).toFixed(2) + +parseFloat(x?.tax).toFixed(2),
          service_type: x.service_type,
          worktype: x.worktype,
          worksubtype: x.worksubtype,
          is_flat_rate: x.is_flat_rate,
          service_order_number: x?.service_order_number
        });
      });
      let serviceName = ""
      serviceName = props?.invoiceDetail?.is_multi_so_invoice === true ? "Multiple Services selected" : `${props?.invoiceDetail?.service_order.service_order_number} ${props?.invoiceDetail?.service_order.service_order_name}`

      dispatch(setNewInvoiceDataField({
        total: props?.invoiceDetail?.total || '',
        uuid: props?.invoiceDetail?.uuid,
        status: props?.status,
        customer: props?.invoiceDetail?.user,
        sales_tax: props?.invoiceDetail?.sales_tax || '',
        balance_due: props?.invoiceDetail?.balance_due || '',
        quantity: props?.invoiceDetail?.quantity,
        rowsData: rows,
        serviceOrderDropDownValue: serviceName,
        serviceOrderUuid: [props?.invoiceDetail?.service_order_uuid] || [],
        notes: props?.invoiceDetail?.notes || '',
        invoice_date: props?.invoiceDetail?.invoice_date || moment(),
        serviceOrderNumberList: [],
        due_date: props?.invoiceDetail?.due_date || '',
        amount_received: props?.invoiceDetail?.amount_received || '0',
        refund_amount: props?.invoiceDetail?.refund_amount || '0.00',
        credit_memo_amount_applied: props?.invoiceDetail?.credit_memo_amount_applied || '0.00',
      }));
    } else {
      dispatch(setNewInvoiceDataField({ serviceOrderNumberList: [], serviceOrderUuid: [] }));
      dispatch(setInvoiceDataField({ selectedWorkOrders: [] }));
    }
  }
  const datafilter = serviceOrderNumberList?.filter((item) => {
    return item?.display_for_invoice == true || item?.service_order_name == "Retail Invoice"
  });

  useEffect(() => {
    dispatch(setNewInvoiceDataField({ customer: props?.customer, uuid: props.invoice_uuid }));
    initialLoad();
    return (() => {
      dispatch(resetInvoiceData());
    })
  }, []);
  const initialLoad = () => {
    getTaxValue();
    getState();
    dispatch(setInvoiceDataField({ loading: true }));
    const serviceOrderList = async () => {
      const data = await getServiceOrderByClient({
        field_office_uuid: props?.record?.field_office?.uuid ?? props.record.service_order?.field_office_uuid,
        client_uuid: props?.customerId ?? props?.record?.uuid
      });
      dispatch(setInvoiceDataField({ loading: false }));

      let serviceName = ""
      let retailService = {};
      let filterData = data?.filter((d) => {
        if (d.service_order_name === "Retail Invoice") {
          retailService = d;
          if(props.isRetailSale) {
            serviceName = `${d.service_order_number} ${d.service_order_name}`
          }
        }
        if (d.service_type?.toLowerCase() === "maintenance" && d.is_invoice_generated === true) {
          return true;
        } else if (d.is_invoice_generated === false) {
          return true;
        }
      });
      if (!retailService.uuid) {
        setNewRetailServiceName(true);
      }
      let retailInvoiceOption = filterData?.filter((option) => option.service_order_name === "Retail Invoice");
      let sortedServiceOrder = filterData?.filter((item) => item.service_order_name !== "Retail Invoice");
      dispatch(setInvoiceDataField({ serviceOrderNumberList: [...retailInvoiceOption, ...sortedServiceOrder] }));
      if (serviceName !== "") {
        dispatch(setNewInvoiceDataField({ serviceOrderDropDownValue: serviceName, serviceOrderUuid: props.isRetailSale && retailInvoiceOption[0].uuid ? [retailInvoiceOption[0].uuid] : [] }));
      }
    }
    serviceOrderList();
  }

  useEffect(() => {
    if (newRetailServiceName) {
      const serviceOrderList = async () => {
        const data = await getServiceOrderByClient({
          field_office_uuid: props?.record?.field_office?.uuid ?? props.record.service_order?.field_office_uuid,
          client_uuid: props?.customerId ?? props?.record?.uuid
        });
        let filterData = data?.filter((d) => {
          if (d.service_type?.toLowerCase() === "maintenance" && d.is_invoice_generated === true) {
            return true
          } else if (d.is_invoice_generated === false) {
            return true
          }
        });

        let retailInvoiceOption = filterData?.filter((option) => option.service_order_name === "Retail Invoice");
        let sortedServiceOrder = filterData?.filter((item) => item.service_order_name !== "Retail Invoice");
        dispatch(setInvoiceDataField({ serviceOrderNumberList: [...retailInvoiceOption, ...sortedServiceOrder] }));

        if (props.isRetailSale) {
          dispatch(setNewInvoiceDataField({
            serviceOrderDropDownValue: `${retailInvoiceOption[0].service_order_number} ${retailInvoiceOption[0].service_order_name}`,
            serviceOrderUuid: [retailInvoiceOption[0].uuid],
          }))
        }
      }

      const createReq = async () => {
        try {
          await createRetailServiceRequest();
          serviceOrderList();
        } catch (e) {
        }
        setNewRetailServiceName(false);
      }
      createReq();
    }
  }, [newRetailServiceName]);

  const modalToggleClearAll = () => {
    dispatch(setInvoiceDataField({ isOpenClearAll: true }))
  }

  const createRetailServiceRequest = async () => {
    const retailSaleObj = props?.record;
    const response = await createServiceOrder({
      "status": retailSaleObj?.status,
      "service_address_uuid": retailSaleObj?.service_address[0].uuid,
      "field_office_uuid": retailSaleObj?.field_office.uuid,
      "client_uuid": retailSaleObj?.uuid,
      "is_service": false,
      "is_hide_from_display": true,
      "is_retail": true,
      "proposal_date": null,
      "contract_date": null,
      "preferred_start_date": null,
      "service_type_uuid": null,
      "is_terms_accepted": true,
      "pool_type_uuid": "",
      "pool_size": null,
      "frequency_settings": null,
      "is_flat_rate": false,
      "price": 0,
      "service_price": 0,
      "client_type": retailSaleObj?.client_type,
    });
    return response;
  }

  const deleteWorkOrder = (index) => {
    let filterRowsData = [];
    let filterServiceOrderUUID = [];
    let tempServiceOrderDropDownValue = ''
    if (index) {
      filterRowsData = newInvoice?.rowsData.filter((f) => f.index !== index);
    } else {
      if (selectedItem.type === "Retail Invoice") {
        if (selectedItem.repair_code?.includes("Labor")) {
          filterRowsData = newInvoice?.rowsData.filter((f) => f.repair_code !== selectedItem.repair_code && f.repair_code !== f.bundle_code);
        } else {
          filterRowsData = newInvoice?.rowsData.filter((f) => f.index !== selectedItem.index);
        }
      } else {
        if (selectedItem.work_order_number === "--") {
          filterRowsData = newInvoice?.rowsData.filter((f) => f.index !== selectedItem.index);
        } else {
          filterRowsData = newInvoice?.rowsData.filter((f) => f.work_order_number !== selectedItem.work_order_number);
        }
      }
    }
    // filterServiceOrderUUID = newInvoice?.serviceOrderUuid.filter(e => e !== selectedItem.service_order_uuid)
    // filterRowsData = filterRowsData.filter(e => e.service_order_uuid !== selectedItem.service_order_uuid)
    //map function for service order uuid (bundle data)
    filterRowsData.map((e) => {
      if(!filterServiceOrderUUID.includes(e.service_order_uuid)) {
        filterServiceOrderUUID.push(e.service_order_uuid);
      }
    })

    if(filterServiceOrderUUID.length === 1){
      let filterService = serviceOrderNumberList.find(service => service.uuid === filterServiceOrderUUID[0]);
      tempServiceOrderDropDownValue = filterService?.service_order_number + " " + filterService?.service_order_name;
    }
    else if(filterServiceOrderUUID.length > 1){
      tempServiceOrderDropDownValue = 'Multiple Service Order selected'
    }
    
    dispatch(setNewInvoiceDataField({ rowsData: filterRowsData,serviceOrderUuid: filterServiceOrderUUID,serviceOrderDropDownValue: tempServiceOrderDropDownValue}));
    dispatch(setInvoiceDataField({ selectedItem: {}, isOpenDelete: false}));
  }

  const localToUTC = (date) => {
    return moment.utc(date)
  }

  // on invoice detail change
  const onTextChange = (e) => {
    dispatch(setNewInvoiceDataField({ [e.target.name]: e.target.value }))
  }

  const renderWorkOrder = () => {
    const resultArray = Array.isArray(selectedWorkOrders) ? selectedWorkOrders?.filter((f) => ((f.status === 'complete') || (f.status === "cancelled")) && (f.is_invoiced === false)) : []
    if (newInvoice?.rowsData.length > 0) {
      let selectedWorkOrderNo = newInvoice?.rowsData.map((each) => each.work_order_number)
      let res = resultArray.filter((r) => !selectedWorkOrderNo.includes(r.work_order_number))
      return res;
    } else {
      return resultArray;
    }
  }

  const isGenerateButtonDisable = () => {
    if (props?.isRetailSale) {
      if (newInvoice?.rowsData.length) return false;
      return true;
    }
    if (newInvoice?.rowsData.length > 0) {
      if (newInvoice?.rowsData.find((each) => each.description !== '' && each.price !== '' && each.tax !== '')) return false;

      return true;
    }
    return true;
  }

  // on item row change
  const onRowDataChange = (e) => {
    let tempRows = [...newInvoice?.rowsData]?.filter((f) => f.type !== "")
    let isExist = false;
    let selectedWorkOrder = [];
    if (e.target) {
      selectedWorkOrder = selectedWorkOrders.filter((f) => f.work_order_uuid === e.target.value)?.[0]
    } else
      selectedWorkOrder = selectedWorkOrders.filter((f) => f.work_order_number === e)
    selectedWorkOrder?.lineitems?.map((x) => {
      tempRows.push({
        uuid: x?.uuid,
        service_uuid: x?.work_order_uuid,
        type: selectedWorkOrder?.servicetype,
        item_type: selectedWorkOrder?.servicetype,
        date: x?.date,
        description: x?.description,
        price: x?.unit_cost || 0,
        unit_cost: x?.unit_cost || 0,
        quantity: x?.quantity || 1,
        tax: x?.tax || '0',
        isEditRow: true,
        other_description: x?.sub_description,
        service_code: x?.service_code,
        total: (+x?.unit_cost * +x?.quantity) + +x?.tax,
        service_info: x,
        isDefault: false,
        work_order_number: selectedWorkOrder?.work_order_number,
        invoice_date: newInvoice?.invoice_date,
        index: Date.now() + Math.random(),
        service_order_number: x?.service_order_number
      })
    })
    let tempRows2 = tempRows.slice(1);
    let defaultObj = tempRows[0];
    if (tempRows2?.[0]?.type?.toLowerCase() !== "maintenance") {
      tempRows2.forEach((each) => {
        if (each?.type?.toLowerCase() === defaultObj?.service_info?.service_type?.toLowerCase()) {
          isExist = true;
        }
      })
    }
    if (isExist) {
      tempRows = [...tempRows2];
    }
    if (tempRows.some(tempRows => e.target ? tempRows.service_uuid === e.target.value : tempRows?.service_order_uuid === selectedWorkOrder[0]?.service_order_uuid
    )) {
      dispatch(setNewInvoiceDataField({
        rowsData: tempRows,
      }));
    }
  }

  const handleAddItemClick = () => {
    let service = serviceOrderNumberList.find((item) => item.uuid === newInvoice?.serviceOrderUuid[0]);
    if (service?.service_order_name === "Retail Invoice") {
      dispatch(setInvoiceDataField({ addItemModal: true }))
    } else {
      handleTableData();
    }
  }

  const amount = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const getTaxValue = async () => {
    const response = await getAvalaraTaxValue(newInvoice?.customer?.service_address ? newInvoice.customer?.service_address[0]?.zip_code : props?.customer?.service_address[0]?.zip_code);
    if (response?.tax_rate === 0) {
      let data = amount(1)
      dispatch(setNewInvoiceDataField({ taxRate: data }))
    } else {
      let data1 = amount(response?.tax_rate ?? newInvoice?.taxRate)
      dispatch(setNewInvoiceDataField({ taxRate: data1 }))
    }
  }

  const handleTableData = () => {
    let tempRowData = JSON.parse(JSON.stringify(newInvoice?.rowsData));
    tempRowData = [...tempRowData, { service_uuid: '', type: '', item_type: '', date: '', description: '', quantity: 1, price: '', unit_cost: '', taxChecked: false, tax: '', index: Date.now() + Math.random(), isDefault: false }];
    dispatch(setNewInvoiceDataField({ rowsData: tempRowData }));
  }

  const applyTaxCheck = (e, item) => {
    let tempRowData = newInvoice?.rowsData.map((row) => {
      if (row.index === item.index) {
        let tax = e.target.checked ? amount(((item.price) * parseFloat(item.taxRate)) / 100) : 0;
        let total = (item.price + parseFloat(tax));
        return ({
          ...row, is_taxable: e.target.checked, tax, total
        })
      }
      return row;
    });
    if (tempRowData.filter(item => item.bundle_code).length > 0) {
      tempRowData = setBundleVal(tempRowData);
    }
    dispatch(setNewInvoiceDataField({ rowsData: tempRowData }));
  }

  const handleChange = event => {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
    const filteredVal = event.target.value.match(regex)[0];
    dispatch(setNewInvoiceDataField({ taxRate: filteredVal }))
  }

  const onClearAll = () => {
    let filterData = newInvoice?.rowsData?.filter((f) => f.isDefault !== false)
    dispatch(setNewInvoiceDataField({ rowsData: filterData }))
    let filterDataWithWo = newInvoice?.rowsData?.filter((f) => Object.keys(f).includes('work_order_number'))
    if (filterDataWithWo && filterDataWithWo.length > 0) {
      dispatch(setNewInvoiceDataField({ rowsData: [] }))
    }
    dispatch(setInvoiceDataField({ isOpenClearAll: false }))
  }

  const handleServiceSelect = (e) => {
    const { value, checked } = e.target;
    let currentService = serviceOrderNumberList.find(service => service?.uuid === value);
    if(previousName === "Retail Invoice"){
      setOpenConfirmSO(true);
    }
    setPreviousName(currentService.service_order_name)
    if (checked && currentService.service_order_name === "Retail Invoice" && newInvoice.serviceOrderUuid.length > 0) {
      setOpenConfirm(true);
      setConfirmArgument(value);
    } else {
      handleMultipleServiceOrder(e);
    }
  }

  const handleMultipleServiceOrder = (e) => {
    if (serviceOrderNumberList.length > 0) {
      const { value, checked } = e.target;
      if (checked) {
        let retailService = serviceOrderNumberList.find(service => service?.service_order_name === "Retail Invoice");
        let currentService = serviceOrderNumberList.find(service => service.uuid === value);
        if (currentService?.service_order_name === "Retail Invoice") {
          dispatch(setNewInvoiceDataField({ serviceOrderUuid: [value], rowsData: [], status: 'open', serviceOrderDropDownValue: currentService.service_order_number + " " + currentService.service_order_name }));
          dispatch(setInvoiceDataField({ selectedWorkOrders: [] }));
        } else {
          let tempServiceOrderDropDownValue = newInvoice.serviceOrderDropDownValue;
          let tempServiceOrderUuid = [...newInvoice?.serviceOrderUuid.filter(id => id != retailService.uuid), value];
          if (tempServiceOrderUuid.length === 0) {
            tempServiceOrderDropDownValue = "";
          } else if (tempServiceOrderUuid.length === 1) {
            let filterService = serviceOrderNumberList.find(service => service.uuid === tempServiceOrderUuid[0]);
            tempServiceOrderDropDownValue = filterService?.service_order_number + " " + filterService?.service_order_name;
          } else {
            tempServiceOrderDropDownValue = "Multiple Service Order selected";
          }
          dispatch(setNewInvoiceDataField({ serviceOrderUuid: tempServiceOrderUuid, status: 'open', serviceOrderDropDownValue: tempServiceOrderDropDownValue }));
          getWorkOrder(value);
        }
      } else {
        const data = newInvoice?.rowsData.filter((item) => item?.service_order_uuid !== value);
        let tempServiceOrderDropDownValue = newInvoice.serviceOrderDropDownValue;
        let filteredData = newInvoice?.serviceOrderUuid.filter(item => item !== value);
        if (filteredData.length === 0) {
          tempServiceOrderDropDownValue = "";
        } else if (filteredData.length === 1) {
          let filterService = serviceOrderNumberList.find(service => service.uuid === filteredData[0]);
          tempServiceOrderDropDownValue = filterService.service_order_number + " " + filterService.service_order_name
        } else {
          tempServiceOrderDropDownValue = "Multiple Service Order";
        }
        dispatch(setNewInvoiceDataField({ rowsData: data, serviceOrderUuid: filteredData, status: filteredData.length > 0 ? "open" : "", serviceOrderDropDownValue: tempServiceOrderDropDownValue }));
        let updatedWorkOrders = selectedWorkOrders.filter(workOrder => filteredData.includes(workOrder.service_order_uuid));
        dispatch(setInvoiceDataField({ selectedWorkOrders: updatedWorkOrders }));
      }
    }
  }

  const clearServiceOrderSelection = () => {
    dispatch(setNewInvoiceDataField({ serviceOrderUuid: [], rowsData: [], serviceOrderDropDownValue: null }));
    dispatch(setInvoiceDataField({ selectedWorkOrders: [] }));
  }

  const getWorkOrder = async (uuid) => {
    let tempRowData = [...newInvoice?.rowsData];
    let existingData = tempRowData?.filter((item) => item.type !== "Retail Invoice");
    tempRowData = existingData;
    let data = await getSingleInvoice(uuid);
    let tableData = (Array.isArray(data) ? data?.filter((f) => ((f.status === 'complete') || (f.status === "cancelled")) && (f.is_invoiced === false)) : []);
    map(tableData, (elem) => {
      if ((elem.lineitems).length > 0) {        
        elem?.lineitems?.map((x) => {
          tempRowData = [...tempRowData, {
            uuid: x?.uuid,
            service_uuid: x?.work_order_uuid,
            type: elem?.servicetype,
            item_type: elem?.servicetype,
            date: x?.date,
            description: x?.description,
            price: x?.unit_cost || 0,
            unit_cost: x?.unit_cost || 0,
            quantity: x?.quantity || 1,
            tax: x?.tax ? parseFloat(x?.tax).toFixed(2) : 0,
            isEditRow: true,
            other_description: x?.sub_description,
            service_code: x?.service_code,
            service_order_uuid: elem.service_order_uuid,
            total: +parseFloat(+x?.unit_cost * +x?.quantity).toFixed(2) + +parseFloat(x?.tax).toFixed(2),
            service_info: x,
            isDefault: false,
            work_order_number: elem?.work_order_number,
            index: Date.now() + Math.random(),
            service_order_number: x?.service_order_number
          }];
        })
      }
    });

    const serviceList = serviceOrderNumberList?.filter(x => x.uuid === uuid)
    map(serviceList, (x) => {
      if (x?.service_type?.toLowerCase() === 'maintenance' && x?.is_invoice_generated === false) {
        let des = `${x?.worktype}/${x?.service_type}-${x?.is_flat_rate ? "Flat Rate" : ''}`
        const taxesVal = x.is_apply_tax
          ? x.tax
            ? x.tax_amount + x.tax
            : x.tax_amount
          : "0"
        let taxes = taxesVal !== "0" ? taxesVal.toFixed(2) : taxesVal;
        tempRowData.push({
          uuid: x.uuid,
          service_uuid: x?.service_uuid,
          service_order_uuid: x?.uuid,
          type: x?.service_type,
          item_type: x?.service_type,
          service_type: x?.service_type,
          date: x.date,
          description: des,
          price: x?.is_recurring ? x?.service_price : x?.price || 0,
          unit_cost: x?.is_recurring ? x?.service_price : x?.price || 0,
          quantity: x.quantity || 1,
          tax: taxes,
          isEditRow: true,
          other_description: x.sub_description,
          service_code: x.service_code,
          total: x?.is_recurring ? x?.service_price + parseFloat(taxes) : x?.price ? x?.price + parseFloat(taxes) : 0,
          service_info: x,
          isDefault: true,
          work_order_number: "--",
          index: Date.now() + Math.random(),
          service_order_number: x?.service_order_number,
        })
      }
    });
    dispatch(setInvoiceDataField({ selectedWorkOrders: [...selectedWorkOrders, ...tableData] }));
    dispatch(setNewInvoiceDataField({ rowsData: tempRowData }));
  }

  useEffect(() => {
    calculateTableData();
  }, [newInvoice?.rowsData])

  const calculateTableData = () => {
    let rows = newInvoice?.rowsData.filter((row)=>!(row.bundle_code?.length>0));
    let newTotal = 0, salesTax = 0, balanceDue = 0;
    map(rows, x => {
      salesTax = parseFloat(salesTax) + parseFloat(x?.tax)
      newTotal = parseFloat(newTotal) + +(parseFloat(x?.rate || x?.unit_cost) * parseFloat(x?.quantity || 1)).toFixed(2)
      balanceDue = parseFloat(balanceDue) + ((parseFloat(x?.rate || x?.unit_cost) * parseFloat(x?.quantity || 1)) + parseFloat(x?.tax || 0))
    })
    dispatch(setNewInvoiceDataField({ total: parseFloat(newTotal).toFixed(2), sales_tax: salesTax, balance_due: balanceDue }))
  }

  // validate required fields
  const validate = async () => {
    const schema = yup.object().shape({
      serviceOrderUuid: yup.array().of(yup.string()).required('Service order# is required'),
      invoice_date: yup.string().required('Invoice Date is required'),
      due_date: yup.string().required('Due Date is required').test("compare", "Invalid due date", (due_date) => moment(newInvoice?.invoice_date).isBefore(moment(due_date))),
    });

    const schema1 = yup.object().shape({
      invoice_date: yup.string().required('Invoice Date is required'),
      due_date: yup.string().required('Due Date is required').test("compare", "Invalid due date", (due_date) => moment(newInvoice?.invoice_date).isBefore(moment(due_date))),
    });

    const data = { serviceOrderUuid: newInvoice?.serviceOrderUuid, due_date: newInvoice?.due_date, invoice_date: newInvoice?.invoice_date };

    setTimeout(() => {
      newInvoice?.uuid ? schema1.validate(data, { abortEarly: false }).then(() => {
        dispatch(setInvoiceDataField({ errors: {} }))
        onSave();
      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => { err_data[item.path] = item.message })
        dispatch(setInvoiceDataField({ errors: err_data, }))
      }) : schema.validate(data, { abortEarly: false }).then(() => {
        dispatch(setInvoiceDataField({ loading: true, errors: {}, }));
        generateInvoice()
      }).catch((error) => {
        let err_data = {};
        map(error.inner, (item) => { err_data[item.path] = item.message })
        dispatch(setInvoiceDataField({ errors: err_data }));
      });
    }, 500)
  };

  // on save
  const onSave = () => {
    const { addManualInvoice } = props;
    const address = !isEmpty(serviceOrderNumberList?.billing_address) ? serviceOrderNumberList?.billing_address : !isEmpty(serviceOrderNumberList?.service_address) ? serviceOrderNumberList?.service_address : {};

    if (!address?.geo_location) {
      delete address?.geo_location
    }
    map(newInvoice?.rowsData, (r, i) => {
      r.date = localToUTC(newInvoice?.due_date).format('YYYY-MM-DD')
      r.item_type = r.type
    })
    let uniqueRows = [...new Set(newInvoice?.rowsData.map(item => item.service_uuid))];
    let workOrders = [];
    map(uniqueRows, (ur, i1) => {
      let obj = {};
      obj.work_order = ur;
      let lineItems = newInvoice?.rowsData.filter(function (obj) {
        return obj.service_uuid === ur;
      });
      obj.isEditRow = lineItems[0].isEditRow;
      obj.line_items = lineItems;
      workOrders.push(obj);
    });
    let deleteFilter = newInvoice?.selectedDeleteItem.filter(function (df) {
      return df.isEditRow === false;
    });
    let isApprove = false
    let delWorkOrder = _.uniqBy(deleteFilter, 'service_uuid');
    delWorkOrder = _.map(delWorkOrder, 'service_uuid');
    let isInvoiceProcessed = false;
    if (newInvoice?.uuid === '' && isApprove === false) {
      isInvoiceProcessed = false;
    }
    if (newInvoice?.uuid === '' && isApprove === true) {
      isInvoiceProcessed = true;
    }
    if (newInvoice?.uuid !== '' && isApprove === false) {
      isInvoiceProcessed = false;
    }
    if (newInvoice?.uuid !== '' && isApprove === true) {
      isInvoiceProcessed = true;
    }
    addManualInvoice({
      user_uuid: newInvoice.customer?.uuid,
      invoice_date: newInvoice?.invoice_date ? localToUTC(newInvoice?.invoice_date).format('YYYY-MM-DD') : null,
      due_date: newInvoice?.due_date ? localToUTC(newInvoice?.due_date).format('YYYY-MM-DD') : null,
      balance_due: parseFloat(newInvoice?.balance_due || '0').toFixed(2),
      quantity: newInvoice?.quantity,
      amount_received: parseFloat(newInvoice?.amount_received || '0').toFixed(2),
      status: 'open',
      billing_address: address,
      billing_type: 'email',
      notes: newInvoice?.notes,
      service_order_uuid: newInvoice?.serviceOrderUuid,
      invoice_uuid: newInvoice?.uuid ? newInvoice?.uuid : null,
      is_process: isInvoiceProcessed,
      work_orders: workOrders.filter(function (wo) {
        return wo.isEditRow === true && wo.work_order !== "";
      }),
      delete_work_orders: newInvoice?.uuid ? delWorkOrder : []
    })
    dispatch(setInvoiceDataField({ loading: true }));
  }

  const generateInvoice = () => {
    let uniqueRows = [...new Set(newInvoice?.rowsData.map(item => item?.work_order_number))];
    let workOrders = [];
    map(selectedWorkOrders, (ur, i1) => {
      if (uniqueRows.includes(ur?.work_order_number)) {
        workOrders.push(selectedWorkOrders[i1])
      }
    });

    const createInvoice = async (obj) => {
      const data = await createManualInvoice(obj);
      if (data.status) {
        if (data.status === 400) {
          dispatch(setInvoiceDataField({ loading: false }));
          props.onChangeView(0);
          props.getOverdueOpen();
          message.info(`${data.data.message}`, 10);
        } else {
          dispatch(setInvoiceDataField({ loading: false }));
          props.onChangeView(0);
          props.getOverdueOpen();
          message.error("Something went wrong..!", 10);
        }
      }
      if (data.invoice_uuid) {
        dispatch(setInvoiceDataField({ loading: false }));
        props.onChangeView(0);
        props.getOverdueOpen();
        message.success(`${data.message}`, 10);
      }
    }
    let obj = {};
    if (newInvoice?.rowsData[0].type === "Retail Invoice") {
      obj.user_uuid = newInvoice?.customer?.uuid;
      obj.invoice_date = localToUTC(newInvoice?.invoice_date).format('YYYY-MM-DD');
      obj.due_date = localToUTC(newInvoice?.due_date).format('YYYY-MM-DD');
      obj.invoice_type = "retail";
      obj.quantity = newInvoice?.rowsData.length;
      obj.billing_type = "email";
      obj.notes = newInvoice?.notes;
      obj.service_order_uuid = newInvoice?.serviceOrderUuid;
      obj.invoice_uuid = null;
      obj.is_process = false;
      obj.lineitems = newInvoice?.rowsData.map((item) => ({
        bundle_code: item?.bundle_code || null,
        repair_code: item.repair_code,
        description: item.description,
        is_taxable: item.is_taxable,
        tax: parseFloat(item.tax || 0),
        unit_cost: item.unit_cost,
        quantity: item.quantity,
        price: item.price ? item.price : item.total,
        qb_account: item.qb_account,
        is_lineitem_invoiced: false,
      }));
      obj.delete_work_orders = [];
      obj.balance_due = newInvoice?.balance_due;
    } else {
      let workOrders = selectedWorkOrders.map((wo) => ({
        ...wo,
        lineitems: newInvoice.rowsData.filter(li => !li.isDefault && wo.service_order_uuid === li.service_order_uuid && wo.work_order_uuid === li.service_info?.work_order_uuid).map(li => ({
          "billable": li.service_info?.billable,
          "bundle_code": li.service_info?.bundle_code,
          "description": li.description,
          "is_lineitem_invoiced": li.service_info?.is_lineitem_invoiced,
          "is_taxable": li.service_info?.is_taxable,
          "qb_account": li.service_info?.qb_account,
          "quantity": li.service_info?.quantity,
          "repair_code": li.service_info?.repair_code,
          // "tax": li.service_info?.is_taxable ? li.tax : 0,
          "tax": li?.tax ? li?.tax : 0,
          "total": li.total,
          "unit_cost": li.unit_cost,
          "uuid": li.uuid,
          "work_order_uuid": li.service_info?.work_order_uuid,
        }))
      }))
      workOrders = workOrders.filter(wo => wo.lineitems?.length > 0);
      obj.user_uuid = newInvoice.customer?.uuid;
      obj.invoice_date = localToUTC(newInvoice?.invoice_date).format('YYYY-MM-DD');
      obj.due_date = localToUTC(newInvoice?.due_date).format('YYYY-MM-DD');
      obj.quantity = newInvoice?.quantity;
      obj.billing_type = "email";
      obj.notes = newInvoice?.notes;
      obj.service_order_uuid = newInvoice?.serviceOrderUuid;
      obj.invoice_uuid = newInvoice?.uuid ? newInvoice?.uuid : null;
      obj.is_process = false;
      obj.work_orders = workOrders;
      obj.delete_work_orders = [];
      obj.balance_due = newInvoice?.balance_due;
    }
    createInvoice(obj);
  }

  const handleDeleteItem = (item) => {
    if (item?.type === "Retail Invoice") {
      dispatch(setInvoiceDataField({ selectedItem: item, isOpenDelete: true }));
    } else if (item?.type !== "") {
      dispatch(setInvoiceDataField({ isOpenDelete: true, selectedItem: item }));
    } else {
      deleteWorkOrder(item?.index);
    }
  }

  // render Item Row
  const renderItemRow = (item, index) => {
    var getKeys = _.map(newInvoice?.rowsData, 'service_order_uuid');
    getKeys = [...new Set(getKeys)];

    let retailService = serviceOrderNumberList?.find((item) => item.uuid === newInvoice?.serviceOrderUuid[0]);
    return (
      <div className="divrow editrow" key={`items${index}`} style={{ marginLeft: "0px" }}>
        {
        <div className="dcell serviceno">{item?.service_order_number}</div>
        }
        {retailService?.service_order_name === "Retail Invoice" ? (
          <div className="dcell sno">N/A</div>
        ) : (
          <>
            {item?.work_order_number ? (
              <div className="dcell sno">{item?.work_order_number}</div>
            ) : (
              <div className="dcell sno">
                <Select
                  disabled={item?.service_uuid ? true : false}
                  dropdownClassName="sm-filter"
                  showArrow
                  name={'service_uuid'}
                  virtual={false}
                  style={{ width: '100%' }}
                  value={item?.service_uuid ? item?.service_uuid : undefined}
                  placeholder={'Select'}
                  onChange={(e) => {
                    onRowDataChange({ target: { name: 'service_uuid', value: e } })
                  }}
                >
                  {map(renderWorkOrder(), (obj) => <Option hidden={!obj?.has_lineitems} value={obj?.work_order_uuid} key={obj?.work_order_uuid}>{obj?.work_order_number}</Option>)}
                </Select>
                {errors?.[`rowsData[${index}].service_uuid`] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.[`rowsData[${index}].service_uuid`]}</label> : ''}
              </div>
            )}
          </>
        )}
        <div className="dcell sdate">{newInvoice?.invoice_date ? localToUTC(newInvoice?.invoice_date).format('YYYY-MM-DD') : null || "--"}</div>
        <div className="dcell stype">{item?.type ? item?.type : "--"}</div>
        <div className="dcell sdesc">{item?.description}</div>
        <div className="dcell sprice">{item?.quantity ? item?.quantity || 1 : "--"}</div>        
        {
          !isEmpty(props.invoice_uuid) ?
          ( <div className="dcell sprice">{"$" + formatToCurrency(item?.rate || "--")}</div>)
          :
          (<div className="dcell sprice">{"$" + formatToCurrency(item?.unit_cost || "--")}</div>)
        }
        
        {retailService?.service_order_name === "Retail Invoice" && !newInvoice?.uuid? <div className="dcell sprice">
          <input type="checkbox"
            className="fitem-ck-input"
            id="checkbox"
            checked={item?.is_taxable}
            onChange={(e) => { applyTaxCheck(e, item) }}
            name="checkboxs"
            disabled={item?.disabled}
          ></input>
        </div> : null}
        <div className="dcell stax">
          <div className="flex ai-center">
            <div className="inline-check-list sm-checks">
              <div className="flex ai-center">
                <div className="sprice">
                  {"$" + formatToCurrency(item?.tax)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="dcell sprice">
            {"$" + formatToCurrency(item?.total)}
          </div>
          {(newInvoice?.uuid && newInvoice?.status !== 'draft') || (item.isDefault) ? null : <i className="las la-trash-alt" onClick={() => handleDeleteItem(item)} />}
        </div>
      </div>
    )
  }

  const handleResetTaxValue = (e) => {
    e.preventDefault();
    getTaxValue();
  }

  // render table
  const renderItemTable = () => {
    let retailService = serviceOrderNumberList?.find((item) => item?.uuid === newInvoice?.serviceOrderUuid[0]); //Only used for retail invoice
    return (
      <div className="table-block">
        <div className="div-table maintenance-table" style={newInvoice?.uuid ? null : { 'display': 'block' }}>
          <div className="divhead">
            <div className="dcell serviceno">Service Order #</div>
            <div className="dcell sno">Work Order #</div>
            <div className="dcell sdate">Date</div>
            <div className="dcell stype">Type</div>
            <div className="dcell sdesc">Description</div>
            <div className="dcell squantity">Quantity</div>
            <div className="dcell sprice">Price</div>
            {retailService?.service_order_name === "Retail Invoice" && !newInvoice?.uuid ? <div className="dcell stax">Apply tax</div> : null}
            <div className="dcell stax">Tax</div>
            <div className="dcell stax">Total</div>
          </div>
          <div className="divbody">
            {map(newInvoice?.rowsData, renderItemRow)}
            <div className="divrow dfoot">
              <div className="dcell maintenance-cell-margin padding-r-remove">
                {retailService?.service_order_name === "Retail Invoice" && !newInvoice?.uuid ? <div className="half-fitem fitem">
                  <label className="mr-10"> Tax Rate: </label>
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "20%" }}
                    value={newInvoice?.taxRate}
                    onChange={(e) => { handleChange(e) }}
                  />
                  <u>
                    <a role="button" href="/#" onClick={handleResetTaxValue} className="ml-10"
                    >
                      {" "}
                      Reset Tax Rate to local
                    </a>
                  </u>
                </div> : null}
                <div className="btn-group">
                  <button className={`btn btn-warning ${(newInvoice?.status === 'draft' || newInvoice?.serviceOrderUuid.length === 0 || newInvoice.uuid) ? "disbale_btn" : ""}`} disabled={(newInvoice?.status === 'draft' || newInvoice?.serviceOrderUuid.length === 0 || newInvoice.uuid)} onClick={handleAddItemClick}><i className="las la-plus-circle"></i> Add Item</button>
                  <a className={`clear-item btn-warning  ${(newInvoice?.status === 'draft' || newInvoice?.serviceOrderUuid?.length === 0 || newInvoice.uuid) ? "disbale_btn" : ""}`} disabled={newInvoice?.status === 'draft' || newInvoice?.serviceOrderUuid?.length === 0 || newInvoice.uuid} onClick={() => (newInvoice?.status === 'draft' || newInvoice?.rowsData?.length === 0 || newInvoice.uuid) ? null : modalToggleClearAll()}>Clear All Items</a>
                </div>
                <div className="form-group">
                  <div className="fitem">
                    <label><b>Message</b></label>
                    <textarea
                      disabled={newInvoice?.status === 'draft' || newInvoice.uuid}
                      rows="2"
                      className="form-control"
                      name="notes"
                      placeholder={"Add a message to your invoice !"}
                      value={newInvoice?.notes}
                      onChange={onTextChange} />
                  </div>
                </div>
              </div>
              <div className="dcell flex ai-start flex-end">
                <div className="price-block">
                  <span>Total</span>
                  <span>Sales Tax</span>
                  <span>Amount Received</span>
                  <span>Credit Applied</span>
                  {newInvoice?.status !== 'draft' && newInvoice?.uuid !== '' ? <span>Balance Due</span> : null}
                  <span>Amount Refunded</span>
                </div>
                <div className="pricing">
                  <span>${newInvoice?.total ? formatToCurrency(newInvoice?.total) : '0.00'}</span>
                  <span>${newInvoice?.sales_tax ? formatToCurrency(newInvoice?.sales_tax) : '0.00'}</span>
                  <span>${newInvoice?.amount_received ? formatToCurrency(newInvoice?.amount_received) : '0.00'}</span>
                  <span>${newInvoice?.credit_memo_amount_applied ? formatToCurrency(newInvoice?.credit_memo_amount_applied) : '0.00'}</span>
                  {newInvoice?.status !== 'draft' && newInvoice?.uuid !== '' ? <span>${newInvoice?.balance_due ? formatToCurrency(parseFloat(newInvoice?.balance_due).toFixed(2)) : '0.00'}</span> : null}
                  <span>${props.invoiceDetail ? props.invoiceDetail.refund_amount ? formatToCurrency(props.invoiceDetail.refund_amount) : '0.00' : '0.00'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const { onChangeView } = props;

  const is_service_order = !isEmpty(serviceOrderNumberList?.billing_address) || !isEmpty(serviceOrderNumberList?.service_address);

  const address = !isEmpty(serviceOrderNumberList?.billing_address) ? serviceOrderNumberList?.billing_address : !isEmpty(serviceOrderNumberList?.service_address) ? serviceOrderNumberList?.service_address : {};

  const handleConfirmApprove = () => {
    handleMultipleServiceOrder({ target: { value: confirmArgument, checked: true } });
    setOpenConfirm(false);
    setConfirmArgument();
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
    setConfirmArgument();
  };

  const handleConfirmApproveSO = () => {
    setOpenConfirmSO(false);
    setConfirmArgument();
  };

  const closeConfirmSO = () => {
    setOpenConfirmSO(false);
    setConfirmArgument();
  };

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="filter_wrapper">
        <ul className="breadcrumb">
          <li className={"cursor_pointer"}><span onClick={() => { onChangeView(0) }}>Back to all invoices</span></li>
        </ul>
      </div>
      <div className="invoice-container">
        <div className="flex flex-service">
          <div className="flex-1">
            <div className="bill-add">
              <b>Bill to:</b>
              <p>{newInvoice?.customer?.first_name} {newInvoice?.customer?.last_name}, <br />{is_service_order ? `${address?.address},` : ''} <br />{is_service_order ? `${address?.city}, ${address?.state}, ${address?.zip_code ? address?.zip_code : ''}` : ''}</p>
            </div>
          </div>
          <div className="flex-1">
            <div className="form-group">
              <div className="half-fitem fitem">
                <div className="half-fitem fitem" style={{ width: '100%' }}>
                  <label><b>Service Order + Job Name <span style={{ 'color': 'red' }}>*</span></b></label>
                  <Select
                    dropdownClassName="sm-filter no-tick"
                    disabled={newInvoice?.uuid ? true : false}
                    mode="multiple"
                    removeIcon
                    showArrow
                    allowClear
                    onClear={clearServiceOrderSelection}
                    value={newInvoice?.serviceOrderDropDownValue ? newInvoice?.serviceOrderDropDownValue : ""}
                    optionLabelProp="label"
                    name={'service_order'}
                    virtual={false}
                    style={{ width: 400 }}
                    placeholder={'Service Order'}
                  >

                    {map(_.orderBy(datafilter, (item) => (item.service_order_number), ['desc']), (obj) => obj.service_order_name === "Retail Invoice" ? (
                      <Option 
                        hidden={obj?.service_order_name === "Retail Invoice" ? false : !obj?.show_service_order} value={obj?.uuid} 
                        key={obj?.uuid} 
                        >
                        <input
                          type="checkbox"
                          className="mr-10"
                          checked={newInvoice?.serviceOrderUuid.includes(obj?.uuid)}
                          value={obj?.uuid}
                          name={obj?.service_order_name}
                          onClick={handleServiceSelect}
                        />
                          <a onClick={() => handleServiceSelect({target: { value: obj?.uuid, checked: !newInvoice?.serviceOrderUuid.includes(obj?.uuid) }})} style={{ 'color': 'black'}}>
                            {obj?.service_order_number} {obj?.service_order_name}
                          </a>
                      </Option>) : null)}
                      {map(_.orderBy(datafilter, (item) => (item.service_order_number), ['desc']), (obj) => obj.service_order_name !== "Retail Invoice" ? (
                      <Option hidden={obj?.service_order_name === "Retail Invoice" ? false : !obj?.show_service_order} value={obj?.uuid} key={obj?.uuid} onClick={handleServiceSelect} >
                      <input
                        type="checkbox"
                        className="mr-10"
                        checked={newInvoice?.serviceOrderUuid.includes(obj?.uuid)}
                        value={obj?.uuid}
                        name={obj?.service_order_name}
                        onClick={handleServiceSelect}
                      />
                        <a onClick={() => handleServiceSelect({target: { value: obj?.uuid, checked: !newInvoice?.serviceOrderUuid.includes(obj?.uuid) }})} style={{ 'color': 'black'}}>
                          {obj?.service_order_number} {obj?.service_order_name}
                        </a>
                      </Option>) : null)}
                  </Select>
                  {errors?.['serviceOrderUuid'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['serviceOrderUuid']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <label><b>Invoice Date</b></label>
                  <DatePicker
                    disabled={newInvoice?.status === 'draft' || newInvoice.uuid}
                    inputReadOnly
                    style={{ 'width': '100%' }}
                    name='date'
                    format="MM/DD/YYYY"
                    allowClear={false}
                    value={newInvoice?.serviceOrderUuid && newInvoice?.invoice_date ? moment(newInvoice?.invoice_date) : null}
                    onChange={(e) => onTextChange({ target: { name: 'invoice_date', value: e } })}
                  />
                  {errors?.['invoice_date'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['invoice_date']}</label> : ''}
                </div>
                <div className="half-fitem fitem">
                  <label><b>Due Date</b></label>
                  <DatePicker
                    disabled={newInvoice?.status === 'draft' || newInvoice.uuid}
                    inputReadOnly
                    style={{ 'width': '100%' }}
                    disabledDate={(current) => { return current && current <= moment().startOf('day'); }}
                    name='date'
                    format="MM/DD/YYYY"
                    allowClear={false}
                    value={newInvoice?.due_date ? moment(newInvoice?.due_date) : null}
                    onChange={(e) => onTextChange({ target: { name: 'due_date', value: e } })}
                  />
                  {errors?.['due_date'] ? <label className="new_err_msg"><i className="las la-info-circle" />{errors?.['due_date']}</label> : ''}
                </div>
              </div >
            </div >
          </div >
        </div >
        {renderItemTable()}
        < div className="sticky-btn-grp" >
          <div className="flex ai-center">
            <div className="flex-1">
              <button className={newInvoice?.uuid || newInvoice?.rowsData.length === 0 || isGenerateButtonDisable() ? 'btn btn-disabled disbale_btn' : 'btn btn-warning'} onClick={newInvoice?.uuid ? null : validate} disabled={(newInvoice?.uuid || newInvoice?.rowsData.length === 0 || isGenerateButtonDisable())}>Generate Invoice</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Delete Work Order"
        visible={isOpenDelete}
        onCancel={() => dispatch(setInvoiceDataField({ isOpenDelete: false }))}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => dispatch(setInvoiceDataField({ isOpenDelete: false }))}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={() => deleteWorkOrder()}>Yes</button>,
        ]}
      >
        <div>
          <p>{isOpenDelete ? 'Are you sure you want to delete the selected Work Order from this invoice?' : ''}</p>
        </div>
      </Modal>
      <Modal
        title="Clear All Items"
        visible={isOpenClearAll}
        onCancel={() => dispatch(setInvoiceDataField({ isOpenClearAll: false }))}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={() => dispatch(setInvoiceDataField({ isOpenClearAll: false }))}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={onClearAll}>Yes</button>,
        ]}
      >
        <div>
          <p>{isOpenClearAll ? 'Are you sure you want to delete the selected Work Order from this invoice?' : ''}</p>
        </div>
      </Modal>
      <Modal
        title="Confirm"
        visible={openConfirm}
        onCancel={closeConfirm}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={closeConfirm}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={handleConfirmApprove}>Yes</button>,
        ]}
      >
        <p>Upon selecting a retail service order while creating a manual invoice - Do you wish to discard the changes for the selected service orders!</p>
      </Modal>
      <Modal
        title="Confirm"
        visible={openConfirmSO}
        onCancel={closeConfirmSO}
        className="delete_user_modal"
        width={400}
        footer={[
          <button key="cancel" className="btn btn-brand btn-bdr" onClick={closeConfirmSO}>No</button>,
          <button key="upload" className={`btn btn-waning`} onClick={handleConfirmApproveSO}>Yes</button>,
        ]}
      >
        <p>Upon selecting a service order while creating a manual invoice - Do you wish to discard the changes for the selected service orders!</p>
      </Modal>
      <AddItemModal />
    </React.Fragment>
  );
}

export default AddNewInvoice;
