import React, { useState, useEffect, } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { authLogout } from "../Action/Auth";
import "react-perfect-scrollbar/dist/css/styles.css";
import dashboard from "../Assets/icons/dashboard.svg";
import franchise from "../Assets/icons/franchises.svg";
import proposal from "../Assets/icons/proposal.svg";
import residential from "../Assets/icons/residential.svg";
import commercial from "../Assets/icons/commercial.svg";
import service from "../Assets/images/service.png";
import accounting from "../Assets/icons/accounting.svg";
import case_mgmt from "../Assets/icons/case-m.svg";
import reports from "../Assets/icons/reports.svg";
import setting from "../Assets/icons/settings.svg";
import support from "../Assets/icons/support.svg";
import logout from "../Assets/images/logout.png";
import userico from "../Assets/images/userico.png";
import userimg from "../Assets/images/userimg.jpg";
import asp_logo from "../Assets/images/asp-logo.png";
import asp_min_logo from "../Assets/images/asp-min-logo.png";
import retailSale from "../Assets/icons/retail-sale.svg";
import RetailSaleCustomerModal from "../Customer/Common/RetailSaleCustomerModal";
import customers from "../Assets/icons/customers-icon.svg";
import dollarIcon from "../Assets/icons/dollar.svg"
import { remove_session_storage_items } from "../Utils/sessionStorage";

const Sidebar = (props) => {
  
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("authUser"))
    ? JSON.parse(localStorage.getItem("authUser"))
    : "";
    
  const user_roles = user ? JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles : [];
  //If the user has dual role, it will take the role different than technician      
  // const role = user && user.user.user_roles[0].name;
  const role = user.user.user_roles.length > 1
    ? user.user?.user_roles.find(i => i.name !== 'field_tech').name
    : user_roles.length === 1
    ? user?.user?.user_roles?.[0].name
    : "";  

  const lead_tech = user?.user?.is_lead_technician;
  const accessToken = JSON.parse(localStorage.getItem("authToken"))
    ? JSON.parse(localStorage.getItem("authToken")).key
    : "";
  const pathname = window.location.pathname;
  const [scrollYSuppressed, setScrollYSuppressed] = useState(false);
  const [key, setKey] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const [hasOpen, sethasOpen] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const toggleCollapsed = (collapsed) => {
    console.log("collapsed", collapsed);
    setCollapsed(!collapsed);
  };
  const toggleSubMenu = () => {
    sethasOpen(!hasOpen);
  };

  useEffect(() => {
    window.FreshWidget.init("", {
      queryString: "&widgetType=popup",
      widgetType: "popup",
      buttonType: "text",
      buttonText: "Support",
      buttonColor: "white",
      buttonBg: "#006063",
      alignment: "4",
      offset: "-1500px",
      formHeight: "500px",
      url: "https://infinitynetworks.freshdesk.com",
    });
  }, []);

  useEffect(() => {
    let timer = null;
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];
    const clearLoginTimeout = () => {
      clearTimeout(timer);
    };


    const setLoginTimeout = () => {
      timer = setTimeout(() => {
        authLogout({ key: JSON.parse(localStorage.getItem("authToken")).key });
        localStorage.clear();
        sessionStorage.clear();
        navigate(`/`);
      }, 1500000);
    };
    const resetTimeout = () => {
      clearLoginTimeout();
      setLoginTimeout();
    };

    for (var i in events) {
      if (
        _.includes(
          ["admin", "franchise_owner", "operations_mgr"],
          JSON.parse(localStorage.getItem("authUser"))?.user?.user_roles[0]
            ?.name
        )
      ) {
        window.addEventListener(events[i], resetTimeout);
      }
    }

    return () => {
      clearTimeout(timer);
      for (var i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  }, [navigate]);

  const openFreshWidget = () => {
    window.FreshWidget.show();
  };

  const selectSideBar = () => {
    const unifyRole = role === "franchise_owner" || role === "operations_mgr" ? "admin" : role;
    switch (unifyRole) {
      case "admin":
        return (
          <>
            {showCustomerModal ? <RetailSaleCustomerModal isOpen={showCustomerModal} toggleModal={setShowCustomerModal} /> : ''}
            <div className="side-nav">
              <div
                className="ant-menu ant-menu-inline-collapsed"
                style={{ background: props.sideBarBackgroundColor }}
              >
                <Button
                  type="primary"
                  className="plain-btn"
                  onClick={toggleCollapsed}
                >
                  {collapsed ? (
                    <img src={asp_min_logo} alt="" />
                  ) : (
                    <img src={asp_logo} alt="" />
                  )}
                </Button>
                <PerfectScrollbar
                  options={{ suppressScrollY: scrollYSuppressed }}
                  key={key}
                >
                  <ul className="">
                    <li
                      className={`ant-menu-item ${pathname.includes("/dashboard") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/dashboard"}>
                        <span className="mitem">
                          <img src={dashboard} alt="" />
                          <span>Dashboard</span>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`ant-menu-item ${pathname.includes("/franchise") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/franchise/franchise-list"}>
                        <span className="mitem">
                          <img src={franchise} alt="" />
                          <span>Franchises</span>
                        </span>
                      </Link>
                    </li>
                    {/*<li
                      className={`ant-menu-item ${pathname.includes("/residential") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/residential"}>
                        <span className="mitem">
                          <img src={residential} alt="" />
                          <span>Residential</span>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`ant-menu-item ${pathname.includes("/commercial") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/commercial/lead-list"}>
                        <span className="mitem">
                          <img src={commercial} alt="" />
                          <span>Commercial</span>
                        </span>
                      </Link>
                    </li>*/}
                    <li
                      className={`ant-menu-item ${pathname.includes("/customer-lead-list") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/customer-lead-list"}  onClick={()=>{
                            // clear chached filters and data
                            sessionStorage.setItem("CACHED_INITIAL_RENDERED","true");
                            remove_session_storage_items( [
                            "CACHED_CUSTOMER_LIST",
                            "CACHED_INACTIVE_CUSTOMER_LIST",
                            "CACHED_LEAD_LIST",
                            "CACHED_CUSTOMER_FILTERS",
                            "CACHED_LEADS_FILTERS",
                            "CACHED_SEARCH_VALUE",    
                            "CACHED_CURRENT_TAB",    
                            "CACHED_OFFSET",    
                            "CACHED_LIMIT",    
                            "CACHED_CUSTOMER_LIST_PAGINATION",
                            "CACHED_SELECTED_FRANCHISE",
                            "CACHED_ACTIVE_PAGNATION",
                            ]);
                      }}>
                        <span className="mitem">
                          <img src={customers} alt="" />
                          <span>Customers</span>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`ant-menu-item ${pathname.includes("/proposal/proposal-list") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/proposal/proposal-list"}>
                        <span className="mitem">
                          <img src={proposal} alt="" />
                          <span className="mitem-proposal">Proposals</span>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`ant-menu-item ${showCustomerModal ? "active" : ""
                        }`}
                      onClick={() => {
                        setShowCustomerModal(true);
                      }}
                    >
                      <span className="mitem">
                        <img src={retailSale} alt="" className="img1" />
                        <span className="retailSale">Product Sale</span>
                      </span>
                    </li>
                    <li
                      className={`ant-menu-item ${pathname.includes("/user-list") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/user-list"}>
                        <span className="mitem">
                          <img src={userico} alt="" />
                          <span>User Management</span>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`ant-menu-item ${pathname.includes("/price-catalog") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/price-catalog"}>
                        <span className="mitem">
                          <img src={dollarIcon} alt=""  className="img1"/>
                          <span>Price Catalog</span>
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`ant-menu-submenu ant-menu-submenu-inline ant-menu-item ${pathname.includes("/field_tech/week_setup") ||
                          pathname.includes("/field_tech/eod_report") ||
                          pathname.includes("/field_tech/scheduling_errors")
                          ? "active"
                          : ""
                        } ${hasOpen ? " has-open" : ""}`}
                      onClick={toggleSubMenu}
                    >
                      {" "}
                      <a>
                        <span className="mitem">
                          {" "}
                          <img src={service} alt="" />{" "}
                          <span>Field Service</span>{" "}
                        </span>
                        <DownOutlined className="ant-menu-submenu-arrow" />
                      </a>
                      <ul className="ant-menu-sub ant-menu-inline">
                        {user?.user?.field_offices[0]?.is_optimoroute_enabled == true && <li
                          className={`ant-menu-item ${
                            pathname.includes("/field_tech/week_setup") ||
                            pathname.includes("/field_tech/eod_report")
                              ? "active"
                              : ""
                            }`}
                        >
                          <Link reloadDocument to={"/field_tech/week_setup"}>
                            <span className="mitem">
                              <span>OptimoRoute Scheduling</span>
                            </span>
                          </Link>
                        </li>}
                        {/* <li className={ `ant-menu-item ${pathname.includes('/field_tech/scheduling_errors') ? 'active' : ''}`}>
                        <Link reloadDocument to={'/field_tech/scheduling_errors'}>
                          <span className="mitem">
                            <span>Scheduling Errors</span>
                          </span>
                        </Link>
                      </li> */}
                        {/* <li className={ `ant-menu-item ${pathname.includes('/field_tech/bulk-reassign') || pathname.includes('/field_tech/bulk-reassign') ? 'active' :''}`}>
                        <Link reloadDocument to={'/field_tech/bulk-reassign'}>
                          <span className="mitem">
                            <span>Bulk Reassign Technician</span>
                          </span>
                        </Link>
                      </li> */}
                        <li
                          className={`ant-menu-item ${pathname.includes(
                            "/field_tech/view-by-work-order"
                          ) ||
                              pathname.includes("/field_tech/view-by-work-order")
                              ? "active"
                              : ""
                            }`}
                        >
                          {" "}
                          <Link
                            reloadDocument
                            to={"/field_tech/view-by-work-order"}
                          >
                            <span className="mitem">
                              <span>View By Work Order</span>{" "}
                            </span>
                          </Link>{" "}
                        </li>
                        <li
                          className={`ant-menu-item ${pathname.includes(
                            "/field_tech/preferred-technician"
                          ) ||
                              pathname.includes(
                                "/field_tech/preferred-technician"
                              )
                              ? "active"
                              : ""
                            }`}
                        >
                          {" "}
                          <Link
                            reloadDocument
                            to={"/field_tech/preferred-technician"}
                          >
                            <span className="mitem">
                              <span>Bulk Reassign Technician</span>{" "}
                            </span>
                          </Link>{" "}
                        </li>
                        <li
                          className={`ant-menu-item ${pathname.includes("/calendar") ? "active" : ""
                            }`}
                        >
                          <Link reloadDocument to={"/calendar"}>
                            <span className="mitem">
                              <span>Calendar View</span>
                            </span>
                          </Link>
                        </li>
                        <li
                          className={`ant-menu-item ${pathname.includes("/tech-glance") ? "active" : ""
                            }`}
                        >
                          <Link reloadDocument to={"/tech-glance"}>
                            <span className="mitem">
                              <span>Tech Glance</span>
                            </span>
                          </Link>
                        </li>
                        <li
                          className={`ant-menu-item ${ pathname.includes("/plot_job") ? "active": ""}`}>                            
                            <Link reloadDocument to={"/field_tech/plot_job"}>
                              <span className="mitem">
                                <span>Plot Jobs on Map</span>
                              </span>
                            </Link>
                        </li>
                      </ul>
                    </li>
                    <hr />
                    <li
                      className={`ant-menu-item ${pathname.includes("/account") ? "active" : ""
                        }`}
                    >
                      {/* <Link reloadDocument to={'/account'}> */}
                      <Link reloadDocument to={"/account/account-list"}>
                        <span className="mitem">
                          <img src={accounting} alt="" />
                          <span>Accounting</span>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={`ant-menu-item ${pathname.includes("/case-management") ? "active" : ""
                        }`}
                    > */}
                      {/* <Link reloadDocument to={"/case-management"}> */}
                      {/* <Link reloadDocument to={"/under_construction"}>
                        <span className="mitem">
                          <img src={case_mgmt} alt="" />
                          <span>Case Management</span>
                        </span>
                      </Link>
                    </li> */}
                    <li
                      className={`ant-menu-item ${pathname.includes("/reports") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/reports"}>
                        {/* <Link reloadDocument to={'/reports'}> */}
                        <span className="mitem">
                          <img src={reports} alt="" />
                          <span>Reports</span>
                        </span>
                      </Link>
                    </li>
                    {/* <li
                      className={`ant-menu-item ${pathname.includes("/settings") ? "active" : ""
                        }`}
                    > */}
                      {/* <Link reloadDocument to={'/settings'}> */}
                      {/* <Link
                        reloadDocument
                        to={"/pool-inspection-form/7845/654"}
                      >
                        <span className="mitem">
                          <img src={setting} alt="" />
                          <span>Settings</span>
                        </span>
                      </Link>
                    </li> */}
                    <hr />
                  </ul>
                </PerfectScrollbar>
                <ul className="btm-menu">
                  <li className="ant-menu-item opitem">
                    {" "}
                    <span className="mitem">
                      <figure className="user-status">
                        {user?.user?.photo ? (
                          <img
                            className="userimg"
                            src={user.user.photo}
                            alt=""
                          />
                        ) : (
                          <img className="userimg" src={userimg} alt="" />
                        )}
                        <small className="astatus"></small>
                      </figure>
                      <span className="ellipse">
                        {user && user.user.first_name}{" "}
                        {user && user.user.last_name}
                      </span>{" "}
                    </span>
                  </li>
                  <li className="ant-menu-item opitem">
                    {" "}
                    <Link
                      reloadDocument
                      to="/"
                      className="mr-2"
                      replace
                      onClick={() => {
                        authLogout({ key: accessToken });
                        localStorage.clear();
                        sessionStorage.clear();
                      }}
                    >
                      <span className="mitem">
                        <img src={logout} alt="" /> <span>Log Out</span>{" "}
                      </span>
                    </Link>{" "}
                  </li>
                  <li className="ant-menu-item opitem">
                    <span className="mitem" style={{ lineHeight: 1 }}>
                      <span style={{ opacity: 0.5, fontSize: 10 }}>1.12</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="btn_contact_support"
              onClick={() => openFreshWidget()}
            >
              <img
                src={support}
                alt=""
                className="support_btn_icon"
              />
              <span className="support_btn_text">Support</span>
            </button>
          </>
        );
      case "field_tech":
        return (
          <>
            <div className="side-nav">
              <div
                className="ant-menu ant-menu-inline-collapsed"
                style={{ background: props.sideBarBackgroundColor }}
              >
                <Button
                  type="primary"
                  className="plain-btn"
                  onClick={toggleCollapsed}
                >
                  {collapsed ? (
                    <img src={asp_min_logo} alt="" />
                  ) : (
                    <img src={asp_logo} alt="" />
                  )}
                </Button>
                <PerfectScrollbar
                  options={{ suppressScrollY: scrollYSuppressed }}
                  key={key}
                >
                  <ul className="">
                  <li
                      className={`ant-menu-item ${pathname.includes("/price-catalog") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/price-catalog"}>
                        <span className="mitem">
                          <img src={dollarIcon} alt="" className="img1" />
                          <span>Price Catalog</span>
                        </span>
                      </Link>
                    </li>
                    <li className={`ant-menu-item`}>
                      <Link reloadDocument to={"/calendar"}>
                        <span className="mitem">
                          <img src={service} alt="" />
                          <span>Field Service</span>
                        </span>
                      </Link>
                    </li>

                    <li
                      className={`ant-menu-item ${pathname.includes("/calendar") ? "active" : ""
                        }`}
                    >
                      <Link reloadDocument to={"/calendar"}>
                        <span className="mitem">
                          <span>Calendar View</span>
                        </span>
                      </Link>
                    </li>
                    {lead_tech && (
                      <li
                        className={`ant-menu-item ${pathname.includes("/tech-glance") ? "active" : ""
                          }`}
                      >
                        <Link reloadDocument to={"/tech-glance"}>
                          <span className="mitem">
                            <span>Tech Glance</span>
                          </span>
                        </Link>
                      </li>
                    )}
                    {lead_tech && (
                      <li
                      className={`ant-menu-item ${ pathname.includes("/plot_job") ? "active": ""}`}>                            
                        <Link reloadDocument to={"/field_tech/plot_job"}>
                          <span className="mitem">
                            <span>Plot Jobs on Map</span>
                          </span>
                        </Link>
                    </li>
                    )}
                  </ul>
                </PerfectScrollbar>
                <ul className="btm-menu">
                  <li className="ant-menu-item opitem">
                    {" "}
                    <span className="mitem">
                      <figure className="user-status">
                        {user?.user?.photo ? (
                          <img
                            className="userimg"
                            src={user.user.photo}
                            alt=""
                          />
                        ) : (
                          <img className="userimg" src={userimg} alt="" />
                        )}
                        <small className="astatus"></small>
                      </figure>
                      <span className="ellipse">
                        {user && user.user.first_name}{" "}
                        {user && user.user.last_name}
                      </span>{" "}
                    </span>
                  </li>
                  <li className="ant-menu-item opitem">
                    {" "}
                    <Link
                      reloadDocument
                      to="/"
                      className="mr-2"
                      replace
                      onClick={() => {
                        authLogout({ key: accessToken });
                        localStorage.clear();
                        sessionStorage.clear();
                      }}
                    >
                      <span className="mitem">
                        <img src={logout} alt="" /> <span>Log Out</span>{" "}
                      </span>
                    </Link>{" "}
                  </li>
                  <li className="ant-menu-item opitem">
                    <span className="mitem" style={{ lineHeight: 1 }}>
                      <span style={{ opacity: 0.5, fontSize: 10 }}>1.12</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="btn_contact_support"
              onClick={() => openFreshWidget()}
            >
              <img
                src={support}
                alt=""
                className="support_btn_icon"
              />
              <span className="support_btn_text">Support</span>
            </button>
          </>
        );
      default:
        return <></>;
    }
  };

  return selectSideBar();
};

export default Sidebar;
