import React from "react";
import { useParams } from "react-router-dom";
import TerritoryExtraSidebar from "./Territory.ExtraSidebar";
import InvoiceReconciliationExtraSidebar from "./InvoiceReconciliation.ExtraSidebar";
import AgingSummaryExtraSidebar from "./AgingSummary.ExtraSidebar";

const ExtraSidebarContent = () => {
  const { id } = useParams();

  const selectSidebar = (id) => {
    switch (id) {
      case "in_territory_revenue_reports": {
        return <TerritoryExtraSidebar />;
      }
      case "invoice_reconciliation_reports": {
        return <InvoiceReconciliationExtraSidebar />;
      }
      case "aging_summary_reports": {
        return <AgingSummaryExtraSidebar />;
      }
      default: {
        return;
      }
    }
  };
  return <>{selectSidebar(id)}</>;
};

export default ExtraSidebarContent;
