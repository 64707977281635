import React, {
  Component,
  useEffect,
  useState,
  useRef,
  forwardRef,
  createRef,
} from "react";
import { map, isEmpty } from "lodash";
import Axios from "axios";
import { Select, TimePicker, Upload, message, Divider } from "antd";
import moment from "moment-timezone";
import * as yup from "yup";
import Script from "react-load-script";
import { errorHandle } from "../Components/errorHandle";
import { updateEditedUSer, getUserEditDetail, getGroupList } from "../Action/User";
import { Switch, Modal } from "antd";
import { Checkbox } from "antd";
import AddressField from "../Components/AddressField";
import { ExclamationCircleFilled } from '@ant-design/icons';

const { Option } = Select;

const EditUser = forwardRef((props, refs) => {
  const [ispulseErrorOpen, setPulseIsErrorOpen] = useState(false);
  const [togglepre, setTogglepre] = useState(false);
  const [togglepost, setTogglepost] = useState(false);
  const [pulsebioemail, setPulsebioemail] = useState(false);
  const [pulsebiosms, setPulsebiosms] = useState(false);
  const [pulsecheckmail, setPulsecheckmail] = useState(false);
  const [pulsechecksms, setPulsechecksms] = useState(false);
  const [alsoTech, setAlsoTech] = useState(false);
  const [pulseError, setPulseError] = useState({});
  const [group_list, setGroup_list] = useState([]);
  const [state, setState] = useState({
    google_service_address: "",
    skillsetList: [],
    permissionset: [],
    hours_of_work: [
      {
        key: "Sunday",
        day: "sun",
        start_time: "",
        end_time: "",
        enable: false,
      },
      {
        key: "Monday",
        day: "mon",
        start_time: "",
        end_time: "",
        enable: false,
      },
      {
        key: "Tuesday",
        day: "tues",
        start_time: "",
        end_time: "",
        enable: false,
      },
      {
        key: "Wednesday",
        day: "wed",
        start_time: "",
        end_time: "",
        enable: false,
      },
      {
        key: "Thursday",
        day: "thurs",
        start_time: "",
        end_time: "",
        enable: false,
      },
      {
        key: "Friday",
        day: "fri",
        start_time: "",
        end_time: "",
        enable: false,
      },
      {
        key: "Saturday",
        day: "sat",
        start_time: "",
        end_time: "",
        enable: false,
      },
    ],
    group: { uuid: "", name: "" },
    user: {
      first_name: "",
      last_name: "",
      email: "",
      franchise_uuid: "",
      is_active: true,
      photo: "",
      description: "",
    },
    phone_numbers: {
      uuid: "",
      phone: "",
      type: "mobile",
      can_send_sms: true,
      primary: true,
    },
    skillset: [],
    start_location: { address: "", city: "", state: "", zip_code: "" },
    stop_location: { address: "", city: "", state: "", zip_code: "" },
    is_new_img: false,
    errors: {},
    authUserFranchiseID: "",
    authUserRole: null,
    authUserUuid: null,
  });
  const start_location_ref = useRef();
  const stop_location_ref = useRef();

  const [start_locationText, setStart_locationText] = useState('');
  const [stop_locationText, setStop_locationText] = useState('');
  const [showInActiveModal, setShowInActiveModal] = useState(false);


  function getState() {
    setState({
      google_service_address: "",
      // roles: [
      //   { key: 'admin', value: 'Admin' },
      //   { key: 'service_rep', value: 'Service Representative' },
      //   { key: 'franchise_owner', value: 'Franchise Owner' },
      //   { key: 'field_tech', value: 'Field Tech' },
      //   { key: 'operations_mgr', value: 'Operations Manager' },
      //   // { key: 'customer', value: 'Customer' },
      //   // { key: 'lead', value: 'Lead' },
      //   // { key: 'commercial_customer', value: 'Commercial Customer' },
      //   // { key: 'commercial_lead', value: 'Commercial Lead' },
      // ],
      skillsetList: [
        // { key: 'maintenance', value: 'Maintenance' },
        // { key: 'repair_service', value: 'Repair Service' },
        // { key: 'renovation', value: 'Renovation' },
        // { key: 'construction', value: 'Construction' },
        // { key: 'estimator', value: 'Estimator' },
      ],
      hours_of_work: [
        {
          key: "Sunday",
          day: "sun",
          start_time: "",
          end_time: "",
          enable: false,
        },
        {
          key: "Monday",
          day: "mon",
          start_time: "",
          end_time: "",
          enable: false,
        },
        {
          key: "Tuesday",
          day: "tues",
          start_time: "",
          end_time: "",
          enable: false,
        },
        {
          key: "Wednesday",
          day: "wed",
          start_time: "",
          end_time: "",
          enable: false,
        },
        {
          key: "Thursday",
          day: "thurs",
          start_time: "",
          end_time: "",
          enable: false,
        },
        {
          key: "Friday",
          day: "fri",
          start_time: "",
          end_time: "",
          enable: false,
        },
        {
          key: "Saturday",
          day: "sat",
          start_time: "",
          end_time: "",
          enable: false,
        },
      ],
      group: { uuid: "", name: "" },
      user: {
        first_name: "",
        last_name: "",
        email: "",
        franchise_uuid: "",
        is_active: true,
        photo: "",
        description: "",
      },
      phone_numbers: {
        uuid: "",
        phone: "",
        type: "mobile",
        can_send_sms: true,
        primary: true,
      },
      skillset: [],
      is_new_img: false,
      errors: {},
      authUserFranchiseID: "",
      authUserRole: null,
      authUserUuid: null,
    });
  }

  const removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== null && v?.trim() !== "")
    );
  };

  const resetFields = async () => {
    setPulsebioemail(false);
    setPulsebiosms(false);
    setPulsecheckmail(false);
    setPulsechecksms(false);
    setPulseError({});
    setTogglepre(false);
    setTogglepost(false);
    setState((state) => ({ ...state, errors: {} }));
    props.userEditDrawerToggle(false);
  }

  useEffect(() => {
    getState();
    props.getRoles();
    let user = JSON.parse(localStorage.getItem("authUser")) || null;
    let authUserRole = user?.user?.role?.[0];
    setState((state) => ({
      ...state,
      authUserFranchiseID: user?.user?.associated_field_offices?.[0]?.uuid,
      authUserRole: authUserRole,
      authUserUuid: user?.user?.uuid,
    }));
    setGroup_list(props.groupList);
  }, []);

  useEffect(() => {
    if (!isEmpty(props.User_details)) {
      let data_notification = { ...props.User_details.notifications };
      setPulseError({});
      if (data_notification?.bio_email || data_notification?.bio_sms) {
        setTogglepre(true);
      }
      if (data_notification?.check_email || data_notification?.check_sms) {
        setTogglepost(true);
      }
      setPulsebioemail(data_notification?.bio_email);
      setPulsebiosms(data_notification?.bio_sms);
      setPulsecheckmail(data_notification?.check_email);
      setPulsechecksms(data_notification?.check_sms);
      // if(["franchise_owner", "field_tech"].join().includes(props.User_details?.role.join())) {
      if ((props.User_details?.role.includes("franchise_owner") || props.User_details?.role.includes("operations_mgr")) && props.User_details?.role.includes("field_tech")) {
        setAlsoTech(true);
      } else {
        setAlsoTech(false);
      }

      //validation for blank spaces
      if (props?.User_details?.staff_profile?.start_location?.formatted_address === null || props?.User_details?.staff_profile?.start_location?.start === null || props?.User_details?.staff_profile?.start_location?.formatted_address === ', , . ') {
        setStart_locationText('');
      }
      else {
        setStart_locationText(props?.User_details?.staff_profile?.start_location?.formatted_address);
      }
      if (props?.User_details?.staff_profile?.stop_location?.formatted_address === null || props?.User_details?.staff_profile?.stop_location?.address === null || props?.User_details?.staff_profile?.stop_location?.formatted_address === ', , . ') {
        setStop_locationText('');
      }
      else {
        setStop_locationText(props?.User_details?.staff_profile?.stop_location?.formatted_address);
      }
    }
  }, [props.User_details]);

  useEffect(() => {
    if (state.user.franchise_uuid) {
      console.log('console on f change-->> ', state.user.franchise_uuid);
      if (
        user?.role?.includes("service_rep") ||
        !state.user?.franchise_uuid
      ) {
        getGroupListDataProps({
          offset: 0,
          limit: 15,
          is_public: true,
        })
      } else {
        getGroupListDataProps({ 'offset': 0, 'limit': 15, 'field_office__uuid': state?.user?.franchise_uuid });
      }
    }
  }, [state?.user?.franchise_uuid]);

  const getGroupListDataProps = async (param) => {
    const data = await getGroupList(param)
    setGroup_list(data?.results);
    setState((state) => ({ ...state, loader: false }));
  }

  useEffect(() => {
    if (
      Object.keys(Object.keys(props.User_details)).length > 0 &&
      props.User_details !== state.User_details
    ) {
      let {
        first_name,
        last_name,
        email,
        uuid,
        is_active,
        group,
        role,
        staff_profile,
        photo,
        description,
      } = props.User_details;
      setState((state) => ({ ...state, loader: false }));
      //set Phone numbers
      if (props.User_details.phone_numbers.length > 0) {
        map(props.User_details.phone_numbers, (item) => {
          if (item.primary) {
            setState((state) => ({
              ...state,
              phone_numbers: {
                uuid: item.uuid,
                phone: item.phone,
                type: item.type,
                can_send_sms: item.can_send_sms,
                primary: item.primary,
              },
            }));
          } else {
            setState((state) => ({
              ...state,
              phone_numbers: {
                uuid: item.uuid,
                phone: item.phone,
                type: item.type,
                can_send_sms: item.can_send_sms,
                primary: item.primary,
              },
            }));
          }
        });
      } else {
        setState((state) => ({
          ...state,
          phone_numbers: {
            uuid: "",
            phone: "",
            type: "mobile",
            can_send_sms: true,
            primary: true,
          },
        }));
      }

      if (group) {
        setState((state) => ({
          ...state,
          group,
        }));
      }

      let hours = [],
        hoursOfWork = props.User_details?.hours_of_work;
      map(hours_of_work, (stateHour) => {
        let index = hoursOfWork?.findIndex(
          (hour) => stateHour?.day === hour?.day
        );

        if (index > -1) {
          hours.push({
            ...stateHour,
            start_time: hoursOfWork[index].enable
              ? moment(hoursOfWork?.[index]?.start_time, "HH:mm:ss")
              : "",
            end_time: hoursOfWork[index].enable
              ? moment(hoursOfWork?.[index]?.end_time, "HH:mm:ss")
              : "",
            enable: hoursOfWork[index].enable,
          });
        } else {
          hours.push({
            ...stateHour,
            start_time: "",
            end_time: "",
            enable: false,
          });
        }
      });

      setState((state) => ({
        ...state,
        hours_of_work: hours,
      }));
      //set user and other data
      setState((state) => ({
        ...state,
        user: {
          first_name,
          last_name,
          email,
          uuid,
          is_active,
          role,
          description,
          photo: photo || "",
          franchise_uuid: staff_profile?.field_office?.uuid
            ? staff_profile?.field_office?.uuid
            : "",
        },
        skillset:
          staff_profile && staff_profile.skillset ? staff_profile.skillset : [],
        permissionset: staff_profile && staff_profile.permissionset ? staff_profile.permissionset : [],
        start_location: staff_profile?.start_location
          ? staff_profile.start_location
          : { address: "", city: "", state: "", zip_code: "" },
        stop_location: staff_profile?.stop_location
          ? staff_profile.stop_location
          : { address: "", city: "", state: "", zip_code: "" },
        staff_profile,
      }));


      // setTimeout(() => {
      //   start_location_ref.current.value = staff_profile?.start_location
      //     ? `${staff_profile.start_location.address}, ${staff_profile.start_location.city}, ${staff_profile.start_location.state}, ${staff_profile.start_location.zip_code}`
      //     : "";
      //   stop_location_ref.current.value = staff_profile?.stop_location
      //     ? `${staff_profile.stop_location.address}, ${staff_profile.stop_location.city}, ${staff_profile.stop_location.state}, ${staff_profile.stop_location.zip_code}`
      //     : "";
      // }, 100);
    }

  }, [props]);

  const formatChangePermission = () => {
    if (!isEmpty(state.staff_profile.permissionset)) {
      let obj = {
        delete_so: state.staff_profile.permissionset.includes('delete_so'),
        assign_wo: state.staff_profile.permissionset.includes('assign_wo'),
        create_wo: state.staff_profile.permissionset.includes('create_wo'),
        reschedule_wo: state.staff_profile.permissionset.includes('reschedule_wo'),
        see_calenders: state.staff_profile.permissionset.includes('see_calenders'),
        enable_price_catalog: state.staff_profile.permissionset.includes('enable_price_catalog'),
      }
      return obj;
    }
  }

  // get logged in user
  const getLoggedInUserRole = () => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    const logged_in_user_role = authUser?.user?.user_roles?.map((x) => x.name);

    const is_franchise_owner = logged_in_user_role?.includes("franchise_owner");
    // const is_account_corporate = logged_in_user_role?.includes('accounting_corporate')
    const is_business_dev_franchiese = logged_in_user_role?.includes(
      "business_dev_franchiese"
    );
    const is_operations_mgr = logged_in_user_role?.includes("operations_mgr");
    const is_field_tech = logged_in_user_role?.includes("field_tech");
    const franchise_uuid = authUser?.user?.field_offices?.map((x) => x.uuid);
    return {
      franchise_uuid: franchise_uuid?.[0],
      is_disable_franchise:
        is_franchise_owner ||
        is_business_dev_franchiese ||
        is_operations_mgr ||
        is_field_tech,
    };
  };

  //Group Table Pagination
  const groupHandleTableChange = (pagination, filters, sorter) => {
    let group_list_pagination = this.state.group_list_pagination;
    let ordering = "";
    group_list_pagination = Object.assign(group_list_pagination, {
      current: pagination.current,
    });

    if (Object.keys(sorter).length > 0) {
      ordering =
        sorter.order === "ascend" ? `${sorter.field}+` : `${sorter.field}-`;
    }

    setState((state) => ({
      ...state,
      group_list_pagination,
      loader: true,
    }));
    props.getUserList({
      offset: pagination.current * 10 - 10,
      limit: 10,
      ordering,
    });
  };

  //User detil setup
  const onUserDetailChange = (e) => {
    let { user } = state;
    user = Object.assign(user, { [e.target.name]: e.target.value });
    setState((state) => ({
      ...state,
      user,
    }));
  };

  //Working hours change
  const onWorkingHoursChange = (e, hour, index) => {
    let { hours_of_work } = state;

    if (e.target.checked) {
      hours_of_work[index].enable = e.target.checked;
      hours_of_work[index].start_time = moment("09:00 AM", "HH:mm A");
      hours_of_work[index].end_time = moment("05:00 PM", "HH:mm A");
    } else {
      hours_of_work[index].enable = e.target.checked;
      hours_of_work[index].start_time = "";
      hours_of_work[index].end_time = "";
    }
    setState((state) => ({
      ...state,
      hours_of_work,
    }));
  };

  // on Working Time Change
  const onTimeChange = (e, type, hour) => {
    let { hours_of_work } = state;

    let index = hours_of_work.findIndex((x) => x.key === hour.key);
    hours_of_work[index][type] = e;
    setState((state) => ({
      ...state,
      hours_of_work,
    }));
  };
  const onprepulsetoggle = (checked) => {
    if (!checked) {
      setPulsebioemail(false);
      setPulsebiosms(false);
    }
    setTogglepre(checked);
  };
  const onpostpulsetoggle = (checked) => {
    if (!checked) {
      setPulsecheckmail(false);
      setPulsechecksms(false);
    }
    setTogglepost(checked);
  };
  const onpulspreemailcheck = (e) => {
    setPulsebioemail(e.target.checked);
  };
  const onpulsepresmscheck = (e) => {
    setPulsebiosms(e.target.checked);
  };
  const onpulsepostmailcheck = (e) => {
    setPulsecheckmail(e.target.checked);
  };
  const onpulsepostsmscheck = (e) => {
    setPulsechecksms(e.target.checked);
  };
  const onnotificationupdate = () => setPulseIsErrorOpen(false);

  //User Form Validation
  const validateUser = async () => {
    let {
      hours_of_work,
      user,
      phone_numbers,
      group,
      skillset,
      start_location,
      stop_location,
    } = state;
    const UserSchema = yup.object().shape({
      user: yup.object().shape({
        first_name: yup.string().required("First name is required"),
        last_name: yup.string().required("Last name is required"),
        email: yup
          .string()
          .required("Last name is required")
          .email("Your Email is invalid."),
      }),
      phone_numbers: yup.object().shape({
        phone: yup.string().required('Please enter a valid Phone Number.')
      }),
      group: yup.object().shape({
        name: yup.string().required("Group name is required"),
      }),
    });
    const UserData = {
      user,
      phone_numbers,
      group,
      hours_of_work,
      skillset,
      start_location,
      stop_location,
      // array_bio,
      // array_check
    };

    // check validity
    setTimeout(() => {
      UserSchema.validate(UserData, { abortEarly: false })
        .then(() => {
          const addressErrors = {};
          let hours = [];
          if (user?.role?.includes("field_tech")) {
            map(hours_of_work, (x) => {
              if (x.enable) {
                if (x.start_time && x.end_time) hours.push(x);
              }
            });
          }
          if (user?.role?.includes("field_tech") && hours?.length === 0) {
            setState((state) => ({
              ...state,
              errors: { hours_of_work: "Hours of work is required" },
            }));
          } else if (Object.keys(addressErrors).length > 0) {
            setState((state) => ({ ...state, errors: addressErrors }));
          } else if (user?.is_active === false) {
            setState((state) => ({ ...state, errors: {} }));
            setShowInActiveModal(true)
          } else {
            setState((state) => ({ ...state, errors: {} }));
            updateUSer();
          }
        })
        .catch((err) => {
          let err_data = {};
          map(err.inner, (item) => {
            err_data[item.path] = item.message;
          });
          console.log("err_data", err_data);
          setState((state) => ({ ...state, errors: err_data }));
        });
    }, 100);
  };

  // user photo selection
  const userPhotoUpload = (info) => {
    let { user } = state;
    const reader = new FileReader();
    reader.onloadend = function () {
      user = Object.assign(user, { photo: reader.result });
      setState((state) => ({ ...state, user, is_new_img: true }));
    };
    reader.readAsDataURL(info.file);
  };

  // convert images to base64
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  // Render Skill Set
  const renderSkillSet = () => {
    let { skillset } = state;
    let { skillsetList } = props;
    return map(skillsetList, (item, i) => {
      return (
        <div className="fitem-check" key={i}>
          <input
            type="checkbox"
            className="fitem-ck-input"
            id={`skill${i}`}
            onChange={(e) => {
              onSkillSetChange(e, item);
            }}
            checked={skillset.includes(item.skill_name)}
            name="checkboxs"
          />
          <label htmlFor={`skill${i}`} className="fitem-ck-txt">
            {item.skill_display_name}
          </label>
        </div>
      );
    });
  };

  // Render Skill Set
  const renderPermissionSet = () => {
    let { permissionset } = state;
    let { permissionList } = props;
    return map(permissionList, (item, i) => {
      return (
        <div className="fitem-check" key={i}>
          <input
            type="checkbox"
            className="fitem-ck-input"
            id={`add_per${i}`}
            onChange={(e) => { onAddPermissionSetChange(e, item); }}
            checked={permissionset.includes(item.permission_name)}
            disabled={false}
            name="checkboxs" />
          <label htmlFor={`add_per${i}`} className="fitem-ck-txt">{item.permission_display_name}</label>
        </div>
      )
    })
  }

  //Set skill set value
  const onSkillSetChange = (e, item) => {
    let { skillset } = state;
    if (e.target.checked) {
      skillset.push(item.skill_name);
    } else {
      const index = skillset.indexOf(item.skill_name);
      if (index > -1) {
        skillset.splice(index, 1);
      }
    }
    setState((state) => ({ ...state, skillset }));
  };

  //Set skill set value
  const onAddPermissionSetChange = (e, item) => {
    let { permissionset } = state;
    if (e.target.checked) {
      permissionset.push(item.permission_name);
    }
    else {
      const index = permissionset.indexOf(item.permission_name);
      if (index > -1) {
        permissionset.splice(index, 1);
      }
    }
    setState((state) => ({ ...state, permissionset }))
  }

  // Google Map Place API
  const handleScriptLoad = () => {
    setState((state) => ({
      ...state,
      loader: true,
    }));
    // Declare Options For Autocomplete
    const options = { componentRestrictions: { country: "us" } };
    //////////////////////Start Location////////////////////////
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const startLocationAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_start_address"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    startLocationAutoComplete.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    startLocationAutoComplete.addListener("place_changed", startLocationChange);

    setState((state) => ({
      ...state,
      startAutocomplete: startLocationAutoComplete,
    }));
    //////////////////////Stop Location////////////////////////
    const stopLocationAutoComplete = new google.maps.places.Autocomplete(
      document.getElementById("google_stop_address"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    stopLocationAutoComplete.setFields([
      "address_components",
      "formatted_address",
    ]);
    // Fire Event when a suggested name is selected
    stopLocationAutoComplete.addListener("place_changed", stopLocationChange);
    setState((state) => ({
      ...state,
      stopAutocomplete: stopLocationAutoComplete,
    }));
    setState((state) => ({
      ...state,
      loader: false,
    }));
  };

  //Start Address change
  const startLocationChange = () => {
    const address = document?.getElementById("google_start_address")?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      }),
    })
      .then((res) => {
        const addressObject = res?.data?.results[0];
        const address = addressObject.address_components;
        let obj = {};
        if (address) {
          map(addressObject?.address_components, (item) => {
            if (item?.types?.includes("administrative_area_level_2")) {
              obj = Object.assign(obj, { address: item.short_name });
            }
            if (item?.types?.includes("route")) {
              obj = Object.assign(obj, { route: item.long_name });
            }
            if (item?.types?.includes("street_number")) {
              obj = Object.assign(obj, { street_number: item.short_name });
            }
            if (item?.types?.includes("administrative_area_level_1")) {
              obj = Object.assign(obj, { state: item.short_name });
            }
            if (item?.types?.includes("locality")) {
              obj = Object.assign(obj, { city: item.long_name });
            }
            if (item?.types?.includes("postal_code")) {
              obj = Object.assign(obj, { zip_code: item.short_name });
            }
          });
          let resAdd = {};
          resAdd["latitude"] = addressObject?.geometry?.location?.lat;
          resAdd["longitude"] = addressObject?.geometry?.location.lng;
          obj.geo_location = resAdd;
          setState((state) => ({ ...state, start_location: obj }));
          if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
            message.error("Please enter valid start location");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Stop Address change
  const stopLocationChange = () => {
    const address = document?.getElementById("google_stop_address")?.value;
    Axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
      params: removeEmpty({
        latlng: undefined,
        address,
        key: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
      }),
    })
      .then((res) => {
        const addressObject = res?.data?.results[0];
        const address = addressObject.address_components;
        let obj = {};
        if (address) {
          map(addressObject?.address_components, (item) => {
            if (item?.types?.includes("administrative_area_level_2")) {
              obj = Object.assign(obj, { address: item.short_name });
            }
            if (item?.types?.includes("route")) {
              obj = Object.assign(obj, { route: item.long_name });
            }
            if (item?.types?.includes("street_number")) {
              obj = Object.assign(obj, { street_number: item.short_name });
            }
            if (item?.types?.includes("administrative_area_level_1")) {
              obj = Object.assign(obj, { state: item.short_name });
            }
            if (item?.types?.includes("locality")) {
              obj = Object.assign(obj, { city: item.long_name });
            }
            if (item?.types?.includes("postal_code")) {
              obj = Object.assign(obj, { zip_code: item.short_name });
            }
          });
          let resAdd = {};
          resAdd["latitude"] = addressObject?.geometry?.location?.lat;
          resAdd["longitude"] = addressObject?.geometry?.location.lng;
          obj.geo_location = resAdd;
          setState((state) => ({ ...state, stop_location: obj }));
          if (!obj?.address || !obj?.state || !obj.city || !obj.zip_code) {
            message.error("Please enter valid start location");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Add New User
  const updateUSer = () => {

    let {
      user,
      hours_of_work,
      group,
      phone_numbers,
      skillset,
      permissionset,
      start_location,
      stop_location,
      is_new_img,
    } = state;
    let temp_hours_of_work = [];

    // set hours of work
    map(hours_of_work, (item, i) => {
      if (hours_of_work[i]["start_time"] && hours_of_work[i]["end_time"]) {
        temp_hours_of_work.push({
          key: hours_of_work[i]["key"],
          day: hours_of_work[i]["day"],
          start_time: moment(hours_of_work[i]["start_time"]).format("HH:mm:ss"),
          end_time: moment(hours_of_work[i]["end_time"]).format("HH:mm:ss"),
          enable: true,
        });
      } else {
        temp_hours_of_work.push({
          key: hours_of_work[i]["key"],
          day: hours_of_work[i]["day"],
          start_time: null,
          end_time: null,
          enable: false,
        });
      }
    });

    if (!is_new_img) {
      delete user?.photo;
    }
    let staff_profile = {
      skillset: skillset?.length > 0 ? skillset : null,
      //
      permissionset: formatChangePermission() || null,
      start_location:
        start_location?.street_number && start_location?.route
          ? {
            ...start_location,
            address: `${start_location?.street_number} ${start_location?.route}`,
          }
          : start_location,
      stop_location:
        stop_location?.street_number && stop_location?.route
          ? {
            ...stop_location,
            address: `${stop_location?.street_number} ${stop_location?.route}`,
          }
          : stop_location,
    };

    if (staff_profile.start_location.uuid) {
      staff_profile.start_location = {};
    }
    if (staff_profile.stop_location.uuid) {
      staff_profile.stop_location = {};
    }

    // stop_location.uuid ? stop_location = '' : stop_location;
    let temp_phone_numbers = [];
    let data;
    if (phone_numbers.phone) {
      temp_phone_numbers.push(phone_numbers);
    }

    map(temp_phone_numbers, (x) => {
      if (!x.uuid) delete x?.uuid;
    });

    user.email = user?.email?.toLowerCase();

    data = {
      ...user,
      hours_of_work: temp_hours_of_work,
      phone_numbers: temp_phone_numbers,
      group,
      staff_profile: {...staff_profile, start_location: state.start_location, stop_location: state.stop_location },
    };
    setState((state) => ({
      ...state,
      loader: true,
    }));

    if (data?.franchise_uuid === "") {
      const { franchise_uuid, is_disable_franchise } = getLoggedInUserRole();
      if (is_disable_franchise) Object.assign(data, { franchise_uuid });
      else delete data.franchise_uuid;
    }
    const updateUserDetails = async () => {
      if (data?.role.includes('franchise_owner') && !data?.role.includes('field_tech') && alsoTech) {
        data.role.push("field_tech");
      }
      if (alsoTech) {
        data.is_also_technician = true;
      } else {
        data.is_also_technician = false;
      }
      const res = await updateEditedUSer(data, user?.uuid);
      if (res.uuid) {
        // props.userEditDrawerToggle(false);
        await resetFields();
        message.success("Updated Successfully");
        let user = JSON.parse(localStorage.getItem("authUser")) || null;
        let temp_user = user?.user || null;

        if (data?.uuid === temp_user?.uuid) {
          temp_user = Object.assign(temp_user, { ...props?.updateUser?.data });
          user = Object.assign(user, { user: temp_user });
          localStorage.setItem("authUser", JSON.stringify(user));
        }

        setState((state) => ({
          ...state,
          group: { uuid: "", name: "" },
          user: {
            first_name: "",
            last_name: "",
            email: "",
            franchise_uuid: "",
            is_active: true,
            photo: "",
            description: "",
          },
          phone_numbers: {
            uuid: "",
            phone: "",
            type: "mobile",
            can_send_sms: true,
            primary: true,
          },
          skillset: [],
          start_stop_location: {
            address: "",
            city: "",
            state: "",
            zip_code: "",
          },
          is_new_img: false,
          loader: false,
        }));
        props.handleEditSuccess();
      }
    };
    data.notifications = {
      bio_sms: pulsebiosms,
      bio_email: pulsebioemail,
      check_sms: pulsechecksms,
      check_email: pulsecheckmail,
      type: "field_technician",
    };
    if (togglepre && togglepost) {
      if ((pulsebiosms || pulsebioemail) && (pulsechecksms || pulsecheckmail)) {
        setPulseError({});
        updateUserDetails();
      } else {
        setState((state) => ({
          ...state,
          loader: false,
        }));
        if (!(pulsebiosms || pulsebioemail) && !(pulsechecksms || pulsecheckmail)) {
          setPulseError({
            bio: { message: "Please select a preferred method" },
            check: { message: "Please select a preferred method" }
          });
        } else if (!(pulsechecksms || pulsecheckmail)) {
          setPulseError({
            check: { message: "Please select a preferred method" }
          });
        } else {
          setPulseError({
            bio: { message: "Please select a preferred method" },
          });
        }
        // setPulseIsErrorOpen(true);
      }
    } else {
      if ((togglepre && (pulsebiosms || pulsebioemail)) || (togglepost && (pulsechecksms || pulsecheckmail)) || (!togglepre && !togglepost)) {
        setPulseError({});
        updateUserDetails();
      } else {
        setState((state) => ({
          ...state,
          loader: false,
        }));
        if (togglepre) {
          setPulseError({
            bio: { message: "Please select a preferred method" },
          });
        } else {
          setPulseError({
            check: { message: "Please select a preferred method" }
          });
        }
        // setPulseIsErrorOpen(true);
      }
    }

  };

  const onAddressChange = (e, addr_ref) => {
    const lState = {...state};
    lState.start_location = {...lState.start_location, [e.target.name]: e.target.value };
    setState(lState);
  };

  const handleAddress = (e) => {
    setState({...state, start_location: e.target.value})
  }

  const onStopAddressChange = (e, addr_ref) => {
    const lState = {...state};
    lState.stop_location = {...lState.stop_location, [e.target.name]: e.target.value };
    setState(lState);
  };
  
  // render specific setting
  const renderSpecificSetting = () => {
    const { errors } = state;
    return (
      <div className="specific-fitem">
        <h6>Field Tech Specific Settings</h6>
        <div className="fitem">
          <label htmlFor="">Start Location</label>
          <AddressField 
            nolabel 
            handleAddressChange={(add) => {
                setState({...state, start_location: add })
            }} 
            onAddressChanged={onAddressChange} 
            onAddressChange={onAddressChange} 
            onChange={(e) => handleAddress(e)} 
            errors={{ address: { address: errors['start_location.address'] }}} 
            data={state.start_location} 
          />
        </div>

        <div className="fitem">
          <label htmlFor="">Stop Location</label>
          <AddressField 
            fieldName="stop_location"
            nolabel 
            handleAddressChange={(add) => {
              setState({...state, stop_location: add })
            }} 
            onAddressChanged={onStopAddressChange} 
            onAddressChange={onStopAddressChange} 
            onChange={(e) => setState({...state, stop_location: e.target.value})} 
            errors={errors} data={state.stop_location} 
          />
        </div>

        <div className="fitem inline-check-list gcheck mt-20" style={{ display: 'flex' }}>
          {/* Render Skill */}
          <div>
            <h5>Skills</h5>
            {renderSkillSet()}
          </div>
          <Divider type="vertical" style={{ height: '15em', borderLeft: '2px solid rgba(0, 0, 0, 0.06)' }} />
          <div>
            <h5>Permissions</h5>
            {renderPermissionSet()}
          </div>
          {errors["skillset"] ? (
            <label className="new_err_msg">
              <i className="las la-info-circle"></i>
              {errors["skillset"]}
            </label>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  // render specific setting FO
  const renderSpecificSettingFO = () => {
    const { errors } = state;
    return (
      <div className="specific-fitem" style={{ marginTop: '8px' }}>
        <h6>Field Tech Specific Settings</h6>
        <div className="fitem">
          <label htmlFor="">Start Location</label>
          <AddressField 
            nolabel 
            handleAddressChange={(add) => {
                setState({...state, start_location: add })
            }} 
            onAddressChanged={onAddressChange} 
            onAddressChange={onAddressChange} 
            onChange={(e) => handleAddress(e)} 
            errors={errors} 
            data={state.start_location} 
          />
        </div>

        <div className="fitem">
          <label htmlFor="">Stop Location</label>
          <AddressField 
            fieldName="stop_location" 
            nolabel 
            handleAddressChange={(add) => {
              setState({...state, stop_location: add })
            }} 
            onAddressChanged={onStopAddressChange} 
            onAddressChange={onStopAddressChange} 
            onChange={(e) => setState({...state, stop_location: e.target.value})} 
            errors={errors} data={state.stop_location} 
          />
        </div>
        <div className="fitem inline-check-list gcheck mt-10">
          <h5>Skills</h5>
          {renderSkillSet()}
          {errors['skillset'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['skillset']}</label> : ''}
        </div>
      </div>
    )
  };

  const onChangeAlsoTech = (e) => {
    setAlsoTech(e.target.checked);
  }

  let { loader, user, phone_numbers, errors, hours_of_work, group, roles } =
    state;
  let { franchiseList, groupList, roleList, skillsList } = props;
  const { is_disable_franchise } = getLoggedInUserRole();
  const grp = group_list?.filter((item) => item.uuid === group?.uuid);
  return (
    <React.Fragment>
      <div className="add_user_wrapper rdrawer">
        <div className="card-row">
          <div className="card-half left-sec">
            <h4>Edit User</h4>
            <div className="form-group">
              <div className="fitem">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={user?.first_name || ""}
                  onChange={(e) => {
                    onUserDetailChange(e);
                  }}
                />
                {errors["user.first_name"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["user.first_name"]}
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div className="fitem">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={user?.last_name}
                  onChange={(e) => {
                    onUserDetailChange(e);
                  }}
                />
                {errors["user.last_name"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["user.last_name"]}
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div className="fitem">
                <label htmlFor="">Phone number</label>
                <div className={phone_numbers.phone ? "fix_isd_code" : ""}>
                  {phone_numbers.phone ? <span></span> : ""}
                  <input
                    style={{
                      marginLeft: phone_numbers?.phone ? "10px" : "0px",
                    }}
                    type="text"
                    placeholder="Phone number"
                    name="phone_numbers"
                    value={phone_numbers?.phone?.replace(/\D/g, '') || ''}
                    inputMode="numeric"
                    max="10"
                    maxLength="10"
                    onPaste={(event) => {
                      if (event.clipboardData.getData("Text").match(/[^\d]/)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyPress={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        // invalid character, prevent input
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      let { phone_numbers } = state;
                      phone_numbers = Object.assign(phone_numbers, {
                        phone: e.target.value,
                      });
                      setState((state) => ({
                        ...state,
                        phone_numbers,
                      }));
                    }}
                  />
                  {errors["phone_numbers.phone"] ? (
                    <label className="new_err_msg">
                      <i className="las la-info-circle"></i>
                      {errors["phone_numbers.phone"]}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="fitem">
                <label htmlFor="">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={user?.email}
                  onChange={(e) => {
                    onUserDetailChange(e);
                  }}
                />
                {errors["user.email"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["user.email"]}
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div className="fitem">
                <label htmlFor="">Franchise</label>
                <Select
                  value={user?.franchise_uuid || undefined}
                  style={{ width: "100%" }}
                  placeholder="Franchise"
                  onChange={(e) => {
                    let { user } = state;
                    user = Object.assign(user, { franchise_uuid: e });
                    setState((state) => ({ ...state, user }));
                    setState((state) => ({
                      ...state,
                      loader: true,
                      group: { uuid: "", name: "" },
                    }));
                  }}
                  disabled={state.authUserRole === "admin" ? false : true}
                >
                  {map(franchiseList, (item, i) => {
                    return (
                      <Option key={i} value={item.uuid}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>

              <div className="fitem">
                <label htmlFor="">Group</label>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Please Select"
                  value={group.name || undefined}
                  // eslint-disable-next-line
                  onChange={({ }, e) => {
                    setState((state) => ({
                      ...state,
                      group: { uuid: e.value, name: e.children },
                    }));
                  }}
                >
                  {group_list
                    .filter((item) => item.status === "active")
                    .map((item, i) => {
                      return (
                        <Option key={i} value={item.uuid}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors["group.name"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["group.name"]}
                  </label>
                ) : (
                  ""
                )}
              </div>

              <div className="fitem">
                <label htmlFor="">Roles</label>
                <Select
                  value={user?.role?.length > 1 ? 'franchise_owner' : user?.role}
                  style={{ width: "100%" }}
                  placeholder="Roles"
                  onChange={(e) => {
                    let { user } = state;
                    let role = [];
                    role.push(e)
                    debugger
                    user = Object.assign(user, { 'role': role });
                    setState((state) => ({ ...state, user }));
                    if (!user.role?.includes('franchise_owner') && !user.role?.includes('operations_mgr') ) {
                      setAlsoTech(false);
                    }
                    // if (user.role?.includes('service_rep') || !state.user?.franchise_uuid) {
                    //   props.getGroupList({ 'offset': 0, 'limit': 15, 'is_public': true });
                    //   setState((state) => ({
                    //     ...state,
                    //     group: {}
                    //   }))
                    // } else {
                    //   props.getGroupList({ 'offset': 0, 'limit': 15, field_office__uuid: state.user?.franchise_uuid || '' });
                    // }
                  }}
                  disabled={
                    state.authUserUuid === user?.uuid ||
                      state.authUserRole === "field_tech" ||
                      state.authUserRole === "service_rep"
                      ? true
                      : false
                  }
                >
                  {map(roleList, (item, i) => {
                    return (
                      <Option key={i} value={item.name}>
                        {item.display_name}
                      </Option>
                    );
                  })}
                </Select>
                {errors["user.role"] ? (
                  <label className="new_err_msg">
                    <i className="las la-info-circle"></i>
                    {errors["user.role"]}
                  </label>
                ) : (
                  ""
                )}
              </div>
              {(grp?.[0]?.name?.toLowerCase()?.includes("field") || user?.role?.[0]?.includes("field")) && renderSpecificSetting()}
              {(grp?.[0]?.name?.toLowerCase()?.includes("franchise_owner") || user?.role?.[0]?.includes("franchise_owner")) ||
               (grp?.[0]?.name?.toLowerCase()?.includes("operations_mgr") || user?.role?.[0]?.includes("operations_mgr")) &&
                <Checkbox onChange={onChangeAlsoTech} checked={alsoTech}>Also a Technician</Checkbox>
              }
              {alsoTech && renderSpecificSettingFO()}
              {/* <div className="specific-fitem">
                  <Script
                    url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_API_KEY}&libraries=places`}
                    onLoad={this.handleScriptLoad}

                  />
                  <h6>Field Tech Specific Settings</h6>
                  <div className="fitem">
                    <label htmlFor="">Start Location</label>
                    <input
                      autoComplete="off"
                      type="text"
                      ref="start_location"
                      onChange={() => { this.startLocationChange() }}
                      className={'form-control'}
                      name="google_start_address"
                      id="google_start_address"
                    />
                    {errors['start_location.address'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['start_location.address']}</label> : ''}
                  </div>

                  <div className="fitem">
                    <label htmlFor="">Stop Location</label>
                    <input
                      autoComplete="off"
                      type="text"
                      ref="stop_location"
                      onChange={() => { this.stopLocationChange() }}
                      className={'form-control'}
                      name="google_stop_address"
                      id="google_stop_address"
                    />
                    {errors['stop_location.address'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['stop_location.address']}</label> : ''}
                  </div>


                  <div className="fitem inline-check-list gcheck mt-20">
                    Render Skill
                    {this.renderSkillSet()}
                    {errors['skillset'] ? <label className="new_err_msg"><i className="las la-info-circle"></i>{errors['skillset']}</label> : ''}
                  </div>

                </div> */}
            </div>
            <div className="hours-work-wrap mt-20">
              <h5>Hours of work</h5>
              {map(hours_of_work, (item, i) => {
                return (
                  <div className="fitem inline-check-list gcheck" key={i}>
                    <div className="fitem-check flex mb-10 ai-center">
                      <div className="hours-week">
                        <input
                          className="fitem-ck-input"
                          type="checkbox"
                          id={i}
                          checked={hours_of_work[i].enable}
                          onChange={(e) => {
                            onWorkingHoursChange(e, hours_of_work[i], i);
                          }}
                        />
                        <label htmlFor={i} className="fitem-ck-txt mb-0">
                          {item.key}
                        </label>
                      </div>
                      <div className="flex">
                        <div className="fitem">
                          <TimePicker
                            format="hh:mm A"
                            minuteStep={15}
                            placeholder="From"
                            // ref={`start_time${i}`}
                            disabled={!hours_of_work[i]["enable"]}
                            value={
                              hours_of_work[i].start_time === ""
                                ? null
                                : hours_of_work[i].start_time
                            }
                            suffixIcon={<i className="las la-angle-down"></i>}
                            allowClear={false}
                            onChange={(e) => {
                              onTimeChange(e, "start_time", hours_of_work[i]);
                            }}
                          />
                        </div>
                        <div className="fitem">
                          <TimePicker
                            placeholder="To"
                            format="hh:mm A"
                            minuteStep={15}
                            allowClear={false}
                            suffixIcon={<i className="las la-angle-down"></i>}
                            disabled={!hours_of_work[i]["enable"]}
                            value={
                              hours_of_work[i].end_time === ""
                                ? null
                                : hours_of_work[i].end_time
                            }
                            onChange={(e) => {
                              onTimeChange(e, "end_time", hours_of_work[i]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                );
              })}
              {errors?.["hours_of_work"] ? (
                <label className="new_err_msg">
                  <i className="las la-info-circle" />
                  {errors?.["hours_of_work"]}
                </label>
              ) : (
                ""
              )}
            </div>

            <div className="fitem">
              <label htmlFor="">Status</label>
              <Select
                value={user?.is_active}
                defaultValue="true"
                style={{ width: "100%" }}
                placeholder="Status"
                onChange={(e) => {
                  let { user } = state;
                  user = Object.assign(user, { is_active: e });
                  setState((state) => ({ ...state, user }));
                }}
              >
                <Option value={true}>Active</Option>
                <Option value={false}>InActive</Option>
              </Select>
            </div>
            {
              user?.role?.includes("field_tech") &&
              <div className="tecpulsenote">
                <label htmlFor="">
                  <h4>PulseM Notification</h4>
                </label>
                <div className="btn-pul-tog">
                  <div className="switch-text-content">
                    <Switch
                      className={togglepre ? 'switch-active' : 'switch-nonactive'}
                      size="medium"
                      onChange={onprepulsetoggle}
                      checked={togglepre}
                    />
                    <span className="btn-check-box">
                      <label htmlFor="">
                        <b>PulseM Pre Service Notification Bio</b>
                      </label>
                    </span>
                  </div>
                  {togglepre && (
                    <div className="linebtncss">
                      <span className="btn-switch-pul">
                        <Checkbox
                          checked={pulsebioemail}
                          onChange={(e) => onpulspreemailcheck(e)}
                        >
                          Email
                        </Checkbox>
                      </span>
                      {errors?.["pulsebioemail"] ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {errors?.["pulsebioemail"]}
                        </label>
                      ) : (
                        ""
                      )}
                      <span className="testclass">
                        <Checkbox
                          checked={pulsebiosms}
                          onChange={(e) => onpulsepresmscheck(e)}
                        >
                          SMS
                        </Checkbox>
                      </span>
                      {pulseError?.bio ? (
                        <label className="new_err_msg">
                          <i className="las la-info-circle" />
                          {pulseError?.bio["message"]}
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div className="checkcssgap">
                  <Switch
                    className={togglepost ? 'switch-active' : 'switch-nonactive'}
                    size="medium"
                    onChange={onpostpulsetoggle}
                    checked={togglepost}
                  />
                  <span className="btn-check-box">
                    <label htmlFor="">
                      <b>PulseM Post Service Notification Check</b>
                    </label>
                  </span>
                  {togglepost && (
                    <>
                      <div className="linebtncss">
                        <Checkbox
                          checked={pulsecheckmail}
                          onChange={(e) => onpulsepostmailcheck(e)}
                        >
                          Email
                        </Checkbox>
                        {errors?.["pulsecheckmail"] ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {errors?.["pulsecheckmail"]}
                          </label>
                        ) : (
                          ""
                        )}
                        <Checkbox
                          checked={pulsechecksms}
                          onChange={(e) => onpulsepostsmscheck(e)}
                        >
                          SMS
                        </Checkbox>
                        {pulseError?.check ? (
                          <label className="new_err_msg">
                            <i className="las la-info-circle" />
                            {pulseError?.check["message"]}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            }

          </div>

          <div className="img_upload right-sec card-half">
            <h4>User’s Bio</h4>
            <blockquote className="horiz">
              <b>The customer will see this information about you!</b>
              <p>
                {" "}
                As a field tech, customers will see who has serviced their
                property; and as a customer care rep, when you reply to tickets,
                customers will know who they are talking to!
              </p>
            </blockquote>

            <div className="file_upload_section flex ai-center flex-start">
              {user?.photo ? (
                <div className="uploded_img">
                  <img src={user?.photo} alt="" className="user_img" />
                </div>
              ) : (
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={(e) => {
                    userPhotoUpload(e);
                  }}
                >
                  <div className="upload_text">
                    <i className="las la-plus-circle"></i>
                    <p>Upload Photo Optional</p>
                  </div>
                </Upload>
              )}

              {user?.photo ? (
                <div className="action-btn">
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    beforeUpload={() => false}
                    onChange={(e) => {
                      userPhotoUpload(e);
                    }}
                  >
                    <button className="btn btn-bdr btn-xs mr-2" type="button">
                      Replace
                    </button>
                  </Upload>

                  <button
                    type="button"
                    className="btn btn-bdr btn-danger btn-xs"
                    onClick={() => {
                      let { user } = this.state;
                      user = Object.assign(user, { photo: "" });
                      setState((sttae) => ({
                        ...state,
                        user,
                        is_new_img: true,
                      }));
                    }}
                  >
                    Remove
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="fitem">
              <label>a little about me</label>
              <textarea
                value={user?.description || ""}
                name="description"
                onChange={(e) => {
                  onUserDetailChange(e);
                }}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="footer" style={{ width: "411px" }}>
          <button
            className="btn btn-primary"
            onClick={resetFields}
          >
            Cancel
          </button>
          <button
            className=" btn btn-warning"
            onClick={() => {
              validateUser();
            }}
          >
            Update User
          </button>
        </div>
        <Modal
          title="Error Modal "
          visible={ispulseErrorOpen}
          onCancel={() => onnotificationupdate()}
          onOk={() => onnotificationupdate()}
          className="delete_user_modal"
          width={400}
        // footer={[
        //     <button
        //         key="cancel"
        //         className="btn btn-brand btn-bdr"
        //         onClick={() => onnoteupdate()} >
        //         OK
        //     </button>,
        // ]}
        >
          <div>
            <h2> Please Select The PulseM Notification !! </h2>
          </div>
        </Modal>
        <Modal
          className="inactive_user_modal"
          visible={showInActiveModal}
          width={400}
          footer={[
            <button
              className="btn"
              onClick={() => setShowInActiveModal(!showInActiveModal)}
            >
              No
            </button>,
            <button
              className="btn"
              onClick={() => {
                updateUSer();
                setShowInActiveModal(!showInActiveModal);
              }}
            >
              Yes
            </button>,
          ]}
        >
          <div style={{    display: "flex", justifyContent: "space-between"}}>
            <span>
                <ExclamationCircleFilled style={{color: "#F8A725",marginTop: "18px",marginRight: "10px"}} /> 
            </span>
            <span>
              <h3>
                Please reassign the jobs of the technician manually as all the jobs assigned
                to technician will be moved as Not Assigned once the status is inactive.
              </h3>
            </span>
          </div>
        </Modal> 

      </div>
      {loader ? (
        <div className="loader_wrapper">
          <div className="loader07"></div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
});
export default EditUser;
