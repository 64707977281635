import React, { useEffect, useState } from "react";
import ProposalFormPage from "./Proposals/ProposalFormPage";
import ProposalDataTable from "./ProposalDataTable";
import { Table } from "antd";
const ProposalPage = (props) => {
    const [proposalData, setproposalData] = useState([]);
    const getproposaldata=async()=>{
        //setproposalData([])
    }
     
    useEffect(()=>{
        getproposaldata();
    },[]);  
  return (
    <div>
      {proposalData.length === 0 ? 
        <ProposalFormPage customer={props.customer} tabChange={props.tabChange}  /> : 
        <Table
        columns={ProposalDataTable.columns}
        dataSource={proposalData}
        onChange={getproposaldata}
        showSorterTooltip={false}
        />
      }
    </div>
  );
};
export default ProposalPage;