import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import SidebarResponsive from "../Components/SidebarResponsive";
import HeaderMobile from "../Components/HeaderMobile";
import AccountSidebar from "./AccountSidebar";
import AccountListTable from "./AccountListTable";
import { getCustomerDetail, getCommercialCustomer } from "../Action/Accounting";
import DataTable from "../Customer/Common/DataTable";
import { getPaymentHistory } from "../Action/Invoice";
import { Table } from "antd";

const AccountList = () => {
  const [customerList, setCustomerList] = useState({});
  const [commercialCustomer, setCommercialCustomer] = useState({});
  const [invoicePaymentHistory, setInvoicePaymentHistory] = useState([]);
  const [state, setState] = useState({
    current: 0,
    curr_id: "",
    is_crud_allow: true,
    service_order_uuid: "",
    current_invoice_uuid: "",
    is_disable_franchise: false,
    user_uuid: "",
    date: "",
    customerType: "",
    customer: {},
    ComCustomer: {},
    customerId: "",
    selectedData: "",
    selectedBatchOption: "",
  });
  const [openMenu, setOpenMenu] = useState(false);
  const onMenuButtonClick = () => {
    setOpenMenu(!openMenu);
  };

  const setData = (date) => {
    setState(
      {
        ...state,
        date: date,
      },
      () => {
        // console.warn("date", date)
      }
    );
  };
  const getPaymentHistoryApi = async (params) => {
    const data = await getPaymentHistory({ invoice__uuid: params });
    setInvoicePaymentHistory(data);
  };
  const ChangeComponent = (e) => {
    setState({ ...state, current: e });
  };
  const selectedBatch = (e) => {
    setState({ ...state, selectedBatchOption: e });
  };
  //View invvoice ID set
  const viewInvoice = async (id, service_order_id, row) => {
    setState({
      ...state,
      current_invoice_uuid: id,
      service_order_uuid: service_order_id,
      user_uuid: row?.user_uuid,
      customerType: row?.service_order?.service_type,
      customerId: row?.user_uuid,
    });
    if (row?.service_order?.service_type === "residential") {
      let temp_data = await getCustomerDetail(row?.user_uuid); // get customer detail
      setState({ ...state, customer: temp_data });
    } else {
      let temp_data = await getCommercialCustomer(row?.user_uuid); // get customer detail
      setState({ ...state, ComCustomer: temp_data });
    }
  };
  const RenderAllStep = () => {
    let {
      current,
      current_invoice_uuid,
      service_order_uuid,
      is_crud_allow,
      is_disable_franchise,
      user_uuid,
      date,
      customerType,
    } = state;
    switch (current) {
      case 0:
        return (
          <AccountListTable
            // {...this.props}
            customerType={state.customerType}
            ChangeComponent={ChangeComponent}
            selectedBatch={selectedBatch}
            selectedBatchOption={state.selectedBatchOption}
            invoice_uuid={current_invoice_uuid}
            viewInvoice={viewInvoice}
            is_disable_franchise={is_disable_franchise}
            is_crud_allow={is_crud_allow}
            getCommercialCustomer={viewInvoice}
            getCustomerDetail={getCustomerDetail}
            getPaymentHistoryApi={getPaymentHistoryApi}
            // CustomerList={this.props.CustomerList}
            // CommercialCustomer={this.props.CommercialCustomer}
            // selectedRecord={this.selectedRecord}
          />
        );
      case 1:
        return PaymentHistory();
      default:
        return (
          <AccountListTable
            customerType={state.customerType}
            ChangeComponent={ChangeComponent}
          />
        );
    }
  };

  const PaymentHistory = () => {
    return (
      <>
        <ul className="breadcrumb">
          <li
            onClick={() => {
              ChangeComponent(0);
            }}
          >
            <span className="pointer">
              <i className="las la-angle-left"></i> Back to All Accounts
            </span>
          </li>
        </ul>
        <h4 style={{ padding: "0 25px 0 25px", margin: "0" }}>
          Payment History
        </h4>
        <div className="block-row pt-10">
          <Table
            rowKey="uuid"
            dataSource={invoicePaymentHistory}
            // onChange={this.onHistoryChange}
            // pagination={payment_history_list_pagination}
            columns={DataTable.InvoiceHistory()}
          />
        </div>
      </>
    );
  };

  return (
    <div id="wrapper" className="accouting-area">
      <div id="accounting-tab" className="content-area" >
        <HeaderMobile onMenubuttonClick={onMenuButtonClick} />
        {/* Sidebar */}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenuButtonClick}
        />

        <div className="container">
          <div className="aside-area pt-0 account_module_wrapper">
            {state.current != 8 && (
              <AccountSidebar
                // {...this.props}
                ChangeComponent={ChangeComponent}
                setData={setData}
              />
            )}
            <div className="main p-0">{RenderAllStep()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountList;
