import React from "react";
import { Col, Row } from "antd";
import { useDashboard } from "../../Hooks/Dashboard.Hook";
import RevenueTrendChart from "../Charts/RevenueTrend.Chart";

const ServiceRevenueCountContainer = () => {
  const { periodType } = useDashboard();
  return (
    <div
      className="cardMetric"
      style={{ padding: "0 0 10px 0", maxHeight: "400px" }}
    >
      <Row>
        <Col xs={24} xxl={24} md={24} className="serviceChart cardFilters">
          <span className="cardTitle">
            Revenue Trend{" "}
            <span className="cardSubTitle">
              {["day", "today"].includes(periodType)
                ? ""
                : `(Rolling ${
                    periodType.charAt(0).toUpperCase() + periodType.slice(1)
                  })`}
            </span>
          </span>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          xxl={24}
          md={24}
          style={{ padding: "10px", height: "300px" }}
        >
          <RevenueTrendChart />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceRevenueCountContainer;
