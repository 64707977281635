import React, { useState, useEffect } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Select, Drawer, DatePicker, Table ,Popconfirm} from "antd";
import moment from "moment-timezone";
import { orderBy } from "lodash";
import { FilterOutlined } from "@ant-design/icons";
import HeaderMobile from "../Components/HeaderMobile";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import SidebarResponsive from "../Components/SidebarResponsive";
import Loader from "../Components/Loader";
import DataTable from "./Common/DataTable";
import { getFranchiseListAll } from "../Action/Franchise";
import {
  getCustomers,getLeads,exportLeads,exportCustomers
} from "../Action/Customer";
import AddNewLead from "./Commercial/AddNewLead";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { resetValues } from "../Store/serviceOrderDetailDataSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { refreshCustomerStatusTable } from "../Store/customerSlice";
import { getFranchiseSelected, setFranchiseSelected } from "../Utils/selectedFranchise";
export const timeout = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms));

const get_franchise = () => {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  return authUser.user.associated_field_offices[0].uuid;
};
const get_filters = () => {
  let filters = JSON.parse(sessionStorage.getItem("CACHED_LEADS_FILTERS"));
  filters.franchise = getFranchiseSelected().uuid;
  filters.filterFranchise = getFranchiseSelected().uuid;
  return filters;
};
const get_cust_filters = () => {
  let filters = JSON.parse(sessionStorage.getItem("CACHED_CUSTOMER_FILTERS"));
  filters.franchise = getFranchiseSelected().uuid;
  filters.filterFranchise = getFranchiseSelected().uuid;
  return filters;
};
const CustomerLeadList = () => {
  let authUser = JSON.parse(localStorage.getItem("authUser"));
  const { Option } = Select;
  const { state } = useLocation();
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [isAddNewLead, setIsAddNewLead] = useState(false);
  const [searchValue, setSearchValue] = useState( sessionStorage.getItem("CACHED_SEARCH_VALUE")|| "");
  const [loader, setLoader] = useState(false);
  const [tableDataloader, setTableDataloader] = useState(false);
  const [currentTab, setCurrentTab] = useState(sessionStorage.getItem("CACHED_CURRENT_TAB") || "customer");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerCustomer, setOpenDrawerCustomer] = useState(false);
  const [activePagination, setActivePagination] = useState(sessionStorage.getItem("CACHED_ACTIVE_PAGNATION") ||15);
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterClickedCustomer, setFilterClickedCustomer] = useState(false);
  const [franchiseList, setFranchiseList] = useState({});
  const [commercialCustList, setCommercialCustomer] = useState({});
  const [customerListCommercial, setCustomerListCommercial] = useState(JSON.parse(sessionStorage.getItem("CACHED_CUSTOMER_LIST") )|| []);
  const [inactiveCustomerListCommercial, setInactiveCustomerListCommercial] = useState(JSON.parse(sessionStorage.getItem("CACHED_INACTIVE_CUSTOMER_LIST")) || []);
  const [current_user, setCurrent_user] = useState('');
  const [offset, setOffset] = useState(sessionStorage.getItem("CACHED_OFFSET") ||0);
  const [limit, setLimit] = useState(sessionStorage.getItem("CACHED_LIMIT") ||15);
  const [leadList, setLeadList] = useState(JSON.parse(sessionStorage.getItem("CACHED_LEAD_LIST") )|| []);
  const [userInteraction, setUserInteraction] = useState(false);
  const [initialRendered, setInitialRendered] = useState(sessionStorage.getItem("CACHED_INITIAL_RENDERED") || "true");
  const [selectedFranchise, setSelectedFranchise] = useState(getFranchiseSelected()?.uuid || get_franchise());
  const [loadingExport, setLoadingExport] = useState(false);
  const [filterData, setFilterData] = useState(JSON.parse(sessionStorage.getItem("CACHED_LEADS_FILTERS"))? get_filters() : {
    franchise: getFranchiseSelected()?.uuid || get_franchise(),
    active: false,
    inactive: false,
    filterFranchise: getFranchiseSelected()?.uuid || get_franchise(),
    last_contacted: [],
    lead_stage: "all",
    outreach: "all",
    created_at__lte: null,
    created_at__gte: null,
    });
  const [filterDataCustomer, setFilterDataCustomer] = useState(JSON.parse(sessionStorage.getItem("CACHED_CUSTOMER_FILTERS"))?  get_cust_filters() : {
    franchise: getFranchiseSelected()?.uuid || get_franchise(),
    franchiseList: [],
    active: false,
    inactive: false,
    filterFranchise: getFranchiseSelected()?.uuid || get_franchise(),
    joined: [],
    lastService: [],
    outstanding: 'all',
    satisfaction: '',
    created_at__lte: null,
    created_at__gte: null,
    service_type: 'all',
    account_type: 'all',
    zipcodes: null,
    account_balace_range: null,
  });
  const dispatch = useDispatch();
  const getCustomerStatus = useSelector((state) => state.customer.refreshCustomerStatusTable);
  const service_types = [{ key: "all", value: "All" },
  { key: 'construction', value: 'Construction' },
  { key: 'maintenance', value: 'Maintenance' },
  { key: 'renovation', value: 'Renovation' },
  { key: 'service', value: 'Service' },
  ];
  const account_types = [{ key: "all", value: "All" },
  { key: 'commercial', value: 'Commercial' },
  { key: 'residential', value: 'Residential' },
  ];
  const value_lead_stage = [
    { key: "all", value: "All" },
    { key: "new", value: "New" },
    { key: "contacted", value: "Contacted" },
    { key: "interested", value: "Interested" },
  ];
  const value_outreach = [
    { key: "all", value: "All" },
    { key: "franchise_owner", value: "Franchise" },
    { key: "call_center", value: "Call Center" },
  ];
  const [customerListPagination, setCustomerListPagination] = useState(JSON.parse(sessionStorage.getItem("CACHED_CUSTOMER_LIST_PAGINATION"))|| {
    showSizeChanger: false,
    pageSize: 15,
    ordering: "-created_at",
    current: 1,
    offset: 0
  });
  useEffect(()=> {
    if(getCustomerStatus === "Deactivate" || getCustomerStatus === "Activate" ) {
      getCustomerStatus === "Activate" ? setCurrentTab("customer")  : setCurrentTab("inactive_customer");
    }
  }, [getCustomerStatus]);

  useEffect(()=> {
    if((currentTab === "customer" || currentTab === "inactive_customer") && (getCustomerStatus === "Deactivate" || getCustomerStatus === "Activate")) {
      renderCustomerTableOnStatusChange();
    }
  }, [currentTab]);

const renderCustomerTableOnStatusChange = async () => {
  setTableDataloader(true);
  let offset = (customerListPagination.current-1) * customerListPagination.pageSize;
  let data = {
    is_active: currentTab === "customer"? true : false,
    offset: offset,
    current: customerListPagination.current !== undefined? customerListPagination.current: 1,
    limit: activePagination,
    ordering:customerListPagination.ordering !== undefined? customerListPagination.ordering: "-created_at" ,
    q: searchValue
  };  
  if (filterDataCustomer?.franchise !== "all") {
    data.lead_profile__field_office__uuid = filterDataCustomer?.franchise;
  }    
  if (filterDataCustomer?.joined.length) {
    data.created_at__lte = filterDataCustomer?.joined[1];
    data.created_at__gte = filterDataCustomer?.joined[0];
  }
  if (filterDataCustomer?.lastService.length) {
    data.last_serviced__lte = filterDataCustomer.lastService[1];
    data.last_serviced__gte = filterDataCustomer.lastService[0];
  }
  if (filterDataCustomer?.service_type !== "all") {
    data.service_type = filterDataCustomer.service_type.join(',');
  }
  if (filterDataCustomer?.account_type !== "all") {
    data.account_type = filterDataCustomer.account_type;
  }
  if (filterDataCustomer?.zipcodes !== undefined) {
    data.zipcodes = filterDataCustomer.zipcodes;
  }
  if (filterDataCustomer?.account_balace_range !== undefined) {
    data.account_balace_range = filterDataCustomer.account_balace_range;
  }

  const customer_lead_list = await getCustomers(data);
  if(currentTab === "customer"){
    setCustomerListCommercial(customer_lead_list);
  }  else {
    setInactiveCustomerListCommercial(customer_lead_list)
  }
    await timeout(2000);
    setTableDataloader(false);
    dispatch(refreshCustomerStatusTable(''));
}

  const onMenubuttonClick = () => {
    setOpenMenu(!openMenu);
  };
  const onSearch = async (e) => {
    if (
      (e.target.value?.length >= 3 || e.target.value?.length === 0)
    ) {
      setTimeout(() => {
        setSearchValue(e.target.value);
      }, 2000);
    }
  };
  useEffect(() => {
    if(userInteraction){
      if(currentTab === "customer" || currentTab === "inactive_customer")
        getCustomer_list();
      if(currentTab == "lead")
        getLead_list();
    }
  }, [searchValue]);

  useEffect(() => {
    if(currentTab == "lead")
      getLead_list();
  }, []);
  
  useEffect(() => {
    if(userInteraction){
      if(current_user === 'franchise_owner'){
        if(currentTab === "customer" || currentTab === "inactive_customer")
          getCustomer_list();
        if(currentTab == "lead")
          getLead_list();
      }
    }
  }, [franchiseList]);
  
  // filter data change
  const onFilterChange = (e, type, name) => {
    const value = type === "bool" ? e.target.checked : e.target.value;
    if (e.target.name === "franchise") {
      setUserInteraction(true);
      setFilterData((filterData) => ({
        ...filterData,
        franchise: value,
      }));
    } else {
      setUserInteraction(true);
      setFilterData((filterData) => ({
        ...filterData,
        [e.target.name]: value,
      }));
    }
  };
  // customer filter data change
  const onFilterChangeCustomer = (e, type, name) => {
    
    const value = type === 'bool' ? e.target.checked : e.target.value;
    if (e.target.name === "franchise") {
      setUserInteraction(true);
      setFilterDataCustomer({
        ...filterDataCustomer,
        franchise: value,
      });
    } else {
      setUserInteraction(true);
      setFilterDataCustomer({
        ...filterDataCustomer,
        [e.target.name]: value,
      });
    }
  };

  const onMultiSelectChange = (e, type) => {
    let selection = e;

    if (e.length > 1 && e[0] ==="all")
    {
      selection = e.filter(item => item !== "all");
    }
    else if (e.length === 0 || (e.length > 0 && e.includes('all')))
    {
      selection = ["all"];
    }
    setUserInteraction(true);
    setFilterDataCustomer({
      ...filterDataCustomer,
      [type]: selection,
    });
  }
  // on filter click
  const onFilterClickCustomer = () => {
    setUserInteraction(true);
    setFilterClickedCustomer(true);
    closeDrawerCustomer();
  };
  // on filter click
    const onFilterClick = () => {
      setFilterClicked(true);
      closeDrawer();
    };
  useEffect(() => {
    if(userInteraction){
      setCustomerListPagination({
        ...customerListPagination,
        pageSize: 15,
        current: 1,
      });
      if (filterClicked) {
        getLead_list();
      }
    }
  }, [filterClicked]);
  useEffect(() => {
    if(userInteraction){
      setCustomerListPagination({
        ...customerListPagination,
        pageSize: 15,
        current: 1,
      });
      if (filterClickedCustomer) {
        getCustomer_list();
      }
    }
  }, [filterClickedCustomer ]);
 


  useEffect(() => {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setCurrent_user(authUser?.user?.role?.[0]);    
    getFranchiseList();
    dispatch(resetValues());
    getCustomer_list();
  }, []);

  const onPrimaryFilterChange = (e, type, name) => {
    setSelectedFranchise(e.target.value);
    if (currentTab === "customer" || currentTab === "inactive_customer") {
      setUserInteraction(true);
      setFilterDataCustomer({
        ...filterDataCustomer,
        franchise: e.target.value,
      });
      onFilterClickCustomer();
    } else {
      setUserInteraction(true);
      setFilterData((filterData) => ({
        ...filterData,
        franchise: e.target.value,
      }));
      onFilterClick();
    }
  };

  const getLeadListFilters = () => {
    let offset = (customerListPagination.current - 1) * customerListPagination.pageSize;
    let data = {
      offset: offset,
      current: customerListPagination.current !== undefined ? customerListPagination.current : 1,
      limit: activePagination,
      ordering: customerListPagination.ordering !== undefined ? customerListPagination.ordering : "-created_at",
      q: searchValue || "",
    };
    let authUser = JSON.parse(localStorage.getItem("authUser"));
  
    if (filterData?.franchise !== "all") {
      data.lead_profile__field_office__uuid = filterData?.franchise;
    }
    if (filterData?.last_contacted.length) {
      data.contacted_date__lte = new Date(filterData?.last_contacted[1]);
      data.contacted_date__gte = new Date(filterData?.last_contacted[0]);
    }
    if (filterData?.outreach !== "all") {
      data.lead_profile__outreach = filterData?.outreach;
    }
    if (filterData?.lead_stage !== "all") {
      data.lead_profile__stage = filterData?.lead_stage;
    }
    if ((filterData.active && !filterData.inactive) ||
      (!filterData.active && filterData.inactive)) {
      data.is_active = filterData.active ? filterData.active : false;
    }
    return data;
  }
  const getLead_list = async () => {
    if(userInteraction || initialRendered === "true"){    
      setTableDataloader(true);
		  setFilterClicked(false);
		
		  const leadList = await getLeads(getLeadListFilters());
		  setLeadList(leadList);
		  setTableDataloader(false);
      setUserInteraction(false);
    }

    else {
      const leadList = await getLeads(getLeadListFilters());
		  setLeadList(leadList);
      setTableDataloader(false);
    }
  };
  const getCustomer_list = async () => {
    setTableDataloader(true);
    setFilterClickedCustomer(false);
    const customer_lead_list = await getCustomers(getCustomerListFilters());
    if(currentTab === "customer")
      setCustomerListCommercial(customer_lead_list);
    else
      setInactiveCustomerListCommercial(customer_lead_list)
    setTableDataloader(false);

    setUserInteraction(false);
    setInitialRendered("false");
    sessionStorage.setItem("CACHED_INITIAL_RENDERED", "false");
  };
  const getCustomerListFilters = () => {
    let offset = (customerListPagination.current-1) * customerListPagination.pageSize;
      let data = {
        is_active: currentTab === "customer"? true : false,
        offset: offset,
        current: customerListPagination.current !== undefined? customerListPagination.current: 1,
        limit: activePagination,
        ordering:customerListPagination.ordering !== undefined? customerListPagination.ordering: "-created_at" ,
        q: searchValue
      };
      let authUser = JSON.parse(localStorage.getItem("authUser"));
      
      if (filterDataCustomer?.franchise !== "all") {
        data.lead_profile__field_office__uuid = filterDataCustomer?.franchise;
      }    
      if (filterDataCustomer?.joined.length) {
        data.created_at__lte = filterDataCustomer?.joined[1];
        data.created_at__gte = filterDataCustomer?.joined[0];
      }
      if (filterDataCustomer?.lastService.length) {
        data.last_serviced__lte = filterDataCustomer.lastService[1];
        data.last_serviced__gte = filterDataCustomer.lastService[0];
      }
      if (filterDataCustomer?.service_type !== "all") {
        data.service_type = filterDataCustomer.service_type.join(',');
      }
      if (filterDataCustomer?.account_type !== "all") {
        data.account_type = filterDataCustomer.account_type;
      }
      if (filterDataCustomer?.zipcodes !== undefined) {
        data.zipcodes = filterDataCustomer.zipcodes;
      }
      if (filterDataCustomer?.account_balace_range !== undefined) {
        data.account_balace_range = filterDataCustomer.account_balace_range;
      }

      return data;
  }
  // close Drawer
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  const closeDrawerCustomer = () => {
    setOpenDrawerCustomer(false);
  }
  //render customer filter drawer
  const renderDrawer = () => {
    switch (currentTab) {
      case "lead":
        return (
          <Drawer
            placement={"right"}
            closable={false}
            onClose={closeDrawer}
            visible={openDrawer}
            key={"right"}
            width={"400"}
            className="drawer-wrapper"
          >
            <div className="group_wrapper rdrawer">
              <div className="card-head">
                <h4>Filter Leads</h4>
              </div>
              <div className="card-row">
                <div className="card-full">
                  <div className="form-group">
                    <div className="fitem">
                      <label>Lead Status</label>
                      <div className="fitem-check">
                        <input
                          type="checkbox"
                          className="fitem-ck-input"
                          id={`active`}
                          name={"active"}
                          checked={filterData?.active}
                          onChange={(e) => onFilterChange(e, "bool")}
                        />
                        <label htmlFor={`active`} className="fitem-ck-txt mb-0">
                          {"Active"}
                        </label>
                      </div>
                      <div className="fitem-check">
                        <input
                          type="checkbox"
                          className="fitem-ck-input"
                          id={`inactive`}
                          name={"inactive"}
                          checked={filterData?.inactive}
                          onChange={(e) => onFilterChange(e, "bool")}
                        />
                        <label htmlFor={`inactive`} className="fitem-ck-txt mb-0">
                          {"Inactive"}
                        </label>
                      </div>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Last Contacted</label>
                      <RangePicker
                        style={{ width: "100%" }}
                        allowClear={true}
                        separator="-"
                        format={"MMM DD, YYYY"}
                        dropdownClassName="current_month"
                        value={
                          filterData?.last_contacted?.length > 0
                            ? [
                              filterData?.last_contacted?.[0]
                                ? moment(filterData?.last_contacted?.[0])
                                : undefined,
                              filterData?.last_contacted?.[1]
                                ? moment(filterData?.last_contacted?.[1])
                                : undefined,
                            ]
                            : undefined
                        }
                        onChange={(e) => {
                          if (e === null) {
                            onFilterChange({
                              target: { name: "last_contacted", value: [] },
                            });
                          } else {
                            onFilterChange({
                              target: {
                                name: "last_contacted",
                                value: [
                                  e?.[0]?.format("YYYY-MM-DD"),
                                  e?.[1]?.format("YYYY-MM-DD"),
                                ],
                              },
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Lead Stage</label>
                      <Select
                        placeholder="Select"
                        name="lead_stage"
                        onChange={(e) =>
                          onFilterChange({
                            target: { name: "lead_stage", value: e },
                          })
                        }
                        value={filterData?.lead_stage || undefined}
                      >
                        {value_lead_stage?.map((option) => (
                          <Option key={option?.key} value={option?.key}>
                            {option?.value}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Outreach</label>
                      <Select
                        placeholder="Select"
                        name="outreach"
                        onChange={(e) =>
                          onFilterChange({ target: { name: "outreach", value: e } })
                        }
                        value={filterData?.outreach || undefined}
                      >
                        {value_outreach?.map((option) => (
                          <Option key={option?.key} value={option?.key}>
                            {option?.value}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <button className={`btn`} onClick={closeDrawer}>
                  Cancel
                </button>
                <button className={`btn`} onClick={() => onFilterClick()}>
                  Filter Leads
                </button>
              </div>
            </div>
          </Drawer>
        );
      case "customer":
      case "inactive_customer":
        return (
          <Drawer
            placement={'right'}
            closable={false}
            onClose={closeDrawerCustomer}
            visible={openDrawerCustomer}
            key={'right'}
            width={'400'}
            className="drawer-wrapper"
          >
            <div className="group_wrapper rdrawer">
              <div className="card-head">
                <h4>Filter Customer</h4>
              </div>
              <div className="card-row">
                <div className="card-full">
                  <div className="form-group">
                    
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Joined Date</label>
                      <RangePicker
                        style={{ 'width': '100%' }}
                        allowClear={true}
                        separator='-'
                        format={"MMM DD, YYYY"}
                        dropdownClassName='current_month'
                        value={filterDataCustomer?.joined?.length > 0 ? [filterDataCustomer?.joined?.[0] ? moment(filterDataCustomer?.joined?.[0]) : undefined, filterDataCustomer?.joined?.[1] ? moment(filterDataCustomer?.joined?.[1]) : undefined] : undefined}
                        onChange={(e) => {
                          if (e === null) {
                            onFilterChangeCustomer({ target: { name: 'joined', value: [] } });
                          } else {
                            onFilterChangeCustomer({ target: { name: 'joined', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                          }
                        }} />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Last Serviced</label>
                      <RangePicker
                        style={{ 'width': '100%' }}
                        allowClear={true}
                        separator='-'
                        format={"MMM DD, YYYY"}
                        dropdownClassName='current_month'
                        value={filterDataCustomer?.lastService?.length > 0 ? [filterDataCustomer?.lastService?.[0] ? moment(filterDataCustomer?.lastService?.[0]) : undefined, filterDataCustomer?.lastService?.[1] ? moment(filterDataCustomer?.lastService?.[1]) : undefined] : undefined}
                        onChange={(e) => {
                          if (e === null) {
                            onFilterChangeCustomer({ target: { name: 'lastService', value: [] } });
                          } else {
                            onFilterChangeCustomer({ target: { name: 'lastService', value: [e?.[0]?.format('YYYY-MM-DD'), e?.[1]?.format('YYYY-MM-DD')] } })
                          }
                        }} />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Zip Code</label>
                      <span style={{"color": "#90979e", "display":"flex"}}>
                          please enter comma-separated values 
                      </span>
                      <input type="text" placeholder="Zipcodes, Ex: 55401,55612,36478" 
                             name="zipcodes" 
                             value={filterDataCustomer?.zipcodes || undefined} 
                             onChange={(e) => {
                              if (e.target.value  === "") {
                                onFilterChangeCustomer({ target: { name: 'zipcodes', value: null } });
                              } else {
                                onFilterChangeCustomer({ target: { name: 'zipcodes', value: e.target.value } })
                              }
                            }}  />
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Service Type</label>
                      <Select
                        mode="multiple"
                        placeholder="Select Type"
                        name="Service Type"
                        onChange={(e) => onMultiSelectChange(e, 'service_type')}
                        value={filterDataCustomer?.service_type || undefined}>
                        {
                          service_types?.map((option) =>
                            <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                        }
                      </Select>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Account Type</label>
                      <Select
                        placeholder="Select Type"
                        name="Account type"
                        onChange={(e) => onFilterChangeCustomer({ target: { name: 'account_type', value: e } })}
                        value={filterDataCustomer?.account_type || undefined}>
                        {
                          account_types?.map((option) =>
                            <Option key={option?.key} value={option?.key}>{option?.value}</Option>)
                        }
                      </Select>
                    </div>
                    <div className="fitem">
                      <label htmlFor="customerFranchise">Account Balance</label>
                      <span style={{"color": "#90979e", "display":"flex"}}>
                          please enter comma-separated values 
                      </span>
                      <input type="text" placeholder="Account Balance, Ex: 100,1600" 
                             name="account_balace_range" 
                             value={filterDataCustomer?.account_balace_range || undefined} 
                             onChange={(e) => {
                              if (e.target.value  === "") {
                                onFilterChangeCustomer({ target: { name: 'account_balace_range', value: null } });
                              } else {
                                onFilterChangeCustomer({ target: { name: 'account_balace_range', value: e.target.value } })
                              }
                            }}  />
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                <button className={`btn`} onClick={() => { closeDrawerCustomer() }}>Cancel</button>
                <button
                  // type="submit"
                  className={`btn`}
                  onClick={() => onFilterClickCustomer()}>
                  Filter Customers
                </button>
              </div>
            </div>
          </Drawer>
        )
    }
  };
  // on lead list row
  const onLeadListRow = (record) => {
    if(record.lead_type.toLowerCase() === "commercial" )
        return {
          onClick: () => {
            navigate(`/customer-lead-list/lead/detail/${record?.uuid}`, {
              state: { source: "customers-lead-list",uuid: record?.uuid, view: "commercial" },
            });
          },
        };
    else
      return {
        onClick: () => {
          navigate(`/customer-lead-list/lead/detail/${record?.uuid}`, { state: { source: "customers-lead-list",uuid: record?.uuid, view: 'residential' } });
        }
      }
  };

  const onCustomerDetailRow = (record) => {
    if(record.customer_type.toLowerCase() === "commercial" )
        return {
          onClick: () => {
            navigate(`/customer-lead-list/commercial-customer-detail`, {
              state: {
                source: "customers-lead-list",
                id: record?.uuid,
                hear_about_us: record?.hear_about_us,
                customer_satisfaction_score: record?.customer_satisfaction_score,
                record: record,
              },
            });
          },
        };
    else
        return {
          onClick: () => {
            navigate(`/customer-lead-list/residential-customer-detail`, { state: { source: "customers-lead-list",'id': record?.uuid, hear_about_us: record?.hear_about_us, customer_satisfaction_score: record?.customer_satisfaction_score, record: record } })
          }
        };
  };
  //Render commercial customer tab table list
  const renderCommercialCustTable = () => {
    const franchise = commercialCustList?.results?.map((item) => ({
      text: item?.name,
      value: item?.uuid,
    }));
    return (
      <div className="leadstbl">
        {currentTab === "customer"?
          <Table
            rowKey={(record) => record?.uuid}
            dataSource={customerListCommercial?.results || []}
            pagination={{
              ...customerListPagination,
              total: customerListCommercial?.count,
            }}
            columns={DataTable.CustomersTableColumns(
              franchise,
              customerListCommercial
            )}
            onChange={onTableChange}
            onRow={onCustomerDetailRow}
            className="customer-list"
          />
        : currentTab === "inactive_customer"?
          <Table
              rowKey={(record) => record?.uuid}
              dataSource={inactiveCustomerListCommercial?.results || []}
              pagination={{
                ...customerListPagination,
                total: inactiveCustomerListCommercial?.count,
              }}
              columns={DataTable.InactiveCustomersTableColumns(
                franchise,
                inactiveCustomerListCommercial
              )}
              onChange={onTableChange}
              onRow={onCustomerDetailRow}
              className="customer-list"
            />
        :
          <Table
          rowKey={(record) => record?.uuid}
          dataSource={leadList?.results || []}
          pagination={{
            ...customerListPagination,
            total: leadList?.count,
          }}
          columns={DataTable.LeadsTableColumns(
            franchise,
            leadList
          )}
          onChange={onTableChange}
          onRow={onLeadListRow}
          className="customer-list"
        />
        }
        {customerListCommercial?.results?.length > 0  || leadList?.results?.length > 0? (
          <ul className="pagination_page_size">
            <li>
              <span>Show:</span>
            </li>
            <li>
              <span
                onClick={() => {
                  setCustomerPageLimit("15");
                }}
                className={activePagination === "15" ? "active" : ""}
              >
                15
              </span>
            </li>
            <li>
              <span
                className={activePagination === "30" ? "active" : ""}
                onClick={() => {
                  setCustomerPageLimit("30");
                }}
              >
                30
              </span>
            </li>
            <li>
              <span
                className={activePagination === "60" ? "active" : ""}
                onClick={() => {
                  setCustomerPageLimit("60");
                }}
              >
                60
              </span>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  };
  //end here
  //Table Order and Pagination
  const onTableChange = (pagination, filters, sorter) => {
    setUserInteraction(true);
    let ordering = '';    
    if (sorter.column) {
      ordering = sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`
    } else {
      ordering = '-created_at'
    }
    let offset_value = (pagination.current - 1) * pagination.pageSize;
    pagination.ordering = ordering;
    setOffset(offset_value);
    setLimit(pagination?.pageSize);
    setCustomerListPagination(pagination);  
    if (filters?.franchise || (filterDataCustomer?.franchise && filterDataCustomer?.franchise !== 'all')) {
      filterDataCustomer.filterFranchise = filters?.franchise?.toString() || filterDataCustomer?.franchise;
    }else
    filterDataCustomer.filterFranchise = '';
    Object.assign(filterDataCustomer, { franchise: filterDataCustomer?.franchise })
  }
  

  //Table Order and Pagination
  const setCustomerPageLimit = (value) => {
    setUserInteraction(true);
    setActivePagination(value);
    setCustomerListPagination((p) => ({ ...p, pageSize: value }));
  };
  useEffect(() => {
    if(userInteraction){
      if(currentTab === "customer" || currentTab === "inactive_customer")
        getCustomer_list();
      else
        getLead_list();
    }
}, [customerListPagination]);
  const getFranchiseList = async () => {
    setLoader(true);
    const data = await getFranchiseListAll();
    setFranchiseList(data ? data : []);
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    setCurrent_user(authUser?.user?.role?.[0]);
    /*setFilterData({
      ...filterData,
      franchise: authUser.user.associated_field_offices[0]?.uuid,
    });
    setFilterDataCustomer({
      ...filterDataCustomer,
      franchise:  authUser.user.associated_field_offices[0]?.uuid,
    });*/
    setLoader(false);
  };

  //Close drawer
  const closeAddDrawer = (type) => {
    setUserInteraction(true);
    setIsAddNewLead(type);
    setLoader(false);
    getLead_list();
  };
  useEffect(() => {
     if(userInteraction || initialRendered === "true"){

        setSearchValue("");    
        //getCommCustomerList();
        let authUser = JSON.parse(localStorage.getItem("authUser"));
        setFilterDataCustomer({
          franchise: selectedFranchise,
          franchiseList: [],
          active: false,
          inactive: false,
          filterFranchise: selectedFranchise,
          joined: [],
          lastService: [],
          outstanding: 'all',
          satisfaction: '',
          created_at__lte: null,
          created_at__gte: null,
          service_type: 'all',
          account_type: 'all',
          zipcodes: null,
          account_balace_range: null
        });
        setFilterData({
          franchise: selectedFranchise,
          active: false,
          inactive: false,
          filterFranchise: selectedFranchise,
          last_contacted: [],
          lead_stage: "all",
          outreach: "all",
          created_at__lte: null,
          created_at__gte: null,
        });
        setCustomerListPagination({
          showSizeChanger: false,
          pageSize: 15,
          ordering: "-created_at",
          current: 1,
        });
        setOffset(0);
        setLimit(15);
        if(currentTab === "customer" || currentTab === "inactive_customer")
            getCustomer_list();
        else
            getLead_list();
        setActivePagination("15");
      } 
  }, [currentTab]);


  useEffect(()=>{
    sessionStorage.setItem("CACHED_CUSTOMER_LIST", JSON.stringify(customerListCommercial));
  }, [customerListCommercial]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_INACTIVE_CUSTOMER_LIST", JSON.stringify(inactiveCustomerListCommercial));
  }, [inactiveCustomerListCommercial]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_LEAD_LIST", JSON.stringify(leadList));
  }, [leadList]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_CUSTOMER_FILTERS", JSON.stringify(filterDataCustomer));
  }, [filterDataCustomer]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_LEADS_FILTERS", JSON.stringify(filterData));
  }, [filterData]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_SEARCH_VALUE", searchValue);
  }, [searchValue]);
  useEffect(()=>{    
    sessionStorage.setItem("CACHED_CURRENT_TAB", currentTab);
  }, [currentTab]);
  useEffect(()=>{    
    sessionStorage.setItem("CACHED_OFFSET", offset);
  }, [offset]);
  useEffect(()=>{    
    sessionStorage.setItem("CACHED_LIMIT", limit);
  }, [limit]);
  useEffect(()=>{    
    sessionStorage.setItem("CACHED_CUSTOMER_LIST_PAGINATION", JSON.stringify(customerListPagination));
  }, [customerListPagination]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_SELECTED_FRANCHISE", selectedFranchise);
    setFranchiseSelected(selectedFranchise);
  }, [selectedFranchise]);
  useEffect(()=>{
    sessionStorage.setItem("CACHED_ACTIVE_PAGNATION", activePagination);
  }, [activePagination]);
  
  const onSearchValueChanges = (value) =>{
    setUserInteraction(true);
    setSearchValue(value);
  }
  const get_filter_icon_style = () => {
    if(currentTab === "customer" || currentTab === "inactive_customer"){
        if( filterDataCustomer.joined.length == 0 && filterDataCustomer.lastService.length == 0 && filterDataCustomer.zipcodes == null 
          && filterDataCustomer.service_type == 'all' && filterDataCustomer.account_type == 'all' 
          && filterDataCustomer.account_balace_range == null)        
            return {};
        else
            return {borderColor:"#ff681d"};
        }
    else{
        if( filterData.active == false && filterData.inactive == false && filterData.last_contacted.length == 0
          && filterData.lead_stage == 'all' && filterData.outreach == 'all') 
            return {};
        else
            return {borderColor:"#ff681d"};
      }
  }
  const onExportTable = async (is500=0) => {
    
    if(currentTab === "lead"){
      let payLoad = {
        ...getLeadListFilters(),
        is_500: is500,
      };
      payLoad.limit = leadList?.count;
        payLoad.offset = 0
      setLoadingExport(true);
      const response = await exportLeads(payLoad);
      setLoadingExport(false);      
    }else{
      let payLoad = {
        ...getCustomerListFilters(),
        is_500: is500,
      };
      if(currentTab === "customer"){
        payLoad.limit = customerListCommercial?.count;
        payLoad.offset = 0
      }else{
        payLoad.limit = inactiveCustomerListCommercial?.count;
        payLoad.offset = 0
      }
      setLoadingExport(true);
      const response = await exportCustomers(payLoad);
      setLoadingExport(false);   
    }
  }
  const getTableRecordsNumber = () => {
    switch (currentTab) {
      case "lead":
        return leadList.count;
      case "customer":
        return customerListCommercial.count;
      case "inactive_customer":
        return inactiveCustomerListCommercial.count;
      default:
        return 0;
    }

  }
  return (
    <div id="wrapper">
      {(tableDataloader) && <Loader/>}
      <div className="content-area" style={{width: "100%"}}>
        <HeaderMobile onMenubuttonClick={onMenubuttonClick} />
        <Header
          placeholder={"Acc Number, Customer Name, Address, Phone Number, Email Address"}
          readOnly={false}
          searchValue={searchValue}
          setSearchValue={onSearchValueChanges}
          onSearch={onSearch}
          onEnter={(e) => {
            e.target.value.length > 2
              ? onSearch(e)
              : alert("Enter at least 3 character!");
          }}
        />
        {/*<GlobalSearch/>*/}
        <Sidebar sideBarBackgroundColor="#005d89" />
        <SidebarResponsive
          sideBarBackgroundColor="#005d89"
          openMenu={openMenu}
          onMenubuttonClick={onMenubuttonClick}
        />
        <div className="container">
          <div className="filter_wrapper customer_list">
            <div className="left_side">
              <div className="fitem" style={{ marginRight: "10px" }}>
                <Select
                  id={"customerFranchise"}
                  className="swhite"
                  placeholder="Select a Franchise"
                  name="franchise"
                  onChange={(e) =>
                    onPrimaryFilterChange({
                      target: { name: "franchise", value: e },
                    })
                  }
                  value={selectedFranchise}
                  disabled={franchiseList?.length === 1}
                >
                  {(current_user !== 'franchise_owner' || franchiseList?.length > 1) && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                  {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map(
                    (option) => (
                      <Option key={option?.uuid} value={option?.uuid}>
                        {option?.name}
                      </Option>
                    )
                  )}
                </Select>                
              </div>
              <ul className="tabs">
                <li
                  style={{ marginRight: "10px" }}
                  className={currentTab === "lead" ? "active" : ""}
                  onClick={() => {
                    setUserInteraction(true);
                    setCurrentTab("lead");
                    getLead_list();
                  }}
                >
                  <span>{`LEADS`}</span>
                </li>
                <li
                  style={{ marginRight: "10px" }}
                  className={currentTab === "customer" ? "active" : ""}
                  onClick={() => {
                    setUserInteraction(true);
                    setCurrentTab("customer"); 
                    getCustomer_list();
                  }}
                >
                  <span>CUSTOMERS</span>
                </li>
                <li
                  style={{ marginRight: "10px" }}
                  className={currentTab === "inactive_customer" ? "active" : ""}
                  onClick={() => {
                    setUserInteraction(true);
                    setCurrentTab("inactive_customer");
                    getCustomer_list();
                  }}
                >
                  <span>INACTIVE CUSTOMERS</span>
                </li>
              </ul>
             
            </div>
            <div className="right_side">
            <div className="fitem">
                {/* <div className='dis'>
                  <Select
                    style={{ 'width': '150px' }}
                    className="status_Customer_list swhite filter_type"
                    defaultValue=""
                    // value={account_list_filter.status}
                    // onChange={(e) => {
                    //   this.customerAndStatusOnChange(e, 'status')
                    // }}
                    suffixIcon={<i className="las la-angle-down"></i>}
                  >
                    <Option value="">All</Option>
                    <Option value="active">Active</Option>
                    <Option value="in-active">In-active</Option>
                  </Select>
                </div> */}
                {/* <div className="fitem"> */}
                <div className='dis_res'>
                  <Select
                    // disabled={is_disable_franchise_dropdown}
                    id={"customerFranchise"}
                    className="status_Customer_list swhite filter_type"
                    placeholder="Select a Franchise"
                    name="franchise"
                    onChange={(e) => onFilterChange({ target: { name: 'franchise', value: e } })}
                    value={selectedFranchise}
                    disabled={franchiseList?.length === 1}>
                    {(current_user !== 'franchise_owner' || franchiseList?.length > 1) && <Option key={'franchiseAll'} value={'all'}>{'All'}</Option>}
                      {orderBy(franchiseList, [item => item.name.toLowerCase()], "asc")?.map(
                        (option) => (
                          <Option key={option?.uuid} value={option?.uuid}>
                            {option?.name}
                          </Option>
                        )
                      )}
                  </Select>
                  {/* </div> */}
                </div>
              </div>
              <span className="ico-btn" style={get_filter_icon_style()}>
                <FilterOutlined
                  onClick={() =>{
                    if(currentTab === "customer" || currentTab === "inactive_customer"){
                      setOpenDrawerCustomer(true);
                    }
                    else
                     setOpenDrawer(true);
                    }
                  }
                >
                  Filter
                </FilterOutlined>
              </span>
              <div className="right_side" style={{ flex: "none" }}>
                {currentTab === "lead"?
                    <button
                      disabled={isAddNewLead}
                      onClick={() => closeAddDrawer(true)}
                      className={
                        !isAddNewLead
                          ? "btn btn-warning"
                          : "btn btn-brand btn-bdr disbale_btn"
                      }
                    >
                      + Add
                    </button>
                : currentTab === "customer" ?<button className='btn btn-warning' onClick={()=>
                      navigate(`/residential/new-customer`, {
                        state: { source: "customers-lead-list" },
                      })
                }>+ Add</button>
                                            :<></>                    
                }
                {
                      getTableRecordsNumber()> 500 ? (
                      <Popconfirm
                        onConfirm={() => !loadingExport && onExportTable(1)}
                        okText="Export"
                        cancelText="Cancel"
                        icon={<></>}
                        title={
                          <>
                            <div>
                              {currentTab === "lead"?"Leads":"Customers"} list data is more than 500 records. Data would be
                              <br />
                              exported and emailed to
                              {' '}
                              <b>{authUser?.user?.email}</b>.
                            </div>
                          </>
                        }
                      >
                        <button
                        className="btn btn-warning"
                        >
                          {loadingExport ? 'Exporting...' : 'Export'}
                        </button>
                      </Popconfirm>
                    ) : (
                      <button
                        disabled={loadingExport}
                        onClick={() => onExportTable()}
                        className="btn btn-warning"
                        >
                          {loadingExport ? 'Exporting...' : 'Export'}
                        </button>
                    )
                  } 
              </div>
            </div>
          </div>
          <div className="data leadstable">
            {renderCommercialCustTable()}
          </div>
          {renderDrawer()}
          <Drawer
            visible={isAddNewLead}
            width={"440"}
            closable={false}
            className="drawer-wrapper create_ticket_drawer"
          >
            <AddNewLead
              closeAddDrawer={closeAddDrawer}
              startLoader={setLoader}
              view={"show_customer_type"}
            />
        </Drawer>
        </div>
      </div>
    </div>
  );
};
export default CustomerLeadList;
