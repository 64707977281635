import React from "react";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { addProposalConNotes } from "../../../Store/ProposalDataSlice";
import "../../../Assets/css/proposalBase.css";


const RenovationConstructionNotes = (props) => {
  const{ editMode } = props;
  const dispatch = useDispatch();
  const proposals = useSelector((state) => state.proposalDataSlice);

  return (
    <Row>
      <Col span={24}>
        {(proposals?.service_type_name=== "construction" ||
          proposals?.service_type_name === "renovation") && (
            <div className="frow">
              <label>Notes: Please Provide Customer's Request</label>
              <textarea
                placeholder="Customer proposal"
                name="message"
                rows="5"
                disabled={editMode}
                value={proposals.notes}
                onChange={(e) => dispatch(addProposalConNotes(e.target.value))}
              ></textarea>
            </div>
          )}
      </Col>
    </Row>
  );
};

export default RenovationConstructionNotes;
